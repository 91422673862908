<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { computed } from 'vue';
import FormFieldHierarchy from '~/forms/molecules/form-field-hierarchy.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  name: {
    type: String,
  },
  label: {
    type: String,
  },
  info: {
    type: String,
    default: null,
  },
  description: {
    type: String,
  },
  rules: {
    type: Array,
  },
  step_number: {
    type: Number,
    default: -1,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  editor_options: {
    type: Object,
    default: () => ({}),
  },
  filter_fields: {
    type: Array,
    default: () => [],
  },
  has_dynamic_fields: {
    type: Boolean,
    default: true,
  },
  additional_suggestions: {
    type: Array,
    default: () => [],
  },
});

const form_workflow_store = useFormWorkflowStore();
const form_template_detail_store = useFormTemplateDetailStore();

const is_workflow = computed(() => !!form_template_detail_store.form_template_detail.workflow);
const dynamic_filed_suggestions = computed(() => {
  if (!props.has_dynamic_fields)
    return [];
  const all_fields = [];
  let all_sections = [];
  const form_steps_with_sections = form_template_detail_store.steps_with_sections;
  if (is_workflow.value) {
    const steps = [];
    if (props.step_number === -1)
      steps.push(...Object.keys(form_template_detail_store.steps_with_sections).filter(step => form_steps_with_sections[step].active));
    else
      steps.push(...form_workflow_store.getSteps(props.step_number));

    all_sections = steps.flatMap(step => form_steps_with_sections[step].sections);
  }
  else {
    all_sections = form_template_detail_store.sections;
  }
  const all_section_fields = all_sections?.filter(section => section.status === 'active' && section.type === 'default').flatMap((section) => {
    return section.fields.filter(field => field.status === 'active' && (props.filter_fields.includes(field.type) || props.filter_fields.length === 0)).map(field => ({
      ...field,
      section_name: section.name,
      section_id: section.uid,
      ...(is_workflow.value
        ? {
            form_block_name: form_steps_with_sections[section.step_index].name,
            form_block_id: `block_${section.step_index}`,
          }
        : {}),
    }));
  });

  all_fields.push(...all_section_fields);
  return all_fields.map(field_data => ({ id: field_data.slug, label: field_data.name, ...field_data }));
});
</script>

<template>
  <div>
    <WysiwygEditorElement
      :name="name"
      :label="label"
      :info="info"
      :description="description"
      :rules="rules"
      :object="true"
      class="mb-5"
      :disabled="is_disabled"
      :add-classes="editor_options?.add_custom_class || {}"
      :options="{
        single_line: true,
        enable_common_rtf_features: false,
        plugins: ['disable-enter', 'tags'],
        suggestions: [
          {
            id: 'name',
            label: $t('Name'),
          },
          {
            id: 'day',
            label: $t('Day'),
          },
          {
            id: 'month_number',
            label: $t('Month Number'),
          },
          {
            id: 'month_name_short',
            label: $t('Month Name short'),
          },
          {
            id: 'month_name_full',
            label: $t('Month Name full'),
          },
          {
            id: 'year_short',
            label: $t('Year Short'),
            additional_item_classes: 'border-t border-gray-200',
          },
          {
            id: 'year_full',
            label: $t('Year Full'),
          },
          {
            id: 'category',
            label: $t('Category'),
            additional_item_classes: 'border-t border-gray-200',
          },
          {
            id: 'due_date',
            label: $t('Due Date'),
          },
          {
            id: 'created_at',
            label: $t('Created At'),
          },
          {
            id: 'submitted_at',
            label: $t('Submitted At'),
          },
          {
            id: 'submitted_by',
            label: $t('Submitted By'),
            additional_item_classes: 'border-t border-gray-200',
          },
          ...additional_suggestions,
        ],
        footer: {
          component: FormFieldHierarchy,
          component_props: {
            nested_suggestions: dynamic_filed_suggestions,
            is_workflow,
          },
        },
        nested_suggestions: dynamic_filed_suggestions,
        ...editor_options,
      }"
    />
  </div>
</template>
