<script setup>
// stores
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  item: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(['close']);

const document_store = useDocumentStore();

const $toast = inject('$toast');
const $t = inject('$t');

const form$ = ref(null);

// Popup save
async function handleSave() {
  const is_from_props = !!props.item;

  const type = is_from_props ? props.item.type : document_store.active_item_details.type;
  const uid = is_from_props ? props.item.uid : document_store.active_item_details.uid;
  const owner = form$.value.data.owner.uid;
  try {
    await document_store.crud_documents({
      request: {
        body: {
          [`${type}s`]: {
            update: [
              {
                uid,
                owner,
              },
            ],
          },
        },
      },
    });
    $toast({
      title: $t('Owner successfully transferred'),
      text: `${$t('The owner has been successfully transferred to ')} ${form$.value.data.owner.name}`,
      type: 'success',
      position: 'bottom-right',
    });
  }
  catch (e) {
    logger.error('TRANSFER OWNERSHIP: ', e);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again later'),
      type: 'error',
      position: 'bottom-right',
    });
  }
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :should_validate_on_mount="false" :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="handleSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t("Transfer Ownership") }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <div class="p-1 max-h-[575px] ">
            <hawk-assignee-input
              class="mb-6"
              :options="{
                name: 'owner',
                label: $t('Owner'),
                rules: ['required'],
                info: 'Owner',
                placeholder: $t('Select Owner'),
              }"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-submit-button :form$="form$">
                {{ $t('Transfer') }}
              </hawk-submit-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
