<script setup>
import TaskStatus from '~/tasks/components/atoms/task-status.vue';
import TaskPriority from '~/tasks/components/atoms/task-priority.vue';

const props = defineProps({
  activity_item: {
    type: Object,
    require: true,
  },
  has_meta_from: {
    type: Boolean,
    default: false,
  },
});

const task_store = inject('task_store');
const $t = inject('$t');

const activity = computed(() => {
  return props.activity_item;
});

function is_overdue(date) {
  if (!date)
    return false;
  return new Date(date).getTime() < new Date();
}
</script>

<template>
  <div>
    <div class="has-secondary-text">
      <div
        v-if="activity.verb === 'TICKET_ASSIGNMENT_CHANGED'"
        class="is-flex is-vcenter"
      >
        <div class="text-sm text-[#667085]">
          <span v-if="props.has_meta_from">
            <HawkMembers :members="activity.meta.status.from" type="badge" />
          </span>
          <span class="mx-2">
            {{ $t('to') }}
          </span>
          <HawkMembers :members="activity.meta.status.to" type="badge" />
        </div>
      </div>
      <div v-else-if="activity.verb === 'TICKET_UNASSIGNED'">
        <div class="text-sm text-[#667085]">
          <span v-if="props.has_meta_from">
            <HawkMembers :members="activity.meta.status.from" type="badge" />
          </span>
        </div>
      </div>
      <div v-else-if="activity.verb === 'TICKET_ASSIGNED'">
        <div class="text-sm text-[#667085]">
          <span class="mx-2">
            {{ $t('to') }}
          </span>
          <HawkMembers :members="activity.meta.status.to" type="badge" />
        </div>
      </div>
      <div v-else-if="activity.verb === 'TICKET_PRIORITY_CHANGED'">
        <div class="text-sm text-[#667085] flex items-center">
          <span v-if="props.has_meta_from">
            <TaskPriority :priority="activity.meta.priority.from" />
          </span>
          <span class="mx-2">
            {{ $t('to') }}
          </span>
          <TaskPriority :priority="activity.meta.priority.to" />
        </div>
      </div>
      <div v-else-if="activity.verb === 'TICKET_STATUS_CHANGED'">
        <div class="text-sm text-[#667085]">
          <span v-if="props.has_meta_from">
            <TaskStatus :status="activity.meta.status.from" />
          </span>
          <span class="mx-2">
            {{ $t('to') }}
          </span>
          <TaskStatus :status="activity.meta.status.to" />
        </div>
      </div>
      <div v-else-if="activity.verb === 'DUE_DATE_CHANGED'">
        <div class="text-sm text-[#667085] flex items-center">
          <span v-if="props.has_meta_from" :class="{ 'text-red-500': is_overdue(activity.meta.dueDate.from) }">
            {{ $date(activity.meta.dueDate.from, 'DATE_SHORT') }}
          </span>
          <span class="mx-2">
            {{ $t('to') }}
          </span>
          <span :class="{ 'text-red-500': is_overdue(activity.meta.dueDate.to) }">
            {{ activity.meta.dueDate.to ? $date(activity.meta.dueDate.to, 'DATE_SHORT') : '-' }}
          </span>
        </div>
      </div>
      <div v-else-if="activity.verb === 'START_DATE_CHANGED'">
        <div class="text-sm text-[#667085] flex items-center">
          <span v-if="props.has_meta_from">
            {{ $date(activity.meta.startDate.from, 'DATE_SHORT') }}
          </span>
          <span class="mx-2">
            {{ $t('to') }}
          </span>
          {{ activity.meta.startDate.to ? $date(activity.meta.startDate.to, 'DATE_SHORT') : '-' }}
        </div>
      </div>
      <div v-else-if=" activity.verb === 'WATCHERS_ADDED'">
        <HawkMembers :members="activity.meta.added?.map(user => user.uid)" class="mx-2" type="badge" />
      </div>
      <div v-else-if=" activity.verb === 'WATCHERS_REMOVED'">
        <HawkMembers :members="activity.meta.removed?.map(user => user.uid)" class="mx-2" type="badge" />
      </div>
      <div v-else-if="activity.verb === 'ASSIGNEE_ADDED' ">
        <HawkMembers :members="activity.meta.added" class="mx-2" type="badge" />
      </div>
      <div v-else-if="activity.verb === 'ASSIGNEE_REMOVED' ">
        <HawkMembers :members="activity.meta.removed" class="mx-2" type="badge" />
      </div>
      <div v-else-if="activity.verb === 'COMMENT_ADDED'">
        <div class="is-flex is-vcenter">
          <span class="text-sm text-[#667085] items-center">
            {{ activity.meta.comment }}
          </span>
        </div>
      </div>
      <div v-else-if="activity.verb === 'CATEGORY_ADDED'">
        <HawkCategoryName :uid="activity.meta.category.to" class="mx-2" />
      </div>
      <div v-else-if="activity.verb === 'CATEGORY_CHANGED'">
        <div class="text-sm text-[#667085] flex items-center">
          <HawkCategoryName v-if="props.has_meta_from" :uid="activity.meta.category.from" />
          <span class="mx-2">
            {{ $t('to') }}
          </span>
          <HawkCategoryName :uid="activity.meta.category.to" />
        </div>
      </div>
      <div v-else-if="activity.verb === 'CATEGORY_REMOVED'">
        <HawkCategoryName v-if="props.has_meta_from" :uid="activity.meta.category.from" class="mx-2" />
      </div>
      <div v-else-if="activity.verb === 'ATTACHMENT_UPLOADED'">
        <div class="is-flex is-vcenter">
          <span class="text-sm">{{ activity.meta.attachments.count }}
            {{
              activity.meta.attachments.count > 1 ? "attachments" : "attachment"
            }}
            {{ $t("added") }}</span>
        </div>
      </div>
      <div v-else-if="activity.verb === 'ATTACHMENT_DELETED'">
        <div class="is-flex is-vcenter">
          <span class="text-sm">{{ $t("Deleted") }}
            {{ activity.meta.attachments.count }}
            {{
              activity.meta.attachments.count > 1 ? "attachments" : "attachment"
            }}</span>
        </div>
      </div>
      <div v-else-if="activity.verb === 'MULTIPLE_ATTACHMENT_UPLOADED'">
        <div class="is-flex is-vcenter">
          <span class="text-sm">{{ activity.meta.attachments.count }}
            {{ $t("attachments added") }}</span>
        </div>
      </div>
      <div v-else-if="activity.verb === 'SUBTASK_ADDED'">
        <div
          v-if="activity?.meta?.uids?.length"
          class=" text-sm"
        >
          <p
            v-for="(uid, index) in activity.meta.uids"
            :key="index"
            class="flex items-center no-whitespace-wrap"
          >
            <IconHawkDependencyLinked class="text-primary mx-2 h-3" />
            {{ task_store?.get_current_task(uid)?.name || `*${$t('Task added')}` }}
          </p>
        </div>
      </div>
      <div v-else-if="activity.verb === 'SUBTASK_REMOVED'">
        <div class="roboto-medium-12 has-opacity-6">
          *{{ $t('Task removed') }}
        </div>
      </div>

      <div v-else-if="activity.verb === 'DEPENDENCY_ADDED'">
        <div class="is-flex is-vcenter">
          <span class="text-sm">
            {{ $t("added") }}
            {{ activity.meta.count }}
            {{
              activity.meta.blockedBy
                ? $t("blocking")
                : activity.meta.blocking
                  ? $t("blocked")
                  : $t("linked")
            }}

            {{
              activity.meta.count > 1
                ? $t("tasks")
                : $t("task")
            }}
          </span>
        </div>
      </div>
      <div v-else-if="activity.verb === 'DEPENDENCY_REMOVED'">
        <div class="is-flex is-vcenter">
          <span class="text-sm">{{ $t("removed") }}
            {{ activity.meta.count }}
            {{
              activity.meta.blockedBy
                ? $t("blocking")
                : activity.meta.waitingOn
                  ? $t("blocked")
                  : $t("linked")
            }} {{
              activity.meta.count > 1
                ? $t("tasks")
                : $t("task")
            }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
