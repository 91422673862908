<script setup>
import RuleFields from '~/forms/components/form-workflows/sidebar/conditional-block/rule-fields.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store.js';

const props = defineProps({
  el$: {
    type: Object,
    default: () => ({}),
  },
});

defineEmits(['update', 'delete']);

provide('use-field-uid', true);


const selected_field = inject('selected-field');
const form_template_detail_store = useFormTemplateDetailStore();
const form_workflow_store = useFormWorkflowStore();

function update_rules(rules) {
  props.el$.form$.update({
    ...props.el$.form$.data,
    condition: { ...selected_field.value.condition, rules: rules.map(rule => ({ ...rule })) },
  });
}

function get_all_fields(all_section_fields) {
  const all_fields = [];
  const current_index = all_section_fields.findIndex(field => field.uid === props.el$.views.field_uid);

  for (let index = 0; index < all_section_fields.length; index++) {
    const section_field = all_section_fields[index];
    const is_not_invalid_field = !(['attachment', 'check_item', 'auto_number', 'multi_text'].includes(section_field.type) || ['info', 'formula'].includes(section_field.properties?.type));

    if (current_index > index && section_field.status === 'active' && is_not_invalid_field)
      all_fields.push(section_field);
  }

  return all_fields;
}

const can_add_condition = computed(() => selected_field.value.condition.add_condition);
const fields_options = computed(() => {
  if (form_template_detail_store.form_template_detail.workflow) {
    // For already created and saved block form_template_detail_store.previous_step_number is null
    const all_section_fields = form_workflow_store.get_fields(form_template_detail_store.previous_step_number || form_template_detail_store.step_number, []);
    const fields = [];

    /**
     * New block can also have fields added, without the block being saved.
     * If the block is not saved the all_section_fields does not have all the ancestral fields,
     * In that case we get all the previous block fields and the current block fields separately and merge them.
     */
    const current_step_data = form_template_detail_store.steps_with_sections[form_template_detail_store.step_number];
    form_template_detail_store.previous_step_number && current_step_data.sections.forEach((section) => {
      if (section.type === 'default')
        fields.push(
          ...(
            section.fields?.filter(
              field => field.type !== 'group' && field.status === 'active',
            ) || []
          ).map((field) => {
            return {
              step: {
                name: current_step_data.name,
                index: current_step_data.index,
              },
              section_name: section.name,
              section_id: section.uid,
              form_block_name: current_step_data.name,
              form_block_id: `block_${current_step_data.index}`,
              ...field,
            };
          }),
        );
    });
    return get_all_fields([...all_section_fields, ...fields]);
  }
  else {
    const all_section_fields = form_template_detail_store.sections.filter(section => section.type === 'default').flatMap(section => section.fields.map(field => ({
      section_name: section.name,
      section_id: section.uid,
      ...field,
    })));
    return get_all_fields(all_section_fields);
  }
});

</script>

<template>
  <div v-if="fields_options.length" class="border-y border-gray-300 py-4 flex flex-col">
    <ToggleElement
      name="add_condition" :label="$t('Add condition')"
      :add-classes="{
        ToggleElement: {
          wrapper: ['justify-end'],
        },
      }"
    />
    <span class="w-2/3 text-gray-700 text-xs mt-1">{{ $t('Define conditions to determine whether to display or hide this field.') }}</span>

    <template v-if="can_add_condition">
      <div class="flex items-center">
        <div class="text-sm text-gray-700 font-medium mt-4">
          {{ $t('Conditions') }}
        </div>
      </div>
      <RuleFields
        :rules="selected_field?.condition?.rules || []"
        :fields="fields_options"
        @updateRules="update_rules"
      />
      <div class="text-sm text-gray-700 font-medium mb-4">
        {{ $t('Display when') }}
      </div>
      <RadiogroupElement
        name="type"
        :items="[
          { value: 'AND', label: $t('All conditions are met (AND)') },
          { value: 'OR', label: $t('Any of the conditions are met (OR)') },
        ]"
        :add-classes="{
          RadiogroupRadio: {
            container: 'mb-2',
          },
        }"
        class="mb-5"
      />
    </template>
  </div>
</template>
