<script setup>
import { watch } from 'vue';
import { isEqual } from 'lodash-es';

import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const graph_data = ref(null);
const loading = ref(false);

const is_grouped = computed(() => !!props.data?.data.csv_data?.formatted_data?.categories?.length);

const chart_config = computed(() => {
  return props.data?.data?.chart_config;
});
const chart_type = computed(() => {
  let type;
  switch (props.data?.data?.type) {
    case 'vertical_graph':
      type = is_grouped.value ? chart_config.value?.chart_display_mode === 'Unstacked' ? 'mscolumn2d' : 'stackedcolumn2d' : 'column2d';
      break;
    case 'horizontal_bar':
      type = is_grouped.value ? chart_config.value?.chart_display_mode === 'Unstacked' ? 'msbar2d' : 'stackedbar2d' : 'bar2d';
      break;
    case 'line':
      type = is_grouped.value ? 'msline' : 'line';
      break;
    case 'area':
      type = is_grouped.value ? 'msarea' : 'area2d';
      break;
    case 'donut':
      type = 'doughnut2d';
      break;
  }

  return type;
});

const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value || '',
  dataSource: {
    chart: {
      xAxisName: chart_config.value?.x_label || props?.data?.data?.csv_data?.data?.[0]?.[0] || '',
      yAxisName: chart_config.value?.y_label || '',
      plotToolText: !is_grouped.value || chart_type.value === 'doughnut2d'
        ? `<div>
      <table id='dataTable'>
        <tr>
          <td style="font-weight:700">$label:</td>
          <td style="font-weight:200">$value</td>
        </tr>  
      </table>
    </div>`
        : '<div><table id=\'dataTable\' ><tr class=\'\'><td style="font-weight:700">$xAxisName:</td><td style="font-weight:200">$label</td></tr><tr><td style="font-weight:700">$seriesname:</td><td style="font-weight:200">$value</td></tr></table></div>',
      subCaption: chart_config.value?.description
      || props.data.data.subCaption || '',

    },
    dashboard_index: props.data.i,
    chart_type: props.data.data.type,
    ...graph_data.value,
  },
}));

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  graph_data.value = props.data.data.csv_data.formatted_data;
  setTimeout(() => {
    loading.value = false;
  }, 1000);
}
if (props.id === 'preview')
  watch(() => props.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });

if (props.id !== 'preview')
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />
    <GraphComponent v-else-if="graph_config?.renderAt" :id="props.id" :configuration="graph_config" :chart_config="chart_config" />
  </div>
</template>
