<script setup>
/* SUPPORTS: [category, tag, member, date, text, number, task-status, form-status, asset] */
import HawkFieldRenderer from '~/common/components/hawk-field-renderer/hawk-field-renderer.vue';

const props = defineProps({
  cell: {
    type: Object,
    required: true,
  },
});

const render_as = props.cell.column.columnDef.render_as;
const cell_data = computed(() => props.cell?.getValue());

const options = computed(() => {
  const field_type = render_as.field_type;

  switch (field_type) {
    // Common
    case 'category':
      return {
        ...(render_as.options || {}),
        value: cell_data.value,
        truncate_length: props.cell.column.getSize() / 5,
      };
    case 'member':
      return {
        ...(render_as.options || {}),
        value: cell_data.value,
        type: 'badge',
      };
    case 'tag':
    case 'task-status':
    case 'task-priority':
      return {
        ...(render_as.options || {}),
        value: cell_data.value,
      };

    // Renderers
    case 'number':
    case 'date':
      return {
        value: cell_data.value,
        options: {
          field_type,
          ...(render_as.options || {}),
        },
      };

    case 'text':
    case 'asset':
      return {
        value: cell_data.value,
        options: {
          field_type,
          ...(render_as.options || {}),
          truncate_config: {
            ...(render_as.options?.truncate_config || {}),
            ...(render_as.options?.truncate_config?.type === 'adaptive' && { length: props.cell.column.getSize() / 5 }),
          },
        },
      };
    default:
      return {
        value: cell_data.value,
      };
  }
});
</script>

<template>
  <HawkFieldRenderer :field_type="render_as.field_type" :options="options" />
</template>
