<script setup>
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  task_uid: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['comment-form-change']);

dayjs.extend(isSameOrAfter);

const task_store = inject('task_store');

const { checkStatusUpdatePermission, checkTaskPermission } = useTasksPermissions();

const status_class_map = {
  1: 'text-warning-600',
  2: 'text-primary-600',
  3: 'text-success-600',
  4: 'text-gray-600',
  5: 'text-error-600',
};

const date_time_element_classes = {
  ElementLayout: {
    container: 'relative',
    innerWrapper: 'hover:bg-gray-50 rounded-md',
  },
};

const form$ = ref(null);
const form_data = ref(null);

const input_toggle_status = ref({
  status: false,
  due_date: false,
  assignees: false,
});

const task_details = computed(() => {
  return props.task_uid ? task_store.get_current_task(props.task_uid) : {};
});

const can_modify_task = computed(() => checkTaskPermission({ permission: 'can_modify', instance: task_details.value }));

const can_modify_status = computed(() => checkTaskPermission({ permission: 'can_modify_status', instance: task_details.value }));

const status_items = computed(() => task_store.status_values.map(status => ({ ...status, disabled: !checkStatusUpdatePermission(task_details.value, status.value) })));

const cursor_classes = computed(() =>
  ({
    TagsElement: {
      select: {
        wrapper: can_modify_task.value ? '' : 'cursor-pointer',
      },
    },
    SelectElement: {
      select: {
        wrapper: can_modify_task.value ? '' : 'cursor-pointer',
        dropdown: 'max-h-60 bottom-0',
      },
    },
  }),
);

onMounted(async () => {
  await form$.value?.load(task_details.value, true);
});
watch(task_details, async (newVal) => {
  await form$.value?.load(newVal, true);
});

function formatLoadedData(data) {
  const fields = Object.keys(input_toggle_status.value);
  return fields.reduce((acc, cur) => {
    acc[cur] = data[cur];
    return acc;
  }, {});
}

defineExpose({ form_data });
</script>

<template>
  <Vueform
    ref="form$"
    v-model="form_data"
    size="sm"
    :format-load="formatLoadedData"
    :presets="['static_display']"
    :override-classes="{
      ElementLayout: {
        innerContainer: '',
        innerWrapper: '',
        outerWrapper: '',
      },
      ElementLabel: {
        container: 'text-gray-600 !text-xs',
        wrapper: 'ml-[12px]',
      },
    }"
    :add-classes="{
      ElementLabel: {
        container_horizontal_sm: 'text-type:form-pt-input-border-sm',
        container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm',
        container_horizontal_sm_MD: 'md:text-type:form-pt-input-border-sm',
      },
    }"
    @change="emit('comment-form-change')"
  >
    <!-- status -->
    <SelectElement
      v-if="can_modify_status"
      v-click-outside="() => input_toggle_status.status = false"
      v-bind="{
        name: 'status',
        label: $t('Status'),
        placeholder: 'Select status',
        items: status_items,
        canClear: false,
        canDeselect: false,
        native: false,
        disabled: !can_modify_status,
        openDirection: 'top',
        closeOnDeselect: 'false',
        appendTo: '#task-comment-settings',
      }"
      :override-classes="{
        ElementLayout: {
          container_sm: `!col-span-3 !sm:col-span-3`,
        },
      }"
      :add-classes="{
        SelectElement: {
          select: {
            dropdown: 'w-48 h-[210px]',
          },
        },
      }"
      :remove-classes="cursor_classes"
    >
      <template #single-label="{ value }">
        <div
          class="flex items-center h-full w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3 text-sm font-semibold"
          :class="status_class_map[value.value]"
        >
          {{ task_store.status_map[value.value].label }}
        </div>
      </template>
      <template #option="{ option }">
        <div
          class="text-sm font-semibold whitespace-nowrap"
          :class="status_class_map[option.value]"
        >
          {{ option.label }}
        </div>
      </template>
    </SelectElement>
    <EmptyStaticElement v-else :label="$t('Status')" />

    <!-- Due Date -->
    <DateTimeElement
      v-if="can_modify_task || task_details.due_date"
      :key="task_details.start_date"
      v-click-outside="() => input_toggle_status.due_date = false"
      class="date_time"
      :class="{ overdue: dayjs().isSameOrAfter(dayjs(task_details.due_date)) }"
      name="due_date"
      :label="$t('Due Date')"
      :add-classes="date_time_element_classes"
      :override-classes="{
        ElementLayout: {
          container_sm: `!col-span-3 !sm:col-span-3`,
        },
      }"
      :options="{
        clearable: false,
        format: 'dd MMM yyyy',
        placeholder: $t('Select Due Date'),
        disabled: !can_modify_task,
        startTime: { hours: 23, minutes: 59 },
        minDate: task_details.start_date ? new Date(task_details.start_date) : null,
      }"
      @open="input_toggle_status.due_date = true"
    >
      <template #after>
        <div class="flex items-center justify-center absolute right-2 top-8">
          <div v-if="input_toggle_status.due_date" class="flex items-center">
            <IconHawkTrashThree
              v-if="task_details.due_date"
              class="cursor-pointer text-gray-600"
              @click="() => { form_data.due_date = null; input_toggle_status.due_date = false }"
            />
          </div>
        </div>
      </template>
    </DateTimeElement>
    <EmptyStaticElement v-else :label="$t('Due Date')" />

    <!-- Assignees -->
    <hawk-assignee-input
      v-if="can_modify_task || task_details.assignees.length"
      v-click-outside="() => input_toggle_status.assignees = false"
      :options="{
        name: 'assignees',
        label: $t('Assignees'),
        canClear: false,
        placeholder: 'Select assignees',
        size: 'sm',
        disabled: !can_modify_task,
        has_teams: true,
        openDirection: 'top',
        appendTo: '#task-comment-settings',
      }"
      :multi="true"
      :value="task_details.assignees"
      :asset_id="task_details.target_element.asset"
      :tags_removable="input_toggle_status.assignees"
      :truncate_tag_length="10"
      :format_load="true"
      :add-classes="{
        TagsElement: {
          select: {
            tagsSearch: input_toggle_status.assignees ? '' : 'cursor-pointer',
            wrapper: 'px-2',
            dropdown: 'h-[240px]',
          },
        },
      }"
      :override-classes="{
        ElementLayout: {
          container_sm: `!col-span-6 !sm:col-span-6`,
        },
      }"
      :remove-classes="cursor_classes"
      @open="input_toggle_status.assignees = true"
    />
    <EmptyStaticElement v-else :label="$t('Assignees')" />
  </Vueform>
</template>

<style lang="scss" scoped>
.date_time {
  :deep(.dp__input){
    border: 0 solid #3b82f6;
    background-color: transparent;
    padding-left: 12px;
  }
  :deep(.dp__input:focus){
    border: 1px solid;
  }

  :deep(.dp__input_icon) {
    display: none;
  }
}
.overdue {
  :deep(.dp__input){
    @apply text-error-700
  }
}
</style>

<style lang="scss">
.static-display {
  border: 1px solid transparent !important;

  .caret {
    visibility: hidden;
  }
  .select-caret {
    visibility: hidden;
  }
  &:hover {
    border: 1px solid transparent !important;
    background: #F9FAFB !important;
    .caret {
      visibility: visible;
    }
    .select-caret {
      visibility: visible;
    }
  }

}

.static-display-focused{
  border: 1px solid transparent !important;

  &:focus-within {
    border: 1px solid #1570EF;
    border-radius: 8px;
    .caret {
      visibility: visible;
    }
    .select-caret {
      visibility: visible;
    }
  }
}

.static-display-disabled{
  cursor: default;
  .caret {
    visibility: hidden !important;
  }
  .select-caret {
    visibility: hidden !important;
    }
  &:hover{
    background-color: white !important;
    cursor: default;
    .caret {
      visibility: hidden;
    }
    .select-caret {
      visibility: hidden;
    }
  }
}
</style>
