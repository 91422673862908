<script setup>
import { useModal } from 'vue-final-modal';
import CreateTag from '~/common/components/molecules/create-tag.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useTagsStore } from '~/common/stores/tags.store.js';
import { searchData, sortRowsByColumn } from '~/common/utils/common.utils.js';

const $t = inject('$t');
const tags_store = useTagsStore();

const COLUMNS = [
  { header: $t('Name'), accessorKey: 'name', id: 'name', sortingFn: sortRowsByColumn },
  { accessorKey: 'context_menu', header: '', id: 'context_menu', show_on_hover: 'true', customStyle: { width: '20px' } },
];

const search = ref('');
const filtered_data = computed(() => searchData(tags_store.tags, search.value));

const { open: openTagModal, close: closeTagModal, patchOptions: patchTagModal } = useModal({
  component: CreateTag,
  attrs: {
    onClose() {
      closeTagModal();
    },
  },
});
const { open: openDeleteModal, close: closeDeleteModal, patchOptions: patchDeleteModal } = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      closeDeleteModal();
    },
  },
});

async function onActionClicked(action_type, tag = null) {
  if (action_type === 'delete') {
    patchDeleteModal({
      attrs: {
        header: $t('Delete Tag'),
        content: `Are you sure you want to delete ${tag.name || ''}? This action cannot be undone.`,
        confirm: async () => {
          await tags_store.delete_tag(tag.uid);
          closeDeleteModal();
        },
      },
    });
    openDeleteModal();
  }
  else {
    patchTagModal({
      attrs: {
        action_type,
        tag,
      },
    });
    openTagModal();
  }
}

function getMenu(item) {
  return [
    { label: $t('Edit'), uid: 'edit', on_click: () => onActionClicked('edit', item) },
    { label: $t('Delete'), uid: 'delete', on_click: () => onActionClicked('delete', item) },
  ];
}
</script>

<template>
  <div>
    <HawkPageHeader class="!px-0 !pt-0">
      <template #title>
        <div>
          <div>{{ $t('Tags') }}</div>
          <p class="text-sm text-gray-600 font-normal">
            {{ $t('Configure tags to label resources like tasks, forms, documents, etc to provide additional context and further segregate them') }}
          </p>
        </div>
      </template>
      <template #right>
        <HawkButton
          color="primary"
          @click="onActionClicked('add')"
        >
          <IconHawkPlus class="text-white" />
          <span>{{ $t('New Tag') }}</span>
        </HawkButton>
      </template>
    </HawkPageHeader>

    <HawkPageSecondaryHeader>
      <template #left>
        <HawkSearchInput
          v-model="search"
          :placeholder="$t('Search tag')"
        />
      </template>
    </HawkPageSecondaryHeader>

    <div v-if="!filtered_data?.length">
      <HawkIllustrations v-if="search" type="no-results" for="tags" />
      <HawkIllustrations v-else type="no-data" for="tags" />
    </div>
    <div v-else-if="filtered_data?.length">
      <TableWrapperVue :key="filtered_data.length" container_class="my-4">
        <HawkTable
          :pagination_config="{ totalRows: filtered_data.length, pageSize: 25 }"
          :data="filtered_data"
          :columns="COLUMNS"
          :is_loading="false"
          :default_height="500"
          is_gapless
          :show_menu_header="false"
          :non_sortable_columns="['context_menu']"
          @row-clicked="onActionClicked('edit', $event)"
        >
          <template #context_menu="{ data }">
            <HawkMenu
              :items="getMenu(data?.row?.original)"
              position="fixed"
              @click.stop
            >
              <template #trigger>
                <IconHawkDotsVertical />
              </template>
            </HawkMenu>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
