<script setup>
import { compact, flow, uniq } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  transmittal: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);
const form$ = ref(null);
const show_cc = ref(false);
const has_custom_options = true;
const { logged_in_user_details } = useAuthStore();
const uniqCompact = flow([compact, uniq]);

const logged_in_user_id = logged_in_user_details?.user_id;
const members = props?.transmittal?.members || [];
const filtered_members = members.filter(member => ['approver', 'submitter', 'information'].includes(member.role)).map(member => member.uid);
const all_members = uniqCompact([...filtered_members, props.transmittal?.owner, undefined]);

const custom_options = all_members.filter(uid => uid !== logged_in_user_id);
function formatMemberTeam(assignees) {
  if (!Array.isArray(assignees))
    assignees = [assignees];
  return assignees.map(assignee => assignee.uid || assignee);
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]" :options="{ 'focus-trap': { allowOutsideClick: true } }">
    <Vueform
      ref="form$"
      :presets="['']"
      :display-errors="false"
      :endpoint="props.submit"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 4 },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Reply') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <HawkAssigneeInput
            :multi="true"
            :format_load="false"
            :options="{
              'name': 'to',
              'label': 'To',
              'can_clear': true,
              'size': 'sm',
              'rules': ['required'],
              'format-data': (n, v) => ({ [n]: v ? formatMemberTeam(v) : '' }),
            }"
            :has_custom_options="has_custom_options"
            :custom_options="custom_options"
          >
            <template #after>
              <div class="flex">
                <hawk-button v-if="!show_cc" type="link" @click="show_cc = true">
                  <icon-hawk-plus /> {{ $t("Cc") }}
                </hawk-button>
              </div>
            </template>
          </HawkAssigneeInput>
          <HawkAssigneeInput
            v-if="show_cc"
            :multi="true"
            :format_load="false"
            :options="{
              'name': 'cc',
              'label': 'Cc',
              'can_clear': true,
              'size': 'sm',
              'format-data': (n, v) => ({ [n]: v ? formatMemberTeam(v) : '' }),
            }"
            :has_custom_options="has_custom_options"
            :custom_options="custom_options"
            class="mt-4"
          />
          <div class="mt-6">
            <ObjectElement name="message">
              <WysiwygEditorElement
                name="description"
                :label="$t('Comment')"

                :options="{
                  editor_enabled: true,
                  menu_enabled: true,
                  plugins: ['images', 'tables', 'tags'],
                  placeholder_text: $t('Enter info here'),
                }"
                rules="required"
              />
              <DmsUploadElement
                class="mt-6"
                name="supporting_documents"
                :label="$t('Supporting documents')"
              />
            </ObjectElement>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits size="sm" name="submit" :button-label="$t('Send')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
