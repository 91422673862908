import { csvInjectionProtector } from '~/common/utils/common.utils.js';

export async function exportTableAsXLSX(table, filename, apply_filters = true, stringifier_map = {}) {
  const ExcelJS = await import('exceljs');
  const { saveAs } = await import('file-saver');

  const workbook = new ExcelJS.Workbook();

  // how many separate Excel  will open when viewing
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  const lastHeaderGroup = table.getHeaderGroups().at(-1);
  if (!lastHeaderGroup) {
    logger.error('No header groups found', table.getHeaderGroups());
    return;
  }

  const parent_headers = [];
  let actual_headers = [];
  let is_add_parent_headers = false;
  actual_headers = lastHeaderGroup.headers
    .filter(h => h.column.getIsVisible())
    .map((header) => {
      if (header.column.parent === undefined) {
        parent_headers.push('');
      }
      else {
        parent_headers.push(header.column.parent.columnDef.header);
        is_add_parent_headers = true;
      }

      return header.column.columnDef.header;
    });
  if (parent_headers[parent_headers.length - 1] === '' && actual_headers[actual_headers.length - 1] === '') {
    parent_headers.pop();
    actual_headers.pop();
  }

  const exportRows = apply_filters ? table.getFilteredRowModel().rows : table.getCoreRowModel().rows;

  const data = [];
  exportRows.forEach((row) => {
    const cells = row.getVisibleCells();
    const values = cells.map((cell) => {
      const cell_value = cell.getValue();
      if (cell_value === null || cell_value === undefined)
        return '';
      if (stringifier_map?.[cell.column?.id])
        return stringifier_map[cell.column.id](cell_value);
      return cell_value;
    });
    data.push(values);
  });
  const all_headers = [];
  if (is_add_parent_headers)
    all_headers.push(parent_headers);
  all_headers.push(actual_headers);

  for (const arr of all_headers)
    for (let j = 0; j < arr.length; j++)
      if (typeof arr[j] === 'string' || arr[j] instanceof String)
        arr[j] = csvInjectionProtector(arr[j]);
  for (const arr of data)
    for (let j = 0; j < arr.length; j++)
      if (typeof arr[j] === 'string' || arr[j] instanceof String)
        arr[j] = csvInjectionProtector(arr[j]);

  const worksheet = workbook.addWorksheet('My Sheet');
  worksheet.addRows([
    ...all_headers,
    ...data,
  ]);

  // Get the header rows
  const header_rows = worksheet.getRows(1, all_headers.length);

  header_rows.forEach((row) => {
    // Style the header rows
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F3' }, // gray background color
      };
      cell.font = {
        bold: true,
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });

  const to_merge_pairs = [];
  let current_pair = { first: null, second: null };
  for (let i = 0; i < parent_headers.length - 1; i++) {
    if (parent_headers[i] === '') {
      if (current_pair.first !== null) {
        to_merge_pairs.push(current_pair);
        current_pair = { first: null, second: null };
      }
      continue;
    }
    if (parent_headers[i] === parent_headers[i + 1]) {
      if (current_pair.first === null)
        current_pair.first = i;
      current_pair.second = i + 1;
    }
    else {
      if (current_pair.first !== null)
        to_merge_pairs.push(current_pair);
      current_pair = { first: null, second: null };
    }
  }

  to_merge_pairs.forEach((item) => {
    worksheet.mergeCells(0, item.first + 1, 0, item.second + 1);
  });

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${filename}.xlsx`);
  }
  catch (error) {
    logger.log('Error writing excel export', error);
  }
}

export async function getTableDataAsAoA(table, apply_filters = true, stringifier_map = {}) {
  const exportRows = apply_filters ? table.getFilteredRowModel().rows : table.getCoreRowModel().rows;
  const data = exportRows.map((row) => {
    const cells = row.getVisibleCells();
    const values = cells.map((cell) => {
      const cell_value = cell.getValue();
      if (cell_value === null || cell_value === undefined)
        return '';
      if (stringifier_map?.[cell.column?.id])
        return stringifier_map[cell.column.id](cell_value);
      return cell_value;
    });
    return values;
  });

  for (const arr of data)
    for (let j = 0; j < arr.length; j++)
      if (typeof arr[j] === 'string' || arr[j] instanceof String)
        arr[j] = csvInjectionProtector(arr[j]);

  logger.log('%cdata', 'font-weight: bold; color: tomato;', data);
  return data;
}
