<!-- eslint-disable vue/no-unused-refs -->
<!-- eslint-disable vue/valid-attribute-name -->
<script>
export default {
  name: 'ListElement',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        list: '',
        listItem: '',
        handle: '',
        handleIcon: '',
        remove: '',
        removeIcon: '',
        add: '',
      },
    };
  },
};
</script>

<template>
  <component :is="elementLayout" ref="container">
    <template #element>
      <!-- Sorting container -->
      <div ref="list" :class="classes.list" role="list" :aria-labelledby="labelId" @keypress.enter.prevent.stop="e => hasAdd ? handleAdd(e) : null">
        <div v-for="(val, i) in value" :key="i" :class="classes.listItem" role="listitem" :data-id="`${path}-${i}`">
          <slot :index="i">
            <component
              :is="component(prototype)"
              v-if="prototype.type"
              v-bind="prototype"
              :key="i"
              :name="i"
            />
          </slot>

          <!-- Sort handle -->
          <span
            v-if="hasSort"
            :class="classes.handle"
            data-handle
          >
            <span :class="classes.handleIcon" />
          </span>

          <!-- Remove button -->
          <div
            v-if="hasRemove"
            :id="`${path}-${i}-remove-button`"
            :aria-roledescription="form$.translations.vueform.a11y.list.remove"
            :class="classes.remove"
            role="button"
            tabindex="0"
            @click.prevent="handleRemove(i)"
            @keypress.space.enter="handleRemove(i)"
          >
            <span :class="classes.removeIcon" />
          </div>
        </div>
      </div>

      <!-- Add button -->
      <div
        v-if="hasAdd"
        :id="`${fieldId}-add-button`"
        :class="classes.add"
        role="button"
        tabindex="0"
        @click.prevent="handleAdd"
        @keypress.enter.space="handleAdd"
      >
        {{ addLabel }}
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </component>
</template>
