import { computed, inject } from 'vue';
import { COLORS } from '../constants';
import { useAnnotationsStore } from '../store/annotations.store';
import { useDocumentTools } from './useDocumentTools';
import OpacitySlider from '~/plans/components/annotation-edit/opacity-slider.vue';

export function useAnnotationsEdit(document_viewer_instance) {
  const $t = inject('$t');

  const annotations_store = useAnnotationsStore();
  const {
    get_color_format,
    get_selected_annotations,
    set_annotations_style,
    adjustFreeTextBoundingBox,
  } = useDocumentTools(document_viewer_instance);

  const handler_methods = {
    fill_color: (color) => {
      const color_with_alpha = [...color.rgb, annotations_store.annotations_edit_config.fill_opacity / 100];
      set_annotations_style(get_selected_annotations(), { FillColor: get_color_format(color_with_alpha) });
    },
    fill_opacity: (opacity_val) => {
      const selected_annotations = get_selected_annotations();
      const color_with_alpha = [...annotations_store.annotations_edit_config.fill_color.rgb, opacity_val / 100];
      // Handling fill opacity/alpha value separately using annotation custom data
      selected_annotations.forEach((annotation) => {
        annotation.setCustomData('CustomFillOpacity', opacity_val / 100);
      });
      set_annotations_style(selected_annotations, {
        FillColor: get_color_format(color_with_alpha),
      });
    },
    outline_color: (color) => {
      const color_with_alpha = [...color.rgb, annotations_store.annotations_edit_config.outline_opacity / 100];
      set_annotations_style(get_selected_annotations(), { StrokeColor: get_color_format(color_with_alpha), TextColor: get_color_format(color_with_alpha) });
    },
    outline_opacity: (opacity_val) => {
      const selected_annotations = get_selected_annotations();
      const color_with_alpha = [...annotations_store.annotations_edit_config.outline_color.rgb, opacity_val / 100];
      // Handling stroke opacity/alpha value separately using annotation custom data
      selected_annotations.forEach((annotation) => {
        annotation.setCustomData('CustomStrokeOpacity', opacity_val / 100);
      });
      set_annotations_style(selected_annotations, {
        StrokeColor: get_color_format(color_with_alpha),
        TextColor: get_color_format(color_with_alpha),
      });
    },
    stroke_thickness: (opacity_val) => {
      annotations_store.annotations_edit_config.is_initial_stroke = false; // To indicate that stroke value has been changed or not
      set_annotations_style(get_selected_annotations(), { StrokeThickness: opacity_val });
    },
  };

  const change_color_handler = (type, color) => {
    annotations_store.annotations_edit_config[type] = color;
    handler_methods[type]?.(color);
  };

  const change_opacity_handler = (type, opacity_val) => {
    annotations_store.annotations_edit_config[type] = opacity_val;
    handler_methods[type]?.(opacity_val);
  };

  const change_stroke_style_handler = (value) => {
    set_annotations_style(get_selected_annotations(), {
      Style: value,
      Dashes: ['2', '2'],
    });
  };

  const change_font_size_handler = (font_size) => {
    annotations_store.annotations_edit_config.font_size = font_size;
    set_annotations_style(get_selected_annotations(), {
      FontSize: `${font_size}pt`,
    });

    const selected_text_annotations = get_selected_annotations(['FreeText'], true);
    selected_text_annotations.forEach((annotation) => {
      adjustFreeTextBoundingBox(annotation);
    });
  };

  const change_text_horizontal_align = (text_x_align) => {
    annotations_store.annotations_edit_config.text_x_align = text_x_align;
    set_annotations_style(get_selected_annotations(), {
      TextAlign: text_x_align,
    });
  };

  const change_text_vertical_align = (text_y_align) => {
    annotations_store.annotations_edit_config.text_y_align = text_y_align;
    set_annotations_style(get_selected_annotations(), {
      TextVerticalAlign: text_y_align,
    });
  };

  // As the IconHawk components are defined globally we dont need to import them
  // so added the eslint disable comment
  function editAnnotColorSubtool(type) {
    return [
      ...COLORS.map(color => ({
        tooltip: $t(color.name),
        // eslint-disable-next-line no-undef
        icon: IconHawkFilledCircle,
        color: color.hex,
        action: () => {
          change_color_handler(type, color);
        },
      })),
    ];
  }

  const edit_normal_annotation_tools = computed(() => {
    return [
      {
        container_class: 'h-56 overflow-hidden scrollbar',
        color: annotations_store.annotations_edit_config.outline_color.hex,
        event: 'outline-color',
        // eslint-disable-next-line no-undef
        icon: IconHawkCircle,
        sub_tools: editAnnotColorSubtool('outline_color'),
        tooltip: $t('Outline color'),
      },
      {
        container_class: 'w-64',
        event: 'outline-opacity',
        // eslint-disable-next-line no-undef
        icon: IconHawkOpacity,
        sub_tools: [
          {
            component: OpacitySlider,
            props: {
              component_type: 'outline-opacity',
              change_opacity_handler,
            },
            tooltip: $t('Outline opacity'),
          },
        ],
        tooltip: $t('Outline opacity'),
      },
      {
        container_class: 'h-56 overflow-hidden scrollbar',
        color: annotations_store.annotations_edit_config.fill_color.hex,
        event: 'fill-color',
        // eslint-disable-next-line no-undef
        icon: IconHawkFilledCircle,
        sub_tools: editAnnotColorSubtool('fill_color'),
        tooltip: $t('Fill color'),
      },
      {
        container_class: 'w-64',
        event: 'fill-opacity',
        // eslint-disable-next-line no-undef
        icon: IconHawkOpacity,
        sub_tools: [
          {
            component: OpacitySlider,
            props: {
              component_type: 'fill-opacity',
              change_opacity_handler,
            },
            tooltip: $t('Fill opacity'),
          },
        ],
        tooltip: $t('Fill opacity'),
      },
      {
        tooltip: $t('Stroke thickness'),
        // eslint-disable-next-line no-undef
        icon: IconHawkBrushLight,
        event: 'stroke-thickness',
        container_class: 'w-64',
        sub_tools: [
          {
            tooltip: $t('Stroke thickness'),
            component: OpacitySlider,
            props: {
              component_type: 'stroke-thickness',
              change_opacity_handler,
            },
          },
        ],
      },
      {
        tooltip: $t('Stroke style'),
        // eslint-disable-next-line no-undef
        icon: IconHawkMinus,
        event: 'stroke-style',
        sub_tools: [
          {
            tooltip: $t('Solid'),
            // eslint-disable-next-line no-undef
            icon: IconHawkMinus,
            action: () => {
              change_stroke_style_handler('solid');
            },
          },
          {
            tooltip: $t('Dashed'),
            // eslint-disable-next-line no-undef
            icon: IconHawkStrokeDash,
            action: () => {
              change_stroke_style_handler('dash');
            },
          },
        ],
      },
    ];
  });

  const edit_text_annotation_tools = computed(() => {
    return [
      {
        tooltip: $t('Text color'),
        // eslint-disable-next-line no-undef
        icon: IconHawkCircle,
        container_class: 'h-56 overflow-hidden scrollbar',
        color: annotations_store.annotations_edit_config.outline_color.hex,
        event: 'outline-color',
        sub_tools: editAnnotColorSubtool('outline_color'),
      },
      {
        tooltip: $t('Text opacity'),
        // eslint-disable-next-line no-undef
        icon: IconHawkOpacity,
        event: 'outline-opacity',
        container_class: 'w-64',
        sub_tools: [
          {
            tooltip: $t('Text opacity'),
            component: OpacitySlider,
            props: {
              component_type: 'outline-opacity',
              change_opacity_handler,
            },
          },
        ],
      },
      {
        tooltip: $t('Fill color'),
        // eslint-disable-next-line no-undef
        icon: IconHawkFilledCircle,
        container_class: 'h-56 overflow-hidden scrollbar',
        color: annotations_store.annotations_edit_config.fill_color.hex,
        event: 'fill-color',
        sub_tools: editAnnotColorSubtool('fill_color'),
      },
      {
        tooltip: $t('Fill opacity'),
        // eslint-disable-next-line no-undef
        icon: IconHawkOpacity,
        event: 'fill-opacity',
        container_class: 'w-64',
        sub_tools: [
          {
            tooltip: $t('Fill opacity'),
            component: OpacitySlider,
            props: {
              component_type: 'fill-opacity',
              change_opacity_handler,
            },
          },
        ],
      },
      {
        tooltip: $t('Font size'),
        // eslint-disable-next-line no-undef
        icon: IconHawkLineHeight,
        event: 'font-size',
        container_class: 'w-64',
        sub_tools: [
          {
            tooltip: $t('Font size'),
            component: OpacitySlider,
            props: {
              component_type: 'font-size',
              change_font_size_handler,
            },
          },
        ],
      },
      {
        tooltip: $t('Text align'),
        // eslint-disable-next-line no-undef
        icon: IconHawkAlignLeft,
        event: 'text-align',
        sub_tools: [
          {
            tooltip: $t('Text left'),
            // eslint-disable-next-line no-undef
            icon: IconHawkAlignLeft,
            action: () => {
              change_text_horizontal_align('left');
            },
          },
          {
            tooltip: $t('Text center'),
            // eslint-disable-next-line no-undef
            icon: IconHawkAlignCenter,
            action: () => {
              change_text_horizontal_align('center');
            },
          },
          {
            tooltip: $t('Text right'),
            // eslint-disable-next-line no-undef
            icon: IconHawkAlignRight,
            action: () => {
              change_text_horizontal_align('right');
            },
          },
          {
            tooltip: $t('Text justify'),
            // eslint-disable-next-line no-undef
            icon: IconHawkAlignJustify,
            action: () => {
              change_text_horizontal_align('justify');
            },
          },
          {
            tooltip: $t('Text top'),
            // eslint-disable-next-line no-undef
            icon: IconHawkAlignTopArrowOne,
            action: () => {
              change_text_vertical_align('top');
            },
          },
          {
            tooltip: $t('Text middle'),
            // eslint-disable-next-line no-undef
            icon: IconHawkAlignVerticalCenterOne,
            action: () => {
              change_text_vertical_align('center');
            },
          },
          {
            tooltip: $t('Text bottom'),
            // eslint-disable-next-line no-undef
            icon: IconHawkAlignBottomOne,
            action: () => {
              change_text_vertical_align('bottom');
            },
          },
        ],
      },
    ];
  });

  return {
    change_color_handler,
    change_opacity_handler,
    change_stroke_style_handler,
    change_font_size_handler,
    change_text_horizontal_align,
    change_text_vertical_align,
    edit_normal_annotation_tools,
    edit_text_annotation_tools,
  };
}
