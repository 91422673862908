<script setup>
import hawkButton from '~/common/components/atoms/hawk-button.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormPermissions } from '~/forms/composables/form-permissions.composable';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { auth_store, common_store, route, router } = useCommonImports();
const member_team = ref(null);
const save_loading = ref(false);
const { share_access_levels } = useFormPermissions();
const form_template_detail_store = useFormTemplateDetailStore();

// saving sharing users
function data_structure(arr) {
  const members_obj = {};
  arr.forEach((item) => {
    members_obj[`${item.uid}`] = item.access;
  });
  return { ...members_obj };
}
async function onSave() {
  const payload = {
    body: {
      members: data_structure(member_team.value.users),
      teams: data_structure(member_team.value.teams),
    },
  };
  save_loading.value = true;
  await form_template_detail_store.update_form_details(payload);
  form_template_detail_store.form_template_track_events('Members updated');
  save_loading.value = false;
}

const members = computed(() => common_store.filter_users(form_template_detail_store?.form_template_detail?.members || []));
const teams = computed(() => common_store.filter_teams(form_template_detail_store?.form_template_detail?.members || []));
</script>

<template>
  <div class="border-b pb-6">
    <h2 class="mt-6 text-lg font-semibold tracking-tight">
      {{ $t('Sharing') }}
    </h2>
    <span class="mt-6 text-gray-600 text-xs"> {{ $t('The following users have access to this template') }}</span>
    <div class="flex flex-row mt-6">
      <div class="basis-[40%]">
        <HawkShare
          :access_levels="share_access_levels"
          :members="members" :teams="teams" @input="$event => member_team = $event"
        />
      </div>
    </div>
  </div>
  <div class="sticky bottom-0 bg-white z-1">
    <div class="mt-4 flex justify-end text-sm font-medium">
      <hawk-button
        class="mr-3"
        type="outlined"
        @click="router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid } })"
      >
        {{ $t('Cancel') }}
      </hawk-button>
      <hawk-button
        button-class="w-full bg-blue-600" class="pl-4" name="submit" :submits="true" :disabled="!member_team" :loading="save_loading"
        @click="onSave"
      >
        {{ $t('Share') }}
      </hawk-button>
    </div>
  </div>
</template>
