<script setup>
const props = defineProps({
  details: {
    type: Array,
    default: () => [],
  },
  timeline_class: {
    type: String,
    default: 'bg-gray-200',
    required: false,
  },
});
</script>

<template>
  <div class="mx-4">
    <ol class="">
      <li
        v-for="(detail, index) in props.details" :key="detail.uid" class="border-gray-200"
        :class="{
          'border-l-2': props?.details?.length !== index + 1,
          'border-l-2 border-transparent': index === props?.details?.length - 1,
          'dynamic-after relative': index === 0 && timeline_class.includes('versions'),
        }"
      >
        <div class="flex flex-start text-sm">
          <div class="w-8 h-8 flex items-center justify-center rounded-full -ml-4 flex-shrink-0" :class="timeline_class">
            <slot name="icon" :data="detail" />
          </div>
          <div class="block ml-3 mb-8">
            <div>
              <slot name="title" :data="detail" />
            </div>
            <div>
              <slot name="description" :data="detail" />
            </div>
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>

<style lang="scss" scoped>
.dynamic-after {
  &::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: white;
    position: absolute;
    top: 0;
    left: -3px;
  }
}
</style>
