import { datadogRum } from '@datadog/browser-rum';
import { version } from '../../../package.json';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { accessTokenCookie, getHostName, handleAppUpdate } from '~/common/utils/common.utils';

function computeViewName(routeMatches) {
  let viewName = '';
  for (const routeMatch of routeMatches) {
    const path = routeMatch.path;
    // Skip pathless routes
    if (!path)
      continue;

    if (path.startsWith('/'))
      // Handle absolute child route paths
      viewName = path;
    else
      // Handle route paths ending with "/"
      viewName += viewName.endsWith('/') ? path : `/${path}`;
  }
  return viewName || '/';
}

function getViewNameBasedOnQuery(query) {
  if (query.form)
    return 'Form detail';
  else if (query.view_name === 'scan')
    return 'Terra Viewer - Scan';
  else if (query.view_name === 'analytics')
    return 'Terra Viewer - Analytics';
  else if (query.view_name === 'charts')
    return 'Terra Viewer - Charts';
}

export function routerBeforeEach(to, from) {
  try {
    const viewName = getViewNameBasedOnQuery(to?.query) || to?.meta?.view_name || (to.matched && computeViewName(to.matched));
    if (viewName)
      datadogRum.startView({ name: viewName });
  }
  catch (error) {
    console.error('error', error);
  }

  if (!to?.meta?.noAuthRequires) {
    if (!accessTokenCookie() && to.name !== 'sign-in')
      return { name: 'sign-in' };

    if (!to?.params?.asset_id && from.params?.asset_id && to.matched[0]?.path?.includes('asset_id')) {
      to.params.asset_id = from.params.asset_id;
      return to;
    }
  }
}

export function routerAfterEach(to, from) {
  const common_store = useCommonStore();
  if (getHostName() !== 'localhost' && to?.name !== from?.name) {
    const auth_store = useAuthStore();
    window.analytics.page(to?.meta?.module, to?.name, {
      session_id: sessionStorage.getItem('segment_session_id'),
      organization: auth_store.current_organization?.name,
      organization_id: auth_store.current_organization?.uid,
      version,
      is_online: true,
      access_level: auth_store.get_access_level,
      user_type: auth_store.is_internal_user ? 'Internal' : 'Customer',
      application: 'Desktop',
    });
  }

  if (common_store.reload_on_next_route_change) {
    common_store.reload_on_next_route_change = false;
    location.reload();
  }
}

export function onRouteError(error) {
  if (error.message.includes('import') && error.message.includes('module'))
    handleAppUpdate('onRouteError');
}
