<script>
export default {
  props: {
    custom_classes: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="animate-pulse">
    <div class="h-4 w-2/5 bg-gray-200 rounded-lg" :class="custom_classes" />
  </div>
</template>
