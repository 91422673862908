<script setup>
import { cloneDeep } from 'lodash-es';
import { useRouter } from 'vue-router';
import { useTransmittalWorkflowStore } from '~/dms/store/transmittal-workflow.store';
import { useTransmittalWorkflowConfiguration, useTransmittalWorkflowPayloadConfiguration } from '~/dms/composables/useTransmittalWorkflow.js';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';

const props = defineProps({
  workflow: {
    type: Object,
  },
  delete_workflow: {
    type: Function,
  },
});

const emit = defineEmits(['close', 'delete']);

const router = useRouter();
const { default_statuses, outcome_strategy_list, data, steps } = useTransmittalWorkflowConfiguration(props);
const transmittal_workflow_store = useTransmittalWorkflowStore();
const transmittal_permissions = useTransmittalPermissions();

const form$ = ref(null);

function setCreatedCategory(e) {
  form$.value.elements$.category.load(e.uid);
}

// Save workflow template --------------------------------
const { formatSteps, formatStatusMap } = useTransmittalWorkflowPayloadConfiguration();

async function formSubmitted(data, form) {
  const payload = cloneDeep(form.data);
  payload.workflow.steps = formatSteps(steps.value);
  payload.workflow.status_map = formatStatusMap(payload.workflow?.status_map, default_statuses.value);
  if (props.workflow)
    await transmittal_workflow_store.update_workflow(props.workflow.uid, payload);
  else
    await transmittal_workflow_store.create_workflow(payload);
  emit('close');
}

// End of save workflow template ----------------------------------------------------------------

// Delete workflow --------------------------------
const is_deleting = ref(false);
async function deleteWorkflow(uid) {
  is_deleting.value = true;
  try {
    await props.delete_workflow(uid);
  }
  catch (e) {
    logger.log(e);
  }
  is_deleting.value = false;
}

// ----End of delete workflow --------------------------------

// Publish workflow --------------------------------

const is_publish_toggling = ref(false);

async function toggleWorkflowPublish() {
  is_publish_toggling.value = true;
  try {
    await transmittal_workflow_store.update_workflow(props.workflow.uid, { active: !props.workflow?.active });
  }
  catch (e) {
    logger.log(e);
  }
  is_publish_toggling.value = false;
}

// End of publish workflow --------------------------------

onBeforeUnmount(() => {
  router.replace({ query: null });
});
</script>

<template>
  <div class="pb-5">
    <div>
      <div class="flex items-center justify-between">
        <div class="flex items-center cursor-pointer hover-group -ml-4 pt-4 pb-4" @click="emit('close')">
          <hawk-button type="text">
            <icon-hawk-chevron-left class="text-blue-600 text-xl" />
          </hawk-button>
          <span>{{ $t("Back") }}</span>
        </div>
        <div>
          <HawkButton v-if="workflow && transmittal_permissions.checkTransmittalPermission({ permission: 'workflow_publish' })" text="Published" :loading="is_publish_toggling" @click="toggleWorkflowPublish()">
            <span v-if="workflow.active">{{ $t('Unpublish') }} </span>
            <span v-else> {{ $t('Publish') }} </span>
          </HawkButton>
        </div>
      </div>
      <Vueform
        ref="form$"
        :display-errors="false"
        :remove-classes="{ ElementLabel: { container_horizontal_sm: 'text-type:form-pt-input-border-sm', container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm', container_horizontal_sm_MD: 'md:text-type:form-pt-input-border-sm' } }"
        size="sm"
        :endpoint="formSubmitted"
        @mounted="form$ => form$.load(data, true)"
      >
        <div class="col-span-12 ">
          <div class="max-w-[700px] pr-4">
            <div class="mb-6">
              <div class="text-lg font-semibold">
                {{ $t('Initial details') }}
              </div>
              <div class="text-xs">
                {{ $t('Begin crafting the template by providing basic details') }}
              </div>
            </div>
            <TextElement
              name="name" :label="$t('Template Name')" placeholder="Enter template name"
              :rules="['required']"
            />
            <EditorElement
              class="mt-4"
              name="description" :label="$t('Description')" :placeholder=" $t('Add Description')"
              :rules="['required']"
              :hide-tools="['bold', 'italic', 'strike', 'link', 'heading', 'quote', 'code', 'bullet-list', 'number-list', 'decrease-nesting', 'increase-nesting', 'attach', 'undo', 'redo']"
            />
            <hawk-category-input
              class="mt-4"
              :options="{
                name: 'category',
                label: $t('Category'),
                placeholder: $t('Select Category'),
                create: true,
                from: 'Forms',

              }"
              :setter_function="setCreatedCategory"
            />
          </div>
          <transmittal-workflow-detail-workflow :steps="steps" :outcome_strategy_list="outcome_strategy_list" :default_statuses="default_statuses" @toggle-active="({ index, status }) => default_statuses[index].active = status" />
        </div>
        <div v-if="transmittal_permissions.checkTransmittalPermission({ permission: 'workflow_footer' })" class="col-span-12 border-t pt-2 mt-3">
          <div class="flex justify-between w-full pt-2">
            <div>
              <HawkButton color="error" text="Delete" :loading="is_deleting" :class="{ invisible: !workflow || workflow?.active }" @click="deleteWorkflow(workflow.uid)">
                {{ $t('Delete') }}
              </HawkButton>
            </div>
            <div class="flex">
              <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
