<script>
import { ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'CurrencyElement',
  props: {
    currency_options: {
      type: Object,
      default: {},
    },
    currency_code: {
      type: String,
      default: 'USD',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}, {
  setup(props, { element }) {
    const defaultClasses = ref({
      container: '',
      // currency: '',
      input: 'focus-within:form-ring focus-within:border-primary-500',
      input_danger: '!border-red-300 !ring-4 !ring-red-100',
      $input: (classes, { isDanger }) => ([
        !isDanger ? classes.input : null,
        isDanger ? classes.input_danger : null,
      ]),
    });
    const currency = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = `${value}`;
      },
    });
    return {
      defaultClasses,
      currency,
      props,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <hawk-currency-input
        v-model="currency"
        :currency_code="currency_code"
        :placeholder="currency_options.placeholder"
        :hide_dropdown="currency_options.hide_dropdown"
        :search_placeholder="currency_options.search_placeholder"
        :class="classes.input + (disabled ? ' hawk-currency-disabled pointer-events-none !bg-gray-100' : '')"
        @unitChanged="$emit('unitChanged', $event);"
        @inputChanged="$emit('inputChanged', $event);"
        @focus="test = true"
      />
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>

<style lang="scss">
.custom:focus{
  @apply border-primary-500 form-ring;
}

.hawk-currency-disabled input{
  @apply bg-gray-100;
}
</style>
