<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { computed, nextTick } from 'vue';
import AssetResourcesInput from '~/acct-settings/components/acct-settings-asset-resources/acct-settings-asset-resources-input.vue';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  form_data: {
    type: Object,
    default: () => ({}),
  },
  asset_uid: {
    type: String,
  },
  display_type: {
    type: String,
    default: 'asset-level',
  },
});
const emit = defineEmits(['update', 'formInitialized']);
const $t = inject('$t');
const common_store = useCommonStore();

const resource_access_options = {
  no: $t('No access'),
  limited: $t('Limited access'),
  full: $t('Full access'),
};

const form$ = ref(null);
const form = ref({});

const asset_details = computed(() => common_store.assets_map[props.asset_uid]);

function onFormMounted(el$) {
  el$.update({ ...form.value });
}

function init() {
  const has_access = props.form_data?.has_access;
  const resources = props.form_data?.resources || [];
  const resource_access = props.form_data?.resource_access || (resources?.length ? 'limited' : 'no');
  form.value = { ...props.form_data, has_access, resource_access, resources };

  emit('formInitialized', form.value);
}
init();

async function handleValidation() {
  nextTick(() => form$.value?.validate());
}

watch(() => [form$.value?.data], () => {
  emit('update', { ...props.form_data, ...form.value });
});
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      sync
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <div v-if="display_type === 'asset-level'" class="grid max-w-[700px] grid-cols-12 gap-4 text-sm">
          <div class="col-span-6 text-sm font-medium text-gray-700">
            {{ $t('Maps/Layers') }}
          </div>
          <div class="col-span-6">
            <RadiogroupElement
              name="resource_access"
              class="mb-4"
              :items="resource_access_options"
              :native="false"
            />
            <AssetResourcesInput
              v-if="form.resource_access === 'limited'"
              :asset_uid="asset_uid"
              :resources="props.form_data?.resources || []"
              @update-resources="form.resources = $event"
              @validate="handleValidation"
            />
          </div>
        </div>
        <div v-else class="my-2 grid min-h-9 grid-cols-12 items-center text-sm font-medium text-gray-900">
          <div class="col-span-4 h-full">
            <div class="flex items-center h-9">
              <div class="mt-0.5 w-12 flex-none">
                <ToggleElement name="has_access" :class="{ 'pointer-events-none opacity-70': form_data.is_manager }" />
              </div>
              <div class="flex items-center w-[calc(100%-100px)] break-words" :class="{ 'text-gray-500': !form?.has_access }">
                <div class="break-all">
                  {{ asset_details.name }}
                </div>
                <HawkBadge v-if="form_data.is_manager" color="blue" class="ml-1">
                  {{ $t('Asset admin') }}
                </HawkBadge>
              </div>
            </div>
          </div>
          <div class="col-span-8 h-full">
            <div class="flex items-start gap-x-8 h-full">
              <div class="w-52 flex-none">
                <SelectElement
                  v-if="form.has_access"
                  name="resource_access"
                  :disabled="form_data.is_manager || !form.has_access"
                  :items="resource_access_options"
                  :native="false"
                  placeholder="Select"
                  default="no"
                  :can-clear="false"
                  :can-deselect="false"
                />
              </div>
              <div v-if="form.resource_access === 'limited'" class="w-[450px] flex-none">
                <AssetResourcesInput
                  :disabled="form_data.is_manager || !form.has_access"
                  :asset_uid="asset_uid"
                  :resources="props.form_data?.resources || []"
                  @update-resources="form.resources = $event"
                  @validate="handleValidation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Vueform>
  </div>
</template>
