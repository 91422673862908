<script setup>
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const common_store = useCommonStore();
const auth_store = useAuthStore();
</script>

<template>
  <div v-if="auth_store.logged_in_user_id">
    <HawkNavbar />
    <main class="text-gray-700">
      <router-view />
    </main>
  </div>
  <div v-else class="flex items-center justify-center h-screen flex-col">
    <div class="flex items-center">
      <img src="../../../assets/logos/taskmapper-full.svg" alt="TaskMapper" width="180">
    </div>
    <div class="m-8">
      <HawkLoader container_class="m-0 w-10 h-10" />
    </div>
  </div>
</template>
