<script setup>
import { useIntegrationsStore } from '~/common/stores/integrations.store.js';

const props = defineProps({
  id: {
    type: String,
    default: 'all',
  },
  integrations: {
    type: Array,
    default: () => [],
  },

});
const emit = defineEmits(['close']);
const integrations_store = useIntegrationsStore();
const $services = inject('$services');
const state = reactive({
  is_loading: false,
});

async function deleteIntegration(id = 'all') {
  try {
    state.is_loading = true;
    if (id === 'all') {
      const promises = [];
      props.integrations.forEach((element) => {
        promises.push($services.integrations.patch({
          id: element._id,
          body: {
            active: false,
          },
        }));
      });
      await Promise.all(promises);
    }
    else {
      await $services.integrations.delete({
        id,
      });
    }
    await integrations_store.set_integrations({
      forceUpdate: true,
    });
    state.is_loading = false;
    emit('close');
  }
  catch (error) {
    state.is_loading = false;
  }
}
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="emit('close')">
      <template #title>
        {{ id !== 'all' ? $t('Delete') : $t('Deactivate integration') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <div class="text-sm">
        {{ id !== 'all' ? $t('You are about to delete the integration. Are you sure?') : $t('You are about to turn off the integration. Are you sure?') }}
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-4">
          <HawkButton type="outlined" @click="emit('close')">
            {{ $t('Close') }}
          </HawkButton>
          <HawkButton :loading="state.is_loading" @click="deleteIntegration(id)">
            {{ $t('Confirm') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
