<script setup>
import { reactive } from 'vue';
import { Validator } from '@vueform/vueform';

const props = defineProps({
  group: {
    type: Object,
  },
  on_save: {
    type: Function,
  },
});

const state = reactive({
  form: props.group,
});

function getCustomRule(type) {
  return class extends Validator {
    get message() {
      return `The ${type === 'affected_capacity' ? 'Affected Capacity' : 'Total DC Capacity'} cannot be equal to ${state.form[type]}`;
    }

    check(value) {
      return !Number(props.group[type]) || Boolean(Number(value));
    }
  };
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="state.form"
      :columns="{
        lg: { container: 12, label: 4, wrapper: 12 },
      }"
      sync size="sm" :display-errors="false" :endpoint="on_save"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Edit') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div class="col-span-12 text-gray-700 text-[14px] font-medium mb-6">
            <div class="grid grid-cols-12">
              <div class="col-span-4">
                {{ $t('Map name') }}
              </div>
              <div class="col-span-8">
                <HawkText :content="group.name" :length="42" />
              </div>
            </div>
          </div>
          <TextElement
            name="affected_capacity"
            :label="$t('Affected Capacity')"
            :rules="[
              'numeric',
              getCustomRule('affected_capacity'),
            ]"
            :default="0.0"
            input-type="number"
            class="mb-6"
            autocomplete="off"
          >
            <template #addon-after>
              {{ $t('KW') }}
            </template>
          </TextElement>
          <TextElement
            name="total_dc_capacity"
            :label="$t('Total DC Capacity')"
            :rules="[
              'numeric',
              getCustomRule('total_dc_capacity'),
            ]"
            :default="0.0"
            input-type="number"
            class="mb-6"
            autocomplete="off"
          >
            <template #addon-after>
              {{ $t('MW') }}
            </template>
          </TextElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
