<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useAuthStore } from '../stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { setCookie } from '~/common/utils/common.utils';

const form = ref({});
const otp = ref([]);
const $services = inject('$services');
const $t = inject('$t');
const auth_store = useAuthStore();
const router = useRouter();
const $toast = inject('$toast');
const common_store = useCommonStore();
const verification_in_process = ref(false);

onMounted(() => {
  const inputs = document.querySelectorAll('.otp__input');
  const inputArr = [...inputs];

  inputs.forEach((input) => {
    input.addEventListener('input', handleInput);
  });

  inputs.forEach((input) => {
    input.addEventListener('paste', handlePaste);
  });

  inputs.forEach((input) => {
    input.addEventListener('keyup', handleBackspace);
  });

  inputs.forEach((input) => {
    input.addEventListener('keydown', handleArrows);
  });

  inputs.forEach((input) => {
    input.addEventListener('focus', selectIfNotEmpty);
  });

  function handlePaste(e) {
    e.preventDefault();
    const data = e.clipboardData.getData('text');
    otp.value = data.trim().split('');
    inputs.forEach((input, i) => {
      input.value = data[i];
    });

    if (inputArr.every(input => input.value))
      verifyPasscode();
  }
});

const is_otp_filled = computed(() => {
  return otp.value.filter(item => item).length === 4;
});

function handleBackspace(e) {
  if (e.keyCode === 8 && e.target.value === '' && e.target.previousElementSibling)
    e.target.previousElementSibling.focus();
}

function handleInput(e) {
  if (e.target.value && e.target.nextElementSibling)
    e.target.nextElementSibling.focus();
}

function handleArrows(e) {
  if (e.keyCode === 37 && e.target.previousElementSibling) {
    e.preventDefault();
    e.target.previousElementSibling.focus();
  }

  if (e.keyCode === 39 && e.target.nextElementSibling) {
    e.preventDefault();
    e.target.nextElementSibling.focus();
  }
}

function selectIfNotEmpty(e) {
  if (e.target.value)
    e.target.select();
}

async function verifyPasscode() {
  if (!is_otp_filled.value)
    return;
  try {
    verification_in_process.value = true;
    const { uid } = auth_store.sign_up_details;
    await $services.auth.confirmEmail({ otp: otp.value?.join(''), uid });
    $toast({ type: 'success', title: 'Verification complete', text: 'Your email is now successfully verified.' });
    const { signed_in, response } = await auth_store.sign_in(auth_store.sign_up_details.formData);
    setCookie('signed_in_at_least_once', true);

    if (signed_in) {
      await common_store.initialize({ forceUpdate: true });
      router.push({ path: '/' });
    }

    else if (response.errorCode === 'E0000004') {
      logger.log('Incorrect email address or password. Error: ', 'error');
      $toast({
        title: 'Incorrect email address or password',
        type: 'error',
      });
    }
  }
  catch (error) {
    logger.log('error while verifying passcode', error);
    $toast({
      title: $t('Incorrect verification code.'),
      type: 'error',
    });
  }
  finally {
    verification_in_process.value = false;
  }
}

async function resendCode() {
  try {
    const { uid, formData: { email } } = auth_store.sign_up_details;
    await $services.auth.resendCode({
      id: uid,
      body: { email },
    });
    $toast({
      title: $t('Verification code sent, check your email'),
      type: 'success',
    });
  }
  catch (error) {
    logger.log('error while resending OTP', error);
    $toast({
      title: $t('Error while resending verification code'),
      type: 'error',
    });
  }
}

function isNumber(event) {
  if (!['Tab', 'Backspace'].includes(event.key) && (!/\d/.test(event.key) || event.key === '.'))
    event.preventDefault();
}
</script>

<template>
  <div class="min-h-screen flex justify-center">
    <div class="w-[360px]">
      <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24 w-[360px]">
        <div class="flex justify-center mb-4">
          <IconHawkMailOne width="66" height="66" />
        </div>
        <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
          {{ $t('Verify your email') }}
        </p>
        <span class="text-gray-600">
          {{ $t('Please enter the 4-digit code sent to your email.') }}
        </span>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class=" px-4 sm:rounded-lg sm:px-10">
          <Vueform
            v-model="form" :display-errors="false"
            size="sm"
            :columns="{
              default: { container: 12, label: 0, wrapper: 12 },
              sm: { container: 12, label: 0, wrapper: 12 },
            }"
          >
            <div class="col-span-12 w-full">
              <div class="grid grid-cols-4 gap-4 auto-cols-max mb-5">
                <input
                  v-for="(item, i) in 4"
                  :id="`otp-${i}`"
                  :key="i"
                  v-model.number.trim="otp[i]"
                  maxlength="1"
                  class="otp__input border border-gray-300 shadow-xs rounded-lg text-center !text-gray-900 w-[64px] h-[64px] text-2xl"
                  @keydown="isNumber($event)"
                  @input="verifyPasscode"
                >
              </div>
            </div>
          </Vueform>
        </div>
        <HawkButton :disabled="!is_otp_filled" :loading="verification_in_process" color="primary" size="md" :block="true" @click="verifyPasscode">
          {{ $t('Continue') }}
        </HawkButton>
        <div class="py-8 flex justify-center text-gray-600 text-sm">
          {{ $t('Didnt receive an email?') }}
          <div class="mx-2 text-blue-600 hover:text-blue-500 font-semibold cursor-pointer" @click="resendCode">
            {{ $t('Resend') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
