import { PLANS_ROUTES_CONFIG } from '../constants';

const routes = [
  {
    path: PLANS_ROUTES_CONFIG.PLANS.path,
    name: PLANS_ROUTES_CONFIG.PLANS.name,
    component: () => import('~/plans/pages/plans.vue'),
    meta: {
      title: 'Plans',
    },
    children: [
      {
        path: PLANS_ROUTES_CONFIG.SHEET.path,
        name: PLANS_ROUTES_CONFIG.SHEET.name,
        component: () => import('~/plans/pages/sheet.vue'),
        meta: {
          title: 'Sheet',
        },
      },
    ],
  },
];
export default routes;
