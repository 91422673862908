<script setup>
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useTasksStore } from '~/tasks/store/tasks.store';
import { useThermStore } from '~/therm/store/therm.store.js';
import DefectDetails from '~/therm/components/defect-details/defect-details.vue';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import TaskForm from '~/tasks/components/molecule/task-form/therm-task-form.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  defect_index: {
    type: Number,
    default: -1,
  },
  filtered_features: {
    type: Array,
    default: () => [],
  },
  show_prev_next_button: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['nextDefect', 'prevDefect', 'close']);

const { addSymbols } = useThermHelperComposable();

const $t = inject('$t');
const $services = inject('$services');

const route = useRoute();
const router = useRouter();

const tasks_store = useTasksStore('therm_tasks_store');
const therm_store = useThermStore();

const state = reactive({
  feature_data: null,
  fetching_data: false,
  task_details: null,
  show_loader: false,
  open_task_popup: false,
});

const issue_details = computed(() => ({
  ...(props.data || {}),
  inverter: therm_store.projects_details_map[props.data?.projectUid],
}));
const get_dropdown_options = computed(() => ([
  ...((!therm_store.use_tasks || !state.task_details)
    ? [{
        label: $t('Create task'),
        on_click: () => {
          createTask();
        },
      }]
    : []),
  // {
  //   label: $t('Duplicate'),
  //   on_click: () => {
  //     logger.log('Handle duplicate');
  //   },
  // },
  // {
  //   label: $t('Delete'),
  //   on_click: () => {
  //     logger.log('Handle delete');
  //   },
  // },
]));
async function createTask() {
  try {
    if (therm_store.use_tasks) {
      state.show_loader = true;

      const payload = { issues: therm_store.selected_features };
      await therm_store.create_therm_tasks(payload);
      state.show_loader = false;
    }
    else {
      state.open_task_popup = true;
    }
  }
  catch (err) {
    logger.error(
      '🚀 ~ file: therm-defect-detail.vue ~ line 158 ~ createTask ~ err',
      err,
    );
    state.show_loader = false;
  }
}
async function updateIssue(payload, show_loader = true) {
  state.show_loader = show_loader;
  const before_update_data = { ...state.feature_data };
  state.feature_data = {
    ...state.feature_data,
    ...payload,
  };
  const res = await therm_store.updateIssue({ uid: props.data?.uid, ...payload });
  if (res.error)
    state.feature_data = { ...before_update_data };
  if (payload.status)
    addSymbols();
  state.show_loader = false;
}

function closeTaskPopup() {
  state.open_task_popup = false;
}

async function getFeatureDetails() {
  state.fetching_data = true;
  try {
    const response = await $services.therm.get({
      attribute: `features/view/${therm_store.container.uid}/project/${props.data.projectUid || props.data.project}/feature/${props.data.uid}`,
      query: { tables: false, parse_raw_images: true },
    });
    const data = response.data?.features?.[0]?.properties;
    state.feature_data = {
      ...issue_details.value,
      ...data,
      featureTypeId: data.class_id,
    };
    therm_store.updateIssue(data, { skip_API: true });
    therm_store.update_map_features();
  }
  catch (error) {
    state.feature_data = issue_details.value;
    logger.error(error);
  }
  state.fetching_data = false;
}

watch(() => [tasks_store.tasks(), props.data], () => {
  state.task_details = therm_store.tasks_hash[props.data?.uid];
});

watch(() => ({ index: props.defect_index, data: issue_details.value }), async (val, old_val) => {
  if (val.index !== old_val?.index)
    await getFeatureDetails();
  else
    state.feature_data = {
      ...state.feature_data,
      ...val.data,
    };
}, { immediate: true });

onMounted(async () => {
  state.task_details = therm_store.tasks_hash[props.data?.uid];
});
</script>

<template>
  <div :key="defect_index">
    <TaskForm v-if="state.open_task_popup" @refreshSymbols="closeTaskPopup" @toggleCreation="closeTaskPopup" @close="closeTaskPopup" />
    <div class="flex justify-between items-center m-6">
      <p class="text-lg text-gray-900 font-semibold">
        {{ $t("Defect details") }}
      </p>
      <div class="flex items-center">
        <HawkMenu v-if="get_dropdown_options.length" class="mx-2" :items="get_dropdown_options" additional_trigger_classes="!ring-0 !border-0 w-full" />
        <IconHawkXClose class="cursor-pointer" @click="emit('close')" />
      </div>
    </div>

    <div class="h-[calc(100vh-260px)] w-full overflow-y-auto scrollbar overflow-x-hidden" :class="options?.container_class || ''">
      <HawkLoader v-if="state.fetching_data" />
      <template v-else-if="!state.fetching_data">
        <DefectDetails
          :key="defect_index"
          :data="state.feature_data"
          :feature_types="therm_store.feature_types"
          :update-issue="updateIssue"
          :use_tasks="therm_store.use_tasks"
          @update="updateIssue"
        />
      </template>
    </div>
    <div
      v-if="show_prev_next_button"
      class="h-11 bg-gray-100 flex justify-between items-center px-8 py-3"
    >
      <div
        :class="{ 'pointer-events-none opacity-0 cursor invisible': defect_index === 0 }"
        class="cursor-pointer flex ml-1 items-center gap-1"
        @click="emit('prevDefect', defect_index)"
      >
        <IconHawkChevronLeft />
        <div class="font-medium text-sm text-black">
          {{ $t("Previous") }}
        </div>
      </div>
      <div
        :class="{ 'pointer-events-none opacity-0 cursor invisible': defect_index === filtered_features.length - 1 }"
        class="cursor-pointer mr-2 flex items-center gap-1"
        @click="emit('nextDefect', defect_index)"
      >
        <div class="font-medium text-sm text-black">
          {{ $t("Next") }}
        </div>
        <IconHawkChevronRight />
      </div>
    </div>
  </div>
</template>
