<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const inventory_store = useInventoryStore();
const { $t, route, router } = useCommonImports();
const form$ = ref(null);
</script>

<template>
  <div>
    <HawkPageHeader>
      <template #title>
        <div class="flex items-center gap-3">
          <HawkButton type="text" icon @click="router.push({ name: 'inventory-reports', params: { ...route.params } })">
            <IconHawkChevronLeft />
          </HawkButton>
          <span>{{ $t('New Report') }}</span>
        </div>
      </template>
      <template #right>
        <HawkButton>
          {{ $t('Publish') }}
        </HawkButton>
      </template>
    </HawkPageHeader>
    <div class="grid grid-cols-12 gap-4 px-4">
      <div class="col-span-3 h-[calc(100vh-144px)] scrollbar">
        <Vueform
          ref="form$"
          size="sm"
          :display-errors="false"
          :columns="{ sm: { container: 12, label: 12, wrapper: 12 } }"
        >
          <div class="col-span-12 grid gap-4">
            <TextElement
              :label="$t('Name')"
              name="name"
              :placeholder="$t('Enter name')"
              rules="required"
              :attrs="{ autofocus: true }"
            />
            <SelectElement
              :label="$t('Type')"
              name="type"
              :native="false"
              :items="[
                'Material Tracking',
                'Material Tracking 1',
                'Material Tracking 2',
                'Material Tracking 3',
              ]"
            />
            <TagsElement
              :label="$t('Warehouses')"
              name="warehouses"
              label-prop="name"
              value-prop="uid"
              :close-on-select="false"
              :items="inventory_store.warehouses"
              :description="$t('Choose the locations if you want to filter the stock/transactions from particular locations.')"
            />
            <hr class="col-span-12">
            <div class="col-span-12">
              <ListElement
                name="list"
                label="Fields"
                :presets="['repeatable_list', 'repeatable_drag_icon']"
                :sort="true"
                add-text="Add field"
                :add-classes="{
                  ElementLayout: { innerContainer: 'ml-5' },
                  ListElement: {
                    remove: ['h-5 w-5 ml-auto', 'right-0', 'bottom-2', 'absolute', '!z-1'],
                  },
                }"
              >
                <template #default="{ index }">
                  <TextElement
                    :name="index"
                    :placeholder="$t('Add field')"
                    class="mr-8"
                  />
                </template>
              </ListElement>
            </div>
            <hr class="col-span-12">
            <ToggleElement
              name="track_stock_quantity"
              :columns="{
                sm: {
                  container: 12,
                  label: 9,
                  wrapper: 12,
                },
              }"
              :add-classes="{
                ElementLayout: {
                  innerContainer: 'justify-items-end',
                },
              }"
            >
              <template #label>
                <div>
                  <div>{{ $t('Track stock quantities') }}</div>
                  <div class="text-xs text-gray-500 font-normal">
                    {{ $t('Track day, week or month wise procured quantities for the selected transaction type') }}
                  </div>
                </div>
              </template>
            </ToggleElement>
            <hr class="col-span-12">
            <ToggleElement
              name="bom"
              :columns="{
                sm: {
                  container: 12,
                  label: 9,
                  wrapper: 12,
                },
              }"
              :add-classes="{
                ElementLayout: {
                  innerContainer: 'justify-items-end',
                },
              }"
            >
              <template #label>
                <div>
                  <div>{{ $t('Bill Of Material (BOM)') }}</div>
                  <div class="text-xs text-gray-500 font-normal">
                    {{ $t('Track block-level material requirements for the selected warehouses') }}
                  </div>
                </div>
              </template>
            </ToggleElement>
          </div>
        </Vueform>
      </div>
      <div class="col-span-9 h-[calc(100vh-144px)] scrollbar">
        --- Table ---
      </div>
    </div>
  </div>
</template>
