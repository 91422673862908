<script>
import { load_js_css_file } from '~/common/utils/load-script.util.js';

const layout = {
  yaxis: { fixedrange: true },
  xaxis: { fixedrange: true },

  height: 300,
  title: 'Line Elevation',
};
const chart_modalbar_options = {
  modeBarButtonsToRemove: [
    'zoom2d',
    'pan2d',
    'zoomIn2d',
    'zoomOut2d',
    'toImage',
    'select2d',
    'lasso2d',
    'autoScale2d',
    'resetScale2d',
    'hoverClosestCartesian',
    'hoverCompareCartesian',
    'zoom3d',
    'pan3d',
    'resetCameraDefault3d',
    'resetCameraLastSave3d',
    'hoverClosest3d',
    'orbitRotation',
    'tableRotation',
    'zoomInGeo',
    'zoomOutGeo',
    'resetGeo',
    'hoverClosestGeo',
    'sendDataToCloud',
    'hoverClosestGl2d',
    'hoverClosestPie',
    'toggleHover',
    'resetViews',
    'resetViewMapbox',
    'toggleSpikelines',
  ],
  responsive: true,
  displaylogo: false,
};
export default {
  props: ['line_elevation', 'draw'],
  watch: {
    line_elevation() {
      if (!this.line_elevation)
        return;

      const trace = {
        y: [],
        mode: 'lines',
        fill: 'tozeroy',
      };
      trace.y = this.line_elevation.map((e) => {
        return e[2];
      });

      layout.yaxis.range = [Math.min(...trace.y), Math.max(...trace.y)];

      Plotly.newPlot('lineGraph', [trace], layout, chart_modalbar_options);
      const myPlot = document.getElementById('lineGraph');
      myPlot.on('plotly_hover', (data) => {
        const feature = {
          id: 'elevation-point',
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              this.line_elevation[data.points[0].pointIndex][0],
              this.line_elevation[data.points[0].pointIndex][1],
            ],
          },
          properties: { elevation: 1 },
        };
        this.draw.add(feature);
      });
    },
  },
  async created() {
    await load_js_css_file(
      'https://cdnjs.cloudflare.com/ajax/libs/plotly.js/1.51.1/plotly.min.js',
      'plotly-js',
      'js',
    );
  },
  unmounted() {
    this.close();
  },
  methods: {
    close() {
      this.draw.delete('elevation-point');
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div
    class="line-elevation"
    style="position:fixed;left:0;right:0;bottom:0;z-index:1000"
  >
    <div
      class="button is-small is-borderless"
      style="position:absolute;top:1rem;right:1rem;border:none;z-index:100"
      @click="close()"
    >
      <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
    </div>
    <div id="lineGraph" class="w-[100vw]" />
  </div>
</template>
