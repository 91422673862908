<script setup>
import { RRule } from 'rrule';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import SearchBar from '~/common/components/molecules/hawk-search-input.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import SecondaryPageHeader from '~/common/components/organisms/hawk-page-secondary-header.vue';

import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useCommonStore } from '~/common/stores/common.store';

import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import TaskName from '~/tasks/components/molecule/task-name.vue';
import { useTasksPermissions } from '~/tasks/composables/task-composables.js';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  store_key: {
    type: String,
    default: 'templates',
  },
});
const { copy } = useClipboard();
const $t = inject('$t');
const $toast = inject('$toast');

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const common_store = useCommonStore();
const template_store = useTasksStore(props.store_key);

const { checkTaskPermission } = useTasksPermissions();

const current_organization = authStore.current_organization;

const state = reactive({
  is_loading: false,
  page_size: 25,
  freeze_table: '-1',
});

const columns = ref(
  [
    {
      accessorKey: 'name',
      header: $t('Template Name'),
      id: 'name',
      size: '40',
    },
    {
      accessorKey: 'category',
      header: $t('Category'),
      id: 'category',
      render_as: {
        field_type: 'category',
      },
    },

    {
      accessorFn: row => row.target_element?.asset,
      header: $t('Asset'),
      id: 'asset',
      render_as: {
        field_type: 'asset',
        options: {
          truncate_config: {
            type: 'adaptive',
          },
        },
      },
    },
    {
      accessorFn: row => getRecurrenceText(row),
      header: $t('Schedule'),
      id: 'schedule',
      render_as: {
        field_type: 'text',
        options: {
          truncate_config: {
            type: 'adaptive',
          },
        },
      },
    },
    {
      accessorKey: 'context_menu',
      header: '',
      id: 'context_menu',
      size: '5',
      show_on_hover: 'true',
    },
  ],
);

onMounted(async () => {
  await getTemplates({});
});

async function getTemplates({ sort_by, pagination_state }) {
  state.is_loading = true;
  state.page_size = pagination_state?.pageSize || 25;
  const query = {
    page_number: 1,
    page_size: state.page_size,
    is_template: true,
    ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
  };
  if (pagination_state?.pageIndex && pagination_state?.pageSize) {
    query.page_number = pagination_state.pageIndex;
    query.page_size = pagination_state.pageSize;
  }
  if (sort_by)
    query.sort = sort_by.desc ? `-${sort_by.id}` : sort_by.id;
  await template_store.set_tasks(query);
  state.is_loading = false;
}

function getTargetElement() {
  if (route.params.asset_id)
    return common_store.active_asset.element;
  return current_organization.element;
}

/* --------- HAWK TABLE HELPERS --------- */
function viewTemplateDetailsHandler(id) {
  const template = btoa(JSON.stringify({
    id,
    store_key: props.store_key,
  }));
  router.push({
    ...route,
    query: {
      template,
    },
  });
}

/* --------- CREATE TEMPLATE --------- */
const { open: openTaskForm, close: closeTaskForm } = useModal({
  component: TaskForm,
  attrs: {
    task_type: 'template',
    allow_multi_task_create: true,
    onClose() {
      closeTaskForm();
    },
    async on_submit(data) {
      if (data.has_multiple_tasks) {
        const target_element = getTargetElement();
        const payload = data.payload.map((task_obj) => {
          return { ...task_obj, target_element, is_template: true };
        });
        await template_store.create_tasks({ tasks: payload }, { type: 'Template' }, 'Template created');
      }
      const payload = data;
      payload.target_element = getTargetElement();
      payload.is_template = true;
      await template_store.create_tasks({ tasks: [payload] }, { type: 'Template' }, 'Template created');
    },
  },
});

/* --------- DELETE TEMPLATE --------- */
const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

function templateDeleteHandler({ name, uid }) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Template'),
        content: `Are you sure you want to delete ${name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await template_store.remove_tasks([uid]);
            closeDeletePopup();
          }
          catch {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

/* --------- OTHER METHODS --------- */
function copyTemplateURLHandler(task_uid) {
  const encoded_uid = btoa(JSON.stringify({
    id: task_uid,
    store_key: props.store_key,
  }));
  const task_path = `${window.location.origin}/tasks/templates/tasks?template=${encoded_uid}&organization=${current_organization.uid}`;

  copy(task_path);
  $toast({
    title: 'Url copied successfully',
    type: 'success',
    position: 'bottom-right',
  });
}
async function searchTasks(value) {
  state.is_loading = true;
  template_store.set_search_key(value);
  await template_store.set_tasks({ is_template: true, page_number: 1, page_size: 25, ...(route.params.asset_id ? { asset_uid: route.params.asset_id } : {}) });
  state.is_loading = false;
}
function getRecurrenceText(task) {
  if (task.rrule)
    return RRule.fromString(task.rrule).toText();
  return '-';
}
</script>

<template>
  <div>
    <SecondaryPageHeader class="py-4 mb-4">
      <template #left>
        <SearchBar
          :model-value="template_store.search_key"
          :debounce_time="700"
          @update:model-value="searchTasks"
        />
      </template>
      <template #right>
        <HawkButton
          v-if="authStore.check_permission('modify_task_templates', route.params.asset_id)"
          color="primary"
          @click="() => openTaskForm()"
        >
          <IconHawkPlusWhite class="text-white h-3" />
          {{ $t('New template') }}
        </HawkButton>
      </template>
    </SecondaryPageHeader>
    <TableWrapperVue v-if="template_store.tasks().length || state.is_loading" container_class="border-0">
      <HawkTable
        :pagination_config="{ totalRows: template_store.total_task_count, pageSize: 25 }"
        :data="template_store.tasks()"
        :is_loading="state.is_loading"
        :columns="columns"
        :non_sortable_columns="['context_menu']"
        :manual_pagination="true"
        :manual_sorting="true"
        :freeze_table="state.freeze_table"
        is_gapless
        @pagination="getTemplates($event)"
        @sort="getTemplates($event)"
        @row-clicked="viewTemplateDetailsHandler($event.uid)"
      >
        <template #name="task_name">
          <TaskName
            :task="task_name.data.row.original"
            :asset_uid="task_name.data.row.original?.target_element?.asset"
            :show_asset="false"
            @on-name-clicked="viewTemplateDetailsHandler(task_name.data.row.original?.uid)"
          />
        </template>
        <template #context_menu="task">
          <hawk-menu
            position="fixed"
            additional_trigger_classes="!ring-0 hover:bg-gray-50"
            :items="[
              {
                label: 'Copy URL',
                on_click: () => copyTemplateURLHandler(task.data.row.original?.uid),
              },
              {
                label: 'Delete',
                on_click: () => templateDeleteHandler(task.data.row.original),
                disabled: !checkTaskPermission({ permission: 'can_modify_templates' }),
              }]"
            @open="state.freeze_table = task?.data?.row?.id"
            @close="state.freeze_table = '-1'"
            @click.stop=""
          />
        </template>
      </HawkTable>
    </TableWrapperVue>
    <div v-else>
      <hawk-illustrations type="no-data" for="tasks" />
    </div>
  </div>
</template>
