<script setup>
import { snakeCase } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useFormsStore } from '~/forms/store/forms.store.js';
import IconFilter from '~icons/hawk/filter-icon';
import { searchData } from '~/common/utils/common.utils';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { useStatusMetrics } from '~/forms/composables/form-list-configuration';
import FormStatusGroup from '~/forms/molecules/form-status-group.vue';
import FiltersPopupForms from '~/forms/components/form-filter.vue';
import { useFormFilterStore } from '~/forms/store/form-filter-store';
import FormOverDueCount from '~/forms/atoms/form-overdue-count.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { FORM_PERMISSIONS } from '~/forms/constants';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
});

const $t = inject('$t');

const auth_store = useAuthStore();
const search = ref('');
const router = useRouter();
const route = useRoute();
const forms_store = useFormsStore('form');
const filter_store = useFormFilterStore('form');
const common_store = useCommonStore();

const query = { all_access: true, status: 'published', metrics: true, group_by: 'template' };
const columns = ref([{
  header: $t('ID'),
  accessorKey: 'number',
  id: 'number',
  enableResizing: false,
  size: 100,
}, {
  header: $t('Form name'),
  accessorKey: 'total_overdue',
  id: 'total_overdue',
  enableResizing: false,
  size: 200,
},
{
  header: $t('Category'),
  accessorKey: 'category',
  id: 'category',
  enableResizing: false,
},
{
  header: $t('Asset'),
  accessorKey: 'target_element',
  id: 'asset',
  enableResizing: false,
  sortingFn: (rowA, rowB, columnId) => {
    const asset_name_A = getAssetName(rowA.original.target_element);
    const asset_name_B = getAssetName(rowB.original.target_element);

    return asset_name_A.localeCompare(asset_name_B);
  },
},
{
  header: $t('Status'),
  accessorKey: 'metrics',
  id: 'metrics',
  size: 300,
  enableResizing: false,
},
]);

const loading = ref(false);
const table_instance = ref(null);
function getOverdueMetrics(form) {
  const metric_data = form?.metrics || {};
  const overdue = Object.keys(metric_data).reduce((sum, key) => sum + (metric_data[key]?.due_metrics?.today || 0) + (metric_data[key]?.due_metrics?.overdue || 0), 0);
  return overdue;
}

function getStatusMetrics(metric) {
  return useStatusMetrics(metric);
}

function getAssetName(target_element) {
  const asset_id = target_element?.asset || '';

  return asset_id && common_store.assets_map?.[asset_id]?.name;
};

async function getData(data) {
  loading.value = data;
  await forms_store.set_forms({ query });
  loading.value = false;
}

onMounted(async () => {
  await getData(!forms_store?.templates?.length);
});
watch(() => props.asset_id, () => {
  getData(true);
});
function get_sorted_data(forms) {
  return forms?.map(form => ({ ...form, total_overdue: getOverdueMetrics(form) })).sort((form1, form2) => form2.total_overdue - form1.total_overdue) || [];
}

const filtered_data = computed(() => searchData(get_sorted_data(forms_store?.templates), search.value));

const has_data = computed(() => !!filtered_data.value.length);
const has_filter_applied = computed(() => filter_store.filters_count > 0 || search.value);
const is_boarding = computed(() => !has_data.value && !has_filter_applied.value);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);
function routeToFormInstancesListPage(row, query = {}) {
  if (query.open) {
    const metric_data = row.metrics[snakeCase(query.open)];
    query.open = metric_data.block_uid || metric_data.step_index || query.open;
    query.status_type = 'submission_status';
    metric_data.block_uid && (query.status_type = 'block');
    metric_data.step_index && (query.status_type = 'step_index');
  }

  router.push({ name: 'form-instances', params: { template_uid: row.uid, asset_id: route.params.asset_id }, query });
}
// when search value is present reset the pagination
watch(() => search?.value?.length, () => {
  table_instance.value.resetPagination();
});

// ----------------- Filters popup -----------------------------------

const filters_loading = ref();

const { open: openFiltersPopup, close: closeFiltersPopup } = useModal({
  component: FiltersPopupForms,
  attrs: {
    query,
    fields: ['Status', 'Category', 'Tags', 'Assignees', 'Start Date', 'Due Date', 'Created At', 'Created By', 'Rolled Back'],
    forms_store,
    onClose() {
      closeFiltersPopup();
    },
  },
});

async function clearFilters() {
  loading.value = true;
  filter_store.set_filters({ reset: true });
  await forms_store.set_forms({ query, page_number: 1, page_size: 25 });
  loading.value = false;
}

function navigateToFormsInstancesListPage({ template }) {
  router.push({ name: 'form-instances', params: { template_uid: template, asset_id: route.params.asset_id } });
}
</script>

<template>
  <div>
    <div>
      <hawk-sticky-header>
        <div class="flex justify-between">
          <div class="flex">
            <hawk-search-input v-model="search" :placeholder="$t('Search')" class="my-[1px]" />
            <div class="flex items-center ml-4">
              <hawk-button
                class="mr-4"
                type="outlined"
                @click="openFiltersPopup"
              >
                <IconFilter class="text-gray-600 w-[15px] h-2.5" />
                <span class="text-sm text-[#667085]">{{ $t('filters') }}</span>
              </hawk-button>
              <p
                v-if="filter_store.filters_count"
                class="flex items-center rounded-md p-2 text-sm font-semibold text-primary-700 bg-primary-50"
              >
                {{ `${filter_store.filters_count} ${$t('Applied')}` }}
                <Icon-hawk-x-close class="cursor-pointer ml-2 " @click="clearFilters" />
              </p>
            </div>
          </div>
          <new-form-button @form-created="navigateToFormsInstancesListPage" />
        </div>
      </hawk-sticky-header>
      <div v-if="loading">
        <hawk-loader />
      </div>
      <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms" />
      <HawkIllustrations v-else-if="is_boarding" type="on-boarding" for="forms" :is_create_indicator="auth_store.check_permission(FORM_PERMISSIONS.V2_CREATE_FORMS, route.params.asset_id)" />
      <HawkTable
        v-else
        :is_loading="loading" :pagination_config="{ totalRows: filtered_data.length, pageSize: 25 }"
        :data="filtered_data" :columns="columns" :is_gapless="true" :show_menu_header="false"
        stick_header_offset="132"
        :disable_resize="true"
        scroll_to_top_on_pagination
        @row-clicked=" $event => routeToFormInstancesListPage($event)"
        @tableInstance="table_instance = $event"
      >
        <template #total_overdue="form">
          <hawk-text :length="40" class="text-gray-900 font-medium" :content="form.data.row.original.name" />
          <FormOverDueCount
            class="ml-1"
            :overdue="form.data.row.original.due_metrics" :count="form.data.row.original.total_overdue"
            @click.native="routeToFormInstancesListPage(form.data.row.original, { group_by: 'due_date' })"
          />
        </template>
        <template #category="category">
          <HawkCategoryName v-if="category.data.getValue()" :truncate_length="20" :uid="category.data.getValue()" />
          <span v-else>-</span>
        </template>
        <template #metrics="metric">
          <div class="flex flex-wrap">
            <FormStatusGroup
              v-if="getStatusMetrics(metric).length" :data="getStatusMetrics(metric)"
              @click.native.prevent.stop
              @clicked="$event => routeToFormInstancesListPage(metric.data.row.original, { group_by: 'status', open: $event.name })"
            />
          </div>
        </template>
        <template #asset="asset">
          <div class="flex flex-wrap">
            <HawkText v-if="getAssetName(asset.data.getValue())" :length="30" class="text-gray-600" :content="getAssetName(asset.data.getValue())" />
            <span v-else>-</span>
          </div>
        </template>
      </HawkTable>
    </div>
  </div>
</template>
