<script setup>
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryUom from '~/inventory/components/inventory-uom/inventory-uom.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const { $t, route, router } = useCommonImports();
const inventory_store = useInventoryStore();
const columns = ref([
  {
    accessorKey: 'number',
    header: $t('Transaction'),
    id: 'number',
    font_medium: true,
  },
  {
    accessorKey: 'date',
    header: $t('Date'),
    id: 'date',
  },
  {
    accessorKey: 'total_quantity',
    header: $t('Quantity'),
    id: 'total_quantity',
  },

  {
    accessorKey: 'from_stock',
    header: $t('From'),
    id: 'from_stock',
  },
  {
    accessorKey: 'to_stock',
    header: $t('To'),
    id: 'to_stock',
  },
]);
const state = reactive({
  pagination_config: {
    totalRows: 0,
    pageSize: 25,
    pageIndex: 1,
  },
  adjustments: [],
  is_loading: false,
});

async function get_history_status({ pagination_state }) {
  state.pagination_config = { ...state.pagination_config, ...pagination_state };
  try {
    state.is_loading = true;
    const data = await inventory_store.$services.inventory_adjustments.getAll({
      query: {
        'status': 'published',
        'item': inventory_store?.active_item_uid,
        'include[]': 'adjustment_lines.*',
        'asset': route.params.asset_id,
        'page': pagination_state.pageIndex,
        'limit': pagination_state.pageSize,
        ...(route?.params?.warehouse_id ? { warehouse: route?.params?.warehouse_id } : {}),
      },
    });
    state.pagination_config.totalRows = data.headers['x-total-count'];
    state.adjustments = data?.data?.adjustments;
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('🚀 ~ get_history_status ~ error:', error);
  }
}

watch(() => inventory_store?.active_item_uid, async () => {
  state.pagination_config = {
    totalRows: 10,
    pageSize: 25,
    pageIndex: 1,
  };
  get_history_status({ pagination_state: state.pagination_config });
}, { immediate: true });
</script>

<template>
  <div>
    <div class="font-semibold text-sm">
      {{ $t('Transactions') }}
    </div>
    <div v-if="state.is_loading" class="text-sm text-gray-500 mt-3">
      {{ $t('Loading') }}...
    </div>
    <div v-else-if="!state.adjustments?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No transactions found') }}
    </div>
    <div v-else-if="state.adjustments?.length">
      <TableWrapperVue :height="500" container_class="border mt-2">
        <HawkTable
          :pagination_config="{ ...state.pagination_config }"
          :data="state.adjustments"
          :columns="columns"
          :manual_pagination="true"
          is_gapless
          cell_height="40px"
          :show_menu_header="false"
          disable_resize
          @pagination="get_history_status($event)"
          @row-clicked="e => router.push({ name: 'inventory-transactions', query: { transaction_number: e.number }, params: { ...route.params } })"
        >
          <template #number="{ data }">
            <div class="flex items-center gap-2">
              <span>{{ data.getValue() }}</span>
              <HawkBadge :custom_color="inventory_store.workflows_map[data.row.original.workflow]?.color">
                {{ inventory_store?.workflows_map?.[data.row.original.workflow].name }}
              </HawkBadge>
            </div>
          </template>
          <template #date="{ data }">
            {{ $date(data.getValue(), 'DATE_MED') }}
          </template>
          <template #total_quantity="{ data }">
            <div class="mr-1">
              {{ data.row.original?.adjustment_line_item_quantity?.[0]?.quantity }}
            </div>
            <InventoryUom :uom="data.row.original?.adjustment_line_item_quantity?.[0]?.uom" is_symbol />
          </template>
          <template #from_stock="{ data }">
            <div class="break-all">
              {{ data.getValue()?.name }}
            </div>
          </template>
          <template #to_stock="{ data }">
            <div class="break-all">
              {{ data.getValue()?.name }}
            </div>
          </template>
          <template #created_by="{ data }">
            <HawkMembers :members="data.getValue()" type="badge" />
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
