<script setup>
import { useElementSize } from '@vueuse/core';
import { useRoute } from 'vue-router';

import { useTasksStore } from '~/tasks/store/tasks.store';
import { usePusherStore } from '~/common/stores/pusher.store.js';

import TaskDetailsHeader from '~/tasks/pages/task-details-new/task-details-header.vue';
import TaskDetailsLeftSection from '~/tasks/pages/task-details-new/left-section/task-details-left-section.vue';

import 'splitpanes/dist/splitpanes.css';

const emit = defineEmits(['close']);

const route = useRoute();

const store_key = ref('');
const task_uid = ref(null);

const template_details_header = ref(null);
const show_task_name_in_header = ref(false);

const { height: header_height } = useElementSize(template_details_header);

try {
  const task_query = JSON.parse(atob(route.query.template));
  store_key.value = task_query?.store_key || 'templates';
  task_uid.value = task_query.id;
}
catch (error) {
  task_uid.value = route.query.template;
  store_key.value = 'templates';
}

const task_store = useTasksStore(store_key.value);
const pusher_store = usePusherStore();

const task_loading = ref(false);

const task_details = computed(() => {
  return task_store.get_current_task(task_uid.value);
});

onUnmounted(() => {
  if (pusher_store.is_initialized)
    pusher_store.PUSHER.unsubscribe(`presence-TASKS_TICKET_${task_uid.value}`);
  task_store.attachments_map = {};
  task_store.dependencies = {};
});

watch(() => route.query.template, async (newVal) => {
  if (newVal) {
    try {
      const task_query = JSON.parse(atob(newVal));
      store_key.value = task_query?.store_key || 'templates';
      task_uid.value = task_query.id;
    }
    catch (error) {
      task_uid.value = newVal;
      store_key.value = 'templates';
    }
    await get_data();
  }
}, { immediate: true });

watchEffect(() => {
  if (pusher_store.is_initialized)
    task_store.task_details_channel = pusher_store.PUSHER.subscribe(
      `presence-TASKS_TICKET_${task_uid.value}`,
    );
}, { flush: 'post' });

async function get_data() {
  task_loading.value = true;

  try {
    const data = await task_store.set_task_details(task_uid.value, { is_template: true });
    if (!data?.uid)
      emit('close');
    task_loading.value = false;
  }
  catch (err) {
    emit('close');
    logger.log({ err });
  }
}

async function updateTask(payload) {
  const uids = [task_details.value.uid];
  await task_store.update_tasks(uids, payload);
  task_store.task_track_events('Template updated', {}, '', []);
}

function update_task_uid(id) {
  task_store.attachments_map = {};
  task_uid.value = id;
}

function handleTemplateClose() {
  task_store.remove_opened_task();
  emit('close');
}

provide('task-loading', readonly(task_loading));
provide('task-uid', {
  task_uid,
  update_task_uid,
});
provide('is-template', true);
provide('task_store', task_store);
provide('updateFunction', updateTask);
provide('header_height', header_height);
</script>

<template>
  <div v-if="(!task_details || task_details.uid) && task_loading" class="h-[100vh] flex items-center justify-center">
    <hawk-loader />
  </div>
  <div v-else-if="task_details && task_details.uid" id="task-details">
    <TaskDetailsHeader
      ref="template_details_header"
      :show_task_name="show_task_name_in_header"
      :task_details="task_details"
      @close="handleTemplateClose"
    />
    <div class="flex">
      <TaskDetailsLeftSection
        :task_details="task_details"
        class="border-r w-2/3 border-gray-200"
        @taskNameVisible="$event => show_task_name_in_header = !$event"
      />
      <div class="w-1/3 p-6">
        <p class="text-lg font-semibold mb-6">
          {{ $t('Activity') }}
        </p>
        <HawkIllustrations variant="mini" type="no-data" for="activity_and_reminders" />
      </div>
    </div>
  </div>
  <div v-else>
    {{ $t('No data') }}
  </div>
</template>
