<script setup>
import { computed } from 'vue';

const props = defineProps({
  background_color: {
    type: String,
    default: '#f1f5f9',
  },
  size: {
    type: String,
    default: 'xs',
    validator(value) {
      return ['badge', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
    },
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  has_border: {
    type: Boolean,
    default: false,
  },
});

const avatar_classes = computed(() => {
  const avatar = [
    'flex', 'items-center', 'justify-center',
  ];

  switch (props.size) {
    case 'badge':
    case 'tiny':
      avatar.push('h-4', 'w-4', 'text-xs');
      break;
    case 'xxs':
      avatar.push('h-5', 'w-5', 'text-xs');
      break;
    case 'xs':
      avatar.push('h-6', 'w-6', 'text-xs');
      break;
    case 'sm':
      avatar.push('h-8', 'w-8', 'text-sm');
      break;
    case 'md':
      avatar.push('h-10', 'w-10', 'text-base');
      break;
    case 'lg':
      avatar.push('h-12', 'w-12', 'text-lg');
      break;
    case 'xl':
      avatar.push('h-14', 'w-14', 'text-xl');
      break;
    case '2xl':
      avatar.push('h-16', 'w-16', 'text-2xl');
      break;
  }

  return avatar;
});

const rounded_class = computed(() => (props.rounded ? 'rounded-full' : 'rounded'));
const has_border = computed(() => (props.has_border ? 'border-2 border-white' : ''));
</script>

<template>
  <div
    :class="[avatar_classes, rounded_class, has_border]"
    :style="{
      backgroundColor: background_color,
    }"
  >
    <IconIcOutlineGroups class="text-blue-500" />
  </div>
</template>
