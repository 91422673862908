<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';

const props = defineProps({
  document_details: {
    type: Object,
  },
  actual_document_details: {
    type: Object,
  },
});

const emit = defineEmits(['close']);

const HawkFileIcon = defineAsyncComponent(() => import('~/common/components/atoms/hawk-file-icon.vue'));

const route = useRoute();
const router = useRouter();
const transmittals_store = useTransmittalsStore();

const state = reactive({
  show_viewer: false,
});

const actual_document_details = props.actual_document_details;

async function download(document) {
  if (document.presigned_url)
    window.open(document.presigned_url, '_blank');
}

function addMarkup(document) {
  if (document?.actions?.can_review) {
    router.replace({ name: 'document-markup', params: { document_uid: document?.uid, transmittal_uid: transmittals_store?.transmittal.uid } });
    emit('close');
  }
}

function onDocumentBreadcrumbClicked() {
  router.push({ name: 'files-documents-all-files', params: { asset_id: route.params.asset_id }, query: { document: actual_document_details.uid, folder: actual_document_details.parent || actual_document_details.asset || 'null' } });
}
</script>

<template>
  <DocumentViewerWrapper
    v-slot="{ is_attachment_supported }"
    :document="document_details"
    :show_viewer="state.show_viewer"
    @close-attachment="state.show_viewer = false"
  >
    <div class="flex">
      <div
        class="flex-none relative cursor-pointer group border rounded-lg"
        @click="state.show_viewer = true"
      >
        <img
          v-if="document_details?.thumbnail_xsmall"
          alt="thumbnail"
          :src="document_details?.thumbnail_xsmall"
          class="w-40 h-40 rounded-lg object-cover"
        >
        <HawkFileIcon v-else icon_classes="w-20 h-20 rounded-lg object-cover" :file-name="document_details.file_name " />
        <div v-if="is_attachment_supported" class="absolute top-0  place-items-center hidden group-hover:grid">
          <div class="h-40 w-40 bg-black rounded-lg bg-opacity-20" />
          <div class="text-white absolute">
            <IconHawkEye />
          </div>
        </div>
      </div>
      <div class="flex flex-col pl-5">
        <div v-if="actual_document_details" class="flex-1 grid grid-cols-12 gap-x-4">
          <div class="col-span-4 text-sm text-gray-500">
            {{ $t('Category') }}:
          </div>
          <div class="col-span-8">
            <hawk-category-name :uid="actual_document_details.category" />
          </div>
          <div class="col-span-4 text-sm text-gray-500">
            {{ $t('Tags') }}:
          </div>
          <div class="col-span-8">
            <hawk-tags-name v-if="actual_document_details.tags?.length" :tags="actual_document_details.tags" />
            <div v-else>
              -
            </div>
          </div>
          <div class="col-span-4 text-sm text-gray-500">
            {{ $t('Location') }}:
          </div>
          <div class="col-span-8">
            <DocumentBreadcrumbs
              :active_item="actual_document_details" :is_file_details="true"
              @on-click="onDocumentBreadcrumbClicked"
            />
          </div>
        </div>
        <div class="flex mt-4">
          <hawk-button v-if="document_details?.actions?.can_review && is_attachment_supported" type="outlined" class="mr-3" @click.stop="addMarkup(document_details)">
            <IconHawkPenToolTwo />
          </hawk-button>
          <hawk-button v-if="is_attachment_supported" type="outlined" class="mr-3" @click="state.show_viewer = true">
            <IconHawkMaximizeOne />
          </hawk-button>
          <hawk-button v-if="document_details?.presigned_url" type="outlined" class="flex" @click.stop="download(document_details)">
            <IconHawkDownloadTwo class="mr-1" /> {{ $t('Download') }}
          </hawk-button>
        </div>
      </div>
    </div>
  </DocumentViewerWrapper>
</template>
