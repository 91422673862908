<script>
</script>

<template>
  <div v-if="$slots.footer">
    <slot name="footer" />
  </div>
  <div v-else class="p-6 border-t border-t-gray-200">
    <slot name="left">
      <div />
    </slot>
    <slot name="right" />
  </div>
</template>
