<template>
  <div class="h-[calc(100vh-64px)]">
    <iframe
      width="100%"
      height="100%"
      title="google sheet"
      src="https://docs.google.com/spreadsheets/d/1akww06fXVUOpiGI2PFx9xe6q00hz9ImylhgmqQeLAWE/edit?rm=minimal"
    />
  </div>
</template>
