<script setup>
import { isEmpty } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
import { useTransmittalWorkflowConfiguration } from '~/dms/composables/useTransmittalWorkflow.js';
import { useTransmittalWorkflowStore } from '~/dms/store/transmittal-workflow.store';

const route = useRoute();
const router = useRouter();
const transmittals_store = useTransmittalsStore();
const { getSelectedWorkflow, get_default_statuses } = useTransmittalWorkflowConfiguration();

const transmittal_uid = computed(() => route?.params?.transmittal_uid);
const transmittal = computed(() => transmittals_store.transmittal);
const default_statuses = ref(null);
const transmittal_configuration = ref(null);
const form$ = ref(null);

const transmittal_workflow_store = useTransmittalWorkflowStore();
transmittal_workflow_store.set_workflow({
  query: {
    active: true,
  },
});

if (transmittal_uid.value) {
  const { workflow, review_status_map } = transmittal.value;
  transmittal_configuration.value = workflow && !isEmpty(workflow) ? getSelectedWorkflow({ workflow }) : undefined;
  default_statuses.value = get_default_statuses(workflow?.status_map || review_status_map);
}

function formMounted(el$) {
  if (transmittal_uid.value) {
    const { documents } = transmittal.value;

    setTimeout(() => {
      el$.update({
        ...transmittal.value,
        reviewal_duration: documents?.[0]?.reviewal_duration,
      });
    }, 10);
  }
}

function go_back() {
  router.push({ name: 'files-transmittals-detail', params: { asset_id: route.params.asset_id, transmittal_uid: transmittal_uid.value } });
}
</script>

<template>
  <div v-if="transmittal?.uid">
    <div class="flex items-center my-4 mb-6">
      <div>
        <hawk-button type="text" @click="go_back">
          <icon-hawk-arrow-left class="text-blue-600 text-xl" />
        </hawk-button>
      </div>
      <div>
        <div class="mt-1 text-sm text-gray-500">
          {{ transmittal?.number || '-' }}
        </div>
        <div class="text-lg font-semibold">
          {{ transmittal?.name }}
          <hawk-badge
            v-if="transmittal_status"
            :color="transmittal_status?.color"
          >
            {{ transmittal_status?.label }}
          </hawk-badge>
        </div>
      </div>
    </div>
    <vueform
      ref="form$"
      :disabled="true"
      class="pointer-events-none"
      size="sm"
      :columns="{
        default: { container: 12, wrapper: 12, label: 3 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, label: 3, wrapper: 12 },
      }" @mounted="formMounted"
    >
      <div class="col-span-12">
        <create-transmittal-recipient
          v-model="transmittal_configuration"
          v-model:default_statuses="default_statuses"
          :form$="form$"
          :transmittal="transmittal"
          :is_settings="true"
        />
      </div>
    </vueform>
    <div class="my-8 justify-between flex border-t border-gray-200 p-4">
      <div />
      <hawk-button type="outlined" @click="go_back">
        {{ $t('Cancel') }}
      </hawk-button>
    </div>
  </div>
</template>
