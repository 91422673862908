<script setup>
import { onMounted, ref } from 'vue';
import { omit } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);

const $track_event = inject('$track_event');
const $toast = inject('$toast');
const auth_store = useAuthStore();
const common_store = useCommonStore();

const form$ = ref(null);
const is_loading = ref(false);
const form = ref({});

async function save() {
  try {
    is_loading.value = true;
    const payload = omit(props.item, [
      'uid',
      'name',
      'bookmark',
      'organization',
      'slug',
      'links',
    ]);
    if (payload?.config?.length)
      payload.config = payload?.config.map((item) => {
        delete item.uid;
        return item;
      });

    const response = await common_store.update_data({
      type: 'add',
      data: {
        field: {
          ...payload,
          name: form.value.name,
          organization: auth_store.current_organization?.uid,
        },
      },
      service: 'fields',
      append_data: false,
      state_prop: 'assets_custom_fields_map',
      update_state: false,
    });

    if (response?.data?.field) {
      await common_store.update_global_data({ asset_custom_fields: [response?.data?.field?.uid] });
      $toast({
        text: 'Field duplicated successfully',
        type: 'success',
      });
      $track_event('create_project_metadata', {
        type: payload.type,
      });
      emit('close');
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
    is_loading.value = false;
  }
  catch (error) {
    is_loading.value = false;
    logger.log(error);
  }
}

onMounted(() => {
  form.value = {
    name: `copy of ${props.item.name}`,
  };
  form$.value.update(form.value);
});
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Duplicate field') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            label="Field name"
            name="name"
            placeholder="Enter field name"
            rules="required"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Duplicate') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
