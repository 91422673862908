<script setup>
import { reactive, ref } from 'vue';

const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  task: {
    type: Object,
    required: true,
  },
  confirm: {
    type: Function,
    default: () => {
      return true;
    },
  },
});

const emit = defineEmits(['close']);

const $toast = inject('$toast');

const options = [
  { value: 'assignee', label: 'Assignees' },
  { value: 'attachments', label: 'Attachments' },
  { value: 'checklists', label: 'Checklists' },
  { value: 'priority', label: 'Priority' },
  { value: 'watchers', label: 'Watchers' },
  { value: 'tags', label: 'Tags' },
  { value: 'category', label: 'Category' },
  { value: 'sub_tasks', label: 'Subtasks' },
  { value: 'schedule', label: 'Schedule' },
  { value: 'status', label: 'Status' },
];

const form$ = ref(null);
const is_loading = ref(false);
const formData = reactive({
  name: `${props.task.name} - Copy`,
  inclusion_type: 'all',
  include: options.map(o => o.value),
});

function changeInclusions(newVal) {
  const include_element = form$.value.elements$.include.children$.include;
  if (newVal === 'all') {
    include_element.disableAll();
    include_element.checkAll();
  }
  else {
    include_element.enableAll();
  }
}
async function duplicateTask() {
  is_loading.value = true;
  const all = options.map(o => o.value);
  const include = {};
  all.forEach((item) => {
    if (formData.include.includes(item))
      if (item === 'checklists')
        include[item] = {
          assignee: true,
          attachments: true,
          dueDate: true,
          resolved: true,
        };
      else include[item] = true;
    else include[item] = false;
  });

  try {
    const payload = {
      action: 'duplicate',
      include: {
        ...include,
        properties: false,
        labels: true,
      },
      name: formData.name,
      description: `Duplicate of ${formData.name}`,
      task_uid: props.task.uid,
    };
    await props.task_store.duplicate_tasks(payload);
    is_loading.value = false;
    $toast({
      text: 'Duplicated successfully',
      type: 'success',
      position: 'bottom-right',
    });
    emit('close');
  }
  catch (err) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
      position: 'bottom-right',
    });
  }
}
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="emit('close')">
      <template #left>
        {{ $t('Duplicate Task') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <Vueform
        ref="form$"
        v-model="formData"
        size="sm"
        :format-load="(data) => data"
        :columns="{
          default: { container: 12, label: 4, wrapper: 12 },
          sm: { container: 12, label: 4, wrapper: 12 },
          md: { container: 12, label: 4, wrapper: 12 },
        }"
        sync
      >
        <!-- Name -->
        <TextElement v-bind="{ label: `${$t('Name')}`, class: 'mb-4', name: 'name', autocomplete: 'off' }" />
        <!-- Options -->
        <GroupElement :label="$t('What do you want to copy')" name="include">
          <RadiogroupElement
            name="inclusion_type"
            default="all"
            :items="[
              { value: 'all', label: 'Everything' },
              {
                value: 'custom',
                label:
                  'Custom',
              },
            ]"
            :override-classes="{
              RadiogroupElement: {
                wrapper: 'three_columns',
              },
            }"
            @change="changeInclusions"
          />
          <CheckboxgroupElement
            v-bind="{
              name: 'include',
              items: options,
              disabled: true,
              label: null,
              overrideClasses: {
                CheckboxgroupElement: {
                  wrapper: 'three_columns ',
                },
                CheckboxgroupCheckbox: {
                  container: 'mb-3',
                },
              },

            }"
          />
        </GroupElement>
      </Vueform>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end">
          <HawkButton color="primary" type="outlined" class="mr-3" @click="emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton color="primary" :loading="is_loading" @click="duplicateTask">
            {{ $t('Duplicate Task') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>

<style lang="scss">
.three_columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
