<script setup>
import { inject, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useSheetStore } from '../store/sheet.store';
import { useDocumentTools } from '../composables/useDocumentTools';
import { PLANS_PERMISSION } from '../constants';
import DrawingVersionModal from './modals/drawing-version-modal.vue';
import AssignedDrawingUploadModal from './modals/assigned-drawing-upload-modal.vue';
import SelectDrawingPage from './modals/select-drawing-page.vue';
import EmptyLoaderModal from './modals/empty-loader-modal.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';

const $t = inject('$t');
const $date = inject('$date');
const document_viewer_instance = inject('document-viewer-instance');
const load_document = inject('load-document');

const sheet_store = useSheetStore();
const auth_store = useAuthStore();
const route = useRoute();
const { create_pdf_document, get_doc_from_page } = useDocumentTools(document_viewer_instance);

const selected_sheet_version = ref(sheet_store.selected_version_obj);
const upload_btn_elem = ref(null);

const { open: open_drawing_version_modal, close: close_drawing_version_modal, patchOptions: patch_drawing_version_options } = useModal({
  component: DrawingVersionModal,
  attrs: {
    version_dropdown_flow: true,
    onClose() {
      upload_btn_elem.value.clear();
      close_drawing_version_modal();
    },
  },
});

const { open: open_select_drawing_page_modal, close: close_select_drawing_page_modal, patchOptions: patch_select_drawing_page_options } = useModal({
  component: SelectDrawingPage,
  attrs: {
    upload_new_version_flow: true,
    onClose() {
      upload_btn_elem.value.clear();
      close_select_drawing_page_modal();
    },
  },
});

const { open: open_assign_drawing_upload_modal, close: close_assign_drawing_upload_modal, patchOptions: patch_assign_drawing_upload_options } = useModal({
  component: AssignedDrawingUploadModal,
  attrs: {
    upload_new_version_flow: true,
    onClose() {
      close_assign_drawing_upload_modal();
    },
  },
});

// As thumbnail generation can take some time for large files
const { open: open_empty_loader_modal, close: close_empty_loader_modal } = useModal({
  component: EmptyLoaderModal,
  attrs: {
    loading_text: `${$t('Loading files')}...`,
  },
});

function selectVersionHandler(selected_version_obj) {
  selected_sheet_version.value = selected_version_obj;
  sheet_store.update_selected_sheet_version(selected_version_obj);
  load_document(document_viewer_instance, selected_version_obj.url);
}

function getSheetVerDisplayName(version_obj) {
  return `${version_obj.version_name} - ${$date(version_obj.version_date, 'L_DATE_MED')}`;
}

function psuedoHandler() {
  upload_btn_elem.value.input.click();
}

function uploadAndPatchNewVersion(pdf_to_upload) {
  patch_drawing_version_options({
    attrs: {
      uploaded_files: pdf_to_upload,
      onConfirm(versioning_form_value) {
        upload_btn_elem.value.clear();
        close_drawing_version_modal();
        patch_assign_drawing_upload_options({
          attrs: {
            documents_to_upload: pdf_to_upload,
            versioning_form_value,
          },
        });
        open_assign_drawing_upload_modal();
      },
    },
  });
  open_drawing_version_modal();
}

async function onFileUpload($uploaded_pdf) {
  if (!$uploaded_pdf || !isFileExtensionAllowed($uploaded_pdf?.name))
    return;

  open_empty_loader_modal();

  const document = await create_pdf_document($uploaded_pdf); // create pdf doc instance from uploaded file
  await window.Core.PDFNet.initialize();
  const single_page_doc_arr = await get_doc_from_page(document); // Converted a document of multiple page to multiple documents of single page
  const document_array = single_page_doc_arr.flat().map(doc => ({ doc_instance: doc }));

  for (const doc of document_array) {
    const document = await create_pdf_document(doc.doc_instance, false);
    document.loadThumbnail(1, (thumbnail) => {
      doc.thumbnail = thumbnail.toDataURL('image/jpeg');
    });
    const page_data = await document.extractPages([1]);
    const arr = new Uint8Array(page_data);
    const blob = new Blob([arr], { type: 'application/pdf' });
    doc.file_blob = blob;
  }

  close_empty_loader_modal();

  if (document_array.length === 1)
    uploadAndPatchNewVersion(document_array);

  if (document_array.length > 1) {
    patch_select_drawing_page_options({
      attrs: {
        document_pages: document_array,
        onConfirm(document_to_upload) {
          upload_btn_elem.value.clear();
          uploadAndPatchNewVersion([document_to_upload]);
        },
      },
    });
    open_select_drawing_page_modal();
  }
}

watch(() => sheet_store.selected_version_obj, (version_obj) => {
  if (version_obj) {
    selected_sheet_version.value = version_obj;
    load_document(document_viewer_instance, version_obj.url);
  }
}, { deep: true });
</script>

<template>
  <!-- hidden form element as this does not have the required styling customization -->
  <Vueform class="absolute top-0" :display-errors="false">
    <FileElement
      ref="upload_btn_elem"
      class="invisible overflow-hidden"
      name="upload_pdf"
      :auto="false"
      accept=".pdf"
      :use_uppy="false"
      :add-class="{ button: ['hidden'] }"
      @change="onFileUpload"
    />
  </Vueform>
  <hawk-menu
    class="bg-white rounded-md"
    :items="sheet_store.sheet.version_history"
    additional_dropdown_classes="max-h-44 overflow-hidden scrollbar w-full"
    additional_footer_classes="sticky bottom-0"
    @select="selectVersionHandler"
    @wheel.stop
  >
    <template #trigger>
      <div class="w-60 h-9 border rounded-lg flex items-center justify-between px-3 text-sm">
        <hawk-text :length="25" :content="getSheetVerDisplayName(selected_sheet_version)" />
        <div>
          <IconHawkChevronDown class="text-gray-500" />
        </div>
      </div>
    </template>
    <template #item="{ item }">
      <span class="text-sm">
        <hawk-text :length="27" :content="getSheetVerDisplayName(item)" />
      </span>
    </template>
    <template v-if="auth_store.check_permission(PLANS_PERMISSION.CREATE_PLANS, route.params.asset_id)" #footer>
      <div class="flex items-center cursor-pointer px-3 h py-3 text-sm font-medium rounded-lg text-gray-700 group hover:bg-gray-50" @click="psuedoHandler">
        <IconHawkUploadCloudTwo class="text-gray-500 mr-3 group-hover:text-gray-700" />
        {{ $t('Upload new version') }}
      </div>
    </template>
  </hawk-menu>
</template>
