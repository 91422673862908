<script setup>
import { computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useEmitter from '~/common/composables/useEmitter';
import { useDocumentTools } from '~/plans/composables/useDocumentTools';
import { ANNOTATION_TYPES, TOOL_EVENTS } from '~/plans/constants';
import { useAnnotationsStore } from '~/plans/store/annotations.store';
import { toggleFullscreen } from '~/plans/utils/helper';
import { useAnnotationsEdit } from '~/plans/composables/useAnnotationsEdit';
import DocumentReviewers from '~/plans/components/document-reviewers.vue';
import { useSheetStore } from '~/plans/store/sheet.store';

const props = defineProps({
  active_tool: {
    type: String,
  },
  is_fullscreen_mode: {
    type: Boolean,
    default: false,
  },
  show_annotation_edit: {
    type: Boolean,
    default: false,
  },
  show_comment_back_drop: {
    type: Boolean,
    default: false,
  },
  custom_annot_context_menu: {
    type: Object,
    required: true,
  },
  text_annots_selected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onToggleFullscreen', 'onToggleBackdrop', 'onUpdateCustomAnnot']);
const route = useRoute();
const router = useRouter();

const $t = inject('$t');
const document_viewer_instance = inject('document-viewer-instance');
const viewer_container_elem = inject('viewer-container-elem');
const load_document = inject('load-document');
const sheet_url = inject('sheet-url');

const emitter = useEmitter();
const annotations_store = useAnnotationsStore();
const sheet_store = useSheetStore();

const annotation_manager = document_viewer_instance.value.getAnnotationManager();
const display_mode = document_viewer_instance.value
  .getDisplayModeManager()
  .getDisplayMode();

const {
  edit_normal_annotation_tools,
  edit_text_annotation_tools,
} = useAnnotationsEdit(document_viewer_instance);

const {
  zoom_in,
  zoom_out,
  delete_annotations,
  download_pdf,
  export_annotations,
  get_annotation_by_id,
} = useDocumentTools(document_viewer_instance);

function hideAnnotations(selected_reviewers = []) {
  const annotation_manager = document_viewer_instance.value.getAnnotationManager();

  const all_annotations = annotation_manager.getAnnotationsList();

  all_annotations.forEach((annotation) => {
    annotation.Hidden = !selected_reviewers.includes(annotation.Author);
    annotation_manager.redrawAnnotation(annotation);
  });
};

const zoom_tools = computed(() => {
  return [
    {
      tooltip: $t('Zoom in'),
      icon: IconHawkPlusCircle,
      event: TOOL_EVENTS.ZOOM_IN,
      action: zoom_in,
    },
    {
      tooltip: $t('Zoom out'),
      icon: IconHawkMinusCircle,
      event: TOOL_EVENTS.ZOOM_OUT,
      action: zoom_out,
    },
    {
      tooltip: $t('Pan'),
      icon: IconHawkHandCopy,
      event: TOOL_EVENTS.PAN,
      action: () => emitter.emit('toolActivated', TOOL_EVENTS.PAN),
    },
    {
      tooltip: $t('Edit'),
      icon: IconHawkNavigationPointerOne,
      event: TOOL_EVENTS.EDIT,
      action: () => emitter.emit('toolActivated', TOOL_EVENTS.EDIT),
    },
  ];
});

const drawing_tools = computed(() => {
  return [
    {
      tooltip: $t('Text'),
      icon: IconHawkTypeOne,
      event: TOOL_EVENTS.CREATE_TEXT,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_TEXT);
      },
    },
    {
      tooltip: $t('Arrow'),
      icon: IconHawkArrowDownRight,
      event: TOOL_EVENTS.CREATE_ARROW,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_ARROW);
      },
    },
    {
      tooltip: $t('Line'),
      icon: IconHawkSlashDivider,
      event: TOOL_EVENTS.CREATE_LINE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_LINE);
      },
    },
    {
      tooltip: $t('Cloud'),
      icon: IconHawkCloudOne,
      event: TOOL_EVENTS.CREATE_CLOUD,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_CLOUD);
      },
    },
    {
      tooltip: $t('Rectangle'),
      icon: IconHawkSquare,
      event: TOOL_EVENTS.CREATE_RECTANGLE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_RECTANGLE);
      },
    },
    {
      tooltip: $t('Ellipse'),
      icon: IconHawkCircle,
      event: TOOL_EVENTS.CREATE_ELLIPSE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_ELLIPSE);
      },
    },
  ];
});

const feature_tools = computed(() => {
  return [
    {
      tooltip: props.is_fullscreen_mode ? $t('Collapse') : $t('Fullscreen'),
      icon: props.is_fullscreen_mode ? IconHawkMinimizeTwo : IconHawkMaximizeTwo,
      event: TOOL_EVENTS.FULLSCREEN,
      action: () => {
        emit('onToggleFullscreen', !props.is_fullscreen_mode);
        toggleFullscreen(viewer_container_elem.value);
        emitter.emit('toolActivated', TOOL_EVENTS.FULLSCREEN);
      },
    },
    {
      tooltip: $t('Comment'),
      icon: IconHawkMessageDotsCircle,
      event: TOOL_EVENTS.CREATE_STICKY,
      action: () => emitter.emit('toolActivated', TOOL_EVENTS.CREATE_STICKY),
    },
    {
      tooltip: $t('Download'),
      icon: IconHawkDownloadOne,
      action: download_pdf,
    },
  ];
});

watch(
  () => route.query.note,
  async (note_id) => {
    if (note_id) {
      emit('onToggleBackdrop', true);
      if (note_id.includes('new_')) {
        const annot_id = note_id.split('_')[1];
        const annot = get_annotation_by_id(annot_id);
        !annot && clear_route(); // when new_ is present and page is refreshed
        annotation_manager.selectAnnotation(annot);

        const scrollViewElement = document_viewer_instance.value.getScrollViewElement();
        const scroll_top = scrollViewElement.scrollTop;
        const scroll_left = scrollViewElement.scrollLeft;

        const window_coordinates = display_mode.pageToWindow({
          x: annot.getX(),
          y: annot.getY(),
        }, 1);

        emit('onUpdateCustomAnnot', {
          annotation: annot,
          data: null,
          is_mouse_over: true,
          pos_x: window_coordinates.x - scroll_left,
          pos_y: window_coordinates.y - scroll_top,
          type: ANNOTATION_TYPES.NOTE,
        });
      }
      else {
        const annotations = annotation_manager.getAnnotationsList();
        const note_annotation = annotations.find(
          annotation => annotation.Subject === ANNOTATION_TYPES.NOTE && annotation.Id === note_id,
        );
        emit('onUpdateCustomAnnot', {
          is_mouse_over: true,
        });

        const scroll_view_elem = document.getElementById('scroll-view');
        const scroll_top = scroll_view_elem.scrollTop;
        const scroll_left = scroll_view_elem.scrollLeft;
        const offset_y = 0;
        const window_coordinates = display_mode.pageToWindow({
          x: note_annotation.getX(),
          y: note_annotation.getY(),
        }, 1);

        emit('onUpdateCustomAnnot', {
          pos_x: window_coordinates.x - scroll_left + 10,
          pos_y: window_coordinates.y - scroll_top - offset_y,
          data: null,
          annotation: note_annotation,
          type: ANNOTATION_TYPES.NOTE,
        });
      }
    }

    else if (!note_id) {
      emit('onToggleBackdrop', false);
    }
  }, { immediate: true },
);

function getContexPositionStyle() {
  const container_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };

  const style_x
      = props.custom_annot_context_menu.pos_x < container_center.x ? { left: `${10}px` } : { right: `${10}px` };
  const style_y
      = props.custom_annot_context_menu.pos_y < container_center.y
        ? { top: `${0}px` }
        : { bottom: `${-100}px` };

  return { position: 'absolute', ...style_x, ...style_y };
}

function clear_route() {
  router.replace({
    ...route,
    query: {},
  });
}

function closeNoteContext() {
  clear_route();
  annotation_manager.deselectAllAnnotations();

  emit('onUpdateCustomAnnot', {
    is_mouse_over: false,
    pos_x: 0,
    pos_y: 0,
    data: null,
    annotation: null,
    type: null,
  });
}
function deleteNoteAnnotation() {
  delete_annotations([get_annotation_by_id(props.custom_annot_context_menu.annotation.Id)]);

  emit('onUpdateCustomAnnot', {
    pos_x: 0,
    pos_y: 0,
    data: null,
    annotation: null,
    type: null,
    is_mouse_over: false,
  });

  clear_route();
}
function exitClickHandler() {
  load_document(document_viewer_instance, sheet_url);
  sheet_store.set_compare_view(false);
}
</script>

<template>
  <div v-if="!sheet_store.is_compare_view" class="z-1 absolute right-4 top-4 flex gap-2">
    <template v-if="show_annotation_edit">
      <sheet-tool-icons :tools="text_annots_selected ? edit_text_annotation_tools : edit_normal_annotation_tools" :active_tool="active_tool" :horizontal_icons="true" />
    </template>
    <template v-else>
      <sheet-tool-icons :tools="zoom_tools" :active_tool="active_tool" horizontal_icons />
      <sheet-tool-icons :tools="drawing_tools" :active_tool="active_tool" horizontal_icons />
      <sheet-tool-icons :tools="feature_tools" :active_tool="active_tool" horizontal_icons />
    </template>
  </div>
  <div v-if="!show_annotation_edit" class="z-1 absolute right-4 top-16 flex gap-2">
    <DocumentReviewers @hideAnnotations="hideAnnotations" />
  </div>
  <div v-if="sheet_store.is_compare_view" class="z-1 absolute right-4 top-4 compare-widget flex justify-between">
    <hawk-button class="ml-2" type="outlined" @click="exitClickHandler">
      <IconHawkArrowLeft />
      {{ $t('Exit compare') }}
    </hawk-button>
    <sheet-tool-icons :tools="zoom_tools" :active_tool="active_tool" horizontal_icons />
  </div>
  <Teleport to="#viewer-container">
    <div
      v-if="props.custom_annot_context_menu.type === ANNOTATION_TYPES.NOTE"
      class="z-1 absolute" :style="{ top: `${props.custom_annot_context_menu.pos_y}px`, left: `${props.custom_annot_context_menu.pos_x}px` }"
      :class="{ 'z-2': props.show_comment_back_drop }"
      @mouseover="emit('onUpdateCustomAnnot', {
        is_mouse_over: true,
      })"
      @mouseout="emit('onUpdateCustomAnnot', {
        is_mouse_over: false,
      })"
    >
      <note-context-menu
        :style="{ ...getContexPositionStyle() }"
        :note="props.custom_annot_context_menu.data"
        :annotation="props.custom_annot_context_menu.annotation"
        :export_annotations="export_annotations"
        @closeContext="closeNoteContext"
        @deleteAnnot="deleteNoteAnnotation"
      />
    </div>
  </Teleport>
  <div
    v-if="props.show_comment_back_drop" class="absolute top-0 left-0 h-[calc(100%+1%)] w-[calc(100%+0.5%)] bg-black z-1 opacity-10 rounded-md"
    @wheel.stop
    @mousedown.stop
    @mousemove.stop
    @keydown.stop
    @click="emit('onToggleBackdrop', false); annotation_manager.deselectAllAnnotations()"
  />
</template>

<style lang="scss" scoped>
.compare-widget {
  width: calc(100% - (2rem + 3.125rem));
}
</style>
