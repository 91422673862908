<script setup>
import { computed } from 'vue';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  uid: {
    type: String,
    default: '',
  },
  truncate_length: {
    type: Number,
    default: 30,
  },
});

const common_store = useCommonStore();

const category = computed(() => common_store.get_category(props?.uid));
</script>

<template>
  <div v-if="props.uid && category?.name" class="text-gray-600 text-sm whitespace-nowrap">
    <HawkText :content="category?.name" :length="truncate_length" />
  </div>
  <div v-else>
    {{ '-' }}
  </div>
</template>
