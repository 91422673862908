<script>
export default {
  props: { is_scroll: { default: true } },
};
</script>

<template>
  <div class="p-6 " :class="{ 'max-h-[calc(100vh-200px)] overflow-auto scrollbar': is_scroll }">
    <slot />
  </div>
</template>
