<script setup>
import { snakeCaseToWord } from '~/common/utils/common.utils.js';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const TEMPLATE_VERB_MAP = {
  CREATED: 'created the template',
  UPDATED: 'updated the template',
  DELETED: 'deleted the template',
  MARKED_AS_CURRENT: 'published the template',
};

const COMPONENT_VERB_MAP = {
  CREATED: 'created the component',
  UPDATED: 'updated the component',
  DELETED: 'deleted the component',
  CUSTOM_FIELD_VALUE_CHANGED: 'updated the properties',
  INSTANCES_CREATED: activity => `created ${activity.meta?.instances?.count} instances`,
  INSTANCES_UPDATED: activity => `updated ${activity.meta?.instances?.count} instances`,
  INSTANCES_DELETED: activity => `deleted ${activity.meta?.instances?.count} instances`,
};

function getVerbMap(resource) {
  switch (resource) {
    case 'TEMPLATE':
      return TEMPLATE_VERB_MAP;
    case 'COMPONENT':
      return COMPONENT_VERB_MAP;
    default: {
      const resource_name_formatted = snakeCaseToWord(resource).toLowerCase();
      return {
        CREATED: `created the ${resource_name_formatted}`,
        UPDATED: `updated the ${resource_name_formatted}`,
        DELETED: `deleted the ${resource_name_formatted}`,
      };
    }
  }
}

function getActivityText(activity) {
  const verb_map = getVerbMap(activity.resource_name);

  if (typeof verb_map[activity.verb] === 'function')
    return verb_map[activity.verb](activity);
  return verb_map[activity.verb];
}
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="Object.keys(getVerbMap(activity.resource_name)).includes(activity.verb)" class="text-sm">
      {{ getActivityText(activity) }}
    </div>
  </div>
</template>
