import { pick } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import { getUserFullName } from '~/common/utils/common.utils.js';

export function usePivotTableComposable() {
  const { $services, common_store } = useCommonImports();
  const { static_keys } = useTerraHelperComposable();

  function getFeatureProperties(feature) {
    const featureType = feature.featureTypeName || '';
    const extraProperties = {};
    Object.entries(feature.extraProperties || {}).forEach(([key, value]) => {
      if (!key.startsWith('_') && !static_keys.includes(key))
        extraProperties[key] = String(value || '');
    });
    return {
      ...pick(feature, ['uid', 'name', 'description']),
      'Feature type': featureType,
      ...extraProperties,
    };
  }

  function getProgressHistory(data) {
    return {
      'Feature': data.name,
      'Workflow': data.workflow,
      'Field': data.field,
      'Date': data.date,
      'Value': data.value,
      'User': getUserFullName(common_store.get_user(data.user) || {}),
      'Category': common_store.get_category(data.category)?.name,
      'Notes': data.notes,
      'Reset': data.reset,
      'Reset purpose': data.invalidate_purpose,
      'Layer': data.layer,
      'Group': data.group,
    };
  }

  function getProgressData(data) {
    return {
      Feature: data.name,
      Workflow: data.workflow,
      Field: data.field,
      Current: data.current,
      Total: data.total,
      Layer: data.layer,
      Group: data.group,
    };
  }

  function processPivotTableData(data, options) {
    const pivot_table_data_map = {};
    data.forEach((f, i) => {
      if (options.data_source === 'feature_properties')
        pivot_table_data_map[f.uid] = getFeatureProperties(f);
      else if (options.data_source === 'progress_history')
        pivot_table_data_map[i] = getProgressHistory(f);
      else
        pivot_table_data_map[i] = getProgressData(f);
    });
    return pivot_table_data_map;
  }

  async function getPivotTableData(options) {
    let response = [];
    if (options.data_source === 'feature_properties') {
      response = await $services.features.post({
        attribute: `container/${options.container}/properties/operations`,
        body: {
          op: 'get',
          projects: options.projects,
        },
      });
    }
    else {
      let attribute = `container/${options.container}/workflow-progress`;
      if (options.data_source === 'progress_history')
        attribute = 'progress-data';
      response = await $services.terra.post({
        attribute,
        body: {
          [options.data_source === 'progress_history' ? 'project' : 'projects']: options.projects,
          include_reset: true,
        },
        query: {
          asset_uid: options.asset_id,
        },
      });
    }
    return processPivotTableData(response.data, options);
  }

  return { getPivotTableData };
}
