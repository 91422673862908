<script setup>
import { sortData } from '~/common/utils/common.utils';
import AssetsGridItem from '~/assets/components/assets-grid-item.vue';

const props = defineProps({
  assets: {
    type: Array,
  },
  hawk_menu_items: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  sort_order: {
    type: String,
    default: '',
  },
});
const $t = inject('$t');

const filtered_data = computed(() => {
  return sortData(props?.assets, 'name', ['asc'], true);
});
</script>

<template>
  <HawkLoader v-if="props.loading" />
  <div v-else class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
    <div
      v-for="asset in filtered_data" :key="asset.uid"
      class="ol-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-md hover:shadow-lg relative cursor-pointer"
      @click="$router.push({ name: 'asset-dashboard', params: { asset_id: asset.uid } })"
    >
      <AssetsGridItem :asset="asset" :hawk_menu_items="hawk_menu_items" />
    </div>
  </div>
</template>
