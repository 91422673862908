export function useForm() {
  return {
    formatLoadedData(data) {
      const load_data = {};
      const members_obj = {};
      const teams_obj = {};
      data?.assignees?.length && data?.assignees.forEach((item) => {
        if (item.member)
          members_obj[`${item.uid}`] = 'assignee';
        else
          teams_obj[`${item.uid}`] = 'assignee';
      });
      load_data.members = { ...members_obj };
      load_data.teams = { ...teams_obj };
      return load_data;
    },
  };
}
function getFilteredoverdues(overdue_obj) {
  return Object.entries(overdue_obj).filter(([key, value]) => value > 0).map(([key, value]) => `${value} ${key}`).join(', ');
}
export function useStatusMetrics(metric) {
  const data = [];
  const metric_data = metric.data.getValue() || {};
  Object.keys(metric_data).forEach((key) => {
    const obj = {};
    const overdue_obj = {};
    obj.value = metric_data[key]?.value;
    obj.color = metric_data[key]?.color;
    obj.name = metric_data[key]?.name;
    const order_value = obj.name === 'Draft' ? 0 : Number.POSITIVE_INFINITY;
    obj.order = obj.name === 'Open' ? -1 : order_value;
    obj.order = metric_data[key]?.index || metric_data[key]?.step_index || obj.order;
    overdue_obj.Overdue = metric_data[key]?.due_metrics?.overdue;
    overdue_obj.Upcoming = metric_data[key]?.due_metrics?.upcoming;
    overdue_obj.Today = metric_data[key]?.due_metrics?.today;
    overdue_obj['Pending Approval'] = metric_data[key]?.approval_metrics?.general?.overdue;
    obj.overdueMetrics = getFilteredoverdues(overdue_obj);
    data.push(obj);
  });
  data.sort((metric1, metric2) => {
    return metric1.order - metric2.order;
  });
  return data;
}
export function useSubmissionStatusMetrics(form, $t) {
  if (form?.status?.name === 'Submitted')
    return { name: $t('Submitted'), color: '#0EAC53' };
  if (form?.workflow && form?.workflow_version === 'v2')
    return { name: form?.current_step?.block_name, color: form?.current_step?.color };
  else if (Object.keys(form?.current_step || {})?.length)
    return form?.current_step;

  else if (
    form?.status?.name === 'Draft'
  )
    return {
      name: $t('Draft'),
      color: '#F8C900',
    };

  else
    return {
      name: $t('Open'),
      color: '#F79C4B',
    };
}
