<script setup>
import { isEqual } from 'lodash-es';
import { getFieldComponentData, getFieldComponentType, getSubmittedFieldData } from '~/forms/composables/form-field-schema.composable.js';

const props = defineProps({
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  section: {
    type: Object,
  },
  fields: {
    type: Array,
    default: () => [],
  },
  row: {
    type: Object,
    default: () => ({}),
  },
  form_data: {
    type: Object,
    default: () => ({}),
  },
  mandatory: {
    type: Boolean,
    default: false,
  },
  is_data_editable: {
    type: Boolean,
    default: false,
  },
  update_field_properties: {
    type: Boolean,
    default: true,
  },
  field_values: {
    type: Array,
    default: () => [],
  },
  is_disabled: {
    type: Boolean,
  },
  visibility: {
    type: Object,
  },
  focusFieldUID: {
    type: String,
  },
});

const emit = defineEmits(['close']);

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const $toast = inject('$toast');
const form$ = ref(null);
const highlight_classes = ref(null);
const field_elements = ref({});
const row = { ...(props?.row || {}) };
const row_index = row?.index;
const data = {};

function is_valid_value(field, value) {
  const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;
  if (Array.isArray(field?.config))
    return field_type === 'checkbox' ? value?.length > 0 : !!field.config.find(field => field?.uid === value);

  return ['checkbox', 'members', 'attachment'].includes(field_type) ? value?.length > 0 : !!value;
}

function check_equality(field, new_value, old_value) {
  const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;

  if (field_type === 'attachment') {
    const service_object_new = new_value.service;
    const service_object_old = old_value.service;
    return isEqual(service_object_new, service_object_old);
  }
  else {
    return isEqual(new_value, old_value);
  }
}

const fields = props.fields.filter(field => field.status === 'active').map((field, field_index) => ({
  ...field,
  mandatory: props.update_field_properties ? false : field.mandatory,
  disabled: props.is_data_editable ? false : is_valid_value(field, field?.properties?._value?.[row_index]) && check_equality(field, props.field_values?.[field_index]?.[row_index], field?.properties?._value?.[row_index]),
}));

const is_field_disabled = fields.filter(field => field.disabled || (props.visibility && props.visibility(props.section, field) === '')).length > 0;

props.fields.forEach((field, field_index) => {
  let value = props.field_values?.[field_index]?.[row_index];
  if (value === '-')
    value = '';

  if (field.type === 'phone_number' && !value)
    data[field.uid] = '';
  else if (field?.properties.type === 'checkbox' && !value)
    data[field.uid] = [];
  else
    data[field.uid] = value;
});

async function updateFields(form) {
  try {
    let has_data = false;
    Object.values(form.requestData).forEach((value) => {
      has_data = value || has_data;
    });
    if (has_data)
      await props.submit(form, row);
    else
      emit('close');
  }
  catch (e) {
    logger.log(e);
    $toast({ text: e?.data?.message || 'Field Updating failed!', type: 'error' });
  }
}

function scrollAndFocusField() {
  const scroll_element = field_elements.value[props.focusFieldUID];

  scroll_element?.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
  });
  highlight_classes.value = 'bg-yellow-200 rounded-md';

  const field_focus = form$.value.elements$[props.focusFieldUID]?.custom_focus || form$.value.elements$[props.focusFieldUID]?.handleOpen || form$.value.elements$[props.focusFieldUID].focus;
  setTimeout(() => {
    field_focus();
  }, 10);

  setTimeout(() => {
    highlight_classes.value = null;
  }, 1000);
}

function loadData(form$) {
  form$.disableValidation();
  form$.load(data, true);
  !props.focusFieldUID && setTimeout(() => {
    form$.enableValidation();
    form$.elements$[props.fields?.[0]?.uid]?.focus();
  }, 100);

  props.focusFieldUID && setTimeout(() => {
    scrollAndFocusField();
  }, 0);
}
</script>

<template>
  <hawk-modal-container @click.stop>
    <Vueform
      id="table-value-popup"
      ref="form$" size="sm" :display-errors="false" :endpoint="(form_data, form) => updateFields(form, row)"
      :columns="{
        default: { container: 12, wrapper: 12, label: 6 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @mounted="loadData"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ props.section.name }} - {{ +(row?.index || 0) + 1 }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="w-[600px] min-h-[450px] max-h-[60vh] overflow-auto scrollbar">
          <div v-for="field in fields" :ref="(elem) => field_elements[field.uid] = elem" :key="field.uid" class="px-1 py-2" :class="[visibility?.(section, field), field.uid === focusFieldUID ? highlight_classes : null]">
            <TextElement v-if="(field.properties.type === 'signature' && update_field_properties)" :label="field.name || ' '" placeholder="This field will be available while submitting the form" disabled />
            <StaticElement
              v-else-if="field.disabled || visibility?.(section, field) === '' " :label="field.name || ' '" class="pb-4"
              :columns="{
                default: { container: 6, wrapper: field.type === 'attachment' ? 12 : 8, label: 6 },
                sm: { container: 12, label: 12, wrapper: 12 },
                md: { container: 8, label: 4, wrapper: field.type === 'attachment' ? 12 : 8 },
              }"
            >
              <HawkWysiwygEditorComponent v-if="field.type === 'long_text'" :model-value="data[field.uid] || '-'" :editor_enabled="false" :view="{ no_padding: true }" class="!p-0" editor_classes="!p-0" />
              <div v-else-if="field.type === 'member' && getSubmittedFieldData(field, data[field.uid]) && getSubmittedFieldData(field, data[field.uid]) !== '-' " class="flex flex-wrap">
                <HawkMembers
                  :members="[...getSubmittedFieldData(field, data[field.uid]).members, ...getSubmittedFieldData(field, data[field.uid]).teams]"
                  size="badge"
                  type="label"
                />
              </div>
              <template v-else-if="field.properties.type === 'signature'">
                <hawk-signature v-if="data[field.uid]?.[0]" :model-value="data[field.uid]?.[0]" class="pointer-events-none" :placeholder="true" />
                <div v-else>
                  -
                </div>
              </template>
              <hawk-sheet-input v-else-if="field.type === 'multi_text'" v-bind="getFieldComponentData(field, { is_submitted: true, is_builder: false })" :editable="false" :model-value="data[field.uid]" />
              <hawk-attachments-grid v-else-if="field.type === 'attachment' && data[field.uid] && getSubmittedFieldData(field, data[field.uid]) !== '-'" :items="getSubmittedFieldData(field, data[field.uid])" :can_delete="false" :enable_description="true" class="pointer-events-auto" />
              <div v-else-if="(field.type === 'attachment' || field.type === 'member')">
                -
              </div>
              <ul v-else-if="field.properties.type === 'checkbox'" class="list-disc">
                <div v-if="getSubmittedFieldData(field, data[field.uid]).length === 0">
                  -
                </div>
                <li v-for="(value, index) in getSubmittedFieldData(field, data[field.uid])" v-else :key="index" class="list-item">
                  {{ value }}
                </li>
              </ul>
              <div v-else-if="!(['long_text', 'member', 'attachment'].includes(field.type))">
                {{ getSubmittedFieldData(field, data[field.uid]) }}
              </div>
            </StaticElement>
            <TextElement v-else-if="field?.config?.type == 'formula'" :label="field.name" placeholder="This field will be auto calculated" disabled />
            <component
              :is="getFieldComponentType(field)"
              v-else
              class="text-[#344054] font-normal text-sm"
              v-bind="getFieldComponentData(field)"
              :disabled="field.disabled"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer v-if="!props.is_disabled">
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-between w-full">
                <div>
                  <HawkButton v-if="!is_field_disabled" color="error" type="plain" class="mr-4" @click="emit('remove', row_index)">
                    {{ $t('Remove') }}
                  </HawkButton>
                </div>
                <div class="flex">
                  <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                    {{ $t('Cancel') }}
                  </HawkButton>
                  <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
