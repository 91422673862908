import { defineStore } from 'pinia';
import { keyBy } from 'lodash-es';

export function useFormTemplatesStore(key) {
  return defineStore(key || 'form-templates', {
    state: () => ({
      form_templates_map: {},
      total_template_count: 0,
      forms_asset_template: [],
    }),
    getters: {
      form_templates(state) {
        return Object.values(state.form_templates_map).sort((form1, form2) => {
          return form1.name.localeCompare(form2.name);
        });
      },

    },
    actions: {
      async set_form_templates(req) {
        try {
          const { data } = await this.$services.forms.getAll({
            attribute: 'templates',
            body: req.body,
            query: {
              ...req?.query || {},
              asset_uid: req?.query?.asset_uid || this.$router.currentRoute.value.params.asset_id || undefined,
              page_size: 100000000,
            },
          });
          this.form_templates_map = keyBy(data.templates, 'uid');
          this.total_template_count = data?.templates?.length;
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'Fetching Templates Failed!', type: 'error' });
          return error;
        }
      },
      async create_form_template(req) {
        try {
          const { data } = await this.$services.forms.post({
            attribute: 'templates',
            body: req.body,
            query: {
              ...req?.query || {},
              asset_uid: this.$router.currentRoute.value.params.asset_id || null,
            },
            ...req,
          });
          const new_form = data.template;
          this.form_templates_map = { ...{ [new_form.uid]: new_form }, ...this.form_templates_map };
          if (req.toast)
            this.$toast({ text: 'New Template Created Successfully!', type: 'success' });
          return new_form;
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'New Template Creation Failed', type: 'error' });
          return error;
        }
      },

      async get_approve_template(req) {
        try {
          const { data } = await this.$services.forms.get(
            {
              query: {
                ...req?.query || {},
              },
              attribute: 'templates/import-templates',
            },
          );
          this.forms_asset_template = data?.templates;
        }
        catch (error) {
          logger.error(error);
          throw error;
        }
      },
      async import_template(req) {
        try {
          const { data } = await this.$services.forms.post({
            attribute: `templates/${req.uid}/duplicate`,
            body: req.body,
          });
          const new_template = data.template;
          this.form_templates_map = { ...{ [new_template.uid]: new_template }, ...this.form_templates_map };
          this.$toast({ text: 'Form Imported Successfully!', type: 'success' });
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'Form imported Failed', type: 'error' });
          return error;
        }
      },
    },
  })();
}
