<script setup>
defineProps({
  metrics: {
    type: Array,
    required: true,
  },
  get_block_by_uid: {
    type: Object,
  },
});
const $t = inject('$t');

const form = ref({ condition: true });
</script>

<template>
  <div>
    <div class="text-[14px] mb-5">
      {{ metrics.count }}
      {{ $t("ongoing submissions will be affected by deleting this block.") }}
    </div>
    <div
      v-for="(count, block_uid) in metrics.blocks"
      :key="block_uid"
      class="text-[14px] font-semibold mb-2 columns-2"
    >
      <div>
        {{ get_block_by_uid[block_uid].properties.name }}
      </div>
      <div>
        {{ count }}
      </div>
    </div>
    <div class="text-[14px] mb-2 mt-5">
      {{ $t('Choose an action for the affected forms') }}
    </div>
    <Vueform v-model="form" :sync="true" size="sm">
      <RadiogroupElement
        name="condition"
        :items="[{ label: $t('Close/Submit the forms'), value: true }, { label: $t('Continue with the old workflow'), value: false }]"
        @change="($event) => $emit('change', $event)"
      />
    </Vueform>
  </div>
</template>
