<script setup>
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useTerraStore } from '~/terra/store/terra.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import NewLayerPopup from '~/terra/components/self-service/new-map-popup.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import ProjectsList from '~/terra/components/layers/projects-list.vue';
import { $toast } from '~/common/utils/common.utils';

const props = defineProps({
  terra_store_name: {
    type: String,
    default: 'terra',
  },
  side: {
    type: String,
  },
});
const emit = defineEmits(['groupOrProjectToggled']);

const new_layer_popup = useModal({
  component: NewLayerPopup,
  attrs: {
    is_layer: true,
    onClose() {
      new_layer_popup.close();
    },
  },
});
const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      delete_popup.close();
    },
  },
});

const $t = inject('$t');
const $services = inject('$services');
const route = useRoute();
const terra_store = useTerraStore(props.terra_store_name);
const auth_store = useAuthStore();

const state = reactive({
  opened_group: [],
  is_group_opened: false,
  group_menu_opened: '',
});

const container = computed(() => terra_store.container);
const active_projects = computed(() => terra_store.active_projects);

function addNewGroup() {
  new_layer_popup.patchOptions({
    attrs: {
      is_layer: true,
      name: '',
      header: 'Add New Group',
      popup_type: 'rename',
      async on_save({ name }) {
        const payload = {
          name,
          organization: auth_store?.current_organization?.uid,
        };
        await terra_store.update_groups({ action: 'add', payload });
        terra_store.terra_track_events('Created group');
        terra_store.terra_track_events('Add layer group');
        new_layer_popup.close();
      },
    },
  });
  new_layer_popup.open();
}
function getGroupMenuItems(group) {
  return [
    {
      label: $t('Rename'),
      uid: 'rename',
      show: container.value.modify_groups,
      on_click: () => {
        new_layer_popup.patchOptions({
          attrs: {
            is_layer: true,
            onClose() {
              new_layer_popup.close();
            },
            name: group.name,
            header: 'Rename',
            popup_type: 'rename',
            async on_save({ name }) {
              await terra_store.update_groups({ action: 'update', payload: { name }, group_uid: group.uid });
              terra_store.terra_track_events('Updated grouped');
              new_layer_popup.close();
            },
          },
        });
        new_layer_popup.open();
      },
    },
    {
      label: $t('Delete'),
      uid: 'delete',
      show: container.value.modify_groups,
      on_click: () => {
        delete_popup.patchOptions({
          attrs: {
            onClose() {
              delete_popup.close();
            },
            header: group.name,
            content: 'Are you sure you want to delete the group?',
            show_toast: true,
            confirm: async () => {
              await terra_store.update_groups({ action: 'delete', group_uid: group.uid });
              terra_store.terra_track_events('Deleted group');
              delete_popup.close();
            },
          },
        });
        delete_popup.open();
      },
    },
    {
      label: $t('Add new sub-layer'),
      uid: 'add-layer',
      show: container.value.create_projects,
      on_click: () => {
        new_layer_popup.patchOptions({
          attrs: {
            header: 'New layer',
            popup_type: 'map',
            name: '',
            async on_save({ name, geojson_data }) {
              try {
                const payload = {
                  name,
                  organization: auth_store?.current_organization?.uid,
                };
                await terra_store.update_projects({ action: 'add', payload, group_uid: group.uid, geojson_data });
                terra_store.terra_track_events('Created project');
                terra_store.terra_track_events('Add new sub layer');
                new_layer_popup.close();
              }
              catch (error) {
                $toast({
                  title: 'Error importing features',
                  type: 'error',
                });
                new_layer_popup.close();
              }
            },
          },
        });
        new_layer_popup.open();
      },
    },
  ].filter(menu => menu.show);
}
function openGroup(uid) {
  const a = state.opened_group.indexOf(uid);
  if (a !== -1)
    state.opened_group.splice(a, 1);
  else
    state.opened_group.push(uid);
}
async function toggleGroup(group, type = 'both') {
  try {
    terra_store.is_loading = true;
    const container_copy = container.value;
    // get group state
    if (type === 'both') {
      const active = !(terra_store.active_group_map[group.uid].ortho_enabled && terra_store.active_group_map[group.uid].features_enabled);
      terra_store.active_group_map[group.uid].ortho_enabled = terra_store.active_group_map[group.uid].features_enabled = active;
    }
    else if (type === 'ortho') {
      terra_store.active_group_map[group.uid].ortho_enabled = !terra_store.active_group_map[group.uid].ortho_enabled;
    }
    else { terra_store.active_group_map[group.uid].features_enabled = !terra_store.active_group_map[group.uid].features_enabled; }

    terra_store.projects_request_status.show = (terra_store.active_group_map[group.uid].ortho_enabled || terra_store.active_group_map[group.uid].features_enabled);

    // Update group data for features and
    group = await terra_store.set_group({ group, container: container.value });

    terra_store.projects_request_status = {
      total: 0,
      current: 0,
      cancel: false,
      show: false,
    };
    // Replace the group in container with updated group
    container_copy.groups[group.uid] = group;
    terra_store.set_container({ group, container: container_copy });

    if (terra_store.active_group_map[group.uid].ortho_enabled || terra_store.active_group_map[group.uid].features_enabled)
      terra_store.fly_to_project({ project: Object.values(group.projects)[0] });
    terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
    await terra_store.update_map_features_and_polygon();
    terra_store.selected_features = [];
    // Order should be maintained because of features on the map

    terra_store.terra_track_events('Layers filtered');
    emit('groupOrProjectToggled');
    terra_store.is_loading = false;
  }
  catch (err) {
    logger.log('🚀 ~ file: groups-list.vue:192 ~ err:', err);
    terra_store.is_loading = false;
  }
}
function setOpenedGroups() {
  active_projects.value.forEach((item) => {
    Object.values(container.value.groups).forEach((group) => {
      if (container.value.groups[group.uid].projects[item.uid] && !state.opened_group.includes(group.uid) && !state.is_group_opened) {
        state.is_group_opened = true;
        state.opened_group.push(group.uid);
      }
    });
  });
}
setOpenedGroups();

// Watcher
watch(container, (curr, prev) => {
  if (curr?.uid !== prev?.uid)
    state.is_group_opened = false;
  setOpenedGroups();
});

watch(active_projects, () => {
  if (!state.is_group_opened)
    setOpenedGroups();
}, { deep: true });
</script>

<template>
  <div v-if="container" :class="{ '!pointer-events-none': terra_store.is_loading || terra_store.projects_request_status.show }">
    <div class="flex items-center justify-between mb-3 px-4">
      <div class="text-md font-semibold">
        {{ $t('Layers') }}
      </div>

      <HawkButton v-if="terra_store_name === 'terra' && terra_store.check_permission('create_groups')" icon type="light" color="gray" @click="addNewGroup()">
        <IconHawkPlus v-tippy="$t('Add New Group')" />
      </HawkButton>
    </div>
    <div class="px-4 pb-4">
      <div
        v-for="(group, gi) in container.groups"
        :key="group.uid"
        class="relative"
      >
        <div class="group flex items-center justify-between h-8">
          <div class="flex items-center">
            <div>
              <HawkCheckbox :id="`group_checkbox${gi}_${side}`" :model-value="terra_store.active_group_map[group.uid]?.features_enabled" @input.stop="toggleGroup(group, 'both')" />
            </div>
            <div class="text-sm text-gray-700 font-medium cursor-pointer" @click.stop="openGroup(gi)">
              <template v-if="group.properties && group.properties.date">
                <HawkText :content="$date(group.properties.date, 'DATE_MED')" :length="22" />
              </template>
              <template v-else>
                <HawkText :content="group.name" :length="22" />
              </template>
            </div>
          </div>
          <div class="flex items-center justify-end">
            <HawkMenu
              v-if="terra_store_name === 'terra' && terra_store.check_permission('modify_groups')"
              :class="[state.group_menu_opened === group.uid ? '!visible' : 'invisible group-hover:visible']"
              :items="getGroupMenuItems(group)"
              :has_bordered_trigger="false"
              additional_trigger_classes="!ring-0 p-0 w-7.5 h-5"
              additional_dropdown_classes="-ml-[190px] !w-[208px]"
              position="fixed"
              @open="state.group_menu_opened = group.uid"
              @close="state.group_menu_opened = ''"
            />
            <div
              class="cursor-pointer"
              @click.stop="openGroup(gi)"
            >
              <IconHawkChevronUp v-if="state.opened_group.includes(gi)" class="w-5 h-5" />
              <IconHawkChevronDown v-else class="w-5 h-5" />
            </div>
          </div>
        </div>
        <div v-show="state.opened_group.includes(gi)">
          <ProjectsList :group="group" :terra_store_name="terra_store_name" :side="side" @groupOrProjectToggled="$emit('groupOrProjectToggled')" />
        </div>
      </div>
    </div>
  </div>
</template>
