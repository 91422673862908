<script setup>
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import SelectBlockColor from '~/forms/atoms/select-block-color.vue';

const props = defineProps({
  is_edit: {
    type: Boolean,
    default: false,
  },
  form_values: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(['close', 'save']);

const dms_settings_store = useDMSSettingsStore();

const form = ref({});

async function onConfirmHandler() {
  let data = {};
  if (props?.form_values?.uid)
    data = await dms_settings_store.update_document_status(props.form_values.uid, form.value);
  else
    data = await dms_settings_store.create_document_statuses(form.value);

  emit('save', data);
  emit('close');
}

function formMounted(el$) {
  if (props?.form_values)
    el$.load(props.form_values);
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      v-model="form"
      size="sm"
      class="w-full"
      :display-errors="false"
      :endpoint="onConfirmHandler"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('close')">
          <template #title>
            {{ is_edit ? $t('Edit status') : $t('New status') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <TextElement
            :placeholder="$t('Enter status name')"
            rules="required"
            name="name"
            :label="$t('Name')"
          />
          <SelectBlockColor :color="form_values?.color" wrapper_class="mb-5 mt-4" />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <div class="flex">
                <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits name="submit_status" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
