<script setup>
import { useModal } from 'vue-final-modal';
import { useTagsStore } from '~/common/stores/tags.store';
import CreateTag from '~/common/components/molecules/create-tag.vue';

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  tags_removable: {
    type: Boolean,
    default: true,
  },
  setter_function: {
    type: Function,
    default: () => {},
  },
  create_inline: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  search_value: '',
  is_creating_tag: false,
});

const tags_store = useTagsStore();

const { open: openTagModal, close: closeNormalModal } = useModal({
  component: CreateTag,
  attrs: {
    onClose() {
      closeNormalModal();
    },
    async onCreated(e) {
      await props.setter_function(e);
    },
  },
});

const computed_options = computed(() => {
  const options = { ...props.options };
  if (props.create_inline && options.create)
    delete options.create;
  return options;
});

async function createTag() {
  state.is_creating_tag = true;
  const response = await tags_store.add_tag(state.search_value);
  await props.setter_function(response);
  state.is_creating_tag = false;
}
</script>

<template>
  <TagsElement
    v-bind="{
      'search': true,
      'track-by': 'name',
      'value-prop': 'uid',
      'label-prop': 'name',
      'autocomplete': 'off',
      'tags_removable': true,
      'close-on-select': false,
      'items': tags_store.tags,
      'loading': state.is_creating_tag,
      ...computed_options,
    }"
    @search-change="(query) => (state.search_value = query)"
  >
    <template #no-results>
      <p v-if="create_inline && state.search_value.trim()" class="hover:bg-gray-100 font-medium rounded-md -mt-1 mx-1 mb-1 py-1.5 px-1.5" @click="createTag">
        + {{ $t('Create new') }} "{{ state.search_value }}"
      </p>
    </template>
    <template #before-list>
      <p v-if="!create_inline && options.create" class="hover:bg-gray-100 font-medium rounded-md m-1 mb-0 py-2 px-1.5" @click="openTagModal">
        + {{ $t('Create new') }}
      </p>
    </template>
    <template #tag="{ option, handleTagRemove }">
      <slot name="tag" :option="option" :handle-tag-remove="handleTagRemove">
        <div class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-0.5 px-1.5 mr-1 mb-1">
          <p v-tippy="option.name.length > 15 ? option.name : ''" class="text-gray-700">
            {{ $filters.truncate(option.name, 15) }}
          </p>
          <div v-if="tags_removable" class="hover:bg-gray-50 rounded ml-2" @mousedown.prevent="handleTagRemove(option, $event)">
            <IconHawkX class="text-[17px] text-gray-400 hover:text-gray-600" />
          </div>
        </div>
      </slot>
    </template>
    <template #after>
      <slot name="after" />
    </template>
  </TagsElement>
</template>
