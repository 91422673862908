import { reactive } from 'vue';
import { useRoute } from 'vue-router';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';

// constants
import { TRANSMITTAL_PERMISSIONS } from '~/dms/constants';

export function useTransmittalPermissions() {
  const route = useRoute();
  const auth_store = useAuthStore();

  const asset_id = route.params.asset_id;

  // global permissions
  const global_permissions = reactive({
    create_transmittals: auth_store.check_permission(TRANSMITTAL_PERMISSIONS.CREATE_TRANSMITTALS, asset_id),
    configure_dms_settings: auth_store.check_permission(TRANSMITTAL_PERMISSIONS.CONFIGURE_DMS_SETTINGS, asset_id),
    view_transmittal: auth_store.check_permission(TRANSMITTAL_PERMISSIONS.VIEW_TRANSMITTAL, asset_id),
  });

  // UI level permissions check
  function checkTransmittalPermission({ permission = '', item = {} }) {
    switch (permission) {
      case 'view_transmittal':
        return global_permissions.view_transmittal;
      case 'create_transmittal':
        return global_permissions.create_transmittals;
      case 'setting_icon':
        return global_permissions.configure_dms_settings;
      case 'workflow_tab':
        return global_permissions.create_transmittals;
      case 'workflow_new_cta':
        return global_permissions.configure_dms_settings;
      case 'workflow_footer':
        return global_permissions.configure_dms_settings;
      case 'workflow_publish':
        return global_permissions.configure_dms_settings;
      case 'add_document_status':
        return global_permissions.configure_dms_settings;
      case 'modify_document_status':
        return global_permissions.configure_dms_settings;
      case 'create_dms_custom_fields':
        return global_permissions.configure_dms_settings;
      case 'modify_dms_custom_fields':
        return global_permissions.configure_dms_settings;
      case 'save_transmittal_schema':
        return global_permissions.configure_dms_settings;
      default:
        return false;
    }
  }

  return {
    checkTransmittalPermission,
  };
}
