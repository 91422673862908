<script setup>
import { useRoute, useRouter } from 'vue-router';

// other
import { useModal } from 'vue-final-modal';
import FormCompactView from '~/forms/components/form-compact-view.vue';

// composables

// stores
import { useFormsStore } from '~/forms/store/forms.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';

// components
import NewFormModal from '~/forms/components/new-form/new-form-modal.vue';

const props = defineProps({
  document: {
    type: Object,
    default: null,
  },
  store_key: {
    type: String,
    default: 'dms',
  },
});

const $date = inject('$date');

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();

const forms_store = useFormsStore(`forms-${props.store_key}_forms_store`);

const forms = computed(() => forms_store?.forms);
const can_create_form = computed(() => authStore.check_permission('use_forms', route?.params?.asset_id));
const state = reactive({
  is_loading: false,
});

const { open: openFormModal, close: closeFormModal } = useModal({
  component: NewFormModal,
  attrs: {
    task_data: {},
    onClose() {
      closeFormModal();
    },
    async on_submit(data) {
      try {
        const body = {};
        const { element, location } = props.document;
        body.forms = {
          add: [{
            ...data.forms.add[0],
            target_element: element,
            ...(route.params.asset_id ? { asset: route.params.asset_id } : {}),
            ...(props.options?.properties ? { properties: props.options.properties } : {}),
            ...(location ? { location } : {}),
          }],
        };
        await forms_store?.create_form({ body });
      }
      catch (err) {
        logger.error(err);
      }
      finally {
        closeFormModal();
      }
    },
  },
});
onMounted(async () => {
  forms_store.reset();
});

function openFormDetailHandler(uid) {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: uid, store_key: `forms-${props.store_key}_forms_store` })) } });
}
</script>

<template>
  <div v-show="forms?.length > 0">
    <hr class="my-4">
    <div class="flex justify-between items-center">
      <div class="text-gray-500 font-medium text-sm">
        {{ $t('Forms') }} <span v-if="forms?.length">({{ forms.length }})</span>
      </div>
      <HawkButton v-if="can_create_form" type="link" class="font-semibold text-sm" @click="openFormModal">
        <IconHawkPlus />{{ $t('New form') }}
      </HawkButton>
    </div>
    <div class="mt-2">
      <FormCompactView
        :id="`${store_key}_forms_store`"
        :options="{
          show_no_data: true,
          query: {
            all_access: true,
            element: document?.element,
            status: 'published',
            ...(route.params.asset_id ? { asset_uid: route.params.asset_id } : {}),
          },
        }"
      />
    </div>
  </div>
</template>
