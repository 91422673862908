<script setup>
import { computed, watch } from 'vue';
import { sortBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';
import { useTerraStore } from '~/terra/store/terra.store';
import GroupsList from '~/terra/components/layers/groups-list.vue';

const props = defineProps({
  terra_store_name: {
    type: String,
    default: 'terra',
  },
  side: {
    type: String,
  },
});

const emit = defineEmits(['containerChange']);

const $services = inject('$services');
const route = useRoute();

const common_store = useCommonStore();
const terra_store = useTerraStore(props.terra_store_name);

const asset_name = computed(() => common_store.active_asset?.name || 'NA');
const filtered_data = computed(() => {
  const list = common_store.assets_map?.[route.params.asset_id]?.terra_list || [];
  const sorted_list = sortBy(list, item => item.name);
  return sorted_list.map((f) => {
    return {
      label: f.name,
      uid: f.uid,
      on_click: () => {
        if (f.uid !== terra_store.container.uid) {
          emit('containerChange', f);
          terra_store.terra_track_events('Container switched');
        }
      },
    };
  });
});

async function getTerraData() {
  if (!common_store.assets_map?.[route.params.asset_id]?.terra_list) {
    const terraResponse = await $services.terra_view_service.getAll({
      query: {
        asset: route.params.asset_id,
      },
    });
    const terra = terraResponse.data;
    if (terra.length)
      common_store.set_terra_therm_list_in_assets({
        asset_id: route.params.asset_id,
        data: terra,
        key: 'terra_list',
      });
  }
}

watch(() => common_store.assets_map, (val) => {
  if (!val?.[route.params.asset_id]?.terra_list)
    getTerraData();
}, { immediate: true });
</script>

<template>
  <div v-if="terra_store.container" class="h-full scrollbar">
    <div class="px-4 pt-4">
      <div class="text-xs font-medium text-gray-500">
        {{ asset_name }}
      </div>
      <HawkMenu
        :items="filtered_data"
        :has_bordered_trigger="false"
        class="w-full"
        :active_item="terra_store.container.uid"
        additional_trigger_classes="!focus:ring-0 !active:ring-0 w-full"
        additional_item_classes="!w-[246px]"
      >
        <template #trigger>
          <div class="w-full py-1">
            <div class="flex justify-between items-center">
              <div class="text-xs font-medium">
                <HawkText :content="terra_store.container.name" length="32" />
              </div>
              <IconHawkChevronDown v-show="filtered_data.length" />
            </div>
          </div>
        </template>
      </HawkMenu>
    </div>
    <div class="border my-5 mx-4" />
    <GroupsList
      :terra_store_name="terra_store_name"
      :side="side"
      @groupOrProjectToggled="$emit('groupOrProjectToggled')"
    />
  </div>
</template>
