const type_validators = {
  alphabetic_text: /^[^0-9]+$/,
  number: /^\d+(\.\d+)?$/,
  email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  date: /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
  url: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
};

async function validate(value, column_config, column_data) {
  // Empty check
  if (column_config.required === true) {
    if (!value)
      return get_validation_object('is a required field');
  }

  // Type check
  if (!typeValidator(value, column_config.data_type))
    return get_validation_object('is of invalid format');

  // Unique check
  if (column_config.unique) {
    const has_duplicates = column_data.filter(item => `${item}` === `${value}`).length > 1;
    if (has_duplicates || column_config.unique?.in?.includes(value))
      return get_validation_object('cannot have duplicates');
  }

  // length check
  if (column_config.max_length && (`${value}`.length > column_config.max_length)) {
    return get_validation_object(`length should be less than ${column_config.max_length}`);
  }

  // Custom validator
  if (column_config.validator) {
    const response = await column_config.validator(value);
    if (response === false || response.is_valid === false)
      return get_validation_object(response?.message || 'is of invalid format');
  }
  return { is_valid: true, message: '' };
}

function typeValidator(val, format) {
  if (val && type_validators[format]) {
    const reg = type_validators[format];
    if (!reg.test(val))
      return false;
  }
  return true;
}

function get_validation_object(message) {
  return { is_valid: false, message };
}

export default validate;
