<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { inject } from 'vue';
import axios from 'axios';
import FormSubmissionHistory from '~/forms/components/form-detail/form-detail-submission-history.vue';
import FormSchedule from '~/forms/components/form-schedule.vue';

import Geofencing from '~/forms/atoms/geofencing.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useExportPDF } from '~/forms/composables/form-detail-composable';
import useEmitter from '~/common/composables/useEmitter';
import { FORM_PERMISSIONS } from '~/forms/constants';

const props = defineProps({
  form_detail_store: {
    type: Object,
    default: () => {},
  },
  form_name_ref: {
    type: Object,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  is_preview: {
    type: Boolean,
    default: false,
  },
  is_submitted: {
    type: Boolean,
    default: false,
  },
});

const common_store = useCommonStore();
const { copy } = useClipboard();
const emitter = useEmitter();
const is_exporting = ref(false);
const forms_store = props.form_detail_store.forms_list_store;
const { formsPDFExportHandler } = useExportPDF();
const { check_split, check_permission } = useAuthStore();
const route = useRoute();
const router = useRouter();
const $toast = inject('$toast');
const copied = ref(false);
const $t = inject('$t');
const template_uid = route.params?.template_uid || props.form_detail_store?.form_detail?.template?.uid;
// delete modal
const { open: openDeletePopup, close: closeDeletePopup } = useModal({
  component: HawkDeletePopup,
  attrs: {
    header: $t('Delete Form Instance'),
    content: `${$t('Are you sure you want to delete')}  ${props.form_detail_store?.form_detail?.name || ''}?`,
    onClose() {
      closeDeletePopup();
    },
    confirm: async () => {
      try {
        await forms_store.delete_form(props.form_detail_store.form_detail.uid);
        emitter.emit('form_deleted', { data: props.form_detail_store.form_detail });
        closeDeletePopup();
        router.push({ query: {} });
      }
      catch (err) {
        $toast({
          title: 'Something went wrong',
          text: 'Please try again',
          type: 'error',

        });
      }
    },
  },
});

// geofencing modal
function add_geofencing() {
  const { open, close } = useModal({
    component: Geofencing,
    attrs: {
      data: props.form_detail_store?.form_detail,
      disable_footer: true,
      onClose() {
        close();
      },
      async submit(data, form) {
        const form_detail = { ...props.form_detail_store.form_detail };
        if (!form_detail.geofencing_distance && +form.data.geofencing_distance)
          props.form_detail_store.forms_list_store.forms_track_events('Geofencing added', form_detail.uid, {}, ['associated_with']);
        if (form_detail.geofencing_distance && !+form.data.geofencing_distance)
          props.form_detail_store.forms_list_store.forms_track_events('Geofencing removed', form_detail.uid, {}, ['associated_with']);
        await props.form_detail_store.set_geofencing_distance(+form.data.geofencing_distance);
        close();
      },
      description: $t('Allow updates and submission to the form(on mobile) only if the user is within the given proximity of the form location.'),
    },
  });
  return { label: $t('Add Geofencing'), on_click: open };
}

// copy url handler
function copyTemplateURLHandler(e) {
  e.stopPropagation();
  const form_path = `${window.location.href}`;
  copy(form_path);
  copied.value = true;
  props.form_detail_store.forms_list_store.forms_track_events('Link copied', props.form_detail_store?.form_detail.uid, {}, ['associated_with']);
}

// submission history
function submission_history() {
  const { open, close } = useModal({
    component: FormSubmissionHistory,
    attrs: {
      form_detail: props.form_detail_store?.form_detail,
      submission: props.form_detail_store?.form_submissions,
      disable_footer: true,
      onClose() {
        close();
      },
      onTrackEvent(e) {
        props.form_detail_store.forms_list_store.forms_track_events(e, props.form_detail_store?.form_detail.uid);
      },
      async submit(form, data) {
        await props.form_detail_store.set_geofencing_distance(+data.data.geofencing_distance);
        close();
      },
      description: $t('Allow updates and submission to the form(on mobile) only if the user is within the given proximity of the form location.'),
    },
  });
  return {
    label: $t('History'),
    on_click: () => {
      open();
      props.form_detail_store.forms_list_store.forms_track_events('Viewed history', props.form_detail_store?.form_detail.uid);
    },
  };
}

const { open: openSchedulePopup, close: closeSchedulePopup, patchOptions: patchScheduleOptions } = useModal({
  component: FormSchedule,
});
function setSchedule() {
  patchScheduleOptions({
    attrs: {
      task_uid: props.form_detail_store?.form_detail.uid,
      schedule: props.form_detail_store?.form_detail.schedule ? { ...props.form_detail_store.form_detail.schedule, duration: props.form_detail_store?.form_detail.duration } : undefined,
      start_date: props.form_detail_store?.form_detail.start_date,
      onClose() {
        closeSchedulePopup();
      },
      async onUpdate(payload) {
        await props.form_detail_store.set_schedule(payload);
        props.form_detail_store.forms_list_store.forms_track_events(payload.schedule ? 'Scheduled' : 'Unscheduled', props.form_detail_store?.form_detail.uid, {}, ['associated_with']);
        closeSchedulePopup();
      },
    },
  });
  openSchedulePopup();
}

function renameForm() {
  props.form_name_ref?.editField?.();
}

function getFileName(response) {
  if (response.headers['content-disposition']) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(response.headers['content-disposition']);
    if (matches?.[1])
      return matches[1].replace(/['"]/g, '');
    else
      return `${props.form_detail_store?.form_detail?.name}.pdf`;
  }
  else { return `${props.form_detail_store?.form_detail?.name}.pdf`; }
}

async function customPDFExporter(url) {
  const response = await axios({
    method: 'post',
    url: `https://api.sensehawk.com/${url}`,
    responseType: 'blob',
    data: {
      formData: {
        form: props.form_detail_store?.form_detail,
        submission: props.form_detail_store?.form_submissions,
        field_values: props.form_detail_store?.field_values,
      },
      memberData: {
        users: common_store.users,
      },
    },
  });
  saveAs(response.data, getFileName(response));
}

function exportCTAHandler() {
  if (is_exporting.value)
    is_exporting.value = false;
  nextTick(() => {
    is_exporting.value = true;
  });
}

async function exportPdf() {
  if (route?.params?.template_uid === '_ip5PV1Kw')
    await customPDFExporter('extras/custom-reports/ymp-tssr');
  else if (route?.params?.template_uid === 'Z1yRse-sQ')
    await customPDFExporter('extras/custom-reports/ril-pfr');
  else
    await formsPDFExportHandler([props.form_detail_store?.form_detail ?? {}], template_uid, () => is_exporting.value = false, props.form_detail_store.forms_list_store);
}

function getArchiveOption() {
  if (!props.form_detail_store.form_detail.archived && props.form_detail_store.form_detail.can_modify)
    return [
      {
        label: $t('Archive'),
        on_click: () => {
          forms_store.archiveForms([props.form_detail_store.form_detail.uid], (data) => {
            const { archived, errors } = data.forms;

            if (!archived.length && errors.length)
              $toast({
                title: $t('Unable to archive'),
                type: 'error',
                position: 'bottom-right',
                text: `${$t('Form')} ${$t('could not be archived, please check submission status and block type')}`,
              });

            if (archived.length)
              $toast({
                title: `${$t('Form')} ${$t('archived')}`,
                type: 'success',
                position: 'bottom-right',
                text: $t('You will be able to view this form in the archived forms section.'),
              });
            router.back();
          },
          );
        },
      },
    ];

  if (props.form_detail_store.form_detail.archived)
    return [
      {
        label: $t('Unarchive'),
        on_click: () => {
          forms_store.unarchiveForms([props.form_detail_store.form_detail.uid], (data) => {
            const { unarchived, errors } = data.forms;

            if (!unarchived.length && errors.length)
              $toast({
                title: $t('Unable to unarchive'),
                type: 'error',
                position: 'bottom-right',
                text: `${$t('Form')} ${$t('could not be archived, please check submission status and block type')}`,
              });

            if (unarchived.length)
              $toast({
                title: `${$t('Form')} ${$t('unarchived')}`,
                type: 'success',
                position: 'bottom-right',
                text: `${$t('Form')} ${$t('has been successfully unarchived.')}`,
              });

            router.back();
          },
          );
        },
      },
    ];

  return [];
}

// dropdown items
const form_menulist = computed(() => {
  return [{ label: copied.value ? $t('Copied!') : $t('Copy Link'), on_click: copyTemplateURLHandler },
    ...(props.is_disabled || props.is_submitted ? [] : [{ label: $t('Rename'), on_click: renameForm }]),
    ...(props.is_disabled
      ? []
      : [{
          label: (props.form_detail_store?.form_detail?.schedule && props.form_detail_store?.form_detail.schedule.rrule !== null) ? $t('Update Schedule') : $t('Set Schedule'),
          on_click: setSchedule,
        }]
    ),
    ...(props.form_detail_store?.form_detail?.status?.submission_status !== 'open' ? [{ label: $t('Export PDF'), on_click: exportCTAHandler }] : []),
    ...(props.is_disabled ? [] : [add_geofencing()]),
    ...(props.form_detail_store?.form_submissions?.step_history?.length ? [submission_history()] : []),
    ...(props.form_detail_store?.form_detail?.can_delete && !props.is_preview ? [{ label: $t('Delete'), on_click: openDeletePopup }] : []),
    ...(check_permission(FORM_PERMISSIONS.V2_MODIFY_FORMS, route.params.asset_id)
      ? getArchiveOption()
      : []),
  ];
});
</script>

<template>
  <HawkExportToast v-if="is_exporting" :submit="exportPdf" @close="is_exporting = false" />
  <hawk-menu class="mx-2" :items="form_menulist" additional_trigger_classes="!ring-0 w-full" @close="copied = false">
    <template #trigger>
      <div class="flex cursor-pointer items-center rounded  hover:bg-gray-50">
        <hawk-button type="plain" :icon="true">
          <icon-hawk-dots-vertical />
        </hawk-button>
      </div>
    </template>
  </hawk-menu>
</template>
