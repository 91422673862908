<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const emit = defineEmits(['resetTab']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_task, active_schedule_data } = storeToRefs(project_management_store);
const { set_active_task_uid } = project_management_store;

const columns = computed(() => [
  {
    id: 'id',
    header: $t('ID'),
    accessorKey: 'id',
    size: 2,
  },
  {
    id: 'name',
    header: $t('Name'),
    accessorKey: 'name',
    size: 2,
  },
  {
    id: 'type',
    header: $t('Relation type'),
    accessorKey: 'type',
    size: 1,
  },
  {
    id: 'lag',
    header: $t('Lag'),
    accessorKey: 'lag',
    size: 1,
  },
  {
    id: 'status',
    header: $t('Status'),
    accessorKey: 'status',
    size: 2,
  },
]);

const link_type_map = {
  SS: $t('Start to start'),
  SF: $t('Start to finish'),
  FS: $t('Finish to start'),
  FF: $t('Finish to finish'),
};

const sections = computed(() => [
  ...getDependencies('target').filter(Boolean),
  ...getDependencies('source').filter(Boolean),
]);

function groupByFunction(data) {
  const group_by = 'group';
  let res = data.reduce((group, row) => {
    const { original } = row;
    group[original[group_by]] = group[original[group_by]] ?? {
      show: false,
      rows: [],
    };
    group[original[group_by]]?.rows.push(row);
    return group;
  }, {});

  res = {
    ...(res.target
      ? {
          predecessors: res.target,
        }
      : {}),
    ...(res.source
      ? {
          successors: res.source,
        }
      : {}),
  };

  return res;
}

function getDependencies(sourceOrTarget) {
  const deps = active_schedule_data.value.links.filter(
    link => link[sourceOrTarget] === active_task.value?.id,
  );

  return deps.map((dep) => {
    const task = gantt.getTask(
      dep[sourceOrTarget === 'source' ? 'target' : 'source'],
    );

    if (!task)
      return null;

    const lag = Math.ceil(dep.lag);

    return {
      id: task.id,
      name: task.name,
      type: link_type_map[dep.type],
      lag: lag === 1 ? `1 ${$t('day')}` : `${lag} ${$t('days')}`,
      status: task.status,
      group: sourceOrTarget,
    };
  });
}

function handleClick(task) {
  gantt.eachParent((t) => {
    gantt.open(t.id);
  }, task.id);
  set_active_task_uid(gantt.getTask(task.id).uid, 0);
  emit('resetTab');
}
</script>

<template>
  <HawkTable
    is_gapless
    :columns="columns"
    :resize_columns="false"
    :group_by_function="groupByFunction"
    :show_menu_header="false"
    :data="sections"
    :table_header_sticky="false"
    @rowClicked="handleClick"
  >
    <template #id="data">
      <div class="text-sm font-medium text-gray-900">
        {{ data.data.row.original.id }}
      </div>
    </template>
    <template #name="data">
      <div class="text-sm font-regular text-gray-600">
        {{ data.data.row.original.name }}
      </div>
    </template>
    <template #type="data">
      <div class="text-sm font-regular text-gray-600">
        {{ data.data.row.original.type }}
      </div>
    </template>
    <template #lag="data">
      <div class="text-sm font-regular text-gray-600">
        {{ data.data.row.original.lag }}
      </div>
    </template>
    <template #status="data">
      <div class="text-sm font-regular text-gray-600">
        {{ data.data.row.original.status }}
      </div>
    </template>
    <template #group-label="{ data }">
      <div class="text-sm font-semibold">
        <span class="capitalize mr-1">{{ data?.value }}</span> ({{ data?.count }} {{ data?.count === 1 ? $t('activity') : $t('activities') }})
      </div>
    </template>
  </HawkTable>
</template>
