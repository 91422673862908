<script setup>
import { useRoute } from 'vue-router';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store.js';
import FormTemplateListTable from '~/forms/components/form-templates-list-table.vue';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
});

const $t = inject('$t');

const route = useRoute();
const form_templates_store = useFormTemplatesStore();
const query = { all_access: true, metrics: true };
const loading = ref(false);
const published_form = computed(() => form_templates_store.form_templates.filter(form => form.status === 'published'));
const draft_form = computed(() => form_templates_store.form_templates.filter(form => form.status === 'draft'));
const public_form = computed(() => form_templates_store.form_templates.filter(form => form.public));
const tabs = computed(() => [{
  uid: 'Published',
  label: $t('Published'),
  to: { params: { asset_id: route.params.asset_id }, query: { active: 'published' } },
  disabled: false,
  count: published_form.value.length,
}, {
  uid: 'Drafts',
  label: $t('Drafts'),
  to: { params: { asset_id: route.params.asset_id }, query: { active: 'draft' } },
  disabled: false,
  count: draft_form.value.length,
},
]);

const active_tab = computed(() => tabs.value.find(tab => route?.query?.active ? tab.to.query.active === route?.query?.active : tabs.value[0])?.uid);
const present_form = computed(() =>{
  const draft_value = active_tab.value === 'Drafts' ? draft_form.value : public_form.value;
  return active_tab.value === 'Published' ? published_form.value : draft_value;
});
async function getData(data) {
  loading.value = data;
  await form_templates_store.set_form_templates({ query });
  loading.value = false;
}
onMounted(async () => {
  loading.value = true;
  await getData(!form_templates_store?.form_templates?.length);
  loading.value = false;
});
watch(() => props.asset_id, () => {
  getData(true);
});
</script>

<template>
  <div>
    <div class="my-5">
      <hawk-tabs :tabs="tabs" :active_item="active_tab" />
    </div>
    <FormTemplateListTable :key="active_tab" :forms="present_form" :is_loading="loading" />
  </div>
</template>
