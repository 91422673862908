<script setup>
import { debounce } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useDashboardCustomStore } from '~/dashboard/store/dashboard-custom.store.js';

const emit = defineEmits(['input']);

const dashboard_custom_store = useDashboardCustomStore();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const {
  custom_configuration,
} = storeToRefs(dashboard_custom_store);

const onInput = debounce(async (event) => {
  emit('input', event);
}, 200);
</script>

<template>
  <HawkWysiwygEditorComponent
    key="custom-widget-rich-text"
    :plugins="['tables', 'images']"
    :initial_content="custom_configuration?.rich_text || ''"
    :resize_config="{ quality: 1, maxWidth: 1280, maxHeight: 800 }"
    :menu_enabled="true"
    class="!h-auto border border-solid border-gray-300 rounded-lg pt-1"
    @input="onInput"
  />
</template>
