<script setup>
import { filter, find, get, isEmpty, keyBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import DocumentFolderSelectPopup from '~/dms/components/documents/widgets/document-folder-select-popup.vue';
import EmailInput from '~/forms/components/form-workflows/sidebar/email-block/email-workflow-input.vue';
import FormWorkflowSchemaInput from '~/forms/atoms/form-schema-input.vue';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});

const form_workflow_store = useFormWorkflowStore();
const form_template_detail_store = useFormTemplateDetailStore();
const authStore = useAuthStore();
const dms_settings_store = useDMSSettingsStore();
const { $t, $services, common_store, route } = useCommonImports();

const form_data = ref({});
const folder = ref(null);
const show_loader = ref(false);
const is_fetching_dms_data = ref(false);
const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const { open: openDestinationPopup, close: closeDestinationPopup } = useModal({
  component: DocumentFolderSelectPopup,
  attrs: {
    onClose() {
      closeDestinationPopup();
    },
    onSave(val) {
      folder.value = val.folder;
      form_data.value.upload_path = val.value;
      closeDestinationPopup();
    },
  },
});

// INFO: DMS custom field types can be found in custom-field-composable.js -> custom_fields_collection
const dms_to_form_field_map = {
  text: {
    key_path: 'properties.type',
    form_field_types: ['short_text'],
  },
  number: {
    key_path: 'type',
    form_field_types: ['number'],
  },
  members: {
    key_path: 'type',
    form_field_types: ['member'],
  },
  date: {
    key_path: 'config.type',
    form_field_types: ['date', 'datetime'],
  },
  dropdown: {
    key_path: 'properties.type',
    form_field_types: ['dropdown'],
  },
  yes_no_na: {
    key_path: 'properties.type',
    form_field_types: ['yes-no'],
  },
  single_select: {
    key_path: 'properties.type',
    form_field_types: ['short_text', 'dropdown'],
  },
  multi_select: {
    key_path: 'properties.type',
    form_field_types: ['short_text', 'dropdown', 'checkbox'],
  },
};

const field_configs = {
  text: { create: true, rules: ['required'] },
  number: { create: true, rules: ['required'] },
  members: {
    create: false,
    rules: ['required'],
    placeholder: $t('Enter email'),
  },
  date: { create: true, rules: ['required'], placeholder: 'DD-MM-YYYY' },
  single_select: { create: true, rules: ['required'] },
  multi_select: { create: true, rules: ['required'] },
  document_status: { create: false, rules: ['required'] },
  yes_no_na: { create: false, rules: ['required'] },
};

const field_options = form_workflow_store.get_fields(props.step_number);
const field_slug_map = keyBy(field_options, 'slug');

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        name: 'Upload Files',
        description: 'Sync attachments from form fields to documents',
        upload_type: 'upload_to_folder',
        upload_path: {},
        folder_schema: null,
        version_schema: null,
        upload_field: [],
      };
});

const get_attachment_fields = computed(() => {
  const fields = form_workflow_store.get_fields(props.step_number, ['attachment'], ['default', 'tablev2']).reduce((acc, field) => {
    if (field.properties?.type !== 'signature') {
      acc[field.slug] = `${field.step.name} > ${field.section_name} > ${field.name}`;
    }
    return acc;
  }, {});
  const document_blocks = form_workflow_store.get_document_generate_blocks().reduce((acc, block) => {
    acc[block.slug] = `${form_template_detail_store?.form_template_detail?.name} > ${block.name}`;
    return acc;
  }, {});
  return [
    { label: 'Fields', items: fields },
    { label: 'Document Generate Blocks', items: document_blocks },
  ];
});

const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => field.active));
const custom_field_map = computed(() => keyBy(custom_fields.value, 'uid'));
const document_statuses = computed(() => dms_settings_store.document_status.filter(document_status => document_status.active));
const dms_metadata_property_options = computed(() => {
  const dms_metatdata_properties = [
    {
      label: 'Document Status',
      uid: 'document_status',
    },
    ...custom_fields.value,
  ];
  const selected_properties = form_data.value.extra_properties.map(extra_property => extra_property.property);

  return dms_metatdata_properties.map(dms_metatdata_property => ({ ...dms_metatdata_property, disabled: selected_properties.includes(dms_metatdata_property.uid) }));
});
const users_data = computed(() => {
  const asset_id = route.params.asset_id;
  const users_list = filter(common_store.scope_users(asset_id), item => item.status !== 'deprovisioned');
  const members = users_list?.map((user) => {
    return {
      type: 'member',
      name: user.first_name ? `${user.first_name} ${user.last_name || ''}` : user.email,
      value: user.uid,
    };
  });

  return [
    {
      label: 'Members',
      items: members?.value ? members.value : members,
    },
  ];
});
const get_email_by_slug = computed(() => {
  const fields_by_slug = {};

  getDmsMappedFormFields('members').forEach(
    field => (fields_by_slug[field.slug] = field),
  );
  return fields_by_slug;
});

function getFilePath(value) {
  if (folder.value && value) {
    let path = 'File > ';
    folder.value.ancestors.forEach((val) => {
      path += `${val.name} > `;
    });
    path += `${folder.value.name}`;
    return path;
  }
  return '';
}

function addProperty() {
  form_data.value.extra_properties.push({ property: '', value: null });
}

function getAddedOptions(property) {
  const added_options = [];
  const extra_property_obj = find(form_data.value.extra_properties, ['property', property]);

  if (!extra_property_obj.value && extra_property_obj.type !== 'document_status')
    return added_options;

  if (Array.isArray(extra_property_obj.value)) {
    const custom_options = extra_property_obj.value.filter(extra_property => !field_slug_map[extra_property] && !custom_field_map.value[property].items.some(item => item.label === extra_property));

    return custom_options.map(custom_option => ({ label: custom_option, value: custom_option }));
  }
  else {
    const is_custom_option = !field_slug_map[extra_property_obj.value] && !custom_field_map.value[property].items.some(item => item.label === extra_property_obj.value);
    if (is_custom_option)
      added_options.push({ label: extra_property_obj.value, value: extra_property_obj.value });
    return added_options;
  }
}

function onMetaPropertySelect(property, index) {
  form_data.value.extra_properties[index].type = custom_field_map.value[form_data.value.extra_properties[index].property]?.type || form_data.value.extra_properties[index].property;
  form_data.value.extra_properties[index].value = null;
}

function getDmsMappedFormFields(dms_field_type) {
  return field_options.filter((form_field) => {
    const valid_field_types = dms_to_form_field_map[dms_field_type]?.form_field_types;
    if (!valid_field_types)
      return false;

    return valid_field_types.includes(get(form_field, dms_to_form_field_map[dms_field_type].key_path));
  });
}

function getValidFieldOptions(property) {
  if (!property || property === 'document_status' || isEmpty(custom_field_map.value))
    return [];

  const dms_field_type = custom_field_map.value[property].type;

  return getDmsMappedFormFields(dms_field_type).map(field_option => ({ [dms_field_type === 'members' ? 'name' : 'label']: field_option.name, value: field_option.slug, type: 'field' }));
}

function getValueOptions(property) {
  if (!property || isEmpty(custom_field_map.value))
    return [];
  if (property === 'document_status')
    return document_statuses.value.map(document_status => ({ label: document_status.name, value: document_status.uid }));

  const dms_lable_options = custom_field_map.value[property].items.map(item => ({ label: item.label, value: item.label }));
  if (custom_field_map.value[property].type === 'yes_no_na')
    dms_lable_options.push(...(['Yes', 'No', 'NA'].map(option_string => ({ label: option_string, value: option_string }))));

  return [
    ...getValidFieldOptions(property),
    ...(['single_select', 'multi_select', 'yes_no_na'].includes(custom_field_map.value[property].type) ? dms_lable_options : []),
    ...(['text', 'number', 'date', 'single_select', 'multi_select'].includes(custom_field_map.value[property].type) ? getAddedOptions(property) : []),
  ];
}

async function save() {
  const data = {
    ...default_data.value,
    ...form_data.value,
  };
  if (data.upload_type !== 'upload_to_folder') {
    data.upload_field = data.upload_field_one;
    delete data.upload_field_one;
  }
  const payload = {
    ...data,
    upload_path: form_data.value.upload_path ? [form_data.value.upload_path] : [],
    folder_schema: form_data.value.folder_schema || '',
    version_schema: form_data.value.version_schema || '',
  };
  if (props.submit_function)
    await props.submit_function(payload);
}

function init() {
  form_data.value = default_data.value;
  if (form_data.value.upload_type !== 'upload_to_folder')
    form_data.value.upload_field_one = default_data.value.upload_field;
  if (form_data.value.upload_path.length)
    form_data.value.upload_path = form_data.value.upload_path[0];
  else
    form_data.value.upload_path = null;
}
init();

onMounted(async () => {
  show_loader.value = true;
  if (form_data.value.upload_path) {
    const { data } = await $services.documents.getAll(
      {
        query: {
          uid: form_data.value.upload_path.uid,
        },
      },
    );
    folder.value = data?.details[0];
  }
  show_loader.value = false;

  is_fetching_dms_data.value = true;
  const p1 = dms_settings_store.fetch_all_statuses();
  const p2 = dms_settings_store.set_custom_fields({
    query: {
      allowed_in: 'documents',
    },
  });

  await Promise.all([p1, p2]);
  is_fetching_dms_data.value = false;
});
</script>

<template>
  <Vueform
    v-model="form_data"
    :sync="true" size="sm"
    :display-errors="false"
    :endpoint="save"
    :columns="{
      lg: {
        container: 12,
        label: 3,
        wrapper: 12,
      },
    }"
    :messages="{ required: $t('This field is required') }"
    @change="form_workflow_store.is_sidebar_form_dirty = true"
  >
    <div class="col-span-12">
      <div class="flex justify-between items-start mb-10">
        <div class="w-80">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Upload Files Block') }}
          </div>
          <div class="text-xs text-gray-600">
            {{ $t('Sync attachments from form fields to a selected folder/schema or update a version.') }}
          </div>
        </div>
        <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
          <IconHawkXClose class="text-gray-500" />
        </HawkButton>
      </div>
      <div :class="{ 'pointer-events-none': is_disabled }">
        <TextElement name="name" :label="$t('Name')" class="mb-5" rules="required" :readonly="is_disabled" />
        <TextareaElement name="description" :label="$t('Description')" class="mb-5" :readonly="is_disabled" />
        <hr class="mb-4">
        <RadiogroupElement
          name="upload_type"
          :items="[
            { value: 'upload_to_folder', label: $t('Upload to a folder') },
            { value: 'update_version', label: $t('Update version') },
          ]"
          class="mb-5"
          :add-classes="{
            RadiogroupRadio: {
              container: 'mr-5 flex',
              text: 'mt-[1px]',
            },
          }"
          :remove-class="{
            wrapper: ['flex-col'],
          }"
        />
        <div v-if="form_data.upload_type === 'upload_to_folder'">
          <TagsElement
            name="upload_field"
            :label="$t('Source')"
            :placeholder="$t('Select')"
            class="mb-4"
            :can-clear="false"
            :close-on-select="false"
            :items="get_attachment_fields"
            :groups="true"
            :native="false"
            rules="required"
          >
            <template #tag="{ option, handleTagRemove }">
              <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
                <HawkText :content="option.label" :length="30" />
                <div class="hover:bg-gray-100 rounded">
                  <IconHawkX @mousedown.prevent="handleTagRemove(option, $event)" />
                </div>
              </div>
            </template>

            <template #after>
              <div class="text-xs text-gray-500 mt-1">
                {{ $t('Choose one/more attachment fields to be synced') }}
              </div>
            </template>
          </TagsElement>
          <SelectElement
            :key="form_data.upload_type.length"
            name="upload_path"
            :label="$t('Destination')"
            :placeholder="$t('Select')"
            :native="false"
            :object="true"
            value-prop="uid"
            :items="form_data.upload_path ? [form_data.upload_path] : []" class="mb-5" :can-clear="true"
            :loading="show_loader"
            @open="openDestinationPopup()"
            @clear="form_data.upload_path = null"
          >
            <template #single-label="{ value }">
              <div class="p-2 w-[258px]">
                <HawkText :content="getFilePath(value)" :length="30" />
              </div>
            </template>
            <template #after>
              <div class="text-xs text-gray-500 mt-1">
                {{ $t('Choose the folder to which the attachments to be uploaded') }}
              </div>
            </template>
          </SelectElement>
          <FormWorkflowSchemaInput
            name="folder_schema"
            :label="$t('Schema')"
            :info="$t('The modifications will affect forms that have not yet reached this stage and forms created thereafter.')"
            :description="$t('Upload attachments to a new/existing sub folder matching the schema.')"
            :disabled="is_disabled"
            :editor_options="{
              placeholder_text: `${$t('Schema')}`,
            }"
            :step_number="step_number"
          />
        </div>
        <div v-else>
          <MultiselectElement
            name="upload_field_one"
            :label="$t('Source')"
            :placeholder="$t('Select')"
            class="mb-4"
            :items="get_attachment_fields"
            :groups="true"
            :hide-selected="true"
            :native="false"
            rules="required"
            @change="($event) => (form_data.upload_field_one = $event.slice(-1))"
          >
            <template #multiple-label="{ values }">
              <div class="px-1 py-2 w-[258px]">
                <HawkText :content="values[0].label" length="34" />
              </div>
            </template>
            <template #after>
              <div class="text-xs text-gray-500 mt-1">
                {{ $t('Choose an attachment fields that should be synced') }}
              </div>
            </template>
          </MultiselectElement>
          <FormWorkflowSchemaInput
            name="version_schema"
            :label="$t('Schema')"
            :description="$t('Define schema for the version name')"
            :disabled="is_disabled"
            :editor_options="{
              placeholder_text: `${$t('Version Schema')}`,
            }"
            :step_number="step_number"
          />

          <div class="text-sm text-gray-700 mb-5">
            <span class="text-sm font-semibold">{{ $t("Note") }} :</span> {{ $t('This will only execute for the forms that are created for a file.') }}
          </div>
        </div>
      </div>
      <hr class="mb-4">
      <ToggleElement
        :add-classes="{
          ToggleElement: {
            wrapper: ['justify-end'],
          },
        }"
        :columns="{
          lg: {
            container: 12,
            label: 6,
            wrapper: 12,
          },
        }"
        name="has_metadata" label="Update file metadata"
        :disabled="is_disabled"
      />
      <div class="w-3/4 text-gray-700 text-xs mt-1 mb-4">
        {{ $t('Update document meta data by mapping the form fields') }}
      </div>

      <!-- Conditional metadata -->
      <template v-if="form_data?.has_metadata">
        <div class="grid grid-cols-12 text-sm font-semibold text-gray-700 mb-2">
          <div class="col-span-6">
            {{ $t('Property') }}
          </div>
          <div class="col-span-5">
            {{ $t('Value') }}
          </div>
        </div>
        <ListElement
          :class="{ 'pointer-events-none': is_disabled }"
          name="extra_properties"
          :controls="{ add: false, remove: false, sort: false }"
          rules="required"
        >
          <template #default="{ index }">
            <ObjectElement
              :name="index"
            >
              <div class="col-span-12">
                <div class="grid grid-cols-12 mb-3">
                  <div class="col-span-6 mr-4">
                    <SelectElement
                      name="property"
                      :items="dms_metadata_property_options"
                      label-prop="label"
                      value-prop="uid"
                      :can-clear="true"
                      :native="false"
                      :create="false"
                      :search="true"
                      :attrs="{ autofocus: true }"
                      :append-new-option="false"
                      rules="required"
                      :loading="is_fetching_dms_data"
                      :readonly="is_disabled"
                      @select="(property) => onMetaPropertySelect(property, index)"
                    >
                      <template #single-label="{ value }">
                        <div class="flex items-center h-full absolute left-0 top-0 bg-transparent leading-snug pl-3">
                          <HawkText :content="value.label" :length="14" />
                        </div>
                      </template>
                      <template #option="{ option }">
                        {{ option.label }}
                      </template>
                    </SelectElement>
                  </div>
                  <div class="col-span-5 mr-4">
                    <TagsElement
                      v-if="form_data.extra_properties[index].type === 'multi_select'"
                      v-bind="field_configs[form_data.extra_properties[index].type] || {}"
                      name="value"
                      :items="getValueOptions(form_data.extra_properties[index].property)"
                      :can-clear="true"
                      :hide-selected="false"
                      :close-on-select="false"
                      :native="false"
                      :append-new-option="false"
                      :loading="is_fetching_dms_data"
                      :readonly="is_disabled"
                    >
                      <template #tag="{ option, handleTagRemove }">
                        <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
                          <HawkText :content="option.label" :length="8" />
                          <div class="hover:bg-gray-100 rounded">
                            <IconHawkX class="text-gray-400 h-4.5 w-4.5" @mousedown.prevent="handleTagRemove(option, $event)" />
                          </div>
                        </div>
                      </template>
                    </TagsElement>
                    <EmailInput
                      v-else-if="form_data.extra_properties[index].type === 'members'"
                      v-bind="field_configs[form_data.extra_properties[index].type] || {}"
                      name="value"
                      :items="[
                        ...users_data,
                        {
                          label: 'Custom Fields',
                          items: getValueOptions(form_data.extra_properties[index].property),
                        },
                      ]"
                      :name_truncate_length="8"
                      :get_email_by_slug="get_email_by_slug"
                      :readonly="is_disabled"
                    />
                    <SelectElement
                      v-else
                      v-bind="field_configs[form_data.extra_properties[index].type] || {}"
                      name="value"
                      :items="getValueOptions(form_data.extra_properties[index].property)"
                      :can-clear="false"
                      :native="false"
                      :append-new-option="false"
                      :loading="is_fetching_dms_data"
                      :readonly="is_disabled"
                    >
                      <template #single-label="{ value }">
                        <div class="flex items-center h-full absolute left-0 top-0 bg-transparent leading-snug pl-3">
                          <HawkText :content="value.label" :length="14" />
                        </div>
                      </template>
                    </SelectElement>
                  </div>
                  <div
                    v-if="!is_disabled"
                    class="cursor-pointer mt-1.5"
                    @click="form_data.extra_properties.splice(index, 1)"
                  >
                    <IconHawkTrashOne class="text-gray-600 w-4.5 h-4.5" />
                  </div>
                </div>
              </div>
            </ObjectElement>
          </template>
        </ListElement>
        <HawkButton
          class="mt-2 mb-5" type="link" :disabled="is_disabled"
          @click="addProperty"
        >
          <IconHawkPlus
            class="text-primary-700 w-5 h-4.75"
          />
          <span>
            {{ $t('Add Property') }}
          </span>
        </HawkButton>
      </template>
      <hr class="mb-4">
      <div v-if="!is_disabled" class="flex justify-end">
        <ButtonElement
          :button-label="$t('Cancel')"
          class="mr-3"
          :secondary="true"
          @click="$emit('close')"
        />
        <ButtonElement
          :button-label="$t('Save')"
          :submits="true"
        />
      </div>
    </div>
  </Vueform>
</template>
