<script setup>
defineProps({
  steps: {
    type: Array,
    default: () => [],
  },
  on_save: {
    type: Function,
  },
});

const form = ref({
  source_block: '',
});
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="form" sync size="sm" :display-errors="false" :columns="{
        lg: {
          container: 12,
          label: 5,
          wrapper: 12,
        },
      }" :endpoint="on_save"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="$emit('close')">
          <template #title>
            {{ $t('Select block to clone') }}
          </template>
        </HawkModalHeader>
        <hawk-modal-content class="h-[150px]">
          <SelectElement
            name="source_block"
            rules="required"
            label-prop="name"
            value-prop="uid"
            track-by="name"
            search
            :items="steps"
            :native="false"
            :label="$t('Select block')"
            :placeholder="$t('Select block')"
            :description="$t('All sections/fields will be duplicated to the new block')"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Clone') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
