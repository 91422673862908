<script setup>
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import TaskTemplateDropdown from '~/tasks/components/atoms/task-template-dropdown.vue';

const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  asset_id: {
    type: String,
  },
  fields: {
    type: Array,
    default: () => ['Status', 'Priority', 'Category', 'Tags', 'Template', 'Assignees', 'Start Date', 'Due Date', 'Created By', 'Created At', 'Archived', 'Progress'],
  },
  filter_type: {
    type: String,
    default: '',
  },
  filters: {
    type: Object,
  },
  extra_query: {
    type: Object,
    default: () => {},
  },
  modal_options: {
    type: Object,
    default: () => ({}),
  },
  therm_options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close', 'filter']);

const route = useRoute();

const form$ = ref(null);
const loading = ref(false);
const min_temperature$ = ref(null);
const max_temperature$ = ref(null);
const temperature_slider$ = ref(null);
const min_temperature = ref(0);
const max_temperature = ref(100);
const auth_store = useAuthStore();

function onFormMounted(el$) {
  if (props.task_store?.filters_count)
    el$.load(props.task_store?.filters, true);
  if (['terra', 'therm'].includes(props.filter_type) && props.filters)
    el$.load(props.filters, true);
};

function formatLoadedData(data) {
  const load_data = {};
  const user_id = auth_store?.logged_in_user_details?.user_id;

  Object.keys(data).forEach((key) => {
    if (key === 'assignees') {
      const assignees_option = data[key][0] === user_id ? 'Assigned to me' : 'Others';
      load_data.assignees_group = { [key]: data[key], assignees_option };
    }
    else if (['start_date_start', 'start_date_end', 'due_date_start', 'due_date_end', 'created_at_start', 'created_at_end'].includes(key)) {
      const date_key = key.replace(/_(start|end)$/, '');
      load_data[date_key] = load_data[date_key] || [];
      const dateArray = load_data[date_key];
      const dateValue = new Date(data[key]);
      key.endsWith('start') ? dateArray.unshift(dateValue) : dateArray.push(dateValue);
    }
    else if (key === 'progress_start' || key === 'progress_end') {
      load_data.progress = load_data.progress || [0, 100];
      load_data.progress[key === 'progress_start' ? 0 : 1] = data[key] * 100;
    }
    else {
      load_data[key] = data[key];
    }
  });

  // Setting default progress for SliderElement
  load_data.progress = load_data.progress || [0, 100];

  if (props.filter_type === 'therm' && !load_data.temperature_difference) {
    const default_temperature_range = [0, 100];
    load_data.temperature_difference = default_temperature_range;
    setTimeout(() => handleTemperatureSliderChange(default_temperature_range), 100);
  }

  return load_data;
}

function progressFormat(value) {
  return `${value}%`;
}

function generate_payload() {
  const payload = {};
  const form_data = form$.value.data;
  const user_id = auth_store?.logged_in_user_details?.user_id;

  const handleAssignees = () => {
    const assigneesGroup = form_data.assignees_group;
    if (assigneesGroup.assignees_option === 'Assigned to me')
      payload.assignees = [user_id];

    else if (assigneesGroup.assignees_option === 'Others' && assigneesGroup.assignees.length)
      payload.assignees = assigneesGroup.assignees.map(assignee => assignee.uid);
  };

  const handleOwner = () => {
    const ownerData = form_data.owner;
    if (ownerData?.length)
      payload.owner = ownerData.map(user => user.uid);
  };

  const handleDate = (key, startKey, endKey) => {
    const dateArray = form_data[key];
    if (dateArray?.length > 0) {
      payload[startKey] = dayjs(dateArray[0]).toISOString();
      payload[endKey] = dayjs(dateArray[1]).toISOString();
    }
  };

  const handleProgress = () => {
    const valueArray = form_data.progress;
    if (!(valueArray[0] === 0 && valueArray[1] === 100)) {
      payload.progress_start = valueArray[0] / 100;
      payload.progress_end = valueArray[1] / 100;
    }
  };

  const handleTemperature = () => {
    const valueArray = form_data.temperature;
    if (!(valueArray[0] === 0 && valueArray[1] === 100)) {
      payload.temperature_difference[0] = valueArray[0] / 100;
      payload.temperature_difference[1] = valueArray[1] / 100;
    }
  };

  Object.keys(form_data).forEach((key) => {
    switch (key) {
      case 'assignees_group':
        handleAssignees();
        break;

      case 'owner':
        handleOwner();
        break;

      case 'start_date':
        handleDate('start_date', 'start_date_start', 'start_date_end');
        break;

      case 'due_date':
        handleDate('due_date', 'due_date_start', 'due_date_end');
        break;

      case 'created_at':
        handleDate('created_at', 'created_at_start', 'created_at_end');
        break;

      case 'progress':
        handleProgress();
        break;

      case 'temperature':
        handleTemperature();
        break;

      default:
        if (key !== 'undefined' && form_data[key] && `${form_data[key]}`.length)
          payload[key] = form_data[key];
    }
  });

  return payload;
}

async function apply() {
  loading.value = true;

  const payload = generate_payload();
  const properties = Object.keys(payload).reduce((acc, curr) => {
    if (curr === 'start_date_start')
      acc.push('Start date');
    else if (curr === 'due_date_start')
      acc.push('Due date');
    else if (curr === 'created_at_start')
      acc.push('Created at');
    else if (curr === 'progress_start')
      acc.push('Progress');
    else if (!curr.includes('_end'))
      acc.push(curr.charAt(0).toUpperCase() + curr.slice(1));
    return acc;
  }, []);

  props.task_store.task_track_events('Filtered', { properties }, '', ['view']);
  if (['terra', 'therm'].includes(props.filter_type)) {
    emit('filter', payload);
    loading.value = false;
    return;
  }
  props.task_store.set_filters({ payload });
  emit('filter', payload);

  loading.value = false;
  emit('close');
}

function handleMinTempDiff(value) {
  value = Number(value);
  if (value) {
    min_temperature.value = Number(value) < 0 ? 0 : value;
    value < 0 && min_temperature$.value[0].load(0);
    temperature_slider$.value[0].load([value, max_temperature.value]);
  }
  else {
    min_temperature$.value[0].load(0);
  }
}

function handleMaxTempDiff(value) {
  value = Number(value);
  if (value) {
    value > 100 && max_temperature$.value[0].load(100);
    max_temperature.value = value > 100 ? 100 : value;
    temperature_slider$.value[0].load([min_temperature.value, value]);
  }
  else {
    max_temperature$.value[0].load(100);
  }
}

function handleTemperatureSliderChange(value) {
  min_temperature$.value[0].load(value[0]);
  max_temperature$.value[0].load(value[1]);
}

function clearFilters() {
  form$.value.reset();
}
</script>

<template>
  <hawk-modal-container :options="modal_options" id="task-filters-modal">
    <Vueform
      ref="form$"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :format-load="formatLoadedData"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('filters') }}
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <template v-for="field in fields">
            <RadiogroupElement
              v-if="field === 'Group by'"
              :key="field"
              name="group_by"
              class="mb-4"
              default="Status"
              :label="$t('Group by')"
              :items="[
                'Status',
                'Priority',
              ]"
            />
            <!-- Status -->
            <v-tags-input
              v-if="field === 'Status'"
              :key="field"
              :options="{
                name: 'status',
                label: $t('status'),
                placeholder: $t('Select Status'),
                items: task_store?.status_values,
                search: true,
                closeOnSelect: false,
                tags_removable: true,
                labelProp: 'label',
                dataProp: 'value',
              }"
              class="mb-4"
            />

            <!-- Priority -->
            <v-tags-input
              v-if="field === 'Priority'"
              :key="field"
              :options="{
                name: 'priority',
                label: $t('Priority'),
                placeholder: $t('Select Priority'),
                items: task_store?.priority_values,
                search: true,
                closeOnSelect: false,
                tags_removable: true,
                labelProp: 'label',
                dataProp: 'value',
              }"
              class="mb-4"
            />

            <!-- Category -->
            <hawk-category-input
              v-if="field === 'Category'"
              :key="field"
              class="mb-4"
              :options="{
                name: 'category',
                label: $t('Category'),
                placeholder: $t('Select Category'),
                tags_removable: true,
              }"
              :multi="true"
              :asset_id="asset_id"
            />

            <hawk-tags-input
              v-if="field === 'Tags'"
              :key="field"
              class="mb-4"
              :options="{
                name: 'tag',
                label: $t('Tags'),
                placeholder: $t('Select Tags'),
                tags_removable: true,
              }"
            />

            <!-- Templates -->
            <TaskTemplateDropdown
              v-if="field === 'Template'"
              :key="field"
              class="mb-4"
              :options="{
                tags_removable: true,
                name: 'template',
                closeOnSelect: false,
              }"
              :multi="true"
              :asset_id="asset_id"
            />

            <!-- Assignees -->
            <ObjectElement
              v-if="field === 'Assignees'"
              :key="field"
              class="mb-4"
              name="assignees_group"
              :label="$t('Assignees')"
            >
              <RadiogroupElement
                name="assignees_option"
                :items="[
                  'Assigned to me',
                  'Others',
                ]"
              />
              <hawk-assignee-input
                :options="{
                  name: 'assignees',
                  conditions: [
                    ['assignees_group.assignees_option', 'Others'],
                  ],
                  has_teams: true,
                  placeholder: $t('Select Assignees'),
                  columns: {
                    default: { container: 12, label: 12, wrapper: 12 },
                    sm: { container: 12, label: 12, wrapper: 12 },
                    md: { container: 12, label: 12, wrapper: 12 },
                  },
                }"
                :asset_id="props.asset_id"
                :multi="true"
                :format_load="true"
              />
            </ObjectElement>

            <!-- Start Date -->
            <DateTimeElement
              v-if="field === 'Start Date'"
              :key="field"
              class="mb-4"
              name="start_date"
              :label="$t('Start Date')"
              :options="{
                range: true,
                format: 'dd/MM/yyyy',
                placeholder: $t('Select Start Date'),
                startTime: [{ hours: 0, minutes: 0 }, { hours: 23, minutes: 59, seconds: 59 }],
              }"
            />

            <!-- Due Date -->
            <DateTimeElement
              v-if="field === 'Due Date'"
              :key="field"
              class="mb-4"
              name="due_date"
              :label="$t('Due Date')"
              :options="{
                range: true,
                format: 'dd/MM/yyyy',
                placeholder: $t('Select Due Date'),
                startTime: [{ hours: 0, minutes: 0 }, { hours: 23, minutes: 59, seconds: 59 }],
              }"
            />

            <!-- Created By -->
            <hawk-assignee-input
              v-if="field === 'Created By'"
              :key="field"
              :options="{
                label: $t('Created By'),
                name: 'owner',
                placeholder: $t('Select Owner'),
                appendTo: '#task-filters-modal'
              }"
              :multi="true"
              :format_load="true"
              class="mb-4"
            />

            <!-- Created At -->
            <DateTimeElement
              v-if="field === 'Created At'"
              :key="field"
              class="mb-4"
              name="created_at"
              :label="$t('Created At')"
              :options="{
                'range': true,
                'start-time': [{ hours: 0, minutes: 0 }, { hours: 23, minutes: 59, seconds: 59 }],
                'format': 'dd/MM/yyyy',
                'placeholder': $t('Select Created At Date'),
              }"
            />

            <TagsElement
              v-if="field === 'Include Feature Types'"
              :key="field"
              class="mb-4"
              name="include_feature_types"
              label-prop="name"
              value-prop="id"
              track-by="name"
              :label="$t('Include')"
              :placeholder="$t('Select')"
              :items="therm_options.feature_types"
              :search="true"
              :close-on-select="false"
            />

            <SliderElement
              v-if="field === 'Temperature difference'"
              ref="temperature_slider$"
              :key="field"
              class="mb-4"
              name="temperature_difference"
              tooltip-position="bottom"
              :label="$t('Temperature difference')"
              :default="[0, 100]"
              :merge="-1"
              @change="handleTemperatureSliderChange"
            />

            <ObjectElement
              v-if="field === 'Temperature difference'"
              :key="`${field}_min_max`"
              name="temp_diff_elements"
              :override-classes="{
                ElementLayout: {
                  innerWrapper: 'flex justify-end items-center',
                },
                ObjectElement: {
                  wrapper: ' w-[60%] gap-4 mr-8 flex justify-end',
                },
              }"
            >
              <TextElement
                ref="min_temperature$"
                key="temp_diff_elements.min_temp_diff"
                input-type="number"
                name="temp_diff_elements.min_temp_diff"
                label="Min"
                autocomplete="off"
                @blur="handleMinTempDiff($event.value)"
              />

              <TextElement
                ref="max_temperature$"
                key="temp_diff_elements.max_temp_diff"
                input-type="number"
                name="temp_diff_elements.max_temp_diff"
                label="Max"
                autocomplete="off"
                @blur="handleMaxTempDiff($event.value)"
              />
            </ObjectElement>

            <ToggleElement
              v-if="field === 'Starred'"
              :key="field"
              name="starred"
              :label="$t('Starred')"
              class="mb-4"
            />

            <!-- Archived -->
            <ToggleElement
              v-if="field === 'Archived'"
              :key="field"
              class="mb-4"
              name="archived"
              :label="$t('Archived')"
            />
            <CheckboxElement
              v-if="field === 'Ignore above filters for defects without tasks'"
              :key="field"
              name="ignore_task_filters"
              class="mb-4"
            >
              <div class="font-medium">
                {{ $t('Ignore above filters for defects without tasks') }}
              </div>
            </CheckboxElement>

            <!-- Progress -->
            <SliderElement
              v-if="field === 'Progress'"
              :key="field"
              class="mb-4"
              name="progress"
              tooltip-position="bottom"
              :label="$t('Progress')"
              :default="[0, 100]"
              :merge="-1"
              :format="progressFormat"
            />
          </template>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #left>
            <hawk-button type="text" @click="clearFilters">
              {{ $t('Clear filters') }}
            </hawk-button>
          </template>
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-button
                color="primary"
                :loading="loading"
                @click="apply"
              >
                {{ $t('Save') }}
              </hawk-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
