<script setup>
</script>

<template>
  <div v-if="file?.service" class="mb-1">
    <hawk-attachments-list
      :items="[file]"
      :can_delete="!el$?.disabled"
      @delete="remove"
      @download="download_file_url(file.service.url)"
    />
  </div>
  <div
    v-else-if="file && (file.name || file.fname)"
    class="flex p-3 border mb-2 rounded-lg relative"
    :class="is_error?.value || file?.has_unsupported_extension ? 'border-error-600' : progress.value === 100 ? 'border-primary-600' : 'border-gray-200'"
  >
    <div v-if="is_error?.value || file?.has_unsupported_extension" class="flex align-center absolute top-0 left-0 w-full h-full">
      <div class="h-full w-full rounded-lg bg-error-25" />
    </div>
    <div v-else-if="progress && progress.value !== 0" class="flex align-center absolute top-0 left-0 w-full h-full ">
      <div class="h-full rounded-lg bg-gray-50" :style="`width:${progress && progress.value}%;`" />
    </div>
    <div v-if="el$.use_uppy && !file.service" class="pr-1 z-1">
      <div v-if="file?.has_unsupported_extension" class="flex item-center justify-center w-10 pt-2 text-error-600 text-xl">
        <IconHawkAlertTriangle />
      </div>
      <div v-else-if="is_error?.value" class="flex item-center justify-center w-10 pt-2 text-error-600 text-xl cursor-pointer" @click="reupload">
        <IconHawkRefreshCcwOne />
      </div>
      <div v-else-if="progress.value === 100" class="flex item-center justify-center w-10 pt-2 text-primary-600 text-xl">
        <IconHawkCheck />
      </div>
      <hawk-progress-ring v-else :radius="20" :progress="progress.value" :stroke="4" />
    </div>
    <div v-else class="pr-1 z-1">
      <IconHawkUploadFileIcon class="w-10 h-10" />
    </div>
    <div class="flex-1 z-1">
      <div class="flex">
        <div class="flex-1">
          <hawk-text :content="file.name || file.fname" :length="30" class="text-gray-700" type="truncateMiddle" />
          <div v-if="file?.has_unsupported_extension" class="subtitle_text text-gray-600 font-normal text-sm">
            {{ $t("It seems like you're trying to upload an unsupported file type. Please try again with a supported file format.") }}
          </div>
          <div v-else-if="el$.use_uppy" class="subtitle_text text-gray-600 font-normal text-sm">
            {{ numberToBytes((file.size || file.file_size)) }} <span v-if="progress.value"> - {{ progress.value }}% {{ $t('uploaded') }} </span>
          </div>
          <div v-else class="subtitle_text text-gray-600 font-normal text-sm">
            {{ numberToBytes(file.size || file.file_size) }}
          </div>
        </div>
        <div
          class="flex p-2 text-lg rounded-md text-danger h-fit"
          :class="!is_uploading || file?.has_unsupported_extension ? 'hover:bg-gray-100 cursor-pointer' : ''"
          v-on="!is_uploading || file?.has_unsupported_extension ? { click: remove } : {}"
        >
          <IconHawkXClose class="text-gray-500" :class="!is_uploading || file?.has_unsupported_extension ? '' : 'invisible'" />
        </div>
      </div>
    </div>
  </div>
</template>
