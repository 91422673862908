<script setup>
import {
  Dialog,
  DialogPanel,
} from '@headlessui/vue';
import { useWindowSize } from '@vueuse/core';
import { debounce } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { PLANS_ROUTES_CONFIG } from '~/plans/constants';

const router = useRouter();
const route = useRoute();
const $t = inject('$t');
const common_store = useCommonStore();
const auth_store = useAuthStore();
const mobile_menu_open = ref(false);
const open_more = ref(false);

const { width } = useWindowSize();

const state = reactive({
  visible_items_count: 100,
});

const popover_group = ref(null);

const { set_form_quick_access } = common_store;
const { quick_access_list } = storeToRefs(common_store);

const set_quick_access_list = computed(() => {
  return [...quick_access_list.value
    .filter((form) => {
      return (
        form?.target_element.asset === null
        || form?.target_element.asset === route.params.asset_id
      );
    })
    .map((form) => {
      return {
        name: form?.name,
        label: form?.name,
        to: {
          name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-overview',
          params: {
            asset_id: common_store.active_asset?.uid,
            template_uid: form?.uid,
            template_name: form?.name?.replaceAll(' ', '-'),
          },
        },
        description: '',
        icon: IconIcBaselineFlashOn,
      };
    }),
  ];
});

const home_route = computed(() => route?.params?.asset_id ? [] : [{ name: $t('Home'), to: { name: 'home' }, description: '', icon: IconIcOutlineHome }]);

const dbd_pod_route = computed(() => {
  return auth_store.check_split('display_dbd_pod') && route.params.asset_id === '9e1OtnulDO' && auth_store?.current_organization?.uid === '00g9goh6osoHwTeax0i7'
    ? [{
        name: $t('POD'),
        to: { name: 'pod' },
        description: '',
        icon: IconIcBaselineFlashOn,
        badge_label: 'New',
      }, {
        name: $t('POD-2'),
        to: { name: 'pod-2' },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : [];
});

const dashboard_route = computed(() => {
  return auth_store.check_split('org_level_dashboard') || route.params.asset_id
    ? [{
        name: $t('Dashboard'),
        to: {
          name: 'asset-dashboard',
          params: { asset_id: route.params.asset_id },
        },
      }]
    : [];
});

const all_menu_items = computed(() => [
  ...home_route.value,
  ...dbd_pod_route.value,
  ...dashboard_route.value,
  ...(route.params.asset_id
    ? [{
        name: $t('Maps'),
        to: {
          name: 'maps-list',
          params: {
            type: 'maps-list',
          },
        },
        description: '',
        icon: IconIcBaselineFlashOn,
        badge_label: 'New',
      }]
    : []),
  { name: $t('Tasks'), to: { name: 'tasks' }, description: '', icon: IconIcOutlineTask },
  {
    name: $t('Forms'),
    to: { name: 'forms', params: { asset_id: common_store.active_asset?.uid } },
    description: '',
    icon: IconIcBaselineFlashOn,
  },
  {
    name: $t('Files'),
    to: { name: 'dms', params: { asset_id: common_store.active_asset?.uid } },
    description: '',
    icon: IconIcBaselineFlashOn,
  },
  ...(route.params.asset_id
    ? [{
        name: $t('Project management'),
        to: { name: 'project-management', params: { asset_id: route.params.asset_id } },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : []),

  ...(route.params.asset_id
    ? [{
        name: $t('System model'),
        to: { name: 'system-model', params: { asset_id: route.params.asset_id } },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : []),
  ...(route.params.asset_id
    ? [{
        name: $t('Plans'),
        to: {
          name: PLANS_ROUTES_CONFIG.PLANS.name,
          params: { asset_id: route.params.asset_id },
        },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : []),

  ...(auth_store.check_permission('access_inventory', route.params.asset_id) && auth_store.check_split('inventory_v2')
    ? [{
        name: 'Inventory',
        to: { name: 'inventory', params: { asset_id: common_store.active_asset?.uid } },
        description: '',
      }]
    : []),
  { name: $t('Conversations'), to: { name: 'conversations', params: { asset_id: common_store.active_asset?.uid } }, description: '', icon: IconIcBaselineFlashOn },
  ...(route.params.asset_id && auth_store.check_split('asset_reports')
    ? [{
        name: $t('Project Outputs'),
        to: {
          name: 'project-outputs-reports',
          params: { asset_id: route.params.asset_id },
        },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : []),
  ...(route.params.asset_id && auth_store.check_split('cnc')
    ? [{
        name: 'Commissioning and Certification',
        to: {
          name: 'cnc-reports',
          params: { asset_id: route.params.asset_id },
        },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : []),
  ...(auth_store.check_split('solarlabs_crm')
    ? [{
        name: $t('DRE'),
        to: {
          name: 'solar-labs',
        },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : []),
  ...(auth_store.check_split('eanalytix') && route.params.asset_id
    ? [{
        name: 'CMS',
        to: {
          name: 'eanalytix',
        },
        description: '',
        icon: IconIcBaselineFlashOn,
      }]
    : []),
  ...set_quick_access_list.value,
]);

const visibleMenu = computed(() => all_menu_items.value.slice(0, state.visible_items_count));

const organization_cover_image_url = computed(() => auth_store?.current_organization?.cover_image?.url);

const dropdownMenu = computed(() => all_menu_items.value.slice(state.visible_items_count));

function isActive(menu) {
  // Therm and Terra both has same to.name, due to this both terra and therm text was getting highlighted.
  if (route.name === 'views-list')
    return route.params?.type === menu.to.params?.type;

  return (
    route.name === menu?.to?.name
    || route?.meta?.module === menu?.to?.name
  );
}

onBeforeMount(async () => {
  await set_form_quick_access();
});

const moreMenuItems = computed(() => {
  const dropdownMenuItems = dropdownMenu.value.map((item) => {
    return {
      ...item,
      label: item.name,
    };
  });
  return [...dropdownMenuItems];
});

function changeAsset(asset) {
  router.replace({
    ...route,
    params: { asset_id: asset },
    query: {},
  });
}

function adjustNavItems() {
  let available_navbar_item_space = 0;
  const nav_items_width = [];
  // not needed now: let total_width = 0;

  const navbar = document.getElementById('top-navbar');
  available_navbar_item_space = navbar?.clientWidth - 40;

  const navbar_item = document?.getElementById('top-nav-item');

  if (navbar_item?.children.length) {
    const items = navbar_item.getElementsByTagName('a');
    for (const item of items)
      nav_items_width.push(item.clientWidth + 16);

    // this used to exist before: total_width = nav_items_width.reduce((a, b) => a + b, 0);

    let sum = 0;
    let index = 0;
    for (const width of nav_items_width) {
      sum += width;
      if (sum < (available_navbar_item_space - 200))
        state.visible_items_count = index + 1;
      else break;
      index++;
    }
  }
  state.nav_items_width = nav_items_width;
}

async function handleChatWithUs() {
  common_store.help_widget = !common_store.help_widget;
  common_store.toggle_chat_with_us(common_store.help_widget);
}

onBeforeUpdate(() => adjustNavItems());

watch(() => [route.name, width.value], debounce(() => {
  adjustNavItems();
}, 100), { immediate: true });
</script>

<template>
  <!-- for mobile -->
  <div ref="popover_group" class="sticky top-0 z-[1000] block md:hidden">
    <header
      class="relative z-10 text-gray-300 bg-gray-700 border-b isolate"
    >
      <nav
        class="py-2.75 mx-auto flex items-center justify-between px-4 md:justify-start md:space-x-5"
        aria-label="Global"
      >
        <div class="flex shrink-0">
          <div class="cursor-pointer shrink-0" @click="router.push({ name: 'home' })">
            <img v-if="organization_cover_image_url?.length" :src="organization_cover_image_url" alt="org logo" class="object-contain w-24 h-10">
            <div v-else>
              <img src="./../../../../../assets/logos/taskmapper-full-white.svg" alt="TaskMapper" width="133">
            </div>
          </div>
        </div>
        <div class="flex gap-4 md:hidden">
          <HawkGlobalSearch />
          <HawkLanguageDropdown />
          <button
            type="button"
            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobile_menu_open = true"
          >
            <IconHawkMenuOne class="w-6 h-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        class="md:hidden"
        :open="mobile_menu_open"
        @close="mobile_menu_open = false"
      >
        <div class="fixed inset-0" />
        <DialogPanel
          class="fixed inset-y-0 right-0 z-[99] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        >
          <div class="flex items-center justify-between">
            <div class="cursor-pointer shrink-0" @click="router.push({ name: 'home' })">
              <img v-if="organization_cover_image_url?.length" :src="organization_cover_image_url" alt="org logo" class="object-contain w-24 h-10">
              <div v-else>
                <img src="./../../../../../assets/logos/taskmapper-full-white.svg" alt="TaskMapper" width="133">
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div>
                <hawk-button icon type="text" size="md">
                  <IconHawkBellOne class="!text-gray-600" />
                </hawk-button>
              </div>
              <hawk-button icon type="text" size="md" @click="mobile_menu_open = false">
                <span class="sr-only">Close menu</span>
                <IconHawkX class="h-6 w-6 !text-gray-600" aria-hidden="true" />
              </hawk-button>
            </div>
          </div>
          <template v-if="route.params.asset_id">
            <hawk-asset-input
              class="mt-6"
              :default_asset="common_store?.active_asset?.uid"
              @select="changeAsset, (mobile_menu_open = false)"
            />
          </template>
          <div class="flow-root mt-6">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="py-6 space-y-2">
                <template v-for="menu in all_menu_items" :key="menu.name">
                  <RouterLink
                    v-if="menu.name"
                    :id="menu.name"
                    :to="menu.to ? menu.to : ''"
                    class="block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover:bg-gray-50"
                    :class="
                      isActive(menu)
                        ? 'text-blue-600 font-semibold'
                        : 'text-gray-700 font-medium'
                    "
                    @click="mobile_menu_open = false"
                  >
                    {{ menu.name }}
                  </RouterLink>
                </template>
              </div>
              <div class="py-6 space-y-2">
                <ProfileDropdown @close="mobile_menu_open = false" />
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  </div>

  <!-- for desktop -->
  <div ref="popover_group" class="sticky top-0 z-[1000] hidden md:block">
    <header
      class="relative z-10 text-gray-300 bg-gray-700 border-b isolate"
    >
      <nav
        class="py-2.75 mx-auto flex items-stretch justify-between px-4 md:justify-start md:space-x-5"
        aria-label="Global"
      >
        <!-- Taskmapper Icon / organization icon -->
        <div class="flex items-center shrink-0">
          <div class="cursor-pointer shrink-0" @click="router.push({ name: 'home' })">
            <img v-if="organization_cover_image_url?.length" :src="organization_cover_image_url" alt="org logo" class="object-contain w-24 h-10">
            <div v-else>
              <img src="./../../../../../assets/logos/taskmapper-full-white.svg" alt="TaskMapper" width="133">
            </div>
          </div>
        </div>

        <!-- if asset_id is available show the home icon -->
        <div v-if="route.params?.asset_id" class="flex items-center" :class="$route.name === 'home' ? 'text-primary-600' : 'text-white'" @click="router.push({ name: 'home' })">
          <div class="bg-gray-200 h-5 w-[1px]" />
          <HawkButton v-tippy="$t('Go to all assets')" type="plain" class="!text-gray-300 hover:!text-white">
            <IconHawkHomeLine class="w-5 h-5" />
          </HawkButton>
          <div class="bg-gray-200 h-5 w-[1px]" />
        </div>

        <!-- visible & more menu -->
        <div id="top-navbar" class="hidden md:flex md:flex-1 md:items-stretch md:justify-between">
          <nav class="flex items-stretch gap-4" aria-label="navbar">
            <template v-for="menu in visibleMenu" :key="menu.name">
              <RouterLink
                v-if="menu.name"
                :ref="menu.name"
                :to="menu.to ? menu.to : ''"
                class="flex items-center h-full text-sm hover:text-white flex-shrink-0"
                :class="
                  isActive(menu)
                    ? 'text-white font-semibold'
                    : 'text-gray-300 font-medium'
                "
              >
                {{ menu.name }}
              </RouterLink>
            </template>
            <HawkMenu
              v-if="moreMenuItems.length"
              :items="moreMenuItems"
              :has_bordered_trigger="true"
              additional_trigger_classes="!ring-0 border-0 h-full"
              class="flex items-center text-gray-300"
              additional_dropdown_classes="w-56 max-h-[50vh] overflow-auto scrollbar"
              @open="open_groupby = true"
              @close="open_groupby = false"
            >
              <template #trigger>
                <div
                  class="flex items-center justify-between text-sm font-medium rounded shadow-xs"
                >
                  <span class="mr-1">{{ $t('More') }}</span>
                  <IconHawkChevronUp v-if="open_more" class="text-lg" />
                  <IconHawkChevronDown v-else class="text-lg" />
                </div>
              </template>
              <template #content="{ close }">
                <div class="flex flex-col p-1">
                  <hawk-button
                    v-for="item in moreMenuItems"
                    :key="item.label" type="text"
                    class="!h-11 !justify-start"
                    :class="
                      isActive(item)
                        ? '!text-blue-600 font-semibold'
                        : '!text-gray-700 font-medium'
                    "
                    @click="$router.push(item.to);close()"
                  >
                    <HawkText
                      :content="item.label" :length="20"
                    >
                      {{ item.label }}
                    </HawkText>
                  </hawk-button>
                </div>
              </template>
            </HawkMenu>
          </nav>
        </div>

        <div class="hidden md:flex md:justify-end">
          <div class="flex items-center gap-1">
            <hawk-button
              v-if="route.params.asset_id"
              v-tippy="{ content: $t('Asset settings'), placement: 'bottom' }" icon type="text" size="md" class="flex hover:bg-gray-900"
              @click="router.push({ name: 'account-settings-asset-general' })"
            >
              <IconHawkSettingsOne class="!text-white" />
            </hawk-button>

            <!-- asset selection -->
            <template v-if="route.params.asset_id">
              <HawkAssetInput
                :default_asset="common_store?.active_asset?.uid"
                @select="changeAsset"
              />
            </template>

            <!-- global search -->
            <HawkGlobalSearch />

            <!-- language Dropdown -->
            <HawkLanguageDropdown />

            <!-- notification -->
            <HawkNotifications />

            <hawk-button
              v-tippy="{ content: $t('Chat with us'), placement: 'bottom' }"
              icon type="text" size="md" class="hover:bg-gray-900"
              @click="handleChatWithUs"
            >
              <IconHawkMessageCircleTwo class="!text-white" />
            </hawk-button>

            <!-- profile dropdown -->
            <ProfileDropdown />
          </div>
        </div>
      </nav>
    </header>
  </div>
  <!-- --------------- START - Please do not remove this block --------------- -->
  <div class="hidden md:flex md:flex-1 md:items-stretch md:justify-between fixed -top-5">
    <nav id="top-nav-item" class="flex items-stretch gap-4" aria-label="navbar">
      <template v-for="menu in all_menu_items" :key="menu.name">
        <RouterLink
          v-if="menu.name"
          :ref="menu.name"
          :to="menu.to ? menu.to : ''"
          class="flex items-center h-full text-sm flex-shrink-0"
          :class="
            isActive(menu)
              ? 'font-semibold'
              : 'font-medium'
          "
        >
          {{ menu.name }}
        </RouterLink>
      </template>
    </nav>
  </div>
  <!-- ---------------- END - Please do not remove this block ---------------- -->
</template>

<style>
.popover-arrow:before{
  content:'';
    top: -16px;
    left: calc(160px - 14px);
    border-width: 10px;
    border-color: transparent white transparent transparent;
    @apply rotate-90 absolute h-0 w-0 border-solid border-[10px];
}
</style>
