import { createUppyInstance } from '~/common/utils/uppy.util.js';

export function useThermCustomReport() {
  async function convertSvgToPng(svgString, graph_height = null) {
    const canvas = document.getElementById('custom-report-canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    img.src = URL.createObjectURL(svgBlob);

    await new Promise((resolve) => {
      img.onload = resolve;
    });
    const svgDoc = new DOMParser().parseFromString(svgString, 'image/svg+xml');
    const width = svgDoc.documentElement.getAttribute('width') || 400; // Default width
    const height = graph_height || svgDoc.documentElement.getAttribute('height') || 400; // Default height

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0);

    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, 'image/png');
    });
    return blob;
  }

  async function uploadFiles(files) {
    const uppy = createUppyInstance({
      attachment_config: {
        meta: {
          service: 'therm',
          id: 'upload',
        },
      },
    });
    uppy.addFiles(files);

    const waitForUploads = () => {
      return new Promise((resolve, reject) => {
        uppy.on('complete', (result) => {
          if (result.failed.length > 0) {
            reject(new Error('Some files failed to upload'));
          }
          else {
            resolve(result);
          }
        });
      });
    };

    try {
      await waitForUploads();
      logger.log('Files uploaded successfully');
    }
    catch (error) {
      logger.error('Error during upload:', error);
    }
  }

  function getAttachmentDetails(file) {
    if (!file)
      return null;
    return {
      uid: file.uid || crypto.randomUUID(),
      service: file.service_object || file.service,
      file_name: file.name || file.file_name,
      file_size: file.size || file.file_size,
      file_type: file.type || file.file_type,
    };
  }

  return {
    convertSvgToPng,
    uploadFiles,
    getAttachmentDetails,
  };
}
