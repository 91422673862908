<script setup>
import { isArray, isObject, keyBy, slice } from 'lodash-es';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useTagsStore } from '~/common/stores/tags.store';

const props = defineProps({
  tags: {
    required: true,
    default: () => [],
  },
  max_tags_to_display: {
    type: Number,
    default: Number.MAX_SAFE_INTEGER,
  },
  popover_position: {
    type: String,
    default: 'middle',
    validator(value) {
      return ['left', 'middle', 'right'].includes(value);
    },
  },
  popover_items_wrap: {
    type: Boolean,
    default: true,
  },
  tag_truncate_length: {
    type: Number,
    default: 30,
  },
  popover_tag_truncate_length: {
    type: Number,
    default: 20,
  },
  additional_tag_classes: {
    type: String,
    default: '',
  },
});

const tags_store = useTagsStore();
const open = ref(false);
const popover_position_class = ref('right-0 top-0');
const popover_pos_target = ref(null);

const tags_map = computed(() => {
  return keyBy(tags_store.tags, 'uid');
});

const tags_uids = computed(() => {
  if (isArray(props.tags))
    return props.tags.map(tag => isObject(tag) ? tag.uid : tag);
  else return isObject(props.tags) ? [props.tags.uid] : [props.tags];
});

const tags_to_display = computed(() => slice(tags_uids.value, 0, props.max_tags_to_display));

const remaining_tags_to_display = computed(() => slice(tags_uids.value, props.max_tags_to_display, tags_uids.value?.length));

const show_popover = computed(() => remaining_tags_to_display.value.length);

function togglePopover(value) {
  const current_position = popover_pos_target.value.getBoundingClientRect();
  const screen_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  const y_class
    = current_position.y < screen_center.y ? 'top-full' : 'bottom-full';
  popover_position_class.value = `${y_class}`;

  open.value = value;
}

const popover_panel_classes = computed(() => {
  const classes = [];
  switch (props.popover_position) {
    case 'left':
      classes.push('');
      break;
    case 'middle':
      classes.push('left-1/2 -translate-x-1/2');
      break;
    case 'right':
      classes.push('right-0');
      break;
  }

  return classes;
});
</script>

<template>
  <div class="flex flex-wrap gap-1">
    <div v-for="tag in tags_to_display" :key="tag" class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-0.5 px-1.5" :class="additional_tag_classes">
      <HawkText v-if="tags_map?.[tag]?.name" :content="tags_map[tag].name" :length="tag_truncate_length" />
      <div v-else>
        -
      </div>
    </div>
    <Popover
      v-if="show_popover"
      class="relative flex items-center"
      @mouseenter="togglePopover(true)"
      @mouseleave="togglePopover(false)"
      @click="togglePopover(!open)"
    >
      <PopoverButton>
        <div
          class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-0.5 px-1.5"
          :class="additional_tag_classes"
        >
          +{{ remaining_tags_to_display?.length }}
        </div>
      </PopoverButton>
      <span
        ref="popover_pos_target"
        class="absolute top-0 left-0 w-full h-full pointer-events-none"
      />

      <div v-if="open">
        <PopoverPanel
          static
          class="absolute z-1 flex w-screen max-w-min"
          :class="[popover_position_class, popover_panel_classes]"
        >
          <div
            class="w-56 flex-shrink-0 rounded-xl bg-white p-1.5 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 max-h-60 scrollbar gap-1"
            :class="popover_items_wrap ? 'flex flex-wrap' : 'grid'"
          >
            <div v-for="tag in remaining_tags_to_display" :key="tag" class="flex items-center whitespace-nowrap text-sm rounded-lg border border-gray-300 py-0.5 px-1.5" :class="additional_tag_classes">
              <HawkText v-if="tags_map?.[tag]?.name" :content="tags_map[tag].name" :length="popover_tag_truncate_length" />
              <div v-else>
                -
              </div>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </Popover>
  </div>
</template>
