<script setup>
const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  task: {
    type: Object,
    required: true,
  },
  payload: {
    type: Object,
    required: true,
  },
  update_function: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['close']);

const state = reactive({
  loading: false,
  is_dependency_loading: false,
  dependencies: [],
});

onMounted(async () => {
  state.is_dependency_loading = true;

  await props.task_store.set_task_details(props.task.uid);

  if (props.task_store.dependencies && Object.keys(props.task_store.dependencies).length) {
    const task = props.task_store.get_current_task(props.task.uid);
    state.dependencies = task?.blocked_by?.map((uid) => {
      return {
        ...props.task_store.dependencies[uid],
        uid,
      };
    }) || [];
  }

  state.is_dependency_loading = false;
});

async function closeTask() {
  state.loading = true;
  const payload = {
    ...props.payload,
    skipBlocking: true,
  };
  await props.update_function(payload);
  state.loading = false;
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-content>
      <HawkFeaturedIcon theme="light-circle-outline" color="warning" size="xl" class="mb-6">
        <IconHawkInfoCircle />
      </HawkFeaturedIcon>
      <p class="text-gray-900 text-lg font-semibold">
        {{ $t('Dependency Warning') }}
      </p>
      <p class="text-gray-600 text-sm mb-6">
        {{ $t('This task is waiting on another task to be completed. Are you sure you want to change the status of this task?') }}
      </p>
      <p class="text-gray-600 text-xs">
        {{ $t('Waiting on') }}
      </p>
      <hawk-skeleton v-if="state.is_dependency_loading" custom_classes="w-[20%]" />
      <div v-else>
        <p v-for="dependency in state.dependencies" :key="dependency.uid" class="text-gray-900 text-sm font-medium">
          {{ dependency.name }}
        </p>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-between items-center">
      <template #right>
        <!-- Footer -->
        <div class="flex justify-end items-center">
          <hawk-button
            class="mr-5"
            type="outlined"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            color="primary"
            :loading="state.loading"
            @click="closeTask"
          >
            {{ $t('Yes, close this task') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
