<script setup>
import { countBy, orderBy } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  therm_store: {
    type: Object,
    required: true,
  },
  default_filters_map: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['applyFilter']);

const {
  $date_relative,
  $date,
  $services,
  $t,
  $toast,
  $track_event,
  auth_store,
  common_store,
  route,
  router,
} = useCommonImports();

const can_reset = computed(() => !!Object.values(props.default_filters_map || {}).filter(value => value?.length).length);
const feature_type_count = computed(() => countBy(props.therm_store.report_defects, f => f.type_id));

const display_filters = computed(() => {
  return [
    {
      name: $t('Blocks'),
      is_static: true,
      data_type: 'single_select',
      operators: ['isAnyOf'],
      option_type: 'single_select',
      options: orderBy(
        Object.values(props.therm_store?.active_group?.projects || {}).map(project => ({ name: project.name, uid: project.uid })),
        'name', ['asc'],
      ),
      uid: 'blocks',
    },
    {
      name: $t('Defect type'),
      is_static: true,
      data_type: 'single_select',
      operators: ['isAnyOf'],
      option_type: 'single_select',
      options: Object.values(props.therm_store.feature_types).reduce((acc, feature_type) => {
        if (feature_type_count.value[feature_type.id] > 0)
          acc.push({ name: feature_type.name, uid: feature_type.class_name });
        return acc;
      }, []),
      uid: 'types',
    },
    {
      name: $t('Severity'),
      is_static: true,
      data_type: 'single_select',
      operators: ['isAnyOf'],
      option_type: 'single_select',
      options: [
        { name: $t('Severity 1'), uid: 'low' },
        { name: $t('Severity 2'), uid: 'medium' },
        { name: $t('Severity 3'), uid: 'high' },
      ],
      uid: 'severity',
    },
    {
      name: $t('Assignees'),
      is_static: true,
      data_type: 'multi_select',
      operators: ['isAnyOf'],
      option_type: 'members',
      type: 'members',
      options: [],
      uid: 'assignees',
    },
    {
      name: $t('Status'),
      is_static: true,
      data_type: 'single_select',
      operators: ['isAnyOf'],
      option_type: 'single_select',
      options: props.therm_store.status_configs.map(status => ({ name: status.name, uid: status.uid })),
      uid: 'status',
    },
  ].map(filter => ({
    ...filter,
    default_value: {
      value: props.default_filters_map[filter.uid] || null,
    },
  }));
});

function updateFilter(filter, e) {
  emit('applyFilter', { ...props.default_filters_map, [filter.uid]: e.value });
}

function resetFilters() {
  emit('applyFilter', {});
}
</script>

<template>
  <div>
    <div class="flex gap-2 flex-wrap items-center">
      <div v-for="filter_config in display_filters" :key="filter_config.uid">
        <HawkQuickFilters
          v-bind="filter_config"
          @apply="e => updateFilter(filter_config, e)"
        />
      </div>
      <HawkButton v-if="can_reset" icon size="xxs" color="gray" rounded @click="resetFilters()">
        <IconHawkX />
      </HawkButton>
    </div>
  </div>
</template>
