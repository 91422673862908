<script setup>
import { debounce } from 'lodash-es';

import FormStatus from '~/forms/atoms/form-status.vue';

import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
  asset_id: {
    type: String,
    default: '',
  },
  additional_queries: {
    type: Object,
    default: () => ({}),
  },
  additional_table_options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['selected']);

const $services = inject('$services');

const common_store = useCommonStore();

const search_key = toRefs(props).search;
const table_instance = ref(null);

const state = reactive({
  loading: false,
  scroll_loading: false,
  selected_forms: [],
  forms: [],
  total_form_count: 0,
  page_size: 12,
});
const columns = [
  {
    accessorKey: 'select',
    id: 'select',
  },
  {
    header: 'Form Name',
    accessorKey: 'name',
    id: 'name',
    size: '400',
  },
  {
    header: 'Status',
    accessorKey: 'metrics',
    id: 'metrics',
  },
];

onMounted(async () => {
  await getData({}, '');
});

watch(search_key, debounce(async (newVal, oldVal) => {
  if (newVal !== oldVal)
    await getData({}, newVal);
}, 500));

const getAssetName = target_element => common_store.get_asset(target_element.asset)?.name || null;

async function getData({ pagination_state }, search = null, init = true) {
  try {
    if (init)
      state.loading = true;
    else
      state.scroll_loading = true;

    const query = {
      page_number: 1,
      page_size: state.page_size,
      q: search,
      is_child: false,
      status: 'published',
      public: true,
      ...(props.asset_id && { asset_uid: props.asset_id }),
      ...props.additional_queries,
    };
    state.page_size = pagination_state?.pageSize || 12;
    if (pagination_state?.pageIndex && pagination_state?.pageSize) {
      query.page_number = pagination_state.pageIndex;
      query.page_size = pagination_state.pageSize;
    }

    const { data, headers } = await $services.forms.getAll({ query });
    if (init)
      state.forms = data.forms.filter(form => !props.restricted_items.includes(form.uid));
    else
      state.forms = [...state.forms, ...data.forms.filter(form => !props.restricted_items.includes(form.uid))];

    state.total_form_count = headers['x-total-count'];
    if (init)
      state.loading = false;
    else
      state.scroll_loading = false;
  }
  catch (error) {
    logger.log(error);
    state.forms = [];
    if (init)
      state.loading = false;
    else
      state.scroll_loading = false;
  }
}

function handleFormSelection(e) {
  state.selected_forms = e.map(forms_list_row => forms_list_row.original);
  emit('selected', state.selected_forms);
}
</script>

<template>
  <div class="text-sm font-medium text-gray-600 px-4 mb-2">
    {{ $t('Forms') }}
  </div>
  <div v-if="!state.loading && !state.forms.length" class="px-5 text-sm text-gray-600 font-medium h-[350px]">
    {{ $t("No results found") }}
  </div>
  <div v-else id="attach-forms" class="h-[350px] scrollbar">
    <HawkTable
      container_class="border-0 h-full"
      parent_id_for_infinite_scrolling="attach-forms"
      :pagination_config="{ totalRows: state.total_form_count, pageSize: state.page_size }"
      :is_loading="state.loading"
      :data="state.forms"
      :columns="columns"
      :show_menu_header="false"
      :header_visible="false"
      :disable_resize="true"
      :enable_infinite_scroll="true"
      :is_loading_on_scroll="state.scroll_loading"
      is_gapless
      v-bind="additional_table_options"
      @loadMore="getData($event, search_key, false)"
      @tableInstance="table_instance = $event"
      @select-row="handleFormSelection"
    >
      <template #name="form_name">
        <div class="flex items-center">
          <div class="whitespace-nowrap">
            <p class="text-xs text-gray-600 mb-0.5">
              {{ getAssetName(form_name.data.row.original.target_element) }}
            </p>
            <HawkText class="text-sm font-medium text-gray-900 max-w-[150px]" :content="form_name.data.row.original.name" :length="50" />
          </div>
        </div>
      </template>
      <template #metrics="metrics">
        <FormStatus
          :form="metrics?.data?.row?.original"
          custom_class="content-center text-sm rounded-full px-2 py-1 inline-flex text-sm whitespace-nowrap font-medium"
        />
      </template>
      <template #rowLoader>
        <HawkSkeleton custom_classes="!w-32 py-2" />
      </template>
    </HawkTable>
  </div>
</template>
