<script setup>
import { computed } from 'vue';
import { useSheetStore } from '../store/sheet.store';

const sheet_store = useSheetStore();

const is_marked_private = computed(() => sheet_store.sheet.private);

function sheetPrivacyToggler() {
  sheet_store.update_sheet_privacy(!is_marked_private.value);
}
</script>

<template>
  <div class="flex items-center justify-between w-full text-sm ml-1">
    {{ $t('Mark sheet as private') }}
    <hawk-toggle
      class="scale-75"
      :initial-state="is_marked_private"
      @click="sheetPrivacyToggler"
    />
  </div>
</template>
