import { defineStore } from 'pinia';
import { cloneDeep, keyBy, omit } from 'lodash-es';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

export function useChecklistStore(key) {
  return defineStore(key || 'checklists', {
    state: () => ({
      search_key: '',
      checklists_map: {},
      total_checklist_count: 0,
      attachments_map: {},
    }),
    getters: {
      checklists: (state) => {
        return Object.values(state.checklists_map);
      },
      get_current_checklist: (state) => {
        return checklist_id => state.checklists_map[checklist_id];
      },
      checklist_attachments: state => (item_id) => {
        return Object.values(state.attachments_map).filter(obj => obj.foreign_object_uid === item_id);
      },
    },
    actions: {
      set_task_checklists(checklists) {
        try {
          this.checklists_map = keyBy(checklists, 'uid');
          this.total_checklist_count = checklists.length;
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      set_search_key(value) {
        this.search_key = value;
      },
      async create_checklist(task_uid, payload) {
        try {
          const { data } = await this.$services.tasks.create_checklist({
            id: task_uid,
            body: payload,
          });
          const checklist = data.checklists[0];
          this.checklists_map = { ...this.checklists_map, [checklist.uid]: checklist };
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async duplicate_checklist(task_uid, payload) {
        try {
          const { data } = await this.$services.tasks.update_checklists_template({
            id: task_uid,
            body: payload,
          });
          let checklists = {};
          if (data?.checklists[0])
            checklists = keyBy(data.checklists[0], 'uid');
          this.checklists_map = { ...this.checklists_map, ...checklists };
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async update_checklists(task_uid, checklists) {
        const checklistClone = cloneDeep(this.checklists_map);
        try {
          this.checklists_map = keyBy(checklists, 'uid');
          await this.$services.tasks.update_checklists({
            id: task_uid,
            body: {
              checklists,
            },
          });
        }
        catch (err) {
          this.checklists_map = checklistClone;
          logger.log(err);
          return err;
        }
      },
      async update_checklist(task_uid, checklist) {
        try {
          const { data } = await this.$services.tasks.update_checklists({
            id: task_uid,
            body: {
              checklist,
            },
          });
          this.checklists_map[checklist.uid] = data.checklist;
        }
        catch (err) {
          logger.log(err);
          return err;
        }
      },
      async delete_checklist(task_id, checklist_id) {
        const checklistsClone = cloneDeep(this.checklists_map);
        try {
          this.checklists_map = omit(this.checklists_map, checklist_id);
          await this.$services.tasks.delete_checklist({
            id: task_id,
            checklist_id,
          });
        }
        catch (error) {
          this.checklists_map = checklistsClone;
          logger.log(error);
          return error;
        }
      },

      // template
      async load_from_template(task_uid, payload) {
        try {
          const { data } = await this.$services.tasks.update_checklists_template({
            id: task_uid,
            body: payload,
          });
          let checklists = {};
          if (data?.checklists[0])
            checklists = keyBy(data.checklists[0], 'uid');
          this.checklists_map = { ...this.checklists_map, ...checklists };
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async set_checklists(query) {
        try {
          const { data, headers } = await this.$services.checklists.getAll({
            query: {
              ...query,
              ...(this.search_key && { q: this.search_key }),
            },
          });
          this.checklists_map = keyBy(data.checklists, 'uid');
          this.total_checklist_count = headers['x-total-count'];
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async update_checklist_template(task_uid, payload) {
        try {
          await this.$services.tasks.update_checklists_template({
            id: task_uid,
            body: {
              checklists: payload,
            },
          });
        }
        catch (err) {
          logger.log(err);
          return err;
        }
      },
      async load_multiple_checklists(payload) {
        try {
          await this.$services.tasks.load_multiple_checklists({
            body: {
              checklists: payload,
            },
          });
        }
        catch (err) {
          logger.log(err);
          return err;
        }
      },
      async create_template(payload) {
        try {
          const { data } = await this.$services.checklists.post({
            body: {
              checklist: payload,
            },
          });
          const checklist = data.checklist;
          this.checklists_map = { [checklist.uid]: checklist, ...this.checklists_map };
        }
        catch (err) {
          logger.log(err);
          return err;
        }
      },
      async update_template(payload) {
        try {
          const { data } = await this.$services.checklists.patch({
            body: {
              checklist: payload,
            },
          });
          if (data?.checklist)
            this.checklists_map[payload.uid] = data.checklist;
        }
        catch (err) {
          logger.log(err);
          return err;
        }
      },
      async delete_template(checklist_uid) {
        const checklistsClone = cloneDeep(this.checklists_map);
        try {
          this.checklists_map = omit(this.checklists_map, checklist_uid);
          await this.$services.checklists.delete({
            id: checklist_uid,
          });
        }
        catch (error) {
          this.checklists_map = checklistsClone;
          logger.log(error);
          return error;
        }
      },
      async get_checklist_template_attachments(checklist_uid) {
        try {
          const { data } = await this.$services.checklists.get_attachments({
            checklist_id: checklist_uid,
          });
          if (data?.attachments)
            this.attachments_map = keyBy(data.attachments, 'uid');
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async set_checklist_template_attachments(uid_obj, payload) {
        try {
          const { data } = await this.$services.checklists.add_attachments({
            checklist_id: uid_obj.checklist_uid,
            item_id: uid_obj.item_uid,
            body: payload,
          });
          let attachments = {};
          if (data?.attachment)
            attachments = { [data.attachment.uid]: data.attachment };
          if (data?.attachments)
            attachments = keyBy(data.attachments, 'uid');
          this.attachments_map = { ...this.attachments_map, ...attachments };
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async delete_checklist_template_attachments(uid_obj) {
        const attachmentsClone = cloneDeep(this.attachments_map);
        try {
          this.attachments_map = omit(this.attachments_map, uid_obj.attachment_uid);
          await this.$services.checklists.delete_attachments({
            checklist_id: uid_obj.checklist_uid,
            item_id: uid_obj.item_uid,
            attachment_id: uid_obj.attachment_uid,
          });
        }
        catch (error) {
          this.attachments_map = attachmentsClone;
          return error;
        }
      },
      async set_checklist_attachments(uid_obj, payload) {
        const task_store = useTasksStore(uid_obj.store_key);
        try {
          const { data } = await this.$services.tasks.set_checklist_attachments({
            id: uid_obj.task_uid,
            checklist_id: uid_obj.checklist_uid,
            item_id: uid_obj.item_uid,
            body: payload,
          });

          let attachments = {};
          if (data?.attachment)
            attachments = { [data.attachment.uid]: data.attachment };
          if (data?.attachments)
            attachments = keyBy(data.attachments, 'uid');
          task_store.$patch({ attachments_map: { ...task_store.attachments_map, ...attachments } });
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async delete_checklist_attachments(uid_obj) {
        const task_store = useTasksStore(uid_obj.store_key);
        const attachments_map = task_store.attachments_map;
        const updated_map = attachments_map;
        delete updated_map[uid_obj.attachment_uid];
        task_store.$patch({ attachments_map: updated_map });

        try {
          await this.$services.tasks.delete_checklist_attachments({
            id: uid_obj.task_uid,
            checklist_id: uid_obj.checklist_uid,
            item_id: uid_obj.item_uid,
            attachment_id: uid_obj.attachment_uid,
          });
        }
        catch (error) {
          task_store.$patch({ attachments_map });
          logger.log(error);
          return error;
        }
      },
    },
  })();
}
