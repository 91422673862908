<script setup>
const props = defineProps({
  items: {
    type: Array,
    default: () => ([]),
  },
  container_classes: {
    type: String,
    default: '',
  },
  scroll_amount: {
    type: Number,
    default: 250,
  },
});

const state = reactive({
  scrollAmount: 0,
  scrollWidth: Number.MAX_SAFE_INTEGER,
  unique_id: null,
});

function generateUniqueId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function onLeftButtonClick() {
  state.scrollAmount = Math.max(state.scrollAmount - props.scroll_amount, 0);
  document.querySelector(`#${state.unique_id}`).scrollTo({
    top: 0,
    left: state.scrollAmount,
    behavior: 'smooth',
  });
}

function onRightButtonClick() {
  const element = document.getElementById(state.unique_id);
  state.maxScroll = element.scrollWidth - element.clientWidth;
  state.scrollAmount = Math.min(state.scrollAmount + props.scroll_amount, state.maxScroll);
  document.querySelector(`#${state.unique_id}`).scrollTo({
    top: 0,
    left: state.scrollAmount,
    behavior: 'smooth',
  });
}

function onContainerScroll(e) {
  state.maxScroll = e.target.scrollWidth - e.target.clientWidth;
  state.scrollAmount = Math.min(Math.ceil(e.target.scrollLeft), state.maxScroll);
}

onMounted(() => {
  state.unique_id = generateUniqueId(8);
});
</script>

<template>
  <div class="flex items-center gap-4">
    <div class="bg-white hover:bg-gray-50 h-9 w-9 rounded-full border border-gray-300 flex-shrink-0 grid place-content-center cursor-pointer" :class="{ 'pointer-events-none opacity-50': state.scrollAmount === 0 }" @click="onLeftButtonClick">
      <IconHawkArrowLeft />
    </div>
    <div :id="state.unique_id" class="grid grid-flow-col gap-4 overflow-x-auto snap-type" :class="container_classes" @scroll="onContainerScroll">
      <div v-for="item in items" :key="item.id">
        <slot :content="item" name="content" />
      </div>
    </div>
    <div class="bg-white hover:bg-gray-50 h-9 w-9 rounded-full border border-gray-300 flex-shrink-0 grid place-content-center cursor-pointer ml-auto" :class="{ 'pointer-events-none opacity-50': state.scrollAmount === state.maxScroll }" @click="onRightButtonClick">
      <IconHawkArrowRight />
    </div>
  </div>
</template>

<style scoped>
.snap-type {
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.snap-type > * {
  scroll-snap-align: start;
}

.snap-type::-webkit-scrollbar {
  display: none;
}
</style>
