import { useRoute } from 'vue-router';

// stores
import { computed, inject, watch } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store';

// constants
import { FORM_PERMISSIONS } from '~/forms/constants';

export function useFormPermissions() {
  const route = useRoute();
  const auth_store = useAuthStore();
  const permission_form_template_store = useFormTemplatesStore('form-templates-permission-access');
  const $t = inject('$t');

  const asset_id = route.params.asset_id;

  function sync_template_permission() {
    permission_form_template_store.set_form_templates({
      query: {
        isChild: false,
        public: false,
        status: 'published',
        schema: false,
        asset_uid: !route?.params?.asset_id ? 'Organization' : route?.params?.asset_id,
      },
    });
  }

  function syncAndGetButtonState() {
    sync_template_permission();

    watch(() => route.params.asset_id, (val, old_val) => {
      if (val && old_val && val !== old_val)
        sync_template_permission();
    });

    const button_state = computed(() => {
      const state = {
        state: 'hidden',
        tooltip: '',
      };
      const template_uid = route.params?.template_uid;

      if (template_uid) {
        const template_data = permission_form_template_store.form_templates_map[template_uid];

        if (!template_data) {
          return state;
        }
        else {
          return template_data.can_create_forms
            ? {
                state: 'enabled',
                tooltip: '',
              }
            : state;
        }
      }

      const templates_with_create_access = permission_form_template_store.form_templates.filter(template => template.can_create_forms);

      if (auth_store.check_permission(FORM_PERMISSIONS.V2_CREATE_FORMS, asset_id) && templates_with_create_access.length === 0) {
        state.state = 'disabled';
        state.tooltip = 'No published templates found';
      }
      else if (templates_with_create_access.length > 0) {
        state.state = 'enabled';
        state.tooltip = '';
      }

      return state;
    });

    return button_state;
  }

  const share_access_levels = [
    ...(auth_store.check_split('forms_create_only_permission') ? [{ name: 'create_only', label: $t('Creator'), description: $t('Create new forms.') }] : []),
    { name: 'read', label: $t('Basic'), description: $t('Access all forms.') },
    { name: 'create', label: $t('Advanced'), description: $t('Access all forms and create new forms.') },
    { name: 'submit', label: $t('Manager'), description: $t('Access all forms, create new forms and submit any form') },
    { name: 'write', label: $t('Admin'), description: $t('Access, modify, submit and delete any form. Create new forms.') },
  ];

  return {
    sync_template_permission,
    share_access_levels,
    syncAndGetButtonState,
  };
}
