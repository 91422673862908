<script setup>
// stores
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  tree_item: {
    type: Object,
    required: true,
  },
  move: {
    type: Boolean,
    required: false,
    default: false,
  },
  selected_item: {
    type: String,
    required: false,
    default: 'null',
  },
});

const emit = defineEmits(['itemClick']);

const auth_store = useAuthStore();
const item = computed(() => {
  try {
    return JSON.parse(props.tree_item.name);
  }
  catch (e) {
    return props.tree_item;
  }
});
const item_name = computed(() => {
  try {
    return JSON.parse(props.tree_item.name).name;
  }
  catch (e) {
    return props.tree_item.name;
  }
});
function onItemClick() {
  emit('itemClick', item.value);
}
</script>

<template>
  <div v-if="item_name === tree_item.key && item_name !== 'null' && !tree_item.data" class="flex items-center folder-tree py-2" :class="{ selected_branch: selected_item === tree_item.key }" @click="onItemClick">
    <IconHawkFolder class="mr-3 text-gray-500" />
    <hawk-asset-name :uid="tree_item.key" asset_class="text-gray-700 font-medium files text-sm" />
  </div>

  <div v-else-if="item_name === 'null'" class="flex items-center folder-tree py-2" :class="{ selected_branch: selected_item === tree_item.key }" @click="onItemClick">
    <IconHawkFolder class="mr-3 text-gray-500" />
    <hawk-organization-name organization_class="text-gray-700 font-medium files text-sm" :uid="auth_store.current_organization?.uid" />
  </div>

  <div v-else-if="move" class="flex items-center folder-tree py-2" :class="{ selected_branch: selected_item === tree_item.key }" @click="onItemClick">
    <IconHawkFolder class="mr-3 text-gray-500" />
    <span v-tippy="item_name.length > 20 ? item_name : ''" class="max-w-[200px] truncate text-sm text-gray-700 font-medium">
      {{ tree_item.data.row.original.name }}
    </span>
  </div>

  <div v-else class="flex items-center folder-tree py-2" :class="{ selected_branch: selected_item === tree_item.key }" @click="onItemClick">
    <IconHawkFolder class="mr-3 text-gray-500" />
    <span v-tippy="item_name.length > 20 ? item_name : ''" class="max-w-[200px] truncate text-sm text-gray-700 font-medium">
      {{ item_name }}
    </span>
  </div>
</template>
