<script setup>
import { debounce } from 'lodash-es';
import { useModal } from 'vue-final-modal';

import { watch } from 'vue';
import ChangeDocumentStatusModal from '~/dms/components/settings/change-document-status-modal.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DmsNewCustomStatusModal from '~/dms/components/settings/dms-new-custom-status-modal.vue';
import DmsNewIssuePurposeModal from '~/dms/components/settings/dms-new-issue-purpose-modal.vue';
import HawkConfirmationPopup from '~/common/components/organisms/hawk-confirmation-popup.vue';

import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';

const $t = inject('$t');
const $toast = inject('$toast');
const dms_settings_store = useDMSSettingsStore();
const transmittal_permissions = useTransmittalPermissions();

const document_status_header = ['Status name', ''];
const review_status_header = ['Status name'];

const review_status_list = computed(() => dms_settings_store.review_status_list);

const is_fetching_document_status = ref(false);
const selected_color = ref('');
const form$ = ref(null);

const form = ref({
  document_status_list: [],
  issue_purposes: [],
  review_status_list: review_status_list.value,
});

const state = reactive({
  is_loading: false,
});

const configuration = computed(() => dms_settings_store.configuration);

const { open: open_delete_popup, close: close_delete_popup, patchOptions: patch_delete_popup } = useModal({
  component: HawkDeletePopup,
  attrs: {
    header: $t('Delete document status'),
    button_text: $t('Delete'),
    onClose() {
      close_delete_popup();
    },
  },
});

const { open: open_status_change_popup, close: close_status_change_popup, patchOptions: patch_status_change_popup } = useModal({
  component: ChangeDocumentStatusModal,
  attrs: {
    onAddStatus: value => form.value.document_status_list.push(...value),
    onClose() {
      close_status_change_popup();
    },
  },
});

const { open: open_new_status_popup, close: close_new_status_popup, patchOptions: patch_new_status_popup } = useModal({
  component: DmsNewCustomStatusModal,
  attrs: {
    onClose() {
      close_new_status_popup();
    },
  },
});

const { open: open_new_issue_purpose_popup, close: close_new_issue_purpose_popup, patchOptions: patch_new_issue_purpose_popup } = useModal({
  component: DmsNewIssuePurposeModal,
  attrs: {
    onClose() {
      close_new_issue_purpose_popup();
    },
  },
});

const update_color = debounce((index, color) => {
  form.value.document_status_list[index].color = color;
}, 100);

function editDocumentStatus(index) {
  patch_new_status_popup({
    attrs: {
      is_edit: true,
      form_values: form.value.document_status_list[index],
      onSave(value) {
        if (!value?.data?.errorCode)
          form.value.document_status_list[index] = value[0];
      },
    },
  });
  open_new_status_popup();
}

function editIssuePurpose(index) {
  patch_new_issue_purpose_popup({
    attrs: {
      is_edit: true,
      form_values: form.value.issue_purposes[index],
      onSave(value) {
        if (!value?.data?.errorCode)
          form.value.issue_purposes[index] = value[0];
      },
    },
  });
  open_new_issue_purpose_popup();
}

function migrateAndDeleteDocumentStatus(index) {
  patch_status_change_popup({
    attrs: {
      delete_status: form.value.document_status_list[index],
      async onSave(value) {
        await dms_settings_store.delete_document_status(form.value.document_status_list[index].uid, { document_status: { to_status: value.to_status } });
        form$.value.elements$.document_status_list.remove(index);
        close_status_change_popup();
      },
    },
  });
  open_status_change_popup();
}

function deleteDocumentStatus(index) {
  patch_delete_popup({
    attrs: {
      content: `${$t('Are you sure you want to delete document status')} ?`,
      confirm: async () => {
        const error_data = await dms_settings_store.delete_document_status(form.value.document_status_list[index].uid);
        if (error_data)
          migrateAndDeleteDocumentStatus(index);

        else
          form$.value.elements$.document_status_list.remove(index);

        close_delete_popup();
      },
    },
  });
  open_delete_popup();
}

const { open: open_delete_issue_purpose_popup, close: close_delete_issue_purpose_popup, patchOptions: patch_issue_purpose_popup } = useModal({
  component: HawkDeletePopup,
  attrs: {
    header: $t('Delete issue purpose'),
    button_text: $t('Delete'),
    onClose() {
      close_delete_issue_purpose_popup();
    },
  },
});

function deleteIssuePurpose(index) {
  patch_issue_purpose_popup({
    attrs: {
      content: `${$t('Are you sure you want to delete issue purpose')} ?`,
      confirm: async () => {
        const error_data = await dms_settings_store.delete_issue_purposes(form.value.issue_purposes[index].uid);
        if (error_data)
          throw new Error(error_data);
        else
          form$.value.elements$.issue_purposes.remove(index);
        close_delete_issue_purpose_popup();
      },
    },
  });
  open_delete_issue_purpose_popup();
}

function toggleDocumentStatusActivation(index) {
  if (form.value.document_status_list[index].active)
    dms_settings_store.deactivate_document_status(form.value.document_status_list[index].uid);
  else
    dms_settings_store.activate_document_status(form.value.document_status_list[index].uid);

  form.value.document_status_list[index].active = !form.value.document_status_list[index].active;
}

function addNewStatus() {
  patch_new_status_popup({
    attrs: {
      is_edit: false,
      form_values: null,
      onSave(value) {
        form.value.document_status_list.push(...value);
      },
    },
  });
  open_new_status_popup();
}

function addNewIssuePurpose() {
  patch_new_issue_purpose_popup({
    attrs: {
      is_edit: false,
      form_values: null,
      onSave(value) {
        form.value.issue_purposes.push(...value);
      },
    },
  });
  open_new_issue_purpose_popup();
}

function handleCustomColor(event, index) {
  selected_color.value = event.target.value;
  update_color(index, event.target.value);
}

watch(() => configuration?.value?.uid, (value) => {
  if (value)
    form.value.review_status_list = review_status_list.value;
}, { immediate: true });

onMounted (async () => {
  try {
    is_fetching_document_status.value = true;
    const p1 = dms_settings_store.fetch_all_statuses();
    const p2 = dms_settings_store.fetch_all_issue_purposes();
    await Promise.all([p1, p2]);
    form.value.document_status_list = dms_settings_store.document_status;
    form.value.issue_purposes = dms_settings_store.issue_purposes;
    form.value.enable_version_numbering = dms_settings_store?.configuration?.enable_version_numbering;
  }
  catch (e) {
    logger.error(e);
  }
  is_fetching_document_status.value = false;
});

async function submitHandler(form$) {
  try {
    state.is_loading = true;
    const review_status_list = form$.data?.review_status_list;
    const config = review_status_list?.reduce((list, status) => {
      list[status.slug] = status.name;
      return list;
    }, {});
    if (configuration.value?.uid)
      await dms_settings_store.update_configuration(configuration.value?.uid, { config });
    else
      await dms_settings_store.add_configuration({ config });
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

const { open: openConfirmationPopup, close: closeConfirmationPopup, patchOptions: patchConfirmationPopup } = useModal({
  component: HawkConfirmationPopup,
});
</script>

<template>
  <Vueform
    ref="form$"
    v-model="form"
    sync
    class="mt-4"
    size="sm"
    :display-errors="false"
    @submit="submitHandler"
  >
    <div class="col-span-12">
      <!-- Document Statuses -->
      <div class="max-w-[700px]">
        <div class="flex flex-col mb-2">
          <span class="text-lg font-semibold text-gray-900">{{ $t('Document Statuses') }}</span>
          <span class="text-xs text-gray-600">{{ $t("Customize document statuses to match your organization's preferences and apply them to your documents. These statuses can also be used for mapping review statuses from transmittals.") }}</span>
        </div>
        <HawkLoader v-if="is_fetching_document_status" class="!m-0 mb-8" />
        <div class="mb-8">
          <ListElement
            name="document_status_list" :controls="{ add: false, sort: false, remove: false }"
          >
            <template #default="{ index }">
              <ObjectElement
                :name="index"
                :columns="{
                  default: { container: 8, label: 0, wrapper: 12 },
                  sm: { container: 8, label: 0, wrapper: 12 },
                  md: { container: 8, label: 0, wrapper: 12 },
                }"
              >
                <div
                  class="h-3 rounded-full w-3 my-3 mx-auto"
                  :style="{ backgroundColor: form?.document_status_list[index]?.color }"
                />
                <div class="col-span-8 cursor-pointer" @click="!transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }) || !form?.document_status_list[index]?.active ? null : editDocumentStatus(index)">
                  <TextElement
                    :columns="{
                      default: { container: 9, label: 0, wrapper: 12 },
                      sm: { container: 9, label: 0, wrapper: 12 },
                      md: { container: 9, label: 0, wrapper: 12 },
                    }"
                    :disabled="!transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }) || !form?.document_status_list[index]?.active"
                    :placeholder="$t('Enter status name')"
                    class="pointer-events-none"
                    rules="required"
                    name="name"
                  />
                </div>
                <HawkMenu
                  additional_trigger_classes="ring-transparent"
                  :items="[
                    {
                      label: form?.document_status_list[index]?.active ? 'Deactivate' : 'Activate',
                      uid: 'activation',
                      disabled: !transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }),
                      on_click: () => {
                        toggleDocumentStatusActivation(index);
                      },
                    },
                    {
                      label: 'Edit',
                      uid: 'edit',
                      disabled: !transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }) || !form?.document_status_list[index]?.active,
                      on_click: () => {
                        editDocumentStatus(index);
                      },
                    },
                    {
                      label: 'Delete',
                      uid: 'delete',
                      disabled: !transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }),
                      on_click: () => {
                        deleteDocumentStatus(index);
                      },
                    },
                  ]"
                />
              </ObjectElement>
            </template>
          </ListElement>
          <HawkButton v-if="!is_fetching_document_status" type="link" :disabled="!transmittal_permissions.checkTransmittalPermission({ permission: 'add_document_status' })" @click="addNewStatus">
            + {{ $t('New Status') }}
          </HawkButton>
        </div>
      </div>
      <hr class="my-6">
      <!-- Review Statuses -->
      <div class="max-w-[700px]">
        <div class="flex flex-col mb-4">
          <span class="text-lg font-semibold text-gray-900">{{ $t('Default review statuses') }}</span>
          <span class="text-xs text-gray-600">{{ $t("Manage document reviews with our predefined statuses, which can be renamed as needed. Customize these statuses during transmittal creation to fit different workflows. 'Rejected' and 'Revise and Resubmit' are default reject statuses used for sending documents back to the initiator") }}</span>
        </div>
        <!-- Table -->
        <div class="mb-8 max-w-[400px]">
          <!-- Table data -->
          <ListElement
            name="review_status_list"
            :controls="{ add: false, sort: false, remove: false }"
          >
            <template #default="{ index }">
              <ObjectElement
                :name="index"
                :columns="{
                  default: { container: 12, label: 0, wrapper: 12 },
                  sm: { container: 12, label: 0, wrapper: 12 },
                  md: { container: 12, label: 0, wrapper: 12 },
                }"
              >
                <GroupElement name="review_status_group">
                  <div class="col-span-12 grid grid-cols-12">
                    <div class="h-3 rounded-full w-3 my-3 mx-auto" :style="{ backgroundColor: form?.review_status_list[index]?.color }" />
                    <TextElement
                      :placeholder="$t('Enter status name')"
                      rules="required"
                      name="name"
                      :disabled="!transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' })"
                      :columns="{
                        default: { container: 11, label: 0, wrapper: 12 },
                        sm: { container: 11, label: 0, wrapper: 12 },
                        md: { container: 11, label: 0, wrapper: 12 },
                      }"
                    />
                  </div>
                </GroupElement>
              </ObjectElement>
            </template>
          </ListElement>
        </div>
      </div>
      <hr class="my-6">
      <div class="max-w-[700px]">
        <div class="flex flex-col mb-2">
          <span class="text-lg font-semibold text-gray-900">{{ $t('Issue purpose') }}</span>
          <span class="text-xs text-gray-600">{{ $t('Create custom issue purposes to explain the intent of sending documents. Use these Issue purposes to create document version numbers. Each version number will include the issue purpose code and a unique number in sequence.') }}</span>
        </div>
        <HawkLoader v-if="is_fetching_document_status" class="!m-0 mb-8" />
        <div class="mb-8">
          <ListElement
            name="issue_purposes" :controls="{ add: false, sort: false, remove: false }"
          >
            <template #default="{ index }">
              <ObjectElement
                :name="index"
                :columns="{
                  default: { container: 8, label: 0, wrapper: 12 },
                  sm: { container: 8, label: 0, wrapper: 12 },
                  md: { container: 8, label: 0, wrapper: 12 },
                }"
              >
                <div class="col-span-8 cursor-pointer" @click="!transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }) ? null : editIssuePurpose(index)">
                  <TextElement
                    :columns="{
                      default: { container: 10, label: 0, wrapper: 12 },
                      sm: { container: 10, label: 0, wrapper: 12 },
                      md: { container: 10, label: 0, wrapper: 12 },
                    }"
                    :addons="{ before: form?.issue_purposes[index]?.code ? `(${form?.issue_purposes[index]?.code})` : '' }"
                    :disabled="!transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }) || !form?.issue_purposes[index]?.active"
                    :placeholder="$t('Enter name')"
                    class="pointer-events-none"
                    rules="required"
                    name="name"
                  />
                </div>
                <HawkMenu
                  additional_trigger_classes="ring-transparent"
                  :items="[
                    {
                      label: 'Edit',
                      uid: 'edit',
                      disabled: !transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }) || !form?.issue_purposes[index]?.active,
                      on_click: () => {
                        editIssuePurpose(index);
                      },
                    },
                    {
                      label: 'Delete',
                      uid: 'delete',
                      disabled: !transmittal_permissions.checkTransmittalPermission({ permission: 'modify_document_status' }),
                      on_click: () => {
                        deleteIssuePurpose(index);
                      },
                    },
                  ]"
                />
              </ObjectElement>
            </template>
          </ListElement>
          <HawkButton v-if="!is_fetching_document_status" type="link" :disabled="!transmittal_permissions.checkTransmittalPermission({ permission: 'add_document_status' })" @click="addNewIssuePurpose">
            + {{ $t('New issue purpose') }}
          </HawkButton>
        </div>
      </div>
      <!-- Footer -->
      <div class="flex justify-end my-4">
        <HawkButton color="primary" type="outlined" class="mr-3">
          {{ $t('Cancel') }}
        </HawkButton>
        <ButtonElement
          submits
          :loading="state.is_loading"
          name="assign_drawing_submit"
          :button-label="$t('Save')"
          button-class="vf-btn-primary"
        />
      </div>
    </div>
  </Vueform>
</template>
