<script setup>
import { computed } from 'vue';

const props = defineProps({
  transmittal_messages: {
    type: Array,
    default: () => [],
  },
  is_public_page: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  is_collapsed: false,
});

const messages = computed(() => {
  const transmittal = (props.transmittal_messages || []).filter(message => message.message?.length > 0 || message.attachment?.length > 0);

  if (!transmittal.length)
    return [];
  if (state.is_collapsed || transmittal.length <= 2)
    return transmittal;
  return [transmittal[0], transmittal[transmittal.length - 1]];
});

function collapseMessage() {
  state.is_collapsed = true;
}
</script>

<template>
  <div v-if="messages?.length" :key="messages?.length" class="col-span-12 border-t">
    <template v-for="(message, index) in messages" :key="index">
      <div v-if="messages?.length > 2 && !state.is_collapsed && index === 1" class="relative">
        <HawkButton type="outlined" class="!absolute !rounded-full z-1 -top-5 w-9 flex justify-center items-center group" @click="collapseMessage">
          <span class="text-gray-700 font-semibold group-hover:hidden">
            {{ messages?.length - 2 }}
          </span>
          <span class="hidden group-hover:flex">
            <IconHawkChevronSelectorVertical />
          </span>
        </HawkButton>
        <div class="mt-0.5 border-t -mx-4" />
      </div>
      <transmittal-detail-message
        class="relative border-b -mx-4 pb-4 px-4"
        :transmittal="message"
        :is_public_page="is_public_page"
        :is_last_collapsed="index === (messages?.length - 1)"
      />
    </template>
  </div>
</template>
