const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
let element = null;

function preventDefault(e) {
  if (element && e.target.offsetParent?.classList?.value !== element.classList.value)
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
        return true;
      },
    }),
  );
}
catch (e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent
  = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

export const FixedPosition = {
  mounted(el, binding) {
    if (binding.value === true) {
      element = el;
      window.addEventListener('wheel', preventDefault, {
        passive: false,
      });
      window.addEventListener('scroll', preventDefault, false);
      window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
      window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);

      const position = el.parentElement.getBoundingClientRect();
      el.style.position = 'fixed';
      el.style.top = `${position.bottom}px`;
      el.style.left = `${position.left}px`;
      el.style.zIndex = 100;
    }
  },
  unmounted() {
    window.removeEventListener('wheel', preventDefault, { passive: false });
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  },
};
