<script setup>
import { Validator } from '@vueform/vueform';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  is_edit: {
    type: Boolean,
    default: false,
  },
  form_values: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(['close', 'save']);

const dms_settings_store = useDMSSettingsStore();

const form = ref({});
const $t = inject('$t');

async function onConfirmHandler() {
  let data = {};
  if (props?.form_values?.uid)
    data = await dms_settings_store.update_issue_purposes(props.form_values.uid, form.value);
  else
    data = await dms_settings_store.create_issue_purposes(form.value);

  emit('save', data);
  emit('close');
}

function formMounted(el$) {
  if (props?.form_values)
    el$.load(props.form_values);
}

const alphabetical = class extends Validator {
  check(value) {
    return /^[A-Za-z]*$/.test(value);
  }

  get message() {
    return $t('Only letters are allowed');
  }
};
</script>

<template>
  <hawk-modal-container>
    <Vueform
      v-model="form"
      size="sm"
      class="w-full"
      :display-errors="false"
      :endpoint="onConfirmHandler"
      :columns="{
        default: { container: 12, wrapper: 4, label: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('close')">
          <template #title>
            {{ is_edit ? $t('Edit issue purpose') : $t('New issue purpose') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <TextElement
            :placeholder="$t('Enter name')"
            rules="required"
            name="name"
            :label="$t('Name')"
          />
          <TextElement
            class="mt-4"
            :placeholder="$t('Enter description')"
            name="description"
            :label="$t('Description')"
          />
          <TextElement
            class="mt-4"
            :placeholder="$t('Enter prefix')"
            name="code"
            :rules="[alphabetical, 'max:3']"
            :label="$t('Prefix')"
            :description="$t('Set up issue purpose prefixes, which are short codes that can be used in document version numbering (Ex A for draft, B for review)')"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <div class="flex">
                <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits name="submit_status" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
