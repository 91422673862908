import DeleteSVG from '../assets/icons/delete.svg';
import EditSVG from '../assets/icons/edit.svg';

const white = '#FFFFFF';
const grey = '#F2F4F7';
const black = '#000000';
const node_bg = '#FFFFFF';
const node_stroke = '#EAECF0';

export function NodeShape(dia) {
  return dia.Element.define(
    'workflow.Node',
    {
      size: { width: 350, height: 64 },
      attrs: {
        body: {
          refWidth: '100%',
          refHeight: '100%',
          fill: node_bg,
          stroke: node_stroke,
          strokeWidth: 1,
          rx: 8,
          ry: 8,
        },
        label: {
          refX: 58,
          refY: 12,

          fontFamily: 'Inter, sans-serif',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: 19,
          fill: black,
          text: 'Label',
          textWrap: {
            width: -120,
            maxLineCount: 1,
            ellipsis: true,
          },
          textVerticalAnchor: 'top',
        },
        description: {
          refX: 58,
          refY: 37,
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          fontSize: 12,
          lineHeight: 15,
          fill: '#9C9FB1',

          text: 'Description',
          textWrap: {
            width: -95,
            maxLineCount: 1,
            ellipsis: true,
          },
        },
        icon: {
          refX: 16,
          refY: '50%',
        },
        iconBody: {
          r: 16,
          fill: '#F5F5F5',
          refX: 16,
        },
        iconImage: {
          width: 35,
          height: 35,
          refX: -5,
          refY: -17,
        },
      },
    },
    {
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
        {
          tagName: 'text',
          selector: 'label',
        },
        {
          tagName: 'text',
          selector: 'description',
        },
        {
          tagName: 'g',
          selector: 'icon',
          children: [
            {
              tagName: 'circle',
              selector: 'iconBody',
            },
            {
              tagName: 'image',
              selector: 'iconImage',
            },
          ],
        },
      ],
    },
  );
}

const nodeShape = [
  {
    size: { width: 64, height: 64 },
    attrs: {
      body: {
        refWidth: '100%',
        refHeight: '100%',
        fill: 'transparent',
        strokeWidth: 0,
      },
      node: {
        refWidth: '100%',
        refHeight: '100%',
        fill: '#F79009',

        rx: '50%',
        ry: '50%',
      },
      label: {
        refX: '16',
        refY: '22',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 19,
        fill: white,
        text: 'End',
        textWrap: {
          width: -10,
          maxLineCount: 1,
          ellipsis: true,
        },
        textVerticalAnchor: 'top',
      },
    },
  },
  {
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'rect',
        selector: 'node',
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ],
  },
];

export function EndNodeShape(dia) {
  const endShape = [...nodeShape];
  endShape[0].attrs.node.fill = '#F79009';
  endShape[0].attrs.label = {
    ...endShape[0].attrs.label,
    refX: '27%',
    refY: '38%',
    text: 'End',
  };
  return dia.Element.define(
    'workflow.EndNode',
    ...endShape,
  );
}

export function StartNodeShape(dia) {
  const startShape = [...nodeShape];
  startShape[0].attrs.node.fill = '#12B76A';
  startShape[0].attrs.label = {
    ...startShape[0].attrs.label,
    refX: '13',
    refY: '38%',
    text: 'Start',
  };
  return dia.Element.define(
    'workflow.StartNode',
    ...startShape,
  );
}

export function AddButtonNodeShape(dia) {
  return dia.Element.define(
    'workflow.AddButtonNode',
    {
      size: { width: 0, height: 0 },
      attrs: {
        cursor: 'pointer',
      },
    },
    {
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },
      ],
    },
  );
}

export const button_markups = {
  add: [
    {
      tagName: 'g',
      selector: 'nodeAddButton',
      attributes: {
        'cursor': 'pointer',
        'fill': white,
        'stroke': '#4666E5',
        'stroke-width': 2,
        'refX': '50%',
        'refX2': 20,
        'refY': '100%',
        'z': 3,
      },
      children: [
        {
          tagName: 'rect',
          selector: 'nodeAddButtonBody',
          attributes: {
            width: 20,
            height: 20,
            rx: 20,
            ry: 20,
            x: -10,
            y: -10,
          },
        },
        {
          tagName: 'path',
          selector: 'nodeAddButtonIcon',
          attributes: {
            'd': 'M -4 0 4 0 M 0 -4 0 4',
            'fill': '#4666E5',
            'stroke-width': 2,
          },
        },
      ],
    },
  ],
  edit: [
    {
      tagName: 'g',
      selector: 'nodeEditButton',
      attributes: {
        cursor: 'pointer',
        fill: 'transparent',
        refX: '50%',
        refX2: -6,
        refY: '100%',
        z: 3,

      },
      children: [
        {
          tagName: 'image',
          selector: 'edit',
          attributes: {
            'xlink:href': EditSVG,
            'height': 15,
            'width': 15,
          },
        },
      ],
    },
  ],
  delete: [
    {
      tagName: 'g',
      selector: 'nodeEditButton',
      attributes: {
        cursor: 'pointer',
        fill: 'transparent',
        refX: '50%',
        refX2: -6,
        refY: '100%',
        z: 3,
      },
      children: [
        {
          tagName: 'image',
          selector: 'delete',
          attributes: {
            'xlink:href': DeleteSVG,
            'height': 15,
            'width': 15,
          },
        },
      ],
    },
  ],
};

export function LinkShape(dia) {
  return dia.Link.define(
    'workflow.Link',
    {
      attrs: {
        root: {
          cursor: 'pointer',
        },
        line: {
          stroke: '#4666E5',
          strokeWidth: 2,
          connection: true,
          targetMarker: null,
          fill: 'none',
        },
      },
    },
    {
      markup: [
        {
          tagName: 'path',
          selector: 'line',
        },
      ],
    },
  );
}

export function LinkLabelShape(dia) {
  return dia.Element.define(
    'workflow.LinkLabelNode',
    {
      size: { width: 140, height: 25 },
      attrs: {
        body: {
          refWidth: '100%',
          refHeight: '100%',

          fill: black,
          rx: 5,
          ry: 5,
        },

        label: {
          refX: '50%',
          refY: '49%',
          refHeight: '100%',

          fontFamily: 'Inter, sans-serif',
          fontWeight: 500,
          fontSize: 14,
          yAlignment: 'middle',
          fill: white,
          text: 'End Block',
          xAlignment: 'middle',

          textWrap: {
            width: 140,
            maxLineCount: 1,
            ellipsis: true,
          },
        },
        rule_id: {
          fill: 'transparent',
          text: '',
          refX: 0,
          refY: 0,
        },
      },
    },
    {
      markup: [
        {
          tagName: 'rect',
          selector: 'body',
        },

        {
          tagName: 'text',
          selector: 'label',
        },
      ],
    },
  );
}
