<script setup>
const props = defineProps({
  uid: {
    type: String,
    required: true,
  },
  enable_click: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['badgeClicked']);
const task_store = inject('task_store');
const task = computed(() => task_store.get_current_task(props.uid));

function handleBadgeClicked() {
  if (props.enable_click)
    emit('badgeClicked');
}
</script>

<template>
  <div v-if="task" class="whitespace-nowrap">
    <!-- B42318 -->
    <HawkBadge
      v-if="task.linked_uids?.length"
      v-tippy="`Linked with ${task.linked_uids.length} tasks`"
      class="h-8 mx-1"
      color="blue"
      :class="{ 'cursor-pointer': enable_click }"
      @click="handleBadgeClicked"
    >
      <IconHawkDependencyLinked class="text-primary h-3" />
      {{ task.linked_uids.length }}
    </HawkBadge>
    <!-- #FFFAEB -->
    <HawkBadge
      v-if="task.blocked_by?.length"
      v-tippy="`Waiting on ${task.blocked_by.length} tasks`"
      class="h-8 mx-1"
      color="yellow"
      :class="{ 'cursor-pointer': enable_click }"
      @click="handleBadgeClicked"
    >
      <IconHawkDependencyWaitingOn class="text-[#B42318] h-3" />
      {{ task.blocked_by.length }}
    </HawkBadge>
    <!-- #EFF8FF -->
    <HawkBadge
      v-if="task.blocking?.length"
      v-tippy="`Blocking ${task.blocking.length} tasks`"
      class="h-8 mx-1"
      color="red"
      :class="{ 'cursor-pointer': enable_click }"
      @click="handleBadgeClicked"
    >
      <IconHawkDependencyBlocking class="text-[#B42318] h-3" />
      {{ task.blocking.length }}
    </HawkBadge>
  </div>
</template>
