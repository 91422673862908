<script setup>
// --------------------------------- Imports -------------------------------- //
import { snakeCase } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { FORM_PERMISSIONS } from '~/forms/constants';
import { searchData } from '~/common/utils/common.utils';
import { useStatusMetrics } from '~/forms/composables/form-list-configuration';
import FormOverDueCount from '~/forms/atoms/form-overdue-count.vue';
import FormStatusGroup from '~/forms/molecules/form-status-group.vue';
import FormsSearchAndFilter from '~/forms/forms-temp/components/forms-search-and-filter.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
const columns = ref([
  {
    header: $t('ID'),
    accessorKey: 'number',
    id: 'number',
    enableResizing: false,
    size: 100,
  }, {
    header: $t('Name'),
    accessorKey: 'name',
    id: 'total_overdue',
    enableResizing: false,
    size: 250,
  },
  {
    header: $t('Category'),
    accessorKey: 'category',
    id: 'category',
    enableResizing: false,
    sortingFn: (rowA, rowB, columnId) => {
      const category_name_A = getCategoryName(rowA.original.category);
      const category_name_B = getCategoryName(rowB.original.category);

      return category_name_A.localeCompare(category_name_B);
    },
  },
  {
    header: $t('Asset'),
    accessorKey: 'target_element',
    id: 'asset',
    size: 250,
    enableResizing: false,
    sortingFn: (rowA, rowB, columnId) => {
      const asset_name_A = getAssetName(rowA.original.target_element) || '';
      const asset_name_B = getAssetName(rowB.original.target_element) || '';

      return asset_name_A.localeCompare(asset_name_B);
    },
  },
  {
    header: $t('Status'),
    accessorKey: 'metrics',
    id: 'metrics',
    enableResizing: false,
    enableSorting: false,
  },
]);

// ------------------------ Variables - Local - refs ------------------------ //
const loading = ref(false);
const search = ref('');
const selected_forms = ref([]);
const available_templates = ref([]);
const applied_filters = ref(null);
const total_form_count = ref(0);

// ---------------------- Variables - Local - reactives --------------------- //
const table_state = reactive({
  page_size: 25,
  page_number: 1,
  table_loading: false,
  table_instance: null,
});

// --------------------------- Computed properties -------------------------- //
const filtered_data = computed(() => searchData(getSortedData(available_templates.value), search.value, ['name', 'category_name']));
const has_data = computed(() => !!filtered_data.value.length);
const has_filter_applied = computed(() => !!applied_filters.value || search.value);
const is_boarding = computed(() => !has_data.value && !has_filter_applied.value);
const has_no_results = computed(() => !has_data.value && has_filter_applied.value);

// -------------------------------- Functions ------------------------------- //
async function getData(options = {}) {
  const { extra_filters } = options;

  table_state.table_loading = true;

  const { data, headers } = await $services.forms.post({
    attribute: 'list-view',
    body: {
      filters: {
        all_access: true,
        status: 'published',
        metrics: true,
        group_by: 'template',
        templates: [],
        page_size: 100000000,
        ...(
          applied_filters.value
            ? {
                advanced_filters: { logic: { type: 'AND' }, rules: applied_filters.value.filters },
                [applied_filters.value.user_filter.value]: !!applied_filters.value.user_filter.value,
              }
            : {}
        ),
        ...extra_filters,
      },
    },
  });

  available_templates.value = data.templates;
  total_form_count.value = headers['x-total-count'];
  table_state.table_loading = false;
}

function getStatusMetrics(metric) {
  return useStatusMetrics(metric);
}

function getAssetName(target_element) {
  const asset_id = target_element?.asset || '';

  return asset_id && common_store.assets_map?.[asset_id]?.name;
};

function getCategoryName(category_id) {
  return category_id && common_store.get_category(category_id)?.name ? common_store.get_category(category_id).name : '';
}

function getOverdueMetrics(form) {
  const metric_data = form?.metrics || {};
  const overdue = Object.keys(metric_data).reduce((sum, key) => sum += (metric_data[key]?.due_metrics?.today || 0) + (metric_data[key]?.due_metrics?.overdue || 0), 0);
  return overdue;
}

function getSortedData(forms) {
  return forms?.map(form => ({ ...form, total_overdue: getOverdueMetrics(form) })).sort((form1, form2) => form2.total_overdue - form1.total_overdue) || [];
}

function routeToFormInstancesListPage(row, query = {}) {
  if (query.open) {
    const metric_data = row.metrics[snakeCase(query.open)];
    query.open = metric_data.block_uid || metric_data.step_index || query.open;
    query.status_type = 'submission_status';
    metric_data.block_uid && (query.status_type = 'block');
    metric_data.step_index && (query.status_type = 'step_index');
  }

  router.push({ name: 'form-instances', params: { template_uid: row.uid, asset_id: route.params.asset_id }, query });
}

function onSearch(val) {
  search.value = val;
}

async function onFilterApply({ filters, user_filter }) {
  applied_filters.value = { filters, user_filter };

  await getData({ });
}

// -------------------------------- Watchers -------------------------------- //
watch(() => route.params.asset_id, async () => {
  loading.value = true;
  await getData({});
  loading.value = false;
});

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(async () => {
  loading.value = true;
  await getData({});
  loading.value = false;
});
</script>

<template>
  <FormsSearchAndFilter :quick_filters="['current_user_access_filters', 'category', 'assignees', 'priority', 'due_date']" @search="onSearch" @apply="onFilterApply" />
  <div v-if="!has_data && (loading || table_state.table_loading)">
    <hawk-loader />
  </div>
  <HawkIllustrations v-else-if="has_no_results" type="no-results" for="forms" />
  <HawkIllustrations v-else-if="!has_data" variant="default" type="no-data" for="forms" />
  <HawkIllustrations v-else-if="is_boarding" type="on-boarding" for="forms" :is_create_indicator="auth_store.check_permission(FORM_PERMISSIONS.V2_CREATE_FORMS, route.params.asset_id)" />
  <TableWrapperVue v-else class="max-w-[calc(100vw-80px)] !max-h-[calc(100vh-65px)]" container_class="mt-0">
    <HawkTable
      :is_loading="table_state.table_loading"
      :pagination_config="{ totalRows: filtered_data.length, pageSize: 25 }"
      :data="filtered_data"
      :columns="columns"
      :is_gapless="true"
      :show_menu_header="false"
      :disable_resize="true"
      scroll_to_top_on_pagination
      @row-clicked=" $event => routeToFormInstancesListPage($event)"
      @tableInstance="table_state.table_instance = $event"
    >
      <template #total_overdue="form">
        <hawk-text :length="40" class="text-gray-900 font-medium" :content="form.data.row.original.name" />
        <FormOverDueCount
          class="ml-1"
          :overdue="form.data.row.original.due_metrics" :count="form.data.row.original.total_overdue"
          @click.native="routeToFormInstancesListPage(form.data.row.original, { group_by: 'due_date' })"
        />
      </template>
      <template #category="category">
        <HawkCategoryName v-if="category.data.getValue()" :truncate_length="20" :uid="category.data.getValue()" />
        <span v-else>-</span>
      </template>
      <template #metrics="metric">
        <div class="flex flex-wrap">
          <FormStatusGroup
            v-if="getStatusMetrics(metric).length" :data="getStatusMetrics(metric)"
            @click.native.prevent.stop
            @clicked="$event => routeToFormInstancesListPage(metric.data.row.original, { group_by: 'status', open: $event.name })"
          />
        </div>
      </template>
      <template #asset="asset">
        <div class="flex flex-wrap">
          <HawkText v-if="getAssetName(asset.data.getValue())" :length="30" class="text-gray-600" :content="getAssetName(asset.data.getValue())" />
          <span v-else>-</span>
        </div>
      </template>
    </HawkTable>
  </TableWrapperVue>
</template>
