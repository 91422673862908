<script setup>
import { debounce } from 'lodash-es';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  task_uid: {
    type: String,
    require: true,
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');

const task_store = useTasksStore();
const loading = ref(false);

const form$ = ref(null);
const tasks = ref([]);
const search_key = ref('');
onMounted(async () => {
  const { data } = await $services.tasks.getAll({ query: { page_number: 1, page_size: 5, q: search_key.value } });
  tasks.value = data.tasks;
});

watch(search_key, debounce(async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    const { data } = await $services.tasks.getAll({ query: { page_number: 1, page_size: 5, q: search_key.value } });
    tasks.value = data.tasks;
  }
}, 500));

async function apply() {
  loading.value = true;
  try {
    const payload = {
      parent_task_uid: form$.value.data.task_uid,
    };
    await task_store.update_tasks([props.task_uid], payload);
    task_store.task_track_events('Created', { method: 'Convert task', type: 'subtask', count: 1 }, props.task_uid);
    task_store.set_tasks_count(-1);
    emit('close', true);
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
      position: 'bottom-right',
    });
    emit('close');
  }
  loading.value = false;
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :should_validate_on_mount="false"
      :display-errors="false"
      :endpoint="apply"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Convert to subtask') }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            class="mb-4"
            track-by="name"
            label-prop="name"
            value-prop="uid"
            name="task_uid"
            :label="$t('Task')"
            :placeholder="$t('Select Task')"
            :rules="['required']"
            :search="true"
            :items="tasks.filter(task => task.uid !== task_uid)"
            @search-change="search_key = $event"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <HawkButton color="primary" type="outlined" class="mr-3" @click="emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-500" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
