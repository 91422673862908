<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import FormStatus from '~/forms/atoms/form-status.vue';
import {
  getPriorityObject,
} from '~/common/composables/kanban-helpers.js';

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
});
const router = useRouter();

const form_priority = computed(() => getPriorityObject('name')[props.form.priority || 'not_set']);
const due_date_class = computed(() => props.form.due_date && new Date(props.form.due_date) <= new Date() ? 'text-error-700' : 'text-gray-700');

function viewFormDetails() {
  router.push({
    query: {
      form: btoa(JSON.stringify({
        form_uid: props.form.uid,
        store_key: 'therm_forms_store',
      })),
    },
  });
}
</script>

<template>
  <div v-if="form" class="my-2">
    <div class="border rounded-lg p-3 hover:border-primary-700 group cursor-pointer" @click="viewFormDetails">
      <div class="whitespace-nowrap">
        <HawkAssetName :uid="form?.target_element?.asset" asset_class="text-xs font-regular text-gray-600" />
        <p class="text-sm font-medium text-gray-900 group-hover:text-primary-700">
          {{ form.name }}
        </p>
      </div>
      <FormStatus :form="form" class="-ml-2 pb-2" />
      <div class="flex justify-between pt-2 text-sm">
        <HawkCategoryName :uid="form.category" />
        <div class="text-sm whitespace-nowrap" :class="due_date_class">
          {{ form.due_date ? $date(form.due_date, 'DD MMMM YYYY') : '-' }}
        </div>
      </div>
      <div class="flex justify-between pt-1">
        <span class="flex items-center" :class="form_priority.priority_class_icon">
          <component :is="form_priority.icon" class="w-3 h-3" />
          <span class="pl-1 text-xs !flex !items-center" :class="form_priority.priority_class_label">
            {{ form_priority?.label }}
          </span>
        </span>
        <HawkMembers v-if="form.assignees?.length" class="font-medium" :members="form.assignees" type="badge" />
        <div v-else>
          {{ '-' }}
        </div>
      </div>
    </div>
  </div>
</template>
