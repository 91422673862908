<script setup>
const props = defineProps({
  form_detail: {
    type: Object,
    default: () => { },
  },
  submission: {
    type: Object,
    default: () => { },
  },
});
const emit = defineEmits(['close', 'trackEvent']);
const $date = inject('$date');
function viewHistory(uid) {
  const form_path = `${window.location.href}&submission_history=${uid}`;
  window.open(form_path);
  emit('trackEvent', 'Viewed past submission');
}
</script>

<template>
  <hawk-modal-container>
    <div class="col-span-12">
      <hawk-modal-header @close="emit('close')">
        <template #title>
          {{ $t('submission history') }}
        </template>
      </hawk-modal-header>
      <hawk-modal-content class="max-h-[500px] overflow-y-auto scrollbar">
        <div v-for="(step, idx) in submission?.step_history || []" :key="idx" class="flex justify-between pb-4">
          <div>
            <div class="pb-1">
              <span v-if="step?.from_step?.name" class="text-sm font-semibold">
                {{
                  ["forward", "hold"].includes(step?.type)
                    ? `${$t('Submitted for')} ${step?.from_step?.name}`
                    : `${$t('Rolled back from')} ${step?.from_step?.name
                      ? step?.from_step?.name
                      : $t('Submitted')
                    } ${$t('to')} ${step?.to_step?.name}`
                }}
              </span>
              <span v-else class="text-sm font-semibold">
                {{
                  ["forward", "hold"].includes(step?.type)
                    ? $t('form submitted') : step?.type === "reopen" ? $t('Form reopened due to') : $t('Form rolledback due to')
                }}
                <span class="font-normal">
                  {{
                    step?.message ? `${step?.message}` : ''
                  }}
                </span>
              </span>
            </div>
            <div>
              <div class="flex items-center">
                <HawkMembers
                  class="pl-0.5 mr-2"
                  :members="step.submitted_by"
                  type="badge"
                />
                <span class="text-xs text-[#475467]">{{ $date(step?.submitted_at, "L_DATETIME_MED") }}</span>
              </div>
            </div>
          </div>
          <div>
            <HawkButton type="outlined" text="Cancel" @click="viewHistory(step.uid)">
              {{ $t('View') }}
            </HawkButton>
          </div>
        </div>
      </hawk-modal-content>
    </div>
  </hawk-modal-container>
</template>
