import { compact, isString } from 'lodash-es';
import { Validator } from '@vueform/vueform';

export const FIELDS_PROPERTY = {
  checkbox: {
    properties: { checkbox: true },
    type: 'labels',
  },
  radio: {
    properties: { radio: true },
    type: 'dropdown',
  },
  money: {
    properties: { currency: 'AED' },
    type: 'number',
  },
};

export const FIELDS_TYPE = [
  {
    value: 'text',
    label: 'Text',
  },
  {
    value: 'checkbox',
    label: 'Checkbox',
  },
  {
    value: 'radio',
    label: 'Radio',
  },
  {
    value: 'labels',
    label: 'Labels',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'dropdown',
    label: 'Dropdown',
  },
  {
    value: 'url',
    label: 'URL',
  },
  {
    value: 'money',
    label: 'Money',
  },
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'file',
    label: 'File',
  },
];

export function fieldValueMap(fields = []) {
  const field_values = {};
  fields.forEach((element) => {
    field_values[element.field.uid] = element;
  });
  return field_values;
}

export function removeNullProperties(data) {
  const new_obj = {};
  for (const [key, value] of Object.entries(data))
    if (value === '')
      new_obj[key] = null;
    else
      new_obj[key] = value;
  return new_obj;
}

export function createNewFieldPayload(data, category) {
  const body = {
    ...(category ? { category } : {}),
    type: FIELDS_PROPERTY[data.type]?.type || data.type,
    name: data.name,
    organization: data.organization,
    ...(data.config ? { config: data.config } : {}),
    ...(FIELDS_PROPERTY[data.type] ? { properties: FIELDS_PROPERTY[data.type].properties } : {}),
  };
  if (data.type === 'money')
    body.properties = data.properties;

  return body;
}

export function createListOnPaste(event) {
  const string = event.clipboardData.getData('text');
  const items = string.split(/[\n,]+/);
  return compact(items.map(item => item.trim())).map((item) => {
    return item.split(/[\t,]+/)[0];
  });
}

/**
 * Create flat data from hierarchy for component/instance
 */
export function getFlatData(nestedData, parentId = null) {
  let flatArray = [];
  for (const item of nestedData) {
    const { children, ...rest } = item;
    const flattenedItem = { ...rest, parent: parentId };
    flatArray.push(flattenedItem);

    if (children) {
      const childrenArray = getFlatData(children, item.uid);
      flatArray = [...flatArray, ...childrenArray];
    }
  }

  return flatArray;
}

/**
 * Format attachment list while updating files for component/instance
 */
export function formatAttachment(files, options) {
  return files?.map((file) => {
    if (file instanceof File)
      return {
        name: file.name,
        file_name: file.name,
        file_size: file.size || 0,
        service: { ...file.service_object, stage: 'system_model' },
        created_by: options?.user_id,
        meta: {
          stage: 'system_model',
          resource: '{"uid":"upload"}',
          name: file.name,
          type: file.type,
        },
      };
    return file;
  });
}

/**
 * Custom validator for checking duplicate name in the list
 */
export function uniqueName(data, message) {
  return class extends Validator {
    get message() {
      return message || 'Name must be unique';
    }

    check(value) {
      if (data?.length && value) {
        const is_match = data.filter(name => name?.toLowerCase() === value?.toLowerCase());
        if (is_match.length)
          return false;
      }
      return true;
    }
  };
}

/**
 * Format component/instance fields
 */
export function formatFields(fields, field_values) {
  const fields_value_map = fieldValueMap(field_values);
  return fields.map((field) => {
    let value = formatFieldValues(fields_value_map[field.uid]);
    let field_name = field.name;

    if (field.type === 'number')
      field_name = `${field_name} ${(field?.properties?.currency || '')}`;

    if (field.type === 'file')
      value = isString(value)
        ? []
        : value?.map((file) => {
          file.service.url = file?.pre_signed_url || '';
          file.file_name = file?.file_name || file.name;
          return file;
        });

    return {
      name: field_name,
      type: field.type,
      value,
    };
  });
}

/**
 * Format component/instance field values for field type labels, checkbox etc.
 */
export function formatFieldValues(fieldValue) {
  if (!fieldValue)
    return '';

  if (Array.isArray(fieldValue.field.config) && fieldValue.value)
    return fieldValue.field.config
      .filter(conf => fieldValue.value.includes(conf.uid))
      .map(conf => conf.name)
      .join(', ');

  return fieldValue.value;
}
