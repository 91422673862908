export function useFormDateTimeField() {
  const valid_time_formats = [
    'ha',
    'hA',
    'h a',
    'h A',
    'h:ma',
    'h:mA',
    'h:m a',
    'h:m A',
    'h:mm A', // form-field-schema submitted value format
    'H',
    'H:m',
  ];

  const valid_date_formats = [
    'D MMM YYYY',
    'D MMMM YYYY',
    'DD/MM/YY',
    'DD/MM/YYYY',
    'DD-MM-YY',
    'DD-MM-YYYY',
  ];

  const valid_date_time_formats = valid_date_formats.flatMap(date_format =>
    valid_time_formats.map(time_format => `${date_format} ${time_format}`),
  );
  valid_date_time_formats.push('DD/MM/YYYY, h:mm A'); // form-field-schema submitted value format

  return { valid_time_formats, valid_date_formats, valid_date_time_formats };
}
