<script setup>
import { useElementSize } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { usePusherStore } from '~/common/stores/pusher.store.js';

import TaskActivityComments from '~/tasks/pages/task-details-new/task-comments.vue/task-activity-comments.vue';
import TaskBreadcrumbs from '~/tasks/pages/task-details-new/task-breadcrumbs.vue';

const props = defineProps({
  resource: {
    type: Object,
    required: true,
  },
});

const task_store = inject('task_store');
const pusher_store = usePusherStore();

const route = useRoute();
const router = useRouter();

watchEffect(() => {
  if (pusher_store.is_initialized)
    task_store.task_details_channel = pusher_store.PUSHER.subscribe(
      `presence-TASKS_TICKET_${props.resource.uid}`,
    );
}, { flush: 'post' });

onUnmounted(() => {
  if (pusher_store.is_initialized)
    pusher_store.PUSHER.unsubscribe(`presence-TASKS_TICKET_${props.resource.uid}`);
});

function viewTaskHandler() {
  const task = btoa(JSON.stringify({
    id: props.resource.uid,
  }));
  router.push({ ...route, query: { task } });
}

const conversations_comment_header = ref(null);
const { height } = useElementSize(conversations_comment_header);
const header_height = computed(() => {
  // 80 is for hawk-header height
  return `${height.value + 80}`;
});

provide('is-template', false);
provide('task-uid', {});
provide('comment_uid', null);
provide('header_height', header_height);
</script>

<template>
  <div class="relative">
    <div ref="conversations_comment_header" class="px-3">
      <TaskBreadcrumbs
        class="mb-1"
        :items="resource?.breadcrumbs"
        :asset_id="resource?.target_element?.asset"
      />
      <p
        v-tippy="resource.name.length > 300 ? resource.name : ''"
        :class="{ 'line-clamp-3 overflow-hidden': resource.name.length > 210 }"
        class="font-semibold text-lg mb-6 cursor-pointer"
        @click="viewTaskHandler"
      >
        {{ resource.name }}
      </p>
    </div>
    <div>
      <TaskActivityComments :task_uid="resource.uid" location="conversations" />
    </div>
  </div>
</template>
