<script setup>
import SearchBar from '~/common/components/molecules/hawk-search-input.vue';
import { getRandomKey } from '~/common/utils/common.utils';

import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';
import DocumentSidebar from '~/dms/components/documents/document-sidebar.vue';

const emit = defineEmits(['close']);

const $services = inject('$services');

const dashboard_dms_store = useDashboardDMSStore();
const { set_widget_configuration } = useDashboardStore();

const is_loading = ref(false);

const folders = ref([]);
const tree_data = ref([]);

const active_nodes = ref([]);
const selected_items = ref([]);

function randomNodeKey(id) {
  return `${id}+${getRandomKey()}`;
}

async function set_documents(search_key = '') {
  try {
    is_loading.value = true;
    const { data } = await $services.documents.getAll({
      query: {
        hierarchy: true,
        q: search_key,
      },
    });
    folders.value = data.folders;
    setTreeData();
    is_loading.value = false;
  }
  catch (e) {
    logger.log({ e });
    is_loading.value = false;
  }
}

async function onSearch(key) {
  await set_documents(key || '');
}

function setTreeData() {
  tree_data.value = keyify(folders.value);
  if (dashboard_dms_store.dms_configuration.folders?.length)
    active_nodes.value = getActiveNodeKeys(tree_data.value);
}

function keyify(items) {
  return items.map((item) => {
    if (item?.children) {
      return {
        ...item,
        hasChildren: true,
        children: keyify(item.children),
        nodeKey: randomNodeKey(item.uid),
      };
    }
    else {
      return {
        ...item,
        nodeKey: randomNodeKey(item.uid),
      };
    }
  });
}

function save() {
  const folders = selected_items.value.map(item => ({ ...item, uid: item.key.split('+')[0] }));
  dashboard_dms_store.update_dms_configuration({ ...dashboard_dms_store.dms_configuration, folders });
  set_widget_configuration({
    ...dashboard_dms_store.dms_configuration,
    folders,
  });
}

function getActiveNodeKeys(tree_items) {
  const activeNodeKeys = [];

  function searchTree(treeItems, activeInstances) {
    treeItems.forEach((item) => {
      if (activeInstances.includes(item.uid))
        activeNodeKeys.push(item.nodeKey); // Object found, add it to the list
      if (item.hasChildren && item?.children?.length)
        searchTree(item.children, activeInstances);
    });
  }

  searchTree(tree_items, dashboard_dms_store.dms_configuration.folders.map(node => node.key.split('+')[0]));
  return activeNodeKeys;
}

onBeforeMount(async () => {
  await set_documents();
});
</script>

<template>
  <hawk-modal-container content_class="w-[900px]">
    <hawk-modal-header @close="$emit('close')">
      <template #left>
        {{ $t('Folders') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content :is_scroll="false">
      <SearchBar
        class="mb-2"
        :debounce_time="700"
        @update:model-value="onSearch"
      />
      <div class="flex items-center justify-center border border-gray-300 border-solid rounded-lg h-[320px]">
        <hawk-loader v-if="is_loading" />
        <DocumentSidebar
          v-else-if="folders.length"
          :multi="true"
          :active_folder="folders[0]"
          :data="tree_data"
          sidebar_class="w-full max-h-[318px]"
          :default_checked="active_nodes"
          @selected="(e) => selected_items = e"
        />
        <p
          v-else
          class="text-sm font-medium text-gray-600"
        >
          {{ $t('No folders found') }}
        </p>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-4">
          <HawkButton type="outlined" @click="emit('close')">
            {{ $t('Close') }}
          </HawkButton>
          <HawkButton type="default" @click="save">
            {{ $t('Save') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>

<style lang="scss" scoped>
:deep(.vir-tree-node) {
  padding: 8px 4px;
  border-radius: 0px;
  &:hover {
    background-color: #F2F4F7;
  }
}
:deep(.vir-tree-node:first-of-type) {
  border-radius: 8px 8px 0 0;
}
:deep(p) {
  font-weight: 400;
}
</style>
