<script setup>
import SmFieldList from '~/system-model/components/sm-field-list.vue';

const props = defineProps({
  global_search_item: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close']);
const $services = inject('$services');

const state = reactive({
  is_loading: false,
  global_instance_details: null,
});

onMounted(async () => {
  await getData();
});
async function getData() {
  try {
    state.is_loading = true;
    const { data } = await $services.sm_instances.get({
      id: props.global_search_item?.uid,
      query: {
        'include[]': ['component.fields.*', 'component.category.fields.*', 'fieldvalues.field.*', 'parents.component', 'component.fieldvalues.field.*'],
        'sideloading': 'false',
      },
    });
    state.global_instance_details = data?.instance;
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}
</script>

<template>
  <hawk-modal-container content_class="w-2/5 rounded-lg">
    <hawk-modal-header @close="emit('close')">
      <template #left>
        <div>
          <div class="text-gray-900">
            {{ global_search_item?.name }}
          </div>
          <span class="text-sm text-gray-600 font-normal">
            {{ global_search_item?.component_name }}
          </span>
        </div>
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <HawkLoader v-if="state.is_loading" />
      <div v-else>
        <SmFieldList
          :properties="state.global_instance_details?.component?.fieldvalues"
          :fields="state.global_instance_details?.component?.category?.fields"
        />
      </div>
    </hawk-modal-content>
  </hawk-modal-container>
</template>
