<script setup>
import { includes, uniq } from 'lodash-es';

const props = defineProps({
  item_uid_key: {
    default: 'uid',
  },
  items: {
    type: Array,
    default: () => [{}],
  },
  open_multiple: {
    type: Boolean,
    default: true,
  },
  show_icon: {
    type: Boolean,
    default: true,
  },
  expand_icon_position: {
    type: String,
    default: 'left',
    validator(value) {
      return ['left', 'right'].includes(value);
    },
  },
  all_open: {
    type: Boolean,
    default: true,
  },
  all_closed: {
    type: Boolean,
    default: false,
  },
  icon_type: {
    type: String,
    default: 'circle',
    validator(value) {
      return ['circle', 'chevron', ''].includes(value);
    },
  },
  type: {
    type: String,
    default: 'outlined',
    validator(value) {
      return ['outlined', 'plain', ''].includes(value);
    },
  },
  color: {
    type: String,
    default: 'gray',
    validator(value) {
      return ['gray', 'dark', 'blue', 'red', 'green', 'yellow', 'none'].includes(value);
    },
  },
});

const open = ref([]);

const accordion_classes = computed(() => {
  const classes = [];

  switch (props.color) {
    case 'gray':
      classes.push('bg-gray-100');
      break;

    case 'dark':
      classes.push('bg-gray-700 text-white');
      break;

    case 'blue':
      classes.push('bg-blue-700');
      break;

    case 'red':
      classes.push('bg-red-100');
      break;

    case 'green':
      classes.push('bg-green-700');
      break;

    case 'yellow':
      classes.push('bg-yellow-700');
      break;
    case 'none':
      classes.push('bg-transparent');
      break;
    default:
      classes.push('bg-gray-100');
      break;
  }

  return classes;
});

function openItem(item) {
  if (includes(open.value, item[props?.item_uid_key])) {
    open.value = open.value.filter(i => i !== item[props?.item_uid_key]);
  }

  else
    if (props.open_multiple) {
      open.value.push(item[props?.item_uid_key]);
      open.value = uniq(open.value);
    }
    else {
      open.value = [];
      open.value.push(item[props?.item_uid_key]);
    }
}

watch(() => props.items, () => {
  accordionsToOpen();
}, { immediate: true });

function accordionsToOpen() {
  if (!props.all_closed)
    if (props.all_open)
      props.items.forEach((item) => {
        open.value.push(item[props?.item_uid_key]);
      });

    else
      open.value.push(props.items[0].uid);
}
</script>

<template>
  <div v-if="type === 'outlined'" class="grid gap-3">
    <div v-for="item in items" :key="item[props?.item_uid_key]">
      <div class="flex items-center justify-between px-2 py-1.5 rounded-lg" :class="accordion_classes">
        <div class="flex items-center gap-3">
          <div v-if="expand_icon_position === 'left'" class="cursor-pointer" @click="openItem(item)">
            <div v-if="icon_type === 'circle'">
              <IconHawkMinusCircle v-if="open.includes(item[props?.item_uid_key])" />
              <IconHawkPlusCircle v-else />
            </div>
            <div v-else-if="icon_type === 'chevron'">
              <IconHawkChevronRight v-if="open.includes(item[props?.item_uid_key])" />
              <IconHawkChevronDown v-else />
            </div>
          </div>

          <slot v-if="$slots.left" name="left" :item="item" />
          <div v-else-if="item?.title" class="text-sm font-semibold">
            {{ item.title }}
          </div>
        </div>

        <div class="flex items-center gap-3">
          <slot v-if="$slots.right" name="right" :item="item" />

          <div v-if="expand_icon_position === 'right'" class="cursor-pointer" @click="openItem(item)">
            <div v-if="icon_type === 'circle'">
              <IconHawkMinusCircle v-if="open.includes(item[props?.item_uid_key])" />
              <IconHawkPlusCircle v-else />
            </div>
            <div v-else-if="icon_type === 'chevron'">
              <IconHawkChevronUp v-if="open.includes(item[props?.item_uid_key])" />
              <IconHawkChevronDown v-else />
            </div>
          </div>
        </div>
      </div>
      <div v-if="open.includes(item[props?.item_uid_key])">
        <div v-if="item.description && !$slots.content" class="text-gray-600">
          {{ item.description }}
        </div>
        <slot v-else name="content" :data="item" />
      </div>
    </div>
  </div>
  <div v-else-if="type === 'plain'" class="grid gap-3">
    <div v-for="(item, index) in items" :key="item[props?.item_uid_key]" :class="{ 'border-b': index + 1 !== items.length }">
      <div class="cursor-pointer" @click="openItem(item)">
        <slot name="header" :item="item" :is_open="open.includes(item[props?.item_uid_key])" />
      </div>

      <div v-if="open.includes(item[props?.item_uid_key])">
        <slot name="content" :content="item" :is_open="open.includes(item[props?.item_uid_key])" />
      </div>
    </div>
  </div>
</template>
