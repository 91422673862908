import ProjectOutputsReports from '~/project-outputs/pages/project-outputs-report.vue';

const routes = [
  {
    path: '/:asset_id/project-reports',
    name: 'project-outputs-reports',
    component: ProjectOutputsReports,
    meta: {
      title: 'Reports',
    },
  },

];
export default routes;
