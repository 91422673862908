<script setup>
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';

const props = defineProps({
  field: {
    type: Object,
    default: null,
  },
  value: {
    default: null,
  },
});

function formatted_value() {
  let result = '';
  if (!props.value)
    return '-';

  switch (props.field?.type) {
    case 'checkboxes':
      result = props.value.join(', ') || '-';
      break;
    case 'member':
      result = props.value;
      break;
    case 'members':
      result = props.value;
      break;
    case 'date':
      result = dayjs(props.value).format('D MMMM, YYYY');
      break;
    default:
      result = props.value;
  }
  return result;
}
function goToLink(url) {
  window.open(url, '_blank');
}
</script>

<template>
  <div>
    <template v-if="field?.type === 'signature'">
      <img v-if="value" class="max-h-[40px]" :src="value?.url ? value.url : value" alt="">
      <div v-else>
        -
      </div>
    </template>
    <template v-else-if="field?.type === 'member'">
      <HawkMembers :members="value" type="label" :has_avatar="false" />
    </template>
    <template v-else-if="field?.type === 'members'">
      <HawkMembers :members="value" type="badge" />
    </template>
    <template v-else-if="field?.type === 'url'">
      <div class="cursor-pointer break-all hover:text-blue-600 hover:underline" @click="goToLink(DOMPurify.sanitize(value))">
        {{ value }}
      </div>
    </template>
    <template v-else-if="field?.type">
      {{ formatted_value() }}
    </template>
  </div>
</template>
