<script setup>
import { inject } from 'vue';

import { useModal } from 'vue-final-modal';

import { useRoute } from 'vue-router';

import TaskBreadcrumbs from '~/tasks/pages/task-details-new/task-breadcrumbs.vue';
import ContextMenuDropdown from '~/tasks/components/organisms/context-menu-dropdown.vue';
import WatchersPopup from '~/tasks/components/organisms/watchers-popup.vue';

import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  task_details: {
    type: Object,
    required: true,
  },
  show_task_name: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const task_store = inject('task_store');
const $toast = inject('$toast');
const updateFunction = inject('updateFunction');
const is_template = inject('is-template');

const route = useRoute();
const emitter = useEmitter();

const rename = ref(false);

const show_navigator = computed(() => {
  return task_store.navigation_context_stack.length;
});

function handleNameChange(name) {
  updateFunction({ name });
  rename.value = false;
}
const { open: openWatchersPopup, close: closeWatchersPopup, patchOptions } = useModal({
  component: WatchersPopup,
});

function handleWatchers() {
  patchOptions({
    attrs: {
      watchers: props.task_details.watchers,
      viewed: props.task_details.viewed,
      asset_id: props.task_details?.target_element?.asset,
      onClose() {
        closeWatchersPopup();
      },
      async on_update(payload) {
        try {
          await updateFunction(payload);
        }
        catch (err) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
            position: 'bottom-right',
          });
        }
        closeWatchersPopup();
      },
    },
  });
  openWatchersPopup();
}

function navigateTask(direction) {
  emitter.emit('navigate-task', direction);
}
</script>

<template>
  <div v-if="task_details" class="flex justify-between items-center h-[60px] px-8 border-b">
    <div class="inline-block">
      <HawkText
        v-if="show_task_name"
        :content="task_details.name"
        :length="100"
        class="text-lg font-semibold"
      />
      <TaskBreadcrumbs
        v-else
        :items="task_details?.breadcrumbs"
        :reference_name="task_details?.properties?.reference_name"
        :asset_id="task_details.target_element.asset"
      />
    </div>
    <div class="flex items-center gap-2">
      <div v-if="show_navigator">
        <HawkButton
          icon
          type="text"
          :disabled="task_store.navigation_meta.disable.prev"
          @click="() => navigateTask('prev')"
        >
          <IconHawkChevronLeft />
        </HawkButton>
        <HawkButton
          class="ml-2"
          icon
          type="text"
          :disabled="task_store.navigation_meta.disable.next"
          @click="() => navigateTask('next')"
        >
          <IconHawkChevronRight />
        </HawkButton>
      </div>
      <div class="mx-3 cursor-pointer relative hover:bg-gray-50 rounded-md">
        <div v-if="task_details.watchers.length" class="badge">
          {{ task_details.watchers.length }}
        </div>
        <icon-hawk-watcher class="h-5 w-7" @click="handleWatchers" />
      </div>
      <ContextMenuDropdown
        location="task_details_header"
        :task="task_details"
        @close-popup="emit('close')"
      />
      <hawk-button type="text" icon @click="emit('close')">
        <IconHawkX class="w-6 h-6" />
      </hawk-button>
    </div>
  </div>
</template>

<style lang="scss">
.badge {
  position: absolute;
  top: -14px;
  right: -7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 15px;
  padding: 8px;
  border: 3px solid #f4fdff;
  border-radius: 500px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  z-index: 1;
  background: #1570EF
}
</style>
