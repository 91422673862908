/*
The utility functions exported from this file abstracts away date-time formatting. It's currently adapted for
Day.js but can be easily used with another date-time library.

Note: Not all formats and presets are covered by these functions. For a complete reference to formats supported
by Day.js, refer to https://day.js.org/docs/en/display/format
*/

/* -------- TIME FROM NOW -------- */
/* There are two options to use this utility: */

// $date_relative(date, TRUE)                                   4 days
// $date_relative(date, FALSE) OR $date_relative(date)          4 days ago

/* ---------- LOCALIZED ---------- */
/* These presets adapt to the user's locale and should be preferred over their non-localized counterparts */

// L_TIME_SHORT                               8:02 PM
// L_TIME_LONG                                8:02:18 PM
// L_DATE_SHORT                               10/20/1983
// L_DATE_MED                                 Oct 20, 1983
// L_DATE_LONG                                October 20, 1983
// L_DATETIME_MED                             Oct 20, 1983 8:02 PM
// L_DATETIME_LONG                            October 20, 1983 8:02 PM
// L_DATETIME_MED_WITH_WEEKDAY                Fri, Oct 20, 1983 8:02 PM
// L_DATETIME_LONG_WITH_WEEKDAY               Friday, October 20, 1983 8:02 PM

/* ---------- NOT LOCALIZED ---------- */
/* These presets are represented as-is and DO NOT adapt to the user's locale */

// DATE_SHORT                                 20/10/1983
// DATE_MED                                   Oct 20, 1983
// DATE_LONG                                  October 20, 1983
// DATE_MED_WITH_WEEKDAY                      Fri, Oct 20, 1983
// DATE_LONG_WITH_WEEKDAY                     Friday, October 20, 1983
// TIME_SHORT                                 1:30 PM
// TIME_LONG                                  1:30:23 PM
// TIME_SHORT_WITH_OFFSET                     1:30:23 PM EDT
// TIME_LONG_WITH_OFFSET                      1:30:23 PM Eastern Daylight Time
// TIME_24_SHORT                              13:30
// TIME_24_LONG                               13:30:23
// TIME_24_SHORT_WITH_OFFSET                  13:30:23 EDT
// TIME_24_LONG_WITH_OFFSET                   13:30:23 Eastern Daylight Time
// DATETIME_SHORT                             20/10/1983, 1:30 PM
// DATETIME_SHORT_WITH_WEEKDAY                Fri, Oct 20, 1983, 1:30 PM
// DATETIME_SHORT_WITH_SECONDS                20/10/1983, 1:30:23 PM
// DATETIME_MED                               Oct 20, 1983, 1:30 PM
// DATETIME_MED_WITH_SECONDS                  Oct 20, 1983, 1:30:23 PM
// DATETIME_LONG                              October 20, 1983, 1:30 PM
// DATETIME_LONG_WITH_OFFSET                  October 20, 1983, 1:30 PM EDT
// DATETIME_LONG_WITH_SECONDS                 October 20, 1983, 1:30:23 PM
// DATETIME_LONG_WITH_WEEKDAY                 Friday, October 20, 1983, 1:30 PM
// DATETIME_LONG_WITH_SECONDS_WEEKDAY         Friday, October 20, 1983, 1:30:23 PM
// DATETIME_LONG_WITH_SECONDS_OFFSET          October 20, 1983, 1:30:23 PM EDT
// DATETIME_LONG_WITH_WEEKDAY_OFFSET          Friday, October 20, 1983, 1:30 PM Eastern Daylight Time
// DATETIME_LONG_WITH_SECONDS_WEEKDAY_OFFSET  Friday, October 20, 1983, 1:30:23 PM Eastern Daylight Time

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export const $date_relative = (date, suffix = false) => dayjs(date).fromNow(suffix);

export function $date(date, preset = 'L_DATETIME_LONG_WITH_WEEKDAY') {
  let format = preset;

  const formats = {
    L_TIME_SHORT: 'LT',
    L_TIME_LONG: 'LTS',
    L_DATE_SHORT: 'l',
    L_DATE_MED: 'll',
    L_DATE_LONG: 'LL',
    L_DATETIME_MED: 'lll',
    L_DATETIME_LONG: 'LLL',
    L_DATETIME_MED_WITH_WEEKDAY: 'llll',
    L_DATETIME_LONG_WITH_WEEKDAY: 'LLLL',
    DATE_ORDINAL: 'Do MMMM YYYY',
    DATE_SHORT: 'D/M/YYYY',
    DATE_MED: 'MMM DD, YYYY',
    DATE_LONG: 'MMMM DD, YYYY',
    DATE_MED_WITH_WEEKDAY: 'ddd, MMM DD, YYYY',
    DATE_LONG_WITH_WEEKDAY: 'dddd, MMMM DD, YYYY',
    TIME_SHORT: 'h:mm A',
    TIME_LONG: 'h:mm:s A',
    TIME_SHORT_WITH_OFFSET: 'h:mm:s A z',
    TIME_LONG_WITH_OFFSET: 'h:mm:s A zzz',
    TIME_24_SHORT: 'H:mm',
    TIME_24_LONG: 'H:mm:s',
    TIME_24_SHORT_WITH_OFFSET: 'H:mm:s z',
    TIME_24_LONG_WITH_OFFSET: 'H:mm:s zzz',
    DATETIME_SHORT: 'DD/MM/YYYY, h:mm A',
    DATETIME_SHORT_WITH_WEEKDAY: 'ddd, MMM DD, YYYY, h:mm A',
    DATETIME_SHORT_WITH_SECONDS: 'DD/MM/YYYY, h:mm:s A',
    DATETIME_MED: 'MMM D, YYYY, h:mm A',
    DATETIME_MED_WITH_SECONDS: 'MMM D, YYYY, h:mm:s A',
    DATETIME_LONG: 'MMMM D, YYYY, h:mm A',
    DATETIME_LONG_WITH_SECONDS: 'MMMM D, YYYY, h:mm:s A',
    DATETIME_LONG_WITH_WEEKDAY: 'dddd, MMMM D, YYYY, h:mm A',
    DATETIME_LONG_WITH_SECONDS_WEEKDAY: 'dddd, MMMM D, YYYY, h:mm:s A',
    DATETIME_LONG_WITH_SECONDS_OFFSET: 'MMMM D, YYYY, h:mm:s A z',
    DATETIME_LONG_WITH_WEEKDAY_OFFSET: 'dddd, MMMM D, YYYY, h:mm A zzz',
    DATETIME_LONG_WITH_OFFSET: 'MMMM D, YYYY, h:mm A z',
    DATETIME_LONG_WITH_SECONDS_WEEKDAY_OFFSET: 'dddd, MMMM D, YYYY, h:mm:s A zzz',
    DATETIME_LONG_WITH_SHORT_DAY_AND_MONTH: 'ddd, MMM DD, YYYY hh:mm A',
    DATETIME_LONG_WITH_LONG_DAY_AND_MONTH: 'dddd, MMMM DD, YYYY hh:mm A',
  };

  format = formats?.[preset] || preset;

  return dayjs(date).format(format);
}

export function setDefaultTimezone(timezone) {
  if (timezone)
    dayjs.tz.setDefault(timezone);
}

export function prepareTimeZones() {
  const timezones = Intl.supportedValuesOf('timeZone');
  const data = [];

  for (const timezone of timezones)
    data.push({
      label: `[GMT ${dayjs(new Date()).tz(timezone).format('Z')}] ${timezone}`,
      value: timezone,
      offset: dayjs().tz(timezone).utcOffset(),
    });

  return data;
}
