<script setup>
// --------------------------------- Imports -------------------------------- //
import { RRule } from 'rrule';
import { keyBy } from 'lodash-es';
import { Validator } from '@vueform/vueform';

import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

import DMSTransmittalSchema from '~/dms/components/settings/dms-transmittal-schema.vue';
import DocumentSchemaPreview from '~/dms/components/atoms/document-schema-preview.vue';
import { get_default_suggestions } from '~/forms/composables/form-builder-auto-number-composable';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDocumentStore } from '~/dms/store/document.store.js';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  sub_header: {
    type: String,
    default: '',
  },
  folder_data: {
    type: Object,
    default: () => {},
  },
  save: {
    type: Function,
    required: true,
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['close']);

// ---------------------------- Injects/Provides ---------------------------- //
provide('extra-props', { folder_data: props.folder_data });
dayjs.extend(minMax);
dayjs.extend(timezone);
const $t = inject('$t');

// ----------------------- Variables - Pinia - consts ----------------------- //
const auth_store = useAuthStore();
const document_store = useDocumentStore();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const default_suggestions = get_default_suggestions({ $t }).filter(suggestion => !['category', 'due_date', 'submitted_by', 'submitted_at'].includes(suggestion.id));

// Override classes for palette design
const override_class_map = {
  Monthly: {
    wrapper: '!grid grid-cols-7',
    container: 'col-span-1 month_border_class',
  },
  Annually: {
    wrapper: '!grid grid-cols-4',
    container: 'col-span-1 annual_border_class',
  },
};
const frequency_map = {
  Daily: 'days',
  Weekly: 'weeks',
  Monthly: 'months',
  Annually: 'years',
};
const weeknumber_items = [
  { label: '1st', value: 1 },
  { label: '2nd', value: 2 },
  { label: '3rd', value: 3 },
  { label: '4th', value: 4 },
  { label: 'Last', value: -1 },
];
const weekday_items = [
  { label: 'Sun', name: 'Sunday', value: 6 },
  { label: 'Mon', name: 'Monday', value: 0 },
  { label: 'Tue', name: 'Tuesday', value: 1 },
  { label: 'Wed', name: 'Wednesday', value: 2 },
  { label: 'Thu', name: 'Thursday', value: 3 },
  { label: 'Fri', name: 'Friday', value: 4 },
  { label: 'Sat', name: 'Saturday', value: 5 },
];

const number_tag_validation = class extends Validator {
  get message() {
    return $t('Number tag is mandatory');
  }

  check(value) {
    return value?.includes('data-id="number"');
  }
};

// ------------------------ Variables - Local - refs ------------------------ //
const form$ = ref(null);
const form_data = ref(null);
const is_edit = ref(false);
const configuration_data = ref(null);

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const get_timezone = computed(() => auth_store.logged_in_user_details?.timezone || dayjs.tz.guess());

const schedule_items = computed(() => {
  if (form_data.value?.reset_frequency === 'Monthly')
    return [
      ...[...Array(31).keys()].map(i => i + 1),
      ...[-4, -3, -2, -1],
    ];
  else if (form_data.value?.reset_frequency === 'Annually')
    return [
      { label: 'Jan', value: 1 },
      { label: 'Feb', value: 2 },
      { label: 'Mar', value: 3 },
      { label: 'Apr', value: 4 },
      { label: 'May', value: 5 },
      { label: 'Jun', value: 6 },
      { label: 'Jul', value: 7 },
      { label: 'Aug', value: 8 },
      { label: 'Sep', value: 9 },
      { label: 'Oct', value: 10 },
      { label: 'Nov', value: 11 },
      { label: 'Dec', value: 12 },
    ];
});

const rrule = computed(() => {
  if (!form_data.value)
    return null;
  if (form_data.value.reset_frequency === 'None')
    return null;

  let recurSet = {};
  if (props.schedule?.rrule) {
    recurSet.dtstart = RRule.fromString(props.schedule.rrule).options.dtstart;
    const tzid = gettzid(props.schedule.rrule);
    if (tzid)
      recurSet.tzid = tzid;
  }
  else {
    // set dtstart to 8:00 AM in user's app timezone
    recurSet.dtstart = new Date(dayjs.tz(`${dayjs().format('YYYY-MM-DD')} 08:00:00`, get_timezone.value));
    recurSet.tzid = get_timezone.value;
  }

  recurSet.interval = Number(form_data.value?.interval);

  switch (form_data.value.reset_frequency) {
    case 'Weekly':
      recurSet.freq = RRule.WEEKLY;
      recurSet.byweekday = setWeekRule();
      break;
    case 'Monthly':
      recurSet = {
        ...recurSet,
        ...setMonthRule(),
        freq: RRule.MONTHLY,
      };
      break;
    case 'Annually':
      recurSet = {
        ...recurSet,
        ...setYearRule(),
        freq: RRule.YEARLY,
      };
      break;
    case 'Daily':
      recurSet.freq = RRule.DAILY;
      break;
  }
  return new RRule(recurSet);
});

// -------------------------------- Functions ------------------------------- //

/* ---------RRule helpers----------- */
function setWeekRule() {
  const rule = [];
  const value_name_map = keyBy(weekday_items, 'value');
  form_data.value.selected_weekdays.forEach((day_number) => {
    rule.push(RRule[value_name_map[day_number].label.slice(0, 2).toUpperCase()]);
  });
  return rule;
}
function setMonthRule() {
  const month_rule = {};
  if (form_data.value.schedule_group.freq === 'On particular dates') {
    month_rule.bymonthday = form_data.value.schedule_group.month_dates;
  }
  else {
    month_rule.byweekday = [];
    const value_name_map = keyBy(weekday_items, 'value');
    const key = value_name_map[form_data.value.schedule_group.on_the.week_day].label.slice(0, 2).toUpperCase();
    month_rule.byweekday = RRule[key].nth(form_data.value.schedule_group.on_the.week_number);
  }
  return month_rule;
}
function setYearRule() {
  const year_rule = {
    bymonth: form_data.value.month_of_year,
    byweekday: [],
  };
  const value_name_map = keyBy(weekday_items, 'value');
  const key = value_name_map[form_data.value.on_the.week_day].label.slice(0, 2).toUpperCase();
  year_rule.byweekday = RRule[key].nth(form_data.value.on_the.week_number);
  return year_rule;
}
/* ---------RRule helpers End----------- */

/* --------- Timezone helpers ----------- */
function gettzid(rruleString) {
  if (!rruleString)
    return get_timezone.value;
  const tzidMatch = rruleString.match(/DTSTART;TZID=([^:]+):/);
  if (tzidMatch?.[1])
    return tzidMatch[1];
  return null;
}
/* --------- Timezone helpers end ----------- */

function generatePayload() {
  const payload = {};
  const {
    active,
    html,
    number_format,
    schema_inheritance,
    starting_number,
    is_global,
    update_existing_files,
  } = form$.value.data;

  payload.inherit = schema_inheritance;
  payload.active = active;
  payload.html = html;
  payload.number_format = number_format;
  payload.rrule = rrule.value?.toString() || null;
  payload.starting_number = Number(starting_number);
  payload.is_global = schema_inheritance ? !is_global : true;
  payload.update_existing_files = update_existing_files;

  return payload;
}

async function onSave() {
  const payload = generatePayload();

  await props.save(payload);
  emit('close');
}

function loadDefault(reset_frequency = 'None') {
  const data = {
    active: true,
    number_format: '01',
    starting_number: 1,
    reset_frequency,
    interval: 1,
    // Weekly
    selected_weekdays: [6],
    // Monthly
    schedule_group: {
      freq: 'On particular dates',
      month_dates: [1],
      on_the: {
        week_number: 1,
        week_day: 0,
      },
    },
    // Annual
    month_of_year: [1],
    on_the: {
      week_number: 1,
      week_day: 0,
    },
    is_global: false,
  };
  form_data.value = data;
}

function loadData() {
  const { active, html, rrule, starting_number, number_format, inherit, is_global } = configuration_data.value;
  const { options: existingRules } = RRule.fromString(rrule || '');

  const data = {
    active,
    html,
    number_format,
    starting_number,
    interval: existingRules.interval,
    schema_inheritance: inherit,
    is_global: !is_global,
  };

  if (!rrule) {
    data.reset_frequency = 'None';
    form_data.value = data;
    return;
  }

  // RRule parsing
  switch (existingRules.freq) {
    case 0:
      data.reset_frequency = 'Annually';
      data.month_of_year = existingRules.bymonth;
      data.on_the = {
        week_day: existingRules.bynweekday[0][0],
        week_number: existingRules.bynweekday[0][1],
      };
      break;
    case 1:
      data.reset_frequency = 'Monthly';
      if (existingRules?.bymonthday?.length || existingRules?.bynmonthday?.length)
        data.schedule_group = {
          freq: 'On particular dates',
          month_dates: [...(existingRules.bymonthday || []), ...(existingRules.bynmonthday || [])],
        };
      else
        data.schedule_group = {
          freq: 'On the',
          on_the: {
            week_day: existingRules.bynweekday[0][0],
            week_number: existingRules.bynweekday[0][1],
          },
        };
      break;
    case 2:
      data.reset_frequency = 'Weekly';
      data.selected_weekdays = existingRules.byweekday;
      break;
    case 3:
      data.reset_frequency = 'Daily';
      break;
    default:
      data.reset_frequency = 'Monthly';
      data.schedule_group.on_the.month_dates = existingRules.bymonthday;
  }

  form_data.value = data;
}

async function onFormMounted(el$) {
  if (props.folder_data.config_uid) {
    configuration_data.value = await document_store.fetch_autonum_configuration(props.folder_data.config_uid);
    loadData();
  }
  else {
    is_edit.value = true;
    loadDefault();
  }
}
</script>

<template>
  <HawkModalContainer content_class="rounded-lg w-[700px]">
    <Vueform
      ref="form$"
      v-model="form_data"
      sync
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex flex-col justify-start">
                  {{ header }}
                  <span v-if="sub_header" class="font-normal text-sm text-gray-600">
                    {{ sub_header }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
                </div>
              </div>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div class="flex flex-col gap-6">
            <ToggleElement :class="{ hidden: !is_edit }" name="active" label="Activate" />
            <WysiwygEditorElement
              v-if="form_data"
              :class="{ hidden: !is_edit }"
              name="html"
              :label="$t('Schema')"
              :disabled="!form_data?.active"
              :rules="['required', number_tag_validation]"
              :options="{
                editor_enabled: true,
                menu_enabled: false,
                single_line: true,
                enable_common_rtf_features: false,
                plugins: ['disable-enter', 'tags'],
                placeholder_text: $t('Enter schema'),
                suggestions: [
                  ...default_suggestions,
                ],
                footer: {
                  component: DMSTransmittalSchema,
                  component_props: {
                    nested_suggestions: [],
                  },
                },
                nested_suggestions: [],
              }"
            />
            <SelectElement
              :class="{ hidden: !is_edit }"
              :label="$t('Number format')"
              name="number_format"
              :native="false"
              :placeholder="$t('Choose number format')"
              :can-deselect="false"
              :can-clear="false"
              :items="['01', '001', '0001', '00001', '000001']"
              :disabled="!form_data?.active"
            />
            <TextElement
              :class="{ hidden: !is_edit }"
              name="starting_number"
              :label="$t('Starting number')"
              :placeholder="$t('Enter starting number')"
              :disabled="!form_data?.active"
              input-type="number"
            />
            <StaticElement
              :label="$t('Preview')"
              :disabled="true"
              :content="DocumentSchemaPreview"
              :add-classes="{
                ElementLayout: {
                  innerContainer: ['border', 'border-gray-300', 'bg-gray-50', 'p-2', 'rounded-lg'],
                },
              }"
            />
            <SelectElement
              :class="{ hidden: !is_edit }"
              name="reset_frequency"
              :label="$t('Reset frequency')"
              :can-clear="false"
              :can-deselect="false"
              :native="false"
              :items="['None', 'Daily', 'Weekly', 'Monthly', 'Annually']"
              :placeholder="$t('Select reset frequency')"
              :disabled="!form_data?.active"
            />
            <TextElement
              :class="{ hidden: !is_edit }"
              name="interval"
              input-type="number"
              rules="numeric"
              :label="$t('Every')"
              :placeholder="$t('Enter interval')"
              :disabled="!form_data?.active"
              :conditions="[
                ['reset_frequency', '!=', 'None'],
              ]"
            >
              <template #addon-after>
                {{ form_data?.reset_frequency ? frequency_map[form_data.reset_frequency] : '' }}
              </template>
            </TextElement>
            <!-- Weekly -->
            <CheckboxgroupElement
              :class="{ hidden: !is_edit }"
              class="mb-3"
              name="selected_weekdays"
              view="tabs"
              :label="$t('On days')"
              :items="weekday_items"
              :conditions="[['reset_frequency', 'Weekly']]"
              :disabled="!form_data?.active"
            />
            <!-- Monthly -->
            <ObjectElement
              :class="{ hidden: !is_edit }"
              class="mb-3"
              name="schedule_group"
              view="tabs"
              :label="$t('Schedule')"
              :items="schedule_items"
              :conditions="[['reset_frequency', 'Monthly']]"
            >
              <RadiogroupElement
                name="freq"
                :items="[
                  'On particular dates',
                  'On the',
                ]"
                :disabled="!form_data?.active"
              />
              <CheckboxgroupElement
                class="mb-3"
                name="month_dates"
                view="tabs"
                :conditions="[['schedule_group.freq', 'On particular dates']]"
                :items="schedule_items"
                :override-classes="{
                  CheckboxgroupElement: {
                    wrapper: override_class_map.Monthly.wrapper,
                    wrapper_sm: '',
                  },
                  CheckboxgroupCheckbox: {
                    wrapper: 'flex items-center justify-center w-full text-sm font-semibold text-gray-700 cursor-pointer',
                    container: override_class_map.Monthly.container,
                    wrapper_first_sm: '',
                    wrapper_last_sm: '',
                    wrapper_unselected: '',
                    wrapper_selected: 'bg-gray-100',
                  },
                }"
                :disabled="!form_data?.active"
              />
              <ObjectElement
                name="on_the"
                :conditions="[['schedule_group.freq', 'On the']]"
              >
                <SelectElement
                  name="week_number"
                  :native="false"
                  :can-clear="false"
                  :can-deselect="false"
                  :items="weeknumber_items"
                  :columns="{
                    default: 4,
                    sm: 4,
                    md: 4,
                  }"

                  :disabled="!form_data?.active"
                />
                <SelectElement
                  class="ml-4"
                  name="week_day"
                  label-prop="name"
                  :native="false"
                  :can-clear="false"
                  :can-deselect="false"
                  :items="weekday_items"
                  :columns="{
                    default: 8,
                    sm: 8,
                    md: 8,
                  }"

                  :disabled="!form_data?.active"
                />
              </ObjectElement>
            </ObjectElement>
            <!-- Annually -->
            <CheckboxgroupElement
              :class="{ hidden: !is_edit }"
              name="month_of_year"
              view="tabs"
              :label="$t('Schedule')"
              :items="schedule_items"
              :conditions="[['reset_frequency', 'Annually']]"
              :override-classes="{
                CheckboxgroupElement: {
                  wrapper: override_class_map.Annually.wrapper,
                  wrapper_sm: '',
                },
                CheckboxgroupCheckbox: {
                  wrapper: 'flex items-center justify-center w-full text-sm font-semibold text-gray-700 cursor-pointer',
                  container: override_class_map.Annually.container,
                  wrapper_first_sm: '',
                  wrapper_last_sm: '',
                  wrapper_unselected: '',
                  wrapper_selected: 'bg-gray-100',
                },
              }"
              :disabled="!form_data?.active"
            />
            <ObjectElement :class="{ hidden: !is_edit }" name="on_the" :label="$t('On the')" :conditions="[['reset_frequency', 'Annually']]">
              <SelectElement
                name="week_number"
                :native="false"
                :can-clear="false"
                :can-deselect="false"
                :items="weeknumber_items"
                :columns="{
                  default: 4,
                  sm: 4,
                  md: 4,
                }"
                :disabled="!form_data?.active"
              />
              <SelectElement
                class="ml-4"
                name="week_day"
                label-prop="name"
                :native="false"
                :can-clear="false"
                :can-deselect="false"
                :items="weekday_items"
                :columns="{
                  default: 8,
                  sm: 8,
                  md: 8,
                }"
                :disabled="!form_data?.active"
              />
            </ObjectElement>
            <hr :class="{ hidden: !is_edit }">
            <CheckboxElement
              :class="{ hidden: !is_edit }"
              class="font-medium text-gray-700 w-1/2"
              name="schema_inheritance" :text="$t('Apply schema to sub folders')"
              :disabled="!form_data?.active"
            />
            <CheckboxElement
              :class="{ hidden: !is_edit }"
              class="font-medium text-gray-700 w-1/2"
              name="is_global" :text="$t('Reset number to 1 for each sub folder')"
              :disabled="!form_data?.active"
              :conditions="[['schema_inheritance', true]]"
            />
            <CheckboxElement
              :class="{ hidden: !is_edit }"
              class="font-medium text-gray-700 w-1/2"
              name="update_existing_files" :text="$t('Apply schema to existing files')"
              :disabled="!form_data?.active"
            />
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <template v-if="is_edit">
                  <HawkButton type="outlined" class="mr-4" @click="() => { is_edit = false; emit('close'); }">
                    {{ $t('Cancel') }}
                  </HawkButton>
                  <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
                </template>
                <template v-else>
                  <HawkButton type="outlined" class="mr-4" @click="is_edit = true">
                    Edit Auto numbering
                  </HawkButton>
                </template>
              </div>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
