<script setup>
import { storeToRefs } from 'pinia';
import slugify from 'slugify';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { accessTokenCookie } from '~/common/utils/common.utils';

const emit = defineEmits(['close']);
const authStore = useAuthStore();
const project_management_store = useProjectManagementStore();
const { active_schedule } = storeToRefs(project_management_store);

const state = reactive({
  is_loading: false,
});

const form$ = ref(null);

async function exportAsXLSX() {
  const only_tracked_activities = form$.value.data.only_tracked_activities;
  state.is_loading = true;
  const filename = active_schedule.value.name
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
  const result = await fetch(
    `${import.meta.env.VITE_APP_SENSEHAWK_HOST}/project-management/schedules/${active_schedule.value.uid}/download-p6-xlsx/?organization=${authStore.current_organization?.uid}`,
    {
      headers: {
        'Authorization': `jwt ${accessTokenCookie()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ tracked: only_tracked_activities }),
    },
  );
  if (!result.ok) {
    state.is_loading = false;
    return;
  }
  const blob = await result.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${slugify(filename, { lower: true, strict: true })}.xlsx`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  state.is_loading = false;
  emit('close');
}
</script>

<template>
  <HawkModalContainer>
    <Vueform ref="form$" size="sm" class="max-w-[600px]">
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              {{ $t("Export schedule") }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div class="col col-span-12">
            <IconHawkMsExcel class="w-[72px] h-[72px]" />
          </div>
          <div class="col col-span-12 text-sm font-regular text-gray-600 pt-4">
            {{ $t("export-schedule-description") }}
          </div>
          <div class="col col-span-12 flex text-sm pt-4">
            <CheckboxElement
              name="only_tracked_activities"
            />
            <h1 class="text-sm font-medium text-gray-700 ml-0.5">
              {{ $t('Export only tracked activities') }}
            </h1>
            <span v-tippy="$t('export-only-tracked-activities-description')" class="ml-2 mt-[1px]">
              <IconHawkInfoCircle class="w-4 h-4" />
            </span>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="export"
                :loading="state.is_loading"
                @click="exportAsXLSX"
              >
                {{ $t('Export') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
