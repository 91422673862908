<script setup>
import { Validator } from '@vueform/vueform';
import { cloneDeep, compact } from 'lodash-es';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import HawkSubmitButton from '~/common/components/atoms/hawk-submit-button.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  uid: {
    type: String,
  },
  attached_to: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['close']);

const { route, $toast } = useCommonImports();
const inventory_store = useInventoryStore();

const form$ = ref({});
const form = ref({});
const loading = ref(false);

const types = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
    options: true,
  },
  {
    label: 'Checkbox',
    value: 'checkboxes',
    options: true,
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Member',
    value: 'member',
  },
  {
    label: 'Members',
    value: 'members',
  },
  {
    label: 'Signature',
    value: 'signature',
  },
  {
    label: 'Email',
    value: 'email',
  },

  {
    label: 'Phone Number',
    value: 'phone_number',
  },
  {
    label: 'URL',
    value: 'url',
  },
];
const nameValidator = class extends Validator {
  get message() {
    return 'Custom field with this name already exists';
  }

  check(value) {
    if (props.uid) {
      const existing_name = inventory_store.custom_fields_map[props.uid]?.name;
      if (existing_name === value)
        return true;
    }
    const names = compact(inventory_store.custom_fields.map(item => item.active && item.name));
    return !names.includes(value);
  }
};

const has_options = computed(() => {
  const field = props.uid ? inventory_store.custom_fields_map[props.uid] : form.value;
  return field?.type === 'checkboxes' || field?.type === 'dropdown';
});

const filtered_custom_fields = computed(() => {
  const custom_fields = inventory_store.get_filtered_custom_fields({
    uid: props.attached_to?.uid,
    attached_to: props.attached_to?.type,
  });
  return props.uid ? [...custom_fields, inventory_store.$state.custom_fields_map?.[props.uid]] : custom_fields;
});

async function save() {
  try {
    loading.value = true;
    let payload = {};
    if (route.name === 'inventory-settings')
      if (!props.uid)
        payload = {
          attached_to: [],
          name: form.value.name,
          description: form.value.description,
          type: form.value.type,
          mandatory: form.value.mandatory,
          config: form.value.config,
        };
      else
        payload = {
          name: form.value.name,
          description: form.value.description,
          mandatory: form.value.mandatory,
          config: form.value.config,
        };

    else
      if (form.value.fields?.length)
        payload = form.value.fields.map((uid) => {
          const field = cloneDeep(inventory_store.custom_fields_map[uid]);
          const index = field.attached_to.findIndex(item => (item.uid ? item.uid === props.attached_to.uid : true) && item.type === props.attached_to?.type);
          if (index > -1) {
            field.attached_to[index].active = true;
            field.attached_to[index].uid = props.attached_to.uid;
            field.attached_to[index].type = props.attached_to.type;
          }
          else {
            field.attached_to.push({
              uid: props.attached_to.uid,
              type: props.attached_to.type,
              active: true,
              properties: { mandatory: false },
            });
          }
          return field;
        });
      else if (!props.uid)
        payload = {
          attached_to: [{
            uid: props.attached_to.uid,
            type: props.attached_to.type,
            active: true,
            properties: { mandatory: form.value.mandatory },
          },
          ],
          name: form.value.name,
          description: form.value.description,
          type: form.value.type,
          config: form.value.config,
        };

    if (props.uid) {
      const response = await inventory_store.$services.inventory_custom_fields.patch({
        id: props.uid,
        body: {
          custom_field: payload,
        },
      });
      inventory_store.custom_fields_map[props.uid] = response.data.custom_field;
      $toast({
        title: 'Updated successfully',
        text: 'The custom field has been updated successfully.',
        type: 'success',
      });
    }

    else if (form.value.fields?.length) {
      const response = await inventory_store.$services.inventory_custom_fields.patch({
        body: {
          custom_fields: payload,
        },
      });
      response.data.custom_fields.forEach((field) => {
        inventory_store.custom_fields_map[field.uid] = field;
      });
      $toast({
        title: 'Attached successfully',
        text: 'The custom field has been attached successfully.',
        type: 'success',
      });
    }
    else {
      const response = await inventory_store.$services.inventory_custom_fields.post({
        body: {
          custom_field: payload,
        },
      });
      inventory_store.custom_fields_map[response.data.custom_field.uid] = response.data.custom_field;
      $toast({
        title: 'Created successfully',
        text: 'The custom field has been created successfully.',
        type: 'success',
      });
    }
    inventory_store.custom_fields_map = { ...inventory_store.custom_fields_map };
    loading.value = false;
    emit('close');
  }
  catch ({ data: error }) {
    loading.value = false;
    const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
    $toast({
      title: title || 'Something went wrong',
      text: message || 'Please try again',
      type: 'error',
    });
  }
}
function onFormMounted(el$) {
  const field = props.uid && inventory_store.custom_fields_map[props.uid];
  if (field)
    el$.load({
      operation_type: route.name === 'inventory-settings' ? 'create' : 'attach',
      name: field.name,
      description: field.description,
      type: field.type || 'text',
      mandatory: field.attached_to.find(item => item.type === props.attached_to.type
          && (props.attached_to.uid ? item.uid === props.attached_to.uid : true))?.properties?.mandatory ?? field.mandatory,
      config: field.config,
    });
  else
    el$.load({
      operation_type: route.name === 'inventory-settings' ? 'create' : 'attach',
      type: 'text',
    });
}
</script>

<template>
  <HawkModalContainer>
    <Vueform
      ref="form$"
      v-model="form"
      :display-errors="false"
      class="grid grid-flow-col gap-4"
      size="sm"
      :endpoint="save"
      :loading="loading"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start items-center">
              {{ uid ? $t(`Update`) : $t(`Add`) }} {{ $t('Field') }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent class="" :is_scroll="false">
          <RadiogroupElement
            v-show="!uid && route.name !== 'inventory-settings'"
            name="operation_type"
            class="mb-4"
            :items="[{ value: 'attach', label: 'Attach fields' }, { value: 'create', label: 'Create field' }]"
          />
          <GroupElement
            v-if="form.operation_type === 'attach'"
            name="custom_field_group1"
          >
            <TagsElement
              name="fields"
              label="Choose fields to attach"
              placeholder="Choose fields to attach"
              label-prop="name"
              value-prop="uid"
              :search="true"
              track-by="name"
              :close-on-select="false"
              :items="filtered_custom_fields"
              :native="false"
              :add-classes="{
                TagsElement: {
                  select: {
                    option: ['break-all'],
                  },
                },
              }"
            />
          </GroupElement>
          <GroupElement
            v-if="form.operation_type === 'create'"
            name="custom_field_group2"
          >
            <TextElement
              name="name"
              label="Name"
              placeholder="Enter Name"
              :rules="[
                'required',
                nameValidator,
                'max:50',
              ]"
            />

            <TextareaElement
              name="description"
              label="Description"
              placeholder="Enter description"
              :autogrow="false"
              :rules="['max:160']"
            />
            <CheckboxElement
              label="Required"
              name="mandatory"
              :submit="false"
            />
            <SelectElement
              name="type"
              :items="types"
              label="Field Type"
              placeholder="Select type"
              :native="false"
              :disabled="!!uid"
              :rules="[
                'required',
              ]"
            />

            <GroupElement
              v-if="has_options"
              name="options_group"
            >
              <ListElement
                name="config"
                label="Options"
                :add-text="`+ ${$t('Add')} ${$t('option')}`"
                :initial="1"
                :rules="[
                  'distinct',
                ]"
                :sort="true"
                class="mb-4"
                :presets="['repeatable_list', 'repeatable_drag_icon']"
                :remove-classes="{
                  ListElement: {
                  },
                }"
                :add-classes="{
                  ListElement: {
                    add: [
                      'text-primary-700', 'text-sm',
                    ],
                    removeIcon: ['!h-4'],
                  },
                }"
              >
                <template #default="{ index }">
                  <TextElement
                    placeholder="Enter option value"
                    :name="index"
                    :rules="[
                      'required',
                    ]"
                    :messages="{ required: 'Field is required.' }"
                    :columns="{
                      default: { container: 11, label: 12, wrapper: 12 },
                      sm: { container: 11, label: 12, wrapper: 12 },
                      md: { container: 11, label: 12, wrapper: 12 },
                    }"
                    :add-classes="{
                      TextElement: {
                        inputContainer: ['pr-8'],
                      },
                    }"
                  />
                </template>
              </ListElement>
            </GroupElement>
          </GroupElement>
        </HawkModalContent>
        <HawkModalFooter class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center gap-3">
              <hawk-button type="outlined" color="gray" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <HawkSubmitButton :form$="form$">
                {{ uid ? $t('Update') : $t('Save') }}
              </HawkSubmitButton>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
