<script setup>
import { orderBy } from 'lodash-es';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  visible_filters: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['apply']);

const { $t } = useCommonImports();
const inventory_store = useInventoryStore();

const filters = ref({
  dates: [],
  date: null,
  group_by: [],
  workflow: null,
});

const workflows = computed(() => orderBy(inventory_store.workflows, ['order_index'], ['asc']).map((workflow) => {
  return {
    name: workflow.name,
    uid: workflow.uid,
  };
}));

const supported_custom_field_types = [
  'text',
  'number',
  'dropdown',
  'checkboxes',
  'date',
  'members',
  'member',
  'email',
];

const filtered_custom_fields = computed(() => {
  return inventory_store.custom_fields
    .filter(field => field.active && supported_custom_field_types.includes(field.type))
    .map((field) => {
      return {
        ...field,
        name: field.name,
        uid: `custom_field.${field.uid}`,
      };
    });
});

const group_by_filtered_options = computed(() => {
  return [
    { name: 'Date', uid: 'date' },
    { name: 'Warehouse', uid: 'warehouse' },
    { name: 'Asset', uid: 'asset' },
    ...filtered_custom_fields.value,
  ];
});

const display_filters = computed(() => {
  return [
    {
      name: $t('Type'),
      is_static: true,
      data_type: 'single_value_select',
      operators: ['equalsExactly'],
      option_type: null,
      options: [...workflows.value],
      uid: 'workflow',
    },
    ...(props.visible_filters.includes('date')
      ? [{
          uid: 'date',
          name: $t('Date'),
          data_type: 'date',
          is_static: true,
          option_type: null,
          operators: ['isEqualTo'],
          options: [],
          type: 'date',
        }]
      : []),
    ...(props.visible_filters.includes('date_range')
      ? [{
          uid: 'dates',
          name: $t('Date'),
          data_type: 'date',
          is_static: true,
          option_type: null,
          operators: ['between'],
          options: [],
          type: 'date',
        }]
      : []),
    ...(props.visible_filters.includes('group_by')
      ? [{
          uid: 'group_by',
          name: $t('Group by'),
          is_static: true,
          data_type: 'single_value_select',
          operators: ['equalsExactly'],
          option_type: null,
          options: group_by_filtered_options.value,
        }]
      : []),
  ];
});

function applyFilters(filter) {
  const filter_value = {};
  filter.forEach((element) => {
    filter_value[element.field] = element.value;
  });
  filters.value = { ...filter_value };
  emit('apply', filters.value);
}

function get_filters() {
  return filters.value;
}

defineExpose({ get_filters });
</script>

<template>
  <HawkDisplayFilters
    :display_filters="display_filters"
    @apply="applyFilters"
  />
</template>
