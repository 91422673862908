<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useTasksStore } from '~/tasks/store/tasks.store';

const props = defineProps({
  options: {
    type: Object,
    default: () => {},
  },
  asset_id: {
    type: String,
    default: '',
  },
  multi: {
    type: Boolean,
    default: false,
  },
  show_only_org_templates: {
    type: Boolean,
    default: false,
  },
});

const auth_store = useAuthStore();
const common_store = useCommonStore();
const template_store = useTasksStore('template');

const items = ref([]);
const loading = ref(false);

onMounted(async () => {
  await getData();
});

async function getData() {
  loading.value = true;
  items.value = [];
  await template_store.set_tasks({
    limit: Number.MAX_SAFE_INTEGER,
    is_template: true,
  });

  if (template_store.tasks().length)
    items.value = groupDataByAsset(template_store.tasks());

  loading.value = false;
}

function groupDataByAsset(template_list) {
  // This method has code duplication for the sake of simplicity
  const asset_templates = {};
  const org_templates = [];
  template_list.forEach((task) => {
    if (task.target_element.type === 'organization') {
      org_templates.push(task);
    }
    else if (task.target_element.asset === props.asset_id) {
      const asset = common_store.get_asset(task.target_element.asset);
      if (asset) {
        if (!Object.keys(asset_templates).includes(asset.uid))
          asset_templates[asset.uid] = {
            label: asset.name,
            items: [],
          };
        asset_templates[asset.uid].items.push(task);
      }
    }
    else if (props.asset_id === '' && !props.show_only_org_templates) {
      const asset = common_store.get_asset(task.target_element.asset);
      if (asset) {
        if (!Object.keys(asset_templates).includes(asset.uid))
          asset_templates[asset.uid] = {
            label: asset.name,
            items: [],
          };
        asset_templates[asset.uid].items.push(task);
      }
    }
  });

  return [
    ...Object.values(asset_templates),
    {
      label: auth_store.current_organization?.name,
      items: org_templates,
    },
  ];
}
</script>

<template>
  <v-tags-input
    v-if="multi"
    :options="{
      'name': 'template_uid',
      'label': $t('Template'),
      'placeholder': $t('Select Templates'),
      'track-by': 'name',
      'label-prop': 'name',
      'value-prop': 'uid',
      'items': items,
      'hide-selected': false,
      'groups': true,
      'group-hide-empty': true,
      'search': true,
      'group-select': false,
      ...options,
    }"
  />
  <SelectElement
    v-else
    v-bind="{
      'name': 'template_uid',
      'label': $t('Template'),
      'placeholder': $t('Select Template'),
      'track-by': 'name',
      'label-prop': 'name',
      'value-prop': 'uid',
      'items': items,
      'hide-selected': false,
      'groups': true,
      'group-hide-empty': true,
      'search': true,
      ...options,
    }"
  >
    <template #option="{ option }">
      <p v-if="template_store.get_current_task(option.uid)">
        {{ option.name }}
      </p>
      <p v-else class="whitespace-nowrap inline-block w-auto truncate">
        <span class="font-semibold">{{ $t('Create template') }}: </span> {{ option.name }}
      </p>
    </template>
  </SelectElement>
</template>
