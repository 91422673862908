<script setup>
import { MenuItem } from '@headlessui/vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  status: {
    type: Number,
    default: 1,
  },
  position: {
    type: String,
    default: 'bottom-left',
  },
});

const emit = defineEmits(['close', 'status-selected']);

const is_template = inject('is-template');
const { task_uid } = inject('task-uid');
const task_store = inject('task_store');

const auth_store = useAuthStore();

const route = useRoute();

const { checkStatusUpdatePermission, checkTaskPermission } = useTasksPermissions();

const show_dropdown = ref(false);

const details = computed(() => task_store.get_current_task(task_uid.value));
const can_modify_status = computed(() => checkTaskPermission({ permission: 'can_modify_status', instance: details.value }));

const class_map = {
  1: 'bg-warning-600',
  2: 'bg-primary-600',
  3: 'bg-success-600',
  4: 'bg-gray-600',
  5: 'bg-error-600',
};

function handleTriggerClick(e) {
  if (!can_modify_status.value) {
    e.stopPropagation();
    e.preventDefault();
  }
}
</script>

<template>
  <hawk-menu :position="position" @open="show_dropdown = true" @close="show_dropdown = false">
    <template #trigger>
      <slot name="trigger">
        <div
          type="plain"
          :class="class_map[status]"
          class="flex gap-2 items-center px-3 py-2 rounded-lg text-xs text-white cursor-pointer"
          @click="handleTriggerClick"
        >
          <p class="text-sm font-semibold whitespace-nowrap">
            {{ task_store.status_map[status].label }}
          </p>
          <IconHawkChevronDown
            v-if="can_modify_status"
            class="text-white transition-transform"
            :class="{ 'rotate-180 !visible': show_dropdown }"
          />
        </div>
      </slot>
    </template>
    <template #content>
      <MenuItem
        v-for="status_item in task_store.status_values"
        v-slot="{ active }"
        :key="status_item.value"
        as="div"
        class="px-2 py-1 w-[164px]"
        :class="{ 'cursor-not-allowed opacity-50 pointer-events-none': !checkStatusUpdatePermission(details, status_item.value) }"
      >
        <div
          :class="[
            {
              [class_map[status_item.value]]: active,
              'text-white': active,
            },
          ]"
          class="flex items-center justify-between whitespace-nowrap cursor-pointer p-2 rounded-md text-sm text-gray-700 font-medium"
          @click="emit('status-selected', status_item.value)"
        >
          <div class="flex items-center">
            <div :class="class_map[status_item.value]" class="h-[8px] w-[8px] rounded-full mr-2" />
            <p>{{ status_item.label }}</p>
          </div>
          <IconHawkCheck v-if="status_item.value === status" class="w-4" :class="active ? 'text-white' : 'text-blue-500'" />
        </div>
      </MenuItem>
    </template>
  </hawk-menu>
</template>
