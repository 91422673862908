<script setup>
// --------------------------------- Imports -------------------------------- //
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  sidebar_top_items: {
    type: Object,
    default: () => ({}),
  },
  sidebar_bottom_items: {
    type: Object,
    default: () => ({}),
  },
});
const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
</script>

<template>
  <div class="relative">
    <div
      class="h-[calc(100vh-64px)] w-20 transition-all duration-200 ease-in-out fixed z-999 pointer-events-auto bg-white border-r border-gray-300"
    >
      <div class="flex flex-col h-full scrollbar">
        <div
          v-for="menu in sidebar_top_items"
          :key="menu.uid"
          v-tippy="{ content: menu?.tooltip, placement: 'right' }"
          class="flex w-full py-2 flex-col items-center gap-1 text-gray-600 cursor-pointer hover:bg-gray-50 border-y border-white"
          :class="{
            'bg-primary-50 text-primary-600 !border-primary-100 font-semibold': menu.to.name === (route.meta.parent || route.name),
            '!text-gray-300 hover:bg-transparent !cursor-not-allowed': menu.is_disabled,
          }"
          @click="!menu.is_disabled && router.push(menu.to)"
        >
          <component :is="menu.icon" class="w-6 h-6" />
          <div
            class="text-xs font-medium leading-4 text-center"
            :class="{ 'font-semibold': menu.to.name === (route.meta.parent || route.name) }"
          >
            {{ menu.label }}
          </div>
        </div>
        <div class="mt-auto">
          <div
            v-for="menu in sidebar_bottom_items"
            :key="menu.uid"
            v-tippy="{ content: menu?.tooltip, placement: 'right' }"
            class="flex w-full py-2 flex-col items-center gap-1 text-gray-600 cursor-pointer hover:bg-gray-100 border-y border-white"
            :class="{
              'bg-primary-50 text-primary-600 !border-primary-100 font-semibold': menu.to.name === (route.meta.parent || route.name),
              '!text-gray-300 hover:bg-transparent !cursor-not-allowed': menu.is_disabled,
            }"
            @click="!menu.is_disabled && router.push(menu.to)"
          >
            <component :is="menu.icon" class="w-6 h-6" />
            <div class="text-xs font-medium leading-4 text-center">
              {{ menu.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
