<script setup>
import { find, flatten, isEqual } from 'lodash-es';
import { ref } from 'vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const dashboard_schedule_store = useDashboardScheduleStore();
const $services = inject('$services');

const data_source = ref(null);
const response_data = ref([]);
const loading = ref(false);
const payload = ref({});
const table_string = ref('');

const widget_data = computed(() => props.data?.data || {});

async function getReports() {
  loading.value = true;
  payload.value = dashboard_schedule_store.parse_schedule_form_to_server_format(widget_data.value);

  try {
    const { data } = await $services.project_management.get_graph({ body: payload.value });
    response_data.value = data.data;
    const keys = flatten(response_data.value[0].series.map(a => Object.keys(a)));
    const response = [];
    keys.forEach((key) => {
      response.push({
        seriesname: key,
        data: response_data.value.map((act) => {
          return { value: find(act.series, key)?.[key] || '0' };
        }),
      });
    });

    data_source.value = {
      categories: [
        {
          category: response_data.value.map((a) => {
            const name = find(payload.value?.properties.activity_table_activities, { uid: a.uid });
            return { label: name.name };
          }),
        },
      ],
      dataset: response,

    };

    loading.value = false;
  }
  catch {
    loading.value = false;
  }
}

const chart_config = computed(() => props.data.data.chart_config);
const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: 'mscolumn2d',
  dataSource: {
    chart: {
      theme: 'fusion',
      xAxisName: chart_config.value?.x_label || 'Activity',
      yAxisName: chart_config.value?.y_label || 'Progress',
      subCaption: chart_config.value?.description || '',
      maxColWidth: '150',
      yAxisValuesPadding: '175',
      showVLineLabelBorder: '1',
      numVDivLines: props.data.data.activities.length - 1,
      showlegend: props.chart_config?.legend_position !== 'none' ? '1' : '0',
    },
    table: table_string.value,
    ...data_source.value,
  },
  events: {
    renderComplete(evt) {
      let str = '<table id="outTable" class="w-full text-sm" style="table-layout: fixed;margin:10px;width:100%">';
      str += '<tr>';
      const length = evt.sender.getJSONData().categories[0].category.length;
      str += `<td class=" border w-[200px]" style="text-transform: capitalize;font-weight:600">${props.data.data.activity_history_interval}</td>`;
      for (let i = 0; i < length; i++)
        str += `<td class="border" id="g${i}" style="text-align: center;font-weight:600" >${evt.sender.getJSONData().categories[0].category[i].label}</td>`;

      str += '</tr>';
      for (let j = 0; j < evt.sender.getJSONData().dataset.length; j++) {
        str += '<tr>';
        str += `<td class="row-header border">${evt.sender.getJSONData().dataset[j].seriesname} </td>`;
        for (let i = 0; i < evt.sender.getJSONData().dataset[j].data.length; i++)

          str += `<td class="border" id="${j}-${i}">${evt.sender.getJSONData().dataset[j].data[i].value} </td>`;

        str += '</tr>';
      }
      str += '</table>';

      document.getElementById(`table-${graph_config.value.renderAt}`).innerHTML = str;
      table_string.value = str;
    },

    dataplotdragend(evt, arg) {
      const dsIndx = arg && arg.datasetIndex;
      const dtIndx = arg && arg.dataIndex;
      const val = arg && Math.round(arg.endValue * 100) / 100;
      document.getElementById(`${dsIndx}-${dtIndx}`).innerHTML = `${val}M`;
      document.getElementById(`${dsIndx}-${dtIndx}`).style.background = '#ffe8e2';
    },

    datarestored(evtObj) {
      for (let i = 0; i < evtObj.sender.getJSONData().categories[0].category.length; i++)
        document.getElementById(`g${i}`).innerHTML = evtObj.sender.getJSONData().categories[0].category[i].label;

      for (let k = 0; k < evtObj.sender.args.dataSource.dataset.length; k++)

        changeValues(evtObj.sender.args.dataSource.dataset[k].data, k);
    },
  },

}));

watch(() => props.data.data, async (new_val, old_val) => {
  if (!isEqual(new_val, old_val))
    await getReports();
}, { immediate: true, deep: true });

watch(() => props.activeSelectedRange, async (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    await getReports();
  }
});
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />
    <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
      <graphComponent v-if="!loading && graph_config?.renderAt" :id="`combined-${props.id}`" :configuration="graph_config" :table_chart="true" :chart_config="chart_config" group_key="interval" />
      <div :id="`table-${graph_config?.renderAt}`" />
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep() {
  th, td {
    padding: 5px 15px;
    text-align: left;
  }
}
</style>
