const routes = [
  {
    path: '/:asset_id/cnc-reports',
    component: () => import('~/cnc/pages/cnc.vue'),
    children: [
      {
        path: '',
        name: 'cnc-reports',
        component: () => import('~/cnc/pages/cnc-input-review.vue'),
        meta: {
          title: 'Reports',
        },
      },
      {
        path: 'executive-summary',
        name: 'cnc-executive-summary',
        component: () => import('~/cnc/pages/cnc-executive-summary.vue'),
        meta: {
          title: 'ExecutiveSummary',
        },
      },
    ],
  },

];
export default routes;
