<script setup>
import { debounce } from 'lodash-es';

import TaskName from '~/tasks/components/molecule/task-name.vue';
import TaskStatus from '~/tasks/components/atoms/task-status.vue';

const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
  asset_id: {
    type: String,
    default: '',
  },
  additional_queries: {
    type: Object,
    default: () => ({}),
  },
  additional_table_options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['selected']);

const $services = inject('$services');

const search_key = toRefs(props).search;
const table_instance = ref(null);

const state = reactive({
  loading: false,
  scroll_loading: false,
  selected_tasks: [],
  tasks: [],
  total_task_count: 0,
  page_size: 12,
});
const columns = [
  {
    accessorKey: 'select',
    id: 'select',
  },
  {
    accessorKey: 'name',
    id: 'name',
    size: '400',
  },
  {
    accessorKey: 'status',
    id: 'status',
  },
];

onMounted(async () => {
  await getData({}, '');
});

watch(search_key, debounce(async (newVal, oldVal) => {
  if (newVal !== oldVal)
    await getData({}, newVal);
}, 500));

async function getData({ pagination_state }, search = null, init = true) {
  try {
    if (init)
      state.loading = true;
    else
      state.scroll_loading = true;
    const query = {
      page_number: 1,
      page_size: state.page_size,
      q: search,
      is_template: false,
      ...(props.asset_id && { asset_uid: props.asset_id }),
      ...props.additional_queries,
    };
    state.page_size = pagination_state?.pageSize || 12;
    if (pagination_state?.pageIndex && pagination_state?.pageSize) {
      query.page_number = pagination_state.pageIndex;
      query.page_size = pagination_state.pageSize;
    }

    const { data, headers } = await $services.tasks.getAll({ query });

    if (init)
      state.tasks = data.tasks.filter(task => !props.restricted_items.includes(task.uid));
    else
      state.tasks = [...state.tasks, ...data.tasks.filter(task => !props.restricted_items.includes(task.uid))];
    state.total_task_count = headers['x-total-count'];

    if (init)
      state.loading = false;
    else
      state.scroll_loading = false;
  }
  catch (error) {
    logger.log(error);
    state.tasks = [];
    if (init)
      state.loading = false;
    else
      state.scroll_loading = false;
  }
}

function handleTaskSelection(e) {
  state.selected_tasks = e.map(tasks_list_row => tasks_list_row.original);
  emit('selected', state.selected_tasks);
}
</script>

<template>
  <div class="text-sm font-medium text-gray-600 px-4 mb-2">
    {{ $t('Tasks') }}
  </div>
  <div v-if="!state.loading && !state.tasks.length" class="px-5 text-sm text-gray-600 font-medium h-[350px]">
    {{ $t("No results found") }}
  </div>
  <div v-else id="attach-tasks" class="h-[350px] scrollbar">
    <HawkTable
      container_class="border-0 h-full"
      parent_id_for_infinite_scrolling="attach-tasks"
      :pagination_config="{ totalRows: state.total_task_count, pageSize: state.page_size }"
      :is_loading="state.loading"
      :data="state.tasks"
      :columns="columns"
      :show_menu_header="false"
      :header_visible="false"
      :disable_resize="true"
      :enable_infinite_scroll="true"
      :is_loading_on_scroll="state.scroll_loading"
      is_gapless
      v-bind="additional_table_options"
      @loadMore="getData($event, search_key, false)"
      @tableInstance="table_instance = $event"
      @select-row="handleTaskSelection"
    >
      <template #name="task_name">
        <TaskName
          :task="task_name.data.row.original"
          :asset_uid="asset_id"
          :show_asset="true"
        />
      </template>
      <template #status="status">
        <TaskStatus :status="status.data.getValue()" />
      </template>
      <template #rowLoader>
        <HawkSkeleton custom_classes="!w-32 py-2" />
      </template>
    </HawkTable>
  </div>
</template>
