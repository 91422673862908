<script setup>
import { uniqBy } from 'lodash-es';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { getFlatData } from '~/system-model/utils/helper';

const props = defineProps({
  connection: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close']);
const system_model_store = useSystemModelStore();

const state = reactive({
  is_loading: false,
});

const active_component = computed(() => system_model_store.active_component);
const flat_component_hierarchy = computed(() => {
  const components = uniqBy(getFlatData(system_model_store.active_component_hierarchy), 'uid');
  return components.filter(item => active_component.value.uid !== item.uid)
    .map(item => ({ label: item.name, value: item.uid }));
});

function formMounted(el$) {
  if (props.connection)
    el$.update({
      child_component: props.connection.child_uid,
      parent_component: props.connection.uid,
      layout_x: props.connection?.layouts?.layout_x || 0,
      layout_y: props.connection?.layouts?.layout_y || 0,
      orientation: props.connection?.alignment,
    });
}

async function setAndUpdateConnection(form_data) {
  const data = {
    uid: form_data.child_component,
    layout: {
      layout_x: Number.parseInt(form_data.layout_x),
      layout_y: Number.parseInt(form_data.layout_y),
      alignment: form_data.orientation,
    },
  };
  await system_model_store.set_and_update_connection({
    body: [data],
    parent_uid: form_data.parent_component,
  });
}

async function submitConnections(form$) {
  try {
    state.is_loading = true;
    const form_data = form$.data;
    await setAndUpdateConnection(form_data);
    await system_model_store.set_active_component_details({
      component_id: active_component.value.uid,
    });
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="submitConnections"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('New Connection') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <GroupElement
            name="group"
            :rules="['required']"
            :add-classes="{
              ElementLayout: {
                container: ['mb-6'],
              },
            }"
            :info="$t('Choose the upstream/downstream connections')"
          >
            <template #label>
              <div class="text-gray-700 font-medium text-sm">
                {{ $t('Connections') }}
              </div>
            </template>

            <SelectElement
              v-bind="{
                columns: {
                  default: { container: 5 },
                  sm: { container: 5 },
                  md: { container: 5 },
                },
                readonly: true,
                default: active_component.uid,
                name: 'child_component',
                items: [{ label: active_component.name, value: active_component.uid }],
                search: false,
                native: false,
                autocomplete: 'off',
                rules: ['required'],
                canClear: false,
                disabled: !!props.connection,
              }"
            />
            <StaticElement
              name="static"
              class="max-h-[36px]"
              :add-classes="{
                ElementLayout: {
                  container: ['flex items-center justify-center border border-gray-300 rounded-lg'],
                },
              }"
              :columns="{
                default: { container: 2 },
                sm: { container: 2 },
                md: { container: 2 },
              }"
            >
              <div>
                <IconHawkArrowRight class="text-gray-600" />
              </div>
            </StaticElement>
            <SelectElement
              v-bind="{
                columns: {
                  default: { container: 5, label: 4, wrapper: 12 },
                  sm: { container: 5, label: 4, wrapper: 12 },
                  md: { container: 5, label: 4, wrapper: 12 },
                },
                name: 'parent_component',
                items: flat_component_hierarchy,
                search: true,
                native: false,
                autocomplete: 'off',
                rules: ['required'],
                disabled: !!props.connection,
              }"
            />
          </GroupElement>
          <GroupElement
            name="layout"
            :add-classes="{
              ElementLayout: {
                container: ['mb-6'],
              },
            }"
            :info="$t('Choose the horizontal/vertical layout between the components')"
          >
            <template #label>
              <div class="flex gap-1 text-gray-700 font-medium text-sm">
                {{ $t('Layout') }}
              </div>
            </template>

            <TextElement
              name="layout_x"
              placeholder="x"
              input-type="number"
              :columns="{
                default: { container: 5 },
                sm: { container: 5 },
                md: { container: 5 },
              }"
              :default="0"
              :attrs="{ min: 0 }"
            />
            <StaticElement
              name="static"
              class="max-h-[36px]"
              :add-classes="{
                ElementLayout: {
                  container: ['flex items-center justify-center'],
                },
              }"
              :columns="{
                default: { container: 2 },
                sm: { container: 2 },
                md: { container: 2 },
              }"
            >
              <div>
                <IconHawkCross class="h-3 w-3 text-gray-600" />
              </div>
            </StaticElement>
            <TextElement
              input-type="number"
              name="layout_y"
              placeholder="y"
              :columns="{
                default: { container: 5 },
                sm: { container: 5 },
                md: { container: 5 },
              }"
              :default="0"
              :attrs="{ min: 0 }"
            />
          </GroupElement>
          <RadiogroupElement
            name="orientation"
            view="tabs"
            :items="['SW', 'NW', 'SE', 'NE']"
            :columns="{
              default: { container: 12, wrapper: 7 },
              sm: { container: 12, wrapper: 7 },
              md: { container: 12, wrapper: 7 },
            }"
            :add-classes="{
              RadiogroupRadio: {
                wrapper: ['font-semibold'],
                wrapper_selected: ['!bg-gray-100 text-gray-900'],
                wrapper_unselected: ['text-gray-700'],
              },
            }"
            :remove-classes="{
              RadiogroupRadio: {
                wrapper_selected: ['!bg-primary-50', '!border-primary-500'],
              },
            }"
            :info="$t('Choose the indexing strategy to use. This is how the components are numbered')"
          >
            <template #label>
              <div class="flex gap-1 text-gray-700 font-medium text-sm">
                {{ $t('Orientation') }}
              </div>
            </template>
          </RadiogroupElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="!!props.connection ? $t('Update') : $t('Save')"
                :loading="state.is_loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
