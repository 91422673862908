<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { onMounted } from 'vue';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import DashboardSingleFormFieldsPopup from '~/dashboard/components/form-detail-fields/dashboard-single-form-fields-popup.vue';

const emit = defineEmits(['update']);

const dashboard_forms_store = useDashboardFormsStore();

const {
  forms_configuration,
  active_template_id,
} = storeToRefs(dashboard_forms_store);

const form$ = ref(null);
const fields_data = ref(null);
const form_data = ref({
  type: 'all',
});

const single_form_fields_popup = useModal({
  component: DashboardSingleFormFieldsPopup,
  attrs: {
    onClose() {
      single_form_fields_popup.close();
    },
    onSave(data) {
      emitUpdate(data);
      form$.value.el$('type').update('custom');
      fields_data.value = { ...data };
      single_form_fields_popup.close();
    },
  },
});

function emitUpdate(data) {
  emit('update', data);
}

function updateConfigurationType(data) {
  form_data.value.type = data.type;
  if (data.type === 'all')
    emitUpdate(null);
  else
    emitUpdate(fields_data.value);
}

watch(active_template_id, (new_val, old_val) => {
  if (old_val && new_val !== old_val) {
    form$.value.el$('type').update('all');
    emitUpdate(null);
  }
});

onMounted(() => {
  fields_data.value = forms_configuration.value?.single_form_visibility || null;
  if (fields_data.value)
    form$.value.el$('type').update('custom');
});
</script>

<template>
  <div>
    <div class="text-sm text-gray-700 font-medium mb-4">
      {{ $t('Fields') }}
    </div>
    <Vueform
      ref="form$"
      v-model="form_data"
      size="sm"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      @change="updateConfigurationType($event)"
    >
      <RadiogroupElement
        name="type"
        :items="[
          {
            value: 'all',
            label: $t('Show all form fields'),
          },
          {
            value: 'custom',
            label: $t('Show custom form fields'),
          },
        ]"
        :label="$t('Fields visibility')"
        :default="form_data.type"
      />
      <ButtonElement
        v-if="form_data.type === 'custom'"
        name="button"
        secondary
        class="!col-start-5 !col-span-8"
        @click="single_form_fields_popup.open()"
      >
        {{ $t('Choose fields') }}
      </ButtonElement>
    </Vueform>
  </div>
</template>
