<script setup>
import { useModal } from 'vue-final-modal';
import HawkOnboardingPopup from '~/common/components/organisms/hawk-illustrations/hawk-onboarding-popup.vue';

const props = defineProps({
  on_boarding_for: {
    type: String,
    default: 'forms',
  },
});

const { open: openAssetModal, close: closeAssetModal, patchOptions } = useModal({
  component: HawkOnboardingPopup,
});

function AssetSelectionHandler() {
  patchOptions(
    {
      attrs: {
        on_boarding_for: props?.on_boarding_for,
        onClose() {
          closeAssetModal();
        },
        on_submit: async (e) => {
          assetResourceActions(e);
          closeAssetModal();
        },
      },
    },
  );
  openAssetModal();
}
</script>

<template>
  <HawkButton icon type="text" class="cursor-pointer" @click="AssetSelectionHandler">
    <IconHawkHelpCircle />
  </HawkButton>
</template>
