<script setup>
import { useTransmittalWorkflowConfiguration } from '~/dms/composables/useTransmittalWorkflow.js';

const props = defineProps({
  transmittal: {
    type: Object,
  },
});

const emit = defineEmits(['close']);

const form$ = ref(null);

const { outcome_strategy_list, default_statuses, steps } = useTransmittalWorkflowConfiguration({ workflow: props?.transmittal });

function loadData() {
  form$.value.load(props?.transmittal, true);
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[90%]" :options="{ 'focus-trap': { allowOutsideClick: true } }">
    <hawk-modal-header @close="emit('close')">
      <template #title>
        {{ $t('Workflow') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <Vueform ref="form$" :disabled="true">
        <div class="col-span-12">
          <transmittal-workflow-detail-workflow v-if="transmittal?.workflow" class="px-4" :published="true" :disabled="true" :steps="steps" :outcome_strategy_list="outcome_strategy_list" :default_statuses="default_statuses" @mounted="loadData" />
        </div>
      </Vueform>
    </hawk-modal-content>
  </hawk-modal-container>
</template>
