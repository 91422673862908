import { computed, ref } from 'vue';
import { groupBy, sortBy } from 'lodash-es';
import useAbortController from '~/common/composables/abort-controller.js';
import { csvInjectionProtector } from '~/common/utils/common.utils.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

export function useInventoryBom() {
  const { route } = useCommonImports();
  const controllers = useAbortController();
  const is_bom_exporting = ref(false);
  const inventory_store = useInventoryStore();

  const statuses = computed(() => inventory_store.statuses);

  function addDataToWorkSheet(worksheet, procurement_data) {
    const headers = [
      { header: 'Item number', key: 'number' },
      { header: 'Item name', key: 'name' },
      { header: 'UOM', key: 'uom' },
      { header: 'Description', key: 'description' },
      { header: 'Scope', key: 'scope' },
      ...(route?.params?.warehouse_id ? [] : [{ header: 'Ordered', key: 'ordered_qty' }]),
      ...sortBy(statuses.value.map(status => ({ header: status.name, key: status.uid })), ['header']),
      { header: 'Status', key: 'status' },
    ];

    worksheet.columns = headers.map(header => ({ ...header, width: 25 }));

    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'd9d9d9' },
      };
      cell.font = { bold: true };
    });

    // Get status column index
    let column_index = -1;
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (cell?._column?._key === 'status')
        column_index = colNumber;
    });
    // Cell colors
    const status = {
      less: { backgroundColor: 'FFFEF2F2', textColor: 'FFB91C1C' },
      more: { backgroundColor: 'FFEFF6FF', textColor: 'FF2E90FA' },
    };

    procurement_data.forEach((procurement, i) => {
      const data = {
        ...procurement,
        number: procurement.number,
        name: csvInjectionProtector(procurement.name),
        uom: csvInjectionProtector(procurement.uom),
        description: csvInjectionProtector(procurement.description),
        scope: procurement.scope,
        ordered_qty: procurement.ordered_qty,
        status: Math.abs(+procurement.scope - +procurement.available_qty),
      };
      worksheet.addRow(data);

      // Color status cell
      const row = worksheet.getRow(i + 2);
      const cell = row.getCell(column_index);
      // Checking if value is > 0
      if (cell?.value) {
        const status_colors = (procurement.item_status?.status === -1 ? status.less : status.more);
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: status_colors.backgroundColor },
        };
        cell.font = {
          color: { argb: status_colors.textColor },
        };
      }
    });
  }

  async function exportBom(procurement_data) {
    controllers.add('export_bom');
    try {
      const ExcelJS = await import('exceljs');
      const { saveAs } = await import('file-saver');

      const workbook = new ExcelJS.Workbook();

      const group_by_category = groupBy(procurement_data, item => item.category);
      Object.entries(group_by_category).forEach(([category, entries]) => {
        let sheet_name = csvInjectionProtector(category) || 'No type associated';
        const characters_not_allowed = '*?:\\/';
        const sheet_name_array = sheet_name.split('');
        sheet_name = sheet_name_array.map(char =>
          characters_not_allowed.includes(char) ? ' ' : char,
        ).join('');
        const worksheet = workbook.addWorksheet(sheet_name);
        addDataToWorkSheet(worksheet, entries);
      });

      if (is_bom_exporting.value) {
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'Bom.xlsx');
        setTimeout(() => {
          setExporting(false);
        }, 1500);
      }
    }
    catch (error) {
      cancelBomExporting();
      logger.error(error);
    }
  }

  function cancelBomExporting() {
    controllers.abort('export_bom');
    setExporting(false);
  }

  function setExporting(value) {
    is_bom_exporting.value = value;
  }

  return {
    exportBom,
    cancelBomExporting,
    setExporting,
    is_bom_exporting,
  };
}
