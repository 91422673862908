<script setup>
import { storeToRefs } from 'pinia';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardFilters from '~/dashboard/components/filters/dashboard-filters.vue';
import { getDateInterval, isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';

const emit = defineEmits(['update']);

const dashboard_schedule_store = useDashboardScheduleStore();
const dashboard_store = useDashboardStore();

const {
  active_schedule,
} = storeToRefs(dashboard_schedule_store);

const {
  widget_configuration,
} = storeToRefs(dashboard_store);

const initial_state = ref([]);
const initial_state_set = ref(false);

const default_filters = [
  ['Activity Status', 'select', 'status'],
  ['Critical', 'select', 'is_critical'],
  ['Start date status', 'select', 'start_status'],
  ['Finish date status', 'select', 'finish_status'],
  ['Start date', 'date', 'start'],
  ['Finish date', 'date', 'finish'],
  ['Actual start', 'date', 'actual_start'],
  ['Actual Finish', 'date', 'actual_finish'],
  ['Planned start', 'date', 'planned_start'],
  ['Planned start', 'date', 'planned_start'],
  ['Planned start', 'date', 'planned_start'],
].map(item => ({
  label: item[0],
  type: item[1],
  value: item[2],
}));

const activity_codes = computed(() => {
  return active_schedule.value?.activity_codes.map((a) => {
    const values = a.values.map(item => ({
      value: item.name,
      label: item.description,
    }));

    return {
      value: `activity_code_values_map.${a.name}`,
      label: a.name,
      type: 'activity_code',
      items: values,
    };
  }) || [];
});

const custom_fields = computed(() => {
  const keys = Object.keys(active_schedule.value?.custom_fields || {});

  return keys.map((key) => {
    const values = active_schedule.value?.custom_fields?.[key]?.values?.map(item => ({
      value: item,
      label: item,
    })) || [];

    return {
      value: `custom_field_values.${key}`,
      label: key,
      type: 'custom_field',
      items: values,
    };
  });
});

const all_filters = computed(() => {
  return [
    ...default_filters,
    ...activity_codes.value,
    ...custom_fields.value,
  ];
});

function formatFilters(data) {
  return data.map((f) => {
    if (!f)
      return null;

    let value = f.value;
    if (['is_in', 'is_not_in'].includes(f.operator) && typeof value !== 'object')
      value = [value];

    const is_relative_date = isDateIntervalOperator(f.operator);

    return {
      field: f.filter_type,
      ...(is_relative_date
        ? {
            value: getDateInterval(f.operator),
            date_operator: f.operator,
            operator: 'between',
          }
        : {
            operator: f.operator,
            value,
          }),
    };
  });
}

function updateFilters(data) {
  if (!data?.length)
    emit('update', []);

  const filters = formatFilters([...data]);

  emit('update', filters);
}

function setInitialState() {
  const filters = widget_configuration.value?.filters;

  if (!Array.isArray(filters) && !filters?.length) {
    initial_state.value = [];
    initial_state_set.value = true;

    return;
  }

  initial_state.value = filters?.map((f) => {
    return {
      filter_type: f.field,
      operator: f.date_operator ? f.date_operator : f.operator,
      value: f.date_operator ? null : f.value,
    };
  }) || [];

  initial_state_set.value = true;
}

setInitialState();

watch(active_schedule, (new_val, old_val) => {
  emit('update', {});

  if (!old_val && new_val)
    setInitialState();
  else
    initial_state.value = [];
});
</script>

<template>
  <div class="text-sm text-blue-600 font-medium my-4 cursor-pointer">
    {{ $t('Advanced filters') }}
  </div>
  <DashboardFilters
    v-if="initial_state_set"
    :key="active_schedule"
    :custom_fields="all_filters"
    :initial_state="initial_state"
    service="schedule"
    @update="updateFilters($event)"
  />
</template>
