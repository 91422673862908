<script setup>
// stores
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDocumentStore } from '~/dms/store/document.store.js';

const props = defineProps({
  active_item: {
    type: Object,
    required: true,
  },
  is_file_details: {
    type: Boolean,
    required: false,
    default: false,
  },
  is_widget: {
    type: Boolean,
    default: false,
  },
  add_class: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits(['on-click', 'breadcrumbsComputed']);

const $t = inject('$t');

const route = useRoute();

const document_store = useDocumentStore();
const auth_store = useAuthStore();
const common_store = useCommonStore();

const active_folder_uid = computed(() => document_store.active_folder_meta?.uid);
const search_query = computed(() => document_store.search_query);

const active_tab = computed(() => route.meta.page);

const breadcrumb_title = computed(() => {
  if (active_tab.value === 'shared')
    return $t('Shared with me');
  else if (active_tab.value === 'archive')
    return $t('Archived');
  else if (active_tab.value === 'trash')
    return $t('Trash');
  return null;
});

function calculateScope() {
  let calculated_scope = 'null';
  const { scope, asset, type, uid } = props.active_item;
  if (scope)
    calculated_scope = scope;
  else if (type === 'asset')
    calculated_scope = uid;
  else if (asset)
    calculated_scope = asset;
  else if (type === 'organization' && uid !== 'null')
    calculated_scope = uid;
  return calculated_scope;
}

const data_items = computed(() => {
  if (!props.active_item)
    return [];

  const asset_id = route.params.asset_id;
  const data = ['shared', 'archive', 'trash'].includes(active_tab.value)
    ? [{
        uid: asset_id || 'null',
        label: 'Files',
        type: asset_id ? 'asset' : 'organization',
      }]
    : [{
        uid: 'files',
        label: 'Files',
        type: 'files',
        disabled: !props.is_widget,
        is_widget: props.is_widget,
      }];
  if (!props.is_widget) {
    const scope = calculateScope();
    const scope_data = {
      uid: scope,
      type: scope === 'null' ? 'organization' : 'asset',
      label: scope === 'null' ? auth_store.current_organization.name : common_store.assets_map[scope]?.name,
    };
    data.push(scope_data);
  }
  if (props.active_item?.ancestors?.length)
    props.active_item.ancestors.forEach((item) => {
      data.push({
        uid: item.uid,
        type: 'folder',
        label: item.name,
      });
    });
  if (!['asset', 'organization', 'files'].includes(props.active_item.type))
    data.push({
      uid: props.active_item.uid,
      type: 'folder',
      label: props.active_item.name || props.active_item.label,
    });

  const breadcrumbs = data.map((item, i) => ({
    ...item,
    disabled: (['shared', 'archive', 'trash'].includes(active_tab.value) && i !== 0) || item.uid === active_folder_uid.value,
  }));
  emit('breadcrumbsComputed', breadcrumbs);
  return breadcrumbs;
});

const should_show = computed(() => {
  if (props.is_widget)
    if (data_items.value.length > 1)
      return true;
    else return false;

  else
    return true;
});

function getBreadcrumbString() {
  return data_items.value?.slice(1).map(({ label }) => label).join(' > ');
}
</script>

<template>
  <div v-if="search_query && !is_file_details" class="text-gray-900 mt-4 text-sm font-medium">
    {{ `Search Results for “${search_query}” in ${getBreadcrumbString()}` }}
  </div>
  <div v-else class="flex items-center" :class="add_class">
    <div v-if="breadcrumb_title && !is_file_details">
      <span class="text-sm text-gray-700 font-semibold">
        {{ breadcrumb_title }}
      </span>
      <span class="mx-2 text-gray-400">|</span>
    </div>
    <hawk-breadcrumbs
      v-if="should_show"
      :items="is_file_details && data_items.length
        ? data_items.slice(0, -1)
        : data_items"
      :show_active_color="!is_file_details"
      class="font-medium"
      @crumb-clicked="(item) => { emit('on-click', { item, path: data_items }) }"
    />
  </div>
</template>
