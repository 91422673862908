<script setup>
import { debounce } from 'lodash-es';

const props = defineProps({
  files: {
    type: Array,
    default: () => [],
  },
  folders: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['view', 'selected', 'loadMore']);

const selected_files = inject('selected_files');
const scroll_loading = inject('scroll_loading');

onMounted(() => {
  setTimeout(() => {
    const items_list = document.getElementById('items-list');
    items_list.onscroll = debounce(() => {
      if (items_list.scrollTop + items_list.clientHeight >= items_list.scrollHeight)
        emit('loadMore');
    }, 100);
  }, 100);
});

const isFileSelected = file => selected_files.findIndex(selected_file => selected_file.uid === file.uid) >= 0;
function toggleSelection(file) {
  let selected_files_cache = selected_files;
  if (isFileSelected(file))
    selected_files_cache = selected_files.filter(selected_file => selected_file.uid !== file.uid);
  else
    selected_files_cache.push(file);
  emit('selected', selected_files_cache);
}
</script>

<template>
  <div id="items-list" class="h-[350px] scrollbar overflow-x-hidden">
    <template v-for="folder in props.folders" :key="folder.uid">
      <div
        class="flex items-center justify-between px-3.5 py-2 hover:bg-gray-50 border-b border-gray-100 cursor-pointer group"
        @click="emit('view', folder)"
      >
        <div class="flex items-center">
          <div
            v-if="folder.has_children"
            class="rounded-lg bg-gray-50 min-w-[40px] min-h-[40px] w-10 h-10 flex justify-center items-center group-hover:bg-gray-100"
          >
            <IconHawkFolder class="text-sm text-gray-900" />
          </div>
          <p
            v-tippy="folder.name && folder.name > 50 ? folder.name : ''"
            class="text-sm font-medium text-gray-900 max-w-[350px] truncate ml-3"
          >
            {{ folder.name }}
          </p>
        </div>
        <IconHawkChevronRight />
      </div>
    </template>
    <template v-for="file in props.files" :key="file.uid">
      <div class="flex items-center cursor-pointer px-6 py-2 hover:bg-gray-50 border-b border-gray-100 group">
        <input
          type="checkbox"
          class="ml-1 mr-5"
          :checked="isFileSelected(file)"
          @click="toggleSelection(file)"
        >
        <div class="w-10 h-10 min-w-[40px] min-h-[40px] flex-items-center rounded-lg justify-center mr-2">
          <img
            v-if="file.thumbnail_small"
            :src="file.thumbnail_small"
            alt="thumbnail"
            class="h-10 w-10 object-cover rounded-lg border border-gray-100"
          >
          <div v-else class="rounded-lg bg-gray-50 min-w-[40px] min-h-[40px] w-10 h-10 flex justify-center items-center group-hover:bg-gray-100">
            <IconHawkFileFour />
          </div>
        </div>
        <div class="grid grid-cols-12 w-full">
          <p
            v-tippy="file.name && file.name > 15 ? file.name : ''"
            class="col-span-4 text-sm font-medium text-gray-900 max-w-[150px] truncate"
          >
            {{ file.name }}
          </p>
          <p class="col-span-4 text-sm text-gray-600">
            {{ $date(file.created_at, 'L_DATETIME_MED') }}
          </p>
          <div class="col-span-4">
            <HawkMembers v-if="file?.members" :members="file.members?.map(member => member.uid)" type="badge" />
          </div>
        </div>
      </div>
    </template>
    <hawk-loader v-if="scroll_loading" />
  </div>
</template>

<style scoped lang="scss">
/* Basic styling */
[type=checkbox] {
  width: 16px;
  height: 16px;
  color: dodgerblue;
  vertical-align: middle;
  appearance: none;
  background: none;
  outline: 0;
  flex-grow: 0;
  border-radius: 5px;
  background-color: #FFFFFF;
  cursor: pointer;
}

[type=checkbox]:focus {
  outline: 4px solid #bde0ff84;
}

/* Pseudo element for check styling */
[type=checkbox]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}

/* Checked */
[type=checkbox]:checked {
  box-sizing: border-box;
  color: transparent;
  border: 1px solid dodgerblue;
  background-color: rgb(239, 248, 255);
}

[type=checkbox]:checked::before {
  box-shadow: none;
  background-position: -1px -1px;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='dodgerBlue' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

/* Disabled */
[type=checkbox]:disabled {
  background-color: #CCD3D8;
  opacity: 0.84;
  cursor: not-allowed;
}
</style>
