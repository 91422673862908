<script setup>
import { isEqual } from 'lodash-es';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const dashboard_store = useDashboardStore();
const forceUpdate = ref(0);

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const height = computed(() => {
  return ((props?.data?.h || 22) * 20) - 44;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: 'rich_text',
    renderAt: `chart-container-${props?.id}`,
    renderType: 'rich-text',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    chart_name: props.data.data.name,
    dataSource: {
      rich_text: props.data.data.rich_text,
      dashboard_index: props.data.i,
    },
  });
}

watch(() => props.data, (new_val, old_val) => {
  if (!old_val || (!isEqual(new_val, old_val))) {
    forceUpdate.value++;
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });
</script>

<template>
  <div>
    <div class="h-[inherit]">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>

      <HawkWysiwygEditorComponent
        :key="forceUpdate"
        :editor_enabled="false"
        :display_images_fullwidth="true"
        :initial_content="props.data.data.rich_text"
        :resize_config="{ quality: 1, maxWidth: 1280, maxHeight: 800 }"
        class="schema border rounded-lg w-full"
        placeholder_text="Schema"
      />
    </div>
  </div>
</template>
