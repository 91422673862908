<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryBomInstancesDetails from '~/inventory/components/inventory-bom/inventory-bom-item-instances-details-modal.vue';
import InventoryBomStockInOtherWarehouses from '~/inventory/components/inventory-bom/inventory-bom-stock-in-other-warehouses.vue';

const props = defineProps({
  warehouse_id: { type: String, default: '' },
  item: { type: Object, default: () => {} },
  on_save: { type: Function },
});

const emit = defineEmits(['close', 'save']);

const inventory_store = useInventoryStore();

function getItemProgress(item) {
  const progress_data = [];
  item.warehouse_stock.forEach((stocks) => {
    stocks.stock.forEach((stock) => {
      if (stock.is_existing_stock)
        progress_data.push({ value: stock.qty, title: stock.name, color: inventory_store.statuses_map[stock.uid].color });
    });
  });
  return progress_data;
}
</script>

<template>
  <HawkModalContainer content_class="rounded-lg w-[720px]">
    <div class="col-span-12">
      <HawkModalHeader @close="emit('close')">
        <template #title>
          <div class="mb-1">
            {{ item.name }}
          </div>
          <div class="text-sm text-gray-600 font-normal">
            #{{ item.number }}
          </div>
        </template>
      </HawkModalHeader>
      <HawkModalContent>
        <div class="text-sm grid gap-6 form-normal">
          <div class="text-sm grid grid-cols-12">
            <div class="col-span-3">
              <div class="m-0.5 text-gray-600">
                Scope
              </div>
              <div class="font-medium">
                {{ item.scope }} {{ item?.uom || '' }}
              </div>
            </div>
            <div class="col-span-3">
              <div class="m-0.5 text-gray-600">
                Available/used
              </div>
              <div class="font-medium">
                {{ item.available_qty }}
              </div>
            </div>
            <div class="col-span-3">
              <div class="m-0.5 text-gray-600">
                Status
              </div>
              <div class="font-medium" :class="[{ 'text-red-700': item.item_status?.status === -1 }]">
                {{ item.item_status?.text || 0 }}
              </div>
            </div>
          </div>
          <div v-if="item.scope > 0">
            <div class="font-medium text-sm mb-3">
              Summary
            </div>
            <HawkProgressBar show_legend :progress="getItemProgress(item)" :total="Math.max(item.scope, item.available_qty)" has_custom_color />
          </div>
          <div v-if="Object.values(item.instances || {})?.length">
            <InventoryBomInstancesDetails :on_save="on_save" :item="item" @save="emit('save')" />
          </div>
          <div>
            <div class="mb-3 flex items-center justify-between">
              <div class="font-semibold">
                Stock in other warehouses
              </div>
            </div>
            <InventoryBomStockInOtherWarehouses :item="item" :warehouse_id="warehouse_id" />
          </div>
        </div>
      </HawkModalContent>
      <HawkModalFooter />
    </div>
  </HawkModalContainer>
</template>
