<script setup>
import { useModal } from 'vue-final-modal';
import InventoryItemTypePopup from '~/inventory/components/inventory-item-type/inventory-item-type-popup.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const inventory_store = useInventoryStore();
const item_type$ = ref({});

const item_types = computed(() => inventory_store.item_types);
const { $t } = useCommonImports();
const { open: openItemTypeModal, close: closeItemTypeModal } = useModal({
  component: InventoryItemTypePopup,
  attrs: {
    onClose() {
      closeItemTypeModal();
    },
    onCreatedItemType(e) {
      item_type$.value.load(e[0], false);
    },
  },
});
</script>

<template>
  <SelectElement
    ref="item_type$"
    name="category"
    :items="item_types"
    :search="true"
    :native="false"
    :label="$t('Item type')"
    input-type="search"
    track-by="name"
    :create="false"
    autocomplete="off"
    label-prop="name"
    value-prop="uid"
    :placeholder="$t('Select item type')"
  >
    <template #before-list>
      <p
        class="px-3 pt-1.5 pb-1 text-primary hover:bg-gray-100 font-medium"
        @click="openItemTypeModal"
      >
        + {{ $t('Add item type') }}
      </p>
    </template>
  </SelectElement>
</template>
