<script setup>
import { flatMap, remove } from 'lodash-es';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  documents: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['close', 'clearSelection']);

const transmittal_store = useTransmittalsStore();

const state = reactive({
  documents: props.documents,
  is_loading: false,
});

const form$ = ref(null);

const { logged_in_user_details } = useAuthStore();
const logged_in_user_id = logged_in_user_details?.user_id;

const transmittal = computed(() => transmittal_store.transmittal);
const is_owner = transmittal.value.owner === logged_in_user_id;

const existing_users = computed(() => {
  const all_member = [...transmittal.value.all_members, transmittal.value.owner];
  return [...new Set(all_member)];
});

const allowed_users = computed(() => {
  const all_member = flatMap(props.documents, document => document.members.filter(member => member.role === 'reviewer').map(member => member.uid));
  return [...new Set(all_member)];
});

function removeDocument(uid) {
  remove(state.documents, f => f.uid === uid);
}
async function submitHandler() {
  try {
    state.is_loading = false;
    const documents = [];
    state.documents.forEach((doc) => {
      documents.push({
        uid: doc.uid,
        replace: is_owner ? form$.value.data?.replace?.uid : logged_in_user_id,
        reassign: form$.value.data?.reassign?.uid,
        message: form$.value.data?.message || '',
      });
    });
    await transmittal_store.document_operations({
      transmittal_uid: transmittal.value.uid,
      transmittals: { documents },
    }, 'reassign-members');
    emit('clearSelection');
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]">
    <Vueform
      ref="form$"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }"
      :endpoint="submitHandler"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ !is_owner ? $t('Replace') : $t('Reassign') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <div>
            <template v-for="document in state.documents" :key="document.uid">
              <div class="flex justify-between mb-3">
                <div class="text-gray-700 text-sm font-semibold">
                  {{ document.name }}
                </div>
                <IconHawkXClose
                  v-if="state.documents?.length !== 1"
                  class="w-5 h-5 text-gray-500 hover:text-gray-900 cursor-pointer"
                  @click="removeDocument(document.uid)"
                />
              </div>
            </template>
            <div class="text-sm text-gray-700">
              <span class="font-semibold">{{ $t('Note') }} : </span>
              <span v-if="is_owner">{{ $t('Replace and reassign a reviewer with another member') }}</span>
              <span v-else>{{ is_owner ? $t('Reassign with another member') : $t('Replace with another member') }}</span>
            </div>
            <hr class="my-6">
          </div>
          <HawkAssigneeInput
            v-if="is_owner"
            :options="{
              name: 'replace',
              placeholder: $t('Select a member'),
              label: $t('Replace'),
              canClear: false,
              canDeselect: false,
              native: false,
              size: 'sm',
              rules: ['required'],
            }"
            :has_custom_options="true"
            :custom_options="allowed_users || []"
            :multi="false"
            class="mb-4"
          />
          <HawkAssigneeInput
            :options="{
              name: 'reassign',
              placeholder: $t('Select a member'),
              label: is_owner ? $t('With') : $t('Replace with'),
              canClear: false,
              canDeselect: false,
              native: false,
              size: 'sm',
              existing_users: existing_users || [],
              rules: ['required'],
            }"
            :multi="false"
          />
          <WysiwygEditorElement
            class="mt-5"
            name="message"
            label="Message"
            size="sm"
            :rules="['required']"
            :options="{
              editor_enabled: true,
              menu_enabled: true,
              plugins: ['images', 'tables', 'tags'],
              placeholder_text: $t('Enter message here'),
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  submits size="sm"
                  name="submit"
                  :button-label="$t('Send')"
                  :loading="state.is_loading"
                  button-class="vf-btn-primary"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
