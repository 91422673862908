<script setup>
import HawkAssetsInput from '~/common/components/vueform/hawk-assets-input.vue';
import HawkRolesInput from '~/common/components/vueform/hawk-roles-input.vue';
import HawkTeamsInput from '~/common/components/vueform/hawk-teams-input.vue';

defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  asset_id: {
    type: String,
  },
});
const $t = inject('$t');

const user_type_options = {
  member: $t('Member'),
  global_admin: $t('Global admin'),
  asset_admin: $t('Asset admin'),
};

function getConditions(name) {
  if (name === 'assets') {
    return [
      ['user_type', ['member', 'asset_admin']],
    ];
  }

  return [
    ['user_type', ['member']],
  ];
}

function getInputOptions(name) {
  return {
    name,
    'object': false,
    'label': '',
    'placeholder': $t(`Select ${name}`),
    'description': $t(`Choose the ${name} to add the invited users`),
    'add-classes': {
      ElementDescription: {
        container: ['!text-xs', 'mt-1'],
      },
    },
    'can-clear': true,
    'conditions': getConditions(name),
  };
}
</script>

<template>
  <div>
    <div>
      <StaticElement class="mb-4" name="static_user_type">
        <div class="text-sm font-semibold text-gray-900">
          {{ $t('User type') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Add domains to allow your team members to find your account with') }}
        </div>
      </StaticElement>
      <RadiogroupElement
        name="user_type"
        class="mb-6"
        :items="user_type_options"
        :native="false"
        default="member"
      />
    </div>
    <div v-if="!asset_id">
      <StaticElement name="static_assets" class="mb-4" :conditions="getConditions('assets')">
        <div class="text-sm font-semibold text-gray-900">
          {{ $t('Assets') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Assign assets maps to team and users') }}
        </div>
      </StaticElement>
      <HawkAssetsInput
        class="mb-6 w-[360px]"
        multi
        :options="getInputOptions('assets')"
        has_select_all
      />
    </div>
    <div>
      <StaticElement name="static_teams" class="mb-4" :conditions="getConditions('teams')">
        <div class="text-sm font-semibold text-gray-900">
          {{ $t('Teams') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Assign assets maps to team and users') }}
        </div>
      </StaticElement>
      <HawkTeamsInput
        class="mb-6 w-[360px]"
        multi
        :options="getInputOptions('teams')"
      />
    </div>
    <div>
      <StaticElement name="static_roles" class="mb-4" :conditions="getConditions('roles')">
        <div class="text-sm font-semibold text-gray-900">
          {{ $t('Roles') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Assign roles to users') }}
        </div>
      </StaticElement>
      <HawkRolesInput
        class="mb-6 w-[360px]"
        multi
        :options="getInputOptions('roles')"
      />
    </div>
  </div>
</template>
