import { cloneDeep, flatten, has, isObject, isString, keyBy } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { usePusherStore } from '~/common/stores/pusher.store.js';
import { getPageNameByRouteName, trainCase } from '~/common/utils/common.utils';
import { useChecklistStore } from '~/tasks/store/checklists.store.js';

export function useTasksStore(key) {
  return defineStore(key || 'tasks', {
    state: () => ({
      active_view: 'list',
      tasks_map: {},
      attachments_map: {},
      dependencies: {},
      filters: {},
      search_key: '',
      last_read: null,
      /*
        Variable used to track opened task facilitating
        removal from list if it does not exist on the current page
      */
      opened_task: null,
      kanban_tasks_count: {},
      total_task_count: 0,
      task_channel: null,
      task_details_channel: null,
      priority_values: [
        {
          name: 'critical',
          label: 'Critical',
          value: 1,
        },
        {
          name: 'high',
          label: 'High',
          value: 2,
        },
        {
          name: 'medium',
          label: 'Medium',
          value: 3,
        },
        {
          name: 'low',
          label: 'Low',
          value: 4,
        },
        {
          name: 'not_set',
          label: 'Not set',
          value: 5,
        },
      ],
      status_values: [
        {
          name: 'pending',
          label: 'Pending',
          value: 1,
          color: '#DC6803',
        },
        {
          name: 'inprogress',
          label: 'In Progress',
          value: 2,
          color: '#1570EF',
        },
        {
          name: 'resolved',
          label: 'Resolved',
          value: 3,
          color: '#099250',
        },
        {
          name: 'closed',
          label: 'Closed',
          value: 4,
          color: '#475467',
        },
        {
          name: 'rejected',
          label: 'Rejected',
          value: 5,
          color: '#D92D20',
        },
      ],
      navigation_context_stack: [],
    }),
    getters: {
      status_map: state => keyBy(state.status_values, 'value'),
      priority_map: state => keyBy(state.priority_values, 'value'),
      tasks: state => () => {
        return Object.values(state.tasks_map).filter(task => !task?.parent_task_uid);
      },
      tasks_with_children: state => () => {
        return Object.values(state.tasks_map);
      },
      get_task_count_by_status: state => (status) => {
        const status_key = state.status_values.find(status_obj => status_obj.value === Number(status)).name;
        return state.kanban_tasks_count?.[status_key] || 0;
      },
      get_tasks_by_status: state => (status) => {
        return state.tasks().filter(task => task.status === Number(status));
      },
      get_subtasks: state => (task_id) => {
        return Object.values(state.tasks_map).filter(task => task.parent_task_uid === task_id);
      },
      attachments: (state) => {
        return Object.values(state.attachments_map);
      },
      checklist_attachments: state => (checklist_id) => {
        return Object.values(state.attachments_map).filter(obj => obj.foreign_object_type === 'checklist' && obj.foreign_object_uid === checklist_id);
      },
      get_current_task: (state) => {
        return taskId => state.tasks_map[taskId];
      },
      filters_count: (state) => {
        if (Object.keys(state.filters).includes('progress_start') || Object.keys(state.filters).includes('progress_end'))
          return Object.keys(state.filters).length - 1;
        return Object.keys(state.filters).length;
      },
      navigation_meta: (state) => {
        if (!state.navigation_context_stack.length) {
          return {
            current_task_uid: null,
            task_nav_uids: [],
            strict: false, // if enabled it does not paginate
            disable: {
              prev: true,
              next: true,
            },
          };
        }

        return state.navigation_context_stack[state.navigation_context_stack.length - 1];
      },
      current_task_index: (state) => {
        return state.navigation_meta.task_nav_uids.findIndex(uid => uid === state.navigation_meta.current_task_uid);
      },
    },
    actions: {
      set_active_view(view) {
        this.active_view = view;
      },
      create_navigation_context(meta) {
        this.navigation_context_stack.push({
          current_task_uid: meta?.current_task_uid || null,
          task_nav_uids: meta?.task_nav_uids || [],
          strict: !!meta?.strict,
          disable: meta?.disable || {
            prev: true,
            next: true,
          },
        });
      },
      delete_navigation_context(reset_stack = false) {
        if (reset_stack)
          this.navigation_context_stack = [];
        else
          this.navigation_context_stack.pop();
      },
      set_navigation_meta(meta) {
        const latest_context = { ...this.navigation_context_stack[this.navigation_context_stack.length - 1] };
        if (meta?.current_task_uid)
          latest_context.current_task_uid = meta.current_task_uid;
        if (meta?.task_nav_uids)
          latest_context.task_nav_uids = meta.task_nav_uids;

        if (meta?.strict)
          latest_context.strict = meta.strict;

        if (meta?.disable)
          latest_context.disable = meta.disable;

        this.navigation_context_stack[this.navigation_context_stack.length - 1] = { ...latest_context };
      },
      remove_opened_task() {
        if (this.opened_task) {
          const sub_tasks_uids = this.tasks_map[this.opened_task].sub_task_uids;
          delete this.tasks_map[this.opened_task];
          sub_tasks_uids.forEach(uid => delete this.tasks_map[uid]);
          this.tasks_map = { ...this.tasks_map };
          this.opened_task = null;
        }
      },
      async set_tasks(query, append = false) {
        try {
          if (
            (has(query, 'reference_name') && !query?.reference_name && query.element && isString(query.element))
            || (!has(query, 'reference_name') && query.element && isString(query.element))
          ) {
            this.tasks_map = {};
            this.total_task_count = 0;
            // set data to null or empty
            return;
          }

          if (isObject(query?.element) && query?.element?.uid)
            query.element = query.element.uid;
          else if (query?.element && isString(query.element))
            delete query.element;

          const { data, headers } = await this.$services.tasks.getAll(
            {
              query: {
                ...query,
                ...this.filters,
                ...(this.search_key && { q: this.search_key }),
              },
            },
          );
          const tasks = keyBy(data.tasks, 'uid');

          if (this.opened_task || append) {
            // If opened task already exists on the current page then don't set
            if (tasks[this.opened_task])
              this.opened_task = null;
            this.tasks_map = { ...tasks, ...this.tasks_map };
          }
          else {
            this.tasks_map = tasks;
          }

          await this.set_tasks_count(headers['x-total-count'], true);
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async set_kanban_tasks(query, append = true) {
        const { data } = await this.$services.tasks.get_tasks_by_status({
          query: {
            ...query,
            ...this.filters,
            ...(this.search_key && { q: this.search_key }),
          },
        });
        if (data.tasks) {
          const tasks = keyBy(flatten(Object.values(data.tasks)), 'uid');
          if (append) {
            this.tasks_map = { ...this.tasks_map, ...tasks };
          }
          else {
            this.tasks_map = tasks;
            this.kanban_tasks_count = data.total_count;
            let total_task_count = 0;
            Object.keys(data?.total_count || {}).forEach((status_name) => {
              total_task_count += Number(data.total_count[status_name]);
            });
            this.total_task_count = total_task_count;
          }
        }
      },
      async set_tasks_count(count, reset = false) {
        if (this.active_view === 'list') {
          if (!Number.isNaN(count))
            this.total_task_count = reset ? Number(count) : Number(this.total_task_count) + Number(count);
        }
        else {
          const common_store = useCommonStore();
          const { data } = await this.$services.tasks.get_tasks_by_status({
            query: {
              count_only: true,
              status: [1, 2, 3, 4, 5],
              ...this.filters,
              ...(this.search_key && { q: this.search_key }),
              ...(common_store.active_asset?.uid && { asset_uid: common_store.active_asset.uid }),
            },
          });
          let total_task_count = 0;
          this.kanban_tasks_count = {};
          Object.keys(data?.total_count || {}).forEach((status_name) => {
            this.kanban_tasks_count[status_name] = data.total_count[status_name];
            total_task_count += Number(data.total_count[status_name]);
          });
          this.total_task_count = total_task_count;
        }
      },

      async get_sub_tasks(task_id) {
        try {
          const { data } = await this.$services.tasks.get({ id: task_id, query: { sub_tasks: true } });

          this.tasks_map = { ...this.tasks_map, ...(keyBy(data.sub_tasks, 'uid')) };
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async set_task_details(task_id, additional_queries) {
        try {
          // If other task is already open -> remove it
          if (this.opened_task !== task_id)
            this.remove_opened_task();
          const { data } = await this.$services.tasks_v2.get({
            id: task_id,
            query: {
              include: ['sub_tasks', 'dependencies', 'attachments', 'checklists'],
              breadcrumbs: true,
              source: 'WEB',
              latest_comment: true,
              ...additional_queries,
            },
          });
          /*
            If task is not present in current page then set the
            opened task so it can be removed when task is closed
          */
          if (!this.tasks_map[task_id])
            this.opened_task = data.task.uid;

          this.tasks_map[task_id] = data.task;
          this.dependencies = keyBy(data.dependencies, 'uid');
          this.attachments_map = keyBy(data.attachments, 'uid');
          this.tasks_map = Object.assign(this.tasks_map, keyBy(data.sub_tasks, 'uid'));

          const checklist_store = useChecklistStore();
          checklist_store.set_task_checklists(data.checklists);
          return data.task;
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async create_tasks(payload, event_properties = null, event_name = 'Created') {
        try {
          const task_instance = payload.tasks[0];
          if (task_instance?.template_uid) {
            const auth_store = useAuthStore();
            const req_payload = {
              tasks: payload.tasks.map(task_obj => ({
                ...task_obj,
                action: 'load_template',
                organization: auth_store.current_organization?.uid,
                include: {
                  assignees: !task_obj.assignees?.length,
                  attachments: true,
                  category: !task_obj.category,
                  checklists: {
                    assignee: true,
                    attachments: true,
                    dueDate: true,
                    resolved: true,
                  },
                  due_date: !task_obj.due_date,
                  labels: true,
                  priority: !task_obj.priority,
                  properties: false,
                  schedule: true,
                  start_date: !task_obj.start_date,
                  status: true,
                  sub_tasks: true,
                  tags: !task_obj.tags?.length,
                  users: !task_obj.users?.length,
                  watchers: true,
                },
              })),
            };
            return await this.load_from_template(req_payload, event_properties, event_name);
          }

          const { data } = await this.$services.tasks.post({
            body: {
              ...payload,
            },
          });
          let tasks = {};
          if (data.task)
            tasks = { [data.task.uid]: data.task };
          if (data.tasks)
            tasks = keyBy(data.tasks, 'uid');
          await this.set_tasks_count(Object.keys(tasks).length);
          this.tasks_map = { ...tasks, ...this.tasks_map };

          const task_event_properties = this.get_event_properties(payload);
          const added_task = data.tasks ? data.tasks[0] : data.task;
          if (added_task.is_template)
            this.task_track_events(event_name, {}, '', []);
          else
            this.task_track_events(event_name, { ...task_event_properties, ...(event_properties || {}) }, added_task.uid);

          return tasks;
        }
        catch (error) {
          logger.error(error);
          return new Error(error);
        }
      },
      async create_sub_tasks(payload) {
        try {
          const { data } = await this.$services.tasks.post({
            body: {
              ...payload,
            },
          });
          let tasks = {};
          if (data.task)
            tasks = { [data.task.uid]: data.task };
          if (data.tasks)
            tasks = keyBy(data.tasks, 'uid');

          const task_event_properties = this.get_event_properties(payload, 'Subtask');
          const added_task = data.tasks ? data.tasks[0] : data.task;
          this.task_track_events('Created', { ...task_event_properties }, added_task.uid);

          this.tasks_map = Object.assign(this.tasks_map, tasks);
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async update_sub_tasks(payload) {
        try {
          const { data } = await this.$services.tasks.patch({
            body: {
              ...payload,
            },
          });
          if (data?.tasks) {
            data?.tasks.forEach((task) => {
              this.tasks_map[task.uid] = task;
            });
          }
          else if (data?.task) {
            this.tasks_map[data.task.uid] = data.task;
          }
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },

      format_payload(uid, payload) {
        const task_obj = this.tasks_map[uid];
        const formatted_payload = {};
        Object.keys(payload).forEach((key) => {
          if (key === 'users') {
            if (payload.users?.add?.length) {
              const add_user_uids = payload.users.add.map(obj => obj.uid);
              formatted_payload.assignees = [...task_obj.assignees, ...add_user_uids];
            }
            else if (payload.users?.remove?.length) {
              const remove_user_uids = payload.users.remove.map(obj => obj.uid);
              formatted_payload.assignees = task_obj.assignees.filter(assignee_uid => !remove_user_uids.includes(assignee_uid));
            }
          }
          else if (key === 'tags') {
            if (payload.tags?.add?.length)
              formatted_payload.tags = [...task_obj.tags, ...payload.tags.add];
            else if (payload.tags?.remove?.length)
              formatted_payload.tags = task_obj.tags.filter(tag_uid => !payload.tags.remove.includes(tag_uid));
          }
          else {
            formatted_payload[key] = payload[key];
          }
        });
        return formatted_payload;
      },
      async update_tasks(uids = [], payload = {}, bulk = false) {
        const tasksClone = cloneDeep(this.tasks_map);
        uids.forEach((uid) => {
          if (bulk) {
            // If action comes from bulk actions we need to format the payload
            const formatted_payload = this.format_payload(uid, payload);
            this.tasks_map[uid] = { ...this.tasks_map[uid], ...formatted_payload };
          }
          else {
            this.tasks_map[uid] = { ...this.tasks_map[uid], ...payload };
          }
        });
        try {
          await this.$services.tasks.patch({
            body: {
              tasks: uids.map(uid => ({ uid, ...payload })),
            },
          });
          await this.set_tasks_count();
        }
        catch (error) {
          this.tasks_map = tasksClone;
          logger.error(error);
          return error;
        }
      },
      async archive_tasks(uids = [], archive = true) {
        const tasksClone = cloneDeep(this.tasks_map);
        try {
          const payload = [];
          uids.forEach((uid) => {
            payload.push({
              uid,
              archive,
            });
            delete this.tasks_map[uid];
          });
          await this.$services.tasks.patch({
            body: {
              tasks: payload,
            },
          });
          await this.set_tasks_count(-uids.length);
        }
        catch (error) {
          this.tasks_map = tasksClone;
          logger.error(error);
          return error;
        }
      },
      async remove_tasks(uids = []) {
        try {
          const payload = uids.map(uid => ({ uid }));
          await this.$services.tasks.delete({
            body: {
              tasks: payload,
            },
          });
          if (uids.length) {
            const check_template = this.tasks_map[uids[0]].is_template;
            if (check_template)
              this.task_track_events('Template removed', {}, '', []);
            else
              this.task_track_events('Deleted', { mode: uids.length > 1 ? 'Bulk' : 'Single' }, uids[0]);
          }

          await this.set_tasks_count(-uids.length);
          uids.forEach(id => delete this.tasks_map[id]);
          this.tasks_map = { ...this.tasks_map };
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async load_from_template(payload, event_properties = null, event_name = 'Loaded a template') {
        try {
          const { data } = await this.$services.tasks.load_from_template({ body: payload });

          const tasks = keyBy(data.tasks, 'uid');
          this.tasks_map = { ...tasks, ...this.tasks_map };
          await this.set_tasks_count(data.tasks.length);

          if (event_properties) {
            const task_event_properties = this.get_event_properties(payload, 'Template');
            const added_task = data.tasks[0];
            this.task_track_events(event_name, { ...task_event_properties, ...event_properties }, added_task.uid);
          }

          return tasks;
        }
        catch (error) {
          logger.error(error);
          return new Error(error);
        }
      },
      async save_template(payload) {
        try {
          const { data } = await this.$services.tasks.save_template({ body: payload });

          data.tasks.forEach((task) => {
            this.tasks_map[task.uid] = task;
          });
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      set_filters({ payload, reset }) {
        if (reset)
          this.filters = {};
        else
          this.filters = payload;
      },
      set_search_key(value) {
        this.search_key = value;
      },
      async duplicate_tasks(payload) {
        try {
          const { data } = await this.$services.tasks.duplicate({
            body: {
              tasks: payload,
              is_strict: true,
            },
          });
          await this.set_tasks_count(data.tasks.length);
          const tasks = keyBy(data.tasks, 'uid');
          this.tasks_map = { ...tasks, ...this.tasks_map };
        }
        catch (error) {
          logger.error(error);
          return error;
        }
      },
      async set_task_attachments(task_uid, payload) {
        try {
          const { data } = await this.$services.tasks.set_task_attachments({
            id: task_uid,
            body: payload,
          });

          let attachments = {};
          if (data?.attachment)
            attachments = { [data.attachment.uid]: data.attachment };
          if (data?.attachments)
            attachments = keyBy(data.attachments, 'uid');
          this.attachments_map = { ...this.attachments_map, ...attachments };
          return data?.attachments || [data?.attachment];
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async delete_task_attachments(task_uid, attachment_uid) {
        const attachments_map = cloneDeep(this.attachments_map);
        delete this.attachments_map[attachment_uid];
        try {
          await this.$services.tasks.delete_task_attachments({
            id: task_uid,
            attachment_id: attachment_uid,
          });
        }
        catch (error) {
          this.attachments_map = attachments_map;
          logger.log(error);
          return error;
        }
      },
      async set_comment_attachments(uid_obj, payload) {
        try {
          const { data } = await this.$services.tasks.set_comment_attachments({
            id: uid_obj.task_uid,
            comment_id: uid_obj.comment_id,
            body: payload,
          });

          let attachments = {};
          if (data?.attachment)
            attachments = { [data.attachment.uid]: data.attachment };
          if (data?.attachments)
            attachments = keyBy(data.attachments, 'uid');
          this.attachments_map = { ...this.attachments_map, ...attachments };
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async delete_comment_attachments(uid_obj) {
        const attachments_map = cloneDeep(this.attachments_map);
        delete this.attachments_map[uid_obj.attachment_uid];
        try {
          await this.$services.tasks.delete_comment_attachments({
            id: uid_obj.task_uid,
            comment_id: uid_obj.comment_id,
            attachment_id: uid_obj.attachment_uid,
          });
        }
        catch (error) {
          this.attachments_map = attachments_map;
          logger.log(error);
          return error;
        }
      },
      async update_task_attachments(task_uid, payload) {
        const attachments_map = cloneDeep(this.attachments_map);
        try {
          const { data } = await this.$services.tasks.update_task_attachments({
            id: task_uid,
            body: payload,
          });

          let attachments = {};
          if (data?.attachment)
            attachments = { [data.attachment.uid]: data.attachment };
          if (data?.attachments)
            attachments = keyBy(data.attachments, 'uid');
          this.attachments_map = { ...this.attachments_map, ...attachments };
          return data?.attachments || [data?.attachment];
        }
        catch (error) {
          this.attachments_map = attachments_map;
          logger.log(error);
          return error;
        }
      },
      update_last_read(task_uid, user_id, time) {
        this.last_read = time;
        this.tasks_map[task_uid] = {
          ...this.tasks_map[task_uid],
          viewed: {
            ...this.tasks_map[task_uid].viewed,
            [user_id]: time,
          },
        };
      },
      update_comment_stats(task_uid, unread_comments) {
        const task = this.tasks_map[task_uid];
        this.tasks_map[task_uid] = {
          ...task,
          comment_stats: {
            ...task.comment_stats,
            unread_comments,
          },
        };
      },
      async get_task_attachments(task_id) {
        try {
          const { data } = await this.$services.tasks.get_task_attachments({
            id: task_id,
          });
          if (data?.attachments)
            this.attachments_map = keyBy(data.attachments, 'uid');
        }
        catch (error) {
          logger.log(error);
          return error;
        }
      },
      async subscribe_pusher(payload, additional_queries) {
        const pusher_store = usePusherStore();

        const getAssetTasks = (details) => {
          return payload.asset
            ? details.filter(d => d.asset === payload.asset).map(t => t.uid)
            : details.map(t => t.uid);
        };

        const action = async (details, event) => {
          if (!details)
            return;

          const asset_tasks = getAssetTasks(details);

          if (asset_tasks?.length) {
            let tasks_to_update = asset_tasks;
            const task_encoded = this.$router.currentRoute.value.query[payload.is_template ? 'template' : 'task'];
            if (event === 'updated' && task_encoded) {
              const task_uid = JSON.parse(atob(task_encoded))?.id;
              if (tasks_to_update.includes(task_uid)) {
                tasks_to_update = tasks_to_update.filter(uid => uid !== task_uid);
                await this.set_task_details(task_uid, {
                  ...additional_queries,
                  ...(payload.is_template && { is_template: payload.is_template }),
                });
              }
            }
            if (tasks_to_update.length) {
              const { data } = await this.$services.tasks.getAll({
                query: {
                  task_uid: tasks_to_update,
                  is_template: payload.is_template,
                  page_size: Number.MAX_SAFE_INTEGER,
                  page_number: 1,
                  sub_tasks: true,
                  breadcrumbs: true,
                  archived: false,
                  latest_comment: true,
                  ...additional_queries,
                },
              });

              data.tasks.forEach((task) => {
                if (event === 'added' || (event === 'updated' && this.tasks_map[task.uid]))
                  this.tasks_map[task.uid] = { ...this.tasks_map[task.uid], ...task };
              });
              this.tasks_map = { ...this.tasks_map, ...keyBy(data.sub_tasks, 'uid') };
            }
          }
        };
        if (!this.task_channel) {
          this.task_channel = pusher_store.PUSHER.subscribe(
            `private-tickets-org_${payload.organization}`,
          );

          this.task_channel.bind('TICKETS_UPDATED', ({ details }) => action(details, 'updated'));
          this.task_channel.bind('TICKETS_ADDED', ({ details }) => action(details, 'added'));

          this.task_channel.bind('TICKETS_REMOVED', ({ details }) => {
            const asset_tasks = getAssetTasks(details);
            asset_tasks.forEach(id => delete this.tasks_map[id]);
            this.tasks_map = { ...this.tasks_map };
          });
        }
      },
      get_event_properties(payload, type = 'Task', method = 'Direct') {
        const task_event_properties = { properties: [], count: payload?.tasks?.length || 1, type, method };
        const task_form = payload.tasks[0];

        const property_keys = ['name', 'description', 'status', 'priority', 'category', 'tags', 'assignees', 'start_date', 'due_date', 'template_uid', 'watchers'];
        const key_to_name_map = { start_date: 'Start date', due_date: 'Due date', target_element: 'Location', template_uid: 'Template' };

        Object.keys(task_form).forEach((key) => {
          if (property_keys.includes(key)) {
            if (key_to_name_map[key] && task_form[key]) {
              task_event_properties.properties.push(key_to_name_map[key]);
            }
            else if ((!Array.isArray(task_form[key]) && task_form[key]) || task_form[key]?.length) {
              const property_name = key.charAt(0).toUpperCase() + key.slice(1);
              task_event_properties.properties.push(property_name);
            }
          }
        });
        return task_event_properties;
      },
      task_track_events(event_name, properties = {}, task_uid = '', include_properties = ['view', 'associated_with']) {
        const route = this.$router.currentRoute.value;
        let task = null;
        if (task_uid)
          task = this.tasks_map[task_uid];

        const default_properties = { uid: task_uid };

        if (include_properties.includes('view'))
          default_properties.view = route.name === 'tasks' ? `${trainCase(this.active_view)}` : getPageNameByRouteName(route.name);
        if (task && include_properties.includes('associated_with'))
          default_properties.associated_with = `${task?.target_element?.type?.toUpperCase()}`;
        this.$track_event(event_name, {
          ...default_properties,
          ...properties,
          module: 'Tasks',
        });
      },
    },
  })();
}

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTasksStore, import.meta.hot));
