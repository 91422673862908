<!-- eslint-disable no-mixed-operators -->
<script setup>
import { computed, ref } from 'vue';
import { useThermStore } from '../store/therm.store';
import ThermFeatures from './therm-features.vue';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  side: {
    type: String,
    default: 'left',
  },
  therm_store_name: {
    type: String,
    default: 'therm',
  },
});
const therm_store = useThermStore(props.therm_store_name);
const common_store = useCommonStore();

const type = ref('left');

// Computed Properties
const expand_left_sidebar = computed(() => therm_store.expand_left_sidebar);
const container = computed(() => therm_store.container);
const asset_name = computed(() => {
  return common_store.active_asset.name || '...';
});

// Methods
function set_expand_left_sidebar(value) {
  therm_store.set_expand_left_sidebar(value);
}

function toggleSidebar(value) {
  type.value = type.value === 'right' ? 'left' : 'right';
  if (therm_store.map)
    setTimeout(() => {
      therm_store.map.resize();
    }, 250);
  set_expand_left_sidebar(value);
}
</script>

<template>
  <div
    class="relative therm-left-sidebar h-[calc(100vh-150px)] bg-white transition-all duration-200 ease-in-out border-t border-gray-300"
    :class="expand_left_sidebar ? 'grow-0 shrink-0 basis-72' : 'grow-0 shrink-0 basis-11 w-11'"
  >
    <div
      :class="[
        side === 'right' && type === 'right'
          ? 'absolute z-10 shadow-md rounded-[56px] bg-white top-5 -left-2'
          : 'therm-left-sidebar__expand-icon bg-white',
        side === 'right' && type === 'left'
          ? 'absolute z-10 shadow-md rounded-[56px] bg-white top-5 right-[17.2rem]'
          : '',
      ]"
      class="h-6 w-6 flex items-center justify-center cursor-pointer"
      @click="toggleSidebar(!expand_left_sidebar)"
    >
      <IconHawkChevronRight v-if="(type === 'right' && side === 'left') || (type === 'left' && side === 'right')" class="text-gray-600 w-4 h-4" />
      <IconHawkChevronLeft v-else class="text-gray-600 w-4 h-4" />
    </div>
    <div v-if="expand_left_sidebar" class="therm-sidebar">
      <div class="p-2.5 border-b border-gray-300 pl-4 py-4">
        <div class="text-sm font-medium text-gray-500 pb-2">
          {{ asset_name || "" }}
        </div>
        <div v-if="container" class="text-sm text-gray-600 font-semibold leading-17">
          {{ container.name }}
        </div>
      </div>
      <ThermFeatures :key="container" :therm_store_name="therm_store_name" />
    </div>
    <div v-else class="flex absolute top-[50%] items-center justify-center gap-2 items-center w-full -rotate-90 m-auto whitespace-nowrap font-medium">
      <span class="text-sm text-gray-600">{{ asset_name || "" }} </span>
      <span v-if="asset_name">
        <IconHawkChevronRight class="text-gray-600" />
      </span>
      <span v-if="container" class="text-sm text-gray-600">
        {{ container.name }}
      </span>
    </div>
  </div>
</template>

<style lang="scss">
  .therm-left-sidebar {
    &__expand-icon {
      position: absolute;
      right: -8px;
      z-index: 1;
      top: 17px;
      box-shadow: 0px 2px 5px #323c464d;
      border-radius: 56%;
      padding: 2px;
    }
  }
</style>
