<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, auth_store, route, router } = useCommonImports();

const tabs = computed(() => [{
  uid: '1',
  label: $t('General'),
  to: { name: 'form-template-detail-settings-general', params: { asset_id: route.params.asset_id } },
  disabled: false,
}, {
  uid: '2',
  label: $t('Email & Notification'),
  to: { name: 'form-template-detail-settings-mail', params: { asset_id: route.params.asset_id } },
  disabled: false,
},
{
  uid: '3',
  label: $t('Sharing & Security'),
  to: { name: 'form-template-detail-settings-sharing', params: { asset_id: route.params.asset_id } },
  disabled: false,
},
{
  uid: '4',
  label: $t('Quick Access'),
  to: { name: 'form-template-detail-settings-quickAccess', params: { asset_id: route.params.asset_id } },
  disabled: false,
},
]);
const active_tab = computed(() => {
  return tabs.value.find(item => item.to.name === route.name)?.uid;
});
</script>

<template>
  <div class="flex min-h-full flex-col justify-center mt-4">
    <div class="flex py-5 border-b border-t">
      <div class="mr-4 text-lg font-semibold flex items-center">
        <span class="text-gray-600 rounded-md  cursor-pointer flex items-center p-2 hover:bg-gray-50 relative top-0.5" @click="router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid } })">
          <icon-hawk-chevron-left class="h-5 w-5 -mt-1" />
        </span>
        <p>{{ $t("Settings") }} </p>
      </div>
      <hawk-page-header-tabs :tabs="tabs" :active_item="active_tab" />
    </div>
    <router-view />
  </div>
</template>
