<script setup>
// --------------------------------- Imports -------------------------------- //
import { useRoute } from 'vue-router';
import { getFileExtension } from '~/common/utils/common.utils.js';
import useEmitter from '~/common/composables/useEmitter';

// ---------------------------------- Props --------------------------------- //

const props = defineProps({
  document_details: {
    type: Object,
  },
});

// ---------------------------------- Emits --------------------------------- //

const emit = defineEmits(['close']);

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //

// ------------------------ Variables - Local - refs ------------------------ //

const state = reactive({
  show_viewer: false,
});
const route = useRoute();
const emitter = useEmitter();

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
async function downloadFile(version) {
  if (version.presigned_url)
    window.open(version.presigned_url, '_blank');
}

async function getFileUrl(payload) {
  const response = await document_store.get_download_url({
    body: {
      files: [payload],
    },
  });
  const url = response.data?.urls[0]?.url;

  return url;
}

async function compareHandler(version) {
  const url = await getFileUrl({
    uid: state.document_details.uid,
    version_uid: version.uid,
  });
  url && emitter.emit('document_compare', url);
  emit('close');
}

function getVersionMenu(version) {
  const menu = [
    {
      label: $t('Download'),
      uid: 'download',
      on_click: async () => {
        await downloadFile(version);
      },
    },
    {
      label: $t('View'),
      uid: 'view',
      on_click: () => {
        state.show_viewer = version.uid;
      },
    },
  ];

  // Show compare option only for .pdf files in current version and selected version
  if (route.name === 'document-markup' && getFileExtension(version.name)?.toLowerCase() === 'pdf' && getFileExtension(props.document_details.name)?.toLowerCase() === 'pdf')
    menu.push({
      label: $t('Compare'),
      uid: 'compare',
      on_click: () => {
        compareHandler(version);
      },
    });

  return menu;
}
// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div>
    <div v-if="document_details?.versions?.length">
      <div v-for="(version, index) in document_details.versions" :key="index" class="px-4 py-3">
        <DocumentViewerWrapper
          v-slot="{ is_attachment_supported }"
          :document="version"
          :show_viewer="state.show_viewer === version.uid"
          @closeAttachment="state.show_viewer = null"
        >
          <div class="flex items-center justify-between text-sm w-full">
            <div class="flex items-center">
              <div
                v-if="version?.thumbnail_xsmall"
                class="flex-none relative cursor-pointer group mr-3"
                @click="state.show_viewer = version.uid"
              >
                <img
                  alt="thumbnail"
                  :src="version?.thumbnail_xsmall"
                  class="w-10 h-10 rounded-lg object-cover"
                >
                <div v-if="is_attachment_supported" class="absolute top-0  place-items-center hidden group-hover:grid">
                  <div class="h-10 w-10 bg-black rounded-lg bg-opacity-20" />
                  <div class="text-white absolute">
                    <IconHawkEye />
                  </div>
                </div>
              </div>
              <div>
                <div class="text-gray-900 font-medium">
                  {{ version.name }}
                </div>
                <div class="flex items-center mt-1">
                  <span v-if="version?.created_by?.email" class="text-xs text-gray-600 mr-2"> {{ version?.created_by?.email }} </span>
                  <HawkMembers v-else :members="version?.created_by?.uid || document_details?.owner" type="badge" />
                  <span class="text-xs text-gray-600">
                    {{ `${$date(version.created_at, 'L_DATETIME_MED')}, ${version?.file_size ? $prettysize(version.file_size) : ''}` }}
                  </span>
                </div>
              </div>
            </div>
            <HawkMenu
              :items="getVersionMenu({ ...version, is_attachment_supported })"
              @click.stop
            >
              <template #trigger>
                <IconHawkDotsVertical class="text-gray-600" />
              </template>
            </HawkMenu>
          </div>
        </DocumentViewerWrapper>
      </div>
    </div>
  </div>
</template>
