<script setup>
const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const SCHEDULE_VERB_MAP = {
  CREATED: 'created the schedule',
  UPDATED: 'updated the schedule',
  VERSION_ADDED: activity => `added a new version ${activity.meta?.version?.name}`,
  VERSION_DELETED: activity => `deleted a version ${activity.meta?.version?.name}`,
  CURRENT_VERSION_CHANGED: activity => `activated the version - ${activity.meta?.version?.to?.name}`,
  MEMBERS_CHANGED: 'modified the access for the members',
  ASSET_ACCESS_CHANGED: 'modified the access for the asset',
  EXPORTED: 'exported the schedule',
  DELETED: 'deleted the schedule',
  SYNCED: 'synced the schedule',
  SYNC_SCHEDULE_CHANGED: 'modified the sync schedule',
  CUSTOM_FIELD_CREATED: activity => `created a new field - ${activity.meta?.field?.name}`,
  CUSTOM_FIELD_UPDATED: activity => `updated the field - ${activity.meta?.field?.name}`,
  CUSTOM_FIELD_DELETED: activity => `deleted the field - ${activity.meta?.field?.name}`,
  PUBLISHED: 'published the schedule',
  UNPUBLISHED: 'unpublished the schedule',
};

const ACTIVITY_VERB_MAP = {
  PROGRESS_CHANGED: activity => `updated the progress from ${activity.meta?.progress?.from * 100}% to ${activity.meta?.progress?.to * 100}%`,
  PROGRESS_CONFIGURATION_CHANGED: 'updated the progress configuration',
  REFERENCES_CHANGED: 'updated the references',
  CUSTOM_FIELD_VALUE_CHANGED: activity => `updated the value for the field - ${activity.meta?.custom_field?.name}`,

};

function getVerbMap(resource) {
  if (resource === 'SCHEDULE')
    return SCHEDULE_VERB_MAP;
  return ACTIVITY_VERB_MAP;
}

function getActivityText(activity) {
  const verb_map = getVerbMap(activity.resource_name);

  if (typeof verb_map[activity.verb] === 'function')
    return verb_map[activity.verb](activity);
  return verb_map[activity.verb];
}
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="Object.keys(getVerbMap(activity.resource_name)).includes(activity.verb)" class="text-sm">
      {{ getActivityText(activity) }}
    </div>
  </div>
</template>
