<script setup>
import { onMounted } from 'vue';
import { Validator } from '@vueform/vueform';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store.js';

const $services = inject('$services');
const common_store = useCommonStore();
const route = useRoute();

const isValidEmail = ref({});
const fetched_users = ref([]);

const users_in_assets = computed(() => {
  return common_store?.members_scope_users(route?.params?.asset_id, false) || [];
});

const checkEmails = class extends Validator {
  get message() {
    return 'Please enter valid email address';
  }

  check(value) {
    // Custom validation, check each value(type-object) whether it is a valid email address
    return Object.values(value).map((val) => {
      const emailFormat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      isValidEmail.value[val] = val.match(emailFormat);
      return !val.match(emailFormat);
    }).every(email => !email);
  }
};

const checkMemberExists = class extends Validator {
  get message() {
    return 'Member Already Exists';
  }

  check(value) {
    // Custom validation, check each value(type-object) whether it is a valid email address
    return value.map((val) => {
      const userExists = users_in_assets.value?.map(user => user.email)?.includes(val);
      if (userExists)
        isValidEmail.value[val] = null;
      return userExists;
    }).every(email => !email);
  }
};

async function setUsers(search) {
  const response = await $services.users.getAll({
    query: {
      search,
      asset: false,
    },
  });
  fetched_users.value = (response?.data?.users || []).filter(user => !(users_in_assets.value?.some(asset_user => asset_user?.uid === user?.uid)));
}

onMounted(async () => {
  if (route?.params?.asset_id)
    await setUsers();
});
</script>

<template>
  <hawk-email-tags-input
    class="mb-4"
    :options="{
      'name': 'emails',
      'placeholder': $t('Enter email address'),
      'tags_removable': true,
      'create': true,
      'class': 'mb-4',
      'show-options': !!$route?.params?.asset_id,
      'isValidEmail': isValidEmail,
      'description': $t('Please press enter to add an email'),
      'label-prop': 'email',
      'value-prop': 'email',
      'loading': false,
      'close-on-select': true,
      'track-by': 'email',
    }"
    :items="fetched_users"
    :rules="['required', checkEmails, checkMemberExists]"
  />
</template>
