<script setup>
import { storeToRefs } from 'pinia';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

const props = defineProps({
  workflows_tree: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close', 'save']);

const dashboard_schedule_store = useDashboardScheduleStore();

const {
  schedule_configuration,
} = storeToRefs(dashboard_schedule_store);

const initial_data = computed(() => {
  const columns = schedule_configuration.value?.columns || [];
  if (!columns.length)
    return [];

  const fields = columns?.filter(c => c.type === 'field');

  return fields.map(f => f.key);
});

const form_data = ref({
  data: [],
});
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div class="text-lg font-semibold">
          {{ $t('Choose quantities') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="flex flex-col justify-between w-[900px]">
      <Vueform
        v-if="props.workflows_tree.length"
        v-model="form_data"
        size="sm"
        class="w-full [&>*]:grid-cols-1 border border-gray-300 border-solid rounded-lg"
      >
        <HawkTreeSelect
          show_tree_select
          :options="{
            name: 'data',
            label: $t('Activities'),
          }"
          :data="props.workflows_tree"
          :initial_state="initial_data"
          children_key="fields"
          label_key="name"
          value_key="uid"
          select_type="LEAF_PRIORITY"
          add_classes="!h-auto min-h-[240px] max-h-[420px] max-[1366px]:max-h-[320px]"
          @updateForm="form_data.data = $event"
        />
      </Vueform>
      <div v-else class="w-full h-auto min-h-[240px] max-h-[420px] border border-gray-300 border-solid rounded-lg" />
    </div>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="emit('save', form_data.data)"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
