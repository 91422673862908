<script setup>
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  parent_instances: {
    type: Array,
    default: () => [],
  },
  selected_instances: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close', 'instanceUpdated']);
const system_model_store = useSystemModelStore();

const state = reactive({
  loading: false,
});
const parent_instance_options = computed(() => props.parent_instances?.map(({ name, uid }) => ({ label: name, value: uid })));

async function submitHandler(form$) {
  try {
    state.loading = true;
    const { instance } = form$.data;
    const payload = props.selected_instances.map(uid => ({ parents: [instance], uid }));
    await system_model_store.update_instances(payload);
    emit('instanceUpdated');
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.loading = false;
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('Edit') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            v-bind="{
              name: 'instance',
              items: parent_instance_options,
              search: true,
              native: false,
              label: $t('Parent Instance'),
              placeholder: 'Select Instance',
              autocomplete: 'off',
              inputType: 'search',
              rules: ['required'],
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Update')"
                :loading="state.loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
