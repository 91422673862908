<script>
import { ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'QuantityElement',
}, {
  setup(props, { attrs, element }) {
    const min = ref(Number.parseFloat(attrs?.min ?? 1));
    const max = ref(Number.parseFloat(attrs?.max) ?? undefined);
    const step = Number.parseFloat(attrs?.step ?? 1);
    const aria = null;
    const readonly = null;

    if (!element.model.value)
      element.model.value = min.value;
    else
      element.model.value = Number.parseFloat(element.model.value);

    const defaultClasses = ref({
      container: 'form-text-type',
      addButton: 'px-2 border-l',
      minusButton: 'px-2 border-r',
      inputContainer: 'w-full flex flex-1 transition-input duration-200 border-solid form-border-width-input form-shadow-input form-input-group group',
      inputContainer_sm: 'form-radius-input-sm form-h-input-height-sm',
      inputContainer_md: 'form-radius-input form-h-input-height',
      inputContainer_lg: 'form-radius-input-lg form-h-input-height-lg',
      inputContainer_focused: 'form-focus',
      input: 'w-full bg-transparent h-full outline-0 text-center',
      inputContainer_default: 'form-bg-input form-color-input form-border-color-input hover:form-bg-input-hover hover:form-color-input-hover hover:form-border-color-input-hover hover:form-shadow-input-hover focused:form-bg-input-focus focused:form-color-input-focus focused:form-border-color-input-focus focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover',
      inputContainer_disabled: 'form-bg-disabled form-color-disabled form-border-color-input',
      inputContainer_success: 'form-bg-input-success form-color-input-success form-border-color-input-success hover:form-shadow-input-hover focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover',
      inputContainer_danger: 'form-bg-input-danger form-color-input-danger form-border-color-input-danger hover:form-shadow-input-hover focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover text-gray-900 !outline-red-100 focus:!outline-red-100 focus:text-gray-900 focus:outline-4 focus:border-0',
      input_sm: 'form-p-input-sm form-radius-input-sm form-text-sm with-floating:form-p-input-floating-sm',
      input_md: 'form-p-input form-radius-input form-text with-floating:form-p-input-floating',
      input_lg: 'form-p-input-lg form-radius-input-lg form-text-lg with-floating:form-p-input-floating-lg',
      input_enabled: 'border-0 form-color-input group-hover:form-color-input-hover form-autofill-default',
      input_disabled: 'form-color-disabled',
      input_focused: 'form-color-input-focus form-autofill-focus',
      input_success: 'form-color-input-success form-autofill-success',
      input_danger: 'form-color-input-danger form-autofill-danger',
      $inputContainer: (classes, { isDisabled, Size, isSuccess, isDanger, focused }) => ([
        classes.inputContainer,
        classes[`inputContainer_${Size}`],
        isDisabled ? classes.inputContainer_disabled : null,
        (!isDisabled && !isSuccess && !isDanger) ? classes.inputContainer_default : null,
        (!isDisabled && focused) ? classes.inputContainer_focused : null,
        (!isDisabled && isSuccess) ? classes.inputContainer_success : null,
      ]),
      $input: (classes, { isDisabled, Size, isSuccess, isDanger, focused }) => ([
        classes.input,
        classes[`input_${Size}`],
        isDisabled ? classes.input_disabled : null,
        (!isDisabled && !isSuccess && !isDanger && !focused) ? classes.input_enabled : null,
        (!isDisabled && focused && !isSuccess && !isDanger) ? classes.input_focused : null,
        (!isDisabled && isSuccess) ? classes.input_success : null,
      ]),
      $addButton: classes => ([
        classes.addButton,
        'border-l-gray-300',
      ]),
      $minusButton: classes => ([
        classes.minusButton,
        'border-r-gray-300',
      ]),
    });
    watch(element.model, (newValue) => {
      if (element.el$.value.messageBag.clearPrepended)
        element.el$.value.messageBag.clearPrepended('errors');
    });

    watch(() => attrs.max, () => {
      max.value = attrs.max;
    });
    watch(() => attrs.min, () => {
      min.value = attrs.min;
    });

    const quantity = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        if (attrs.disable_input_clear && Number.isNaN(Number.parseFloat(value)))
          element.model.value = null;

        else
          element.model.value = Number.parseFloat(value);
      },
    });

    function decrease() {
      if (element.model.value > min.value)
        element.model.value -= step;
    }
    function increase() {
      if (!element.model.value)
        element.model.value = step;
      else if (!max.value || element.model.value < max.value)
        element.model.value += step;
    }

    function handleInput(e) {
      if (attrs.disable_input_clear && e.target.value === '') {
        element.model.value = min.value;
        return;
      }

      if (e.target.value < min.value)
        element.model.value = min.value;

      if (max.value && e.target.value > max.value)
        element.model.value = max.value;
    }
    return {
      defaultClasses,
      decrease,
      increase,
      quantity,
      attrs,
      handleInput,
      aria,
      readonly,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <div :class="[classes.inputContainer, isDisabled ? 'pointer-events-none' : null]" class="border border-gray-300 rounded-lg">
        <button
          type="button"
          :class="classes.minusButton"
          @click="decrease"
        >
          <IconHawkMinus class="font-medium text-gray-600" />
        </button>
        <input
          v-bind="{
            ...attrs,
            ...aria,
          }"
          v-model="quantity"
          type="number"
          :class="classes.input"
          :disabled="isDisabled"
          :readonly="readonly"
          @blur="handleInput"
          @wheel="$event.target.blur()"
        >
        <button
          type="button"
          :class="classes.addButton"
          @click="increase"
        >
          <IconHawkPlus class="font-medium text-gray-600" />
        </button>
      </div>
    </template>

    <template v-for="(component, slot) in elementSlots" #[slot]>
      <!-- eslint-disable-next-line vue/valid-attribute-name -->
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
</style>
