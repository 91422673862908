<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep } from 'lodash-es';
import { onMounted } from 'vue';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import FormStepBuilderPopup from '~/forms/components/form-workflows/form-step-builder-popup.vue';
import CloneStepPopup from '~/forms/components/form-workflows/sidebar/form-block/clone-step-popup.vue';
import Advanced from '~/forms/components/form-workflows/sidebar/form-block/step-block-advanced.vue';
import Basic from '~/forms/components/form-workflows/sidebar/form-block/step-block-basic.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  form: {
    type: Object,
    default() {
      return {};
    },
  },
  template_uid: {
    type: String,
  },
  step_number: {
    type: Number,
    default: -1,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  block_create: {
    type: Boolean,
    default: false,
  },
  submit_function: {
    type: Function,
    default: null,
  },
});

defineEmits(['close']);

const form_workflow_store = useFormWorkflowStore();
const form_template_detail_store = useFormTemplateDetailStore();

const { 
  $services,
  $t,
  auth_store,
  route,
} = useCommonImports();

const form_builder_popup = useModal({
  component: FormStepBuilderPopup,
  attrs: {
    onCancel() {
      form_builder_popup.close();
    },
  },
});

const clone_step_popup = useModal({
  component: CloneStepPopup,
  attrs: {
    onClose() {
      clone_step_popup.close();
    },
  },
});

const active_tab = ref('basic');
const form_data = ref({});
const payload_data = ref({});
const email_forms = ref({});
const form$ = ref(null);
const creating_step = ref(false);
const access_controls_sections = ref({});
const access_controls_fields = ref({});
const is_form_initialized = ref(false);

const default_form = ref({
  name: '',
  description: '',
  color: '',
  assignees: [],
  days: 1,
  access_controls: {
    type: 'default',
  },
  allow_rollback: false,
  dynamic_assignees: {
    type: '',
    field_uid: null,
  },
  escalations: {
    is_enabled: false,
    email: {
      subject: '',
      body: '',
      customize_email: false,
    },
    dynamic_assignees: {
      type: '',
      field_uid: null,
    },
    wait: 1,
    members: {},
    escalationType: 'add',
  },
  reminders: {
    is_enabled: false,
    interval: 1,
    count: 1,
    wait: 1,
    sent: 1,
    email: {
      subject: '',
      body: '',
      customize_email: false,
    },
  },
});

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : default_form.value;
});
const steps_with_sections = computed(() => form_template_detail_store.steps_with_sections);
const sections = computed(() => form_template_detail_store.sections);
const is_invalid = computed(() => !(payload_data.value.step || form_data.value.step));

function resetForm(key) {
  form_data.value[key] = {
    ...default_form.value[key],
    is_enabled: form_data.value[key].is_enabled,
    ...(key === 'escalations' && {
      members: [],
      members_data: [],
    }),
  };
  if (!form_data.value[key].is_enabled && email_forms.value[key])
    email_forms.value[key] = form_data.value[key].email;
  // Needs to refactored
  if (key === 'escalations') {
    setTimeout(async () => {
      await form$.value.form$.el$('escalations').el$.validateChildren();
    }, 1);
  }
}

function numberInputValidator({ name, e, key = null }) {
  if (e.target.value) {
    const value = Number(e.target.value);
    let value_to_load = 1;
    if (value >= value_to_load && Number.isInteger(value))
      value_to_load = value;
    if (key)
      form_data.value[key][name] = value_to_load;
    else
      form_data.value[name] = value_to_load;
  }
}

function getPreviousSection() {
  const sections = Object.values(steps_with_sections.value).reduce((acc, step) => {
    if (step.index < payload_data.value.step)
      acc.push(...step.sections);
    return acc;
  }, []);
  if (sections.length === 0)
    return null;
  else return sections[sections.length - 1].uid;
}

function initSections() {
  const sections_data = {};
  const fields_data = {};
  Object.values(steps_with_sections.value).forEach((step) => {
    if (step.index < payload_data.value.step && form_workflow_store.form_blocks.includes(step.index)) {
      step.sections.filter(section => section.status === 'active').forEach((item) => {
        sections_data[item.uid] = access_controls_sections.value[item.uid] ?? 'view';
        item.fields.filter(field => field.status === 'active').forEach((field) => {
          fields_data[field.uid] = access_controls_fields.value[field.uid] || access_controls_sections.value[item.uid] || 'view';
        });
      });
    }
  });
  access_controls_sections.value = sections_data;
  if (auth_store.check_split('fields_access_controls')) {
    access_controls_fields.value = fields_data;
  }
}

async function createUpdateStep(payload_map = {}) {
  try {
    const payload = {
      conditional_logic: {},
      access_controls: {
        type: 'default',
      },
      color: '',
      active: true,
      ...payload_map,
    };
    const steps_payload = { ...steps_with_sections.value };
    const index = payload_data.value.step ? payload_data.value.step : (Object.keys(steps_with_sections.value).length + 1);
    steps_payload[index] = {
      ...payload,
      section_before: null,
      section_after: sections.value[sections.value.length - 1]?.uid,
      index,
      type: 'final',
      sections: undefined,
    };
    Object.values(steps_payload).forEach((step) => {
      let step_type;
      if (step.index === Object.values(steps_payload).length)
        step_type = 'final';
      else if (step.index === 1)
        step_type = 'initial';
      else step_type = 'intermediate';
      steps_payload[step.index] = {
        ...step,
        type: step_type,
        sections: undefined,
      };
    });
    await form_template_detail_store.update_form_details({
      body: {
        steps: {
          ...steps_payload,
        },
      },
      suppressToast: true,
    });
    if (payload_data.value.step)
      return;
    const previousSectionUid = getPreviousSection();
    const section_payload = {
      name: 'Untitled',
      form: form_template_detail_store.form_template_detail.uid,
      type: 'default',
      previous_section: previousSectionUid,
      step_index: index ?? 1,
    };
    const section_response = await $services.forms.post({
      attribute: 'sections',
      body: {
        section: section_payload,
      },
    });
    const field_payload = {
      name: 'Untitled',
      type: 'short_text',
      mandatory: true,
      properties: {
        type: 'short_text',
      },
      section: section_response.data.section.uid,
      config: {
        name: 'text',
      },
    };
    await $services.forms.post({
      attribute: 'fields',
      body: {
        field: field_payload,
      },
    });
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    payload_data.value = { ...payload_data.value, step: index };
    form_workflow_store.new_node.form_step_index = index;
  }
  catch (error) {
    logger.error(error);
  }
}

function openCloneStep() {
  clone_step_popup.patchOptions({
    attrs: {
      steps: form_workflow_store.blocks.filter(val => val.type === 'step'),
      on_save: async (val, form) => {
        const parent_block = form_workflow_store.getBlockByNodeId[form_workflow_store.new_node.parent_id];
        const properties = createPayload();
        properties.node_id = form_workflow_store.new_node.model.id;
        if (parent_block.type === 'conditionalLogic')
          properties.rule_id = form_workflow_store.new_node.rule_id;
        const payload = {
          source_block: form.data.source_block,
          name: properties.name,
          source: parent_block.uid,
          sourceOutcome:
                parent_block.type === 'conditionalLogic'
                  ? 'passed'
                  : (form_workflow_store.new_node.outcome || '').toLowerCase(),
          type: 'step',
          properties,
          data: '',
          initial_block: false,
          assignees: properties.assignees,
          days: properties.days,
          reminders: properties.reminders,
          escalations: properties.escalations,
          access_controls: properties.access_controls,
          color: properties.color,
        };
        try {
          const block = await form_workflow_store.cloneStep({ payload });
          await form_template_detail_store.set_form_template({ id: route.params.template_uid });
          payload_data.value = { ...payload_data.value, step: block.step };
          form_data.value.step = block.step;
          if (parent_block.type === 'conditionalLogic')
            form_data.value.rule_id = block.properties.rule_id;
        }
        catch (err) {
          logger.log({ err });
        }
        clone_step_popup.close();
      },
    },
  });
  clone_step_popup.open();
}

async function openFormBuilder() {
  if (!payload_data.value.step) {
    creating_step.value = true;
    await createUpdateStep({
      name: form_data.value.name,
      assignees: form_data.value.assignees.map(val => val.uid),
      days: form_data.value.days,
      dynamic_assignees: form_data.value.dynamic_assignees,
    });
    creating_step.value = false;
  }
  if (payload_data.value.step)
    initSections();
  form_builder_popup.patchOptions({
    attrs: {
      step_number: payload_data.value.step,
      previous_step_number: props.block_create ? props.step_number : null,
      step_name: form_data.value.name,
    },
  });
  form_builder_popup.open();
}

function updateEmail(data) {
  email_forms.value[data.key] = {
    ...email_forms.value[data.key],
    ...data.value,
  };
  if (!email_forms.value[data.key].customize_email) {
    email_forms.value[data.key].body = '';
    email_forms.value[data.key].subject = '';
  }
}

function createPayload() {
  form_workflow_store.disable_outside_click = true;
  const payload = cloneDeep({ ...payload_data.value, ...form_data.value });
  if (payload_data.value.step !== 1) {
    payload.assignees = form_data.value.assignees.map(val => val.uid);
    payload.access_controls.sections = access_controls_sections.value;
    auth_store.check_split('fields_access_controls') && (payload.access_controls.fields = access_controls_fields.value);
  }
  payload.escalations.members = payload.escalations?.members_data.reduce((acc, curr, i) => {
    acc[i + 1] = { type: curr.member ? 'user' : 'team', uid: curr.uid };
    return acc;
  }, {});
  delete payload.escalations.members_data;
  payload.escalations.email = email_forms.value.escalations;
  payload.reminders.email = email_forms.value.reminders;
  return payload;
}

async function save() {
  form_workflow_store.disable_outside_click = true;
  const payload = createPayload();
  await createUpdateStep({
    name: form_data.value.name,
    assignees: payload.assignees,
    access_controls: payload.access_controls,
    ...(payload_data.value.step !== 1
      ? {
          days: form_data.value.days,
          allow_rollback: payload.allow_rollback,
          dynamic_assignees: form_data.value.dynamic_assignees,
        }
      : {}
    ),
  });
  if (props.submit_function)
    await props.submit_function(payload);
}

function init(data) {
  const load_data = data;
  payload_data.value = data;
  load_data.escalations.members = Object.values(data.escalations.members);
  load_data.escalations.members_data = Object.values(data.escalations.members).map(val => val.uid);
  load_data.reminders.is_once = load_data.reminders.is_once ?? false;
  load_data.reminders.frequency = load_data.reminders.frequency ?? 0;
  load_data.reminders.from = load_data.reminders.from ?? 'dueDate';
  load_data.reminders.when = load_data.reminders.when ?? 'before';
  email_forms.value = {
    escalations: data.escalations.email,
    reminders: data.reminders.email,
  };
  access_controls_sections.value = payload_data.value?.access_controls?.sections || {};
  auth_store.check_split('fields_access_controls') && (access_controls_fields.value = payload_data.value?.access_controls?.fields || {});
  initSections();
  form_data.value = load_data;
  if (!props.block_create) {
    const step = form_workflow_store.getBlockByNodeId[form_workflow_store.selected_node?.model?.id].step;
    form_data.value.step = step;
    payload_data.value.step = step;
  }
}

function onUpdateAccess($event, type) {
  if (type === 'section') {
    access_controls_sections.value = { ...access_controls_sections.value, ...$event };
  }
  if (type === 'field') {
    access_controls_fields.value = { ...access_controls_fields.value, ...$event };
  }
}

onMounted(async () => {
  init(default_data.value);
  await form$.value.load(form_data.value, true);
  is_form_initialized.value = true;
});
</script>

<template>
  <Vueform
    ref="form$" v-model="form_data" :sync="true" size="sm" :should_validate_on_mount="true" :display-errors="false"
    :columns="{
      lg: {
        container: 12,
        label: 3,
        wrapper: 12,
      },
    }"
    :messages="{
      required: $t('This field is required'),
    }"
    :endpoint="save"
    @change="form_workflow_store.is_sidebar_form_dirty = is_form_initialized"
  >
    <div class="col-span-12" :class="{ 'pointer-events-none': is_disabled }">
      <div class="flex justify-between items-start mb-10">
        <div class="w-80">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Form Block') }}
          </div>
          <div class="text-xs text-gray-600">
            {{ $t('Create a new form step or add an existing one') }}
          </div>
        </div>
        <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
          <IconHawkXClose class="text-gray-500" />
        </HawkButton>
      </div>
      <HawkPageHeaderTabs
        class="mb-6 pointer-events-auto"
        :tabs="[
          {
            uid: 'basic',
            label: $t('Basic'),
          },
          {
            uid: 'advanced',
            label: $t('Advanced'),
          },
        ]"
        :active_item="active_tab"
        @tab-click="active_tab = $event.uid"
      />
      <Basic
        v-show="active_tab === 'basic'"
        :is_disabled="is_disabled"
        :payload_data="payload_data"
        :creating_step="creating_step"
        :block_create="block_create"
        :invalid="form$ && form$.invalid"
        :is_initial_block_saved="form_workflow_store.is_initial_block_saved"
        :step_number="step_number"
        @open-form-builder="openFormBuilder"
        @validate-number-input="numberInputValidator"
        @clone-step="openCloneStep"
      />
      <Advanced
        v-show="active_tab === 'advanced'"
        :payload_data="payload_data"
        :form_data="form_data"
        :access_controls_sections="access_controls_sections"
        :access_controls_fields="access_controls_fields"
        :steps_with_sections="steps_with_sections"
        :email_forms="email_forms"
        :is_disabled="is_disabled"
        @change-member="form_data.escalations.members = $event"
        @update-email="updateEmail($event)"
        @update-access="onUpdateAccess"
        @reset="resetForm"
        @validate-number-input="numberInputValidator"
        @init-sections="initSections"
      />
      <hr class="my-6">
      <div v-if="!is_disabled" class="flex justify-end">
        <ButtonElement
          v-if="form_workflow_store.is_initial_block_saved"
          :button-label="$t('Cancel')"
          class="mr-3"
          :secondary="true"
          @click="$emit('close')"
        />
        <ButtonElement
          :disabled="is_invalid"
          :button-label="$t('Save')"
          :submits="true"
        />
      </div>
    </div>
  </Vueform>
</template>
