<script setup>
import { storeToRefs } from 'pinia';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';

const emit = defineEmits(['close', 'save']);

const dashboard_forms_store = useDashboardFormsStore();

const {
  forms_configuration,
  single_form_data,
} = storeToRefs(dashboard_forms_store);

const form_fields_tree = ref([]);
const form_data = ref({
  data: [],
});
const uid_type_map = ref({});

const initial_data = ref([]);

function updateFormData(data) {
  const res = data.map(item => item.toString());
  form_data.value.data = [...res];
}

function emitUpdate() {
  if (!form_data.value?.data) {
    emit('close');
    return;
  }

  const visibility_map = {
    steps: [],
    sections: [],
    fields: [],
  };

  for (const [nestedKey, nestedValue] of Object.entries(uid_type_map.value || {}))
    if (form_data.value.data.includes(nestedKey))
      visibility_map[nestedValue].push(nestedKey);

  emit('save', visibility_map);
}

function mapSections(data) {
  if (!data?.length)
    return [];

  return data.map((section, index) => {
    uid_type_map.value[section.uid] = 'sections';
    const fields = section.fields?.map((f) => {
      uid_type_map.value[f.uid] = 'fields';
      return {
        name: (section?.type === 'signature' || section?.type === 'checklist') ? section.name : f.name,
        uid: f.uid,
      };
    });

    return {
      name: `Section ${index + 1}: ${section.name}`,
      uid: section.uid,
      children: fields || [],
    };
  });
}

function setTreeOptions() {
  let tree_options = [];
  if (single_form_data.value?.[0]?.sections)
    tree_options = single_form_data.value.map((step, index) => {
      const children = mapSections(step.sections || []);
      uid_type_map.value[step.index] = 'steps';
      return {
        name: `Step ${index + 1}: ${step.name}`,
        uid: step.index,
        children,
      };
    });

  else
    tree_options = mapSections(single_form_data.value);

  form_fields_tree.value = [...tree_options];
}

function setInitialData() {
  const data = forms_configuration.value?.single_form_visibility || null;
  if (data) {
    initial_data.value = [
      ...data.steps,
      ...data.sections,
      ...data.fields,
    ];
  }
  else {
    let keys = [];
    if (single_form_data.value?.[0]?.sections)
      keys = single_form_data.value.map(step => step.index);

    else
      keys = single_form_data.value.map(section => section.uid);

    initial_data.value = [...keys];
  }
}

onMounted(() => {
  setTreeOptions();
  setInitialData();
});
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div class="text-lg font-semibold">
          {{ $t('Select fields') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="flex flex-col justify-between w-[900px]">
      <Vueform
        v-if="form_fields_tree.length"
        v-model="form_data"
        size="sm"
        class="w-full [&>*]:grid-cols-1 border border-gray-300 border-solid rounded-lg"
      >
        <HawkTreeSelect
          :key="initial_data?.length"
          show_tree_select
          :options="{
            name: 'data',
            label: $t('Fields'),
          }"
          :data="form_fields_tree"
          :initial_state="initial_data"
          children_key="children"
          label_key="name"
          value_key="uid"
          select_type="BRANCH_PRIORITY"
          add_classes="!h-auto min-h-[240px] max-h-[420px] max-[1366px]:max-h-[320px]"
          @updateForm="updateFormData($event)"
        />
      </Vueform>
      <div v-else class="w-full h-auto min-h-[240px] max-h-[420px] border border-gray-300 border-solid rounded-lg">
        <div class="text-sm font-medium text-center text-gray-700 pt-4">
          {{ $t('No data available') }}
        </div>
      </div>
    </div>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="emitUpdate"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
