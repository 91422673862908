<script setup>
import dayjs from 'dayjs';
import { flatten, keyBy, pick, startCase } from 'lodash-es';
import { reactive } from 'vue';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useMembers } from '~/common/composables/members.js';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const emits = defineEmits(['close']);

const { common_store, route, $t, $services } = useCommonImports();
const { getUserDetails } = useMembers();
const { getProjectOptions, exportGeoJson, getFeatureProperties } = useTerraHelperComposable();
const terra_store = useTerraStore();

const form = ref({
  projects: [],
  options: {
    is_xlsx: true,
    excel_options: ['properties'],
    is_geojson: null,
  },
});
const state = reactive({
  progress_history_attachments: {},
});
const features_data_map = ref({});

const excel_options = [
  { label: $t('Properties'), value: 'properties' },
  { label: $t('Progress data'), value: 'progress_data' },
  { label: $t('Progress history'), value: 'progress_history' },
  { label: $t('Attachments'), value: 'attachments' },
  { label: $t('Notes'), value: 'notes' },
];

const projects_list = computed(() => getProjectOptions());

function getUserDetailsFullName(user_id) {
  const { members_details } = getUserDetails(user_id);
  const user = members_details?.[0];
  return user?.type === 'unknown' ? '' : user?.name;
}

function addProperties(worksheet) {
  const feature_keys = new Set();

  Object.values(features_data_map.value).forEach((value) => {
    Object.keys({ ...value, ...(value.sm_properties || {}) }).forEach((key) => {
      if (!['feature_details_properties', 'sm_attachments', 'sm_properties'].includes(key))
        feature_keys.add(key);
    });
  });

  const header_name_map = {
    'uid': 'Vector ID',
    'name': 'Vector name',
    'description': 'Description',
    'Class': 'Class',
    'Class group': 'Class group',
    'Layer': 'Layer',
    'Sublayer': 'Sublayer',
  };

  const fixed_keys = Object.keys(header_name_map);
  const other_keys = Array.from(feature_keys).filter(key => !fixed_keys.includes(key)).sort();

  const headers = [...fixed_keys, ...other_keys].map(key => ({ value: key, header: header_name_map[key] || key, width: 30 }));
  worksheet.columns = headers;
  const rows = Object.values(features_data_map.value).map((value) => {
    return headers.map(header => ({ ...value, ...(value.sm_properties || {}) })[header.value] || null);
  });
  worksheet.addRows(rows);
}

function parseHtmlToExcelText(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

function addNotes(worksheet) {
  const features_notes = Object.values(features_data_map.value).reduce((acc, value) => {
    const notes = value?.feature_details_properties?.notes || [];
    if (notes.length) {
      acc.push(...notes.map(note => ({
        'Vector ID': value.uid,
        'Vector name': value.name,
        'Note ID': note.uid,
        'Notes': parseHtmlToExcelText(note.message || ''),
        'Attachments': (value?.feature_details_properties?.attachments || [])?.filter(attachment => attachment.attached_to?.uid === note.uid)?.length || 0,
        'User': getUserDetailsFullName(note.created_by),
        'Timestamp': dayjs(note.created_at).format('DD MMMM YYYY h:mm a'),
        'Edited': dayjs(note.updated_at).isAfter(note.created_at) ? 'True' : 'False',
      })));
    }
    return acc;
  }, []);

  const headers = ['Vector ID', 'Vector name', 'Note ID', 'Notes', 'Attachments', 'User', 'Timestamp', 'Edited'];
  worksheet.columns = headers.map(header => ({ value: header, key: header, header, width: 30 }));
  worksheet.addRows(features_notes);
}

function addAttachments(worksheet) {
  const features_attachments = Object.values(features_data_map.value).reduce((acc, value) => {
    const attachments = value?.feature_details_properties?.attachments || [];
    Object.entries(value.sm_attachments || {}).forEach(([, sm_attachments]) => {
      if (sm_attachments.length) {
        attachments.push(...sm_attachments.map(attachment => ({
          url: attachment?.pre_signed_url || '',
          attached_to: {
            type: 'system model',
            uid: null,
          },
          ...attachment,
        })));
      }
    });
    attachments.push(...(state.progress_history_attachments[value.uid] || []));
    if (attachments.length) {
      acc.push(...attachments.map(attachment => ({
        'Vector ID': value.uid,
        'Vector name': value.name,
        'Attachment ID': attachment.uid,
        'Attachment': attachment.url
          ? {
              text: attachment.file_name,
              hyperlink: attachment.url,
            }
          : attachment.file_name,
        'Type': startCase(attachment.attached_to?.type ?? ''),
        'Attached to ID': attachment.attached_to?.uid ?? '',
        'User': getUserDetailsFullName(attachment.created_by),
        'Timestamp': dayjs(attachment.created_at).format('DD MMMM YYYY h:mm a'),
      })));
    }
    return acc;
  }, []);

  const headers = ['Vector ID', 'Vector name', 'Attachment ID', 'Attachment', 'Type', 'Attached to ID', 'User', 'Timestamp'];
  worksheet.columns = headers.map(header => ({ value: header, key: header, header, width: 30 }));
  worksheet.addRows(features_attachments);
  worksheet.getColumn('Attachment').eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      const has_url = typeof cell.value !== 'string';
      if (has_url) {
        cell.font = {
          color: { argb: 'FF0000FF' },
          underline: true,
        };
      }
    }
  });
}

async function addProgressData(worksheet) {
  const headers_to_keys_map = {
    'Vector ID': 'uid',
    'Vector name': 'name',
    'Field ID': 'field_uid',
    'Field name': 'field',
    'Workflow ID': 'workflow_uid',
    'Workflow name': 'workflow',
    'Current': 'current',
    'Total': 'total',
    'Layer': 'layer',
    'Sublayer': 'group',
  };
  const headers = Object.keys(headers_to_keys_map);
  worksheet.columns = headers.map(header => ({ value: header, key: headers_to_keys_map[header], header, width: 30 }));
  try {
    const { data } = await $services.terra.post({
      attribute: `container/${route.params.id}/workflow-progress`,
      body: {
        projects: form.value.projects,
      },
    });
    worksheet.addRows(data);
  }
  catch (error) {
    logger.error('Error failed in fetching progress data', error);
  }
}

async function addProgressHistory(worksheet) {
  try {
    const { data } = await $services.terra_view_service.export_progress_data({
      query: {
        add_invalidate: true,
      },
      body: {
        project: form.value.projects,
      },
    });
    state.progress_history_attachments = {};
    const header_name_map = {
      feature: 'Vector ID',
      feature_name: 'Vector name',
      tid: 'Update ID',
      field: 'Field ID',
      field_name: 'Field name',
      workflow: 'Workflow ID',
      workflow_name: 'Workflow name',
      userTimestamp: 'Date',
      value: 'Value',
      user: 'User',
      category: 'Category',
      notes: 'Comments',
      reset: 'Reset',
      reset_purpose: 'Reset purpose',
      Layer: 'Layer',
      Sublayer: 'Sublayer',
    };
    const headers = Object.keys(header_name_map).map(key => ({ value: key, key, header: header_name_map[key], width: 30 }));
    worksheet.columns = headers;

    const rows = data.reduce((acc, row) => {
      const feature = features_data_map.value[row.feature];
      if (!feature) {
        return acc;
      }
      const attachments = row.attachments || [];
      if (attachments.length) {
        if (!state.progress_history_attachments[row.feature]) {
          state.progress_history_attachments[row.feature] = [];
        }
        state.progress_history_attachments[row.feature].push(...attachments.map(attachment => ({
          created_by: row.user,
          created_at: row.timestamp,
          ...attachment,
          attached_to: {
            type: 'progress history',
            uid: row.field,
          },
        })));
      }
      const formatted_data = {
        ...row,
        userTimestamp: dayjs(row.userTimestamp).format('DD MMMM YYYY h:mm a'),
        feature_name: feature?.name || null,
        Layer: feature?.Layer || null,
        Sublayer: feature?.Sublayer || null,
        category: common_store.categories_map[row.category]?.name || null,
        user: getUserDetailsFullName(row.user),
        field_name: terra_store.all_workflow_fields_hash[row.field]?.name || null,
        workflow_name: terra_store.terra_workflows[row.workflow]?.name || null,
        reset: startCase(row.invalidate),
        reset_purpose: row.invalidatePurpose || null,
      };
      acc.push(pick(formatted_data, headers.map(header => header.key)));
      return acc;
    }, []);
    worksheet.addRows(rows);
  }
  catch (error) {
    logger.error('Error failed in fetching progress history', error);
  }
}

async function onSave() {
  try {
    const project_uids = form.value.projects.filter(project_uid => !terra_store.projects_features_map[project_uid]);
    const file_name = `${terra_store.container.name}-${new Date()}`;
    await terra_store.set_projects_features({
      projects: project_uids.map(project_uid => terra_store.active_projects_data_map({ all_projects: true })[project_uid]),
      forceUpdate: true,
    });

    const features = flatten(form.value.projects.map(project_uid => flatten(Object.values(terra_store.projects_features_map[project_uid] || {}))));

    if (form.value.options.is_geojson) {
      exportGeoJson(features, 'from_layers', file_name);
    }
    else {
      const features_properties = features.map((feature) => {
        const { properties, sm_attachments, sm_properties } = getFeatureProperties(feature);
        return {
          uid: feature.properties.uid,
          ...properties,
          feature_details_properties: feature.properties,
          sm_attachments,
          sm_properties,
        };
      });

      features_data_map.value = keyBy(features_properties, 'uid');

      const ExcelJS = await import('exceljs');
      const { saveAs } = await import('file-saver');

      const workbook = new ExcelJS.Workbook();

      if (form.value.options.excel_options.includes('properties')) {
        const worksheet = workbook.addWorksheet($t('Properties'));
        addProperties(worksheet);
      }
      if (form.value.options.excel_options.includes('progress_data')) {
        const worksheet = workbook.addWorksheet($t('Progress data'));
        await addProgressData(worksheet);
      }
      if (form.value.options.excel_options.includes('progress_history')) {
        const worksheet = workbook.addWorksheet($t('Progress history'));
        await addProgressHistory(worksheet);
      }
      if (form.value.options.excel_options.includes('attachments')) {
        const worksheet = workbook.addWorksheet($t('Attachments'));
        addAttachments(worksheet);
      }
      if (form.value.options.excel_options.includes('notes')) {
        const worksheet = workbook.addWorksheet($t('Notes'));
        addNotes(worksheet);
      }

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `${file_name}.xlsx`);
    }
  }
  catch (error) {
    logger.log(error);
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      v-model="form"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      sync
      :display-errors="false"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emits('close')">
          <template #title>
            {{ $t('Export data') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <HawkTreeSelect
            class="mb-5"
            :options="{
              name: 'projects',
              label: 'Projects',
              placeholder: 'Select Project',
              rules: ['required'],
            }"
            select_type="LEAF_PRIORITY"
            children_key="projects"
            label_key="name"
            value_key="uid"
            :data="projects_list"
            @update-form="form.projects = $event"
          />
          <ObjectElement :label="$t('Export as')" name="options">
            <div class="col-span-12">
              <div class="flex flex-col gap-y-2">
                <RadioElement
                  name="is_xlsx"
                  text="XLSX"
                  radio-value="true"
                  :add-classes="{
                    RadioElement: {
                      wrapper: 'gap-y-1',
                    },
                  }"
                  @input="form.options.is_geojson = null; form.options.is_xlsx = true"
                />
                <div v-if="form?.options?.is_xlsx" class="ml-4 mt-1">
                  <CheckboxgroupElement
                    name="excel_options"
                    :default="['properties']"
                    :add-classes="{
                      CheckboxgroupElement: {
                        wrapper: 'gap-y-1.5',
                      },
                    }"
                    :rules="['required']"
                    :items="excel_options"
                  />
                </div>
                <RadioElement
                  name="is_geojson"
                  text="GEOJSON"
                  radio-value="true"
                  :add-classes="{
                    RadioElement: {
                      wrapper: 'gap-y-1',
                    },
                  }"
                  @input="form.options.is_geojson = true; form.options.is_xlsx = null"
                />
              </div>
            </div>
          </ObjectElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="emits('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Export') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
