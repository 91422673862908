<script setup>
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  form_data: {
    type: Object,
    default: null,
  },
});

const form_detail_store = inject('form_detail_store');

const auth_store = useAuthStore();

const route = useRoute();
const common_store = useCommonStore();
const form = computed(() => props.form_data || form_detail_store.form_detail);
const form_breadcrumb = {
  name: 'Form',
  label: 'Form',
  uid: 1,
  disabled: true,
};

const reference_name = computed(() => form.value.properties?.reference_name
  ? [{
      name: form.value.properties?.reference_name,
      label: form.value.properties?.reference_name,
      uid: 2,
      disabled: true,
    }]
  : []);

const tabs = computed(() => {
  return form.value?.target_element?.asset
    ? [{
        name: common_store.get_asset(form.value.target_element.asset)?.name,
        label: common_store.get_asset(form.value.target_element.asset)?.name,
        uid: 1,
        disabled: true,
      }, ...reference_name.value, form_breadcrumb]
    : [{
        name: common_store.get_organization(auth_store.current_organization?.uid)?.name,
        label: common_store.get_organization(auth_store.current_organization?.uid)?.name,
        uid: 1,
        disabled: true,
      }, form_breadcrumb];
});

onBeforeMount(() => {
  common_store.set_organization_list();
});
</script>

<template>
  <hawk-breadcrumbs :items="tabs" />
</template>
