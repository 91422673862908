<script setup>
import Dashboard from '~/dashboard/pages/dashboard.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';

const dashboard_forms_store = useDashboardFormsStore();
const dashboard_store = useDashboardStore();

const state = reactive({
  is_loading: false,
  can_edit: false,
});
const $fam_overview_display_filters_ref = ref();
const form_template_detail_store = useFormTemplateDetailStore();

const dashboard_store_views = computed(() => {
  return dashboard_store.current_dashboard?.meta?.views;
});

const { display_filters, setActiveViewID, handleDisplayFilterConfiguration, setCustomViews } = useFamCustomView({
  feature: 'dashboard_view',
  fam_view_store_id: 'dashboard_view',
});

watch(dashboard_store_views, (views) => {
  setCustomViews(views);
  setActiveViewID();
}, { immediate: true });

function updateFamFilters(filters) {
  dashboard_forms_store.set_forms_v2_filters(filters, $fam_overview_display_filters_ref);
}
</script>

<template>
  <div>
    <div v-if="state.is_loading" class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
      <HawkLoader container_class="m-0 " />
    </div>
    <Dashboard
      v-else
      :options="{
        can_modify_resource: true,
        hide_side_bar: true,
        is_stand_alone: false,
        hide_dashboard_options: true,
        show_top_bar: state.can_edit,
        is_fam_configure_filter: form_template_detail_store?.form_template_detail?.can_modify_template,
      }"
      :template-name="form_template_detail_store?.form_template_detail?.name"
      @allow-edit="state.can_edit = !state.can_edit"

      @configure_filter="handleDisplayFilterConfiguration()"
    >
      <template #top-bar>
        <div>
          <HawkDisplayFilters
            ref="$fam_overview_display_filters_ref"
            :display_filters="display_filters"
            @apply="updateFamFilters"
          />
        </div>
      </template>
    </Dashboard>
  </div>
</template>
