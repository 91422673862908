<script setup>
import { onMounted, ref } from 'vue';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['close']);
const { route } = useCommonImports();
const $services = inject('$services');
const $toast = inject('$toast');
const common_store = useCommonStore();

const form$ = ref(null);
const is_loading = ref(false);
const form = ref({});

async function save() {
  try {
    is_loading.value = true;
    const { data } = await $services.teams.duplicate({
      id: props.item.uid,
      body: {
        name: `${form.value.name}`,
        asset: props.item.asset || null,
      },
    });

    logger.log('data', data);

    if (data.team) {
      await common_store.update_global_data({ teams: true, ...(route.params.asset_id ? { assets: [route.params.asset_id] } : {}) });
      $toast({
        text: 'Team duplicated successfully',
        type: 'success',
      });
      emit('close');
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
    is_loading.value = false;
  }
  catch (error) {
    is_loading.value = false;
    logger.log(error);
  }
}

onMounted(() => {
  form.value = {
    name: `copy of ${props.item.name}`,
  };
  form$.value.update(form.value);
});
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Duplicate team') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            label="Team name"
            name="name"
            placeholder="Enter team name"
            rules="required"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Duplicate') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
