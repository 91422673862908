<script setup>
import { cloneDeep, isEqual, keyBy } from 'lodash-es';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import FormSingleViewSections from '~/dashboard/components/widgets/form-widgets/form-single-view-section.widget.vue';
import FormStatus from '~/forms/atoms/form-status.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useSubmissionStatusMetrics } from '~/forms/composables/form-list-configuration';
import { useExportPDF } from '~/forms/composables/form-detail-composable';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const loading = ref(false);
const dashboard_forms_store = useDashboardFormsStore();
const dashboard_store = useDashboardStore();
const form_detail_store = useFormDetailStore(`dashboard_${props.id}`);
const form_workflow_store = useFormWorkflowStore();
const auth_store = useAuthStore();
const common_store = useCommonStore();
const current_asset_name = common_store?.active_asset?.name;
const current_asset_address = common_store?.active_asset?.address?.name || '';
const { formsPDFExportHandler } = useExportPDF();

const route = useRoute();
const router = useRouter();
const no_data = ref(false);
const is_exporting = ref(false);
const step_data = ref({});
const $t = inject('$t');
const $date = inject('$date');

const widget_name = computed(() => {
  if (![null, 'Untitled'].includes(props.data?.data?.name))
    return props.data?.data?.name;

  return form_detail_store.form_detail?.name || $t('Untitled');
});

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

const due_date_text = computed(() => {
  return `${$t('Due on')} ${$date(form_detail_store.form_detail.due_date, 'MMM DD, YYYY')}`;
});

async function getReports() {
  try {
    loading.value = true;
    await form_detail_store.set_form({ id: props.data.data.template_form, toast: false });
    dashboard_forms_store.set_single_form_data(form_detail_store.get_all_visible_data || []);
    getSteps();
    updatePrintMap();
  }
  catch (e) {
  }
  loading.value = false;
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val))
    await getReports();
}, { immediate: true }, { deep: true });

// ----------------VISIBILITY----------------
const visibility = computed(() => props?.data?.data?.single_form_visibility);

const getVisibleFields = fields => fields.filter(field => visibility.value.fields.includes(field.uid));

function getVisibleSections(sections) {
  sections.forEach((section, index) => {
    section.section_index = index;
  });

  if (!visibility.value)
    return sections;

  return sections?.filter((section) => {
    const is_section_visible = visibility.value.sections.includes(section.uid);
    if (is_section_visible)
      return true;
    else
      section.fields = cloneDeep(getVisibleFields(section.fields));
    return section.fields.length > 0;
  });
}

function getVisibleSteps(steps) {
  if (!visibility.value)
    return steps;
  return steps?.filter((step) => {
    const is_step_visible = visibility.value.steps.includes(`${step.index}`);
    if (is_step_visible)
      return true;
    else
      step.sections = cloneDeep(getVisibleSections(step.sections));
    return step.sections.length > 0;
  });
}

function get_workflow_step_visibility(step_index) {
  const completed_steps = form_detail_store?.form_detail?.completed_steps?.map(
    step => +step.index,
  );

  const allowed_steps = [...(completed_steps || []), ...[+form_detail_store?.form_detail?.status?.index || 1]];
  // Irrespective of whether the form is submitted or not, we should only display
  // the steps that are either completed or the ones in the current step. The deleted steps i.e
  // step.active=false is only checked while previewing the form but not in the submission view since
  // we should not consider whether a step is deleted or not for the completed ones and the current step
  // will never be set to a deleted step.
  return allowed_steps.includes(step_index);
}
// ----------------END of VISIBILITY----------------

function getSteps() {
  let steps;
  if (form_workflow_store.form_blocks.length)
    steps = markRaw(Object.values(form_detail_store.steps_with_sections || {}).filter(step => (form_workflow_store.form_blocks.includes(step.index))).reduce((acc, curr) => {
      acc[curr.index] = curr;
      return acc;
    }, {}));
  else
    steps = markRaw(form_detail_store.steps_with_sections);

  if (!visibility.value)
    step_data.value = steps;

  steps = getVisibleSteps(cloneDeep(Object.values(steps)));

  step_data.value = keyBy(steps, 'index');
}

function getAllSections() {
  if (!visibility.value)
    return form_detail_store?.form_template?.sections;
  else
    return getVisibleSections(cloneDeep(form_detail_store?.form_template?.sections));
}

const get_step_submission = step_index => form_detail_store?.form_submissions?.step_submissions?.[step_index];

function getDashboardPrintDataset() {
  if (
    no_data.value
    || form_detail_store.form_detail.template.status !== 'published'
    || !form_detail_store.form_detail.active
  )
    return [];

  const has_no_steps = Object.keys(form_detail_store.steps_with_sections).length <= 0;
  const form_data = cloneDeep(form_detail_store.get_all_visible_data);
  return has_no_steps ? getVisibleSections(form_data) : getVisibleSteps(form_data);
}

function getDashboardPrintErrorMessage() {
  if (no_data.value)
    return $t('No data present');
  if (!form_detail_store.form_detail.active)
    return $t('Form not found');
  if (form_detail_store.form_detail.template.status !== 'published')
    return $t('Template not published');
}

const can_show_data = computed(() => form_detail_store.form_detail.active && form_detail_store.form_detail.template.status === 'published');

function updatePrintMap() {
  if (props.id !== 'preview' || !dashboard_store.is_editing_widget)

    dashboard_store.update_print_map(props.id, {
      type: props.data.data.type,
      renderAt: `chart-container-${props?.id}`,
      renderType: 'single_form_view',
      width: '100%',
      height: '100%',
      dataFormat: 'json',
      chart_name: widget_name.value,
      dimensions: {
        x: props.data.x,
        y: props.data.y,
      },
      dataSource: {
        dataset: getDashboardPrintDataset() || [],
        due_date: form_detail_store.form_detail.due_date ? due_date_text.value : '',
        current_status: useSubmissionStatusMetrics(form_detail_store.form_detail, $t),
        value_map: form_detail_store.field_value_map || {},
        error_message: getDashboardPrintErrorMessage(),
      },
    });
}

async function exportPdf() {
  try {
    is_exporting.value = true;
    await formsPDFExportHandler([form_detail_store?.form_detail ?? {}], route.params?.template_uid, () => is_exporting.value = false);
  }
  catch (e) {
    logger.log(e);
  }
  is_exporting.value = false;
}

function openFormSubmissionComponent() {
  router.push({ query: { form: btoa(JSON.stringify({ form_uid: form_detail_store.form_detail.uid })) } });
}
</script>

<template>
  <div class="flex flex-col h-full pb-4">
    <div class="widget-header group">
      <div class="flex items-center">
        <div class="font-medium truncate">
          {{ widget_name }}
        </div>
        <FormStatus v-if="can_show_data" :form="form_detail_store.form_detail" class="ml-2" />

        <div v-if="can_show_data && form_detail_store.form_detail.due_date && form_detail_store.form_detail.status.name !== 'Submitted'" class="ml-2 text-xs text-gray-600">
          {{ due_date_text }}
        </div>
      </div>
      <div class="flex items-center">
        <slot name="header-actions" />
        <hawk-button
          v-if="form_detail_store?.form_detail?.status?.submission_status !== 'open' && can_show_data"
          type="text"
          :loading="is_exporting"
          @click=" exportPdf"
        >
          <icon-hawk-download-two />
        </hawk-button>
        <hawk-button v-if="can_show_data" type="text" @click="openFormSubmissionComponent">
          <icon-hawk-eye />
          {{ $t('View form') }}
        </hawk-button>
      </div>
    </div>    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <div v-else-if="!form_detail_store.form_detail.active" class="flex items-center justify-center h-full text-xs min-h-[200px]">
      {{ $t("Form not found") }}
    </div>
    <div v-else-if="form_detail_store.form_detail.template.status !== 'published'" class="flex items-center justify-center h-full text-xs min-h-[200px]">
      {{ $t("Template not published") }}
    </div>
    <div v-else class="overflow-auto scrollbar p-4" :style="{ height: `${content_height || height}px` }">
      <div class="flex justify-between items-center py-4">
        <img v-if="auth_store?.current_organization?.cover_image?.url" :src="auth_store?.current_organization?.cover_image?.url" alt="org logo" class="object-contain w-24 h-10">
        <img v-else src="./../../../../../assets/logos/taskmapper-full.svg" alt="TaskMapper" width="133">

        <div class="text-end">
          <div class="text-gray-900 font-semibold text-sm">
            {{ auth_store.current_organization.name }}
          </div>
          <div class="text-gray-600 text-xs">
            {{ current_asset_name }}
          </div>
          <div v-if="current_asset_address" class="text-xs font-normal text-gray-600">
            {{ current_asset_address }}
          </div>
        </div>
      </div>
      <!---  Steps --->
      <div v-for="(step, idx) in step_data" :key="idx">
        <div v-if="get_workflow_step_visibility(step.index)" class="mb-8">
          <div class="font-semibold text-md p-4 flex justify-between  sticky -top-[16px] bg-white border border-b-0 z-50">
            {{ $t('Step') }} {{ step.index }}:   {{ step.name }}
            <div class="pointer-events-auto cursor-pointer">
              <icon-hawk-plus-circle v-if="step.hide" @click="step.hide = false" />
              <icon-hawk-minus-circle v-else @click="step.hide = true" />
            </div>
          </div>
          <!---  Section --->
          <FormSingleViewSections v-if="!step.hide" :id="props.id" :sections="step.sections" :has_steps="true" class="border" />
          <!---  Step footer --->
          <div v-if="get_step_submission(step.index)" class="flex items-center justify-end pr-6 pb-6 pt-6 border">
            <div class=" bg-gray-50 w-[388px] p-4 rounded-lg">
              <div class="content-center text-gray-700 mr-2 text-sm font-medium mb-2">
                {{ $t('Submitted by') }}:
              </div>
              <div class="flex">
                <div>
                  <HawkMembers :members="get_step_submission(step.index)?.submitted_by" type="badge" class="bg-gray-200 mr-1 !flex" />
                </div> <div class="text-sm font-normal">
                  {{ $date(get_step_submission(step.index)?.submitted_at, "DATETIME_LONG") }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex items-center justify-end pr-6 pb-6 pt-6 border">
            <div class="bg-gray-50 w-[388px] p-4 rounded-lg">
              <div class="grid grid-cols-12 ">
                <div class="col-span-3 text-gray-500 font-medium text-sm flex items-center">
                  {{ $t('Assignees') }}
                </div>
                <div class="col-span-9">
                  <template v-if="step.assignees?.length">
                    <HawkMembers :members="step.assignees" type="badge" class="mr-1" />
                  </template>
                  <template v-else>
                    <div class="px-3">
                      -
                    </div>
                  </template>
                </div>
              </div>
              <div v-if="form_detail_store.form_detail.step_due_date" class="grid grid-cols-12 mt-2">
                <div class="col-span-3 text-gray-500 font-medium text-sm">
                  {{ $t('Due on') }}
                </div> <div class="col-span-9">
                  {{ form_detail_store.form_detail.step_due_date }}
                </div>
              </div>
            </div>
          </div>
          <!---  Step footer End --->
        </div>
      </div>

      <!---  Step End --->
      <FormSingleViewSections v-if="Object.keys(form_detail_store.steps_with_sections).length <= 0" :id="props.id" :sections="getAllSections()" class="border" />
      <div v-if="!form_detail_store.form_detail?.workflow && form_detail_store?.form_detail?.status.name === 'Submitted'" class="flex items-center justify-end py-6">
        <div class=" bg-gray-50 w-[388px] p-4 rounded-lg">
          <div class="content-center text-gray-700 mr-2 text-sm font-medium mb-2">
            {{ $t('Submitted by') }}:
          </div>
          <div class="flex">
            <div>
              <HawkMembers :members="form_detail_store.form_detail.owner" type="badge" class=" mr-1 !flex" />
            </div>
            <div class="text-sm font-normal">
              {{ $date(form_detail_store.form_submissions?.submitted_at, "DATETIME_LONG") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
