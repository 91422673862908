<script setup>
import { useCommonStore } from '~/common/stores/common.store.js';

defineProps({
  on_save: {
    type: Function,
  },
});

const form = ref({
  asset: '',
});
const common_store = useCommonStore();

const get_assets = computed(() => Object.values(common_store.$state.assets_map));
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform v-model="form" sync size="sm" :display-errors="false" :endpoint="on_save">
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Duplicate workflow') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            name="asset"
            rules="required"
            :items="get_assets"
            value-prop="uid"
            label-prop="name"
            search
            :native="false"
            :label="$t('Asset')"
            :placeholder="$t('Select asset')"
            :columns="{
              lg: {
                container: 12,
                label: 5,
                wrapper: 12,
              },
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
