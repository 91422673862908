import { computed, ref } from 'vue';
import { keyBy } from 'lodash-es';

export const Options = Object.freeze({
  MIN_CHARS: 'min_chars',
  MAX_CHARS: 'max_chars',
});

export const Types = Object.freeze({
  LABELS: { value: 'labels', label: 'Label' },
  CHECKBOX: { value: 'checkbox', label: 'Checkbox' },
  CHECKBOXES: { value: 'checkboxGroup', label: 'Checkbox' },
  RADIO: { value: 'radio', label: 'Radio' },
  TEXT: { value: 'text', label: 'Text' },
  EMAIL: { value: 'email', label: 'Email' },
  URL: { value: 'url', label: 'URL' },
  NUMBER: { value: 'number', label: 'Number' },
  DROPDOWN: { value: 'dropdown', label: 'Dropdown' },
  DATE: { value: 'date', label: 'Date' },
  FILE: { value: 'file', label: 'File' },
  PHONE_NUMBER: { value: 'phone_number', label: 'Phone number' },
  MEMBER: { value: 'member', label: 'Member', display_as: 'badge' },
  DMS_MEMBER: { value: 'member', label: 'Member', display_as: 'avatar' },
  MEMBERS: { value: 'members', label: 'Members' },
  DMS_MEMBERS: { value: 'members', label: 'Members', options: { max_badges_to_display: 1, type: 'badge' } },
  MULTI_SELECT: { value: 'multi_select', label: 'Labels', type: 'badge' },
  SINGLE_SELECT: { value: 'single_select', label: 'Label', type: 'badge' },
  DMS_LABELS: { value: 'multi_select', label: 'Multi label', type: 'badge' },
  DMS_LABEL: { value: 'single_select', label: 'Single label', type: 'badge' },
  SIGNATURE: { value: 'signature', label: 'Signature' },
  YES_NO_NA: { value: 'yes_no_na', label: 'Checkbox' },
});

export const custom_fields_collection = {
  dms: [Types.NUMBER, Types.TEXT, Types.DMS_LABELS, Types.DMS_LABEL, Types.DMS_MEMBERS, Types.YES_NO_NA, Types.DATE],
};

export function useCustomFields(props, emit) {
  // Types ----------------------------------------------------------------
  function getCustomFieldTypes(group) {
    return custom_fields_collection[group];
  }
  // ----------------------------------------------------------------

  // is form editable
  const is_edit = computed(() => {
    return !!props.uid;
  });

  // load the form data
  const form = ref({});
  const fields_map = computed(() => {
    return keyBy(props.fields, 'uid');
  });
  function load_field_data(uid) {
    const field_data = fields_map.value[uid];
    if (field_data)
      form.value = {
        exist: true,
        uid,
        label: field_data.label,
        description: field_data.description,
        type: field_data.type,
        rules: field_data.rules,
        required: field_data.rules?.required,
        items: field_data.items,
        default: { value: field_data.default },
      };
    else
      form.value = {
        exist: false,
        name: '',
        items: form.value.items,
        type: form.value.type,
      };
  }

  if (is_edit.value)
    load_field_data(props.uid);

  return {
    form,
    is_edit,
    getCustomFieldTypes,
  };
}
