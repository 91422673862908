<script setup>
import { useModal } from 'vue-final-modal';
import { useCommonStore } from '~/common/stores/common.store';
import HawkSearchContent from '~/common/components/organisms/hawk-navbar/hawk-global-search-content.vue';

const $t = inject('$t');
const common_store = useCommonStore();

const { open: openSearchPopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkSearchContent,
});

function searchPopupHandler() {
  patchOptions(
    {
      attrs: {
        onClose() {
          closeDeletePopup();
        },
      },
    },
  );
  openSearchPopup();
}
</script>

<template>
  <hawk-button v-tippy="{ content: $t('Search'), placement: 'bottom' }" icon type="text" size="md" class="hover:bg-gray-900" @click="() => searchPopupHandler()">
    <IconHawkSearchMd class="!text-white" />
  </hawk-button>
</template>
