import Requests from '~/forms/forms-temp/pages/requests.vue';
import Archived from '~/forms/forms-temp/pages/archived.vue';
import FormsRoot from '~/forms/forms-temp/pages/forms-root.vue';
import FormsIndex from '~/forms/forms-temp/pages/forms/forms-index.vue';
import FormsList from '~/forms/forms-temp/pages/forms/forms-list.vue';
import FormsCalendar from '~/forms/forms-temp/pages/forms/forms-calendar.vue';
import Templates from '~/forms/forms-temp/pages/templates.vue';
import Submissions from '~/forms/forms-temp/pages/submissions.vue';

const routes = [
  {
    path: '/:asset_id?/forms',
    component: FormsRoot,
    name: 'forms',
    redirect: { name: 'form-overview-list' },
    meta: {},
    children: [
      {
        path: 'overview',
        component: FormsIndex,
        name: 'form-overview-list',
        redirect: { name: 'form-list-view' },
        meta: {
          module: 'forms',
        },
        children: [
          {
            path: 'list',
            component: FormsList,
            name: 'form-list-view',
            props: route => ({ asset_id: route.params.asset_id }),
            meta: {
              view_name: 'Forms overview',
            },
          },
          {
            path: 'calendar',
            component: FormsCalendar,
            name: 'form-calendar-view',
            props: route => ({ asset_id: route.params.asset_id }),
            meta: {
              parent: 'form-list-view',
            },
          },
        ],
      },
      {
        path: 'tempaltes',
        component: Templates,
        name: 'form-templates',
        props: route => ({ asset_id: route.params.asset_id }),
        meta: {
          module: 'forms',
          requiresAuth: true,
        },
      },
      {
        path: 'submissions-overview',
        component: Submissions,
        name: 'form-overview-submission-list',
        props: route => ({ asset_id: route.params.asset_id }),
      },
      {
        path: 'requests',
        component: Requests,
        name: 'form-requests',
        props: route => ({ asset_id: route.params.asset_id }),
        meta: {
          module: 'forms',
        },
      },
      {
        path: 'archived',
        component: Archived,
        name: 'form-archived',
        props: route => ({ asset_id: route.params.asset_id }),
        meta: {
          module: 'forms',
        },
      },
    ],
  },
];
export default routes;
