<script setup>
import SelectBlockColor from '~/forms/atoms/select-block-color.vue';
import ApproversField from '~/forms/components/form-workflows/sidebar/approval-block/approvers-input-field.vue';
import EmailPopupButton from '~/forms/components/form-workflows/sidebar/email-block/email-popup-button.vue';

defineProps({
  form_data: {
    type: Object,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  email_forms: {
    type: Object,
  },
  step_number: {
    type: Number,
    default: -1,
  },
});
const $t = inject('$t');
</script>

<template>
  <div>
    <TextElement name="name" :label="$t('Name')" class="mb-5" rules="required" :readonly="is_disabled" />
    <TextareaElement name="description" :label="$t('Description')" class="mb-5" :readonly="is_disabled" />
    <SelectBlockColor wrapper_class="mb-5" :is_disabled="is_disabled" />
    <hr>
    <TextElement
      name="days"
      input-type="number"
      class="my-5"
      :default="1"
      :rules="[
        'required',
        'integer',
        'min:1',
      ]"
      autocomplete="off"
      :label="$t('Days')"
      @input="$event => $emit('validateNumberInput', { name: 'days', e: $event })"
    >
      <template #addon-after>
        {{ $t('days') }}
      </template>
    </TextElement>
    <ApproversField :members="form_data.members" :condition="form_data.condition" @validateNumberInput="$emit('validateNumberInput', $event)" @change="$emit('changeMember', $event)" />
    <hr>
    <EmailPopupButton
      class="mt-3"
      :form="email_forms.approval_email"
      :step_number="step_number"
      :is_disabled="is_disabled"
      @updateEmail="$emit('updateEmail', { key: 'approval_email', value: $event })"
    />
  </div>
</template>
