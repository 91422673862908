<script setup>
import { useRoute } from 'vue-router';
import { useFormsStore } from '~/forms/store/forms.store.js';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  document: {
    type: Object,
  },
  schema: {
    type: Object,
  },
});

const route = useRoute();
const forms_store = useFormsStore();
const form_workflow_store = useFormWorkflowStore();

const $services = inject('$services');
const $toast = inject('$toast');

async function getFormList() {
  await forms_store.set_forms({
    query: {
      page_size: Number.MAX_SAFE_INTEGER,
      parent_form_uid: route.params.template_uid,
      submission_status: ['draft', 'submitted'],
    },
  });
  return forms_store?.forms;
}

async function onSave(formData, { data }) {
  try {
    const { data: resourceURL } = await $services.forms.post({
      attribute: `${data.form_uid}/document/preview`,
      body: {
        block_uid: null,
        ...props,
      },
      toast: false,
    });
    const link = document.createElement('a');
    link.href = resourceURL;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: 'Something went wrong',
      text: 'Error in generating document',
      type: 'error',
    });
  }
}
</script>

<template>
  <hawk-modal-container :width="900" content_class="rounded-lg max-w-[600px]">
    <Vueform
      size="sm" :display-errors="false" :columns="{
        lg: {
          container: 8,
          label: 4,
          wrapper: 12,
        },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Generate') }}
            </div>
            <div class="text-xs font-normal text-gray-500">
              {{ $t('Choose a form below to fill the template with form data and click on the Generate button.') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            name="form_uid"
            rules="required"
            :items="getFormList"
            value-prop="uid"
            label-prop="name"
            search
            :native="false"
            :label="$t('Select form')"
            :placeholder="$t('Choose a form')"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Generate') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
