// field container settings
const field_columns = {
  default: { container: 6, label: 12, wrapper: 12 },
  sm: { container: 6, label: 12, wrapper: 12 },
  md: { container: 6, label: 12, wrapper: 12 },
};

const two_field_columns = {
  default: { container: 2, label: 12, wrapper: 12 },
  sm: { container: 2, label: 12, wrapper: 12 },
  md: { container: 2, label: 12, wrapper: 12 },
};

// fields
const default_operator_field = {
  name: 'operator',
  columns: field_columns,
  search: false,
  native: false,
  placeholder: 'Select',
  autocomplete: 'off',
  inputType: 'search',
  rules: ['required'],
  items: [
    {
      label: 'is',
      value: 'is',
    },
    {
      label: 'is not',
      value: 'is_not',
    },
  ],
  component: 'SelectElement',
  canClear: false,
};

const default_text_field = {
  name: 'value',
  columns: field_columns,
  component: 'TextElement',
  inputType: 'text',
  rules: ['required'],
  default: null,
};

const default_number_field = {
  name: 'value',
  columns: field_columns,
  component: 'TextElement',
  inputType: 'number',
  rules: ['required', 'numeric', 'min:0', 'max:100'],
  default: 0,
};

const default_tags_field = {
  name: 'value',
  columns: field_columns,
  items: [],
  search: false,
  native: false,
  placeholder: 'Select',
  autocomplete: 'off',
  inputType: 'search',
  rules: ['required'],
  component: 'TagsElement',
  default: [],
};

const default_select_field = {
  name: 'value',
  columns: field_columns,
  items: [],
  search: false,
  native: false,
  placeholder: 'Select',
  autocomplete: 'off',
  canDeselect: false,
  canClear: false,
  inputType: 'search',
  rules: ['required'],
  component: 'SelectElement',
  default: null,
};

// refresh_component_key is required to differentiate between the two fields and
// refresh the component inside dashboard-filters.vue
// we need this bcs the component doesn't update when prop 'range' changes
const default_dates_field = {
  columns: field_columns,
  name: 'value',
  rules: ['required'],
  options: {
    range: true,
    format: 'dd MMM yyyy',
  },
  component: 'DateTimeElement',
  refresh_component_key: 'multi',
  default: [],
};

const default_date_field = {
  columns: field_columns,
  name: 'value',
  rules: ['required'],
  options: {
    range: false,
    format: 'dd MMM yyyy',
  },
  component: 'DateTimeElement',
  refresh_component_key: 'single',
  default: null,
};

const default_checkbox_field = {
  name: 'value',
  columns: field_columns,
  items: [],
  addClasses: {
    CheckboxgroupElement: {
      wrapper: ['!flex-row', 'flex-wrap', 'gap-x-5', 'gap-y-1'],
    },
  },
  component: 'CheckboxgroupElement',
  default: [],
};

const default_toggle_field = {
  columns: field_columns,
  type: 'toggle',
  name: 'value',
  component: 'ToggleElement',
  default: false,
};

const empty_form_operator_fields = {
  this_week: [],
  this_month: [],
  this_quarter: [],
  this_year: [],
  start_this_week: [],
  start_this_month: [],
  start_this_quarter: [],
  start_this_year: [],
  end_this_week: [],
  end_this_month: [],
  end_this_quarter: [],
  end_this_year: [],
};

const static_element = {
  component: 'StaticElement',
  content: 'and',
  addClasses: {
    ElementLayout: {
      container: 'flex items-center justify-center',
    },
  },
};

export {
  field_columns,
  two_field_columns,
  default_operator_field,
  default_text_field,
  default_number_field,
  default_tags_field,
  default_select_field,
  default_date_field,
  default_dates_field,
  default_checkbox_field,
  default_toggle_field,
  empty_form_operator_fields,
  static_element,
};
