<script setup>
import { useElementSize } from '@vueuse/core';
import { find } from 'lodash-es';
import { useRoute } from 'vue-router';

import { useTasksStore } from '~/tasks/store/tasks.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { usePusherStore } from '~/common/stores/pusher.store.js';
import { handleDependencyWarning } from '~/tasks/composables/task-composables.js';

import TaskDetailsHeader from '~/tasks/pages/task-details-new/task-details-header.vue';
import TaskDetailsLeftSection from '~/tasks/pages/task-details-new/left-section/task-details-left-section.vue';
import TaskActivityCommentSection from '~/tasks/pages/task-details-new/task-comments.vue/task-activity-comments.vue';

const emit = defineEmits(['close']);

const $toast = inject('$toast');
const $t = inject('$t');
const common_store = useCommonStore();

const route = useRoute();

const comment_uid = ref(null);
const store_key = ref('');
const task_uid = ref(null);
const task_details_header = ref(null);

const show_task_name_in_header = ref(false);

const { height: header_height } = useElementSize(task_details_header);

try {
  const task_query = JSON.parse(atob(route.query.task));
  store_key.value = task_query?.store_key || '';
  task_uid.value = task_query.id;
  comment_uid.value = task_query?.comment_uid || null;
}
catch (error) {
  task_uid.value = route.query.task;
  store_key.value = '';
}

const task_store = useTasksStore(store_key.value);

const task_loading = ref(false);

const task_details = computed(() => {
  return task_store.get_current_task(task_uid.value);
});

const pusher_store = usePusherStore();

onUnmounted(() => {
  if (pusher_store.is_initialized)
    pusher_store.PUSHER.unsubscribe(`presence-TASKS_TICKET_${task_uid.value}`);
  task_store.attachments_map = {};
  task_store.dependencies = {};
});

watch(() => route.query.task, async (newVal) => {
  if (newVal) {
    let task_query = null;
    try {
      task_query = JSON.parse(atob(newVal));
      store_key.value = task_query?.store_key || '';
      task_uid.value = task_query.id;
    }
    catch (error) {
      task_query = null;
      task_uid.value = newVal;
      store_key.value = '';
    }
    await get_data();
    task_store.task_track_events('Viewed', task_query?.track_event_view ? { view: task_query?.track_event_view } : {}, task_uid.value);
  }
}, { immediate: true });

watchEffect(() => {
  if (pusher_store.is_initialized) {
    task_store.task_details_channel = pusher_store.PUSHER.subscribe(
      `presence-TASKS_TICKET_${task_uid.value}`,
    );

    task_store.task_details_channel.bind('NEW_ACTIVITY_ADDED', async (data) => {
      const has_dependency_updates = find(data, activity => activity.verb === 'DEPENDENCY_ADDED' || activity.verb === 'DEPENDENCY_REMOVED');
      if (has_dependency_updates)
        await task_store.set_task_details(task_uid.value);
    });
  }
}, { flush: 'post' });

function getUpdatedKey(payload) {
  let updated_key = '';
  if (payload.name)
    updated_key = 'Name';
  else if (payload.start_date)
    updated_key = 'Start date';
  else if (payload.due_date)
    updated_key = 'Due date';
  else if (payload.target_element)
    updated_key = 'Location';
  else
    updated_key = Object.keys(payload)[0].charAt(0).toUpperCase() + Object.keys(payload)[0].slice(1);
  return updated_key;
}
function trackUpdateEvent(payload) {
  const { properties } = task_store.get_event_properties({ tasks: [payload] });
  properties.forEach((updated_key) => {
    const event_properties = ['Name', 'Description'].includes(updated_key) ? {} : { mode: 'Single', count: 1 };
    task_store.task_track_events(`${updated_key} updated`, event_properties, task_details.value.uid);
  });
}

async function updateTask(payload) {
  const uids = [task_details.value.uid];
  const res = await task_store.update_tasks(uids, payload);
  if (res?.data?.description === 'circular dependent tasks') {
    $toast({
      title: $t('Invalid dependency'),
      text: $t('The dependency relations are conflicting with each other'),
      type: 'error',
      position: 'bottom-right',
    });
    return;
  }
  else if (res?.data?.description === 'Task Blocked') {
    handleDependencyWarning(task_store, task_details.value, payload, updateTask);
  }
  trackUpdateEvent(payload);
}

async function get_data() {
  task_loading.value = true;

  try {
    const data = await task_store.set_task_details(task_uid.value);
    if (!data?.uid)
      emit('close');
    task_loading.value = false;
  }
  catch (err) {
    emit('close');
    logger.log({ err });
  }
}
function update_task_uid(id) {
  task_store.attachments_map = {};
  task_store.dependencies = {};
  task_uid.value = id;
}

function handleTaskClose() {
  task_store.remove_opened_task();
  task_store.delete_navigation_context(true);
  emit('close');
}

provide('task-loading', readonly(task_loading));
provide('task-uid', {
  task_uid,
  update_task_uid,
});
provide('is-template', false);
provide('is-checklist-template', false);
provide('task_store', task_store);
provide('updateFunction', updateTask);
provide('comment_uid', comment_uid);
provide('header_height', header_height);
</script>

<template>
  <div v-if="(!task_details || task_details.uid) && task_loading" class="h-[100vh] flex items-center justify-center">
    <hawk-loader />
  </div>
  <div v-else-if="task_details && task_details.uid" id="task-details">
    <TaskDetailsHeader
      ref="task_details_header"
      :show_task_name="show_task_name_in_header"
      :task_details="task_details"
      @close="handleTaskClose"
    />
    <div class="flex">
      <TaskDetailsLeftSection
        :task_details="task_details"
        class="border-r w-2/3 border-gray-200"
        @taskNameVisible="$event => show_task_name_in_header = !$event"
      />
      <TaskActivityCommentSection
        location="task_details"
        :task_uid="task_details.uid"
        class="w-1/3 p-3"
      />
    </div>
  </div>
  <div v-else>
    {{ $t('No data') }}
  </div>
</template>
