<script setup>
import TaskName from '~/tasks/components/molecule/task-name.vue';
import TaskPriority from '~/tasks/components/atoms/task-priority.vue';

defineProps({
  task: {
    type: Object,
    required: true,
  },
});

const $date = inject('$date');
</script>

<template>
  <div class="min-w-[18rem] text-xs border rounded-md shadow-md p-2 bg-white">
    <!-- Header section -->
    <div class="flex justify-between items-center">
      <div>
        <TaskName
          :task="task"
        />
      </div>
    </div>
    <!-- Body section -->
    <div class="mt-4">
      <div class="grid grid-cols-[0.5fr_1fr] items-center pb-2">
        <div>{{ $t('Assignees') }}:</div>
        <div>
          <HawkMembers :members="task.assignees" type="badge" />
        </div>
      </div>
      <div class="grid grid-cols-[0.5fr_1fr] items-center pb-2">
        <div>{{ $t('Due Date') }}:</div>
        <div>
          <span class="text-sm whitespace-nowrap" :class="[task.due_date && new Date(task.due_date) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']">
            {{ task.due_date ? $date(task.due_date, 'DD MMMM YYYY') : ' — ' }}
          </span>
        </div>
      </div>
      <div class="grid grid-cols-[0.5fr_1fr] items-center pb-2">
        <div>{{ $t('Priority') }}:</div>
        <TaskPriority :priority="task.priority" />
      </div>
      <div class="grid grid-cols-[0.5fr_1fr] items-center pb-2">
        <div>{{ $t('Category') }}:</div>
        <div>
          <HawkCategoryName v-if="task.category" class="font-medium" :uid="task.category" />
          <span v-else>
            —
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
