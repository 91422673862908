<script setup>
const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const ITEM_VERB_MAP = {
  CREATED: 'created the item',
  UPDATED: 'updated the item',
  DEACTIVATED: 'deactivated item',
};

const STOCK_VERB_MAP = {
  STOCK_IN: activity => `marked ${activity.meta?.stock?.count} ${activity.meta?.item?.uom} as ${activity.meta?.status?.to?.name} for the item ${activity.meta?.item?.name}`,
  STOCK_OUT: activity => `marked ${activity.meta?.stock?.count} ${activity.meta?.item?.uom} as ${activity.meta?.status?.to?.name} for the item ${activity.meta?.item?.name}`,
  MOVE: activity => `moved ${activity.meta?.stock?.count} ${activity.meta?.item?.uom} of ${activity.meta?.item?.name} from ${activity.meta?.location?.from?.name} to ${activity.meta?.location?.to?.name}`,
};

const ADJUSTMENT_VERB_MAP = {
  CREATED: 'created the transaction',
  UPDATED: 'updated the transaction',
  PUBLISHED: 'published the transaction',
};

const WAREHOUSE_VERB_MAP = {
  CREATED: 'created the warehouse',
  UPDATED: 'updated the warehouse',
  DEACTIVATED: 'deleted the warehouse',
};

const VENDOR_VERB_MAP = {
  CREATED: 'created the vendor',
  UPDATED: 'updated the vendor',
  DEACTIVATED: 'deleted the vendor',
};

const CUSTOM_FIELD_VERB_MAP = {
  CREATED: 'created the field',
  UPDATED: 'updated the field',
  DELETED: 'deleted the field',
};

function getVerbMap(resource) {
  switch (resource) {
    case 'ITEM':
      return ITEM_VERB_MAP;
    case 'STOCK':
      return STOCK_VERB_MAP;
    case 'ADJUSTMENT':
      return ADJUSTMENT_VERB_MAP;
    case 'WAREHOUSE':
      return WAREHOUSE_VERB_MAP;
    case 'VENDOR':
      return VENDOR_VERB_MAP;
    case 'CUSTOM_FIELD':
      return CUSTOM_FIELD_VERB_MAP;
    default:
      return {};
  }
}

function getActivityText(activity) {
  const verb_map = getVerbMap(activity.resource_name);

  if (typeof verb_map[activity.verb] === 'function')
    return verb_map[activity.verb](activity);
  return verb_map[activity.verb];
}
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="Object.keys(getVerbMap(activity.resource_name)).includes(activity.verb)" class="text-sm">
      {{ getActivityText(activity) }}
    </div>
    <div v-else-if="activity.verb === 'NOTIFICATION_SENT'" class="text-sm flex items-start">
      <p class="whitespace-nowrap mr-2">
        {{ activity.meta.notification.type }} {{ activity.meta.notification.channel }} notification sent to
      </p>
      <div>
        <HawkBadge v-for="email in activity.meta.notification.to" :key="email" class="mb-2">
          {{ email }}
        </HawkBadge>
      </div>
    </div>
  </div>
</template>
