<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { router } = useCommonImports();
</script>

<template>
  <div class="bg-white grid place-content-center h-[calc(100vh-66px)]">
    <IconIllustrationMaintenance class="h-96 w-96" />
    <div class="text-center text-2xl font-semibold mb-4 text-gray-900">
      {{ $t('Something went wrong!') }}
    </div>
    <div class="text-center">
      <div class="flex gap-3 justify-center">
        <HawkButton type="outlined" @click="$router.replace({ name: 'home' })">
          {{ $t('Take me home') }}
        </HawkButton>
        <HawkButton @click="$emit('onInit')">
          {{ $t('Reload') }}
        </HawkButton>
      </div>
    </div>
  </div>
</template>
