<script setup>
import { useRouter } from 'vue-router';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  save_as_draft: {
    type: Function,
  },
});

const emitter = useEmitter();
const router = useRouter();
const form$ = inject('form$');

async function next() {
  if (form$.value.tabs$.next$)
    form$.value.tabs$.next$.select();
}
const is_saving = ref(false);

async function saveAsDrafts() {
  is_saving.value = true;
  try {
    await props.save_as_draft();
  }
  catch (e) {
    logger.error(e.message);
  }
  is_saving.value = false;
}
</script>

<template>
  <div v-if="form$" class="mt-4 flex justify-end">
    <div class="flex">
      <hawk-button type="plain" class="mr-3" @click="router.push({ name: 'files-transmittals' });">
        {{ $t('Cancel') }}
      </hawk-button>
      <hawk-button
        :loading="is_saving" :disabled="is_saving || form$.hasErrors"
        type="outlined" class="mr-3"
        @click="saveAsDrafts"
      >
        {{ $t('Save as draft') }}
      </hawk-button>
      <hawk-button
        v-if="form$.tabs$.next$"
        @click="next()"
      >
        {{ $t('Next') }}
      </hawk-button>
      <ButtonElement
        v-else
        submits
        size="sm"
        name="submit"
        :button-label="$t('Send')"
        :loading="form$.isLoading"
      >
        {{ $t('Send') }}
      </ButtonElement>
    </div>
  </div>
</template>
