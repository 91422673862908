function createPivotTable(chart, index) {
  const chart_container = chart.dataSource.table_html;
  const body = chart_container.split('<body>')[1].split('</body>')[0];

  const div = document.createElement('div');
  div.innerHTML = body;
  div.classList.add('pivot-table');

  return div;
}

export { createPivotTable };
