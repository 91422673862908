<script setup>
import { reactive } from 'vue';
import { orderBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  template_uid: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(['close']);
const $toast = inject('$toast');
const route = useRoute();
const common_store = useCommonStore();
const system_model_store = useSystemModelStore();

const state = reactive({
  loading: false,
});

const form = ref({ assets: [] });
const assets_options = computed(() => orderBy(Object.values(common_store.assets), 'name'));

async function submitHandler() {
  try {
    state.loading = true;
    if (form.value.assets.length) {
      const assets = form.value.assets.map(asset => asset.uid);
      await system_model_store.create_duplicate_template({
        template_uid: props.template_uid,
        body: { assets },
      });
      if (assets.includes(route.params.asset_id))
        await system_model_store.set_templates({
          asset_id: route.params.asset_id,
        });
      $toast({
        text: 'Duplicated successfully',
        type: 'success',
      });
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.loading = false;
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      v-model="form"
      sync
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('Duplicate template') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <HawkTreeSelect
            :options="{
              name: 'assets',
              object: true,
              label: $t('Duplicate to'),
              placeholder: $t('Choose assets'),
            }"
            data_type="object"
            label_key="name"
            value_key="uid"
            :data="assets_options"
            select_type="BRANCH_PRIORITY"
            @updateForm="form.assets = $event"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="state.loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
