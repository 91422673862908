<script>
export default {
  name: 'MultifileElement',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        list: '',
        listItem: '',
        handle: '',
        handleIcon: '',
        dnd: '',
        button: '',
      },
    };
  },
};
</script>

<template>
  <component :is="elementLayout" ref="container" :multiple="true">
    <template #element>
      <!-- Actual input field -->
      <input
        v-show="false"
        :id="fieldId"
        ref="input"
        multiple
        type="file"
        :accept="accept"
        :disabled="isDisabled"
        @change="handleChange"
      >

      <div v-show="!empty" :key="`${fieldId}-${length}`" ref="list" :class="classes.list">
        <div v-for="(val, i) in value" :key="i" :class="classes.listItem">
          <component
            :is="component(prototype)"
            v-if="prototype.type"
            v-bind="prototype"
            :disabled="!hasRemove"
            :columns="{
              default: { container: 12, wrapper: 12, label: 12 },
              sm: { container: 12, label: 12, wrapper: 12 },
              md: { container: 12, label: 12, wrapper: 12 },
            }"
            :embed="true"
            :name="i"
            @remove="remove(i)"
          />
          <!-- Sort handle -->
          <span v-if="hasSort" :class="classes.handle" data-handle>
            <span :class="classes.handleIcon" />
          </span>
        </div>
      </div>
      <!-- Drag n drop -->
      <DragAndDrop
        v-if="drop && canDrop && hasAdd"
        title=""
        :options="options"
        :description="options?.description || ''"
        :disabled="isDisabled"
        :class="classes.dnd"
        @click="handleClick"
        @drop="handleDrop"
      >
        <slot name="dnd" />
      </DragAndDrop>

      <!-- Upload button -->
      <div
        v-else-if="hasAdd"
        v-bind="aria"
        class="border"
        :class="classes.button"
        :aria-labelledby="labelId"
        :aria-placeholder="form$.translations?.vueform.elements.multifile.uploadButton"
        role="button"
        tabindex="0"
        @click.prevent="handleClick"
        @keypress.enter.space="handleClick"
      >
        <div class="flex items-center text-sm">
          <IconHawkPlus />
          {{ $t('Add files') }}
        </div>
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </component>
</template>
