<script setup>
import { remove } from 'lodash-es';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';

const props = defineProps({
  documents: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['close', 'clearSelection']);
const transmittal_store = useTransmittalsStore();
const form$ = ref(null);
const state = reactive({
  documents: props.documents,
  is_loading: false,
});

const transmittal = computed(() => transmittal_store.transmittal);

const existing_users = computed(() => {
  const { consolidator = {}, reviewers = [] } = form$.value?.data || {};
  const all_members = transmittal.value.members.map(member => member.uid);
  const consolidator_uid = consolidator?.uid ? [consolidator.uid] : [];
  const reviewers_uids = reviewers?.map(member => member.uid) || [];
  return [...new Set([...all_members, ...reviewers_uids, ...consolidator_uid])];
});

function removeDocument(uid) {
  remove(state.documents, f => f.uid === uid);
}

async function submitHandler() {
  try {
    state.is_loading = true;
    const { consolidator, consolidator_due_date = null, review_due_date, message, reviewers } = form$.value.data;
    const documents = [];
    state.documents.forEach((doc) => {
      documents.push({
        uid: doc.uid,
        consolidator: consolidator?.uid,
        members: (reviewers || []).map(reviewer => reviewer.uid),
        consolidator_due_date,
        review_due_date,
        message,
      });
    });
    await transmittal_store.document_operations({
      transmittal_uid: transmittal.value.uid,
      transmittals: { documents },
    }, 'add_reviewers');
    emit('clearSelection');
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}

function formMounted(form$) {
  if (props?.documents?.length && props?.documents?.length > 1)
    return;
  const document = props.documents[0] || {};
  const { additional_reviewers, consolidator } = document;
  const reviewers = additional_reviewers?.filter(reviewer => reviewer.role === 'reviewer') || [];

  const data = {
    reviewers: reviewers.map(reviewer => reviewer.uid),
    review_due_date: reviewers?.[0]?.due_date,
    consolidator,
    consolidator_due_date: consolidator?.due_date,
  };
  form$.load(data, true);
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]" :options="{ 'focus-trap': { allowOutsideClick: true } }">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }"
      :endpoint="submitHandler"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Add additional reviewers') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div>
            <template v-for="document in state.documents" :key="document.uid">
              <div class="flex justify-between mb-3">
                <div class="text-gray-700 text-sm font-semibold">
                  {{ document.name }}
                </div>
                <IconHawkXClose
                  v-if="state.documents?.length !== 1"
                  class="w-5 h-5 text-gray-500 hover:text-gray-900 cursor-pointer"
                  @click="removeDocument(document.uid)"
                />
              </div>
            </template>
            <div class="text-sm text-gray-700">
              <span class="font-semibold">{{ $t('Note') }} : </span>
              <span>The transmittal will be delegated to the concerned person selected below. Henceforth, the concerned person will be responsible to complete the task and.</span>
            </div>
            <hr class="my-6">
          </div>
          <HawkAssigneeInput
            :multi="true"
            :description="$t('Choose members(s) to review selected document')"
            :options="{
              name: 'reviewers',
              label: $t('Reviewers'),
              can_clear: true,
              rules: ['required'],
              existing_users: existing_users || [],
            }"
            :format_load="true"
          />
          <DateTimeElement
            class="mt-5"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            name="review_due_date"
            label="Reviewer due date"
            display-format="D MMM YYYY"
            :placeholder="$t('Select date')"
            :rules="['required']"
            :options="{
              clearable: false,
              format: 'dd MMMM yyyy',
              placeholder: $t('Select date'),
              startTime: { hours: 0, minutes: 0 },
              minDate: new Date(),
            }"
          />
          <HawkAssigneeInput
            :multi="false"
            class="mt-5"
            :options="{
              name: 'consolidator',
              label: $t('Consolidator'),
              placeholder: $t('Select the consolidator'),
              can_clear: true,
              existing_users: existing_users || [],
            }"
          />
          <DateTimeElement
            class="mt-5"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            name="consolidator_due_date"
            label="Consolidator due date"
            display-format="D MMM YYYY"
            :options="{
              clearable: false,
              format: 'dd MMMM yyyy',
              placeholder: $t('Select date'),
              startTime: { hours: 0, minutes: 0 },
              minDate: new Date(),
            }"
          />
          <WysiwygEditorElement
            class="mt-5"
            name="message"
            label="Message"
            size="sm"
            rules="required"
            :options="{
              editor_enabled: true,
              menu_enabled: true,
              plugins: ['images', 'tables', 'tags'],
              placeholder_text: $t('Enter message here'),
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  submits size="sm"
                  name="submit"
                  :button-label="$t('Send')"
                  :loading="state.is_loading"
                  button-class="vf-btn-primary"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
