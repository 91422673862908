<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermStore } from '~/therm/store/therm.store';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';

const { $t } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const severity_levels_graph_details = computed(() => {
  return {
    type: 'pie2d',
    title: $t('Defects by severity'),
    id: 'severity_levels_container',
    is_dashboard: true,
    hide_download: true,
  };
});
const severity_levels_graph_data = computed(() => {
  const severity = {
    low: { label: 'Severity 1', value: 0, color: '#1570EF' },
    medium: { label: 'Severity 2', value: 0, color: '#53B1FD' },
    high: { label: 'Severity 3', value: 0, color: '#1849A9' },
  };
  therm_store.report_defects.forEach((feature) => {
    severity[feature.severity].value++;
  });
  const data = [];
  for (const severity_uid in severity)
    data.push({
      uid: severity_uid,
      ...severity[severity_uid],
    });
  return { data, description: true, type: 'severity' };
});
</script>

<template>
  <div class="border rounded-xl h-[260px] p-4">
    <ThermReportGraph
      :data="severity_levels_graph_data"
      :graph_details="severity_levels_graph_details"
      :has_borders="false"
      height="200"
    />
  </div>
</template>
