import { renderRichText, stringToNode } from '~/dashboard/print/utilities.js';

function createRichText(chart, index) {
  const page_break_class = index === 0 ? 'dashboard-row--page-break' : '';

  const chart_container = stringToNode(`
    <div class="dashboard-row ${page_break_class}">
      <div class="dashboard-row__chart">
        <h3 class="dashboard-row__name">
          ${chart.chart_name}
        </h3>
        ${renderRichText(chart.dataSource.rich_text)}
      </div>
    </div>
  `);

  return chart_container;
}

export { createRichText };
