<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'primary',
    validator(value) {
      return ['primary', 'success', 'error', 'warning', 'gray'].includes(value);
    },
  },
});

const alert_classes = computed(() => {
  const classes = [];
  const defaultClasses = ['flex', 'p-4', 'border', 'gap-3', 'rounded-lg', 'justify-between', 'w-full'];
  switch (props.color) {
    case 'primary':
      classes.push(...defaultClasses, 'border-primary-300', 'bg-primary-25', 'text-primary-700');
      break;
    case 'success':
      classes.push(...defaultClasses, 'border-success-300', 'bg-success-25', 'text-success-700');
      break;
    case 'error':
      classes.push(...defaultClasses, 'border-error-300', 'bg-error-25', 'text-error-700');
      break;
    case 'warning':
      classes.push(...defaultClasses, 'border-warning-300', 'bg-warning-25', 'text-warning-700');
      break;
    case 'gray':
      classes.push(...defaultClasses, 'border-gray-300', 'bg-gray-25', 'text-gray-700');
      break;

    default:
      classes.push(...defaultClasses, 'border-primary-300', 'bg-primary-25', 'text-primary-700');
      break;
  }

  return classes;
});
</script>

<template>
  <div :class="alert_classes">
    <slot name="left">
      <div class="flex gap-3">
        <slot name="icon" />
        <div class="font-semibold text-sm">
          <slot name="content" />
        </div>
      </div>
    </slot>
    <div>
      <slot name="right" />
    </div>
  </div>
</template>
