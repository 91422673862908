<script setup>
import { computed, ref } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

// props
const props = defineProps({
  type: {
    type: String,
    default: 'primary',
    validator: value => ['primary', 'secondary'].includes(value),
  },
  items: {
    type: Array,
    default: () => [],
  },
  active_item: {
    type: [String, Number],
    default: '',
  },
  has_bordered_trigger: {
    type: Boolean,
    default: false,
  },
  additional_trigger_classes: {
    type: String,
    default: '',
  },
  additional_header_classes: {
    type: String,
    default: '',
  },
  additional_dropdown_classes: {
    type: String,
    default: '',
  },
  additional_item_classes: {
    type: String,
    default: '',
  },
  additional_footer_classes: {
    type: String,
    default: '',
  },
  position: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['open', 'close', 'select', 'transition-leave']);

const dropdown_pos_target = ref(null);
const dropdown_menu = ref(null);
const menu_state_watch = ref(null);
const dropdown_position_class = ref('right-0 top-0');
const trigger_position = ref({});
const menu_position = ref({});

// computed
const trigger_classes = computed(() => {
  const classes = [];
  if (props.has_bordered_trigger) {
    classes.push('border', 'border-gray-300');
    if (props.type === 'primary')
      classes.push('focus:ring-blue-200', 'focus:border-blue-300', 'active:border-blue-300');
    else
      classes.push('focus:ring-gray-200', 'focus:border-gray-300', 'active:border-gray-300');
  }
  return classes.join(' ');
});
const active_item = computed(() => props.active_item);
const disabled = computed(() => props.disabled);
const position = computed(() => props.position);
const additional_dropdown_classes = computed(() => props.additional_dropdown_classes);
const hawk_menu_items = computed(() => Array.isArray(props.items?.[0]) ? props.items : [props.items]);

// methods

async function setFixedPosition() {
  trigger_position.value = dropdown_pos_target.value.getBoundingClientRect();

  let menu_height = 0;

  menu_position.value.position = 'fixed';
  menu_position.value.left = '100%';
  menu_position.value.right = 'auto';
  menu_position.value.top = '100%';
  menu_position.value.bottom = 'auto';

  await new Promise((resolve) => {
    setTimeout(() => {
      const menu_dimensions = dropdown_menu.value?.el?.getBoundingClientRect() || null;
      menu_height = Math.ceil(menu_dimensions?.height || 0);
      resolve();
    }, 100);
  });

  dropdown_position_class.value = '';

  const screen_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };

  if (trigger_position.value.x < screen_center.x - 100) {
    menu_position.value.left = `${Math.round(trigger_position.value.x)}px`;
    menu_position.value.right = 'auto';
  }
  else {
    menu_position.value.right = `${Math.round(window.innerWidth - trigger_position.value.right)}px`;
    menu_position.value.left = 'auto';
  }

  if (trigger_position.value.y < screen_center.y - 100) {
    const top_position = Math.round(trigger_position.value.y + trigger_position.value.height);
    if (top_position + menu_height >= window.innerHeight) {
      menu_position.value.top = 'auto';
      menu_position.value.marginTop = '0';
      menu_position.value.marginBottom = '0';
      menu_position.value.bottom = '8px';
    }
    else {
      menu_position.value.top = `${top_position}px`;
      menu_position.value.marginTop = '8px';
      menu_position.value.marginBottom = '0';
      menu_position.value.bottom = 'auto';
    }
  }
  else {
    const bottom_position = Math.round(window.innerHeight - trigger_position.value.y);

    if (bottom_position + menu_height >= window.innerHeight) {
      menu_position.value.top = '8px';
      menu_position.value.marginTop = '0';
      menu_position.value.marginBottom = '0';
      menu_position.value.bottom = 'auto';
    }
    else {
      menu_position.value.bottom = `${bottom_position}px`;
      menu_position.value.marginBottom = '8px';
      menu_position.value.marginTop = '0';
      menu_position.value.top = 'auto';
    }
  }

  menu_position.value.position = 'fixed';
}

function setPosition() {
  // reposition the dropdown menu if it overflows

  if (position.value === 'fixed') {
    setFixedPosition();
    return '';
  }

  menu_position.value = {};
  dropdown_position_class.value = 'right-0 top-0';

  switch (position.value) {
    case 'bottom-left':
      dropdown_position_class.value = 'top-full mt-2 right-0';
      break;
    case 'bottom-right':
      dropdown_position_class.value = 'top-full mt-2 left-0';
      break;
    case 'top-left':
      dropdown_position_class.value = 'bottom-full mb-2 right-0';
      break;
    case 'top-right':
      dropdown_position_class.value = 'bottom-full mb-2 left-0';
      break;
    default:{
      const current_position = dropdown_pos_target.value.getBoundingClientRect();
      const screen_center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

      const x_class = current_position.x < screen_center.x - 100 ? 'left-0' : 'right-0';
      const y_class = current_position.y < screen_center.y - 100 ? 'top-full mt-2' : 'bottom-full mb-2';

      dropdown_position_class.value = `${x_class} ${y_class}`;
    }
  }
}

function emitItemEvent(item) {
  emits('select', item);
}

let observer = null;

function watchMenuOpen() {
  if (disabled.value)
    return;

  observer = new MutationObserver((mutationList) => {
    if (mutationList?.[0]?.addedNodes?.[0]?.wholeText === 'true') {
      emits('open');
      if (position.value === 'fixed')
        document.addEventListener('scroll', closeOnScroll, true);
    }
    else {
      emits('close');
      if (position.value === 'fixed')
        document.removeEventListener('scroll', closeOnScroll, true);
    }
  });

  const config = { attributes: false, childList: true, subtree: true };

  observer.observe(menu_state_watch.value, config);
}

const manual_close = ref(0);

function closeOnScroll() {
  if (!dropdown_pos_target.value)
    return;
  const new_pos = dropdown_pos_target.value.getBoundingClientRect();
  if (new_pos.left !== trigger_position.value.left || new_pos.top !== trigger_position.value.top)
    manual_close.value += 1;
}

function unwatchMenuOpen() {
  observer && observer.disconnect();
}

onMounted(() => {
  watchMenuOpen();
});

onUnmounted(() => {
  unwatchMenuOpen();
});
</script>

<template>
  <Menu
    v-slot="{ close, open }"
    :key="manual_close"
    as="div"
    class="relative inline-block text-left"
  >
    <span ref="dropdown_pos_target" class="absolute left-0 top-0 h-full w-full pointer-events-none" />
    <span ref="menu_state_watch" class="absolute invisible left-0 top-0 h-0 w-0 pointer-events-none">
      {{ open }}
    </span>
    <MenuButton
      class="rounded-lg"
      :class="[
        {
          'pointer-events-none opacity-60': disabled,
          'ring-4 ring-blue-200': open,
        },
        trigger_classes, additional_trigger_classes]"
      @click="setPosition"
    >
      <slot name="trigger" :open="open" :close="close">
        <HawkButton type="plain" size="sm" icon :disabled="disabled">
          <IconHawkDotsVertical />
        </HawkButton>
      </slot>
    </MenuButton>
    <transition
      v-if="!disabled"
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
      @after-enter="emits('transition-leave')"
    >
      <MenuItems
        ref="dropdown_menu"
        as="div"
        class="absolute z-[1002] origin-top-right rounded-lg bg-white border-solid border-gray-200 shadow-lg focus:outline-none border"
        :class="[
          { fixed: position === 'fixed' },
          dropdown_position_class,
          additional_dropdown_classes,
        ]"
        :style="menu_position"
      >
        <div
          v-if="$slots.header"
          class="rounded-lg py-3 px-4 border-b border-solid border-gray-200 [&>*]:w-full"
          :class="[additional_header_classes]"
        >
          <slot name="header" :close="close" />
        </div>
        <slot name="content" :close="close">
          <div
            v-for="(group, i) in hawk_menu_items"
            :key="`menu-group${i}`"
            class="p-1 bg-white rounded-lg"
            :class="{ 'border-t border-gray-200': i > 0 }"
          >
            <MenuItem
              v-for="item in group"
              :key="item.uid"
              v-slot="{ active }"
              as="div"
              :class="item?.additional_item_classes || {}"
            >
              <!-- Separate div with no click events -->
              <div v-if="item.menu_item_header" class="text-xs text-gray-600 pl-2.5 mt-2">
                {{ item.menu_item_header }}
              </div>
              <div
                v-if="item.disabled"
                v-tippy="item.tooltip ? item.tooltip : ''"
                class="relative flex items-center px-3 h py-3 text-sm font-medium min-w-[10rem] rounded-lg user-select-none cursor-default text-gray-700 opacity-60"
                :class="[
                  {
                    'bg-gray-50': active,
                  },
                  additional_item_classes,
                ]"
                @click.stop
              >
                <slot name="item" :item="item" :active="active">
                  {{ item.label }}
                </slot>
                <IconHawkCheck v-if="active_item === item.uid" class="ml-auto text-primary-600 w-5 h-5 min-w-5" />
              </div>
              <router-link
                v-else-if="item?.to"
                class="relative flex items-center px-3 h py-3 text-sm font-medium min-w-[10rem] cursor-pointer rounded-lg"
                :class="[
                  {
                    'bg-gray-50': active,
                  },
                  additional_item_classes,
                  $route.name === item?.to?.name
                    ? '!text-blue-600 font-semibold'
                    : '!text-gray-700 font-medium',
                ]"
                :to="item.to"
                @click="manual_close++"
              >
                <slot name="item" :item="item" :active="active">
                  {{ item.label }}
                </slot>
                <IconHawkCheck v-if="active_item === item.uid" class="ml-auto text-primary-600 w-5 h-5 min-w-5" />
              </router-link>
              <div
                v-else-if="item.on_click"
                class="relative flex items-center px-3 h py-3 text-sm font-medium min-w-[10rem] cursor-pointer rounded-lg text-gray-700"
                :class="[{ 'bg-gray-50': active }, additional_item_classes]"
                @click="item.on_click"
              >
                <slot name="item" :item="item" :active="active">
                  <span>
                    {{ item.label }}
                  </span>
                </slot>
                <IconHawkCheck v-if="active_item === item.uid" class="ml-auto text-primary-600 w-5 h-5 min-w-5" />
              </div>
              <div
                v-else
                class="relative flex items-center px-3 h py-3 text-sm font-medium min-w-[10rem] cursor-pointer rounded-lg text-gray-700"
                :class="[
                  {
                    'bg-gray-50': active,
                  },
                  additional_item_classes,
                ]"
                @click="emitItemEvent(item)"
              >
                <slot name="item" :item="item" :active="active">
                  {{ item.label }}
                </slot>
                <IconHawkCheck v-if="active_item === item.uid" class="ml-auto text-primary-600 w-5 h-5 min-w-5" />
              </div>
            </MenuItem>
          </div>
        </slot>
        <div
          v-if="$slots.footer"
          class="p-1 rounded-b-lg bg-white border-t border-solid border-gray-200 [&>*]:w-full"
          :class="[additional_footer_classes]"
        >
          <slot name="footer" :close="close" />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
