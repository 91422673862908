<script setup>
import { storeToRefs } from 'pinia';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const emit = defineEmits(['update']);

const dashboard_terra_store = useDashboardTerraStore();

const {
  projects_options,
  terra_configuration,
  active_container_id,
  is_loading_projects,
} = storeToRefs(dashboard_terra_store);

const form_data = ref({
  data: [],
});

async function updateProjects(data) {
  form_data.value.data = data;

  emit('update', data);
}
</script>

<template>
  <div>
    <HawkLoader v-if="is_loading_projects" class="!m-0 !mb-4" />
    <Vueform
      v-else-if="projects_options.length && !is_loading_projects"
      :key="active_container_id + projects_options.length"
      v-model="form_data"
      size="sm"
      sync
      class="mb-4"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
    >
      <HawkTreeSelect
        :options="{
          name: 'data',
          label: $t('Layers'),
          placeholder: $t('Select layers'),
        }"
        :initial_state="terra_configuration.projects || []"
        :data="projects_options || []"
        children_key="projects"
        label_key="name"
        value_key="uid"
        select_type="LEAF_PRIORITY"
        @updateForm="updateProjects($event)"
      />
    </Vueform>
    <div v-else-if="!is_loading_projects" class="text-sm font-medium mb-4">
      {{ $t('No layers available') }}
    </div>
  </div>
</template>
