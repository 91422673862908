<script setup>
import { computed, inject } from 'vue';
import { useDocumentTools } from '../composables/useDocumentTools';
import { useSheetStore } from '../store/sheet.store';

const sheet_store = useSheetStore();

const $date = inject('$date');
const document_viewer_instance = inject('document-viewer-instance');
const load_document = inject('load-document');

const { compare_pdf } = useDocumentTools(document_viewer_instance);

const selected_sheet_version = ref(sheet_store.selected_version_obj);
const versions = computed(() =>
  sheet_store.sheet.version_history.filter(sheet => sheet.version_number !== sheet_store.selected_version_obj.version_number) ?? [],
);
const current_pdf_url = computed(() => sheet_store.selected_version_obj.url);
const selected_pdf_url = computed(() => selected_sheet_version.value.url);

async function selectVersionHandler(selected_version_obj) {
  selected_sheet_version.value = selected_version_obj;

  const doc = await compare_pdf(current_pdf_url.value, selected_pdf_url.value);
  load_document(document_viewer_instance, doc);
}
function getSheetVerDisplayName(version_obj) {
  return `${version_obj.version_name} - ${$date(version_obj.version_date, 'L_DATE_MED')}`;
}
function exitClickHandler() {
  load_document(document_viewer_instance, sheet_store.selected_version_obj.url);
  sheet_store.set_compare_view(false);
}
</script>

<template>
  <div class="flex justify-between w-full">
    <!-- Exit compare CTA -->
    <hawk-button type="outlined" @click="exitClickHandler">
      <IconHawkArrowLeft />
      {{ $t('Exit compare') }}
    </hawk-button>
    <!-- Compare dropdown -->
    <div class="bg-white border rounded-md p-4">
      <div class="text-sm font-semibold mb-4">
        {{ $t('Select a version to compare') }}
      </div>
      <div class="flex gap-4">
        <span class="font-medium text-sm">{{ $t('Version') }}</span>
        <div>
          <hawk-menu
            class="bg-white rounded-md"
            :items="versions"
            @select="selectVersionHandler"
          >
            <template #trigger>
              <div class="w-60 h-9 border rounded-lg flex items-center justify-between px-3 text-sm">
                {{ getSheetVerDisplayName(selected_sheet_version) }}
                <div>
                  <IconHawkChevronDown class="text-gray-500" />
                </div>
              </div>
            </template>
            <template #item="{ item }">
              <span class="text-sm w-44">
                {{ getSheetVerDisplayName(item) }}
              </span>
            </template>
          </hawk-menu>
          <span class="block text-sm text-gray-600 mt-2">
            {{ $t('This version is displayed as black') }}
          </span>
        </div>
      </div>
      <div class="flex gap-8 font-medium text-xs mt-4">
        <span class="flex gap-2 items-center">
          <span class="w-2 h-2 bg-green-500 rounded-full" />
          {{ $t('Additions') }}
        </span>
        <span class="flex gap-2 items-center">
          <span class="w-2 h-2 bg-red-500 rounded-full" />
          {{ $t('Removals') }}
        </span>
      </div>
    </div>
  </div>
</template>
