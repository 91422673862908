<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
</script>

<template>
  <div class="grid grid-cols-12 px-6 py-2.5 text-sm items-center">
    <div class="col-span-8">
      <div class="font-medium text-gray-900">
        {{ $t('Owner') }}
      </div>
      <div class="text-xs text-gray-600">
        {{ $t('Full access to all modules and resources in the entire organization and manage global and asset admins.') }}
      </div>
    </div>
    <div class="col-span-4">
      <HawkMembers :members="common_store.current_organization_owner" type="badge" />
    </div>
  </div>
</template>
