<script setup>
import { onMounted } from 'vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, auth_store, common_store, route, router } = useCommonImports();
const form_template_detail_store = useFormTemplateDetailStore();
const quick_access_data = ref({});
const save_loading = ref(false);

const radio_items = ref([
  { value: 'everyone', label: $t('Everyone') },
  { value: 'custom', label: $t('Custom') },
]);

async function onSave() {
  save_loading.value = true;
  const get_device = () => {
    if (quick_access_data.value.desktop && quick_access_data.value.mobile)
      return ['desktop', 'mobile'];
    if (quick_access_data.value.desktop)
      return ['desktop'];
    if (quick_access_data.value.mobile)
      return ['mobile'];

    return null;
  };

  await form_template_detail_store.update_form_details({
    body: {
      quickAccess: {
        device: get_device(),
        users:
          quick_access_data?.value?.members === 'everyone'
            ? ['*']
            : quick_access_data?.value?.custom?.map(item => item.uid),
      },
    },
  });
  await common_store.set_form_quick_access();
  form_template_detail_store.form_template_track_events('Quick access updated');
  save_loading.value = false;
}
function getCustomData(members) {
  const member_data = members.map((ids) => {
    const user = common_store.get_user(ids);
    const team = common_store.get_team(ids);
    return { name: user?.username, uid: user?.uid || team?.uid, value: user?.uid || team?.uid, member: !!user?.uid };
  });
  return member_data;
}
onMounted(async () => {
  const { device, users } = form_template_detail_store?.form_template_detail?.quick_access || {};
  const get_members_type = () => {
    if (!users.length)
      return null;

    if (users.includes('*'))
      return 'everyone';
    else
      return 'custom';
  };

  quick_access_data.value.desktop = device?.includes('desktop');
  quick_access_data.value.mobile = device?.includes('mobile');
  quick_access_data.value.members = get_members_type();
  quick_access_data.value.custom = quick_access_data.value.members === 'custom' ? getCustomData(users) : null;
});
</script>

<template>
  <div>
    <div class="col-span-8 mb-5">
      <h2 class="mt-6 text-lg font-semibold tracking-tight">
        {{ $t('Quick Access') }}
      </h2>
      <span class="mt-6 text-gray-600 text-xs">{{ $t('setup-shortcuts-sidebar-app-drawer-desktop-mobile') }}</span>
    </div>
    <div class="grid grid-rows-1 grid-flow-col gap-4">
      <Vueform v-model="quick_access_data" sync validate-on="" :display-errors="false" size="sm" @submit="onSave">
        <div class="col-span-8 mb-5">
          <div class="grid grid-cols-12">
            <div class="col-span-3 text-sm font-medium">
              {{ $t('Visible On') }}
            </div>
            <div class="col-span-6">
              <div class="grid grid-flow-row-dense grid-cols-3 grid-rows-1">
                <div class="col-span-1">
                  <ToggleElement name="desktop" class="font-medium" :columns="{ container: 12, label: 12, wrapper: 12 }">
                    {{ $t('Desktop') }}
                  </ToggleElement>
                </div>
                <div class="col-span-1">
                  <ToggleElement name="mobile" class="font-medium" :columns="{ container: 12, label: 4, wrapper: 12 }">
                    {{ $t('Mobile') }}
                  </ToggleElement>
                </div>
              </div>
              <StaticElement
                :columns="{ container: 12, label: 4, wrapper: 12 }"
                class="pt-2"
              >
                {{ $t('choose-devices-for-shortcut-availability') }}
              </StaticElement>
            </div>
          </div>
        </div>
        <div class="col-span-8">
          <div class="grid grid-cols-12">
            <div class="col-span-3 text-sm font-medium">
              {{ $t('Members') }}
            </div>
            <div class="col-span-6">
              <div class="grid grid-flow-row-dense grid-cols-3 grid-rows-1">
                <div class="col-span-12">
                  <RadiogroupElement :items="radio_items" name="members" value="members" class="pb-2 font-medium" />
                  <!-- Assignees -->
                  <hawk-assignee-input
                    :add-classes="{
                      ElementLabel: {
                        container: ['hidden'],
                      },
                      ElementLayout: {
                        innerContainer: ['md:!col-span-12'],
                      },
                    }"
                    class="mb-6"
                    :options="{
                      conditions: [
                        ['members', '==', 'custom'],
                      ],
                      name: 'custom',
                      has_teams: true,
                      placeholder: $t('select members and teams'),
                    }"
                    :multi="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <StaticElement
          name="divider_1_copy"
          content="<hr style='border-color: #d1d5db; margin-top: 8px; padding-bottom: 8px;' />"
        />
        <StaticElement>
          <div class=" flex justify-end text-sm font-medium">
            <hawk-button
              type="outlined"
              @click="router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid } })"
            >
              {{ $t('Cancel') }}
            </hawk-button>
            <ButtonElement
              button-class="w-full bg-blue-600" class="pl-4" name="submit" :submits="true"
              :loading="save_loading"
            >
              {{ $t('Save') }}
            </ButtonElement>
          </div>
        </StaticElement>
      </Vueform>
    </div>
  </div>
</template>
