<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  form_template_detail_store: {
    type: Object,
  },
  section: {
    type: Object,
  },
  field: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(['close']);
const $services = inject('$services');
const $toast = inject('$toast');
const $track_event = inject('$track_event');
const route = useRoute();
const $t = inject('$t');
const sections = ref([]);
const form$ = ref(null);
const is_loading = ref(false);
const form_template_detail_store = props.form_template_detail_store;

async function apply() {
  try {
    const payload = {
      section: {
        name: form$.value.data.template.name,
        form: route.params.template_uid,
        sectionUid: props.section.element.uid,
        uid: form$.value.data.template.uid,
      },
    };
    is_loading.value = true;
    const response = await $services.forms.post({
      body: payload,
      attribute: 'sections/template/load',
    });
    const section_index = props.section.index;
    form_template_detail_store.form_template_detail.sections[section_index].fields = [...form_template_detail_store.form_template_detail.sections[section_index].fields, ...response.data.section.fields];
    form_template_detail_store.reset_form_template();
    form_template_detail_store.force_re_render_hands_on_table(props.section.element.uid);
    const types = {
      default: 'Basic',
      checklist: 'Checklist',
      tablev2: 'Table',
      signature: 'Signature',
    };
    $track_event('save_load_field_template', {
      type: types[response.data.section?.type],
      method: 'Load',
    });

    is_loading.value = false;
    $toast({
      title: $t('Template loaded'),
      text: $t('Successfully added fields from the template.'),
      type: 'success',
    });
    emit('close');
  }
  catch (err) {
    logger.log(err);
    $toast({
      title: $t('Failed to load template'),
      text: $t('Unable to load fields from the template. Please try again.'),
      type: 'error',
    });
  }
}

onMounted (async () => {
  is_loading.value = true;
  const { data } = await $services.forms.getAll({
    attribute: 'sections',
    query: {
      isTemplate: true,
      page_size: 100000000,
    },
  });
  sections.value = data?.sections.filter(section => !!section.name && section.type === props.section.element.type);
  is_loading.value = false;
});
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :format-load="(data) => data"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :should_validate_on_mount="false"
      :display-errors="false"
      :endpoint="apply"
      sync
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Load from template') }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content :is_scroll="false">
          <p class="text-sm text-gray-600 ml-1 mb-6">
            {{ $t('Select a template to add its fields to this section.') }}
          </p>
          <div class="p-1">
            <SelectElement
              class="mb-4"
              name="template"
              track-by="name"
              label-prop="name"
              value-prop="uid"
              :label="$t('Name')"
              :placeholder="$t('Choose Template')"
              :search="true"
              :object="true"
              :items="sections"
              :rules="['required']"
              @search-change="search_key = $event"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-3" type="outlined" color="gray" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('load') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style lang="scss">
.three_columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
