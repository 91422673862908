<script setup>
import IconInfo from '~icons/hawk/overdue-icon';

const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  overdue: {
    default: 0,
  },
});

const tooltip = [];
if (props.overdue.overdue)
  tooltip.push(`${props.overdue.overdue} Overdue`);
if (props.overdue.today)
  tooltip.push(`${props.overdue.today} Today`);

const tooltip_text = tooltip.join(', ');
</script>

<template>
  <div v-if="props.count">
    <hawk-badge v-tippy="tooltip_text" color="yellow">
      <span><IconInfo class="w-3 h-3 text-warning-700" /></span>
      <span class="text-xs"> {{ props.count }}</span>
    </hawk-badge>
  </div>
</template>
