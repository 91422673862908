<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { getUserFullName } from '~/common/utils/common.utils.js';

const props = defineProps({
  member: {
    required: Object,
  },
  name_truncate_length: {
    type: Number,
    default: 30,
  },
  email_truncate_length: {
    type: Number,
    default: 30,
  },
  has_avatar: {
    type: Boolean,
    default: true,
  },
  has_name: {
    type: Boolean,
    default: false,
  },
  has_email: {
    type: Boolean,
    default: false,
  },
  can_clear: {
    type: Boolean,
    default: false,
  },
  has_tooltip: {
    type: Boolean,
    default: false,
  },
  tooltip_content: {
    type: String,
    default: '',
  },
  has_border: {
    type: Boolean,
    default: false,
  },
  // :- Design props
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['badge', 'tiny', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
    },
  },
  is_outlined: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'default',
    validator(value) {
      return ['default', 'group', 'label', 'badge', 'only_name', 'only_email'].includes(value);
    },
  },
  name_classes: {
    type: String,
    default: '',
  },
  email_classes: {
    type: String,
    default: '',
  },
});
const common_store = useCommonStore();
const auth_store = useAuthStore();

// Inspiration
// Avatar
// Profile picture - If the user details in state and has profile picture
// Initial - Else if the user details in state but do not have profile picture
// SenseHawk icon - Else If the user uid is in internal_users uids
// Unknown icon - Otherwise
// Text
// Firstname Lastname - If the user details is in state
// SenseHawk - Else If the user uid is in internal_users uids
// Unknown - Otherwise

const is_member_a_logged_in_user = computed(
  () => auth_store?.logged_in_user_details.user_id === props.member.uid,
);
const is_logged_user_a_internal_user = computed(
  () => !!auth_store?.logged_in_user_details?.user_role,
);
const is_member_a_internal_user = computed(
  () => !!(common_store?.internal_users_uids_map?.[props?.member?.uid]),
);
const is_member_a_bot = computed(() => props.member.uid === 'sensehawk');

const organization_member_details = computed(() =>
  props?.member.type === 'team'
    ? common_store.get_team(props?.member?.uid)
    : common_store.get_user(props?.member?.uid),
);
const internal_member_details = computed(() =>
  props?.member.type === 'user'
    ? common_store?.internal_users_map?.[props?.member?.uid]
    : {},
  // props?.member.type === 'user'
  //   ? !!common_store?.internal_users_map?.[props?.member?.uid]
  //   : false,
);
const logged_in_user_details = computed(() => auth_store?.logged_in_user_details);

const member_details = computed(() => {
  if (organization_member_details.value)
    return {
      uid: props.member.uid,
      index: props.member.index,
      name: getUserFullName(organization_member_details.value),
      email: organization_member_details.value?.email,
      display_picture: organization_member_details.value?.display_picture,
      type: 'organization',
      is_online: false, // later use
      is_team: props.member.type === 'team',
    };

  if (is_member_a_logged_in_user.value)
    return {
      uid: props.member.uid,
      index: props.member.index,
      name: getUserFullName(logged_in_user_details.value),
      email: logged_in_user_details.value?.email,
      display_picture: logged_in_user_details.value?.display_picture,
      type: 'me',
      is_online: false, // later use
      is_team: false,
    };

  if (is_logged_user_a_internal_user.value && internal_member_details.value?.uid)
    return {
      uid: props.member.uid,
      index: props.member.index,
      name: getUserFullName(internal_member_details.value),
      email: internal_member_details.value?.email,
      display_picture: internal_member_details.value?.display_picture,
      type: 'internal',
      is_online: false, // later use
      is_team: false,
    };

  if (
    (is_logged_user_a_internal_user.value && is_member_a_internal_user.value && !internal_member_details.value)
    || (!is_logged_user_a_internal_user.value && is_member_a_internal_user.value)
  )
    return {
      uid: props.member.uid,
      index: props.member.index,
      name: 'Sensehawk',
      display_picture: null,
      type: 'sensehawk',
      is_online: false, // later use
      is_team: false,
    };

  if (is_member_a_bot.value)
    return {
      uid: props.member.uid,
      index: props.member.index,
      name: 'Sensehawk Bot',
      display_picture: null,
      type: 'sensehawk',
      is_online: false,
      is_team: false,
    };

  return {
    uid: props.member.uid,
    index: props.member.index,
    name: 'Unknown',
    display_picture: null,
    type: 'unknown',
    is_online: false, // later use
    is_team: false,
  };
});
const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];

function stringToNumber(string) {
  if (!string)
    return;
  let total = 0;
  for (const str of string) total += Number.parseInt(str.charCodeAt(0));

  return color_set[total % color_set.length];
}
</script>

<template>
  <template
    v-if="type === 'group'"
  >
    <MemberGroupAvatar
      v-tippy="tooltip_content || ''"
      :member_details="member_details"
      :background_color="stringToNumber(member_details?.name)"
      :has_email="has_email"
      :has_tooltip="has_tooltip"
      :size="size"
      :has_border="has_border"
      :img_src="member_details?.display_picture"
    />
  </template>
  <template v-else-if="type === 'label'">
    <MemberLabel
      v-tippy="tooltip_content || ''"
      :member_details="member_details"
      :img_src="member_details?.display_picture"
      :background_color="stringToNumber(member_details?.name)"
      :has_email="has_email"
      :size="size"
      :name_truncate_length="name_truncate_length"
      :email_truncate_length="email_truncate_length"
      :has_avatar="has_avatar"
      :name_classes="name_classes"
      :email_classes="email_classes"
    />
  </template>
  <template v-else-if="type === 'only_name'">
    <HawkText
      :content="member_details?.name"
      :length="name_truncate_length"
      :class="[name_classes]"
      class="whitespace-nowrap text-sm"
    />
  </template>
  <template v-else-if="type === 'only_email'">
    <HawkText
      :content="member_details?.email"
      :length="name_truncate_length"
      :class="[email_classes]"
      class="whitespace-nowrap text-sm"
    />
  </template>
  <template v-else-if="type === 'badge'">
    <MemberBadge
      v-tippy="tooltip_content || ''"
      :member_details="member_details"
      :size="size"
      :img_src="member_details?.display_picture"
      :background_color="stringToNumber(member_details?.name)"
      :has_border="has_border"
      :is_outlined="is_outlined"
      :has_avatar="has_avatar"
      :can_clear="can_clear"
      :name_truncate_length="name_truncate_length"
    />
  </template>
  <template v-else>
    <SensehawkAvatar
      v-if="member_details.type === 'sensehawk'"
      v-tippy="tooltip_content || has_tooltip ? member_details?.name : ''"
      :size="size"
    />
    <UnknownAvatar
      v-else-if="member_details.type === 'unknown'"
      v-tippy="tooltip_content || has_tooltip ? member_details?.name : ''"
      :size="size"
    />
    <TeamAvatar
      v-else-if="member_details?.is_team"
      v-tippy="tooltip_content || has_tooltip ? member_details?.name : ''"
      :size="size"
    />
    <HawkAvatar
      v-else
      v-tippy="tooltip_content || has_tooltip ? member_details?.name : ''"
      :name="member_details?.name"
      :size="size"
      :img_src="member_details?.display_picture"
      :background_color="stringToNumber(member_details?.name)"
      :has_border="has_border"
    />
  </template>
</template>
