<script setup>
import TableWrapperVue from "~/common/components/organisms/hawk-table/table.wrapper.vue";
import { flatMapDeep } from "lodash-es";
import { computed } from "vue";
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
const props = defineProps({
  document: {
    type: Object
  },
  confirm: {
    type: Function,
    default: null
  }
});
const emit = defineEmits(["close"]);

const form$ = ref(null);
const { $t, $toast, $services, route } = useCommonImports();
const table_instance = ref(null);

const getFolders = folder => {
  if (!folder.children || !folder.children.length) {
    return folder;
  }
  return [folder, _.flatMapDeep(folder.children, getFolders)];
};

const is_submitting = ref(false);
const has_dirty_values =  ref(false);
async function submitHandler() {
  is_submitting.value = true;
  const documents = flatMapDeep(folder_hierarchy.value, getFolders).map(
    document => ({
      uid: document.uid,
      type: document.type,
      weightage: parseFloat(document.weightage || 0).toFixed(2)
    })
  );
  documents.shift();
  const folders = documents.filter(folder => folder.type === "folder");
  const files = documents.filter(folder => folder.type === "file");
  await props?.confirm({
    folders: {
      update: folders
    },
    files: {
      update: files
    }
  });
  is_submitting.value = false;
}

const folder_hierarchy = ref([]);

function set_weightage(folders,max_weight=100, parent = null) {
  folders.forEach((folder, index) => {
    folder.parent_folder = parent;
    // weightage calculation
    if (folder.weightage===null || isNaN(folder.weightage)) {
      if (!parent) folder.weightage = max_weight;
      else {
        const weightage = parseFloat(max_weight / parent.children.length).toFixed(2);
        if (index + 1 === folders.length && folders.length !== 1) {
          folder.weightage = parseFloat(
            max_weight - weightage * (parent.children.length - 1)
          ).toFixed(2);
        } else folder.weightage = weightage;
      }
    }
    // weightage calculation end
    if (folder.children) set_weightage(folder.children, max_weight, folder);
  });
  return folders;
}

function clear_weights(folders){
  folders.forEach(folder => {
    folder.weightage=0;
    if (folder.children && folder.children.length > 0) {
        clear_weights(folder.children);
    }
  });
}

function set_invalid_fields(folders, invalid_fields = {}) {
  folders.forEach(folder => {
    if (folder.children && folder.children.length > 0) {
      const total_weight = folder.children
        .map(child_folder => child_folder.weightage || 0)
        .reduce((sum, weight) => sum + parseFloat(weight), 0);
      const has_empty_weights = folder.children.filter(
        folder => !parseFloat(folder.weightage)
      ).length;
      const parsed_total_weight = parseFloat(total_weight).toFixed(2);
      if (parsed_total_weight != 100.0)
        invalid_fields[folder.uid] = parseFloat(parsed_total_weight).toFixed(2);
      else if (has_empty_weights) invalid_fields[folder.uid] = 0;

      set_invalid_fields(folder.children, invalid_fields);
    }
  });
  return invalid_fields;
}

async function set_hierarchy() {
  const response = await $services.documents.getAll({
    query: {
      hierarchy: true,
      children: true,
      uid: props.document.item.uid
    }
  });
  const folders = response.data.folders || [];
  if (response.data.folders) folder_hierarchy.value = set_weightage(folders);
}

const is_loading = ref(false);

onMounted(async () => {
  try {
    is_loading.value = true;
    await set_hierarchy();
    is_loading.value = false;
  } catch (e) {
    is_loading.value = false;
    $toast({
      title: "Something went wrong",
      text: "Please try again later",
      type: "error",
      position: "bottom-right"
    });
  }
});

const columns = [
  {
    header: "Name",
    accessorKey: "name",
    id: "name",
    cell: info => info.getValue(),
    static: true
  },
  {
    header: "Weightage",
    accessorKey: "weightage",
    id: "weightage",
    custom_classes: "!p-0 h-full",
    static: true
  }
];

function getChildren(row) {
  return row.children;
}

const selected_row = ref([]);
function updateStyle(e, row) {
  selected_row.value =
    row.parent_folder?.children.map(folder => folder.uid) || [];
  handleSelectText(e.target);
  function handleSelectText(element) {
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}
const invalid_items = computed(() => {
  return set_invalid_fields(folder_hierarchy.value);
});

function updateInputValue(e, folder, weight) {
  let value = weight || parseFloat(e.target.innerText || 0).toFixed(2);
  if (value > 100 || value < 0) value = folder.weightage || 0;
  e.target.innerText = value;
  folder.weightage = value;
  selected_row.value = [];
  has_dirty_values.value = true;
}

const pasted = ref(0);
function pasteFields(event, folder, index) {
  try {
    event.preventDefault();
    const string = event.clipboardData.getData("text");

    const decimal_regex = /\b\d+\.\d+\b/g;
    const decimal_string = string.replace(decimal_regex, val =>
      Math.floor(Number.parseFloat(val))
    );

    const regex = /(?:\r\n|\r|\n)|[\t,]/;
    const rows = decimal_string
      .split(/\r?\n/)
      .map(row => {
        if (row.trim())
          return row
            .trim()
            .split(regex)
            .map(val => val.trim());
        return [];
      })
      .filter(val => !!val.length);

    if (!rows.length) return;

    rows.forEach((value, i) => {
      const weight = parseFloat(value[0]);
      if (folder.parent_folder.children[i + index] && weight) {
        updateInputValue(event,folder.parent_folder.children[i + index],weight);
      }
    });
  } catch (error) {
    logger.log(error);
  }
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg !w-[1200px]">
    <div
      v-if="is_loading"
      class="w-full min-h-[680px] border border-gray-300 border-solid rounded-lg flex justify-center items-center !bg-white"
    >
      <hawk-loader />
    </div>
    <div v-else class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex flex-col justify-start">
                  {{ $t("Assign weightage") }}
                  <span class="font-normal text-sm text-gray-600">
                    {{
                      $t("Assign weightages to folders, subfolders and files")
                    }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose
                    class="w-6 h-6 text-gray-500 hover:text-gray-900"
                  />
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div class="-mt-2">
            <span class="text-sm text-gray-600">{{
              $t(
                "You can assign weightages to folders, files and see overall completion status on parent folder."
              )
            }}</span>
          </div>
          <div>
            <TableWrapperVue :height="500" container_class="border mt-3">
              <hawk-table
                is_gapless
                :data="folder_hierarchy"
                :columns="columns"
                :show_menu_header="false"
                :getChildren="getChildren"
                @tableInstance="table_instance = $event"
                @tableInstanceCreated="
                  table_instance.toggleExpandedRow(folder_hierarchy[0].uid)
                "
              >
                <template #name="slotProps">
                  <div class="flex">
                    <div v-if="!slotProps.data.row.original.children">
                      <DocumentThumbnail
                        :file="slotProps.data.row.original"
                        :index="slotProps.data.row.index"
                        source="files"
                      />
                    </div>
                    <div class="ml-2">
                      <div class="text-sm font-medium text-gray-900">
                        {{ slotProps.data.row.original.name }}
                      </div>
                      <div
                        v-if="
                          slotProps.data.row.original.number &&
                            !slotProps.data.row.original.children
                        "
                        class="text-xs text-gray-500"
                      >
                        {{ slotProps.data.row.original.number }}
                      </div>
                    </div>
                  </div>
                </template>
                <template #weightage="slotProps">
                  <div
                    class="flex w-full h-full px-8 items-center justify-between"
                    :class="{
                      'bg-green-100': selected_row.includes(
                        slotProps.data.row.original.uid
                      )
                    }"
                  >
                    <span
                      v-if="
                        slotProps.data.row.original.uid !==
                          folder_hierarchy[0].uid
                      "
                      :key="pasted"
                      class="focus:outline-none number-input cursor-text"
                      role="textbox"
                      contenteditable
                      @keypress="
                        event =>
                          (event.charCode >= 48 && event.charCode <= 57) ||
                          event.charCode === 46
                            ? null
                            : event.preventDefault()
                      "
                      @keypress.enter.prevent="e => e.target.blur()"
                      tabindex="10"
                      @focusin="
                        e => updateStyle(e, slotProps.data.row.original)
                      "
                      @focusout="
                        e => updateInputValue(e, slotProps.data.row.original)
                      "
                      @paste="
                        e =>
                          pasteFields(
                            e,
                            slotProps.data.row.original,
                            slotProps.data.row.index
                          )
                      "
                    >
                      {{ slotProps.data.row.original.weightage || 0 }}
                    </span>
                    <span v-else></span>
                    <span
                      v-if="invalid_items[slotProps.data.row.original.uid]"
                      v-tippy="
                        $t(
                          'The weight of the folder is not equal to 100%, current weight is '
                        ) + invalid_items[slotProps.data.row.original.uid]
                      "
                      class="text-sm text-red-600 font-semibold ml-2"
                    >
                      <IconHawkAlertTriangle
                        class="text-danger-500"
                      ></IconHawkAlertTriangle>
                    </span>
                    <span
                      v-else-if="
                        invalid_items[slotProps.data.row.original.uid] === 0
                      "
                      v-tippy="
                        $t('One/more folders or files do not have weights.')
                      "
                      class="text-sm text-warning-500 font-semibold ml-2"
                    >
                      <IconHawkInfoCircle></IconHawkInfoCircle>
                    </span>
                  </div>
                </template>
              </hawk-table>
            </TableWrapperVue>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-between w-full">
                <HawkButton type='text' @click="clear_weights(folder_hierarchy)">
                  {{ $t("Clear values") }}
                </HawkButton>
                <div>
                <HawkButton type="outlined" class="mr-4" @click="emit('close')">
                  {{ $t("Cancel") }}
                </HawkButton>
                <HawkButton
                  @click="submitHandler"
                  :disabled="Object.keys(invalid_items).length || is_submitting || !has_dirty_values"
                  :loading="is_submitting"
                >
                  {{ $t("Save") }}
                </HawkButton>
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-footer>
    </div>
  </hawk-modal-container>
</template>

<style lang="scss" scoped>
.number-input::after {
  content: "%";
}

:deep() {
  table {
    height: 1px;
  }
}
</style>
