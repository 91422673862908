<script setup>
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapper from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { usePlansStore } from '~/plans/store/plans.store';

defineProps({
  version_dropdown_flow: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'confirm']);

const $t = inject('$t');

const plans_store = usePlansStore();
const form = ref({});
const is_advanced_view = ref(false);
const columns = ref(
  [
    {
      accessorKey: 's_no',
      header: $t('S.No.'),
      id: 's_no',
      size: 10,
      enableResizing: false,
    },
    {
      accessorKey: 'assigned_drawing',
      header: $t('Drawing'),
      id: 'assigned_drawing',
      size: 100,
      enableResizing: false,
    },
    {
      accessorKey: 'existing_sheet_name',
      header: $t('Sheet'),
      id: 'existing_sheet_name',
      size: 200,
      enableResizing: false,
    },
    {
      accessorKey: 'version_name',
      header: $t('Version name'),
      id: 'version_name',
      enableResizing: false,
    },
    {
      accessorKey: 'version_date',
      header: $t('Date'),
      id: 'version_date',
      size: 200,
      enableResizing: false,
    },
    {
      accessorKey: 'is_current_version',
      header: $t('Default'),
      id: 'is_current_version',
      size: 10,
      enableResizing: false,
    },
  ]);

const btn_text = computed(() => is_advanced_view.value ? 'Basic options' : 'Advanced options');
const duplicate_docs = computed(() => plans_store.uploaded_document_arr.filter(doc => doc.is_duplicate));

function onConfirmHandler() {
  plans_store.fetch_all_drawings();

  emit('confirm', form.value);
  emit('close');
}
</script>

<template>
  <hawk-modal-container content_class="!w-auto">
    <Vueform
      v-model="form"
      size="sm"
      class="w-full"
      :display-errors="false"
      @submit="onConfirmHandler"
    >
      <div class="col-span-12 !left-[calc(50%-200px)]">
        <hawk-modal-header @close="$emit('close')">
          <template #title>
            {{ version_dropdown_flow ? $t('Review new version') : $t('Update versions') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div v-if="!version_dropdown_flow" class="p-2 pt-0 text-sm">
            {{ `${$t('Duplicate sheets found')} - ${duplicate_docs.length}` }}
          </div>
          <template v-if="is_advanced_view">
            <TableWrapper class="border-0 h-60">
              <HawkTable
                class="!p-0"
                :data="duplicate_docs"
                :columns="columns"
                :show_menu_header="false"
              >
                <template #s_no="s_no">
                  {{ s_no.data.row.index + 1 }}
                </template>
                <template #version_name="version_name">
                  <TextElement
                    class="w-full"
                    :placeholder="$t('Enter new version name')"
                    rules="required"
                    :messages="{
                      required: 'required',
                    }"
                    :name="`version_name_${version_name.data.row.original.existing_sheet_uid}`"
                  />
                </template>
                <template #version_date="version_date">
                  <DateTimeElement
                    class="w-full"
                    :options="{
                      calendarCellClassName: 'w-8 h-8',
                      placeholder: $t('Enter date'),
                      format: 'yyyy-MM-dd',
                    }"
                    rules="required"
                    :messages="{
                      required: 'required',
                    }"
                    :name="`version_date_${version_date.data.row.original.existing_sheet_uid}`"
                  />
                </template>
                <template #is_current_version="is_current_version">
                  <ToggleElement
                    size="sm"
                    :name="`is_current_version_${is_current_version.data.row.original.existing_sheet_uid}`"
                  />
                </template>
              </HawkTable>
            </TableWrapper>
          </template>
          <template v-else>
            <div class="p-2">
              <TextElement
                class="w-full py-2"
                :label="$t('Version name')"
                name="version_name"
                :placeholder="$t('Enter new version name')"
                rules="required"
              />
              <DateTimeElement
                class="w-full py-2"
                :label="$t('Version date')"
                name="version_date"
                :options="{
                  calendarCellClassName: 'w-8 h-8',
                  placeholder: $t('Enter date'),
                  format: 'yyyy-MM-dd',
                }"
                rules="required"
              />
              <ToggleElement class="py-2 pb-0" size="sm" name="is_current_version" :label="$t('Mark as current version')" />
            </div>
          </template>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-between w-full">
              <HawkButton :class="{ invisible: version_dropdown_flow }" type="link" class="mr-3" @click="is_advanced_view = !is_advanced_view">
                {{ $t(btn_text) }}
              </HawkButton>
              <div class="flex">
                <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits name="assign_drawing_submit" :button-label="$t('Confirm')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
