<script setup>
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { getUserFullName } from '~/common/utils/common.utils.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, $date, common_store } = useCommonImports();

const form_template_detail_store = useFormTemplateDetailStore();
const form_template_detail = computed(() => form_template_detail_store?.form_template_detail || {});

const organization_user = computed(() => (common_store.get_user(form_template_detail.value.owner)));

// internal_user
const internal_user = computed(() => common_store?.internal_users_map?.[form_template_detail.value.owner]);
</script>

<template>
  <div class="text-white w-max">
    <div v-if="form_template_detail.description" class="max-w-[500px] truncate">
      {{ form_template_detail.description }}
    </div>
    <div class="text-sm font-light flex items-center font-normal pr-2">
      <div class="flex items-center">
        <p class="pr-1 text-sm font-medium">
          {{ $t('Created by') }}:
        </p>
        {{ getUserFullName(organization_user || internal_user || null) }}
      </div>
      <span v-if="form_template_detail.created_at" class="text-m pl-1"> on <span>{{ $date(form_template_detail.created_at, 'L_DATETIME_MED') }}</span></span>
    </div>
  </div>
</template>
