<script setup>
import { onMounted, reactive } from 'vue';
import { groupBy } from 'lodash-es';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useProjectManagementStore } from '~/project-management/store/pm.store';
import { useTerraStore } from '~/terra/store/terra.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['close', 'importScheduleData']);
dayjs.extend(timezone);
const terra_store = useTerraStore();
const pm_store = useProjectManagementStore();
const { $t, $services, auth_store, route } = useCommonImports();
// Temporary until we come up with a better solution than $date
if (auth_store?.logged_in_user_details?.timezone)
  dayjs.tz.setDefault(auth_store?.logged_in_user_details?.timezone);
const state = reactive({
  selected_schedule: '',
  importing_schedule: false,
  loading: false,
});
async function getData() {
  try {
    state.loading = true;
    await pm_store.set_schedules(
      {
        asset: route?.params?.asset_id,
      },
    );

    state.loading = false;
  }
  catch (error) {
    state.loading = false;
    logger.log(error);
  }
}
async function save() {
  try {
    state.importing_schedule = true;
    const response = await $services.terra.get_container_schedules({
      schedule_id: state.selected_schedule,
      container_id: route.params.id,
    });
    const field_data = groupBy(response.data.data || [], 'field_uid');
    const field_keys = Object.keys(field_data || {});
    const payload = {};
    if (field_keys.length)
      field_keys.forEach((key) => {
        field_data[key].forEach((data) => {
          const field_name = terra_store.all_workflow_fields_hash[data.field_uid]?.name;
          const project_name = terra_store.active_projects_data_map({ all_projects: true })[data.project_uid]?.name;

          payload[field_name] = {
            ...(payload[field_name] || {}),
            [`${project_name}_start_date`]: data.planned_start ? dayjs(data.planned_start).format('DD/MM/YYYY') : null,
            [`${project_name}_finish_date`]: data.planned_finish ? dayjs(data.planned_finish).format('DD/MM/YYYY') : null,
          };
        });
      });

    emit('importScheduleData', payload);

    state.importing_schedule = false;
    emit('close');
  }
  catch (err) {
    state.importing_schedule = false;
    logger.log(err);
  }
}
onMounted(() => {
  getData();
});
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="emit('close')">
      <template #title>
        <p class="text-gray-900 text-lg font-semibold">
          {{ $t('Import schedule') }}
        </p>
      </template>
    </hawk-modal-header>
    <hawk-modal-content class="h-[350px] overflow-auto ">
      <HawkLoader v-if="state.loading" />
      <div v-else>
        <div v-for="schedule in pm_store.schedules" :key="schedule.uid">
          <div class="flex  p-2 cursor-pointer hover:bg-gray-50 rounded-lg justify-between items-center w-[100%]" :class="{ 'bg-gray-50': state.selected_schedule === schedule.uid }" @click="state.selected_schedule = schedule.uid">
            <div class="text-[14px]">
              {{ schedule.name }}
            </div>
            <IconHawkCheck v-if="state.selected_schedule === schedule.uid " />
          </div>
        </div>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-2">
          <HawkButton type="outlined" @click="emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton :loading="state.importing_schedule" :disabled="!state.selected_schedule" @click="save">
            {{ $t('Import') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
