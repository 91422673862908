import { stringToNode } from '~/dashboard/print/utilities.js';

function createTrendTemplate(trend_status_success = false, upwards_trend = false, difference = 0) {
  let trend_description_text = '';
  if (difference === 0)
    trend_description_text = 'Activity is on track';
  else if (trend_status_success)
    trend_description_text = `Activity is ahead by ${difference}%`;
  else
    trend_description_text = `Activity is behind by ${difference}%`;

  const trend_template = `
      <div class="number-chart__trend ${!trend_status_success ? 'number-chart__trend--danger' : ''}">
        <span style="${difference === 0 ? 'display: none;' : ''}">
          ${upwards_trend ? '+' : '-'}
        </span>
        <span>
          ${difference}%
        </span>
      </div>
    `;

  const trend_description_template = `
      <div class="number-chart__trend-description">
        ${trend_description_text}
      </div>
    `;

  return { trend_template, trend_description_template };
}

function createNumberChart(chart) {
  const display_value = chart.dataSource.prettify_values ? chart.dataSource.current_value?.value || '0' : `${chart.dataSource.current_value?.actual_value || 0}%`;
  const upwards_trend = chart.dataSource.difference?.is_val_increased || false;
  const difference = chart.dataSource.difference?.diff_percent || 0;

  let trend_template = '';
  let trend_description_template = '';
  if (chart.dataSource.timerange && chart.dataSource.displayPreviousValue && difference !== 0) {
    let trend_status_success = true;

    if (chart.dataSource.inverse_trend)
      trend_status_success = !upwards_trend;
    else
      trend_status_success = upwards_trend;

    const template = createTrendTemplate(trend_status_success, upwards_trend, difference);

    trend_template = template.trend_template;
    trend_description_template = template.trend_description_template;
  }

  const chart_container = stringToNode(`
    <div class="dashboard-row dashboard-row--half-w">
      <div class="dashboard-row__chart dashboard-row__chart--number">
        <h3 class="dashboard-row__name">
          ${chart.chart_name} ${chart.dashboard_selected_range ? `| ${chart.dashboard_selected_range?.start}- ${chart.dashboard_selected_range?.end}` : ''}
        </h3>
        <div style="display: flex;">
          <div class="number-chart__value">
            ${display_value}
          </div>
          ${trend_template}
        </div>
        ${trend_description_template}
      </div>
    </div>
  `);

  return chart_container;
}

export {
  createNumberChart,
};
