<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useFormsStore } from '../store/forms.store';

const props = defineProps ({
  query: {
    default: { all_access: true, is_child: true, public: false },
  },
});

const $t = inject('$t');

const form_store = useFormsStore();
const route = useRoute();
const router = useRouter();
const final_query = computed(() => {
  return { ...props.query, parent_form_uid: route.params.template_uid };
});

watch(() => route.params.asset_id, () => {
  router.push({ name: 'form-overview-list', params: { asset_id: route.params.asset_id } });
});

const tabs = computed(() => {
  return [{ uid: '1', label: $t('Forms'), to: { name: 'form-overview-list', params: { asset_id: route.params.asset_id } } }, { uid: '2', truncate_length: 100, label: form_store?.templates?.[0]?.name || '' }];
});
</script>

<template>
  <div class="flex min-h-full flex-col justify-center py-4 sm:px-6 lg:px-4">
    <div class="flex justify-between items-start">
      <hawk-breadcrumbs :items="tabs" />
    </div>
    <form-instances-list :query="final_query" :has_sticky_header="true" class="mt-4" />
  </div>
</template>
