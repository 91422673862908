import { ref } from 'vue';
import { createUppyInstance } from '~/common/utils/uppy.util.js';

export default function VueFormInitialiser() {
  return {
    apply: 'Vueform',
    props: {
      should_validate_on_mount: {
        type: Boolean,
        required: false,
        default: false,
      },
      endpoint: {
        type: Function,
        default: () => { },
      },
      attachment_config: {
        type: Object,
        default: () => {
          return {
            meta: {
              service: 'vault',
              id: 'upload',
            },
          };
        },
      },
    },
    created() {
      this.uploading = ref(false);
    },
    mounted() {
      if (this.should_validate_on_mount)
        this.validate();
      this.uppy = createUppyInstance({
        attachment_config: this?.attachment_config,
        fire_element: this,
      });
    },
    beforeUnmount() {
      this?.uppy?.destroy({ reason: 'user' });
    },
    install(app, $vueform) {
      $vueform.config.endpoints.submit = form => form;
      $vueform.config.endpoints.uploadTempFile = form => form;
    },
  };
}
