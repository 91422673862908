<script setup>
import { isEqual } from 'lodash-es';
import IndexChartComponent from '~/dashboard/components/widgets/common-widgets/index-chart-component.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
});

const dashboard_schedule_store = useDashboardScheduleStore();
const dashboard_store = useDashboardStore();

const $services = inject('$services');

const value = ref('');
const is_loading = ref(false);

async function getIndices() {
  is_loading.value = true;
  const payload = dashboard_schedule_store.parse_schedule_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.project_management.get_graph({ body: payload });
    value.value = data.data[payload.chart];
  }
  catch (err) {
    logger.log({ err });
  }
  is_loading.value = false;
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    renderType: 'index-chart',
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      type: props.data.data.type,
      index: value.value,
      dashboard_index: props.data.i,
    },
  });
  dashboard_store.update_new_print_map((props.data.data.name), {
    type: 'value',
    data: value.value,
  });
}

watch(() => props.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    await getIndices();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <HawkLoader v-if="is_loading" container_class="m-1" />
    <IndexChartComponent v-else :value="value" />
  </div>
</template>
