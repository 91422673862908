<script setup>
import { debounce } from 'lodash-es';

const emit = defineEmits(['close', 'export']);

const HawkWysiwygEditorComponent = defineAsyncComponent(
  () => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'),
);

const form_data = ref(null);
const summary = ref(null);

const custom_fields = computed(() => {
  if (form_data.value?.customFields?.length)
    return form_data.value.customFields.filter(field => field?.key && field?.value);
  return [];
});

const is_save_disabled = computed(() => {
  if (form_data.value)
    return !(form_data.value.title || form_data.value.subtitle || custom_fields.value.length || summary.value);
});

const updateSummary = debounce((e) => {
  if (e !== summary.value)
    summary.value = e;
}, 750);

function handleSave() {
  const payload = {
    is_custom: true,
    ...form_data.value,
    summary: summary.value,
    customFields: custom_fields.value,
  };
  emit('export', payload);
  emit('close');
}
</script>

<template>
  <hawk-modal-container content_class="w-[95%] h-[95%]">
    <Vueform
      v-model="form_data"
      size="sm"
      :sync="true"
      :columns="{
        default: { container: 12, label: 2, wrapper: 5 },
        sm: { container: 12, label: 2, wrapper: 5 },
        md: { container: 12, label: 2, wrapper: 5 },
      }"
      :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div>
              <p>{{ $t('Customize report') }}</p>
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content class="!h-[calc(100vh-200px)] flex flex-col">
          <TextElement
            class="mb-8"
            name="title"
            :label="$t('Title')"
            :placeholder="$t('Enter title')"
          />
          <TextElement
            class="mb-8"
            name="subtitle"
            :label="$t('Subtitle')"
            :placeholder="$t('Enter subtitle')"
          />
          <p class="text-md font-semibold text-gray-700 mb-3">
            {{ $t('Fields') }}
          </p>
          <div
            v-if="form_data?.customFields?.length"
            class="grid grid-cols-12 text-sm font-medium text-gray-700 mb-1"
          >
            <p class="col-span-3">
              Key
            </p>
            <p class="col-span-2 mx-[-8px]">
              Value
            </p>
          </div>
          <ListElement
            class="mb-8"
            name="customFields"
            add-text="+ Add new field"
            :presets="['repeatable_list']"
            :override-classes="{
              ListElement: {
                listItem: 'flex w-full',
              },
            }"
            :columns="{
              default: { container: 12, wrapper: 6 },
              sm: { container: 12, wrapper: 6 },
              md: { container: 12, wrapper: 6 },
            }"
          >
            <template #default="{ index }">
              <ObjectElement
                class="mb-2"
                :name="index"
                :add-classes="{
                  ElementLayout: {
                    container: 'w-full',
                  },
                }"
                :columns="{
                  default: { wrapper: 12 },
                  sm: { wrapper: 12 },
                  md: { wrapper: 12 },
                }"
              >
                <TextElement
                  name="key"
                  :placeholder="$t('Enter key')"
                  :columns="{
                    default: { container: 6, wrapper: 11 },
                    sm: { container: 6, wrapper: 11 },
                    md: { container: 6, wrapper: 11 },
                  }"
                />
                <TextElement
                  name="value"
                  :placeholder="$t('Enter value')"
                  :columns="{
                    default: { container: 6, wrapper: 11 },
                    sm: { container: 6, wrapper: 11 },
                    md: { container: 6, wrapper: 11 },
                  }"
                />
              </ObjectElement>
            </template>
          </ListElement>
          <p class="text-md font-semibold text-gray-700 mb-3">
            {{ $t('Add summary') }}
          </p>
          <div class="flex-1">
            <HawkWysiwygEditorComponent
              :placeholder_text="$t('Add information here')"
              :menu_enabled="true"
              :plugins="[]"
              :header_level="1"
              class="border border-gray-300 rounded-lg"
              focus_position="end"
              @input="updateSummary"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-button :disabled="is_save_disabled" @click="handleSave">
                {{ $t('Export') }}
              </hawk-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
