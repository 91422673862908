<script setup>
const props = defineProps({
  status: {
    default: '',
    type: String,
  },
});
const status_value = computed(() => {
  if (props.status === 'in_stock')
    return {};

  else if (props.status === 'low_stock')
    return { name: 'Low stock', color: 'yellow' };

  else if (props.status === 'out_of_stock')
    return { name: 'Out of stock', color: 'red' };
});
</script>

<template>
  <HawkBadge v-if="status_value?.name?.length" :color="status_value.color">
    {{ status_value.name }}
  </HawkBadge>
</template>
