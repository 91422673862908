import { setNumberDecimals, stringToNode } from '~/dashboard/print/utilities.js';

function createProgressCardView(chart, index) {
  const page_break_class = index === 0 ? 'dashboard-row--page-break' : '';

  const progress_percent = Number.parseInt(chart.dataSource.progress_data.completion * 100);

  const chart_container = stringToNode(`
    <div class="dashboard-row ${page_break_class} dashboard-row--half-w">
      <div class="dashboard-row__chart dashboard-row__chart--progress-card">
        <h3 class="dashboard-row__name">
          ${chart.chart_name}
        </h3>
        <div style="text-align: right;">
          <div>
            <span style="font-size: 0.75rem; color: #718096; margin-right: 0.5rem;">Actual:</span>
            ${chart.dataSource.prefix_suffix.prefix || ''}
            <span style="font-size: 1.25rem; font-weight: 500;">${setNumberDecimals(chart.dataSource.progress_data.actual || 0, 2)}</span>
            ${chart.dataSource.prefix_suffix.suffix || ''}
          </div>
          <div>
            <span style="font-size: 0.75rem; color: #718096; margin-right: 1rem;">Plan:</span>
            ${chart.dataSource.prefix_suffix.prefix || ''}
            <span style="font-size: 1.25rem; font-weight: 500;">${setNumberDecimals(chart.dataSource.progress_data.planned || 0, 2)}</span>
            ${chart.dataSource.prefix_suffix.suffix || ''}
          </div>
        </div>
        <div style="display: flex; align-items: baseline">
          <div style="font-size: 1rem; font-weight: 500; color: #4A5568; margin-right: 0.75rem">
            ${progress_percent}%
          </div>
          <div style="width: 100%; background-color: #E2E8F0; height: 10px; border-radius: 0.25rem">
            <div style="background-color: #2E90FA; height: 100%; border-radius: 0.25rem; width: ${progress_percent}%;"></div>
          </div>
        </div>
      </div>
    </div>
  `);

  return chart_container;
}

export { createProgressCardView };
