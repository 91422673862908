<script setup>
import { useTerraStore } from '~/terra/store/terra.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const emit = defineEmits(['close', 'move']);

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');
const terra_store = useTerraStore();

const { getProjectOptions } = useTerraHelperComposable();

const get_header = computed(() => {
  if (terra_store.selected_features.length === 1)
    return `${$t('Move')} 1 ${$t('feature')}`;
  return `${$t('Move')} ${terra_store.selected_features.length} ${$t('features')}`;
});
const current_feature_project = computed(() => {
  const feature = terra_store.selected_features[0];
  const project_id = feature.properties.project || feature.properties.projectUid;
  return project_id;
});
const groups_projects_list = computed(() => getProjectOptions().map((group => ({
  ...group,
  projects: group.projects?.map(project => ({
    ...project,
    disabled: project.uid === current_feature_project.value,
  })),
}))));
const groups_projects_map = computed(() => groups_projects_list.value.reduce((acc, group) => {
  acc[group.uid] = group;
  group.projects.forEach((project) => {
    acc[project.uid] = project;
  });
  return acc;
}, {}));

const form = ref({
  destination: null,
});

function getDetails(type = 'from') {
  let project_id = null;
  if (type === 'from')
    project_id = current_feature_project.value;
  else
    project_id = form.value.destination;
  const project = groups_projects_map.value?.[project_id];
  const group = groups_projects_map.value?.[project?.group];
  return { project: project?.name, group: group?.name };
}

async function onSave() {
  try {
    const payload = {
      source_project: current_feature_project.value,
      destination_project: form.value.destination,
      features: terra_store.selected_features.map(f => f.properties.uid),
    };
    const response = await $services.features.post({
      attribute: `container/${terra_store.container.uid}/move`,
      body: payload,
    });
    if (terra_store.projects_details_map[payload.destination_project])
      terra_store.update_features_in_state(response.data);
    terra_store.delete_features({ skip_API: true });
    $toast({
      title: 'Feature moved successfully!',
      type: 'success',
    });
    emit('move');
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    logger.log(error);
  }
  emit('close');
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="form" sync size="sm" :display-errors="false" :endpoint="onSave"
      :columns="{
        lg: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ get_header }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div>
            <StaticElement class="mb-6" :label="$t('From')">
              <div class="flex items-center text-sm font-medium text-gray-600">
                {{ getDetails().group }} <IconHawkChevronRight class="w-4 h-4 mx-2 text-gray-500" />
                {{ getDetails().project }}
              </div>
            </StaticElement>
            <HawkTreeSelect
              :options="{
                'name': 'destination',
                'label': $t('To'),
                'placeholder': $t('Select destination'),
                'can-clear': false,
                'native': false,
                'rules': ['required'],
              }"
              select_type="LEAF_PRIORITY"
              children_key="projects"
              label_key="name"
              value_key="uid"
              select_view="SELECT"
              :multi_select="false"
              :data="groups_projects_list"
              :disable_branch_nodes="true"
              @updateForm="form.destination = $event?.[0]"
            >
              <template v-if="form.destination" #hawk_tree_select_label>
                <div class="flex items-center text-sm font-medium text-gray-600">
                  {{ getDetails('to').group }} <IconHawkChevronRight class="w-4 h-4 mx-2 text-gray-500" />
                  {{ getDetails('to').project }}
                </div>
              </template>
            </HawkTreeSelect>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Move') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
