<script setup>
import { pick } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import DmsDocumentsRequestForm from '~/dms/components/documents/forms/dms-documents-request-form.vue';

const props = defineProps({
  document_details: {
    type: Object,
  },
});

const emit = defineEmits(['close', 'refresh']);

const transmittal_store = useTransmittalsStore();
const transmittal = computed(() => transmittal_store.transmittal);
const $t = inject('$t');

const is_syncing = ref(false);

const document_request_modal = useModal({
  component: DmsDocumentsRequestForm,
});

function openSyncForm(document) {
  document_request_modal.patchOptions({
    attrs: {
      header: $t('Sync document'),
      button_text: $t('Sync'),
      confirm: async (data) => {
        document_request_modal.close();
        sync(document, data.comment);
      },
      onClose() {
        document_request_modal.close();
      },
    },
  });
  document_request_modal.open();
}

async function sync(document, message = '') {
  try {
    is_syncing.value = true;
    const payload = createPayload(document, { message });
    await transmittal_store.document_operations(payload, 'sync');
    emit('refresh');
  }
  catch (e) {
    logger.error(e.message);
  }
  is_syncing.value = false;
}

function createPayload(document, data, pick_by = ['uid']) {
  const documents = [document];
  const payload = documents.map(doc => ({ ...pick(doc, pick_by), ...data }));
  return {
    transmittal_uid: transmittal.value.uid,
    transmittals: {
      documents: payload,
    },
  };
}
</script>

<template>
  <div class="rounded-lg border border-gray-300 bg-gray-25 p-3 grid gap-3">
    <div class="font-semibold text-gray-700">
      {{ $t('Sync document') }}
    </div>
    <div class="text-gray-600 text-sm">
      {{ $t('The final document status and new version of the document will be synced immediately in the register') }}
    </div>
    <div v-if="document_details?.synced" class="mb-2">
      <IconHawkCheckCircle /> {{ document_details?.name }} {{ $t('synced successfully') }}.
    </div>
    <div v-else class="mb-2">
      <HawkButton type="outlined" :disabled="is_syncing" @click="openSyncForm(document_details)">
        <IconHawkRefreshCwFour :class="{ 'animate-spin': is_syncing }" /> {{ $t('Sync document') }}
      </HawkButton>
    </div>
  </div>
</template>
