<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep, isEqual, omit } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';

import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const dashboard_selected_range = inject('dashboardSelectedRange');

const router = useRouter();
const route = useRoute();
const $date = inject('$date');
const $services = inject('$services');
const $t = inject('$t');
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const graph_data = ref(null);
const payload = ref(null);
const dashboard_forms_store = useDashboardFormsStore();
const dashboard_store = useDashboardStore();
const forceUpdate = ref(0);
const no_data = ref(false);
const prevent_watcher = ref(false);
const form_error = ref(false);
const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const { parseRulesDateData } = useFamConstants();

const {
  forms_v2_filters,
} = storeToRefs(dashboard_forms_store);

const attachment_uids = ref([]);

function formatReponseTableData(data) {
  if (!data.value) {
    return '-';
  }
  else if (data.type === 'long_text') {
    return data.value;
  }

  else if (data.type === 'object' && data.uid === 'attached_to') {
    return data.value?.form_reference_name || data.value?.name || '-';
  }

  else if (
    (data.type === 'date' && data.uid === 'submitted_at')
    || data.type === 'datetime'
  ) { return $date(data.value, 'DATETIME_MED'); }
  else if (data.type === 'date') {
    return $date(data.value, 'DATE_MED');
  }

  else if (data.type === 'time') {
    return $date(data.value, 'TIME_24_SHORT');
  }
  else { return data.value; }
}
async function getReports() {
  no_data.value = false;
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_forms_store.parse_forms_form_to_server_format(props.data.data);
  const forms_payload = cloneDeep(payload.value);

  const filters = forms_payload.filters.advanced_filter && Object.keys(forms_payload.filters.advanced_filter).length ? [forms_payload.filters.advanced_filter, ...cloneDeep(forms_v2_filters.value) || []] : cloneDeep(forms_v2_filters.value);

  forms_payload.filters = { ...forms_payload.filters, advanced_filter: filters };
  forms_payload.filters.asset_uid = route.params.asset_id || null;
  forms_payload.filters.advanced_filter = forms_payload.filters.advanced_filter.map((filter) => {
    filter.rules = parseRulesDateData(filter.rules);
    return filter;
  });
  try {
    const { data } = await $services.forms.get_graph({ body: forms_payload });
    if (data?.data?.length) {
      if (props.data.data.transpose)
        generateTransposeActivities(data.data);
      else
        generateActivities(data.data);
      no_data.value = false;
    }
    else {
      no_data.value = true;
      columns.value = [];
      activities.value = [];
    }

    forceUpdate.value++;
    form_error.value = null;
    loading.value = false;
  }
  catch (err) {
    form_error.value = err?.response?.status === 400 ? $t('Template is not published') : $t('Template not found');
    loading.value = false;
  }
}

const columns_widths_map = computed(() => props.data.data.columns_widths || {});

function generateTransposeActivities(data) {
  const column_array = [{
    accessorKey: 'name',
    header: 'Field name',
    id: 'name',
    size: columns_widths_map.value.name || 400,
  }];

  attachment_uids.value = [];

  data.reduce((acc, item) => {
    // building column data
    return column_array.push({
      accessorKey: item.form_uid,
      header: item.name,
      id: item.form_uid,
      size: columns_widths_map.value[item.form_uid] || 150,
    });
  }, []);
  const activity_obj = {};
  data.forEach((item) => {
    const dataItem = item;
    item.values.forEach(
      (val) => {
        if (!activity_obj[val.uid])
          activity_obj[val.uid] = {};
        activity_obj[val.uid].name = val.name;
        activity_obj[val.uid][dataItem.form_uid] = formatReponseTableData(val);
        activity_obj[val.uid].type = val.type;
        activity_obj[val.uid].field_uid = val.uid;
      },
    );
  });

  activities.value = Object.values(activity_obj);
  columns.value = column_array;
}

function generateActivities(data) {
  const activity_array = [];
  const column_array = [];

  attachment_uids.value = [];

  payload.value.fields.forEach((value) => {
    column_array.push({
      accessorKey: value.key,
      header: value.label,
      id: value.key,
      size: columns_widths_map.value[value.key] || 150,
      type: value.type,
    });
  });
  Object.values(data).forEach((value) => {
    const current_item = {};

    Object.keys(value).forEach((valueKey) => {
      if (valueKey === 'values') {
        value[valueKey].forEach((val) => {
          current_item[val.uid] = formatReponseTableData(val);
        });
      }
      else {
        current_item[valueKey] = value[valueKey];
      }
    });

    activity_array.push(current_item);
  });

  activities.value = activity_array;
  columns.value = column_array;
}
const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dashboard_selected_range,
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: props.data.data.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    type: 'table',
    data: activities.value.map(a => Object.values(omit(a, ['uid', 'form_uid']))),
  });
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

function handleCellClick(data) {
  if (!dashboard_store.is_editing_widget.value) {
    if (props.data.data.transpose)
      router.push({ query: { ...route.query, form: btoa(JSON.stringify({ form_uid: data.column.id, store_key: '' })) } });
    else
      router.push({ query: { ...route.query, form: btoa(JSON.stringify({ form_uid: data.row.original.form_uid, store_key: '' })) } });
  }
}

function formatTable(cell) {
  if (cell?.getValue?.() === 'View')
    return { color: 'blue' };
  return {};
}

function getCellContent(cell = {}) {
  const row_selector_length = cell?.data?.row?.id?.length + 1 || 0;
  const accessorKey = cell?.data?.id?.slice(row_selector_length) || '0';
  return cell?.data?.row?.original?.[accessorKey] || '';
}

function getCellType(cell = {}, column = {}) {
  if (!props.data?.data?.transpose)
    return column?.type || '';
  else
    return cell?.data?.row?.original?.type || '';
}

function onHawkAttachmentClick(form_uid, field_uid) {
  router.push({
    query: {
      ...route.query,
      form: btoa(JSON.stringify({
        store_key: '',
        form_uid,
        scroll_to_field_uid: field_uid,
      })),
    },
  });
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });

watch(() => props.activeSelectedRange, async (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    await getReports();
  }
});
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="activities?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="forceUpdate"
          :data="activities"
          :columns="columns"
          additional_table_classes=""
          additional_row_classes="even:bg-gray-50"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          :container_class="`h-[${content_height || height}px]`"
          :show_menu_header="false"
          :show_column_borders="true"
          :striped="true"
          is_gapless
          cell_height="30px"
          :format-table="formatTable"
          @column-resized="columnResized"

          @cell-click="handleCellClick"
        >
          <template
            v-for="col in columns"
            :key="col.accessorKey"
            #[col.accessorKey]="cell"
          >
            <div v-if="getCellType(cell, col) === 'long_text'">
              <HawkWysiwygEditorComponent
                :editor_enabled="false"
                :display_images_fullwidth="true"
                :initial_content="getCellContent(cell)"
                editor_classes="[&>*]:m-0"
                class="w-full [&>*]:p-0"
              />
            </div>
            <div v-else-if="getCellType(cell, col) === 'attachment'">
              <hawk-attachments-list
                v-if="Array.isArray(getCellContent(cell)) && getCellContent(cell).length"
                variant="mini"
                :show-image-name-on-hover="true"
                :items="getCellContent(cell)"
                :can_delete="false"
                :can_view="false"
                :can-scroll-to-form-field="true"
                :enable_description="true"
                :can_download="false"
                additional_classes="!flex flex-wrap gap-4"
                img-container-classes="!h-[75px] !w-[75px] flex items-center justify-center"
                @scroll-to-form-field="() => props.data.data.transpose ? onHawkAttachmentClick(col.accessorKey, cell.data.row.original.field_uid) : onHawkAttachmentClick(cell.data.row.original.form_uid, col.accessorKey)"
              />
              <span v-else>{{ typeof getCellContent(cell) === 'string' ? getCellContent(cell) : '-' }}</span>
            </div>
            <div v-else>
              {{ getCellContent(cell) }}
            </div>
          </template>
        </HawkTable>
      </div>
    </a>
    <div
      v-else-if="!loading && form_error"
      class="pt-10 w-full flex items-center justify-center"
    >
      <div class="text-sm">
        {{ form_error }}
      </div>
    </div>
  </div>
</template>
