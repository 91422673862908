<script setup>
import { useThermStore } from '~/therm/store/therm.store.js';

const props = defineProps({
  status: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'badge',
  },
  status_object: {
    type: Object,
    default: null,
  },
});
const therm_store = useThermStore();

const current_status = computed(() => props.status_object || therm_store.status_map[props.status] || {});
</script>

<template>
  <HawkBadge v-if="type === 'badge'" class="font-medium" :custom_color="current_status.color" custom_color_background_opacity="0.1">
    {{ current_status.name }}
  </HawkBadge>
  <HawkMenu v-else additional_trigger_classes="!ring-0 p-0" position="fixed">
    <template #trigger="{ open }">
      <HawkBadge v-if="current_status?.name" class="font-medium" :custom_color="current_status.color" custom_color_background_opacity="0.1">
        {{ current_status.name }}
      </HawkBadge>
      <div v-else class="flex items-center w-full cursor-pointer">
        <div class="text-sm text-gray-500 font-medium">
          {{ $t('Select status') }}
        </div>
        <div class="ml-1 text-gray-500">
          <IconHawkChevronUp v-if="open" class="w-5 h-5" />
          <IconHawkChevronDown v-else class="w-5 h-5" />
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <div class="w-[180px] p-1 text-sm font-medium">
        <div
          v-for="(item) in therm_store.status_configs" :key="item.uid"
          class="p-2.5 flex items-center justify-between cursor-pointer rounded-lg hover:bg-gray-50"
          @click="$emit('update', item), close()"
        >
          <div class="flex items-center justify-start">
            <div
              class="mr-2 h-2.5 w-2.5 bg-blue-100 rounded-full shrink-0"
              :style="{ background: item.color }"
            />
            <div class="text-sm font-medium">
              {{ item.name }}
            </div>
          </div>
          <IconHawkCheck v-if="item.uid === current_status.uid" class="text-primary-700 w-5 h-5" />
        </div>
      </div>
    </template>
  </HawkMenu>
</template>
