<script setup>
import { ref } from 'vue';

const props = defineProps({
  use_tasks: {
    type: Boolean,
  },
  show_defect_status_icons: {
    type: Boolean,
  },
  disable_use_tasks: {
    type: Boolean,
  },
  on_save: {
    type: Function,
  },
});

const form = ref({
  use_tasks: props.use_tasks,
  show_defect_status_icons: props.show_defect_status_icons,
});
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform v-model="form" sync size="sm" :display-errors="false" :endpoint="on_save">
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Settings') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div v-show="!disable_use_tasks" class="flex items-start justify-between">
            <div class="w-11/12 pr-4">
              <div class="text-sm font-semibold text-gray-700 mb-1">
                {{ $t('Track defects using tasks') }}
              </div>
              <div class="text-sm text-gray-700">
                {{ $t('Replace common defects properties with tasks data such as status, priority, assignees, tags, etc. Use this for legacy containers where users have used tasks for tracking defects.') }}
              </div>
            </div>
            <div class="w-1/12 flex justify-end">
              <ToggleElement
                name="use_tasks"
              />
            </div>
          </div>

          <div v-show="!form.use_tasks" class="flex items-start justify-between mt-4">
            <div class="w-11/12 pr-4">
              <div class="text-sm font-semibold text-gray-700 mb-1">
                {{ $t('Show defects status icons') }}
              </div>
              <div class="text-sm text-gray-700">
                {{ $t('Quickly see the status of defects with visual icons. Easy decision-making and better organization for your container.') }}
              </div>
            </div>
            <div class="w-1/12 flex justify-end">
              <ToggleElement
                name="show_defect_status_icons"
                :default="true"
              />
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
