import { ref, shallowRef } from 'vue';

import { CUSTOM_ANNOTATION_TYPES, PDF_NET_CDN_URL, PDF_TRON_CDN_BASE_URL, PDF_WORKER_PATH, WEB_CORE_CDN_URL } from '../constants';
import { useAnnotationsStore } from '../store/annotations.store';
import { useSheetStore } from '../store/sheet.store';
import { extractAttrFromXML } from '../utils/helper';
import { useDocumentTools } from './useDocumentTools';
import { useCustomAnnotationHandlers } from './useCustomAnnotationHandlers';
import { $toast, track_event } from '~/common/utils/common.utils';

export function useDocumentViewer(props, emit) {
  const { scroll_view_elem, viewer_elem, doc_url, element, version } = props;
  const is_web_viewer_core_ready = ref(false);
  const has_document_loaded = ref(false);
  const err_msg = ref('');
  const document_viewer_instance = shallowRef(null);
  const current_progress = ref(0);

  const annotations_store = useAnnotationsStore();
  const sheet_store = useSheetStore();
  const { import_annotations, export_annotations, scroll_to_zoom, pan, set_text_annot_opacity } = useDocumentTools(document_viewer_instance);
  const { handleCustomAnnotChange } = useCustomAnnotationHandlers({});

  const setup_core = async () => {
    // Note: Vite's Limitation: https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#imports-must-start-with--or-
    // WEB_CORE_CDN_URL contains a CDN url and not local file so added ignore comment
    await import(/* @vite-ignore */ WEB_CORE_CDN_URL);
    await import(/* @vite-ignore */ PDF_NET_CDN_URL);

    window.Core.setWorkerPath(PDF_TRON_CDN_BASE_URL);
    window.Core.setLocalWorkerPath('/pdftron');
    window.Core.setResourcesPath(`${PDF_TRON_CDN_BASE_URL}/assets`);
    window.Core.setPDFWorkerPath(PDF_WORKER_PATH);
    window.Core.disableEmbeddedJavaScript(); // disabling pdf javascript

    is_web_viewer_core_ready.value = true;
  };

  const load_document = async (document_viewer_instance, doc_url, extra_options = {}) => {
    const { extension, load_as_pdf } = extra_options;
    has_document_loaded.value = false;
    current_progress.value = 0;
    document_viewer_instance.value?.closeDocument();
    const file = doc_url instanceof File ? new Uint8Array(await doc_url.arrayBuffer()) : doc_url;

    document_viewer_instance.value.loadDocument(file, {
      licenseKey: import.meta.env.VITE_APP_PDF_TRON_LICENSE_KEY,
      extension: extension || 'pdf',
      ...(typeof load_as_pdf === 'boolean' ? { loadAsPDF: load_as_pdf } : {}),
      onError: (err) => {
        err_msg.value = err.message ?? err;

        $toast({
          title: 'Failed to load document',
          text: 'An error occurred while loading the document. Please try again.',
          type: 'error',
          timeout: 4000,
        });
      },
      onLoadingProgress: (progress) => {
        current_progress.value = Math.round(progress * 100);
      },
    });
  };

  // PDFTron by default does not support stroke and fill opacity separately for annotations,
  // on each annotation modification apply the existing/updated opacity.
  const handle_custom_opacity = (annotation, action) => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();
    const custom_stroke_opacity = annotation.getCustomData('CustomStrokeOpacity');
    const custom_fill_opacity = annotation.getCustomData('CustomFillOpacity');

    set_text_annot_opacity(annotation, custom_stroke_opacity);

    if (['modify'].includes(action) && (custom_stroke_opacity || custom_fill_opacity)) {
      custom_stroke_opacity && (annotation.StrokeColor.A = Number(custom_stroke_opacity));
      custom_stroke_opacity && annotation.TextColor && (annotation.TextColor.A = Number(custom_stroke_opacity));
      custom_fill_opacity && annotation.FillColor && (annotation.FillColor.A = Number(custom_fill_opacity));
      annotation_manager.redrawAnnotation(annotation);
    }
  };

  function track_events(annotations, action, options) {
    if (action === 'add' && !options.imported)
      annotations.forEach((annotation) => {
        const freeHandType = {
          4: 'Brush Light',
          8: 'Brush Medium',
          12: 'Brush Heavy',
        };
        const measureToolNames = [
          'AnnotationCreateDistanceMeasurement',
          'AnnotationCreateAreaMeasurement',
        ];
        if (measureToolNames.includes(annotation.ToolName)) {
          track_event('perform_measurement', {
            type: annotation.Subject === 'Line' ? 'Length' : 'Area',
            calibration: false,
          });
        }
        else if (annotation.Subject !== 'Comment') {
          let type;
          if (annotation.ToolName === 'AnnotationCreateArrow')
            type = 'Line Arrow';
          else if (annotation.ToolName === 'AnnotationCreateFreeHand')
            type = freeHandType[annotation.StrokeThickness];
          else
            type = annotation.Subject;

          track_event('create_markup', {
            type,
            where: 'Plan',
          });
        }
      });
  }

  const on_annotation_change = () => {
    const annotation_manager = document_viewer_instance.value.getAnnotationManager();

    annotation_manager.addEventListener('annotationChanged', async (annotations, action, options) => {
      // Tracking events for annotations
      track_events(annotations, action, options);
      // Filter out custom annotations (Comment, Form and Task)
      const filtered_annotations = annotations.filter(annotation =>
        ![...Object.values(CUSTOM_ANNOTATION_TYPES), 'OCR'].includes(annotation.Subject),
      );
      const custom_annotations = annotations.filter(annotation =>
        Object.values(CUSTOM_ANNOTATION_TYPES).includes(annotation.Subject),
      );

      (custom_annotations.length) && handleCustomAnnotChange(custom_annotations, action, options);

      if (!filtered_annotations.length)
        return;

      filtered_annotations.forEach((annotation) => {
        (['Ellipse', 'Rectangle', 'FreeText'].includes(annotation.Subject)) && annotation.disableRotationControl();

        handle_custom_opacity(annotation, action);
      });

      if (options.imported)
        return;

      switch (action) {
        case 'add': {
          filtered_annotations.forEach((annotation) => {
            annotation.setCustomData('CustomStrokeOpacity', annotations_store.annotations_edit_config.outline_opacity / 100);
            annotation.setCustomData('CustomFillOpacity', annotations_store.annotations_edit_config.fill_opacity / 100);
            annotation.setAutoSizeType?.(window.Core.Annotations.FreeTextAnnotation.AutoSizeTypes.AUTO);
            annotation_manager.redrawAnnotation(annotation);
          });
          const annotation_xml_arr = await export_annotations(filtered_annotations, {
            widgets: false,
            links: false,
            fields: false,
            generateInlineAppearances: false,
          }, true);

          Promise.all(annotation_xml_arr.map(annotation_xml =>
            annotations_store.create_annotations([
              {
                annotationData: annotation_xml,
                name: 'ANNOTATION ROLEINTERNAL',
                targetElement: sheet_store.sheet?.element || element,
                version,
                type: 'shape',
              },
            ]),
          ));
          break;
        }
        case 'modify': {
          const annotationData = await export_annotations(filtered_annotations, {
            widgets: false,
            links: false,
            fields: false,
            generateInlineAppearances: false,
          }, true);

          const annot_map = {};

          annotationData.forEach((annot_xml) => {
            const annot_id = extractAttrFromXML(annot_xml, 'name');
            annot_map[annot_id] = annot_xml;
          });

          const annotations_body = annotations.map(annotation =>
            ({
              uid: annotations_store.annotations_map[annotation.Id].uid,
              annotationData: annot_map[annotation.Id],
            }),
          );

          annotations_store.update_annotations(annotations_body);
          break;
        }
        case 'delete': {
          annotations_store.delete_annotations(filtered_annotations);
          break;
        }
        default:
          break;
      }
    });
  };

  const setup_document_viewer = ({ extension, no_zoom, load_as_pdf }) => {
    const document_viewer = new window.Core.DocumentViewer();

    document_viewer.setScrollViewElement(scroll_view_elem.value);
    document_viewer.setViewerElement(viewer_elem.value);
    document_viewer.enableAnnotations();

    const { Annotations } = window.Core;
    const { SelectionModel, BoxSelectionModel } = Annotations;
    SelectionModel.setCustomHandlers(BoxSelectionModel, {
      // Hide selection outline only for stamp annotations (used for custom task, form and comment annotation)
      drawSelectionOutline(ctx, annotation, zoom, pageMatrix, { selectionModel, originalDrawSelectionOutline }) {
        if (!(annotation instanceof Annotations.StampAnnotation))
          originalDrawSelectionOutline(ctx, annotation, zoom, pageMatrix);
      },
    });

    document_viewer.addEventListener('documentLoaded', async () => {
      // call methods relating to the loaded document
      has_document_loaded.value = true;
      current_progress.value = 0;

      annotations_store.get_annotations.forEach(annotation => import_annotations(annotation.annotation_data));

      const document_tools = useDocumentTools(document_viewer_instance);
      document_tools.load_document = load_document;
      emit('onDocLoad', document_viewer, document_tools);
    });

    document_viewer_instance.value = document_viewer;
    load_document(document_viewer_instance, doc_url, { extension, load_as_pdf });

    on_annotation_change();
    if (!no_zoom)
      scroll_to_zoom();
    pan();
  };

  return {
    // states
    is_web_viewer_core_ready,
    has_document_loaded,
    err_msg,
    document_viewer_instance,
    current_progress,

    // methods
    setup_core,
    setup_document_viewer,
    load_document,
  };
}
