<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep } from 'lodash-es';
import { computed } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkModalTemplate from '~/common/components/hawk-modal/hawk-modal-template.vue';
import HawkSidebar from '~/common/components/organisms/hawk-sidebar.vue';
import ApprovalBlock from '~/forms/components/form-workflows/sidebar/approval-block/form-workflow-approval-block.vue';

import ConditionalBlock from '~/forms/components/form-workflows/sidebar/conditional-block/form-workflow-conditional-block.vue';
import DocumentGenerationBlock from '~/forms/components/form-workflows/sidebar/document-generate-block/form-workflow-document-generate-block.vue';
import EmailBlock from '~/forms/components/form-workflows/sidebar/email-block/form-workflow-email-block.vue';
import StepBlock from '~/forms/components/form-workflows/sidebar/form-block/form-workflow-step-block.vue';
import RollbackBlock from '~/forms/components/form-workflows/sidebar/form-workflow-rollback-block.vue';
import FeatureBlock from '~/forms/components/form-workflows/sidebar/form-workflow-update-feature-block.vue';
import SyncAssetBlock from '~/forms/components/form-workflows/sidebar/sync-asset-block/form-workflow-sync-asset-block.vue';
import UploadBlock from '~/forms/components/form-workflows/sidebar/upload-block/form-workflow-upload-block.vue';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  is_disabled: {
    type: Boolean,
    default: false,
  },
});
const $services = inject('$services');
const $t = inject('$t');
const $toast = inject('$toast');

const form_workflow_store = useFormWorkflowStore();
const auth_store = useAuthStore();
const route = useRoute();

const form = ref({});
const is_valid = ref(false);
const force_update = ref(0);

const is_sidebar_open = computed(() => {
  return (
    form_workflow_store.selected_node !== null
    && !form_workflow_store.selected_node?.model?.get('type').includes('AddButton')
  );
});
const block_data = computed(() => {
  return form_workflow_store.getBlockByNodeId[form_workflow_store.selected_node?.model?.id];
});
const parent_block_for_new_node = computed(() => {
  return form_workflow_store.getBlockByNodeId[form_workflow_store.new_node?.parent_id];
});
const get_step_number = computed(() => {
  if (form_workflow_store.new_node)
    return parent_block_for_new_node.value.step;

  return block_data.value?.step;
});
const is_update_feature = computed(() => {
  const block
          = form_workflow_store.getBlockByNodeId[form_workflow_store.selected_node?.model?.id]
          || form_workflow_store.new_node?.block;
  return block?.type === 'updateFeature';
});
const current_component = computed(() => {
  const block
          = form_workflow_store.getBlockByNodeId[form_workflow_store.selected_node?.model?.id]
          || form_workflow_store.new_node?.block;
  if (block) {
    const name_hash = {
      step: StepBlock,
      conditionalLogic: ConditionalBlock,
      approvalFlow: ApprovalBlock,
      sendEmail: EmailBlock,
      uploadFiles: UploadBlock,
      updateFeature: FeatureBlock,
      updateInstance: FeatureBlock,
      rollback: RollbackBlock,
      documentGeneration: DocumentGenerationBlock,
      syncAssetMetadata: SyncAssetBlock,
    };
    return name_hash[block.type];
  }
  return '';
});
const is_created = computed(() => {
  return !form_workflow_store.getBlockByNodeId[form_workflow_store.selected_node?.model?.id]?.uid;
});

function onConfirm(title_text, default_text, proceed_function) {
  const { open: openConfirm, close: closeConfirm } = useModal({
    component: HawkModalTemplate,
    attrs: {
      footer_buttons: [
        { color: 'primary', type: 'outlined', text: $t('Cancel'), class: 'mr-3', emit: 'close' },
        { color: 'primary', text: $t('Proceed'), emit: 'proceed' },
      ],
      onClose() {
        closeConfirm();
      },
      async onProceed() {
        await proceed_function();
        closeConfirm();
      },
    },
    slots: {
      title_text,
      default: default_text,
    },

  });
  openConfirm();
}

function onCancel() {
  if (form_workflow_store.selected_node?.model?.id === form_workflow_store.new_node?.model?.id) {
    const onProceed = async () => {
      const step = form_workflow_store.new_node?.form_step_index;
      await form_workflow_store.deleteNode({ block: null });
      if (step)
        await form_workflow_store.deleteStep(step);
    };
    onConfirm($t('Form Workflow'), $t('Progress would be lost, are you sure?'), onProceed);
  }
  else if (!props.is_disabled && form_workflow_store.is_sidebar_form_dirty) {
    const onProceed = () => {
      form_workflow_store.selected_node = null;
    };
    onConfirm($t('Form Workflow'), $t('Progress would be lost, are you sure?'), onProceed);
  }
  else {
    form_workflow_store.selected_node = null;
  }
}

watch(() => form_workflow_store.selected_node, (val, old_val) => {
  form_workflow_store.is_sidebar_form_dirty = false;
  if (val) {
    if (val?.model?.id !== old_val?.model?.id) {
      form.value = cloneDeep(
        form_workflow_store.getBlockByNodeId[form_workflow_store.selected_node?.model?.id]
          ?.properties || {},
      );
      force_update.value++;
    }
  }
  else {
    form.value = {};
    form_workflow_store.disable_outside_click = false;
  }
});

function createRemindersPayload(form_payload) {
  const reminders = form_payload?.reminders || {};
  return {
    is_once: reminders.is_once,
    when: reminders.when,
    frequency: reminders.frequency,
    interval: reminders.frequency || reminders.interval || 1,
    count: reminders.count || 1,
    from: reminders.from,
    is_enabled: reminders.is_enabled,
    email: reminders.email,
  };
}

async function createOrUpdate(form) {
  form_workflow_store.disable_outside_click = true;
  try {
    let payload = {};
    const form_payload = form;
    const reminders_payload = createRemindersPayload(form_payload);
    form_payload.reminders = reminders_payload;
    form_workflow_store.selected_node.model.attr('body', {
      stroke: '#E1E2E7',
      strokeDasharray: '0,0',
      strokeWidth: 1,
    });
    if (form_workflow_store.selected_node?.model?.id === form_workflow_store.new_node?.model?.id) {
      const parent_block = form_workflow_store.getBlockByNodeId[form_workflow_store.new_node.parent_id];
      if (['approvalFlow'].includes(form_workflow_store.new_node?.block.type)) {
        const { data } = await $services.common.post({
          url: 'approval-template',
          body: {
            ...form_payload,
            organization: auth_store.current_organization?.uid,
            resource_type: 'form',
            resource_uid: route.params.template_uid,
          },
        });
        form_payload.source_approval_workflow_uid = data.uid;
      }
      payload = {
        name: form_payload.name,
        color: form_payload.color || '',
        source: parent_block.uid,
        sourceOutcome:
                parent_block.type === 'conditionalLogic'
                  ? 'passed'
                  : (form_workflow_store.new_node.outcome || '').toLowerCase(),
        type: form_workflow_store.new_node.block.type,
        step: (form_workflow_store.new_node.block.type === 'step'
          ? form_payload.step
          : parent_block.step) || 1,
        ...(form_workflow_store.new_node.block.type === 'step' && {
          assignees: form_payload?.assignees,
          days: form_payload?.days,
          access_controls: form_payload?.access_controls,
        }),
        properties: {
          name: form_workflow_store.new_node.block.name,
          description: form_workflow_store.new_node.block.description,
          ...form_payload,

          ...(form_workflow_store.new_node.block.type === 'conditionalLogic' && {
            conditional_logic: form_payload?.conditional_logic || {},
          }),

          ...(parent_block.type === 'conditionalLogic' && {
            rule_id: form_workflow_store.new_node.rule_id,
          }),
          node_id: form_workflow_store.new_node.model.id,
        },
        initialBlock: false,
      };
    }
    else {
      const selected_block = form_workflow_store.getBlockByNodeId[
        form_workflow_store.selected_node?.model?.id
      ];
      const data = { ...selected_block.properties, ...form_payload, node_id: form_workflow_store.selected_node?.model?.id };
      payload = {
        name: data.name,
        color: form_payload.color || '',
        properties: data,
        type: selected_block.type,
        ...(selected_block.type === 'step' && {
          assignees: data?.assignees,
          days: data?.days,
          access_controls: data?.access_controls,
        }),
      };
      if (selected_block.type === 'approvalFlow') {
        await $services.common.patch(
          {
            url: `approval-template/${payload?.properties?.source_approval_workflow_uid}`,
            body: payload.properties,
          },
        );
      }
    }
    await form_workflow_store.saveAndUpdateNode({
      payload,
      data: {
        block_id: (form_workflow_store.getBlockByNodeId[form_workflow_store.selected_node?.model?.id] || {}).uid,
      },
    });
  }
  catch (error) {
    logger.log('Error:', error);
    if (error?.status === 400)
      $toast({ title: 'Error', text: (error?.data?.description || []).join(', ') || error?.data?.message || 'Something went wrong', type: 'error', timeout: 2000 });
    else
      $toast({ title: 'Error', text: 'Something went wrong', type: 'error' });
  }
  form_workflow_store.disable_outside_click = false;
}

watch(() => form_workflow_store.new_node, (val) => {
  if (val) {
    is_valid.value = false;
    form.value = {};
  }
});
</script>

<template>
  <HawkSidebar :show="is_sidebar_open" width="490" position="right" addition_classes="!top-[135px] !h-[calc(100vh-135px)] rounded-l-lg" :emit_close="false">
    <component
      :is="current_component"
      :key="force_update"
      :form="form"
      :rule_id_to_open="form_workflow_store.rule_id_to_open"
      :step_number="get_step_number"
      :is_update_feature="is_update_feature"
      :is_popup="false"
      :is_disabled="is_disabled"
      :block_create="is_created"
      :submit_function="createOrUpdate"
      :template_uid="form_workflow_store.workflow.form"
      @update-is-form-dirty="form_workflow_store.is_sidebar_form_dirty = $event"
      @close="onCancel"
    />
    <div v-if="is_disabled" class="flex justify-center">
      {{ $t('Unpublish to modify the workflow') }}
    </div>
  </HawkSidebar>
</template>
