<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  item: { type: Object, default: () => {} },
  warehouse_id: { type: String, default: '' },
});

const { $t, $services } = useCommonImports();
const columns = [
  {
    header: $t('Name'),
    accessorKey: 'name',
    id: 'name',
  },
  {
    header: $t('Scope'),
    accessorKey: 'scope',
    id: 'scope',
    size: 100,
  },
  {
    header: 'Available/used',
    accessorKey: 'bom_quantity',
    id: 'bom_quantity',
  },
  {
    header: $t('Status'),
    accessorKey: 'status',
    id: 'status',
  },
];

const state = reactive({
  is_loading: false,
  warehouses: [],
});

function generate_status(available, required) {
  const status = Math.sign(available - required);

  if (status === -1)
    return { text: `${Math.abs(available - required)} ${props.item?.uom || ''} less`, status };
  else if (status === 1)
    return { text: `${Math.abs(available - required)} ${props.item?.uom || ''} excess`, status };
  else if (status === 0 && required > 0 && available > 0)
    return { text: 'Exact material', status };
  else
    return { text: '-', status };
}

async function getStockInOtherWarehouses() {
  try {
    state.is_loading = true;

    const { data } = await $services.inventory_warehouses.get_stock_in_other_warehouses({
      id: props?.warehouse_id,
      item_id: props?.item?.uid,
    });

    const response = (data?.results || []);
    state.warehouses = response.map((item) => {
      return {
        ...item,
        status: generate_status(
          item.bom_quantity || 0,
          item.scope || 0,
        ),
      };
    });

    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
  }
}

onMounted(() => {
  getStockInOtherWarehouses();
});
</script>

<template>
  <div>
    <HawkLoader v-if="state.is_loading" />
    <div v-else-if="!state.warehouses?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No stock information found') }}
    </div>
    <div v-else>
      <div class="border border-gray-100 rounded-[8px] w-[670px] max-h-60 scrollbar">
        <HawkTable
          :data="state.warehouses"
          :columns="columns"
          :default_height="300"
          is_gapless
          cell_height="30px"
          disable_resize
          :show_menu_header="false"
        >
          <template #name="{ data }">
            <div class="flex flex-col font-medium">
              <div class="text-xs text-gray-600 mb-1">
                #{{ data.row.original.number }}
              </div>
              <div class="text-sm text-gray-900">
                {{ data.getValue() }}
              </div>
            </div>
          </template>
          <template #scope="{ data }">
            <div class="text-sm text-gray-600">
              {{ data.getValue() }}
            </div>
          </template>
          <template #available_qty="{ data }">
            <div class="text-sm text-gray-600">
              {{ data.getValue() }}
            </div>
          </template>
          <template #status="{ data }">
            <div class="flex items-center justify-between w-full">
              <div class="text-sm text-gray-600 flex-1" :class="[{ 'text-red-700': data.getValue().status === -1 }]">
                {{ data.getValue()?.text }}
              </div>
            </div>
          </template>
        </HawkTable>
      </div>
    </div>
  </div>
</template>
