<script setup>
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  on_save: {
    type: Function,
  },
});

const { getValidationRulesForPropertyKey } = useTerraHelperComposable();
const { checkPropertyKey } = getValidationRulesForPropertyKey();
const $t = inject('$t');
const form = ref({
  name: props.name || '',
});

async function handleSave() {
  if (form.value.name)
    await props.on_save(form.value.name);
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[400px]">
    <Vueform v-model="form" size="sm" sync :display-errors="false" :columns="{ lg: { container: 12, label: 4, wrapper: 12 } }" :endpoint="handleSave">
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('cancel')">
          <template #title>
            {{ name.length ? $t('Rename field') : $t('Add field') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement name="name" :label="$t('Name')" class="mb-4" :rules="['required', checkPropertyKey]" @keypress.enter.stop="" />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <hawk-button class="mr-5" type="outlined" @click="$emit('cancel')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                :disabled="name.length ? form.name === name : !form.name.length"
                :button-label="$t('Save')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
