<template>
  <div class="h-[calc(100vh-64px)]">
    <iframe
      width="100%"
      height="100%"
      title="google sheet"
      src="https://docs.google.com/spreadsheets/d/1zd-USaJBI05s4Gt---KZr8QSI7Era-VdUuht1Pi-xjk/edit?rm=minimal#gid=374560100"
    />
  </div>
</template>
