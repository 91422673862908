<script setup>
import { useRoute } from 'vue-router';

// composables
import { useDocumentPermissions } from '~/dms/composables/document-permissions.composable';

// components
import DocumentVersionMenu from '~/dms/components/documents/versions/document-versions-menu.vue';

const props = defineProps({
  versions: {
    type: Array,
    required: true,
  },
  file: {
    type: Object,
    required: true,
  },
  is_popup: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const route = useRoute();
const document_permissions = useDocumentPermissions();

const hovered_index = ref(null);
</script>

<template>
  <div
    v-for="(version_item, index) in versions"
    :key="version_item.version"
    class="cursor-pointer p-3 hover:bg-gray-50"
    @mouseover="hovered_index = index"
    @mouseout="hovered_index = null"
  >
    <div :class="{ 'flex justify-between items-center': props.is_popup }">
      <div :class="{ 'flex flex-col': props.is_popup }">
        <div class="text-sm font-medium text-gray-900 mb-1 flex items-center justify-between h-4">
          <div class="flex items-center">
            <span
              v-tippy="(version_item.version || version_item.name).length > 24 ? (version_item.version || version_item.name) : ''"
              class="font-semibold text-gray-900 text-sm mr-2 max-w-[150px] truncate inline-block"
            >
              {{ version_item.version ? (version_item.version === 'base' ? version_item.name : version_item.version) : version_item.name }}
            </span>
            <hawk-badge v-if="version_item.uid === file.defaultversion && !is_popup" color="green">
              {{ $t('Current Version') }}
            </hawk-badge>
          </div>
          <DocumentVersionMenu
            v-if="document_permissions.checkDocumentPermission({ permission: 'versions', item: props.file })"
            v-show="!is_popup ? hovered_index === index : false"
            :item="version_item"
            :file="file.uid"
            :is_current="version_item.uid === file.defaultversion"
          >
            <template #trigger>
              <div v-if="is_popup" class="border rounded-lg w-8 h-8 flex justify-center items-center focus:ring-gray-100">
                <IconHawkDotsVertical class="h-4 w-4" />
              </div>
              <IconHawkDotsVertical v-else class="mt-1.5 h-4 w-4" />
            </template>
          </DocumentVersionMenu>
        </div>
        <div class="text-xs font-normal text-gray-600 flex items-center">
          <HawkMembers :members="version_item?.created_by?.uid || file.owner" type="badge" />
          <span class="text-gray-600 ml-1">
            {{
              `${version_item.uploaded_at
                ? version_item.uploaded_at.includes('+', version_item.uploaded_at.indexOf('+') + 1)
                  ? $date(version_item.uploaded_at.substring(0, version_item.uploaded_at.length - 6), 'L_DATETIME_MED')
                  : $date(version_item.uploaded_at, 'L_DATETIME_MED')
                : $date(version_item.updated_at, 'L_DATETIME_MED')}${version_item.file_size ? `, ${$prettysize(version_item.file_size)}` : ''}`
            }}
          </span>
        </div>
        <div class="text-xs font-medium text-gray-600 flex items-center mt-1">
          {{ version_item.description }}
        </div>
      </div>
      <div v-if="is_popup" class="flex items-center">
        <hawk-badge v-if="version_item.uid === file.defaultversion" color="green" class="mr-4">
          {{ $t('Current Version') }}
        </hawk-badge>
        <DocumentVersionMenu
          v-if="document_permissions.checkDocumentPermission({ permission: 'versions', item: props.file })"
          :item="version_item"
          :file="file.uid"
          :is_current="version_item.uid === file.defaultversion"
          position="fixed"
        >
          <template #trigger>
            <div v-if="is_popup" class="border rounded-lg w-8 h-8 flex justify-center items-center">
              <IconHawkDotsVertical class="h-4 w-4" />
            </div>
            <IconHawkDotsVertical v-else class="mt-1.5 h-4 w-4" />
          </template>
        </DocumentVersionMenu>
      </div>
    </div>
  </div>
</template>
