import { keyBy, take } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

function getConfig() {
  const auth_store = useAuthStore();
  const form_template_detail_store = useFormTemplateDetailStore();

  const status_options = form_template_detail_store.field_filters_map.status.options;
  const number_widgets = take(status_options, 4).map((item, index) => {
    return {
      x: 3 * index,
      y: 0,
      w: 3,
      h: 5,
      i: index + 1,
      source: {
        type: 'forms',
        service: 'forms',
      },
      type: 'number_chart',
      uid: crypto.randomUUID(),
      data: {
        ongoing: true,
        properties: {
          source: {
            type: 'forms',
            service: 'forms',
          },
          config: {},
          prettify_values: false,
          inverse_trend: false,
          name: item.name,
        },
        filters: {
          organization: auth_store?.current_organization.uid,

          form_id: form_template_detail_store.form_template_detail.uid,
          advanced_filter: {
            logic: {
              type: 'and',
            },
            rules: [
              {
                field: 'status',
                operator: 'isAnyOf',
                value: [
                  item.uid,
                ],
                name: 'Status',
                type: 'dropdown',
                fact: 'status',
              },
            ],
          },
        },
        x: {
          key: 'createdAt',
          label: 'Created At',
          type: 'date',
          extract: false,
          value: 'createdAt',
          timerange: {
            type: 'all',
            range: [],
            interval: 'daily',
          },
          displayPreviousValue: false,
        },
        y: {
          key: 'forms_count',
          label: 'Count of forms',
          type: 'number',
          extract: 'false',
          agg: 'count',
          value: 'forms_count',
        },
        chart: 'number_chart',
        scope: 'organization',
        minW: 4,
        agg: {
          key: 'count',
          label: 'count',
        },
      },
      moved: false,

    };
  });

  const list_widget = {
    x: 0,
    y: 6,
    w: 12,
    h: 17,
    i: 5,
    source: {
      type: 'forms',
      service: 'forms',
    },
    type: 'forms_list_with_tabs',
    uid: crypto.randomUUID(),
    data: {
      ongoing: false,
      properties: {
        source: {
          type: 'forms',
          service: 'forms',
        },
        config: {},
        columns_widths: {},
        name: form_template_detail_store?.form_template_detail?.plural_name || form_template_detail_store?.form_template_detail?.name,
      },
      filters: {
        organization: auth_store?.current_organization.uid,

        form_id: form_template_detail_store.form_template_detail.uid,
      },
      chart: 'forms_list_with_tabs',
      scope: 'organization',
      minW: 4,
      tabs: [
        {
          data: {
            uid: crypto.randomUUID(),
            name: 'Overdue',
            filter: {
              condition: {
                logic: 'AND',
                rules: [
                  {
                    field: 'due_date',
                    operator: 'isBefore',
                    value: [
                      new Date().toISOString(),
                    ],
                    operator_option: 'today',
                    name: 'Due Date',
                    type: 'date',
                    fact: 'due_date',
                  },
                ],
              },
            },
            order_index: 1,
          },
          name: 'Overdue',
          service: 'forms',
          feature: 'widget_view',
          resource_id: form_template_detail_store.form_template_detail.uid,
          resource_type: 'template',
          uid: crypto.randomUUID(),
        },
        {
          data: {
            uid: crypto.randomUUID(),
            name: 'Waiting for review',
            filter: {
              condition: {
                logic: 'AND',
                rules: [
                  {
                    field: 'waiting_for_approval',
                    operator: 'isEqualTo',
                    value: true,
                    name: 'Waiting For Approval',
                    type: 'boolean',
                    fact: 'waiting_for_approval',
                  },
                ],
              },
            },
            order_index: 2,
          },
          name: 'Waiting for review',
          service: 'forms',
          feature: 'widget_view',
          resource_id: form_template_detail_store.form_template_detail.uid,
          resource_type: 'template',
          uid: crypto.randomUUID(),
          created_by: auth_store.logged_in_user_id,
        },
        {
          data: {
            uid: crypto.randomUUID(),
            name: 'My requests',
            filter: {
              condition: {
                logic: 'AND',
                rules: [
                  {
                    field: 'created_by',
                    operator: 'isAnyOf',
                    value: [
                      auth_store.logged_in_user_id,
                    ],
                    name: 'Created By',
                    type: 'member',
                    fact: 'created_by',
                  },
                ],
              },
            },
            order_index: 3,
          },
          name: 'My requests',
          service: 'forms',
          feature: 'widget_view',
          resource_id: form_template_detail_store.form_template_detail.uid,
          resource_type: 'template',
          uid: crypto.randomUUID(),
          created_by: auth_store.logged_in_user_id,
        },
      ],
      type: 'forms_list_with_tabs',
    },
    moved: false,
  };

  const dashboard_config = keyBy([...number_widgets, list_widget], 'uid');
  return dashboard_config;
}

export default getConfig;
