<script setup>
import { useElementSize } from '@vueuse/core';
import FormsListWidget from '~/dashboard/components/widgets/form-widgets/form-list.widget.vue';

const props = defineProps({
  count: {
    type: Number,
    default: 0,
  },
  name: {
    type: String,
    default: 'Untitled',
  },
  id: {},
  payload: {
    type: Object,
    default: () => {},
  },
  popup_query: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);

const form_widget_content = ref(null);
const is_loading = ref(false);
const { height } = useElementSize(form_widget_content);
</script>

<template>
  <HawkModalTemplate
    :disable_footer="true"
    content_class="h-[80vh] rounded-lg w-[50vw]"
    @close="emit('close')"
  >
    <template #header_left>
      <div class="flex flex-col justify-start">
        {{ props.name }}
      </div>
    </template>
    <template #default>
      <div ref="form_widget_content">
        <HawkLoader v-if="is_loading" />
        <FormsListWidget
          v-show="!is_loading"
          :id="id"
          :data="props.payload"
          :extra_query="{
            scope: props.payload?.scope,
            is_child: true,
            ...props.payload?.filters,
            ...props.popup_query,
          }"
          :content_height="height"
          is_modal
          class="h-[calc(80vh-121px)] scrollbar"
          @loading="is_loading = $event"
        />
      </div>
    </template>
  </HawkModalTemplate>
</template>
