export default {
  methods: {
    get_activities(req = {}) {
      logger.log({ req });
      return {
        url: `activities/${req.module}/${req.resource_type}/${req.resource_uid
          }/`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
      };
    },
    get_activities_feed(req = {}) {
      return {
        url: `activities/feeds/${req.feed_id}`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      };
    },
    get_audit_logs(req = {}) {
      return {
        url: 'activities/logs/',
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      };
    },
    get_notifications(req = {}) {
      return {
        url: `/activities/notifications/${req.user}/`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
      };
    },
    clear_notifications(req = {}) {
      return {
        url: `/activities/notifications/${req.user}/`,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        data: req.body,
        method: 'POST',
      };
    },
  },
};
