<script setup>
import { storeToRefs } from 'pinia';
import { useI18nStore } from '~/common/stores/i18n.store.js';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import PmGeneralTab from '~/project-management/components/activity-details/pm-general-tab.vue';
import PmDependenciesTab from '~/project-management/components/activity-details/pm-dependencies-tab.vue';
import PmReferencesTab from '~/project-management/components/activity-details/pm-references-tab.vue';
import PmCommentsTab from '~/project-management/components/activity-details/pm-comments-tab.vue';
import PmProgressHistoryTab from '~/project-management/components/activity-details/pm-progress-history-tab.vue';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const i18n_store = useI18nStore();
const common_store = useCommonStore();
const { active_task } = storeToRefs(project_management_store);
const { current_lang } = storeToRefs(i18n_store);
const { set_active_task_uid } = project_management_store;

const state = reactive({
  active_tab_item: 'general',
});

const tabs_items = computed(() => [
  { uid: 'general', label: $t('General'), count: 0, component: PmGeneralTab },
  { uid: 'dependencies', label: $t('Dependencies'), count: 0, component: PmDependenciesTab },
  { uid: 'references', label: $t('References'), count: 0, component: PmReferencesTab },
  { uid: 'comments', label: $t('Comments'), count: 0, component: PmCommentsTab },
  { uid: 'progress_history', label: $t('Progress history'), count: 0, component: PmProgressHistoryTab },
]);

const current_tab = computed(() => {
  return tabs_items.value?.find(tab => tab.uid === state.active_tab_item)?.component || PmGeneralTab;
});

function closeDetails() {
  set_active_task_uid(null);
  state.active_tab_item = 'general';
}
</script>

<template>
  <div
    v-if="active_task"
    :key="`${active_task.uid} ${current_lang}`"
    class="h-[40vh] min-h-[40vh] bg-white p-4 border border-t-2 rounded-b-xl border-solid border-gray-300 scrollbar !overflow-x-hidden"
  >
    <hr class="border-b border-gray-500 mb-4 -mt-4 -ml-4 -mr-8">
    <div class="flex mb-4">
      <HawkTabs
        :tabs="tabs_items"
        :active_item="state.active_tab_item"
        @tab-click="state.active_tab_item = $event.uid"
      />
      <HawkButton icon type="text" class="ml-auto" @click="closeDetails">
        <IconHawkXClose class="text-gray-500" />
      </HawkButton>
    </div>
    <component :is="current_tab" @resetTab="state.active_tab_item = 'general'" />
  </div>
</template>
