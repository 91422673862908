<script setup>
const props = defineProps({
  placeholder: {
    type: String,
    default: 'Add item',
  },
  name: {
    type: String,
    default: '',
  },
  input_classes: {
    type: Object,
    default: () => {
      return { input: 'text-sm' };
    },
  },
  allow_multiple: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close', 'add']);

function addSubItem(e) {
  if (props.allow_multiple) {
    const items = e.map((name) => {
      return {
        uid: crypto.randomUUID(),
        name,
      };
    });
    emit('add', items);
    return;
  }
  emit('add', { uid: crypto.randomUUID(), name: e });
}
</script>

<template>
  <div>
    <hawk-editable-name
      :allow_multiple="allow_multiple"
      :name="props.name"
      :placeholder="props.placeholder"
      :input_classes="input_classes"
      @update="addSubItem"
      @close="emit('close')"
    />
  </div>
</template>
