<script setup>
import { computed, inject, reactive } from 'vue';
import { isNull, isUndefined } from 'lodash-es';
import { FIELDS_PROPERTY, FIELDS_TYPE, uniqueName } from '~/system-model/utils/helper';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  config_field: {
    type: Object,
    default: null,
  },
  unique_fields: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['close', 'submit', 'deleteField']);
const $t = inject('$t');
const system_model_store = useSystemModelStore();
const auth_store = useAuthStore();

const state = reactive({
  is_loading: false,
  currencyToSymbolMap: [],
});

const active_component = computed(() => system_model_store.active_component);
const active_instance = computed(() => system_model_store.active_instance);
const active_tab = computed(() => system_model_store.active_tab);

const field_name_rules = computed(() => {
  const rules = ['required'];
  if (props.unique_fields?.length)
    rules.push(uniqueName(props.unique_fields, 'This field is already exist.'));
  return rules;
});

async function updateConfigField(form_data) {
  const body = {
    name: form_data.field_name,
    organization: auth_store.current_organization?.uid,
    properties: props.config_field.properties,
    type: form_data.type,
  };
  await system_model_store.update_field({
    field_id: props.config_field.uid,
    body,
    property: getCurrentPage(),
  });
}

async function addConfigField(form_data) {
  const field_property_type = FIELDS_PROPERTY[form_data.field_type];
  const body = {
    name: form_data.field_name,
    type: field_property_type?.type || form_data.field_type,
    // organization: current_organization?.uid,
    ...(!isNull(form_data.options_list[0])
      ? {
          config: form_data.options_list.map(option => ({
            name: option,
            color: '#000',
          })),
        }
      : {}),
  };

  if (active_instance.value || active_tab.value === 'SmInstances') {
    body.component = active_component.value.uid;
  }
  else if (!form_data.record_self_values && !isUndefined(form_data.record_self_values)) {
    body.component = active_component.value.uid;
    body.record_self_values = true;
  }
  else {
    body.category = active_component.value.category;
  }

  if (field_property_type) {
    body.properties = field_property_type.properties;
    if (form_data.field_type === 'money')
      body.properties.currency = form_data.currency_symbol;
  }
  await system_model_store.create_field({
    body,
    property: getCurrentPage(),
  });
}
async function deleteConfigField() {
  emit('deleteField', props.config_field?.uid, props.config_field?.record_self_values);
  emit('close');
}

async function submitHandler(form$) {
  try {
    state.is_loading = true;
    const form_data = form$.data;
    if (props.config_field)
      await updateConfigField(form_data);

    else
      await addConfigField(form_data);
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}
function formMounted(el$) {
  if (props.config_field) {
    const { type, name, properties } = props.config_field;
    let field_type = type;
    if (properties?.currency)
      field_type = 'money';
    else if (properties?.checkbox)
      field_type = 'checkbox';
    else if (properties?.radio)
      field_type = 'radio';
    el$.update({
      field_name: name,
      field_type,
    });
  }
}

function getCurrentPage() {
  return (active_instance.value || active_tab.value === 'SmInstances') ? 'instance' : 'component';
}

function getCategoryName(uid) {
  return system_model_store.categories_map[uid]?.name || '';
}

async function getCurrencyToSymbolMap() {
  const { default: currencyToSymbolMap } = await import('currency-symbol-map/map');
  state.currencyToSymbolMap = Object.keys(currencyToSymbolMap).map(code => ({
    label: code,
    value: code,
  }));
};
onMounted(async () => {
  await getCurrencyToSymbolMap();
});
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @mounted="formMounted"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ config_field ? $t('Update Field') : $t('New Field') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <TextElement
            v-bind="{
              label: `${$t('Field Name')}`,
              name: 'field_name',
              placeholder: 'Enter field name',
              rules: field_name_rules,
              class: 'mb-4',
            }"
          />
          <SelectElement
            v-bind="{
              name: 'field_type',
              items: FIELDS_TYPE,
              search: true,
              native: false,
              label: $t('Field Type'),
              placeholder: 'Select type',
              autocomplete: 'off',
              inputType: 'search',
              rules: ['required'],
              class: 'mb-4',
              canClear: false,
              disabled: !!config_field,
            }"
          />
          <ListElement
            v-if="!config_field"
            name="options_list"
            :add-text="`+ ${$t('Add Option')}`"
            label="Options"
            :sort="true"
            :presets="['repeatable_list', 'repeatable_drag_icon']"
            :min="1"
            :conditions="[
              ['field_type', 'in', ['checkbox', 'radio', 'labels', 'select', 'dropdown']],
            ]"
          >
            <template #default="{ index }">
              <TextElement
                :name="index"
                :rules="['required']"
                :messages="{
                  required: 'This field is required.',
                }"
                :columns="{
                  default: { container: 11, label: 12, wrapper: 12 },
                  sm: { container: 11, label: 12, wrapper: 12 },
                  md: { container: 11, label: 12, wrapper: 12 },
                }"
              />
            </template>
          </ListElement>
          <SelectElement
            v-if="!config_field"
            v-bind="{
              name: 'currency_symbol',
              search: true,
              label: 'Currency',
              default: 'AED',
              rules: ['required'],
              items: state.currencyToSymbolMap,
              native: false,
              conditions: [['field_type', 'in', ['money']]],
            }
            "
          />

          <CheckboxElement
            v-if="active_tab === 'SmComponentDetails' && !config_field"
            name="record_self_values"
            class="mt-4"
            size="md"
            default="true"
          >
            <template #label>
              <p class="text-sm">
                Record for all <span class="font-semibold"> {{ getCategoryName(active_component.category) }}</span> components
              </p>
            </template>
          </CheckboxElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-between">
              <div>
                <hawkButton v-if="config_field" color="error" type="plain" class="font-semibold" @click="deleteConfigField">
                  {{ $t('Delete') }}
                </hawkButton>
              </div>
              <div class="flex">
                <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                  {{ $t('Cancel') }}
                </hawk-button>
                <ButtonElement
                  submits
                  size="sm"
                  name="submit"
                  :button-label="$t('Save')"
                  :loading="state.is_loading"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
