import { markRaw } from 'vue';
import { useRoute } from 'vue-router';

import { useModal } from 'vue-final-modal';

import { services } from '~/common/installers/services';
import { $toast } from '~/common/utils/common.utils';

import { useAuthStore } from '~/auth/stores/auth.store';

import DependencyWarning from '~/tasks/components/organisms/dependency-warning.vue';

export async function bulkTasksExporter(uids, exportFailed = () => {}, custom_payload = {}, asset_id = null) {
  const route = useRoute();
  const $services = markRaw(services);
  try {
    const { data: resourceURL } = await $services.tasks.get_custom_report({
      body: {
        taskUids: uids,
        asset: asset_id || route.params.asset_id || null,
        ...custom_payload,
      },
    });
    const link = document.createElement('a');
    link.href = resourceURL;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  catch (err) {
    $toast({
      title: 'Couldn\'t download PDF. Please try again',
      type: 'error',
    });
    exportFailed();
  }
}

export function handleDependencyWarning(task_store, task, payload, update_function, on_close = () => {}) {
  const { open: openDependencyWarning, close: closeDependencyWarning, patchOptions: patchDependencyWarning } = useModal({
    component: DependencyWarning,
  });

  patchDependencyWarning({
    attrs: {
      task_store,
      task,
      payload,
      update_function,
      onClose() {
        on_close();
        closeDependencyWarning();
      },
    },
  });
  openDependencyWarning();
}

export function getDurationAndPeriod(duration) {
  if (duration % 365 === 0)
    return { value: duration / 365, period: 'Years' };
  else if (duration % 30 === 0)
    return { value: duration / 30, period: 'Months' };
  else if (duration % 7 === 0)
    return { value: duration / 7, period: 'Weeks' };
  else
    return { value: duration, period: 'Days' };
}

export function useTasksPermissions() {
  const route = useRoute();
  const asset_id = route.params.asset_id;

  const { check_permission } = useAuthStore();

  function checkTaskPermission({ permission = '', instance = {} }) {
    const asset = asset_id || instance?.target_element?.asset;

    switch (permission) {
      case 'can_modify':
        return instance?.is_template ? check_permission('modify_task_templates', asset) : instance?.can_modify;

      case 'can_modify_status':
        return instance?.can_modify_status;

      case 'can_close':
        return instance?.can_close;

      case 'can_create_tasks':
        return check_permission('create_tasks', asset);

      case 'can_modify_templates':
        return check_permission('modify_task_templates', asset);

      case 'can_add_reminders':
        return instance?.can_modify && [1, 2].includes(instance?.status) && (instance?.start_date || instance?.due_date);
    }
  }

  function checkStatusUpdatePermission(task, status) {
    if (status === 4 || status === 5)
      return checkTaskPermission({ permission: 'can_close', instance: task });

    return checkTaskPermission({ permission: 'can_modify_status', instance: task });
  }
  return { checkStatusUpdatePermission, checkTaskPermission };
}
