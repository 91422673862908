import { useModal } from 'vue-final-modal';
import { useThermStore } from '../store/therm.store';
import { useThermHelperComposable } from './helper-composable';
import TaskFilters from '~/therm/components/therm-filters.vue';
import { useTasksStore } from '~/tasks/store/tasks.store';

const task_filter_popup = useModal({
  component: TaskFilters,
  attrs: {
    filter_type: 'therm',
    fields: ['Status', 'Priority', 'Category', 'Assignees', 'Created By', 'Due Date', 'Starred', 'Archived', 'Ignore above filters for defects without tasks', 'Include Feature Types', 'Temperature difference'],
    onClose() {
      task_filter_popup.close();
    },
  },
});

export function useThermTaskFilters() {
  const therm_store = useThermStore();
  task_filter_popup.patchOptions({
    attrs: {
      filters: therm_store.filters,
      task_store: useTasksStore('therm_tasks_store'),
      therm_options: {
        feature_types: [{ name: 'All', id: 'all' }, ...Object.values(therm_store.feature_types).map(val => ({ id: val.id, name: val.name }))],
      },
      async onFilter(filters) {
        const { addSymbols } = useThermHelperComposable();
        therm_store.set_filters({ ...filters });
        const features = therm_store.features;
        therm_store.update_map_features({
          features,
          set_polygon: true,
        });
        task_filter_popup.close();
        await therm_store.fetch_therm_tasks();
        therm_store.defect_table_instance?.resetPagination?.();
        addSymbols();
      },
    },
  });
  task_filter_popup.open();
}
