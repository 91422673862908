<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import NotificationCard from '~/notifications/pages/notification-card.vue';

const $services = inject('$services');
const auth_store = useAuthStore();

const notifications = ref([]);
const loading = ref(false);

async function getNotifications() {
  try {
    loading.value = true;
    const { data } = await $services.users.get_activities({
      id: auth_store.logged_in_user_details.user_id,
      query: {
        type: 'notification',
        rule: '$feed',
        numActivities: 40,
      },
    });
    notifications.value = data;
    loading.value = false;
  }
  catch (error) {
    loading.value = false;
    logger.log('error', error);
  }
}

onMounted(() => {
  getNotifications();
});
</script>

<template>
  <HawkLoader v-if="loading" />
  <div v-else class="p-4 max-w-[800px] mx-auto">
    <div class="flex items-center justify-between mb-2">
      <div class="text-lg font-semibold">
        Notification
      </div>
      <HawkButton type="link">
        Clear all
      </HawkButton>
    </div>
    <div v-for="item in notifications" :key="item.id" class="border">
      <NotificationCard :item="item" />
    </div>
  </div>
</template>
