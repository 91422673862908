<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
  options: {
    type: Object,
  },
  tag_class: {
    type: String,
    default: 'max-w-[200px]',
  },
  multi: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const common_store = useCommonStore();

const roles_data = computed(() => {
  const asset_id = props?.asset_id ? props.asset_id : route.params.asset_id;
  // Using scoped members
  let roles = common_store.scope_roles(asset_id)?.map((role) => {
    return {
      name: role.name,
      uid: role.uid,
    };
  });
  if (props?.options?.existing_roles)
    roles = roles.filter(item => !props?.options?.existing_roles.includes(item?.uid));

  return roles;
});
</script>

<template>
  <TagsElement
    v-if="multi"
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': false,
      'groups': false,
      'object': true,
      'group-select': false,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': roles_data,
      ...options,
    }"
  >
    <template #option="{ option }">
      <HawkRoleName :uid="option.uid" />
    </template>
    <template #tag="{ option, handleTagRemove }">
      <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
        <HawkRoleName :uid="option?.uid ? option.uid : option" class="text-gray-700 font-medium" />
        <div class="hover:bg-gray-100 rounded ml-1">
          <IconHawkXClose class="text-gray-400 w-4 h-4" @mousedown.prevent="handleTagRemove(option, $event)" />
        </div>
      </div>
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
  </TagsElement>
  <SelectElement
    v-else
    v-bind="{
      'autocomplete': 'off',
      'search': true,
      'close-on-select': true,
      'groups': true,
      'group-hide-empty': true,
      'hide-selected': true,
      'object': true,
      'label-prop': 'name',
      'value-prop': 'uid',
      'track-by': 'name',
      'items': roles_data,
      ...options,
    }"
  >
    <template #option="{ option }">
      <p class="text-gray-700">
        {{ option.name }}
      </p>
    </template>
    <template #single-label="{ value }">
      <div class="flex items-center h-full absolute left-0 pl-3">
        {{ value.name }}
      </div>
    </template>
  </SelectElement>
</template>
