<script setup>
import { useModal } from 'vue-final-modal';

import { useChecklistStore } from '~/tasks/store/checklists.store';

import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

import ChecklistItem from '~/common/components/organisms/hawk-checklist-fields/checklist-item.vue';
import ChecklistInput from '~/common/components/organisms/hawk-checklist-fields/checklist-input.vue';
import ChecklistFormSkeleton from '~/tasks/pages/task-details-new/left-section/task-checklists/checklist-form-skeleton.vue';

import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

const props = defineProps({
  checklist: {
    type: Object,
    required: true,
  },
  can_modify: {
    type: Boolean,
    required: true,
  },
  checklist_length: {
    type: Number,
  },
});

const emit = defineEmits(['moveUp', 'moveDown', 'deleted', 'taskChecklistDeleted']);

const $t = inject('$t');
const $toast = inject('$toast');
const { task_uid } = inject('task-uid', {});
const is_checklist_template = inject('is-checklist-template', false);

const checklist_store = useChecklistStore();
const checklist_template_store = useChecklistStore('checklist-templates');

const { checkTaskPermission } = useTasksPermissions();

const collapse = ref(false);
const addItem = ref(false);
const open_dropdown = ref(null);

const checklist = computed({
  get() {
    return props.checklist;
  },
  set(value) {
    is_checklist_template ? checklist_store.update_template(value) : checklist_store.update_checklist(task_uid.value, value);
  },
});
const checklist_menu = computed(() => {
  if (is_checklist_template)
    return [
      {
        label: `${$t('Rename Checklist')}`,
        on_click: () => renameChecklistHandler(),
        disabled: !props.can_modify,
      },
      {
        label: `${$t('Delete')}`,
        on_click: () => checklistDeleteHandler(),
        disabled: !props.can_modify,
      },
    ];
  return [
    {
      label: `${$t('Rename Checklist')}`,
      on_click: () => renameChecklistHandler(),
      disabled: !props.can_modify,
    },
    {
      label: `${$t('Move up')}`,
      on_click: () => emit('moveUp'),
      disabled: props.checklist.order_index === 0,
    },
    {
      label: `${$t('Move down')}`,
      on_click: () => emit('moveDown'),
      disabled: props.checklist.order_index === props.checklist_length - 1,
    },
    {
      label: `${$t('Duplicate')}`,
      on_click: () => duplicateChecklistHandler(),
      disabled: !props.can_modify,
    },
    {
      label: `${$t('Save as template')}`,
      on_click: () => saveAsTemplateHandler(),
      disabled: !checkTaskPermission({ permission: 'can_modify_templates' }),
    },
    {
      label: `${$t('Check all items')}`,
      on_click: () => checkAllItems(),
    },
    {
      label: `${$t('Delete')}`,
      on_click: () => checklistDeleteHandler(),
      disabled: !props.can_modify,
    },
  ];
});

onMounted(async () => {
  if (checklist.value.attachments_count && is_checklist_template)
    await checklist_template_store.get_checklist_template_attachments(checklist.value.uid);
});

async function addChecklistItems(children) {
  checklist.value.items = [...checklist.value.items, ...children];
  is_checklist_template ? checklist_store.update_template(checklist.value) : checklist_store.update_checklist(task_uid.value, checklist.value);
}

function update(items) {
  checklist.value.items = items;
  is_checklist_template ? checklist_store.update_template(checklist.value) : checklist_store.update_checklist(task_uid.value, checklist.value);
}

function resolveChildren(children) {
  children.map((item) => {
    item.status = 'resolved';
    if (item.children)
      resolveChildren(item.children);
    return item;
  });
  return children;
}

function checkAllItems() {
  checklist.value.items.map((item) => {
    item.status = 'resolved';
    if (item.children)
      resolveChildren(item.children);
    return item;
  });
  checklist_store.update_checklist(task_uid.value, checklist.value);
}

const { open: openChecklistFormSkeleton, close: closeChecklistFormSkeleton, patchOptions: patchSkeletonOptions } = useModal({
  component: ChecklistFormSkeleton,
});
function saveAsTemplateHandler() {
  patchSkeletonOptions(
    {
      attrs: {
        type: 'Save as template',
        checklist: checklist.value,
        onClose() {
          closeChecklistFormSkeleton();
        },
        async on_submit(data) {
          try {
            if (data.template_uid) {
              const payload = {
                action: 'save_template',
                checklist_uid: data.checklist_uid,
                include: {
                  assignee: true,
                  attachments: true,
                },
                template_uid: data.template_uid,
              };
              await checklist_template_store.update_checklist_template(task_uid.value, [payload]);
              $toast({
                title: 'Template updated sucessfully',
                text: 'Your changes have been saved and your template is updated',
                type: 'success',
                position: 'bottom-right',
              });
            }
            else {
              const payload = {
                action: 'save_template',
                checklist_uid: data.checklist_uid,
                include: {
                  assignee: true,
                  attachments: true,
                },
                name: data.name,
              };
              await checklist_template_store.update_checklist_template(task_uid.value, [payload]);
              $toast({
                title: 'Template created sucessfully',
                text: 'Your changes have been saved and your template is created',
                type: 'success',
                position: 'bottom-right',
              });
            }
          }
          catch (error) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openChecklistFormSkeleton();
}
function renameChecklistHandler() {
  patchSkeletonOptions(
    {
      attrs: {
        type: 'Rename Checklist',
        checklist: checklist.value,
        onClose() {
          closeChecklistFormSkeleton();
        },
        on_submit(data) {
          checklist.value = data;
        },
      },
    },
  );
  openChecklistFormSkeleton();
}
function duplicateChecklistHandler() {
  patchSkeletonOptions(
    {
      attrs: {
        type: 'Duplicate Checklist',
        checklist: checklist.value,
        index: props.checklist_length,
        onClose() {
          closeChecklistFormSkeleton();
        },
        async on_submit(data) {
          try {
            await checklist_store.duplicate_checklist(task_uid.value, { checklist: data });
            $toast({
              title: 'Checklist duplicated sucessfully',
              text: 'Your changes have been saved and your checklist is added',
              type: 'success',
            });
          }
          catch (error) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openChecklistFormSkeleton();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});
function checklistDeleteHandler() {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Checklist'),
        content: `Are you sure you want to delete ${props.checklist.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            if (is_checklist_template) {
              await checklist_store.delete_template(props.checklist.uid);
              emit('deleted');
            }
            else {
              await checklist_store.delete_checklist(task_uid.value, props.checklist.uid);
              emit('taskChecklistDeleted');
            }
            closeDeletePopup();
            $toast({
              title: 'Checklist deleted sucessfully',
              text: 'Your changes have been saved and your checklist is removed from the list',
              type: 'success',
            });
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}
</script>

<template>
  <div>
    <div class="flex justify-between items-center p-2 group bg-gray-100 rounded-lg mb-4 cursor-pointer" @click="collapse = !collapse">
      <div class="flex items-center">
        <IconHawkDragIcon
          :class="{ '!invisible': !can_modify || is_checklist_template }"
          class="cursor-move mr-1.5 h-4 invisible group-hover:visible text-gray-300"
        />

        <p class="text-base font-semibold">
          <HawkText :content="checklist.name" :length="45" />
        </p>
      </div>
      <div
        class="invisible group-hover:visible flex items-center"
        :class="{ '!visible': open_dropdown === checklist.uid }"
      >
        <hawk-menu
          position="fixed"
          class="mr-1"
          :items="checklist_menu" @click.stop
          @open="open_dropdown = checklist.uid"
          @close="open_dropdown = null"
        />
        <span
          v-tippy="collapse ? $t('Expand') : $t('Collapse')"
          class="mr-2"
        >
          <IconHawkPlusCircle v-if="collapse" class="w-4.5 text-gray-600" />
          <IconHawkMinusCircle v-else class="w-4.5 text-gray-600" />
        </span>
      </div>
    </div>

    <div id="container" class="is_draggable border-b border-gray-300 pb-3 last:border-b-0">
      <ol v-show="!collapse">
        <ChecklistItem
          class="my-2"
          :subitem_level="0"
          :checklist_uid="checklist.uid"
          :items="props.checklist.items"
          @updateChecklist="update"
        />
        <ChecklistInput v-if="addItem" class="mx-5 mt-4" placeholder="Add item" :allow_multiple="true" @close="addItem = false" @add="addChecklistItems" />
        <p
          v-else-if="can_modify"
          class="text-sm font-semibold text-gray-600 cursor-pointer my-2 mx-6 w-auto inline-block"
          @click="addItem = true"
        >
          + {{ $t('Add Item') }}
        </p>
      </ol>
    </div>
  </div>
</template>
