<script setup>
import { computed } from 'vue';
import { getUserName } from '~/dms/utils/dms.utils';
import { useCommonStore } from '~/common/stores/common.store';
import { TRANSMITTAL_STATUS } from '~/dms/constants';

const props = defineProps({
  document: {
    type: Object,
    required: true,
  },
  document_status_map: {
    type: Object,
    required: true,
  },
});
const common_store = useCommonStore();

const is_pending_review = computed(() => {
  return ['pending_review'].includes(props.document?.draft?.status || props.document?.status);
});
const is_submitter = computed(() => props.document.user_role === 'submitter');

function reviewStatusColor(document) {
  if (is_pending_review.value)
    return is_submitter.value ? 'gray' : 'yellow';
  if (document?.status === 'not_submitted') {
    const attachment_color = document?.draft?.attachment ? 'green' : 'yellow';
    return is_submitter.value ? attachment_color : 'gray';
  }
  return TRANSMITTAL_STATUS[document?.draft?.status || document.status]?.color;
}

function reviewStatusName(document) {
  if (is_pending_review.value && is_submitter.value)
    return 'Waiting for review';

  return props.document_status_map?.[document?.draft?.status || document?.status] || TRANSMITTAL_STATUS[document?.draft?.status || document.status]?.title;
}

function reviewStatusBadgeClasses(document) {
  const color = reviewStatusColor(document);
  const class_map = {
    orange: '!bg-orange-50 !text-orange-700',
    lightgreen: '!bg-emerald-50 !text-emerald-700',
  };
  return class_map[color];
}

function getPendingReviewInfo(document) {
  const titles = [];
  const { has_delegate, delegated_to, has_additional_reviewers, additional_reviews, has_consolidator, consolidator_review } = document;

  const delegated_user = has_delegate && delegated_to?.user;
  if (delegated_user) {
    const userName = getUserName(common_store.get_org_or_internal_user(delegated_user));
    titles.push(`Delegated to ${userName || 'Sensehawk'}.`);
  }

  const additional_reviews_length = additional_reviews?.filter(review => review.status !== 'pending_review')?.length || 0;
  const additional_reviewers_length = has_additional_reviewers && ((document.additional_reviewers?.length || 0) - additional_reviews_length);
  if (additional_reviewers_length)
    titles.push(`Waiting for ${additional_reviewers_length} additional review(s)`);

  if (has_consolidator && !consolidator_review?.user?.status !== 'pending_review')
    titles.push('Waiting for consolidator review');

  return titles.join(', ');
}
</script>

<template>
  <hawk-badge :color="reviewStatusColor(document)" :custom_classes="reviewStatusBadgeClasses(document)" class="whitespace-nowrap">
    <IconHawkInfoCircle
      v-if="getPendingReviewInfo(document)"
      v-tippy="getPendingReviewInfo(document)"
      class="h-4 w-4"
    />
    <template v-if="document?.status === 'not_submitted'">
      <template v-if="document.user_role === 'submitter' ">
        <template v-if="document?.draft?.attachment">
          Uploaded
        </template>
        <template v-else>
          Pending submission
        </template>
      </template>
      <template v-else>
        Waiting for submission
      </template>
    </template>
    <template v-else>
      {{ reviewStatusName(document) }}
    </template>
  </hawk-badge>
</template>


