<script setup>
import { cloneDeep, compact, differenceWith, flatten, intersection, isEqual, uniq } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import dayjs from 'dayjs';
import tippy from 'tippy.js';
import DOMPurify from 'dompurify';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { useTaskNavigation } from '~/tasks/composables/task.navigation.js';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { truncateMiddle } from '~/common/filters/truncate.js';
import { fullName } from '~/tasks/composables/view-helpers.js';
import { asyncDebounce, changeIconDimensions, stringToNumber } from '~/common/utils/common.utils';
import { handleDependencyWarning, useTasksPermissions } from '~/tasks/composables/task-composables.js';

import CalendarFilters from '~/tasks/pages/tasks/calendar-view/calendar-filters.vue';
import StandardTaskForm from '~/tasks/components/molecule/task-form/standard-task-form.vue';
import CriticalPriorityFlag from '~icons/hawk/critical-priority-flag?raw';
import HighPriorityFlag from '~icons/hawk/high-priority-flag?raw';
import MediumPriorityFlag from '~icons/hawk/medium-priority-flag?raw';
import LowPriorityFlag from '~icons/hawk/low-priority-flag?raw';
import NoPriorityFlag from '~icons/hawk/no-priority-flag?raw';
import OutlineGroups from '~icons/ic/outline-groups?raw';

const emit = defineEmits(['viewTask']);

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const $t = inject('$t');
const task_store = inject('task_store');

const common_store = useCommonStore();
const auth_store = useAuthStore();

const route = useRoute();

const { checkTaskPermission, checkStatusUpdatePermission } = useTasksPermissions();

const { subscribe_navigator, unsubscribe_navigator, setNavigationContext } = useTaskNavigation(task_store, 'calendar_view');

const view_map = {
  day: 0,
  week: 1,
  month: 2,
  timeline: 3,
};

const state = reactive({
  cached_months: [],
  active_view: 'month',
  active_date: dayjs().startOf('day').toDate(),
  active_date_range: [],
  attached_events: [],
  filters: {},
  group_by_mode: { name: 'priority', label: $t('Priority') },
  timeline_x_start: 0,
  timeline_loader: false,
  timeline_length: 14,
  open_folders: [],
  is_getting_tasks: false,
});

const scheduler_container = ref(null);

const current_date_text = computed(() => {
  if (state.active_view === 'day')
    return `${dayjs(state.active_date).format('DD MMM YYYY')}`;
  else if (state.active_view === 'week')
    return `${dayjs(state.active_date).startOf('week').format('DD MMM YYYY')} to ${dayjs(state.active_date).endOf('week').format('DD MMM YYYY')}`;
  else if (state.active_view === 'month')
    return `${dayjs(state.active_date).format('MMM YYYY')}`;
  else
    return `${dayjs(state.active_date_range[0]).format('DD MMM YYYY')} to ${dayjs(state.active_date_range[1]).format('DD MMM YYYY')}`;
});

const timeline_options = computed(() => [
  ['day', $t('Day'), 'day'],
  ['week', $t('Week'), 'week'],
  ['month', $t('Month'), 'month'],
  ['timeline', $t('Timeline'), 'timeline'],
].map((option, index) => ({
  uid: index,
  label: option[1],
  action: option[2],
})));

/* ---------- TASKS DATA ---------- */
const tasks_by_date_range = computed(() => {
  const tasks_with_due_date = task_store.tasks().filter(task => task.due_date);
  const formatted_tasks = tasks_with_due_date.map((task) => {
    const start_date = dayjs(task.start_date);
    const due_date = dayjs(task.due_date);
    return {
      ...task,
      id: task.uid,
      text: DOMPurify.sanitize(task.name, { ALLOWED_TAGS: [] }) || 'Invalid task name',
      start_date: start_date.isValid() ? start_date.startOf('day').toDate() : due_date.startOf('day').toDate(),
      end_date: due_date.endOf('day').toDate(),
      category: task.category ? task.category : 'not-set',
      assignees: task.assignees?.length ? task.assignees : ['not-set'],
      tags: task.tags?.length ? task.tags : ['not-set'],
    };
  });
  return formatted_tasks.filter((task) => {
    return (!(dayjs(task.start_date).isSameOrAfter(state.active_date_range[1]) || dayjs(task.end_date).isSameOrBefore(state.active_date_range[0])));
  });
});

// TASKS FILTERED BY STATUS | PRIORITY
const block_one_filtered_data = computed(() => {
  if (state.filters?.status?.length || state.filters?.priority?.length)
    return tasks_by_date_range.value.filter(task => state.filters.status.includes(task.status) || state.filters.priority.includes(task.priority));
  return tasks_by_date_range.value;
});
// TASKS FILTERED BY STATUS | PRIORITY | CATEGORY
const block_two_filtered_data = computed(() => {
  if (state.filters?.category?.length)
    return block_one_filtered_data.value.filter(task => state.filters.category.includes(task.category));
  return block_one_filtered_data.value;
});
// TASKS FILTERED BY STATUS | PRIORITY | CATEGORY | TAGS
const block_three_filtered_data = computed(() => {
  if (state.filters?.tags?.length)
    return block_two_filtered_data.value.filter(task => intersection(state.filters.tags, task.tags).length);
  return block_two_filtered_data.value;
});
// TASKS FILTERED BY STATUS | PRIORITY | CATEGORY | TAGS | ASSIGNEES
const filtered_tasks = computed(() => {
  if (state.filters?.assignees?.length)
    return block_three_filtered_data.value.filter(task => intersection(state.filters.assignees, task.assignees).length);
  return block_three_filtered_data.value;
});

/* ---------- GROUPING DATASET ---------- */
const group_by_modes = computed(() => {
  return [
    { name: 'priority', label: $t('Priority') },
    { name: 'category', label: $t('Category') },
    { name: 'assignees', label: $t('Assignees') },
    { name: 'status', label: $t('Status') },
  ];
});

const assignees_data = computed(() => {
  const assigned_assignees = uniq(flatten(filtered_tasks.value.map(task => task.assignees)));
  const users = common_store.scope_users(route.params.asset_id);
  const teams = common_store.scope_teams(route.params.asset_id);
  const all_assignees = [...users, ...teams].map(assignee => assignee.uid);
  const unassigned_assignees = differenceWith(all_assignees, assigned_assignees, isEqual);

  return [
    {
      open: true,
      key: 'assigned',
      label: $t('Assigned'),
      group_header: true,
      children: preprocessAssignees(assigned_assignees).sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      key: 'unassigned',
      label: $t('Unassigned'),
      group_header: true,
      open: state.open_folders.includes('unassigned'),
      children: [
        {
          member: true,
          key: 'not-set',
          name: 'Not set',
          label: $t('Not set'),
        },
        ...preprocessAssignees(unassigned_assignees).sort((a, b) => a.label.localeCompare(b.label)),
      ],
    },
  ];
});

const category_data = computed(() => {
  const all_categories = common_store.scope_categories(route.params.asset_id).map(category => category.uid);
  const assigned_categories = uniq(compact(filtered_tasks.value.map(task => task.category)));
  const unassigned_categories = differenceWith(all_categories, assigned_categories, isEqual);

  return [
    {
      key: 'assigned',
      label: $t('Assigned'),
      open: true,
      children: preprocessCategories(assigned_categories).sort((a, b) => a.label.localeCompare(b.label)),
      group_header: true,
    },
    {
      key: 'unassigned',
      label: $t('Unassigned'),
      group_header: true,
      open: state.open_folders.includes('unassigned'),
      children: [
        {
          key: 'not-set',
          label: $t('Not set'),
          name: 'Not set',
        },
        ...preprocessCategories(unassigned_categories).sort((a, b) => a.label.localeCompare(b.label)),
      ],
    },
  ];
});

const sections_data = computed(() => {
  return {
    status: task_store.status_values.map(item => ({ ...item, key: item.value })),
    priority: task_store.priority_values.map(item => ({ ...item, key: item.value })),
    assignees: assignees_data.value,
    category: category_data.value,
  };
});

subscribe_navigator(() => {}, handleViewTask);

/* ---------- STORE LISTENERS ---------- */
task_store.$onAction(({ args, name, after }) => {
  after(() => {
    if (name === 'update_tasks') {
      updateEvent(args[0][0], args[1]);
    }
    else if (name === 'remove_tasks' || name === 'archive_tasks') {
      const task_uids = args[0];
      task_uids.forEach(uid =>
        deleteEvent(uid),
      );
    }
    refreshView();
  });
});

onBeforeUnmount(() => {
  unsubscribe_navigator();
});

/* --------- CREATE TASK --------- */
const { open: openTaskForm, close: closeTaskForm, patchOptions: patchTaskForm } = useModal({
  component: StandardTaskForm,
});

function createTask(id, task_data = {}) {
  patchTaskForm({
    attrs: {
      task_store,
      task_data,
      allow_multi_task_create: true,
      onClose() {
        closeTaskForm();
      },
      onTaskCreated() {
        refreshView();
      },
      on_cancel() {
        scheduler.deleteEvent(id);
        closeTaskForm();
      },
    },
  });
  openTaskForm();
}

function preprocessAssignees(assignees) {
  const data = [];
  assignees.forEach((assignee_uid) => {
    if (common_store.get_user(assignee_uid)?.uid) {
      const member = common_store.get_user(assignee_uid);
      const name = member.first_name ? `${member.first_name} ${member.last_name || ''}` : member.email;
      data.push({
        member: true,
        key: assignee_uid,
        label: name,
        name,
      });
    }
    else if (common_store.get_team(assignee_uid)?.uid) {
      const team = common_store.get_team(assignee_uid);
      data.push({
        member: false,
        key: assignee_uid,
        label: team.name,
        name: team.name,
      });
    }
  });
  return data;
}

function preprocessCategories(categories) {
  const data = [];
  categories.forEach((category_uid) => {
    if (common_store.get_category(category_uid)?.uid) {
      const category = common_store.get_category(category_uid);
      data.push({
        key: category_uid,
        label: category.name,
        name: category.name,
      });
    }
  });
  return data;
}

const getTasks = asyncDebounce(async ({ start_date, end_date }, init = false, append = true) => {
  if (init) {
    await initializeScheduler();
  }
  else {
    state.is_getting_tasks = true;
    const query = {
      page_number: 1,
      page_size: 100,
      is_template: false,
      start_date: start_date || dayjs(state.active_date_range[0]).startOf('day'),
      due_date: end_date || dayjs(state.active_date_range[1]).endOf('day'),
      ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
    };
    await task_store.set_tasks(query, append);
    refreshView();
    state.is_getting_tasks = false;
  }
}, 500);

/* ---------- CALENDAR METHODS ---------- */
function handleViewTask(current_task_uid, create_new_context = true) {
  setNavigationContext(
    create_new_context,
    { current_task_uid, tasks: filtered_tasks.value },
  );

  emit('viewTask', current_task_uid);
}

async function handleUncachedDateRange(start_date, end_date) {
  await getTasks({
    start_date: start_date.toISOString(),
    end_date: end_date.toISOString(),
  });

  let date = start_date.startOf('month');

  while (date.isBefore(end_date.endOf('month'))) {
    if (!state.cached_months.includes(date.format('YYYY-MM')))
      state.cached_months.push(date.format('YYYY-MM'));
    date = date.add(1, 'month');
  }
  const scheduler = window.scheduler;
  scheduler.clearAll();
  scheduler.parse(filtered_tasks.value);
}

function setActiveDateAndActiveDateRange(payload, active_date) {
  const unit = state.active_view === 'timeline' ? 'day' : state.active_view;
  const offset = state.active_view === 'timeline' ? state.timeline_length : 1;

  let final_date;
  if (payload === 'prev' || payload === 'next') {
    const signed_offset = payload === 'prev' ? -offset : offset;
    final_date = active_date.add(signed_offset, unit);
  }
  else {
    final_date = active_date;
  }

  if (state.active_view === 'timeline')
    state.active_date_range = [final_date.startOf('week').toDate(), final_date.add(1, 'week').endOf('week').toDate()];
  else if (state.active_view === 'week')
    state.active_date_range = [final_date.day(0).startOf('day').toDate(), final_date.day(6).endOf('day').toDate()];
  else if (state.active_view === 'month')
    state.active_date_range = [final_date.startOf('month').startOf('week').toDate(), final_date.endOf('month').endOf('week').toDate()];
  else
    state.active_date_range = [final_date.startOf(unit).toDate(), final_date.endOf(unit).toDate()];

  state.active_date = final_date.startOf('day').toDate();
}

// payload should be 'today', 'prev', 'next', or a JS Date
async function changeScheduleDate(payload = 'today') {
  const scheduler = window.scheduler;
  let active_date = state.active_date ?? dayjs().startOf('day').toDate();
  if (payload === 'today')
    active_date = dayjs().startOf('day').toDate();
  if (payload === 'prev' || payload === 'next') {
    const unit = state.active_view === 'timeline' ? 'day' : state.active_view;
    active_date = dayjs(active_date).startOf(unit).toDate();
  }
  if (dayjs(payload).isValid())
    active_date = dayjs(payload).startOf('day');
  else
    active_date = dayjs(active_date).startOf('day');

  setActiveDateAndActiveDateRange(payload, active_date);

  let start_date = dayjs(state.active_date).startOf('month').startOf('day');
  let end_date = dayjs(state.active_date).endOf('month').startOf('day');

  if (state.active_view === 'timeline') {
    start_date = dayjs(state.active_date_range[0]).startOf('day');
    end_date = dayjs(state.active_date_range[1]).endOf('day');
  }

  refreshView();

  if (!isDateRangeCached(start_date, end_date))
    await handleUncachedDateRange(start_date, end_date);

  if (state.active_view === 'timeline')
    scheduler.setCurrentView(state.active_date_range[0], state.active_view);
  else
    scheduler.setCurrentView(state.active_date, state.active_view);
}

async function initializeScheduler() {
  try {
    const { scheduler } = await import('dhtmlx-scheduler');
    window.scheduler = scheduler;
    configureScheduler();
    setTemplates();
    attachEvents();
    scheduler.init(scheduler_container.value, new Date(), 'month');
    setActiveView('month');
  }
  catch (error) {
    logger.error(error);
  }
}

function configureScheduler() {
  const scheduler = window.scheduler;
  scheduler.config.header = [
    'day',
    'week',
    'month',
    'date',
    'prev',
    'today',
    'next',
  ];
  scheduler.plugins({
    tooltip: true,
    timeline: true,
    all_timed: true,
    multisection: true,
    treetimeline: true,
    container_autoresize: true,
  });

  // allows preventing short events from overlapping
  scheduler.config.separate_short_events = false;
  // By default, events scheduled to the same time are displayed one by one. To display them as a cascade, make this true.
  scheduler.config.cascade_event_display = false;
  // sets the maximum number of events in a cascade
  scheduler.config.cascade_event_count = 0;
  // sets the left margin for a cascade of events
  scheduler.config.cascade_event_margin = 0;
  // only events < 24 hours will be displayed as usual ones
  scheduler.config.all_timed = false;
  // rest of multiday events would be displayed at the top
  scheduler.config.multi_day = true;
  // enables the possibility to render the same events in several sections of the Timeline or Units view
  scheduler.config.multisection = true;
  // enables the possibility to create events by double click
  scheduler.config.dblclick_create = false;
  // enables the possibility to resize multi-day events in the Month view by drag-and-drop
  scheduler.config.resize_month_events = true;
  // 'says' to use the extended form while creating new events by drag or double click
  scheduler.config.details_on_create = false;
  // 'says' to open the lightbox after double clicking on an event
  scheduler.config.details_on_dblclick = false;
  // enables/disables the marker displaying the current time
  scheduler.config.mark_now = true;
  // enables/disables caching of GET requests in the browser
  scheduler.config.prevent_cache = false;
  // allows working with recurring events independently of time zones
  scheduler.config.occurrence_timestamp_in_utc = true;
  // defines whether the date specified in the 'End by' field should be exclusive or inclusive
  scheduler.config.include_end_by = true;
  // prevents including past days to events with the 'weekly' recurrence
  scheduler.config.repeat_precise = true;
  // enables the possibility to create new events by drag-and-drop
  scheduler.config.drag_create = true;
  // allows dragging scheduler events by any part of the body
  scheduler.config.drag_event_body = true;
  // enables the possibility to move events by drag-and-drop
  scheduler.config.drag_move = true;
  // resizing the start and due date of tasks
  scheduler.config.drag_resize = true;
  // sets the maximum number of events displayable in a cell
  scheduler.config.max_month_events = 2;
  // enables the possibility to resize single-day events in the Month view by drag-n-drop
  scheduler.config.resize_month_timed = true;
  // enables setting of the event's duration to the full day
  scheduler.config.full_day = true;
  // sets the start day of weeks
  scheduler.config.start_on_monday = false;
  // sets the minimum height of cells in the month view
  scheduler.config.month_day_min_height = 120;
}

function setTemplates() {
  const scheduler = window.scheduler;

  // helper functions
  const formatWeekDay = (date) => {
    return scheduler.date.date_to_str('%l')(date);
  };
  const formatDay = (date) => {
    return scheduler.date.date_to_str('%j')(date);
  };

  scheduler.templates.tooltip_text = function (_start, _end, event) {
    return `${event.text}`;
  };
  scheduler.templates.event_header = function (_start, _end, event) {
    return `<span class="event_line__status-tag">${task_store.status_map[event.status || 1].label}</span>`;
  };
  scheduler.templates.event_text = function (_start, _end, event) {
    const repeat_icon = event?.rrule ? '<span class="dhx_cal_event_line__repeat-icon"></span>' : '';
    return `
      <div class="flex w-full items-center">
        ${repeat_icon}
        <span class="whitespace-nowrap truncate">${event.text}</span>
        <span class="event_line__status-tag">${task_store.status_map[event.status || 1].label}</span>
      </div>
    `;
  };
  scheduler.templates.event_bar_text = function (_start, _end, event) {
    const repeat_icon = event?.rrule ? '<span class="dhx_cal_event_line__repeat-icon"></span>' : '';
    return `
      <div class="flex w-full items-center">
        ${repeat_icon}
        <span class="whitespace-nowrap truncate">${event.text}</span>
        <span class="event_line__status-tag">${task_store.status_map[event.status || 1].label}</span>
      </div>
    `;
  };
  scheduler.templates.event_class = function (start, end, event) {
    const classes = ['event_line'];

    if (dayjs(end).diff(start, 'day') < 3)
      classes.push('event_line--short');

    switch (event?.status) {
      case 1:
        classes.push('event_line--pending');
        break;
      case 2:
        classes.push('event_line--in-progress');
        break;
      case 3:
        classes.push('event_line--resolved');
        break;
      case 4:
        classes.push('event_line--closed');
        break;
      case 5:
        classes.push('event_line--rejected');
        break;
    }

    if (dayjs(start).isBefore(state.active_date_range[0]))
      classes.push('event_line--continues_left');
    if (dayjs(end).isAfter(state.active_date_range[1]))
      classes.push('event_line--continues_right');

    return classes.join(' ');
  };
  scheduler.templates.month_scale_date = function (date) {
    return formatWeekDay(date).slice(0, 3);
  };
  scheduler.templates.month_date_class = function (date) {
    if (dayjs(date).isSame(state.active_date, 'day'))
      return 'outline outline-1 outline-primary-600';
  };
  scheduler.templates.hour_scale = function (date) {
    return scheduler.date.date_to_str('%g %A')(date);
  };
  scheduler.templates.day_scale_date = function (date) {
    return `
      <div class="flex flex-col items-center w-min">
        <div class="text-sm text-primary-600">
          ${formatWeekDay(date).slice(0, 3)}
        </div>
        <div class="w-[52px] h-[52px] rounded-full bg-primary-600 flex items-center justify-center font-semibold text-white text-xl">
          ${formatDay(date)}
        </div>
      </div>
    `;
  };
  scheduler.templates.week_scale_date = function (date) {
    const is_current_date = dayjs().isSame(date, 'day');
    const is_active_date = dayjs(state.active_date).isSame(date, 'day');
    let day_classes = 'text-gray-900';
    let week_day_classes = 'text-gray-600';
    if (is_current_date)
      day_classes = 'border border-gray-100 bg-gray-100';
    if (is_active_date) {
      week_day_classes = 'text-primary-600';
      day_classes = 'bg-primary-600 text-white border border-primary';
    }
    return `
      <div class="flex flex-col items-center w-full">
        <div class="text-sm ${week_day_classes}">
          ${formatWeekDay(date).slice(0, 3)}
        </div>
        <div class="w-[52px] h-[52px] rounded-full flex items-center justify-center font-semibold text-xl ${day_classes}">
          ${formatDay(date)}
        </div>
      </div>
    `;
  };
}

function compareEventObjects(task_obj_1, task_obj_2) {
  const what_changed = [];
  const start = dayjs(task_obj_1.start_date);
  const due = dayjs(task_obj_1.due_date);

  task_obj_1.assignees = Array.isArray(task_obj_1.assignees) ? task_obj_1.assignees : [task_obj_1.assignees];
  task_obj_2.assignees = Array.isArray(task_obj_2.assignees) ? task_obj_2.assignees : [task_obj_2.assignees];

  if (!start.isSame(task_obj_2.start_date, 'day'))
    what_changed.push('start_date');
  if (!due.isSame(task_obj_2.end_date, 'day'))
    what_changed.push('end_date');
  if (task_obj_1.priority !== task_obj_2.priority)
    what_changed.push('priority');
  if (task_obj_1.status !== task_obj_2.status)
    what_changed.push('status');
  if (task_obj_1.category !== task_obj_2.category)
    what_changed.push('category');
  if (!isEqual(task_obj_1.assignees, task_obj_2.assignees))
    what_changed.push('assignees');

  return what_changed;
}

function onNewTaskCreated(id) {
  const event = scheduler.getEvent(id);
  const start_date = dayjs(event.start_date).toDate();
  const due_date = dayjs(event.end_date);
  const copied_event = { ...event, assignees: Array.isArray(event.assignees) ? event.assignees : [event.assignees] };
  delete copied_event.id;
  delete copied_event.text;
  delete copied_event.end_date;
  delete copied_event.due_date;
  delete copied_event.start_date;
  if (copied_event.category === 'not-set')
    delete copied_event.category;
  if (copied_event.assignees.includes('not-set'))
    delete copied_event.assignees;
  if (state.active_view === 'month' || state.active_view === 'timeline') {
    copied_event.start_date = start_date;
    copied_event.due_date = due_date.subtract(1, 'day').toDate();
  }
  else {
    copied_event.start_date = start_date;
    copied_event.due_date = due_date.toDate();
  }
  createTask(id, copied_event);
}

function getAssignees(what_changed, event, original_event) {
  let assignees = [];

  if (what_changed.includes('assignees')) {
    assignees = Array.isArray(event.assignees) ? event.assignees : [event.assignees];
    const assignee_uids = [];

    assignees.forEach((assignee) => {
      if (assignee.includes(','))
        assignee_uids.push(...assignee.split(','));
      else if (assignee)
        assignee_uids.push(assignee);
    });

    // if the user has dragged a task into the 'not-set' area, remove it
    if (assignee_uids.includes('not-set'))
      return [];

    return assignee_uids.filter(assignee => (assignee !== 'not-set' && !original_event.assignees.includes(assignee)));
  }
  else {
    return original_event.assignees.filter(assignee => assignee !== 'not-set');
  }
}

function getResizeOrMovePayload(what_changed, event, original_event, mode) {
  let due = dayjs(event.end_date);
  if (what_changed.includes('end_date') && mode === 'resize')
    due = dayjs(event.end_date).subtract(1, 'day');

  const payload = {
    ...(what_changed.includes('status') && { status: event.status }),
    ...(what_changed.includes('category') && { category: event.category === 'not-set' ? null : event.category }),
    ...(what_changed.includes('priority') && { priority: event.priority }),
    ...(what_changed.includes('start_date') && { start_date: dayjs(event.start_date).startOf('day').toDate() }),
    ...(what_changed.includes('end_date') && { due_date: due.endOf('day').toDate() }),
    ...(what_changed.includes('assignees') && { assignees: getAssignees(what_changed, event, original_event) }),
  };

  if (payload.category === 'not-set')
    delete payload.category;

  return payload;
}
async function onResizeOrMove(id, mode, original_event) {
  const task = filtered_tasks.value.find(task => task.uid === id);

  if (task?.is_recurring || !checkTaskPermission({ permission: 'can_modify', instance: task }))
    return;

  const event = scheduler.getEvent(id);

  const what_changed = compareEventObjects(original_event, event);

  if (mode !== 'resize' && !what_changed.length)
    return;

  if (what_changed.includes('status') && !checkStatusUpdatePermission(task, event.status))
    return;

  const payload = getResizeOrMovePayload(what_changed, event, original_event, mode);

  const res = await task_store.update_tasks([task.uid], payload);
  if (res?.data?.description === 'Task Blocked')
    handleDependencyWarning(
      task_store,
      task,
      payload,
      async payload => await task_store.update_tasks([task.uid], payload),
    );

  original_event = null;
  refreshView();
}

function attachEvents() {
  const scheduler = window.scheduler;

  scheduler.attachEvent('onAfterFolderToggle', (section, is_open) => {
    if (!is_open)
      state.open_folders = state.open_folders.filter(folder => folder !== section.key);
    else
      state.open_folders.push(section.key);
  });

  scheduler.attachEvent('onBeforeViewChange', () => {
    scheduler.xy.bar_height = 32;
    return true;
  });

  scheduler.attachEvent('onTemplatesReady', () => {
    scheduler.templates.event_bar_date = function () {
      return '';
    };
  });

  // Disable lightbox
  scheduler.attachEvent('onBeforeLightbox', () => {
    return false;
  });

  // Open task
  scheduler.attachEvent('onClick', id => handleViewTask(id));

  let original_event;
  scheduler.attachEvent('onBeforeDrag', (id, mode) => {
    original_event = cloneDeep(scheduler.getEvent(id));

    if (mode === 'create')
      return auth_store.check_permission('create_tasks', route.params.asset_id);
    else
      return !(original_event?.is_recurring || !checkTaskPermission({ permission: 'can_modify', instance: original_event }));
  });

  scheduler.attachEvent('onDragEnd', async (id, mode) => {
    if (mode === 'new-size')
      onNewTaskCreated(id);
    else
      await onResizeOrMove(id, mode, original_event);
  });

  scheduler.attachEvent('onViewMoreClick', (date) => {
    state.active_view = 'day';
    changeScheduleDate(date);
    return true;
  });

  scheduler.attachEvent('onDataRender', () => {
    tippy('[data-tippy-content]');
    state.timeline_loader = false;
  });
}

function isDateRangeCached(start_date, end_date) {
  let date = start_date.startOf('month');

  while (date.isBefore(end_date.endOf('month'))) {
    if (!state.cached_months.includes(date.format('YYYY-MM')))
      return false;
    date = date.add(1, 'month');
  }

  return true;
}

function priorityTemplate(obj) {
  const IconHawkCriticalPriorityFlag = changeIconDimensions(CriticalPriorityFlag);
  const IconHawkHighPriorityFlag = changeIconDimensions(HighPriorityFlag);
  const IconHawkMediumPriorityFlag = changeIconDimensions(MediumPriorityFlag);
  const IconHawkLowPriorityFlag = changeIconDimensions(LowPriorityFlag);
  const IconHawkNoPriorityFlag = changeIconDimensions(NoPriorityFlag);

  const priority_object = {
    1: {
      priority_class_icon: 'text-error-500',
      IconToBeDisplayed: IconHawkCriticalPriorityFlag,
    },
    2: {
      priority_class_icon: 'text-warning-500',
      IconToBeDisplayed: IconHawkHighPriorityFlag,
    },
    3: {
      priority_class_icon: 'text-success-500',
      IconToBeDisplayed: IconHawkMediumPriorityFlag,
    },
    4: {
      priority_class_icon: 'text-gray-500',
      IconToBeDisplayed: IconHawkLowPriorityFlag,
    },
    5: {
      priority_class_icon: 'text-gray-500',
      IconToBeDisplayed: IconHawkNoPriorityFlag,
    },
  };

  return `
    <div class="flex justify-between">
      <span class="flex items-center ${priority_object[obj.key]?.priority_class_icon}">
        ${priority_object[obj.key]?.IconToBeDisplayed}
        <span class="pl-3 text-sm font-medium text-gray-700 !flex !items-center">
          ${obj?.label}
        </span>
      </span>
    </div>
  `;
}

function categoryTemplate(obj) {
  const sanitized_label = DOMPurify.sanitize(obj.label, { ALLOWED_TAGS: [] });
  if (obj?.group_header)
    return `
        <span class="!normal-case inline-flex items-center justify-center leading-loose text-gray-900">
          ${sanitized_label}
        </span>`;
  else
    return `<span class="text-sm font-medium text-gray-700" data-tippy-content="${sanitized_label}">${truncateMiddle(sanitized_label, 17)}</span>`;
}

function assigneesTemplate(obj) {
  const user = common_store.get_user(obj?.key);
  const sanitized_label = DOMPurify.sanitize(obj?.label || '', { ALLOWED_TAGS: [] });
  const sanitized_name = DOMPurify.sanitize(obj?.name || '', { ALLOWED_TAGS: [] });
  let html_string = '';
  if (obj?.group_header)
    html_string += `
        <span class="!normal-case inline-flex items-center justify-center leading-loose text-gray-900">
          ${sanitized_label}
        </span>`;
  else if (obj?.member)
    if (user?.display_picture)
      html_string += `
          <img src="${user?.display_picture}" class="w-6 h-6 rounded-full inline" />
        `;
    else
      html_string += `
          <span class="inline-flex items-center justify-center leading-none rounded-full text-white p-1 w-6 h-6" style="background-color: ${stringToNumber(user?.first_name || user?.email)};">
            ${fullName(user)?.charAt(0).toUpperCase()}
          </span>`;

  else
    html_string += `
        <span class="text-blue-500 inline-flex ml-[2.5px] mr-[3px]">
          ${OutlineGroups.replace('width="1.2em"', 'width="19"').replace('height="1.2em"', 'height="19"')}
        </span>
      `;

  html_string += `<span class="text-sm font-medium text-gray-700 ${obj?.group_header ? '' : 'ml-3'}" data-tippy-content="${sanitized_name}">${truncateMiddle(sanitized_name, 17)}</span>`;
  return html_string;
}

function statusTemplate(obj) {
  return `
      <div
        class="!w-3 !h-3 mr-3 rounded-full"
        style="background-color: ${obj.color};"
      >
      </div>
      <span class="text-sm font-medium text-gray-700">${obj.label}</span>
    `;
}

function columnTemplate(obj) {
  if (state.group_by_mode.name === 'priority')
    return priorityTemplate(obj);

  else if (state.group_by_mode.name === 'category')
    return categoryTemplate(obj);

  else if (state.group_by_mode.name === 'assignees')
    return assigneesTemplate(obj);

  else if (state.group_by_mode.name === 'status')
    return statusTemplate(obj);
}

function createTimelineView() {
  scheduler.createTimelineView({
    name: 'timeline',
    x_unit: 'day',
    x_date: '%M %j',
    x_step: 1,
    x_size: state.timeline_length,
    x_start: state.timeline_x_start,
    y_unit: sections_data.value[state.group_by_mode.name],
    y_property: state.group_by_mode.name,
    render: 'tree',
    round_position: true,
    columns: [{ template: columnTemplate }],
    dx: 250,
  });
}

async function setActiveView(view = 'month') {
  if (view === 'timeline' || state.active_view === 'timeline')
    state.timeline_loader = true;

  state.active_view = view;
  await changeScheduleDate(state.active_date);
}

function updateEvent(id, payload = {}) {
  const scheduler = window.scheduler;
  Object.keys(payload || {}).forEach((key) => {
    scheduler.getEvent(id)[key] = payload[key];
    if (key === 'name')
      scheduler.getEvent(id).text = payload.name;
    else if (key === 'assignees')
      scheduler.getEvent(id).assignees = payload.assignees?.length ? payload.assignees : ['not-set'];
    else if (key === 'category')
      scheduler.getEvent(id).category = payload.category ? payload.category : 'not-set';
  });
  scheduler.updateEvent(id);
}

function deleteEvent(id) {
  const scheduler = window.scheduler;
  scheduler.deleteEvent(id);
}

function setGroupByMode(access) {
  state.group_by_mode = group_by_modes.value.find(item => item.name === access.name);
  createTimelineView();
}

function refreshView() {
  const scheduler = window.scheduler;
  scheduler.clearAll();
  scheduler.parse(filtered_tasks.value);
  if (state.active_view === 'timeline')
    createTimelineView();
}

/* ---------- TASKS METHODS ---------- */

function applyFilters(filters) {
  state.filters = filters;
  refreshView();
}

async function searchTasks(value) {
  task_store.set_search_key(value);
  await getTasks({}, false, false);
}

window.$onTimelineChevronClick = (direction = 'prev') => {
  changeScheduleDate(direction);
};

defineExpose({ getTasks, searchTasks });
</script>

<template>
  <div>
    <div class="flex items-center">
      <HawkButton type="outlined" @click="changeScheduleDate('today')">
        {{ $t('Today') }}
      </HawkButton>
      <div class="flex ml-3">
        <HawkButton
          icon
          type="text"
          @click="changeScheduleDate('prev')"
        >
          <IconHawkChevronLeft />
        </HawkButton>
        <HawkButton
          icon
          type="text"
          @click="changeScheduleDate('next')"
        >
          <IconHawkChevronRight />
        </HawkButton>
      </div>
      <div class="text-sm text-gray-900 font-medium ml-2">
        {{ current_date_text }}
      </div>
      <IconHawkLoadingCircle v-if="state.is_getting_tasks" class="ml-4 animate-spin" />
      <hawk-button-group
        class="ml-auto"
        :items="timeline_options"
        :active_item="view_map[state.active_view]"
        @day="setActiveView('day')"
        @week="setActiveView('week')"
        @month="setActiveView('month')"
        @timeline="setActiveView('timeline')"
      />
    </div>
    <div class="tasks-calendar-view mt-4 border-t border-solid border-gray-300">
      <div class="scrollbar h-[calc(100vh-254px)] pb-4">
        <CalendarFilters
          :active_date_range="state.active_date_range"
          :active_date="state.active_date"
          :tasks="tasks_by_date_range"
          :block_one_filtered_data="block_one_filtered_data"
          :block_two_filtered_data="block_two_filtered_data"
          :block_three_filtered_data="block_three_filtered_data"
          @filter="applyFilters"
          @setScheduleDate="changeScheduleDate"
        />
      </div>
      <div class="scrollbar h-[calc(100vh-254px)] border-l border-solid border-gray-300 relative">
        <HawkMenu v-if="state.active_view === 'timeline' && !state.timeline_loader" :items="group_by_modes" class="mt-2 ml-2 absolute top-[5px] left-4 z-999" position="bottom-right" additional_trigger_classes="!ring-0" @select="setGroupByMode">
          <template #item="{ item }">
            <div class="flex flex-col w-full">
              <span class="text-sm text-gray-700 font-medium w-full flex justify-between">
                {{ item.label }}
                <IconHawkCheck v-if="item.name === state.group_by_mode.name" class="text-primary-600" />
              </span>
            </div>
          </template>
          <template #trigger="{ open }">
            <div class="flex cursor-pointer items-center text-sm font-semibold text-gray-600">
              {{ state.group_by_mode.label }}
              <IconHawkChevronUp v-if="open" class="ml-[2px] text-lg" />
              <IconHawkChevronDown v-else class="ml-[2px] text-lg" />
            </div>
          </template>
        </HawkMenu>
        <div v-show="!state.timeline_loader" ref="scheduler_container" class="w-full" />
        <hawk-loader v-show="state.timeline_loader" class="z-[1000]" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~/tasks/styles/tasks-calendar-skin.scss";

.tasks-calendar-view {
  width: calc(100% + 30px);
  display: grid;
  grid-template-columns: 312px 1fr;
  margin-left: -16px;
}
</style>
