<script setup>
import { useRoute } from 'vue-router';

// other
import { sortRowsByColumn } from '~/common/utils/common.utils.js';

// stores
import { useDocumentStore } from '~/dms/store/document.store';

// components
import DocumentBrowsePopup from '~/dms/components/atoms/document-browse-popup.vue';

const props = defineProps({
  item: {
    type: Object,
    required: false,
    default: () => {},
  },
  bulk_items: {
    type: Array,
    required: false,
    default: () => [],
  },
  element: {
    type: Object,
    required: false,
    default: () => {},
  },
  is_widget: {
    type: Boolean,
    required: false,
    default: false,
  },
  move_data: {
    type: Array,
    required: false,
    default: () => [],
  },
  table_instance: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const emit = defineEmits(['close', 'targetElementUpdate']);

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');

const route = useRoute();

const document_store = useDocumentStore();

const item_type = computed(() => {
  if (props.item)
    return props.item.parent === undefined ? 'folder' : 'file';

  else
    return null;
});

const columns = [
  {
    header: 'Name',
    accessorKey: 'name',
    id: 'name',
    cell: info => info.getValue(),
    columns: [],
    sortingFn: sortRowsByColumn,
  },
];

const items_length = computed(() => {
  if (props.bulk_items.length)
    return props.bulk_items.length;

  else
    return 1;
});

const computed_title = `${$t('Move')} ${items_length.value} ${$t('item(s) to...')}`;

async function getData() {
  const options = {};
  if (document_store.active_folder_meta.type === 'asset') {
    options.asset = document_store.active_folder_meta.uid;
    options.is_root = true;
  }

  else if (document_store.active_folder_meta.type === 'folder') {
    options.uid = document_store.active_folder_meta.uid;
  }

  await document_store.set_documents({
    query: {
      ...(document_store.active_folder_meta.type === 'organization' ? { asset: 'null', is_root: true } : { children: true }),
      ...options,
    },
  });
}

// Popup save
async function handleSave({ destination_uid = '' }) {
  const bulk_files = [];
  const bulk_folders = [];
  if (props.bulk_items.length)
    props.bulk_items.forEach((item) => {
      if (item.folder === undefined)
        bulk_folders.push(item.uid);

      else
        bulk_files.push(item.uid);
    });

  else
    if (item_type.value === 'file')
      bulk_files.push(props.item.uid);
    else
      bulk_folders.push(props.item.uid);

  try {
    // could be used regardless of bulk/single action
    await $services.documents.post({
      body: {
        files: {
          update: bulk_files.length
            ? [
                {
                  destination: destination_uid,
                  uids: bulk_files,
                },
              ]
            : [],
        },
        folders: {
          update: bulk_folders.length
            ? [
                {
                  destination: destination_uid,
                  uids: bulk_folders,
                },
              ]
            : [],
        },
      },
    });

    // reset table
    await getData();

    // reset sidebar
    await document_store.set_hierarchy(route.params.asset_id);

    // close file details
    document_store.set_show_details(false);

    let toast_text = $t('File/folder moved successfully');

    if (props.bulk_items.length && props.bulk_items.length > 1)
      toast_text = `${props.bulk_items.length} ${$t('files/folders moved successfully')}`;

    $toast({
      title: $t('Move successful'),
      text: toast_text,
      type: 'success',
      position: 'bottom-right',
    });

    // clear selected
    if (items_length.value > 1)
      props.table_instance?.clearSelect();
  }
  catch (e) {
    logger.error(e);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again later'),
      type: 'error',
      position: 'bottom-right',
    });
  }

  // close move popup
  emit('close');
}
</script>

<template>
  <DocumentBrowsePopup
    :title="computed_title"
    :action_label="$t('Move')"
    :move_data="move_data"
    :columns="columns"
    :is_move="true"
    :on_submit="handleSave"
    :update_table="getData"
    :element="element"
    :move_item="item"
    :move_bulk_items="bulk_items"
    @close="emit('close')"
    @targetElementUpdate="emit('targetElementUpdate')"
  />
</template>
