<script>
/* Automatically create tools and sub tools from items array */
export default {
  props: {
    tools: {
      type: Array,
      default: () => [],
    },
    active_tool: {
      type: String,
    },
    disabled: {
      type: Array,
      default: () => [],
    },
    active_tools: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    horizontal_icons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_sub_tools: false,
    };
  },
  computed: {
    all_tools() {
      const tools = [];
      this.tools.forEach((tool) => {
        tools.push({
          event: tool.event,
          tooltip: tool.tooltip,
          icon: tool.icon,
        });
        if (tool.sub_tools)
          tool.sub_tools.forEach((sub_tool) => {
            tools.push({
              event: sub_tool.event,
              tooltip: sub_tool.tooltip,
              icon: sub_tool.icon,
            });
          });
      });
      return tools;
    },
    hash() {
      return this.all_tools.reduce((acc, tool) => {
        acc[tool.event] = tool;
        return acc;
      }, {});
    },
  },
  methods: {
    is_sub_tool_active(tool) {
      if (tool.sub_tools)
        return tool.sub_tools.find(i => ((i.event === this.active_tool) || this.active_tools[i.event]));
      return false;
    },
    choose_icon(tool) {
      if (!this.hash[this.active_tool] || !this.is_sub_tool_active(tool))
        return tool.icon;
      else
      // if any of the sub_tool is active, return icon of the active sub_tool
        return this.hash[this.active_tool].icon;
    },
  },
};
</script>

<template>
  <div
    v-click-outside="() => (show_sub_tools = false)"
    class="opacity-100 left-[var(--left)] h-auto"
    :class="[horizontal_icons ? '!flex' : '']"
  >
    <div
      v-for="(tool, i) in tools"
      :key="tool.event"
      class="relative shadow-sm"
    >
      <div
        :class="{
          '!bg-gray-800': active_tool === tool.event || is_sub_tool_active(tool) || active_tools[tool.event],
          'opacity-50 pointer-events-none': disabled.includes(tool.event),
          'w-9 h-11': !horizontal_icons,
          'w-11 h-9 p-1': horizontal_icons,
          'border-r border-gray-300': horizontal_icons && (tools.length > 1) && i < tools.length - 1,
          'border-b border-gray-300': !horizontal_icons && (tools.length > 1) && i < tools.length - 1,
          'rounded-l-lg': horizontal_icons && i === 0,
          'rounded-r-lg': horizontal_icons && i === tools.length - 1,
          'rounded-t-lg': !horizontal_icons && i === 0,
          'rounded-b-lg': !horizontal_icons && i === tools.length - 1,
        }"
        class="map-tools-icon group bg-white flex items-center justify-center opacity-100 cursor-pointer hover:bg-gray-800 group"
        @click="tool.action"
      >
        <div v-tippy="tool.tooltip" class="absolute">
          <div v-if="tool.marker" class="bg-error-600 w-[6px] h-[6px] rounded-lg absolute right-0 -mr-[1px] -mt-[1px] z-2" />
          <div v-if="tool.letter">
            {{ tool.letter }}
          </div>
          <Component
            :is="is_sub_tool_active(tool) ? choose_icon(tool) : tool.icon" v-else class="text-gray-500 w-4 h-4 group-hover:text-white group-hover:grayscale-[1] group-hover:brightness-[0] group-hover:invert-[1]"
            :class="[
              tool.is_size, active_tool === tool.event || is_sub_tool_active(tool) || active_tools[tool.event] ? 'text-white grayscale-[1] brightness-[0] invert-[1]' : '', tool.custom_class || '',
            ]"
          />
          <div v-if="tool.is_active" class="w-2 h-2 rounded-lg bg-red-500 absolute -top-[2px] -right-[2px]" />
          <div v-if="tool?.sub_tools?.length && active_tool === tool.event" class="absolute -right-[12px] -ml-3 w-[240px] pt-3.5">
            <div
              class="bg-white p-1 rounded-lg z-1"
              @mouseleave="removeHoverIcon"
            >
              <div
                v-for="(sub_tool, key) of tool.sub_tools"
                :key="key"
                class="flex items-center p-2 hover:bg-gray-50 rounded-lg hover:text-gray-700 cursor-pointer"
                :class="{ 'opacity-50 pointer-events-none': disabled.includes(sub_tool.event) }" @click.stop="sub_tool.action"
              >
                <Component :is="sub_tool.icon" :class="sub_tool.is_size" class="text-gray-500 hover:text-gray-700 mr-2.5 w-4 h-4" />
                <span class="text-[14px]">{{ sub_tool.tooltip }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
