<script setup>
import { useRoute } from 'vue-router';
import { sortBy } from 'lodash-es';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import FamListTable from '~/forms-as-module/pages/fam-list/fam-list-table.vue';
import { useCustomViewStore } from '~/common/stores/custom-view.store.js';
import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  is_widget: {
    default: false,
  },
  views: {
    default: () => [],
  },
  fam_overview_display_filters_ref: {
    default: null,
  },
  table_options: {},
  fam_view_store_id: {},
});
const widget_views = computed(() => props.views);
const custom_view_store = useCustomViewStore();
const { handleConfigureTabs, views, active_view_id, setActiveViewID } = useFamCustomView(
  props.is_widget
    ? {
        feature: 'widget_view',
        fam_view_store_id: props.fam_view_store_id,
      }
    : { feature: 'list_view' },
);
const { logged_in_user_details } = useAuthStore();

const route = useRoute();
const $t = inject('$t');
const logged_in_user_id = logged_in_user_details?.user_id;
const $services = inject('$services');
const $additional_filter = ref(null);
const $forms_v2_table = ref(null);
const state = reactive({
  loading_table: false,
});

const form_template_detail_store = useFormTemplateDetailStore();

const tabs_items = computed(() => {
  let items = [];
  if (form_template_detail_store.is_template_flow)
    if (!views.value.length)
      items = [
        {
          label: `All ${form_template_detail_store?.form_template_detail?.plural_name || form_template_detail_store?.form_template_detail?.name}`,
          uid: 'all',
        },
      ];
    else
      items = views.value.map((item) => {
        return {
          ...item,
          label: item?.data?.name || '-',
        };
      });

  else
    items = [
      { label: 'Assigned to me', uid: 'assigned', filters: { assigned_to_me: true } },
      { label: 'Waiting for review', uid: 'review', filters: { pending_approval: true } },
      { label: 'My submissions', uid: 'submission', filters: { submitted_by: logged_in_user_id } }];
  return sortBy(items, item => item?.data?.order_index || item?.order_index);
});

const active_tab_details = computed(() => {
  if (active_view_id.value)
    return tabs_items.value.find(item => active_view_id.value === item.uid);
  else return tabs_items.value?.[0]?.uid;
});

const filters_type = computed(() => {
  return active_view_id.value === 'all' ? 'tab-all' : `tab-${active_view_id.value}`;
});

watch(() => views.value, () => {
  if (tabs_items.value?.findIndex(view => view.uid === active_view_id.value) === -1) {
    setActiveViewID(tabs_items.value?.[0]?.uid);
    onTabClick({ uid: active_view_id.value });
  }
}, { immediate: true });

function onTabClick(event) {
  setActiveViewID(event.uid);
  state.loading_table = true;
  setTimeout(() => {
    state.loading_table = false;
  }, 10);
}
</script>

<template>
  <div class="m-4">
    <div v-if="!form_template_detail_store.is_list_collapsed" class="grid gap-4">
      <div class="flex justify-between items-center gap-4">
        <div class="flex">
          <HawkTabs
            v-if="tabs_items?.length"
            :tabs="tabs_items"
            :active_item="active_view_id"
            :character_length="24"
            @tab-click="(e) => onTabClick(e)"
          />
        </div>
        <div
          v-if="!is_widget && form_template_detail_store.is_template_flow && form_template_detail_store?.form_template_detail?.can_modify_template"
          class="flex items-center gap-4 flex-shrink-0"
        >
          <HawkButton class="focus:!ring-0" type="outlined" @click="handleConfigureTabs">
            {{ $t('Configure tabs') }}
          </HawkButton>
        </div>
      </div>
    </div>
    <FamListTable
      v-if="active_view_id && !state.loading_table"
      ref="$forms_v2_table"
      :fam_overview_display_filters_ref="fam_overview_display_filters_ref"
      :is_widget="is_widget"
      :fam_view_store_id="fam_view_store_id"
      :active_tab_details="active_tab_details"
      :widget_views="widget_views"
      :filters_type="filters_type"
      :table_options="table_options"
    />
  </div>
</template>
