<script setup>
import { computed } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
    required: true,
  },
});

const emit = defineEmits(['openDrilldown']);
const $t = inject('$t');

const data = computed(() => props.data);
const display_value = computed(() => {
  if (data.value.prettify_values) {
    const number = data.value.current_value?.value;
    return number?.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      notation: 'compact',
      compactDisplay: 'short',
    });
  }
  else { return data.value.current_value?.actual_value || 0; }
  ;
});
const upwards_trend = computed(() => data.value.difference?.is_val_increased || false);
const difference = computed(() => data.value.difference?.diff_percent || 0);

const trend_status_success = computed(() => {
  if (difference.value === 0)
    return true;

  if (data.value.inverse_trend)
    return !upwards_trend.value;
  return upwards_trend.value;
});

const trend_tooltip_text = computed(() => {
  return `${$t('Last')} ${data.value.timerange?.type} :   ${data.value.previous_value?.actual_value}`;
});

function openDrilldownPopup(link) {
  if (props.id === 'preview')
    return;

  emit('openDrilldown', link);
}
</script>

<template>
  <div class="px-3">
    <div class="flex items-end">
      <div class="text-lg font-semibold cursor-pointer" @click="openDrilldownPopup(data.current_value.link)">
        {{ display_value }}
      </div>
      <div
        v-if="data.timerange && data.displayPreviousValue && difference !== 0"
        v-tippy="{ content: trend_tooltip_text, placement: 'top' }"
        class="text-xs font-medium p-1 rounded-[4px] ml-2"
        :class="trend_status_success ? 'bg-success-50 text-success-700' : 'bg-error-50 text-error-700'"
      >
        <span v-if="difference !== 0">
          {{ upwards_trend ? '+' : '-' }}
        </span>
        <span class="ml-1">
          {{ difference }}%
        </span>
      </div>
    </div>
  </div>
</template>
