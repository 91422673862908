<script setup>
// --------------------------------- Imports -------------------------------- //
import DOMPurify from 'dompurify';
import { useModal } from 'vue-final-modal';
import FormulaTestPopup from '~/forms/components/form-formula-test/formula-test-popup.vue';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  el$: {
    type: Object,
    default: () => ({}),
  },
});
// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //
const $t = inject('$t');

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const formula_test_popup = useModal({
  component: FormulaTestPopup,
});
// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const formula_html = computed(() => props.el$.data.html);
const is_formula_complete = computed(() => {
  const parsed_formula = DOMPurify.sanitize(formula_html.value, { ALLOWED_TAGS: [] });

  if (!parsed_formula)
    return false;
  // Basic check for formula-like structure
  if (!parsed_formula.startsWith('=') || !parsed_formula.includes('(') || !parsed_formula.includes(')'))
    return false;

  const stack = [];
  for (const char of parsed_formula)
    if (char === '(') {
      stack.push(char);
    }
    else if (char === ')') {
      if (stack.length === 0)
        return false;

      stack.pop();
    }

  return stack.length === 0;
});

// -------------------------------- Functions ------------------------------- //
function openFormulaTestPopup() {
  formula_test_popup.patchOptions({
    attrs: {
      header: $t('Formula test'),
      sub_header: $t('Choose a form below to test the entered formula.'),
      onClose() {
        formula_test_popup.close();
      },
      formula_html,
    },
  });
  formula_test_popup.open();
}
// -------------------------------- Watchers -------------------------------- //
// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div v-if="is_formula_complete" class="flex">
    <HawkButton class="ml-auto" type="link" @click="openFormulaTestPopup">
      {{ $t('Test') }}
    </HawkButton>
  </div>
</template>
