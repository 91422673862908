<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  custom_field: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['close']);
const { getSlugList, insertSlug } = useSlug();
// ---------------------------- Injects/Provides ---------------------------- //
const route = useRoute();
const $t = inject('$t');
const $toast = inject('$toast');
const auth_store = useAuthStore();
const common_store = useCommonStore();
const form$ = ref(null);
const form = ref({});
const aggregation_groups = {
  group_1: [{ label: 'Count', value: 'count' }],
  group_2: [
    { label: 'Sum', value: 'sum' },
    { label: 'Avg', value: 'avg' },
    { label: 'Min', value: 'min' },
    { label: 'Max', value: 'max' },
  ],
};
const field_types_map = {
  text: { value: 'text', label: 'Text', aggregation_group: 'group_1' },
  number: { value: 'number', label: 'Number', aggregation_group: 'group_2', has_prefix_suffix: true },
  email: { value: 'email', label: 'Email' },
  phone: { value: 'phone', label: 'Phone' },
  money: { value: 'money', label: 'Money' },
  percentage: { value: 'percentage', label: 'Percentage', aggregation_group: 'group_2' },
  dropdown: { value: 'dropdown', label: 'Dropdown', is_options: true },
  checkbox: { value: 'checkbox', label: 'Checkbox', is_options: true, parent_type: 'label' },
  radio: { value: 'radio', label: 'Radio', is_options: true },
  date: { value: 'date', label: 'Date' },
  datetime: { value: 'datetime', label: 'Date Time' },
  date_range: { value: 'date_range', label: 'Date Range' },
  planned_actual: { value: 'planned_actual', label: 'Planned/Actual', aggregation_group: 'group_2', has_prefix_suffix: true },
  files: { value: 'files', label: 'Files' },
  members: { value: 'members', label: 'Members' },
  formula: { value: 'formula', label: 'Formula' },
};
const selected_type = computed(() => field_types_map[form.value.type] || {});
const state = reactive({
  is_loading: false,
});
let currency_to_symbol_map;
// -------------------------------- Inline composable functions ------------------------------- //
function useSlug() {
  function insert(text) {
    const textarea = document.getElementById('formula-textarea');

    // Get the current cursor position
    const cursor_position = textarea.selectionStart;

    // Get the text before and after the cursor position
    const text_before = textarea.value.substring(0, cursor_position);
    const text_after = textarea.value.substring(cursor_position);

    // Concatenate the text with the inserted text
    const newText = text_before + text + text_after;

    // Set the updated text back to the textarea
    textarea.value = newText;

    // Set the cursor position after the inserted text
    textarea.setSelectionRange(cursor_position + text.length, cursor_position + text.length);

    // Focus on the textarea
    textarea.focus();
    return newText;
  }
  function getSlugList() {
    const supported_field_types_set = new Set(['text', 'number', 'planned_actual', 'percentage', 'date', 'datetime', 'date_range']);
    const fields = common_store?.assets_custom_fields.filter(item => supported_field_types_set.has(item.type));
    return fields.reduce((slugs, field) => {
      if (field.slug) {
        slugs.push({ name: field.name, slug: field.slug });
        if (field.type === 'planned_actual') {
          slugs.push(
            { name: `${field.name} [Planned]`, slug: `${field.slug}__planned` },
            { name: `${field.name} [Actual]`, slug: `${field.slug}__actual` },
          );
        }

        else if (field.type === 'date_range') {
          slugs.push(
            { name: `${field.name} [Start]`, slug: `${field.slug}__start` },
            { name: `${field.name} [End]`, slug: `${field.slug}__end` },
          );
        }
      }
      return slugs;
    }, []);
  }
  function insertSlug(field) {
    form$.value.update({
      ...form.value,
      properties: {
        ...form.value.properties,
        formula: insert(field.slug),
      },
    });
  }

  return {
    getSlugList,
    insertSlug,
  };
}
// -------------------------------- Functions ------------------------------- //

function resetForm() {
  form$.value.update({
    ...form.value,
    config: selected_type.value?.is_options ? form.value.config : {},
    properties: {
      ...form.value.properties,
      aggregation: null,
      formula: null,
      count: null,
      currency: null,
    },
  });
}

async function save() {
  try {
    let response = null;
    const payload = form.value;

    if (payload.type === 'files')
      payload.properties.count = +(payload.properties.count || 0);
    else if (payload.properties.count)
      delete payload.properties.count;

    if (props.custom_field?.uid) {
      response = await common_store.update_data({
        type: 'patch_update',
        id: props.custom_field?.uid,
        data: {
          field: {
            uid: props.custom_field.uid,
            ...payload,
          },
        },
        service: 'fields',
        append_data: false,
        state_prop: 'assets_custom_fields_map',
        update_state: false,
      });
    }

    else {
      response = await common_store.update_data({
        type: 'add',
        data: {
          field: {
            ...payload,
            organization: auth_store.current_organization?.uid,
          },
        },
        service: 'fields',
        append_data: false,
        state_prop: 'assets_custom_fields_map',
        update_state: false,
      });
    }

    if (response?.data?.field) {
      await common_store.update_global_data({ asset_custom_fields: [response?.data?.field?.uid] });
      $toast({
        text: !props.custom_field?.uid ? 'Field created successfully' : 'Field updated successfully',
        title: 'Success',
        type: 'success',
      });
      emit('close');
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
  }
  catch (error) {
    logger.info(`[DEBUG] asset-custom-field-form-modal.vue::193\n${error}`);
    $toast({
      title: error?.data?.title || 'Something went wrong',
      text: error?.data?.message || 'Please try again',
      type: 'error',
    });
  }
}

function autofocusInputs(index) {
  setTimeout(() => {
    form$.value?.elements$.config.children$Array[index + 1].children$?.name?.input?.focus();
  }, 100);
}

onMounted(async () => {
  currency_to_symbol_map = await import('currency-symbol-map/map');
});

function onFormMounted(el$) {
  if (props.custom_field)
    el$.update(props.custom_field);
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ default: { container: 12, label: 4, wrapper: 12 } }"
      :endpoint="save"
      :sync="true"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('cancel')">
          <template #title>
            {{ custom_field?.uid ? $t('Edit property') : $t('New property') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="selected_type?.is_options || false">
          <HawkLoader v-if="state.is_loading" />
          <div v-else>
            <TextElement
              :label="$t('Name')"
              name="name"
              :placeholder="$t('Enter property name')"
              rules="required"
              class="mb-2"
            />
            <SelectElement
              name="type"
              :search="true"
              :native="false"
              :label="$t('Type')"
              :placeholder="$t('Choose type')"
              :can-deselect="false"
              :can-clear="false"
              rules="required"
              :items="Object.values(field_types_map)"
              class="mb-2"
              :disabled="custom_field?.uid"
              @change="resetForm"
            />

            <ListElement
              v-if="selected_type.is_options"
              name="config"
              label="Options"
              :add-text="`+ ${$t('Add Option')}`"
              :sort="true"
              :min="1"
              :remove-classes="{
                ListElement: {
                  add: ['form-bg-primary', 'form-border-color-primary', 'form-color-on-primary', 'form-border-width-btn', 'hover:scale-105', 'focus:form-ring'],
                },
              }"
              :add-classes="{
                ListElement: {
                  add: ['text-blue-600', 'font-semibold', 'text-sm'],
                },
              }"
              class="mb-2"
            >
              <template #default="{ index }">
                <ObjectElement
                  :name="index"
                >
                  <HiddenElement name="color" default="#000" />
                  <HiddenElement v-if="custom_field?.config?.[index]?.uid" name="uid" />
                  <TextElement
                    name="name"
                    rules="required"
                    :attrs="{ autofocus: true }"
                    @keydown.enter="autofocusInputs(index)"
                  />
                </ObjectElement>
              </template>
            </ListElement>
            <ObjectElement name="properties">
              <TextareaElement
                :label="$t('Description')"
                name="description"
                :placeholder="$t('Enter description')"
                :floating="false"
                class="mb-2"
              />
              <hawk-category-input
                :options="{
                  name: 'category_uid',
                  label: $t('Category'),
                  placeholder: $t('Select Category'),
                  create: true,
                  from: 'Tasks',
                }"
                :asset_id="route.params.asset_id || null"
                class="mb-2"
              />
              <ToggleElement
                name="required"
                :label="$t('Required')"
                class="my-2"
              />
              <SelectElement
                v-if="form?.type === 'money'"
                name="currency"
                :native="false"
                :search="true"
                :label="$t('Currency')"
                rules="required"
                :items="Object.keys(currency_to_symbol_map).map(code => ({
                  label: code,
                  value: code,
                }))"
                class="mb-2"
              />
              <SelectElement
                v-if="selected_type?.aggregation_group"
                name="aggregation"
                :native="false"
                :label="$t('Aggregation')"
                :items="aggregation_groups?.[selected_type?.aggregation_group]"
                class="mb-2"
                :description="$t('The aggregated values are displayed in the footer when the assets are grouped in the list view')"
              />
              <TextareaElement
                v-if="form?.type === 'formula'"
                id="formula-textarea"
                :label="$t('Formula')"
                name="formula"
                class="mb-2"
              />
              <div v-if="form?.type === 'formula'" class="grid grid-cols-12 w-full col-span-12">
                <div class="col-span-6" />
                <div class="col-span-6 flex gap-2 flex-wrap scrollbar max-h-20">
                  <HawkBadge
                    v-for="item in getSlugList()" :key="item.id" class="cursor-pointer"
                    @click="insertSlug(item)"
                  >
                    {{ item?.name }}
                  </HawkBadge>
                </div>
              </div>
              <TextElement
                v-if="form?.type === 'files'"
                :label="$t('Max files')"
                name="count"
                input-type="number"
                :rules="['integer', 'nullable']"
                class="mb-2"
                info="Set value to 0 for no limit"
              />
              <TextElement
                v-if="selected_type?.has_prefix_suffix"
                :label="$t('Prefix')"
                name="prefix"
                :placeholder="$t('Enter prefix')"
                class="mb-2"
              />
              <TextElement
                v-if="selected_type?.has_prefix_suffix"
                :label="$t('Suffix')"
                name="suffix"
                :placeholder="$t('Enter suffix')"
                class="mb-2"
              />
            </ObjectElement>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #left>
            <div class="flex items-center justify-end">
              <div class="flex gap-3">
                <HawkButton type="outlined" @click="emit('close')">
                  {{ $t('Close') }}
                </HawkButton>
                <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                  {{ custom_field?.uid ? $t('Update') : $t('Save') }}
                </ButtonElement>
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
