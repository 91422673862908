<script setup>
import { startCase } from 'lodash-es';
import InventorySlideOver from '~/inventory/old-components/inventory-slide-over.vue';
import FormActivityHistoryWidget from '~/dashboard/components/widgets/form-widgets/form-activity-history.widget.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['closed']);

const $t = inject('$t');
const form_template_detail_store = useFormTemplateDetailStore();
const state = reactive({
  active_filter: 'all',
});

const all_filters = computed(() => {
  const plural_name = form_template_detail_store?.form_template_detail?.plural_name ? form_template_detail_store.form_template_detail.plural_name : form_template_detail_store?.form_template_detail?.name;
  return [
    { label: $t('All'), uid: 'all' },
    { label: startCase(plural_name), uid: 'form' },
    { label: $t('Approvals'), uid: 'approvals' },
    { label: $t('Template'), uid: 'template' },
  ];
});
</script>

<template>
  <div>
    <InventorySlideOver title="hello" :open="props.open" @closed="emit('closed')">
      <template #header>
        <div class="font-semibold text-xl py-6">
          Activity history
        </div>
        <div class="pb-6 flex flex-wrap gap-4">
          <HawkButton
            v-for="item in all_filters"
            :key="item.uid"
            type="outlined"
            :color="state.active_filter === item.uid ? 'active' : 'primary'"
            @click="state.active_filter = item.uid"
          >
            <HawkText :content="item.label" />
          </HawkButton>
        </div>
      </template>

      <template #content>
        <FormActivityHistoryWidget
          :id="props.id"
          class="px-6"
          :data="props.data"
          :extra_query="{ activity_history_filters: state.active_filter }"
          :is_popout="true"
        />
      </template>
    </InventorySlideOver>
  </div>
</template>
