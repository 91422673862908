<script setup>
import { Validator } from '@vueform/vueform';

import HawkInfoIcon from '~/common/components/atoms/hawk-info-icon.vue';

const props = defineProps({
  header: {
    type: String,
    default: 'Cancel Request',
  },
  sub_header: {
    type: String,
    default: 'This action cannot be undone.',
  },
  content: {
    type: String,
    default: null,
  },
  is_cancel: {
    type: Boolean,
    default: false,
  },
  is_mark_as_submitted: {
    type: Boolean,
    default: false,
  },
  button_text: {
    type: String,
    default: 'Cancel documents',
  },
  header_icon: {
    type: Object,
    required: false,
    default: HawkInfoIcon,
  },
  confirm: {
    type: Function,
    default: null,
  },
});
const emit = defineEmits(['close']);

const form$ = ref(null);
const state = reactive({
  is_loading: false,
  file: null,
});

function addFiles(files) {
  state.file = files[0];
}

function removeFiles(item) {
  state.file = null;
}

async function submitHandler(form$) {
  state.is_loading = true;
  const payload = { ...form$?.data };
  await props.confirm(payload);
  state.is_loading = false;
}

const file_length = class extends Validator {
  get message() {
    return 'Maximum allowed number of files is 1';
  }

  check(value) {
    return value?.length === 1;
  }
};
</script>

<template>
  <hawk-modal-container content_class="rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex items-start mr-4 border-gray-200 text-xl">
                  <component :is="header_icon" class="w-12 h-12" />
                </div>
                <div class="flex flex-col justify-start">
                  {{ header }}
                  <span class="font-normal text-sm text-gray-600">
                    {{ sub_header }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div v-if="content" class="text-gray-600 text-sm">
            {{ content }}
          </div>
          <div class="mt-6">
            <div v-if="state.file" class="mb-6">
              <HawkAttachmentsList
                :items="[state.file]"
                @delete="removeFiles"
              />
            </div>
            <DMSUploadElement v-else-if="is_mark_as_submitted" :options="{ single_file: is_mark_as_submitted }" :label="$t('Upload document')" name="file" :rules="['required', file_length]" class="mb-6" />
            <TextareaElement
              name="comment"
              placeholder="Enter comment here"
              :label="$t('Comment')"
              rules="required"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" class="mr-4" @click="emit('close')">
                  {{ is_cancel ? $t('Dismiss') : $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  :danger="is_cancel"
                  button-class="!border !border-transparent"
                  submits
                  size="sm"
                  name="submit"
                  :loading="state.is_loading"
                  :button-label="button_text"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
