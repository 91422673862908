<script setup>
const loading_transmittals = ref(true);
const loading_documents = ref(true);

const loading = computed(() => (loading_transmittals.value || loading_documents.value));
</script>

<template>
  <hawk-loader v-if="loading" />
  <div v-show="!loading">
    <dms-custom-fields-table :heading="$t('Transmittals')" :sub_heading="$t('Personalize your transmittal information with custom fields')" allowed_in="transmittals" @loaded="loading_transmittals = false" />
    <dms-custom-fields-table class="mt-8" :heading="$t('Documents')" :sub_heading="$t('Personalize your document information with custom fields')" allowed_in="documents" @loaded="loading_documents = false" />
  </div>
</template>
