<script setup>
const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
});

defineEmits(['onSearch']);

const is_open = ref(false);
</script>

<template>
  <TagsElement
    v-bind="{ ...options }"
    @tag="options?.handleCreateInline"
    @change="options?.handleCreateInline"
    @select="options?.handleSelect"
    @open="is_open = true"
    @close="is_open = false"
  >
    <template #before-list>
      <slot name="before-list" />
    </template>
    <template #tag="{ option, handleTagRemove }">
      <template v-if="option.is_team">
        <div v-for="item in option.email" :key="item">
          <div
            class="flex items-center whitespace-nowrap text-sm font-medium rounded-lg border border-gray-300 py-0.5 px-1.5 mr-1 mb-1"
            :class="{ 'border-red-600': !options.isValidEmail[item] }"
          >
            <p :class="!options.isValidEmail[item] ? 'text-red-700' : 'text-gray-700'">
              {{ item }}
            </p>
            <div
              v-if="options?.tags_removable"
              class="hover:bg-gray-50 rounded ml-2"
              @mousedown.prevent="handleTagRemove(option, $event)"
            >
              <IconHawkX class="text-[17px]" :class="(!options.isValidEmail[item]) ? 'text-red-700 hover:text-red-800' : 'text-gray-400 hover:text-gray-600'" />
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="flex items-center whitespace-nowrap text-sm font-medium rounded-lg border border-gray-300 py-0.5 px-1.5 mr-1 mb-1"
        :class="{ 'border-red-600': !options.isValidEmail[option.email] }"
      >
        <p v-if="!option?.uid" :class="!options.isValidEmail[option.email] ? 'text-red-700' : 'text-gray-700'">
          {{ option.label || option.email }}
        </p>
        <p v-else :class="!options.isValidEmail[option.email] ? 'text-red-700' : 'text-gray-700'">
          {{ option.email }}
        </p>
        <div
          v-if="options?.tags_removable"
          class="hover:bg-gray-50 rounded ml-2"
          @mousedown.prevent="handleTagRemove(option, $event)"
        >
          <IconHawkX class="text-[17px]" :class="(!options.isValidEmail[option.email]) ? 'text-red-700 hover:text-red-800' : 'text-gray-400 hover:text-gray-600'" />
        </div>
      </div>
    </template>
    <template #option="{ option }">
      <div v-if="option?.uid">
        <div v-if="option?.is_team" class="flex items-center gap-1">
          <TeamAvatar size="sm" />
          <div>
            <div>
              <HawkMembers
                class="mr-1"
                :members="option"
                has_email
                :name_truncate_length="50"
                type="only_name"
              />
            </div>
            <div class="text-xs text-gray-600">
              {{ option?.users?.length }} {{ $t('members') }}
            </div>
          </div>
        </div>
        <HawkMembers
          v-else
          class="mr-1"
          :members="option"
          has_email
          :name_truncate_length="50"
          type="label"
        />
      </div>
      <div v-else>
        {{ option?.email }}
      </div>
    </template>
    <template #caret="{ component }">
      {{ component }}
      <IconHawkChevronUp v-if="is_open" class="mr-3 text-gray-600 text-lg" />
      <IconHawkChevronDown v-else class="mr-3 text-gray-600 text-lg" />
    </template>
  </TagsElement>
</template>

<style lang="scss" scoped>
:deep([type='email']:focus) {
  box-shadow: none;
}

:deep(.form-pl-input-y-sm) {
  padding-left: 14px;
}

:deep(.form-pl-input-sm) {
  padding-left: 18px;
}

:deep(.focused-hover\:form-shadow-input-hover.form-focus:hover) {
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E9FF;
}

:deep(.outline-zero) {
  outline: none;
}
</style>
