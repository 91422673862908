<script setup>
import { useModal } from 'vue-final-modal';
import { omit } from 'lodash-es';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DMSCustomFieldsForm from '~/dms/components/settings/dms-custom-fields-form.vue';
import DMSCustomFieldsDuplicateForm from '~/dms/components/settings/dms-custom-fields-duplicate.vue';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { Types } from '~/common/components/organisms/hawk-custom-fields/custom-fields.composable.js';

const props = defineProps({
  heading: {
    type: String,
  },
  sub_heading: {
    type: String,
  },
  allowed_in: {
    type: String,
    default: 'transmittals',
  },
});

const emit = defineEmits(['loaded']);

const $t = inject('$t');
const transmittal_permissions = useTransmittalPermissions();
const dms_settings_store = useDMSSettingsStore(props.allowed_in);

const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => !field.deleted));

// BEGIN-NOSCAN

const columns = ref([{
  header: $t('Field name'),
  accessorKey: 'label',
  id: 'label',
  size: 300,
  enableResizing: false,
},
{
  header: $t('Field type'),
  accessorKey: 'type',
  id: 'type',
  enableResizing: false,
},
{
  header: $t('Options'),
  accessorKey: 'items',
  id: 'items',
  enableSorting: false,
  enableResizing: false,
},
{
  header: $t('Required'),
  accessorKey: 'rules',
  id: 'rules',
  enableSorting: false,
  enableResizing: false,
},
{
  header: $t('Active'),
  accessorKey: 'active',
  id: 'active',
  enableSorting: false,
  enableResizing: false,
},
{
  accessorKey: 'context_menu',
  header: '',
  id: 'context_menu',
  show_on_hover: 'true',
  size: 1,
},
]);

const custom_field_form = useModal({
  component: DMSCustomFieldsForm,
});

const delete_field_modal = useModal({
  component: HawkDeletePopup,
});

const duplicate_modal = useModal({
  component: DMSCustomFieldsDuplicateForm,
});

function dropdown_items(field) {
  return [
    {
      label: 'Edit',
      uid: 'edit',
      on_click: () => editCustomField(field),
    },
    ...(transmittal_permissions.checkTransmittalPermission({ permission: 'create_dms_custom_fields' })
      ? [{
          label: 'Duplicate',
          uid: 'duplicate',
          on_click: () => duplicateField(field),
        }]
      : []),
    {
      label: field.active ? 'Deactivate' : 'Activate',
      uid: 'edit',
      on_click: () => updateActiveStatus(field),
    },
    {
      label: 'Delete',
      uid: 'delete',
      on_click: () => deleteField(field),
    }];
}

function addNewField() {
  custom_field_form.patchOptions({
    attrs: {
      uid: null,
      fields: custom_fields,
      module_name: 'DMS',
      hide_default_options: ['default'],
      onClose() {
        custom_field_form.close();
      },
      save: async (field) => {
        try {
          await dms_settings_store.add_custom_fields({
            ...field,
            allowed_in: [props.allowed_in],
            active: true,
          });
          custom_field_form.close();
        }
        catch (e) {
          logger.error(e);
        }
      },
    },
  });
  custom_field_form.open();
}

async function duplicateField(field) {
  duplicate_modal.patchOptions({
    attrs: {
      item: field,
      submit: async (form, { data }) => {
        await dms_settings_store.add_custom_fields({
          ...field,
          label: data.text,
          active: true,
        });
        duplicate_modal.close();
      },
      onClose() {
        duplicate_modal.close();
      },
    },
  });
  duplicate_modal.open();
}

function editCustomField(field) {
  custom_field_form.patchOptions({
    attrs: {
      uid: field.uid,
      fields: custom_fields,
      module_name: 'DMS',
      hide_default_options: ['default'],
      hide_footer_options: true,
      save: async (updated_field) => {
        try {
          const uid = field.uid;
          const field_index = dms_settings_store.custom_fields.findIndex(field => field.uid === uid);
          if (field_index !== -1)
            await dms_settings_store.update_custom_fields(uid, field_index, {
              ...field,
              allowed_in: [props.allowed_in],
              ...updated_field,
            });
          custom_field_form.close();
        }
        catch (e) {
          logger.error(e);
        }
      },
      onClose() {
        custom_field_form.close();
      },
      onClosed() {
        const attrs = omit(custom_field_form.options.attrs, ['custom_field']); // reset attrs
        custom_field_form.options.attrs = attrs;
      },
    },
  });
  custom_field_form.open();
}
function deleteField(field) {
  delete_field_modal.patchOptions({
    attrs: {
      content: `Are you sure you want to delete ${field.label} field?`,
      onClose() {
        delete_field_modal.close();
      },
      confirm: async () => {
        // delete api call
        const index = dms_settings_store.custom_fields.findIndex(({ uid }) => uid === field.uid);
        await dms_settings_store.delete_custom_fields(field.uid, index);
        delete_field_modal.close();
      },
    },
  });
  delete_field_modal.open();
}

async function updateActiveStatus(field) {
  try {
    if (field.active)
      await dms_settings_store.deactivate_custom_fields(field.uid);
    else
      await dms_settings_store.activate_custom_fields(field.uid);
    field.active = !field.active;
  }
  catch (e) {
    logger.error(e);
  }
}

// Initialize
const is_loading = ref(false);
onMounted(async () => {
  is_loading.value = true;
  try {
    await dms_settings_store.set_custom_fields({
      query: {
        allowed_in: props.allowed_in,
      },
    });
  }
  catch (e) {
    logger.error(e);
  }
  is_loading.value = false;
  emit('loaded');
});

function get_configuration(items) {
  return items && items[0] ? items.map(item => item.label).join(', ') : '-';
}

const custom_field_type_map = Object.values(Types).reduce((acc, type) => ({ ...acc, [type.value]: type }), {});

function getTypeLabel(type) {
  return custom_field_type_map[type]?.label || type;
}
</script>

<template>
  <hawk-loader v-if="is_loading" />
  <div v-else>
    <div class="col-span-12">
      <div class="flex items-center justify-between my-4">
        <div>
          <div class=" text-lg font-semibold">
            {{ props.heading }}
          </div>
          <div class="text-xs font-regular">
            {{ props.sub_heading }}
          </div>
        </div>
        <div v-if="transmittal_permissions.checkTransmittalPermission({ permission: 'create_dms_custom_fields' })">
          <hawk-button @click="addNewField">
            <iconHawkPlus /> {{ $t('New Field') }}
          </hawk-button>
        </div>
      </div>
      <hawk-table
        :key="custom_fields.length"
        :columns="columns"
        :data="custom_fields"
        class="!px-0"
        :is_gapless="true"
        :show_menu_header="false"
        :disable_resize="true"
        :class="{ 'pointer-events-none': !transmittal_permissions.checkTransmittalPermission({ permission: 'modify_dms_custom_fields' }) }"
      >
        <template #name="slotProps">
          <span class="text-sm font-medium text-gray-800 ml-2">
            {{ slotProps.data.row.original.label }}
          </span>
        </template>
        <template #type="slotProps">
          <span class="text-sm font-medium text-gray-800 ml-2 capitalize">
            {{ getTypeLabel(slotProps.data.row.original.type) }}
          </span>
        </template>
        <template #rules="{ data: { row: { original: { rules } } } }">
          <span class="text-sm font-medium text-gray-800 ml-2 flex items-center justify-center">
            <span class="ml-2 text-gray-900 text-sm font-medium">{{ rules?.required ? 'Yes' : 'No' }}</span>
          </span>
        </template>
        <template #active="slotProps">
          <span class="text-sm font-medium text-gray-800 ml-2">
            <hawk-badge v-if="slotProps.data.row.original.active" color="green" rounded> {{ $t('Active') }} </hawk-badge>
            <hawk-badge v-else color="gray">  {{ $t('Deactivated') }} </hawk-badge>
          </span>
        </template>
        <template #items="{ data: { row: { original: { items } } } }">
          <span class="text-sm text-gray-900">
            {{ get_configuration(items) }}
          </span>
        </template>
        <template #context_menu="slotProps">
          <hawk-menu :items="dropdown_items(slotProps.data.row.original)" additional_dropdown_classes="w-[200px]">
            <template #trigger>
              <IconHawkDotsVertical class="h-4 w-4" />
            </template>
          </hawk-menu>
        </template>
      </hawk-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #hawk_table {
    :deep(.hawk_table_loader_no_data_container) {
      @apply h-14
    }

    :deep(.hawk_table_no_data) {
      @apply h-14 text-center text-gray-600 text-sm
    }
  }
</style>
