import axios from 'axios';
import { readAndCompressImage } from 'browser-image-resizer';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';

export async function compressAndUploadImage(cover_image, generate_upload_url, options) {
  if (!isFileExtensionAllowed(cover_image?.name))
    return null;

  let image_height = null;
  let image_width = null;
  let cover_image_response = null;
  let full_cover_image_response = null;

  const compress_image_config = {
    quality: options?.quality || 1,
    maxWidth: options?.maxWidth || 150,
  };

  await readAndCompressImage(cover_image, compress_image_config).then(async (res) => {
    // get the dimensions of the compressed image
    const file = res;
    const mime_type = file.type;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        image_height = img.height;
        image_width = img.width;
      };
    };

    // generate signed url by sending the name of the file
    const response_url = await generate_upload_url({
      body: { filename: cover_image?.name, type: mime_type },
    });

    // Read the file as an ArrayBuffer
    const array_buffer = await file.arrayBuffer();

    // Convert ArrayBuffer to Uint8Array
    const binary_data = new Uint8Array(array_buffer);

    // Set up headers for binary upload
    const headers = {
      'Content-Type': mime_type,
    };

    // Upload the binary data
    await axios.put(response_url?.data?.url, binary_data, { headers });

    cover_image_response = { url: response_url?.data?.url?.split('?')[0], size: [image_width, image_height] };
    full_cover_image_response = { ...response_url.data, size: [image_width, image_height] };
  });

  return options.full_response ? full_cover_image_response : cover_image_response;
}
