<script setup>
import { computed } from 'vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import FeatureExtraProperties from '~/terra/components/feature-details/feature-extra-properties.vue';
import FeatureDetails from '~/terra/components/feature-details/feature-details.vue';

const emits = defineEmits(['openCreationPopup', 'filterGlDrawConvertedFeatures']);

const terra_store = useTerraStore();
const { getStyles } = useTerraHelperComposable();

const show_detailed_view = ref(false);

const feature = computed(() => {
  const { active_attachment } = terra_store.gallery_view_state;
  return terra_store.features_hash[active_attachment.feature_uid];
});
const feature_type = computed(() => {
  return terra_store.feature_types?.[feature.value?.properties?.featureTypeId] || null;
});
</script>

<template>
  <div class="flex flex-col">
    <div class="border-b pb-4">
      <div class="text-md font-semibold text-gray-900 mb-1">
        {{ feature.properties.name }}
      </div>
      <div v-if="feature.properties.description" class="text-xs font-semibold text-gray-500 mb-1">
        {{ feature.properties.description }}
      </div>
      <div v-if="feature_type" class="flex mb-2">
        <div
          class="cursor-pointer mt-1 bg-gray-200 rounded-2xl px-2 py-0.5 text-[12px] font-medium"
          :style="getStyles(feature_type, 'tag')"
        >
          <div class="font-medium text-xs">
            <span class="flex items-center"> {{ feature_type?.name }} </span>
          </div>
        </div>
      </div>
      <div class="max-h-[150px]  mb-2 overflow-y-auto scrollbar">
        <FeatureExtraProperties :feature="feature" is_feature_slider hide_quick_filter />
      </div>
      <FeatureDetails
        v-if="terra_store.selected_features.length === 1"
        show_view_details_only
        @filterGlDrawConvertedFeatures="emits('filterGlDrawConvertedFeatures')"
        @openCreationPopup="emits('openCreationPopup', $event)"
      />
    </div>
  </div>
</template>
