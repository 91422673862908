<script setup>
import { computed } from 'vue';
import TriggerDropdown from '~/forms/components/settings/form-settings-reminder-modal.vue';
import ReminderIcon from '~icons/hawk/reminder';

const props = defineProps({
  footer_buttons: {
    type: Array,
    default: () => [],
  },
  disable_footer: {
    type: Boolean,
    default: false,
  },
  final_arr: {
    type: Array,
    default: () => [],
  },
  current_open_modal_id: {
    type: Number,
    default: null,
  },
  callback: {
    type: Function,
    default: null,
  },
  close_callback: {
    type: Function,
    default: null,
  },
});
const emit = defineEmits(['close']);
const $t = inject('$t');

const data = ref(props.final_arr[props.current_open_modal_id]);
const forms$ = ref(null);
const radio_items = ref([
  { value: 'notify', label: $t('Notify'), description: $t('Send Notifications') },
  { value: 'assignAndNotify', label: $t('Assign and Notify'), description: $t('Re-assign members to the form and then notify') },
]);
const mail_notify_data = computed(() => {
  return { ...forms$.value.data };
});
async function onCall() {
  await props.callback(mail_notify_data.value, props.current_open_modal_id);
  await props.close_callback();
}
</script>

<template>
  <hawk-modal-container content_class="!w-[700px]">
    <Vueform
      ref="forms$" v-model="data" :float-placeholders="false"
      :display-errors="false" sync
      :columns="{
        default: { container: 12, label: 6, wrapper: 6 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 8, label: 3, wrapper: 12 },
      }"
      :add-classes="{ ElementLabel: { wrapper: 'text-gray-700 font-medium' } }"
      size="sm"
      :endpoint="onCall"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #icon>
            <ReminderIcon class="text-gray-700" />
          </template>
          <template #title>
            {{ $t("Add Reminder") }}
          </template>
          <template #subtitle>
            {{ $t("send-email-push-notifications-assign-notify") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <RadiogroupElement
            id="group-radio"
            size="sm"
            :add-classes="{
              RadiogroupElement: {
                wrapper: '-mt-3',
              },
              RadiogroupRadio: {
                wrapper: '!py-2',
                text: 'text-sm',
                description: 'text-xs',
              },
            }" class="mail-radio-grp-btn mb-4" :label="$t('Reminder')" name="action"
            :items="radio_items" view="blocks" rules="required"
            default="notify"
            :columns="{
              default: { container: 12, label: 3, wrapper: 12 },
              sm: { container: 12, label: 3, wrapper: 6 },
              md: { container: 12, label: 3, wrapper: 9 },
            }"
          />
          <hawk-assignee-input
            class="mb-6" :options="{
              name: 'custom',
              label: $t('Members'),
              has_teams: true,
              placeholder: $t('select members and teams'),
              rules: 'required',
            }"
            :multi="true"
            :add-classes="{
              ElementLabel: {
                container: ['col-span-3'],
              },
              ElementLayout: {
                innerContainer: ['col-span-7'],
              },
            }"
          />

          <div class="grid grid-cols-12 col-span-12">
            <div class="col-span-3 text-sm text-gray-700 font-medium">
              {{ $t('Trigger') }}
            </div>
            <div>
              <TriggerDropdown />
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="mt-5 flex justify-end text-sm font-medium">
              <hawk-button class="mr-5" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style lang="scss">
.mail-radio-grp-btn {
  .form-border-width-input {
    border: 0 !important;
    padding-left:  0!important;
    padding-right:  0!important;
  }

  .form-bg-selected {
    background-color: transparent !important;
  }
}
</style>
