import Notifications from '~/notifications/pages/notifications.vue';

const routes = [
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      title: 'Notifications',

    },
  },
];
export default routes;
