<script setup>
import { debounce } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const emit = defineEmits(['close', 'fetchItems']);

const { route } = useCommonImports();

const system_model_store = useSystemModelStore();
const { active_instance, active_component } = storeToRefs(system_model_store);

const selected_items = ref([]);
const loading = ref(false);
const form$ = ref(null);

const state = reactive({
  inventory_items: [],
  is_fetching: false,
});

const materials = computed(() => {
  if (active_instance.value)
    return system_model_store.active_instance_details?.materials || [];

  return system_model_store.active_component_details?.materials || [];
});

const dropdown_items = computed(() => {
  return state.inventory_items?.map(item => ({
    label: item.name,
    value: item.uid,
    disabled: selected_items.value.includes(item.uid),
  }));
});
const present_items = computed(() => materials.value?.map(item => item.uid));

async function getData(search) {
  state.inventory_items = await system_model_store.set_inventory_items({
    query: {
      page: 1,
      limit: 100,
      asset: route?.params?.asset_id,
      is_active: true,
      ...(search ? { search } : {}),
    },
  });
}

onMounted(async () => {
  state.is_fetching = true;
  await getData();
  state.is_fetching = false;
});

function listMountHandler(el$) {
  if (materials.value.length) {
    const load_items = materials.value.map((item, i) => {
      selected_items.value[i] = item.uid;
      return {
        item_uid: item.uid,
        quantity: item.quantity,
      };
    });
    el$.update(load_items);
  }
}

function selectedItem(option, index) {
  if (!selected_items.value[index]) {
    selected_items.value[index] = option;
  }
  else {
    deleteSelection(index);
    selected_items.value[index] = option;
  }
  getData();
}

function clearItemFromSelect(el$) {
  const { item_uid } = el$.data;
  if (item_uid && selected_items.value.length) {
    const index = selected_items.value.indexOf(item_uid);
    if (index !== -1)
      deleteSelection(index);
  }
}

async function deleteSelection(index, update = false) {
  if (update) {
    const already_present = present_items.value.includes(selected_items.value[index]);
    if (already_present)
      await system_model_store.update_inventory_items({
        uid: active_instance.value ? active_instance.value.uid : active_component.value.uid,
        body: {
          materials: materials.value.filter(item => item.uid !== selected_items.value[index]),
        },
        type: active_instance.value ? 'instance' : 'component',
      });
  }
  selected_items.value[index] = null;
}

async function addItem() {
  selected_items.value.push(null);
}

function textMountedHandler(el$) {
  if (!el$?.data?.quantity)
    el$.update(1);
}

async function addAndUpdateItems(data) {
  emit('fetchItems', data.map(item => item.uid));
  await system_model_store.update_inventory_items({
    uid: active_instance.value ? active_instance.value.uid : active_component.value.uid,
    body: {
      materials: data,
    },
    type: active_instance.value ? 'instance' : 'component',
  });
}

async function submitHandler(form$) {
  try {
    loading.value = true;
    const { item_list } = form$.data;
    const new_items = item_list.map((item) => {
      const item_name = system_model_store.inventory_items?.find(i_item => i_item.uid === item.item_uid)?.name;
      return {
        name: item_name,
        uid: item.item_uid,
        quantity: item.quantity ?? 0,
      };
    });
    await addAndUpdateItems(new_items);
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    loading.value = false;
    emit('close');
  }
}

function openDropdown(el$) {
  setTimeout(() => {
    if (!el$.model) {
      el$?.focus();
      el$?.input?.open();
    }
  }, 10);
}

const on_fetch_items = debounce(async (searchQuery) => {
  if (!searchQuery)
    return;
  await getData(searchQuery);
}, 500);
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      ref="form$"
      :endpoint="false"
      :float-placeholders="false"
      :display-errors="false"
      size="sm"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ materials.length ? $t('Edit') : $t('New Items') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <StaticElement v-if="state.is_fetching" name="static">
            <HawkLoader />
          </StaticElement>
          <ListElement
            v-else
            name="item_list"
            :add-text="`+ ${$t('Add Item')}`"
            :presets="['repeatable_list']"
            :add-classes="{
              ListElement: {
                remove: ['h-5 w-5 ml-auto'],
              },
            }"
            @remove="(index) => deleteSelection(index, true)"
            @add="addItem"
            @mounted="listMountHandler"
          >
            <template #default="{ index }">
              <ObjectElement
                v-if="index === 0"
                :name="index"
                :columns="{
                  default: { container: 11, label: 12, wrapper: 12 },
                  sm: { container: 11, label: 12, wrapper: 12 },
                  md: { container: 11, label: 12, wrapper: 12 },
                }"
                :add-classes="{
                  ObjectElement: {
                    wrapper: ['items-center', '!gap-10'],
                  },
                }"
              >
                <GroupElement
                  name="items_group"
                  :columns="{
                    default: { container: 8, label: 12, wrapper: 12 },
                    sm: { container: 8, label: 12, wrapper: 12 },
                    md: { container: 8, label: 12, wrapper: 12 },
                  }"
                >
                  <div class="col-span-12 text-[#344054] font-medium">
                    {{ $t('Item') }}<span class="text-amber-600">*</span>
                  </div>
                </GroupElement>
                <GroupElement
                  name="quantity_group"
                  :columns="{
                    default: { container: 4, label: 12, wrapper: 12 },
                    sm: { container: 4, label: 12, wrapper: 12 },
                    md: { container: 4, label: 12, wrapper: 12 },
                  }"
                >
                  <div class="col-span-12 text-[#344054] font-medium">
                    {{ $t('Quantity') }}<span class="text-amber-600">*</span>
                  </div>
                </GroupElement>
              </ObjectElement>
              <ObjectElement
                :name="index"
                :columns="{
                  default: { container: 11 },
                  sm: { container: 11 },
                  md: { container: 11 },
                }"
                :add-classes="{
                  ObjectElement: {
                    wrapper: ['!gap-8'],
                  },
                }"
              >
                <SelectElement
                  :items="dropdown_items"
                  v-bind="{
                    columns: {
                      default: { container: 8, label: 12, wrapper: 12 },
                      sm: { container: 8, label: 12, wrapper: 12 },
                      md: { container: 8, label: 12, wrapper: 12 },
                    },
                    name: 'item_uid',
                    search: true,
                    native: false,
                    placeholder: 'Select item',
                    autocomplete: 'off',
                    inputType: 'search',
                    rules: ['required'],
                    addClasses: {
                      SelectElement: {
                        select: {
                          containerDisabled: ['!bg-gray-100'],
                        },
                      },
                    },
                  }"
                  @select="(option) => selectedItem(option, index)"
                  @clear="clearItemFromSelect"
                  @search-change="on_fetch_items"
                  @mounted="openDropdown"
                />
                <TextElement
                  v-bind="{
                    name: 'quantity',
                    placeholder: 'Add quantity',
                    rules: ['required'],
                    columns: {
                      default: { container: 4, label: 12, wrapper: 12 },
                      sm: { container: 4, label: 12, wrapper: 12 },
                      md: { container: 4, label: 12, wrapper: 12 },
                    },
                    inputType: 'number',
                    attrs: { min: 1 },
                  }"
                  @mounted="textMountedHandler"
                />
              </ObjectElement>
            </template>
          </ListElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="materials.length ? $t('Update') : $t('Save')"
                :loading="loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
