<script setup>
import FormTemplateBuilder from '~/forms/pages/form-template-detail/builder/form-template-detail-form-builder.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const props = defineProps({
  step_number: {
    type: Number,
    default: -1,
  },
  previous_step_number: {
    type: Number,
    default: -1,
  },
  step_name: {
    type: String,
  },
});
const form_template_detail_store = useFormTemplateDetailStore();

form_template_detail_store.step_number = props.step_number;
form_template_detail_store.previous_step_number = props.previous_step_number;
</script>

<template>
  <HawkModalContainer content_class="w-full overflow-auto !h-screen scrollbar !rounded-none">
    <div class="sticky top-0 z-1 bg-white">
      <HawkModalHeader @close="$emit('cancel')">
        <template #title>
          {{ step_name }}
        </template>
      </HawkModalHeader>
    </div>
    <!-- Builder -->
    <HawkModalContent class="bg-white !max-h-none h-[calc(100vh-80px)]">
      <FormTemplateBuilder
        :is_popup="true"
        :previous_step_number="previous_step_number"
        :step_number="step_number"
      />
    </HawkModalContent>
  </HawkModalContainer>
</template>
