<script setup>
import TerraMapViewLegend from '~/dashboard/components/widgets/terra-widgets/terra-map-view-legend.vue';

const props = defineProps({
  map_data_legend: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close']);

// prevent page background scroll
onMounted(() => {
  if (typeof document !== 'undefined')
    document.body.classList.add('overflow-hidden');
});

onUnmounted(() => {
  if (typeof document !== 'undefined')
    document.body.classList.remove('overflow-hidden');
});
</script>

<template>
  <div class="fixed w-full h-full top-0 left-0 flex items-center justify-center z-[1000]">
    <div class="absolute w-full h-full top-0 left-0 bg-black opacity-60 z-0" @click="emit('close')" />
    <div class="relative bg-white rounded-xl w-80">
      <div class="flex items-center justify-between p-4 border-solid border-b border-b-gray-200">
        <div class="text-lg font-semibold">
          {{ $t('Map legend') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <div class="max-h-80 scrollbar">
        <TerraMapViewLegend
          :map_data_legend="props.map_data_legend"
        />
      </div>
    </div>
  </div>
</template>
