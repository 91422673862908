<script setup>
import { groupBy } from 'lodash-es';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryUom from '~/inventory/components/inventory-uom/inventory-uom.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const { $t, route, router } = useCommonImports();
const inventory_store = useInventoryStore();
const columns = ref([
  {
    accessorKey: 'number',
    header: $t('Code'),
    id: 'number',
    font_medium: true,
    size: 150,
  },
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
    size: 400,
  },
  {
    accessorKey: 'quantity',
    header: $t('Quantity'),
    id: 'quantity',
    size: 150,
  },
]);

const item_details = computed(() => {
  return inventory_store.get_active_item_details;
});

const warehouses_stocks = computed(() => {
  const data = [];
  if (item_details.value?.stockstatuses?.length) {
    item_details.value.stockstatuses.forEach(
      ({ resource_id, resource_type, stock_quantity, status }) => {
        if (resource_type === 'warehouse') {
          const warehouse_detail = inventory_store.get_location_details({ type: resource_type, uid: resource_id });
          const status_detail = inventory_store.statuses_map[status];
          data.push({
            warehouse_detail,
            status_detail,
            stock_quantity,
          });
        }
      },
    );
  }
  return data;
});

const warehouses = computed(() => {
  const grouped = groupBy(warehouses_stocks.value, item => item?.warehouse_detail?.uid);
  return Object.keys(item_details.value?.warehouse_stock_quantity)?.map((key) => {
    return {
      uid: key,
      quantity: item_details.value?.warehouse_stock_quantity[key]?.quantity,
      name: grouped?.[key]?.[0]?.warehouse_detail?.name,
      number: grouped?.[key]?.[0]?.warehouse_detail?.number,
      status: item_details.value?.warehouse_stock_quantity[key]?.item_status,
    };
  });
});
</script>

<template>
  <div>
    <div class="font-semibold text-sm">
      {{ $t('Warehouse stock') }}
    </div>
    <div v-if="!warehouses_stocks?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No stock information found') }}
    </div>
    <div v-else>
      <TableWrapperVue :height="500" container_class="border mt-2">
        <HawkTable
          :pagination_config="{ totalRows: warehouses?.length, pageSize: 25 }"
          :data="warehouses"
          :columns="columns"
          cell_height="40px"
          :show_menu_header="false"
          is_gapless disable_resize
          @row-clicked="e => router.push({ name: 'inventory-warehouse-details', params: { warehouse_id: e?.uid, ...route.params } })"
        >
          <template #number="{ data }">
            #{{ data.getValue() }}
          </template>
          <template #name="{ data }">
            <div class="break-all">
              {{ data.getValue() }}
            </div>
          </template>
          <template #quantity="{ data }">
            <div class="mr-1">
              {{ data.getValue() }}
            </div>
            <InventoryUom :uom="item_details.uom" is_symbol />
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
