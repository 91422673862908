import { acceptHMRUpdate, defineStore } from 'pinia';
import { cloneDeep } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

export const useIntegrationsStore = defineStore('integrations', {
  state: () => ({
    integrations_map: {},
  }),
  actions: {
    async authorize_integration(options = {}) {
      try {
        const response = await this.$services.integrations.authorize_integration({
          integration_type: options.integration_type,
        });
        window.open(response.data, '_blank');
        return response;
      }
      catch (err) {
        logger.error('Error while authorizing ', err);
      }
    },
    async set_integrations(options = {}) {
      try {
        const { auth_store, route } = useCommonImports();
        if (!Object.keys(this.integrations_map || {}).length || options?.forceUpdate) {
          const response = await this.$services.integrations.getAll({
            query: {
              show: !options.is_dms && auth_store.check_permission('view_integrations', route?.params?.asset_id) ? 'all' : 'owned+members',
            },
          });
          this.integrations_map = cloneDeep(response.data);
        }
      }
      catch (error) {
        logger.error('🚀 ~ set_integrations ~ error:', error);
      }
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useIntegrationsStore, import.meta.hot));
