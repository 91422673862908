import dayjs from 'dayjs';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { getUserFullName } from '~/common/utils/common.utils.js';

import { $date } from '~/common/utils/date.util';

export function fullName(user, fallback = 'Unknown') {
  const auth_store = useAuthStore();
  const common_store = useCommonStore();

  const result = getUserFullName(user);

  if (result)
    return result;
  else if (common_store.internal_users_uids_map?.[user?.uid])
    if (auth_store?.logged_in_user_details?.user_role) {
      const internal_user = common_store?.internal_users_map?.[user.uid];
      return getUserFullName(internal_user);
    }
    else {
      return 'Sensehawk';
    }
  else
    return fallback;
}

export function payloadDateFormatter(payload, strict = true) {
  const formatted_payload = payload;
  if (payload.start_date && dayjs(payload.start_date).isValid())
    formatted_payload.start_date = dayjs(payload.start_date).startOf('day').toISOString();
  else if (strict)
    delete formatted_payload.start_date;

  if (payload.due_date && dayjs(payload.due_date).isValid())
    formatted_payload.due_date = dayjs(payload.due_date).endOf('day').toISOString();
  else if (strict)
    delete formatted_payload.due_date;

  return formatted_payload;
}

export function listViewDateFormatter({ date, key }) {
  if (!date)
    return '-';

  switch (key) {
    case 'created_at':
    case 'last_commented_at':
      return $date(date, 'DD MMM YYYY hh:mm A');
    default:
      return $date(date, 'DD MMMM YYYY');
  }
}
