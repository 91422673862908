<script setup>
import { storeToRefs } from 'pinia';
import { omit } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import SmAddFieldsForm from '~/system-model/components/forms/sm-add-fields-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

const emit = defineEmits(['close']);
const $t = inject('$t');
const system_model_store = useSystemModelStore();

const { active_component, active_instance, fields } = storeToRefs(system_model_store);
const state = reactive({
  is_loading: false,
});

const config_field_modal = useModal({
  component: SmAddFieldsForm,
});

const config_delete_modal = useModal({
  component: HawkDeletePopup,
});

function deleteConfigField(field_uid, record_self_values = false) {
  config_delete_modal.patchOptions({
    attrs: {
      onClose() {
        config_delete_modal.close();
      },
      header: $t('Delete Field'),
      content: 'Are you sure you want to delete?',
      confirm: async () => {
        try {
          await system_model_store.delete_field({
            uid: field_uid,
            record_self_values,
          });
          if (active_instance.value)
            await system_model_store.set_active_component_details({
              component_id: active_component.value.uid,
            });
          else
            await system_model_store.set_categories();

          config_delete_modal.close();
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  });
  config_delete_modal.open();
}

async function submitHandler() {
  try {
    state.is_loading = true;
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}

function newFieldModal() {
  config_field_modal.patchOptions({
    attrs: {
      onClose() {
        config_field_modal.close();
      },
      onClosed() {
        const attrs = omit(config_field_modal.options.attrs, ['unique_fields']);
        config_field_modal.options.attrs = attrs;
      },
      unique_fields: fields.value?.map(field => field.name),
    },
  });
  config_field_modal.open();
}

function editConfigField(field) {
  config_field_modal.patchOptions({
    attrs: {
      onClose() {
        config_field_modal.close();
      },
      onClosed() {
        const attrs = omit(config_field_modal.options.attrs, ['config_field']);
        config_field_modal.options.attrs = attrs;
      },
      onDeleteField: deleteConfigField,
      config_field: field,
    },
  });
  config_field_modal.open();
}
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="emit('close')">
      <template #left>
        {{ $t('Config') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content class="max-h-96 scrollbar">
      <div class="font-semibold text-base text-gray-900 mb-2">
        {{ $t('Fields') }}
      </div>
      <div>
        <div v-for="field of fields" :key="field.uid" class="flex items-center text-sm mb-1 px-4 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-lg group">
          <div class="text-gray-900 flex-1">
            {{ field.name }}
          </div>
          <div class="flex gap-7 text-gray-500 invisible group-hover:visible">
            <div @click="editConfigField(field)">
              <IconHawkPencilOne class="w-4 h-4" />
            </div>
            <div @click="deleteConfigField(field.uid, field.record_self_values)">
              <IconHawkDeletePopup class="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>
      <hawkButton type="text" class="font-semibold mt-3" @click="newFieldModal">
        <IconHawkPlusGray class="h-3 w-3" /> {{ $t('New Field') }}
      </hawkButton>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end">
          <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
            {{ $t('Cancel') }}
          </hawk-button>
          <HawkButton color="primary" @click="submitHandler">
            {{ $t('Save') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
