<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps({
  show_info: {
    type: String,
  },
});

const emits = defineEmits(['close']);
</script>

<template>
  <TransitionRoot as="template" :show="show_info === 'approval_process'" @close="emits('close')">
    <Dialog as="div" class="relative z-[1000]">
      <div class="fixed inset-y-0 right-0 flex pl-10">
        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
          <DialogPanel class="pointer-events-auto w-[400px]">
            <div class="relative h-full flex flex-col bg-white shadow-xl flex-1 px-4 sm:px-6 pb-6">
              <div class="text-gray-700 text-sm">
                <div class="flex justify-between items-center py-4">
                  <div class="flex items-center">
                    <div class="bg-gray-100 rounded-full p-2 mr-3">
                      <IconHawkAlertCircle />
                    </div>
                    <div>
                      <div class="text-lg font-semibold text-gray-900">
                        Approval Process
                      </div>
                      <div class="text-sm text-gray-700">
                        Info
                      </div>
                    </div>
                  </div>
                  <div class="cursor-pointer">
                    <IconHawkXClose @click="emits('close')" />
                  </div>
                </div>
                <div>Approval Process for Request for Review transmittal:</div>
                <ol class="list-decimal px-4 ">
                  <li class="list-item font-medium my-4">
                    Manual Approval:
                  </li>
                  <ul class="list-disc">
                    <li class="list-item">
                      When selecting manual approval, transmittal initiator's are allowed to designate both reviewers and approvers.
                    </li>
                    <li class="list-item">
                      Reviewers are responsible for assessing the document and updating its review status.
                    </li>
                    <li class="list-item">
                      Approvers and initiators can view the feedback of the reviewers in the feedback section of the document.
                    </li>
                    <li class="list-item">
                      Approvers and initiator of the transmittal have the authority to update the document status regardless of the reviewer's input.
                    </li>
                    <li class="list-item">
                      Based on predefined mapped statuses, Initiator or approver can subsequently update the document's final status.
                    </li>
                  </ul>
                  <li class="list-item font-medium my-4">
                    Automatic Approval Based on Condition:
                  </li>
                  <ul class="list-disc">
                    <li class="list-item">
                      Alternatively, users may opt for automatic approval based on predefined conditions.
                    </li>
                    <li class="list-item">
                      These conditions dictate whether all selected members must approve the document or if any one member's approval is sufficient.
                    </li>
                    <li class="list-item">
                      Once the specified conditions are met, the system automatically updates the final document status according to the mapped statuses.
                    </li>
                    <li class="list-item">
                      This automated approach streamlines the approval process, particularly for routine transmittal requests, by eliminating the need for manual intervention.
                    </li>
                  </ul>
                </ol>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
