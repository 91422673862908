<script setup>
import { flatten } from 'lodash-es';
import DependencySkeleton from './dependency-skeleton.vue';

const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  task_uid: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['close', 'delete', 'update']);

const loading = ref(false);
const dependency_cache = ref({});

const task = computed(() => props.task_store.get_current_task(props.task_uid));
const asset = computed(() => task.value?.target_element?.asset || 'null');

const dependency_by_type = computed(() => {
  if (props.task_store.dependencies && Object.keys(props.task_store.dependencies).length) {
    const linked_uids = task.value?.linked_uids?.map((uid) => {
      return {
        ...props.task_store.dependencies[uid],
        uid,
      };
    });
    const blocking = task.value?.blocking?.map((uid) => {
      return {
        ...props.task_store.dependencies[uid],
        uid,
      };
    });
    const blocked_by = task.value?.blocked_by?.map((uid) => {
      return {
        ...props.task_store.dependencies[uid],
        uid,
      };
    });
    const dependencies = {};
    dependencies.linked_uids = linked_uids || [];
    dependencies.blocked_by = blocked_by || [];
    dependencies.blocking = blocking || [];
    return dependencies;
  }
  return {
    linked_uids: [],
    blocking: [],
    blocked_by: [],
  };
});
const isSaveDisabled = computed(() => {
  const match_uids = (cache_uids, uids) => {
    if (cache_uids.length === uids.length)
      return cache_uids.every(uid_obj => uids.includes(uid_obj.uid));
    return false;
  };
  return (
    match_uids(dependency_cache.value?.linked_uids || [], task.value.linked_uids || [])
    && match_uids(dependency_cache.value?.blocked_by || [], task.value.blocked_by || [])
    && match_uids(dependency_cache.value?.blocking || [], task.value.blocking || [])
  );
});
const restricted_tasks = computed(() => {
  return [...flatten(Object.values(dependency_cache.value)), task.value];
});

onMounted(async () => {
  loading.value = true;
  await props.task_store.set_task_details(props.task_uid);
  dependency_cache.value = dependency_by_type.value;
  loading.value = false;
});

function addToCache(task, dependency_type) {
  if (!Object.keys(dependency_cache.value).includes(dependency_type))
    dependency_cache.value[dependency_type] = [];
  const arr = [...dependency_cache.value[dependency_type], task];
  dependency_cache.value[dependency_type] = arr;
}
function removeFromCache(dependency, dependency_type) {
  if (!Object.keys(dependency_cache.value).includes(dependency_type))
    dependency_cache.value[dependency_type] = [];
  dependency_cache.value[dependency_type] = dependency_cache.value[dependency_type].filter(obj => obj.uid !== dependency.uid);
}
async function updateDependency() {
  const payload = {};
  Object.keys(dependency_cache.value).forEach((key) => {
    const uids = dependency_cache.value[key].map(obj => obj.uid);
    payload[key] = uids;
  });
  emit('update', payload);
  emit('close');
}
</script>

<template>
  <hawk-modal-container content_class="w-[600px] rounded-lg">
    <hawk-modal-header @close="emit('close')">
      <template #title>
        <div class="flex items-center">
          {{ $t('Dependencies') }}
        </div>
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <!-- Waiting On Tasks -->
      <DependencySkeleton
        :loading="loading"
        :restricted-tasks="restricted_tasks"
        :dependencies="dependency_cache.blocked_by"
        :asset="asset"
        dependency-type="Waiting On"
        class="pb-6"
        @delete="removeFromCache($event, 'blocked_by')"
        @add="addToCache($event, 'blocked_by')"
      />
      <!-- Blocking Tasks -->
      <DependencySkeleton
        :loading="loading"
        :restricted-tasks="restricted_tasks"
        :dependencies="dependency_cache.blocking"
        :asset="asset"
        dependency-type="Blocking"
        class="py-6 border-t border-b"
        @delete="removeFromCache($event, 'blocking')"
        @add="addToCache($event, 'blocking')"
      />
      <!-- Linked Tasks -->
      <DependencySkeleton
        :loading="loading"
        :restricted-tasks="restricted_tasks"
        :dependencies="dependency_cache.linked_uids"
        :asset="asset"
        dependency-type="Linked Tasks"
        class="pt-6"
        @delete="removeFromCache($event, 'linked_uids')"
        @add="addToCache($event, 'linked_uids')"
      />
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-between items-center">
      <template #right>
        <!-- Footer -->
        <div class="flex justify-end items-center">
          <hawk-button
            class="mr-5"
            type="outlined"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            color="primary"
            :disabled="isSaveDisabled"
            @click="updateDependency"
          >
            {{ $t('Save') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
