<script setup>
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { onMounted } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { clearAuthentication } from '~/common/utils/common.utils';
import { usePasswordRules } from '~/profile-settings/pages/password-rules.composable.js';

const {
  $services,
  $t,
  $toast,
  auth_store,
  route,
  router,
} = useCommonImports();
const is_loading = ref(false);

const errors_state = reactive({
  user: '',
  first_name: '',
  last_name: '',
  password: '',
  error: '',
});
let _decoded_token;
if (route.query.p)
  _decoded_token = jwtDecode(route.query.p);
const {
  oneLowerAndUpper,
  oneNumberAndSymbol,
  shouldNotContainFirstName,
  shouldNotContainLastName,
  shouldNotContainUserName,
  _oneLowerAndUpper,
  _oneNumberAndSymbol,
  _shouldNotContainFirstName,
  _shouldNotContainLastName,
  _shouldNotContainUserName,
} = usePasswordRules(
  _decoded_token?.email || 'kuldeepy@sensehawk.com',
  _decoded_token?.firstname || 'Kuldeep',
  _decoded_token?.lastname || 'Yadav',
);

const email$ = ref(null);
const form = ref({});
const decoded_token = ref(null);
const toggle_password_type = ref(false);
const toggle_confirm_password_type = ref(false);
const term_condition_unchecked = ref(true);
const rules_dropdown_active = ref(false);
const in_progress = ref(false);
const password_rules = ref([
  {
    type: 'length',
    description: 'The password length must be min 12 and max 64 characters.',
    fulfilled: false,
    condition: password => !password ? true : (password?.length >= 12 && password?.length <= 64),
  },
  {
    type: 'capitalize',
    description: _oneLowerAndUpper.message,
    fulfilled: false,
    condition: _oneLowerAndUpper.check,
  },
  {
    type: 'alpha_numeric',
    description: _oneNumberAndSymbol.message,
    fulfilled: false,
    condition: _oneNumberAndSymbol.check,
  },
  {
    type: 'does_not_contain_first_name',
    description: _shouldNotContainFirstName.message,
    fulfilled: false,
    condition: password => _shouldNotContainFirstName.check(password, form.value.first_name),
  },
  {
    type: 'does_not_contain_last_name',
    description: _shouldNotContainLastName.message,
    fulfilled: false,
    condition: password => _shouldNotContainLastName.check(password, form.value.last_name),
  },
  {
    type: 'does_not_contain_user_name',
    description: _shouldNotContainUserName.message,
    fulfilled: false,
    condition: password => _shouldNotContainUserName.check(password, form.value.email),
  },
]);

const fulfilled_rules = computed(() => {
  return password_rules.value.filter(rule => rule.fulfilled);
});

async function onSignUpClicked() {
  try {
    if (!route.query.p)
      return;

    if (decoded_token?.value?.email) {
      is_loading.value = true;
      await $services.auth.confirmEnrollment({
        query: { p: route.query.p },
        body: {
          ...form.value,
          email: decoded_token.value.email,
        },
      });
      $toast({
        title: 'Registered, please login to continue.',
        type: 'success',
      });
      is_loading.value = false;
      router.push({ name: 'sign-in' });
    }
    else {
      is_loading.value = true;
      const signup_response = await $services.auth.signUp({
        body: {
          ...form.value,
          timezone: dayjs.tz.guess(true),
        },
        ...(route.query.p && {
          query: { p: route.query.p },
        }),
      });
      auth_store.sign_up_details.formData = form.value;
      auth_store.sign_up_details.uid = signup_response.data.uid;

      router.push({ path: '/auth/verify-email' });
      is_loading.value = false;
    }
  }
  catch (error) {
    is_loading.value = false;
    if (error?.data?.code === 'CORE_50')
      errors_state.user = error.data.message;
    else if (error?.data?.code === 'CORE_60' || error?.data?.code === 'CORE_53')
      errors_state.password = error.data.message;
    else
      errors_state.error = error.data.message || 'Internal error';
    logger.log(error);
  }
}

function handlePasswordRulesCheck() {
  in_progress.value = true;
  const password = form.value.password;
  password_rules.value.forEach(rule => rule.fulfilled = rule.condition(password));
  if (password?.length === 0)
    in_progress.value = false;
}
function getProgressWidth() {
  const fulfilled_rules_length = fulfilled_rules.value.length || 1;
  return fulfilled_rules_length === 6 ? '100%' : `${fulfilled_rules_length * 100 / 6}%`;
}

function getPasswordSecureStatusColor() {
  const fulfilled_rules_length = fulfilled_rules.value.length;
  switch (fulfilled_rules_length) {
    case 1:
      return '#F95E68';
    case 2:
    case 3:
    case 4:
    case 5:
      return '#FDD244';
    case 6:
      return '#35CC62';
    default:
      return '#F95E68';
  }
}
onMounted(() => {
  clearAuthentication();
  if (route.query.p) {
    decoded_token.value = jwtDecode(route.query.p);
    form.value.email = decoded_token.value.email;
    email$.value.load(decoded_token.value.email);
  }
  else {
    router.push({ name: 'sign-in' });
  }
});
</script>

<template>
  <div class="min-h-screen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24">
      <div class="flex justify-center mb-6">
        <HawkIconTaskmapperIcon />
      </div>
      <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
        {{ $t('Welcome to TaskMapper') }}
      </p>
      <span class="text-gray-600">
        {{ $t('A Unified Platform for Solar Projects') }}
      </span>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-[0_2px_16px_8px_rgba(112,117,126,0.08)] rounded-xl sm:px-10">
        <Vueform
          v-model="form"
          :display-errors="false"
          :columns="{
            sm: { container: 12, label: 12, wrapper: 12 },
          }"
          size="sm"
          @submit="onSignUpClicked"
        >
          <div class="col-span-12 grid gap-4">
            <TextElement
              ref="email$"
              :disabled="!!(decoded_token && decoded_token.email)"
              name="email"
              label="Email"
              input-type="email"
              :placeholder="$t('Enter email')"
              autocomplete="off"
              rules="required|email"
              :add-class="{
                inputContainer: '!h-10',
              }"
              @input="errors_state.user = ''"
              @change="handlePasswordRulesCheck"
            >
              <template #after>
                <div v-if="errors_state.user" class="text-red-600 text-left">
                  {{ errors_state.user }}
                </div>
                <div v-else-if="errors_state.error" class="text-red-600 text-left">
                  {{ errors_state.error }}
                </div>
              </template>
            </TextElement>
            <TextElement
              name="first_name"
              :label="$t('First name')"
              :placeholder="$t('Enter first name')"
              autocomplete="off"
              rules="required"
              :add-class="{
                inputContainer: '!h-10',
              }"
              @input="errors_state.first_name = ''"
              @change="handlePasswordRulesCheck"
            />
            <TextElement
              name="last_name"
              :label="$t('Last name')"
              :placeholder="$t('Enter last name')"
              autocomplete="off"
              rules="required"
              :add-class="{
                inputContainer: '!h-10',
              }"
              @input="errors_state.last_name = ''"
              @change="handlePasswordRulesCheck"
            />
            <TextElement
              name="password"
              label="Password"
              :rules="[
                'required',
                'confirmed',
                'min:12',
                'max:64',
                oneLowerAndUpper,
                oneNumberAndSymbol,
                shouldNotContainFirstName,
                shouldNotContainLastName,
                shouldNotContainUserName,
              ]"
              class="mt-1 relative"
              autocomplete="off"
              :placeholder="$t('Enter password')"
              :add-class="{
                inputContainer: '!h-10',
              }"
              :input-type="toggle_password_type ? 'text' : 'password'"
              @click=" rules_dropdown_active = true;"
              @change="handlePasswordRulesCheck"
              @blur="rules_dropdown_active = false"
              @input="errors_state.password = ''"
            >
              <template #addon-after>
                <div class="cursor-pointer w-8 h-8 grid place-items-center" @click.stop="toggle_password_type = !toggle_password_type">
                  <IconHawkEye v-if="toggle_password_type" class="h-4 w-4" />
                  <IconHawkEyeOff v-else class="h-4 w-4" />
                </div>
              </template>
              <template #description>
                <div v-if="rules_dropdown_active && fulfilled_rules.length < password_rules.length" class="p-[12px] border border-gray-200 z-20 absolute bg-white w-full shadow-lg rounded-md flex flex-col gap-3">
                  <div v-for="rule in password_rules" :key="rule.description" class="flex items-center">
                    <span class="w-2 h-2 rounded-full border border-gray-300 mr-[8px]" :style="{ backgroundColor: rule.fulfilled ? 'green' : 'red' }" />
                    <p class="text-gray-700 font-medium text-xs text-left" :style="{ color: rule.fulfilled ? 'green' : 'black' }">
                      {{ rule.description }}
                    </p>
                  </div>
                </div>
                <div v-if="form.password && in_progress && fulfilled_rules.length >= 0" class="w-full mt-2">
                  <div class="progress_bar_container rounded-md h-[8px] w-full border border-gray-200 overflow-hidden">
                    <div class="progress_bar h-full" :style="{ width: getProgressWidth(), backgroundColor: getPasswordSecureStatusColor() }" />
                  </div>
                  <p class="text-gray-700 text-sm text-left">
                    Your password is <span class="font-semibold">{{ fulfilled_rules.length > password_rules.length - 1 ? 'secure' : 'weak' }}</span>
                  </p>
                </div>
              </template>
              <template #after>
                <div v-if="errors_state.password" class="text-red-600 text-left">
                  {{ errors_state.password }}
                </div>
              </template>
            </TextElement>
            <TextElement
              name="password_confirmation"
              label="Confirm Password"
              rules="required"
              class="mt-1"
              :placeholder="$t('Enter password again')"
              :add-class="{
                inputContainer: '!h-10',
              }"
              autocomplete="off"
              :input-type="toggle_confirm_password_type ? 'text' : 'password'"
            >
              <template #addon-after>
                <div class="cursor-pointer w-8 h-8 grid place-items-center" @click.stop="toggle_confirm_password_type = !toggle_confirm_password_type">
                  <IconHawkEye v-if="toggle_confirm_password_type" class="h-4 w-4" />
                  <IconHawkEyeOff v-else class="h-4 w-4" />
                </div>
              </template>
            </TextElement>
            <CheckboxElement
              name="checkbox"
              class="py-2"
              rules="required"
              @change="term_condition_unchecked = !term_condition_unchecked"
            >
              <div class="font-medium">
                {{ $t('I agree with the') }} <a href="https://sensehawk.com/privacy-policy" target="_blank" class="text-blue-700 hover:underline">{{ $t('Terms and conditions') }}</a>
              </div>
            </CheckboxElement>
            <ButtonElement
              name="submit"
              button-label="Sign up"
              :submits="true"
              :loading="is_loading"
              button-class="w-full bg-blue-600 !py-[9px] !text-sm"
            />
          </div>
        </Vueform>
        <div class="mt-4 flex justify-center text-gray-600 text-sm">
          {{ $t("Already have an account") }}?
          <div class="mx-2 cursor-pointer" @click="$router.push({ name: 'sign-in' })">
            <div class="text-blue-600 hover:text-blue-500 font-semibold">
              {{ $t("Sign in") }}
            </div>
          </div>
        </div>
      </div>
      <div class="py-12 text-gray-600 text-sm w-96 mx-auto">
        <div>
          {{ $t('By logging in to TaskMapper, you accept Sensehawk’s') }}
          <a href="https://sensehawk.com/" class="underline">{{ $t('Terms of Service') }}</a>,
          <a href="https://sensehawk.com/" class="underline">{{ $t('Privacy Policy') }}</a>
          and <a href="https://sensehawk.com/" class="underline"> {{ $t('Cookie Policy') }}</a>.
        </div>
        <HawkLanguageDropdown variant="full" class="my-3" />
        <div>© Sensehawk, Inc.</div>
      </div>
    </div>
  </div>
</template>
