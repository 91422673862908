<script setup>
import { groupBy, mapValues, orderBy, uniq } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import { useThermStore } from '~/therm/store/therm.store';

const { $t } = useCommonImports();
const { calculateWatts } = useThermHelperComposable();
const menu_items = [
  { uid: 'remaining_defects', label: $t('Remaining defects') },
  { uid: 'remaining_affected_capacity', label: $t('Remaining affected capacity') },
];
const therm_store = useThermStore('therm_dashboard');

const group_by = ref(menu_items[0]);

const state = reactive({
  data: [],
  columns: [],
});

function getRemainingDefects(value, type) {
  if (type === 'class') {
    if (!value || !value?.total)
      return 'bg-gray-50';
    if (value.current === value.total)
      return 'bg-red-50';
    if (!value.current)
      return 'bg-green-50';
  }
  else {
    if (!value?.total)
      return '0';
    return `${value.current}/${value.total}`;
  }
}

function init() {
  const feature_type_ids = [];
  const blocks_hash = {};
  const data = mapValues(
    groupBy(therm_store.report_defects, 'block'),
    items => mapValues(
      groupBy(items, 'type_id'),
      (subItems) => {
        return subItems.reduce((acc, item) => {
          blocks_hash[item.block] = item.block_name;
          feature_type_ids.push(item.type_id);
          acc.remaining_affected_capacity += item.remaining_affected_capacity;
          acc.total++;
          if (!['3004', '3005'].includes(item.status))
            acc.current++;
          return acc;
        }, { remaining_affected_capacity: 0, total: 0, current: 0 });
      },
    ),
  );
  const unique_feature_types_ids = uniq(feature_type_ids);
  state.columns = [
    {
      header: $t('Blocks'),
      accessorKey: 'block',
      id: 'block',
    },
    ...unique_feature_types_ids.map(type_id => ({
      header: therm_store.feature_types[type_id].name,
      id: String(type_id),
      accessorKey: String(type_id),
      custom_classes: '!p-0 !h-12',
    })),
  ];

  state.data = orderBy(Object.entries(data).map(([key, value]) => ({
    ...value,
    block: blocks_hash[key],
  })), f => f.block, ['asc']);
}

init();
</script>

<template>
  <div class="my-4">
    <div class="flex items-center justify-between">
      <div class="flex flex-col">
        <div class="text-md font-semibold text-gray-900">
          {{ $t('Defects classification') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Detailed breakdown of defects by each block across various parameters') }}
        </div>
      </div>
      <div class="flex items-center">
        <HawkMenu
          :items="menu_items.map(item => ({ ...item, on_click: () => (group_by = item) }))"
          :has_bordered_trigger="false"
          additional_trigger_classes="!ring-0 p-0 -mt-1"
          additional_dropdown_classes="max-h-60 scrollbar"
          position="fixed"
        >
          <template #trigger="{ open }">
            <hawk-button type="outlined" size="xs" class="text-sm font-semibold !px-2 !py-0 focus:!ring-0">
              {{ group_by.label }}
              <IconHawkChevronDown
                class="text-white transition-transform"
                :class="{ 'rotate-180': open }"
              />
            </hawk-button>
          </template>
        </HawkMenu>
      </div>
    </div>
    <div class="border border-gray-100 rounded-[8px] w-[calc(100vw-32px)] scrollbar mt-4">
      <HawkTable
        :data="state.data"
        :columns="state.columns"
        :default_height="300"
        is_gapless
        cell_height="40px"
        disable_resize
        :show_menu_header="false"
      >
        <template
          v-for="(type) in state.columns.filter(col => col.id !== 'block')"
          #[type.id]="{ data: { row: { original } } }"
          :key="type.id"
        >
          <div
            v-if="group_by.uid === 'remaining_defects'"
            class="flex items-center h-12 w-full text-sm text-gray-600 px-6"
            :class="getRemainingDefects(original[type.id], 'class')"
          >
            {{ getRemainingDefects(original[type.id], 'value') }}
          </div>
          <div
            v-else
            class="flex items-center h-12 w-full text-sm text-gray-600 px-6"
            :class="{ 'bg-gray-50': original[type.id]?.remaining_affected_capacity === undefined }"
          >
            {{ original[type.id]?.remaining_affected_capacity === undefined ? '0' : calculateWatts(original[type.id]?.remaining_affected_capacity) }}
            <IconHawkCheckCircleGreen v-if="original[type.id]?.remaining_affected_capacity === 0" class="ml-2" />
          </div>
        </template>
      </HawkTable>
    </div>
  </div>
</template>
