<script setup>
import { omit } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import SmNoResultsIllustration from '~/system-model/atoms/sm-no-results-illustration.vue';
import SmConnectionForm from '~/system-model/components/forms/sm-connection-form.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

defineProps({
  connections: {
    type: Array,
    default: () => [],
  },
});

const { $t } = useCommonImports();
const system_model_store = useSystemModelStore();
const { can_modify_resources, active_component } = storeToRefs(system_model_store);

const columns = [
  {
    accessorKey: 'child_name',
    header: $t('Component'),
    id: 'child_name',
  },
  {
    accessorKey: 'value',
    header: '',
    id: 'value',
  },
];

const { open: openConnectionModal, close: closeConnectionModal, patchOptions, options: connectionOptions } = useModal({
  component: SmConnectionForm,
  attrs: {
    onClose() {
      closeConnectionModal();
    },
  },
});

const connection_delete_modal = useModal({
  component: HawkDeletePopup,
});

function openDeleteConnectionModal(data) {
  connection_delete_modal.patchOptions({
    attrs: {
      header: $t('Delete connection'),
      content: 'Are you sure you want to delete this connection?',
      onClose() {
        connection_delete_modal.close();
      },
      confirm: async () => {
        try {
          await system_model_store.remove_connection({ parent_uid: data.uid, child_uid: data.child_uid });
          await system_model_store.set_active_component_details({
            component_id: data.child_uid,
          });
          connection_delete_modal.close();
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  });
  connection_delete_modal.open();
}

function updateConnectionModal(data) {
  patchOptions({
    attrs: {
      onClose() {
        closeConnectionModal();
      },
      onClosed() {
        const attrs = omit(connectionOptions.attrs, ['connection']); // reset attrs
        connectionOptions.attrs = attrs;
      },
      connection: data,
    },
  });
  openConnectionModal();
}
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="my-6">
      <template #left>
        <div class="flex items-center gap-2 text-gray-900 font-semibold text-lg">
          <span>
            {{ $t('Connections') }}
          </span>
          <IconHawkInfoCircle
            v-tippy="`Setup components to which ${active_component?.name} is connected to upstream i.e parents`"
            class="cursor-pointer w-4 h-4"
          />
        </div>
      </template>
      <template #right>
        <hawkButton
          v-if="can_modify_resources"
          color="primary"
          type="outlined"
          class="font-semibold"
          @click="openConnectionModal()"
        >
          <IconHawkPlusGray class="h-3 w-3" /> {{ $t('New Connection') }}
        </hawkButton>
      </template>
    </HawkPageSecondaryHeader>
    <SmNoResultsIllustration v-if="!connections.length" title="No #Connections found" />
    <HawkTable
      v-else
      class="!px-0"
      :data="connections"
      :pagination_config="{ totalRows: connections.length, pageSize: 25 }"
      :columns="columns"
      :show_menu_header="false"
      :enable_infinite_scroll="true"
      :non_sortable_columns="['value', 'parent_name', 'delete']"
      @row-clicked="updateConnectionModal"
    >
      <template #child_name="{ data }">
        <div class="group">
          <span class="text-gray-900 font-medium text-sm block">
            {{ data.row.original.child_name }}
          </span>
          <span class="text-gray-600 text-xs lowercase">
            {{ data.row.original.connections }} {{ $t('Connections') }}
          </span>
        </div>
      </template>
      <template #value="{ data: { row: { original } } }">
        <div class="flex items-center w-full">
          <div class="flex-1 flex items-center">
            <span v-if="original.layouts.layout_x !== null && original.layouts.layout_y !== null" class="flex items-center bg-gray-100 text-[10px] p-2 rounded">
              {{ original.layouts.layout_x }} x {{ original.layouts.layout_y }} <IconHawkArrowRight class="ml-2 h-3 w-3" />
            </span>
            <span class="ml-8 text-gray-900 font-medium">
              {{ original.name }}
            </span>
          </div>
          <div class="text-base p-2 invisible group-hover:visible hover:bg-gray-200 hover:rounded-lg" @click.stop="openDeleteConnectionModal(original)">
            <IconHawkTrashThree class="text-gray-500" />
          </div>
        </div>
      </template>
    </HawkTable>
  </div>
</template>
