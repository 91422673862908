import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';

export const useDashboardThermStore = defineStore('dashboard_therm', {
  state: () => ({
    therm_configuration: {},
    type_options: [
      ['defects_by_inverter', 'Defects By Inverter'],
      ['temperature_histogram', 'Temperature Histogram'],
      ['severity_graph', 'Defects By Severity'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
        description: item[2],
      };
    }),
    view_options: [],
    group_options: [],
    is_loading_groups: false,
    data_set: false,
  }),
  getters: {
    therm_view_uid: state => state.therm_configuration.view || null,
    therm_schema(state) {
      if (!state.data_set)
        return {};

      return {
        type: {
          type: 'select',
          items: state.type_options,
          search: true,
          native: false,
          label: 'Type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: ['required'],
          default: state.therm_configuration.type || state.type_options[0].value || null,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        view: {
          type: 'select',
          items: state.view_options,
          search: true,
          native: false,
          label: 'View',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: ['required'],

          default: state.therm_configuration.view || state.view_options?.[0]?.value || null,
        },
        module: {
          type: 'hidden',
          default: 'therm',
        },
      };
    },
  },
  actions: {
    update_therm_configuration(data) {
      this.therm_configuration = { ...data };
    },
    async set_views() {
      const dashboard_store = useDashboardStore();

      const { data } = await this.$services.therm.getAll({
        attribute: 'views',
        query: {
          assetUid: dashboard_store.widget_asset,
          metrics: true,
          complete: true,
        },
      });

      this.view_options = data.map((c) => {
        return {
          value: c.uid,
          label: c.name,
        };
      });
    },
    async set_groups() {
      if (!this.therm_configuration.view)
        return;
      this.is_loading_groups = true;

      const { data } = await this.$services.therm.getAll({
        attribute: `views/${this.therm_configuration.view}`,
        query: {
          hierarchy: true,
          projects: true,
          complete: true,
        },
      });

      this.group_options = data?.groups || [];
      this.is_loading_groups = false;
    },
    chart_type(widget_type) {
      if (widget_type === 'defects_by_inverter')
        return 'vertical_graph';

      else if (widget_type === 'temperature_histogram')
        return 'vertical_graph';

      else if (widget_type === 'severity_graph')
        return 'donut';

      else
        return widget_type;
    },
    parse_therm_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const authStore = useAuthStore();

      const payload = {
        properties: {
          source: {
            type: 'therm',
            service: 'therm',
          },
          config: widget_data.chart_config || {},
        },
        filters: {
          organization: authStore.current_organization?.uid,
          asset_uid: widget_data.asset_id,
        },
        view: widget_data.view,
        type: widget_data.type,
        group: widget_data.group,
        chart: this.chart_type(widget_data.type),
        minW: 4,
        sub_tasks: false,
        scope: 'organization',
      };

      return payload;
    },
    unparse_therm_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const data = {};
      data.name = widget_data.properties?.name || 'Untitled';
      data.asset_id = widget_data.filters?.asset_uid || null;
      data.type = widget_data.type;
      data.view = widget_data.view;
      data.group = widget_data.group;
      data.chart_config = widget_data.properties?.config || null;
      data.module = 'therm';

      return data;
    },
    async set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
        || widget_configuration?.module !== 'therm'
      ) {
        this.data_set = true;
        this.therm_configuration = {
          ...this.therm_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      this.therm_configuration = { ...widget_configuration };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardThermStore, import.meta.hot));
