<script setup>
import { useCommonStore } from '~/common/stores/common.store';
import { getUserFullName } from '~/common/utils/common.utils.js';

const props = defineProps({
  uid: {
    type: String,
    required: true,
  },
  time_stamp: {
    type: [Date, String],
  },
});

const $date = inject('$date');

const common_store = useCommonStore();

const organization_user = computed(() => (common_store.get_user(props?.uid)));
const internal_user = computed(() => common_store?.internal_users_map?.[props?.uid]);
</script>

<template>
  <div class="text-sm">
    <div class="flex gap-3">
      <HawkMembers :members="uid" />
      <div class="flex flex-col w-full">
        <div class="flex justify-between">
          <div v-if="getUserFullName(organization_user || internal_user || null)" class="text-sm font-medium">
            {{ getUserFullName(organization_user || internal_user || null) }}
          </div>
          <div v-else class="text-sm font-medium">
            Unknown
          </div>
          <slot name="comment-menu" />
        </div>
        <span v-if="time_stamp" class="text-xs text-gray-500">
          {{ $date(time_stamp, 'DATETIME_LONG') }}
        </span>
      </div>
    </div>
  </div>
</template>
