<script setup>
const emit = defineEmits(['close', 'confirm']);

const form$ = ref(null);
</script>

<template>
  <HawkModalContainer>
    <Vueform ref="form$" size="sm" class="max-w-[600px]">
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              {{ $t("Activate version") }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div class="flex text-sm text-gray-600 mb-8">
            {{ $t("enable-reset-progress-data-irreversible") }}
          </div>
          <ToggleElement name="override_actuals" :label="$t('Override actuals')" class="h-9 mr-40" />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                class="mr-4"
                :secondary="true"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="confirm"
                @click="emit('confirm', form$.data.override_actuals)"
              >
                {{ $t('Confirm') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
