import { defineStore } from 'pinia';
import { keyBy } from 'lodash-es';

export function useTransmittalWorkflowStore(key) {
  return defineStore(key ? `${key}-workflow` : 'transmittal-workflow', {
    state: () => ({
      workflow_map: {},
    }),
    getters: {
      workflows: (state) => {
        return Object.values(state.workflow_map);
      },
    },
    actions: {
      async set_workflow(payload = {}) {
        this.filters = payload;
        const { data } = await this.$services.dms.getAll({
          attribute: 'workflow-templates',
          query: payload.query,
        });
        this.workflow_map = keyBy(data.workflows, 'uid');
      },
      async update_workflow(uid, payload = {}) {
        const { data } = await this.$services.dms.patch({
          attribute: `workflow-templates/${uid}`,
          body: { workflow: payload },
        });
        data.forEach((workflow) => {
          this.workflow_map[workflow.uid] = workflow;
        });
      },
      async create_workflow(payload = {}) {
        const { data } = await this.$services.dms.post({
          attribute: 'workflow-templates',
          body: { workflow: payload },
        });
        data.forEach((workflow) => {
          this.workflow_map[workflow.uid] = workflow;
        });
      },
      async delete_workflow(uid) {
        await this.$services.dms.delete({
          attribute: `workflow-templates/${uid}`,
        });
        delete this.workflow_map[uid];
      },
    },
  })();
}
