import { useModal } from 'vue-final-modal';
import HawkInfoPopup from '~/common/components/molecules/hawk-info-popup.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { currentOrganizationCookie, purgeCommonStore } from '~/common/utils/common.utils';

export async function openJoinOrganizationInfo(organization_uid, name, icon, callBack) {
  const common_store = useCommonStore();
  if (organization_uid) {
    purgeCommonStore();
    currentOrganizationCookie('set', organization_uid);
    await common_store.initialize({ forceUpdate: true });
  }
  const info_popup = useModal({
    component: HawkInfoPopup,
  });
  info_popup.patchOptions({
    attrs: {
      type: 'success',
      header_icon: icon,
      title: 'You joined an organization.',
      description: `It’s great to see you joined ${name} organization successfully.`,
      action_button: 'Okay',
      onClose() {
        callBack();
        info_popup.close();
      },
      onActionButton() {
        callBack();
        info_popup.close();
      },
    },
  });
  info_popup.open();
}
