<script setup>
import { watch } from 'vue';
import { round, sortBy } from 'lodash-es';
import { import_fusioncharts } from '~/common/utils/package.utils.js';
import { color_by_menu } from '~/terra/utils/charts-constants.js';
import { safeMultiplication } from '~/terra/utils/terra.utils';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['selectProject', 'selectField', 'trackViewEvent']);
const state = reactive({
  heatmap_color_by: 'Status',
  fc_instance: null,
});
watch(() => [props.data?.selected_workflow, props.data?.re_render_heatmap], (val, old) => {
  if (val?.[0] !== old?.[0] || val?.[1] !== old?.[1])
    loadHeatMap();
}, { immediate: true });
function getStatusTextMap(item) {
  if (state.heatmap_color_by === 'Status')
    return {
      'Not started': 'Activity is not yet started',
      'Behind': `Activity is behind by ${Math.abs(round(item.difference_percent_complete * 100))}%`,
      'On pace': 'Activity is on pace',
      'Ahead': `Activity is ahead by ${round(item.difference_percent_complete * 100)}%`,
      'Completed': 'Activity is completed',
      'NA': 'NA',
    };
  if (state.heatmap_color_by === 'Work rate')
    return {
      'Not started': `${item.planned_work_rate} ${item.units}/day expected`,
      'Behind': `${Math.abs(round(item.difference_work_rate))} ${item.units}/day less. Need ${round(item.required_work_rate)}  ${item.units}/day to finish on time`,
      'Ahead': `${round(item.difference_work_rate)} ${item.units}/day more. Need ${round(item.required_work_rate)}  ${item.units}/day to finish on time`,
      'Completed': `${round(item.actual_work_rate)} ${item.units}/day at completion which is ${Math.abs(round(item.difference_work_rate))} ${item.units}/day ${item.difference_work_rate < 0 ? 'less' : 'more'} than planned`,
      'NA': 'NA',
    };
}
function createSelectedWorkflowPayload(item, number_status_map) {
  const payload = {
    rowId: item.field,
    columnId: item.project,
    value: null,
    displayValue: null,
    toolText: null,
    field_index: item.field_index,
  };
  const project_metrics_key = `${item.workflow}:${item.project}:${item.field}`;
  const status_text_map = getStatusTextMap(item);
  if (state.heatmap_color_by === 'Status') {
    payload.value = number_status_map[props.data.field_project_metrics[project_metrics_key]?.status] ?? 0;
    payload.displayValue = `${round(safeMultiplication(props.data.field_project_metrics[project_metrics_key]?.percent_complete, 100)) ?? 0}%`;
    payload.toolText = `${payload.displayValue} -> ${status_text_map[props.data.field_project_metrics[project_metrics_key]?.status ?? 'NA']}`;
  }
  else if (state.heatmap_color_by === 'Work rate') {
    payload.value = (number_status_map[props.data.field_project_metrics[project_metrics_key]?.work_rate_status] ?? 0);
    payload.displayValue = `${round(props.data.field_project_metrics[project_metrics_key]?.actual_work_rate) ?? 0}`;
    payload.toolText = `${payload.displayValue} -> ${status_text_map[props.data.field_project_metrics[project_metrics_key]?.work_rate_status ?? 'NA']}`;
  }
  else {
    payload.value = round(safeMultiplication(props.data.field_project_metrics[project_metrics_key]?.percent_complete, 100));
    payload.displayValue = `${round(safeMultiplication(props.data.field_project_metrics[project_metrics_key]?.percent_complete, 100)) ?? 0}%`;
    payload.toolText = `${payload.displayValue} -> ${round(safeMultiplication(props.data.field_project_metrics[project_metrics_key]?.percent_complete, 100))}% completed`;
  }
  return payload;
}
async function loadHeatMap() {
  try {
    const { VueFusionChartsComponent, FusionCharts, PowerCharts } = await import_fusioncharts();
    const color_range = {
      gradient: '0',
      minvalue: '0',
      code: 'F04438',
      startlabel: '0-20%',
      endlabel: '80-100%',
      color: [{
        code: 'F04438',
        minvalue: '0',
        maxvalue: '20',
        label: '0-20%',
      }, {
        code: 'F79009',
        minvalue: '20',
        maxvalue: '40',
        label: '20-40%',
      }, {
        code: '85E13A',
        minvalue: '40',
        maxvalue: '60',
        label: '40-60%',
      },
      {
        code: '12B76A',
        minvalue: '60',
        maxvalue: '80',
        label: '60-80%',
      },
      {
        code: '12B76A',
        minvalue: '80',
        maxvalue: '100',
        label: '80-100%',
      },
      ],

    };
    const number_status_map = {
      'Not started': 0,
      'Behind': 1,
      'On pace': 2,
      'Ahead': 3,
      'Completed': 4,
    };

    const status_color_range = {
      gradient: '0',
      minvalue: '0',
      code: '98A2B3',
      startlabel: 'Not started',
      endlabel: 'Completed',
      color: [
        {
          code: '98A2B3',
          minvalue: '0',
          maxvalue: '0',
          label: 'Not started',
        }, {
          code: 'F04438',
          minvalue: '1',
          maxvalue: '1',
          label: 'Behind',
        },
        ...(state.heatmap_color_by === 'Status'
          ? [{
              code: 'FAC515',
              minvalue: '2',
              maxvalue: '2',
              label: 'On pace',
            }]
          : []),

        {
          code: '53B1FD',
          minvalue: '3',
          maxvalue: '3',
          label: 'Ahead',
        },
        {
          code: '12B76A',
          minvalue: '4',
          maxvalue: '4',
          label: 'Completed',
        },
      ],
    };

    const data_set = [];
    if (props.data.selected_workflow_data) {
      props.data.selected_workflow_data.forEach((item) => {
        if (props.data?.unique_projects?.includes(item.project)) {
          const payload = createSelectedWorkflowPayload(item, number_status_map);
          data_set.push(payload);
        }
      });

      VueFusionChartsComponent(FusionCharts, PowerCharts);
      state.fc_instance = new FusionCharts({
        type: 'heatmap',
        renderAt: 'workflow-metrics-heatmap',
        width: '100%',
        height: '400',
        dataSource: {
          chart: {
            bgColor: '#FFFFFF',
            legendBgColor: '#FFFFFF',
            legendBorderColor: '#FFFFFF',
            xAxisName: 'Blocks',
            yAxisName: 'Activities',
            plottooltext: '$toolText',
            showplotborder: '1',
            showValues: '1',
            xAxisLabelsOnTop: '1',
            theme: 'fusion',

            showShadow: '0',
            showBorder: '0',
            borderColor: '#EAECF0',
            plotBorderColor: '#EAECF0',
            plotBorderThickness: '1',
            canvasBorderThickness: '0',

            baseFont: 'Inter',
            baseFontSize: 12,
            baseFontColor: '#344054',

            legendPosition: window.innerWidth < 768 ? 'top-left' : 'top-right',
            showLegend: '1',
            interactiveLegend: '1',
            plotHighlightEffect: 'hide',
            legendBgAlpha: '0',
            legendBorderAlpha: '0',
            legendShadow: '0',
            drawCustomLegendIcon: '1',
            legendIconBorderColor: '#FFFFFF',
            legendIconBorderThickness: '0',
            legendIconSides: '0',

          },
          dataset: [{
            data: sortBy(data_set, ['field_index', 'columnId']),
          }],
          colorrange: (state.heatmap_color_by === 'Status' || state.heatmap_color_by === 'Work rate') ? status_color_range : color_range,
        },
        events: {
          dataPlotClick(eventObj, dataObj) {
            emit('selectProject', dataObj.columnId);
            emit('selectField', dataObj.rowId);
            state.selected_project_for_range_metrics = dataObj.columnId;
            state.selected_range_metrics_field = dataObj.rowId;
          },
        },

      });
      state.fc_instance.render();
    }
  }
  catch (error) {
    logger.error('Error while loading chart', error);
  }
}

function updateHeatMapColorBy(e) {
  state.heatmap_color_by = e;
  loadHeatMap();
  emit('trackViewEvent', state.heatmap_color_by);
}
</script>

<template>
  <div class="bg-white p-5 border rounded-2xl border-gray-200">
    <div class="flex items-center justify-between">
      <div class="text-base font-semibold">
        {{ data.workflow_metrics[data.selected_workflow]?.name }}
      </div>
      <Vueform>
        <SelectElement
          name="map_color_by"
          size="sm"
          :default="state.heatmap_color_by"
          :native="false"
          :can-deselect="false"
          :can-clear="false"
          :items="color_by_menu"
          @select="updateHeatMapColorBy($event)"
        />
      </Vueform>
    </div>

    <hr class="my-4">
    <div id="workflow-metrics-heatmap" />
  </div>
</template>
