<script setup>
import { useDocumentViewer } from '~/plans/composables/useDocumentViewer';
import { getFileExtension } from '~/common/utils/common.utils.js';
import { useDocumentStore } from '~/dms/store/document.store';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

const props = defineProps({
  document: {
    type: Object,
    default: () => ({}),
  },
  show_viewer: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['closeAttachment']);
const $t = inject('$t');
const $toast = inject('$toast');
const route = useRoute();
const document_store = useDocumentStore();
const document_crud = useDocumentCrud();

const { setup_core } = useDocumentViewer({});

const is_setting_up_document_viewer = ref(false);

const state = reactive({
  modified_attachment: null,
});

if (!window?.Core)
  setup_core().then(() => is_setting_up_document_viewer.value = true);

const document_type = computed(() => props.document.type);

watch(() => props.show_viewer, async (value) => {
  if (value)
    openAttachment();
});

async function openAttachment() {
  if (getSupportedAttachment(props.document)) {
    state.modified_attachment = {
      uid: props.document.uid,
      url: props.document.presigned_url,
      file_name: props.document.file_name,
    };
  }
  else {
    emit('closeAttachment');
    $toast({
      title: $t('Unsupported file'),
      text: $t('This file is not supported for viewing on the platform. Download to view it.'),
      type: 'error',
      position: 'bottom-right',
    });
  }
}

function getSupportedAttachment(file) {
  const extension = getFileExtension(file.file_name || file.name);
  if (!window.Core)
    return true;

  return window.Core.SupportedFileFormats.CLIENT.includes(extension?.toLowerCase());
}

function onDownload() {
  if (state.modified_attachment?.url)
    window.open(state.modified_attachment?.url, '_blank');
  else
    document_crud.itemDownload({ file: state.modified_attachment });
}

function onClose() {
  state.modified_attachment = null;
  emit('closeAttachment');
}
</script>

<template>
  <slot
    :is_attachment_supported="getSupportedAttachment(document)"
  />
  <HawkAttachmentViewer
    v-if="show_viewer"
    :key="state.modified_attachment?.url"
    :attachment="state.modified_attachment"
    @download="onDownload"
    @close="onClose"
  />
</template>
