<script setup>
import { useModal } from 'vue-final-modal';
import { storeToRefs } from 'pinia';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { searchData } from '~/common/utils/common.utils';
import SmComponentBadgeGroup from '~/system-model/molecules/sm-component-badge-group.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import SmTemplateContextMenu from '~/system-model/molecules/sm-template-context-menu.vue';
import SmTemplateForm from '~/system-model/components/forms/sm-template-form.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  is_loading: {
    type: Boolean,
  },
});

const { route, router, $t, auth_store } = useCommonImports();
const system_model_store = useSystemModelStore();
const { can_modify_resources } = storeToRefs(system_model_store);

const state = reactive({
  search: '',
});

const table_columns = [
  {
    accessorKey: 'name',
    header: $t('Template Name'),
    id: 'name',
  },
  {
    accessorKey: 'components',
    header: $t('Components'),
    id: 'components',
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '2',
    show_on_hover: 'true',
  },
];

const template_modal = useModal({
  component: SmTemplateForm,
  attrs: {
    onClose() {
      template_modal.close();
    },
  },
});

const templates_data = computed(() => {
  return searchData(system_model_store?.templates, state.search);
});

function handleCellClick(data) {
  if (data.column.id === 'name')
    router.push({ name: 'sm-template-details', params: { asset_id: route.params.asset_id, template_id: data.row.original.uid } });
}
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="mb-4">
      <template #left>
        <HawkSearchInput v-model="state.search" placeholder="Search" />
      </template>
      <template #right>
        <hawkButton
          v-if="can_modify_resources"
          color="primary" @click="template_modal.open()"
        >
          <IconHawkPlusWhite class="w-3 h-3" /> {{ $t('New Template') }}
        </hawkButton>
      </template>
    </HawkPageSecondaryHeader>
    <TableWrapperVue v-if="templates_data.length || props.is_loading" class="border-0">
      <HawkTable
        :data="templates_data"
        :columns="table_columns"
        :is_loading="props.is_loading"
        :show_menu_header="false"
        :non_sortable_columns="['context_menu', 'components']"
        is_gapless
        @cellClick="handleCellClick"
      >
        <template #name="{ data: { row: { original: { name, default: default_template } } } }">
          <p class="font-medium font-sm">
            {{ name }}
            <HawkBadge v-if="default_template" color="green">
              {{ $t('Current Version') }}
            </HawkBadge>
          </p>
        </template>
        <template #components="{ data: { row: { original } } }">
          <div class="flex items-center w-full">
            <SmComponentBadgeGroup class="flex-auto" :items="original.components" />
          </div>
        </template>
        <template #context_menu="{ data }">
          <SmTemplateContextMenu v-if="can_modify_resources" :template="data.row.original" />
        </template>
      </HawkTable>
    </TableWrapperVue>
    <div v-else>
      <HawkIllustrations v-if="state.search && !templates_data.length" type="no-results" />
      <HawkIllustrations v-else type="no-data" for="system-model" />
    </div>
  </div>
</template>
