export function truncate(str, length = 20, ellipses = '...') {
  if (!str)
    return '';

  const _str = String(str);

  if (_str.length <= length)
    return str;

  return _str.slice(0, length) + ellipses;
}

export function truncateMiddle(str, length = 20, ellipses = '...') {
  if (!str)
    return '';

  const _str = String(str);

  if (_str.length <= length)
    return str;

  return _str.slice(0, length / 2)
    + ellipses
    + _str.slice(_str.length - length / 2, _str.length);
}
