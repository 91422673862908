<script setup>
const props = defineProps({});

const attachments = computed(() => props.el$?.slots?.attachments ?? []);

function attachmentDownloadHandler(file) {
  const file_url = file.url || file.service?.url || '';
  window.open(file_url, '_blank');
}
</script>

<template>
  <div
    v-if="attachments?.length"
    class="w-[350px] xl:w-[700px] mt-4"
  >
    <div class="pb-1">
      {{ $t('Attachments') }}:
    </div>
    <hawk-attachments-list
      additional_classes="grid-cols-1 xl:grid-cols-2 gap-2"
      :items="attachments"
      :can_delete="false"
      :filename_truncate_length="30"
      @download="attachmentDownloadHandler"
    />
  </div>
</template>
