<script setup>
const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const SHEET_VERB_MAP = {
  CREATED: 'created the sheet',
  UPDATED: 'updated the sheet',
  DELETED: 'deleted the sheet',
  VERSION_ADDED: 'added a version',
  VERSION_DELETED: 'deleted a version',
  CURRENT_VERSION_CHANGED: 'changed the version',
  DRAWING_CHANGED: activity => `moved the sheet to ${activity.meta?.drawing?.to?.name}`,
  ACCESS_CHANGED: 'changed the access',
};

function getVerbMap(resource) {
  if (resource === 'SHEET')
    return SHEET_VERB_MAP;

  const resource_name_formatted = resource.toLowerCase();
  return {
    CREATED: `created the ${resource_name_formatted}`,
    UPDATED: `updated the ${resource_name_formatted}`,
    DELETED: `deleted the ${resource_name_formatted}`,
  };
}

function getActivityText(activity) {
  const verb_map = getVerbMap(activity.resource_name);

  if (typeof verb_map[activity.verb] === 'function')
    return verb_map[activity.verb](activity);
  return verb_map[activity.verb];
}
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="Object.keys(getVerbMap(activity.resource_name)).includes(activity.verb)" class="text-sm">
      {{ getActivityText(activity) }}
    </div>
  </div>
</template>
