<script setup>
import { onMounted, onUpdated, ref } from 'vue';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { useThermStore } from '../store/therm.store';
import ThermScanDates from './therm-scan-dates.vue';

const props = defineProps({
  side: {
    type: String,
    default: 'left',
  },
  therm_store_name: {
    type: String,
    default: 'therm',
  },
});
const therm_store = useThermStore(props.therm_store_name);
const active = ref(false);
const route = useRoute();
const select_projects$ = ref(null);
const all_checked = computed(() => {
  return therm_store.active_projects?.length === Object.values(therm_store.active_group?.projects || {}).length;
});

onMounted(() => {
  select_projects$.value?.update(all_checked.value);
});

onUpdated(() => {
  select_projects$.value?.update(all_checked.value);
});

if (route.name === 'therm')
  active.value = true;

const get_active_group_name = computed(() => {
  const active_group_value = therm_store.active_group;
  if (active_group_value)
    return (
      (active_group_value?.properties?.date
              && dayjs(active_group_value.properties.date)?.utc()?.format('DD MMM YYYY'))
            || active_group_value.name
    );
  else return '';
});

// Methods
async function toggleAllProjects(e) {
  try {
    therm_store.loader_enabled = true;
    therm_store.selected_features = [];
    const response = await Promise.all(
      Object.values(therm_store.active_group.projects).map((project) => {
        return therm_store.set_therm_active_project({
          project,
          is_active: e.target.checked,
          in_toggling_all_projects_context: true,
        });
      }),
    );
    therm_store.last_selected_project = response[response.length - 1];
    await therm_store.fetch_therm_tasks();
    therm_store.update_map_features();

    therm_store.loader_enabled = false;
  }
  catch (err) {
    therm_store.loader_enabled = false;
  }
}

async function toggleProject(name) {
  logger.log(props.therm_store_name);
  try {
    therm_store.loader_enabled = true;
    therm_store.selected_features = [];
    const project = therm_store.active_group.projects[
      therm_store.active_group.project_name_hash[name]?.uid
    ];
    await therm_store.set_therm_active_project({
      project,
      is_active: !project.is_active,
    });

    therm_store.loader_enabled = false;
  }
  catch (err) {
    therm_store.loader_enabled = false;
  }
}
function isProjectActive(name) {
  if (therm_store.active_group?.project_name_hash[name])
    return therm_store.active_group.projects[therm_store.active_group.project_name_hash[name]?.uid].is_active;
  return false;
}
</script>

<template>
  <div
    class="absolute z-20 shadow-md rounded-lg"
    :class="[side === 'left' ? (route.name === 'therm' ? 'left-16' : 'left-5') : 'right-5']"
  >
    <div
      :class="[active ? 'border-1 border-solid border-[#84CAFF] shadow-[0_1px_2px_rgba(16,24,40,0.05),0_0_0_4px_rgba(209,233,255,1)]' : '']"
      class="w-60 h-16 rounded-lg border border-solid border-gray-300 bg-white flex items-center justify-between cursor-pointer"
      @click="active = !active"
    >
      <div class="scan-project-info flex flex-col w-full">
        <div class="flex items-center my-2 ml-[14px]">
          <span class="text-sm whitespace-nowrap  mr-1 text-gray-500 font-normal ">{{ $t('Scan date') }}: </span>
          <span class="text-gray-900 font-medium text-sm ml-1">
            <HawkText :content="get_active_group_name" :length="12" />
          </span>
        </div>
        <div class="flex items-center mb-2 ml-[14px]">
          <span class="text-sm  text-gray-500">{{ $t('Blocks') }}: </span>
          <span class="text-gray-900 text-sm font-medium ml-8">{{ therm_store.active_projects && therm_store.active_projects.length }} {{ $t('selected') }}</span>
        </div>
      </div>
      <div class="flex items-center justify-center mr-[19px]">
        <IconHawkChevronDown v-if="active" :style="{ transform: 'rotate(180deg)' }" class="text-gray-500" />
        <IconHawkChevronDown v-else class="text-gray-500" />
      </div>
    </div>
    <div v-if="active" class="border-t z-30 rounded-lg border-gray-300 bg-white py-2.5 px-[14px] mt-[4px] max-h-52 overflow-auto scrollbar w-60">
      <ThermScanDates :therm_store_name="therm_store_name" />
      <p class="font-semibold text-sm mb-3 leading-5 text-gray-700 mt-5">
        {{ $t('Blocks') }}
      </p>
      <p class="mb-3">
        <Vueform size="sm">
          <CheckboxgroupElement
            ref="select_projects$"
            :items="[
              {
                value: 0,
                label: $t('Select all'),
              },
            ]"
            @input="toggleAllProjects"
          />
        </Vueform>
      </p>
      <div class="grid grid-cols-3 gap-2">
        <!-- Update css classes for applying border blue on active project -->
        <div
          v-for="(project, index) in therm_store.unique_projects_list"
          :key="index"
          @click="toggleProject(project)"
        >
          <div
            class="border border-solid h-6 w-17 rounded-lg grid place-items-center"
            :class="[isProjectActive(project) ? 'text-black text-gray-700 border-primary-600' : 'text-black border-gray-300',
                     therm_store.active_group && !therm_store.active_group.project_name_hash[project] ? 'text-gray-300 text-sm font-medium border-gray-300 bg-white pointer-events-none rounded-lg' : 'cursor-pointer']"
          >
            <HawkText
              class="text-xs font-medium"
              :class="[isProjectActive(project) ? ' text-gray-700' : 'text-black',
                       therm_store.active_group && !therm_store.active_group.project_name_hash[project] ? 'text-gray-300' : '']"
              :content="project" :length="6"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
