<script setup>
import { cloneDeep, isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useMap } from '~/common/composables/mapbox/maps';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';

import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});
const dashboard_forms_store = useDashboardFormsStore();
const dashboard_store = useDashboardStore();
const router = useRouter();
const route = useRoute();
const {
  forms_v2_filters,
} = storeToRefs(dashboard_forms_store);
const { parseRulesDateData } = useFamConstants();

const $services = inject('$services');
const loading = ref(false);
const map_data = ref(null);
const map_instance = ref(null);

const { initMapbox, loadMapBoxPackage, addMapboxToken, setSources, setLayers, loadImages, removeLayers, removeSources, removeMapboxInstance } = useMap({}, async (event_data, event_name) => {
  if (event_name === 'loaded') {
    const { sources, layers } = sourcesLayers();

    loadImages(map_instance.value, '/img/form-icon.png', 'form-icon', { sdf: true });

    removeSources(sources, map_instance.value);
    removeLayers(layers, map_instance.value);
    setSources(sources, map_instance.value);
    setLayers(layers, map_instance.value);
    await setFeatures();
    setupPopup();
  }
});

async function getReports() {
  if (props.id === 'preview' && map_instance.value)
    removeMapboxInstance(map_instance.value);
  map_instance.value = null;
  map_data.value = null;
  loading.value = true;
  const payload = dashboard_forms_store.parse_forms_form_to_server_format(props.data.data);
  const forms_payload = cloneDeep(payload);

  const filters = forms_payload.filters.advanced_filter && Object.keys(forms_payload.filters.advanced_filter).length ? [forms_payload.filters.advanced_filter, ...cloneDeep(forms_v2_filters.value) || []] : cloneDeep(forms_v2_filters.value);

  forms_payload.filters = { ...forms_payload.filters, advanced_filter: filters };

  forms_payload.filters.advanced_filter = forms_payload.filters.advanced_filter.map((filter) => {
    filter.rules = parseRulesDateData(filter.rules);
    return filter;
  });
  try {
    const { data } = await $services.forms.get_graph({ body: forms_payload });
    if (data?.data?.length) {
      map_data.value = { type: 'FeatureCollection', features: data?.data };
      nextTick(() => {
        initDashboardMapbox();
      });
    }
  }
  catch (err) {
    logger.log({ err });
  }
  loading.value = false;
}

watch(() => props.data.data, (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val))
    getReports();
}, { immediate: true, deep: true });

watch(() => props.activeSelectedRange, (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    getReports();
  }
});
function sourcesLayers() {
  const sources = ['all_features_source', 'symbol-source'];

  const layers = [
    {
      id: 'point_feature_layer',
      type: 'circle',
      source: 'all_features_source',
      filter: ['==', '$type', 'Point'],
    },
    {
      id: 'polygon_feature_layer',
      type: 'fill',
      source: 'all_features_source',
      paint: {
        'fill-opacity': 0,
        'line-color': 'red',
      },
    },
    {
      id: 'linestring_feature_layer',
      type: 'line',
      source: 'all_features_source',
      paint: {
        'line-color': 'red',
        'line-width': 2,
      },
    },
    {
      id: 'symbol-layer-icon',
      type: 'symbol',

      source: 'symbol-source',
      layout: {
        // 'icon-image': ['coalesce', ['get', 'status'], 'status-1'],
        'icon-image': 'form-icon',
        'icon-size': ['coalesce', ['get', 'icon_size'], 0.5],
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-color': ['coalesce', ['get', 'color'], ''],
      },
    },

  ];
  return { sources, layers };
}

async function initDashboardMapbox() {
  try {
    await loadMapBoxPackage();
    await addMapboxToken();
    map_instance.value = await initMapbox({
      container_id: props.id,
      style: 'mapbox://styles/mapbox/streets-v11',
    });
  }
  catch (err) {
    logger.log(err);
  }
}
async function setFeatures() {
  map_instance.value.getSource('all_features_source').setData(map_data.value);
  map_instance.value.getSource('symbol-source').setData(map_data.value);
  if (map_data.value) {
    const turf = (await import('@turf/turf'));
    const centroid = turf.centroid(
      map_data.value.features[0],
    );
    map_instance.value.flyTo({
      center: centroid.geometry.coordinates,
      speed: 2.5,
      zoom: 12,
    });
  }
}

function setupPopup() {
  try {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map_instance.value.on('mouseenter', 'symbol-layer-icon', (e) => {
      map_instance.value.getCanvas().style.cursor = 'pointer';
      const feature = e.features[0];
      const description = feature.properties.name;
      popup
        .setLngLat(e.lngLat)
        .setHTML(description)
        .addTo(map_instance.value);
    });

    map_instance.value.on('mouseleave', 'symbol-layer-icon', () => {
      map_instance.value.getCanvas().style.cursor = '';
      popup.remove();
    });

    map_instance.value.on('click', 'symbol-layer-icon', (e) => {
      const task_form = e.features[0].properties;
      router.push({
        ...route,
        query: {
          form: btoa(JSON.stringify({
            form_uid: task_form.uid,
            store_key: 'terra_form_store',
          })),
        },
      });
    });
  }
  catch (err) {
    logger.log(err);
  }
}

onUnmounted(() => removeMapboxInstance(map_instance.value));
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group relative z-50">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <HawkLoader v-if="loading" container_class="m-0 " />
    <div v-else-if="!map_data?.features?.length" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <a v-show="map_data?.features?.length">

      <div :id="id" class="is-map" :style="[id === 'preview' ? { height: '300px' } : null]" />
    </a>
  </div>
</template>

<style lang="scss">
.mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }
  .mapboxgl-canvas {
    position: unset !important
  }
</style>
