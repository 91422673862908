<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { keyBy, orderBy } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkColumnsSelector from '~/common/components/organisms/hawk-columns-selector.vue';

const emit = defineEmits(['update']);
const auth_store = useAuthStore();
const common_store = useCommonStore();
const $t = inject('$t');
const $toast = inject('$toast');
const route = useRoute();

const {
  active_asset,
  assets_custom_fields,
} = storeToRefs(common_store);

const is_loading = ref(false);

const edit_permission = computed(() => auth_store.check_permission('modify_assets', route.params.asset_id));

const asset_metadata_visibility_popup = useModal({
  component: HawkColumnsSelector,
});

const all_fields = computed(() => {
  return orderBy(assets_custom_fields.value, 'name').map(item => ({
    uid: item.uid,
    name: item.uid,
    label: item.name,
  })) || [];
});

const selected_fields = computed(() => {
  const active_fields_keys = active_asset.value?.properties?.metadata_visibility || [];
  const fields_map = keyBy(all_fields.value, 'uid');
  return active_fields_keys.reduce((res, field_key) => {
    if (fields_map[field_key])
      res.push(fields_map[field_key]);

    return res;
  }, []);
});

async function saveColumns(event) {
  if (!edit_permission.value)
    return;

  if (event.is_save_clicked)
    try {
      is_loading.value = true;
      const asset_payload = { ...active_asset.value };

      const active_fields = event.active_columns || [];

      asset_payload.properties = {
        ...asset_payload.properties,
        metadata_visibility: !active_fields?.length ? null : active_fields.map(field => field.uid),
      };

      await common_store.update_data({
        type: 'patch_update',
        id: active_asset.value.uid,
        data: { asset: asset_payload },
        service: 'assets',
        append_data: true,
        state_prop: 'assets_map',
        update_state: true,
        response_key: 'asset',
      });

      $toast({ text: 'Changes saved successfully', type: 'success' });

      asset_metadata_visibility_popup.close();
      is_loading.value = false;
      emit('update');
    }
    catch (err) {
      logger.log(err);
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
      asset_metadata_visibility_popup.close();
    }
}

function openPopup() {
  asset_metadata_visibility_popup.patchOptions({
    attrs: {
      onClose() {
        asset_metadata_visibility_popup.close();
      },
      onSave(event) {
        saveColumns(event);
      },
      all_columns: all_fields.value,
      active_columns: selected_fields.value,
      texts: {
        heading: $t('Choose fields'),
        left_heading: $t('Available fields'),
        right_heading: $t('Displayed fields'),
      },
      close_on_save: false,
      is_loading_save: is_loading,
    },
  });
  asset_metadata_visibility_popup.open();
}
</script>

<template>
  <HawkButton
    v-if="edit_permission"
    icon
    type="text"
    class="ml-auto min-w-[36px]"
    @click="openPopup"
  >
    <IconHawkSettingsOne />
  </HawkButton>
</template>
