<script setup>
const props = defineProps({
  member_details: {
    type: Object,
    default: () => {},
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['badge', 'xs', 'sm', 'md', 'lg', 'xl'].includes(value);
    },
  },
  has_border: {
    type: Boolean,
    default: false,
  },
  has_tooltip: {
    type: Boolean,
    default: false,
  },
  background_color: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <SensehawkAvatar
    v-if="member_details.type === 'sensehawk'"
    v-tippy="has_tooltip ? member_details?.name : ''"
    :size="size"
    :has_border="has_border"
    :style="`z-index:[${member_details.index}]`"
    class="relative"
  />
  <UnknownAvatar
    v-else-if="member_details.type === 'unknown'"
    v-tippy="has_tooltip ? member_details?.name : ''"
    :size="size"
    :has_border="has_border"
    :style="`z-index:[${member_details.index}]`"
    class="relative"
  />
  <TeamAvatar
    v-else-if="member_details?.is_team"
    v-tippy="has_tooltip ? member_details?.name : ''"
    :size="size"
    :has_border="has_border"
    :style="`z-index:[${member_details.index}]`"
    class="relative"
  />
  <HawkAvatar
    v-else
    v-tippy="has_tooltip ? member_details?.name : ''"
    :name="member_details?.name"
    :img_src="member_details?.display_picture"
    :has_border="has_border"
    :style="`z-index:[${member_details.index}]`"
    class="relative"
    v-bind="props"
  />
</template>
