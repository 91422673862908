import { isArray, merge } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { getDateInterval } from '~/dashboard/components/filters/composables/filters-config';

import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';
import SelectScheduleTemplate from '~/dashboard/components/vueform-schema-templates/select-schedule-template.vue';

import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

export const useDashboardScheduleStore = defineStore('dashboard_schedule', {
  state: () => ({
    schedule_configuration: {},

    s_curve_type_options: [
      ['progress', 'Activities progress'],
      ['activities_completed', 'Activities completed'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    s_curve_interval_options: [
      ['month', 'Monthly'],
      ['week', 'Weekly'],
      ['day', 'Daily'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    history_compare_options: [
      ['week', 'last_week', 'Last week'],
      ['month', 'month', 'Last month'],
      ['day', 1, 'Custom'],
    ].map((item) => {
      return {
        name: item[0],
        duration: item[1],
        label: item[2],
      };
    }),
    activity_history_range_options: [
      ['selected_range', 'dashboard_active_selected_range', 'Selected range'],
      ['week', 'last_week', 'This week'],
      ['month', 'month', 'This month'],
      ['quarter', 'month', 'This quarter'],
      ['year', 'month', 'This year'],
      ['custom', 'custom', 'Custom range'],
    ].map((item) => {
      return {
        name: item[0],
        duration: item[1],
        label: item[2],
      };
    }),
    number_chart_field_options: [
      ['actual_progress', 'Actual progress'],
      ['planned_progress', 'Planned progress'],
      ['total_duration', 'Total duration'],
      ['average_duration', 'Average duration'],
      ['total_remaining_duration', 'Total remaining duration'],
      ['average_remaining_duration', 'Average remaining duration'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    schedule_options: [],
    project_list_columns: [
      {
        name: 'Actual progress',
        column: 'actual_progress',
        type: 'column',
      },
      {
        name: 'Planned progress',
        column: 'planned_progress',
        type: 'column',
      },
      {
        name: 'Duration',
        column: 'absolute_remaining_duration',
        type: 'computed_column',
      },
      {
        name: 'Status',
        column: 'status',
        type: 'column',
      },
    ],
    data_set: false,
  }),
  getters: {
    type_options: () => {
      const dashboard_store = useDashboardStore();

      return [
        ['project_progress', 'Project progress', 'Total percentage completion of the schedule along with lead/lag indicator.'],
        ...(dashboard_store.scope === 'organization' ? [['projects_list', 'Projects List', 'Shows a list of projects and their progress.']] : []),
        ['project_duration', 'Project duration', 'Total percentage completion of the schedule along with lead/lag indicator.'],
        ['s_curve', 'S-curve (planned/ actual progress)', 'Total percentage completion of the schedule along with lead/lag indicator.'],
        ['spi', 'Schedule Performace Index (SPI)', 'Total percentage completion of the schedule along with lead/lag indicator.'],
        ['cpi', 'Cost Performace Index (CPI)', 'Total percentage completion of the schedule along with lead/lag indicator.'],
        ['planned_vs_actual', 'Planned vs Actual', 'Total percentage completion of the schedule along with lead/lag indicator.'],
        ['activity_history_table', 'Activity history table', 'Time based progress view for selected activities'],
        ['activities_work_combined_table', 'Activities table', 'Total percentage completion of the schedule along with lead/lag indicator.'],
        ['activities_breakdown', 'Activities breakdown', 'Plot bar and pie charts using the standard/custom fields from the schedule.'],
        ['activities_metrics', 'Metric', 'Extract a single value metric for the selected activities from the schedule.'],
        ['progress_history', 'Progress history', 'Compare current vs previous schedule progress'],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          description: item[2],
        };
      });
    },

    schedule_widget_type: state => state.schedule_configuration?.type || null,
    chart_type_options: (state) => {
      let chart_types = [];
      if (state.schedule_configuration.type === 'planned_vs_actual') {
        chart_types = [
          ['horizontal_bar', 'Horizontal graph'],
          ['vertical_graph', 'Vertical chart'],
        ];
      }
      else if (state.schedule_configuration.type === 'activities_breakdown') {
        chart_types = [
          ['horizontal_bar', 'Horizontal graph'],
          ['vertical_graph', 'Vertical chart'],
          ['donut', 'Pie chart'],
        ];
      }
      else if (state.schedule_configuration.type === 's_curve') {
        chart_types = [
          ['bar', 'Bar chart'],
          ['table', 'Table'],
        ];
      }
      else {
        chart_types = [
          ['meter', 'Meter'],
          ['donut', 'Pie chart'],
        ];
      }

      return chart_types.map((item) => {
        return {
          value: item[0],
          label: item[1],
        };
      });
    },
    activity_history_range: state =>
      state.schedule_configuration?.activity_history_range,

    activity_history_custom_range: state => state.schedule_configuration?.activity_history_custom_range,

    active_schedule: state => state.schedule_options.find(s => s.uid === state.schedule_configuration?.schedule) || null,

    chart_field_options() {
      const activity_codes = this.active_schedule?.activity_codes.map((a) => {
        return {
          value: `activity_code_values_map.${a.name}`,
          label: `Activity code: ${a.name}`,
        };
      }) || [];

      const custom_fields = Object.keys(this.active_schedule?.custom_fields || {}).map((c) => {
        return {
          value: `custom_field_values.${c}`,
          label: `Custom field: ${c}`,
        };
      }) || [];

      return [
        { value: 'status', label: 'Status' },
        { value: 'critical', label: 'Critical' },
        ...activity_codes,
        ...custom_fields,
      ];
    },
    chart_value_options(state) {
      if (['planned_vs_actual', 'progress_history'].includes(state.schedule_configuration.type)) {
        return [
          ['progress', 'Progress'],
        ].map((item) => {
          return {
            value: item[0],
            label: item[1],
          };
        });
      }
      return [
        ['task_count', 'Activities count'],
        ['average_progress', 'Actual progress'],
        ['average_percent_schedule_complete', 'Planned progress'],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
        };
      });
    },
    activity_history_interval_options: (state) => {
      let options = [];

      const type = state.schedule_configuration.activity_history_range === 'custom' ? state.schedule_configuration.activity_history_custom_range.timePeriod : state.schedule_configuration.activity_history_range;
      switch (type) {
        case 'week':
          options = [['day', 'Daily']];
          break;
        case 'month':
          options = [['day', 'Daily'], ['week', 'Weekly']];
          break;
        case 'quarter':
          options = [['week', 'Weekly'], ['month', 'Monthly']];
          break;
        case 'year':
          options = [['month', 'Monthly'], ['quarter', 'Quarterly']];
          break;
        case 'selected_range':
          options = [['day', 'Daily'], ['week', 'Weekly'], ['month', 'Monthly'], ['quarter', 'Quarterly']];
          break;
        default:
          options = [['day', 'Daily']];
          break;
      }

      return options.map((item) => {
        return {
          value: item[0],
          label: item[1],
        };
      });
    },
    schedule_schema(state) {
      if (!state.data_set)
        return {};

      return {
        type: {
          type: 'select',
          items: state.type_options,
          search: true,
          native: false,
          label: 'Type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.schedule_configuration.type || state.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        schedule: {
          type: 'select',
          items: state.schedule_options,
          search: true,
          native: false,
          label: 'Schedule',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'not_in',
              [
                'projects_list',
              ],
            ],
          ],
          rules: [
            {
              required: [
                'type',
                'not_in',
                [
                  'projects_list',
                ],
              ],
            },
          ],
          default: state.schedule_configuration.schedule || state.schedule_options?.[0]?.value || null,
        },
        multi_schedule: {
          type: 'tags',
          items: state.schedule_options,
          search: true,
          native: false,
          label: 'Schedule',
          canClear: true,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          slots: {
            option: SelectScheduleTemplate,
          },
          conditions: [
            [
              'type',
              'in',
              [
                'projects_list',
              ],
            ],
          ],
          rules: [
            {
              required: [
                'type',
                'in',
                [
                  'projects_list',
                ],
              ],
            },
          ],
          default: state.schedule_configuration.multi_schedule || [],
        },
        s_curve_type: {
          type: 'select',
          items: state.s_curve_type_options,
          search: true,
          native: false,
          label: 'Type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                's_curve',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    's_curve',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.s_curve_type || state.s_curve_type_options[0].value,
        },
        s_curve_interval: {
          type: 'select',
          items: state.s_curve_interval_options,
          search: true,
          native: false,
          label: 'Interval',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                's_curve',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    's_curve',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.s_curve_interval || state.s_curve_interval_options[0].value,
        },
        chart_type: {
          type: 'select',
          items: this.chart_type_options,
          search: true,
          native: false,
          label: 'Chart type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'planned_vs_actual',
                'activities_breakdown',
                'project_progress',
                's_curve',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'planned_vs_actual',
                    'activities_breakdown',
                    'project_progress',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.chart_type || this.chart_type_options[0].value,
        },
        chart_field: {
          type: 'select',
          items: this.chart_field_options,
          search: true,
          native: false,
          label: 'Field',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'planned_vs_actual',
                'activities_breakdown',
                'progress_history',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'planned_vs_actual',
                    'activities_breakdown',
                    'progress_history',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.chart_field || this.chart_field_options[0].value,
        },
        chart_value: {
          type: 'select',
          items: this.chart_value_options,
          search: true,
          native: false,
          label: 'Value',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'activities_breakdown',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'activities_breakdown',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.chart_value || this.chart_value_options[0].value,
        },
        breakdown: {
          type: 'select',
          items: this.chart_field_options,
          search: true,
          native: false,
          label: 'Breakdown by',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'activities_breakdown',
                'progress_history',
              ],
            ],
            [
              'chart_type',
              'not_in',
              [
                'donut',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'planned_vs_actual',
                    'activities_breakdown',
                    'progress_history',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.breakdown || this.chart_field_options[0].value,
        },
        number_chart_field: {
          type: 'select',
          items: state.number_chart_field_options,
          search: true,
          native: false,
          label: 'Field',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'activities_metrics',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'activities_metrics',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.number_chart_field || state.number_chart_field_options[0].value,
        },
        history_compare: {
          type: 'select',
          items: state.history_compare_options,
          search: true,
          native: false,
          label: 'Compare to',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          valueProp: 'name',
          conditions: [
            [
              'type',
              'in',
              [
                'progress_history',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'progress_history',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.history_compare || state.history_compare_options[0].name,
        },
        history_duration: {
          type: 'text',
          label: 'Custom interval',
          inputType: 'number',
          attrs: {
            min: 1,
          },
          conditions: [
            [
              'type',
              'in',
              [
                'progress_history',
              ],
            ],
            [
              'history_compare',
              'in',
              [
                'day',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'progress_history',
                  ],
                ],
                [
                  'history_compare',
                  'in',
                  [
                    'day',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.history_duration || 1,
        },
        transpose: {
          type: 'checkbox',
          text: 'Transpose row and columns',
          conditions: [
            [
              'type',
              'in',
              [
                'activities_work_combined_table',
              ],
            ],
          ],
          default: state.schedule_configuration.transpose || null,
        },
        activity_table_chart: {
          type: 'checkbox',
          text: 'Display chart',
          conditions: [
            [
              'type',
              'in',
              [
                'activities_work_combined_table',
              ],
            ],
          ],
          default: state.schedule_configuration.activity_table_chart || false,
        },
        dual_axis: {
          type: 'checkbox',
          text: 'Dual axis',
          conditions: [
            [
              'type',
              'in',
              [
                's_curve',
              ],
            ],
            [
              'chart_type',
              'in',
              [
                'bar',
              ],
            ],
          ],
          default: state.schedule_configuration.dual_axis !== false,
        },
        activity_history_range: {
          type: 'select',
          items: state.activity_history_range_options,
          search: true,
          native: false,
          label: 'Range',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          valueProp: 'name',
          conditions: [
            [
              'type',
              'in',
              [
                'activity_history_table',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'activity_history_table',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.activity_history_range || state.activity_history_range_options[0].name,
        },
        activity_history_custom_range: {
          type: 'object',
          label: 'Custom range',
          columns: '{ container: 12, label: 3, wrapper: 12 }',
          conditions: [

            function () {
              return state.schedule_configuration.type === 'activity_history_table' && state.schedule_configuration.activity_history_range === 'custom';
            },

          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'activity_history_table',
                  ],
                ],
              ],
            },
          ],
          schema: {
            duration: {
              type: 'text',
              placeholder: 'Duration',
              fieldName: 'Duration',
              rules: [
                'min:1',
                'max:253',
              ],
              columns: '{ container: 6 }',
              default: state.schedule_configuration.activity_history_custom_range?.duration || 1,
            },
            timePeriod: {
              type: 'select',
              search: true,
              native: false,
              autocomplete: 'disabled',
              canClear: false,
              placeholder: 'Time Period',
              items: [
                { label: 'Day', value: 'day' },
                { label: 'Week', value: 'week' },
                { label: 'Month', value: 'month' },
                { label: 'Year', value: 'year' },
              ],
              columns: '{ container: 6 }',
              default: state.schedule_configuration.activity_history_custom_range?.timePeriod || 'day',

            },
          },
        },
        activity_history_interval: {
          type: 'select',
          items: this.activity_history_interval_options,
          search: true,
          native: false,
          label: 'Interval',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          valueProp: 'value',
          conditions: [
            [
              'type',
              'in',
              [
                'activity_history_table',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'activity_history_table',
                  ],
                ],
              ],
            },
          ],
          default: state.schedule_configuration.activity_history_interval || this.activity_history_interval_options[0].value,
        },

        module: {
          type: 'hidden',
          default: 'schedule',
        },
      };
    },
    asset_id() {
      const dashboard_store = useDashboardStore();

      return dashboard_store.widget_asset;
    },
  },
  actions: {
    update_schedule_configuration(data) {
      this.schedule_configuration = { ...data };
    },
    async set_schedules() {
      try {
        const { data } = await this.$services.project_management.getAll({
          attribute: 'schedules',
          query: {
            asset: this.asset_id,
          },
        });

        this.schedule_options = data.data.map((s) => {
          return {
            ...s,
            value: s.uid,
            label: s.name,
          };
        });
      }
      catch (error) {
        logger.error(error);
      }
    },
    async set_schedule_details(schedule_uid) {
      const select = [
        'calendars',
        'resources',
        'versions',
        'activities',
        'resource_assignments',
        'suggestions',
        'relations',
        'schedules',
      ].join(',');

      try {
        // fetch the schedule with selected properties
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: schedule_uid,
          query: { select },
        });

        const schedule = data.data[0];
        const gantt = window.gantt;

        // prepare the data to be used with DHTMLX Gantt
        for (const task of schedule.activities) {
          task.text = task.name;
          task.total_duration = task.duration;
          task.type = task.is_milestone ? 'milestone' : task.type.toLowerCase();
          task.start_date = gantt.date.day_start(new Date(task.start));
          task.end_date = gantt.date.day_start(new Date(task.finish));
          task.bl_start = task.bl_start ? gantt.date.day_start(new Date(task.bl_start)) : null;
          task.bl_finish = task.bl_finish ? gantt.date.day_start(new Date(task.bl_finish)) : null;
          task.actual_start = task.actual_start ? gantt.date.day_start(new Date(task.actual_start)) : null;
          task.actual_finish = task.actual_finish ? gantt.date.day_start(new Date(task.actual_finish)) : null;
          task.planned_start = task.planned_start ? gantt.date.day_start(new Date(task.planned_start)) : null;
          task.planned_finish = task.planned_finish ? gantt.date.day_start(new Date(task.planned_finish)) : null;
        }

        return {
          data: schedule.activities,
          links: schedule.relations,
        };
      }
      catch (error) {
        logger.error(error);
      }
    },
    get_gantt_task_data() {
      const gantt = window.gantt;

      const getChildrenData = (parent) => {
        const { id, uid, name, type } = gantt.getTask(parent);

        const children = gantt.getChildren(parent);

        const children_data = [];

        children?.forEach((child_key) => {
          const child_data = getChildrenData(child_key);
          if (child_data)
            children_data.push(child_data);
        });
        return {
          id,
          uid,
          name,
          type,
          show_select_all: type === 'wbs',
          ...(children_data.length
            ? { children: children_data }
            : {}),
        };
      };

      const top_level_parents = gantt.getChildren(gantt.config.root_id);
      return top_level_parents.map(getChildrenData).filter(val => val !== null);
    },
    parse_columns(columns = []) {
      return columns.map((c) => {
        if (['column', 'computed_column'].includes(c.type)) {
          return {
            name: c.name,
            column: c.key,
            type: c.type,
          };
        }
        else {
          return {
            name: c.name,
            column: c.key,
            type: c.type,
            column_types: c.column_types,
            agg: 'sum',
          };
        }
      });
    },
    unparse_columns(columns = []) {
      return columns.map(c => ({
        name: c.name,
        key: c.column,
        column_types: c.column_types || [],
        type: c.type,
      }));
    },
    update_relative_date_filters(filters) {
      if (Array.isArray(filters) && filters.length) {
        const res = filters?.reduce((acc, f) => {
          if (!f.date_operator) {
            acc.push(f);
          }
          else {
            acc.push({
              ...f,
              value: getDateInterval(f.date_operator),
            });
          }

          return acc;
        }, []);

        return res;
      }
      else {
        return [];
      }
    },

    parse_project_progress(widget_data, activity_table_activities) {
      return {
        chart: 'project_progress',
        type: widget_data.chart_type,
        data: {
          activities: widget_data.activities?.map(a => a.value) || [],
          as_of_meta: widget_data.as_of_meta || {},
        },
        properties: { activity_table_activities },
        filters: {
          as_of: widget_data.as_of,
        },
      };
    },

    parse_project_list_data(widget_data) {
      return {
        chart: 'projects_list',
        data: { columns: this.project_list_columns },
        filters: { advanced_filters: this.update_relative_date_filters(widget_data.filters) },
      };
    },

    parse_s_curve_data(widget_data, activity_table_activities) {
      return {
        chart: 's_curve',
        data: {
          type: widget_data.s_curve_type,
          interval: {
            name: widget_data.s_curve_interval,
          },
          ...(widget_data.chart_type === 'table' ? { activities: widget_data.activities?.map(a => a.value) || [] } : {}),
        },
        properties: { dual_axis: widget_data.dual_axis, ...(widget_data.chart_type === 'table' ? { activity_table_activities } : {}),
        },
        chart_type: widget_data.chart_type,
        filters: {
          advanced_filters: this.update_relative_date_filters(widget_data.filters),
        },
      };
    },

    parse_planned_actual_data(widget_data) {
      return {
        chart: widget_data.chart_type,
        type: 'planned_vs_actual',
        x: {
          key: widget_data.chart_field,
          label: widget_data.chart_field,
        },
        y: {
          key: 'progress',
          label: 'Progress',
        },
      };
    },

    parse_activities_breakdown_data(widget_data) {
      return {
        chart: widget_data.chart_type,
        x: {
          key: widget_data.chart_field,
          label: widget_data.chart_field,
        },
        y: {
          key: widget_data.chart_value,
          label: widget_data.chart_value,
        },
        ...(widget_data.chart_value !== 'task_count'
          ? { data: {
              as_of_meta: widget_data.as_of_meta || null,
              as_of: widget_data.as_of || null,
            }, filters: { as_of: widget_data.as_of } }
          : {}),

        ...(widget_data.chart_type !== 'donut'
          ? {
              group: {
                key: widget_data.breakdown,
                label: widget_data.breakdown,
              },
            }
          : { group: null }
        ),
        filters: {
          advanced_filters: this.update_relative_date_filters(widget_data.filters),
        },
      };
    },

    parse_progress_history_data(widget_data) {
      const compare_value = this.history_compare_options.find(o => o.name === widget_data.history_compare);
      const compare_duration = compare_value.name === 'day' ? Number.parseInt(widget_data.history_duration) : compare_value.duration;

      return {
        chart: 'vertical_graph',
        x: {
          key: widget_data.chart_field,
          label: widget_data.chart_field,
        },
        y: {
          key: 'progress',
          label: 'Progress',
        },
        group: null,
        data: {
          type: 'actual_progress_history',
          interval: {
            label: compare_value.label,
            duration: compare_duration,
            name: compare_value.name,
          },
        },
        filters: {
          advanced_filters: this.update_relative_date_filters(widget_data.filters),
        },
      };
    },

    parse_activities_metrics_data(widget_data, activity_table_activities) {
      return {
        chart: 'activities_metrics',
        properties:
        { activity_table_activities },
        data: {
          activities: widget_data.activities?.map(a => a.value) || [],
          column: widget_data.number_chart_field,
        },
        filters: {
          advanced_filters: this.update_relative_date_filters(widget_data.filters),
        },
      };
    },
    parse_activities_work_combined_table_data(widget_data, activity_table_activities) {
      return {
        chart: 'activities_work_combined_table',
        properties: {
          activity_table_activities,
          transpose: widget_data.transpose,
          activity_table_chart: widget_data.activity_table_chart,
          columns_widths: widget_data.columns_widths || {},
        },
        data: {
          activities: widget_data.activities?.map(a => a.value) || [],
          columns: this.parse_columns(widget_data.columns),
        },
        filters: {
          advanced_filters: this.update_relative_date_filters(widget_data.filters),
        },

      };
    },
    parse_activity_history_table_data(widget_data, activity_table_activities) {
      const { getFormattedDate } = useFamConstants();
      return {
        chart: 'activity_history_table',
        properties: { activity_table_activities },
        data: {
          activities: widget_data.activities?.map(a => a.value) || [],
          range: {
            name: (widget_data.activity_history_range === 'custom' ? widget_data.activity_history_custom_range.timePeriod : widget_data.activity_history_range),
            interval: widget_data.activity_history_interval,
            value: widget_data.activity_history_range === 'selected_range' ? getFormattedDate('dashboard_active_selected_range') : widget_data.activity_history_custom_range?.duration || 1,
          },
        },
      };
    },
    parse_schedule_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;
      const authStore = useAuthStore();
      const activity_table_activities = widget_data.activities?.map(a => (
        {
          id: a.id,
          uid: a.value,
          name: a.label,
          has_children: a.has_children,
        }
      )) || [];

      const payload = {
        properties: {
          source: {
            type: 'project management',
            service: 'pm',
          },
          config: widget_data.chart_config || {},
        },
        filters: {
          organization: authStore.current_organization?.uid,
          asset: widget_data.asset_id,
          schedule: widget_data.type === 'projects_list' ? widget_data.multi_schedule : widget_data.schedule,
        },
      };
      let data;
      switch (widget_data.type) {
        case 'project_progress':
          data = this.parse_project_progress(widget_data, activity_table_activities);
          break;
        case 'projects_list':
          data = this.parse_project_list_data(widget_data);
          break;
        case 's_curve':
          data = this.parse_s_curve_data(widget_data, activity_table_activities);
          break;
        case 'planned_vs_actual':
          data = this.parse_planned_actual_data(widget_data);
          break;
        case 'activities_breakdown':
          data = this.parse_activities_breakdown_data(widget_data);
          break;
        case 'progress_history':
          data = this.parse_progress_history_data(widget_data);
          break;
        case 'activities_metrics':
          data = this.parse_activities_metrics_data(widget_data, activity_table_activities);
          break;
        case 'activities_work_combined_table':
          data = this.parse_activities_work_combined_table_data(widget_data, activity_table_activities);
          break;
        case 'activity_history_table':
          data = this.parse_activity_history_table_data(widget_data, activity_table_activities);
          break;
        case 'spi':
        case 'cpi':
        case 'project_duration':
          data = { chart: widget_data.type };
          break;
      }
      return merge(payload, data);
    },

    unparse_schedule_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const data = {};

      // set widget type, very comlpex becayse payload is not consistent :/
      if (widget_data.type === 'planned_vs_actual') {
        data.type = 'planned_vs_actual';
        data.chart_type = widget_data.chart;
      }
      else if (widget_data.data?.type === 'actual_progress_history') {
        data.type = 'progress_history';
      }
      else if (widget_data.chart === 'project_progress') {
        data.chart_type = widget_data.type;
        data.type = widget_data.chart;
      }
      else if (widget_data.chart === 's_curve') {
        data.type = widget_data.chart;
        data.chart_type = widget_data.chart_type;
      }
      else if (
        [
          'vertical_graph',
          'horizontal_bar',
          'donut',
        ].includes(widget_data.chart)
      ) {
        data.type = 'activities_breakdown';
        data.chart_type = widget_data.chart;
      }
      else { data.type = widget_data.chart; }

      if (isArray(widget_data.filters.schedule))
        data.multi_schedule = widget_data.filters.schedule;
      else
        data.schedule = widget_data.filters.schedule;

      if (widget_data.chart !== 'projects_list')
        data.columns = this.unparse_columns(widget_data.data?.columns || []);
      data.name = widget_data.properties?.name || 'Untitled';
      data.transpose = widget_data.properties?.transpose || false;
      data.activity_table_chart = widget_data.properties?.activity_table_chart;
      data.number_chart_field = widget_data.data?.column || null;
      data.s_curve_type = widget_data.data?.type || null;
      data.s_curve_interval = widget_data.data?.interval?.name || null;
      data.filters = widget_data.filters.advanced_filters || [];
      data.chart_config = widget_data.properties?.config || null;
      data.columns_widths = widget_data.properties?.columns_widths || {};
      data.asset_id = widget_data.filters?.asset || null;
      data.module = 'schedule';
      data.dual_axis = !!widget_data.properties.dual_axis;

      if (widget_data.chart === 'activity_history_table') {
        if (Number.parseInt(widget_data.data?.range?.value) === 1 || Array.isArray(widget_data.data?.range?.value)) {
          data.activity_history_range = widget_data.data?.range?.name || null;
        }
        else {
          data.activity_history_custom_range = {
            timePeriod: widget_data.data?.range?.name,
            duration: widget_data.data?.range?.value,
          };
          data.activity_history_range = 'custom';
        }

        data.activity_history_interval = widget_data.data?.range?.interval || null;
      }

      if (widget_data.x?.key)
        data.chart_field = widget_data.x.key;
      if (
        widget_data.y?.key
        && data.type === 'activities_breakdown'
      ) {
        data.chart_value = widget_data.y.key;
      }
      if (widget_data.group?.key)
        data.breakdown = widget_data.group.key;

      if (widget_data.data?.type === 'actual_progress_history')
        data.history_compare = widget_data.data.interval.name;
      else
        data.history_compare = null;

      if (data.history_compare === 'day')
        data.history_duration = Number.parseInt(widget_data.data.interval.duration);
      else
        data.history_duration = 1;

      if (widget_data.properties?.activity_table_activities?.length) {
        data.activities = widget_data.properties.activity_table_activities.map(a => ({
          id: a.id,
          value: a.uid,
          label: a.name,
          has_children: a.has_children,
        }));
      }
      else {
        data.activities = [];
      }

      if (widget_data?.filters?.as_of)
        data.as_of = widget_data.filters.as_of;
      if (widget_data?.data?.as_of_meta)
        data.as_of_meta = widget_data.data.as_of_meta;

      return data;
    },
    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
        || widget_configuration?.module !== 'schedule'
      ) {
        this.data_set = true;
        this.schedule_configuration = {
          ...this.schedule_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      this.schedule_configuration = { ...widget_configuration };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardScheduleStore, import.meta.hot));
