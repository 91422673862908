<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { onMounted, onUnmounted, provide, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import useEmitter from '~/common/composables/useEmitter';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { useCustomAnnotationHandlers } from '../composables/useCustomAnnotationHandlers';
import { useDocumentTools } from '../composables/useDocumentTools';
import { useDocumentViewer } from '../composables/useDocumentViewer';
import { ANNOTATION_TYPES, CUSTOM_ANNOTATION_TYPES, MEASUREMENT_PRESET, PLANS_PERMISSION, TOOL_EVENTS } from '../constants';
import { useAnnotationsStore } from '../store/annotations.store';
import { usePlansStore } from '../store/plans.store';
import { useSheetStore } from '../store/sheet.store';
import calibrationModal from './modals/calibration-modal.vue';

const props = defineProps({
  sheet_url: {
    type: [File, String, Object], // File blob, String path, pdf tron doc object
    required: true,
  },
  element_uid: {
    type: String,
    default: null,
  },
  version: {
    type: String,
    default: null,
  },
  element: {
    type: Object,
    default: () => ({}),
  },
  no_tools: {
    type: Boolean,
    default: false,
  },
  scroll_view_class: {
    type: String,
    default: null,
  },
  setup_options: {
    type: Object,
    default: () => ({}),
  },
  has_annotation_two_clicks: {
    type: Boolean,
    default: true,
  },
  can_annotate: {
    type: Boolean,
    default: true,
  },
  module: {
    type: String,
    default: 'plans',
  },
  viewer_class: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['onDocLoad']);
const emitter = useEmitter();

const auth_store = useAuthStore();
const route = useRoute();
const router = useRouter();
const annotations_store = useAnnotationsStore();
const plans_store = usePlansStore();
const sheet_store = useSheetStore();
const transmittal_store = useTransmittalsStore();

const scroll_view_elem = ref(null);
const viewer_container_elem = ref(null);
const viewer_elem = ref(null);
const active_tool = ref('');
const is_fullscreen_mode = ref(false);

const get_calibration = computed(() => {
  if (sheet_store.sheet.properties?.page_scale && sheet_store.sheet.properties?.world_scale) {
    return [
      [sheet_store.sheet.properties.page_scale.value, sheet_store.sheet.properties.page_scale.unit],
      [sheet_store.sheet.properties.world_scale.value, sheet_store.sheet.properties.world_scale.unit],
    ];
  }

  if (plans_store.get_active_drawing.properties?.page_scale && plans_store.get_active_drawing.properties?.world_scale) {
    return [
      [plans_store.get_active_drawing.properties.page_scale.value, plans_store.get_active_drawing.properties.page_scale.unit],
      [plans_store.get_active_drawing.properties.world_scale.value, plans_store.get_active_drawing.properties.world_scale.unit],
    ];
  }

  return MEASUREMENT_PRESET.Scale;
});

const has_saved_calibration = computed(() => {
  return !!((sheet_store.sheet.properties?.page_scale && sheet_store.sheet.properties?.world_scale) || (plans_store.get_active_drawing.properties?.page_scale && plans_store.get_active_drawing.properties?.world_scale));
});

const calibration_preset = computed(() => {
  const calibration_data = get_calibration.value;

  return {
    Scale: calibration_data,
  };
});

const {
  fetch_tasks,
  fetch_forms,
  fetch_comments,
  add_fetched_custom_annotations,
} = useCustomAnnotationHandlers({ route, router });

const {
  is_web_viewer_core_ready,
  has_document_loaded,
  document_viewer_instance,
  current_progress,
  setup_core,
  setup_document_viewer,
  load_document,
  err_msg,
} = useDocumentViewer({ scroll_view_elem, viewer_elem, doc_url: props.sheet_url, element: props.element, version: props.version }, emit);

provide('document-viewer-instance', document_viewer_instance);
provide('load-document', load_document);
provide('viewer-container-elem', viewer_container_elem);
provide('sheet-url', props.sheet_url);

const { open: open_calibration_modal, close: close_calibration_modal, patchOptions: patch_calibration_options } = useModal({
  component: calibrationModal,
  attrs: {
    onClose() {
      close_calibration_modal();
    },
    has_saved_calibration,
    calibration_preset,
    modal_options: { teleportTo: '#viewer-container' },
  },
});

const {
  create_arrow,
  create_brush,
  create_cloud,
  create_ellipse,
  create_highlight,
  create_line,
  create_polyline,
  create_polygon,
  create_rectangle,
  create_rectangle_two_clicks,
  create_text,
  calibrate,
  measure_area,
  measurement_calibration,
  measure_distance,
  pan,
  select,
  set_editable_permission,
  create_and_add_stamp_annotation,
  create_stamp_tool,
  delete_annotations,
  get_annotation_by_id,
} = useDocumentTools(document_viewer_instance);

const tool_event_map = {
  [TOOL_EVENTS.CREATE_ARROW]: create_arrow,
  [TOOL_EVENTS.FREEHAND]: () => create_brush(6),
  [TOOL_EVENTS.CREATE_CLOUD]: create_cloud,
  [TOOL_EVENTS.CREATE_ELLIPSE]: create_ellipse,
  [TOOL_EVENTS.CREATE_HIGHLIGHT]: create_highlight,
  [TOOL_EVENTS.CREATE_LINE]: create_line,
  [TOOL_EVENTS.CREATE_POLYLINE]: create_polyline,
  [TOOL_EVENTS.CREATE_POLYGON]: create_polygon,
  [TOOL_EVENTS.CREATE_RECTANGLE]: () => {
    props.has_annotation_two_clicks ? create_rectangle_two_clicks() : create_rectangle();
  },
  [TOOL_EVENTS.CREATE_TEXT]: create_text,
  [TOOL_EVENTS.AREA]: () => {
    has_saved_calibration.value ? measure_area(calibration_preset.value) : calibrationConfig();
  },
  [TOOL_EVENTS.CALIBRATE]: calibrationConfig,
  [TOOL_EVENTS.DISTANCE]: () => {
    has_saved_calibration.value ? measure_distance(calibration_preset.value) : calibrationConfig();
  },
  [TOOL_EVENTS.EDIT]: () => select(),
  [TOOL_EVENTS.PAN]: () => pan(),
  [TOOL_EVENTS.CREATE_TASK]: () => {
    create_stamp_tool(
      CUSTOM_ANNOTATION_TYPES.TASK,
      {
        onToolActivated,
      },
    );
  },
  [TOOL_EVENTS.CREATE_FORM]: () => {
    create_stamp_tool(
      CUSTOM_ANNOTATION_TYPES.FORM,
      {
        onToolActivated,
      },
    );
  },
  [TOOL_EVENTS.CREATE_COMMENT]: () => {
    create_stamp_tool(CUSTOM_ANNOTATION_TYPES.COMMENT, {
      onToolActivated,
      route,
      router,
    });
  },
  [TOOL_EVENTS.FULLSCREEN]: () => {
    is_fullscreen_mode.value = viewer_container_elem.value.getAttribute('fullscreen') ?? false;
  },
  [TOOL_EVENTS.CREATE_STICKY]: () => {
    create_stamp_tool(
      ANNOTATION_TYPES.NOTE,
      {
        onToolActivated,
        route,
        router,
      },
    );
  },
};

function calibrationConfig() {
  const calibration_modal_config = {
    open_calibration_modal,
    patch_calibration_options,
  };

  patch_calibration_options({
    attrs: {
      onSave({
        world_scale_value,
        world_unit,
        page_scale_value,
        page_unit,
        apply_to_drawing,
      }) {
        const page_scale = { value: page_scale_value, unit: page_unit };
        const world_scale = { value: world_scale_value, unit: world_unit };
        if (apply_to_drawing)
          plans_store.patch_calibration_to_drawing({ page_scale, world_scale });
        else
          sheet_store.patch_calibration_to_sheet({ page_scale, world_scale });

        calibrate(page_scale, world_scale);

        // Reset active tool after calibration save
        active_tool.value = '';
        pan();
      },
      onClose(annotation) {
        if (annotation)
          delete_annotations([get_annotation_by_id(annotation.Id)]);

        close_calibration_modal();
      },
    },
  });

  measurement_calibration(calibration_modal_config);
}

function escHandler() {
  const handler = (evt) => {
    if (evt.key === 'Escape') {
      active_tool.value = '';
      pan();

      document.removeEventListener('keydown', handler);
    }
  };
  document.addEventListener('keydown', handler);
}

function onToolActivated(tool_event_name) {
  active_tool.value = tool_event_name;

  const location_marker_annotation = document_viewer_instance.value.getAnnotationManager().getAnnotationsList().find(annot => annot.Subject === CUSTOM_ANNOTATION_TYPES.LOCATION);
  delete_annotations([location_marker_annotation]);
  annotations_store.reset_location_marker_config();

  const tool_event_action = tool_event_map[tool_event_name];
  tool_event_action?.();
  escHandler();
}

emitter.on('toolActivated', onToolActivated);
emitter.on('focusAnnotation', focusAnnotation);

function focusAnnotation({ annotation_id }) {
  const annotation_manager = document_viewer_instance.value.getAnnotationManager();
  annotation_manager.deselectAllAnnotations();

  if (!annotation_id)
    return;

  const annotation = annotation_manager.getAnnotationById(annotation_id);
  if (annotation) {
    annotation_manager.selectAnnotation(annotation);
    annotation_manager.jumpToAnnotation(annotation, { isSmoothScroll: true });
  }
}

onMounted(async () => {
  try {
    window?.Core && (is_web_viewer_core_ready.value = true);
    if (!window?.Core)
      await setup_core();

    await setup_document_viewer(props.setup_options);
    if (props.element_uid) {
      let owner_uid;
      if (props.module === 'dms') {
        const feedback_visibility = transmittal_store.transmittal.feedback_visibility;
        if (!feedback_visibility?.includes('reviewer')) { // Filter by user only when the feedback visibility is false
          const members = transmittal_store.transmittal.members.filter(member => ['reviewer'].includes(member.role)).map(member => member.uid);
          owner_uid = members.find(member_uid => member_uid === auth_store.logged_in_user_details?.user_id);
        }
      }
      await Promise.all([annotations_store.fetch_annotations(props.element_uid, owner_uid, props.version), fetch_tasks(), fetch_forms(), fetch_comments()]);
      add_fetched_custom_annotations(create_and_add_stamp_annotation);
    }

    set_editable_permission(props.can_annotate && auth_store.check_permission(PLANS_PERMISSION.MODIFY_ANNOTATIONS, route.params.asset_id));
  }
  catch (error) {
    logger.error(error);
  }
});

onUnmounted(() => {
  is_web_viewer_core_ready.value = false;
  has_document_loaded.value = false;
  document_viewer_instance.value?.closeDocument();
  document_viewer_instance.value = null;
  annotations_store.$reset();

  emitter.off('toolActivated');
  emitter.off('focusAnnotation');
});
</script>

<template>
  <!-- PDF viewer container -->
  <div id="viewer-container" ref="viewer_container_elem" tabindex="0" class="h-full outline-none">
    <div v-if="err_msg.includes('File extension')" class="text-sm flex items-center gap-2 px-4 py-2 mb-2 border border-error-500 rounded-md text-error-500 bg-error-50">
      <IconHawkAlertTriangle />
      {{ $t('The file cannot be viewed') }}. {{ $t('It is only available for download') }}.
    </div>
    <div id="scroll-view" ref="scroll_view_elem" class="scrollbar border border-gray-300 rounded-lg" :class="scroll_view_class">
      <!-- Error Message -->
      <div v-if="err_msg" class="flex align-center justify-center mt-20">
        <slot name="err_content">
          <div class="w-3/4">
            {{ err_msg.includes('File extension') ? $t('File type is not supported for preview') : err_msg }}
          </div>
        </slot>
      </div>
      <!-- Progress bar -->
      <div v-else-if="current_progress || !has_document_loaded" class="flex align-center w-1/3 mt-10 absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]" :class="{ relative: module === 'dms' }">
        <div class="w-full bg-gray-200 rounded-full dark:bg-gray-200 my-2 flex-1">
          <div
            class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 rounded-full h-[0.5rem]"
            :style="`width:${current_progress}%;`"
          />
        </div>
        <div style="width:3rem;text-align:right;">
          {{ current_progress }}%
        </div>
      </div>
      <!-- Tools container -->
      <div
        v-if="!props.no_tools && has_document_loaded"
        class="tools-contatiner absolute h-[calc(100vh-200px)] w-[calc(100%-2.5rem)]"
        :class="{ '!h-[100vh] !w-[100vw]': is_fullscreen_mode, 'w-[calc(65%-2.5rem)]': !is_fullscreen_mode && module === 'dms' }"
      >
        <sheet-tools :active_tool="active_tool" :has_annotation_two_clicks="has_annotation_two_clicks" :module="module" @tool-activated="onToolActivated" />
      </div>
      <div v-if="!err_msg" class="h-full flex">
        <div id="viewer" ref="viewer_elem" :class="viewer_class" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#viewer {
  flex: none;
  margin: auto;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.14), 0 2px 2px 0 rgba(0,0,0,.12), 0 1px 3px 0 rgba(0,0,0,.12);
}
</style>
