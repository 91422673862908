<script setup>
import HawkActivitiesTemplate from '~/common/components/organisms/hawk-activities/hawk-activities-template.vue';

import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});
const task_store = useTasksStore();

const ACTIVITY_VERB_MAP_FROM = {
  TICKET_ASSIGNMENT_CHANGED: 'changed assignment',
  TICKET_UNASSIGNED: 'unassigned task from',
  TICKET_ASSIGNED: 'assigned task to',
  TICKET_PRIORITY_CHANGED: 'changed priority',
  DUE_DATE_CHANGED: 'changed due date',
  ATTACHMENT_DELETED: 'deleted attachment',
  ATTACHMENT_UPLOADED: 'added attachment',
  MULTIPLE_ATTACHMENT_UPLOADED: 'added multiple attachments',
  TICKET_STATUS_CHANGED: 'changed status',
  CATEGORY_ADDED: 'set category',
  START_DATE_CHANGED: 'changed start date',
  CATEGORY_REMOVED: 'removed category',
  CATEGORY_CHANGED: 'changed category',

};

const ACTIVITY_VERB_MAP = {
  TICKET_ASSIGNMENT_CHANGED: 'set assignment',
  CATEGORY_ADDED: 'set category',
  TICKET_PRIORITY_CHANGED: 'set priority',
  DUE_DATE_CHANGED: 'set due date',
  TICKET_STATUS_CHANGED: 'set status',
  START_DATE_CHANGED: 'set start date',
  PARENT_ADDED: 'added parent task',
  ASSIGNEE_ADDED: 'added assignees',
  ASSIGNEE_REMOVED: 'removed assignees',
  SUBTASK_ADDED: 'added subtask',
  WATCHERS_ADDED: 'added watchers',
  WATCHERS_REMOVED: 'removed watchers',
  PARENT_REMOVED: 'removed parent task',
  SUBTASK_REMOVED: 'removed subtask',
  SUBTASK_STATUS_CHANGED: 'updated subtask status',
  DEPENDENCY_REMOVED: 'removed',
  DEPENDENCY_ADDED: 'added',
};

const meta_key = computed(() => {
  const key = Object.keys(props.activity.meta).filter(key => key !== 'task')[0];
  return key;
});
const has_meta_from = computed(() => {
  return !!props.activity.meta[meta_key.value].from;
});
const has_meta_to = computed(() => {
  return !!props.activity.meta[meta_key.value].to;
});
</script>

<template>
  <HawkActivitiesTemplate :activity="activity" class="text-sm" :exclude_keys="['task']">
    <template #action="{ action }">
      <span class="capitalize">
        {{ action.has_meta_from ? ACTIVITY_VERB_MAP_FROM[activity.verb] : ACTIVITY_VERB_MAP[activity.verb] }}
      </span>
    </template>
    <template #from="{ from }">
      <span class="mx-[5px]">
        {{ $t('from') }}
      </span>
      <TaskPriority v-if="from.key === 'priority'" :priority="from.value" />
      <TaskStatus v-else-if="from.key === 'status'" :status="from.value" />
      <HawkCategoryName v-else-if="from.key === 'category'" :uid="from.value" />
      <div v-else-if="from.key === 'due_date' || from.key === 'start_date'">
        {{ $date(from.value) }}
      </div>
    </template>
    <template #to="{ to }">
      <span class="mx-[5px]">
        {{ $t('to') }}
      </span> <TaskPriority v-if="to.key === 'priority'" :priority="to.value" />
      <TaskStatus v-else-if="to.key === 'status'" :status="to.value" />
      <HawkCategoryName v-else-if="to.key === 'category'" :uid="to.value" />
      <div v-else-if="to.key === 'due_date' || to.key === 'start_date'">
        {{ $date(to.value) }}
      </div>
    </template>
    <template #meta="{ meta }">
      <div class="mx-[5px]">
        <div v-if="meta.key === 'dependencies'">
          <span class="text-sm">
            {{ $t("added") }}
            {{ Object.values(meta.items).flat().length }}
            {{ meta.items.blockedBy ? $t("blocking") : meta.items.blocking ? $t("blocked") : $t("linked") }}
            {{ Object.values(meta.items).flat().length > 1 ? $t("tasks") : $t("task") }}
          </span>
        </div>
        <div v-else-if="meta.key === 'blockedBy'">
          <span class="text-sm">
            {{ `${meta.items.length} ${$t("blocking")} ${$t("task")}` }}
          </span>
        </div>
        <div v-else-if="meta.key === 'blocking'">
          <span class="text-sm">
            {{ `${meta.items.length} ${$t("blocked")} ${$t("task")}` }}
          </span>
        </div>
        <div v-else-if="meta.key === 'linkedTo'">
          <span class="text-sm">
            {{ `${meta.items.length} ${$t("linked")} ${$t("task")}` }}
          </span>
        </div>
        <div v-else-if="meta.key === 'added' || meta.key === 'removed'">
          <HawkMembers :members="meta.items" type="badge" />
        </div>
        <div v-else-if="meta.key === 'attachments'">
          {{ `${meta.items.count} ${$t("attachments added")}` }}
        </div>
        <div v-else-if="meta.key === 'uids'">
          <div
            v-if="meta.items?.length"
            class=" text-sm flex"
          >
            <p
              class="flex items-center no-whitespace-wrap"
            >
              <IconHawkDependencyLinked class="text-primary h-3" />
              {{ task_store?.tasks_map[meta.items[0]]?.name || `*${$t('Task added')}` }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </HawkActivitiesTemplate>
</template>
