<script setup>
import { useAnnotationsStore } from '~/plans/store/annotations.store';

const props = defineProps({
  custom_props: {
    type: Object,
    required: true,
  },
});

const annotations_store = useAnnotationsStore();

const { component_type, change_opacity_handler, change_font_size_handler } = props.custom_props;

const form = ref({
  fill_opacity: annotations_store.annotations_edit_config.fill_opacity,
  outline_opacity: annotations_store.annotations_edit_config.outline_opacity,
  stroke_thickness: annotations_store.annotations_edit_config.stroke_thickness,
  font_size: annotations_store.annotations_edit_config.font_size,
});

function onChangeHandler(newVal, oldVal, el$) {
  if (el$.data.fill_opacity >= 0)
    change_opacity_handler('fill_opacity', newVal);

  if (el$.data.outline_opacity >= 0)
    change_opacity_handler('outline_opacity', newVal);

  if (el$.data.stroke_thickness)
    change_opacity_handler('stroke_thickness', newVal);

  if (el$.data.font_size)
    change_font_size_handler(newVal);
}
</script>

<template>
  <div class="w-full p-2 py-3">
    <Vueform
      v-model="form"
      size="sm"
      :display-errors="false"
      sync
    >
      <div class="col-span-12 w-full">
        <SliderElement
          v-if="component_type === 'outline-opacity'"
          class="w-full"
          name="outline_opacity"
          tooltip-position="bottom"
          :format="{ suffix: '%' }"
          @change="onChangeHandler"
        />
        <SliderElement
          v-if="component_type === 'fill-opacity'"
          class="w-full"
          name="fill_opacity"
          tooltip-position="bottom"
          :format="{ suffix: '%' }"
          @change="onChangeHandler"
        />
        <SliderElement
          v-if="component_type === 'stroke-thickness'"
          class="w-full"
          name="stroke_thickness"
          :min="1"
          :max="10"
          tooltip-position="bottom"
          @change="onChangeHandler"
        />
        <SliderElement
          v-if="component_type === 'font-size'"
          class="w-full"
          name="font_size"
          :min="1"
          :max="128"
          tooltip-position="bottom"
          @change="onChangeHandler"
        />
      </div>
    </Vueform>
  </div>
</template>
