<script setup>
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  default_statuses: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['toggle-active']);

const dms_settings_store = useDMSSettingsStore('transmittals');

function onTextUpdated(index, value, old_value) {
  if (value?.length === 0)
    emit('toggle-active', { index, status: false });
  else if (old_value?.length === 0)
    emit('toggle-active', { index, status: true });
}
</script>

<template>
  <div>
    <div class="grid gap-3 grid-cols-[12px_400px_50px_400px]">
      <div class="col-span-3 text-sm font-medium">
        {{ $t('Review Statuses') }}
      </div>
      <div class="text-sm font-medium">
        {{ $t('Document Statuses') }}
      </div>
    </div>
    <ListElement name="status_map" :default="default_statuses" :controls="{ remove: false, add: false }">
      <template #default="{ index }">
        <ObjectElement :name="index">
          <div class="col-span-12">
            <div class="grid gap-3 items-center grid-cols-[12px_400px_50px_400px] mt-2">
              <div class=" h-[12px] rounded-full" :class="default_statuses[index].class" />
              <div>
                <TextElement name="review_status" :disabled="disabled" @change="(value, old_value) => onTextUpdated(index, value, old_value)">
                  <template v-if="default_statuses[index].key === 'revise_resubmit' || default_statuses[index].key === 'rejected'" #addon-after>
                    <IconHawkInfoCircle v-tippy="$t('The document will be sent back to submitter')" class="w-4 h-4 mt-0.5" />
                  </template>
                </TextElement>
              </div>
              <div v-if="default_statuses[index].disabled" class="flex justify-center cursor-pointer">
                <IconHawkXClose class="text-gray-400" />
              </div>
              <div v-else class="flex justify-center cursor-pointer" @click="emit('toggle-active', { index, status: default_statuses[index].active ? false : true })">
                <IconHawkArrowRight v-if="default_statuses[index].active" />
                <IconHawkXCircle v-else class="text-error-600" />
              </div>
              <div>
                <SelectElement
                  :native="false"
                  :can-deselect="false"
                  :can-clear="false"
                  :add-classes="{
                    SelectElement: {
                      select: {
                        dropdown: ['scrollbar'],
                      },
                    },
                  }"
                  name="document_status"
                  :items="dms_settings_store.document_status"
                  :disabled="default_statuses[index].disabled || !default_statuses[index].active || disabled"
                  label-prop="name"
                  value-prop="slug"
                />
              </div>
            </div>
          </div>
        </ObjectElement>
      </template>
    </ListElement>
    <div class="mt-4">
      <span class="font-semibold">{{ $t('Note') }}:</span> {{ $t('The document statuses will be synced only after completion of the transmittal.') }}
    </div>
  </div>
</template>
