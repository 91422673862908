<script setup>
import { Validator } from '@vueform/vueform';
import { isArray, pick } from 'lodash-es';
import HawkSubmitButton from '~/common/components/atoms/hawk-submit-button.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryCustomFieldInput from '~/inventory/components/inventory-custom-fields/inventory-custom-field-input.vue';
import InventoryItemTypeInput from '~/inventory/components/inventory-item-type/inventory-item-type-input.vue';
import InventoryUom from '~/inventory/components/inventory-uom/inventory-uom.vue';
import InventoryUomInput from '~/inventory/components/inventory-uom/inventory-uom-input.vue';
import { useCustomFields } from '~/inventory/composables/inventory-custom-fields.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const { $t, $toast, auth_store, route, router } = useCommonImports();
const form$ = ref({});
const form = ref({});
const batch_number_toggle = ref(null);
const reorder_point$ = ref(null);

const state = reactive({
  loading: false,
  form_loading: false,
});

const cost_currency = ref('USD');
const custom_fields_config = ref([]);
const inventory_store = useInventoryStore();

const is_edit = computed(() => !!(!route.query.duplicate && route.params?.item_id));
const item_id = computed(() => route.params.item_id);

const item_details = computed(() => {
  return route.params?.item_id
    ? inventory_store.items.find(item => item.uid === item_id.value)
    : null;
});

const get_item_custom_fields = computed(() => inventory_store.get_custom_fields({ attached_to: 'item' }, true));

const { map_custom_fields } = useCustomFields();

function showToast(data) {
  if (isArray(data?.items) ? data?.items?.length : data?.item?.uid) {
    const success_title = route.query?.duplicate?.length
      ? $t('Item duplicated')
      : $t('Item updated');
    const success_text = route.query?.duplicate?.length
      ? $t('The item was successfully duplicated.')
      : $t('Changes to the item saved successfully.');
    $toast({
      title: is_edit.value ? success_title : $t('Item created'),
      text: is_edit.value ? success_text : $t('The item has been created successfully.'),
      type: 'success',
    });
    router.push({ name: 'inventory-items', params: { ...route.params } });
  }

  else {
    const { title, message } = inventory_store.get_error_status(data?.error_code) || {};

    $toast({
      title: title || 'Something went wrong',
      text: message || 'Please try again',
      type: 'error',
    });
  }
}

async function save() {
  try {
    const form_data = form$.value.requestData;

    form_data.custom_fields = form_data.custom_fields?.map((cf) => {
      return {
        ...cf,
        created_at: new Date(),
        created_by: auth_store.$state.logged_in_user_details.user_id,
      };
    }) || [];
    form_data.cost_currency = cost_currency.value;

    if (!form.value?.enable_notifications)
      form_data.reorder_point = 0;
    else if (!form.value?.reorder_point)
      form_data.reorder_point = 1;

    let response = null;
    if (is_edit.value) {
      response = await inventory_store.update_inventory_item({
        id: route.params.item_id,
        body: {
          item: form_data,
        },
      });
    }
    else {
      response = await inventory_store.create_inventory_items({
        items: [form_data],
      });
    }

    showToast(response?.data);
  }
  catch (error) {
    showToast(error?.data);
  }
}

const isNumber = class extends Validator {
  get message() {
    return 'The cost exceeds the limit';
  }

  check(value) {
    const length = value.toString().length;
    return length <= 16;
  }
};

const decimalCheck = class extends Validator {
  get message() {
    return 'Cost can have upto 2 decimals';
  }

  check(value) {
    // eslint-disable-next-line regexp/no-unused-capturing-group
    return /(^\d*(\.\d{1,2})?$)/.test(value);
  }
};

function onFormUpdated(event) {
  if (event.type === 'upload_started')
    state.loading = true;
  else if (event.type === 'upload_completed')
    state.loading = false;
}
watch(get_item_custom_fields, async () => {
  await map_custom_fields(custom_fields_config, get_item_custom_fields.value, form.value.custom_fields);
  form.value.custom_fields = custom_fields_config.value?.map(field => ({
    uid: field.uid,
    value: field.value,
  }));
  form$.value.load(form.value, true);
});
onMounted(async () => {
  if (is_edit.value) {
    if (item_details.value) {
      await map_custom_fields(custom_fields_config, get_item_custom_fields.value, item_details.value.custom_fields);
      const keys = Object.keys(form.value);
      form.value = pick(item_details.value, keys);
      form.value.enable_notifications = !!form.value.reorder_point;
      form.value.custom_fields = custom_fields_config.value?.map(field => ({
        uid: field.uid,
        value: field.value,
      }));
      cost_currency.value = item_details.value.cost_currency;
      form.value.image = item_details.value.image || null;
      form.value.cost = Number(item_details.value.cost);
      form$.value.load(form.value, true);
    }
    else {
      router.push({ name: 'inventory-items', params: { ...route.params } });
    }
  }

  else if (item_id.value) {
    if (item_details.value) {
      const keys = Object.keys(form.value);
      form.value = pick(item_details.value, keys);
      form.value.cost = Number(item_details.value.cost);
      form.value.enable_notifications = !!form.value.reorder_point;
      form.value.custom_fields = item_details.value.custom_fields;
      cost_currency.value = item_details.value.cost_currency;
      form.value.number = null;
      form.value.name = `${form.value.name} (copy)`;
      form$.value.load(form.value, true);
      await map_custom_fields(custom_fields_config, get_item_custom_fields.value, item_details.value.custom_fields);
    }
    else {
      router.push({ name: 'inventory-items', params: { ...route.params } });
    }
  }
  else {
    await map_custom_fields(custom_fields_config, get_item_custom_fields.value);
  }
});

function preventKeydown(e) {
  if (e.keyCode === 189 || e.keyCode === 187 || e.keyCode === 69)
    e.preventDefault();
}
</script>

<template>
  <div class="m-5 grid gap-5">
    <div class="">
      <div class="text-lg font-semibold">
        {{ is_edit ? $t('Edit Item') : $t('New Item') }}
      </div>
      <div class="text-xs text-gray-600">
        {{ $t('Add item details') }}
      </div>
    </div>
    <Vueform
      ref="form$"
      v-model="form"
      :display-errors="false"
      :attachment_config="{ meta: { service: 'vault', id: 'upload' } }"
      class="pb-8"
      size="sm"
      :endpoint="save"
      :loading="state.loading"
      :columns="{
        sm: {
          container: 6,
          label: 12,
          wrapper: 12,
        },
      }"
      @updated="onFormUpdated"
    >
      <div class="col-span-12">
        <div class="grid max-w-[900px] grid-cols-6 gap-4 md:grid-cols-12">
          <div class="col-span-6">
            <TextElement
              name="name"
              :label="$t('Item name')"
              :placeholder="$t('Enter item name')"
              :rules="['required', 'max:250']"
            />
          </div>
          <div class="col-span-6">
            <TextElement
              name="number"
              :label="$t('Item number')"
              :placeholder="$t('Enter item number')"
              :rules="['required', 'max:32']"
              :submit="!is_edit"
              :disabled="is_edit"
              :addons="{ before: '#' }"
            />
          </div>
          <div class="col-span-6">
            <InventoryItemTypeInput />
          </div>
          <div class="col-span-6">
            <InventoryUomInput />
          </div>
          <div class="col-span-6">
            <TextareaElement
              name="description"
              :label="$t('Description')"
              :placeholder="$t('Enter description')"
              :rules="['max:250']"
            />
          </div>
          <div class="col-span-6">
            <GroupElement
              name="cost_group"
              :label="$t('Cost per item')"
            >
              <CurrencyElement
                class="mb-4"
                name="cost"
                :currency_code="cost_currency"
                :rules="['nullable', isNumber, 'numeric', decimalCheck]"
                autocomplete="off"
                default="0"
                :columns="{
                  sm: {
                    container: 12,
                    label: 12,
                    wrapper: 12,
                  },
                }"
                @keydown="e => preventKeydown(e)"
                @unit-changed="cost_currency = $event.code;"
              />
            </GroupElement>
          </div>

          <div class="col-span-6">
            <ToggleElement
              v-if="!is_edit"
              name="is_serial_number"
              :submit="form.is_serial_number && !is_edit"
              :columns="{
                sm: {
                  container: 12,
                  label: 8,
                  wrapper: 12,
                },
              }"
              :add-classes="{
                ElementLayout: {
                  innerContainer: 'justify-items-end',
                },
              }"
            >
              <template #label>
                <div>
                  <div class="flex items-center">
                    {{ $t('Enable serial number tracking') }}
                    <IconHawkInfoCircleTwo v-tippy="{ content: $t('Serial tracking can only be set up at the time of item creation. You will not be able to alter this setting later.') }" class="ml-2 h-4 w-4" />
                  </div>
                  <div class="text-xs">
                    {{ $t('Track serial number for all transactions') }}.
                  </div>
                </div>
              </template>
            </ToggleElement>
          </div>
          <div class="col-span-6">
            <ToggleElement
              ref="batch_number_toggle"
              name="is_batch_number"
              :conditions="[['is_serial_number', '==', true]]"
              :submit="form.is_batch_number && !is_edit"
              :disabled="is_edit"
              :columns="{
                sm: {
                  container: 12,
                  label: 9,
                  wrapper: 12,
                },
              }"
              :add-classes="{
                ElementLayout: {
                  innerContainer: 'justify-items-end',
                },
              }"
            >
              <template #label>
                <div>
                  <div class="flex items-center">
                    {{ $t('Enable pallet number tracking') }}
                    <IconHawkInfoCircleTwo v-tippy="{ content: $t('Pallet numbers tracking can only be set up at the time of item creation. You will not be able to alter this setting later.') }" class="ml-2 h-4 w-4" />
                  </div>
                  <div class="text-xs">
                    {{ $t('Track serial numbers in bulk for all transactions') }}.
                  </div>
                </div>
              </template>
            </ToggleElement>
          </div>
        </div>

        <hr class="my-5">

        <div>
          <div class="grid max-w-[900px] grid-cols-6 gap-4 md:grid-cols-12">
            <div class="col-span-6">
              <ToggleElement
                name="enable_notifications"
                :label="$t('Receive stock alerts')"
                :submit="false"
                :columns="{
                  sm: { container: 12, label: 9, wrapper: 12 },
                }"
                :add-classes="{
                  ElementLayout: {
                    innerContainer: 'justify-items-end',
                  },
                }"
                @change="reorder_point$.load(~~$event)"
              />
            </div>
            <div v-if="form.enable_notifications" class="col-span-6" />

            <div class="col-span-6">
              <TextElement
                ref="reorder_point$"
                name="reorder_point"
                input-type="number"
                :rules="[
                  {
                    required: [['enable_notifications', '==', true]],
                  },
                  'min:1',
                  'numeric',
                  'integer',
                ]"
                :label="$t('Reorder point')"
                :conditions="[['enable_notifications', '==', true]]"
                default="1"
                @keydown="e => preventKeydown(e)"
              >
                <template #addon-after>
                  <InventoryUom
                    class="text-sm text-gray-500"
                    :uom="form.uom"
                    is_symbol
                  />
                </template>
              </TextElement>
            </div>
            <div v-if="form.enable_notifications" class="col-span-6" />
            <div class="col-span-6">
              <TextElement
                name="lead_time"
                input-type="number"
                :rules="['nullable', 'min:0', 'numeric']"
                autocomplete="off"
                :label="$t('Lead time')"
                default="1"
                class="mb-4"
              >
                <template #addon-after>
                  <div class="mr-2 h-full w-[1px] bg-gray-300" />
                  {{ $t('days') }}
                </template>
              </TextElement>
            </div>
          </div>
        </div>

        <div class="grid max-w-[900px] grid-cols-6 gap-4 md:grid-cols-12">
          <div class="col-span-12 grid gap-4">
            <InventoryCustomFieldInput
              :key="`InventoryCustomFieldInput${custom_fields_config?.length}`"
              :options="{
                name: 'custom_fields',
                columns: {
                  sm: { container: 12, label: 12, wrapper: 12 },
                },
              }"
              :label_size="12"
              :data="custom_fields_config"
              :input_size="6"
              attached_to="item"
            />
          </div>
        </div>

        <StaticElement
          name="static"
          class="sticky bottom-0 mt-6 border-t border-t-gray-200 bg-white py-6"
        >
          <div>
            <div class="flex w-full justify-end">
              <HawkButton
                class="mr-2"
                type="outlined"
                @click="router.back()"
              >
                {{ $t('Cancel') }}
              </HawkButton>
              <HawkSubmitButton v-if="route?.params?.item_id ? auth_store.check_permission('modify_items', route.params.asset_id) : auth_store.check_permission('create_items', route.params.asset_id)" :form$="form$">
                {{ $t('Save') }}
              </HawkSubmitButton>
            </div>
          </div>
        </StaticElement>
      </div>
    </Vueform>
  </div>
</template>
