<script setup>
import { inject } from 'vue';
import dompurify from 'dompurify';
import { useRoute } from 'vue-router';

const props = defineProps({
  transmittal: {
    type: Object,
    default: null,
  },
  is_last_collapsed: {
    type: Boolean,
    default: false,
  },
  is_public_page: {
    type: Boolean,
    default: false,
  },
});

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const route = useRoute();
const form$ = inject('form$');
const state = reactive({
  is_collapsed: props.is_last_collapsed,
});

const is_transmittal_detail = computed(() => !!route?.params?.transmittal_uid);
const transmittal_message = computed(() => {
  if (!state.is_collapsed)
    return dompurify.sanitize(props.transmittal?.message, { ALLOWED_TAGS: [] });
  return dompurify.sanitize(props.transmittal?.message);
});

function deleteDocument(file) {
  const supporting_documents = form$.value?.data?.supporting_documents;
  form$.value.update({
    supporting_documents: supporting_documents.filter(docs => docs.file_name !== file.file_name),
  });
}
function downloadDocument(document) {
  window.open(document?.service?.url, '_blank');
}
</script>

<template>
  <div>
    <transmittal-detail-messages-header
      :class="{ 'py-5': state.is_collapsed, 'pt-5': !state.is_collapsed }"
      :message="transmittal"
      :is_public_page="is_public_page"
    />
    <div
      class="ml-12 text-sm font-medium text-gray-600 grid gap-3"
      :class="{ 'cursor-pointer': !state.is_collapsed }"
      @click="state.is_collapsed = true"
    >
      <div v-if="!state.is_collapsed" :class="{ truncate: !state.is_collapsed }" class="max-w-[900px]">
        {{ transmittal_message }}
      </div>
      <HawkWysiwygEditorComponent
        v-else
        v-model="transmittal_message"
        :editor_enabled="false"
        :view="{ no_padding: true }"
        class="!p-0"
        editor_classes="!p-0"
      />
      <div v-if="transmittal.attachments?.length" class="mt-2">
        <div>
          <div class="font-semibold text-sm text-gray-800">
            {{ $t('Supporting documents') }} <span class="text-gray-900 relative -top-[3px] text-lg px-1"> . </span> <span class="text-gray-500"> {{ transmittal.attachments.length }} {{ $t('attachments') }}</span>
          </div>
          <hawk-attachments-list
            :items="transmittal.attachments"
            additional_classes="gap-x-2 grid-cols-4"
            v-bind="{
              can_download: is_transmittal_detail,
              can_delete: !is_transmittal_detail,
            }"
            v-on="{
              ...(is_transmittal_detail ? { download: downloadDocument } : { delete: deleteDocument }),
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
