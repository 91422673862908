<script setup>
import { ref } from 'vue';
import { DISTANCE_UNITS } from '~/plans/constants';

const props = defineProps({
  measurement: {
    type: Number,
    required: true,
  },
  unit: {
    type: String,
    required: true,
  },
  has_saved_calibration: {
    type: Boolean,
    default: false,
  },
  annotation: {
    type: Object,
    default: null,
  },
  calibration_preset: {
    type: Object,
    default: null,
  },
  modal_options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close', 'save']);

const form = ref({
  'measurement-input': props.has_saved_calibration ? props.calibration_preset.Scale[1][0] : props.measurement,
  'unit-select': props.has_saved_calibration ? props.calibration_preset.Scale[1][1] : props.unit,
});

function onSaveHandler() {
  emit('save',
    {
      world_scale_value: Number(form.value['measurement-input']),
      world_unit: form.value['unit-select'],
      page_scale_value: props.measurement,
      page_unit: props.unit,
      apply_to_drawing: form.value['apply-all-toggle'], // Applies the calibration to the current drawing containing all sheets
    },
  );
  emit('close');
}

function onCancelHandler() {
  emit('close', props.annotation);
}
</script>

<template>
  <hawk-modal-container :options="modal_options">
    <Vueform
      v-model="form"
      size="sm"
      class="w-full"
      :sync="true"
      :display-errors="false"
      @submit="onSaveHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <span v-if="!has_saved_calibration">
              {{ $t('Set calibration') }}
            </span>
            <span v-else>
              {{ $t('Update calibration') }}
            </span>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <div class="text-sm">
            <span v-if="!has_saved_calibration" class="block text-gray-600 mb-4">{{ $t('Calibration is not set. Please set the calibration') }}</span>

            <div class="flex justify-between items-center gap-2">
              <span class="font-medium w-1/3">{{ $t('Measurement') }}</span>
              <div class="flex gap-2 w-full">
                <TextElement
                  class="w-full"
                  name="measurement-input"
                  :placeholder="$t('Enter measurement between two points')"
                  input-type="number"
                  :rules="['required', 'min:0.01', 'numeric']"
                  :attrs="{ autofocus: true }"
                />
                <SelectElement
                  class="w-20"
                  name="unit-select"
                  :can-clear="false"
                  :items="DISTANCE_UNITS"
                  rules="required"
                />
              </div>
            </div>
            <div class="flex items-center gap-2 mt-4">
              <span class="font-medium w-1/3">{{ $t('Apply to all sheets in the drawing') }}</span>
              <div class="w-full">
                <ToggleElement size="md" name="apply-all-toggle" />
              </div>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <HawkButton color="primary" type="outlined" class="mr-3" @click="onCancelHandler">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement submits name="calibration-submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
