<script setup>
defineProps({
  items: {
    type: Array,
    required: true,
  },
  last_index: {
    type: Number,
    required: true,
  },
});

const file_path = ref(null);
</script>

<template>
  <div class="flex items-baseline">
    <ul ref="file_path" class="flex max-w-[350px] truncate">
      <li v-for="(item, index) in items" :key="index" class="text-xs font-normal text-gray-600 py-[1px]">
        <span>
          {{ item }}
        </span>
        <span v-if="last_index !== index" class="mx-1">
          {{ '>' }}
        </span>
      </li>
    </ul>
    <span v-if="file_path?.getBoundingClientRect().width === 350" class="text-lg text-gray-500 inline-block ml-[2px]">
      ...
    </span>
  </div>
</template>
