<script>
import { VueFinalModal } from 'vue-final-modal';

export default {
  components: {
    VueFinalModal,
  },
  props: {
    content_class: {
      type: String,
      default: 'rounded-lg',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close'],
};
</script>

<template>
  <VueFinalModal
    v-bind="options"
    :content-class="`bg-white border space-t-2 rounded-lg w-[600px] ${content_class}`"
    class="flex justify-center items-center z-[1003]"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    @clickOutside="$emit('close')"
    @closed="$emit('close')"
  >
    <slot />
  </VueFinalModal>
</template>
