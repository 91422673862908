<script setup>
import { useRoute } from 'vue-router';

const $t = inject('$t');

const route = useRoute();

// Form List page navigation
const tabs = computed(() => [{
  uid: '1',
  label: $t('Forms'),
  to: { name: 'form-overview-list', params: { asset_id: route.params.asset_id } },
  disabled: false,
}, {
  uid: '2',
  label: $t('Templates'),
  to: { name: 'form-templates', params: { asset_id: route.params.asset_id }, query: { active: 'published' } },
  disabled: false,
}]);

const active_tab = computed(() => route.matched.findIndex(route => route.name === 'form-templates') >= 0 ? '2' : '1');
</script>

<template>
  <div class="flex min-h-full flex-col justify-center">
    <HawkPageHeader :title="$t('Forms')" :display_onboarding="{ for: 'forms' }" class="-mb-2">
      <template #left>
        <hawk-page-header-tabs :tabs="tabs" :active_item="active_tab" />
      </template>
    </HawkPageHeader>
    <div class="sm:px-4 lg:px-4">
      <router-view />
    </div>
  </div>
</template>
