<script setup>
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  on_save: {
    type: Function,
  },
  title: {
    type: String,

  },
});

const form = ref({
  projects: [],
});

const { getProjectOptions } = useTerraHelperComposable();
const projects_list = computed(() => getProjectOptions());
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="form" :columns="{
        lg: {
          container: 12,
          label: 5,
          wrapper: 12,
        },
      }"
      sync size="sm" :display-errors="false" :endpoint="on_save"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ title }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="min-h-[200px]">
          <HawkTreeSelect
            class="mb-5"
            :options="{
              name: 'projects',
              label: 'Projects',
              placeholder: 'Select Project',
              rules: ['required'],
            }"
            select_type="LEAF_PRIORITY"
            children_key="projects"
            label_key="name"
            value_key="uid"
            :data="projects_list"
            @updateForm="form.projects = $event"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Export') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
