<script setup>
import { useTasksStore } from '~/tasks/store/tasks.store';

import TaskPriority from '~/tasks/components/atoms/task-priority.vue';

const props = defineProps({
  priority: {
    type: Number,
    default: 5,
  },
  position: {
    type: String,
    default: 'bottom-right',
  },
});
const emit = defineEmits(['close', 'priority-selected']);
const tasks_store = useTasksStore();
</script>

<template>
  <hawk-menu :position="position">
    <template #trigger>
      <slot name="trigger">
        <TaskPriority :priority="priority" />
      </slot>
    </template>
    <template #content>
      <div
        v-for="priority_item in tasks_store.priority_values"
        :key="priority_item.value"
        class="px-2 py-1 w-[164px]"
      >
        <div
          class="flex items-center justify-between hover:bg-gray-50 whitespace-nowrap cursor-pointer p-2 rounded-md text-sm text-gray-700 font-medium"
          @click="emit('priority-selected', priority_item.value); emit('close')"
        >
          <TaskPriority :priority="priority_item.value" />
          <IconHawkCheck v-if="priority_item.value === priority" class="text-blue-500 w-3" />
        </div>
      </div>
    </template>
  </hawk-menu>
</template>
