<script setup>
import { debounce } from 'lodash-es';

const props = defineProps({
  type: {
    type: String,
    validator: value => ['Rename Checklist', 'Duplicate Checklist', 'Save as template'].includes(value),
  },
  checklist: {
    type: Object,
    default: () => {
      return {
        name: '',
        items: [],
      };
    },
  },
  index: {
    type: Number,
    default: 0,
  },
  on_submit: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(['close']);
const $t = inject('$t');
const $services = inject('$services');

const form$ = ref(null);
const checklist_templates = ref([]);
const search_key = ref('');
onMounted(async () => {
  if (props.type === 'Save as template') {
    const { data } = await $services.checklists.getAll({ query: { page_number: 1, page_size: 5 } });
    checklist_templates.value = data.checklists;
  }
});
watch(search_key, debounce(async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    const { data } = await $services.checklists.getAll({ query: { page_number: 1, page_size: 5, q: search_key.value } });
    checklist_templates.value = data.checklists;
  }
}, 500));

// Popup save
async function handleSave() {
  const form_data = form$.value.data;
  let payload = {};
  if (props.type === 'Save as template') {
    // Updating an existing template
    if (form_data.template.is_template)
      payload = {
        template_uid: form_data.template.uid,
        checklist_uid: props.checklist.uid,
      };
    // Creating a new template
    else
      payload = {
        name: form_data.template.name,
        checklist_uid: props.checklist.uid,
      };
  }
  else {
    payload = { name: form_data.name };
    if (props.type === 'Rename Checklist')
      payload = {
        ...payload,
        uid: props.checklist.uid,
      };
    else
      payload = {
        ...payload,
        action: 'duplicate',
        include: {
          assignee: true,
          attachments: true,
        },
        checklist_uid: props.checklist.uid,
      };
  }
  props.on_submit(payload);
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :should_validate_on_mount="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :display-errors="false"
      :endpoint="handleSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t(type) }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            v-if="type === 'Save as template'"
            class="mb-4"
            name="template"
            track-by="name"
            label-prop="name"
            value-prop="uid"
            :label="$t('Template')"
            :placeholder="$t('Choose template or Create new')"
            :search="true"
            :items="checklist_templates"
            :object="true"
            :create="true"
            :rules="['required']"
            @search-change="search_key = $event"
          >
            <template #option="{ option }">
              <p v-if="checklist_templates.length">
                {{ option.name }}
              </p>
              <p v-else class="whitespace-nowrap inline-block w-auto truncate">
                <span class="font-semibold">{{ $t('Create template') }}: </span> {{ option.name }}
              </p>
            </template>
          </SelectElement>
          <!-- Checklist Name -->
          <TextElement
            v-else
            class="mb-4"
            name="name"
            autocomplete="off"
            :label="$t('Checklist name')"
            :placeholder="$t('Checklist name')"
            :rules="['required']"
            :default="checklist.name"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
