<script setup>
import { storeToRefs } from 'pinia';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const dashboard_store = useDashboardStore();

const {
  widget_name,
} = storeToRefs(dashboard_store);

const {
  set_widget_name,
} = dashboard_store;

const form_data = ref({
  name: null,
});

onMounted(() => {
  form_data.value.name = widget_name.value || null;
});
</script>

<template>
  <Vueform
    v-model="form_data"
    sync
    class="mb-2"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    size="sm"
  >
    <TextElement
      name="name"
      label="Name"
      @change="set_widget_name"
    />
  </Vueform>
</template>
