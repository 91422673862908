<script setup>
const route = useRoute();
const $services = inject('$services');
const $toast = inject('$toast');
const state = reactive({
  is_loading: true,
});
async function getTokenData() {
  try {
    const { redirect_uri, state, nonce } = route.query;
    const response = await $services.auth.get_freshworks_token({
      query: { nonce },
    });
    const token = response?.data?.jwt;
    if (token && redirect_uri && state)
      location.href = `${redirect_uri}?id_token=${token}&state=${state}`;
  }
  catch (e) {
    $toast({
      title: 'Error',
      text: 'Something went wrong',
      type: 'error',
    });
  }
}
onMounted(() => {
  getTokenData();
});
</script>

<template>
  <div class="h-[calc(100vh-64px)] pt-4">
    Redirecting...
    <hawk-loader v-if="state.is_loading || !source_url" />
  </div>
</template>
