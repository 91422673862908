import { acceptHMRUpdate, defineStore } from 'pinia';
import { cloneDeep, omit, orderBy, pick } from 'lodash-es';
import { getHostName } from '../utils/common.utils';

export const useTagsStore = defineStore('tags', {
  persist: {
    key: getHostName() === 'localhost' ? 'localhost_persisted_tags_store' : `${import.meta.env.VITE_APP_ENV}_persisted_tags_store`,
  },
  state: () => ({
    tags_map: null,
  }),
  getters: {
    tags(state) { return orderBy((Object.values(state.tags_map || {})), ['name']); },
    get_tag(state) { return uid => state.tags_map?.[uid]; },
  },
  actions: {
    async add_tag(payload) {
      const response = await this.$services.tags_service.post({
        body: {
          tag: {
            name: payload,
            color: '#171e48',
          },
        },
      });
      const tag = response?.data?.tag;
      if (tag) {
        this.tags_map[tag.uid] = tag;
        return tag;
      }
    },
    async update_tag(tag) {
      try {
        await this.$services.tags_service.patch({
          id: tag.uid,
          body: {
            tag: pick(tag, ['name', 'color']),
          },
        });
        this.tags_map[tag.uid] = tag;
      }
      catch (err) {}
    },
    async delete_tag(id) {
      try {
        await this.$services.tags_service.delete({
          id,
        });
        this.tags_map = cloneDeep(omit(this.tags_map, id));
      }
      catch (err) {
        logger.log(err);
      }
    },
  },

});
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTagsStore, import.meta.hot));
