<script setup>
import 'flexmonster/lib/flexmonster.fusioncharts.js';
import { defineAsyncComponent, watch } from 'vue';

import './flexmonster.css';
import { getHostName } from '~/common/utils/common.utils';
import { import_fusioncharts } from '~/common/utils/package.utils.js';

const props = defineProps({
  export_file_name: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  chart_id: {
    type: String,
    default: 'fusionchart-container',
  },
  pivot_props: {
    type: Object,
    default: () => {},
  },
  pivot_table_data: {
    type: Array,
    default: () => [],
  },
  hide_header_options: {
    type: Array,
    default: () => [],
  },
  container_class: {
    type: String,
    default: '',
  },
  pivot_table_height: {
    type: Number,
    default: null,
  },
  show_header: {
    type: Boolean,
    default: true,
  },
  active_chart: {
    type: String,
    default: '',
  },
  show_hide_chart: {
    type: Boolean,
    default: true,
  },
  pivot_table_mapping: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['close', 'pivotConfig', 'pivotInstance', 'update', 'aftergriddraw', 'updateActiveChart']);

const Pivot = defineAsyncComponent(() =>
  import('vue-flexmonster/vue3'),
);
const pivot = ref(null);
const fusion_chart_state = ref(null);

const state = reactive({
  show_charts: null,
});

const pivot_settings_menu = [{
  label: 'Fields',
  value: 'Fields',
},
{
  label: 'Layout',
  value: 'Layout',
},
{
  label: 'Format cells',
  value: 'Format cells',
},
{
  label: 'Conditional formatting',
  value: 'Conditional formatting',
},
];
const pivot_export_menu = [
  {
    label: 'Excel',
    value: 'Excel',
  },
  {
    label: 'PDF',
    value: 'PDF',
  },
  {
    label: 'PNG',
    value: 'PNG',
  },

];
const pivot_charts_menu = [
  {
    label: 'Pie chart',
    value: 'Pie chart',
  },
  {
    label: 'Horizontal bar chart',
    value: 'Horizontal bar chart',
  },
  {
    label: 'Vertical bar chart',
    value: 'Vertical bar chart',
  },
  {
    label: 'Line chart',
    value: 'Line chart',
  },
  {
    label: 'Area chart',
    value: 'Area chart',
  },
];

const pivot_report_config = computed(() => {
  return {
    dataSource: {
      data: props.pivot_table_data,
      mapping: props.pivot_table_mapping,
    },
    options: {
      grid: {
        showHeaders: false,
      },
      configuratorButton: false,
    },
    formats: [{
      name: '', // an empty name allows specifying the default format for all the measures
      decimalPlaces: 2,
      thousandsSeparator: ',',
    }],
  };
});
function licenseKey() {
  if (getHostName().includes('localhost'))
    return 'Z7W3-XJB21M-1R5W35-592S3E-344H6Y-46020D-551665-3A4S5F-1G5E4V-073M6M-2W1Q';
  else return import.meta.env.VITE_APP_FLEXMONSTER_KEY;
}
function settingsAction(e) {
  const toolbar_instance = pivot.value.flexmonster.toolbar;

  switch (e.value) {
    case 'Fields':
      pivot.value.flexmonster.openFieldsList();
      break;
    case 'Layout':
      toolbar_instance.optionsHandler();
      break;
    case 'Format cells':
      toolbar_instance.showFormatCellsDialog();
      break;
    case 'Conditional formatting':
      toolbar_instance.showConditionalFormattingDialog();
  }
}

function exportAction(e) {
  const toolbar_instance = pivot.value.flexmonster.toolbar;
  const options = { filename: `${props.export_file_name}-${new Date()}` };
  switch (e.value) {
    case 'Excel':
      toolbar_instance.pivot.exportTo('excel', options);
      break;
    case 'PDF':
      toolbar_instance.pivot.exportTo('pdf', options);
      break;
    case 'PNG':
      toolbar_instance.pivot.exportTo('image', options);
      break;
  }
  emit('exported', { type: e.value });
}
function renderChart(chart, chartConfig, rawData) {
  document.querySelector(`#${props.chart_id}`).classList.add('opacity-0');
  if (chartConfig.categories?.[0]?.category)
    chartConfig.categories[0]?.category.forEach((item) => {
      item.label = String(item.label);
    });
  else if (chartConfig.data?.length)
    chartConfig.data.forEach((item) => {
      if (item.label)
        item.label = String(item.label);
    });

  chart.setJSONData(chartConfig);
  chartConfig.chart.formatNumberScale = '0';
  const format = pivot.value.flexmonster.fusioncharts.getNumberFormat(rawData.meta.formats[0]);
  for (const prop in format)
    chart[prop] = format[prop];

  setTimeout(() => {
    chart.setChartAttribute('theme', 'fusion');
    setTimeout(() => {
      document.querySelector(`#${props.chart_id}`).classList.remove('opacity-0');
    }, 0);
  }, 500);
}
function createFusionChart() {
  try {
    const chart = new fusion_chart_state.value.FusionCharts({
      type: state.show_charts,
      renderAt: props.chart_id,
      width: '100%',
      height: (props.pivot_table_height || 600) - 100,
      theme: 'fusion',
    });

    pivot.value.flexmonster.fusioncharts.getData({
      type: chart.chartType(),
    }, (chartConfig, rawData) => {
      renderChart(chart, chartConfig, rawData);
      chart.render();
    }, (chartConfig, rawData) => {
      renderChart(chart, chartConfig, rawData);
    });
  }
  catch (err) {
    logger.log(err);
  }
}
function chartsAction(e) {
  switch (e.value) {
    case 'Area chart':
      state.show_charts = 'stackedarea2d';

      break;
    case 'Line chart':
      state.show_charts = 'msline';

      break;
    case 'Pie chart':
      state.show_charts = 'pie2d';
      break;
    case 'Horizontal bar chart':
      state.show_charts = 'stackedbar2d';
      break;
    case 'Vertical bar chart':
      state.show_charts = 'stackedcolumn2d';
      break;
  }
  createFusionChart();
  emit('updateActiveChart', state.show_charts);
}
function reportComplete() {
  emit('pivotConfig', pivot.value);
  if (state.show_charts)
    createFusionChart();
}
function reportChange() {
  emit('pivotConfig', pivot.value);
}

onMounted(async () => {
  try {
    fusion_chart_state.value = await import_fusioncharts();

    if (typeof fusion_chart_state.value.VueFusionChartsComponent !== 'function')
      return;
    fusion_chart_state.value.VueFusionChartsComponent(fusion_chart_state.value.FusionCharts,
      fusion_chart_state.value.Charts,
      fusion_chart_state.value.FusionTheme);
  }
  catch (e) {
    logger.log({ e });
  }
});

watch(() => props.active_chart, (val) => {
  if (state.show_charts !== val)
    state.show_charts = props.active_chart;
});
</script>

<template>
  <div :class="container_class">
    <slot name="header">
      <div v-if="show_header" class="pt-4 pl-2 pr-2 flex">
        <slot name="header-left" />
        <slot name="header-right">
          <div class="flex ml-auto w-max">
            <HawkMenu
              v-if="!hide_header_options.includes('Charts')"
              :items="pivot_charts_menu"
              position="bottom-left"
              additional_dropdown_classes="w-[200px]"
              @select="chartsAction"
            >
              <template #trigger>
                <HawkButton type="text">
                  <IconHawkTrendUpOne class="w-4 h-4" />  {{ $t('Charts') }}
                </HawkButton>
              </template>
            </HawkMenu>
            <HawkMenu
              v-if="!hide_header_options.includes('Export')"
              class="ml-1"
              :items="pivot_export_menu"
              position="bottom-left"
              additional_dropdown_classes="w-[200px]"
              @select="exportAction"
            >
              <template #trigger>
                <HawkButton type="text">
                  <IconHawkDownloadOne class="w-4 h-4" />    {{ $t('Export') }}
                </HawkButton>
              </template>
            </HawkMenu>
            <HawkMenu
              v-if="!hide_header_options.includes('Settings')"
              class="ml-1"
              :items="pivot_settings_menu"
              position="bottom-left"
              additional_dropdown_classes="w-[200px]"
              @select="settingsAction"
            >
              <template #trigger>
                <HawkButton type="text">
                  <IconHawkSettingsOne class="w-4 h-4" />    {{ $t('Settings') }}
                </HawkButton>
              </template>
            </HawkMenu>
          </div>
        </slot>
      </div>
    </slot>
    <div class="flex h-full w-full  mt-3">
      <div class="h-full" :class="[state.show_charts ? 'w-[50%]' : 'w-[100%]']">
        <Pivot
          ref="pivot"
          toolbar
          :reportcomplete="reportComplete"
          :reportchange="reportChange"
          :aftergriddraw="emit('aftergriddraw')"
          :ready="emit('pivotInstance', pivot)"
          :height="pivot_table_height || '100%'"
          :update="emit('update', pivot)"
          :report="pivot_report_config"
          class=" pl-2 pr-2"
          :license-key="licenseKey()"
        />
      </div>

      <div v-show="state.show_charts" class="bg-white p-5 mr-2 border  border-gray-200" :class="[state.show_charts ? 'w-[50%]' : 'w-[0%]']" :style="{ height: `${pivot_table_height || 600}px` }">
        <IconHawkXClose
          v-show="show_hide_chart"
          class=" flex ml-auto rounded-full h-5 w-5 mb-2 cursor-pointer"
          @click="state.show_charts = null; emit('updateActiveChart', state.show_charts)"
        />
        <div :id="chart_id" class="w-full" />
      </div>
    </div>
  </div>
</template>
