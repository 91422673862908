<script setup>
import { inject, onMounted } from 'vue';

import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const $services = inject('$services');

const data = ref(null);
async function getData() {
  const terra_response = await $services.terra_view_service.getAll({
    query: {
      asset: route.params.asset_id,
    },
  });
  data.value = terra_response.data;
}

onMounted(() => {
  getData();
});
</script>

<template>
  <SelectElement
    label-prop="name"
    value-prop="uid"
    name="map"
    :label="$t('Map')"
    :search="true" :items="data"
  />
</template>
