<script setup>
import { flatMap, remove } from 'lodash-es';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';

const props = defineProps({
  documents: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['close', 'clearSelection']);

const transmittal_store = useTransmittalsStore();

const state = reactive({
  documents: props.documents,
  is_loading: false,
});

const form$ = ref(null);

const transmittal = computed(() => transmittal_store.transmittal);
const existing_users = computed(() => {
  const all_member = flatMap(props.documents, document => flatMap(document.all_members));
  return [...new Set(all_member)];
});

function removeDocument(uid) {
  remove(state.documents, f => f.uid === uid);
}
async function submitHandler() {
  try {
    state.is_loading = false;
    const documents = [];
    state.documents.forEach((doc) => {
      documents.push({
        uid: doc.uid,
        member: form$.value.data?.member?.uid,
        message: form$.value.data?.message || '',
      });
    });
    await transmittal_store.document_operations({
      transmittal_uid: transmittal.value.uid,
      transmittals: { documents },
    }, 'delegate');
    emit('clearSelection');
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}

function formMounted(form$) {
  if (props?.documents?.length && props?.documents?.length > 1)
    return;
  const document = props.documents[0];
  form$.load({ member: document?.delegated_to?.user ? { uid: document?.delegated_to?.user } : null });
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]">
    <Vueform
      ref="form$"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }"
      :endpoint="submitHandler"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Delegate') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <div>
            <template v-for="document in state.documents" :key="document.uid">
              <div class="flex justify-between mb-3">
                <div class="text-gray-700 text-sm font-semibold">
                  {{ document.name }}
                </div>
                <IconHawkXClose
                  v-if="state.documents?.length !== 1"
                  class="w-5 h-5 text-gray-500 hover:text-gray-900 cursor-pointer"
                  @click="removeDocument(document.uid)"
                />
              </div>
            </template>
            <div class="text-sm text-gray-700">
              <span class="font-semibold">{{ $t('Note') }} : </span>
              <span>The transmittal will be delegated to the concerned person selected below. Henceforth, the concerned person will be responsible to complete the task and complete within the due date. A notification will be sent to the concerned person about the delegation.</span>
            </div>
            <hr class="my-6">
          </div>
          <HawkAssigneeInput
            :options="{
              name: 'member',
              placeholder: $t('Select a member'),
              label: $t('Delegate to'),
              description: $t(transmittal.user_role === 'submitter' ? 'Choose a member to submit selected document on your behalf' : 'Choose a member to review selected document on your behalf'),
              canClear: false,
              canDeselect: false,
              native: false,
              size: 'sm',
              existing_users: existing_users || [],
              rules: ['required'],
            }"
            :multi="false"
          />
          <WysiwygEditorElement
            class="mt-5"
            name="message"
            label="Message"
            size="sm"
            :rules="['required']"
            :options="{
              editor_enabled: true,
              menu_enabled: true,
              plugins: ['images', 'tables', 'tags'],
              placeholder_text: $t('Enter message here'),
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  submits size="sm"
                  name="submit"
                  :button-label="$t('Send')"
                  :loading="state.is_loading"
                  button-class="vf-btn-primary"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
