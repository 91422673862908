import AcctSettings from '~/acct-settings/pages/acct-settings.vue';
import AcctSettingsOrganizationDetails from '~/acct-settings/pages/acct-settings-organization-details.vue';
import AcctSettingsAssetDetails from '~/acct-settings/pages/acct-settings-asset-details.vue';

import AcctSettingsUsersAndInvitations from '~/acct-settings/pages/acct-settings-users-and-invitations/acct-settings-users-and-invitations.vue';
import AcctSettingsUsersList from '~/acct-settings/pages/acct-settings-users-and-invitations/acct-settings-users.vue';
import AcctSettingsUserDetails from '~/acct-settings/pages/acct-settings-user-details.vue';
import AcctSettingsUsersInvitations from '~/acct-settings/pages/acct-settings-users-and-invitations/acct-settings-invitations.vue';

import AcctSettingsTeams from '~/acct-settings/pages/acct-settings-teams.vue';
import AcctSettingsTeamDetails from '~/acct-settings/pages/acct-settings-team-details.vue';

import AcctSettingsRoles from '~/acct-settings/pages/acct-settings-roles.vue';
import AcctSettingsRoleDetails from '~/acct-settings/pages/acct-settings-role-details.vue';

import AcctSettingsAssetFields from '~/acct-settings/pages/acct-settings-asset-fields.vue';
import AssetSettingsIntegrations from '~/acct-settings/pages/acct-settings-integrations.vue';
import AcctSettingsAuditLogs from '~/acct-settings/pages/acct-settings-audit-logs.vue';

import AcctSettingsCategoriesAndTags from '~/acct-settings/pages/acct-settings-categories-and-tags/acct-settings-categories-and-tags.vue';
import AcctSettingsCategories from '~/acct-settings/pages/acct-settings-categories-and-tags/acct-settings-categories.vue';
import AcctSettingsTags from '~/acct-settings/pages/acct-settings-categories-and-tags/acct-settings-tags.vue';

const routes = [
  {
    path: '/:asset_id/account-settings',
    component: AcctSettings,
    meta: { },
    redirect: { name: 'account-settings-asset-general' },
    children: [
      {
        path: 'asset',
        name: 'account-settings-asset-general',
        component: AcctSettingsAssetDetails,
        meta: {
          title: 'General',
        },
      }],
  },
  {
    path: '/account-settings',
    component: AcctSettings,
    meta: { },
    children: [
      {
        path: '',
        name: 'account-settings-general',
        component: AcctSettingsOrganizationDetails,
        meta: {
          title: 'General',
        },
      }],
  },
  {
    path: '/:asset_id?/account-settings',
    component: AcctSettings,
    meta: { },
    children: [
      {
        path: 'users-and-invitations',
        component: AcctSettingsUsersAndInvitations,
        children: [
          {
            path: 'users',
            name: 'account-settings-users',
            component: AcctSettingsUsersList,
            meta: {
              title: 'User',
            },
          },
          {
            path: 'invitations',
            name: 'account-settings-invites',
            component: AcctSettingsUsersInvitations,
            meta: {
              title: 'Invitations',
              parent: 'account-settings-users',
            },
          },
        ],
      },
      {
        path: 'users/:id/details',
        name: 'account-settings-user-details',
        component: AcctSettingsUserDetails,
        meta: {
          title: 'User Details',
          parent: 'account-settings-users',
        },
      },
      {
        path: 'teams',
        name: 'account-settings-teams',
        component: AcctSettingsTeams,
        meta: {
          title: 'Teams',
        },
      },
      {
        path: 'teams/:id?/details',
        name: 'account-settings-team-details',
        component: AcctSettingsTeamDetails,
        meta: {
          title: 'Team Details',
          parent: 'account-settings-teams',
        },
      },
      {
        path: 'roles',
        name: 'account-settings-roles',
        component: AcctSettingsRoles,
        meta: {
          title: 'Roles',
        },
      },
      {
        path: 'roles/:id?/details',
        name: 'account-settings-role-details',
        component: AcctSettingsRoleDetails,
        meta: {
          module: 'account-settings-roles',
          title: 'Create Role',
          parent: 'account-settings-roles',
        },
      },
      {
        path: 'categories-and-tags',
        component: AcctSettingsCategoriesAndTags,
        meta: {

          title: 'Categories',
        },
        children: [
          {
            path: 'categories',
            name: 'account-settings-categories',
            component: AcctSettingsCategories,
            meta: {
              title: 'Categories',
            },
          },
          {
            path: 'tags',
            name: 'account-settings-tags',
            component: AcctSettingsTags,
            meta: {
              title: 'Tags',
              parent: 'account-settings-categories',
            },
          },
        ],
      },
      {
        path: 'categories',
        name: 'account-settings-asset-categories',
        component: AcctSettingsCategories,
        meta: {
          title: 'Categories',
        },
      },
      {
        path: 'asset-fields',
        name: 'account-settings-asset-fields',
        component: AcctSettingsAssetFields,
        meta: {
          title: 'Asset Settings',
        },
      },
      {
        path: 'integrations',
        name: 'account-settings-integrations',
        component: AssetSettingsIntegrations,
        meta: {
          title: 'Integrations',
        },
      },

      {
        path: 'audit-logs',
        name: 'account-settings-audit-logs',
        component: AcctSettingsAuditLogs,
        meta: {
          title: 'Audit logs',
        },
      },

    ],
  },

];
export default routes;
