<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useRoute } from 'vue-router';
import { groupBy, isEmpty, omit, pick } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { htmlToText } from '~/common/utils/common.utils.js';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { useDocumentStore } from '~/dms/store/document.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

import DmsDocumentsRequestForm from '~/dms/components/documents/forms/dms-documents-request-form.vue';
import TransmittalDetailReplyPopup from '~/dms/components/transmittals/transmittal-detail/transmittal-detail-reply-popup.vue';
import DmsTransmitForm from '~/dms/components/documents/forms/dms-transmit-form.vue';

import TransmittalDocumentSidebar from '~/dms/components/transmittals/transmittal-sidebar/transmittal-detail-document-sidebar.vue';

const props = defineProps({
  transmittal_uid: {
    type: String,
  },
  read_only: {
    type: Boolean,
    default: false,
  },
});
const $t = inject('$t');
const route = useRoute();
const router = useRouter();
const transmittal_store = useTransmittalsStore(props.transmittal_uid ? props.transmittal_uid : '');
const document_store = useDocumentStore();
const dms_settings_store = useDMSSettingsStore('transmittals');
const dms_documents_settings_store = useDMSSettingsStore('documents');

const state = reactive({
  isContextOpen: false,
  file_to_show: '',
  is_fetching_detail: false,
  is_loading: false,
  document_details: {},
  selected_row_uid: null,
  sidebar_key: 1,
});

const form$ = ref(null);

const document_request_modal = useModal({
  component: DmsDocumentsRequestForm,
  attrs: {
    onClosed() {
      const attrs = omit(document_request_modal.options.attrs, ['is_cancel', 'content', 'header', 'sub_header', 'header_icon', 'button_text', 'is_mark_as_submitted']); // reset attrs
      document_request_modal.options.attrs = attrs;
    },
  },
});

const reply_modal = useModal({
  component: TransmittalDetailReplyPopup,
  attrs: {
    onClosed() {
      const attrs = omit(reply_modal.options.attrs, ['content', 'header', 'sub_header', 'header_icon', 'button_text']);
      reply_modal.options.attrs = attrs;
    },
  },
});

const transmit_modal = useModal({
  component: DmsTransmitForm,
  attrs: {
    onClosed() {
      const attrs = omit(transmit_modal.options.attrs, ['content', 'header', 'sub_header', 'header_icon', 'button_text']);
      transmit_modal.options.attrs = attrs;
    },
  },
});

function openCompleteForm() {
  document_request_modal.patchOptions({
    attrs: {
      header: $t('Close Transmittal'),
      button_text: $t('Close'),
      confirm: async (data) => {
        await markAsComplete(data.comment);
      },
      onClose() {
        document_request_modal.close();
      },
    },
  });
  document_request_modal.open();
}

function onRowClick(row_data) {
  state.selected_row_uid = row_data?.uid;
  openSidebar();
}

function openSidebar() {
  state.document_details = (transmittal_store.transmittal?.documents || []).find(doc => doc.uid === state.selected_row_uid);
  if (state?.document_details?.unread)
    state.document_details.unread = false;
}

const transmittal = computed(() => {
  if (!isEmpty(transmittal_store.transmittal)) {
    const { members } = transmittal_store.transmittal;
    const grouped_members = groupBy(members, 'role');
    return {
      ...transmittal_store.transmittal,
      assignees: grouped_members?.submitter || [],
      reviewers: grouped_members?.approver || [],
    };
  }
  return {};
});

const has_drafts = computed(() => transmittal.value.documents?.filter(doc => !isEmpty(doc.draft)).length > 0);
onMounted(() => {
  getData();
});

watch(() => route.params.asset_id, (val) => {
  if (val) {
    getData();
    closeSlider();
  }
});

watch(() => route.params.transmittal_uid, (val) => {
  if (val) {
    getData();
    closeSlider();
  }
});

watch(() => props.transmittal_uid, (val) => {
  if (val) {
    getData();
    closeSlider();
  }
});

async function getData() {
  try {
    state.is_fetching_detail = true;
    const promise1 = transmittal_store.set_transmittal({ transmittal_uid: route.params.transmittal_uid || props.transmittal_uid, query: route.query.submitted_by_me ? { submitted_by_me: true } : undefined });
    const promise2 = dms_settings_store.set_custom_fields({ query: { allowed_in: 'transmittals' } });
    const promise3 = dms_documents_settings_store.set_custom_fields({ query: { allowed_in: 'documents' } });
    await Promise.all([promise1, promise2, promise3]);
    state.is_fetching_detail = false;
  }
  catch (err) {
    logger.error(err);
  }
}

function closeSlider() {
  state.document_details = {};
}

function onAttachmentClick(item) {
  state.file_to_show = item;
}

async function documentDownload({ file }) {
  const response = await document_store.get_download_url({
    body: {
      files: [{
        uid: file.uid,
      }],
    },
  });
  const url = response.data.urls[0].url;
  window.open(url, '_blank');
}

const is_saving = ref(false);

async function saveAsDraft() {
  is_saving.value = true;
  const { documents = {} } = form$.value.data;
  delete documents.three_state_checkbox; // need to confirm this field
  const payload = transmittal.value.documents.map((document) => {
    const { status, comments, attachment } = document?.draft || {};
    return {
      uid: document.uid,
      ...(status ? { status } : {}),
      comments: documents?.[document.uid]?.comments || comments || '',
      service_object: attachment?.service_object,
      name: attachment?.name,
      type: attachment?.type,
      size: attachment?.size,
      ...(pick(attachment, ['thumbnail_small', 'thumbnail_xsmall', 'thumbnail_large', 'thumbnail_small_size', 'thumbnail_xsmall_size', 'thumbnail_large_size'])),
    };
  });
  await transmittal_store.save_as_draft_transmittal({
    transmittal_uid: transmittal.value.uid,
    transmittals: { documents: payload },
  });
  is_saving.value = false;
}

async function transmitTransmittal({ description, supporting_documents }) {
  try {
    state.is_loading = true;
    const { documents = {} } = form$.value.data;
    delete documents.three_state_checkbox;
    const filtered_documents = transmittal.value.documents?.filter(document => !document.cancelled);
    const payload = filtered_documents.map((document) => {
      const { status, comments, attachment } = document?.draft || {};
      return {
        uid: document.uid,
        ...(status ? { status } : {}),
        comments: documents?.[document.uid]?.comments || comments || '',
        service_object: attachment?.service_object,
        name: attachment?.name,
        file_name: attachment?.name,
        type: attachment?.type,
        size: attachment?.size,
        ...(pick(attachment, ['thumbnail_small', 'thumbnail_xsmall', 'thumbnail_large', 'thumbnail_small_size', 'thumbnail_xsmall_size', 'thumbnail_large_size'])),
      };
    });
    await transmittal_store.transmit_transmittal({
      transmittal_uid: transmittal.value.uid,
      transmittals: {
        documents: payload,
        message: {
          body: description,
          attachments: supporting_documents,
        },
      },
    });
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

async function openTransmitModal() {
  try {
    transmit_modal.patchOptions({
      attrs: {
        header: $t('Transmit'),
        button_text: $t('Transmit'),
        confirm: async (data) => {
          await transmitTransmittal(data || {});
          transmit_modal.close();
        },
        onClose() {
          transmit_modal.close();
        },
      },
    });
    transmit_modal.open();
  }
  catch (err) {
    logger.error(err);
  }
}

async function markAsComplete(comment) {
  try {
    state.is_loading = true;
    await transmittal_store.transmittal_mark_as_completed({
      transmittal_uid: route.params.transmittal_uid,
      message: comment,
    });
    router.push({ name: 'files-transmittals' });
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

function openReplyModal() {
  try {
    reply_modal.patchOptions({
      attrs: {
        header: $t('Reply'),
        button_text: $t('Send'),
        transmittal: transmittal.value,
        submit: async (form_data, { requestData }) => {
          await transmittal_store.transmittal_send_message({
            transmittal_uid: route.params.transmittal_uid,
            payload: requestData,
          });
          reply_modal.close();
        },
        onClose() {
          reply_modal.close();
        },
      },
    });
    reply_modal.open();
  }
  catch (err) {
    logger.error(err);
  }
}

function onFormMounted(el$) {
  if (transmittal.value) {
    const { documents } = transmittal.value;
    if (!isEmpty(documents))
      documents.forEach((doc) => {
        el$.data.documents[doc.uid] = doc;
      });
  }
}
</script>

<template>
  <div v-if="['cancelled', 'completed'].includes(transmittal.status)" class="px-4 pt-4">
    <HawkAlertBanner :color="transmittal.status === 'cancelled' ? 'error' : 'success' " class="!border !border-gray-300 !rounded-xl !bg-white">
      <template #icon>
        <IconHawkCheckCircle v-if="transmittal.status === 'completed'" />
        <IconHawkAlertTriangle v-if="transmittal.status === 'cancelled'" />
      </template>
      <template #content>
        <div class="flex">
          <HawkMembers :members="transmittal?.owner" type="badge" class="mr-1" />
          <div v-if="transmittal.status === 'completed'" class="font-normal text-green-600">
            <span>marked the transmittal as completed on </span>
            <span class="text-gray-900 font-semibold">{{ $date(transmittal.updated_at, 'L_DATE_MED') }},</span>
            <span v-if="transmittal.messages?.length">
              <span> with note </span>
              <span class="text-gray-900 font-semibold">{{ htmlToText(transmittal.messages[transmittal.messages.length - 1].message) }}</span>
            </span>
          </div>
          <div v-if="transmittal.status === 'cancelled'" class="font-normal text-red-600">
            <span>cancelled the transmittal on </span>
            <span class="text-gray-900 font-semibold">{{ $date(transmittal.updated_at, 'L_DATE_MED') }},</span>
            <span v-if="transmittal.messages?.length">
              <span> with note </span>
              <span class="text-gray-900 font-semibold">{{ htmlToText(transmittal.messages[transmittal.messages.length - 1].message) }}</span>
            </span>
          </div>
        </div>
      </template>
    </HawkAlertBanner>
  </div>
  <router-view v-if="route.name === 'transmittal-detail-settings'" />
  <router-view v-else-if="route.name === 'document-markup'" />
  <template v-else>
    <div v-if="state.is_fetching_detail" class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
      <HawkLoader container_class="m-0 " />
    </div>
    <vueform
      v-else
      ref="form$"
      size="sm"
      class="p-4"
      @mounted="onFormMounted"
    >
      <transmittal-detail :transmittal="transmittal" :is_detail_page="true" :read_only="read_only" class="mb-5" />
      <ObjectElement
        :key="transmittal?.documents"
        name="documents"
        :columns="{
          default: { container: 12, wrapper: 12, label: 12 },
          sm: { container: 12, label: 12, wrapper: 12 },
          md: { container: 12, label: 12, wrapper: 12 },
        }"
      >
        <transmittal-detail-document
          :form$="form$"
          :read_only="read_only"
          :options="{
            is_date_disabled: true,
            can_add_documents: false,
          }"
          :transmittal="transmittal"
          :visible_columns="
            read_only ? ['name', 'versions', 'due_date', 'comments_out', 'comments_in', 'status', 'locked'] : ['select',
                                                                                                               'name',
                                                                                                               'versions',
                                                                                                               'due_date',
                                                                                                               'comments_in',
                                                                                                               'comments_out',
                                                                                                               'context_menu',
                                                                                                               'status',
                                                                                                               'locked',
            ]"
          :context_download="documentDownload"
          :file_to_show="state.file_to_show"
          @closeAttachment="state.file_to_show = ''"
          @rowClick="onRowClick"
        />
      </ObjectElement>
      <div v-if="!read_only" class="border-t  col-span-12 -mx-4 px-4 mt-5 pt-4">
        <div class="flex justify-between">
          <div>
            <hawk-button
              v-if="transmittal?.actions?.can_send_message"
              type="outlined"
              @click="openReplyModal"
            >
              <IconHawkReverseLeft />
              {{ $t('Reply') }}
            </hawk-button>
          </div>
          <div class="flex justify-end">
            <hawk-button
              v-if="transmittal?.actions?.can_transmit"
              :loading="state.is_loading"
              :disabled="state.is_loading || !has_drafts"
              @click="openTransmitModal"
            >
              {{ $t('Send') }}
            </hawk-button>
          </div>
        </div>
      </div>
    </vueform>
    <div v-if="!read_only" class="w-full">
      <div v-if="transmittal?.actions?.can_close" class="p-4">
        <HawkAlertBanner color="gray" class="items-center">
          <template #icon>
            <IconHawkCheckCircle class="text-success-700" />
          </template>

          <template #content>
            {{ $t('All documents are either closed or approved. Transmittal can be closed.') }}
          </template>
          <template #right>
            <hawk-button
              :loading="state.is_loading"
              @click="openCompleteForm"
            >
              {{ $t('Close Transmittal') }}
            </hawk-button>
          </template>
        </HawkAlertBanner>
      </div>
      <div v-else-if="has_drafts && transmittal?.actions?.can_transmit" class="p-4">
        <HawkAlertBanner color="warning">
          <template #icon>
            <IconHawkAlertTriangle />
          </template>
          <template #content>
            <div>
              {{ $t("Your changes are not transmitted. Please proceed to transmit them now.") }}
            </div>
          </template>
        </HawkAlertBanner>
      </div>
    </div>
  </template>
  <!-- use hawk-slide over -->
  <TransitionRoot
    as="template" :show="!!state.document_details?.uid"
    @close="closeSlider"
  >
    <Dialog as="div" class="relative z-[1000]">
      <div class="fixed inset-y-0 right-0 flex pl-10">
        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
          <DialogPanel class="pointer-events-auto w-[680px]">
            <TransmittalDocumentSidebar
              :key="state.sidebar_key"
              :document_details="state.document_details"
              @attachment-click="onAttachmentClick"
              @close="closeSlider"
              @refresh="openSidebar();state.sidebar_key += 1;"
            />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
