<script setup>
import { isEqual } from 'lodash-es';
import Papa from 'papaparse';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import HawkPivotTable from '~/common/components/organisms/hawk-pivot-table/hawk-pivot-table.vue';
import { useHawkPivotTableHelperComposable } from '~/common/components/organisms/hawk-pivot-table/helper-composable.js';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const $t = inject('$t');
const $services = inject('$services');
const emitter = useEmitter();
const dashboard_store = useDashboardStore();
const { preProcessData } = useHawkPivotTableHelperComposable();

const ERROR_MAP = {
  report_item_not_found: 'No stock available for the item',
};

const state = reactive({
  data: [],
  active_chart: null,
  is_loading: true,
  pivot_config: {},
  last_updated_at: null,
  error_msg: null,
});
const pivot_instance = ref({});

const get_pivot_table_height = computed(() => {
  if (props.id === 'preview')
    if (Number.isNaN(props.content_height))
      return false;
    else
      return props.content_height - 96;

  const widget_height = (((props.data.h || 22) * 20) - 44);
  return (props.content_height || widget_height) - 46;
});

async function getData(query = {}) {
  try {
    state.is_loading = true;
    const { data } = await $services.inventory_reports.inventory_dynamic_report({ body: props.data.data, query });

    state.last_updated_at = data.last_updated_date;

    Papa.parse(data.stock_unit, {
      download: true,
      header: true,
      // rest of config ...
      complete(results) {
        state.data = preProcessData(results.data);
        if (props.data?.data?.properties?.pivot_config) {
          state.pivot_config = props.data?.data?.properties?.pivot_config;
          pivot_instance.value?.flexmonster.setReport({
            options: {
              grid: {
                showHeaders: false,
              },
              configuratorButton: false,
            },
            formats: [{
              name: '', // an empty name allows specifying the default format for all the measures
              decimalPlaces: '2',
              thousandsSeparator: ',',
            }],

            ...state.pivot_config,
            dataSource: state.data,

          });
        }
        else {
          pivot_instance.value?.flexmonster.updateData(state.data);
        }
        state.error_msg = null;
        state.is_loading = false;
      },
    });
  }
  catch (err) {
    state.error_msg = $t(ERROR_MAP[err?.data?.error_code]) || err?.data?.message;
    logger.error({ err });
    state.is_loading = false;
  }
}
function updatePivotConfig() {
  dashboard_store.pivot_table_instance = { ...pivot_instance.value?.flexmonster, active_chart: state.active_chart };
}
function exportExcel() {
  const options = { filename: `${props.data?.data?.name}` };
  const toolbar_instance = pivot_instance.value.flexmonster.toolbar;
  toolbar_instance.pivot.exportTo('excel', options);
}
watch(() => props.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    const query = { ...(props.id !== 'preview' && { cache: true }) };
    await getData(query);
  }
}, { immediate: true }, { deep: true });
onMounted(() => {
  if (props.id !== 'preview')
    emitter.on('export-inventory-report', () => {
      exportExcel();
    });
});
onUnmounted(() => {
  if (props.id !== 'preview')
    emitter.off('export-inventory-report');
});
</script>

<template>
  <div>
    <div v-if="props.id !== 'preview'" class="flex justify-end">
      <p class="text-sm mr-4">
        <span class="mr-1">{{ $t('Last updated at') }}:</span>
        <span class="font-semibold">{{ state.last_updated_at ? $date(state.last_updated_at, 'DATETIME_SHORT_WITH_WEEKDAY') : 'NA' }}</span>
      </p>
      <p class="cursor-pointer mr-1" @click="getData">
        <icon-hawk-refresh-ccw-one />
      </p>
    </div>
    <div
      v-if="state.is_loading || state.error_msg"
      class="h-[300px] flex items-center justify-center text-sm text-gray-600"
    >
      <HawkLoader v-if="state.is_loading" container_class="m-1" />
      <p v-else-if="state.error_msg">
        {{ state.error_msg }}
      </p>
    </div>
    <HawkPivotTable
      v-show="!(state.is_loading || state.error_msg)"
      :show_hide_chart="id === 'preview'"
      :show_header="id === 'preview'"
      :pivot_table_height="get_pivot_table_height"
      :pivot_table_data="state.data?.data"
      :pivot_table_mapping="state.data?.mapping"
      :active_chart="state.active_chart"
      @pivotInstance="pivot_instance = $event"
      @pivotConfig="updatePivotConfig"
      @updateActiveChart="state.active_chart = $event; updatePivotConfig()"
    />
  </div>
</template>
