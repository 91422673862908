<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { uniqBy } from 'lodash-es';

import { onMounted } from 'vue';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';
import DashboardTerraFieldsPopup from '~/dashboard/components/terra-fields/dashboard-terra-fields-popup.vue';

const emit = defineEmits(['update']);

const dashboard_terra_store = useDashboardTerraStore();

const {
  terra_configuration,
  workflow_options,
} = storeToRefs(dashboard_terra_store);

const form_data = ref({
  list: [],
});

const all_fields = computed(() => {
  return uniqBy(workflow_options.value?.reduce((acc, wf) => {
    Object.values(wf.data || {}).forEach((group) => {
      acc.push(...group.fields);
    });
    return acc;
  }, []), 'uid');
});

const fields_modal = useModal({
  component: DashboardTerraFieldsPopup,
  attrs: {
    onClose() {
      fields_modal.close();
    },
    onSave(data) {
      updateSelectedFields(data);
      fields_modal.close();
    },
  },
});

function updateSelectedFields(data) {
  form_data.value.list = [
    ...data,
  ];
  emitUpdate();
}

function setInitialData() {
  const res = terra_configuration.value?.workprogress_fields || [];

  form_data.value.list = [...res];
}
function emitUpdate() {
  emit('update', form_data.value.list);
}

onMounted(() => setInitialData());
</script>

<template>
  <div>
    <div class="text-lg font-semibold mb-4">
      {{ $t('Fields') }}
    </div>
    <Vueform
      v-model="form_data"
      size="sm"
      sync
      :class="{ 'mb-4': form_data.list?.length > 0 }"
    >
      <ListElement
        name="list"
        :sort="true"
        :initial="0"
        :controls="{ add: false, remove: true, sort: true }"
        @sort="emitUpdate"
        @remove="emitUpdate"
      >
        <template #default="{ index }">
          <ObjectElement
            :name="index"
          >
            <HiddenElement name="column" />
            <HiddenElement name="units" />
            <HiddenElement name="type" />
            <TextElement
              name="name"
              :readonly="true"
              label=""
            />
          </ObjectElement>
        </template>
      </ListElement>
    </Vueform>
    <HawkButton
      size="sm"
      type="text"
      class="w-fit"
      @click="fields_modal.open()"
    >
      <IconHawkPlus />
      <span class="ml-1">
        {{ $t('Add fields') }}
      </span>
    </HawkButton>
  </div>
</template>
