<script setup>
import { useRoute } from 'vue-router';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useChecklistStore } from '~/tasks/store/checklists.store.js';

const route = useRoute();
const $t = inject('$t');

const task_store = useTasksStore();
const template_store = useTasksStore('templates');
const checklist_store = useChecklistStore('checklist-templates');

const headerTabs = computed(() => {
  return [
    {
      uid: 'tasks',
      label: $t('tasks'),
      to: { name: 'tasks' },
      count: task_store.total_task_count,
    },
    {
      uid: 'templates',
      label: $t('templates'),
      to: { name: 'task-templates' },
      count: route.path.includes('checklists') ? checklist_store.total_checklist_count : template_store.total_task_count,
    },
  ];
});
const active_tab = computed(() => route.name === 'task-templates' ? 'templates' : route.name);
onBeforeUnmount(() => {
  task_store.$reset();
});
</script>

<template>
  <div>
    <HawkPageHeader v-if="!route.params.checklist_id" :title="$t('Tasks')" :display_onboarding="{ for: 'tasks' }">
      <template #left>
        <HawkPageHeaderTabs :tabs="headerTabs" :active_item="active_tab" />
      </template>
    </HawkPageHeader>
    <router-view class="px-4" />
  </div>
</template>
