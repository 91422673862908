<script setup>
import { useModal } from 'vue-final-modal';
import SelectFolderTemplatePopup from '~/dashboard/components/vueform-schema-templates/folder-selection-popup.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';

const props = defineProps(['option', 'default']);

const dashboard_dms_store = useDashboardDMSStore();

const { set_widget_configuration } = useDashboardStore();

const is_loading = ref(false);

const { open: openFolderModal, close: closeFolderModal, patchOptions } = useModal({
  component: SelectFolderTemplatePopup,
});

function handleOpenFolderModal() {
  if (is_loading.value)
    return;
  patchOptions({
    attrs: {
      onClose() {
        closeFolderModal
        ();
      },

    },
  });
  openFolderModal();
}

function removeFolder(uid) {
  let folders = dashboard_dms_store?.dms_configuration?.folders;
  folders = folders.filter(f => f.uid !== uid);
  dashboard_dms_store.update_dms_configuration({ ...dashboard_dms_store.dms_configuration, folders });
  set_widget_configuration({
    ...dashboard_dms_store.dms_configuration,
    folders,
  });
}

function clearAll() {
  dashboard_dms_store.update_dms_configuration({ ...dashboard_dms_store.dms_configuration, folders: [] });
  set_widget_configuration({
    ...dashboard_dms_store.dms_configuration,
    folders: [],
  });
}
</script>

<template>
  <div class="cursor-pointer border border-gray-300 rounded-lg p-1">
    <div v-if="dashboard_dms_store?.dms_configuration?.folders?.length" class="flex items-center justify-between" @click="handleOpenFolderModal">
      <div>
        <HawkTag v-for="folder in dashboard_dms_store?.dms_configuration.folders" :key="folder.uid" :rounded="false" type="outlined" color="white" class="m-[1px]" :is_clearable="true" @clear="removeFolder(folder.uid)">
          <span class="font-normal text-sm">
            {{ folder.name }}
          </span>
        </HawkTag>
      </div>
      <div @click.stop="clearAll">
        <IconHawkXClose class="w-4 h-4 pr-1" />
      </div>
    </div>
    <div v-else class="flex justify-between items-center" @click="handleOpenFolderModal">
      <span class="text-gray-500"> {{ $t('Select Folders') }}</span>
      <HawkLoader v-if="is_loading" container_class="pl-2" :width="5" :height="5" />
    </div>
  </div>
</template>
