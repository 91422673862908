<script setup>
import { onMounted, ref } from 'vue';
import { useFormBuilderAllFields } from '~/forms/composables/form-builder-all-fields.composable.js';

const props = defineProps({
  form_template_detail_store: {
    type: Object,
  },
  section: {
    type: Object,
  },
  field: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(['close']);
const $services = inject('$services');
const $toast = inject('$toast');
const $track_event = inject('$track_event');
const $t = inject('$t');
const form_template_detail_store = props.form_template_detail_store;

const { field_map } = useFormBuilderAllFields();

const sections = ref([]);
const form$ = ref(null);
const is_loading = ref(false);
const payload_name = Object.keys(props?.field).length > 0 ? 'field' : 'section';

async function apply() {
  try {
    const payload
      = payload_name === 'field'
        ? {
            field: {
              name: form$.value.data.template.name,
              uid: props.field.uid,
            },
          }
        : {
            section: {
              name: form$.value.data.template.name,
              uid: props.section.element.uid,
            },
          };
    is_loading.value = true;
    const { data } = await $services.forms.post({
      body: payload,
      attribute: `${payload_name}s/template/save`,
    });
    const types = {
      default: 'Basic',
      checklist: 'Checklist',
      checklistv2: 'Checklist',
      tablev2: 'Table',
      signature: 'Signature',
    };
    $track_event('save_load_field_template', {
      type: payload_name === 'field' ? field_map[data.field?.properties?.type].name : types[data.section?.type],
      method: 'Save',
    });
    is_loading.value = false;
    $toast({
      title: $t('Template saved'),
      text: $t('Your template has been saved successfully'),
      type: 'success',
    });

    payload_name === 'section' && form_template_detail_store.section_templates.push(data.section);
    emit('close');
  }
  catch (err) {
    logger.log(err);
    $toast({
      title: $t('Failed to save template'),
      text: $t('Unable to save template. Please try again.'),
      type: 'error',
    });
  }
}

onMounted (async () => {
  is_loading.value = true;
  const { data } = await $services.forms.getAll({
    attribute: payload_name === 'field' ? 'fields' : 'sections',
    query: {
      isTemplate: true,
      page_size: 100000000,
    },
  });
  sections.value = data?.sections || data?.fields;
  is_loading.value = false;
});
</script>

<template>
  <hawk-modal-container content_class="!w-[500px]">
    <Vueform
      ref="form$"
      size="sm"
      :format-load="(data) => data"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :should_validate_on_mount="false"
      :display-errors="false"
      :endpoint="apply"
      sync
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Save as template') }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content :is_scroll="false">
          <div class="text-sm text-gray-600 ml-1 mb-5 flex flex-col gap-2">
            <p>
              {{ $t('Create a new template or choose an existing one to overwrite it. These templates can be used across the organization in any form templates.') }}
            </p>
            <p>
              <span class="font-medium">{{ $t('Note') }}: </span>
              <span>{{ $t('The pre-defined conditions will not be templatized.') }}</span>
            </p>
          </div>
          <div class="p-1">
            <SelectElement
              class="mb-4"
              name="template"
              track-by="name"
              label-prop="name"
              value-prop="uid"
              :label="$t('Name')"
              :placeholder="$t('Template name')"
              :search="true"
              :create="true"
              :object="true"
              :items="sections"
              :rules="['required']"
              @search-change="search_key = $event"
            >
              <template #option="{ option }">
                <p v-if="option.__CREATE__">
                  + Create "{{ option.name }}"
                </p>
              </template>
            </SelectElement>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-3" type="outlined" color="gray" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style lang="scss">
.three_columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
