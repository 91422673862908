<script setup>
const BASE_URL = 'https://app.sdpd.rnel.com/auth';
const $services = inject('$services');
const state = reactive({
  is_loading: false,
  is_error: false,
  token: null,
});
async function getToken() {
  try {
    state.is_loading = true;
    state.is_error = false;
    const response = await $services.users.get_solarlabs_token({ toast: false });
    state.token = response.data.token;
    state.is_loading = false;
  }
  catch (e) {
    state.is_error = true;
    state.is_loading = false;
  }
}

onMounted(() => {
  getToken();
});
const source_url = computed(() => `${BASE_URL}/?token=${state?.token}`);
</script>

<template>
  <div class="h-[calc(100vh-64px)]">
    <hawk-loader v-if="state.is_loading" />
    <div v-else-if="state.is_error">
      <HawkIllustrations type="no-permission" for="users" />
    </div>
    <iframe
      v-else
      width="100%"
      height="100%"
      title="google sheet"
      :src="source_url"
    />
  </div>
</template>
