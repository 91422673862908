<script setup>
import { useModal } from 'vue-final-modal';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryWarehouseTypeForm from '~/inventory/components/inventory-warehouse/inventory-warehouse-type-form-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  name: {
    type: String,
    default: 'warehouse_type',
  },
  truncate_width: {
    type: Number,
    default: 20,
  },
});
const { $t } = useCommonImports();
const inventory_store = useInventoryStore();
const warehouse_type$ = ref({});

const warehouse_types = computed(() => inventory_store.warehouse_types);

const { open: openWarehouseTypeModal, close: closeWarehouseTypeModal } = useModal({
  component: InventoryWarehouseTypeForm,
  attrs: {
    onClose() {
      closeWarehouseTypeModal();
    },
    onCreatedWarehouseType(e) {
      warehouse_type$.value.load(e[0], false);
    },
  },
});
</script>

<template>
  <SelectElement
    ref="warehouse_type$"
    :name="props.name"
    :items="warehouse_types"
    :search="true"
    :native="false"
    track-by="search"
    :create="false"
    :label="$t('Warehouse type')"
    input-type="search"
    autocomplete="off"
    value-prop="uid"
    label-prop="name"
    :placeholder="$t('Select warehouse type')"
    :rules="['required']"
  >
    <template #before-list>
      <p
        class="px-3 pt-1.5 pb-1 text-primary hover:bg-gray-100 font-medium"
        @click="openWarehouseTypeModal"
      >
        + {{ $t('Create new') }}
      </p>
    </template>
    <template #single-label="{ value }">
      <div class="flex items-center h-full absolute left-0 top-0 bg-transparent leading-snug pl-3">
        <HawkText :length="truncate_width" :content="value.name" />
      </div>
    </template>
  </SelectElement>
</template>
