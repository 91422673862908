<script setup>
import { VueFinalModal } from 'vue-final-modal';

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
    required: false,
  },
});

defineEmits(['close', 'okay']);

const form_data = ref(null);
</script>

<template>
  <VueFinalModal
    v-bind="props?.options"
    class="flex justify-center items-center"
    content-class="flex bg-white border rounded-lg p-6 w-fit min-w-fit"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <div class="flex flex-col justify-between">
      <Vueform v-model="form_data" size="sm">
        <DateTimeElement
          name="date_input"
          :options="{
            inline: true,
            range: true,
          }"
        />
      </Vueform>
      <div class="flex justify-end w-full mt-4">
        <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
          {{ $t('Cancel') }}
        </HawkButton>
        <HawkButton color="primary" @click="$emit('okay', form_data.date_input)">
          {{ $t('Okay') }}
        </HawkButton>
      </div>
    </div>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(input) {
  @apply hidden;
}

:deep(.flatpickr-months) {
  @apply border-x border-t rounded-t-lg;
}

:deep(.flatpickr-month) {
  @apply rounded-t-lg;
}

:deep(.flatpickr-innerContainer) {
  @apply border-x border-b rounded-b-lg;
}

:deep(.flatpickr-monthDropdown-months),
:deep(.flatpickr-current-month) {
  @apply text-base;
}

:deep(.flatpickr-monthDropdown-month) {
  @apply text-sm;
}
</style>
