<script setup>
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  form: {
    type: Object,
    required: true,
  },
});

const $date = inject('$date');

const common_store = useCommonStore();

const bread_crumbs = computed(() => [
  common_store.get_asset(props.form.target_element.asset).name,
  props.form.properties?.reference_name,
]);
</script>

<template>
  <div class="min-w-[18rem] text-xs border rounded-md shadow-md p-2 bg-white">
    <!-- Header section -->
    <div>
      <div class="whitespace-nowrap">
        <nav class="flex text-xs items-center" aria-label="Breadcrumb">
          <ol>
            <li
              v-for="(name, index) in bread_crumbs"
              :key="index"
              class="inline mr-0.5"
            >
              <HawkText :length="15" :content="name" class="inline mr-0.5" />
              <IconHawkChevronRight v-if="index < (bread_crumbs.length - 1)" aria-hidden="true" class="inline w-[10px] h-[10px]" />
            </li>
          </ol>
        </nav>
        <p
          v-tippy="form.name && form.name.length > 50 ? form.name : ''"
          class="text-sm font-medium text-gray-900 max-w-[350px] truncate"
        >
          {{ form.name }}
        </p>
      </div>
    </div>
    <!-- Body section -->
    <div class="mt-4">
      <div class="grid grid-cols-[0.5fr_1fr] items-center pb-2">
        <div>{{ $t('Assignees') }}:</div>
        <div>
          <HawkMembers :members="form.assignees" type="badge" />
        </div>
      </div>
      <div class="grid grid-cols-[0.5fr_1fr] items-center pb-2">
        <div>{{ $t('Due Date') }}:</div>
        <div>
          <span class="text-sm whitespace-nowrap" :class="[form.due_date && new Date(form.due_date) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']">
            {{ form.due_date ? $date(form.due_date, 'DD MMMM YYYY') : ' — ' }}
          </span>
        </div>
      </div>
      <div class="grid grid-cols-[0.5fr_1fr] items-center pb-2">
        <div>{{ $t('Category') }}:</div>
        <div>
          <HawkCategoryName v-if="form.category" class="font-medium" :uid="form.category" />
          <span v-else>
            —
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
