<script setup>
import { useModal } from 'vue-final-modal';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryUom from '~/inventory/components/inventory-uom/inventory-uom.vue';
import inventoryUomPopup from '~/inventory/components/inventory-uom/inventory-uom-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  name: {
    type: String,
    default: 'uom',
  },
});
const { $t } = useCommonImports();
const inventory_store = useInventoryStore();
const uom$ = ref({});

const uom = computed(() => inventory_store.uoms.map(unit => ({ ...unit, search: `${unit.name} ${unit.symbol}` })));

const { open: openUomModal, close: closeUomModal } = useModal({
  component: inventoryUomPopup,
  attrs: {
    onClose() {
      closeUomModal();
    },
    onCreatedUom(e) {
      uom$.value.load(e[0], false);
    },
  },
});
</script>

<template>
  <SelectElement
    ref="uom$"
    :name="props.name"
    :items="uom"
    :search="true"
    :native="false"
    track-by="search"
    :create="false"
    :label="$t('Unit of measurement')"
    input-type="search"
    autocomplete="off"
    value-prop="uid"
    label-prop="name"
    :placeholder="$t('Select unit of measure')"
    :rules="['required']"
  >
    <template #single-label="{ value }">
      <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent pl-2">
        {{ value.name }} ({{ value.symbol }})
      </div>
    </template>
    <template #before-list>
      <p
        class="px-3 pt-1.5 pb-1 text-primary hover:bg-gray-100 font-medium"
        @click="openUomModal"
      >
        + {{ $t('Add measurement unit') }}
      </p>
    </template>
    <template #option="{ option }">
      <InventoryUom :uom="option.uid" is_full />
    </template>
  </SelectElement>
</template>
