import { computed } from 'vue';

export default function VueFormUploadLoader() {
  return {
    apply: 'ButtonElement',
    setup(props, context, component) {
      const uploading_data_loading = computed(() => (props.submits && component.form$?.value?.uploading?.value) || component.isLoading.value || props.loading);
      const uploading_data_disabled = computed(() => (props.submits && component.form$?.value?.uploading?.value) || component.isDisabled.value || props.disabled);
      return {
        ...component,
        isLoading: uploading_data_loading,
        isDisabled: uploading_data_disabled,
      };
    },
  };
}
