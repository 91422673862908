<script setup>
import { computed, watch } from 'vue';
import { isEqual } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import { getUserFullName } from '~/common/utils/common.utils.js';
import PivotTable from '~/common/components/organisms/hawk-pivot-table/hawk-pivot-table.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useHawkPivotTableHelperComposable } from '~/common/components/organisms/hawk-pivot-table/helper-composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});
const common_store = useCommonStore();
const dashboard_store = useDashboardStore();
const { preProcessData } = useHawkPivotTableHelperComposable();
const widget_data = computed(() => props.data?.data);
const pivot = ref({});
const state = reactive({
  active_chart: null,
  pivot_config: {},
  supported_field_types: {
    text: 'text',
    number: 'number',
    email: 'text',
    phone: 'text',
    money: 'text',
    percentage: 'number',
    dropdown: 'text',
    checkbox: 'text',
    radio: 'text',
    date: 'text',
    datetime: 'text',
    date_range: 'text',
    planned_actual: 'number',
    members: 'text',
  },
});

const get_pivot_table_height = computed(() => {
  if (props.id === 'preview')
    if (Number.isNaN(props.content_height))
      return false;
    else
      return props.content_height - 96;
  const widget_height = (((props.data.h || 22) * 20) - 44);
  return (props.content_height || widget_height) - 46;
});
const pivot_table_data_source = computed(() => preProcessData(common_store.assets.map((asset) => {
  return {
    'Asset name': asset.name,
    ...getFieldsData(asset),
  };
})));
watch(() => props.data?.data, (val) => {
  if (pivot.value && props.id !== 'preview')
    initPivotTable();
}, { deep: true });
watch(() => pivot.value, (data, old_data) => {
  if (data && !isEqual(data, old_data)) {
    initPivotTable();
    state.active_chart = props.data?.data?.properties?.pivot_config?.active_chart;
  }
});

function getFieldsData(asset) {
  return common_store.assets_custom_fields.reduce((acc, field) => {
    const type = state.supported_field_types[field.type];
    if (!type)
      return acc;

    const default_value = type === 'text' ? '' : 0;
    const data = asset.metadata[field.uid]?.value;
    if (['dropdown', 'radio'].includes(field.type)) {
      acc[field.name] = field.config.find(options => options.uid === data)?.name || '';
    }
    else if (field.type === 'checkbox') {
      acc[field.name] = (data || []).map(uid => field.config.find(options => options.uid === uid)?.name || '').join(', ');
    }
    else if (field.type === 'date_range') {
      acc[`${field.name}_start`] = data?.start;
      acc[`${field.name}_end`] = data?.end;
    }
    else if (['date', 'datetime'].includes(field.type)) {
      acc[field.name] = data;
    }
    else if (field.type === 'planned_actual') {
      acc[field.name] = +(data?.ratio || 0) * 100;
    }
    else if (field.type === 'members') {
      acc[field.name] = (data || []).map((uid) => {
        const user = common_store.get_user(uid);
        const team = common_store.get_team(uid);
        if (user)
          return getUserFullName(user);
        if (team)
          return team.name;
        return '';
      }).join(', ');
    }
    else {
      acc[field.name] = data || default_value;
    }
    return acc;
  }, {});
}
function updatePivotConfig() {
  dashboard_store.pivot_table_instance = { ...pivot.value?.flexmonster, active_chart: state.active_chart };
}
function updatePrintMap() {
  if (props.id === 'preview' || !pivot.value)
    return;
  pivot.value.flexmonster.exportTo('html', { destinationType: 'plain' }, (data) => {
    const table_html = data.data;

    if (table_html)
      dashboard_store.update_print_map(props.id, {
        renderAt: `pivot-container-${props?.id}`,
        chart_name: props.data?.data?.name,
        type: props.data?.type,
        renderType: 'pivot_table',
        dimensions: {
          x: props?.data?.x,
          y: props?.data?.y,
        },
        dataSource: {
          table_html,
        },
      });
  });
}
function initPivotTable() {
  if (props.data?.data?.properties?.pivot_config) {
    state.pivot_config = props.data?.data?.properties?.pivot_config;
    pivot.value.flexmonster.setReport({
      options: {
        grid: {
          showHeaders: false,
        },
        configuratorButton: false,
      },
      formats: [{
        name: '', // an empty name allows specifying the default format for all the measures
        decimalPlaces: '2',
        thousandsSeparator: ',',
      }],

      ...state.pivot_config,
      dataSource: pivot_table_data_source.value,

    });
  }
  else {
    pivot.value.flexmonster.updateData(pivot_table_data_source.value);
  }
  if (props.id !== 'preview')
    updatePrintMap();
}
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <PivotTable
      v-if="get_pivot_table_height"
      :chart_id="`pivot_table_chart_${id}`"
      :show_header="id === 'preview'"
      :show_hide_chart="id === 'preview'"
      :active_chart="state.active_chart"
      :pivot_table_data="pivot_table_data_source.data"
      :pivot_table_mapping="pivot_table_data_source.mapping"
      :export_file_name="data?.data?.name || 'Untitled'"
      :pivot_table_height="get_pivot_table_height"
      @pivotInstance="pivot = $event"
      @pivotConfig="updatePivotConfig"
      @updateActiveChart="state.active_chart = $event; updatePivotConfig()"
    />
  </div>
</template>
