<script setup>
import { capitalize } from 'lodash-es';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { convertTableUnitsData } from '~/common/utils/common.utils.js';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';

import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';

const props = defineProps({
  id: {
    type: String,
  },
  name: {
    type: String,
    default: 'Untitled',
  },
  payload: {
    type: Object,
    default: () => {},
  },
  popup_query: {},
});

const emit = defineEmits(['close']);
const authStore = useAuthStore();
const route = useRoute();

const $services = inject('$services');
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const graph_data = ref(null);
const children = ref({});
const payload = ref(null);
const dashboard_schedule_store = useDashboardScheduleStore();
const dashboard_store = useDashboardStore();
const activity_key_map = ref(null);
const no_data = ref(false);
const current_organization = authStore.current_organization;

const column_key_map = {
  actual: '% Actual',
  actual_units: 'Actual',
  lag: '% Lag',
  lag_units: 'Lag',
  planned: '% Planned',
  planned_units: 'Planned',
  remaining: '% Remaining',
  remaining_units: 'Remaining',
  total_units: 'Total (Scope)',
  actual_progress: '% Actual progress',
  planned_progress: '% Planned progress',
};
async function getReports() {
  graph_data.value = null;
  loading.value = true;
  try {
    const { data } = await $services.project_management.get_graph({
      body: {
        filters: {
          asset: dashboard_store.widget_asset,
          organization: current_organization.uid,
          schedule: props.payload.data.schedule,
          ...{ advanced_filters: props.popup_query },
        },
        chart: 'activities_work_table',
        type: 'drill-down',
      },
    });
    if (data && Object.keys(data)) {
      activities.value = generateActivities(data.data);
      generateColumns((Object.values(data.data))[0]);
    }
    else {
      no_data.value = false;
    }
    loading.value = false;
  }
  catch (err) {
    loading.value = false;
  }
}

const status_color = {
  'On track': 'blue',
  'Delayed': 'red',
  'Completed': 'green',
  'Not Started': 'gray',
};

function generateActivities(data) {
  const array = [];
  for (const [key, value] of Object.entries(data)) {
    const current_item = {};
    if (['PROJECT', 'WBS'].includes(value.type))

      current_item.subRows = [null];

    current_item.activity_id = key;
    const field_names = ['uid', 'id', 'activity_id', 'type', 'activity', 'actual_duration', 'actual_progress', 'critical', 'lag', 'lag_days', 'planned_duration', 'planned_progress', 'remaining_duration', 'remaining_progress', 'status', 'bl_start', 'bl_finish', 'planned_start', 'planned_finish', 'actual_start', 'actual_finish', 'duration', 'free_slack', 'total_slack', 'overdue_days', 'name'];
    for (const [nestedKey, nestedValue] of Object.entries(value))
      if (!field_names.includes(nestedKey))
        Object.keys(nestedValue.values).forEach((valueKey) => {
          current_item[`${nestedKey}-${valueKey}`] = convertTableUnitsData(nestedValue.values[valueKey], nestedValue.units);
        });
      else if (nestedKey === 'name')
        current_item.name = activity_key_map.value?.find(e => e.uid === key)?.name || nestedValue;
      else current_item[nestedKey] = convertTableUnitsData(nestedValue, null);

    array.push(current_item);
  }
  return array;
}

function generateColumns(data) {
  const column_array = [{
    accessorKey: 'name',
    id: 'name',
    header: 'Activity',
    size: '400',
  }];
  Object.keys(data).forEach((item) => {
    const header_column_key = column_key_map[item] ? column_key_map[item] : capitalize(item);
    if (item !== 'uid')
      column_array.push({
        accessorKey: item,
        header: item === 'id' ? 'Activity id' : header_column_key,
        id: item,
      });
  },
  );
  columns.value = [];
  columns.value = [...column_array];
}

onMounted(() => {
  getReports();
});
</script>

<template>
  <HawkModalTemplate :disable_footer="true" content_class="rounded-lg w-[75vw]" @close="emit('close')">
    <template #header_left>
      <div class="flex flex-col justify-start">
        {{ props.name }}
      </div>
    </template>
    <template #default>
      <HawkLoader v-if="loading" class="h-[62.5vh] !m-0" />
      <div v-else class="h-[62.5vh] overflow-auto scrollbar">
        <a v-if="activities && columns?.length && activities?.length" class="w-full scrollbar" :style="{ height: `${height}px` }">
          <HawkTable
            :key="columns.length"
            :data="activities"
            :columns="columns"
            freeze_column_id="name"
            :show_menu_header="false"
            :show_column_borders="true"
            additional_table_classes=""
            :container_class="`h-[${height}px]`"
            :striped="true"
            is_gapless
            cell_height="30px"
          >
            <template #status="status">
              <HawkBadge :color="status_color[status.data.getValue()]">
                {{ status.data.getValue() }}
              </HawkBadge>
            </template>
          </HawkTable>
        </a>
      </div>
    </template>
  </HawkModalTemplate>
</template>
