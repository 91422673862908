<script setup>
import { useModal } from 'vue-final-modal';
import { keyBy } from 'lodash-es';

import TaskDependency from '~/tasks/components/atoms/task-dependency.vue';
import TaskStatusDropdown from '~/tasks/components/atoms/task-status-dropdown.vue';
import HawkRemindersOverview from '~/common/components/organisms/hawk-reminders/hawk-reminders-overview.vue';

import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  task_details: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['badge-clicked', 'close']);

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');
const updateFunction = inject('updateFunction');
const is_template = inject('is-template');
const task_store = inject('task_store');

const { checkTaskPermission } = useTasksPermissions();

const rename = ref(false);

const { open: openRemindersOverviewPopup, close: closeRemindersOverviewPopup, patchOptions: patchRemindersOverviewPopup } = useModal({
  component: HawkRemindersOverview,
});
function openRemindersOverview() {
  patchRemindersOverviewPopup({
    attrs: {
      options: {
        number_of_reminders: props.task_details.reminder_ref.length,
        can_add_reminders: checkTaskPermission({ permission: 'can_add_reminders', instance: props.task_details }),
        can_delete_reminder: checkTaskPermission({ permission: 'can_modify', instance: props.task_details }),
        load_from_trigger_property: props.task_details?.due_date ? 'dueDate' : 'startDate',
        assignees: props.task_details.assignees,
        watchers: props.task_details.watchers.map(obj => obj.uid),
        creator: props.task_details.owner.uid,
        asset: props.task_details?.element_asset || null,
        member_options: [
          {
            label: $t('Assignees'),
            value: 'assignees',
          },
          {
            label: $t('Watchers'),
            value: 'watchers',
          },
          {
            label: $t('Creator'),
            value: 'creator',
          },
          {
            label: $t('Custom'),
            value: 'custom',
          },
        ],
        status_values: task_store.status_values,
        date_options: [
          {
            value: 'startDate',
            label: $t('Start Date'),
            disabled: !props.task_details.start_date,
          },
          {
            value: 'dueDate',
            label: $t('Due Date'),
            disabled: !props.task_details.due_date,
          },
        ],
        get_reminders: async () => {
          const { data } = await $services.tasks.get_reminders({
            id: props.task_details.uid,
          });
          let reminders = {};
          if (data?.reminders)
            reminders = keyBy(data.reminders, 'uid');

          if (data?.reminder)
            reminders = { [data.reminder.uid]: data.reminder };

          return reminders;
        },
        create_reminder: async (body) => {
          await $services.tasks.create_reminder({
            id: props.task_details.uid,
            body,
          });
        },
        update_reminder: async (body) => {
          await $services.tasks.update_reminder({
            id: props.task_details.uid,
            body,
          });
        },
        delete_reminder: async (reminder_uid) => {
          await $services.tasks.delete_reminder({
            id: props.task_details.uid,
            reminder_id: reminder_uid,
          });
        },
      },
      onClose() {
        closeRemindersOverviewPopup();
      },
    },
  });
  openRemindersOverviewPopup();
}

function handleNameChange(name) {
  updateFunction({ name });
  rename.value = false;
}
</script>

<template>
  <div class="flex justify-between items-center">
    <div class="inline-block">
      <div v-if="rename">
        <hawk-editable-name
          class="max-w-[50vw]"
          :name="task_details.name"
          :input_classes="{
            TextElement: {
              input: 'font-semibold !text-xl !p-0',
              inputContainer: 'border-0 border-b',
            },
            ElementLayout: {
              innerWrapper: 'border-b',
            },
          }"
          @close="rename = false"
          @update="handleNameChange"
        />
      </div>
      <div v-else class="inline-flex items-center mt-1 group">
        <p
          id="task-name"
          v-tippy="task_details.name.length > 300 ? task_details.name : ''"
          class="text-xl text-gray-900 font-semibold max-w-[65vw]"
          :class="{ 'line-clamp-3 overflow-hidden': task_details.name.length > 300 }"
        >
          {{ task_details.name }}
        </p>
        <span>
          <icon-hawk-edit
            class="text-primary w-4 h-4 ml-6 cursor-pointer  invisible"
            :class="[checkTaskPermission({ permission: 'can_modify', instance: task_details }) ? 'group-hover:visible' : 'invisible']"
            @click="rename = true"
          />
        </span>
      </div>
    </div>
    <div class="flex items-center">
      <HawkBadge
        v-if="!rename && task_details.reminder_ref?.length"
        class="mx-1 cursor-pointer"
        color="blue"
        @click="openRemindersOverview"
      >
        <IconHawkBellOne class="text-primary h-3.5" />
        {{ task_details.reminder_ref.length }}
      </HawkBadge>
      <TaskDependency
        v-if="!rename"
        :uid="task_details.uid"
        :enable_click="true"
        class="mr-2"
        @badge-clicked="emit('badge-clicked')"
      />
      <div
        v-if="is_template"
        class="px-4 py-2 rounded-lg bg-warning-300 text-sm font-semibold text-gray-700 cursor-default"
      >
        {{ $t('Template') }}
      </div>
      <TaskStatusDropdown
        v-else
        :status="task_details.status"
        @status-selected="updateFunction({ status: $event })"
      />
    </div>
  </div>
  <div class="flex items-center text-gray-700 text-sm font-medium mt-2">
    <HawkMembers :members="task_details.owner.uid" type="label" size="xs" class="mr-2" />
    <p>created on {{ $date(task_details.created_at, 'DATETIME_LONG_WITH_SHORT_DAY_AND_MONTH') }}</p>
  </div>
</template>

<style lang="scss">
.badge {
  position: absolute;
  top: -14px;
  right: -7px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 15px;
  padding: 8px;
  border: 3px solid #f4fdff;
  border-radius: 500px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  z-index: 1;
  background: #1570EF
}
</style>
