export const STATIC_FORM_DATA = {
  tags: [],
  is_approval_pending: false,
  approval: {},
  schedule: null,
  next_occurence: null,
  recurring: false,
  status: 'published',
  submission_status: 'open',
  bookmark: false,
  location: {},
  icon: null,
  cta: null,
  seen: true,
  active: true,
  public: false,
  total_submissions: 0,
  private: false,
  due_date: null,
  start_date: null,
  step_status: '1',
  workflow: false,
  current_blocks: [],
  completed_blocks: [],
  total_fields: 0,
  total_sections: 0,
  step_due_date: null,
  created_from_schedule: false,
  notifications: null,
  quick_access: {
    active: true,
    device: [],
    users: [],
  },
  target_element: {},
  geofencing_distance: null,
  category: null,
  description: null,
  reminders: [],
  owner: 'UjJYqLTntC',
  organization: '00g174ikp75l48FwW0h8',
  uid: '4LY85VV6UT6',
  number: 3602,
  name: 'Test',
  created_at: '2023-09-12T07:33:44.497Z',
  updated_at: '2023-09-27T07:32:04.335Z',
  members: [],
  approvers: [],
  asset_submission_counts: {},
  filters: {},
  steps: {},
  current_step: {},
  references: [],
  write_access: true,
  can_modify: true,
  can_create: true,
  is_child: true,
  teams: [],
};

export const FORM_PERMISSIONS = {
  VIEW_FORMS: 'view_forms',
  SUBMIT_FORMS: 'submit_forms',
  CREATE_FORMS: 'create_forms',
  USE_FORMS: 'use_forms',
  MODIFY_FORMS: 'modify_forms',
  IMPORT_FORMS: 'import_forms',

  // V2 PERMISSIONS
  V2_CREATE_FORMS: 'v2_create_forms',
  V2_VIEW_FORMS: 'v2_view_forms',
  V2_SUBMIT_FORMS: 'v2_submit_forms',
  V2_MODIFY_FORMS: 'v2_modify_forms',
};

export const TEMPLATE_PERMISSIONS = {
  V2_VIEW_FORM_TEMPLATES: 'v2_view_form_templates',
  V2_CREATE_FORM_TEMPLATES: 'v2_create_form_templates',
  V2_MODIFY_FORM_TEMPLATES: 'v2_modify_form_templates',
  V2_IMPORT_FORM_TEMPLATES: 'v2_import_form_templates',
};

export const STATIC_EMAIL_VARIABLES = [
  {
    label: 'Created by',
    name: 'Created by',
    tag: 'Created by',
    id: 'created_by',
  },
  {
    label: 'Assignees',
    name: 'Assignees',
    tag: 'Assignees',
    id: 'assignees',
  },
  {
    label: 'Template members',
    name: 'Template members',
    tag: 'Template members',
    id: 'template_members',
  },
  {
    label: 'Watchers',
    name: 'Watchers',
    tag: 'Watchers',
    id: 'watchers',
  },
];

// Info: Template icon ID should be cautiously updated(if required) as these IDs are stored in Backend for templates.
export const TEMPLATE_ICONS = {
  1: 'incidents-one.svg',
  2: 'risk-management-one.svg',
  3: 'risk-management-two.svg',
  4: 'health-safety-env-one.svg',
  5: 'health-safety-env-two.svg',
  6: 'inspection-one.svg',
  7: 'punch-list-one.svg',
  8: 'punch-list-two.svg',
  9: 'approvals-one.svg',
  10: 'approvals-two.svg',
  11: 'approvals-three.svg',
  12: 'finance-one.svg',
  13: 'finance-two.svg',
  14: 'land-management-one.svg',
  15: 'land-management-two.svg',
  16: 'acquisition-one.svg',
  17: 'permits-one.svg',
  18: 'permits-two.svg',
  19: 'daily-log-one.svg',
  20: 'daily-log-two.svg',
  21: 'maintenance-one.svg',
  22: 'maintenance-two.svg',
  23: 'activity-one.svg',
  24: 'activity-two.svg',
  25: 'analytics-one.svg',
  26: 'documentation-one.svg',
};
