<script setup>
import { permissionsMatrix } from '~/acct-settings/components/acct-permissions/permissions-matrix.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  modelValue: { type: Object, default: () => {} },
});

const emit = defineEmits(['update:modelValue']);
const { $t, route } = useCommonImports();

const form$ = ref({});
const permissions = ref({});
const data = permissionsMatrix($t);

function updatePermission(item, value) {
  permissions.value[item.value] = value;
  emit('update:modelValue', permissions.value);
}

const permission_description = computed(() => (item) => {
  return item.permissions.find(_item => _item.value === permissions.value[item.value]);
});

onMounted(() => {
  data.forEach((item) => {
    permissions.value[item.value] = (props.modelValue[item.value] || '').toLowerCase() || item?.permissions[0]?.value;
  });
  form$.value.update({ ...permissions.value });
  emit('update:modelValue', permissions.value);
});
</script>

<template>
  <div>
    <div class="my-5">
      <p class="text-sm font-semibold text-gray-900">
        {{ $t('Permissions') }}
      </p>
      <p class="text-gray-600 text-xs">
        {{ route.params.asset_id ? $t('Configure granular permissions for respective modules for the asset.') : $t('Configure granular permissions for respective modules. Users/teams will inherit these permissions for the assets they are granted access.') }}
      </p>
    </div>

    <Vueform ref="form$" size="sm">
      <div class="col-span-12">
        <HawkAccordion type="plain" item_uid_key="value" :items="data" :open_multiple="true" all_closed>
          <template #header="{ item, is_open }">
            <div class="mb-4 flex gap-3">
              <IconHawkChevronDown v-if="is_open" class="w-4 h-4 mt-0.5" />
              <IconHawkChevronRight v-else class="w-4 h-4 mt-0.5" />
              <div>
                <div class="text-sm font-medium uppercase">
                  {{ item.label }}
                </div>
                <div class="text-xs text-gray-600">
                  {{ permission_description(item)?.label }} {{ $t('access') }}
                </div>
              </div>
            </div>
          </template>
          <template #content="{ content: item }">
            <div class="ml-7 bg-gray-50 p-4 border rounded-2xl mb-4">
              <RadiogroupElement
                :name="item.value"
                view="blocks"
                :items="item.permissions"
                :default="permissions[item.value]"
                :add-classes="{
                  RadiogroupRadio: {
                    wrapper: ['!border-0'],
                    wrapper_selected: ['bg-transparent'],
                  },
                }"
                :remove-classes="{
                  RadiogroupRadio: {
                    container: ['form-bg-input'],
                    input: ['mb-2'],
                    wrapper_unselected: ['form-bg-input'],
                    wrapper_selected: ['form-bg-selected', '!bg-primary-50'],
                  },
                }"
                @change="value => updatePermission(item, value)"
              />
              <hr class="my-4">
              <div class="mb-4 text-xs font-semibold">
                {{ $t('Permission matrix') }}
              </div>
              <div>
                <table class="w-full table bg-white" border="0">
                  <thead>
                    <tr class="sticky top-16">
                      <th v-if="item.matrix.length > 1" class="w-40">
                        {{ $t('Resource') }}
                      </th>
                      <th>{{ $t('Permission') }}</th>
                      <th
                        v-for="column in item.columns"
                        :key="column.id"
                        class="w-28 cursor-pointer"
                        :class="{ '!bg-primary-50 !border-l border-r border-t !border-l-primary-300 border-r-primary-300 border-t-primary-300': (modelValue[item.value] === column.value) }"
                        @click="form$.update({ ...permissions, [item.value]: column.value });"
                      >
                        <div class="flex justify-center">
                          {{ column.label }}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <template v-for="(matrix_item, matrix_index) in item.matrix" :key="matrix_item.value">
                    <tr v-for="(matrix_item_row, row_index) in matrix_item.rows" :key="matrix_item_row.value">
                      <td v-if="item.matrix.length > 1 && row_index === 0" :rowspan="matrix_item.rows.length">
                        {{ matrix_item.label }}
                      </td>
                      <td>
                        <div v-if="matrix_item_row.labels">
                          <ul class="list-disc">
                            <li v-for="single_label in matrix_item_row.labels" :key="single_label">
                              {{ single_label }}
                            </li>
                          </ul>
                        </div>
                        <div v-else>
                          {{ matrix_item_row.label }}
                        </div>
                      </td>
                      <td
                        v-for="column in item.columns" :key="column.id"
                        :class="{
                          'bg-primary-50 border-l border-r border-l-primary-300 border-r-primary-300': (modelValue[item.value] === column.value),
                          'border-b border-b-primary-300': (row_index + 1) === matrix_item.rows.length && (matrix_index + 1) === item.matrix.length && (modelValue[item.value] === column.value),
                        }"
                      >
                        <div class="flex justify-center">
                          <IconHawkCheckFour v-if="matrix_item_row[column.value]" />
                          <IconHawkXTwo v-else />
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </template>
        </HawkAccordion>
      </div>
    </Vueform>
  </div>
</template>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  @apply bg-gray-50 text-xs font-medium text-left border px-6 py-4;
}

td {
  @apply border text-xs font-medium px-6 py-4;
}
</style>
