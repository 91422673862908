import dayjs from 'dayjs';
import { cloneDeep, remove } from 'lodash-es';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import getTranslationMap from '~/project-management/utils/helper.utils';

dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

export function getIntervalsBetween(interval, from, to, format = 'YYYY-MM-DD') {
  if (!interval || !from || !to)
    throw Object.assign(
      new Error('grouping failed'),
      { code: 16 },
    );
  let start = dayjs(from).startOf(interval);
  const end = dayjs(to).endOf(interval);
  const intervals = [];

  while (start < end) {
    const intervalEnd = dayjs(start)
      .endOf(interval)
      .add(1, 'second');

    let labelString = '';

    if (interval === 'day')
      labelString = `Day ${start.dayOfYear()} `;

    else if (interval === 'week')
      labelString = `Week ${start.week()} of ${start.year()} `;

    else if (interval === 'month')
      labelString = `${start.format('MMMM')} `;

    else if (interval === 'quarter')
      labelString = `Quarter ${start.quarter()} `;

    else if (interval === 'year')
      labelString = `Year ${start.year()} `;

    intervals.push({
      label: `${labelString}[${start.format(
              format,
            )} - ${intervalEnd.format(format)}]`,
      start: new Date(start.format(format)),
      end: new Date(intervalEnd.format(format)),
    });
    start = intervalEnd;
  }

  return intervals;
}
export function groupByStatus(source_tasks, parent_task_id) {
  const tasks = cloneDeep(source_tasks);
  const return_array = [];
  const statuses = [
    'Pending',
    'In-Progress',
    'Resolved',
    'Closed',
    'Rejected',
  ];
  for (const status of statuses) {
    const wrapper_task_id = crypto.randomUUID();
    const wrapper_task = {
      id: wrapper_task_id,
      type: 'virtual',
      name: `Status: ${status}`,
      parent: parent_task_id,
      readonly: true,
    };
    const filtered_tasks = tasks.filter(
      task =>
        task.associated_task
              && task.type === 'task'
              && status === task.status,
    );
    if (filtered_tasks.length) {
      filtered_tasks.forEach((task) => {
        if (task.type !== 'virtual')
          task.parent = wrapper_task_id;
      });
      filtered_tasks.unshift(wrapper_task);
      return_array.push({ filtered_tasks, wrapper_task_id });
    }
  }
  return return_array;
}
export function groupByProgress(source_tasks, parent_task_id) {
  const tasks = cloneDeep(source_tasks);
  const return_array = [];
  const ranges = [
    [-0.1, 0.1],
    [0.1, 0.2],
    [0.2, 0.3],
    [0.3, 0.4],
    [0.4, 0.5],
    [0.5, 0.6],
    [0.6, 0.7],
    [0.7, 0.8],
    [0.8, 0.9],
    [0.9, 1.0],
  ];
  for (const range of ranges) {
    const wrapper_task_id = crypto.randomUUID();
    const wrapper_task = {
      id: wrapper_task_id,
      type: 'virtual',
      name: `Progress: ${Math.max(
              Math.round(range[0] * 100),
              0,
            )}% - ${Math.round(range[1] * 100)}%`,
      parent: parent_task_id,
      readonly: true,
    };
    const filtered_tasks = remove(
      tasks,
      task =>
        task.type === 'task'
              && range[0] < task.progress
              && task.progress <= range[1],
    );
    if (filtered_tasks.length) {
      filtered_tasks.forEach((task) => {
        if (task.type !== 'virtual')
          task.parent = wrapper_task_id;
      });
      filtered_tasks.unshift(wrapper_task);
      return_array.push({ filtered_tasks, wrapper_task_id });
    }
  }
  return return_array;
}
export function groupByCriticality(source_tasks, parent_task_id) {
  const tasks = cloneDeep(source_tasks);
  const critical_wrapper_task_id = crypto.randomUUID();
  const non_critical_wrapper_task_id = crypto.randomUUID();
  const critical_tasks = [
    {
      id: critical_wrapper_task_id,
      type: 'virtual',
      name: 'Critical',
      parent: parent_task_id,
      readonly: true,
    },
  ];
  const non_critical_tasks = [
    {
      id: non_critical_wrapper_task_id,
      type: 'virtual',
      name: 'Non-Critical',
      parent: parent_task_id,
      readonly: true,
    },
  ];

  for (const task of tasks) {
    if (task.type === 'virtual')
      continue;
    if (task?.is_critical) {
      task.parent = critical_wrapper_task_id;
      critical_tasks.push(task);
    }
    else {
      task.parent = non_critical_wrapper_task_id;
      non_critical_tasks.push(task);
    }
  }

  const return_array = [];
  if (critical_tasks.length > 1)
    return_array.push({
      filtered_tasks: critical_tasks,
      wrapper_task_id: critical_wrapper_task_id,
    });
  if (non_critical_tasks.length > 1)
    return_array.push({
      filtered_tasks: non_critical_tasks,
      wrapper_task_id: non_critical_wrapper_task_id,
    });

  return return_array;
}
export function groupByDate(datetype, source_tasks, parent_task_id, option) {
  const tasks = cloneDeep(source_tasks);
  const return_array = [];
  let min_date = Number.MAX_SAFE_INTEGER;
  let max_date = 0;

  for (const task of tasks) {
    if (task[datetype] < min_date)
      min_date = task[datetype];

    if (dayjs(task[datetype]).add(1, 'day') > max_date)
      max_date = task[datetype];
  }

  const ranges = getIntervalsBetween(option, min_date, max_date);

  for (const range of ranges) {
    const wrapper_task_id = crypto.randomUUID();
    const wrapper_task = {
      id: wrapper_task_id,
      type: 'virtual',
      name: range.label,
      parent: parent_task_id,
      readonly: true,
    };
    const filtered_tasks = tasks.filter(
      task => range.start <= task[datetype] && range.end > task[datetype],
    );
    if (filtered_tasks.length) {
      filtered_tasks.forEach((task) => {
        if (task.type !== 'virtual')
          task.parent = wrapper_task_id;
      });
      filtered_tasks.unshift(wrapper_task);
      return_array.push({ filtered_tasks, wrapper_task_id });
    }
  }
  return return_array;
}
function handleUndefinedTasks(tasks, property, label, parent_task_id) {
  const undefined_array = tasks.filter(task => task[property] === undefined);
  const other_tasks = tasks.filter(task => task[property] !== undefined);

  if (undefined_array.length) {
    const wrapper_task_id = crypto.randomUUID();
    const wrapper_task = {
      id: wrapper_task_id,
      type: 'virtual',
      name: `${label}: Not set`,
      parent: parent_task_id,
      readonly: true,
    };
    undefined_array.forEach((task) => {
      if (task.type === 'task' && task.type !== 'virtual')
        task.parent = wrapper_task_id;
    });
    undefined_array.unshift(wrapper_task);
    return {
      undefined_array,
      other_tasks,
      wrapper_task_id,
    };
  }
  return { undefined_array: [], other_tasks, wrapper_task_id: null };
}

function findMinMaxValues(tasks, property) {
  let min_val = Number.MAX_SAFE_INTEGER;
  let max_val = 0;

  tasks.forEach((task) => {
    if (task[property] < min_val)
      min_val = task[property];
    if (task[property] > max_val)
      max_val = task[property];
  });
  return { min_val, max_val: Math.ceil(max_val) + 1 };
}

function createRanges(min_val, max_val, gap) {
  const ranges = [];
  for (let i = 0; ; i += gap)
    if (i + gap > max_val) {
      ranges.push([i, max_val]);
      break;
    }
    else {
      ranges.push([i, i + gap]);
    }
  return ranges;
}

function groupTasksByRange(tasks, ranges, property, label, parent_task_id) {
  const return_array = [];
  for (const range of ranges) {
    const wrapper_task_id = crypto.randomUUID();
    const wrapper_task = {
      id: wrapper_task_id,
      type: 'virtual',
      name: `${label}: ${range[0]} - ${range[1]}`,
      parent: parent_task_id,
      readonly: true,
    };
    const filtered_tasks = remove(
      tasks,
      task => range[0] <= task[property] && task[property] < range[1],
    );
    if (filtered_tasks.length) {
      filtered_tasks.forEach((task) => {
        if (task.type !== 'virtual')
          task.parent = wrapper_task_id;
      });
      filtered_tasks.unshift(wrapper_task);
      return_array.push({ filtered_tasks, wrapper_task_id });
    }
  }
  return return_array;
}

export function groupByDurationOrTotalOrFreeSlack(property, source_tasks, parent_task_id, option, $t) {
  const tasks = cloneDeep(source_tasks);
  const return_array = [];
  const label = getTranslationMap($t)[property];
  const gap = option === 'week' ? 7 : 1;
  const { undefined_array, other_tasks, wrapper_task_id } = handleUndefinedTasks(tasks, property, label, parent_task_id);
  if (undefined_array.length)
    return_array.push({
      filtered_tasks: undefined_array,
      wrapper_task_id,
    });
  if (other_tasks.length) {
    const { min_val, max_val } = findMinMaxValues(other_tasks, property);
    const ranges = createRanges(min_val, max_val, gap);
    const grouped_tasks = groupTasksByRange(other_tasks, ranges, property, label, parent_task_id);
    return_array.push(...grouped_tasks);
  }
  return return_array;
}
export function getGroupingFunction(key, $t) {
  switch (key) {
    case 'wbs':
      return tasks => tasks;
    case 'status':
      return groupByStatus;
    case 'progress':
      return groupByProgress;
    case 'total_duration':
    case 'total_slack':
    case 'free_slack':
      return (tasks, parent_task_id = undefined, option = 'day') =>
        groupByDurationOrTotalOrFreeSlack(
          key,
          tasks,
          parent_task_id,
          option,
          $t,
        );
    case 'critical':
      return groupByCriticality;
    // case 'resources':
    //   return groupByResources;
    case 'start_date':
    case 'end_date':
    case 'actual_start':
    case 'actual_finish':
    case 'planned_start':
    case 'planned_finish':
      return (tasks, parent_task_id = undefined, option = 'day') =>
        groupByDate(key, tasks, parent_task_id, option);
  }
}
// </script>
