<script setup>
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const emit = defineEmits(['close', 'submit', 'setCategory']);
const loading = ref(false);
const system_model_store = useSystemModelStore();

const categories = computed(() => system_model_store.categories);
async function submitHandler(form$) {
  try {
    loading.value = true;
    const category_name = form$.data.category;
    const is_category_exist = categories.value.find(category => category.name.toLowerCase() === category_name.toLowerCase());
    if (is_category_exist) {
      form$.el$('category').messageBag.prepend('Category is already exist.');
    }
    else {
      form$.el$('category').messageBag.clear();
      const data = await system_model_store.create_category({
        name: category_name,
        description: category_name,
      });
      emit('setCategory', data.uid);
      emit('close');
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    loading.value = false;
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('New category') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            v-bind="{
              label: `${$t('Category Name')}`,
              name: 'category',
              placeholder: 'Enter category name',
              rules: ['required'],
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
