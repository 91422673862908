<script setup>
import { difference } from 'lodash-es';

const props = defineProps({
  assignees: {
    type: Array,
    default: () => ([]),
  },
  on_update: {
    type: Function,
  },
});
const emits = defineEmits(['close']);

const form$ = ref(null);

async function onSave(formData, { data }) {
  const selected_assignees = data.assignees.map(assignee => assignee.uid);
  const payload = {};
  payload.add = difference(selected_assignees, props.assignees);
  payload.remove = difference(props.assignees, selected_assignees);
  await props.on_update({ assignees: payload }, false);
  emits('close');
}

function onFormMounted(el$) {
  el$.load({ assignees: props.assignees }, true);
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      ref="form$"
      :columns="{
        lg: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
      }"
      size="sm"
      :display-errors="false"
      :endpoint="onSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Assignees') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <hawk-assignee-input
            class="mb-5"
            :format_load="true"
            :multi="true"
            :value="assignees"
            :options="{
              name: 'assignees',
              has_teams: true,
              label: $t('Assignees'),
              placeholder: $t('Select Assignees'),
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
