<script setup>
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
import TransmittalsView from '~/dms/components/transmittals/list/transmittals-view.vue';

const $t = inject('$t');

const route = useRoute();
const router = useRouter();
const transmittals_store = useTransmittalsStore();

const tabs = [
  {
    uid: 'all',
    label: $t('All'),
    disabled: false,
  },
  {
    uid: 'outgoing',
    label: $t('Created by me'),
    disabled: false,
  },
  {
    uid: 'incoming',
    label: $t('Shared with me'),
    disabled: false,
  },
  {
    uid: 'unpublished',
    label: $t('Unpublished'),
    disabled: false,
  },
  {
    uid: 'submitted_by_me',
    label: $t('Submitted by me'),
    disabled: false,
  },
  {
    uid: 'archive',
    label: $t('Archived'),
    disabled: false,
  },
];
const active_tab = computed(() => route?.query?.active ? route.query.active : 'all');
provide('active_transmittal_tab', active_tab);

function changeTab(tab) {
  router.replace({
    query: { active: tab.uid },
  });
}
onBeforeMount(async () => {
  transmittals_store.set_filters({});
});
</script>

<template>
  <div class="mt-4">
    <hawk-tabs :tabs="tabs" :active_item="active_tab" :show_item="6" @tab-click="changeTab" />
    <TransmittalsView />
  </div>
</template>
