<script setup>
import { useRoute, useRouter } from 'vue-router';
import { PLANS_ROUTES_CONFIG } from '~/plans/constants';

const props = defineProps({
  task_uid: {
    type: String,
    required: true,
  },
  sheet: {
    type: Object,
    required: true,
  },
  location: {
    type: Object,
    default: () => {},
  },
});

const $services = inject('$services');
const $toast = inject('$toast');

const route = useRoute();
const router = useRouter();

const loading = ref(false);
const plan_data = ref(null);
const thumbnail = ref(null);
const drawing = ref(null);
const marker_position = ref(null);

async function getSheetThumbnail() {
  try {
    loading.value = true;
    const query = {
      sheets: true,
      element: true,
      asset: props.sheet.asset,
      sheet_element_uid: props.sheet.uid,
    };
    const { data } = await $services.plans.getAll({ query });
    if (data?.drawings) {
      drawing.value = data.drawings[0];
      plan_data.value = drawing.value?.sheets[0];
      thumbnail.value = plan_data.value?.current_version;
      const size = {
        width: plan_data.value?.width,
        height: plan_data.value?.height,
        rotation: plan_data.value?.rotation,
      };
      let x = props.location?.annotation_position?.x;
      let y = props.location?.annotation_position?.y;
      switch (plan_data.value?.rotation) {
        case 1:
          x = size.width - props.location?.annotation_position?.y;
          y = props.location?.annotation_position?.x;
          break;
        case 2:
          y = size.height - props.location?.annotation_position?.y;
          x = size.width - props.location?.annotation_position?.x;
          break;
        case 3:
          x = props.location?.annotation_position?.y;
          y = size.height - props.location?.annotation_position?.x;
          break;
      }
      if (props.location?.annotation_position && size?.width)
        marker_position.value = {
          left: `${(x / size.width) * 100}%`,
          top: `${(y / size.height) * 100}%`,
          margin: '-15px -10px',
        };
    }
    loading.value = false;
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
      position: 'bottom-right',
    });
    loading.value = false;
  }
}

onMounted(async () => {
  await getSheetThumbnail();
});

function routeToSheet() {
  if (route.name === PLANS_ROUTES_CONFIG.PLANS.name)
    return;
  router.push({
    name: PLANS_ROUTES_CONFIG.SHEET.name,
    params: {
      asset_id: plan_data.value.asset,
      sheet_id: plan_data.value.uid,
      drawing_id: drawing.value.uid,
    },
    query: {
      type: 'ticket',
      uid: props.task_uid,
    },
  });
}
</script>

<template>
  <div class="relative group outline outline-1 outline-gray-300 rounded-lg flex justify-center">
    <hawk-loader v-if="loading" />
    <div v-else-if="thumbnail" class="relative shrink-0">
      <img
        v-if="thumbnail && thumbnail.thumbnails && thumbnail.thumbnails.small"
        :src="thumbnail.thumbnails && thumbnail.thumbnails.small"
        class="rounded-lg"
        alt="thumbnail 1"
      >
      <IconHawkLocation
        v-if="marker_position"
        :style="marker_position"
        class="absolute text-error-600"
      />
    </div>
    <div v-else class="h-[160px] flex items-center justify-center">
      <IconHawkRefreshCcwOne class="text-primary-500 text-lg cursor-pointer" @click="getSheetThumbnail" />
    </div>
  </div>
  <p
    v-if="thumbnail"
    class="text-primary-700 text-xs font-semibold mt-2"
    :class="[loading ? 'pointer-events-none opacity-60' : 'pointer-events-auto cursor-pointer']"
    @click="routeToSheet"
  >
    {{ $t('View sheet') }}  <IconHawkChevronRight aria-hidden="true" class="w-[16px] h-[16px] inline" />
  </p>
</template>
