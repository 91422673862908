<script setup>
import InventoryTransactions from '~/inventory/pages/inventory-transactions.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  warehouse_id: {
    type: String,
    default: '',
  },
});
const { $t } = useCommonImports();
const state = reactive({
  filters: {
    label: $t('All'),
    uid: 'all',
    type: 'all',
  },
});

const inventory_store = useInventoryStore();

const all_filters = computed(() => {
  const items = [
    {
      uid: 'all',
      type: 'all',
      label: $t('All'),
      count: inventory_store?.adjustments?.length,
    },
  ];
  inventory_store.workflows.forEach((workflow) => {
    items.push({
      uid: workflow.uid,
      type: 'workflows',
      label: workflow.name,
      count: inventory_store?.adjustments?.filter(item => item.workflow === workflow.uid)?.length,
    });
  });
  items.push({
    uid: 'draft',
    type: 'draft',
    label: $t('Draft'),
    count: inventory_store?.adjustments?.filter(item => item.status === 'draft')?.length,
  });
  return items;
});

function onFilterApply(filter) {
  state.filters = filter;
}
</script>

<template>
  <div class="border rounded-2xl p-5">
    <div class="font-semibold mb-5">
      {{ $t('Recent Transactions') }} <span>({{ inventory_store?.adjustments?.length }})</span>
    </div>
    <div class="flex flex-wrap gap-3 mb-3">
      <HawkButton
        v-for="item in all_filters"
        :key="item.uid"
        type="outlined" size="xxs"
        :color="state.filters?.uid === item.uid ? 'active' : 'primary'"
        @click="onFilterApply(item)"
      >
        <div>
          <HawkText :content="item.label" />
          ({{ item?.count }})
        </div>
      </HawkButton>
    </div>
    <InventoryTransactions
      show_only_list
      :warehouse_id="warehouse_id"
      :filters="state.filters"
      is_recent_transactions
    />
  </div>
</template>
