<script setup>
// composables
import { useDocumentBulkActions } from '~/dms/composables/document-bulk-actions.composable';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';
import { useExportDocumentsMetadata } from '~/dms/composables/document-metadata-export.composable';

// components
import DocumentContextMenu from '~/dms/components/documents/table/document-context-menu.vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  source: {
    type: String,
    required: false,
    default: 'files',
  },
  active_tab: {
    type: String,
    required: false,
    default: 'files',
  },
  table_instance: {
    type: Object,
    required: false,
    default: () => {},
  },
});
const emit = defineEmits(['clear']);
const route = useRoute();
const document_bulk_actions = useDocumentBulkActions(props.table_instance);
const document_crud = useDocumentCrud(props.table_instance);
const { exportDocumentsMetaData, is_metadata_exporting, startExporting, cancelExportingMetadata } = useExportDocumentsMetadata(props.table_instance);

const only_files_selected = computed(() => props.items.every(item => item.type === 'file'));

const can_export = computed(() => {
  const has_files = props.items.some(item => item.type === 'file');
  return !has_files && route.name === 'files-documents-all-files';
});

const isContextOpen = ref(false);
</script>

<template>
  <HawkExportToast
    v-if="is_metadata_exporting"
    :submit="exportDocumentsMetaData"
    :progress_text="$t('Exporting documents')"
    :completed_text="$t('Documents exported')"
    @cancel="cancelExportingMetadata"
    @close="() => is_metadata_exporting = false"
  />
  <div class="flex items-center">
    <DocumentContextMenu
      class="mr-4"
      :bulk_items="items"
      :options="{
        dropdown_items_ids: props.active_tab === 'trash' ? ['restore'] : [...(only_files_selected ? ['bulk_update'] : []), 'share', 'download', 'move', 'archive', 'delete', 'export'],
      }"
      :active_tab="active_tab"
      @openContext="isContextOpen = true"
      @closeContext="isContextOpen = false"
      @bulkActionComplete="$emit('clear')"
      @download="document_crud.itemDownload({ bulk_items: items })"
      @delete="document_crud.itemDelete({ bulk_items: items })"
      @archive="document_bulk_actions.itemArchive({ bulk_items: items, is_archived_view: props.active_tab === 'archive', clear_selection: () => $emit('clear') })"
      @share="document_bulk_actions.itemShare({ bulk_items: items })"
      @move="document_bulk_actions.itemMove({ bulk_items: items })"
      @restore="document_bulk_actions.itemRestore({ bulk_items: items })"
      @bulkUpdate="document_bulk_actions.itemBulkUpdate({ bulk_items: items })"
      @export="startExporting"
    >
      <template #trigger>
        <hawk-button class="flex justify-between" type="outlined">
          Actions
          <!-- change icon -->
          <icon-ic-round-keyboard-arrow-up v-if="isContextOpen" class="text-lg" />
          <icon-ic-round-keyboard-arrow-down v-else-if="!isContextOpen" class="text-lg" />
        </hawk-button>
      </template>
    </DocumentContextMenu>
    <span class="text-sm text-gray-700">{{ `${items.length} ${$t('selected')}` }}</span>
    <hawk-button
      type="text"
      @click="emit('clear')"
    >
      {{ $t('Clear') }}
    </hawk-button>
  </div>
</template>
