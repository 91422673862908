<script setup>
import { orderBy } from 'lodash-es';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TerraProgressBar from '~/terra/components/barcode-scan/terra-progress-bar.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  projects: {
    type: Array,
    default: () => [],
  },
  filename_options: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['saveFile']);
const $services = inject('$services');
const columns = [
  {
    id: 'select',
    header: '',
    accessorKey: 'select',
    size: 5,
  },
  {
    id: 'name',
    header: 'Block',
    accessorKey: 'name',
  },
  {
    id: 'progress',
    header: 'Progress',
    accessorKey: 'progress',
  },
  {
    id: 'trackers',
    header: 'Trackers',
    accessorKey: 'trackers',
    columns: [
      {
        id: 'pending',
        header: 'Pending',
        accessorKey: 'pending',
        size: 120,
      },
      {
        id: 'inprogress',
        header: 'In progress',
        accessorKey: 'inprogress',
        size: 120,
      },
      {
        id: 'completed',
        header: 'Completed',
        accessorKey: 'completed',
        size: 120,
      },
    ],
  },
  {
    id: 'modules',
    header: 'Modules',
    accessorKey: 'modules',
    columns: [
      {
        id: 'total_modules_count',
        header: 'Total',
        accessorKey: 'total_modules_count',
        size: 100,
      },
      {
        id: 'num_scanned_modules',
        header: 'Scanned',
        accessorKey: 'num_scanned_modules',
        size: 100,
      },
      {
        id: 'modules_pending',
        header: 'Pending',
        accessorKey: 'modules_pending',
        size: 100,
      },
      {
        id: 'duplicates',
        header: 'Duplicates',
        accessorKey: 'duplicates',
        size: 100,
      },
    ],
  },
];

const state = reactive({
  selected_projects: [],
  is_exporting: false,
});

const table_data = computed(() => Object.values(props.data).map(value => ({
  uid: value.uid,
  name: value.name,
  progress: getProgress(value),
  pending: value.pending || 0,
  inprogress: value.inprogress || 0,
  completed: value.completed || 0,
  total_modules_count: value.total_modules_count || 0,
  num_scanned_modules: value.num_scanned_modules || 0,
  modules_pending: (value.total_modules_count || 0) - (value.num_scanned_modules || 0),
  duplicates: value.duplicates || 0,
})));

function getProgress(row) {
  const { pending, inprogress, completed } = row;
  const total = pending + inprogress + completed;
  const percentage_completed = Math.floor((completed / total) * 100);
  const percentage_inprogress = Math.floor(((inprogress / total) * 100), 100);
  const percentage_pending = Math.floor(((pending / total) * 100), 100);
  return {
    progress_details: [
      { percentage: percentage_completed, color: '#12B76A', tooltip: `${percentage_completed}% Completed` },
      { percentage: percentage_inprogress + percentage_completed, color: '#FDB022', tooltip: `${percentage_inprogress}% In-progress` },
      { percentage: percentage_pending + percentage_inprogress + percentage_completed, color: '#F04438', tooltip: `${percentage_pending}% Pending` },
    ],
    percentage_completed,
  };
}

function handleRowSelection(e) {
  state.selected_projects = e.map(row => row.original);
}
function getFileName(type = '') {
  const filename_parts = [];
  filename_parts.push(props.filename_options.asset_name);
  if (type)
    filename_parts.push(type);
  if (state.selected_projects.length === 1)
    filename_parts.push(state.selected_projects[0].name);
  filename_parts.push(`${props.filename_options.current_date}.csv`);
  return filename_parts.join('_');
}

async function downloadReport() {
  try {
    const { data } = await $services.terra.post({
      url: '/features/barcode/serial-numbers/',
      query: {
        json: true,
        pending: true,
      },
      body: {
        projects: state.selected_projects?.length ? state.selected_projects.map(item => item.uid) : props.projects,
      },
    });
    const export_data = data.map(item => ({
      'Block': item.project_name,
      'Tracker/Table': item.feature_name,
      'Module number': item.module_number,
      'Serial number': item.serial_number,
      'Module(X,Y)': item['module(x,y)'],
      'Duplicate': item.is_duplicate,
      'Duplicate At': item.duplicated_at,
    }));
    emit('saveFile', orderBy(export_data, ['Block', 'Tracker/Table', 'Module number']), getFileName());
  }
  catch (error) {
    logger.log(error);
  }
  state.is_exporting = false;
}
function downloadTableData() {
  const export_data = table_data.value.map(item => ({
    'Block': item.name,
    'Progress': item.progress.percentage_completed,
    'Trackers-Pending': item.pending,
    'Trackers-In progress': item.inprogress,
    'Trackers-Completed': item.completed,
    'Modules-Total': item.total_modules_count,
    'Modules-Scanned': item.num_scanned_modules,
    'Modules-pending': item.modules_pending,
    'Duplicates': item.duplicates,
  }));
  emit('saveFile', export_data, getFileName('summary'));
}
</script>

<template>
  <div class="py-3">
    <HawkExportToast v-if="state.is_exporting" :submit="downloadReport" progress_text="Exporting to CSV" completed_text="Exported CSV" @cancel="state.is_exporting = false" @close="state.is_exporting = false" />
    <div class="flex items-center justify-between py-3">
      <div class="text-lg font-semibold">
        {{ $t('Block wise report') }}
      </div>
      <div class="flex items-center gap-x-2">
        <HawkButton type="plain" icon @click="downloadTableData">
          <div v-tippy="{ content: $t('Download table'), placement: 'top' }">
            <IconHawkDownloadTwo />
          </div>
        </HawkButton>
        <hawk-button type="outlined" @click="state.is_exporting = true">
          {{ $t('Export data') }}
        </hawk-button>
      </div>
    </div>
    <div class="max-h-[60vh] w-full overflow-auto scrollbar border rounded-lg">
      <HawkTable
        is_gapless
        :pagination_config="{ pageSize: table_data.length }"
        :data="table_data"
        :columns="columns"
        :show_menu_header="false"
        :disable_resize="true"
        show_column_borders
        @select-row="handleRowSelection"
      >
        <template #progress="{ data: { row: { original: { progress } } } }">
          <div class="flex items-center">
            <div class="w-40">
              <TerraProgressBar :progress_details="progress.progress_details" />
            </div>
            <span class="text-sm font-medium ml-2">{{ progress.percentage_completed }}%</span>
          </div>
        </template>
      </HawkTable>
    </div>
  </div>
</template>
