<script setup>
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { uniqBy } from 'lodash-es';
import { computed, inject } from 'vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { fieldValueMap, formatAttachment, getFlatData, removeNullProperties } from '~/system-model/utils/helper';
import SmCategoryForm from '~/system-model/components/forms/sm-category-form.vue';
import SmAddFieldsForm from '~/system-model/components/forms/sm-add-fields-form.vue';
import SmCustomFieldsInput from '~/system-model/molecules/sm-custom-fields-input.vue';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  parent_id: {
    type: String,
    default: null,
  },
  component_detail: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close']);

const route = useRoute();
const auth_store = useAuthStore();
const current_organization = inject('current_organization');
const system_model_store = useSystemModelStore();

const selected_component = ref(null);
const loading = ref(false);
const form$ = ref(null);

const component_fields = computed(() => system_model_store.fields);
const component_values = computed(() => fieldValueMap(props.component_detail?.fieldvalues));
const active_component = computed(() => system_model_store.active_component);

const flat_component_hierarchy = computed(() => {
  const uniq_data = uniqBy(getFlatData([...system_model_store.active_component_hierarchy]), 'uid');
  return uniq_data?.filter(item => item.uid !== props.parent_id);
});
const component_options = computed(() => flat_component_hierarchy.value?.map(({ name, uid }) => ({ label: name, value: uid })));

const component_exists = computed(() => flat_component_hierarchy.value?.find(component => component.uid === selected_component.value));
const category_options = computed(() => {
  return system_model_store?.categories?.map(category => ({
    value: category.uid,
    label: category.name,
  }));
});

const new_field_modal = useModal({
  component: SmAddFieldsForm,
  attrs: {
    onClose() {
      new_field_modal.close();
    },
  },
});

function mountedForm(el$) {
  if (props.component_detail)
    el$.update({
      component_name: props.component_detail.name,
      category: props.component_detail.category,
    });
}

async function addNewComponent(data) {
  const response = await system_model_store.create_components({
    body: {
      name: data.component_name,
      asset: route.params.asset_id,
      children: [],
      category: data.category,
      template: route?.params?.template_id,
      ...(props.parent_id ? { parents: [props.parent_id] } : {}),
    },
  });
  await updateComponentHierarchy(response.component);
  return response;
}

async function addChildComponent() {
  const response = await system_model_store.create_child_component({ parent_uid: props.parent_id, child_uid: component_exists.value.uid });
  await updateComponentHierarchy(response[0]);
}

async function saveComponent(form$) {
  try {
    loading.value = true;
    system_model_store.is_hierarchy_dirty = true;
    const form_data = form$.data;
    if (props.component_detail)
      await updateComponentDetails(form_data);
    else
      await handleNewComponent(form_data);
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    loading.value = false;
    emit('close');
  }
}

async function updateComponentDetails(form_data) {
  try {
    const sanitized_data = removeNullProperties(form_data.custom_field);
    const create_fields_payload = [];
    const update_fields_payload = [];

    if (form_data.component_name !== props.component_detail.name) {
      await system_model_store.update_component({
        body: {
          name: form_data.component_name,
        },
        attribute: props.component_detail.uid,
      });
      await updateComponentHierarchy(active_component.value);
      updateComponentName(form_data.component_name);
    }

    for (const [field_uid, field_value] of Object.entries(sanitized_data)) {
      const field_id = component_values.value[field_uid]?.uid;
      const has_files = (Array.isArray(field_value)) ? field_value.some(file => file instanceof File) : false;
      const payload = {
        uid: field_id,
        value: has_files ? formatAttachment(field_value, { user_id: auth_store?.logged_in_user_details?.user_id }) : field_value,
      };
      if (field_id)
        update_fields_payload.push(payload);
      else
        create_fields_payload.push({
          field: field_uid,
          component: props.component_detail?.uid,
          organization: current_organization?.uid,
          value: payload.value,
        });
    }
    await Promise.all([
      update_fields_payload.length ? system_model_store.update_field_values(update_fields_payload) : Promise.resolve(),
      create_fields_payload.length ? system_model_store.create_field_value(create_fields_payload) : Promise.resolve(),
    ]);
    await system_model_store.set_active_component_details({
      component_id: props.component_detail?.uid,
    });
  }
  catch (err) {
    logger.error(err);
  }
}
function updateComponentName(componentName) {
  if (props.component_detail.uid === active_component.value.uid)
    system_model_store.$patch({
      active_component_details: {
        ...system_model_store.active_component_details,
        name: componentName,
      },
    });
}
async function handleNewComponent(form_data) {
  if (component_exists.value && props.parent_id)
    await addChildComponent();
  else
    await addNewComponent({ component_name: form_data.component_name, category: form_data.category });
}
async function updateComponentHierarchy(component) {
  await system_model_store.set_active_component_hierarchy({
    template_id: route?.params?.template_id,
    component,
  });
  system_model_store.set_active_tab('SmComponentDetails');
}

function selectComponentHandler(option) {
  selected_component.value = option;
  if (component_exists.value) {
    form$.value.el$('category').update(component_exists.value.category);
    form$.value.el$('category').disable();
  }
}

function clearComponentHandler() {
  selected_component.value = null;
  form$.value.el$('category').enable();
}

const { open: openCategoryModal, close: closeCategoryModal } = useModal({
  component: SmCategoryForm,
  attrs: {
    onClose() {
      closeCategoryModal();
    },
    onSetCategory(category) {
      form$.value.el$('category').load(category);
    },
  },
});
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      ref="form$"
      :display-errors="false"
      size="sm"
      :add-classes="{
        ElementLabel: {
          wrapper: ['text-[#344054] font-medium'],
        },
        TextElement: {
          input: ['text-[#475467]'],
        },
        CheckboxgroupCheckbox: {
          text: ['text-[#475467]'],
        },
        StaticElement: {
          content: ['text-[#344054] font-medium'],
        },
      }"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :attachment_config="{ meta: { service: 'system_model', id: 'upload' } }"
      :should_validate_on_mount="false"
      @mounted="mountedForm"
      @submit="saveComponent"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ component_detail ? $t('Edit') : $t('New component') }}
          </template>
        </hawk-modal-header>

        <hawk-modal-content :class="component_detail ? 'max-h-96 scrollbar' : null" :is_scroll="false">
          <SelectElement
            v-if="parent_id"
            v-bind="{
              items: component_options,
              name: 'component_name',
              search: true,
              native: false,
              label: `${$t('Component name')}`,
              placeholder: 'Select component',
              autocomplete: 'off',
              inputType: 'search',
              rules: ['required'],
              class: 'mb-6',
              create: true,
              appendNewOption: false,
              addOptionOn: ['enter'],
            }"
            @select="selectComponentHandler"
            @clear="clearComponentHandler"
          />
          <TextElement
            v-else
            v-bind="{
              label: `${$t('Component name')}`,
              name: 'component_name',
              placeholder: 'Enter component name',
              rules: ['required'],
              class: 'mb-6',
            }"
          />
          <SelectElement
            v-bind="{
              disabled: component_detail ? true : false,
              name: 'category',
              items: category_options,
              search: true,
              native: false,
              label: $t('Category'),
              placeholder: 'Select Category',
              autocomplete: 'off',
              inputType: 'search',
              rules: ['required'],
            }"
          >
            <template #before-list>
              <p
                class="px-3 pt-1.5 pb-1 text-primary hover:bg-gray-100 font-medium"
                @click="openCategoryModal"
              >
                + {{ $t('Create new') }}
              </p>
            </template>
          </SelectElement>

          <GroupElement
            v-if="component_detail"
            name="column2"
          >
            <StaticElement name="divider">
              <div class="my-6 border-t border-t-gray-200" />
            </StaticElement>
            <SmCustomFieldsInput
              :key="component_fields.length"
              :fields="component_fields"
              :fieldvalues_map="component_values"
              :options="{ name: 'custom_field' }"
            />
            <!-- Open adding new fields modal -->
            <StaticElement name="static_btn">
              <hawk-button type="text" @click="new_field_modal.open()">
                <IconHawkPlusGray class="h-3 w-3" />{{ $t('New Field') }}
              </hawk-button>
            </StaticElement>
          </GroupElement>
        </hawk-modal-content>

        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
