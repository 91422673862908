<script setup>
import { computed } from 'vue';
import { $date } from '~/common/utils/date.util';

const props = defineProps({
  selected_feature: {
    type: Function,
  },
});

const volume_calculation_columns = [
  {
    header: 'Date',
    accessorKey: 'date',
    id: 'date',
    size: 100,
  },
  {
    header: 'Type',
    accessorKey: 'layer',
    id: 'layer',
    size: 60,
  },
  {
    header: 'Method',
    accessorKey: 'method',
    id: 'method',
    size: 140,
  },
  {
    header: 'Cut',
    accessorKey: 'cut',
    id: 'cut',
    size: 80,
  },
  {
    header: 'Fill',
    accessorKey: 'fill',
    id: 'fill',
    size: 80,
  },
  {
    header: 'Total',
    accessorKey: 'total',
    id: 'total',
    size: 80,
  },
  {
    header: 'Height',
    accessorKey: 'height',
    id: 'height',
    size: 80,
  },
  {
    header: 'Net',
    accessorKey: 'net',
    id: 'net',
    size: 80,
  },
];

const volume_calculation_history = computed(() => {
  if (props.selected_feature?.properties?.dataProperties?.volume_cal_history) {
    const data = props.selected_feature.properties.dataProperties.volume_cal_history;
    return data.map((item) => {
      const format = { ...item };
      format.layer = item.method === 'surface-to-surface' ? '-' : format.layer.toUpperCase();
      format.date = getVolumeCalculatedData(item.date, 'date');
      format.method = getVolumeCalculatedData(item.method, 'method');
      format.height = item.method === 'base-height' ? getVolumeCalculatedData(item.height, 'format number') : '-';
      format.cut = getVolumeCalculatedData(item.cut, 'format number');
      format.fill = getVolumeCalculatedData(item.fill, 'format number');
      format.total = getVolumeCalculatedData(item.total, 'format number');
      const net = (typeof item?.cut === 'number' && typeof item?.fill === 'number') ? (item.cut - item.fill) : '';
      format.net = getVolumeCalculatedData(net, 'format number');
      return format;
    }).sort((a, b) => {
      const date_a = new Date(a.date).getTime();
      const date_b = new Date(b.date).getTime();
      return date_a - date_b;
    });
  }

  return [];
});

function getVolumeCalculatedData(data, type) {
  const method_value_name = {
    'base-height': 'Base height',
    'flat-minimum': 'Flat minimum',
    'triangulated': 'Triangulated',
    'surface-to-surface': 'Surface to Surface',
  };
  if (type === 'date')
    return $date(data, 'DD-MM-YYYY');
  if (type === 'format number') {
    if (typeof data === 'string')
      data = Number.parseFloat(data);
    return (typeof data === 'number') ? data.toFixed(2) : '-';
  }
  return method_value_name[data];
}
</script>

<template>
  <div v-if="volume_calculation_history.length" class="my-4">
    <div class="font-semibold text-base mb-2">
      {{ $t('Volume History') }}
    </div>
    <div class="w-full scrollbar">
      <HawkTable
        :data="volume_calculation_history"
        :columns="volume_calculation_columns"
        :manual_pagination="true"
        :show_menu_header="false"
        :header_grid_lines="{
          horizontal: true,
          vertical: true,
        }"
        :data_grid_lines="{
          horizontal: true,
          vertical: true,
        }"
        is_gapless
        disable_resize
        :is_pagination_enabled="false"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  :deep() {
    th {
      font-weight: 600;
      padding: 12px 8px !important;
    }
    td {
      padding: 12px 8px !important;
    }
    tr:last-child  {
      td:first-child{
        border-bottom-left-radius: 8px;
      }
      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
</style>
