<script setup>
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import SmGraph from '~/system-model/components/sm-graph/sm-graph.vue';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  instance_uid: {
    type: String,
    default: null,
  },
});

const emitter = useEmitter();
const system_model_store = useSystemModelStore();

const state = reactive({
  instance_hierarchy: [],
  is_loading: false,
  active_instance: {},
});

onMounted(async () => {
  await getData();
});

async function getData() {
  try {
    if (props.instance_uid) {
      state.is_loading = true;
      state.instance_hierarchy = await system_model_store.set_active_instance_hierarchy({ instance_id: props.instance_uid, descendants: true }, false);
      state.active_instance = { uid: props.instance_uid };
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

function doubleClickInstanceHandler(data) {
  emitter.emit('get-instance-detail', data.name);
}
</script>

<template>
  <div>
    <hawk-loader v-if="state.is_loading" />
    <template v-else>
      <SmGraph
        v-if="state.instance_hierarchy.length"
        :items="state.instance_hierarchy"
        :active="state.active_instance"
        class="!border-l-0 [&>*:first-child]:hidden"
        @element-dbl-clicked="doubleClickInstanceHandler"
      />
      <div v-else class="px-3 py-4 bg-gray-100">
        No data available
      </div>
    </template>
  </div>
</template>
