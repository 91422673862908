<script>
export default {
  name: 'ElementLabel',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        wrapper: '',
      },
    };
  },
};
</script>

<template>
  <!-- If label is a component -->
  <label v-if="label && isLabelComponent" :id="id" :class="classes.container" :for="name">
    <span :class="classes.wrapper"><component :is="label" v-if="isLabelComponent" /></span>
    <span v-if="el$.rules && el$.rules.indexOf('required') >= 0" class="text-amber-600">*</span>
    <ElementInfo><slot name="info" /></ElementInfo>
  </label>

  <!-- If label is HTML -->
  <label v-else-if="label" :id="id" :class="classes.container" :for="name">
    <span :class="classes.wrapper">
      <span>{{ label }}</span>
      <span v-if="el$.rules && el$.rules.indexOf('required') >= 0" class="text-amber-600">*</span>
    </span>
    <ElementInfo><slot name="info" /></ElementInfo>
  </label>

  <!-- If label is a slot -->
  <label v-else-if="isSlot" :id="id" :class="classes.container" :for="name">
    <span :class="classes.wrapper"><slot /></span>
    <span v-if="el$.rules && el$.rules.indexOf('required') >= 0" class="text-amber-600">*</span>
    <ElementInfo><slot name="info" /></ElementInfo>
  </label>

  <!-- If labels are forced but has no value -->
  <label v-else-if="hasLabel" :id="id" :class="classes.container" :for="name" />
</template>
