<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermCustomReport } from '~/therm/composables/therm-custom-report.js';
import { useThermStore } from '~/therm/store/therm.store.js';

const props = defineProps({
  values: {
    type: Object,
  },
});
const emits = defineEmits(['back', 'close']);
const { $services, $t, $toast } = useCommonImports();
const { uploadFiles, getAttachmentDetails } = useThermCustomReport();
const therm_store = useThermStore();

const document_viewer_ref = ref(null);
const document_viewer = ref(null);
const state = reactive({
  disable_actions: false,
  is_downloading: false,
  is_publishing: false,
  doc_blob: null,
});
function getFileURL() {
  return new URL(`/static/therm/therm-custom-report.docx`, import.meta.url).href;
}

async function initDocViewer(doc_instance) {
  state.disable_actions = true;
  document_viewer.value = doc_instance;
  document_viewer.value.zoomTo(1.25);
  try {
    await document_viewer.value.getDocument().applyTemplateValues(JSON.stringify(props.values));
    state.disable_actions = false;
  }
  catch (error) {
    logger.error('Error applying template values:', error);
    $toast({
      title: 'Error',
      text: 'Something went wrong. Please try again.',
      type: 'error',
    });
  };
}

async function getDocumentBlob() {
  if (!window.Core.PDFNet)
    await window.Core.PDFNet.initialize();

  if (state.blob)
    return state.blob;
  const doc_instance = document_viewer.value.getDocument();
  const pdf_buf = await doc_instance.getFileData({ downloadType: 'pdf' });
  const blob = new Blob([pdf_buf], { type: 'application/pdf' });
  state.blob = blob;
  return blob;
}
async function handlePublish() {
  state.is_publishing = true;
  try {
    const blob = await getDocumentBlob();
    const file = new File([blob], `Therm-custom-report-${props.values?.scan_date?.split(' ')?.filter(val => !!val)?.join('-')}.pdf`, { type: 'application/pdf' });
    await uploadFiles([file]);
    await $services.therm.post({
      attribute: `views/${therm_store.container.uid}/groups/${props.values?.group_uid}/summary-report/publish`,
      body: getAttachmentDetails(file),
    });
    $toast({
      text: 'Report published successfully',
      type: 'success',
    });
  }
  catch (error) {
    logger.error('Error publishing PDF:', error);
  }
  state.is_publishing = false;
}

async function downloadDocument() {
  state.is_downloading = true;
  try {
    const blob = await getDocumentBlob();
    const url = window.URL.createObjectURL(blob);
    const a = window.document.createElement('a');
    a.href = url;
    a.download = `Therm-custom-report-${props.values?.scan_date?.split(' ')?.filter(val => !!val)?.join('-')}.pdf`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  catch (error) {
    logger.error('Error downloading PDF:', error);
  }
  state.is_downloading = false;
}
</script>

<template>
  <div>
    <hawk-modal-header class="!py-4" @close="emits('close')">
      <template #title>
        {{ $t("Preview and Publish") }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content :is_scroll="false" class="h-[calc(100vh-120px)] w-full !p-0 !py-5">
      <div class="flex-none h-full w-[800px] m-auto border border-gray-300 rounded-lg scrollbar">
        <div class="h-9 my-3 mx-6 !mb-0 flex items-center justify-between">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Preview') }}
          </div>
          <HawkButton type="text" :loading="state.is_downloading" :disabled="state.disable_actions" @click="downloadDocument()">
            <IconHawkDownloadOne class="w-5 h-5" />
            {{ $t('Download') }}
          </HawkButton>
        </div>
        <div ref="document_viewer_ref" class="w-full text-white h-[calc(100vh-235px)]">
          <sheet-viewer
            :sheet_url="getFileURL()" :no_tools="true" scroll_view_class="!border-0 h-full" class="text-white report-preview"
            :setup_options="{
              no_zoom: true,
              extension: 'docx',
            }"
            viewer_class="!shadow-none"
            @on-doc-load="initDocViewer"
          />
        </div>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-end items-center border-t border-t-gray-200 !py-4 w-full">
      <template #right>
        <div class="flex justify-end items-center">
          <hawk-button
            class="mr-4 font-bold"
            type="outlined"
            @click="emits('back')"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            class="mr-4 font-bold"
            :loading="state.is_publishing"
            :disabled="state.disable_actions"
            @click="handlePublish()"
          >
            <IconHawkRocketOne />
            {{ $t('Publish') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </div>
</template>

<style lang="scss">
#viewer-container:deep(.report-preview) {
  :deep(#viewer) {
    flex: none;
    margin: auto;
    box-shadow: none !important;
  }
}
</style>
