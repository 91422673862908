<script setup>
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  rule_id: {
    type: String,
    default: null,
  },
  source_outcome: {
    type: String,
    default: '',
  },
  concurrent_node_click: {
    type: Boolean,
    default: false,
  },
  cell_model_parent: {
    type: String,
    default: '',
  },
  map_icons: {
    type: Object,
  },
});

const $t = inject('$t');
const is_loading = ref(true);
const blocks = ref([]);

const form_workflow_store = useFormWorkflowStore();

async function getBlocksData() {
  is_loading.value = true;

  blocks.value = await form_workflow_store.getSupportedBlocks({
    block_id: form_workflow_store.getBlockByNodeId[
      props.concurrent_node_click
        ? props.cell_model_parent
        : form_workflow_store.selected_node?.parent_id
    ]?.uid,
    query: {
      source_outcome: props.source_outcome,
      ...(props.rule_id && { rule_id: props.rule_id }),
    },
  });
  is_loading.value = false;
}
getBlocksData();
</script>

<template>
  <hawk-modal-container content_class="rounded-lg min-w-[800px]">
    <hawk-modal-header @close="$emit('close')">
      <template #title>
        {{ $t('Select a block below') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content class="scrollbar">
      <HawkLoader v-if="is_loading" />
      <div v-else class="grid grid-cols-2 gap-4">
        <div v-for="block in blocks" :key="block" class="border p-3 my-0.5 w-[370px] items-start rounded-lg hover:shadow-lg cursor-pointer" @click="$emit('addBlock', block)">
          <div class="flex justify-start items-start w-full">
            <div class="w-[50px]">
              <img
                :src="block.image_v2"
                class="w-9 h-9"
                alt="icon"
              >
            </div>
            <div class="flex flex-col h-full w-[320px]" :class="{ 'mt-2': block.type === 'end' }">
              <div class="text-[14px] font-semibold mb-1">
                {{ block.name }}
              </div>
              <div v-if="block.type !== 'end'" class="text-[12px]">
                {{ block.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </hawk-modal-content>
  </hawk-modal-container>
</template>
