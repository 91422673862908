<script setup>
import { reactive } from 'vue';
import { groupBy, orderBy } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  component_uid: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);
const $services = inject('$services');
const $toast = inject('$toast');

const common_store = useCommonStore();
const system_model_store = useSystemModelStore();

const state = reactive({
  loading: false,
  all_assets_templates: [],
  is_fetching: false,
  form: { components: [] },
});

const assets_options = computed(() => Object.values(common_store.assets));

onMounted(async () => {
  state.is_fetching = true;
  await getData();
  state.is_fetching = false;
});

async function getData() {
  const { data } = await $services.sm_templates.getAll({
    query: {
      'include[]': 'asset.*',
    },
  });
  if (data?.templates) {
    const grouped_template = groupBy(data?.templates, 'asset');
    state.all_assets_templates = orderBy(assets_options.value?.map((asset) => {
      return {
        ...asset,
        templates: grouped_template[asset.uid] || [],
      };
    }), 'name');
  }
}

async function submitHandler() {
  try {
    state.loading = true;
    const { components } = state.form;
    if (components?.length) {
      const payload = [];
      const { assets, templates } = components.reduce((acc, component) => {
        if (component?.parent_id)
          acc.templates.push(component);
        else
          acc.assets.push(component);
        return acc;
      }, { assets: [], templates: [] });

      if (assets.length)
        assets.forEach((asset) => {
          payload.push({
            asset: asset.uid,
            templates: asset.templates?.map(template => template.uid) || [],
          });
        });

      if (templates.length) {
        const grouped_templates = templates.reduce((acc, template) => {
          const asset = template.asset || '';
          acc[asset] = {
            asset,
            templates: [...(acc[asset]?.templates || [])],
          };
          acc[asset].templates.push(template.uid);
          return acc;
        }, {});
        payload.push(...Object.values(grouped_templates));
      }
      await system_model_store.create_duplicate_component({
        component_uid: props.component_uid,
        body: {
          data: payload,
        },
      });
      $toast({
        text: 'Duplicated successfully',
        type: 'success',
      });
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.loading = false;
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      v-model="state.form"
      sync
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('Duplicate component') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <StaticElement
            v-if="state.is_fetching"
            name="static_html"
          >
            <HawkLoader class="!m-1" />
          </StaticElement>
          <HawkTreeSelect
            v-else
            :options="{
              name: 'components',
              object: true,
              label: $t('Duplicate to'),
              placeholder: $t('Choose assets/templates'),
            }"
            data_type="object"
            label_key="name"
            value_key="uid"
            :data="state.all_assets_templates"
            children_key="templates"
            select_type="BRANCH_PRIORITY"
            @updateForm="state.form.components = $event"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="state.loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
