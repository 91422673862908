<script setup>
import { cloneDeep } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useCommonStore } from '~/common/stores/common.store';
import HawkAddReminders from '~/common/components/organisms/hawk-reminders/hawk-add-reminders.vue';

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
    //  - options: Object with the following properties:
    //    - number_of_reminders: Number
    //    - can_add_reminders: Boolean
    //    - can_delete_reminder: Boolean
    //    - load_from_trigger_property: String
    //    - assignees: Array
    //    - watchers: Array
    //    - creator: String
    //    - member_options: Array
    //    - status_values: Array
    //    - date_options: Array
    //    - get_reminders: Function
    //    - create_reminder: Function
    //    - update_reminder: Function
    //    - delete_reminder: Function
    //    - append_to_body: Boolean
    //    - modal_options: Object
  },
});

const emit = defineEmits(['close', 'update']);

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');

const common_store = useCommonStore();

const state = reactive({
  are_reminders_loading: false,
  reminders_map: {},
});

const reminders = computed(() => {
  return Object.values(state.reminders_map);
});

onMounted(async () => {
  await getReminders();
});

async function getReminders() {
  state.are_reminders_loading = true;
  const reminders = await props.options.get_reminders();
  state.reminders_map = { ...reminders };
  state.are_reminders_loading = false;
}

const { open: openRemindersPopup, close: closeRemindersPopup, patchOptions: patchRemindersPopup } = useModal({
  component: HawkAddReminders,
});
function openRemindersForm(reminder = null) {
  patchRemindersPopup({
    attrs: {
      options: {
        reminder,
        status_values: props.options.status_values,
        ...(props.options.member_options.length ? { member_options: props.options.member_options } : {}),
        date_options: props.options.date_options,
        load_from_trigger_property: props.options.load_from_trigger_property,
        create_reminder: props.options.create_reminder,
        update_reminder: props.options.update_reminder,
        asset: props.options.asset,
        append_to_body: props.options.append_to_body,
        modal_options: props.options.modal_options,
      },
      onRefresh: async () => {
        await getReminders();
      },
      onClose() {
        closeRemindersPopup();
      },
    },
  });
  openRemindersPopup();
}

async function deleteReminder(reminder_uid) {
  const reminders_map_copy = cloneDeep(state.reminders_map);
  try {
    delete state.reminders_map[reminder_uid];
    await props.options.delete_reminder(reminder_uid);
    $toast({
      title: $t('Reminder deleted successfully'),
      type: 'success',
    });
  }
  catch (error) {
    state.reminders_map = { ...reminders_map_copy };
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'error',
      position: 'bottom-right',
    });
  }
}

function formatReminder(reminder) {
  const getAssigneesNames = (uids) => {
    const assignee_names = uids.slice(0, 2).map(uid => common_store.get_user_or_team_name(uid));
    if (uids.length > 2)
      return `${assignee_names.join(', ')} ${$t('and')} ${uids.length - 2} ${$t('others')}`;
    else
      return assignee_names.join(` ${$t('and')} `);
  };
  return {
    action: {
      text: reminder.action === 'notify' ? $t('Notify') : $t('Assign and notify'),
      class: '',
    },
    assignees: {
      text: getAssigneesNames(reminder.to),
      class: 'font-semibold',
    },
    period: {
      text: `${reminder.config.days} ${reminder.config.days > 1 ? 'days' : 'day'}`,
      class: 'font-semibold text-violet-700',
    },
    when: {
      text: reminder.config.when,
      class: '',
    },
    from: {
      text: reminder.config.from === 'startDate' ? $t('start date') : $t('due date'),
      class: 'font-semibold text-warning-700',
    },
    ...(reminder.config.count > 1 && {
      count: {
        text: `${$t('for')} ${reminder.config.count} ${$t('times')}`,
        class: '',
      },
    }),
  };
}

function getReminderText(reminder) {
  let all_assignees = [];

  if (reminder.action === 'assign_and_notify') {
    all_assignees = reminder.to;
  }
  else {
    if (reminder.to.assignees)
      all_assignees = [...all_assignees, ...props.options.assignees];

    if (reminder.to.watchers)
      all_assignees = [...all_assignees, ...(props.options.watchers)];

    if (reminder.to.creator)
      all_assignees.push(props.options.creator);

    if (reminder.to.custom?.length)
      all_assignees = [...all_assignees, ...reminder.to.custom];
  }

  all_assignees = [...new Set(all_assignees)];

  return formatReminder({ ...reminder, to: all_assignees });
}
</script>

<template>
  <HawkModalContainer :options="props.options.modal_options">
    <HawkModalHeader @close="emit('close')">
      <template #left>
        {{ $t('Reminders') }}
      </template>
    </HawkModalHeader>
    <HawkModalContent>
      <div v-if="state.are_reminders_loading">
        <template v-if="options.number_of_reminders">
          <hawk-skeleton
            v-for="i in options.number_of_reminders"
            :key="i"
            custom_classes="w-full mb-6 h-4"
          />
        </template>
        <HawkLoader v-else />
      </div>
      <div v-else>
        <div v-for="reminder in reminders" :key="reminder.uid">
          <div class="grid grid-cols-12 mb-4">
            <p class="col-span-10 text-sm">
              <span
                v-for="(value, key) in getReminderText(reminder)"
                :key="key"
                :class="value.class"
                class="mr-1"
              >
                {{ value.text }}
              </span>
            </p>
            <div class="col-span-2 flex justify-end mt-[-7px]">
              <hawk-button
                icon
                type="plain"
                :disabled="!options.can_add_reminders"
                @click="() => openRemindersForm(reminder)"
              >
                <IconHawkPencil class="text-gray-600 h-4 mr-4" />
              </hawk-button>
              <hawk-button
                icon
                type="plain"
                :disabled="!options.can_delete_reminder"
                @click="() => deleteReminder(reminder.uid)"
              >
                <IconHawkTrashThree class="text-gray-600 h-5" />
              </hawk-button>
            </div>
          </div>
        </div>
      </div>
      <hawk-button
        icon
        type="plain"
        class="text-sm font-semibold w-auto"
        :disabled="!options.can_add_reminders"
        @click="() => openRemindersForm()"
      >
        <IconHawkPlusWhite class="mr-2 h-4" />
        <p class="whitespace-nowrap">
          {{ $t('Add reminder') }}
        </p>
      </hawk-button>
    </HawkModalContent>
    <HawkModalFooter class="flex justify-between items-center">
      <template #right>
        <div class="flex justify-end items-center">
          <hawk-button
            color="primary"
            @click="emit('close')"
          >
            {{ $t('Done') }}
          </hawk-button>
        </div>
      </template>
    </HawkModalFooter>
  </HawkModalContainer>
</template>
