import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useTagsStore } from '~/common/stores/tags.store.js';

const status_options = [
  [1, 'Pending'],
  [2, 'In-progress'],
  [3, 'Resolved'],
  [4, 'Closed'],
  [5, 'Rejected'],
].map((item) => {
  return {
    key: item[0],
    label: item[1],
  };
});

const priority_options = [
  [1, 'Critical'],
  [2, 'High'],
  [3, 'Medium'],
  [4, 'Low'],
  [5, 'Not set'],
].map((item) => {
  return {
    value: item[0],
    label: item[1],
  };
});

const operator_options = {
  date: [
    ['is', 'Is'],
    ['earlier', 'Earlier than'],
    ['later', 'Later than'],
    ['between', 'Between'],
    ['this_week', 'This week'],
    ['this_month', 'This month'],
    ['this_quarter', 'This quarter'],
    ['this_year', 'This year'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  number: [
    'greater than',
    'greater than or equal to',
    'less than',
    'between',
  ].map((key) => {
    return {
      value: key,
      label: key,
    };
  }),
  text: [
    ['is', 'Is'],
    ['contains', 'Contains'],
    ['starts_with', 'Starts with'],
    ['ends_with', 'Ends with'],
  ].map(item => ({ value: item[0], label: item[1] })),
  date_range: [
    ['equals', 'Duration equals'],
    ['start_earlier', 'Start earlier than'],
    ['start_later', 'Start later than'],
    ['start_between', 'Start between'],
    ['start_this_week', 'Start This week'],
    ['start_this_month', 'Start this month'],
    ['start_this_quarter', 'Start this quarter'],
    ['start_this_year', 'Start this year'],
    ['end_earlier', 'End earlier than'],
    ['end_later', 'End later than'],
    ['end_between', 'End between'],
    ['end_this_week', 'End This week'],
    ['end_this_month', 'End this month'],
    ['end_this_quarter', 'End this quarter'],
    ['end_this_year', 'End this year'],
  ].map(item => ({ value: item[0], label: item[1] })),
};

function time_options() {
  const items = [];
  let currentDate = dayjs('12');
  Array.from({ length: 48 }).fill().forEach(() => {
    const date = currentDate.format('h:mm a');
    items.push({
      value: date,
      label: date,
    });
    currentDate = currentDate.add(30, 'minutes');
  });
  return items;
}

function member_options() {
  const route = useRoute();
  const common_store = useCommonStore();

  return [
    ...common_store.scope_users(route.params?.asset_id || null).map((user) => {
      let user_name = '';
      if (user.first_name || user.last_name)
        user_name = `${user.first_name} ${user.last_name}`;
      if (!user_name)
        user_name = user.email;
      return {
        label: user_name,
        value: user.uid,
      };
    }),
    ...common_store.scope_teams(route.params?.asset_id || null).map((team) => {
      return {
        label: `Team ${team.name || 'undefined'}`,
        value: team.uid,
      };
    }),
  ];
}

function categories_options() {
  const common_store = useCommonStore();
  const route = useRoute();

  return common_store.scope_categories(route.params?.asset_id || null).map((category) => {
    return {
      label: category.name,
      value: category.uid,
    };
  });
}

function tags_options() {
  const tags_store = useTagsStore();

  return tags_store.tags.map(tag => ({
    value: tag.uid,
    label: tag.name,
  }));
}

function getDateInterval(interval = 'this_week') {
  const interval_slug = interval.slice(5);

  return [
    dayjs().startOf(interval_slug).toDate(),
    dayjs().endOf(interval_slug).toDate(),
  ];
}

function isDateIntervalOperator(filter_type) {
  return [
    'this_week', 'start_this_week', 'end_this_week',
    'this_month', 'start_this_month', 'end_this_month',
    'this_quarter', 'start_this_quarter', 'end_this_quarter',
    'this_year', 'start_this_year', 'end_this_year',
  ].includes(filter_type);
}

export {
  operator_options,
  time_options,
  member_options,
  tags_options,
  categories_options,
  getDateInterval,
  isDateIntervalOperator,
};
