import ProfileSettingsV2 from '~/profile-settings/pages/profile-settings-v2.vue';
import General from '~/profile-settings/pages/profile-settings-general.vue';
import Security from '~/profile-settings/pages/profile-settings-security.vue';
import Notifications from '~/profile-settings/pages/profile-settings-notifications.vue';

const routes = [
  {
    path: '/profile-settings',
    component: ProfileSettingsV2,
    children: [
      {
        path: '',
        name: 'profile-settings-general',
        component: General,
        meta: {
          title: 'General',
        },
      },
      {
        path: 'security',
        name: 'profile-settings-security',
        component: Security,
        meta: {
          title: 'Security',
        },
      },
      {
        path: 'notifications',
        name: 'profile-settings-notifications',
        component: Notifications,
        meta: {
          title: 'Notifications',
        },
      },
    ],
  },
];
export default routes;
