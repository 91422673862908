<script setup>

</script>

<template>
  <HawkAlertBanner color="warning" class="!p-3 flex items-center">
    <template #icon>
      <IconHawkAlertTriangle />
    </template>
    <template #content>
      <slot />
    </template>
  </HawkAlertBanner>
</template>
