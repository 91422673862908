<script setup>
import { computed } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  text_length: {
    type: Number,
    default: 20,
  },
});

defineEmits(['click']);

// computed
const item = computed(() => (props.item));
</script>

<template>
  <div class="p-3 min-w-[10rem] font-medium flex items-center text-sm w-full hover:bg-gray-50 cursor-pointer rounded-lg flex-nowrap whitespace-nowrap overflow-hidden text-ellipsis" @click="$emit('click')">
    <span class="text-gray-700 font-medium">
      <slot name="label">
        <HawkText :content="item.label" :length="text_length" />
      </slot>
    </span>
    <span v-if="item.description" class="text-gray-600 ml-2">
      {{ item.description }}
    </span>
    <IconHawkCheck v-if="selected" class="ml-auto text-primary min-w-5" />
  </div>
</template>
