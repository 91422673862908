<script setup>
import { useTasksStore } from '~/tasks/store/tasks.store.js';

import TaskTemplateDropdown from '~/tasks/components/atoms/task-template-dropdown.vue';

const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  task: {
    type: Object,
    require: true,
  },
  type: {
    type: String,
    require: true,
    validator: (value) => { return ['load', 'save'].includes(value); },
  },
});

const emit = defineEmits(['close']);

const $toast = inject('$toast');
const $t = inject('$t');

const template_store = useTasksStore('template');

const options = [
  { value: 'assignee', label: 'Assignees' },
  { value: 'attachments', label: 'Attachments' },
  { value: 'checklists', label: 'Checklists' },
  { value: 'priority', label: 'Priority' },
  { value: 'watchers', label: 'Watchers' },
  { value: 'tags', label: 'Tags' },
  { value: 'category', label: 'Category' },
  { value: 'sub_tasks', label: 'Sub tasks' },
  { value: 'schedule', label: 'Schedule' },
  { value: 'status', label: 'Status' },
];
const form$ = ref(null);
const is_loading = ref(false);
const formData = reactive({
  template: '',
  inclusion_type: 'all',
  include: options.map(o => o.value),
});
const title = computed(() => {
  if (props.type === 'load')
    return $t('Load from template');
  else if (props.type === 'save')
    return $t('Save as template');
});

function changeInclusions(newVal) {
  const include_element = form$.value.elements$.include.children$.include;
  if (newVal === 'all') {
    include_element.disableAll();
    include_element.checkAll();
  }
  else {
    include_element.enableAll();
  }
}
async function apply() {
  is_loading.value = true;
  const inclusion_data = {};
  options.forEach(({ value }) => {
    if (value === 'checklists')
      if (formData.include.includes('checklists'))
        inclusion_data[value] = {
          assignee: true,
          dueDate: true,
          attachments: true,
          resolved: true,
        };
      else
        inclusion_data[value] = false;
    else
      inclusion_data[value] = formData.include.includes(value);
  });
  try {
    const payload = {
      tasks: [
        {
          action: `${props.type}_template`,
          name: template_store.get_current_task(formData.template)?.name || formData.template,
          task_uid: props.task.uid,
          ...template_store.get_current_task(formData.template) && { template_uid: formData.template },
          include: {
            ...inclusion_data,
            due_date: !props.task.due_date,
            start_date: !props.task.start_date,
            properties: false,
          },
        },
      ],
    };
    if (props.type === 'load') {
      await props.task_store.load_from_template(payload);
      props.task_store.task_track_events('Loaded a template', {}, props.task.uid, ['associated_with']);
    }
    else if (props.type === 'save') {
      await props.task_store.save_template(payload);
      props.task_store.task_track_events('Template created', {}, '', []);
    }
    is_loading.value = false;
    $toast({
      text: `${title.value} successful!`,
      type: 'success',
      position: 'bottom-right',
    });
    emit('close');
  }
  catch (err) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
      position: 'bottom-right',
    });
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="formData"
      size="sm"
      :format-load="(data) => data"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :should_validate_on_mount="false"
      :display-errors="false"
      :endpoint="apply"
      sync
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ title }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <!-- Name -->
          <TaskTemplateDropdown
            v-if="type === 'load'"
            class="mb-4"
            :options="{
              name: 'template',
              label: $t('Name'),
              placeholder: $t('Choose template'),
              rules: ['required'],
            }"
            :asset_id="task.target_element.asset"
            :show_only_org_templates="!task.target_element.asset"
          />
          <TaskTemplateDropdown
            v-else-if="type === 'save'"
            class="mb-4"
            :options="{
              name: 'template',
              label: $t('Name'),
              placeholder: $t('Choose template or Create new'),
              rules: ['required'],
              create: true,
              appendNewOption: false,
            }"
            :asset_id="task.target_element.asset"
            :show_only_org_templates="!task.target_element.asset"
          />
          <!-- Options -->
          <GroupElement :label="$t('Include in template')" name="include">
            <RadiogroupElement
              name="inclusion_type"
              default="all"
              :items="[
                { value: 'all', label: 'Everything' },
                {
                  value: 'custom',
                  label:
                    'Custom',
                },
              ]"
              :override-classes="{
                RadiogroupElement: {
                  wrapper: 'three_columns',
                },
              }"
              @change="changeInclusions"
            />
            <CheckboxgroupElement
              v-bind="{
                name: 'include',
                items: options,
                disabled: true,
                label: null,
                overrideClasses: {
                  CheckboxgroupElement: {
                    wrapper: 'three_columns ',
                  },
                  CheckboxgroupCheckbox: {
                    container: 'mb-3 flex items-center',
                    text: 'mt-[2px] inline-block',
                  },
                },

              }"
            />
          </GroupElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ title }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style lang="scss">
.three_columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
