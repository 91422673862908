import { cloneDeep, keyBy, omit } from 'lodash-es';
import { defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { getHostName } from '~/common/utils/common.utils';

export function useCustomViewStore(key = 'store') {
  const auth_store = useAuthStore();
  return defineStore(`custom-view-${key}`, {
    persist:
    [
      {
        pick: ['views_map'],
        storage: localStorage,
        key: getHostName() === 'localhost' ? `localhost_custom-view-${auth_store.current_organization_uid}-${key}` : `${import.meta.env.VITE_APP_ENV}_custom-view-${auth_store.current_organization_uid}-${key}`,
      },
    ],
    state: () => ({
      views_map: {},
      promise: null,
      called_for_session: false,
    }),
    getters: {
      views: state => Object.values(state.views_map),
      get_view: state => id => state.views_map[id],
    },
    actions: {
      async set_custom_views(payload, options) {
        try {
          if (options.views) {
            this.views_map = keyBy(options.views, 'uid');
            return;
          }
          if (!options.forceUpdate && this.called_for_session)
            return;

          if (this.promise) {
            return this.promise;
          }
          else {
            this.promise = this.$services.custom_views.getAll({
              query: payload?.query,
            });
            this.called_for_session = true;
            const { data } = await this.promise;
            this.promise = null;
            this.views_map = keyBy(data?.custom_views, 'uid');
            return this.views_map;
          }
        }
        catch (err) {
          logger.error(err);
          this.promise = null;
        }
      },
      async create_view(payload) {
        try {
          const { data } = await this.$services.custom_views.post({
            body: {
              custom_view: payload,
            },
          });
          if (data?.custom_view) {
            this.views_map[data.custom_view.uid] = data.custom_view;
            this.views_map = cloneDeep(this.views_map);
            return data.custom_view;
          }
        }
        catch (err) {
          logger.error(err);
        }
      },

      async update_view(payload, view_id) {
        try {
          const { data } = await this.$services.custom_views.patch({
            id: view_id,
            body: {
              custom_view: payload,
            },
          });
          this.views_map[data.custom_view.uid] = data.custom_view;
          this.views_map = cloneDeep(this.views_map);
        }
        catch (err) {
          logger.error(err);
        }
      },
      async delete_view(view_id) {
        try {
          await this.$services.custom_views.delete({
            id: view_id,
          });
          this.views_map = cloneDeep(omit(this.views_map, view_id));
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  })();
}
