<script setup>
import { orderBy } from 'lodash-es';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [
      {
        uid: '1',
        label: 'Item 1',
        to: { name: 'tasks' },
        on_click: () => {},
        disabled: false,
        count: 10,
      },
      {
        uid: '2',
        label: 'Item 2',
        to: {},
        on_click: () => {},
        disabled: true,
        count: 0,
      },
      {
        uid: '3',
        label: 'Item 3',
        to: {},
        on_click: () => {},
        disabled: false,
        count: 0,
      },
    ],
  },
  active_item: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['sm', 'md'].includes(value);
    },
  },
  visible_item: {
    type: Number,
    // 0 for all menu
    default: 0,
  },
  active_item_first: {
    type: Boolean,
    default: false,
  },
  sort: {
    // asc, desc
    type: String,
    default: null,
  },
});

const emit = defineEmits(['tabClick']);

const router = useRouter();

const tab_classes = computed(() => {
  const tab = [];

  switch (props.size) {
    case 'sm':
      tab.push('text-sm py-0.75 px-3');
      break;
    case 'md':
      tab.push('text-base py-2.5 px-3.5');
      break;
  }

  return tab;
});

const tab_count_classes = computed(() => {
  const tabCount = [];

  switch (props.size) {
    case 'sm':
      tabCount.push('text-xs px-2 py-0.5');
      break;
    case 'md':
      tabCount.push('text-sm px-2.5 py-0.5');
      break;
  }

  return tabCount;
});

const filtered_tabs = computed(() => {
  if (props.active_item_first) {
    // remove the item from menu and add to the first
    const first_item = props.tabs.filter(tab => tab?.uid === props.active_item);
    const rest_item = props.sort
      ? orderBy(props.tabs.filter(tab => tab?.uid !== props.active_item), ['label'], props.sort)
      : props.tabs.filter(tab => tab?.uid !== props.active_item);
    return [...first_item, ...rest_item];
  }
  else {
    return props.sort ? orderBy(props.tabs, ['label'], props.sort) : props.tabs;
  }
});

const tab_items = computed(() => {
  return props.visible_item === 0 ? filtered_tabs.value : filtered_tabs.value.slice(0, props.visible_item);
});

const more_tab_items = computed(() => {
  return props.visible_item ? filtered_tabs.value.slice(props.visible_item) : [];
});

const more_tab_classes = computed(() => {
  if (more_tab_items.value.find(i => i.uid === props.active_item))
    return 'text-gray-900 bg-white shadow';
  else return '';
});

function onTabClick(tab) {
  if (tab?.to && Object.keys(tab?.to)?.length)
    router.push(tab.to);
  else if (tab?.on_click)
    tab.on_click();
  else
    emit('tabClick', tab);
}
</script>

<template>
  <div>
    <div class="md:hidden">
      <HawkMenu :items="tabs">
        <template #trigger="{ open }">
          <div
            class="md:w-full lg:w-40 h-9 text-sm font-medium border rounded-lg flex items-center justify-between px-3 no-whitespace-wrap truncate"
          >
            {{ tabs.find((tab) => tab.uid === active_item)?.label }}
            <div>
              <IconHawkChevronUp v-if="open" class="text-lg" />
              <IconHawkChevronDown v-else class="text-lg" />
            </div>
          </div>
        </template>
        <template #content="{ close }">
          <div class="p-1 w-52 flex flex-col max-h-80 scrollbar overflow-y-auto">
            <div
              v-for="tab in filtered_tabs"
              :key="tab.label"
              class="w-80 px-2 py-2.5 hover:bg-gray-100 cursor-pointer"
              :class="
                tab.uid === active_item
                  ? '!text-blue-600 font-semibold'
                  : '!text-gray-700 font-medium'
              "
              @click="onTabClick(tab);close()"
            >
              <HawkText :content="tab.label" />
            </div>
          </div>
        </template>
      </HawkMenu>
    </div>
    <div class="hidden md:block">
      <nav
        class="flex gap-2 flex-wrap bg-gray-100 rounded-lg w-fit border"
        :class="{ 'p-[3px]': size === 'sm', 'p-1.5': size === 'md' }"
        aria-label="Tabs"
      >
        <div
          v-for="tab in tab_items"
          :key="tab.name"
          class="font-semibold rounded cursor-pointer flex items-center h-7"
          :class="[
            tab_classes,
            tab.uid === active_item
              ? 'text-gray-900 bg-white shadow'
              : 'text-gray-500 hover:text-gray-700 hover:bg-white hover:shadow',
            { 'pointer-events-none opacity-40': tab.disabled },
          ]"
          @click="onTabClick(tab)"
        >
          <div class="flex gap-2 items-center">
            <HawkText :content="tab.label" />
            <slot v-if="$slots?.right_icon && tab?.has_right_icon" name="right_icon" />
          </div>
          <div
            v-if="tab.count"
            class="mix-blend-multiply bg-gray-100 rounded-full ml-2"
            :class="tab_count_classes"
          >
            {{ tab.count }}
          </div>
        </div>
        <HawkMenu v-if="more_tab_items?.length" :items="more_tab_items" additional_trigger_classes="!ring-0">
          <template #trigger="{ open }">
            <div
              class="font-semibold rounded cursor-pointer flex items-center h-7 text-gray-500 hover:text-gray-700 hover:bg-white hover:shadow gap-2"
              :class="[tab_classes, more_tab_classes]"
            >
              <span> {{ $t('More') }} </span>
              <IconHawkChevronUp v-if="open" class="text-lg" />
              <IconHawkChevronDown v-else class="text-lg" />
            </div>
          </template>
          <template #content="{ close }">
            <div class="p-1 w-52 flex flex-col max-h-80 scrollbar overflow-y-auto">
              <div
                v-for="tab in more_tab_items"
                :key="tab.label"
                type="text"
                class="py-2.5 text-left flex justify-between w-full text-sm items-center px-3 hover:bg-gray-50 rounded-lg cursor-pointer"
                :class="
                  tab.uid === active_item
                    ? '!text-blue-600 font-semibold'
                    : '!text-gray-700 font-medium'
                "
                @click="onTabClick(tab);close()"
              >
                <HawkText :content="tab.label" />
                <slot v-if="$slots?.right_icon && tab?.has_right_icon" name="right_icon" />
              </div>
            </div>
          </template>
        </HawkMenu>
      </nav>
    </div>
  </div>
</template>
