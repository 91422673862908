<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { import_fusioncharts } from '~/common/utils/package.utils';

const $t = inject('$t');
const $date = inject('$date');

const project_management_store = useProjectManagementStore();

const { active_task } = storeToRefs(project_management_store);

const { get_activity_logs, get_activity_progress_history } = project_management_store;

const state = reactive({
  is_loading: true,
  fc_instance: null,
  progress_logs_data: [],
  progress_history_data: [],
});

const logs = computed(() => {
  return state.progress_logs_data.map(log => ({
    user: log?.owner?.uid,
    from: Math.round(log.data.old.progress * 100),
    to: Math.round(log.data.new.progress * 100),
    data_date: log.data.new.data_date,
  }));
});

async function getData() {
  state.is_loading = true;
  state.progress_logs_data = await get_activity_logs(active_task.value.uid);
  state.progress_history_data = await get_activity_progress_history(active_task.value.uid);
  const progress_logs = state.progress_logs_data.reduce((acc, curr) => {
    const { data_date, progress } = curr.data.new;
    acc.push([
      dayjs(data_date).format('DD-MMM-YY'),
      'Actual',
      Math.round(progress * 100),
    ]);
    return acc;
  }, []);

  const progress_history = state.progress_history_data.reduce(
    (acc, curr) => {
      const data_date = dayjs(curr[0]);
      acc.push([
        data_date.format('DD-MMM-YY'),
        'Scheduled',
        Math.round(curr[1] * 100),
      ]);
      return acc;
    },
    [],
  );

  let dates = [];
  const progress_logs_data = progress_logs.reduce((arr, curr) => {
    if (!dates.includes(curr[0])) {
      dates.push(curr[0]);
      arr.push(curr);
    }
    return arr;
  }, []);

  if (state.progress_logs_data.length) {
    const data = state.progress_logs_data[
      state.progress_logs_data.length - 1
    ];
    const { data_date, progress } = data.data.old;

    if (data_date)
      progress_logs_data.unshift([
        dayjs(data_date).format('DD-MMM-YY'),
        'Actual',
        Math.round(progress * 100),
      ]);
  }

  dates = [];
  const progress_history_data = progress_history.reduce((arr, curr) => {
    if (!dates.includes(curr[0])) {
      dates.push(curr[0]);
      arr.push(curr);
    }
    return arr;
  }, []);

  const schema = [
    {
      name: 'Time',
      type: 'date',
      format: '%d-%b-%y',
    },
    {
      name: 'Type',
      type: 'string',
    },
    {
      name: 'Progress',
      type: 'number',
    },
  ];

  const data = [...progress_logs_data, ...progress_history_data];

  const { FusionCharts } = await import_fusioncharts();
  const data_store = new FusionCharts.DataStore();
  const data_source = {
    chart: {},
    data: data_store.createDataTable(data, schema),
    navigator: {
      enabled: 0,
    },
    series: 'Type',
    xaxis: {
      plot: 'Time',
      timemarker: [
        {
          start: dayjs(active_task.value.data_date).format('DD-MMM-YY'),
          timeformat: '%d-%b-%y',
          type: 'full',
          label: $t('Data date'),
        },
      ],
    },
    yaxis: [
      {
        plot: [
          {
            value: 'Progress',
            connectnulldata: true,
            min: '0',
            max: '100',
            format: {
              suffix: '%',
            },
            style: {
              'plot.null': {
                'stroke-dasharray': 'none',
              },
            },
          },
        ],
        title: 'Progress',
      },
    ],
  };
  state.is_loading = false;
  return data_source;
}

async function plotChart(data_source) {
  const { VueFusionChartsComponent, FusionCharts, TimeSeries } = await import_fusioncharts();

  VueFusionChartsComponent(FusionCharts, TimeSeries);
  state.fc_instance = new FusionCharts({
    type: 'timeseries',
    renderAt: 'progress-history-chart',
    width: '500',
    height: '350',
    dataSource: data_source,
  });
  state.fc_instance.render();
}

watch(() => active_task.value.uid, async () => {
  if (state.fc_instance) {
    const data_source = await getData();
    if (!state.progress_logs_data?.length && !state.progress_history_data?.length)
      return;
    state.fc_instance.setJSONData(data_source);
    state.fc_instance.render();
  }
  else {
    const data_source = await getData();
    if (!state.progress_logs_data?.length && !state.progress_history_data?.length)
      return;
    await plotChart(data_source);
  }
}, { immediate: true });
</script>

<template>
  <div class="flex">
    <div class="overflow-auto mr-3 w-full">
      <div class="text-sm font-semibold text-gray-900 mb-2">
        {{ `${$t('History')} (${logs.length})` }}
      </div>
      <div class="h-96 overflow-auto scrollbar pr-3">
        <div
          v-for="log of logs"
          :key="log.uid"
          class="w-full flex items-center justify-between py-3 border-b border-solid border-gray-200 text-sm text-gray-600"
        >
          <div class="flex items-center">
            <template v-if="log.user">
              <HawkMembers
                :members="log.user"
                class="mr-1" type="badge"
              />
              {{ $t('changed progress') }}
            </template>
            <template v-else>
              {{ $t('Progress updated') }}
            </template>
            <span class="ml-1">{{ $t('from') }}</span>
            <span class="ml-1 font-semibold">{{ log.from }}%</span>
            <span class="ml-1">{{ $t('to') }}</span>
            <span class="ml-1 font-semibold">{{ log.to }}%</span>
          </div>
          <div>
            {{ $date(log.data_date, "DATE_MED") }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-[490px] min-w-[490px]">
      <hawk-loader v-if="state.is_loading" />
      <div v-else-if="!state.progress_logs_data?.length && !state.progress_history_data?.length" id="progress-history-chart" class="text-xs flex items-center justify-center h-32">
        {{ $t('Nothing to show') }}
      </div>
      <div v-else id="progress-history-chart" />
    </div>
  </div>
</template>
