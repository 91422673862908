<script setup>
import ActivityBlock from '~/tasks/pages/task-details-new/task-comments.vue/activity/task-activity-block.vue';

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
});

const activity_text = {
  TICKET_ASSIGNMENT_CHANGED: 'changed assignment',
  TICKET_UNASSIGNED: 'unassigned task from',
  TICKET_ASSIGNED: 'assigned task to',
  TICKET_PRIORITY_CHANGED: 'changed priority',
  DUE_DATE_CHANGED: 'changed duedate',
  ATTACHMENT_DELETED: 'deleted attachment',
  ATTACHMENT_UPLOADED: 'added attachment',
  MULTIPLE_ATTACHMENT_UPLOADED: 'added multiple attachments',
  TICKET_STATUS_CHANGED: 'changed status',
  CATEGORY_ADDED: 'set category',
  START_DATE_CHANGED: 'changed startdate',
  CATEGORY_REMOVED: 'removed category',
  CATEGORY_CHANGED: 'changed category',
  COMMENT_ADDED: 'commented',
  PARENT_REMOVED: 'removed parent task',
  SUBTASK_REMOVED: 'removed subtask',
  SUBTASK_STATUS_CHANGED: 'updated subtask status',
  DEPENDENCY_REMOVED: 'removed dependency',
  DEPENDENCY_ADDED: 'addeded dependency',
};
// Activities which has "from" null
const initial_activity_text = {
  TICKET_ASSIGNMENT_CHANGED: 'set assignment',
  CATEGORY_ADDED: 'set category',
  TICKET_PRIORITY_CHANGED: 'set priority',
  DUE_DATE_CHANGED: 'set duedate',
  TICKET_STATUS_CHANGED: 'set status',
  START_DATE_CHANGED: 'set startdate',
  PARENT_ADDED: 'added parent task',
  SUBTASK_STATUS_CHANGED: 'added subtask status',
  ASSIGNEE_ADDED: 'added assignees',
  ASSIGNEE_REMOVED: 'removed assignees',
  SUBTASK_ADDED: 'added subtask',
  WATCHERS_ADDED: 'added watchers',
  WATCHERS_REMOVED: 'removed watchers',
};

const has_meta_from = computed(() => {
  return props?.activity?.meta?.[Object.keys(props?.activity?.meta)[0]].hasOwnProperty('from') && !!props?.activity?.meta?.[Object.keys(props.activity?.meta)[0]]?.from;
});
</script>

<template>
  <div v-if="activity">
    <div class="flex justify-between items-center">
      <div>
        <div class="flex items-center gap-2 mb-1">
          <HawkMembers :members="activity?.actor?.split(':')?.[1]" type="badge" />
          <div class="flex">
            <div class="text-gray-600">
              <p v-if="has_meta_from">
                <span class="capitalize">
                  {{ activity_text[activity?.verb] }}
                </span>
                <span class="mx-2">
                  {{ $t('from') }}
                </span>
              </p>
              <span v-else class="capitalize">
                {{ initial_activity_text[activity?.verb] }}
              </span>
            </div>
            <div class="text-gray-600">
              <div v-if="activity?.verb === 'COMMENT_ADDED'">
                {{ $t('commented') }}
              </div>
              <ActivityBlock
                v-else
                :has_meta_from="has_meta_from"
                :activity_item="activity"
              />
            </div>
          </div>
        </div>
        <div v-if="activity?.verb === 'COMMENT_ADDED'" class="ml-6">
          <ActivityBlock
            :has_meta_from="has_meta_from"
            :activity_item="activity"
          />
        </div>
      </div>
      <div class="text-gray-600 flex-shrink-0">
        {{ $date(props?.activity?.createdAt, 'L_DATETIME_MED') }}
      </div>
    </div>
  </div>
</template>
