import { inject } from 'vue';
import { useRoute } from 'vue-router';

// other
import { useModal } from 'vue-final-modal';

// composables
import { useDMSSettingsStore } from '../store/dms-settings.store';
import { useDocumentPermissions } from '~/dms/composables/document-permissions.composable';

// stores
import { useDocumentStore } from '~/dms/store/document.store';

// components
import HawkShare from '~/common/components/organisms/hawk-share.vue';
import DocumentMoveForm from '~/dms/components/documents/forms/document-move-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DMSDocumentCustomFieldForm from '~/dms/components/documents/forms/dms-document-custom-field-form.vue';
import DocumentIntegrateForm from '~/third-party-widgets/components/integrations/dms-integrate-sharepoint-form.vue';

export function useDocumentBulkActions(table) {
  const route = useRoute();

  const document_permissions = useDocumentPermissions();
  const $t = inject('$t');

  const document_store = useDocumentStore();
  const dms_settings_store = useDMSSettingsStore();

  const $toast = inject('$toast');

  function calculateType({ item, bulk_items = [] }) {
    if (bulk_items.length > 0)
      return 'bulk';

    else
      return item.type;
  }

  const archive_warning_modal = useModal({
    component: HawkDeletePopup,
  });

  // ARCHIVE --> start
  async function archiveHandler({ item, is_archived_view, bulk_items, clear_selection }) {
    const uid = item.uid;
    const local_type = calculateType({ item, bulk_items });
    const archive_type = !is_archived_view ? 'archived' : 'unarchived';

    if (!bulk_items.length)
      try {
        await document_store.crud_documents({
          request: {
            body: {
              [`${local_type}s`]: {
                update: [
                  {
                    uid,
                    archive: !is_archived_view,
                  },
                ],
              },
            },
          },
        });

        if (clear_selection)
          clear_selection();
        // update sidebar
        await document_store.set_hierarchy(route.params.asset_id);
        // close details
        document_store.set_show_details(false);

        const itemType = local_type === 'file' ? 'File' : 'Folder';
        const title = $t(`${itemType} ${archive_type} successfully`);
        const text = $t(`${local_type} has been ${archive_type} successfully`);
        $toast({
          title,
          text: `${item.name} ${text}`,
          type: 'success',
          position: 'bottom-right',
        });
      }
      catch (e) {
        logger.error('ARCHIVE ITEM: ', e);
        $toast({
          title: $t('Something went wrong'),
          text: $t('Please try again later'),
          type: 'error',
          position: 'bottom-right',
        });
      }

    else
      try {
        const files = [];
        const folders = [];

        if (bulk_items.length)
          bulk_items.forEach((item) => {
            const item_payload = { uid: item.uid, archive: !is_archived_view };
            item.type === 'folder' ? folders.push(item_payload) : files.push(item_payload);
          });

        await document_store.crud_documents({
          request: {
            body: {
              files: {
                update: files,
              },
              folders: {
                update: folders,
              },
            },
          },
        });
        table?.clearSelect();
        if (clear_selection)
          clear_selection();
        // update sidebar
        await document_store.set_hierarchy(route.params.asset_id);

        const toast_text = $t(`files/folders have been ${archive_type} successfully`);
        $toast({
          title: $t(`Files/folders ${archive_type} successfully`),
          text: `${bulk_items.length} ${toast_text}`,
          type: 'success',
          position: 'bottom-right',
        });
      }
      catch (e) {
        logger.error('ITEMS ARCHIVE: ', e);
        $toast({
          title: $t('Something went wrong'),
          text: $t('Please try again later'),
          type: 'error',
          position: 'bottom-right',
        });
      }

    archive_warning_modal?.close();
  }
  async function itemArchive({ item = {}, is_archived_view = false, bulk_items = [], clear_selection = null }) {
    if (is_archived_view) {
      await archiveHandler({ item, is_archived_view, bulk_items, clear_selection });
    }
    else {
      archive_warning_modal.patchOptions({
        attrs: {
          onClose() {
            archive_warning_modal.close();
          },
          // eslint-disable-next-line no-undef
          header_icon: IconHawkDashboardPreviewWarning,
          header: $t('Archive item(s)'),
          content: $t('Are you sure you want to archive the item(s)? They will be moved to archived section and will be hidden.'),
          button_text: $t('Proceed'),
          button_color: 'primary',
          confirm: async () => {
            await archiveHandler({ item, is_archived_view, bulk_items, clear_selection });
          },
        },
      });
      archive_warning_modal.open();
    }
  }
  // ARCHIVE --> end

  // SHARE --> start
  async function onShare({ data = {}, item = {}, bulk_items = [] }) {
    const uid = item?.uid || '';
    const members = data.users;
    const teams = data.teams;
    const local_type = calculateType({ item, bulk_items });
    const is_private = data.global_access_level === 'no_access';
    try {
      const files = [];
      const folders = [];

      if (bulk_items.length)
        bulk_items.forEach((item) => {
          if (item.type === 'folder')
            folders.push({ uid: item.uid, members: [...members, ...teams] });

          else
            files.push({ uid: item.uid, members: [...members, ...teams] });
        });

      else

        if (local_type === 'file')
          files.push({ uid, members: [...members, ...teams] });
        else
          folders.push({ uid, members: [...members, ...teams] });

      await document_store.crud_documents({
        request: {
          body: {
            files: {
              update: files,
            },
            folders: {
              update: folders.map(folder => ({ ...folder, ...(!bulk_items.length ? { private: is_private } : {}) })),
            },
          },
        },
      });

      $toast({
        title: $t('Files/folders shared successfully'),
        text: `${bulk_items.length || 1} ${$t('files/folders have been shared successfully')}`,
        type: 'success',
        position: 'bottom-right',
      });

      if (bulk_items.length)
        table?.clearSelect();
    }
    catch (e) {
      logger.error('BULK SHARE: ', e);

      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });
    }
  }

  const share_modal = useModal({
    component: HawkShare,
    slots: {
      global_access: '',
    },
  });

  function getOrgAssetLabel(item) {
    const { asset } = item?.target_element || {};
    const keyword = asset ? 'asset' : 'organization';
    const label = $t(`Anyone in the ${keyword}`);
    return `<div class="text-sm font-semibold text-gray-700 my-2">${label}</div>`;
  }

  function itemShare({ item = {}, bulk_items = [] }) {
    const is_global_access_visible = !bulk_items.length ? (item?.type === 'folder' && !item?.parent && (item?.actions?.can_mark_folder_public)) : false;
    const is_editable = bulk_items.length
      ? document_permissions.checkDocumentBulkPermission({ permission: 'share', bulk_items })
      : document_permissions.checkDocumentPermission({ permission: 'share', item });
    share_modal.patchOptions({
      attrs: {
        onClose() {
          share_modal.close();
        },
        global_access_levels: [
          { name: 'no_access', label: $t('No access'), description: $t('Can not access unless shared with them') },
          { name: 'read', label: $t('Can view'), description: $t('Can view the items of the folder and subfolders') },
        ],
        access_levels: [
          { name: 'read', label: $t('Can view'), description: $t('Can view') },
          { name: 'write', label: $t('Can edit'), description: $t('Can view and edit') },
        ],
        is_global_access_visible,
        global_access_level: item?.private ? 'no_access' : 'read',
        is_modal: true,
        is_editable,
        share_action_text: $t('Share File'),
        teams: bulk_items.length > 0 ? [] : item?.teams,
        members: bulk_items.length > 0 ? [] : item?.members,
        get_share_data(data) {
          onShare({ data, item, bulk_items });
        },
      },
      slots: {
        global_access: getOrgAssetLabel(item),
      },
    });
    share_modal.open();
  }
  // SHARE --> end

  // MOVE --> start
  const files_move_form_modal = useModal({
    component: DocumentMoveForm,
  });

  function itemMove({ item = {}, bulk_items = [], element = {}, move_data = [], is_widget = false }) {
    const local_type = calculateType({ item, bulk_items });
    if (local_type === 'bulk')
      files_move_form_modal.patchOptions({
        attrs: {
          element,
          move_data,
          is_widget,
          table_instance: table,
          footer_buttons: [
            { type: 'outlined', text: 'Cancel', class: 'mr-2' },
            { color: 'primary', text: 'Move' },
          ],
          onClose() {
            files_move_form_modal.close();
          },
          bulk_items,
        },
      });

    else
      files_move_form_modal.patchOptions({
        attrs: {
          is_widget,
          move_data,
          footer_buttons: [
            { type: 'outlined', text: 'Cancel', class: 'mr-2' },
            { color: 'primary', text: 'Move' },
          ],
          onClose() {
            files_move_form_modal.close();
          },
          item,
        },
      });

    files_move_form_modal.open();
  }
  // MOVE --> end

  // INTEGRATE --> start
  const integrate_modal = useModal({
    component: DocumentIntegrateForm,
  });

  function itemIntegrate({ item = {}, bulk_items = [], element = {}, move_data = [], is_widget = false }) {
    integrate_modal.patchOptions({
      attrs: {
        is_widget,
        move_data,
        footer_buttons: [
          { type: 'outlined', text: 'Cancel', class: 'mr-2' },
          { color: 'primary', text: 'Move' },
        ],
        onClose() {
          integrate_modal.close();
        },
        item,
      },
    });

    integrate_modal.open();
  }
  // INTEGRATE --> end

  // RESTORE --> start
  async function itemRestore({ item = {}, bulk_items = [] }) {
    try {
      const local_type = calculateType({ item, bulk_items });

      if (bulk_items.length) {
        const payload = bulk_items.reduce((documents, item) => {
          if (item.type === 'file')
            documents.files.push(item.uid);
          if (item.type === 'folder')
            documents.folders.push(item.uid);
          return documents;
        }, { files: [], folders: [] });

        await document_store.crud_documents({
          request: {
            body: {
              files: {
                restore: payload.files,
              },
              folders: {
                restore: payload.folders,
              },
            },
            query: {
              deleted: true,
            },
          },
        });
        const toast_text = $t('files/folders have been restored successfully');
        $toast({
          title: $t('Files/folders restored successfully'),
          text: `${bulk_items.length} ${toast_text}`,
          type: 'success',
          position: 'bottom-right',
        });
        table?.clearSelect();
      }
      else {
        await document_store.crud_documents({
          request: {
            body: {
              [`${local_type}s`]: {
                restore: [item.uid],
              },
            },
            query: {
              deleted: true,
            },
          },
        });
        const itemType = local_type === 'file' ? 'File' : 'Folder';
        const title = $t(`${itemType} restored successfully`);
        const text = $t(`${itemType} has been restored successfully`);
        $toast({
          title,
          text: `${item.name} ${text}`,
          type: 'success',
          position: 'bottom-right',
        });
      }
    }
    catch (err) {
      handleErrors(err);
    }
  }

  function handleErrors(err) {
    logger.error(err);

    if (err.data.documents.files.errors.length || err.data.documents.folders.errors.length)
      if (
        err.data.documents.files.errors[0]?.message === 'File/Folder not found'
        || err.data.documents.folders.errors[0]?.message === 'File/Folder not found'
      )
        $toast({
          title: $t('Cannot restore document'),
          text: $t('Unable to find the location to restore. Make sure the path exists.'),
          type: 'error',
          position: 'bottom-right',
        });
      else
        $toast({
          title: $t('Something went wrong'),
          text: $t('Please try again later'),
          type: 'error',
          position: 'bottom-right',
        });
  }
  // RESTORE --> end

  // BULK UPDATE --> start
  const custom_field_modal = useModal({
    component: DMSDocumentCustomFieldForm,
  });

  async function updateCustomFields({ data = {}, item = {}, bulk_items = [] }) {
    const uid = item?.uid || '';
    const local_type = calculateType({ item, bulk_items });
    const field_value_payload = [];

    for (const uid in data.field_values)
      field_value_payload.push({ field: uid, value: data.field_values[uid] });
    try {
      const files = [];
      if (bulk_items.length)
        bulk_items.forEach((item) => {
          files.push({
            uid: item.uid,
            field_values: field_value_payload,
            status: data.document_status,
            tags: data?.tags,
            category: data?.category,
          });
        });
      else
        if (local_type === 'file')
          files.push({
            uid,
            field_values: field_value_payload,
            status: data.document_status,
            tags: data?.tags,
            category: data?.category,
          });

      await document_store.crud_documents({
        request: {
          body: {
            files: {
              update: files,
            },
          },
        },
      });

      $toast({
        title: 'Files custom fields updated successfully',
        text: `${bulk_items.length || 1} files custom fields have been updated successfully`,
        type: 'success',
        position: 'bottom-right',
      });

      if (bulk_items.length)
        table?.clearSelect();
    }
    catch (e) {
      logger.error('BULK SHARE: ', e);

      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });
    }
  }

  function itemBulkUpdate({ item = {}, bulk_items = [] }) {
    const custom_fields = dms_settings_store.custom_fields.filter(field => field.active);
    const document_status_options = dms_settings_store.document_status.filter(document_status => document_status.active);

    custom_field_modal.patchOptions({
      attrs: {
        header: `Update fields for ${bulk_items.length} document${bulk_items.length > 1 ? 's' : ''}`,
        custom_fields,
        document_status_options,
        has_active_transmittal: bulk_items.some(item => !!item?.active_transmittal?.uid),
        onClose() {
          custom_field_modal.close();
        },
        async save(data) {
          await updateCustomFields({ data, item, bulk_items });
        },
      },
    });
    custom_field_modal.open();
  }
  // BULK UPDATE --> end

  return {
    itemShare,
    itemArchive,
    itemMove,
    itemRestore,
    itemIntegrate,
    itemBulkUpdate,
    getOrgAssetLabel,
  };
}
