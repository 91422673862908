<script setup>
import { useElementSize } from '@vueuse/core';
import FilesListWidget from '~/dashboard/components/widgets/dms-widgets/files-list.widget.vue';
import TrasmittalListWidget from '~/dashboard/components/widgets/dms-widgets/transmittal-list.widget.vue';

const props = defineProps({
  component_type: {
    type: String,
    default: 'files',
  },
  name: {
    type: String,
    default: 'Untitled',
  },
  id: {},
  data: {
    type: Object,
  },
  popup_query: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);
const dms_widget_component = ref(null);
const { height } = useElementSize(dms_widget_component);
</script>

<template>
  <HawkModalTemplate :disable_footer="true" content_class="h-[80vh] rounded-lg w-[50vw]" @close="emit('close')">
    <template #header_left>
      <div class="flex flex-col justify-start">
        {{ props.name }}
      </div>
    </template>
    <template #default>
      <div ref="dms_widget_component">
        <div v-if="header_note" class="font-regular text-sm mb-2">
          <span class="font-semibold text-sm">
            {{ $t('Note') }}:
          </span>
          {{ header_note }}
        </div>
        <div v-if="component_type === 'files'">
          <FilesListWidget id="files-list-modal" :data="props.data" :query="popup_query" :content_height="height" class="h-[calc(80vh-121px)] scrollbar" />
        </div>
        <div v-else>
          <TrasmittalListWidget id="trasmittal-list-modal" :data="props.data" :query="popup_query" />
        </div>
      </div>
    </template>
  </HawkModalTemplate>
</template>
