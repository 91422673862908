<script setup>
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const props = defineProps({
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
});
const emit = defineEmits(['close']);
const store = useFormTemplateDetailStore();
const form_name = `Copy of ${store?.form_template_detail?.name}`;
const form = ref({});
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$" v-model="form" :display-errors="false"
      :presets="['']" :endpoint="props.submit"
      :columns="{
        default: { container: 6, wrapper: 12, label: 6 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 8, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Duplicate Form') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            :label="$t('Name')"
            name="text"
            size="md"
            rules="required"
            :default="`${form_name}`"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement submits size="sm" name="submit" :button-label="$t('Duplicate')" button-class="vf-btn-primary" />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
