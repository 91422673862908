<script setup>
import { computed } from '@vue/runtime-core';
import TrashIcon from '~icons/hawk/trash-three';
import PencilIcon from '~icons/hawk/pencil-one';

const props = defineProps({
  data: {
    type: Array,
    default() {
      return [];
    },
  },
  edit_reminders: {
    type: Function,
    default: null,
  },
  delete_reminders: {
    type: Function,
    default: null,
  },
  set_current_data_id: {
    type: Function,
    default: null,
  },
});

const emit = defineEmits(['currrentId']);

const $t = inject('$t');

function formatMemberCustom(custom) {
  let str = '';
  const arr = [];
  custom.forEach((item, idx) => {
    if (idx <= 2)
      arr.push(item.name);
  });
  str += arr.join(', ');

  if (custom.slice(3).length)
    str += ` ${$t('and')} ${custom.slice(3).length}  ${$t('others')}`;
  return str;
}
const formated_data = computed(() => {
  const formatKeys = [
    { prefix: '', suffix: '', key: 'custom', class: ['text-gray-900', 'font-semibold', 'capitalize', 'text-sm'] },
    { prefix: '', suffix: $t('days'), key: 'days', class: ['text-[#6927DA]', 'font-medium', 'text-sm'] },
    { prefix: '', suffix: $t('the'), key: 'when', class: ['text-[#000]', 'text-sm'] },
    { prefix: '', suffix: '', key: 'from', class: ['text-[#B54708]', 'font-medium', 'capitalize', 'text-sm'] },
    { prefix: $t('for'), suffix: '', key: '', class: [] },
    { prefix: '', suffix: $t('times'), key: 'count', class: ['text-[#6927DA]', 'font-medium', 'text-sm'] },
  ];
  props.data.forEach((item) => {
    const reminders = formatKeys.map((obj) => {
      if (obj.key === 'custom')
        return { value: `${formatMemberCustom(item?.custom)}`, class: obj.class };
      else
        return item[obj.key] ? { value: `${obj.prefix} ${item[obj.key]} ${obj.suffix}`, class: obj.class } : { value: `${obj.prefix}` };
    });
    const notify_text = [{ value: item.action === 'notify' ? $t('Notify') : $t('Assign and notify'), class: ['text-gray-900', 'text-sm'] }];
    item.reminders = [...notify_text, ...reminders];
  });
  return props.data;
});
function edit(idx) {
  emit('currrentId', idx);
  props.edit_reminders();
}
</script>

<template>
  <div v-for="(item, idx) in formated_data" :key="idx" class="flex pt-4">
    <div class="flex items-center">
      <span
        v-for="(obj, indx) in item.reminders " :key="indx"
        :class="obj.class"
        class="pr-1"
      >
        {{ obj.value }}
      </span>
    </div>
    <div class="flex pl-2">
      <PencilIcon class="cursor-pointer h-5 w-8" @click="edit(idx)" />
      <TrashIcon class="cursor-pointer h-5 w-8" @click="delete_reminders(idx)" />
    </div>
  </div>
</template>
