<script setup>
import InventoryWarehouseAlert from '~/inventory/components/inventory-warehouse/inventory-warehouse-alert.vue';
import InventoryWarehouseRecentTransactions from '~/inventory/components/inventory-warehouse/inventory-warehouse-recent-transactions.vue';

const props = defineProps({
  warehouse_id: {
    type: String,
    default: '',
  },
});
const state = reactive({

});
</script>

<template>
  <div class="grid gap-5 grid-cols-12">
    <InventoryWarehouseAlert class="col-span-6" :warehouse_id="warehouse_id" />
    <InventoryWarehouseRecentTransactions class="col-span-6" :warehouse_id="warehouse_id" />
  </div>
</template>
