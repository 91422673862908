export default function getTranslationMap($t) {
  return {
    wbs: $t('WBS'),
    status: $t('Status'),
    progress: $t('Progress'),
    total_duration: $t('Total duration'),
    total_slack: $t('Total slack'),
    free_slack: $t('Free slack'),
  };
}
