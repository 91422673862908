<template>
  <SelectElement>
    <template #single-label="{ value }">
      <div class="text-sm text-gray-900 flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
        <span class="truncate overflow-hidden w-40" :style="{ color: value.color }">
          {{ value.label }}
        </span>
      </div>
    </template>
    <template #option="{ option }">
      <div class="flex items-center">
        <span class="h-[10px] w-[10px] rounded-full mx-auto inline-block" :style="{ backgroundColor: option.color }" />
        <span class="ml-2">
          {{ option.label }}
        </span>
      </div>
    </template>
  </SelectElement>
</template>
