<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const router = useRouter();
const route = useRoute();

const auth_store = useAuthStore();
const common_store = useCommonStore();

function go_back() {
  router.push({ name: 'files-transmittals' });
}

const breadcrumb = {
  name: 'Transmittals',
  label: 'Transmittals',
  uid: 2,
  disabled: true,
};

const tabs = computed(() => {
  return route.params.asset_id
    ? [{
        name: common_store.get_asset(route.params.asset_id)?.name,
        label: common_store.get_asset(route.params.asset_id)?.name,
        uid: 1,
        on_click: go_back,
      }, breadcrumb]
    : [{
        name: common_store.get_organization(auth_store.current_organization?.uid)?.name,
        label: common_store.get_organization(auth_store.current_organization?.uid)?.name,
        uid: 1,
        on_click: go_back,

      }, breadcrumb];
});
</script>

<template>
  <div class="pb-2">
    <div class="flex justify-between w-full  px-4">
      <div>
        <hawk-breadcrumbs class="mb-2" :items="tabs" @crumbClicked="item => item.on_click()" />

        <div class="text-md text-gray-900 font-semibold">
          {{ $t("New Transmittal") }}
        </div>
        <div class="text-gray-900 text-sm">
          {{ $t("Create a new transmittal to request documents, reviews, or both from suppliers, clients, and internal team members.") }}
        </div>
      </div>
      <hawk-button class="cursor-pointer hover-group" type="text" @click="go_back">
        <icon-hawk-x-close class="text-blue-600 text-xl" />
      </hawk-button>
    </div>
  </div>
</template>
