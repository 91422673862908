<script setup>
import { onMounted, ref } from 'vue';
import { uniqBy } from 'lodash-es';
import { useTerraStore } from '../store/terra.store';
import { parseStringifiedObjectsInArray } from '~/common/utils/common.utils';

const props = defineProps({
  marker_location: {
    type: Object,
    default: () => null,
  },
});
const emit = defineEmits(['close', 'confirm']);
const array = ref([]);
const $t = inject('$t');
const form = ref({
  selection: '',
});
const terra_store = useTerraStore();
async function handleNoSelectedFeatures() {
  const projects = [];
  const turf = (await import('@turf/turf'));
  Object.values(terra_store.active_projects_data_map()).forEach((item) => {
    const bound = terra_store.active_projects_bounds(turf)[item.uid];
    if (bound)
      if (turf.booleanIntersects(turf.point([props.marker_location.lngLat.lng, props.marker_location.lngLat.lat]), bound))
        projects.push(
          {
            label: item.name,
            value: {
              data: terra_store.projects_details_map[item.uid],
              type: 'project',
              uid: terra_store.projects_details_map[item.uid].uid,
            },
          });
  });
  return projects;
}
async function features_or_projects() {
  if (props.marker_location) {
    let point = props.marker_location.point;
    if (!point)
      point = terra_store.map.project([props.marker_location.lngLat.lng, props.marker_location.lngLat.lat]);
    let features = [];

    features = terra_store.map.queryRenderedFeatures(point, {
      layers: ['polygon_feature_layer', 'linestring_feature_layer', 'point_feature_layer'],
    });

    features = parseStringifiedObjectsInArray(uniqBy(features, f => f.properties.uid)).map(item => ({
      geometry: item.geometry || item._geometry,
      id: item.id,
      properties: item.properties,
      type: item.type,
    }));

    if (features.length) {
      array.value = features.map(item => ({
        label: item.properties.name || 'Untitled',
        value: { data: item.properties, type: 'feature', uid: item.properties.uid },
      }));
      return array.value;
    }
    else {
      const projects = await handleNoSelectedFeatures();

      array.value = projects;
      return array.value;
    }
  }
  array.value = [];
  return array.value;
}
function confirmLocation() {
  logger.log(form);
  const selection = form.value.selection;
  const target_element = selection.data.element;
  let project_details;
  let feature_details;
  if (selection.type === 'feature') {
    project_details = terra_store.projects_details_map[selection.data.project];
    feature_details = selection.data;
  }
  else if (selection.type === 'project') {
    project_details = terra_store.projects_details_map[selection.data.uid];
  }

  emit('confirm', { target_element, project_details, feature_details });
}
onMounted(async () => {
  const items_array = await features_or_projects();
  if (items_array.length) {
    logger.log(items_array);
    form.value.selection = items_array[0].value;
  }
});
</script>

<template>
  <div class="w-[300px] bg-white rounded-xl">
    <div class="flex items-center justify-between pt-[8px] pb-[8px] pl-[16px] pr-[16px] border-b">
      <div class="text-[16px] font-semibold">
        {{ $t('Confirm location') }}
      </div>
      <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center" @click="emit('close')">
        <IconHawkXClose />
      </div>
    </div>

    <Vueform v-model="form" :sync="true" size="sm" class="px-3 mt-3">
      <SelectElement

        name="selection"
        open-direction="bottom"
        :items="array"
        :native="false"
        :can-clear="false"
        autocomplete="off"
        label=""
      />
    </Vueform>
    <div class="flex justify-end p-3">
      <hawk-button class="mr-4" type="outlined" @click="$emit('close')">
        {{ $t('Cancel') }}
      </hawk-button>
      <hawk-button :disabled="!form.selection" class="" button-class="w-full bg-blue-600" @click="confirmLocation">
        {{ $t('Confirm') }}
      </hawk-button>
    </div>
  </div>
</template>
