<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useElementSize } from '@vueuse/core';
import { isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useFormsStore } from '~/forms/store/forms.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import FamList from '~/forms-as-module/pages/fam-list/fam-list.vue';
import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  extra_query: {
    type: Object,
    default: () => {},
  },
});

const dashboard_forms_store = useDashboardFormsStore();

const authStore = useAuthStore();
const dashboard_store = useDashboardStore();

const fam_view_store_id = `dashboard_widget_view_${dashboard_store.widget_uid || props.data.uid || 'preview'}`;
const { setCustomViews } = useFamCustomView({
  feature: 'widget_view',
  fam_view_store_id,
});

const route = useRoute();
const router = useRouter();
const current_organization = authStore.current_organization;
const is_loading = ref(false);
const prevent_watcher = ref(false);
const forceUpdate = ref(0);

const form_widget_header = ref(null);
const { height } = useElementSize(form_widget_header);

const forms_store = useFormsStore(`forms-${props.id}`);
const {
  forms_v2_filters,
} = storeToRefs(dashboard_forms_store);
const columns_config = computed(() => {
  const columns = [
    ['name', 'Form'],
    ['members', 'Assignees'],
    ['due_date', 'Due Date'],
    ['status', 'Status'],
  ];

  const widths_map = props.data?.data?.columns_widths || {};

  return columns.map((col) => {
    const col_id = col[0];

    const column = {
      accessorKey: col_id,
      id: col_id,
      header: col[1],
    };

    if (widths_map[col_id])
      column.size = widths_map[col_id];

    return column;
  });
});

function openFormDetails(row) {
  router.push({ query: { ...route.query, form: btoa(JSON.stringify({ form_uid: row.uid, store_key: `forms-${props.id}` })) } });
}
async function getData({ pagination_state }) {
  is_loading.value = true;
  const query = {
    page_number: pagination_state?.pageIndex || 1,
    page_size: pagination_state?.pageSize || 10,
    asset_uid: props.data?.data?.asset_id || [],
    organization: current_organization.uid,
    all_access: true,
    ...(dashboard_store.scope !== 'global' && dashboard_store.scope !== 'organization'
      ? {
          parent_form_uid: props.data?.data?.form,
        }
      : {}),
    scope: props.data?.data?.scope ? 'user' : 'organization',
    is_child: true,
    ...(props.extra_query
      ? props.extra_query
      : {
          only_metrics: false,
          status: 'published',
        }),
    ...(
      Object.values(props?.data?.data?.filters?.filters || {}).length
        ? props?.data?.data?.filters?.filters
        : {}
    ),

  };
  await forms_store.set_forms({ query });
  is_loading.value = false;
}

function getHeight() {
  if (props.id === 'preview')
    return ((props.data.h || 22) * 20) - 90;
  else if (!props.id && window.innerWidth < 1300)
  // fix for form list drilldown on smaller screens
    return 360;
  else
    return (props?.data?.h * 24.5) - (height.value + 20);
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props?.data?.data?.name,
    renderType: 'forms-list',
    dimensions: {
      x: props?.value?.x?.label,
      y: props?.value?.y?.label,
    },
    dataSource: {
      dataset: forms_store.forms,
      total: forms_store.total_form_count,
      dashboard_index: props.data.i,
    },
  });
}

function getMembersAndTeams(members = [], teams = []) {
  return [...members.map(member => member.uid), ...teams.map(team => team.uid)];
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

watch(() => props.data, async (new_val, old_val) => {
  if (!old_val || (!isEqual(new_val, old_val))) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getData({});
    forceUpdate.value++;
    if (props.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });

onMounted(() => {
  setCustomViews(props.data?.data?.tabs || []);
  setTimeout(async () => {
    await getData({});
    if (props.id !== 'preview')
      updatePrintMap();
  }, 100);
});
</script>

<template>
  <div>
    <div ref="form_widget_header">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
    </div>
    <a class="block w-full scrollbar" :style="{ height: `${getHeight()}px` }">
      <FamList
        :key="props.data?.data?.tabs"
        :is_widget="true"
        :fam_view_store_id="fam_view_store_id"
        :fam_overview_display_filters_ref="dashboard_forms_store.fam_overview_display_filters_ref"
        :views="props.data?.data?.tabs"
        :table_options="{
          no_table_wrapper: true,
          wrapper_class: `height:${getHeight()}px`,
        }"
      />
    </a>
  </div>
</template>
