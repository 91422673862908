import { defineStore } from 'pinia';
import { keyBy } from 'lodash-es';
import { $get_sorted_dates } from '~/forms/utils/forms-due-date.util';

export function useFormSubmission(key) {
  return defineStore(key || 'forms-submission', {
    state: () => ({
      forms_map: {},
      total_submission_count: 0,
      form_templates_map: {},
    }),
    getters: {
      forms: (state) => {
        return Object.values(state?.forms_map);
      },
      submissions(state) {
        const date_fn = this.$date;
        return Object.values(state.forms_map).map((submission) => {
          const sorted_dates = $get_sorted_dates(date_fn(submission.submitted_at, 'L_DATETIME_MED'));
          return { ...submission, group_by_dates: date_fn(submission.submitted_at, 'L_DATETIME_MED'), group_by: sorted_dates };
        });
      },
      get_parent_form: state => (uid) => {
        return state.parent_form_map[uid];
      },
    },
    actions: {
      async set_submitted_forms(req) {
        try {
          const { data, headers } = await this.$services.forms.getAll({
            query: {
              ...req?.query || {},
              asset_uid: this.$router.currentRoute.value.params.asset_id || null,
            },
            body: {
              ...req.body,
            },
            attribute: req?.attribute,
          });
          this.forms_map = data.forms;
          this.form_templates = data.templates;
          this.form_templates_map = keyBy(data.templates, 'uid');
          this.total_submission_count = headers['x-total-count'];
        }
        catch (error) {
          logger.error(error);
          this.$toast({ text: 'Failed to set submitted forms!', type: 'error' });
          return error;
        }
      },
    },
  })();
}
