<script setup>
import { useModal } from 'vue-final-modal';
import HawkFieldsSelector from '~/common/components/organisms/hawk-fields-selector/hawk-fields-selector.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const dashboard_store = useDashboardStore();

const $t = inject('$t');
const dms_settings_store = useDMSSettingsStore();

const dashboard_dms_store = useDashboardDMSStore();

const dms_configuration = computed(() => dashboard_dms_store.dms_configuration);

const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => !field.deleted && field.active));

const all_columns = computed(() => {
  const custom_fields_columns = custom_fields.value.map((element) => {
    return {
      uid: element.uid,
      id: element.uid,
      name: element.label,
      label: element.label,
      accessorKey: element.uid,
    };
  });

  return [...dashboard_dms_store.standard_columns, ...custom_fields_columns];
});
function handleColumnsConfiguration() {
  const configure_columns_popup = useModal({
    component: HawkFieldsSelector,
    attrs: {
      min_selected_items: 1,
      items: all_columns.value,
      selected_items: dms_configuration.value.columns,
      onClose() {
        configure_columns_popup.close();
      },
      texts: {
        heading: 'Customize columns',
        left_heading: 'Hidden Columns',
        right_heading: 'Visible Columns',
      },
      has_field_pin_option: true,
      close_on_save: true,
      update: (columns) => {
        dashboard_dms_store.update_dms_configuration({ ...dashboard_dms_store.dms_configuration, columns });
        dashboard_store.set_widget_configuration({
          ...dms_configuration.value,
        });
      },
    },
  });
  configure_columns_popup.open();
}
</script>

<template>
  <div>
    <div v-for="col in dms_configuration?.columns" :key="col.key" class="flex items-center mb-2 px-2 h-[36px] border border-gray-300 rounded-lg">
      {{ col.label }}
    </div>

    <hawk-button
      type="outlined"
      size="xs"
      @click="handleColumnsConfiguration"
    >
      {{ $t('Choose columns') }}
    </hawk-button>
  </div>
</template>
