<script>
export default {
  props: {
    status: {
      type: Number,
      default: 5,
    },
  },
  setup() {
    const status_values = {
      1: {
        name: 'Pending',
        label: 'Pending',
        value: 1,
      },
      2: {
        name: 'In-Progress',
        label: 'In Progress',
        value: 2,
      },
      3: {
        name: 'Resolved',
        label: 'Resolved',
        value: 3,
      },
      4: {
        name: 'Closed',
        label: 'Closed',
        value: 4,
      },
      5: {
        name: 'Rejected',
        label: 'Rejected',
        value: 5,
      },
    };

    return { status_values };
  },
};
</script>

<template>
  <div :class="`text-task-status-${status} bg-task-status_bg-${status}`" class="content-center rounded-full px-2 py-1 inline-flex text-xs whitespace-nowrap font-medium">
    {{ status_values[status].label }}
  </div>
</template>
