<script setup>
import { onMounted } from 'vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import FormTemplateDetailDropdown from '~/forms/components/form-template-detail/form-template-detail-dropdown.vue';
import FormTemplateDetailHeader from '~/forms/components/form-template-detail/form-template-detail-header.vue';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
});

const { $t, auth_store, route, router } = useCommonImports();

const form_template_detail_store = useFormTemplateDetailStore();
provide('form_template_detail_store', form_template_detail_store);
const is_loading = ref(true);

async function getData() {
  if (route.params.template_uid) {
    is_loading.value = true;
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    is_loading.value = false;
  }
}
onMounted(async () => {
  await getData();
});
watch(() => props.asset_id, (val, old_val) => {
  if (val && old_val && val !== old_val)
    router.push({ name: 'form-templates', params: { asset_id: route.params.asset_id }, query: { active: 'published' } });
});

watch(() => route.params.template_uid, () => {
  getData();
});

// Template detail breadcrumb navigation
const is_form_setting_page = computed(() => route.matched.findIndex(route => route.name === 'form-template-detail-settings') >= 0);
const is_form_builder_page = computed(() => route.matched.findIndex(route => route.name === 'form-template-detail-builder') >= 0);
const active_template_detail_tab = computed(() => (is_form_setting_page.value || is_form_builder_page.value) ? '4' : '3');
const settings_tab = [{ uid: '4', label: $t('Settings'), to: { name: 'form-template-detail-settings' } }];
const builder_tab = [{ uid: '4', label: $t('Builder'), to: { name: 'form-template-detail-builder' } }];
const form_template_detail_tabs = computed(() => [{ uid: '1', label: $t('Forms'), to: { name: 'form-overview-list' } }, { uid: '2', label: $t('Templates'), to: { name: 'form-templates' } }, { uid: '3', label: form_template_detail_store.form_template_detail.name, to: { name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid, asset_id: route.params.asset_id } } }]);
const final_tabs = computed(() => [...form_template_detail_tabs.value, ...(is_form_setting_page.value ? settings_tab : []), ...(is_form_builder_page.value ? builder_tab : [])]);
</script>

<template>
  <div class="justify-center py-4 sm:px-6 lg:px-4">
    <hawk-loader v-if="is_loading" />
    <div v-else class="flex min-h-full flex-col">
      <div v-if="$route.name !== 'form-template-detail-workflow-builder'" class="flex justify-between items-start">
        <FormTemplateDetailHeader>
          <div>
            <hawk-breadcrumbs :items="final_tabs" :active_item="active_template_detail_tab" />
          </div>
        </FormTemplateDetailHeader>
        <template v-if="!is_form_setting_page">
          <FormTemplateDetailDropdown :key="is_form_builder_page" :is_form_builder_page="is_form_builder_page" class="relative z-[30]" />
        </template>
      </div>
      <router-view />
    </div>
  </div>
</template>
