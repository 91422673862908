<script setup>
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

const props = defineProps({
  user: { type: Object, default: () => ({}) },
});

const emit = defineEmits(['open', 'close', 'update', 'loading']);

const { auth_store, $t, route, router, common_store, $toast } = useCommonImports();
const is_modify_users = computed(() => auth_store.check_permission('modify_users', route.params.asset_id));
const logged_in_user_details = computed(() => auth_store.logged_in_user_details);

function viewDetails(uid) {
  router.push({ name: 'account-settings-user-details', params: { id: uid } });
}

const menu_items = computed(() => {
  const { is_manager, active, uid, organization, admin_assets } = props.user;
  const user_current_organization = (organization === auth_store?.current_organization?.uid);
  const logged_in_user_details = auth_store.logged_in_user_details;
  const is_owner_or_internal = logged_in_user_details.is_owner || (logged_in_user_details.is_internal && is_modify_users.value);

  const menu = [];
  if (route.name !== 'account-settings-user-details')
    menu.push({ label: $t('View details'), on_click: () => viewDetails(uid) });

  if (is_modify_users.value && user_current_organization)
    menu.push({
      label: active ? $t('Deactivate') : $t('Activate'),
      on_click: () => toggleMemberActivation(active),
    });

  if (is_owner_or_internal && user_current_organization && active)
    if (!is_manager)
      menu.push({ label: 'Convert to global admin', on_click: () => convertToAdmin() });
    else
      menu.push({ label: 'Convert to member', on_click: () => convertToMember() });

  if (route.params.asset_id && !is_manager && ((admin_assets?.length && (logged_in_user_details.is_manager || is_owner_or_internal)) || is_modify_users.value))
    menu.push({ label: 'Remove', on_click: () => removeUserFromAsset() });
  return menu;
});

async function convertToAdmin() {
  await updateMemberAndStore(
    'update',
    {
      id: props.user.uid,
      body: { user_type: 'admin' },
      attribute: 'convert-to-admin',
    },
    $t('Your changes to the user have been successfully saved'),
    $t('User updated'),
  );
}

async function convertToMember() {
  await updateMemberAndStore(
    'update',
    {
      id: props.user.uid,
      body: { user_type: 'member' },
      attribute: 'convert-to-member',
    },
    $t('Your changes to the user have been successfully saved'),
    $t('User updated'),
  );
}

async function toggleMemberActivation(active) {
  updateMemberAndStore(
    'update',
    {
      id: props.user.uid,
      attribute: `lifecycle/${active ? 'deactivate' : 'activate'}`,
      body: { sendEmail: false },
    },
    active ? $t('The user has been successfully deactivated') : $t('The user has been successfully activated'),
    active ? $t('User deactivated') : $t('User activated'),
  );
}

async function updateMemberAndStore(type, params, message = '', title = 'Success') {
  try {
    emit('loading', true);
    const { data } = await common_store.update_data({
      type,
      id: params.id,
      data: params.body,
      attribute: params.attribute || '',
      service: 'users',
      append_data: true,
      state_prop: 'users_map',
      update_state: true,
    });

    if (data) {
      await common_store.update_global_data({
        users: true,
        internal_users: true,
        internal_users_uids: true,
      });
      $toast({
        title,
        text: message,
        type: 'success',
      });
      emit('loading', false);
      emit('update');
    }
    else {
      throw new Error(data);
    }
  }
  catch (error) {
    emit('loading', false);
    $toast({
      title: $t('Failed to update user'),
      text: $t('Unable to update the user. Please try again'),
      type: 'error',
    });
  }
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

function removeUserFromAsset() {
  patchOptions(
    {
      attrs: {
        header: $t('Remove user'),
        button_text: $t('Remove'),
        content: $t('Are you sure you want to remove? This action cannot be undone.'),
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            emit('loading', true);
            const { data } = await common_store.update_data({
              type: 'patch_update',
              id: route.params.asset_id,
              data: { asset: { members: { remove: [props.user.uid] } } },
              service: 'assets',
              append_data: false,
              state_prop: 'assets_map',
              update_state: false,
            });
            if (data?.asset) {
              await common_store.update_global_data({ assets: true, users: true });
              emit('loading', false);
              emit('update');
              $toast({
                title: $t('User removed'),
                text: $t('User removed successfully from the asset'),
                type: 'success',
              });
            }
            else {
              $toast({
                title: $t('Error'),
                text: $t('Failed to remove user from the asset'),
                type: 'error',
              });
            }
          }
          catch (error) {
            console.error(error);
            emit('loading', false);
          }
        },
      },
    },
  );
  openDeletePopup();
}
</script>

<template>
  <HawkMenu
    v-if="menu_items?.length"
    :items="menu_items"
    additional_trigger_classes="!ring-0"
    position="fixed"
    @click.stop
    @open="emit('open')"
    @close="emit('close')"
  >
    <template #trigger>
      <slot name="trigger" />
    </template>
  </HawkMenu>
</template>
