<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    display_onboarding: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <!-- add this class for sticky header - sticky top-16 z-10 -->
  <div class="block items-center justify-between gap-10 space-y-4 p-4 md:flex md:space-y-0">
    <div class="flex items-center justify-between md:justify-start">
      <slot name="back" />
      <div v-if="$slots.title" class="mr-4 text-lg font-semibold flex items-center gap-2">
        <slot name="title" />
      </div>
      <div v-else-if="title" class="mr-4 text-lg font-semibold flex items-center gap-2">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <slot name="left" />
    </div>
    <div class="flex items-center gap-3">
      <HawkOnboardingInfo v-if="display_onboarding" :on_boarding_for="display_onboarding?.for" />
      <div v-if="$slots.right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>
