<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import useEmitter from '~/common/composables/useEmitter';
import NotesEditor from '~/terra/molecules/note-editor.vue';

const props = defineProps({
  attachments: {
    type: Array,
    default: () => ([]),
  },
  attachment_config: {
    type: Object,
    default: () => ({}),
  },
  attached_to: {
    type: Object,
    default: () => ({}),
  },
  image_dimensions: {
    type: Array,
    default: () => ([240, 160]),
  },
  on_update: {
    type: Function,
    default: () => {},
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});
const { $t, $toast } = useCommonImports();
const emitter = useEmitter();
const state = reactive({
  select_all_attachments: 'unchecked',
  selected_count: 0,
  selected_attachments: {},
  is_downloading: false,
});

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      closeDeletePopup();
    },
  },
});

function attachmentDeleteHandler(file) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Attachment'),
        content: `Are you sure you want to delete ${file.file_name}? This action cannot be undone.`,
        confirm: async () => {
          await props.on_update({ attachments: { remove: [file.uid] } });
          closeDeletePopup();
        },
      },
    },
  );
  openDeletePopup();
}
function attachmentDownloadHandler(file) {
  window.open(file.url, '_blank');
}
async function downloadAllFiles() {
  const files = (props.attachments || []).reduce((acc, attachment) => {
    const url = attachment.url || attachment.service.url;
    if (url && (!state.selected_count || state.selected_attachments?.[attachment.uid])) {
      acc.push({
        uid: attachment.uid,
        name: attachment.file_name,
        size: attachment.file_size,
        url,
      });
    }
    return acc;
  }, []);
  if (files.length > 1) {
    emitter.emit('download_zip', { files, name: new Date().toLocaleDateString('en-In'), is_zip_item: true });
  }
  else if (files.length === 1) {
    attachmentDownloadHandler(files[0]);
  }
  else {
    $toast({
      text: $t('No attachments for this feature'),
      type: 'warning',
      position: 'bottom-right',
    });
  }
}
function updateSelectAllStatus(attachment = null) {
  if (attachment === null) {
    state.select_all_attachments = state.select_all_attachments === 'checked' ? 'unchecked' : 'checked';
    const is_all_selected = (state.select_all_attachments === 'checked');
    props.attachments.forEach((val) => {
      state.selected_attachments[val.uid] = is_all_selected;
    });
    state.selected_count = is_all_selected ? props.attachments?.length : 0;
  }
  else {
    state.selected_attachments[attachment.uid] = !state.selected_attachments[attachment.uid];
    const selected_attachments = Object.keys(state.selected_attachments).filter(uid => state.selected_attachments[uid]);
    if (selected_attachments.length === props.attachments.length)
      state.select_all_attachments = 'checked';
    else if (selected_attachments.length > 0)
      state.select_all_attachments = 'intermediate';
    else
      state.select_all_attachments = 'unchecked';
    state.selected_count = selected_attachments.length;
  }
}
</script>

<template>
  <div class="w-full">
    <div v-if="attachments?.length">
      <div v-if="!options.is_sm" class="mt-4 mb-1">
        <div class="flex items-center justify-between mb-2">
          <HawkCheckbox :key="state.select_all_attachments" class="text-sm font-gray-900 ml-0.25" :model-value="state.select_all_attachments" tristate @click="updateSelectAllStatus()">
            {{ $t('Select all') }} <span v-if="state.selected_count">({{ state.selected_count }}  {{ $t('selected') }}) </span>
          </HawkCheckbox>
          <HawkButton type="text" class="font-semibold" :loading="state.is_downloading" @click="downloadAllFiles()">
            <IconHawkDownloadTwo class="w-4.5 h-4.5" />
            {{ $t('Download') }}
          </HawkButton>
        </div>
      </div>
      <hawk-attachments-grid
        :items="attachments"
        :image_dimensions="image_dimensions"
        :can_delete="false"
        :can_download="!options?.is_sm ?? true"
        :show_delete="!options?.is_sm ?? true"
        @delete="attachmentDeleteHandler"
        @view="options?.attachmentHandler?.('top')"
        @close="options?.attachmentHandler"
      >
        <template
          v-for="attachment in attachments" :key="attachment.uid"
          #[`attachment-details-left-content-${attachment.uid}`]
        >
          <div v-if="!options.is_sm">
            <HawkCheckbox :id="attachment.uid" :model-value="Boolean(state.selected_attachments[attachment.uid])" @input="updateSelectAllStatus(attachment)" />
          </div>
        </template>
      </hawk-attachments-grid>
    </div>
    <NotesEditor
      v-if="options?.show_editor ?? true"
      class="px-0 mt-2"
      :attachment_config="attachment_config"
      :on_send="on_update"
    />
  </div>
</template>
