<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { extractAttrFromXML } from '~/plans/utils/helper';
import { useAnnotationsStore } from '~/plans/store/annotations.store';
import commentDisplay from '~/plans/components/comment-display.vue';

const props = defineProps({
  note: {
    type: Object,
    required: true,
  },
  annotation: {
    type: Object,
    required: true,
  },
  export_annotations: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['deleteAnnot', 'closeContext']);

const route = useRoute();
const annotations_store = useAnnotationsStore();

const form = ref({});
const is_note_clicked = computed(() => route.query.note);
const is_new_note = computed(() => is_note_clicked.value && route.query.note.includes('new_'));

async function onSaveHandler() {
  if (form.value.note === props.annotation.getContents()) {
    emit('closeContext');
    return;
  }

  props.annotation.setContents(form.value.note);
  props.annotation.setCustomData('uid', props.annotation.Id);

  const annotationData = await props.export_annotations([props.annotation], {
    widgets: false,
    links: false,
    fields: false,
    generateInlineAppearances: false,
  }, true);

  const annot_map = {};

  annotationData.forEach((annot_xml) => {
    const annot_id = extractAttrFromXML(annot_xml, 'name');
    annot_map[annot_id] = annot_xml;
  });

  const annotations_body = [props.annotation].map(annotation =>
    ({
      uid: annotations_store.annotations_map[annotation.Id].uid,
      annotationData: annot_map[annotation.Id],
    }),
  );

  annotations_store.update_annotations(annotations_body);
  emit('closeContext');
}
</script>

<template>
  <div class="min-w-[18rem] text-xs border rounded-md shadow-md p-2 bg-white">
    <Vueform
      v-model="form"
      sync
      size="sm"
      class="w-full"
      :display-errors="false"
      @submit="onSaveHandler"
    >
      <div class="col-span-12">
        <!-- Header section -->
        <comment-display
          :uid="annotation.Author"
          :time_stamp="annotation.DateCreated"
        >
          <template #comment-menu>
            <hawk-menu
              v-if="is_note_clicked || is_new_note"
              class="ml-auto mr-0"
              :items="[
                {
                  name: 'Delete',
                  value: 'delete',
                  on_click: async () => {
                    emit('deleteAnnot');
                  },
                },
              ]"
              position="fixed"
              additional_trigger_classes="ring-transparent"
            >
              <template #trigger>
                <IconHawkDotsVertical class="cursor-pointer text-gray-500" />
              </template>
              <template #item="{ item }">
                <span class="text-sm w-40">
                  {{ $t(item.name) }}
                </span>
              </template>
            </hawk-menu>
          </template>
        </comment-display>
        <!-- Editable section -->
        <div v-if="is_new_note || is_note_clicked">
          <!-- Body section -->
          <div class="mt-4">
            <TextareaElement
              rules="required"
              name="note"
              :autogrow="false"
              placeholder="Enter Note"
              :add-classes="{
                TextareaElement: {
                  input: ['scrollbar'],
                },
              }"
              @mounted="(el$) => {
                el$.focus();
                el$.load(annotation.getContents() ?? null)
              }"
            />
          </div>
          <!-- Footer section -->
          <div class="flex justify-end gap-2 mt-2">
            <HawkButton
              color="primary" type="outlined"
              @click="emit('closeContext')"
            >
              {{ $t('Cancel') }}
            </HawkButton>
            <ButtonElement submits name="save_note" :button-label="$t('Save')" button-class="vf-btn-primary" />
          </div>
        </div>
        <div v-else class="p-2 mt-2">
          {{ annotation.getContents() }}
        </div>
      </div>
    </Vueform>
  </div>
</template>
