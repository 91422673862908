<script setup>
import { storeToRefs } from 'pinia';
import SmGraph from '~/system-model/components/sm-graph/sm-graph.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const system_model_store = useSystemModelStore();
const { route, router } = useCommonImports();
const { active_component, active_component_hierarchy } = storeToRefs(system_model_store);

async function dblClickComponentHandler(component) {
  await system_model_store.set_active_component({ ...component, only_update: true });
  await system_model_store.set_instances();
  const instance = system_model_store.component_instances[0];
  system_model_store.set_active_instance(instance);
  router.replace({ query: { ...route.query, instance: instance.uid, component: component.uid } });
}
async function clickComponentHandler(component) {
  system_model_store.set_sidebar_force_render(false);
  system_model_store.set_active_component(component);
  await new Promise(resolve => setTimeout(resolve, 10));
  router.replace({ query: { ...route.query, component: component.uid } });
  system_model_store.set_sidebar_force_render(true);
}
</script>

<template>
  <SmGraph
    v-if="active_component_hierarchy.length"
    :items="active_component_hierarchy"
    :active="active_component"
    @element-dbl-clicked="dblClickComponentHandler"
    @element-clicked="clickComponentHandler"
  />
</template>
