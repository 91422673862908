<script setup>
import { useRoute } from 'vue-router';
import { useFormsStore } from '~/forms/store/forms.store';

const $t = inject('$t');

const route = useRoute();
const forms_store = useFormsStore('form');
const tabs = computed(() => [{
  uid: 'form',
  label: $t('Form'),
  to: { name: 'form-overview-forms-list', params: { asset_id: route.params.asset_id } },
  count: forms_store?.templates.length,
}, {
  uid: 'submission',
  label: $t('Submissions'),
  to: { name: 'form-overview-submission-list', params: { asset_id: route.params.asset_id } },
},
]);

const active_tab = computed(() => route.name === 'form-overview-submission-list' ? 'submission' : 'form');
</script>

<template>
  <div>
    <div class="my-5">
      <hawk-tabs :tabs="tabs" :active_item="active_tab" />
    </div>
    <router-view />
  </div>
</template>
