<script setup>
import { useModal } from 'vue-final-modal';
import { reactive } from 'vue';
import { cloneDeep, keyBy } from 'lodash-es';
import { createUppyInstance } from '~/common/utils/uppy.util.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DocumentUploadForm from '~/dms/components/documents/forms/document-upload-form.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  note_details: {
    type: Object,
    default: null,
  },
  attachment_config: {
    type: Object,
  },
  on_send: {
    type: Function,
    required: true,
  },
});

const emits = defineEmits(['cancel']);

const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      closeDeletePopup();
    },
  },
});
const { open: openDocumentUploadFormPopup, close: closeDocumentUploadFormPopup, patchOptions: patchDocumentUploadForm } = useModal({
  component: DocumentUploadForm,
  attrs: {
    onClose() {
      closeDocumentUploadFormPopup();
    },
  },
});

const state = reactive({
  note: '',
  attachments: [],
  files_to_attach: [],
  uppy: createUppyInstance({
    attachment_config: props.attachment_config,
    complete_cb: result => onPastedFilesUploadComplete(result),
  }),
  is_sending: false,
  is_editor_focussed: false,
});

const is_send_allowed = computed(() => {
  return !state.files_to_attach.some(file => file?.upload_pending)
    && (state.files_to_attach.length || state.note.replaceAll('<br>', '').replaceAll(/<p>\s*<\/p>/gmi, '').trim().length);
});

function addFilesToAttach(files) {
  state.files_to_attach.push(...files);
}

function onRemoveAttachment(uid) {
  state.files_to_attach = state.files_to_attach.filter(att => att.uid !== uid);
}

function onPastedFilesUploadComplete(result) {
  const successful_uids = result.successful.map(thing => thing.data.uid);
  const failed_uids = result.failed.map(thing => thing.data.uid);
  successful_uids.forEach(onRemoveAttachment);
  failed_uids.forEach(onRemoveAttachment);
  const files = result.successful.map((res) => {
    res.data.upload_pending = false;
    return res.data;
  });
  addFilesToAttach(files);
}

function onFilesPaste(files) {
  try {
    const updated_files = files.map((file) => {
      file.uid = file.uuid;
      return file;
    });
    state.uppy.addFiles(updated_files);
    addFilesToAttach(updated_files);
  }
  catch (error) {
    logger.error(error);
  }
}

function attachmentPopup() {
  patchDocumentUploadForm({
    attrs: {
      fields_list: [{ name: 'File', component: 'MultifileElement', auto: false }],
      attachment_config: props.attachment_config,
      is_single_file: false,
      submit: (form) => {
        const files = cloneDeep(form.data?.File) || [];
        files.forEach(file => file.uid = crypto.randomUUID());
        if (files.length)
          addFilesToAttach(files);
        closeDocumentUploadFormPopup();
      },
    },
  });
  openDocumentUploadFormPopup();
}

function getPayload() {
  const notes = {};
  const attachments = {};
  const now = new Date().toISOString();

  const note_data = {
    uid: props.note_details?.uid || crypto.randomUUID(),
    created_by: auth_store.logged_in_user_id,
    message: state.note,
  };

  const formatted_attachments = state.files_to_attach.map(file => ({
    uid: file.uid || crypto.randomUUID(),
    service: file.service_object,
    file_name: file.name,
    file_size: file.size,
    file_type: file.type,
    meta: file.meta,
    created_at: now,
    updated_at: now,
    created_by: auth_store.logged_in_user_id,
    attached_to: { type: 'notes', uid: note_data.uid },
  }));

  if (!props.note_details) {
    notes.add = [note_data];
    attachments.add = formatted_attachments;
  }
  else {
    notes.update = [note_data];
    attachments.add = [];

    const attachment_map = keyBy(props.note_details.attachments, 'uid');
    formatted_attachments.forEach((attachment) => {
      if (!attachment_map[attachment.uid])
        attachments.add.push(attachment);
      else
        delete attachment_map[attachment.uid];
    });

    attachments.remove = Object.keys(attachment_map);
  }

  return { notes, attachments };
}

async function onSend() {
  try {
    state.is_sending = true;
    await props.on_send(getPayload());
    state.is_sending = false;
  }
  catch (error) {
    logger.error(error);
    state.is_sending = false;
  }
  finally {
    state.note = '';
    state.files_to_attach = [];
    emits('close');
  }
}

onMounted(() => {
  if (props.note_details) {
    state.note = props.note_details.message;
    state.files_to_attach = props.note_details.attachments.map(file => ({
      name: file.file_name,
      size: file.file_size,
      type: file.file_type,
      ...file,
    }));
  }
});
</script>

<template>
  <div class="rounded-lg w-full p-2">
    <div
      class="w-full min-h-[70px] border p-1.5 rounded-lg flex flex-col"
      :class="{
        'border-primary-500 outline outline-4 outline-primary-100': state.is_editor_focussed,
      }"
    >
      <HawkWysiwygEditorComponent
        v-model="state.note"
        class="[&>*]:p-0"
        editor_classes="min-h-10 py-2 mb-1"
        :placeholder_text="`${$t('Enter your notes')}...`"
        :plugins="['paste-files']"
        @focus="state.is_editor_focussed = true"
        @blur="state.is_editor_focussed = false"
        @filesPaste="onFilesPaste"
      />
      <div v-if="state.files_to_attach.length" class="pr-4 py-2 flex flex-wrap gap-5">
        <div v-for="file of state.files_to_attach" :key="file?.uid" class="relative border p-2 border-gray-200 rounded-2xl w-fit flex items-center">
          <IconHawkLoadingCircle v-if="file?.upload_pending" class="animate-spin w-10 h-10 mr-4" />
          <hawk-icon-thumbnail v-else :width="10" :height="10" class="rounded-md mr-4" />
          <div v-tippy="file?.name || ''" class="text-sm">
            {{ $filters.truncate(file?.name || '', 15) }}
          </div>
          <div
            v-if="!file?.upload_pending"
            class="absolute -top-3 -right-3 p-1 cursor-pointer flex justify-center items-center w-fit rounded-full text-xs text-white bg-gray-900 hover:bg-gray-700"
            @click="onRemoveAttachment(file?.data?.uid ?? file.uid)"
          >
            <IconHawkX class="w-4 h-4" />
          </div>
        </div>
      </div>
      <div class="rounded-b-lg flex justify-end">
        <div class="flex justify-center items-center">
          <div class="mr-2 h-7 w-7 rounded-lg cursor-pointer hover:bg-gray-100 flex justify-center items-center" @click="attachmentPopup()">
            <IconHawkAttachmentOne class="w-4 h-4 text-gray-500" />
          </div>
          <div
            v-if="note_details"
            type="text"
            class="mr-2 p-1.5 rounded-lg text-xs cursor-pointer flex justify-center items-center hover:bg-gray-200"
            @click="emits('close')"
          >
            {{ $t('Cancel') }}
          </div>
          <div
            class="flex justify-center items-center w-[31px] h-7 rounded-lg text-white"
            :class="[is_send_allowed ? 'cursor-pointer bg-blue-600 hover:bg-blue-700' : 'bg-blue-200']"
          >
            <IconHawkSendThree v-if="!state.is_sending" class="w-4 h-4" @click="onSend()" />
            <HawkLoader v-else container_class="w-4 h-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
