<script setup>
import { jwtDecode } from 'jwt-decode';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth.store';
import HawkInfo from '~/common/components/molecules/hawk-info.vue';

const route = useRoute();
const router = useRouter();
const $toast = inject('$toast');
const $services = inject('$services');
const auth_store = useAuthStore();
const state = reactive({
  decoded_data: '',
  loading: false,
  error: false,
  is_verifying: false,
});
onMounted(() => {
  if (route.query.p)
    try {
      state.loading = true;
      state.decoded_data = jwtDecode(route.query.p);
      state.loading = false;
    }
    catch (err) {
      logger.log('🚀 ~ file: domain-confirmation.vue:27 ~ onMounted ~ err:', err);
      state.loading = false;
      state.error = err;
    }

  else
    router.push({
      name: auth_store.logged_in_user_details ? 'home' : 'sign-in',
    });
});
async function verifyDomain() {
  try {
    state.is_verifying = true;
    await $services.organizations.post({
      query: { p: route.query.p },
      body: {},
      attribute: 'confirm-support-domain',
    });
    state.is_verifying = false;
    $toast({
      text: 'Domain verified successfully!',
      type: 'success',
    });
    router.push({
      name: auth_store.logged_in_user_details ? 'home' : 'sign-in',
    });
  }
  catch (err) {
    logger.log('🚀 ~ file: domain-confirmation.vue:55 ~ verifyDomain ~ err:', err);
    state.loading = false;
    state.error = err.data;
  }
}
</script>

<template>
  <div v-if="state.loading" class="flex items-center justify-center h-screen flex-col">
    <HawkLoader container_class="m-0 w-10 h-10" />
  </div>
  <div class="flex items-center justify-center h-screen flex-col text-black">
    <template v-if="state.decoded_data">
      <img src="../../../assets/logos/sensehawk-full.svg" alt="sensehawk-logo" class="w-[226px] h-[28px]">
      <div class="rounded-xl border p-6 my-7 text-gray-900 justify-start w-[600px]">
        <div class="flex flex-col items-baseline mb-4 text-sm text-left text-gray-600 ">
          <div class="mb-1">
            {{ $t('Hi') }},
          </div>
          <div class="text-md font-semibold text-primary-700 mb-1">
            {{ state.decoded_data.req_email }}
          </div>
          <div class="mb-1">
            (<span class="font-semibold">@{{ state.decoded_data.domain }}</span>) {{ $t('has been added as the domain name') }}. {{ $t('Click on the button below to verify the domain') }}.
          </div>
        </div>
        <HawkButton :loading="state.is_verifying" class="mt-6" type="default" color="primary" @click="verifyDomain()">
          <span style="line-height: initial;">
            {{ $t('Verify Domain') }}
          </span>
        </HawkButton>
      </div>
      <div class="text-xs text-gray-600">
        <div class="mb-1">
          Sensehawk, Inc.
        </div>
        <div>
          12920, Brandywine Dr., Saratoga, California 95070, United States
        </div>
        <div class="mb-1">
          <HawkButton type="link" color="primary">
            {{ $t('Change email settings') }}
          </HawkButton>
        </div>
      </div>
    </template>
    <HawkInfo v-if="state.error" class="capitalize" type="error" title="Error" :description="state.error?.message" action_button="Back to home" @action-button="$router.push('/')" />
  </div>
</template>
