<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { useAnnotationsStore } from '~/plans/store/annotations.store';

const emit = defineEmits(['hideAnnotations']);

const route = useRoute();
const annotations_store = useAnnotationsStore();
const common_store = useCommonStore();
const transmittal_store = useTransmittalsStore();

const members = computed(() => transmittal_store.transmittal.members.filter(member => ['consolidator', 'reviewer'].includes(member.role)).map(member => member.uid));
const current_document = computed(() => (transmittal_store.transmittal.documents || []).find(doc => doc.uid === route.params.document_uid));

const form = ref({
  reviewers: [
    ...members.value,
  ],
});

const items = [
  ...members.value,
].map(uid => ({ uid, name: common_store.get_user_or_team_name(uid) }));

const get_multi_label_text = values => values?.length === items.length ? 'All Reviewers' : `Reviewers (${values.length})`;

function onChangeHandler(selected_reviewers) {
  emit('hideAnnotations', selected_reviewers);
}
</script>

<template>
  <Vueform
    v-if="current_document?.has_consolidator"
    v-model="form"
    sync :display-errors="false"
  >
    <MultiselectElement
      v-tippy="$t('select to filter annotation')"
      name="reviewers"
      :native="false"
      :multiple-label="get_multi_label_text"
      :can-clear="false"
      track-by="name"
      :search="true"
      value-prop="uid"
      :close-on-select="false"
      :clear-on-select="false"
      :hide-selected="false"
      :items="items"
      class="w-52"
      placeholder="Select Reviewers"
      :add-classes="{
        MultiselectElement: {
          select: {
            dropdown: ['scrollbar'],
          },
        },
      }"
      @change="onChangeHandler"
      @wheel.stop
    >
      <template #multiple-label="{ values }">
        <div class="flex w-full gap-2 items-center">
          <IconHawkPenToolTwo class="ml-3" />
          {{ get_multi_label_text(values) }}
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex">
          <HawkCheckbox :model-value="form.reviewers.includes(option.uid)" class="pointer-events-none" />
          <div class="flex gap-2 items-center">
            <HawkMembers size="xs" :rounded="true" :members="option.uid" />
            <div class="flex flex-col w-full">
              <div class="flex justify-between">
                <hawk-text :length="10" class="text-sm text-gray-900 font-medium" :content="option.name" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </MultiselectElement>
  </Vueform>
</template>
