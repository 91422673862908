/* eslint-disable no-undef */
export default () => {
  // Create a custom element.
  // ------------------------
  if (joint.shapes.html)
    return;
  joint.shapes.html = {};
  joint.shapes.html.Element = joint.shapes.basic.Rect.extend({
    defaults: joint.util.deepSupplement(
      {
        type: 'html.Element',
        attrs: {
          rect: { 'stroke': 'none', 'fill-opacity': 0 },
        },
      },
      joint.shapes.basic.Rect.prototype.defaults,
    ),
  });

  // Create a custom view for that element that displays an HTML div above it.
  // -------------------------------------------------------------------------

  joint.shapes.html.ElementView = joint.dia.ElementView.extend({
    template: [
      '<div class="html-element">',
      '<div class=\'element\'></div>',
      '<div class=\'category\'></div>',
      '<div class=\'popup\'></div>',
      '<br/>',
      '</div>',
    ].join(''),

    initialize() {
      _.bindAll(this, 'updateBox');
      joint.dia.ElementView.prototype.initialize.apply(this, arguments);

      this.$box = window.$(_.template(this.template)());
      // Prevent paper from handling pointerdown.

      // This is an example of reacting on the input change and storing the input data in the cell model.
      // this.$box.find("select").val(this.model.get("select"));
      this.$box
        .find('.delete')
        .on('click', _.bind(this.model.remove, this.model));
      // Update the box position whenever the underlying model changes.
      this.model.on('change', this.updateBox, this);
      // Remove the box when the model gets removed from the graph.
      this.model.on('remove', this.removeBox, this);

      this.updateBox();
    },
    render() {
      joint.dia.ElementView.prototype.render.apply(this, arguments);
      this.listenTo(this.paper, 'scale', this.updateBox);
      this.listenTo(this.paper, 'translate', this.updateBox);
      this.paper.$el.prepend(this.$box);
      this.updateBox();
      return this;
    },
    updateBox() {
      const bbox = this.model.getBBox();
      const scale = this.paper?.scale();
      this.$box.find('div.element').text(this.model.get('label')).css({
        fontSize: `${12 * scale?.sx}px`,
        lineHeight: `${13 * scale?.sx}px`,
        marginTop: `${4 * scale?.sx}px`,
      });

      this.$box.find('div.category').text(this.model.get('select')).css({
        fontSize: `${10 * scale?.sx}px`,
        marginTop: `${5 * scale?.sx}px`,
      });
      this.$box.find('div.popup').html(this.model.get('popup')).css({
        top: `${50 * scale?.sx}px`,
        fontSize: `${12 * scale?.sx}px`,
        padding: `${10 * scale?.sx}px`,
        minWidth: `${150 * scale?.sx}px`,
      });

      this.$box.css({
        width: bbox.width * scale?.sx,
        height: bbox.height * scale?.sy,
        left: bbox.x * scale?.sx,
        top: bbox.y * scale?.sy,
        transform: `rotate(${this.model.get('angle') || 0}deg)`,
        padding: `${5 * scale?.sx}px`,
      });
    },
    removeBox(evt) {
      this.$box.remove();
    },
  });
};
