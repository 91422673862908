<script setup>
import { onMounted } from 'vue';
import { compact } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { load_js_css_file } from '~/common/utils/load-script.util';
import { useDashboardCustomStore } from '~/dashboard/store/dashboard-custom.store.js';

const emit = defineEmits(['close', 'save']);

const dashboard_custom_store = useDashboardCustomStore();

const {
  custom_configuration,
} = storeToRefs(dashboard_custom_store);

const hands_on_table = ref(null);
const loading = ref(false);
const is_loading = ref(false);

const initial_data = computed(() => custom_configuration.value.csv_data?.data || []);

async function bulk_create_items() {
  const grid_data = hands_on_table.value.getData();
  const specified_items = grid_data
    .map(item => compact(item))
    .filter(item => item && item.length);

  if (specified_items.length)
    try {
      loading.value = true;
      emit('save', specified_items);
    }
    catch (err) {
      logger.error(err);
      loading.value = false;
    }

  else
    logger.log('error');
}

function loadHandsOnTable() {
  const container = document.getElementById('import-items-handson');
  hands_on_table.value = new Handsontable(container, {
    startCols: 12,
    startRows: 15,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    colHeaders: true,
    rowHeaders: true,
    height: '450',
    allowEmpty: true,
    allowHtml: false,
  });
  if (initial_data.value?.length)
    hands_on_table.value.loadData(initial_data.value);
}

async function loadFiles() {
  is_loading.value = true;
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.1/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.1/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );
  is_loading.value = false;
  setTimeout(async () => {
    await loadHandsOnTable();
  }, 200);
}

onMounted(() => {
  loadFiles();
});
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div>
          {{ $t('Import values') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="pa-10 create-items w-[550px]" @click.stop="">
      <HawkLoader v-if="is_loading" />
      <div v-else id="import-items-handson" />
    </div>

    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="bulk_create_items"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
