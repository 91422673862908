<script setup>
import dayjs from 'dayjs';
import { cloneDeep, groupBy, mapValues, sum, uniq } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import ReportPreview from '~/therm/components/customize-report/report-preview.vue';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';
import { useThermCustomReport } from '~/therm/composables/therm-custom-report.js';
import { useThermStore } from '~/therm/store/therm.store.js';

const emits = defineEmits(['close']);

const { convertSvgToPng, uploadFiles, getAttachmentDetails } = useThermCustomReport();
const { $services, $t, common_store, auth_store } = useCommonImports();
const therm_store = useThermStore();

const columns = {
  lg: { container: 6, label: 2, wrapper: 4 },
};

const form$ = ref(null);
const state = reactive({
  sections: {},
  custom_report_save: false,
  loading_scan_date: false,
  features: [],
  group_details: {},
  charts: {},
  is_saving: false,
  current_step: 1,
  report_payload: {},
  default_data: {},
  is_next_loading: false,
});

const form = computed(() => form$.value?.data);
const scan_dates = computed(() => {
  return therm_store.groups_by_scan_dates.map((group) => {
    return {
      value: group.uid,
      label: (group
        && group.properties
        && group.properties.date
        && dayjs(group.properties.date)?.utc()?.format('DD MMM YYYY'))
        || (group && group.name),
    };
  });
});
const get_barchart_details = computed(() => {
  return {
    type: 'stackedcolumn2d',
    xAxisName: $t('Inverters'),
    yAxisName: $t('No. of defects'),
    plotToolText: '$seriesName: $value',
    id: 'chart_1_container',
    only_chart: true,
  };
});
const get_defect_count = computed(() => {
  const block_uid_name_map = {};
  const grouped_by_project = state.features.reduce((r, a) => {
    block_uid_name_map[a.block] = a.block_name;
    r[a.block] = r[a.block] || [];
    r[a.block].push(a);
    return r;
  }, {});
  const feature_types = Object.values(therm_store.feature_types);
  const data = {
    categories: [
      {
        category: Object.keys(grouped_by_project)
          .reverse()
          .map(project_id =>
            new Object({
              label: block_uid_name_map[project_id],
            }),
          ),
      },
    ],
    dataset: feature_types.map((feature_type) => {
      const feature_count = Object.keys(grouped_by_project)
        .reverse()
        .map((project_id) => {
          return new Object({
            value: grouped_by_project[project_id].filter(
              feature =>
                feature.type_id === feature_type.id,
            ).length,
          });
        });
      if (feature_count.filter(cnt => cnt.value > 0).length) {
        return {
          seriesname: feature_type.name,
          data: feature_count,
          color: getHexVal(
            feature_type.properties.color.slice(4, -1),
          ),
        };
      }
      return {};
    }),
  };
  data.dataset = data.dataset.filter(val => val !== undefined);
  return data;
});

const get_temperature_graph_details = computed(() => {
  return {
    type: 'column2d',
    xAxisName: 'Temperature range(°C)',
    yAxisName: 'Number of Defects',
    plotToolText: '$yAxisName: $value',
    id: 'chart_2_container',
    only_chart: true,
  };
});
const get_temperature_graph_data = computed(() => {
  const temperature_difference = state.features
    .map(feature => feature.temperature_difference.toFixed(3))
    .sort((a, b) => a - b);
  const grouped_data = temperature_difference.reduce((acc, curr) => {
    if (acc[curr])
      acc[curr] = ++acc[curr];
    else
      acc[curr] = 1;
    return acc;
  }, {});
  const data = [];
  for (const temperature in grouped_data) {
    data.push({
      label: temperature,
      value: grouped_data[temperature],
    });
  }

  return { data };
});
function getHexVal(val) {
  val = val.split(',');
  return (
    `#${
      val
        .map((x) => {
          const hex = (+x).toString(16);
          return hex.length === 1 ? `0${hex}` : hex;
        })
        .join('')}`
  );
}
async function handleSelectScanDate(group) {
  try {
    state.loading_scan_date = true;

    const response = await $services.therm.post({
      attribute: 'metrics/reports',
      body: {
        view: therm_store.container.uid,
        group: group.value,
        type: 'group_summary_and_filtered_defects',
      },
    });
    state.group_details = response.data.summary;
    state.features = response.data.defects;
    const group_response = await $services.therm.get({
      attribute: `views/${therm_store.container.uid}/groups/${form.value.scan_date.value}/summary-report/config`,
    });

    form$.value.load({
      scan_date: form.value.scan_date,
      ...group_response.data,
    }, true);

    state.default_data = group_response.data;

    state.loading_scan_date = false;
  }
  catch (error) {
    logger.error(error);
    state.loading_scan_date = false;
  }
}

async function handleSave() {
  try {
    state.is_saving = true;
    const payload = cloneDeep({
      is_view_config: state.custom_report_save,
      config: {
        ...form.value,
        cover_image: getAttachmentDetails(form.value.cover_image),
        thermal_orthomap: getAttachmentDetails(form.value.thermal_orthomap),
        methodology: {
          plant_layout: getAttachmentDetails(form.value?.methodology?.plant_layout),
        },
      },
    });
    const response = await $services.therm.post({
      attribute: `views/${therm_store.container.uid}/groups/${form.value.scan_date.value}/summary-report/config`,
      body: payload,
    });
    form$.value.load({
      scan_date: form.value.scan_date,
      ...response.data,
    }, true);
    state.default_data = group_response.data;
    state.is_saving = false;
  }
  catch (error) {
    logger.error(error);
    state.is_saving = false;
  }
}

function findDefectTemperatureThresholds(temps) {
  temps.sort((a, b) => a - b);
  const total_count = temps.length;

  let defect_threshold_low = temps[Math.floor(total_count * 0.15)];
  const defect_threshold_high = temps[total_count - 1];

  if (defect_threshold_high > 5)
    defect_threshold_low = 5;

  return { defect_threshold_low, defect_threshold_high };
}

function findDefectTemperatureRateAndMaxPercentage() {
  const temperatures = state.features.map(f => f.temperature_difference);
  const thresholds = findDefectTemperatureThresholds(temperatures);

  const { defect_threshold_low, defect_threshold_high } = thresholds;

  const defective_temps = temperatures.filter(temp => temp <= defect_threshold_high && temp >= defect_threshold_low);
  const defect_count = defective_temps.length;
  const defect_percentage = (defect_count / temperatures.length) * 100;
  const max_defect_percentage = Math.max(...defective_temps) * 100 / Math.max(...temperatures);

  return {
    thresholds,
    defective_temps,
    defect_percentage,
    max_defect_percentage,
    percentage: Number.isNaN(defect_percentage) ? '0' : String(defect_percentage?.toFixed(2) || 0),
    min: String(thresholds?.defect_threshold_low?.toFixed(2) || 0),
    max: String(thresholds?.defect_threshold_high?.toFixed(2) || 0),
  };
}

function calculateSeverityPercentages() {
  const severities = state.features.map(f => f.severity);
  const total_count = severities.length;
  const severity_count = {};

  // Count occurrences of each severity level
  severities.forEach((severity) => {
    severity_count[severity] = (severity_count[severity] || 0) + 1;
  });

  // Calculate percentages
  const severity_percentages = { low: '0', medium: '0', high: '0' };
  for (const severity in severity_count) {
    severity_percentages[severity] = String(((severity_count[severity] / total_count) * 100).toFixed(2));
  }

  return severity_percentages;
}

function getSummaryDetails() {
  return {
    blocks_count: String(Object.keys(therm_store.container.groups?.[form.value.scan_date?.value]?.projects || {}).length || 0),
    defects_count: String(state.group_details?.defect?.total || 0),
    affected_defects_count: String(state.group_details?.block?.most_affected?.defect_count?.total || 0),
    affected_capacity: {
      total: String(state.group_details?.capacity?.affected?.total || 0),
      percentage: String((state.group_details?.capacity?.total ? ((state.group_details?.capacity?.affected?.total / state.group_details?.capacity?.total) * 100).toFixed(2) : 0) || 0),
    },
    affected_block: {
      name: state.group_details?.block?.most_affected?.name || '-',
      count: String(state.group_details?.block?.most_affected?.defect_count?.total || 0),
    },
    temperatures: findDefectTemperatureRateAndMaxPercentage(),
    severity_percentages: calculateSeverityPercentages(),
  };
}

function getSeverityTable() {
  const service_id_name_map = { low: '1', medium: '2', high: '3' };
  const data_map = { severity: {}, block: {}, type: {} };
  const data = state.features.map((f) => {
    data_map.severity[f.severity] = service_id_name_map[f.severity];
    data_map.block[f.block] = f.block_name;
    data_map.type[f.type_id] = therm_store.feature_types[f.type_id]?.name || '-';

    return {
      type_id: f.type_id,
      block_id: f.block,
      severity_id: f.severity,
    };
  });

  const grouped_by_severity = mapValues(groupBy(data, 'severity_id'), group => mapValues(groupBy(group, 'type_id'), subgroup => subgroup.length));
  const grouped_by_severity_and_block = mapValues(groupBy(data, 'severity_id'), group => mapValues(groupBy(group, 'block_id'), subgroup => mapValues(groupBy(subgroup, 'type_id'), values => values.length)));

  const table_data = [];

  for (const severity_id in grouped_by_severity_and_block) {
    const type_ids = Object.keys(grouped_by_severity[severity_id]);
    const columns = ['Inverter blocks', ...type_ids.map(type_id => data_map.type[type_id]), 'Grand Total'];
    const rows = [];
    for (const block_id in grouped_by_severity_and_block[severity_id]) {
      const values = type_ids.map(type_id => grouped_by_severity_and_block[severity_id][block_id][type_id] || 0);
      const row = [
        data_map.block[block_id],
        ...values.map(value => String(value)),
        String(sum(values)),
      ];
      rows.push(row);
    }
    rows.push([
      'Total',
      ...type_ids.map(type_id => String(sum(rows.map(row => Number(row[1 + type_ids.indexOf(type_id)]))))),
      String(sum(rows.map(row => Number(row[1 + type_ids.length])))),
    ]);

    table_data.push({ severity: data_map.severity[severity_id], headers: { insert_rows: [columns] }, data: { insert_rows: rows }, column_length: String(columns.length), columns_not_supported: columns.length > 13 });
  }
  return table_data;
}

async function getCharts() {
  const defects_count = await convertSvgToPng(state.charts?.defect_count_graph?.getSVGString(), state.charts?.defect_count_graph?.height);
  const temperature_histogram = await convertSvgToPng(state.charts?.temperature_graph?.getSVGString(), state.charts?.temperature_graph?.height);

  const charts = {
    defects_count,
    temperature_histogram,
  };

  const files_array = Object.entries(charts).map(([key, blob]) => {
    return new File([blob], `${key}.png`, { type: 'image/png' });
  });

  await uploadFiles(files_array);

  const response = await $services.therm.post({
    attribute: `views/${therm_store.container.uid}/groups/${form.value.scan_date.value}/summary-report/config`,
    body: {
      config: {
        ...state.default_data,
        charts: files_array.map(file => getAttachmentDetails(file)),
      },
    },
  });

  const charts_data = response.data.charts.reduce((acc, file) => {
    const key = file.file_name.split('.')[0];
    acc[key] = {
      image_url: file.url,
      width: 460,
      height: 280,
    };
    return acc;
  }, {});

  return charts_data;
}

function getDefectTypeTable() {
  const type_id_list = state.features.map(({ type_id }) => type_id);
  const unique_type_id_list = uniq(type_id_list);

  const type_id_table_class_map = {
    hotspots: [101, 301, 302],
    bypass_diode_active: [102],
    module_short_circuit: [104],
    string_short_circuit: [106],
    substring_short_circuit: [325],
    potentially_induced_degradation_pid_only_solar_plants_prior_to_2018: [107],
    shade_induced_hotspot: ['check'],
    junction_box_hot: [320],
    to_be_investigated_junction_box_pattern: [317],
    module_hot: [103],
    string_hot: [105],
    tracker_malfunction: [110],
    vegetation: [109],
    dirt: [108],
    shadow: [303],
    missing_module: [304],
    cracked_module: [111],
    cell_failure_cell_hot: [306],
    full_edge_cell_hot: [323],
    partial_edge_cell_hot: [324],
    full_interior_cell_hot: [321],
    partial_interior_cell_hot: [322],
    center_brace_hot: [310],
    end_brace_hot: [311],
  };

  return Object.keys(type_id_table_class_map).reduce((acc, key) => {
    const type_id_list = type_id_table_class_map[key];
    acc[key] = unique_type_id_list.some(type_id => type_id_list.includes(type_id));
    return acc;
  }, {});
}

function handleDataCollectionDates(value) {
  if (typeof value === 'string')
    return value;
  else
    return value?.filter(value => !!value).join(', ');
}

function getImagesStatus() {
  return {
    has_organization_logo: !!auth_store.current_organization.cover_image?.url,
    has_asset_logo: !!common_store.active_asset.cover_image?.url,
    has_cover_image: !!form.value?.cover_image?.url,
    has_plant_layout: !!form.value?.methodology?.plant_layout?.url,
    has_thermal_orthomap: !!form.value?.thermal_orthomap?.url,
  };
}

async function handlePdfGeneration() {
  state.is_next_loading = true;
  try {
    const summary = getSummaryDetails();
    const charts = await getCharts();
    const severity_table = getSeverityTable();

    const payload = {
      ...getImagesStatus(),
      cover_image: {
        image_url: form.value?.cover_image?.url,
        width: 460,
        height: 460,
      },
      organization_details: {
        name: auth_store.current_organization.name,
        logo: {
          image_url: auth_store.current_organization.cover_image?.url,
          width: 96,
          height: 40,
        },
      },
      asset_details: {
        name: common_store.active_asset.name,
        address: common_store.active_asset.address?.name,
        logo: {
          image_url: common_store.active_asset.cover_image?.url,
          width: 144,
          height: 60,
        },
      },
      scan_date: form.value?.scan_date?.label,
      assignment_details: {
        plant_name: form.value?.assignment_details?.plan_name || '-',
        location: form.value?.assignment_details?.location || '-',
        dc_capacity: form.value?.assignment_details?.dc_capacity || '-',
        pv_module_manufacturer: form.value?.assignment_details?.pv_module_manufacturer || '-',
        racking_type: form.value?.assignment_details?.racking_type || '-',
        inverter_manufacturer: form.value?.assignment_details?.inverter_manufacturer || '-',
      },
      charts,
      summary,
      severity_table,
      technical_specification: {
        flight_parameters: {
          gsd: ![null, undefined].includes(form.value?.technical_specification?.flight_parameter?.gsd) ? String(form.value?.technical_specification?.flight_parameter?.gsd) : '-',
          frontal_overlap: ![null, undefined].includes(form.value?.technical_specification?.flight_parameter?.frontal_overlap) ? String(form.value?.technical_specification?.flight_parameter?.frontal_overlap) : '-',
          side_overlap: ![null, undefined].includes(form.value?.technical_specification?.flight_parameter?.side_overlap) ? String(form.value?.technical_specification?.flight_parameter?.side_overlap) : '-',
        },
        thermal_sensor_details: {
          sensor_model: form.value?.technical_specification?.thermal_sensor_details?.sensor_model || '-',
          sensor_resolution: form.value?.technical_specification?.thermal_sensor_details?.sensor_resolution || '-',
          frame_rate: ![null, undefined].includes(form.value?.technical_specification?.thermal_sensor_details?.frame_rate) ? String(form.value?.technical_specification?.thermal_sensor_details?.frame_rate) : '-',
          lens_model: ![null, undefined].includes(form.value?.technical_specification?.thermal_sensor_details?.lens_model) ? String(form.value?.technical_specification?.thermal_sensor_details?.lens_model) : '-',
        },
        data_collection_details: {
          name_of_pilot: form.value?.technical_specification?.data_collection_details?.name_of_pilot || '-',
          data_collection_dates: handleDataCollectionDates(form.value?.technical_specification?.data_collection_details?.data_collection_dates) || '-',
          irradiance: form.value?.technical_specification?.data_collection_details?.irradiance || '-',
        },
      },
      defects: state.features.map((feature, i) => {
        return {
          ...feature,
          index: String(i + 1),
          string_number: String(feature.string_number || '-'),
          type_name: therm_store.feature_types[feature.type_id]?.name || '-',
        };
      }),
      thermal_orthomap: {
        image_url: form.value?.thermal_orthomap?.url,
        width: 460,
        height: 360,
      },
      plant_layout: {
        image_url: form.value?.methodology?.plant_layout?.url,
        width: 460,
        height: 360,
      },
      include_features: form.value?.include_features || false,
      defect_type_table: getDefectTypeTable(),
      group_uid: form.value?.scan_date?.value,
    };
    state.report_payload = payload;
    state.current_step = 2;
  }
  catch (error) {
    logger.error(error);
  }
  state.is_next_loading = false;
}

async function handleMounted() {
  const active_group = scan_dates.value.find(date => date.value === therm_store.active_group?.uid);
  await form$.value.load({
    scan_date: active_group,
  }, true);
  await handleSelectScanDate(active_group);
};
</script>

<template>
  <hawk-modal-container :width="400" content_class="rounded-none min-w-full min-h-full">
    <Vueform
      v-show="state.current_step === 1"
      ref="form$"
      size="sm" :display-errors="false"
      :endpoint="handleSave"
      @mounted="handleMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!py-4" @close="emits('close')">
          <template #title>
            {{ $t("Customize report") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false" class="h-[calc(100vh-120px)] scrollbar !p-0">
          <div class="flex flex-col w-full p-6">
            <div class="flex items-center gap-2">
              <div class="cursor-pointer" @click="state.sections.scan_dates = !state.sections.scan_dates">
                <IconHawkChevronRight v-if="state.sections?.scan_dates" />
                <IconHawkChevronDown v-else />
              </div>
              <div class="text-md font-semibold">
                {{ $t('Scan date') }}
              </div>
            </div>
            <SelectElement
              v-show="!state.sections.scan_dates"
              class="mt-4 ml-1"
              name="scan_date"
              :label="$t('Date')"
              :items="scan_dates"
              :can-clear="false"
              :native="false"
              :can-deselect="false"
              :columns="columns"
              :object="true"
              :loading="state.loading_scan_date"
              @select="handleSelectScanDate"
            >
              <template #single-label="{ value }">
                <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                  <div class="flex items-center gap-x-2">
                    <IconHawkCalendar class="text-gray-600" />
                    <div class="font-semibold text-gray-900">
                      {{ value.label }}
                    </div>
                  </div>
                </div>
              </template>
            </SelectElement>
            <CheckboxElement name="include_features" :columns="columns" class="mt-4 ml-1" label="Include defects" :default="true" />
          </div>
          <div class="border-4 border-gray-200 w-full" />
          <div class="flex flex-col w-full p-6">
            <div class="flex items-center gap-2">
              <div class="cursor-pointer" @click="state.sections.cover_image = !state.sections.cover_image">
                <IconHawkChevronRight v-if="state.sections?.cover_image" />
                <IconHawkChevronDown v-else />
              </div>
              <div class="text-md font-semibold">
                {{ $t('Cover image') }}
              </div>
            </div>
            <FileElement
              v-show="!state.sections?.cover_image"
              name="cover_image"
              class="mt-4"
              :presets="['hawk_file_element']"
              :options="{
                button_type: 'text',
                button_classes: '!h-auto !text-sm !font-semibold !p-0',
              }"
              accept=".png,.jpg,.jpeg"
              :auto="false"
              :columns="columns"
            >
              <template #upload-trigger>
                <div class="flex items-center gap-2">
                  <IconHawkFileFour class="w-5 h-5" />
                  <span class="mr-2">{{ $t('Choose file') }}</span>
                </div>
              </template>
              <template #description>
                <div class="text-sm text-gray-600">
                  {{ $t('Supported formats: .png, .jpeg') }}
                </div>
              </template>
            </FileElement>
          </div>
          <div class="border-4 border-gray-200 w-full" />
          <div class="flex flex-col w-full p-6">
            <div class="flex items-center gap-2">
              <div class="cursor-pointer" @click="state.sections.assignment_details = !state.sections.assignment_details">
                <IconHawkChevronRight v-if="state.sections?.assignment_details" />
                <IconHawkChevronDown v-else />
              </div>
              <div class="text-md font-semibold">
                {{ $t('Assignment details') }}
              </div>
            </div>
            <ObjectElement
              v-show="!state.sections?.assignment_details"
              name="assignment_details"
            >
              <div class="col-span-12 pt-4">
                <div class="flex flex-col gap-y-4">
                  <TextElement
                    name="plan_name"
                    :label="$t('Plant name')"
                    :columns="columns"
                  />
                  <TextElement
                    name="location"
                    :label="$t('Location')"
                    :columns="columns"
                  />
                  <TextElement
                    name="dc_capacity"
                    :label="$t('DC capacity')"
                    :columns="columns"
                  />
                  <TextElement
                    name="pv_module_manufacturer"
                    :label="$t('PV module manufacturer')"
                    :columns="columns"
                  />
                  <TextElement
                    name="racking_type"
                    :label="$t('Racking type')"
                    :columns="columns"
                  />
                  <TextElement
                    name="inverter_manufacturer"
                    :label="$t('Inverter manufacturer')"
                    :columns="columns"
                  />
                  <TextareaElement
                    name="notes"
                    :label="$t('Notes')"
                    :columns="columns"
                    :rows="5"
                    :placeholder="$t('Add notes here')"
                  />
                </div>
              </div>
            </ObjectElement>
          </div>
          <div class="border-4 border-gray-200 w-full" />
          <div class="flex flex-col w-full p-6">
            <div class="flex items-center gap-2">
              <div class="cursor-pointer" @click="state.sections.technical_specifications = !state.sections.technical_specifications">
                <IconHawkChevronRight v-if="state.sections?.technical_specifications" />
                <IconHawkChevronDown v-else />
              </div>
              <div class="text-md font-semibold">
                {{ $t('Technical specifications') }}
              </div>
            </div>
            <ObjectElement
              v-show="!state.sections?.technical_specifications"
              name="technical_specification"
            >
              <div class="col-span-12 pt-4">
                <div class="flex flex-col gap-y-4">
                  <div class="text-sm font-semibold">
                    {{ $t('Flight parameters') }}
                  </div>
                  <ObjectElement name="flight_parameter">
                    <div class="col-span-12">
                      <div class="flex flex-col gap-y-4">
                        <TextElement
                          name="gsd"
                          :label="$t('GSD')"
                          input-type="number"
                          :addons="{
                            after: 'cm/pixel',
                          }"
                          :columns="columns"
                        />
                        <TextElement
                          name="frontal_overlap"
                          :label="$t('Frontal overlap')"
                          input-type="number"
                          :addons="{
                            after: '%',
                          }"
                          :columns="columns"
                        />
                        <TextElement
                          name="side_overlap"
                          :label="$t('Side overlap')"
                          input-type="number"
                          :addons="{
                            after: '%',
                          }"
                          :columns="columns"
                        />
                      </div>
                    </div>
                  </ObjectElement>
                  <div class="border border-gray-200" />
                  <div class="text-sm font-semibold">
                    {{ $t('Thermal sensor details') }}
                  </div>
                  <ObjectElement name="thermal_sensor_details">
                    <div class="col-span-12">
                      <div class="flex flex-col gap-y-4">
                        <TextareaElement
                          name="sensor_model"
                          :label="$t('Sensor model')"
                          :columns="columns"
                          :rows="1"
                        />
                        <TextElement
                          name="sensor_resolution"
                          :label="$t('Sensor resolution')"
                          :columns="columns"
                        />
                        <TextElement
                          name="frame_rate"
                          input-type="number"
                          :label="$t('Frame rate')"
                          :addons="{
                            after: 'Hz',
                          }"
                          :columns="columns"
                        />
                        <TextElement
                          name="lens_model"
                          input-type="number"
                          :label="$t('Lens model')"
                          :addons="{
                            after: 'mm',
                          }"
                          :columns="columns"
                        />
                      </div>
                    </div>
                  </ObjectElement>
                  <div class="border border-gray-200" />
                  <div class="text-sm font-semibold">
                    {{ $t('Data collection details') }}
                  </div>
                  <ObjectElement name="data_collection_details">
                    <div class="col-span-12">
                      <div class="flex flex-col gap-y-4">
                        <TextElement
                          name="name_of_pilot"
                          :label="$t('Name of Pilot')"
                          :columns="columns"
                        />
                        <TextElement
                          name="data_collection_dates"
                          :label="$t('Data collection dates')"
                          :columns="columns"
                        />
                        <TextElement
                          name="irradiance"
                          :label="$t('Irradiance')"
                          :columns="columns"
                        />
                      </div>
                    </div>
                  </ObjectElement>
                </div>
              </div>
            </ObjectElement>
          </div>
          <div class="border-4 border-gray-200 w-full" />
          <div class="flex flex-col w-full p-6">
            <div class="flex items-center gap-2">
              <div class="cursor-pointer" @click="state.sections.plant_layout = !state.sections.plant_layout">
                <IconHawkChevronRight v-if="state.sections?.plant_layout" />
                <IconHawkChevronDown v-else />
              </div>
              <div class="text-md font-semibold">
                {{ $t('Methodology - Plant layout') }}
              </div>
            </div>
            <ObjectElement v-show="!state.sections?.plant_layout" name="methodology">
              <div class="col-span-12">
                <FileElement
                  name="plant_layout"
                  class="mt-4"
                  :presets="['hawk_file_element']"
                  :options="{
                    button_type: 'text',
                    button_classes: '!h-auto !text-sm !font-semibold !p-0',
                  }"
                  accept=".png,.jpg,.jpeg"
                  :auto="false"
                  :columns="columns"
                >
                  <template #upload-trigger>
                    <div class="flex items-center gap-2">
                      <IconHawkFileFour class="w-5 h-5" />
                      <span class="mr-2">{{ $t('Choose file') }}</span>
                    </div>
                  </template>
                  <template #description>
                    <div class="text-sm text-gray-600">
                      {{ $t('Supported formats: .png, .jpeg') }}
                    </div>
                  </template>
                </FileElement>
              </div>
            </ObjectElement>
          </div>
          <div class="border-4 border-gray-200 w-full" />
          <div class="flex flex-col w-full p-6">
            <div class="flex items-center gap-2">
              <div class="cursor-pointer" @click="state.sections.orthomap = !state.sections.orthomap">
                <IconHawkChevronRight v-if="state.sections?.orthomap" />
                <IconHawkChevronDown v-else />
              </div>
              <div class="text-md font-semibold">
                {{ $t('Annexure: Anomaly distribution - Thermal Orthomap') }}
              </div>
            </div>
            <FileElement
              v-show="!state.sections?.orthomap"
              name="thermal_orthomap"
              class="mt-4"
              :presets="['hawk_file_element']"
              :options="{
                button_type: 'text',
                button_classes: '!h-auto !text-sm !font-semibold !p-0',
              }"
              accept=".png,.jpg,.jpeg"
              :auto="false"
              :columns="columns"
            >
              <template #upload-trigger>
                <div class="flex items-center gap-2">
                  <IconHawkFileFour class="w-5 h-5" />
                  <span class="mr-2">{{ $t('Choose file') }}</span>
                </div>
              </template>
              <template #description>
                <div class="text-sm text-gray-600">
                  {{ $t('Supported formats: .png, .jpeg') }}
                </div>
              </template>
            </FileElement>
          </div>
          <div class="border-4 border-gray-200 w-full" />
          <div v-if="form?.scan_date" class="flex flex-col w-full p-6">
            <div class="flex items-center gap-2">
              <div class="cursor-pointer" @click="state.sections.chart = !state.sections.chart">
                <IconHawkChevronRight v-if="state.sections?.chart" />
                <IconHawkChevronDown v-else />
              </div>
              <div class="text-md font-semibold">
                {{ $t('Chart') }}
              </div>
            </div>
            <div v-show="!state.sections?.chart" class="flex gap-2 pt-4">
              <div class="w-1/2">
                <ThermReportGraph
                  :data="get_defect_count"
                  :graph_details="get_barchart_details"
                  :has_borders="false"
                  height="460"
                  @chart="state.charts.defect_count_graph = $event"
                />
              </div>
              <div class="w-1/2">
                <ThermReportGraph
                  :data="get_temperature_graph_data"
                  :graph_details="get_temperature_graph_details"
                  :has_borders="false"
                  height="460"
                  @chart="state.charts.temperature_graph = $event"
                />
              </div>
            </div>
            <canvas id="custom-report-canvas" style="display:none;" />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center border-t border-t-gray-200 !py-4 w-full">
          <template #left>
            <HawkCheckbox id="custom-report-save" v-model="state.custom_report_save">
              {{ $t('Save the data for future reports') }}
            </HawkCheckbox>
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-4 font-bold"
                type="text"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full" secondary name="submit" :submits="true" :disabled="!form?.scan_date?.value || state.loading_scan_date">
                {{ $t('Save') }}
              </ButtonElement>
              <hawk-button
                class="ml-4"
                :disabled="state.loading_scan_date"
                :loading="state.is_next_loading"
                @click="handlePdfGeneration"
              >
                {{ $t('Next') }}
              </hawk-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
    <ReportPreview
      v-if="state.current_step === 2"
      :values="state.report_payload"
      @back="state.current_step = 1"
      @close="emits('close')"
    />
  </hawk-modal-container>
</template>
