<script>
export default {
  name: 'TextareaElement',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        inputContainer: '',
        input: '',
      },
    };
  },
};
</script>

<template>
  <component :is="elementLayout" ref="container">
    <template #element>
      <div :class="classes.inputContainer">
        <ElementAddon v-if="hasAddonBefore" type="before">
          <slot name="addon-before" />
        </ElementAddon>

        <ElementAddon v-if="hasAddonAfter" type="after">
          <slot name="addon-after" />
        </ElementAddon>

        <ElementLabelFloating
          v-if="hasFloating && !empty"
          :visible="!empty"
        />

        <ElementLoader
          v-show="pending"
        />

        <textarea
          :id="fieldId"
          v-bind="{
            ...attrs,
            ...aria,
          }"
          ref="input"
          :value="model"
          :name="name"
          :class="classes.input"
          :placeholder="placeholder"
          :disabled="isDisabled"
          :readonly="readonly"
          :rows="rows"
          @keydown="e => e.keyCode === 13 ? (e.preventDefault(), form$.submit()) : handleKeydown(e)"
          @keyup="handleKeyup"
          @keypress="handleKeypress"
          @input="handleInput"
          @blur="handleBlur"
        />
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </component>
</template>
