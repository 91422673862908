<script setup>
import { useModal } from 'vue-final-modal';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryMailPopup from '~/inventory/components/inventory-mail-popup.vue';

const { $t, $services, $toast } = useCommonImports();
const inventory_store = useInventoryStore();

const history_details = computed(() => inventory_store.history_details);

const { open: openMailSettings, close: closeMailSettings, patchOptions } = useModal({
  component: InventoryMailPopup,
});

function openEmails() {
  patchOptions({
    attrs: {
      value: history_details?.value?.trigger_emails,
      is_published: true,
      onSave(data) {
        $services.inventory_adjustments.send_emails({
          id: history_details.value.uid,
          body: {
            trigger_emails: data.emails,
          },
          toast: {
            title: $t('Users will be notified'),
            text: $t('The added users will receive an email with the transaction details.'),
            type: 'success',
          },
        });
        closeMailSettings();
      },
      onClose() {
        closeMailSettings();
      },
    },
  });
  openMailSettings();
}
</script>

<template>
  <div>
    <HawkButton icon type="text" @click="openEmails">
      <IconHawkEmail />
    </HawkButton>
  </div>
</template>
