<script setup>
const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  modelValue: {},
  id: {
    type: String,
    default: 'checkbox',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['xs', 'sm', 'md', 'lg'].includes(value);
    },
  },
  tristate: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const checkbox_value = ref(props.modelValue);

function onChange(e) {
  emit('update:modelValue', e);
}
</script>

<template>
  <div v-if="tristate">
    <div
      class="flex w-full items-center gap-3 group"
      :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
      @click="onChange(modelValue === 'checked' ? 'unchecked' : 'checked')"
    >
      <div
        v-if="modelValue === 'checked'"
        class="border text-primary-500 border-primary-500 h-4 w-4 grid place-content-center rounded flex-shrink-0"
      >
        <IconMdiCheckBold class="w-3 h-3" />
      </div>
      <div
        v-else-if="modelValue === 'intermediate'"
        class="border text-primary-500 border-primary-500 h-4 w-4 grid place-content-center rounded flex-shrink-0"
      >
        <IconMdiMinusThick class="w-3 h-3" />
      </div>
      <div
        v-else-if="modelValue === 'unchecked'"
        class="border text-gray-300 border-gray-300 group-hover:border-primary-500 h-4 w-4 grid place-content-center rounded flex-shrink-0"
      />
      <div class="w-full flex-shrink-0">
        <slot />
      </div>
    </div>
  </div>
  <div v-else class="checkbox-container" :class="`checkbox-${props.size}`">
    <div v-if="$slots?.left" :for="id" class="text-sm">
      <slot name="left" />
    </div>
    <input
      :id="id"
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled"
      @click="onChange((checkbox_value = !checkbox_value))"
    >
    <div v-if="!$slots?.left" :for="id" class="text-sm">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.checkbox-container {
  @apply flex items-center gap-3;
  &.checkbox-xs {
    > input[type="checkbox"] {
      width: 14px;
      height: 14px;
      &:checked {
        &:after {
          left: 3px;
          top: 1px;
        }
      }
    }
  }
  &.checkbox-sm {
    > input[type="checkbox"] {
      width: 16px;
      height: 16px;
      &:checked {
        &:after {
          left: 4.8px;
          top: 1.8px;
        }
      }
    }
  }
  &.checkbox-md {
    > input[type="checkbox"] {
      width: 18px;
      height: 18px;
      &:checked {
        &:after {
          left: 5px;
          top: 2px;
          width: 5px;
          height: 10px;
        }
      }
    }
  }
  &.checkbox-lg {
    > input[type="checkbox"] {
      width: 20px;
      height: 20px;
      &:checked {
        &:after {
          left: 6px;
          top: 2px;
          width: 6px;
          height: 12px;
        }
      }
    }
  }
  & > label {
    font-size: 14px;
    @apply flex-shrink-0;
  }
  & > input[type="checkbox"] {
    @apply flex-shrink-0;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    display: inline-block;
    vertical-align: center;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid #d0d5dd;
    background: #fff;
    border-radius: 4px;
    &:checked {
      border: 1px solid #2e90fa;
      border-radius: 4px;
    }
    &:disabled {
      @apply bg-gray-200 cursor-not-allowed border border-gray-300;
      &:checked {
        &:after {
          @apply border-2 border-gray-400;
        }
      }
      & + label {
        @apply cursor-not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          border: 1px solid #2e90fa;
        }
      }
    }
    &:after {
      opacity: 1;
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
    }
  }
  input[type="checkbox"] {
    border-radius: 4px;
    &:after {
      width: 5px;
      height: 9px;
      border: 0px solid transparent;
      border-top: 0;
      border-left: 0;
      left: 3.75px;
      top: 2px;
      transform: rotate(45deg);
      content: "";
      display: block;
      position: absolute;
    }
    &:checked {
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid #2e90fa;
        border-top: 0;
        border-left: 0;
        left: 3.75px;
        top: 2px;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
