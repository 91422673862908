<script setup>
const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
  exclude_keys: {
    type: Array,
    default: () => [],
  },
});

const meta_key = computed(() => {
  const key = Object.keys(props.activity.meta).filter(key => !props.exclude_keys.includes(key))[0];
  return key;
});
const has_meta_from = computed(() => {
  return !!props.activity.meta?.[meta_key.value]?.from;
});
const has_meta_to = computed(() => {
  return !!props.activity.meta?.[meta_key.value]?.to;
});
</script>

<template>
  <div class="flex items-center flex-wrap">
    <slot v-if="props.activity" name="action" :action="{ verb: activity.verb, has_meta_from, has_meta_to }" />
    <slot v-if="has_meta_from" name="from" :from="{ key: meta_key, value: props.activity.meta[meta_key].from }" />
    <slot
      v-if="has_meta_to"
      name="to" :to="{ key: meta_key, value: props.activity.meta[meta_key].to }"
    />
    <slot v-if="!has_meta_to && !has_meta_from" name="meta" :meta="{ key: meta_key, items: props.activity.meta[meta_key] }" />
  </div>
</template>
