<script setup>
import { computed, watch } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useTerraStore } from '~/terra/store/terra.store';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import { useCustomViewStore } from '~/common/stores/custom-view.store';
import PivotTable from '~/common/components/organisms/hawk-pivot-table/hawk-pivot-table.vue';
import { usePivotTableComposable } from '~/terra/utils/pivot-table.composable.js';
import { useHawkPivotTableHelperComposable } from '~/common/components/organisms/hawk-pivot-table/helper-composable.js';

const emit = defineEmits(['close']);
const pivot = ref(null);
const fusion_chart_state = ref(null);

const terra_store = useTerraStore();
const custom_view_store = useCustomViewStore();

const { common_store, $t, route } = useCommonImports();
const { getProjectOptions } = useTerraHelperComposable();
const { getPivotTableData } = usePivotTableComposable();
const { preProcessData } = useHawkPivotTableHelperComposable();

const state = reactive({
  features_data: {},
  projects_loading: false,
  data_source_loading: false,
  initial_load: true,
  show_charts: null,
  form: {
    projects: [],
  },
  is_view_changed: false,
  data_source_options: [
    { value: 'feature_properties', label: $t('Feature properties') },
    { value: 'progress_history', label: $t('Progress history') },
    { value: 'progress_data', label: $t('Progress data') },
  ],
  force_update_form: 0,
});

const projects_list = computed(() => getProjectOptions());
const pivot_table_data_source = computed(() => preProcessData(Object.values(state.features_data)));
const custom_view_data = computed(() => custom_view_store.views_map[route.query?.view_uid]?.data);
const initial_form_data = computed(() => ({
  data_source: custom_view_data.value?.pivot_config?.data_source || 'feature_properties',
  projects: custom_view_data?.value?.pivot_config?.selected_projects || terra_store.active_projects.map(item => item.uid) || [],
}));

watch(() => ({ view_uid: route.query?.view_uid, is_data_loaded: !terra_store.is_loading }), async (curr, prev) => {
  // When view is changed
  if (curr.view_uid !== prev.view_uid)
    state.is_view_changed = true;

  // when data is loaded and view is changed select projects
  if (curr.is_data_loaded && state.is_view_changed) {
    state.form = initial_form_data.value;
    // We force update form to fetch data from project select updateForm trigger
    state.force_update_form++;
  }
}, { deep: true });

function updatePivotTableData(set_report) {
  if (set_report || ((state.initial_load || state.is_view_changed) && custom_view_data?.value?.pivot_config))
    pivot.value.flexmonster.setReport({
      options: {
        grid: {
          showHeaders: false,
        },
        configuratorButton: false,
      },
      ...(set_report ? {} : custom_view_data?.value?.pivot_config?.table_settings),
      dataSource: pivot_table_data_source.value,
    });
  else
    pivot.value.flexmonster.updateData(pivot_table_data_source.value);
  state.initial_load = false;
  state.is_view_changed = false;
}

async function getData(set_report = false) {
  try {
    state.features_data = await getPivotTableData({
      container: route.params.id,
      projects: state.form.projects,
      data_source: state.form.data_source,
    });
    updatePivotTableData(set_report);
  }
  catch (err) {
    logger.log(err);
  }
}

async function selectProjects(data) {
  state.projects_loading = true;
  if (state.initial_load) {
    state.form = initial_form_data.value;
    terra_store.pivot_config = {
      ...terra_store.pivot_config,
      ...state.form,
    };
  }
  else {
    state.form.projects = data;
  };
  await getData();
  terra_store.pivot_config.selected_projects = data;
  state.projects_loading = false;
}

async function selectDataSource(value) {
  state.data_source_loading = true;
  state.form.data_source = value;
  await getData(true);
  terra_store.pivot_config.data_source = value;
  terra_store.terra_track_events(`Analyze - ${value.split('_').join(' ')}`);

  state.data_source_loading = false;
}

function toggleOptions(el$) {
  if (el$?.input?.isOpen)
    el$?.input?.close();
  else
    el$?.input?.open();
}
</script>

<template>
  <div>
    <PivotTable
      container_class="h-[calc(100vh-135px)]"
      :pivot_table_data="pivot_table_data_source.data"
      :pivot_table_mapping="pivot_table_data_source.mapping"
      :hide_header_options="!terra_store?.check_permission('modify_pivot') ? ['Settings'] : []"
      :export_file_name="terra_store?.container?.name"
      @pivotInstance="pivot = $event"
      @pivotConfig="terra_store.pivot_config = { ...terra_store.pivot_config, table_settings: { ...$event?.flexmonster } };"
      @updateActiveChart="$event && terra_store.terra_track_events('Analyze - charts')"
      @exported="terra_store.terra_track_events('Analyze - exported', $event)"
    >
      <template #header-left>
        <div>
          <Vueform
            :key="state.force_update_form"
            v-model="state.form"
            sync size="md"
            :columns="{
              md: {
                container: 12,
                wrapper: 12,
              },
            }" :display-errors="false"
          >
            <div class="col-span-12 flex gap-3">
              <SelectElement
                name="data_source"
                class="w-[300px]"
                :loading="state.data_source_loading"
                :items="state.data_source_options"
                :native="false"
                :can-clear="false"
                :can-deselect="false"
                @select="selectDataSource"
              >
                <template #single-label="{ value }">
                  <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                    <div class="text-sm text-gray-900 font-medium">
                      <span class="text-gray-600 font-normal">
                        {{ $t('Data') }}:
                      </span>
                      {{ value.label }}
                    </div>
                  </div>
                </template>
              </SelectElement>
              <HawkTreeSelect
                :options="{
                  'name': 'projects',
                  'placeholder': 'Select Project',
                  'can-clear': false,
                }"
                class="w-[300px]"
                :add_classes="{
                  TagsElement: {
                    select: {
                      tags: '!hidden',
                      dropdown: '!overflow-y-auto',
                      options: '!none !hidden !h-0',
                      noOptions: '!none !hidden !h-0',
                      noResults: '!none !hidden !h-0',
                      wrapper: 'overflow-hidden max-h-[36px]',
                    },
                  },
                }"
                select_type="LEAF_PRIORITY"
                children_key="projects"
                label_key="name"
                value_key="uid"
                :loading="state.projects_loading"
                :data="projects_list"
                :initial_state="initial_form_data.projects"
                @updateForm="selectProjects"
              >
                <template #after="{ el }">
                  <div v-if="state.form.projects.length" class="text-sm text-gray-900 font-medium absolute -mt-7 ml-3 cursor-pointer" @click="toggleOptions(el)">
                    <span class="text-gray-600 font-normal">{{ $t('Projects') }}</span>:
                    {{ state.form.projects.length }} {{ $t('selected') }}
                  </div>
                </template>
              </HawkTreeSelect>
            </div>
          </Vueform>
        </div>
      </template>
    </PivotTable>
  </div>
</template>
