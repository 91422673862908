<script setup>
// other
import { isEmpty } from 'lodash-es';

// stores
import { useDocumentStore } from '~/dms/store/document.store.js';

// composables
import { useDocumentGlobalActions } from '~/dms/composables/document-global.composable';

// components
import DocumentUploadDropdown from '~/dms/components/atoms/document-upload-dropdown.vue';
import HawkDndArea from '~/common/components/organisms/hawk-dnd-area.vue';
import DocumentTable from '~/dms/components/documents/table/document-table.vue';

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['fileDropped']);

const document_store = useDocumentStore();
const document_global_actions = useDocumentGlobalActions();

const state = reactive({
  is_initial_loading: false,
});

onBeforeMount(async () => {
  document_store.$reset();
  await getData();
});

async function getData(initial_loading = true) {
  state.is_initial_loading = initial_loading;
  await document_global_actions.getDocuments({
    query: props.options.query,
  });
  state.is_initial_loading = false;
}

function fileDropHandler(e) {
  if (props.options.is_drag_and_drop)
    emit('fileDropped', e);
}
</script>

<template>
  <div>
    <HawkPageHeader :title="$t('Files')" class="!px-0">
      <template #right>
        <div class="flex content-between">
          <slot name="dropdown" :options="props.options">
            <DocumentUploadDropdown
              :is_widget="true"
              :options="props.options"
            />
          </slot>
        </div>
      </template>
    </HawkPageHeader>
    <HawkLoader v-if="state.is_initial_loading" />
    <template v-else>
      <component :is="props.options.is_drag_and_drop && !isEmpty(document_store.documents) ? HawkDndArea : 'div'" :on_file_drop="fileDropHandler">
        <DocumentTable
          :documents="document_store.documents"
          :options="props.options"
          :is_widget="true"
          :refresh_documents="getData"
        >
          <template #context_menu="{ menu_item }">
            <slot name="context_menu" :menu_item="menu_item" />
          </template>
          <template #files_vue_form="{ options: form_options }">
            <slot name="files_vue_form" :options="form_options" />
          </template>
        </DocumentTable>
      </component>
    </template>
  </div>
</template>
