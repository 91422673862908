<script>
import { h } from 'vue';
import HawkMembers from '~/common/components/organisms/hawk-members-badge/hawk-members.vue';

export default {
  props: {
    activity: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const get_member_render_fn = members => h(HawkMembers, { members, type: 'badge', max_badges_to_display: 1, class: 'ml-2' });

    function getReviewedDocumentText({ transmittal, document, review, workflow_step, unlock }) {
      if (workflow_step?.name)
        if (review.comment)
          return `marked the document  as ${review.status_label} with a comment ${review.comment} for ${workflow_step?.name}`;
        else
          return `marked the document  as ${review.status_label} for ${workflow_step.name}`;
      else
        if (review.comment)
          return `marked the document  as ${review.status_label} with a comment ${review.comment}`;
        else
          return `marked the document  as ${review.status_label}`;
    }

    const TRANSMITTAL_ACTIVITY_VERB_MAP = {
      CREATED: ({ transmittal }) => `created a new transmittal ${transmittal?.number}`,
      UPDATED: ({ transmittal }) => `updated the transmittal ${transmittal?.number}`,
      CLOSED: ({ transmittal }) => `closed the transmittal ${transmittal?.number}`,
      DELETED: ({ transmittal }) => `deleted the transmittal ${transmittal?.number}`,
      TRANSMITTED: ({ transmittal }) => `transmitted ${transmittal?.number}`,
      PUBLISHED: ({ transmittal }) => `published the transmittal ${transmittal?.number}`,
      CANCELLED: ({ transmittal }) => `cancelled the transmittal ${transmittal?.number}`,
      MESSAGED: ({ transmittal }) => `sent a message for the transmittal ${transmittal?.number}`,
      TRANSMITTAL_STARTED: ({ transmittal }) => `transmitted the file in the transmittal ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_CANCELLED: ({ transmittal }) => `cancelled the file in the transmittal ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_COMPLETED: ({ transmittal }) => `completed the file in the transmittal ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_SYNCED: ({ transmittal }) => `synced the file from the transmittal ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_PAUSED: ({ transmittal }) => `paused the file from the transmittal ${transmittal?.number} ${transmittal?.name}`,
      TRANSMITTAL_RESUMED: ({ transmittal }) => `resumed the file from the transmittal ${transmittal?.number} ${transmittal?.name}`,
    };

    const TRANSMITTAL_DOCUMENT_ACTIVITY_VERB_MAP = {
      VIEWED: () => 'viewed the document ',
      UPDATED: () => 'updated the document ',
      TRANSMITTAL_PUBLISHED: () => 'published the document ',
      SUBMITTED: ({ submission }) => submission?.comment ? `submitted the document  with comment ${submission?.comment}` : 'submitted the document ',
      REVIEWED: getReviewedDocumentText,
      UNCANCELLED: ({ cancellation }) => `un-cancelled the document  with a message ${cancellation?.message}`,
      CANCELLED: ({ cancellation }) => `cancelled the document  with a message ${cancellation?.message}`,
      SYNCED: ({ synced }) => `synced the document with a message ${synced?.message}`,
      DELEGATED: ({ delegation }) => [`delegated the document for ${delegation?.for} to `, get_member_render_fn(delegation?.user)],
      REVIEWERS_ADDED: ({ additional_reviews }) => ['requested review for the document  from ', get_member_render_fn(additional_reviews?.consolidator), get_member_render_fn(additional_reviews?.reviewers)],
      REVIEWERS_REMOVED: ({ additional_reviews }) => ['revoked the request to review from ', get_member_render_fn(additional_reviews?.consolidator), get_member_render_fn(additional_reviews?.reviewers)],
      REVIEW_UNLOCKED: ({ unlocked, workflow_step }) => workflow_step?.name ? ['unlocked the ', get_member_render_fn(unlocked?.for_user), `review with a message ${unlocked?.message} for ${workflow_step?.name}`] : ['unlocked the ', get_member_render_fn(unlocked?.for_user), ` review with a message ${unlocked?.message}`],
      REVIEW_REOPENED: ({ reopened, workflow_step }) => workflow_step?.name ? ['reopened the ', get_member_render_fn(reopened?.for_user), ` review with a message ${reopened?.message} for ${workflow_step?.name}`] : ['reopened the ', get_member_render_fn(reopened?.for_user), ` review with a message ${reopened?.message}`],
      WORKFLOW_OUTCOME: ({ outcome }) => `The final outcome for the document is ${outcome}`,
      WORKFLOW_STEP_OUTCOME: ({ outcome, workflow_step }) => `The ${workflow_step?.name} for the document is ${outcome}`,
      SUB_WORKFLOW_STARTED: () => 'started a sub-workflow for the document ',
      SUB_WORKFLOW_COMPLETED: () => 'completed a sub-workflow for the document ',
      SUB_WORKFLOW_CANCELLED: () => 'cancelled the sub-workflow',
    };

    function getDescriptionText() {
      if (props.activity.resource_name === 'TRANSMITTAL' || props.activity.resource_name === 'FILE')
        return TRANSMITTAL_ACTIVITY_VERB_MAP[props?.activity?.verb] ? TRANSMITTAL_ACTIVITY_VERB_MAP[props?.activity?.verb](props?.activity?.meta) : '';
      else
        return TRANSMITTAL_DOCUMENT_ACTIVITY_VERB_MAP[props?.activity?.verb] ? TRANSMITTAL_DOCUMENT_ACTIVITY_VERB_MAP[props?.activity?.verb](props?.activity?.meta) : '';
    }
    const text = getDescriptionText();
    // return the render function
    return () => h('div', { class: 'flex items-center' }, text);
  },
};
</script>
