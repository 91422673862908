<script setup>
import SmComponentName from '~/system-model/atoms/sm-component-name.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  show_no_badge: {
    type: Number,
    default: 5,
  },
});

const system_model_store = useSystemModelStore();

const tooltip_components = computed(() => {
  return props.items.slice(props.show_no_badge)?.map(uid => system_model_store.templates_components_map[uid]?.name)?.join(', ');
});
</script>

<template>
  <div>
    <template v-for="(item, index) in items" :key="item">
      <SmComponentName v-if="index < show_no_badge" :uid="item" />
    </template>
    <hawk-badge v-if="items.length > show_no_badge" v-tippy="tooltip_components" custom_classes="border !border-gray-300 rounded-lg">
      + {{ items.length - show_no_badge }}
    </hawk-badge>
  </div>
</template>
