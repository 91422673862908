export function getDefaultView($t) {
  return {
    uid: '__default',
    data: {
      label: 'Default',
      grid_width: 760,
      group_by: [],
      layout: 'grid-timeline',
      resource_view: false,
      wbs_level_max: Number.MAX_SAFE_INTEGER,
      wbs_level: Number.MAX_SAFE_INTEGER,
      zoom_level: 2,
      feature_visibility: {
        activity_name: true,
        links: false,
        percentage_complete: true,
        float: false,
        today_line: true,
        data_date_line: true,
        overdue: true,
        critical_path: false,
        baseline: false,
      },
      columns: [
        ['left', 'general', $t('WBS'), 'wbs', true, false, 'text', 65, 75],
        ['left', 'general', $t('Activity'), 'name', true, true, 'text', 250, 300],
        ['center', 'dates', $t('Start'), 'start_date', true, false, 'date', 90, 130],
        ['center', 'dates', $t('Finish'), 'end_date', true, false, 'date', 90, 130],
        ['center', 'durations', $t('Duration'), 'total_duration', true, false, 'number', 75, 100],
        ['center', 'percentages', $t('Progress'), 'progress', true, false, 'number', 80, 100],
      ].map(column =>
        ['align', 'group', 'label', 'name', 'resize', 'tree', 'type', 'width', 'min_width']
          .reduce((acc, cur, index) => {
            acc[cur] = column[index];
            return acc;
          }, {}),
      ),
      active_date_range: {
        from: undefined,
        to: undefined,
      },
      filters: {
        comments: false,
        critical: false,
        milestone: false,
        statuses: [],
        priorities: ['high', 'medium', 'low'],
        resources: [],
        progress: [0, 100],
        date_range: [null, null],
      },
    },
  };
}
