<script setup>
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';

import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';

const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  task_data: {
    type: Object,
    default: () => {
      return {};
    },
  },
  on_cancel: {
    type: Function,
    required: false,
    default: () => ({}),
  },
  task_type: {
    type: String,
    default: 'task',
  },
  allow_multi_task_create: {
    type: Boolean,
    default: false,
  },
  parent_task: {
    type: Object,
    default: () => {},
  },
  modal_options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close', 'taskCreated']);

const $toast = inject('$toast');

const route = useRoute();
const router = useRouter();

const auth_store = useAuthStore();
const common_store = useCommonStore();

const current_organization = auth_store.current_organization;

function getTargetElement() {
  if (route.params.asset_id)
    return common_store.active_asset.element;
  return current_organization?.element;
}

function viewTaskDetailsHandler(id) {
  const task = btoa(JSON.stringify({
    id,
    store_key: props.task_store.$id,
  }));
  router.push({
    ...route,
    query: {
      ...route.query,
      task,
    },
  });
}

async function on_submit(data) {
  try {
    let payload = [];

    const target_element = getTargetElement();

    if (data.has_multiple_tasks)
      payload = data.payload.map((task_obj) => {
        return { ...task_obj, target_element };
      });
    else
      payload = [{ ...data, target_element }];

    const res = await props.task_store.create_tasks({ tasks: payload }, { method: 'Direct' });
    if (res instanceof Error)
      throw res;

    if (!data.has_multiple_tasks)
      viewTaskDetailsHandler(Object.keys(res)[0]);

    emit('taskCreated', res);
  }
  catch (error) {
    $toast({
      title: 'Task creation failed',
      text: 'Please try again',
      type: 'error',
    });
  }
  emit('close');
}
</script>

<template>
  <TaskForm v-bind="{ ...props, on_submit }" @close="emit('close')" />
</template>
