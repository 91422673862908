<script>
import { h } from 'vue';

export default {
  props: {
    text: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    return () => h('p', props.text());
  },
};
</script>
