<script setup>
import FormStatus from '~/forms/atoms/form-status.vue';

const props = defineProps({
  data: {
    type: Array,
    default: () => ([]),
  },
});

const emit = defineEmits(['clicked', 'click']);
</script>

<template>
  <div v-for="(item, index) in data" :key="index">
    <FormStatus :item="item" @click="emit('clicked', item)" />
  </div>
</template>
