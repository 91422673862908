import { stringToNode } from '~/dashboard/print/utilities.js';
import { createTableWidget } from '~/dashboard/print/charts/table.js';

function createCombinedTable(chart, index) {
  const table = createTableWidget(chart);
  const h3Elements = table.getElementsByTagName('h3');
  while (h3Elements.length > 0)
    h3Elements[0].parentNode.removeChild(h3Elements[0]);

  const fusion_chart = `<div class="fusion-chart " id="${chart.dataSource.graph_config.renderAt}"></div>`;

  const chart_container = stringToNode(`
    <div class="dashboard-row ">
      <div class="dashboard-row__chart ">
        <h3 class="dashboard-row__name">
          ${chart.chart_name}
        </h3>
        ${fusion_chart}
        </div>
    </div>
  `);

  chart_container.appendChild(table);

  return chart_container;
}

export {
  createCombinedTable,
};
