import Pod from '~/third-party-widgets/pages/pod.vue';
import Pod2 from '~/third-party-widgets/pages/pod-2.vue';
import SolarLabs from '~/third-party-widgets/pages/solar-labs.vue';
import Eanalytix from '~/third-party-widgets/pages/eanalytix.vue';
import freshworksAuth from '~/third-party-widgets/pages/freshworks-auth.vue';
import AuthorizeIntegration from '~/third-party-widgets/pages/authorize-integration.vue';

const routes = [
  {
    path: '/:asset_id/pod',
    name: 'pod',
    component: Pod,
    meta: {
      title: 'POD Report',
    },
  },
  {
    path: '/:asset_id/pod-2',
    name: 'pod-2',
    component: Pod2,
    meta: {
      title: 'POD Report',
    },
  },
  {
    path: '/solar-labs',
    name: 'solar-labs',
    component: SolarLabs,
    meta: {
      title: 'Solar Labs',
    },
  },
  {
    path: '/:asset_id/eanalytix',
    name: 'eanalytix',
    component: Eanalytix,
    meta: {
      title: 'Eanalytix',
    },
  },
  {
    path: '/freshworks/sign-in',
    name: 'freshworks-auth',
    component: freshworksAuth,
    meta: {
      layout: 'auth',
      title: 'Freshworks auth',
    },
  },
  {
    path: '/auth/integrations/:type/authorize',
    name: 'authorize-integration',
    component: AuthorizeIntegration,
    meta: {
      layout: 'auth',
    },
  },
];
export default routes;
