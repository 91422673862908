<script setup>
const props = defineProps({
  options: {
    type: Object,
    default: () => {},
  },
  fields: {
    type: Array,
    default: () => [],
  },
  fieldvalues_map: {
    type: Object,
    default: () => {},
  },
});

const $t = inject('$t');
const components = ref([]);

onMounted(() => {
  createComponentList();
});

function getComponentOptions(type) {
  let options = {};

  switch (type) {
    case 'labels':
    case 'checkbox':
      options = {
        component: 'CheckboxgroupElement',
        additional_options: fields_value => ({ items: fields_value }),
      };
      break;
    case 'radio':
      options = {
        component: 'RadiogroupElement',
        additional_options: fields_value => ({ items: fields_value }),
      };
      break;
    case 'text':
    case 'email':
    case 'url':
    case 'number':
      options = {
        component: 'TextElement',
        additional_options: (_, properties, type) => ({
          inputType: type,
          ...(properties?.currency || type === 'number'
            ? {
                attrs: { min: 0 },
                rules: 'nullable|integer|regex:/^[0-9]+$/',
                messages: {
                  integer: 'This field must be an integer.',
                  regex: 'This field must be an integer.',
                },
              }
            : {}),
          ...(type === 'email' ? { rules: 'nullable|email' } : {}),
          ...(type === 'url' ? { rules: 'nullable|url' } : {}),
        }),
      };
      break;
    case 'dropdown':
      options = {
        component: 'SelectElement',
        additional_options: (fields_value, properties) => ({
          items: fields_value,
          search: !properties.radio,
          native: false,
        }),
      };
      break;
    case 'date':
      options = {
        component: 'DateTimeElement',
        additional_options: () => ({
          options: {
            'format': 'yyyy-MM-dd',
            'model-type': 'yyyy-MM-dd',
            'placeholder': $t('Select date'),
          },
        }),
      };
      break;
    case 'file':
      options = {
        component: 'MultifileElement',
        additional_options: () => ({
          presets: ['hawk_file_element'],
          drop: true,
          use_uppy: true,
          auto: false,
          options: {
            clickable_text: $t('Click to upload'),
            text: $t('or drag and drop'),
          },
          events: {
            mounted: el$ => filesMounted(el$, default_field_value),
          },
        }),
      };
      break;
  }

  return options;
}

function createComponentList() {
  const custom_fields = props.fields;
  components.value = custom_fields.map((element) => {
    const { type, uid, name, config, properties } = element;
    let fields_value = [];

    if (Array.isArray(config))
      fields_value = config.map(field => ({ value: field.uid, label: field.name }));

    const default_field_value = props.fieldvalues_map?.[uid]?.value;

    const baseComponent = {
      name: uid,
      label: sanitizer(name),
      default: default_field_value,
    };
    const type_check = type === 'dropdown' && properties?.radio ? 'radio' : type;
    const { component, additional_options } = getComponentOptions(type_check);
    const options = {
      ...baseComponent,
      ...additional_options(fields_value, properties, type_check),
    };

    return {
      component,
      options,
    };
  });
}

function sanitizer(data) {
  const div_element = document.createElement('div');
  const text_node = document.createTextNode(data);
  div_element.appendChild(text_node);
  return div_element.innerHTML;
}

function filesMounted(el$, files) {
  if (files) {
    const format_files = files.map((file) => {
      const service = file?.service || {};
      service.url = file?.pre_signed_url;
      return { ...file, service };
    });
    el$.load(format_files);
  }
}
</script>

<template>
  <ObjectElement
    v-bind="options"
  >
    <component
      :is="component.component"
      v-bind="component.options"
      v-for="component in components"
      :key="component.name"
      v-on="component?.events || {}"
    />
  </ObjectElement>
</template>
