<script setup>
import { useModal } from 'vue-final-modal';
import { flatten } from 'lodash-es';
import { useTerraStore } from '~/terra/store/terra.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import NewLayerPopup from '~/terra/components/self-service/new-map-popup.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  group: { type: Object },
  terra_store_name: {
    type: String,
    default: 'terra',
  },
  side: {
    type: String,
  },
});

const emit = defineEmits(['groupOrProjectToggled']);
const $t = inject('$t');
const $services = inject('$services');

const new_layer_popup = useModal({
  component: NewLayerPopup,
  attrs: {
    is_layer: true,
    onClose() {
      new_layer_popup.close();
    },
  },
});
const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      delete_popup.close();
    },
  },
});

const { preProcessGeojson, exportGeoJson } = useTerraHelperComposable();

const authStore = useAuthStore();
const common_store = useCommonStore();
const terra_store = useTerraStore(props.terra_store_name);
const route = useRoute();

const state = reactive({
  opened_project: [],
  project_menu_opened: '',
});

const asset_name = computed(() => common_store.active_asset?.name || 'NA');
const projects = computed(() => {
  return Object.values(terra_store.container?.groups || {}).reduce((acc, curr) => {
    acc.push(...Object.values(curr.projects || {}));
    return acc;
  }, []);
});
const active_projects_map = computed(() => terra_store.active_projects_map);

function openProject(uid) {
  if (state.opened_project.includes(uid)) {
    const a = state.opened_project.indexOf(uid);
    if (a > -1)
      state.opened_project.splice(a, 1);
  }
  else {
    state.opened_project.push(uid);
  }
}
async function toggleProject(project) {
  try {
    terra_store.is_loading = true;
    const active = !(terra_store.active_projects_map[project.uid].ortho_enabled && terra_store.active_projects_map[project.uid].features_enabled);

    terra_store.active_projects_map[project.uid] = {
      ortho_enabled: active,
      features_enabled: active,
    };
    await terra_store.set_projects_essentials({
      projects: [project],
    });
    const inactive_projects = Object.values(terra_store.container.groups[project.group].projects).filter(val => !(terra_store.active_projects_map[val.uid].ortho_enabled || terra_store.active_projects_map[val.uid].features_enabled));
    terra_store.active_group_map[project.group] = {
      ortho_enabled: inactive_projects.length === 0,
      features_enabled: inactive_projects.length === 0,
    };

    if (active)
      await terra_store.fly_to_project({ project });

    terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
    await terra_store.update_map_features_and_polygon();
    terra_store.selected_features = [];
    terra_store.terra_track_events('Layers filtered');

    emit('groupOrProjectToggled');
    terra_store.is_loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ file: projects-list.vue:114 ~ toggleProject= ~ error:', error);
    terra_store.is_loading = false;
  }
}
async function downloadReport(project) {
  try {
    if (!terra_store.projects_details_reports_map[project.uid]) {
      await terra_store.set_projects_details({ projects: [project] });
      terra_store.set_terra_projects_details_for_reports({ projects: [project] });
    }

    if (
      terra_store.projects_details_reports_map[project.uid]
    ) {
      const url = new URL(terra_store.projects_details_reports_map[project.uid]?.report.url);
      const filename_extension = url.searchParams.get('k').split('.')[1];
      const filename = `${
      authStore.current_organization.name
    } - ${asset_name.value} - ${project.name.replaceAll(
      '/',
      '-',
    )}.${filename_extension}`;
      url.searchParams.set('filename', filename);
      window.open(url.href, '_blank');
    }
  }
  catch (error) {
    logger.log('🚀 ~ file: projects-list.vue:141 ~ downloadReport ~ error:', error);
  }
}
async function toggle_ortho_or_features(project, type) {
  try {
    terra_store.is_loading = true;

    if (type === 'ortho')
      terra_store.active_projects_map[project.uid].ortho_enabled = !terra_store.active_projects_map[project.uid].ortho_enabled;

    else terra_store.active_projects_map[project.uid].features_enabled = !terra_store.active_projects_map[project.uid].features_enabled;
    await terra_store.set_projects_essentials({
      projects: [project],
    });
    terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
    await terra_store.update_map_features_and_polygon();

    if (terra_store.active_projects_map[project.uid].ortho_enabled || terra_store.active_projects_map[project.uid].features_enabled)
      await terra_store.fly_to_project({ project });
    terra_store.is_loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ file: projects-list.vue:166 ~ toggle_ortho_or_features= ~ error:', error);
    terra_store.is_loading = false;
  }
}

function getProjectMenuItems(project) {
  return [
    {
      label: $t('Rename'),
      uid: 'rename',
      show: terra_store.check_permission('modify_projects'),
      on_click: () => {
        new_layer_popup.patchOptions({
          attrs: {
            is_layer: true,
            onClose() {
              new_layer_popup.close();
            },
            name: project.name,
            header: 'Rename',
            popup_type: 'rename',
            async on_save({ name }) {
              await terra_store.update_projects({
                action: 'update',
                payload: { name },
                group_uid: props.group.uid,
                project_uid: project.uid,
              });
              terra_store.terra_track_events('Updated project');
              new_layer_popup.close();
            },
          },
        });
        new_layer_popup.open();
      },
    },

    {
      label: $t('Import'),
      uid: 'reimport_merge',
      show: terra_store.check_permission('modify_projects') || terra_store.check_permission('modify_features'),
      on_click: () => {
        new_layer_popup.patchOptions({
          attrs: {
            name: project.name,
            header: 'Import',
            popup_type: 'import',
            async on_save(data) {
              try {
                const geojson_data = preProcessGeojson(data.geojson_data);
                const update_features = [];
                const create_features = [];
                geojson_data.features.forEach((feature) => {
                  if (data.merge_features && terra_store.features_hash[feature?.properties?.uid]) {
                    update_features.push(feature);
                  }
                  else {
                    delete feature.properties?.uid;
                    delete feature.properties?.project;
                    create_features.push(feature);
                  }
                  feature.properties.project = project.uid;
                });
                terra_store.selected_features = update_features;
                if (update_features.length)
                  await terra_store.create_or_update_selected_features({ clearSelectedFeatures: true, updateFeatureRequest: true });
                terra_store.selected_features = create_features;
                if (create_features.length)
                  await terra_store.create_or_update_selected_features({ clearSelectedFeatures: true, updateFeatureRequest: true });
                terra_store.terra_track_events('Imported features', { classified: !!data.categorize_by_property });
                terra_store.terra_track_events('Layer import', { classified: !!data.categorize_by_property });
                new_layer_popup.close();
              }
              catch (error) {
                logger.log(error);
              }
            },
            onClose() {
              new_layer_popup.close();
            },
          },
        });
        new_layer_popup.open();
      },
    },
    {
      label: $t('Export'),
      uid: 'export',
      show: true,
      on_click: () => {
        const features = flatten(Object.values(terra_store.projects_features_map[project.uid] || {}));
        exportGeoJson(features, 'from_layers');
        terra_store.terra_track_events('Exported features');
        terra_store.terra_track_events('Layer Export');
      },
    },
    {
      label: $t('Delete'),
      uid: 'delete',
      show: terra_store.check_permission('modify_projects'),
      on_click: () => {
        delete_popup.patchOptions({
          attrs: {
            onClose() {
              delete_popup.close();
            },
            header: project.name,
            content: 'Are you sure you want to delete the layer?',
            show_toast: true,
            confirm: async () => {
              try {
                await terra_store.update_projects({
                  action: 'delete',
                  group_uid: props.group.uid,
                  project_uid: project.uid,
                });
                terra_store.terra_track_events('Layer delete');
              }
              catch (error) {
                logger.log(error);
              }
              delete_popup.close();
            },
          },
        });
        delete_popup.open();
      },
    },
  ].filter(menu => menu.show);
}
</script>

<template>
  <div>
    <div
      v-for="(project, index) in group.projects"
      :key="index"
    >
      <div class="group/item ml-3">
        <div class="flex justify-between items-center h-8">
          <HawkCheckbox :id="`project_checkbox_${index}_${side}`" :model-value="active_projects_map[index]?.features_enabled" @input="toggleProject(project)">
            <template v-if="project.properties && project.properties.date">
              <HawkText :content="$date(project.properties.date, 'DATE_MED')" />
            </template>
            <template v-else>
              <HawkText :content="project.name" />
            </template>
          </HawkCheckbox>

          <div class="flex items-center justify-end">
            <IconHawkDownloadOne
              v-if="
                terra_store.check_permission('download_reports')
                  && project.reports
                  && project.reports.report
              "
              class="text-sm w-4 mr-2 cursor-pointer invisible group-hover/item:visible"
              @click="downloadReport(project)"
            />
            <HawkMenu
              v-if="terra_store_name === 'terra'"
              :class="[state.project_menu_opened === index ? '!visible' : 'invisible group-hover/item:visible']"
              :items="getProjectMenuItems(project)"
              :has_bordered_trigger="false"
              additional_trigger_classes="!ring-0 p-0 w-7.5 h-5"
              additional_dropdown_classes="-ml-[190px] !w-[208px]"
              position="fixed"
              @open="state.project_menu_opened = index"
              @close="state.project_menu_opened = ''"
            />
            <div
              class="rounded-md cursor-pointer"
              @click.stop="openProject(index)"
            >
              <IconHawkChevronUp v-if="state.opened_project.includes(index)" />
              <IconHawkChevronDown v-else />
            </div>
          </div>
        </div>
        <div
          v-show="state.opened_project.includes(index)"
          class="ml-3 my-1"
        >
          <HawkCheckbox :id="`project_checkbox_${index}_ortho_${side}`" :model-value="active_projects_map[index]?.ortho_enabled" @input="toggle_ortho_or_features(project, 'ortho')">
            {{ $t('Map') }}
          </HawkCheckbox>
          <HawkCheckbox :id="`project_checkbox_${index}_features_${side}`" class="mt-1" :model-value="active_projects_map[index]?.features_enabled" @input="toggle_ortho_or_features(project, 'features')">
            {{ $t('Features') }}
          </HawkCheckbox>
        </div>
      </div>
    </div>
  </div>
</template>
