import Dashboard from '~/dashboard/pages/dashboard.vue';

const routes = [
  {
    path: '/:asset_id?/dashboard',
    name: 'asset-dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    },
  },
  // {
  //   path: '/dashboard',
  //   name: 'organization-dashboard',
  //   component: Dashboard,
  //   meta: {
  //     title: 'Dashboard',
  //   },
  // },
  // {
  //   path: '/:asset_id?/dashboard/widget-form',
  //   name: 'asset-dashboard-form',
  //   component: DashboardWidgetForm,
  //   meta: {
  //     title: 'Widget Form',
  //   },
  // },
];
export default routes;
