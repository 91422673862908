import { acceptHMRUpdate, defineStore } from 'pinia';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';

import { setCookie } from '~/common/utils/common.utils';

export const useI18nStore = defineStore('i18n', {
  persist: true,
  state: () => ({
    current_lang: 'en',
    retry: 0,
    i18n_map: {},
  }),
  getters: {
    is_development() { return import.meta.env.VITE_APP_ENV === 'development'; },
    $t(state) {
      return (key) => {
        return state.i18n_map?.[key] || (this.is_development ? 'Translation not available' : key);
      };
    },
  },
  actions: {
    async set_language(language) {
      setCookie('lang', language);
      await this.load_translations(language);
    },
    async load_translations() {
      try {
        const cookies = new Cookies();
        const lang = cookies.get('lang') || 'en';
        this.i18n_map = (await import(`../../../locales/${lang}.json`)).default;
        const locales = {
          en: () => import('dayjs/locale/en'),
          ja: () => import('dayjs/locale/ja'),
          es: () => import('dayjs/locale/es'),
          pt: () => import('dayjs/locale/pt'),
          de: () => import('dayjs/locale/de'),
        };
        await locales[lang]();
        dayjs.locale(lang);
        this.current_lang = lang;
        this.retry = 0;
        return this.i18n_map;
      }
      catch (error) {
        logger.error('%cFailed to change load translations', 'font-weight: bold; color: tomato;');
        if (this.retry < 3)
          this.set_language('en');
        this.retry++;
      }
    },
  },

});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useI18nStore, import.meta.hot));
