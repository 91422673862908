import Assets from '~/assets/pages/assets.vue';
import AssetForm from '~/assets/pages/asset-form.vue';
import ViewsList from '~/assets/pages/asset-therm-terra-views-list.vue';
import MapView from '~/assets/pages/assets-map-view.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Assets,
    meta: {

      title: 'Home',
    },
  },
  {
    path: '/create-asset',
    name: 'create-asset',
    component: AssetForm,
    meta: {
      title: 'Create asset',
    },
  },
  {
    path: '/:asset_id/views-list/:type',
    name: 'views-list',
    component: ViewsList,
    meta: {
      title: 'List',
    },
  },
  {
    path: '/:asset_id/maps-list',
    name: 'maps-list',
    component: MapView,
    meta: {
      title: 'Map View',
    },
  },
];
export default routes;
