<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  save: {
    type: Function,
  },
});
const emit = defineEmits(['close', 'save']);
const { $t } = useCommonImports();
const inventory_store = useInventoryStore();
const state = reactive({
  is_loading: false,
});
const item_details = computed(() => inventory_store.get_active_item_details);

async function setItemStatus() {
  try {
    state.is_loading = true;
    await props.save();
    emit('close');
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
  }
}
</script>

<template>
  <hawk-modal-container :width="400">
    <div class="flex items-center p-4 justify-between text-lg font-semibold text-gray-800">
      <IconHawkTrashOneRedRipple class="w-12 h-12" />
      <HawkButton icon type="text" @click="emit('close')">
        <IconHawkX class="w-6 h-6" />
      </HawkButton>
    </div>
    <hawk-modal-content>
      <p class="font-semibold text-lg mb-1">
        {{ item_details?.is_active ? $t('Mark as inactive') : $t('Mark as active') }}
      </p>
      <span class="text-gray-700 text-sm">
        {{ item_details?.is_active
          ? $t('Items marked as inactive cannot be used in any transactions. Are you sure you want to mark the selected item as inactive?')
          : $t('Items marked as active can be used in transactions. Are you sure you want to mark the selected item as active?') }}
      </span>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-3">
          <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton
            :color="item_details?.is_active ? 'error' : 'primary'"
            :loading="state.is_loading"
            @click="setItemStatus"
          >
            {{ item_details?.is_active ? $t('Mark as inactive') : $t('Mark as active') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
