import print_config from '~/dashboard/print/print_config';
import { stringToNode } from '~/dashboard/print/utilities.js';

const {
  severity_graph_legend,
} = print_config;

function createGraph(chart) {
  const gauge_class = chart.type === 'angulargauge' ? 'fusion-chart--gauge' : '';

  let fusion_chart = null;

  if (chart.dataSource.chart_type === 'severity_graph') {
    fusion_chart = `
          <div class="fusion-chart ${gauge_class}" id="${chart.renderAt}"></div>
          ${severity_graph_legend}
        `;
  }
  else {
    fusion_chart = `<div class="fusion-chart ${gauge_class}" id="${chart.renderAt}"></div>`;
    if (chart.dataSource.table)
      fusion_chart += `<div>${chart.dataSource.table}</div>`;
  }

  chart.dataSource.chart.numVisiblePlot = '0';

  const chart_container = stringToNode(`
    <div class="dashboard-row">
      <div class="dashboard-row__chart">
        <h3 class="dashboard-row__name">
          ${chart.chart_name}  ${chart.dashboard_selected_range ? `| ${chart.dashboard_selected_range?.start}- ${chart.dashboard_selected_range?.end}` : ''}
        </h3>
        ${fusion_chart} 
      </div>
    </div>
  `);

  return chart_container;
}

export {
  createGraph,
};
