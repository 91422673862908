export function useHawkPivotTableHelperComposable() {
  const date_keys = [
    { name: 'Day', format: 'dd MMM yyyy' },
    { name: 'Month', interval: 'M', format: 'MMM yyyy' },
    { name: 'Year', interval: 'y', format: 'yyyy' },
  ];

  function check_date(value) {
    const date_time_regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}.\d{3}Z)?$/;
    return date_time_regex.test(value);
  }
  function getMapping(field, value, mapping) {
    date_keys.forEach((key) => {
      const uniq_name = `${field}$--custom${key.name}$`;
      const caption_name = `${field}.${key.name}`;
      value[uniq_name] = value[field];
      if (!mapping[uniq_name])
        mapping[uniq_name] = {
          folder: field,
          caption: caption_name,
          type: 'date string',
          format: key.format,
          ...(key.interval ? { interval: key.interval } : {}),
        };
    });
    return mapping;
  }
  function preProcessData(data) {
    const date_field_map = {};
    data.forEach((value) => {
      Object.keys(value).forEach((field) => {
        if (date_field_map[field])
          return;
        if (check_date(value[field]))
          date_field_map[field] = true;
      });
      return value;
    });

    // Creating data with date mapping
    let mapping = {};
    const updated_data = [...data].map((value) => {
      Object.keys(date_field_map).forEach((field) => {
        mapping = getMapping(field, value, mapping);
        delete value[field];
      });
      return value;
    });
    logger.log({ data: updated_data, mapping });
    return { data: updated_data, mapping };
  }
  return { preProcessData };
}
