<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  category: {
    type: Object,
    default: () => {},
  },
  // eslint-disable-next-line vue/prop-name-casing
  action_type: {
    type: String,
    default: '',
  },
  from: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close', 'created']);

const $t = inject('$t');
const $toast = inject('$toast');
const $track_event = inject('$track_event');

const auth_store = useAuthStore();
const common_store = useCommonStore();
const route = useRoute();

const form$ = ref(null);
const form = ref({});

const header_title = computed(() => {
  let title = '';
  switch (props?.action_type) {
    case 'add':
      title = $t('Add new category');
      break;
    case 'edit':
      title = $t('Edit Category');
      break;
    case 'add_subcategory':
      title = $t('Add sub-category');
      break;
  }
  return title;
});

function onFormMounted(el$) {
  el$.update({
    name: props?.action_type === 'edit' ? props?.category?.name : null,
    description: props?.action_type === 'edit' ? props?.category?.description : null,
  });
}

async function save() {
  try {
    const payload = { ...form.value };

    if (props.action_type === 'add' || props.action_type === 'add_subcategory') {
      payload.asset = route?.params?.asset_id;
      payload.organization = auth_store.current_organization?.uid;
      payload.parent = props.category?.uid || null;
    }

    const { data } = await common_store.update_data({
      type: props.action_type === 'edit' ? 'patch_update' : 'add',
      ...(props.action_type === 'edit' ? { id: props.category?.uid } : {}),
      data: { category: payload },
      service: 'categories',
      append_data: false,
      response_key: 'categories',
      state_prop: 'categories_map',
      update_state: false,
    });

    if (props.action_type !== 'edit') {
      const where = route.name.includes('settings-categories') ? 'Category Settings' : '';
      $track_event('create_category', {
        subcategory: props.action_type === 'add_subcategory',
        where: props.from ? props.from : where,
      });

      emit('created', data.category);
    }

    if (data?.category) {
      await common_store.update_global_data({ categories: [data?.category?.uid] });
      $toast({
        title: 'Update',
        text: 'Category updated successfully',
        type: 'success',
      });
      emit('close');
    }
  }
  catch (error) {
    $toast({
      title: error?.data?.title || 'Something went wrong',
      text: error?.data?.message || 'Please try again',
      type: 'error',
    });
  }
}
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :endpoint="save"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ header_title }}
          </template>
        </hawk-modal-header>

        <hawk-modal-content class="max-h-[calc(100vh-40vh)] w-[600px] overflow-auto scrollbar ">
          <TextElement
            name="name"
            :label="$t('Name')"
            placeholder="Enter category name"
            class="mb-4"
            :rules="['max:100', 'required']"
          />
          <TextareaElement
            :label="$t('Description')"
            name="description"
            :rules="['max:200']"
            placeholder="Enter description"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600" name="submit" :submits="true"
                :disabled="!auth_store.check_permission('modify_categories', route.params.asset_id)"
              >
                {{ props.action_type === 'edit' ? $t('Update') : $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
