import { sortBy } from 'lodash-es';
import { currencies } from '~/common/utils/constants.ts';
import { all_countries } from '~/common/constants/all-countries.js';
import { useAutoNumberFieldSchema } from '~/forms/composables/form-builder-auto-number-composable';
import { useFormulaFieldSchema } from '~/forms/composables/form-builder-formula-composable';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import FormBuilderInfoModal from '~/forms/components/form-builder/form-builder-info-modal.vue';
import FormFieldCondition from '~/forms/molecules/form-field-condition.vue';
import FormMemberSelect from '~/forms/molecules/form-member-select.vue';
import FormFieldAssociateMetadata from '~/forms/molecules/form-field-associate-metadata.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useI18nStore } from '~/common/stores/i18n.store';



function autonumber_field_suggestions(options) {
  const form_workflow_store = useFormWorkflowStore();

  const { form_template_detail_store, step_number, previous_step_number, field, filterFields } = options;
  const arr = [];
  const all_fields = [];

  if (form_template_detail_store.form_template_detail?.workflow) {
    // Workflow template
    const form_steps = form_template_detail_store.steps_with_sections;
    const ancestral_steps = form_workflow_store.getSteps(previous_step_number || step_number);
    const sorted_step_sections = sortBy(Object.values(form_steps), ['index']).filter(form_step => form_step.index === step_number || ancestral_steps.includes(form_step.index)).flatMap(step => step.sections);
    const section_fields = sorted_step_sections.filter(section => section.status === 'active' && section.type === 'default').flatMap((section) => {
      return section.fields.map(field => ({
        ...field,
        section_name: section.name,
        section_id: section.uid,
        form_block_name: form_steps[section.step_index].name,
        form_block_id: `block_${section.step_index}`,
      }));
    });
    all_fields.push(...section_fields);
  }
  else {
    // Form template
    const section_fields = form_template_detail_store.form_template_detail.sections.filter(section => section.status === 'active' && section.type === 'default').flatMap((section) => {
      return section.fields.map(field => ({ ...field, section_name: section.name, section_id: section.uid }));
    });
    all_fields.push(...section_fields);
  }

  const current_index = all_fields.findIndex(all_field => all_field.uid === field.uid);
  for (let index = 0; index < all_fields.length; index++) {
    const section_field = all_fields[index];
    if (
      filterFields.includes(
        section_field?.properties?.type,
      )
      && current_index
      > index
      && section_field.status === 'active'
      && section_field?.type !== 'auto_number'
    )
      arr.push({ id: section_field.slug, label: section_field.name, ...section_field });
  }

  return {
    extra_suggestions: arr,
    is_workflow: form_template_detail_store.form_template_detail?.workflow,
  };
}

function get_comman_schemas(type, field, $t) {
  const common_schema = type === 'table'
    ? {
        mandatory: {
          type: 'toggle',
          default: true,
          label: $t('Required'),
        },
      }
    : {
        meta: {
          type: 'group',
          schema: {
            name: {
              type: 'textarea',
              fieldName: 'name',
              label: $t('Name'),
              rows: 1,
              rules: ['required'],
            },
            description: {
              type: 'textarea',
              fieldName: 'name',
              label: $t('Description'),
            },
            mandatory: {
              type: 'toggle',
              label: $t('Required'),
            },
          },
        },
      };
  const submit_button_schema = type === 'table'
    ? {}
    : {
        footer_button: {
          type: 'group',
          addClasses: {
            GroupElement: {
              wrapper: ['!flex', 'justify-end'],
            },
          },
          schema: {
            cancel: {
              type: 'button',
              buttonLabel: $t('Cancel'),
              secondary: true,
              resets: true,
              inline: true,
            },
            submit: {
              type: 'button',
              buttonLabel: field.uid ? $t('Save') : $t('Add'),
              inline: true,
              submits: true,
            },
          },
        },
      };

  const condition_type_map = {
    AND: 'all',
    OR: 'any',
  };

  const condition_schema = type === 'table'
    ? {}
    : {
        condition: {
          type: 'object',
          views: {
            field_uid: field.uid,
          },
          default: {
            add_condition: !!field.condition?.type,
            rules: field.condition?.conditions ? field.condition.conditions[condition_type_map[field.condition.type]] : [],
            type: field.condition?.type ?? 'AND',
          },
          slots: {
            before: FormFieldCondition,
          },
          columns: {
            default: { container: 12, label: 5, wrapper: 12 },
            sm: { container: 12, label: 5, wrapper: 12 },
            md: { container: 12, label: 5, wrapper: 12 },
          },
        },
      };

  return {
    common_schema,
    condition_schema,
    submit_button_schema,
  };
}

export function useFieldConfiguration(field, type, section, extra_options = {}) {
  const form_template_detail_store = useFormTemplateDetailStore();
  const auth_store = useAuthStore();
  const i18n_store = useI18nStore();

  const $t = i18n_store.$t || extra_options.$t;

  const getValidationPath = key => type === 'table' ? `group.config.${key}` : `config.${key}`;
  const {
    common_schema,
    condition_schema,
    submit_button_schema,
  } = get_comman_schemas(type, field, $t);
  const is_asset_scoped_template = !!form_template_detail_store.form_template_detail?.target_element?.asset;

  const info_field_schema = {
    meta: {
      type: 'group',
      schema: {
        name: {
          type: 'textarea',
          rows: 1,
          fieldName: 'name',
          label: $t('Name'),
          rules: ['required'],
          columns: {
            default: { container: 12, label: 5, wrapper: 12 },
            sm: { container: 12, label: 5, wrapper: 12 },
            md: { container: 12, label: 5, wrapper: 12 },
          },
        },
        description: {
          type: 'textarea',
          fieldName: 'name',
          label: $t('Description'),
          columns: {
            default: { container: 12, label: 5, wrapper: 12 },
            sm: { container: 12, label: 5, wrapper: 12 },
            md: { container: 12, label: 5, wrapper: 12 },
          },
        },
      },
    },
    config: {
      type: 'object',
      schema: {
        placeholder: {
          type: 'hidden',
        },
        wysiwyg: {
          type: 'static',
          label: $t('Information'),
          disabled: false,
          content: FormBuilderInfoModal,
          columns: {
            default: { container: 12, label: 5, wrapper: 12 },
            sm: { container: 12, label: 5, wrapper: 12 },
            md: { container: 12, label: 5, wrapper: 12 },
          },
        },
        attachments: {
          type: 'dms-upload',
          label: $t('Upload'),
          name: 'attachments',
          options: {
            attachment_config: {
              meta: {
                service: 'forms',
                id: form_template_detail_store?.form_template_detail?.uid || 'info',
              },
            },
          },
          views: {
            can_download: false,
            can_view: false,
            filename_truncate_length: 12,
          },
          rules: [
            {
              max: (form$, _Validator) => {
                const MAX_UPLOAD = 5;
                return form$.data?.config?.attachments?.length > MAX_UPLOAD;
              },
            },
          ],
          messages: {
            max: 'Upto 5 documents can be attached.',
          },
          columns: {
            default: { container: 12, label: 5, wrapper: 12 },
            sm: { container: 12, label: 5, wrapper: 12 },
            md: { container: 12, label: 5, wrapper: 12 },
          },
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };
  const textbox_schema = {
    ...common_schema,

    config: {
      type: 'object',
      schema: {
        placeholder: {
          type: 'textarea',
          label: $t('Placeholder'),
          rows: 1,
          placeholder: 'Placeholder',
        },
        prefix: {
          type: 'text',
          columns: {
            container: 6,
          },
          label: $t('Prefix'),
          placeholder: 'Prefix',
        },
        suffix: {
          type: 'text',
          columns: {
            container: 6,
          },
          label: $t('Suffix'),
          placeholder: 'Suffix',
        },
        min_length: {
          type: 'text',
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
            'min:1',
          ],
          attrs: { min: '1' },
          autocomplete: 'off',
          label: $t('Min. Characters allowed'),
        },
        max_length: {
          type: 'text',
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
            'min:1',
            {
              max: (form$, Validator) => {
                Validator.watch([getValidationPath('min_length')]);
                return +form$.el$(getValidationPath('max_length'))?.value < +form$.el$(getValidationPath('min_length'))?.value;
              },
            },
          ],
          messages: {
            max: 'Max. Characters allowed should be greater than or equal to Min. Characters allowed',
          },
          attrs: { min: '1' },
          autocomplete: 'off',
          label: $t('Max. Characters allowed'),
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };
  const rich_text_schema = {
    ...common_schema,
    config: {
      type: 'object',
      schema: {
        placeholder: {
          type: 'textarea',
          label: $t('Placeholder'),
          rows: 1,
          placeholder: 'Placeholder',
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };
  const member_field_schema = {
    ...common_schema,
    config: {
      type: 'object',
      schema: {
        allow_multiple_selection: {
          type: 'toggle',
          label: $t('Allow multiple selection'),
        },
        allowed_members: {
          type: 'object',
          slots: {
            after: FormMemberSelect,
          },
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };
  const email_schema = {
    ...rich_text_schema,
  };
  const checkbox_schema = {
    ...common_schema,
    properties: {
      type: 'object',
      schema: {
        minselect: {
          type: 'text',
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
            'min:0',
          ],
          attrs: { min: '0' },
          autocomplete: 'off',
          label: $t('Min values to be selected'),
        },
        type: {
          type: 'hidden',
          default: 'checkbox',
        },
        maxselect: {
          type: 'text',
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
            'min:0',
            {
              max: (form$, Validator) => {
                const validator_path = type === 'table' ? 'group.properties' : 'properties';
                Validator.watch([`${validator_path}.minselect`]);
                return +form$.el$(`${validator_path}.maxselect`)?.value < +form$.el$(`${validator_path}.minselect`)?.value;
              },
            },
          ],
          messages: {
            max: 'Max values to be selected should be greater than or equal to Min values to be selected',
          },
          attrs: { min: '0' },
          autocomplete: 'off',
          label: $t('Max values to be selected'),
        },
      },
    },
    options: {
      type: 'static',
      content: `<p class='-mb-2'>${$t('Options')}</p>`,
    },
    config: {
      type: 'list',
      formatLoad: options => options.filter(option => !option?.deleted),
      presets: ['repeatable_list'],
      columns: {
        default: { container: 11, label: 12, wrapper: 12 },
        sm: { container: 11, label: 12, wrapper: 12 },
        md: { container: 11, label: 12, wrapper: 12 },
      },
      element: {
        type: 'object',
        columns: {
          default: { container: 11, label: 12, wrapper: 12 },
          sm: { container: 11, label: 12, wrapper: 12 },
          md: { container: 11, label: 12, wrapper: 12 },
        },
        schema: {
          name: {
            type: 'text',
          },
          uid: {
            type: 'hidden',
            meta: 'true',
          },
        },
      },
      sort: true,
    },
    ...condition_schema,
    ...submit_button_schema,
  };

  const radio_schema = {
    ...common_schema,
    options: {
      type: 'static',
      content: `<p class='-mb-2'>${$t('Options')}</p>`,
    },
    config: {
      type: 'list',
      formatLoad: options => options.filter(option => !option?.deleted),
      rules: [
        'min: 2',
        'required',
      ],
      messages: {
        min: 'There must be at least 2 options',
      },
      presets: ['repeatable_list'],
      columns: {
        default: { container: 11, label: 12, wrapper: 12 },
        sm: { container: 11, label: 12, wrapper: 12 },
        md: { container: 11, label: 12, wrapper: 12 },
      },
      element: {
        type: 'object',
        columns: {
          default: { container: 11, label: 12, wrapper: 12 },
          sm: { container: 11, label: 12, wrapper: 12 },
          md: { container: 11, label: 12, wrapper: 12 },
        },
        schema: {
          name: {
            type: 'text',
            rules: [
              'required',
            ],
            messages: {
              required: 'This field is required',
            },
          },
          uid: {
            type: 'hidden',
            meta: 'true',
          },
        },
      },
      sort: true,
    },
    ...condition_schema,
    ...submit_button_schema,
  };

  const dropdown_schema = {
    ...radio_schema,
  };

  const date_time_schema = {
    ...common_schema,
    config: {
      type: 'object',
      schema: {
        type: {
          type: 'radiogroup',
          items: [{
            value: 'date',
            label: $t('Date'),
          },
          {
            value: 'datetime',
            label: $t('DateTime'),
          },
          {
            value: 'time',
            label: $t('Time'),
          }],
          label: $t('Type'),
        },
        dateplaceholder: {
          type: 'text',
          label: $t('Placeholder'),
          placeholder: 'Enter Placeholder',
          default: '',
        },
        can_select_past_dates: {
          type: 'toggle',
          label: $t('Can select past dates'),
          conditions: [[
            [
              'config.type',
              'in',
              [
                'date',
                'datetime',
              ],
            ],
            [
              'group.config.type',
              'in',
              [
                'date',
                'datetime',
              ],
            ],
          ],
          ],
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };

  const money_field_schema = {
    ...common_schema,
    config: {
      type: field.editable === false ? 'group' : 'object',
      schema: {
        placeholder: {
          type: 'textarea',
          label: $t('Placeholder'),
          rows: 1,
          placeholder: 'Placeholder',
        },
        code: {
          type: 'select',
          items: Object.keys(currencies).map(currency => ({
            label: currency,
            value: currency,
          })),
          default: field?.config?.code || 'USD',
          search: true,
          native: false,
          canDeselect: false,
          addClasses: { SelectElement: { select: { wrapper: field.editable === false ? 'pointer-events-none' : '' } } },
          canClear: false,
          inputType: 'search',
          autocomplete: 'off',
          label: $t('Currency'),
          info: (field.editable === false) ? $t('Cannot edit previously published fields') : '',
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };

  const signature_field_schema = {
    ...common_schema,
    ...condition_schema,
    ...submit_button_schema,
  };

  const phone_field_schema = {
    ...common_schema,
    config: {
      type: 'object',
      submit:field.editable,
      schema: {
        iso: {
          type: 'select',
          items: all_countries.map(code => ({
            label: code.name,
            value: code.iso2,
          })),
          addClasses: { SelectElement: { select: { wrapper: field.editable === false ? 'pointer-events-none' : '' } } },
          search: true,
          native: false,
          default: field?.config?.iso || 'US',
          canClear: false,
          inputType: 'search',
          autocomplete: 'off',
          label: $t('Country'),
          info: (type !== 'table' && field.editable === false) ? $t('Cannot edit previously published fields') : '',
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };
  const url_field_schema = {
    ...rich_text_schema,
  };
  const file_upload_schema = {
    ...common_schema,
    config: {
      type: 'object',
      schema: {
        option: {
          type: 'text',
          label: $t('Maximum File Size'),
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
            'min:1',
          ],
          autocomplete: 'off',
          addons: {
            after: 'MB',
          },
          attrs: { min: '1' },
        },
        limit_no_of_files: {
          type: 'select',
          canClear: false,
          items: [
            {
              value: '-1',
              label: $t('No Limit'),
            },
            {
              value: '1',
              label: '1',
            },
            {
              value: '2',
              label: '2',
            },
            {
              value: '3',
              label: '3',
            },
            {
              value: '4',
              label: '4',
            },
            {
              value: '5',
              label: '5',
            },
          ],
          search: true,
          native: false,
          inputType: 'search',
          autocomplete: 'off',
          label: $t('Limit'),
          default: -1,
        },
        upload_options: {
          type: 'radiogroup',
          label: $t('Upload Source'),
          info: $t('Applies only for mobile'),
          items: [
            {
              value: 'camera',
              label: $t('Camera'),
            },
            {
              value: 'device',
              label: $t('Device'),
            },
            {
              value: 'both',
              label: $t('Both'),
            },
          ],
        },
        custom: {
          type: 'radiogroup',
          items: [
            {
              value: 'custom',
              label: $t('Custom'),
            },
            {
              value: 'all',
              label: $t('All'),
            },
          ],
          label: $t('Allow file types'),
        },
        type: {
          type: 'checkboxgroup',
          label: $t('Custom Types'),
          items: [
            {
              value: 'Document',
              label: $t('Documents'),
            },
            {
              value: 'Spreadsheet',
              label: $t('Spreadsheets'),
            },
            {
              value: 'Image',
              label: $t('Images'),
            },
            {
              value: 'Audio',
              label: $t('Audios'),
            },
            {
              value: 'Video',
              label: $t('Videos'),
            },
          ],
          conditions: [
            [
              [
                'config.custom',
                'in',
                [
                  'custom',
                ],
              ],
              [
                'group.config.custom',
                'in',
                [
                  'custom',
                ],
              ],
            ],
          ],
          default: [
            'Document',
            'Spreadsheet',
            'Image',
            'Audio',
            'Video',
          ],
        },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };
  const yes_no_field_schema = {
    ...common_schema,
    ...condition_schema,
    ...submit_button_schema,
  };
  const number_field_schema = {
    ...common_schema,

    config: {
      type: 'object',
      schema: {
        placeholder: {
          type: 'textarea',
          label: $t('Placeholder'),
          rows: 1,
          placeholder: 'Placeholder',
        },
        minimum: {
          type: 'text',
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
          ],
          autocomplete: 'off',
          label: $t('Min value allowed'),
        },
        maximum: {
          type: 'text',
          inputType: 'number',
          rules: [
            'nullable',
            'numeric',
            {
              max: (form$, Validator) => {
                Validator.watch(getValidationPath('minimum'));
                return +form$.el$(getValidationPath('maximum'))?.value < +form$.el$(getValidationPath('minimum'))?.value;
              },
            },
          ],
          messages: {
            max: 'Max value to allowed should be greater than or equal to Min value allowed',
          },
          autocomplete: 'off',
          label: $t('Max value allowed'),
        },
        prefix: {
          type: 'text',
          columns: {
            container: 6,
          },
          label: $t('Prefix'),
        },
        units: {
          type: 'text',
          label: $t('Suffix'),
        },
        representation: type === 'table'
          ? ''
          : {
              type: 'radiogroup',
              items: [
                {
                  value: 'Default',
                  label: $t('Default'),
                },
                {
                  value: 'Slider',
                  label: $t('Slider'),
                },
              ],
              label: $t('Representation'),
              default: 'Default',
            },
      },
    },
    ...condition_schema,
    ...submit_button_schema,
  };
  const auto_number_field_schema = {
    meta: {
      type: 'group',
      schema: {
        name: {
          type: 'textarea',
          fieldName: 'name',
          label: $t('Name'),
          rows: 1,
          rules: ['required'],
        },
        description: {
          type: 'textarea',
          fieldName: 'name',
          label: $t('Description'),
        },
      },
    },
    ...useAutoNumberFieldSchema({
      element: form_template_detail_store.form_template_detail,
      ...autonumber_field_suggestions({
        form_template_detail_store,
        ...extra_options,
        field,
        filterFields: [
          'short_text',
          'number',
          'dropdown',
          'radio',
          'date_time',
        ],
      }),
      field,
      $t,
    }),
    ...condition_schema,
    ...submit_button_schema,

  };

  const multitext_schema = {
    ...common_schema,
    ...(auth_store.check_split('multi_text_field_config')
      ? {
          config: {
            type: 'object',
            schema: {
              divider: {
                type: 'static',
                content: '<hr class="border-t border-gray-300"/>',
              },
              link_components: {
                type: 'toggle',
                label: $t('Link with components'),
                addClasses: {
                  ToggleElement: {
                    wrapper: ['justify-end'],
                  },
                },
              },
              link_components_description: {
                type: 'static',
                addClasses: {
                  StaticElement: {
                    content: ['w-3/4 -mt-4'],
                  },
                },
                content: `<span class="text-gray-700 text-xs">${$t('Display the form in all the respective component detail pages.')}</span>`,
              },
              link_components_note: {
                type: 'static',
                content: `<span><b>${$t('Note')}:</b> ${$t('This setting will not affect the submitted data.')}</span>`,
              },
              ...(is_asset_scoped_template && type !== 'table'
                ? {
                    associate_metadata_object: {
                      type: 'object',
                      default: {},
                      slots: {
                        before: FormFieldAssociateMetadata,
                      },
                      columns: {
                        default: { container: 12, label: 5, wrapper: 12 },
                        sm: { container: 12, label: 5, wrapper: 12 },
                        md: { container: 12, label: 5, wrapper: 12 },
                      },
                    },
                  }
                : {}),
            },
          },
        }
      : {}),
    ...condition_schema,
    ...submit_button_schema,
  };

  const formula_field_schema = {
    meta: {
      type: 'group',
      schema: {
        name: {
          type: 'textarea',
          fieldName: 'name',
          label: $t('Name'),
          rows: 1,
          rules: ['required'],
        },
        description: {
          type: 'textarea',
          fieldName: 'name',
          label: $t('Description'),
        },
      },
    },
    ...useFormulaFieldSchema({
      element: form_template_detail_store.form_template_detail,
      ...autonumber_field_suggestions({
        form_template_detail_store,
        ...extra_options,
        field,
        filterFields: [
          'short_text',
          'number',
          'money',
          'date_time',
        ],
      }),
      field,
      $t,
    }),
    ...condition_schema,
    ...submit_button_schema,
  };

  const field_configuration_map = {
    'short_text': textbox_schema,
    'long_text': rich_text_schema,
    'checkbox': checkbox_schema,
    'radio': radio_schema,
    'dropdown': dropdown_schema,
    'date_time': date_time_schema,
    'email': email_schema,
    'money': money_field_schema,
    'signature': signature_field_schema,
    'url': url_field_schema,
    'attachment': file_upload_schema,
    'phone': phone_field_schema,
    'yes-no': yes_no_field_schema,
    'number': number_field_schema,
    'info': info_field_schema,
    'members': member_field_schema,
    'auto_numbering': auto_number_field_schema,
    'multi_text': multitext_schema,
    'formula': formula_field_schema,
  };

  return field_configuration_map;
}

export function useFieldCondition() {
  const form_template_detail_store = useFormTemplateDetailStore();
  const form_workflow_store = useFormWorkflowStore();

  const get_chained_fields = (deleted_fields) => {
    let all_section_fields = [];

    if (form_template_detail_store.form_template_detail.workflow)
      all_section_fields = form_workflow_store.get_all_fields().filter(field => field.type !== 'attachment' && field.status === 'active');
    else
      all_section_fields = form_template_detail_store.sections.flatMap((section) => {
        const fields = section.fields.map(field => ({ ...field, section_name: section.name }));
        return fields;
      }).filter(field => field.type !== 'attachment' && field.status === 'active');

    const conditions = all_section_fields.reduce((accumulator, current_val) => {
      const has_field_conditions = current_val?.condition?.conditions;
      if (has_field_conditions && !deleted_fields.includes(current_val.uid)) {
        const field_conditions = Object.values(current_val.condition.conditions ?? {}).flat();
        const updated_chained_to = field_conditions.map(field_condition => ({ ...field_condition, chained_to: current_val.uid }));
        field_conditions.length && accumulator.push(...updated_chained_to);
      }

      return accumulator.flat();
    }, []);

    const chained_to_fields = conditions.filter(condition => deleted_fields.includes(condition.fact)).map((condition) => {
      const chained_field = all_section_fields.find(field => field.uid === condition?.chained_to);
      return ({ ...condition, ...chained_field });
    });

    return chained_to_fields;
  };

  const get_chain_condition_msg = (is_workflow, chained_fields, deleted_field_type) => {
    let msg = '';

    const msg_type_map = {
      field: 'field',
      section: 'section has fields',
    };

    if (is_workflow) {
      const field_paths = chained_fields.map(field => `${field.step.name} -> ${field.section_name} -> ${field.name}`).join(', ');
      msg = `This ${msg_type_map[deleted_field_type]} conditionally linked to, ${field_paths}. Please unlink before deleting.`;
    }
    else {
      const field_paths = chained_fields.map(field => `${field.section_name} -> ${field.name}`).join(', ');
      msg = `This ${msg_type_map[deleted_field_type]} conditionally linked to, ${field_paths}. Please unlink before deleting.`;
    }

    return msg || 'Something went wrong';
  };

  return {
    get_chained_fields,
    get_chain_condition_msg,
  };
}
