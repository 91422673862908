<script>
import { computed, ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'VPickTimeElement',
}, {
  emits: ['timeUpdated'],
  setup(props, { element, emit }) {
    const hours = [...Array(12).keys()].map(i => i < 9 ? `0${i + 1}` : String(i + 1));
    const minutes = [...Array(60).keys()].map(i => i <= 9 ? `0${i}` : String(i));
    const tods = ['AM', 'PM'];

    const datePart = (part) => {
      return {
        get: () => {
          return (element.model.value || '').split('-')[part] || null;
        },
        set: (value) => {
          const date = (element.model.value || '').split('-');

          date[part] = value;

          element.model.value = `${date[0] || '08'}-${date[1] || '00'}-${date[2] || 'AM'}`;
        },
      };
    };

    const hour = computed(datePart(0));
    const minute = computed(datePart(1));
    const tod = computed(datePart(2));

    const is_open = ref(false);

    const default_classes = 'cursor-pointer border border-gray-300 rounded-lg w-[31%] text-sm py-[6px] px-1';

    function convertTimeStringToIsoString(timeString) {
      const [hours, minutes] = timeString.split('-').map(Number);

      const period = timeString.split('-')[2];

      let adjustedHours = hours;
      if (period === 'PM' && hours !== 12)
        adjustedHours += 12;

      else if (period === 'AM' && hours === 12)
        adjustedHours = 0;

      const date = new Date();
      date.setHours(adjustedHours, minutes, 0, 0);

      return date.toISOString().slice(11, date.length);
    }

    watch(element.model, (newValue, _oldValue) => {
      emit('timeUpdated', convertTimeStringToIsoString(newValue));
    });

    return {
      hour,
      minute,
      tod,
      hours,
      minutes,
      tods,
      is_open,
      default_classes,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <!-- ADD YOUR ELEMENT TEMPLATE HERE -->
      <div :class="classes.wrapper" class="flex w-full justify-between">
        <select v-model="hour" :class="default_classes">
          <option v-for="hour, i in hours" :key="i" :value="hour">
            {{ hour }}
          </option>
        </select>
        <select v-model="minute" :class="default_classes">
          <option v-for="minute, i in minutes" :key="i" :value="minute">
            {{ minute }}
          </option>
        </select>
        <select v-model="tod" :class="default_classes">
          <option v-for="tod, i in tods" :key="i" :value="tod">
            {{ tod }}
          </option>
        </select>
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
