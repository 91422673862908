<script setup>
import HawkSubmitButton from '~/common/components/atoms/hawk-submit-button.vue';
import InventoryCustomFieldInput from '~/inventory/components/inventory-custom-fields/inventory-custom-field-input.vue';

const props = defineProps({
  custom_fields_config: {
    type: Array,
    default: () => [],
  },
  uid: {
    type: String,
    default: '',
  },
  form$: {
    type: Object,
    default: null,
  },
  on_save: {
    type: Function,
  },
  is_sticky_footer: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['cancel']);
const loading = ref(false);
function saveChange() {
  loading.value = true;
  props.on_save();
  loading.value = false;
}
</script>

<template>
  <div class="col-span-12">
    <InventoryCustomFieldInput
      :options="{
        name: 'custom_fields',
      }"
      :data="props.custom_fields_config"
      attached_to="stock_unit"
      :attached_to_uid="props.uid"
      :label_size="4"
    />
    <div class="flex justify-end items-center gap-3" :class="{ 'sticky bottom-0 bg-white py-6 border-t': is_sticky_footer }">
      <hawk-button type="outlined" color="gray" @click="emit('cancel')">
        {{ $t('Cancel') }}
      </hawk-button>
      <HawkSubmitButton
        :loading="loading"
        :form$="form$" @click="on_save()"
      >
        {{ $t('Save') }}
      </HawkSubmitButton>
    </div>
  </div>
</template>
