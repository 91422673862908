<script setup>
import { onMounted } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  global_search_item: {
    type: Object,
    default: null,
  },
  feature: {
    type: Object,
    default: () => {},
  },
});

const route = useRoute();
const system_model_store = useSystemModelStore();

const state = reactive({
  is_loading: false,
  instance_detail: null,
});

const instance_name = computed(() => props.global_search_item?.name || props.feature?.properties?.name);
const feature_location = computedAsync(
  async () => {
    if (props?.feature) {
      const turf = (await import('@turf/turf'));
      const centroid = turf.centroid(props.feature);
      return centroid?.geometry ?? null;
    }
    return null;
  },
  null,
);

onMounted(async () => {
  await getData();
});

async function getData() {
  state.is_loading = true;
  if (instance_name.value)
    state.instance_detail = await system_model_store.set_instance_by_field({
      instance_name: instance_name.value,
      container_id: route.params.id,
    });
  state.instance_detail = {
    ...state.instance_detail,
    projectUid: props.feature?.properties?.project || '',
  };
  state.is_loading = false;
}
</script>

<template>
  <slot
    name="widget_content"
    :instance_detail="state.instance_detail"
    :feature="feature"
    :loading="state.is_loading"
    :location="feature_location"
  />
</template>
