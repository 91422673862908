import { ref } from 'vue';

export default function useAbortController() {
  const controllers = ref({});

  function add(key, overwrite = true) {
    if (overwrite)
      controllers.value?.[key]?.abort?.();
    else if (controllers.value?.[key]?.signal)
      return controllers.value[key].signal;
    controllers.value[key] = new AbortController();
    return controllers.value?.[key].signal;
  }

  function abort(key) {
    return controllers.value?.[key]?.abort();
  }

  function abortAll() {
    Object.values(controllers.value).forEach((controller) => {
      controller.abort();
    });
  }

  return { add, abort, abortAll };
}
