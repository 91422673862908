<script setup>
const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
});
</script>

<template>
  <div class="font-medium">
    <div class="flex justify-between align-center p-3 h-14">
      {{ props.data.data.name || $t('Untitled') }}
    </div>
    <div class="flex justify-center text-gray-600 text-sm">
      {{ $t('Widget not supported') }}
    </div>
  </div>
</template>
