<script setup>
import { useModal } from 'vue-final-modal';
import DmsNewCustomStatusModal from './dms-new-custom-status-modal.vue';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  delete_status: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'save', 'addStatus']);

const form = ref({});
const form$ = ref(null);

const dms_settings_store = useDMSSettingsStore();

const { open: open_new_status_popup, close: close_new_status_popup, patchOptions: patch_new_status_popup } = useModal({
  component: DmsNewCustomStatusModal,
  attrs: {
    onClose() {
      close_new_status_popup();
    },
  },
});

const valid_status_list = computed(() => dms_settings_store.document_status.filter(status => status.active && status.uid !== props.delete_status.uid));

function onConfirmHandler() {
  emit('save', form.value);
  emit('close');
}
function onFormMount(el$) {
  if (props?.delete_status)
    el$.load({
      delete_status_name: props.delete_status.name,
    });
}
function addNewStatus() {
  patch_new_status_popup({
    attrs: {
      is_edit: false,
      form_values: null,
      onSave(value) {
        form$.value.elements$.to_status.load(value[0].uid);
        emit('addStatus', value);
        dms_settings_store.fetch_all_statuses();
      },
    },
  });
  open_new_status_popup();
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      class="w-full"
      :display-errors="false"
      @submit="onConfirmHandler"
      @mounted="onFormMount"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('close')">
          <template #title>
            {{ $t('Change status') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          {{ $t('You are deleting a document status that is associated with some Documents. What should we do?') }}
          <div class="grid grid-cols-[100px_180px_20px_200px] items-center gap-3 mt-6">
            <div class="font-medium">
              {{ $t('Migrate documents') }}
            </div>
            <div><TextElement name="delete_status_name" disabled /></div>
            <div class="flex justify-center">
              <IconHawkArrowRight />
            </div>
            <div>
              <SelectElement
                name="to_status"
                rules="required"
                :native="false"
                :can-deselect="false"
                :can-clear="false"
                :items="valid_status_list" label-prop="name" value-prop="uid"
                :add-classes="{
                  SelectElement: {
                    select: {
                      dropdown: ['scrollbar'],
                    },
                  },
                }"
              >
                <template #before-list>
                  <p
                    class="flex gap-2 text-primary hover:bg-gray-100 font-medium rounded-md m-1 mb-0 py-2 px-1.5"
                    @click="addNewStatus"
                  >
                    + {{ $t('New Status') }}
                  </p>
                </template>
              </SelectElement>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <div class="flex">
                <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits name="submit_status" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
