<script setup>
const props = defineProps({
  modelValue: {
    type: Number,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

function updateState() {
  const state_change_map = {
    '0': null,
    '1': -1,
    '-1': 0,
    'null': 1,
    'undefined': 1,
  };
  emit('update:modelValue', state_change_map[`${props.modelValue}`]);
}
</script>

<template>
  <span class="cursor-pointer" :class="{ 'pointer-events-none cursor-not-allowed': disabled }" @click="updateState">
    <IconCheckboxStateOnHold v-if="modelValue === 0" />
    <IconCheckboxStateRejected v-else-if="modelValue === -1" />
    <IconCheckboxStateResolved v-else-if="modelValue === 1" />
    <IconCheckboxStateNull v-else />
  </span>
</template>
