<!-- eslint-disable curly -->
<script setup>
import { debounce } from 'lodash-es';

import ItemsList from '~/common/components/organisms/hawk-attach/attach-files/items-list.vue';

const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
  asset_id: {
    type: String,
    default: '',
  },
  additional_queries: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['selected']);

const $services = inject('$services');

const search_key = toRefs(props).search;

const state = reactive({
  loading: false,
  scroll_loading: false,
  selected_files: [],
  files: [],
  folders: [],
  viewing_folder: null,
  path: [],
  page_number: 1,
  all_folders_fetched: false,
  all_files_fetched: false,
});

onMounted(async () => {
  await getData();
});

watch(search_key, debounce(async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    state.all_files_fetched = false;
    state.all_folders_fetched = false;
    state.page_number = 1;
    state.viewing_folder = null;
    state.path = [];
    await getData(newVal);
  }
}, 500));

async function getFiles(search, init = true) {
  if (!state.all_files_fetched) {
    const { data } = await $services.files.getAll({
      query: {
        page: state.page_number,
        limit: 8,
        archive: false,
        ...(search && { search }),
        ...(props.asset_id && { asset: props.asset_id }),
        ...props.additional_queries,
      },
    });
    if (init) {
      state.files = data.files.filter(file => !props.restricted_items.includes(file.uid));
    }
    else {
      state.files = [...state.files, ...data.files.filter(file => !props.restricted_items.includes(file.uid))];
      state.page_number = state.page_number + 1;
    }

    if (data.next === 'None')
      state.all_files_fetched = true;
  }
}

async function getData(search = null, init = true) {
  if (init)
    state.loading = true;
  else
    state.scroll_loading = true;

  if (!state.all_folders_fetched) {
    const { data } = await $services.folders.getAll({
      query: {
        page: state.page_number,
        limit: 8,
        is_root: true,
        ...(search && { search }),
        ...(props.asset_id && { asset: props.asset_id }),
        ...props.additional_queries,
      },
    });

    if (init)
      state.folders = data.folders;
    else
      state.folders = [...state.folders, ...data.folders];

    state.page_number = state.page_number + 1;
    if (data.next === 'None') {
      state.all_folders_fetched = true;
      state.page_number = 1;
      await getFiles(search);
    }
  }
  else {
    await getFiles(search, false);
  }

  if (init)
    state.loading = false;
  else
    state.scroll_loading = false;
}

function handleFolderView(folder) {
  if (folder && typeof folder === 'object') {
    state.viewing_folder = folder;
    const folder_index = state.path.findIndex(fold => fold.uid === folder.uid);
    if (folder_index >= 0)
      state.path = state.path.slice(folder_index);
    else
      state.path.push(folder);
  }
  else if (typeof folder === 'string') {
    const folder_index = state.path.findIndex(fold => fold.uid === folder);
    if (folder_index >= 0) {
      state.path.length = folder_index + 1;
      state.viewing_folder = state.path[state.path.length - 1];
    }
  }
  else {
    state.path = [];
    state.viewing_folder = null;
  }
}

provide('selected_files', state.selected_files);
provide('scroll_loading', state.scroll_loading);
</script>

<template>
  <div v-if="!state.viewing_folder?.uid" class="text-sm font-medium text-gray-600 px-4 mb-2">
    {{ $t('Files') }}
  </div>
  <div v-if="!(state.files.length || state.folders.length)" class="px-5 text-sm text-gray-600 font-medium h-[350px]">
    {{ $t("No results found") }}
  </div>
  <div v-else-if="state.loading" class="h-[350px] flex items-center justify-center">
    <hawk-loader />
  </div>
  <div v-else>
    <FolderView
      v-if="state.viewing_folder?.uid"
      :folder_uid="state.viewing_folder.uid"
      :folder_name="state.viewing_folder.name"
      :ancestors="state.viewing_folder.ancestors"
      :restricted_items="restricted_items"
      @view="handleFolderView"
      @selected="$event => { emit('selected', $event); state.selected_files = $event }"
    />
    <ItemsList
      v-else
      :files="state.files"
      :folders="state.folders"
      @view="handleFolderView"
      @selected="$event => { emit('selected', $event); state.selected_files = $event }"
      @loadMore="getData(search_key, false)"
    />
  </div>
</template>
