<script>
import { toRefs } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'MembersElement',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
}, {
  emits: [
    'change',
  ],
  setup(props, { element }) {
    const members = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = value;
      },
    });

    const { options } = toRefs(props);
    const config_options = {
      ...options.value,
    };

    return {
      props,
      members,
      config_options,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <hawk-assignee-input
        v-bind="config_options"
        v-model="members"
        :asset_id="$route.params.asset_id"
        :multi="true"
        :format_load="true"
        :options="{
          ...config_options,
        }"
        @change="$emit('change', $event)"
      />
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
