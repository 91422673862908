<script setup>
import { computed } from 'vue';

const props = defineProps({
  img_src: {
    type: String,
    default: '',
  },
  background_color: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'xs',
    validator(value) {
      return ['badge', 'tiny', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
    },
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  has_border: {
    type: Boolean,
    default: false,
  },
  is_online: {
    type: Boolean,
    default: false,
  },
});

const avatar_classes = computed(() => {
  const avatar = [
    'inline-flex', 'items-center', 'justify-center',
  ];

  switch (props.size) {
    case 'badge':
    case 'tiny':
      avatar.push('h-4', 'w-4', 'text-xs', '[&>img]:w-full', '[&>img]:h-full');
      break;
    case 'xxs':
      avatar.push('h-5', 'w-5', 'text-xs', '[&>img]:w-full', '[&>img]:h-full');
      break;
    case 'xs':
      avatar.push('h-6', 'w-6', 'text-sm', '[&>img]:w-full', '[&>img]:h-full');
      break;
    case 'sm':
      avatar.push('h-8', 'w-8', 'text-sm', '[&>img]:w-full', '[&>img]:h-full');
      break;
    case 'md':
      avatar.push('h-10', 'w-10', 'text-base', '[&>img]:w-full', '[&>img]:h-full');
      break;
    case 'lg':
      avatar.push('h-12', 'w-12', 'text-lg', '[&>img]:w-full', '[&>img]:h-full');
      break;
    case 'xl':
      avatar.push('h-14', 'w-14', 'text-xl', '[&>img]:w-full', '[&>img]:h-full');
      break;
    case '2xl':
      avatar.push('h-16', 'w-16', 'text-2xl', '[&>img]:w-full', '[&>img]:h-full');
      break;
  }

  return avatar;
});

const online_indicator_classes = computed(() => {
  const classes = [
  ];

  switch (props.size) {
    case 'badge':
      classes.push('h-1.5', 'w-1.5');
      break;
    case 'xs':
      classes.push('h-1.5', 'w-1.5');
      break;
    case 'sm':
      classes.push('h-2', 'w-2');
      break;
    case 'md':
      classes.push('h-2.5', 'w-2.5');
      break;
    case 'lg':
      classes.push('h-3', 'w-3');
      break;
    case 'xl':
      classes.push('h-3.5', 'w-3.5');
      break;
    case '2xl':
      classes.push('h-4', 'w-4');
      break;
  }

  return classes;
});

const rounded_class = computed(() => (props.rounded ? 'rounded-full' : 'rounded'));
const has_border = computed(() => (props.has_border ? 'border-2 border-white' : ''));
</script>

<template>
  <div
    class="relative" :class="[avatar_classes, rounded_class, has_border]"
    :style="{
      backgroundColor: background_color,
    }"
  >
    <img
      v-if="img_src"
      :class="[rounded_class]"
      :src="img_src"
      alt=""
      class="object-cover"
    >
    <div
      v-else class="inline-flex items-center justify-center leading-none text-white"
    >
      {{ name?.charAt(0) || '' }}
    </div>
    <div
      v-if="is_online"
      class="bg-green-500 absolute bottom-0 right-0 rounded-full border"
      :class="online_indicator_classes"
    />
  </div>
</template>
