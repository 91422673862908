<script>
export default {
  name: 'TableWrapper',
  props: {
    container_class: {
      type: String,
      default: 'mt-8 border',
    },
    height: {
      default: 'auto',
    },
  },
  data() {
    return {
      freeze_scroll: false,
    };
  },
  mounted() {
    this.setTableHeight();

    if (this.height === 'auto')
      window.addEventListener('resize', this.setTableHeight);
  },
  methods: {
    setTableHeight() {
      if (this.$refs.table_container)
        if (this.height === 'auto') {
          const tableContainerTop = this.$refs.table_container.getBoundingClientRect().top;
          this.$refs.table_container.style.maxHeight = `calc(100vh - ${tableContainerTop}px)`;
        }
        else {
          this.$refs.table_container.style.maxHeight = `${this.height}px`;
        }
    },
  },
};
</script>

<template>
  <div id="table_container" ref="table_container" class="table_container flex flex-col w-full rounded-lg box-border scrollbar" :class="[container_class, freeze_scroll ? '!overflow-hidden' : '']">
    <slot />
  </div>
</template>

<style lang="scss">
.table_container {
  overflow: overlay !important;

}
</style>
