<script setup>
import { storeToRefs } from 'pinia';
import { keys, pickBy } from 'lodash-es';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();

const { active_view } = storeToRefs(project_management_store);

const check_items = computed(() => {
  return {
    activity_name: $t('Activity name'),
    links: $t('Links'),
    percentage_complete: $t('% Complete'),
    float: $t('Float'),
    today_line: $t('Today line'),
    data_date_line: $t('Data date line'),
    overdue: $t('Overdue'),
    critical_path: $t('Critical path'),
    baseline: $t('Baseline'),
  };
});

const default_checked_options = computed(
  () => keys(pickBy(active_view.value.data.feature_visibility)),
);

function onCheckboxGroupChange(value) {
  keys(active_view.value.data.feature_visibility || {}).forEach(key =>
    active_view.value.data.feature_visibility[key] = value.includes(key),
  );
  gantt.render();
}
</script>

<template>
  <hawk-menu additional_trigger_classes="ring-gray-100">
    <template #trigger>
      <hawk-button icon type="outlined">
        <IconHawkSettingsOne class="w-5 h-5" />
      </hawk-button>
    </template>
    <template #content>
      <div class="w-60 py-3 px-4">
        <div class="text-sm text-gray-900 font-semibold mb-2">
          {{ $t("Table settings") }}
        </div>
        <PmTimeRangeMenu />
        <PmLevelMenu v-if="active_view.data.group_by.length === 0" />
        <PmGroupByMenu />
        <div class="text-sm text-gray-900 font-semibold mb-1 mt-6">
          {{ $t("Gantt settings") }}
        </div>
        <Vueform size="sm">
          <CheckboxgroupElement
            :items="check_items"
            :default="default_checked_options"
            :add-classes="{
              CheckboxgroupCheckbox: {
                container: 'my-2',
              },
            }"
            name="displayoptionscheckboxgroup"
            @change="onCheckboxGroupChange"
          />
        </Vueform>
      </div>
    </template>
  </hawk-menu>
</template>
