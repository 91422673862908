<script setup>
import { isEqual, map } from 'lodash-es';
import { computed, ref } from 'vue';
import AssetResources from '~/acct-settings/components/acct-settings-asset-resources/acct-settings-asset-resources.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

const form = ref({
  name: '',
  description: null,
});
const form$ = ref(null);
const asset_access$ = ref(null);

const state = reactive({
  is_loading: false,
  team_detail: {},
  selected_users: [],
  selected_roles: [],
});

const is_disabled = computed(() => !auth_store.check_permission('modify_teams', route.params.asset_id) || !asset_access$.value?.getValue()?.is_valid);

async function onTeamUpdate(response) {
  if (response?.data?.team) {
    await common_store.update_global_data({
      ...(route.params.asset_id ? { assets: [route.params.asset_id] } : { assets: true }),
      teams: [response?.data?.team?.uid],
    });
    if (route.params.id) {
      if (form.value.roles?.length && !isEqual(state.team_detail.roles, form.value.roles)) {
        $track_event('permissions_changed', {
          type: 'Role was assigned',
          where: 'Team details',
        });
      }

      $toast({
        title: $t('Team updated'),
        text: $t('Your changes to the team have been successfully saved'),
        type: 'success',
      });
    }

    else {
      $toast({
        title: $t('Team created'),
        text: $t('Your changes to the team have been successfully saved'),
        type: 'success',
      });
      $track_event('create_team', {
        members: response?.data.team.users?.length,
        roles: response?.data.team.roles?.length,
      });
    }

    router.push({ name: 'account-settings-teams' });
  }
}

async function onSave() {
  try {
    const common_payload = {
      body: {
        team: {
          ...form.value,
          organization: auth_store.current_organization?.uid,
          asset: route.params.asset_id || null,
          users: (form.value.users?.length) ? map(form.value.users, 'uid') : [],
          roles: (form.value.roles?.length) ? map(form.value.roles, 'uid') : [],
          ...asset_access$.value.getValue(),
        },
      },
      asset_id: route?.params?.asset_id || null,
    };
    let response;
    if (route.params.id) {
      response = await $services.teams_v2.patch({
        id: route.params.id,
        ...common_payload,
      });
    }
    else {
      response = await $services.teams.post(common_payload);
    }

    await onTeamUpdate(response);
  }
  catch (error) {
    if (route.params.id) {
      $toast({
        title: $t('Failed to update team'),
        text: $t('Unable to update a team. Please double-check your input and try again'),
        type: 'error',
      });
    }
    else {
      $toast({
        title: $t('Failed to create team'),
        text: $t('Unable to create a team. Please double-check your input and try again'),
        type: 'error',
      });
    }
    logger.error(error);
  }
}

function getFilteredUsers(uids) {
  const users = [
    ...common_store.scope_users(route.params?.asset_id).map((member) => {
      return {
        name: member?.first_name ? `${member.first_name} ${member?.last_name || ''}` : member.email,
        uid: member.uid,
        member: true,
      };
    }),
    ...common_store.scope_teams(route.params?.asset_id).map((team) => {
      return { ...team, member: false };
    }),
  ];
  return users.filter(user => uids.includes(user.uid));
}
function getFilteredRoles(uids) {
  const roles = [
    ...common_store.scope_roles(route.params?.asset_id).map((role) => {
      return {
        name: role.name,
        uid: role.uid,
      };
    }),
  ];
  return roles.filter(role => uids.includes(role.uid));
}
function setFormData() {
  state.selected_users = state.team_detail.users?.length ? getFilteredUsers(state.team_detail.users) : [];
  state.selected_roles = state.team_detail.roles?.length ? getFilteredRoles(state.team_detail.roles) : [];
  const form_data = {
    name: state.team_detail.name,
    access_all_assets: state.team_detail.access_all_assets,
    description: state.team_detail.description,
    users: state.selected_users,
    roles: state.selected_roles,
    default: state.team_detail.default,
  };
  form$.value?.update(form_data);
}

async function setAssetsResourcesData() {
  if (state.team_detail?.resources?.length) {
    const response = await $services.assets.post({
      attribute: 'tree-views',
      body: {
        assets: state.team_detail?.resources?.map(item => item.asset),
      },
    });
    response?.data?.assets?.forEach((asset) => {
      common_store.asset_meta[asset.uid] = { ...(common_store.asset_meta[asset.uid] || {}), resources: asset };
    });
  }
}
async function getDetails() {
  try {
    state.is_loading = true;
    const { data } = await $services.teams_v2.get({
      id: route.params.id,
      asset_id: route.params.asset_id,
    });
    state.team_detail = data.team;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('error', error);
  }
}

onMounted(async () => {
  if (route.params.id) {
    state.is_loading = true;
    await getDetails();
    await setAssetsResourcesData();
    state.is_loading = false;
  }
  setFormData();
});
</script>

<template>
  <div>
    <div class="my-5">
      <HawkPageSecondaryHeader class="w-full">
        <template #left>
          <div class="flex items-center gap-3">
            <HawkButton type="text" icon @click="router.push({ name: 'account-settings-teams' });">
              <IconHawkChevronLeft />
            </HawkButton>
            <div>
              <p class="text-lg font-semibold text-gray-900 leading-none">
                {{ route.params.id ? $t('Update team') : $t('Add team') }}
              </p>
              <span class="text-xs text-gray-600">
                {{ $t('Setup members, roles and grant access to assets and resources') }}
              </span>
            </div>
          </div>
        </template>
      </HawkPageSecondaryHeader>
    </div>
    <HawkLoader v-if="state.is_loading" />
    <div v-show="!state.is_loading">
      <Vueform
        ref="form$"
        v-model="form"
        size="sm"
        :display-errors="false"
        :endpoint="onSave"
      >
        <div class="col-span-12">
          <div class="max-w-[700px]">
            <TextElement
              :label="$t('Name')"
              name="name"
              placeholder="Enter name"
              :rules="['required', 'max:100']"
              class="mb-4"
            />
            <TextareaElement
              :label="$t('Description')"
              name="description"
              placeholder="Enter description"
              class="mb-4"
              :rules="['max:200']"
            />
            <ToggleElement
              class="mb-4"
              :label="$t('Mark as default')"
              name="default"
              :description="route.params.asset_id ? $t('Assign the team to all members of the asset') : $t('Assign the team to all members of the organization')"
            />
            <template v-if="!form.default">
              <HawkAssigneeInput
                v-if="route?.params?.asset_id"
                :callbacks="{ user_filter: (item) => !(common_store.active_asset?.team_members?.map(user => user.uid)?.includes(item.uid)) }"
                class="mr-5 w-full"
                :multi="true"
                :options="{
                  existing_users: state.selected_users,
                  name: 'users',
                  has_teams: false,
                  placeholder: $t('Select users'),
                  class: 'mb-4',
                  label: $t('Members'),
                  description: $t('Choose members to assign the team'),
                }"
              />
              <HawkAssigneeInput
                v-else
                class="mr-5 w-full"
                :multi="true"
                :options="{
                  existing_users: state.selected_users,
                  name: 'users',
                  has_teams: false,
                  placeholder: $t('Select users'),
                  class: 'mb-4',
                  label: $t('Members'),
                  description: $t('Choose members to assign the team'),
                }"
                :show_scoped_users="false"
                :show_scoped_teams="false"
              />
            </template>
            <hawk-roles-input
              class="mb-4 mr-5 w-full"
              :multi="true"
              :options="{
                existing_roles: state.selected_roles,
                name: 'roles',
                placeholder: $t('Select roles'),
                label: $t('Roles'),
                description: $t('Choose roles to assign/inherit role permissions for the team members'),
              }"
            />
          </div>
          <AssetResources
            v-if="!state.is_loading"
            ref="asset_access$"
            :asset_permissions="state.team_detail.resources"
            :access_all_assets="state.team_detail.access_all_assets"
          />

          <div class="sticky bottom-0 z-10 bg-white">
            <hr class="my-5">
            <div class="flex justify-end gap-3 pb-5">
              <HawkButton type="outlined" @click="$router.push({ name: 'account-settings-teams' })">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true" :disabled="is_disabled">
                {{ route.params.id ? $t('Update') : $t('Save') }}
              </ButtonElement>
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
