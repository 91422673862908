<script setup>
import { cloneDeep, uniqBy } from 'lodash-es';
import { onMounted } from 'vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { $date } from '~/common/utils/date.util';
import { import_fusioncharts } from '~/common/utils/package.utils.js';

const terra_store = useTerraStore();
const $services = inject('$services');

const chart = ref(null);
const progress_history = ref([]);
const data_loading = ref(false);
const form = ref({
  selected_activity: 'all',
  show_line: false,
});

const filtered_field_uids = computed(() => {
  const workflowProgress = terra_store.selected_features[0]?.properties
    .workflowProgress;
  return Object.keys(workflowProgress).reduce((acc, curr) => {
    if (workflowProgress[curr].current)
      acc.push(curr);
    return acc;
  }, []);
});

const selected_feature_workflow_fields = computed(() => {
  const fields = [];
  Object.values(
    terra_store.terra_workflows?.[terra_store.selected_features[0]?.properties.workflow]
      ?.data || {},
  ).forEach((item) => {
    fields.push(...(item.fields || []));
  });
  return uniqBy(fields, 'uid').filter(field =>
    filtered_field_uids.value.includes(field.uid),
  );
});

const activities = computed(() => {
  return selected_feature_workflow_fields.value.reduce(
    (acc, field) => {
      acc.push({
        label: field.name,
        value: field.uid,
      });

      return acc;
    },
    [
      {
        label: 'All',
        value: 'all',
      },
    ],
  );
});

function getParsedText(progress, type = 'update') {
  const field = selected_feature_workflow_fields.value?.find(
    item => item.uid === progress.field,
  );

  if (type === 'date')
    return $date(progress.userTimestamp, 'DD MMMM YYYY');
  if (type === 'units')
    return field?.units ?? '';
  if (type === 'featureTypeName')
    return field?.name ?? '';
}
async function getData(e) {
  try {
    data_loading.value = true;
    let payload;
    if (e.value === 'all')
      payload = {
        field: activities.value.reduce((acc, cur) => {
          if (cur.value !== 'all')
            acc.push(cur.value);
          return acc;
        }, []),
        feature: [terra_store.selected_features[0]?.properties?.uid],
      };
    else if (e && e.value !== 'all')
      payload = {
        field: [e.value],
        feature: [terra_store.selected_features[0]?.properties?.uid],
      };

    const response = await $services.terra_view_service.export_progress_data({
      body: {
        ...payload,
      },
    });
    progress_history.value = response.data.reduce((acc, cur) => {
      if (cur.current - cur.past !== 0)
        acc.push(cur);
      return acc.sort((a, b) => {
        return new Date(b?.userTimestamp) - new Date(a?.userTimestamp);
      });
    }, []);

    data_loading.value = false;
  }
  catch (err) {
    logger.log(err);
    data_loading.value = false;
  }
}

function getHistoryPayload(data = []) {
  return {
    type: 'mscombi2d',
    renderAt: 'progress-history-graph',
    width: '100%',
    height: '300',
    dataFormat: 'json',
    dataSource: {
      chart: {
        xaxisname: 'Date',
        yaxisname: form.value.selected_activity.label,
        theme: 'fusion',
        anchorRadius: '0',
        plottooltext: '',
      },
      categories: [
        {
          category: Object.keys(data).map((val) => {
            return {
              label: val,
            };
          }),
        },
      ],
      ...(form.value.selected_activity && {
        dataset: [
          {
            seriesname: form.value.selected_activity,
            data: Object.values(data).map((val) => {
              const data = {
                value: val.current,
                tooltext: `${val.current} ${getParsedText(
                          val,
                          'units',
                        )}`,
              };
              return data;
            }),
          },
          // ?Conditionally add this for line
          ...(form.value.show_line
            ? [
                {
                  seriesname: 'Progress',
                  renderas: 'line',
                  plottooltext: '<b>$value</b>',
                  data: Object.values(data).map((val) => {
                    return {
                      value: val.current,
                    };
                  }),
                },
              ]
            : []),
        ],
      }),
    },
  };
}

function getAllHistoryPayload(data = []) {
  return {
    renderAt: 'progress-history-graph',
    type: 'mscolumn2d',
    width: '100%',
    height: '300',
    dataFormat: 'json',
    dataSource: {
      chart: {
        xaxisname: 'Date',
        yaxisname: 'All',
        theme: 'fusion',
      },

      categories: [
        {
          category: Object.keys(data).map((val) => {
            return {
              label: val,
            };
          }),
        },
      ],
      dataset: selected_feature_workflow_fields.value?.map((field) => {
        return {
          seriesname: field.name,
          data: Object.keys(data).map((date) => {
            const progress = data[date][field.uid];

            return {
              value: progress ? progress.current : 0,
              tooltext: progress
                ? `${progress.current} ${getParsedText(
                            progress,
                            'units',
                          )}`
                : '',
            };
          }),
        };
      }),
    },
  };
}

async function loadChart() {
  const data = cloneDeep(progress_history.value)
    .sort((a, b) => {
      return new Date(a?.userTimestamp) - new Date(b?.userTimestamp);
    })
    .map((val) => {
      return {
        past: val.past,
        current: val.current,
        date: $date(val.userTimestamp, 'MMM DD'),
        field: val.field,
      };
    })

    .reduce((acc, curr) => {
      if (form.value.selected_activity.value !== 'all')
        acc[curr.date] = curr;

      else if (filtered_field_uids.value.includes(curr.field))
        if (acc?.[curr.date]?.[curr.field]) {
          acc[curr.date][curr.field] = curr;
        }
        else {
          if (!acc[curr.date])
            acc[curr.date] = {};
          acc[curr.date][curr.field] = curr;
        }

      return acc;
    }, {});

  let payload = {};
  if (form.value.selected_activity.value !== 'all')
    payload = getHistoryPayload(data);
  // ?For: All
  else
    payload = getAllHistoryPayload(data);

  const { VueFusionChartsComponent, FusionCharts, Charts, FusionTheme } = await import_fusioncharts();
  VueFusionChartsComponent(FusionCharts, Charts, FusionTheme);
  chart.value = new FusionCharts(payload);
  chart.value.render();
}

async function selectActivity(e) {
  try {
    form.value.selected_activity = e;
    form.value.show_line = false;
    await getData(e);
    loadChart();
  }
  catch (e) {
    logger.log(e);
  }
}

onMounted(async () => {
  await selectActivity({
    label: 'All',
    value: 'all',
  });
});
</script>

<template>
  <Vueform v-model="form" sync size="sm">
    <div class="col-span-12">
      <div class="grid grid-cols-12">
        <div class="col-span-4">
          <SelectElement name="selected_activity" class="mb-5" :items="activities" :object="true" :native="false" :can-clear="false" :can-deselect="false" @select="selectActivity" />
        </div>
        <div class="col-span-5" />
        <div v-show="form.selected_activity.value !== 'all'" class="col-span-3">
          <ToggleElement name="show_line" @change="loadChart">
            {{ $t('Show cumulative') }}
          </ToggleElement>
        </div>
      </div>
      <HawkLoader v-if="data_loading" />
      <div
        v-show="!data_loading"
        id="progress-history-graph"
        style="height: 200px;"
      />
    </div>
  </Vueform>
</template>
