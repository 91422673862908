<script setup>
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';

// other
import useEmitter from '~/common/composables/useEmitter';

// composables
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDocumentStore } from '~/dms/store/document.store.js';

// components
import DocumentFolderForm from '~/dms/components/documents/forms/document-folder-form.vue';
import DocumentPlaceholderForm from '~/dms/components/documents/forms/document-placeholder-form.vue';

import DMSCsvImporter from '~/common/components/csv-importer/importers/dms-csv-importer.vue';

const props = defineProps({
  options: {
    type: Object,
    required: false,
  },
  source: {
    type: String,
    required: false,
    default: 'files',
  },
  is_widget: {
    type: Boolean,
    default: false,
  },
});

const $t = inject('$t');
const route = useRoute();
const emitter = useEmitter();

const document_crud = useDocumentCrud();
const authStore = useAuthStore();

const document_store = useDocumentStore();

const state = reactive({
  isContextOpen: false,
  upload_loading: false,
});

const active_tab = computed(() => route.meta.page);
const create_documents = computed(() => authStore.check_permission('create_documents', route?.params?.asset_id));
const folder_modal = useModal({
  component: DocumentFolderForm,
});

const placeholder_modal = useModal({
  component: DocumentPlaceholderForm,
});

function onOpenFileUpload({ flag = 'file', files = [] }) {
  document_crud.uploadHandler({
    flag,
    files,
    options: document_crud.getCommonPayload(props.options),
  });
}

function openCreatePlaceholderForm() {
  placeholder_modal.patchOptions({
    attrs: {
      options: document_crud.getCommonPayload(props.options),
      onClose() {
        placeholder_modal.close();
      },
    },
  });
  placeholder_modal.open();
}

function openFolderModal() {
  folder_modal.patchOptions({
    attrs: {
      on_submit: async (data) => {
        try {
          let payload = { ...data };
          const common_payload = document_crud.getCommonPayload(props.options);
          payload = {
            ...payload,
            ...common_payload,
          };
          if (data.name.includes('/')) {
            // If folder name is 'F4/F4.1/F4.1.1' then create folder hierarchy
            await document_store.create_folder_structure({
              files: [{ path: `${data.name}/sample.txt` }], // "/sample.txt" is to satisfy the BE path structure
              ...common_payload,
            });
            await document_store.set_hierarchy();
            return;
          }

          const response = await document_store.crud_documents({ request: { body: { folders: { add: [payload] } } } });
          await document_store.set_hierarchy();
          const added_folder = response?.data?.documents?.folders?.added?.[0];

          if (added_folder) { // reset files and folders for activating newly created folder
            document_store.reset_files_folders();
            document_store.document_details_map[added_folder?.uid] = added_folder;
            document_store.set_active_folder_meta(added_folder);
            emitter.emit('set-default-expanded');
          }
        }
        catch (e) {
          folder_modal.close();
        }
      },
      folder_name_regex: 'regex:/^[^\\:*?\'"<>|]+$/',
      onClose() {
        folder_modal.close();
      },
    },
  });
  folder_modal.open();
}

const hawk_menu_items = [
  {
    label: $t('New folder'),
    uid: 'menu1',
    has_divider: true,
    icon: IconHawkFolderAdd,
    on_click: () => {
      openFolderModal();
    },
  },
  {
    label: $t('File upload'),
    uid: 'menu2',
    icon: IconHawkFileTwo,
    on_click: () => {
      onOpenFileUpload({});
    },
  },
  {
    label: $t('Folder upload'),
    uid: 'menu3',
    icon: IconHawkFolder,
    on_click: () => {
      onOpenFileUpload({ flag: 'folder', files: [] });
    },
  },
  {
    label: $t('Create document'),
    uid: 'menu2',
    icon: IconHawkFilePlusTwo,
    on_click: () => {
      openCreatePlaceholderForm();
    },
  },
  ...(authStore.check_split('files_v2_advanced')
    ? [{
        label: $t('Import'),
        uid: 'menu4',
        icon: IconHawkDownloadOne,
        on_click: openImporter,
      }]
    : []
  ),
];
function toggleContextMenu() {
  if (active_tab.value === 'files')
    state.isContextOpen = !state.isContextOpen;
}

const { open: openDMSCSVImporter, close: closeDMSCSVImporter, patchOptions: patchDMSCSVImporter } = useModal({
  component: DMSCsvImporter,
});
function openImporter() {
  patchDMSCSVImporter({
    attrs: {
      store: {},
      onClose() {
        closeDMSCSVImporter();
      },
      onImportSuccess() {},
    },
  });
  openDMSCSVImporter();
}
</script>

<template>
  <div>
    <div class="mt-4 flex md:mt-0">
      <div v-if="create_documents">
        <slot name="top-right-controls">
          <hawk-menu
            :disabled="active_tab !== 'files' && !is_widget"
            :items="hawk_menu_items"
            :position="options?.is_pm ? 'bottom-left' : 'fixed'"
            additional_dropdown_classes="w-52"
            additional_item_classes="hover:bg-gray-100"
          >
            <template #trigger>
              <hawk-button
                v-click-outside="() => (state.isContextOpen = false)"
                :type="is_widget ? 'outlined' : 'default'"
                class="flex justify-between text-gray-700 font-semibold" @click="toggleContextMenu"
              >
                <IconHawkPlusWhite class="w-3 h-3" />
                {{ $t('New') }}
              </hawk-button>
            </template>
            <template #item="data">
              <component :is="data.item.icon" class="mr-2 w-4 h-4" :class="{ 'w-4 h-4': options?.is_pm }" />
              <span class="text-sm font-medium text-gray-700">
                {{ data.item.label }}
              </span>
            </template>
          </hawk-menu>
        </slot>
      </div>
    </div>
  </div>
</template>
