<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  task_uid: {
    type: String,
    required: true,
  },
  is_template: {
    type: Boolean,
    default: false,
  },
  duration: {
    type: Number,
    default: null,
  },
  schedule: {
    type: Object,
    default: null,
  },
  start_date: {
    type: String,
    required: true,
  },
  on_update: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(['close', 'update']);

const $track_event = inject('$track_event');
const route = useRoute();

const form_data = ref(null);
const hawk_schedule = ref(null);
const hawk_duration = ref(null);

const is_loading = ref(false);

const show_scheduler = computed(() => {
  if (props.is_template)
    return form_data.value?.duration_type === 'Schedule';

  return true;
});

const is_save_disabled = computed(() => {
  if (props.is_template && form_data.value?.duration_type === 'Duration')
    return !hawk_duration.value?.form_data.duration_value;
  return hawk_schedule.value?.has_errors;
});

const schedule_with_duration = computed(() => ({
  ...props.schedule,
  duration: props.duration,
}));

onMounted(() => {
  if (props.is_template)
    form_data.value = {
      duration_type: props.schedule?.rrule ? 'Schedule' : 'Duration',
    };
});

async function handleDelete() {
  const key = show_scheduler.value ? 'schedule' : 'duration';

  is_loading.value = true;
  await props.on_update({ [key]: null });
  is_loading.value = false;
  emit('close');
}

async function saveSchedule() {
  const data = hawk_schedule.value.setRecurringRule();
  await props.on_update(data);
  const options = {
    3: 'Resolved',
    4: 'Closed',
    6: 'On Schedule',
  };
  const repeat_until = hawk_schedule.value.form_data.repeatation.repeat.slice(7);
  $track_event('task_scheduled', {
    type: route.query.template ? 'Task template' : 'Task',
    frequency: hawk_schedule.value.form_data.recurrence_frequency,
    repeat_on: options[data.schedule.recur_when],
    repeat_until: repeat_until.charAt(0).toUpperCase() + repeat_until.slice(1),
    form_attached: '', // needs to be reviewed
  });
}

async function saveDuration() {
  const data = hawk_duration.value.getDuration();
  await props.on_update({ ...data, schedule: null });
}

async function handleSave() {
  is_loading.value = true;
  if (show_scheduler.value)
    await saveSchedule();
  else
    await saveDuration();
  is_loading.value = false;
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="emit('close')">
      <template #left>
        {{ is_template ? $t('Duration') : $t('Schedule') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <Vueform
        v-if="is_template"
        v-model="form_data"
        sync
        size="sm"
        class="mb-4"
        :columns="{
          default: { container: 12, label: 3, wrapper: 12 },
          sm: { container: 12, label: 3, wrapper: 12 },
          md: { container: 12, label: 3, wrapper: 12 },
        }"
      >
        <RadiogroupElement
          name="duration_type"
          label="Duration type"
          :items="[
            'Duration',
            'Schedule',
          ]"
        />
      </Vueform>
      <HawkSchedule
        v-if="show_scheduler"
        ref="hawk_schedule"
        module="task"
        :schedule="schedule_with_duration"
      />
      <TaskTemplateDuration
        v-else
        ref="hawk_duration"
        :duration="duration"
      />
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-between items-center">
      <template #left>
        <hawk-button
          v-if="schedule?.rrule || duration"
          type="plain"
          color="error"
          @click="handleDelete"
        >
          {{ $t('Delete') }}
        </hawk-button>
      </template>
      <template #right>
        <div class="flex justify-end items-center">
          <hawk-button
            class="mr-5"
            type="outlined"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            color="primary"
            :loading="is_loading"
            :disabled="is_save_disabled"
            @click="handleSave"
          >
            {{ $t('Save') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
