<script setup>
const emit = defineEmits(['close', 'save']);

const form$ = ref(null);
const $toast = inject('$toast');
const $t = inject('$t');

const file_data = ref(null);
const is_file_present = ref(false);
const is_loading = ref(false);

function onFormUpdated(event) {
  logger.log(event);
  if (event.type !== 'upload_completed')
    return;
  is_file_present.value = true;
  file_data.value = form$.value.data.file;
  readFile();
}

const feature_data = ref(null);

function readFile() {
  const reader = new FileReader();
  reader.onload = (e) => {
    let json;
    if (file_data.value.name.includes('.kml')) {
      const dom = new DOMParser().parseFromString(
        reader.result,
        'text/xml',
      );
      json = togeojson.kml(dom);
    }
    else {
      json = JSON.parse(reader.result);
    }
    feature_data.value = json;
  };

  reader.readAsText(file_data.value, 'UTF-8');
}
async function onUploadMap() {
  is_loading.value = true;
  emit('save', feature_data.value);
  // SET CONFIG
  is_loading.value = false;
  emit('close');

  $toast({
    type: 'success',
    title: $t('Map view imported'),
    timeout: 2000,
    position: 'bottom-right',
    has_close_button: true,
  });
}

function onFileRemoved() {
  is_file_present.value = false;
}
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header_left>
      <div class="flex flex-col justify-start">
        {{ $t("Import map") }}
      </div>
    </template>
    <template #default>
      <div class="-m-2">
        <Vueform
          ref="form$"
          size="sm"
          class="px-2 mb-2 w-[566px]"
          @updated="onFormUpdated"
        >
          <div class="flex justify-between w-full col-span-full">
            <div class="flex w-full col-span-full justify-center">
              <FileElement
                class="w-full"
                :auto="true"
                name="file"
                accept=".json"
                :drop="true"
                @remove="onFileRemoved"
              />
            </div>
          </div>
        </Vueform>
      </div>
    </template>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="create"
            class="ml-3"
            :disabled="!is_file_present"
            :loading="is_loading"
            @click="onUploadMap()"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
