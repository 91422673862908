import {
  default_date_field,
  default_dates_field,
  default_operator_field,
  default_select_field,
  empty_form_operator_fields,
} from '~/dashboard/components/filters/composables/fields-config';

const schedule_operator_options = {
  default: [
    ['is_in', 'Is'],
    ['is_not_in', 'Is not'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  select: [
    ['is', 'Is'],
    ['is_not', 'Is not'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  date: [
    ['is', 'Is'],
    ['earlier', 'Earlier than'],
    ['later', 'Later than'],
    ['between', 'Between'],
    ['this_week', 'This week'],
    ['this_month', 'This month'],
    ['this_quarter', 'This quarter'],
    ['this_year', 'This year'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
};

const schedule_status_options = [
  'Not started',
  'On Track',
  'Delayed',
  'Completed',
].map((item) => {
  return {
    value: item,
    label: item,
  };
});

const schedule_date_status_options = [
  'Delayed',
  'On Track',
  'Early',
].map((item) => {
  return {
    value: item,
    label: item,
  };
});

const schedule_critical_options = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

const schedule_items_map = {
  status: schedule_status_options,
  is_critical: schedule_critical_options,
  start_status: schedule_date_status_options,
  finish_status: schedule_date_status_options,
};

export function getScheduleFilterConfig(field) {
  const filter = {
    value: field.value,
    label: field.label,
  };

  filter.operator = {};
  filter.operator = {
    ...default_operator_field,
    items: schedule_operator_options[field.type],
  };

  const operator_options = schedule_operator_options[field.type] || schedule_operator_options.default;

  filter.operator.items = operator_options;

  if (field.type === 'date') {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_date_field,
      },
    ];

    filter.operator.custom_fields = {};
    filter.operator.custom_fields = {
      between: [
        {
          ...default_dates_field,
        },
      ],
      ...empty_form_operator_fields,
    };
  }
  else if (['custom_field', 'activity_code'].includes(field.type)) {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_select_field,
        items: field.items,
      },
    ];
  }
  else if (field.type === 'select') {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_select_field,
        items: schedule_items_map[field.value] || [],
      },
    ];
  }

  return filter;
}
