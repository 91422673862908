<script setup>
</script>

<template>
  <HawkAlertBanner color="gray" class="!p-3 flex items-center">
    <template #icon>
      <IconHawkInfoCircle />
    </template>
    <template #content>
      <slot />
    </template>
  </HawkAlertBanner>
</template>
