<script>
export default {
  name: 'ElementInfo',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        wrapper: '',
        content: '',
      },
    };
  },
};
</script>

<template>
  <span v-if="info || isSlot" @mouseover="handleMouseOver">
    <slot v-if="isSlot" />
    <div v-else v-tippy="info">
      <IconHawkInfoCircle class="ml-1 w-4 h-4 mt-0.5" />
    </div>
  </span>
</template>
