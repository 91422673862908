<script setup>
import ItemsList from '~/common/components/organisms/hawk-attach/attach-files/items-list.vue';

const props = defineProps({
  folder_uid: {
    type: String,
    required: true,
  },
  folder_name: {
    type: String,
    required: true,
  },
  ancestors: {
    type: Array,
    required: true,
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['view', 'selected']);

const $services = inject('$services');

const folder_uid = toRefs(props).folder_uid;
const state = reactive({
  loading: false,
  files: [],
  folders: [],
});

const breadcrumb_items = computed(() => {
  const items = [{ uid: null, label: 'Files' }];
  props.ancestors.forEach((ancestor) => {
    items.push({ ...ancestor, label: ancestor.name });
  });
  items.push({ uid: folder_uid.value, label: props.folder_name, disabled: true });
  return items;
});

onMounted(async () => {
  await getData(props.folder_uid);
});

watch(folder_uid, async (newVal) => {
  await getData(newVal);
});

async function getData(folder_uid) {
  state.loading = true;

  const { data: folder_data } = await $services.folders.get({
    id: folder_uid,
    attribute: 'children',
  });
  const { data: files_data } = await $services.files.get({
    attribute: `folder/${folder_uid}`,
  });

  state.folders = folder_data.folders;
  state.files = files_data.files.filter(file => !props.restricted_items.includes(file.uid));
  state.loading = false;
}
</script>

<template>
  <hawk-breadcrumbs class="ml-4 mb-2" :items="breadcrumb_items" @crumb-clicked="$event => emit('view', $event.uid)" />
  <div v-if="state.loading" class="h-[350px] flex items-center justify-center">
    <hawk-loader />
  </div>
  <ItemsList
    v-else-if="state.files.length || state.folders.length"
    :files="state.files"
    :folders="state.folders"
    @view="$event => emit('view', $event)"
    @selected="$event => emit('selected', $event)"
  />
  <div v-else class="px-4 text-sm text-gray-600 font-medium h-[350px] mt-4">
    Empty Folder
  </div>
</template>
