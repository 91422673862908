import { nodeToString, stringToNode } from '~/dashboard/print/utilities.js';
import { $date } from '~/common/utils/date.util';

function createTerraMapLegendTable(legend_data = []) {
  if (!legend_data?.length)
    return '';

  const container = document.createElement('div');
  legend_data.forEach((group) => {
    const table = document.createElement('table');
    const table_head = document.createElement('thead');

    const table_head_row = document.createElement('tr');
    const table_head_cell = document.createElement('th');

    table_head_cell.innerHTML = group.name;
    table_head_cell.setAttribute('colspan', 3);
    table_head_row.appendChild(table_head_cell);
    table_head.appendChild(table_head_row);

    table.appendChild(table_head);

    const table_body = document.createElement('tbody');
    group.featureTypes.forEach((feature) => {
      const table_row = stringToNode(`
        <tr>
          <td class="table-terra__color">
            <div class="terra-map__icon" style="border-color: ${feature.color}">
            </div>
          </td>
          <td class="table-terra__name">
            ${feature.name}
          </td>
          <td class="table-terra__value">
            ${feature.count}
          </td>
        <tr>
      `);

      table_body.appendChild(table_row);
    });

    table.appendChild(table_body);

    container.appendChild(table);
  });

  return nodeToString(container);
}

function createTerraMapView(chart, index) {
  const terra_map_legend = createTerraMapLegendTable(chart.dataSource.legend || []);

  const map_container = stringToNode(`
    <div class="dashboard-row">
      <div class="dashboard-row__chart">
        <h3 class="dashboard-row__name">
          ${chart.chart_name}
        </h3>
        <div class="terra-map__timestamp">
          ${$date(chart.dataSource.timestamp)}
        <div>
        <div class="terra-map">
          <img src="${chart.dataSource.snapshot}" >
        </div>
        ${terra_map_legend}
      </div>
    </div>
  `);

  return map_container;
}

export {
  createTerraMapView,
};
