<script setup>
const props = defineProps({
  on_submit: {
    type: Function,
    default: null,
  },
  form_detail_store: {
    type: Object,
  },
});
const emit = defineEmits(['close']);
const form_detail_store = props.form_detail_store;
const forms$ = useTemplateRef('forms$');
const blocks_data = ref(null);

async function onSave() {
  const { reason, reopen_to } = forms$.value.data;
  const req = {
    body: {
      status: 'pending',
      message: reason,
      ...(reopen_to ? { step_index: Number(reopen_to) } : {}),
    },
    attribute: `${form_detail_store.form_detail.uid}/revert`,
  };
  await props.on_submit(req);
}
const focusElement = () => forms$.value?.elements$?.reason.input.focus();
onMounted(() => {
  blocks_data.value = form_detail_store.form_detail.completed_steps;
  setTimeout(() => {
    form_detail_store.form_detail?.workflow && forms$.value.elements$.reopen_to.load(blocks_data.value.at(-1).index);
  },100);
});
</script>

<template>
  <hawk-modal-container :options="{ 'focus-trap': { allowOutsideClick: true, onPostActivate: focusElement } }">
    <Vueform
      ref="forms$" size="sm"
      :display-errors="false"
      :presets="['']"
      :float-placeholders="false"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t("reopen") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <StaticElement
            name="text"
            :content="`<p>${
              form_detail_store.form_detail?.workflow
                ? $t('The form will be reopened to the selected form block and the assignees will be notified')
                : $t('The form will be reopened and the assignees will be notified')
            }.</p>`"
            size="sm"
            class="mb-4 text-[#475467]"
          />
          <SelectElement
            v-if="form_detail_store.form_detail?.workflow"
            class="mb-5"
            name="reopen_to"
            :columns="{
              default: { container: 8, wrapper: 12, label: 4 },
              sm: { container: 12, label: 12, wrapper: 12 },
              md: { container: 8, label: 4, wrapper: 12 },
            }"
            :native="false"
            :placeholder="$t('Choose step')"
            label-prop="name"
            value-prop="index"
            :can-clear="false"
            :can-deselect="false"
            :items="blocks_data"
          >
            <template #label>
              <div class="flex gap-2 items-center">
                {{ $t('Reopen to') }}
              </div>
            </template>
          </SelectElement>
          <TextareaElement
            name="reason"
            :label="`${$t('reason')}`"
            :columns="{
              default: { container: 8, wrapper: 12, label: 4 },
              sm: { container: 12, label: 12, wrapper: 12 },
              md: { container: 8, label: 4, wrapper: 12 },
            }"
            :rules="[
              'required',
            ]"
            :attrs="{ autofocus: true }"
            :placeholder="`${$t('Reason for reopen (required)')}`"
            size="sm"
          />
          <div v-if="form_detail_store.form_detail?.workflow" class="flex mt-3">
            <HawkText
              class="text-sm font-semibold mr-1"
              :content="`${$t('Note')}:`"
            />
            <HawkText
              length="1000"
              class="text-sm"
              :content="`${$t('The form will be reassigned to the same members who were assignees at the time of submission of the')} ${blocks_data?.find(item => item.index === forms$?.data?.reopen_to)?.name || 'previous step'}`"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <hawk-button class="mr-3" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('reopen') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
