import { useCommonImports } from '~/common/composables/common-imports.composable.js';

export function useStockItems() {
  const { $services, route } = useCommonImports();
  async function validateStocks(input, props) {
    try {
      const response = await $services.inventory_items.validate_stock_units_v2({
        body: {
          stock_units: {
            search: input,
            workflow: props.workflow?.uid,
            item: props.item?.uid,
            location_id: props.from_stock || props.to_stock,
            field_uid: props.field_uid,
          },
        },
        query: {
          asset: route.params.asset_id,
        },
      });

      const { invalid_stocks, valid_stocks } = prepareValidInvalidStocksData(response.data.stock_units);

      return {
        invalid_stocks,
        valid_stocks,
        response,

      };
    }
    catch (error) {
      logger.log('🚀 ~ validateStocks ~ error:', error);
    }
  }
  function prepareValidInvalidStocksData(arr) {
    return arr.reduce((acc, val) => {
      let valid_list = [];
      let invalid_list = [];
      if (val?.children?.length) {
        const { valid_stocks, invalid_stocks } = prepareValidInvalidStocksData(val.children);
        valid_list = valid_stocks;
        invalid_list = invalid_stocks;
      }
      if (valid_list.length || val.valid)
        acc.valid_stocks.push({ ...val, children: valid_list });
      if (invalid_list.length || !val.valid)
        acc.invalid_stocks.push({ ...val, children: invalid_list });
      return acc;
    }, { invalid_stocks: [], valid_stocks: [] });
  }
  return {
    validateStocks,
    prepareValidInvalidStocksData,
  };
}
