<script setup>
import { useElementBounding, useInfiniteScroll, useScroll, useWindowSize } from '@vueuse/core';
import { debounce, find, isEmpty } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { inject } from 'vue';
import FamGallerySidebar from '~/forms-as-module/pages/fam-gallery/fam-gallery-sidebar.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';
import FamNormalFlowFilters from '~/forms-as-module/components/fam-normal-flow-filters.vue';

const $t = inject('$t');
const { gallery_columns, gallery_filters, getAdvanceDataFilters } = useFamCustomView({ feature: 'gallery_view' });
const $services = inject('$services');
const route = useRoute();
const router = useRouter();
const form_template_detail_store = useFormTemplateDetailStore();

const $additional_filter = ref(null);
const attachment_grid = ref(null);
const filter_row = ref(null);
const { y: grid_scroll_position_y } = useScroll(attachment_grid);
const state = reactive({
  is_loading: true,
  items: [],
  selected_sort_type: '-uploaded_on',
  close_viewer: false,
  normal_flow_filters: {},
  display_filters: {},
  pagination_config: {
    page_number: 1,
    page_size: 100,
    total_count: 0,
  },
  sidebar_filters: {
    blocks: [],
    fields: [],
  },
  tree: [],
});

const field_breadcrumbs = computed(() => {
  let data = form_template_detail_store?.template_fields_tree(item => item.type === 'attachment');

  if (!isEmpty(form_template_detail_store.form_template_detail?.steps))
    data = data.reduce((acc, step) => {
      step.children.forEach((section) => {
        section.children.forEach((field) => {
          acc[field.uid] = [{ label: step.name || '-', uid: step.uid }, { label: section.name || '-', uid: section.uid }, { label: field.name || '-', uid: field.uid }];
        });
      });
      return acc;
    }, {});

  else
    data = data.reduce((acc, section) => {
      section.children.forEach((field) => {
        acc[field.uid] = [{ label: section.name || '-', uid: section.uid }, { label: field.name || '-', uid: field.uid }];
      });
      return acc;
    }, {});
  return data || {};
});

const sorting_menu = [
  {
    label: $t('Latest first'),
    uid: '-uploaded_on',
    on_click: () => {
      state.selected_sort_type = '-uploaded_on';
      state.pagination_config.page_number = 1;
    },
  },
  {
    label: $t('Oldest first'),
    uid: 'uploaded_on',
    on_click: () => {
      state.selected_sort_type = 'uploaded_on';
      state.pagination_config.page_number = 1;
    },
  },

];

function openFormDetails(form_uid) {
  router.replace({
    ...route,
    query: {
      ...route.query,
      form: btoa(JSON.stringify({ form_uid })),
    },
  });
  state.close_viewer = true;
}

function getPayload() {
  const payload = {
    scope: 'user',
    page_number: state.pagination_config.page_number,
    page_size: state.pagination_config.page_size,
    sort: state.selected_sort_type,
    filters: {
      open: true,
      ongoing: true,
      template: route.params.template_uid,
      ...(form_template_detail_store.is_template_flow
        ? { advanced_filters: getAdvanceDataFilters($additional_filter) }
        : state.normal_flow_filters),
    },
    ...state.sidebar_filters,
  };
  return payload;
}

watch([
  () => state.display_filters,
  () => state.normal_flow_filters,
  () => state.sidebar_filters,
  () => state.pagination_config.page_number,
  () => state.selected_sort_type,
], debounce(() => {
  getData();
}, 100), { immediate: true, deep: true });

async function getData(options = {}) {
  try {
    state.is_loading = true;

    const { data, headers } = await $services.forms.get_attachments({
      body: getPayload(),
    });

    if (data?.forms) {
      const next_items = data?.forms.map((val, i) => ({
        ...val.attachment,
        breadcrumb_text: field_breadcrumbs.value[val.field_uid],
        form_uid: val.form_uid,
        form_name: val.name,
        field_uid: val.field_uid,
      })) || [];
      if (state.pagination_config.page_number > 1)
        state.items = [...state.items, ...next_items];
      else
        state.items = next_items;
    }

    state.pagination_config.total_count = +(headers['x-total-count'] || data.forms.length);
    state.is_loading = false;
  }
  catch (error) {
    logger.log('🚀  ~ error:', error);
  }
}

async function getDataWithFilters(data) {
  state.normal_flow_filters = data;
}

const { height } = useElementBounding(filter_row);
const { height: window_height } = useWindowSize();

const grid_height = computed(() => {
  return `${window_height.value - (height.value + 156)}px`;
});

useInfiniteScroll(
  attachment_grid,
  async () => {
    if (state.is_loading)
      return;
    state.is_loading = true;
    if ((state?.items?.length < state.pagination_config.total_count))
      state.pagination_config.page_number = state.pagination_config.page_number + 1;
    else state.is_loading = false;
  },
  { distance: 4000 },
);

function onApplyDisplayFilters(e) {
  state.pagination_config.page_number = 1;
  state.display_filters = e;
}
function onApplyNormalFilters(e) {
  state.pagination_config.page_number = 1;
  state.normal_flow_filters = e;
}
function onApplySidebarFilters(e) {
  state.pagination_config.page_number = 1;
  state.sidebar_filters = e;
}

onMounted(() => {
});
</script>

<template>
  <div class="flex">
    <div class="flex-shrink-0" :class="{ 'w-0': !gallery_columns?.length }">
      <FamGallerySidebar :data="gallery_columns" @update="onApplySidebarFilters" @treeData="state.tree = $event" />
    </div>
    <div class="flex-1 mx-4">
      <div v-if="gallery_columns?.length">
        <div v-if="form_template_detail_store.is_template_flow" ref="filter_row" class="py-4 flex items-center justify-between">
          <HawkDisplayFilters
            ref="$additional_filter"
            :display_filters="gallery_filters"
            @apply="onApplyDisplayFilters"
          />
          <div class="flex gap-3">
            <HawkMenu
              :has_bordered_trigger="true"
              :items="sorting_menu"
              additional_dropdown_classes="w-52"
              @select="(e) => e.on_click()"
            >
              <template #trigger="{ open }">
                <div
                  class="bg-white rounded-lg shadow-xs text-sm h-9 flex items-center justify-between px-3 w-52"
                >
                  <div>
                    <span class="text-gray-500 mr-1">{{ $t('Sort by') }}:</span>
                    <span class="text-gray-900 font-medium mr-3">
                      {{ find(sorting_menu, (o) => { return o.uid === state.selected_sort_type; })?.label }}
                    </span>
                  </div>
                  <IconHawkChevronUp v-if="open" class="text-lg" />
                  <IconHawkChevronDown v-else class="text-lg" />
                </div>
              </template>
            </HawkMenu>
          </div>
        </div>
        <FamNormalFlowFilters v-else class="py-4" @save="onApplyNormalFilters" />
      </div>
      <div>
        <div id="attachment_grid" ref="attachment_grid" class="scrollbar pb-10" :style="{ height: grid_height }">
          <HawkLoader v-if="state.pagination_config.page_number === 1 && state.is_loading" />
          <div v-else-if="!state?.items?.length">
            <HawkIllustrations v-if="!gallery_columns?.length" type="no-data" for="fam-gallery" />
            <HawkIllustrations v-else type="no-results" />
          </div>
          <HawkAttachmentsGrid
            v-else
            :items="state?.items"
            :can_delete="false"
            :enable_description="true"
            :show_delete="false"
            :close_viewer="state.close_viewer"
            @update:close_viewer="event => state.close_viewer = event"
          >
            <template #header-right-content="{ attachment }">
              <HawkButton type="light" @click="openFormDetails(attachment.form_uid)">
                <IconHawkShareThree class="h-4 w-4" />
                <span class="font-normal text-sm">
                  <HawkText :content="attachment.form_name" />
                </span>
              </HawkButton>
            </template>
          </HawkAttachmentsGrid>
          <HawkLoader v-if="state.pagination_config.page_number !== 1 && state.is_loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.items-columns {
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
}
</style>
