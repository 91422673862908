<script setup>
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import TransmittalDetailPage from '~/dms/pages/transmittals/transmittal-detail-page.vue';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';

const props = defineProps({
  id: {
    type: String,
  },
  data: {
    type: Object,
    default: () => {},
  },

  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
});
const dashboard_store = useDashboardStore();
const transmittal_store = useTransmittalsStore(props.data.data.transmittal);

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data?.data?.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'transmittal_details',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data?.data?.name,
    dimensions: {
      x: props.data?.x,
      y: props.data?.y,
    },
    dataSource: {
      columns: props?.columns,
      transmittal_store,
      activities: props.data?.data,
      dashboard_index: props.data?.i,
      dataset: props.data?.data,
    },
  });
}

onMounted(() => {
  updatePrintMap();
});
</script>

<template>
  <div>
    <div>
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
    </div>
    <div class="w-full scrollbar" :style="{ height: `${content_height}px` }">
      <div v-if="props.data?.data?.transmittal && typeof props.data.data.transmittal == 'string'" class="pointer-events-none">
        <TransmittalDetailPage :transmittal_uid="props.data.data.transmittal" :read_only="true" />
      </div>
    </div>
  </div>
</template>
