<script setup>
import { useRouter } from 'vue-router';

const emit = defineEmits(['close']);
const router = useRouter();

const state = reactive({
  request_type: 'request_for_documents',
});
const form$ = ref(null);

function changeRequestType(type) {
  state.request_type = type;
}

function submitHandler() {
  if (state.request_type === 'request_for_documents')
    router.push({ name: 'files-create-transmittals' });
  else
    router.push({ name: 'files-create-transmittals', query: { is_review: true } });
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('New transmittal') }}
          </template>
          <template #subtitle>
            {{ $t("Select an option to create your template") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div class="flex gap-4">
            <div
              class="py-4 px-2 border rounded-xl cursor-pointer"
              :class="{ 'border-blue-700': state.request_type === 'request_for_documents' }"
              @click="changeRequestType('request_for_documents')"
            >
              <div class="flex">
                <div class="mr-4">
                  <icon-hawk-form-template-icon class="w-12 h-12 rounded-full" />
                </div>
                <div>
                  <div class="text-gray-900 text-sm font-medium">
                    {{ $t('Request for documents') }}
                  </div>
                  <div class="text-gray-900 text-xs">
                    Simple and fast one step document reviews- choose, assign and send for review
                  </div>
                </div>
                <div :class="[state.request_type === 'request_for_documents' ? 'visible' : 'invisible']">
                  <icon-hawk-checkbox-base-blue class="text-primary-600 w-4 h-4 rounded-full" />
                </div>
              </div>
            </div>
            <div
              class="py-4 px-2 border rounded-xl cursor-pointer"
              :class="{ 'border-blue-700': state.request_type === 'request_for_review' }"
              @click="changeRequestType('request_for_review')"
            >
              <div class="flex">
                <div class="mr-4">
                  <icon-hawk-form-workflow-icon class="w-12 h-12" />
                </div>
                <div>
                  <div class="text-gray-900 text-sm font-medium">
                    {{ $t('Request for review') }}
                  </div>
                  <div class="text-gray-900 text-xs">
                    Build Workflow template for automating your multi-step review in one go
                  </div>
                </div>
                <div :class="[state.request_type === 'request_for_review' ? 'visible' : 'invisible']">
                  <icon-hawk-checkbox-base-blue class="text-primary-600 w-4 h-4 rounded-full" />
                </div>
              </div>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <hawk-button
                type="outlined"
                class="mr-3"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
              >
                {{ $t('Next') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
