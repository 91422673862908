<script setup>
import { storeToRefs } from 'pinia';
import DashboardAssetsFilter from '~/dashboard/components/filters/dashboard-assets-filters.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardAssetsStore } from '~/dashboard/store/dashboard-assets-store.js';

const dashboard_assets_store = useDashboardAssetsStore();

const dashboard_store = useDashboardStore();

const {
  assets_schema,
  assets_configuration,
  asset_widget_type,
  asset_value,
  asset_field_options,
  asset_aggregation_options,
} = storeToRefs(dashboard_assets_store);

const {
  set_data,
  update_assets_configuration,
} = dashboard_assets_store;

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

const form$ = ref(null);

async function validateForm() {
  // sometimes changes take longer
  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();

  const is_valid = !form$.value.hasErrors;
  set_form_valid(is_valid);
  if (is_valid) {
    set_widget_configuration({
      ...assets_configuration.value,
    });
  }
}

function updateAssetsConfiguration(data, key = null) {
  if (!key) {
    update_assets_configuration({
      // ...assets_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...assets_configuration.value,
    };

    copy[key] = data;
    update_assets_configuration(copy);
  }

  validateForm();
}

watch(asset_widget_type, (_newVal, _oldVal) => {
  // Reset field to default
  form$.value.el$('field')?.update(asset_field_options.value[0]);
  form$.value.el$('agg')?.update(asset_aggregation_options.value[0] || null);
});
watch(asset_value, (_newVal, _oldVal) => {
  // Reset aggregation to default
  form$.value.el$('agg')?.update(asset_aggregation_options.value[0] || null);
});

onMounted(() => {
  set_data();
  validateForm();
});

onBeforeUnmount(() => {
  dashboard_assets_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      size="sm"
      :schema="assets_schema"
      :presets="['supress_errors']"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      :should_validate_on_mount="false"
      :display-errors="false"
      class="mb-6"
      @change="updateAssetsConfiguration($event)"
    />
    <DashboardAssetsFilter
      v-if="asset_widget_type !== 'asset_pivot_table'"
      @update="updateAssetsConfiguration($event, 'filters')"
    />
  </div>
</template>
