import { useModal } from 'vue-final-modal';
import InventoryCustomFieldForm from '~/inventory/components/inventory-custom-fields/inventory-custom-field-form.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

export function useAddCustomFields(context, attached_to, attached_to_uid) {
  const inventory_store = useInventoryStore();

  const { open: openCustomFieldPopup, close: closeCustomFieldPopup, patchOptions } = useModal({
    component: InventoryCustomFieldForm,
    attrs: {
      onClose() {
        closeCustomFieldPopup();
      },
      onCancel() {
        closeCustomFieldPopup();
      },
      onSave(custom_field) {
        inventory_store.add_custom_field({
          uid: custom_field.uid,
          value: custom_field,
        });
        const field = {
          item_meta: {
            ...custom_field.attached_to[0],
            attached_to_index: 0,
          },
          value: null,
          uid: custom_field.uid,
        };
        closeCustomFieldPopup();
        context.added(field);
      },

      onAdded(custom_field) {
        inventory_store.add_custom_field({
          uid: custom_field.uid,
          value: custom_field,
        });
        const meta_index = custom_field.attached_to.findIndex(item => (item.type === attached_to && (attached_to_uid ? item.uid === attached_to_uid : true)));
        const field = {
          item_meta: {
            ...custom_field.attached_to[meta_index],
            attached_to_index: meta_index,
          },
          value: null,
          uid: custom_field.uid,
        };
        closeCustomFieldPopup();
        context.added(field);
      },
      onUpdate(custom_field) {},
      attached_to,
      attached_to_uid,
    },
  });

  function open(uid) {
    patchOptions({
      attrs: {
        uid,
      },
    });
    openCustomFieldPopup();
  }

  return {
    open,
    closeCustomFieldPopup,
  };
}
