<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { filter, flatten, isEmpty, reduce, uniq } from 'lodash-es';
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';
import { isFileExtensionAllowed, sanitizeAoA } from '~/common/utils/common.utils.js';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  plan_id: {
    type: String,
    default: null,
  },
  load_instances: {
    type: Function,
    default: null,
  },
  parent_instances: {
    type: Array,
    default: () => [],
  },
  instance_fields: {
    type: Array,
    default: () => [],
  },
  component: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);
const $toast = inject('$toast');
const $t = inject('$t');
const route = useRoute();
const system_model_store = useSystemModelStore();

const state = reactive({
  is_loading: false,
  is_exporting: false,
  progress_text: '',
  invalid_fields: [],
});

const name_keys = ['name', 'name_1', 'name_2', 'name_3', 'name_4'];

const active_component_details = computed(() => system_model_store.active_component_details);

const parent_instance_map_by_name = computed(() => {
  return reduce(props.parent_instances, (instance_map, instance) => {
    instance_map[instance.name] = instance.uid;
    return instance_map;
  }, {});
});

const fields_map_by_name = computed(() => {
  if (props.instance_fields?.length) {
    const fields = reduce(props.instance_fields, (field_map, field) => {
      field_map[field.name] = { uid: field.uid, type: field.type };
      return field_map;
    }, {});
    return fields;
  }
  return {};
});

const field_values_map_by_name = computed(() => {
  if (props.instance_fields?.length) {
    const fields = {};
    props.instance_fields.forEach((field) => {
      if (['labels', 'dropdown'].includes(field.type) && field?.config?.length) {
        const field_config = field?.config?.map(({ uid, name }) => ({ name, uid }));
        fields[field.name] = reduce(field_config, (field_map, field) => {
          field_map[field.name] = field.uid;
          return field_map;
        }, {});
      }
    });
    return fields;
  }
  return {};
});

async function downloadTemplateSheet() {
  await new Promise(resolve => setTimeout(resolve, 2000));

  const ExcelJS = await import('exceljs');
  const { saveAs } = await import('file-saver');

  const workbook = new ExcelJS.Workbook();

  // how many separate Excel  will open when viewing
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];
  const { parents: component_parent, name: component_name } = active_component_details.value;

  if (system_model_store?.fields?.length) {
    /* convert state to workbook */
    const fields = system_model_store?.fields || [];

    const worksheet = workbook.addWorksheet('Fields');
    worksheet.addRows(sanitizeAoA([
      ['name', ...fields.map(field => field.name)],
    ]));
  }
  if (component_parent?.length) {
    component_parent.forEach((component) => {
      const ws = workbook.addWorksheet(component.name);
      ws.addRows(sanitizeAoA([
        [
          'name',
          `~${component.name}`,
          ...((component?.layouts?.layout_x)
            ? ['xpos', 'ypos']
            : []),
        ],
      ]));
    });
  }

  if (!workbook.worksheets.length) {
    const ws = workbook.addWorksheet(component_name);
    ws.addRows([['name']]);
  }

  /* aliases sheet */
  const _ws = workbook.addWorksheet('Aliases');
  _ws.addRows(sanitizeAoA([name_keys]));

  try {
    // for csv: const buffer = await workbook.csv.writeBuffer();
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `template - ${component_name}.xlsx`);
  }
  catch (error) {
    logger.log('Error writing excel export', error);
  }
}

function getColumns(sheets) {
  return filter(uniq(flatten(sheets.map(sheet => Object.keys(sheet[0] || {})))), item => item !== 'xpos' && item !== 'ypos');
}

function parse_sheet_data(sheetData) {
  const final_data = sheetData.map((sheet_row) => {
    const new_row = {};
    for (const row_key in sheet_row) {
      if (row_key.startsWith('~')) {
        new_row[row_key] = parent_instance_map_by_name.value[sheet_row[row_key]] || null;
      }
      else if (field_values_map_by_name.value[row_key] && sheet_row[row_key]) {
        const split_field_value = sheet_row[row_key]?.split('|') || [];
        new_row[row_key] = split_field_value.reduce((field_values, value) => {
          if (field_values_map_by_name.value[row_key][value])
            field_values.push(field_values_map_by_name.value[row_key][value]);
          return field_values;
        }, []);
      }
      else if (row_key === 'name' || row_key === 'xpos' || row_key === 'ypos' || fields_map_by_name.value[row_key]) {
        validateSheetFields(row_key, sheet_row[row_key]);
        new_row[row_key] = row_key === 'name' ? sheet_row[row_key]?.toString() : sheet_row[row_key];
      }
    }
    return new_row;
  });
  return final_data;
}

function isValidEmail(email) {
  return /^[\w.!#$%&’*+/=?^`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i.test(String(email).toLowerCase());
}

function validateSheetFields(field_name, field_value) {
  if (isEmpty(fields_map_by_name.value) || !fields_map_by_name.value[field_name])
    return;

  const { type } = fields_map_by_name.value[field_name] || {};
  if (type && field_value) {
    let is_valid = true;
    if (type === 'email')
      is_valid = isValidEmail(field_value);
    else if (type === 'number')
      is_valid = Number(field_value);

    if (!is_valid)
      state.invalid_fields = [...state.invalid_fields, { [field_name]: field_value }];
  }
}

function getInvalidFieldMap() {
  if (!state.invalid_fields?.length)
    return null;
  const field_map = state.invalid_fields?.reduce((field_map, value) => {
    for (const field_name in value) {
      field_map[field_name] = field_map[field_name] || [];
      if (field_map[field_name])
        field_map[field_name] = [...field_map[field_name], value[field_name]];
    }
    return field_map;
  }, {});
  return field_map;
}

function handleError(message) {
  $toast({
    title: $t('Server Error'),
    text: message,
    type: 'error',
  });
}

function generateAliases(workbook) {
  const aliases_payload = [];
  let aliases_headers = [];
  const aliases_ws = workbook.getWorksheet('Aliases');
  aliases_ws.eachRow((row, rowNumber) => {
    const obj = {};
    if (rowNumber === 1) {
      aliases_headers = row.values.slice(1);
    }
    else {
      row.eachCell((cell, colNumber) => {
        const key = aliases_headers[colNumber - 1];
        obj[key] = cell.value ? cell.value.toString() : '';
      });
    }

    aliases_payload.push({
      name_1: null,
      name_2: null,
      name_3: null,
      name_4: null,
      ...obj,
    });
  });
  return aliases_payload;
}

async function importData(columns, data, aliases_payload) {
  try {
    state.progress_text = $t('Importing your data…');
    await system_model_store.import_instances({
      columns,
      data,
      plan_id: props.plan_id,
    });
    try {
      if (aliases_payload.slice(1).length) {
        state.progress_text = $t('Importing aliases…');
        await system_model_store.instance_aliases({
          template_id: route.params.template_id,
          body: aliases_payload.slice(1),
        });
      }
    }
    catch (err) {
      handleError($t('Failed to import aliases.'));
      logger.error(err);
    }

    await props?.load_instances();
    state.is_loading = false;
    emit('close');
  }
  catch (err) {
    state.progress_text = '';
    state.is_loading = false;
    handleError($t('Failed to import your data.'));
    logger.error(err);
  }
}

function getSheetsJson(sheet) {
  const sheetValues = sheet.getSheetValues();
  const filteredValues = sheetValues.filter(row => !row.every(value => value === null));
  return filteredValues;
}

async function importExcelSheet(form) {
  try {
    const file = form?.csv_import;
    state.is_loading = true;
    state.invalid_fields = [];
    state.progress_text = $t('Parsing your data...');
    const ExcelJS = await import('exceljs');

    const workbook = new ExcelJS.Workbook();

    // how many separate Excel  will open when viewing
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ];

    const reader = new FileReader();
    reader.onload = async function (e) {
      const bstr = e.target.result;
      workbook.xlsx.load(bstr)
        .then(async () => {
          let columns = [];
          let data = [];

          try {
            const sheets = workbook.worksheets.filter(sheet => sheet.name !== 'Aliases');

            const sheets_json = sheets.map(getSheetsJson);
            const transformed_data = [];

            // Loop through each sub-array
            for (const sub_array of sheets_json) {
              const header_row = sub_array[0];
              const data_row = sub_array.slice(1);
              const temp_arr = [];
              data_row.forEach((row) => {
                const obj = {};
                // Create an object with keys from header_row and values from data_row
                for (let i = 1; i < header_row.length; i++)
                  obj[header_row[i]] = row[i];

                temp_arr.push(obj);
              });
              transformed_data.push(temp_arr);
            }

            // payload data
            const aliases_payload = generateAliases(workbook);

            columns = getColumns(transformed_data);
            data = flatten(transformed_data.map((sheet) => {
              return parse_sheet_data(sheet);
            }));

            if (getInvalidFieldMap()) {
              state.progress_text = '';
              state.is_loading = false;
              return;
            }
            await importData(columns, data, aliases_payload);
          }
          catch (err) {
            state.progress_text = '';
            handleError($t('Failed to parse your data.'));
            logger.error(err);
          }
        })
        .catch((error) => {
          logger.error('Error:', error);
        });
    };
    reader.readAsArrayBuffer(file);
  }
  catch (err) {
    logger.error(err);
  }
}

async function submitHandler(form$) {
  try {
    const file = form$.data.csv_import;
    if (!isFileExtensionAllowed(file?.name) || file?.name.endsWith('.csv')) {
      handleError($t('Unsupported file'));
      return;
    }
    await importExcelSheet(form$.data);
  }
  catch (err) {
    emit('close');
    logger.error(err);
  }
}
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <HawkExportToast v-if="state.is_exporting" :submit="downloadTemplateSheet" :progress_text="$t('Exporting to XLSX')" :completed_text="$t('Exported XLSX')" @close="state.is_exporting = false" />
    <Vueform
      :display-errors="false"
      size="sm"
      :should_validate_on_mount="false"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('Import instances') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-96 scrollbar">
          <StaticElement
            name="static"
          >
            <div class="text-gray-600">
              {{ $t('Fill the details using the template and upload the file below to import instances') }}
            </div>
            <hawk-button type="text" class="text-gray-600 font-semibold mt-3" @click="state.is_exporting = true">
              {{ $t('Download template') }}
            </hawk-button>
            <div class="border-t border-t-gray-200 my-6" />
          </StaticElement>
          <FileElement
            name="csv_import"
            :presets="['hawk_file_element']"
            :options="{
              clickable_text: $t('Click to import'),
              text: $t('or drag and drop'),
            }"
            accept=".xlsx, .xls"
            :use_uppy="false"
            :drop="true"
            :auto="false"
            :submit="false"
            :clickable="false"
            :url="false"
            :rules="['required']"
            :messages="{ required: 'This field is required.' }"
            @remove="state.invalid_fields = []"
          />
          <StaticElement
            v-if="getInvalidFieldMap()"
            name="invalid_field"
          >
            <div class="mb-1">
              <div class="text-red-700">
                These are the invalid fields.
              </div>
              <div class="ml-1">
                <div v-for="(field, key) of getInvalidFieldMap()" :key="key" class="py-1 flex">
                  <div class="text-gray-900 font-medium min-w-[12rem]">
                    {{ key }}:
                  </div>
                  <div class="text-gray-600 text-sm break-all">
                    {{ field?.join(', ') }}
                  </div>
                </div>
              </div>
            </div>
          </StaticElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #left>
            <div class="text-gray-600">
              {{ state.progress_text }}
            </div>
          </template>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Import')"
                :loading="state.is_loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
