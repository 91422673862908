<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  data: {
    type: String,
    default: null,
  },
  save: {
    type: Function,
    default: null,
  },
  can_modify: {
    type: Boolean,
    default: false,
  },
  text_default: {
    type: String,
    default: null,
  },
  use_classes: {
    type: String,
    default: '!text-md text-gray-900 font-semibold ',
  },
  optional: {
    type: Boolean,
  },
});
const route = useRoute();
const text = ref(props.data || '');

const rename = ref(false);

function onSave(text) {
  rename.value = false;
  if (text !== props.data)
    props.save(text);
}

function editField() {
  rename.value = true;
}

defineExpose({
  editField,
});

watch(() => route.params, () => {
  rename.value = false; // when route changes disable the editing
});

watch(() => props.data, () => {
  text.value = props.data;
});
</script>

<template>
  <div v-if="rename">
    <hawk-editable-name
      class="max-w-[880px]"
      :name="text"
      :input_classes="{ TextElement: { input: `${use_classes} !p-0`, inputContainer: 'border-0 border-b' }, ElementLayout: { innerWrapper: 'border-b' } }"
      :optional="props.optional"
      @close="rename = false"
      @update="onSave"
    />
  </div>
  <div v-else class="inline-flex items-baseline mt-1 group">
    <p
      v-tippy="text.length > 55 ? text : ''"
      :class="`${use_classes} max-w-[800px] truncate h-8`"
    >
      {{ text || props.text_default }}
    </p>
    <slot />
    <span>
      <icon-hawk-edit class="text-primary w-4 h-4 ml-6 cursor-pointer  invisible" :class="[props.can_modify ? 'group-hover:visible' : 'invisible']" @click="rename = true" />
    </span>
  </div>
</template>

<style scoped lang="scss">
.input-div {
  background: transparent;
  outline: none;
  width: 100%;
  height: 1.5rem;
}

input[type="text"].input-div {
  &:focus {
    outline: none;
    border-color: #dfdfdd;
  box-shadow: none;
  }
}
</style>
