<script setup>
import dayjs from 'dayjs';
import { keyBy, remove } from 'lodash-es';
import Papa from 'papaparse';
import { useModal, VueFinalModal } from 'vue-final-modal';
import ColumnMapper from '~/common/components/csv-importer/column-mapper.vue';
import ImporterErrorPalette from '~/common/components/csv-importer/importer-error-palette.vue';
import validate from '~/common/components/csv-importer/validation-utils.js';

import { sleep } from '~/common/utils/common.utils.js';
import { load_js_css_file } from '~/common/utils/load-script.util.js';

const props = defineProps({
  schema: {
    type: Object,
    required: true,
  },
  automap_columns: {
    type: Boolean,
    default: false,
  },
  generate_sample_file_name: {
    type: Function,
    default: () => 'sample_data',
  },
});
const emit = defineEmits(['close', 'import-success']);
const $toast = inject('$toast');
const $t = inject('$t');

const log_performance = false;

/* ------------------------------------ COMPONENT VARIABLES ------------------------------------ */

/* ----- NON REACTIVE VARIABLES ----- */
const date_config = {
  type: 'date',
  dateFormat: 'YYYY-MM-DD',
  correctFormat: false,
  datePickerConfig: {
    firstDay: 0,
    showWeekNumber: true,
    numberOfMonths: 1,
  },
};
const initial_state = {
  show_importer: false,

  is_table_loading: false,
  importing: false,
  parsed: false,
  is_parsing: false,
  show_error_palette: false,

  csv_file: null,
  parsed_content: null,
  parsed_data_content: null,
  header_row: 0,
  csv_headers: [],

  mapping: {},
  required_columns_mapped: false,
  validation_status: {},

  translated_col: [],
  discarded_rows: [],
};

/* ----- REACTIVE VARIABLES ----- */
const state = reactive({ ...initial_state });
const form$ = ref(null);
const fileUploadInput = ref(null);
const header_selection_table = ref(null);
const data_table = ref(null);

/* ----- COMPUTED PROPERTIES ----- */
const current_step = computed(() => form$?.value?.steps$?.current$?.name);
const disable_step_navigation = computed(() => state.is_table_loading || state.importing || state.is_parsing);
const is_import_disabled = computed(() => Object.values(state.validation_status).map(value => value.is_valid).includes(false));
const schema_column_by_field = computed(() => keyBy(props.schema.columns, 'field'));

/* ----- TABLE SETTINGS ----- */
const table_settings = reactive({
  header_table_settings: {
    data: [],
    height: '365px',
    stretchH: 'all',
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    rowHeaders: true,
    renderAllRows: false,
    viewportRowRenderingOffset: 10,
    renderAllColumns: false,
    colHeaders: true,
    readOnly: true,
    selectionMode: 'single',
    afterSelection: (row) => {
      state.header_row = row;
      setMappingData();
      setCsvHeaders();
      header_selection_table.value.render();
    },
    renderer: (_instance, td, row, _col, _prop, value) => {
      if (row === state.header_row) {
        td.style.background = '#194185';
        td.style.color = 'white';
        td.style.fontWeight = '600';
      }
      td.innerHTML = value;
    },
  },
  data_table_settings: {
    data: [],
    height: '400px',
    columns: [],
    contextMenu: true,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    rowHeaders: true,
    manualColumnResize: true,
    renderAllRows: false,
    viewportRowRenderingOffset: 100,
    renderAllColumns: false,
    colWidths: i => props.schema.columns[i].col_width || 200,
    colHeaders: (col) => {
      const mapped_to = props.schema.columns.find(column => column.field === state.mapping[state.csv_headers[col].title])?.label;
      if (mapped_to)
        return `<b>${state.csv_headers[col].title} &rarr; ${mapped_to}</b> `;
      return `<b>${state.csv_headers[col].title}</b>`;
    },
    afterRemoveRow: (_index, _amount, physicalRows) => {
      Object.keys(state.validation_status).forEach((key) => {
        if (physicalRows.includes(Number(key.split('-')[0])))
          delete state.validation_status[key];
      });
      state.discarded_rows = [...remove(table_settings.data_table_settings.data, (_value, index) => {
        return physicalRows.includes(index);
      }), ...state.discarded_rows];
    },
    afterRemoveCol: (_index, _amount, physicalColumns) => {
      Object.keys(state.validation_status).forEach((key) => {
        if (physicalColumns.includes(Number(key.split('-')[1])))
          delete state.validation_status[key];
      });
    },
    afterChange: async (changes) => {
      log_performance && logger.time('afterChange');
      state.is_table_loading = true;
      await sleep(0);
      if (changes?.length) {
        const preHookCollection = [];
        state.csv_headers.forEach((header, idx) => {
          if (state.mapping[header.title]) {
            const column_config = schema_column_by_field.value[state.mapping[header.title]];
            preHookCollection.push(execPreHook(column_config, idx));
          }
        });
        await Promise.all(preHookCollection);
        reRenderDataTable();
      }
      state.is_table_loading = false;
      log_performance && logger.timeEnd('afterChange', changes);
    },
  },
});

/* ----- TRANSLATED TABLE DATA ----- */
const formatted_data = computed(() => {
  return state.parsed_data_content.map((data_row) => {
    const obj = {};
    state.csv_headers.forEach((header, idx) => {
      if (Object.keys(state.mapping).includes(header.title))
        obj[state.mapping[header.title]] = data_row[idx];
    });
    return obj;
  });
});
const data_by_col = computed(() => {
  const table_data = state.parsed_data_content;
  const colData = [];
  state.csv_headers.forEach((_header, idx) => {
    colData[idx] = table_data.map(row_data => row_data[idx]);
  });
  return colData;
});

/* ------------------------------------ LIFECYCLE HOOKS ------------------------------------ */
onMounted(async () => {
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.1/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@14.6.1/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );
  if (props.schema.methods?.init)
    await props.schema.methods.init();
});

watch(() => state.required_columns_mapped, (val) => {
  if (!val)
    form$.value.steps$.steps$['4'].disable();
});

/* ------------------------------------ METHODS ------------------------------------ */

/* -------- IMPORTER CLOSE -------- */
function closeImporter() {
  Object.assign(state, initial_state);
  emit('close');
}

/* -------- COLUMN MAPPER -------- */
const { open: openColumnMapper, close: closeColumnMapper, patchOptions: patchColumnMapper } = useModal({
  component: ColumnMapper,
});
function handleColumnMapperPopup() {
  patchColumnMapper({
    attrs: {
      schema_columns: props.schema.columns,
      csv_columns: state.csv_headers.map(header => header.title),
      data: state.parsed_data_content,
      mapping: state.mapping,
      automap_columns: props.automap_columns,
      onClose(mapping_status, mapping) {
        setMappingData(mapping, mapping_status);
        closeColumnMapper();
      },
      async onMapping(mapping) {
        closeColumnMapper();
        setMappingData(mapping, true);
        form$.value.steps$.next();
      },
    },
  });
  openColumnMapper();
}

/* -------- STEP NAVIGATION -------- */
function goNext() {
  form$.value.steps$.next();
}
function goPrev() {
  form$.value.steps$.previous();
}
async function stepSelectHandler(active_step) {
  if (active_step.name === '3')
    await makeHeaderSelectionTable();
  else if (active_step.name === '4')
    await mapColumns();
}

/* -------- FILE DROP AND PARSE -------- */
function triggerInvalidFileToast() {
  $toast({
    title: $t('File upload failed'),
    text: $t('Please upload file with .xlsx extension'),
    type: 'error',
    position: 'top-right',
  });
}
function onFileDrop(e) {
  e.preventDefault();

  const files = e.dataTransfer.files;
  const validFiles = [];

  for (const file of files) {
    if (file.name.endsWith('.xlsx'))
      validFiles.push(file);
  }

  if (validFiles.length > 0) {
    e.target.files = new DataTransfer().files;
    const dataTransfer = new DataTransfer();
    validFiles.forEach(file => dataTransfer.items.add(file));
    e.target.files = dataTransfer.files;
    fileUploadInput.value.files = e.dataTransfer.files;
    addFile();
  }
  else {
    triggerInvalidFileToast();
  }
}
async function parseCSVFile(file) {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      complete: (results) => {
        resolve(results.data);
      },
    });
  });
}
async function addFile(e) {
  const file = fileUploadInput.value.files?.[0];
  resetHeaderSelectionStageData();

  if (!file?.name.endsWith('.xlsx')) {
    triggerInvalidFileToast();
    return;
  }

  state.csv_file = file;
  const file_type = state.csv_file.name.split('.')[1];
  if (file_type === 'csv') {
    state.is_parsing = true;
    state.parsed_content = await parseCSVFile(state.csv_file);
    setCsvHeaders();
    state.is_parsing = false;
    goNext();
  }
  else if (file_type === 'xlsx') {
    const ExcelJS = await import('exceljs');

    const workbook = new ExcelJS.Workbook();

    // how many separate Excel  will open when viewing
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ];

    const FR = new FileReader();
    FR.readAsArrayBuffer(state.csv_file);
    FR.onload = async (e) => {
      const data = new Uint8Array(e.target.result);

      await workbook.xlsx.load(data);

      const worksheet = workbook.worksheets[0];

      const parsed_content = [];

      worksheet.eachRow((row) => {
        const rowData = {};
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          // Assuming the first row contains headers
          const headerCell = worksheet.getRow(1).getCell(colNumber);
          const headerValue = headerCell.value;
          if (headerValue) {
            if (cell.type === ExcelJS.ValueType.Hyperlink)
              rowData[headerValue] = cell.value.text;
            else if (cell.type === ExcelJS.ValueType.Formula)
              rowData[headerValue] = cell.value.result;
            else if (cell.type === ExcelJS.ValueType.Date)
              rowData[headerValue] = dayjs(cell.value).format('YYYY-MM-DD');
            else if (cell.type === ExcelJS.ValueType.Error)
              rowData[headerValue] = '';
            else
              rowData[headerValue] = (cell.value === null || cell.value === undefined) ? '' : cell.value;
          }
        });
        parsed_content.push(Object.values(rowData));
      });

      state.parsed_content = parsed_content;
      setCsvHeaders();
      goNext();
    };
  }
  if (e?.target?.value)
    e.target.value = '';
}

/* -----SETTER METHODS----- */
function setCsvHeaders() {
  state.csv_headers = state.parsed_content[state.header_row].map((item, idx) => ({
    title: item.length ? item : `Column ${idx + 1}`,
  }));
  state.parsed_data_content = state.parsed_content.slice(state.header_row + 1);
}
function resetHeaderSelectionStageData() {
  state.csv_file = null;
  state.parsed_content = null;
  state.parsed_data_content = null;
  state.header_row = 0;
  state.csv_headers = [];
  state.validation_status = {};
  setMappingData();
}
function setMappingData(mapping = {}, required_columns_mapped = false) {
  state.mapping = mapping;
  state.required_columns_mapped = required_columns_mapped;
}

/* ----- HANDSONTABLE UTILITIES----- */
async function makeHeaderSelectionTable() {
  state.is_table_loading = true;
  await sleep(10);
  const container = document.getElementById('header_selection_table');
  table_settings.header_table_settings.data = state.parsed_content;
  header_selection_table.value = new Handsontable(container, table_settings.header_table_settings);
  header_selection_table.value.render();
  state.is_table_loading = false;
}
async function makeDataTable() {
  const container = document.getElementById('data_table');
  table_settings.data_table_settings.data = state.parsed_data_content;
  data_table.value = new Handsontable(container, table_settings.data_table_settings);
  setTimeout(() => {
    data_table.value?.render();
  }, 100);
}
function highlightErrorCell(cell_coords) {
  data_table.value.scrollViewportTo(cell_coords[0], cell_coords[1]);
  data_table.value.selectCell(cell_coords[0], cell_coords[1]);
  const data = data_table.value.getDataAtCell(cell_coords[0], cell_coords[1]);
  data_table.value.getActiveEditor().enableFullEditMode();
  data_table.value.getActiveEditor().beginEditing(data);
}
async function reRenderDataTable() {
  setTimeout(() => {
    data_table.value?.render();
  }, 10);
}

/* ----- AFTER MAPPING METHODS----- */
async function mapColumns() {
  state.is_table_loading = true;
  await sleep(0);
  log_performance && logger.time('mapColumns');
  const promiseCollection = [];
  state.csv_headers.forEach((header, col_index) => {
    if (state.mapping[header.title])
      promiseCollection.push(mapColumn(header.title, col_index));
  });
  await Promise.all(promiseCollection);
  await makeDataTable();
  state.is_table_loading = false;
  log_performance && logger.timeEnd('mapColumns');
}

async function mapColumn(csv_col, col_idx) {
  log_performance && logger.time(`MapColumn-${csv_col}-${col_idx}`);
  const column_config = schema_column_by_field.value[state.mapping[csv_col]];
  await execPreHook(column_config, col_idx);
  validateColumn(col_idx, column_config);
  updateColumnRenderer(column_config, col_idx);
  log_performance && logger.timeEnd(`MapColumn-${csv_col}-${col_idx}`);
}
async function execPreHook(column_config, col_idx) {
  log_performance && logger.time(`Prehook-${column_config.field}`);
  const colData = data_by_col.value[col_idx];
  if (column_config?.preHook) {
    state.translated_col[col_idx] = await column_config.preHook(colData, formatted_data.value);
    table_settings.data_table_settings.data = state.parsed_data_content.map(
      (data, rowIdx) => {
        const row = data;
        row[col_idx] = state.translated_col[col_idx][rowIdx]?.data;
        return row;
      },
    );
    state.parsed_data_content = table_settings.data_table_settings.data;
  }
  log_performance && logger.timeEnd(`Prehook-${column_config.field}`);
}
function getCellAttributes(cell_data, column_config) {
  let title = '';
  let editor = '';
  let validation = {
    is_valid: true,
    message: '',
  };
  if (cell_data?.properties?.message)
    title = cell_data.properties.message;
  // Validation
  const validation_status = cell_data?.properties?.is_valid;
  if (typeof validation_status == 'boolean') {
    validation = {
      is_valid: validation_status,
      message: cell_data?.properties?.message,
    };
  }

  // Disabled check
  if (cell_data?.properties?.is_disabled === true)
    editor = false;
  else if (column_config.data_type === 'date' || column_config.data_type === 'dropdown')
    editor = column_config.data_type;
  else
    editor = 'text';
  return { title, validation, editor };
}

function validateColumn(col_idx, column_config) {
  const column_data = data_by_col.value[col_idx];
  column_data.forEach(async (value, row_idx) => {
    const { is_valid, message } = await validate(value, column_config, column_data);
    state.validation_status[`${row_idx}-${col_idx}`] = { is_valid, message };
    if (is_valid && state.translated_col[col_idx]) {
      const cell_data = state.translated_col[col_idx][row_idx];
      const computed_attributes = getCellAttributes(cell_data, column_config);
      state.validation_status[`${row_idx}-${col_idx}`] = computed_attributes.validation;
    }
  });
}
function updateColumnRenderer(column_config, col_idx) {
  table_settings.data_table_settings.columns[col_idx] = {
    ...(column_config.data_type === 'dropdown' && {
      type: 'dropdown',
      source: column_config.source,
    }),
    ...(column_config.data_type === 'date' && {
      ...date_config,
    }),
    renderer: async (
      _instance,
      td,
      row,
      col,
      _prop,
      value,
      cellProperties,
    ) => {
      if (col === col_idx) {
        const column_data = data_by_col.value[col_idx];
        const { is_valid, message } = await validate(value, column_config, column_data);
        state.validation_status[`${row}-${col}`] = { is_valid, message };
        if (is_valid === false) {
          // If basic validation fails then set the style with the existing value
          const schema_error_style = props.schema?.legends?.error?.style;
          if (schema_error_style) {
            Object.keys(schema_error_style).forEach(
              key => (td.style[key] = schema_error_style[key]),
            );
          }
          else {
            td.style.background = 'red';
            td.style.color = 'white';
          }
          td.title = message;
        }
        else if (state.translated_col[col_idx]) {
          const cell_data = state.translated_col[col_idx][row];
          const computed_attributes = getCellAttributes(cell_data, column_config);
          td.title = computed_attributes.title;
          cellProperties.editor = computed_attributes.editor;
          state.validation_status[`${row}-${col}`] = computed_attributes.validation;
          // Custom style application
          const custom_styles = cell_data?.properties?.style;
          if (custom_styles) {
            Object.keys(custom_styles).forEach(
              key => (td.style[key] = custom_styles[key]),
            );
          }
        }
        td.innerHTML = value;
      }
    },
  };
}

/* ----- POSTHOOK AND FINAL IMPORT----- */
async function execPostHook(column_config, col_idx) {
  const colData = data_by_col.value[col_idx];
  if (column_config?.postHook) {
    state.translated_col[col_idx] = await column_config.postHook(colData);
    table_settings.data_table_settings.data = table_settings.data_table_settings.data.map(
      (data, rowIdx) => {
        const row = data;
        row[col_idx] = state.translated_col[col_idx][rowIdx]?.data;
        return row;
      },
    );
  }
}
async function importHandler() {
  state.is_table_loading = true;
  const postHookPromises = [];
  state.csv_headers.forEach((header, col_idx) => {
    if (state.mapping[header.title]) {
      const column_config = schema_column_by_field.value[state.mapping[header.title]];
      if (column_config?.postHook)
        postHookPromises.push(execPostHook(column_config, col_idx));
    }
  });
  try {
    await Promise.all(postHookPromises);
    reRenderDataTable();
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
      position: 'bottom-right',
    });
    state.is_table_loading = false;
    return;
  }

  try {
    state.importing = true;
    await props.schema.methods.callback(formatted_data.value);
    $toast({
      title: 'Import successful!',
      text: 'Import was successful, imported items should reflect in the list',
      type: 'success',
      position: 'bottom-right',
    });
    state.importing = false;
    // eslint-disable-next-line vue/custom-event-name-casing
    emit('import-success');
    setTimeout(() => closeImporter(), 3000);
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: $t('Import failed'),
      text: $t('Please try again'),
      type: 'error',
      position: 'bottom-right',
    });
    state.importing = false;
  }
  state.is_table_loading = false;
}
</script>

<template>
  <VueFinalModal
    content-class="bg-white border w-full h-screen"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    @click-outside="closeImporter()"
    @closed="closeImporter()"
  >
    <HawkModalHeader @close="closeImporter()" @cancel="closeImporter()">
      <template #title>
        {{ $t('Import your .xlsx files') }}
      </template>
    </HawkModalHeader>
    <HawkModalContent :is_scroll="false" class="!p-0 h-[calc(100vh_-_160px)] scrollbar">
      <!-- STEPS -->
      <div class="px-32 py-8 text-gray-700 font-semibold">
        <Vueform ref="form$">
          <template #empty>
            <FormSteps @select="stepSelectHandler">
              <FormStep name="1">
                <p :class="{ 'text-primary-600': current_step === '1' }">
                  {{ $t('Getting started') }}
                </p>
              </FormStep>
              <FormStep name="2">
                <p :class="{ 'text-primary-600': current_step === '2' }">
                  {{ $t('Upload spreadsheet') }}
                </p>
              </FormStep>
              <FormStep name="3">
                <p :class="{ 'text-primary-600': current_step === '3' }">
                  {{ $t('Match columns') }}
                </p>
              </FormStep>
              <FormStep name="4">
                <p :class="{ 'text-primary-600': current_step === '4' }">
                  {{ $t('Sanitize data') }}
                </p>
              </FormStep>
            </FormSteps>
          </template>
        </Vueform>
      </div>
      <!-- STEP COMPONENTS -->
      <GettingStarted
        v-if="current_step === '1'"
        :columns="schema.columns"
        :generate_sample_file_name="generate_sample_file_name"
      />
      <div
        v-else-if="current_step === '2'"
        class="flex items-center justify-center flex-col h-[calc(100vh_-_350px)] border-2 border-gray-200 rounded-md mx-8 text-sm font-medium"
        @dragover.prevent
        @drop="onFileDrop"
        @dragleave.prevent
      >
        <HawkFeaturedIcon theme="light-circle-outline" color="primary" size="md" class="mb-2">
          <IconHawkUploadCloudTwo />
        </HawkFeaturedIcon>
        <p class=" text-gray-600">
          {{ $t('Drag and drop file here') }}
        </p>
        <p class="my-3">
          or
        </p>
        <HawkButton @click="fileUploadInput?.click()">
          {{ $t('Browse Files') }}
        </HawkButton>
        <p class="text-gray-600 mb-4">
          [.xlsx]
        </p>
        <p
          v-if="state.csv_file"
          class="text-gray-600"
        >
          Selected: <i class="underline text-primary-500">{{ state.csv_file.name }}</i>
        </p>
        <hawk-loader v-if="state.is_parsing" />
        <input
          id="fileUploader"
          ref="fileUploadInput"
          type="file"
          style="display: none"
          accept=".xlsx"
          @change="addFile"
        >
      </div>
      <div v-else-if="current_step === '3'" class="mx-8">
        <div class="flex items-center justify-between mb-1">
          <p class="text-md font-semibold mb-4">
            {{ $t('Select a row from the table which will be the header') }}
          </p>
          <hawk-button type="outlined" @click="handleColumnMapperPopup">
            {{ $t('Map columns') }}
          </hawk-button>
        </div>
        <div class="relative h-[365px]">
          <div v-if="state.is_table_loading" class="absolute top-0 right-0 left-0 bottom-0 z-999 bg-gray-900 opacity-10 flex items-center justify-center">
            <hawk-loader />
          </div>
          <div id="header_selection_table" />
        </div>
        <hawk-alert-banner color="primary" class="text-sm !p-2 font-semibold mt-4">
          <template #icon>
            <IconHawkInfoCircle />
          </template>
          <template #content>
            {{ `Row ${state.header_row + 1} selected as header` }}
          </template>
        </hawk-alert-banner>
      </div>
      <div v-else-if="current_step === '4'" class="mx-8">
        <div class="flex items-center justify-between mb-4 h-[40px]">
          <div class="flex items-center">
            <div v-for="(value, key) in schema.legends" :key="key" class="flex items-center mr-8">
              <div
                class="h-4 w-4 rounded mr-1"
                :style="{ backgroundColor: value?.style?.backgroundColor }"
              />
              <p class="text-gray-700 text-sm">
                {{ value.label }}
              </p>
            </div>
          </div>
          <ImporterErrorPalette
            v-if="is_import_disabled"
            :errors="state.validation_status"
            :csv_columns="state.csv_headers.map(col => col.title)"
            :schema_columns="schema_column_by_field"
            :mapping="state.mapping"
            @scroll-to="highlightErrorCell"
          />
        </div>
        <div class="relative h-[400px]">
          <div v-if="state.is_table_loading" class="absolute top-0 right-0 left-0 bottom-0 z-999 bg-gray-900 opacity-10 flex items-center justify-center">
            <hawk-loader />
          </div>
          <div id="data_table" />
        </div>
      </div>
    </HawkModalContent>
    <HawkModalFooter>
      <template #left>
        <div class="flex justify-between">
          <HawkButton
            :class="{
              invisible: current_step === '1',
            }"
            :disabled="disable_step_navigation"
            type="outlined"
            @click="goPrev"
          >
            {{ $t('Previous') }}
          </HawkButton>
          <div>
            <HawkButton
              type="outlined"
              class="mr-3"
              :disabled="disable_step_navigation"
              @click="closeImporter()"
            >
              {{ $t('Cancel') }}
            </HawkButton>
            <HawkButton
              v-if="current_step === '4'"
              :disabled="is_import_disabled || disable_step_navigation"
              :loading="state.importing"
              @click="importHandler"
            >
              {{ $t('Import') }}
            </HawkButton>
            <HawkButton
              v-else
              :disabled="(current_step === '2' && !state.parsed_content) || (current_step === '3' && !state.required_columns_mapped) || disable_step_navigation"
              @click="goNext"
            >
              {{ $t('Next') }}
            </HawkButton>
          </div>
        </div>
      </template>
    </HawkModalFooter>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
  /* base styling */
  :deep(.handsontable) {
    font-family: Lato, Arial, sans-serif;
    font-weight: normal;
    font-size: 12px;

    td {
      color: #475467;
      padding-left: 14px;
    }

    th {
      line-height: 40px;
      vertical-align: middle;
      background-color: #F9FAFB;
      padding-left: 10px;
      padding-right: 10px;
      color: #475467;
    }
  }
</style>
