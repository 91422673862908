<script setup>
import { compact, isArray, isObject, orderBy, slice } from 'lodash-es';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useElementBounding } from '@vueuse/core';
import { useCommonStore } from '~/common/stores/common.store';
import Member from '~/common/components/organisms/hawk-members-badge/member.vue';

const props = defineProps({
  members: {
    required: true, // any format allowed <uid>, <object>, [<uid>,<object>]
    default: () => [],
  },
  max_badges_to_display: {
    type: Number,
    default: 1,
  },
  name_truncate_length: {
    type: Number,
    default: 30,
  },
  email_truncate_length: {
    type: Number,
    default: 30,
  },
  popover_name_truncate_length: {
    type: Number,
    default: 30,
  },
  has_avatar: {
    type: Boolean,
    default: true,
  },
  has_name: {
    type: Boolean,
    default: false,
  },
  has_email: {
    type: Boolean,
    default: false,
  },
  can_clear: {
    type: Boolean,
    default: false,
  },
  has_tooltip: {
    type: Boolean,
    default: true,
  },
  tooltip_content: {
    type: String,
    required: '',
  },
  has_border: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['badge', 'tiny', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
    },
  },
  popover_avatar_size: {
    type: String,
    default: 'xs',
    validator(value) {
      return ['badge', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
    },
  },
  type: {
    type: String,
    default: 'default',
    validator(value) {
      return ['default', 'group', 'label', 'badge', 'only_name', 'only_email'].includes(value);
    },
  },
  popover_position: {
    type: String,
    default: 'middle',
    validator(value) {
      return ['left', 'middle', 'right'].includes(value);
    },
  },
  is_outlined: {
    type: Boolean,
    default: false,
  },
  is_fixed: {
    type: Boolean,
    default: false,
  },
  name_classes: {
    type: String,
    default: '',
  },
  email_classes: {
    type: String,
    default: '',
  },
});

const common_store = useCommonStore();
const open = ref(false);
const member_size = ref(props.size);
const popover_position_class = ref('right-0 top-0');
const popover_dropdown_position = ref(null);
const popover_pos_target = ref(null);
const el = ref(null);

const { x, y, top, right, bottom, left, width, height } = useElementBounding(el);

const popover_panel = ref(null);
const { width: popover_panel_width, height: popover_panel_height } = useElementBounding(popover_panel);
const members_uids = computed(() => {
  let items = [];
  if (isArray(props.members))
    items = props.members.map(m => isObject(m) ? m.uid : m);
  else
    items = isObject(props.members) ? [props.members.uid] : [props.members];

  return compact(items);
});

// returns [..users,...teams]
// item  - { uid,type:'<team/user>'}
const members_sorted_by_type = computed(() =>
  orderBy(
    members_uids.value.map(uid =>
      ({ uid, type: common_store.is_type_team(uid) ? 'team' : 'user' }),
    ),
    item => item.type === 'user' ? 0 : 1));

const members_to_display = computed(() => slice(members_sorted_by_type.value, 0, props.max_badges_to_display));

const remaining_members_to_display = computed(() => slice(members_sorted_by_type.value, props.max_badges_to_display, members_uids.value?.length));

const show_popover = computed(() => remaining_members_to_display.value.length);

function togglePopover(value) {
  const current_position = popover_pos_target.value.getBoundingClientRect();
  const screen_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  const y_class = current_position.y < screen_center.y ? 'top-full' : 'bottom-full';
  popover_dropdown_position.value = [current_position.y < screen_center.y ? 'down' : 'up', current_position.x < screen_center.x ? 'right' : 'left'];
  popover_position_class.value = `${y_class}`;

  open.value = value;
}

const popover_badge_classes = computed(() => {
  const classes = [
    'bg-gray-100',
    'text-gray-700',
    'grid',
    'place-items-center',
    'px-1.5',
  ];
  if (props.type === 'badge')
    switch (props.size) {
      case 'badge':
      case 'tiny':
      case 'xxs':
      case 'xs':
        classes.push('text-xs');
        break;
      case 'sm':
        classes.push('h-[22px] text-xs');
        break;
      case 'md':
        classes.push('h-7 text-sm');
        break;
      case 'lg':
        classes.push('h-8 text-sm');
        break;
    }

  else
    switch (props.size) {
      case 'badge':
      case 'tiny':
        classes.push('h-4', 'min-w-4', 'text-xs');
        break;
      case 'xxs':
        classes.push('h-5', 'min-w-5', 'text-xs');
        break;
      case 'xs':
        classes.push('h-6', 'min-w-6', 'text-sm');
        break;
      case 'sm':
        classes.push('h-8', 'min-w-8', 'text-sm');
        break;
      case 'md':
        classes.push('h-10', 'min-w-10', 'text-base');
        break;
      case 'lg':
        classes.push('h-12', 'min-w-12', 'text-lg');
        break;
      case 'xl':
        classes.push('h-14', 'min-w-14', 'text-xl');
        break;
      case '2xl':
        classes.push('h-16', 'min-w-16', 'text-2xl');
        break;
    }

  if (props.is_outlined)
    classes.push('border border-gray-300 rounded-lg');

  else
    classes.push('bg-gray-100 rounded-full');

  return [...classes, 'rounded-full'];
});

const popover_panel_classes = computed(() => {
  const classes = [];
  switch (props.popover_position) {
    case 'left':
      classes.push('');
      break;
    case 'middle':
      classes.push('left-1/2 -translate-x-1/2');
      break;
    case 'right':
      classes.push('right-0');
      break;
  }

  return classes;
});
</script>

<template>
  <div
    v-if="members_to_display?.length"
    ref="el"
    class="inline-flex items-center flex-wrap"
    :class="type === 'group' ? '-space-x-2' : 'gap-2'"
  >
    <Member
      v-for="(member, index) in members_to_display"
      :key="member.uid"
      :member="{ ...member, index: index + 1 }"
      v-bind="{
        name_truncate_length,
        email_truncate_length,
        has_avatar,
        has_name,
        has_email,
        can_clear,
        has_tooltip,
        tooltip_content,
        has_border,
        size,
        is_outlined,
        type,
        name_classes,
        email_classes,
      }"
    />
    <Popover
      v-if="show_popover"
      class="relative flex items-center"
      @mouseenter="togglePopover(true)"
      @mouseleave="togglePopover(false)"
      @click="togglePopover(!open)"
    >
      <PopoverButton class="inline-flex items-center font-medium text-gray-900 cursor-pointer h-full w-full">
        <div :class="popover_badge_classes">
          +{{ remaining_members_to_display?.length }}
        </div>
      </PopoverButton>
      <span
        ref="popover_pos_target"
        class="absolute top-0 left-0 w-full h-full pointer-events-none"
      />

      <div v-if="open">
        <PopoverPanel
          ref="popover_panel" static
          class="z-[11] flex w-screen max-w-min"
          :class="!is_fixed ? [popover_position_class, popover_panel_classes,'absolute'] : ['fixed']" 
          :style="is_fixed ? {
            top: popover_dropdown_position?.[0] === 'up' ? `${y - popover_panel_height}px` : `${y + 16}px`,
            left: popover_dropdown_position?.[1] === 'right' ? `${right - 32}px` : `${right - popover_panel_width}px`,
          } : {}"
        >
          <div class="w-56 flex-shrink-0 rounded-xl bg-white p-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 max-h-60 scrollbar">
            <div v-for="(member, index) in remaining_members_to_display" :key="member" class="px-1.5 py-1">
              <Member
                :member="{ ...member, index: index + 1 }"
                type="label"
                :size="popover_avatar_size"
                :name_truncate_length="popover_name_truncate_length"
                name_classes="!whitespace-normal"
              />
            </div>
          </div>
        </PopoverPanel>
      </div>
    </Popover>
  </div>
  <div v-else>
    <slot>-</slot>
  </div>
</template>
