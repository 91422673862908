import { cloneDeep } from 'lodash-es';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { document_status_keys } from '~/dms/constants';

export function useTransmittalWorkflowConfiguration(props) {
  const dms_settings_store = useDMSSettingsStore();
  function get_default_statuses(status_map = []) {
    return dms_settings_store.transmittal_status.map((status, index) => ({
      review_status: status_map[index]?.review_status || status.name,
      document_status: index === 0 ? null : status_map[index]?.document_status || dms_settings_store.active_document_status[0]?.slug,
      class: status.class,
      key: document_status_keys[index] || null,
      disabled: status.disabled,
      active: status_map[index] ? status_map[index]?.active : true,
    }));
  }

  const default_statuses = ref(get_default_statuses(props?.workflow?.workflow?.status_map));

  const outcome_strategy_list = [
    { label: 'Final block decision', value: 'final' },
    { label: 'Least of all blocks', value: 'least' },
  ];

  function getSelectedWorkflow(workflow) {
    if (props?.workflow || workflow) {
      const selected_workflow = cloneDeep(props?.workflow || workflow);
      selected_workflow.workflow.steps = Object.values(selected_workflow?.workflow?.steps || {});
      return selected_workflow;
    }
  }

  const data = getSelectedWorkflow() || {
    name: 'text',
    workflow: {
      steps: {
        1: {
          index: 1,
          type: 'multiple',
          condition: 'OR',
          on_reject: 'continue',
          active: true,
          sub_steps: [
            {
              sub_index: 1,
              name: 'Review block',
              members: [
              ],
              condition: 'AND',
            },
          ],
          duration: 15,
        },
      },
      status_map: get_default_statuses(props?.workflow?.workflow?.status_map),
      outcome_strategy: outcome_strategy_list[0]?.value,
      entry_status: dms_settings_store.active_document_status[0]?.name,
    },
  };

  const steps = ref(Object.values(data?.workflow?.steps || []));

  return { get_default_statuses, getSelectedWorkflow, outcome_strategy_list, default_statuses, data, steps };
}

export function useTransmittalWorkflowPayloadConfiguration() {
  function formatSteps(steps) {
    const obj = {};
    steps.forEach((step, index) => {
      step.name = step.sub_steps[0].name;
      step.members = step.sub_steps[0].members;
      step.min_approvals = step.min_approvals ? +step.min_approvals : 0;
      step.type = step.sub_steps.length === 1 ? 'single' : 'multiple';
      if (step.sub_steps.length === 1)
        obj[index] = { ...step, min_approvals: +step.sub_steps[0] || 0 };
      else
        obj[index] = step;
    });
    return obj;
  };

  function formatStatusMap(status_map, default_statuses) {
    return (status_map || []).map((status, index) => ({
      ...status,
      key: default_statuses?.[index]?.key,
      active: default_statuses?.[index]?.active,
    }));
  }
  return { formatSteps, formatStatusMap };
}
