<script setup>
const props = defineProps({
  attachment: {
    type: Object,
  },
  has_next: {
    type: Boolean,
  },
  has_previous: {
    type: Boolean,
  },
  items: {
    type: Array,
  },
});

const emits = defineEmits(['close', 'delete', 'download', 'next', 'previous', 'buttonClicked', 'setActiveAttachment']);

const document_viewer = ref(null);

const file_name = props.attachment?.file_name || '';
const file_name_split = file_name.split('.');
const setup_options = {
  no_zoom: true,
  extension: file_name_split.pop(),
};

const document_viewer_ref = ref(null);

function initDocViewer(doc_instance) {
  document_viewer.value = doc_instance;
}

function fullScreen() {
  if (document_viewer_ref?.value?.requestFullscreen)
    document_viewer_ref.value.requestFullscreen();
}
const url = props.attachment?.url || props.attachment?.service?.url;
</script>

<template>
  <Teleport v-if="props.attachment" to="body">
    <div class="vfm vfm--fixed vfm--inset flex justify-center items-center" aria-modal="true" style="z-index: 1010;">
      <div class="vfm__overlay vfm--overlay vfm--absolute vfm--inset vfm--prevent-none" aria-hidden="true" style="background-color:#101828E5" />
      <div class="h-full w-full">
        <hawk-attachment-viewer-header :attachment="props.attachment" :document_viewer="document_viewer" @close="emits('close')" @fullscreen="fullScreen" @delete="emits('delete')" @download="emits('download')">
          <template #header-right-content>
            <slot name="header-right-content" />
          </template>
        </hawk-attachment-viewer-header>
        <div class="flex justify-between items-center">
          <div
            class="text-white ml-4 p-2 cursor-pointer hover:bg-gray-800 rounded-lg absolute left-0 z-1"
            :class="{ 'pointer-events-none': !props.has_previous }"
            @click="emits('previous')"
          >
            <IconHawkChevronLeft v-if="props.has_previous" />
          </div>
          <div ref="document_viewer_ref" class="w-full pb-8 text-white" :class="[props.items?.length > 1 ? 'h-[calc(100vh-180px)]' : 'h-[calc(100vh-64px)]']">
            <sheet-viewer v-if="url" :sheet_url="url" :no_tools="true" scroll_view_class="border-0 h-full" class="text-white" :setup_options="setup_options" @on-doc-load="initDocViewer" />
          </div>
          <div
            class="text-white mr-4 p-2 cursor-pointer hover:bg-gray-800 rounded-lg absolute right-0 z-1"
            :class="{ 'pointer-events-none': !props.has_next }"
            @click="emits('next')"
          >
            <IconHawkChevronRight v-if="props.has_next" />
          </div>
        </div>
        <hawk-attachment-viewer-footer
          class="absolute bottom-0 w-full"
          v-if="items?.length > 1"
          :items="items"
          :attachment="attachment"
          @setActiveAttachment="emits('setActiveAttachment', $event)"
        />
      </div>
    </div>
  </Teleport>
</template>
