<script setup>
import { uniq } from 'lodash-es';
import { useRoute } from 'vue-router';
import ChatCommentInput from '~/common/components/organisms/chat-comment-input.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'comment',
    validator: val => ['comment', 'reply'].includes(val),
  },
  task_uid: {
    type: String,
    required: true,
  },
  comment: {
    type: Object,
    default: null,
  },
  chat_store: {
    type: Object,
    required: true,
  },
  comment_input_config: {
    type: Object,
    default: () => ({}),
  },
  user: {
    type: String,
    required: true,
  },
  module: {
    type: String,
    default: 'tasks',
  },
});

const emit = defineEmits([
  'close',
  'input',
  'reply',
  'send',
  'showMoreOptions',
]);

const task_store = inject('task_store');
const $toast = inject('$toast');
const $track_event = inject('$track_event');
const route = useRoute();

const task_details = computed(() => {
  return task_store?.get_current_task?.(props.task_uid) || {};
});

const asset_id = computed(() => {
  return task_details?.value?.target_element?.asset ?? route?.params?.asset_id;
});

async function onSend({ text, mentions, sent_chat, files }) {
  task_store.update_last_read(props.task_uid, props.user, new Date(sent_chat.message.created_at || sent_chat.message.createdAt));
  task_store.update_comment_stats(props.task_uid, 0);

  task_store.task_track_events('Comment added', { mentions: mentions.length }, props.task_uid);
  if (files.length)
    task_store.task_track_events('Attachments added', {
      count: files.length,
      filetype: uniq(files.map(val => val.type)).join(', '),
      filesize: files.map(val => val.size).reduce((acc, val) => acc + val, 0),
      location: 'Comment',
    }, props.task_uid);

  emit('send');
}

async function uploadAttachments({ comment_id, attachments }) {
  await task_store.set_comment_attachments({ task_uid: props.task_uid, comment_id }, { attachments });
}

function commentUpdated({ mentions }) {
  task_store.task_track_events('Comment updated', { mentions: mentions.length }, props.task_uid);
}
</script>

<template>
  <ChatCommentInput
    :comment_input_config="comment_input_config"
    :type="type"
    :comment="comment"
    :chat_store="chat_store"
    :asset_id="asset_id"
    :user="user"
    :attachment_config="{ meta: { service: 'tickets', id: task_uid } }"
    :module="module"
    @commentUpdated="commentUpdated"
    @uploadAttachments="uploadAttachments"
    @commentSent="onSend"
    @input="$event => emit('input', $event)"
    @showMoreOptions="emit('showMoreOptions')"
    @close="emit('close')"
    @reply="emit('reply')"
  >
    <template #more_options>
      <slot name="more_options" />
    </template>
  </ChatCommentInput>
</template>
