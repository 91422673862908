<script setup>
import { storeToRefs } from 'pinia';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { createListOnPaste } from '~/system-model/utils/helper';

const props = defineProps({
  plan_id: {
    type: String,
    default: null,
  },
  load_instances: {
    type: Function,
    default: null,
  },
});

const emit = defineEmits(['close']);
const system_model_store = useSystemModelStore();
const { active_component } = storeToRefs(system_model_store);

const state = reactive({
  is_loading: false,
  is_paste_loading: false,
});
const form$ = ref(null);

async function submitHandler(form$) {
  try {
    state.is_loading = true;
    const data = form$.data;
    const instance_payload = data?.new_instances.map(instance => ({
      name: instance,
      plan: props.plan_id,
      component: active_component.value?.uid,
    }));
    await system_model_store.create_instances(instance_payload);
    await props?.load_instances();
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}

function pasteHandler($event) {
  state.is_paste_loading = true;
  const items = createListOnPaste($event);
  const new_instances = form$.value.data?.new_instances || [];
  const updated_instances = [...new_instances.filter(Boolean), ...items];
  setTimeout(() => {
    form$.value.update({
      new_instances: updated_instances,
    });
    state.is_paste_loading = false;
  }, 1);
}
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      ref="form$"
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :add-classes="{
        ElementLabel: {
          wrapper: ['text-[#344054] font-medium'],
        },
        TextElement: {
          input: ['text-[#475467]'],
        },
      }"
      @submit="submitHandler"
      @paste="pasteHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('New Instance') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-96 scrollbar">
          <HawkLoader v-if="state.is_paste_loading" />
          <ListElement
            v-show="!state.is_paste_loading"
            name="new_instances"
            :add-text="`+ ${$t('Add new')}`"
            :label="$t('Name')"
            :min="1"
            :presets="['repeatable_list']"
          >
            <template #default="{ index }">
              <TextElement
                :name="index"
                placeholder="Enter instance name"
                :rules="['required']"
                :messages="{
                  required: 'This field is required.',
                }"
                :columns="{
                  default: { container: 11, label: 12, wrapper: 12 },
                  sm: { container: 11, label: 12, wrapper: 12 },
                  md: { container: 11, label: 12, wrapper: 12 },
                }"
              />
            </template>
          </ListElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="state.is_loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
