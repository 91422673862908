<script setup>
import { compact } from 'lodash-es';
import { hexToRgbA } from '~/common/utils/common.utils';

const props = defineProps({
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['sm', 'md', 'lg', 'xl'].includes(value);
    },
  },
  color: {
    type: String,
    default: 'gray',
    validator(value) {
      return ['', 'slate', 'gray', 'zinc', 'neutral', 'stone', 'red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose'].includes(value);
    },
  },
  type: {
    type: String,
    default: 'light',
    validator(value) {
      return ['light', 'dark', 'outlined'].includes(value);
    },
  },
  is_clearable: {
    type: Boolean,
    default: false,
  },
  is_rounded: {
    type: Boolean,
    default: true,
  },
  custom_classes: {
    type: String,
    default: '',
  },
  custom_color: {
    type: String,
    default: '',
  },
  custom_color_background_opacity: {
    type: String,
    default: '0.05',
  },
  has_only_icon: {
    type: Boolean,
    default: false,
  },
  tooltip_text: {
    type: String,
    default: '',
  },
});

const uuid = (crypto.randomUUID()).substring(0, 8);

const inner_content = ref([]);

function getColorClasses() {
  const color_classes = {
    slate: {
      light: ['bg-slate-50', 'text-slate-700'],
      dark: ['bg-slate-500', 'text-white'],
      outlined: ['border-slate-700', 'text-slate-700', 'bg-white'],
    },
    gray: {
      light: ['bg-gray-100', 'text-gray-700'],
      dark: ['bg-gray-500', 'text-white'],
      outlined: ['border-gray-700', 'text-gray-700', 'bg-white'],
    },
    zinc: {
      light: ['bg-zinc-50', 'text-zinc-700'],
      dark: ['bg-zinc-500', 'text-white'],
      outlined: ['border-zinc-700', 'text-zinc-700', 'bg-white'],
    },
    neutral: {
      light: ['bg-neutral-50', 'text-neutral-700'],
      dark: ['bg-neutral-500', 'text-white'],
      outlined: ['border-neutral-700', 'text-neutral-700', 'bg-white'],
    },
    stone: {
      light: ['bg-stone-50', 'text-stone-700'],
      dark: ['bg-stone-500', 'text-white'],
      outlined: ['border-stone-700', 'text-stone-700', 'bg-white'],
    },
    red: {
      light: ['bg-red-50', 'text-red-700'],
      dark: ['bg-red-500', 'text-white'],
      outlined: ['border-red-700', 'text-red-700', 'bg-white'],
    },
    orange: {
      light: ['bg-orange-50', 'text-orange-700'],
      dark: ['bg-orange-500', 'text-white'],
      outlined: ['border-orange-700', 'text-orange-700', 'bg-white'],
    },
    amber: {
      light: ['bg-amber-50', 'text-amber-700'],
      dark: ['bg-amber-500', 'text-white'],
      outlined: ['border-amber-700', 'text-amber-700', 'bg-white'],
    },
    yellow: {
      light: ['bg-yellow-50', 'text-yellow-700'],
      dark: ['bg-yellow-500', 'text-white'],
      outlined: ['border-yellow-700', 'text-yellow-700', 'bg-white'],
    },
    lime: {
      light: ['bg-lime-50', 'text-lime-700'],
      dark: ['bg-lime-500', 'text-white'],
      outlined: ['border-lime-700', 'text-lime-700', 'bg-white'],
    },
    green: {
      light: ['bg-green-50', 'text-green-700'],
      dark: ['bg-green-500', 'text-white'],
      outlined: ['border-green-700', 'text-green-700', 'bg-white'],
    },
    emerald: {
      light: ['bg-emerald-50', 'text-emerald-700'],
      dark: ['bg-emerald-500', 'text-white'],
      outlined: ['border-emerald-700', 'text-emerald-700', 'bg-white'],
    },
    teal: {
      light: ['bg-teal-50', 'text-teal-700'],
      dark: ['bg-teal-500', 'text-white'],
      outlined: ['border-teal-700', 'text-teal-700', 'bg-white'],
    },
    cyan: {
      light: ['bg-cyan-50', 'text-cyan-700'],
      dark: ['bg-cyan-500', 'text-white'],
      outlined: ['border-cyan-700', 'text-cyan-700', 'bg-white'],
    },
    sky: {
      light: ['bg-sky-50', 'text-sky-700'],
      dark: ['bg-sky-500', 'text-white'],
      outlined: ['border-sky-700', 'text-sky-700', 'bg-white'],
    },
    blue: {
      light: ['bg-blue-50', 'text-blue-700'],
      dark: ['bg-blue-500', 'text-white'],
      outlined: ['border-blue-700', 'text-blue-700', 'bg-white'],
    },
    indigo: {
      light: ['bg-indigo-50', 'text-indigo-700'],
      dark: ['bg-indigo-500', 'text-white'],
      outlined: ['border-indigo-700', 'text-indigo-700', 'bg-white'],
    },
    violet: {
      light: ['bg-violet-50', 'text-violet-700'],
      dark: ['bg-violet-500', 'text-white'],
      outlined: ['border-violet-700', 'text-violet-700', 'bg-white'],
    },
    purple: {
      light: ['bg-purple-50', 'text-purple-700'],
      dark: ['bg-purple-500', 'text-white'],
      outlined: ['border-purple-700', 'text-purple-700', 'bg-white'],
    },
    fuchsia: {
      light: ['bg-fuchsia-50', 'text-fuchsia-700'],
      dark: ['bg-fuchsia-500', 'text-white'],
      outlined: ['border-fuchsia-700', 'text-fuchsia-700', 'bg-white'],
    },
    pink: {
      light: ['bg-pink-50', 'text-pink-700'],
      dark: ['bg-pink-500', 'text-white'],
      outlined: ['border-pink-700', 'text-pink-700', 'bg-white'],
    },
    rose: {
      light: ['bg-rose-50', 'text-rose-700'],
      dark: ['bg-rose-500', 'text-white'],
      outlined: ['border-rose-700', 'text-rose-700', 'bg-white'],
    },
  };

  const color = props.color || 'gray';
  const type = props.type || 'light';

  return color_classes[color]?.[type] || [];
}

function getSizeClasses() {
  const size_classes = {
    sm: ['text-xs', '[&>svg]:h-3', '[&>svg]:w-3', 'h-[22px]'],
    md: ['text-sm', '[&>svg]:h-3', '[&>svg]:w-3', 'h-6'],
    lg: ['text-sm', '[&>svg]:h-3', '[&>svg]:w-3', 'h-7'],
    xl: ['text-sm', '[&>svg]:h-4', '[&>svg]:w-4', 'h-32'],
  };
  return size_classes[props.size];
}

const badge_classes = computed(() => {
  const classes = ['inline-flex', 'gap-1', 'items-center', 'font-medium', 'hawk-badge'];

  classes.push(...getSizeClasses());

  classes.push(...getColorClasses());

  (props.custom_classes || props.type !== 'outlined') && classes.push('border-transparent');

  props.type === 'outlined' && classes.push('border-2');

  props.is_rounded ? classes.push('rounded-full') : classes.push('rounded');

  props.custom_classes && classes.push(props.custom_classes);

  if (props.has_only_icon)
    classes.push('p-1');
  else if (inner_content.value && inner_content.value[1]?.tagName === 'svg' && inner_content.value[3]?.tagName === 'svg')
    classes.push('px-1');
  else if (inner_content.value && inner_content.value[1]?.tagName === 'svg')
    classes.push('pl-1 pr-2');
  else if (inner_content.value && inner_content.value[3]?.tagName === 'svg')
    classes.push('pl-2 pr-1');
  else
    classes.push('px-2');

  return compact(classes);
});

onMounted(() => {
  inner_content.value = (document.getElementById(`${uuid}`))?.childNodes;
});
</script>

<template>
  <div
    :id="uuid" v-tippy="{ content: tooltip_text || null }"
    :class="badge_classes"
    :style="[custom_color ? { backgroundColor: hexToRgbA(custom_color, custom_color_background_opacity), color: custom_color } : null]"
  >
    <slot />
    <IconHawkX v-if="is_clearable" class="h-4 w-4 cursor-pointer hover:text-gray-900" @click="$emit('clear')" />
  </div>
</template>
