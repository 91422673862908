<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useRoute } from 'vue-router';
import TaskStatus from '~/tasks/components/atoms/task-status.vue';
import TaskName from '~/tasks/components/molecule/task-name.vue';
import { listViewDateFormatter } from '~/tasks/composables/view-helpers.js';

const props = defineProps({
  tasks: {
    type: Array,
    default: () => ([]),
  },
  wrapper_style: {
    type: String,
    default: '',
  },
  pagination_state: {
    type: Object,
    default: () => {},
  },
  load_more: {
    type: Function,
    default: () => true,
  },
  id: {},
  options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['view-task']);
const route = useRoute();
const hawk_infinite_list = ref(null);
async function getNextTasks() {
  if (props.pagination_state?.totalRows > props.tasks?.length) {
    await props.load_more({
      sort_by: null,
      pagination_state: {
        pageIndex: props.pagination_state.page_number + 1,
        pageSize: props.pagination_state.page_size,
      },
    }, true);
  }
}
defineExpose({
  scrollToTop: () => hawk_infinite_list.value?.scrollToTop(),
});
</script>

<template>
  <HawkInfiniteList
    :id="`infinite-${id}`"
    ref="hawk_infinite_list"
    container_class="!overflow-y-auto overflow-x-hidden"
    :style="wrapper_style"
    :list="tasks"
    :load_more="getNextTasks"
    @page-number="(page_number) => list_page_number = page_number"
  >
    <template #item="{ item, index }">
      <div
        class="flex py-2 px-2 cursor-pointer group hover:bg-gray-50"
        @click="emit('view-task', item)"
      >
        <div class="flex-auto">
          <div class="flex justify-between mb-2">
            <div class="text-sm font-medium">
              <TaskName
                :task="item"
                :asset_uid="route.params.asset_id ? null : item?.target_element?.asset"
                :show_asset="true"
                :show_location="options.show_location || false"
              />
            </div>
            <div class="flex gap-1 flex-shrink-0">
              <div>
                <span
                  v-if="item.due_date"
                  class="text-xs whitespace-nowrap py-0.5 px-2 font-medium"
                  :class="[item.due_date && new Date(item.due_date) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']"
                >
                  {{ listViewDateFormatter({ date: item.due_date }) }}
                </span>
              </div>
              <div>
                <TaskStatus :status="item.status" />
              </div>
            </div>
          </div>
          <div class="flex justify-between mb-1">
            <div class="flex items-center gap-1">
              <HawkBadge v-if="item.category">
                <HawkCategoryName :key="item.category" :uid="item.category" class="text-xs" />
              </HawkBadge>
              <TaskDependency :uid="item?.uid" />
              <HawkBadge
                v-if="item?.sub_task_uids?.length"
                v-tippy="`${item?.sub_task_uids?.length} Subtasks`"
                color="blue"
              >
                <IconHawkDependencyLinked class="text-primary h-3" />
                {{ item?.sub_task_uids?.length }}
              </HawkBadge>
            </div>
            <div v-if="item.assignees?.length" class="flex gap-1 text-xs items-center">
              <HawkMembers :members="item.assignees" type="group" size="xs" popover_position="right" />
            </div>
          </div>
        </div>
        <div class="flex items-center invisible group-hover:visible ml-3">
          <slot name="context_menu" :task="item" />
        </div>
      </div>
      <hr v-if="index !== tasks.length - 1" class="my-2">
    </template>
  </HawkInfiniteList>
</template>
