<script setup>
const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
    // options prop structure:
    // reminder: Object,
    // member_options: Array,
    // status_values: Array,
    // date_options: Array,
    // load_from_trigger_property: String,
    // create_reminder: Function,
    // update_reminder: Function,
    // append_to_body: Boolean,
    // modal_options: Object,
  },
});
const emit = defineEmits(['close', 'refresh']);

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');

const REMINDER_ACTIONS = [
  {
    label: $t('Notify'),
    value: 'notify',
  },
  {
    label: $t('Assign and notify'),
    value: 'assign_and_notify',
  },
];

const BEFORE_AFTER_DROPDOWN_OPTIONS = [
  { value: 'after', label: $t('After') },
  { value: 'before', label: $t('Before') },
];

const form$ = ref(null);
const form_data = ref(null);

const is_loading = ref(false);

const date_dropdown_options = computed(() => {
  if (props.options.date_options.length)
    return props.options.date_options;

  return [{ value: 'dueDate', label: $t('Due Date') }];
});

function onFormMounted(el$) {
  let data_to_load = {};
  if (props.options?.reminder?.uid && props.options?.reminder?.action)
    data_to_load = {
      action: props.options?.reminder.action,
      to: {
        custom: props.options?.reminder.action === 'assign_and_notify'
          ? props.options?.reminder.to
          : props.options?.reminder.to.custom,
        member_types: props.options?.reminder.action === 'notify'
          ? [
              ...Object.keys(props.options?.reminder.to).filter(key => props.options?.reminder.to[key] === true),
              ...(props.options?.reminder.to.custom.length ? ['custom'] : []),
            ]
          : [],
      },
      ...props.options?.reminder.config,
      status: props.options?.reminder.status,
    };
  else
    data_to_load = {
      action: 'notify',
      to: {
        member_types: ['assignees'],
        custom: [],
      },
      when: 'before',
      days: 1,
      count: 1,
      from: props.options.load_from_trigger_property,
      status: [1, 2],
    };

  // Loading action first otherwise things break
  form_data.value.action = data_to_load.action;
  el$.load(data_to_load, true);
};

function resetMemberData(_newVal, oldVal) {
  if (oldVal)
    form_data.value.to = {
      custom: [],
      member_types: [],
    };
}

async function handleSave() {
  try {
    is_loading.value = true;

    const selected_member_types = form_data.value.to.member_types;
    const reminder = {
      action: form_data.value.action,
      config: {
        when: form_data.value.when,
        days: Number(form_data.value.days),
        count: Number(form_data.value.count),
        from: form_data.value.from,
      },
      to: form_data.value.action === 'notify'
        ? {
            assignees: selected_member_types.includes('assignees'),
            watchers: selected_member_types.includes('watchers'),
            creator: selected_member_types.includes('creator'),
            custom: selected_member_types.includes('custom') ? form_data.value.to.custom?.map(obj => obj.uid) : [],
          }
        : form_data.value.to.custom?.map(obj => obj.uid),
      status: form_data.value.status,
      asset: props.options.asset || null,
    };

    if (props.options?.reminder?.uid)
      await props.options.update_reminder({
        reminder: { ...reminder, uid: props.options?.reminder.uid },
      });
    else
      await props.options.create_reminder({ reminder });

    $toast({
      title: $t(`${$t('Reminder')} ${props.options?.reminder?.uid ? $t('updated') : $t('added')} ${$t('successfully')}`),
      type: 'success',
    });
    emit('refresh');
    emit('close');
  }
  catch (error) {
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'error',
      position: 'bottom-right',
    });
  }

  is_loading.value = false;
}
</script>

<template>
  <HawkModalContainer :options="props.options.modal_options" content_class="!w-[700px]">
    <Vueform
      ref="form$"
      v-model="form_data"
      :float-placeholders="false"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, label: 3, wrapper: 12 },
      }"
      :add-classes="{ ElementLabel: { wrapper: 'text-gray-700 font-medium' } }"
      size="sm"
      sync
      :format-load="data => data"
      :endpoint="handleSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #icon>
            <IconHawkReminder class="text-gray-700" />
          </template>
          <template #title>
            {{ $t("Add Reminder") }}
          </template>
          <template #subtitle>
            {{ $t("send-email-push-notifications-assign-notify") }}
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <RadiogroupElement
            class="mb-4"
            name="action"
            rules="required"
            :label="$t('Reminder')"
            :items="REMINDER_ACTIONS"
            @change="resetMemberData"
          />
          <ObjectElement
            class="mb-4"
            name="to"
            rules="required"
            :label="$t('Members')"
          >
            <CheckboxgroupElement
              name="member_types"
              rules="required"
              :conditions="[['action', 'notify']]"
              :items="props.options.member_options"
            />
            <HawkAssigneeInput
              :options="{
                'name': 'custom',
                'conditions': [[
                  ['to.member_types', ['custom']],
                  ['action', 'assign_and_notify'],
                ]],
                'has_teams': true,
                'placeholder': $t('Select members'),
                'rules': 'required',
                'messages': {
                  required: $t('The Members field is required'),
                },
                appendToBody: false,
              }"
              :asset_id="props.asset_id"
              :multi="true"
              :format_load="true"
            />
          </ObjectElement>
          <div class="grid grid-cols-12 col-span-12 mb-4">
            <div class="col-span-3 text-sm text-gray-700 font-medium">
              {{ $t('Trigger') }}
            </div>
            <div>
              <div class="grid grid-rows-1 grid-flow-col gap-4">
                <TextElement
                  :rules="['required', 'numeric', 'min:1', 'integer']"
                  default="1"
                  name="days"
                  size="sm"
                  type="number"
                  :attrs="{ min: '1' }"
                  :addons="{ after: `<span class='text-sm'>${$t('days')}</span>` }"
                  input-type="number"
                  :columns="{
                    default: { container: 7 },
                    sm: { container: 7 },
                    md: { container: 7 },
                  }"
                />
                <SelectElement
                  default="before"
                  :placeholder="$t('Select')"
                  :rules="['required']"
                  value="when"
                  size="sm"
                  :columns="{
                    default: { container: 7 },
                    sm: { container: 7 },
                    md: { container: 7 },
                  }"
                  :can-clear="false"
                  name="when"
                  :native="false"
                  :items="BEFORE_AFTER_DROPDOWN_OPTIONS"
                  :override-class="{
                    select: {
                      optionPointed: [''],
                    },
                  }"
                  :extend-options="{
                    appendToBody: props.options?.append_to_body ?? true,
                  }"
                />
                <SelectElement
                  :rules="['required']"
                  :placeholder="$t('Due Date')"
                  size="sm"
                  value="from"
                  :default="date_dropdown_options[0].value"
                  :columns="{
                    default: { container: 8 },
                    sm: { container: 8 },
                    md: { container: 8 },
                  }"
                  :can-deselect="false"
                  :can-clear="false"
                  :disabled="date_dropdown_options.length === 1"
                  :close-on-select="true"
                  name="from"
                  :native="false"
                  :items="date_dropdown_options"
                  :add-class="{
                    select: {
                      optionDisabled: ['!bg-white !cursor-not-allowed'],
                    },
                  }"
                  :override-class="{
                    select: {
                      optionPointed: [''],
                    },
                  }"
                  :extend-options="{
                    appendToBody: props.options?.append_to_body ?? true,
                  }"
                />
                <TextElement
                  min="1"
                  default="1"
                  size="sm"
                  :rules="['required', 'numeric', 'min:1', 'integer']"
                  :addons="{ after: `<span class='text-sm'> ${$t('times')}</span>` }"
                  name="count"
                  type="number"
                  :attrs="{ min: '1' }"
                  input-type="number"
                  :columns="{
                    default: { container: 9 },
                    sm: { container: 9 },
                    md: { container: 9 },
                  }"
                />
              </div>
            </div>
          </div>

          <TagsElement
            name="status"
            rules="required"
            :info="$t('Notifications will be sent while the task is in the selected statuses')"
            :label="$t('Status')"
            :placeholder="$t('Select Status')"
            :items="props.options.status_values"
            :close-on-select="false"
            :extend-options="{
              appendToBody: props.options?.append_to_body ?? true,
            }"
          />
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <HawkButton
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
