<script setup>
import { storeToRefs } from 'pinia';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const emit = defineEmits(['close']);

const dashboard_store = useDashboardStore();

const { widget_configuration } = storeToRefs(dashboard_store);

const { set_widget_configuration } = dashboard_store;

const chart_labels = widget_configuration.value?.chart_config;

const form_data = ref({});

const chart_type = computed(() => widget_configuration.value?.type);
const has_axis_labels = computed(() => {
  return !['donut', 'status_breakdown', 'severity_graph'].includes(chart_type.value) && widget_configuration.value?.chart_type !== 'donut';
});

function saveChartLabels() {
  const data = { ...widget_configuration.value };
  data.chart_config = form_data.value;

  set_widget_configuration(data);

  emit('close');
}
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div class="text-lg font-semibold">
          {{ $t('Configuration') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="w-[700px]">
      <Vueform
        v-model="form_data"
        size="sm"
        :columns="{
          default: {
            container: 12,
            label: 4,
            wrapper: 12,
          },
          sm: {
            label: 4,
          },
          md: {
            label: 4,
          },
          lg: {
            label: 4,
          },
        }"
        :should_validate_on_mount="false"
        :display-errors="false"
      >
        <TextElement
          name="title"
          label="Title"
          placeholder="Enter text here"
          class="mb-4"
          :default="chart_labels?.title || null"
        />
        <TextareaElement
          name="description"
          label="Description"
          placeholder="Enter text here"
          class="mb-4"
          :default="chart_labels?.description || null"
        />
        <TextElement
          v-if="has_axis_labels"
          name="x_label"
          label="Field label"
          placeholder="Enter text here"
          class="mb-4"
          :default="chart_labels?.x_label || null"
        />
        <TextElement
          v-if="has_axis_labels"
          name="y_label"
          label="Value label"
          placeholder="Enter text here"
          :default="chart_labels?.y_label || null"
        />
        <SelectElement
          class="mb-4"
          name="legend_position"
          label="Legend"
          :default="chart_labels?.legend_position || 'bottom'"
          :native="false" :items="{
            none: 'None',
            top: 'Top',
            bottom: 'Bottom',
            left: 'Left',
            right: 'Right',
          }"
        />
        <RadiogroupElement
          v-if="widget_configuration.type !== 'donut' || chart_type !== 'activity_history_table'"
          label="Chart display mode"
          name="chart_display_mode"
          :items="[
            'Stacked',
            'Unstacked',
          ]"
          :default="chart_labels?.chart_display_mode || 'Stacked'"
        />
        <CheckboxElement
          label="Display values" name="display_values" info="Render values on the chart for each component" :default="chart_labels?.display_values || false"
        />
        <CheckboxElement
          label="Prettify values" name="prettify_values" :default="chart_labels?.prettify_values === false ? false : true"
        />
      </Vueform>
    </div>

    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="saveChartLabels"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
