<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { computed } from 'vue';
import AssetResourcesInput from '~/acct-settings/components/acct-settings-asset-resources/acct-settings-asset-resources-access-input.vue';
import AssetsAccessList from '~/acct-settings/components/acct-settings-asset-resources/acct-settings-assets-access-list.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  admin_assets: {
    type: Array,
    default: () => ([]),
  },
  asset_permissions: {
    type: Array,
    default: () => ([]),
  },
  access_all_assets: {
    type: Boolean,
    default: false,
  },
});
const { route } = useCommonImports();

const org_assets_access$ = ref(null);
const asset_form = ref(null);
const assets_list = ref(props.asset_permissions || []);
const is_valid = ref(true);

const get_asset_index = computed(() => assets_list.value.findIndex(resource => resource.asset === route.params?.asset_id));

function getValue() {
  if (route.params?.asset_id)
    return { resources: assets_list.value, is_valid: is_valid.value };

  else
    return org_assets_access$.value?.getAssetsData() || {};
}

function updateAssetForm(e) {
  asset_form.value = e;
  const asset_index = get_asset_index.value;
  const updated_asset = {
    asset: route.params.asset_id,
    resource_access: asset_form.value?.resource_access,
    resources: asset_form.value.resources,
  };
  if (asset_form.value.resource_access === 'no') {
    if (asset_index !== -1)
      assets_list.value.splice(asset_index, 1);
  }
  else if (asset_index === -1) {
    assets_list.value.push(updated_asset);
  }
  else {
    assets_list.value.splice(asset_index, 1, updated_asset);
  }
  is_valid.value = (updated_asset?.resource_access !== 'limited' || updated_asset?.resources?.length > 0);
}

function init() {
  if (route.params?.asset_id) {
    if (get_asset_index.value !== -1)
      asset_form.value = props.asset_permissions?.[get_asset_index.value];
  }
}
init();
defineExpose({ getValue });
</script>

<template>
  <div>
    <div v-if="!route.params?.asset_id" class="sticky top-16 bg-white z-10 py-4">
      <div class="text-base font-semibold text-gray-900">
        {{ $t('Assets') }}
      </div>
      <div class="text-sm text-gray-600 font-normal">
        {{ $t('Choose assets to grant access to the team members.') }}
      </div>
    </div>
    <AssetResourcesInput
      v-if="route.params?.asset_id"
      :asset_uid="route.params?.asset_id"
      :form_data="asset_form"
      @update="updateAssetForm($event)"
    />
    <AssetsAccessList
      v-else
      ref="org_assets_access$"
      :admin_assets="admin_assets"
      :asset_permissions="asset_permissions"
      :access_all_assets="access_all_assets"
    />
  </div>
</template>
