<script setup>
import { useModal } from 'vue-final-modal';
import TransmittalWorkflowBlockEditPopup from '~/dms/components/transmittals/workflow/graph/transmittal-workflow-block-edit-popup.vue';

const props = defineProps({
  step: {
    type: Object,
    default: () => ({}),
  },
  visible_options: {
    type: Object,
  },
  published: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  is_final: {
    type: Boolean,
  },
});
const emit = defineEmits(['delete']);
const step = computed(() => props.step);
const sub_steps = computed(() => props.step.sub_steps);

const existing_members_in_workflow = inject('existing_members_in_workflow');

function addSubSteps() {
  const { open: openEditModal, close: closeEditModal } = useModal({
    component: TransmittalWorkflowBlockEditPopup,
    attrs: {
      is_sub_step: true,
      existing_members: existing_members_in_workflow,
      onClose() {
        closeEditModal();
      },
      async submit(data, form) {
        sub_steps.value.push({ name: form.data.name, members: form.data.members.map(user => user.uid || user), sub_index: sub_steps.value.length, condition: form.data.condition, min_approvals: +form.data.min_approvals, on_reject: form.data.on_reject });
        closeEditModal();
      },
    },
  });
  openEditModal();
}

const removeSubSteps = index => sub_steps.value.length === 1 ? emit('delete') : sub_steps.value.splice(index, 1);
</script>

<template>
  <div class="flex group">
    <div class="relative">
      <div class="bg-white w-[345px] min-h-[150px] border border border-gray-200 rounded-xl p-2">
        <div class="flex justify-between">
          <div class="w-[142px] flex items-center">
            <select v-model="step.condition" :disabled="published || disabled" class=" bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option
                v-for="option in [{ label: 'ALL', value: 'AND' },
                                  { label: 'ANY', value: 'OR' }]"
                :key="option.value" :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
            <IconHawkInfoCircle v-tippy="$t('No. members to respond')" class="ml-2 w-8 h-8 pointer-events-auto" />
          </div>
          <div class="flex">
            <div :class="!published ? 'rounded-lg border border-gray-300 p-2 shadow-xs mr-2 cursor-pointer' : 'p-2 mr-2 pointer-events-none'" @click="step.on_reject = step.on_reject === 'continue' ? 'revert' : 'continue'">
              <IconHawkArrowRight v-if="step.on_reject === 'continue'" v-tippy="$t('Move to next step')" class="pointer-events-auto" />
              <IconHawkFlipBackward v-else v-tippy="$t('Rollback to initiator on rejection / resubmission')" class="text-error-600 pointer-events-auto" />
            </div>
            <div class="relative w-[102px]">
              <input v-model.number="step.duration" :disabled="disabled" type="number" class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pr-12" required="">
              <div class="absolute right-3 top-2">
                days
              </div>
            </div>
          </div>
        </div>
        <transmittal-workflow-graph-step-block
          v-for="(sub_step, index) in step.sub_steps"
          :key="sub_step"
          :sub_step="sub_step"
          :is_final="is_final"
          :published="published"
          :disabled="disabled"
          :visible_options="visible_options" @delete="removeSubSteps(index)"
        />
      </div>
      <div v-if="!published" class=" !absolute right-14 -bottom-2  cursor-pointer invisible group-hover:visible  bg-gray-50" @click="addSubSteps">
        <IconHawkPlusCircle class="w-[24px] h-[24px]" />
      </div>
    </div>
  </div>
</template>

<style scoped>
select {
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 0.75em 0.75em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3E%3C/svg%3E");
}
</style>
