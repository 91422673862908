<script setup>
import { markRaw } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useFormsStore } from '~/forms/store/forms.store.js';
import { useDocumentStore } from '~/dms/store/document.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import TasksView from '~/tasks/pages/tasks/tasks-view.vue';
import PmTaskForm from '~/tasks/components/molecule/task-form/pm-task-form.vue';
import PmNewForm from '~/forms/components/new-form/pm-new-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DocumentPmActivityDetailsWidget from '~/dms/components/documents/widgets/document-pm-activity-details-widget.vue';

import FormCompactView from '~/forms/components/form-compact-view.vue';

const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');

const route = useRoute();
const project_management_store = useProjectManagementStore();
const tasks_store = useTasksStore();
const forms_store = useFormsStore();
const document_store = useDocumentStore();
const common_store = useCommonStore();
const authStore = useAuthStore();

const { active_task } = storeToRefs(project_management_store);
const { update_activity, update_activity_reference } = project_management_store;

const tab_items = computed(() => {
  const document_widget = DocumentPmActivityDetailsWidget;
  return [
    {
      name: 'FILE',
      label: $t('Files'),
      singular_label: $t('File'),
      list_component: markRaw(document_widget),
      service_name: 'files',
      delete_modal_header: $t('Delete File'),
    },
    {
      name: 'TASK',
      label: $t('Tasks'),
      singular_label: $t('Task'),
      list_component: markRaw(TasksView),
      create_component: markRaw(PmTaskForm),
      service_name: 'tasks',
      delete_modal_header: $t('Delete Task'),
    },
    {
      name: 'FORM',
      label: $t('Forms'),
      singular_label: $t('Form'),
      list_component: markRaw(FormCompactView),
      create_component: markRaw(PmNewForm),
      service_name: 'forms',
      delete_modal_header: $t('Delete Form'),
    },
  ];
});

const state = reactive({
  current_tab: tab_items.value[0],
  form_search: '',
});

const options = {
  is_pm: true,
  query: {
    root: false,
    archived: false,
    asset_uid: route.params.asset_id,
    page_number: 1,
    page_size: 100000000,
    resource_id: active_task.value.uid,
    resource_type: 'PM_ACTIVITY',
  },
  disable_options: {
    is_title: true,
    is_sidebar: true,
    is_breadcrumbs: true,
    is_search_bar: true,
    is_files_filter: true,
    is_internal_button: true,
  },
};

const attachReference = async payload => await onReferencesUpdated(payload, 'add');
const detachReference = async payload => await onReferencesUpdated(payload, 'remove');

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeleteOptions } = useModal({
  component: HawkDeletePopup,
});

const activity_references = computed(() => {
  return active_task.value.references;
});

const additional_queries = computed(() => {
  if (state.current_tab.name === 'FORM')
    return {
      all_access: true,
      is_child: true,
      public: false,
    };
  else if (state.current_tab.name === 'TASK')
    return {
      archived: false,
      asset_uid: route.params.asset_id,
    };

  return {};
});

const restricted_items = computed(() => {
  return activity_references.value
    .filter(item => item.resource_type === state.current_tab.name)
    .map(item => item.resource_id);
});

const additional_props = computed(() => {
  if (state.current_tab.name === 'FILE') {
    return {
      attach_reference: attachReference,
      restricted_items: restricted_items.value,
    };
  }
  else if (state.current_tab.name === 'TASK') {
    return {
      is_compact_view: true,
      class: '!px-4',
    };
  }
  else {
    if (state.form_search)
      options.query.q = state.form_search;

    return {
      class: '!px-4',
      asset_id: route.params.asset_id,
      options: {
        show_no_data: true,
        query: options.query,
      },
    };
  }
});

async function triggerRefetchActivity() {
  await update_activity({ uid: active_task.value.uid }, true);
  gantt.render();
}

async function onReferencesUpdated(references, type_of_update) {
  try {
    logger.log('SM:: ', references, type_of_update);
    const references_array = Array.isArray(references) ? references : [references];

    await update_activity_reference({
      activity: active_task.value,
      body: {
        [type_of_update]: references_array.map(uid => ({
          resource_id: uid,
          resource_type: state.current_tab.name,
        })),
      },
    });
    if (state.current_tab.service_name === 'forms')
      await $services[state.current_tab.service_name].post({
        body: {
          [state.current_tab.service_name]: {
            update: references_array.map(
              uid => ({
                references: {
                  [type_of_update]: [
                    {
                      resource_id: active_task.value.uid,
                      resource_type: 'PM_ACTIVITY',
                    },
                  ],
                },
                uid,
              }),
            ),
          },
        },
      });
    else
      await $services[state.current_tab.service_name].patch({
        body: {
          [state.current_tab.service_name]: references_array.map(
            uid => ({
              references: {
                [type_of_update]: [
                  {
                    resource_id: active_task.value.uid,
                    resource_type: 'PM_ACTIVITY',
                  },
                ],
              },
              uid,
            }),
          ),
        },
      });
  }
  catch (error) {
    logger.error(error.message);
  }
  finally {
    await triggerRefetchActivity();
  }
}

async function detachHandler(element) {
  await detachReference(element?.data?.row?.original.uid || element?.uid);
}

function deleteHandler(menu_element) {
  logger.log('SM: menu_element', menu_element);
  const element = menu_element?.data?.row?.original || menu_element || {};
  patchDeleteOptions(
    {
      attrs: {
        header: state.current_tab.delete_modal_header,
        content: `Are you sure you want to delete ${element.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            if (state.current_tab.name === 'FILE' && element.uid)
              await document_store.crud_documents({
                request: {
                  body: {
                    files: {
                      remove: [element.uid],
                    },
                  },
                },
              });

            if (state.current_tab.name === 'TASK')
              await tasks_store.remove_tasks([element.uid]);
            else if (state.current_tab.name === 'FORM')
              await forms_store.remove_forms([element.uid]);
            await triggerRefetchActivity();
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

async function searchTasks(value) {
  tasks_store.set_search_key(value);
  await tasks_store.set_tasks(options.query);
}

async function onTabSelected(item) {
  state.current_tab = item.item;
  if (item.name === 'TASK')
    await tasks_store.set_tasks(options.query);
}
</script>

<template>
  <div class="flex">
    <HawkTree
      :selected_item="state.current_tab"
      :full_row_click="true"
      :items="tab_items"
      class="min-w-60 w-60"
      key_field="name"
      value_key="label"
      selected_item_key="name"
      item_selected_class="bg-gray-700 text-white active"
      item_class="text-sm hover:bg-gray-700 hover:text-white text-medium folder-tree rounded-lg"
      @selectItem="onTabSelected"
    >
      <template #item="item">
        <div class="flex mr-auto">
          <IconHawkFolder class="w-6 h-6 mr-3" />
          <span class="flex items-center text-sm font-medium">{{ item.data.label }}</span>
        </div>
        <IconHawkChevronRight />
      </template>
    </HawkTree>
    <div class="w-[calc(100%-240px)] px-4">
      <HawkPageSecondaryHeader v-if="state.current_tab.name === 'FORM'" class="mb-8 px-4">
        <template #left>
          <HawkSearchInput
            v-model="state.form_search"
            :debounce_time="500"
            :placeholder="$t('Search')"
          />
        </template>
        <template #right>
          <template v-if="state.current_tab.create_component">
            <component
              :is="state.current_tab.create_component"
              :additional_queries="additional_queries"
              :attach_reference="attachReference"
              :restricted_items="restricted_items"
            />
          </template>
        </template>
      </HawkPageSecondaryHeader>
      <component
        :is="state.current_tab.list_component"
        :key="activity_references.length && state.form_search"
        v-bind="{
          ...additional_props,
          refetch_activity: triggerRefetchActivity,
          ...(!['FILE', 'FORM'].includes(state.current_tab.name) ? { options } : {}),
        }"
        :active_task="active_task"
        :is_widget="true"
      >
        <template v-if="['FILE', 'TASK', 'FORM'].includes(state.current_tab.name)" #context_menu="{ task, element, item }">
          <HawkMenu
            position="fixed"
            @click.stop
          >
            <template #trigger>
              <IconHawkDotsVertical class="h-4 w-4" />
            </template>
            <template #content="{ close }">
              <div
                class="relative flex items-center m-1 px-3 h py-3 text-sm font-medium whitespace-nowrap cursor-pointer rounded-lg text-gray-700 hover:bg-gray-50"
                @click="deleteHandler(task?.task || element || item); close();"
              >
                {{ $t('Delete') }} {{ state.current_tab.singular_label }}
              </div>
              <div
                class="relative flex items-center m-1 px-3 h py-3 text-sm font-medium whitespace-nowrap cursor-pointer rounded-lg text-gray-700 hover:bg-gray-50"
                @click="detachHandler(task?.task || element || item); close();"
              >
                {{ $t('Detach') }} {{ state.current_tab.singular_label }}
              </div>
            </template>
          </HawkMenu>
        </template>
        <template #top-panel>
          <HawkPageSecondaryHeader :class="{ 'mb-8': state.current_tab.name === 'TASK' }">
            <template #left>
              <HawkSearchInput
                :model-value="tasks_store.search_key"
                :debounce_time="500"
                :placeholder="$t('Search')"
                @update:modelValue="searchTasks"
              />
            </template>
            <template #right>
              <template v-if="state.current_tab.create_component && state.current_tab.name === 'TASK'">
                <component
                  :is="state.current_tab.create_component"
                  :additional_queries="additional_queries"
                  :attach_reference="attachReference"
                  :restricted_items="restricted_items"
                />
              </template>
            </template>
          </HawkPageSecondaryHeader>
        </template>
      </component>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.infinite-list__item {
  @apply flex;
}
</style>
