<script setup>
import { isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import DashboardDMSFilter from '~/dashboard/components/filters/dashboard-dms-filters.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';

import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const dashboard_dms_store = useDashboardDMSStore();
const dms_settings_store = useDMSSettingsStore();
const dashboard_store = useDashboardStore();

const {
  dms_schema,
  dms_configuration,
} = storeToRefs(dashboard_dms_store);

const {
  set_data,
  set_transmittals,
  update_dms_configuration,
} = dashboard_dms_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

const form$ = ref(null);
async function validateForm() {
  // sometimes changes take longer
  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();

  const is_valid = !form$.value.hasErrors;
  set_form_valid(is_valid);
  if (is_valid) {
    set_widget_configuration({
      ...dms_configuration.value,
    });
  }
}

function updateDMSConfiguration(data, key = null) {
  if (!key) {
    update_dms_configuration({
      ...dms_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...dms_configuration.value,
    };

    copy[key] = data;
    update_dms_configuration(copy);
  }

  validateForm();
}

function set_custom_fields() {
  if (dms_configuration.value.data_source === 'transmittals') {
    dms_settings_store.set_custom_fields({
      query: {
        allowed_in: 'transmittals',
      },
    });
  }
  else {
    dms_settings_store.set_custom_fields({
      query: {
        allowed_in: 'documents',
      },
    });
  }
}
watch(widget_asset, (new_val, old_val) => {
  if (!isEqual(new_val, old_val)) {
    updateDMSConfiguration(widget_asset.value, 'asset_id');
    updateDMSConfiguration([], 'folders');
  };
});

watch(dms_configuration, (new_val, old_val) => {
  if (!isEqual(new_val?.data_source, old_val?.data_source)) {
    set_custom_fields();
    if (form$.value.el$('type'))
      form$.value.el$('type').update(dashboard_dms_store.type_options[0].value);
  }

  if (new_val.type === 'transmittal_detail_view' && old_val.type !== 'transmittal_detail_view')
    form$.value.el$('transmittal')?.update(Object.keys(dashboard_dms_store.transmittals_map || [])[0]);

  if (new_val.type === 'donut') {
    form$.value.el$('files_breakdown_by')?.update(dashboard_dms_store.files_breakdown_options?.[0]);
    form$.value.el$('transmittals_breakdown_by')?.update(dashboard_dms_store.transmittals_breakdown_options?.[0]);
  }

  ;
}, { deep: true });

onMounted(() => {
  set_data();
  set_transmittals();
  validateForm();
  set_custom_fields();
});

onBeforeUnmount(() => {
  dashboard_dms_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <div>
    <Vueform
      ref="form$"
      size="sm"
      :schema="dms_schema"
      :presets="['supress_errors']"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      :should_validate_on_mount="false"
      :display-errors="false"
      class="mb-6"
      @change="updateDMSConfiguration($event)"
    />
    <DashboardDMSFilter
      v-if="dms_configuration.type !== 'transmittal_detail_view'"
      @update="updateDMSConfiguration($event, 'filters')"
    />
  </div>
</template>
