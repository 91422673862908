<script>
export default {
  props: {
    radius: Number,
    progress: Number,
    stroke: Number,
  },
  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference,
    };
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - this.progress / 100 * this.circumference;
    },
  },
};
</script>

<template>
  <svg
    :height="radius * 2"
    :width="radius * 2"
  >
    <circle
      stroke="#F2F4F7"
      fill="transparent"
      :stroke-dasharray="`${circumference} ${circumference}`"
      :stroke-width="stroke"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
    <circle
      stroke="#1570EF"
      fill="transparent"
      :stroke-dasharray="`${circumference} ${circumference}`"
      :style="{ strokeDashoffset, 'transform': 'rotate(-90deg)', 'transform-origin': 'center' }"
      :stroke-width="stroke"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>
