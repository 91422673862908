<script setup>
import { useRouter } from 'vue-router';
import TaskName from '~/tasks/components/molecule/task-name.vue';

const props = defineProps({
  task: {
    type: Object,
    required: true,
  },
});
const router = useRouter();

const due_date_class = computed(() => props.task.due_date && new Date(props.task.due_date) <= new Date() ? 'text-error-700' : 'text-gray-700');

function viewTaskDetails() {
  router.push({
    query: {
      task: btoa(JSON.stringify({
        id: props.task.uid,
        store_key: 'therm_tasks_store',
      })),
    },
  });
}
</script>

<template>
  <div v-if="task" class="my-2">
    <div class="group/task-name border rounded-lg p-3 hover:border-primary-700 group cursor-pointer" @click="viewTaskDetails">
      <TaskName :task="task" class="mb-0.5" />
      <TaskStatus :status="task.status" class="-ml-1 pb-2" />
      <div class="flex justify-between pt-2 text-sm">
        <HawkCategoryName :uid="task.category" />
        <div class="text-sm whitespace-nowrap" :class="due_date_class">
          {{ task.due_date ? $date(task.due_date, 'DD MMMM YYYY') : '-' }}
        </div>
      </div>
      <div class="flex justify-between pt-1">
        <TaskPriority :priority="task.priority" class="priority text-xs" />
        <HawkMembers v-if="task.assignees?.length" class="font-medium" :members="task.assignees" type="badge" />
        <div v-else>
          {{ '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.priority svg {
  height: 12px;
  width: 12px;
}
</style>
