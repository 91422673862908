<script setup>
import { storeToRefs } from 'pinia';
import { flatten, sortBy, uniqBy } from 'lodash-es';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

const emit = defineEmits(['close', 'save']);

const dashboard_terra_store = useDashboardTerraStore();

const {
  workflow_options,
  terra_configuration,
} = storeToRefs(dashboard_terra_store);

const workflow_item_map = ref({});
const form_data = ref({
  data: [],
});

const workflows_tree = computed(() => {
  if (!workflow_options.value?.length)
    return [];

  workflow_item_map.value = {};

  const options = workflow_options.value.map((wf) => {
    const wf_fields = flatten(Object.values(wf.data || {}).map(item => item.fields));

    workflow_item_map.value[wf.uid] = wf.name;

    const child_fields = uniqBy(
      wf_fields,
      f => f.uid,
    );

    child_fields.forEach((f) => {
      workflow_item_map.value[f.uid] = f;
    });

    return {
      name: wf.name,
      uid: wf.uid,
      fields: sortBy(child_fields, ['name']),
    };
  });

  return sortBy(options, ['name']);
});

function emitUpdate() {
  const res = form_data.value.data.map(item => ({
    column: workflow_item_map.value[item].uid,
    name: workflow_item_map.value[item].name,
    type: workflow_item_map.value[item].type,
    units: workflow_item_map.value[item].units,
  }));

  emit('save', res);
}

const initial_state = computed(() => terra_configuration?.value.workprogress_fields?.map(w => w.column) || []);

onMounted(() => {
  form_data.value.data = initial_state.value;
});
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div class="text-lg font-semibold">
          {{ $t('Select fields') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="flex flex-col justify-between w-[900px]">
      <Vueform
        v-if="workflows_tree.length"
        v-model="form_data"
        size="sm"
        class="w-full [&>*]:grid-cols-1 border border-gray-300 border-solid rounded-lg"
      >
        <HawkTreeSelect
          show_tree_select
          :options="{
            name: 'data',
            label: $t('Fields'),
          }"
          :data="workflows_tree"
          :initial_state="initial_state"
          children_key="fields"
          label_key="name"
          value_key="uid"
          select_type="LEAF_PRIORITY"
          add_classes="!h-auto min-h-[240px] max-h-[420px] max-[1366px]:max-h-[320px]"
          @updateForm="form_data.data = $event"
        />
      </Vueform>
      <div v-else class="w-full h-auto min-h-[240px] max-h-[420px] border border-gray-300 border-solid rounded-lg" />
    </div>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="emitUpdate"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
