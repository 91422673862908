<script setup>
import { keyBy } from 'lodash-es';
import { load_js_css_file } from '~/common/utils/load-script.util';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store';

const props = defineProps({
  invalid_stocks_data: {
    type: Array,
    default: () => [],
  },
  valid_stocks_data: {
    type: Array,
    default: () => [],
  },

  sn_table_ref: {
    type: Object,
    default: () => {},
  },
  workflow: {
    type: Object,
    default: null,
  },
  item: {
    type: Object,
    default: null,
  },
  stock_input: {
    type: Object,
    default: () => {},
  },
  is_batch_number: {
    type: Boolean,
    default: false,
  },
  view: {
    type: String,
    default: 'transaction-form',
  },
  valid_stock_table_id: {
    type: String,
    default: 'valid-stock-table',
  },
  invalid_stock_table_id: {
    type: String,
    default: 'invalid-stock-table',
  },
});
const invalid_codes_hash = {
  INVALID_STOCK40: 'Invalid stock',
  INVALID_STOCK44: 'Not found',
  INVALID_STOCK43: 'Stock in different location',
  INVALID_STOCK42: 'Invalid stock status',
};
const state = reactive({

  invalid_stock_filter: null,
  active_stock_filter: null,

  invalid_columns: [
    {
      data: 'batch_number',
      text: 'Pallet number',
    },
    {
      data: 'serial_no',
      text: 'Serial number',
    },
  ],
  valid_columns: [
    {
      data: 'batch_number',
      text: 'Pallet number',
    },
    {
      data: 'serial_no',
      text: 'Serial number',
    },
  ],
  invalid_table_data: [],
  valid_table_data: [],
  invalid_search: '',
  valid_search: '',
});

const invalid_stocks_data_table = ref(null);
const valid_stock_data_table = ref(null);
const { $t } = useCommonImports();
const inventory_store = useInventoryStore();
const is_not_on_system = computed(() => {
  return !props.workflow?.from_status?.length;
});
const uom_symbol = computed(() => {
  return inventory_store.uom_map[props.item?.uom]?.symbol || '';
});
function countFrequencyOfFrequencies(arr) {
  const elementCount = {};
  const frequencyCount = {};

  // Count the frequency of each element
  arr.forEach((element) => {
    if (elementCount[element])
      elementCount[element]++;
    else
      elementCount[element] = 1;
  });

  // Count the frequency of these frequencies and store the key
  Object.entries(elementCount).forEach(([key, frequency]) => {
    if (frequencyCount[frequency])
      frequencyCount[frequency].count++;
    else
      frequencyCount[frequency] = { count: 1, key };
  });

  return frequencyCount;
}
const valid_stocks_types = computed(() => {
  if (!props.is_batch_number)
    return [];
  if (is_not_on_system.value) {
    const prefill_custom_fields_data = state.valid_table_data;
    const pallet_numbers = prefill_custom_fields_data.map(item => item.batch_number).filter(item => item?.length);
    return countFrequencyOfFrequencies(pallet_numbers);
  }

  return countFrequencyOfFrequencies(state.valid_table_data.map(item => item.batch_number).filter(item => item));
});

const invalid_stocks_types = computed(() => {
  const processInvalidItems = (item, types, invalid_codes_hash) => {
    if (item.children && item.children.length)
      item.children.forEach((child) => {
        processInvalidItems(child, types, invalid_codes_hash);
      });

    else
      if (types[item.reason])
        types[item.reason].count++;
      else
        types[item.reason] = {
          count: 1,
          key: invalid_codes_hash[item.reason],
        };
  };

  const types = {};

  props.invalid_stocks_data.forEach((item) => {
    processInvalidItems(item, types, invalid_codes_hash);
  });

  return types;
});
function makeValidStockTable() {
  const container = document.getElementById(props.valid_stock_table_id);
  valid_stock_data_table.value = new Handsontable(container, {
    data: state.valid_table_data,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    autoRowSize: false,
    rowHeaders: true,
    stretchH: 'all',
    height: '22rem',
    width: '100%',
    rowHeights: 26,
    viewportRowRenderingOffset: 100,
    colHeaders(index) {
      return state.valid_columns[index].text || state.valid_columns[index].data;
    },
    columns: state.valid_columns,
  });

  valid_stock_data_table.value.render();
}
function makeInvalidStockTable() {
  const container = document.getElementById(props.invalid_stock_table_id);
  invalid_stocks_data_table.value = new Handsontable(container, {
    data: state.invalid_table_data,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    autoRowSize: false,
    rowHeaders: true,
    stretchH: 'all',
    height: '22rem',
    rowHeights: 26,
    width: '100%',
    className: 'invalid-table',
    viewportRowRenderingOffset: 100,
    colHeaders(index) {
      return state.invalid_columns[index].text || state.invalid_columns[index].data;
    },
    columns: state.invalid_columns,
  });
  invalid_stocks_data_table.value.render();
}
function exportCsv(type) {
  const instance = type === 'valid_stocks' ? valid_stock_data_table.value.getPlugin('exportFile') : invalid_stocks_data_table.value.getPlugin('exportFile');
  instance.downloadFile('csv', {
    bom: false,
    columnDelimiter: ',',
    columnHeaders: true,
    exportHiddenColumns: true,
    exportHiddenRows: true,
    fileExtension: 'csv',
    filename: props.item?.name,
    mimeType: 'text/csv',
    rowDelimiter: '\r\n',
    rowHeaders: false,
  });
}

async function prepareDataSet(dataset) {
  const data = [];
  const column_skeleton = {};
  const columns = [];
  function processItem(item, parent = {}) {
    let data_key;
    if (item.type === 'pallet')
      data_key = 'batch_number';
    else if (item.type === 'serial')
      data_key = 'serial_no';
    else data_key = item.type;

    column_skeleton[data_key] = item.name;

    const currentData = { ...parent, [data_key]: item.number };

    if (item.children?.length) {
      item.children.forEach((child) => {
        processItem(child, currentData);
      });
    }
    else {
      currentData.original_reason_data = item.reason;
      data.push(currentData);
    }
  }
  dataset.forEach(item => processItem(item));

  Object.keys(column_skeleton).forEach((key) => {
    let text = column_skeleton[key] || ' ';
    if (key === 'serial_no')
      text = 'Serial number';
    else if (key === 'batch_number')
      text = 'Pallet number';
    columns.push({
      data: key,
      text,
      readOnly: true,
    });
  });
  return {
    data,
    columns,
  };
}
onUnmounted(() => {
  if (invalid_stocks_data_table.value)
    invalid_stocks_data_table.value.destroy();
  if (valid_stock_data_table.value)
    valid_stock_data_table.value.destroy();
});
onMounted(async () => {
  try {
    await load_js_css_file(
      'https://cdn.jsdelivr.net/npm/handsontable@14.6.1/dist/handsontable.full.min.js',
      'handsontable-js',
      'js',
    );
    await load_js_css_file(
      'https://cdn.jsdelivr.net/npm/handsontable@14.6.1/dist/handsontable.full.min.css',
      'handsontable-css',
      'css',
    );

    if (props.view === 'transaction-form' && is_not_on_system.value) {
      const prefill_custom_fields_data = props.sn_table_ref ? await props.sn_table_ref.state.sn_data : props.stock_input?.data?.data;
      const prefill_custom_fields_columns = props.sn_table_ref ? await props.sn_table_ref.state.sn_columns : props.stock_input?.data?.columns;
      const data_hash = keyBy(prefill_custom_fields_data, 'serial_no');
      if (props.invalid_stocks_data?.length) {
        const data = [];
        props.invalid_stocks_data.forEach((item) => {
          if (data_hash[item.number])
            data.push({
              ...data_hash[item.number],
              original_reason_data: item.reason,
            });
          if (item.children.length)
            item.children.forEach((child) => {
              if (data_hash[child.number])
                data.push({
                  ...data_hash[child.number],
                  original_reason_data: child.reason,
                });
            });
        });
        state.invalid_columns = prefill_custom_fields_columns.map(item => ({ ...item, readOnly: true }));

        state.invalid_table_data = data;
        makeInvalidStockTable();
      }
      if (props.valid_stocks_data?.length) {
        const data = [];
        props.valid_stocks_data.forEach((item) => {
          if (data_hash[item.number])
            data.push({
              ...data_hash[item.number],
              original_reason_data: item.reason,
            });
          if (item.children.length)
            item.children.forEach((child) => {
              if (data_hash[child.number])
                data.push({
                  ...data_hash[child.number],
                  original_reason_data: child.reason,
                });
            });
        });
        state.valid_columns = prefill_custom_fields_columns.map(item => ({ ...item, readOnly: true }));
        state.valid_table_data = data;
        makeValidStockTable();
      }
    }
    else {
      if (props.invalid_stocks_data?.length) {
        const { columns, data } = await prepareDataSet(props.invalid_stocks_data);

        state.invalid_columns = columns;

        state.invalid_table_data = data;

        makeInvalidStockTable();
      }
      if (props.valid_stocks_data?.length) {
        const { columns, data } = await prepareDataSet(props.valid_stocks_data);

        state.valid_columns = columns;
        state.valid_table_data = data;

        makeValidStockTable();
      }
    }
  }
  catch (e) {
    logger.log(e);
  }
});
function searchData(data, searchTerm) {
  searchTerm = searchTerm.toLowerCase(); // Convert search term to lowercase for case-insensitive search
  return data.filter((entry) => {
    return Object.values(entry).some(value =>
      value?.toString()?.toLowerCase()?.includes(searchTerm),
    );
  });
}
function searchInvalidStocks(value) {
  if (value.length)

    invalid_stocks_data_table.value.loadData(searchData(state.invalid_table_data, value));

  else invalid_stocks_data_table.value.loadData(state.invalid_table_data);
}
function searchValidStocks(value) {
  if (value.length)

    valid_stock_data_table.value.loadData(searchData(state.valid_table_data, value));

  else valid_stock_data_table.value.loadData(state.valid_table_data);
}
function filterValidStocks(key) {
  state.valid_stock_filter = state.valid_stock_filter === key ? null : key;
  if (state.valid_stock_filter) {
    const data = state.valid_table_data.filter(item => item.batch_number === state.valid_stock_filter);

    valid_stock_data_table.value.loadData(data);
  }
  else {
    valid_stock_data_table.value.loadData(state.valid_table_data);
  }
}
function filterInvalidStocks(key) {
  state.invalid_stock_filter = state.invalid_stock_filter === key ? null : key;
  if (state.invalid_stock_filter) {
    const data = state.invalid_table_data.filter(item => item.original_reason_data === state.invalid_stock_filter);
    invalid_stocks_data_table.value.loadData(data);
  }
  else {
    invalid_stocks_data_table.value.loadData(state.invalid_table_data);
  }
}
</script>

<template>
  <div class="flex-col flex mt-3">
    <div v-show="valid_stocks_data.length">
      <div class="flex col-span-12 text-sm font-medium text-gray-700">
        <IconHawkCheckCircleGreen
          class=" h-5 w-5 mr-1"
        />  {{ $t('Valid stock') }}
      </div>
      <div class="flex col-span-12 py-3 text-sm items-center justify-between">
        <div class="flex gap-3">
          <template v-if="Object.keys(valid_stocks_types).length">
            <HawkButton
              v-for="(value, key) in valid_stocks_types"
              :key="key"

              type="outlined"
              size="xxs" :color="state.valid_stock_filter === value.key ? 'active' : ''"
              @click="filterValidStocks(value.key)"
            >
              <div>
                <HawkText :content="key" class="text-sm" /> {{ uom_symbol }}
                ({{ value?.count }})
              </div>
            </HawkButton>
          </template>
        </div>
        <div class="flex items-center justify-evenly gap-2">
          <IconHawkDownloadOne
            class="cursor-pointer"
            @click.stop="exportCsv('valid_stocks')"
          />
          <HawkSearchInput
            v-model="state.valid_search"
            :placeholder="$t('Search stocks')"
            @update:modelValue="searchValidStocks"
          />
        </div>
      </div>
      <div :id="valid_stock_table_id" />
      <div v-if="state.valid_table_data.length > 8 " class="text-xs mt-2">
        {{ state.valid_table_data?.length }} {{ $t('entries') }}
      </div>
    </div>
    <div v-show="invalid_stocks_data.length">
      <div class="flex col-span-12 text-sm font-medium text-gray-700">
        <IconHawkAlertTriangle class="mr-2 text-red-500" />    {{ $t('Invalid stock') }}
      </div>
      <div class="flex col-span-12 py-3 text-sm items-center justify-between">
        <div class="flex gap-3">
          <HawkButton
            v-for="(value, key) in invalid_stocks_types"
            :key="key"
            type="outlined"
            size="xxs"
            :color="state.invalid_stock_filter === key ? 'active' : ''" @click="filterInvalidStocks(key)"
          >
            <div>
              <HawkText :content="value.key" class="text-sm" length="30" />
              ({{ value.count }})
            </div>
          </HawkButton>
        </div>
        <div class="flex items-center justify-evenly gap-2">
          <IconHawkDownloadOne
            class="cursor-pointer"
            @click.stop="exportCsv('invalid_stocks')"
          />
          <HawkSearchInput
            v-model="state.invalid_search"
            :placeholder="$t('Search stocks')"
            @update:modelValue="searchInvalidStocks"
          />
        </div>
      </div>
      <div :id="invalid_stock_table_id" />
      <div v-if="state.invalid_table_data.length > 8" class="text-xs mt-4">
        {{ state.invalid_table_data.length }} {{ $t('entries') }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.invalid-table) {
  .handsontable th{
  background-color: rgba(254, 243, 242, 1) !important;
  border: 1px solid rgba(254, 228, 226, 1);
  height: 26px !important;

  }
  .handsontable td{
    background: rgba(255, 251, 250, 1) !important;

  }
}
:deep(.htCore) {
  th {
    vertical-align: middle;
    padding: 4px !important;
    font-weight: 500;
    height: 26px !important;
    text-align: left;
    background-color: rgba(249, 250, 251, 1) !important;
    &:nth-child(1) {
      text-align: center;
    }
  }
  td {
    padding: 4px;
    max-width: 30rem;
  }
}
</style>
