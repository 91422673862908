<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  asset_id: {
    type: String,
    default: '',
  },
  reference_name: {
    type: String,
    default: null,
  },
  items: {
    type: Array,
    default: () => [],
  },
});

const is_template = inject('is-template');
const task_store = inject('task_store');
const { update_task_uid } = inject('task-uid') || {};

const auth_store = useAuthStore();
const current_organization = auth_store.current_organization;

const route = useRoute();
const router = useRouter();

const common_store = useCommonStore();

const breadcrumbs = computed(() => {
  let location_details = [];
  if (props.asset_id && common_store.get_asset(props.asset_id))
    location_details.push({
      name: common_store.get_asset(props.asset_id)?.name || '',
      label: common_store.get_asset(props.asset_id)?.name || '',
      uid: props.asset_id,
      disabled: true,
    });
  else
    location_details.push({
      name: current_organization?.name || '',
      label: current_organization?.name || '',
      uid: current_organization?.uid,
      disabled: true,
    });
  if (props.items.length)
    location_details = [...location_details, ...props.items.map(item => ({ label: item?.name || '', ...item }))];
  if (props.reference_name)
    location_details.push({ name: props.reference_name, label: props.reference_name, disabled: true });
  return [
    ...location_details,
    {
      name: 'Task',
      label: 'Task',
      disabled: true,
    },
    ...(is_template ? [{ name: 'Template', label: 'Templates', disabled: true }] : []),
  ];
});

function handleNavigation(item) {
  if (!item.disabled) {
    let pop_index = task_store.navigation_context_stack.length - 1;
    let create_new_context = true;
    while (pop_index >= 0) {
      if (task_store.navigation_meta.current_task_uid === item.uid) {
        create_new_context = false;
        break;
      }
      task_store.delete_navigation_context();
      pop_index = pop_index - 1;
    }

    if (create_new_context)
      task_store.create_navigation_context({
        current_task_uid: item.uid,
      });

    update_task_uid(item.uid);
    const task = btoa(JSON.stringify({
      id: item.uid,
      store_key: task_store.$id,
    }));
    router.replace({ ...route, query: { [is_template ? 'template' : 'task']: task } });
  }
}
</script>

<template>
  <hawk-breadcrumbs
    :items="breadcrumbs"
    @crumb-clicked="(item) => handleNavigation(item)"
  />
</template>
