<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  asset: {
    type: Object,
  },
  hawk_menu_items: {
    type: Array,
  },
});

const $t = inject('$t');
const auth_store = useAuthStore();
</script>

<template>
  <img
    v-if="asset?.cover_image?.url"
    :src="asset?.cover_image?.url"
    alt=""
    class="object-cover w-full h-[8rem] rounded-t-lg"
  >
  <div v-else class="asset-img-placeholder object-cover w-full h-[8rem]"/>
  <div v-if="asset?.asset_type" class="absolute top-3 right-3 text-xs py-0.5 px-2 bg-white rounded-lg">
    {{ asset.asset_type }}
  </div>
  <div class="p-3 text-sm flex justify-between">
    <div>
      <div class="text-gray-900 mb-1 font-semibold">
        <HawkText :content="asset.name" :length="32" />
      </div>
      <div class="text-gray-600 mb-2">
        <p v-if="asset?.code || asset?.address?.name">
          <span>
            {{ asset?.code ? `#${asset?.code}` : '' }}
          </span>
          <span v-if="asset?.code && asset?.address?.name"> , </span>
          <span class="font-normal">
            <HawkText :content="asset?.address?.name" :length="64" />
          </span>
        </p>
        <p v-else>
          -
        </p>
      </div>
    </div>
    <div v-if="auth_store.check_permission('modify_assets', asset.uid)" @click.stop>
      <HawkMenu
        :items="hawk_menu_items"
        additional_trigger_classes="!ring-0 !focus:ring-0"
        @select="(e) => e.onClick(asset)"
      >
        <template #trigger>
          <HawkButton icon size="xs" type="text">
            <IconHawkDotsVertical />
          </HawkButton>
        </template>
      </HawkMenu>
    </div>
  </div>
</template>
