import { computed } from 'vue';
import { useRoute } from 'vue-router';

// stores
import { useDocumentStore } from '~/dms/store/document.store';

export function useDocumentGlobalActions(store_key) {
  const route = useRoute();

  const document_store = useDocumentStore(store_key);
  const current_active_route = computed(() => route.meta.page);

  const query_type_mapping = computed(() => {
    const { uid } = document_store.active_folder_meta;
    const is_root = !['archive', 'shared', 'trash'].includes(current_active_route.value);

    return {
      asset: { asset: uid, is_root },
      folder: { uid, children: true },
      organization: {
        ...(is_root ? { asset: 'null' } : {}),
        is_root,
      },
    };
  });

  function queryBasedOnType(options, active_tab) {
    const { type } = document_store.active_folder_meta;
    return {
      files: true,
      folders: true,
      [active_tab]: true,
      ...(query_type_mapping.value?.[type] || {}),
      ...(options?.query || {}),
    };
  }

  function buildQuery(options) {
    const { type, uid } = document_store.active_folder_meta;
    if (['archive', 'shared', 'trash'].includes(current_active_route.value))
      return queryBasedOnType(options, current_active_route.value === 'trash' ? 'deleted' : current_active_route.value);
    else
      return {
        ...(query_type_mapping.value?.[type] || {}),
        ...(!type && !uid ? { is_root: true } : {}),
        ...(options?.query ? { ...options.query } : {}),
      };
  }

  async function getDocuments(options = {}) {
    const query = buildQuery(options);
    if (options.skip_root)
      query.is_root = false;
    await document_store.set_documents({
      query,
      ...(options?.filters ? { body: options.filters } : {}),
    });
  }

  return {
    getDocuments,
  };
}
