<script setup>
const props = defineProps({
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['badge', 'tiny', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl'].includes(value);
    },
  },
  member_details: {
    type: Object,
    default: () => {},
  },
  background_color: {
    type: String,
    default: '',
  },
  has_email: {
    type: Boolean,
    default: false,
  },
  name_truncate_length: {
    type: Number,
    default: 30,
  },
  email_truncate_length: {
    type: Number,
    default: 30,
  },
  img_src: {
    type: String,
    default: '',
  },
  has_avatar: {
    type: Boolean,
    default: true,
  },
  name_classes: {
    type: String,
    default: '',
  },
  email_classes: {
    type: String,
    default: '',
  },
});

defineEmits(['clear']);

const user_name_classes = computed(() => {
  const classes = [];
  switch (props.size) {
    case 'badge':
    case 'tiny':
      classes.push('text-xs font-medium text-gray-700');
      break;
    case 'xxs':
      classes.push('text-sm font-medium text-gray-700');
      break;
    case 'xs':
    case 'sm':
      classes.push('text-sm font-medium text-gray-700');
      break;
    case 'md':
      classes.push('text-sm font-medium text-gray-700');
      break;
    case 'lg':
      classes.push('text-base font-medium text-gray-700');
      break;
    case 'xl':
    case '2xl':
      classes.push('text-lg font-medium text-gray-700');
      break;
  }

  return classes;
});

const user_email_classes = computed(() => {
  const classes = [];
  switch (props.size) {
    case 'badge':
      classes.push('text-xs text-gray-600');
      break;
    case 'xs':
    case 'sm':
      classes.push('text-xs text-gray-600');
      break;
    case 'md':
      classes.push('text-sm text-gray-600');
      break;
    case 'lg':
      classes.push('text-base text-gray-600');
      break;
    case 'xl':
    case '2xl':
      classes.push('text-base text-gray-600');
      break;
  }

  return classes;
});

const user_classes = computed(() => {
  const username = [];
  if (props.size === 'badge')
    username.push('p-[3px]');

  return username;
});
</script>

<template>
  <div
    class="flex items-center h-fit flex-shrink-0"
    :class="[user_classes]"
  >
    <template v-if="has_avatar">
      <SensehawkAvatar
        v-if="member_details.type === 'sensehawk'"
        :size="size"
        class="flex-shrink-0"
      />
      <UnknownAvatar
        v-else-if="member_details.type === 'unknown'"
        :size="size"
        class="flex-shrink-0"
      />
      <TeamAvatar
        v-else-if="member_details.is_team"
        :size="size"
        class="flex-shrink-0"
      />
      <HawkAvatar
        v-else
        :name="member_details?.name"
        v-bind="props"
        :size="size"
        :img_src="member_details?.display_picture"
        class="flex-shrink-0"
      />
    </template>
    <div
      class="text-gray-700 font-medium"
      :class="{ 'mx-1.5': has_avatar }"
    >
      <div class="grid">
        <HawkText
          :content="member_details?.name"
          :length="name_truncate_length"
          :class="[user_name_classes, name_classes]"
          class="whitespace-nowrap"
        />
        <HawkText
          v-if="has_email"
          :content="member_details?.email"
          :length="email_truncate_length"
          :class="[user_email_classes, email_classes]"
        />
      </div>
    </div>
  </div>
</template>
