import { inject } from 'vue';
import { keyBy } from 'lodash-es';
import DOMPurify from 'dompurify';

import { $date } from '~/common/utils/date.util';
import { truncate, truncateMiddle } from '~/common/filters/truncate.js';

import { useCommonStore } from '~/common/stores/common.store';

import { fullName } from '~/tasks/composables/view-helpers.js';
import { changeIconDimensions, stringToNumber } from '~/common/utils/common.utils.js';

import OutlineGroups from '~icons/ic/outline-groups?raw';
import CriticalPriorityFlag from '~icons/hawk/critical-priority-flag?raw';
import HighPriorityFlag from '~icons/hawk/high-priority-flag?raw';
import MediumPriorityFlag from '~icons/hawk/medium-priority-flag?raw';
import LowPriorityFlag from '~icons/hawk/low-priority-flag?raw';
import NoPriorityFlag from '~icons/hawk/no-priority-flag?raw';

import IconHawkCriticalPriorityFlag from '~icons/hawk/critical-priority-flag.svg';
import IconHawkHighPriorityFlag from '~icons/hawk/high-priority-flag.svg';
import IconHawkMediumPriorityFlag from '~icons/hawk/medium-priority-flag.svg';
import IconHawkLowPriorityFlag from '~icons/hawk/low-priority-flag.svg';
import IconHawkNoPriorityFlag from '~icons/hawk/no-priority-flag.svg';

export function hawkUserTeamBadgeGroup(cardFields, users, teams, user) {
  const $t = inject('$t');
  const common_store = useCommonStore();
  if (!cardFields.assignees.length)
    return;
  if (users.length) {
    let assignee_number_text = '';
    const sanitized_username = truncateMiddle(DOMPurify.sanitize(fullName(user), { ALLOWED_TAGS: [] }) || $t('Invalid name'), 15);

    if (cardFields.assignees.length > 1)
      assignee_number_text = `
        <div class="ml-1 bg-gray-100 px-2 py-[2px] rounded-lg text-xs font-medium">
          +${cardFields.assignees.length - 1}
        </div>`;

    if (user?.display_picture)
      return `
        <div class="bg-gray-100 pr-2 rounded-lg pl-[3px] text-xs font-medium py-[2px]">
          <img src="${user.display_picture}" class="w-4 h-4 rounded-full inline mr-[6px]" />
          <span>
            ${sanitized_username}
          </span>
        </div>
        ${assignee_number_text}`;

    return `
      <div class="bg-gray-100 pr-2 rounded-lg pl-[3px] text-xs font-medium py-[2px]">
        <span class="inline-flex items-center justify-center leading-none rounded-full text-white p-1 w-4 h-4" style="background-color: ${stringToNumber(user.first_name || user.email)};">
          ${fullName(user)?.charAt(0)}
        </span>
        <span>
          ${sanitized_username}
        </span>
      </div>
      ${assignee_number_text}`;
  }
  else if (teams.length) {
    let team_number_text = '';
    if (cardFields.assignees.length > 1)
      team_number_text = `
        <div class="ml-1 bg-gray-100 px-2 py-[2px] rounded-lg text-xs font-medium">
          +${cardFields.assignees.length - 1}
        </div>`;
    return `
      <div class="bg-gray-100 pr-2 rounded-lg pl-[3px] text-xs font-medium py-[2px] flex items-center">
        <span class="text-blue-500 inline-flex mr-[6px]">
          ${OutlineGroups}
        </span>
        <span>
          ${truncateMiddle(DOMPurify.sanitize(common_store.teams_map[teams?.[0]]?.name || $t('Unknown'), { ALLOWED_TAGS: [] }) || $t('Invalid name'), 15)}
        </span>
        </div> 
        ${team_number_text}`;
  }
}

export function getLocationName(form) {
  const common_store = useCommonStore();
  let location_name = [];
  if (form?.target_element?.asset && common_store.get_asset(form.target_element.asset)) {
    if (form.target_element.asset)
      location_name = [common_store.get_asset(form.target_element.asset).name];
    if (form.properties?.reference_name)
      location_name.push(form.properties?.reference_name);
    return location_name;
  }
  return [];
}

export function sanitizeLocations(location_names) {
  const $t = inject('$t');
  return location_names.map(loc => (DOMPurify.sanitize(loc, { ALLOWED_TAGS: [] }) || $t('Invalid location name')));
}

export function hawkCategory(category_name) {
  const sanitized_category = DOMPurify.sanitize(category_name, { ALLOWED_TAGS: [] }) || '';
  if (sanitized_category?.length > 20)
    return `<span data-tippy-content="${sanitized_category}" class="text-gray-900">
                  ${truncate(sanitized_category, 20)}
                </span>`;
  return `<span class="text-gray-900">
            ${sanitized_category}
          </span>`;
}

export function formattedDate(cardFields) {
  if (cardFields.due_date)
    return $date(cardFields.due_date, 'Do MMM YYYY');
  else return '';
}

export function addTransparency(hexColor, alpha) {
  // Convert hex to RGB
  const r = Number.parseInt(hexColor.slice(1, 3), 16);
  const g = Number.parseInt(hexColor.slice(3, 5), 16);
  const b = Number.parseInt(hexColor.slice(5, 7), 16);

  // Return the new color with transparency
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getPriorityObject(key_by = 'value') {
  const priority_list = [
    {
      priority_class_label: 'text-error-700',
      priority_class_icon: 'text-error-500',
      IconToBeDisplayed: changeIconDimensions(CriticalPriorityFlag),
      icon: IconHawkCriticalPriorityFlag,
      name: 'critical',
      label: 'Critical',
      value: 1,
    },
    {
      priority_class_label: 'text-warning-700',
      priority_class_icon: 'text-warning-500',
      IconToBeDisplayed: changeIconDimensions(HighPriorityFlag),
      icon: IconHawkHighPriorityFlag,
      name: 'high',
      label: 'High',
      value: 2,
    },
    {
      priority_class_label: 'text-success-700',
      priority_class_icon: 'text-success-500',
      IconToBeDisplayed: changeIconDimensions(MediumPriorityFlag),
      icon: IconHawkMediumPriorityFlag,
      name: 'medium',
      label: 'Medium',
      value: 3,
    },
    {
      priority_class_label: 'text-gray-700',
      priority_class_icon: 'text-gray-500',
      IconToBeDisplayed: changeIconDimensions(LowPriorityFlag),
      icon: IconHawkLowPriorityFlag,
      name: 'low',
      label: 'Low',
      value: 4,
    },
    {
      priority_class_label: 'text-gray-700',
      priority_class_icon: 'text-gray-500',
      IconToBeDisplayed: changeIconDimensions(NoPriorityFlag),
      icon: IconHawkNoPriorityFlag,
      name: 'not_set',
      label: 'Not set',
      value: 5,
    },
  ];
  return keyBy(priority_list, key_by);
}
