<script setup>
// stores
import { useDocumentStore } from '~/dms/store/document.store.js';

// components
import DocumentSidebar from '~/dms/components/documents/document-sidebar.vue';

const route = useRoute();

const document_store = useDocumentStore();

const selected_item = ref(null);
const active_item = ref({});

const state = reactive({
  is_loading: false,
  active_item: {},
  selected_item: null,
});

const sidebar_tree = computed(() => document_store.folder_tree(route.params.asset_id));

onMounted(async () => {
  state.is_loading = true;
  await document_store.set_hierarchy(route.params.asset_id);
  state.is_loading = false;
});

function onSidebarChange(item) {
  state.active_item = item;
  if (item.uid === 'null' || item.type === 'asset')
    state.selected_item = null;
  else
    state.selected_item = { type: item.asset ? 'organization' : item.asset, uid: item.uid }; // need to confirm
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg min-w-[625px]">
    <hawk-modal-header @close="$emit('close')">
      <template #title>
        {{ $t('Select Destination') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content class="max-h-80 relative overflow-auto scrollbar">
      <hawk-loader v-if="state.is_loading" />
      <HawkIllustrations v-else-if="!sidebar_tree?.length && !state.is_loading" type="no-data" for="folders" variant="mini_vertical" />
      <DocumentSidebar
        v-else
        sidebar_class="!h-auto !w-auto"
        :options="{
          is_form_workflow: true,
        }"
        :active_folder="state.active_item"
        :data="sidebar_tree"
        @activeFolderChange="onSidebarChange"
      />
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end w-full">
          <HawkButton type="outlined" text="Cancel" class="mr-2" @click="$emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton
            color="primary"
            :class="{ 'opacity-50 pointer-events-none': !state.selected_item }"
            text="Save" @click="$emit('save', { value: state.selected_item, folder: state.active_item });"
          >
            {{ $t('Save') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
