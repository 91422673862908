<script setup>
import { computed } from 'vue';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  // 1TxfBvQSjn
  uid: {
    type: String,
    default: '',
  },
  length: {
    type: Number,
    default: 20,
  },
});

const common_store = useCommonStore();

const role = computed(() => common_store.get_role(props?.uid));
</script>

<template>
  <div class="text-sm truncate w-auto">
    <HawkText :content="role && role.name || ''" :length="length" />
  </div>
</template>
