<script setup>
// --------------------------------- Imports -------------------------------- //
import { isEmpty, keyBy } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { useDocumentStore } from '~/dms/store/document.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

// ---------------------------------- Props --------------------------------- //

const props = defineProps({
  document_details: {
    type: Object,
  },
  actual_document_details: {
    type: Object,
  },
});

// ---------------------------------- Emits --------------------------------- //

const emit = defineEmits(['close']);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const dms_documents_settings_store = useDMSSettingsStore('documents');
const document_store = useDocumentStore();
const router = useRouter();
const route = useRoute();
// ------------------------ Variables - Local - refs ------------------------ //

const actual_document_details = props.actual_document_details;
// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const document_custom_fields = computed(() => dms_documents_settings_store.custom_fields.filter(field => !field.deleted));

const custom_fields_data = computed(() => {
  if (actual_document_details) {
    const document_field_map = keyBy(document_custom_fields.value, 'uid');
    const { field_values = [] } = actual_document_details;
    const custom_fields_detail = field_values.map(field_value => ({
      ...document_field_map[field_value.field],
      value: field_value.value,
    })).filter(field_value => field_value.value && field_value.label);
    return custom_fields_detail;
  }
  return null;
});

// -------------------------------- Functions ------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div v-if="custom_fields_data?.length">
    <hawk-accordion
      :items="[
        { uid: 'document_details', title: $t('Document properties') },
      ]"
      type="plain"
    >
      <template #header="{ item, is_open }">
        <div class="font-semibold gap-2 mb-2 text-gray-900 text-sm flex">
          <IconHawkChevronDown v-if="is_open" />
          <IconHawkChevronRight v-else />
          {{ item.title }}
        </div>
      </template>
      <template #content>
        <div v-for="(custom_field_val, custom_field_index) in custom_fields_data" :key="custom_field_index" class="grid grid-cols-12 auto-rows-auto mt-4  ml-3">
          <div class="col-span-4 ">
            <span class="text-sm font-medium mb-2 text-gray-500">
              {{ custom_field_val.label }}
            </span>
          </div>
          <div class="col-span-8">
            <hawk-custom-field class="text-sm" :field="custom_field_val" :value="custom_field_val?.value" />
          </div>
        </div>
      </template>
    </hawk-accordion>
  </div>
</template>
