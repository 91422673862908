import { useMap } from './maps';

export function useMapItemCreation(options, emits = () => ({})) {
  const { setLayers, setSources } = useMap();
  const iconMarkerForItemCreationSource = (e, map_instance) => {
    emits(e);
    map_instance.getSource('mapItemIcon')?.setData({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: Object.values(e.lngLat),
          },
        },
      ],
    });
  };
  const addIconMarkerForItemCreation = ({ map_instance, icon }) => {
    if (!map_instance.getSource('mapMarker'))
      setSources(['mapItemIcon'], map_instance);

    if (!map_instance.getLayer('mapItemIconLayer'))
      setLayers([
        {
          id: 'mapItemIconLayer',
          type: 'symbol',
          source: 'mapItemIcon',
          layout: {
            'icon-image': icon,
            'icon-size': 1,
          },
        },
      ], map_instance);

    map_instance.on('click', (e) => {
      iconMarkerForItemCreationSource(e, map_instance);
    });
  };

  const removeItemCreationIcon = (map_instance) => {
    if (map_instance.getLayer('mapItemIconLayer'))
      map_instance.removeLayer('mapItemIconLayer');
    if (map_instance.getSource('mapItemIcon'))
      map_instance.removeSource('mapItemIcon');
    map_instance.off('click', e => iconMarkerForItemCreationSource(e, map_instance));
    emits('remove-icon');
  };
  return { addIconMarkerForItemCreation, removeItemCreationIcon, iconMarkerForItemCreationSource };
}
