<template>
  <svg width="48" height="48" viewBox="0 0 62 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="19.9436" width="26" height="98.0563" rx="13" fill="#5296DA" />
    <rect x="36" width="26" height="98.0563" rx="13" fill="#66C299" />
  </svg>
</template>

<style lang="scss" scoped>

</style>
