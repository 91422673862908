<script setup>
import dayjs from 'dayjs';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { getPageNameByRouteName } from '~/common/utils/common.utils';
import { useFormFilterStore } from '~/forms/store/form-filter-store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import { useFormsStore } from '~/forms/store/forms.store';

const props = defineProps({
  forms_store: { type: Object },
  asset_id: {
    type: String,
  },
  fields: {
    type: Array,
    default: () => ['Status', 'Category', 'Tags', 'Template', 'Assignees', 'Start Date', 'Due Date', 'Created At', 'Created By', 'Rolled Back'],
  },
  filter_type: {
    type: String,
    default: '',
  },
  filters: {
    type: Object,
  },
  query: {
    type: Object,
  },
  extra_query: {
    type: Object,
    default: () => {},
  },
  modal_options: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['close', 'save']);
const $track_event = inject('$track_event');
const route = useRoute();
const form$ = ref(null);
const auth_store = useAuthStore();
const forms_store = props.forms_store || useFormsStore();
const filter_store = useFormFilterStore(forms_store.store_key);
const form_workflow_store = useFormWorkflowStore(`${forms_store.store_key}_workflow`);

const is_workflow_v2 = computed(() => {
  const parent_form = forms_store.templates[0];
  return forms_store?.templates?.length === 1 && parent_form?.workflow && parent_form?.workflow_version === 'v2';
});

const due_date_filter = ref([{ name: 'Overdue', value: 'overdue', label: 'Overdue' }, { value: 'today', label: 'Today', name: 'Today' }, { value: 'week', label: 'This Week', name: 'This Week' }, { value: 'month', label: 'This Month', name: 'This Month' }, { value: 'custom', label: 'Custom Range', name: 'Custom Range' }]);

function formatStartDateAndDueDate(load_data, key, value, type) {
  if (key.endsWith('start'))
    load_data[type].unshift(new Date(value).toISOString().split('T')[0]);
  else
    load_data[type].push(new Date(value).toISOString().split('T')[0]);
}

function formatLoadedData(data) {
  const load_data = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value) {
      if (key === 'step_index' || key === 'submission_status' || key === 'block') {
        load_data.submission_status = [...(load_data.submission_status || []), ...value];
      }
      else if (key === 'assignees' || key === 'assigned') {
        load_data.assignees_group = {};
        load_data.assignees_group[key] = value;
        load_data.assignees_group.assigned = key === 'assigned' ? 'Assigned to me' : 'Others';
      }
      else if (['start_date_start', 'start_date_end', 'due_date_start', 'due_date_end', 'created_at_start', 'created_at_end'].includes(key)) {
        if (!load_data.start_date)
          load_data.start_date = [];

        if (!load_data.due_date)
          load_data.due_date = [];

        if (!load_data.created_at)
          load_data.created_at = [];

        if (key.startsWith('start'))
          formatStartDateAndDueDate(load_data, key, value, 'start_date');
        else if (key.startsWith('due'))
          formatStartDateAndDueDate(load_data, key, value, 'due_date');

        if (key.startsWith('created_at')) {
          if (key.endsWith('start'))
            load_data.created_at.unshift(new Date(value));
          else
            load_data.created_at.push(new Date(value));
        }
      }
      else {
        load_data[key] = value;
      }
    }
  });
  return load_data;
}

async function handleSave() {
  const payload = {};
  const form_data = form$.value.data;
  Object.keys(form_data).forEach((key) => {
    if (key === 'submission_status') {
      if (is_workflow_v2.value) {
        form_data[key].forEach((status) => {
          const submitted_key = (status === 'submitted') ? key : 'block';
          if (!payload[submitted_key])
            payload[submitted_key] = [];
          payload[submitted_key].push(status);
        });
      }
      else {
        form_data[key].forEach((status) => {
          if (!payload[Number.isInteger(status) ? 'step_index' : key])
            payload[Number.isInteger(status) ? 'step_index' : key] = [status];
          else
            payload[Number.isInteger(status) ? 'step_index' : key].push(status);
        });
      }
    }
    else if (key === 'assignees_group') {
      if (form_data[key].assigned === 'Assigned to me') {
        payload.assigned = true;
        payload.all_access = undefined;
      }
      else if (form_data[key].assigned === 'Others') {
        payload.assignees = form_data[key].assignees.map(assignee => assignee.uid);
      }
    }
    else if (key === 'created_by' && form_data[key]?.length) {
      payload[key] = form_data[key].map(user => user.uid);
    }
    else if ((key === 'start_date' || key === 'due_date' || key === 'created_at') && form_data[key]?.length > 0) {
      payload[`${key}_start`] = dayjs(form_data[key][0]).startOf('day').toISOString();
      payload[`${key}_end`] = dayjs(form_data[key][1]).endOf('day').toISOString();
    }
    else if (key === 'parent_form_uid' || key === 'rolled_back') {
      if (form_data[key])
        payload[key] = form_data[key];
    }
    else if (Object.values(form_data[key] || {}).length) {
      payload[key] = form_data[key];
    }
  });
  const query = props.query || { parent_form_uid: route.params.template_uid, public: false, is_child: true, all_access: true };
  if (Object.keys(payload).length) {
    $track_event('Filtered', {
      view: getPageNameByRouteName(route.name),
      // quick_filters: false,
      // type: Object.keys(payload).reduce((acc, curr) => {
      //   if (curr === 'start_date_start')
      //     acc.push('Start date');
      //   else if (curr === 'due_date_start')
      //     acc.push('Due date');
      //   else if (curr === 'created_at_start')
      //     acc.push('Created at');
      //   else if (curr === 'progress_start')
      //     acc.push('Progress');
      //   else if (curr === 'step_index')
      //     acc.push('Step block');
      //   else if (curr === 'submission_status')
      //     acc.push('Status');
      //   else if (!curr.includes('_end'))
      //     acc.push(curr.charAt(0).toUpperCase() + curr.slice(1));
      //   return acc;
      // }, []),
    });
  }

  if (props.filter_type === 'terra') {
    emit('filter', payload);
    return;
  }
  await filter_store.set_filters({ payload });
  await forms_store.set_forms({ query: { ...query, ...props.extra_query } });

  emit('save', payload);
  emit('close');
}

async function form_template() {
  const query = {
    isChild: false,
    public: false,
    status: 'published',
  };
  await forms_store.get_templates({ query });
  return forms_store.form_template;
}

function onFormMounted(el$) {
  if (forms_store.filters_count)
    el$.load(forms_store.filters, true);
  if (filter_store.filters_count)
    el$.load(filter_store.filters, true);
  if (props.filter_type === 'terra' && props.filters)
    el$.load(props.filters, true);
}

function clearFilters() {
  form$.value.reset();
}
async function getStatusValues() {
  if (forms_store?.templates?.length === 1) {
    const parent_form = forms_store.templates[0];
    if (parent_form.workflow && parent_form.workflow_version === 'v2') {
      form_workflow_store.workflow = {
        form: parent_form.uid,
        uid: parent_form.workflow_uid,
      };
      await form_workflow_store.getAllBlocks();
      const blocks = form_workflow_store.blocks.filter(block => block.type === 'approvalFlow' || block.type === 'step').map(block => ({
        name: block.name,
        label: block.name,
        value: block.uid,
        type: block,
      }));
      blocks.push({
        name: 'Submitted',
        label: 'Submitted',
        value: 'submitted',
      });
      return blocks;
    }
  }
  return forms_store.status_values;
}
</script>

<template>
  <hawk-modal-container :options="modal_options">
    <Vueform
      ref="form$" :endpoint="handleSave" size="sm" :format-load="formatLoadedData" :columns="{
        default: { container: 12, wrapper: 12 },
        sm: { container: 7, label: 12 },
        md: { container: 12, label: 4 },
      }"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('filters') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="w-[600px]">
          <div class="col-span-12">
            <template v-for="field in fields">
              <!-- Template -->

              <TagsElement
                v-if="field === 'Template' && !route.params.template_uid"
                :key="field"
                class="mb-4"
                name="parent_form_uid"
                :label="$t('template')"
                :placeholder="$t('Choose template')"
                track-by="name"
                label-prop="name"
                value-prop="uid"
                :items="form_template"
                search="true"
                clear-on-search="true"
                close-on-select="true"
                can-deselect="true"
                hide-selected="false"
              />
              <!-- Status -->
              <TagsElement
                v-if="field === 'Status'" :key="field" name="submission_status" :label="$t('status')"
                :placeholder="$t('Select status')" :items="getStatusValues" :tags_removable="true" search="true"
                class="mb-4" :add-classes="{
                  TagsElement: {
                    select: {
                      noResults: '!pb-4',
                    },
                  },
                }"
              >
                <template #tag="{ option, handleTagRemove, disabled }">
                  <div class="border border-gray-300 rounded-lg flex gap-2 items-center p-1 mb-1 mr-1">
                    <HawkText :content="option.label" :length="35" />
                    <span
                      v-if="!disabled"
                      class="p-1 rounded-md group hover:bg-gray-50"
                      @mousedown.stop="handleTagRemove(option, $event)"
                    >
                      <IconHawkCross class="h-2.5 w-2.5 text-gray-400 group-hover:text-gray-600" />
                    </span>
                  </div>
                </template>
              </TagsElement>
              <!-- Assignees -->
              <ObjectElement
                v-if="field === 'Assignees'" :key="field" class="mb-4" name="assignees_group"
                :label="$t('Assignees')"
              >
                <RadiogroupElement
                  name="assigned" :items="[
                    { value: 'Assigned to me', label: $t('Assigned to me') },
                    { value: 'Others', label: $t('Others') },
                  ]"
                />
                <hawk-assignee-input
                  class="mb-6" :options="{
                    name: 'assignees',
                    has_teams: true,
                    conditions: [
                      ['assignees_group.assigned', 'Others'],
                    ],
                    placeholder: $t('Select Assignees'),
                    columns: {
                      default: { container: 12, label: 12, wrapper: 12 },
                      sm: { container: 12, label: 12, wrapper: 12 },
                      md: { container: 12, label: 12, wrapper: 12 },
                    },
                  }" :multi="true"
                />
              </ObjectElement>

              <!-- Start Date -->
              <DateTimeElement
                v-if="field === 'Start Date'"
                :key="field"
                :options="{
                  format: (dates) => (dates[0].toDateString() === dates[1].toDateString()) ? dayjs(dates[0]).format('DD/MM/YYYY') : `${dayjs(dates[0]).format('DD/MM/YYYY')} to ${dayjs(dates[1]).format('DD/MM/YYYY')}`,
                  placeholder: $t('Select start date'),
                  range: true,
                }" class="mb-4"
                name="start_date"
                :label="$t('Start date')"
              />

              <!-- Due Date -->
              <DateTimeElement
                v-if="field === 'Due Date'" :key="field" class="mb-4" mode="range"
                name="due_date" :label="$t('Due date')"
                :options="{
                  format: (dates) => (dates[0].toDateString() === dates[1].toDateString()) ? dayjs(dates[0]).format('DD/MM/YYYY') : `${dayjs(dates[0]).format('DD/MM/YYYY')} to ${dayjs(dates[1]).format('DD/MM/YYYY')}`,
                  placeholder: $t('Select due date'),
                  range: true,
                }"
              />

              <!-- Created By -->
              <hawk-assignee-input
                v-if="field === 'Created By'" :key="field" :options="{
                  label: $t('Created by'),
                  name: 'created_by',
                  placeholder: $t('Select owner'),
                }" :multi="true" class="mb-4"
              />

              <!-- Created At -->
              <DateTimeElement
                v-if="field === 'Created At'"
                :key="field"
                :options="{
                  format: (dates) => (dates[0].toDateString() === dates[1].toDateString()) ? dayjs(dates[0]).format('DD/MM/YYYY') : `${dayjs(dates[0]).format('DD/MM/YYYY')} to ${dayjs(dates[1]).format('DD/MM/YYYY')}`,
                  placeholder: $t('Select created at date'),
                  range: true,
                }"
                class="mb-4"
                name="created_at"
                :label="$t('Created at')"
              />

              <!-- Category -->
              <hawk-category-input
                v-if="field === 'Category'" :key="field" class="mb-4" :options="{
                  name: 'category',
                  label: $t('Category'),
                  placeholder: $t('Select category'),
                  tags_removable: true,
                }" :multi="true"
              />

              <!-- rollback -->
              <CheckboxElement
                v-if="field === 'Rolled Back'"
                :key="field" class="mb-4" v-bind="{
                  name: 'rolled_back',
                  label: $t('Rolled back'),
                }" :multi="true"
              />

              <!-- Tags -->
              <hawk-tags-input
                v-if="field === 'Tags'"
                :key="field"
                class="mb-4"
                :options="{
                  name: 'tag',
                  label: $t('Tags'),
                  placeholder: $t('Select tags'),
                  tags_removable: true,
                }"
              />
            </template>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #left>
            <hawk-button type="text" @click="clearFilters">
              {{ $t('Clear filters') }}
            </hawk-button>
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Apply') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style>
.vfm__content {
  min-width: 600px;
}
</style>
