export const PDF_TRON_CDN_BASE_URL = 'https://cdn.jsdelivr.net/gh/sensehawk/cdn/pdftronv2';
export const WEB_CORE_CDN_URL = `${PDF_TRON_CDN_BASE_URL}/webviewer-core.min.js`;
export const PDF_NET_CDN_URL = `${PDF_TRON_CDN_BASE_URL}/pdf/PDFNet.js`;
export const PDF_WORKER_PATH = `${PDF_TRON_CDN_BASE_URL}/core/pdf`;

export const PLANS_ROUTES_CONFIG = {
  PLANS: {
    name: 'plans',
    path: '/:asset_id/plans',
  },
  SHEET: {
    name: 'sheet',
    path: ':drawing_id/sheet/:sheet_id',
  },
};

export const DISTANCE_UNITS = ['in', 'mm', 'cm', 'pt', 'ft', 'm', 'yd', 'km', 'mi'];

export const MEASUREMENT_PRESET = {
  // value of Scale is an array that is consisted of two arrays
  // the first element in each array is the scale ratio and the second element is the unit.
  // valid units are: mm, cm, m, km, mi, yd, ft, in and pt
  // the following array means that for the annotations created by the distance measurement tool,
  // array[0] 1 inches on the document is equal to array[1] 1 inch in the real world
  Scale: [
    [1, 'in'], // pageScale -> we get this value from the annotation
    [1, 'in'], // worldScale -> user input value from calibration modal
  ],

  // value of Precision is a number that means how many decimal places the calculated value should have
  Precision: 0.01,
};

export const TOOL_EVENTS = {
  // Drawing tools
  FREEHAND: 'free-hand',
  CREATE_LINE: 'create-line',
  CREATE_ARROW: 'create-arrow',
  CREATE_POLYLINE: 'create-polyline',

  // Shape tools
  CREATE_RECTANGLE: 'create-rectangle',
  CREATE_ELLIPSE: 'create-ellipse',
  CREATE_CLOUD: 'create-cloud',
  CREATE_POLYGON: 'create-polygon',

  // Measurement tools
  AREA: 'area',
  CALIBRATE: 'calibrate',
  DISTANCE: 'distance',

  // Zoom tools
  ZOOM_IN: 'zoom-in',
  ZOOM_OUT: 'zoom-out',

  // Custom annotation tools
  CREATE_TASK: 'create-task',
  CREATE_FORM: 'create-form',
  CREATE_COMMENT: 'create-comment',

  CREATE_STICKY: 'create-sticky',
  CREATE_TEXT: 'create-text',
  CREATE_HIGHLIGHT: 'create-highlight',
  EDIT: 'edit',
  DELETE: 'delete',
  PAN: 'pan',
  RESET: 'reset',
  UNDO: 'undo',
  FULLSCREEN: 'fullscreen',

  SETTING: 'setting',
};

export const COLORS = [
  { name: 'Red', hex: '#E11845', rgb: [225, 24, 69] },
  { name: 'Yellow', hex: '#F2CA19', rgb: [242, 202, 25] },
  { name: 'Green', hex: '#13D10B', rgb: [19, 209, 11] },
  { name: 'Blue', hex: '#1568E9', rgb: [21, 104, 233] },
  { name: 'Orange', hex: '#FF9640', rgb: [255, 150, 64] },
  { name: 'Purple', hex: '#9A42FF', rgb: [154, 66, 255] },
  { name: 'Pink', hex: '#FF00BD', rgb: [255, 0, 189] },
  { name: 'Maroon', hex: '#B40000', rgb: [180, 0, 0] },
  { name: 'Grey', hex: '#B3B3B3', rgb: [179, 179, 179] },
  { name: 'Black', hex: '#000000', rgb: [0, 0, 0] },
];

export const PLANS_PERMISSION = {
  CREATE_ANNOTATIONS: 'create_annotations',
  CREATE_PLANS: 'create_plans',
  MODIFY_ANNOTATIONS: 'modify_annotations',
  MODIFY_PLANS: 'modify_plans',
  VIEW_PLANS: 'view_plans',
  VIEW_PRIVATE_PLANS: 'view_private_plans',
};

export const CUSTOM_ANNOTATION_TYPES = {
  COMMENT: 'Custom_Comment',
  FORM: 'Form',
  TASK: 'Task',
  LOCATION: 'Location', // used for location confirmation mark
};

// Different name of annotaiton to separate it out from the regular one and custom one
export const ANNOTATION_TYPES = {
  NOTE: 'Note',
};
