<script setup>
import DocumentActivitiesItem from '~/common/components/organisms/hawk-activities/document-activities/document-activities-item.vue';
import TaskActivitiesItem from '~/common/components/organisms/hawk-activities/task-activities/task-activities-item.vue';
import ProjectManagementActivitiesItem from '~/common/components/organisms/hawk-activities/project-management-activities-item.vue';
import FormsActivitiesItem from '~/common/components/organisms/hawk-activities/forms-activities-item.vue';
import InventoryActivitiesItem from '~/common/components/organisms/hawk-activities/inventory-activities-item.vue';
import SystemModelActivitiesItem from '~/common/components/organisms/hawk-activities/system-model-activities-item.vue';
import CoreActivitiesItem from '~/common/components/organisms/hawk-activities/core-activities-item.vue';
import TerraActivitiesItem from '~/common/components/organisms/hawk-activities/terra-activities-item.vue';
import PlansActivitiesItem from '~/common/components/organisms/hawk-activities/plans-activities-item.vue';
import DashboardsReportsActivitiesItem from '~/common/components/organisms/hawk-activities/dashboards-reports-activities-item.vue';
import ThermActivitiesItem from '~/common/components/organisms/hawk-activities/therm-activities-item.vue';

import HawkMembers from '~/common/components/organisms/hawk-members-badge/hawk-members.vue';

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
});

function getUser(actor) {
  return actor.split(':')[1];
}

const COMPONENTS_MAP = {
  'tasks': TaskActivitiesItem,
  'forms': FormsActivitiesItem,
  'dms': DocumentActivitiesItem,
  'project_management': ProjectManagementActivitiesItem,
  'inventory': InventoryActivitiesItem,
  'system-model': SystemModelActivitiesItem,
  'core': CoreActivitiesItem,
  'terra': TerraActivitiesItem,
  'plans': PlansActivitiesItem,
  'dashboards_reports': DashboardsReportsActivitiesItem,
  'therm': ThermActivitiesItem,
};

function getComponent() {
  if (props.activity.module === 'TASKS' && props.activity.resource_name === 'DASHBOARD')
    return DashboardsReportsActivitiesItem;

  return COMPONENTS_MAP[props.activity.module?.toLowerCase()];
}

const activity_props = computed(() => ({
  activity: props.activity,
  ...(['FORM', 'FORM_TEMPLATE'].includes(props.activity.resource_name) && { show_form_name: false }),
}));
</script>

<template>
  <div class="flex items-start w-full">
    <HawkMembers :members="getUser(props.activity.actor)" class="mr-2" type="badge" />
    <component :is="getComponent()" v-bind="activity_props" class="w-full text-gray-600 flex items-center" />
  </div>
</template>
