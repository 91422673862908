<script setup>
import { slice } from 'lodash-es';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  max_badges_to_display: {
    type: Number,
    default: 1,
  },
  color: {
    type: String,
    default: 'gray',
    validator(value) {
      return ['slate', 'gray', 'zinc', 'neutral', 'stone', 'red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose'].includes(value);
    },
  },
  type: {
    type: String,
    default: 'light',
    validator(value) {
      return ['light', 'dark', 'outlined'].includes(value);
    },
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['sm', 'md', 'lg', 'xl'].includes(value);
    },
  },
  badge_text_length: {
    type: Number,
    default: 20,
  },
  popover_text_length: {
    type: Number,
    default: 20,
  },
  is_rounded: {
    type: Boolean,
    default: true,
  },
  custom_classes: {
    type: String,
    default: '',
  },
  custom_color: {
    type: String,
    default: '',
  },
  custom_color_background_opacity: {
    type: String,
    default: '0.05',
  },
  has_only_icon: {
    type: Boolean,
    default: false,
  },
  tooltip_text: {
    type: String,
    default: '',
  },
  popover_position: {
    type: String,
    default: 'middle',
    validator(value) {
      return ['left', 'middle', 'right'].includes(value);
    },
  },
  popover_trigger_type: {
    type: String,
    default: 'hover',
    validator(value) {
      return ['hover', 'click'].includes(value);
    },
  },
});

const open = ref(false);
const popover_position_class = ref('right-0 top-0');
const badge_popover_pos_target = ref(null);

const badges_to_display = computed(() => {
  return slice((props.items || []), 0, props.max_badges_to_display) || [];
});

const remaining_badges_to_display = computed(() => slice((props.items || []), props.max_badges_to_display, props.items?.length));
const show_popover = computed(() => remaining_badges_to_display.value.length);

function togglePopover(value) {
  const current_position = badge_popover_pos_target.value.getBoundingClientRect();
  const screen_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  const y_class
    = current_position.y < screen_center.y ? 'top-full' : 'bottom-full';
  popover_position_class.value = `${y_class}`;

  open.value = value;
}

const popover_panel_classes = computed(() => {
  const classes = [];
  switch (props.popover_position) {
    case 'left':
      classes.push('');
      break;
    case 'middle':
      classes.push('left-1/2 -translate-x-1/2');
      break;
    case 'right':
      classes.push('right-0');
      break;
  }

  return classes;
});
</script>

<template>
  <div v-if="badges_to_display?.length" class="inline-flex gap-1 flex-wrap" @click.stop>
    <HawkBadge
      v-for="item in badges_to_display" :key="item.uid"
      v-bind="{
        type,
        is_rounded,
        custom_classes,
        custom_color,
        custom_color_background_opacity,
        has_only_icon,
        tooltip_text,
        size,
      }"
      :color="item?.color || color"
    >
      <slot name="badge" :item="item">
        <HawkText :content="item.label" :length="badge_text_length" />
      </slot>
    </HawkBadge>
    <HawkMenu
      v-if="popover_trigger_type === 'click' && remaining_badges_to_display?.length" :items="remaining_badges_to_display" position="fixed"
      additional_trigger_classes="flex"
      additional_dropdown_classes="min-w-40"
    >
      <template #trigger>
        <HawkBadge
          v-bind="{
            type,
            is_rounded,
            custom_classes,
            custom_color,
            custom_color_background_opacity,
            size,
          }"
          :color="color"
        >
          + {{ remaining_badges_to_display.length }}
        </HawkBadge>
      </template>
      <template #content>
        <div class="p-1 max-h-60 scrollbar">
          <div v-for="item in remaining_badges_to_display" :key="item.uid">
            <slot name="popover_content" :item="item">
              <HawkDropdownListItem :item="item" :text_length="popover_text_length" />
            </slot>
          </div>
        </div>
      </template>
    </HawkMenu>
    <Popover
      v-if="popover_trigger_type === 'hover' && show_popover"
      class="relative flex items-center"
      @mouseenter="togglePopover(true)"
      @mouseleave="togglePopover(false)"
      @click="togglePopover(!open)"
    >
      <PopoverButton class="inline-flex items-center font-medium text-gray-900 cursor-pointer h-full w-full">
        <HawkBadge
          v-bind="{
            type,
            is_rounded,
            custom_classes,
            custom_color,
            custom_color_background_opacity,
            size,
          }"
          :color="color"
        >
          + {{ remaining_badges_to_display.length }}
        </HawkBadge>
      </PopoverButton>
      <span
        ref="badge_popover_pos_target"
        class="absolute top-0 left-0 w-full h-full pointer-events-none"
      />

      <div v-if="open">
        <PopoverPanel
          static
          class="absolute z-[11] flex w-screen max-w-min"
          :class="[popover_position_class, popover_panel_classes]"
        >
          <div class="w-56 flex-shrink-0 rounded-xl p-1 bg-white text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 max-h-60 scrollbar">
            <div v-for="item in remaining_badges_to_display" :key="item.uid">
              <slot name="popover_content" :item="item">
                <HawkDropdownListItem :item="item" :text_length="popover_text_length" />
              </slot>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </Popover>
  </div>
  <div v-else>
    <slot name="no_data" />
  </div>
</template>
