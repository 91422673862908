<script setup>
import { useRoute, useRouter } from 'vue-router';
import { okta_config } from '~/common/utils/constants';
import { accessTokenCookie } from '~/common/utils/common.utils';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const route = useRoute();
const router = useRouter();
const $toast = inject('$toast');
const $t = inject('$t');
const $services = inject('$services');
const common_store = useCommonStore();
const auth_store = useAuthStore();
if (!accessTokenCookie())
  handleAuth();

async function handleAuth() {
  try {
    if (route.query.error_description && route.query.error_description === 'User creation was disabled')
      $toast({
        title: $t('Account not registered'),
        type: 'error',
      });

    if (!accessTokenCookie() && !route.query.code)
      router.push({ name: 'sign-in' });

    const response = await $services.auth.fetchAccessToken({ body: { redirect_uri: okta_config.redirectUri, code: route.query.code } });
    if (response?.data?.access_token) {
      accessTokenCookie('set', response.data.access_token);
      window?.fcWidget?.destroy?.();
      auth_store.load_split();
    }

    else { router.push({ name: 'sign-in' }); }

    if (route.path !== '/')
      router.push({ name: 'home' });
    common_store.initialize({ forceUpdate: true });
  }
  catch (error) {
    logger.log('Error while signing with google', error);
    router.push({ name: 'sign-in' });
  }
}
</script>

<template>
  <div />
</template>
