<script setup>
import { isEqual } from 'lodash-es';
import { reactive } from 'vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import PivotTable from '~/common/components/organisms/hawk-pivot-table/hawk-pivot-table.vue';
import { usePivotTableComposable } from '~/terra/utils/pivot-table.composable.js';
import { useHawkPivotTableHelperComposable } from '~/common/components/organisms/hawk-pivot-table/helper-composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const dashboard_store = useDashboardStore();
const { getPivotTableData } = usePivotTableComposable();
const { preProcessData } = useHawkPivotTableHelperComposable();

const pivot = ref({});
const state = reactive({
  initial_load: true,
  is_loading: false,
  features_data: [],
  pivot_config: {},
  active_chart: null,
});

const get_pivot_table_height = computed(() => {
  if (props.id === 'preview')
    if (Number.isNaN(props.content_height))
      return false;
    else
      return props.content_height - 96;

  const widget_height = (((props.data.h || 22) * 20) - 44);
  return (props.content_height || widget_height) - 46;
});

const pivot_table_data_source = computed(() => preProcessData(Object.values(state.features_data)));
function updatePivotTableData() {
  if (!pivot.value?.flexmonster?.getReport({ withGlobals: true }))
    return;
  if (state.initial_load) {
    state.pivot_config = props.data?.data?.pivot_config;
    state.active_chart = props.data?.data?.pivot_config?.active_chart;
  }
  else { state.pivot_config = pivot.value.flexmonster.getReport({ withGlobals: true }); }

  if (state.pivot_config)
    pivot.value.flexmonster.setReport({
      options: {
        grid: {
          showHeaders: false,
        },
        configuratorButton: false,
      },
      ...state.pivot_config,
      dataSource: pivot_table_data_source.value,
    });
  else
    pivot.value.flexmonster.updateData(pivot_table_data_source.value);
  state.initial_load = false;
  if (props.id !== 'preview')
    updatePrintMap();
}
function updatePivotConfig() {
  dashboard_store.pivot_table_instance = { ...pivot.value?.flexmonster, active_chart: state.active_chart };
}
async function getData(data) {
  try {
    state.is_loading = true;
    state.features_data = await getPivotTableData({
      container: data.container,
      projects: data.projects,
      data_source: data.data_source,
    });
    updatePivotTableData();
    state.is_loading = false;
  }
  catch (err) {
    logger.log(err);
    state.is_loading = false;
  }
}
function updatePrintMap() {
  pivot.value.flexmonster.exportTo('html', { destinationType: 'plain' }, (data) => {
    const html = data.data;

    if (html)
      dashboard_store.update_print_map(props.id, {
        type: props.data?.type,
        renderAt: `pivot-container-${props?.id}`,
        chart_name: props.data?.data?.name,
        renderType: 'pivot_table',
        dimensions: {
          x: props?.data?.x,
          y: props?.data?.y,
        },
        dataSource: {
          table_html: html,
        },
      });
  });
}

watch(() => props.data.data, async (data, old_data) => {
  if (props.id !== 'preview')
    state.initial_load = true;
  if (!isEqual(data, old_data))
    await getData(data);
}, { immediate: true, deep: true });

watch(() => pivot.value, (data, old_data) => {
  if (data && !isEqual(data, old_data))
    updatePivotTableData();
});
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <HawkLoader v-if="state.is_loading || !get_pivot_table_height" />
    <PivotTable
      v-if="get_pivot_table_height"
      v-show="!state.is_loading"
      :chart_id="`pivot_table_chart_${id}`"
      :show_header="id === 'preview'"
      :show_hide_chart="id === 'preview'"
      :active_chart="state.active_chart"
      :pivot_table_data="pivot_table_data_source.data"
      :pivot_table_mapping="pivot_table_data_source.mapping"
      :export_file_name="data?.data?.name || 'Untitled'"
      :pivot_table_height="get_pivot_table_height"
      @pivotInstance="pivot = $event"
      @pivotConfig="updatePivotConfig"
      @updateActiveChart="state.active_chart = $event; updatePivotConfig()"
    />
  </div>
</template>
