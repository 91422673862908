<script setup>
const props = defineProps({
  group_options: {
    type: Array,
    default: () => [],
  },
  groups: {
    type: Array,
    default: () => [],
  },
  update: {},
});
const emit = defineEmits(['close', 'save']);
const form_data = ref({});
const form$ = ref({});
const is_loading = ref(false);
async function save() {
  is_loading.value = true;
  await props.update({ groups: form_data.value?.groups });
  is_loading.value = false;
}
onMounted(() => {
  form$.value?.load({ groups: props.groups });
});
</script>

<template>
  <hawk-modal-container content_class="w-[400px] rounded-lg">
    <Vueform
      ref="form$"
      v-model="form_data"
      sync
      :format-load="(data) => data"
      class="mb-2"
      size="sm"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Settings') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <TagsElement
            name="groups"
            :label="$t('Group by')"
            input-type="search"
            autocomplete="off"
            track-by="label"
            :max="4"
            :close-on-select="false"
            :items="group_options"
            :search="true"
            :native="false"
            :can-clear="true"
            :can-deselect="false"
            :placeholder="$t('Choose upto 4 fields to group assets')"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement
                name="submit"
                :submits="true"
                :loading="is_loading"
                @click="save"
              >
                {{ $t('Apply') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
