<script setup>
import TaskBulkActionsMenu from '~/tasks/components/organisms/tasks-bulk-actions-menu.vue';

const props = defineProps({
  selected_tasks: {
    type: Array,
    default: () => ([]),
  },
  bulk_actions_config: {
    type: Object,
    default: () => ({
      prepend: false,
      include: ['bulk-update', 'archive', 'export-as-csv', 'export-as-pdf', 'delete'],
      additional_options: [],
    }),
  },
});

const emit = defineEmits(['clear-selections', 'deleteTasks', 'exportTasksAsPdf', 'archiveTasks', 'exportTasksAsCsv']);
const task_store = inject('task_store');
</script>

<template>
  <div class="w-full flex items-center justify-between">
    <div class="flex items-center">
      <TaskBulkActionsMenu
        :task_store="task_store"
        :selected_tasks="selected_tasks"
        :bulk_actions_config="bulk_actions_config"
        @deleteTasks="emit('deleteTasks')"
        @exportTasksAsPdf="emit('exportTasksAsPdf')"
        @archiveTasks="emit('archiveTasks')"
        @exportTasksAsCsv="emit('exportTasksAsCsv')"
      />
      <p class="text-gray-700 text-sm mx-4">
        {{ `${selected_tasks.length} ${$t('selected')}` }}
      </p>
      <p
        class="font-semibold text-sm text-gray-600 cursor-pointer"
        @click="emit('clear-selections')"
      >
        {{ $t('Clear') }}
      </p>
    </div>
    <div>
      <slot name="right" />
    </div>
  </div>
</template>
