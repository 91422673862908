/**
 * Creates an array containing 1…N
 * @param {Number} count
 * @returns {Number[]}
 */
export function createArrayFromCount(count) {
  return Array.from({ length: count }, (_, i) => i + 1);
}

/**
 * Download blob data to file
 * @param {Blob} blob File blob
 * @param {String} file_extension
 * @param {String} file_name
 * @returns
 */
export function downloadBlob(blob, file_extension, file_name = 'file') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = `${file_name}.${file_extension}`;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
}

/**
 * Parse the XML string to get the specified attribute value
 * @param {String} xml_string
 * @param {String} attr_name
 * @returns {}
 */
export function extractAttrFromXML(xml_string, attr_name) {
  const parser = new DOMParser();
  const xml_doc = parser.parseFromString(xml_string, 'text/xml');
  const annot_elem = xml_doc.querySelector(`[${attr_name}]`);

  return annot_elem?.getAttribute(attr_name);
}

/**
 * Fullscreen mode toggler
 * @param {DOMElement} element
 */
export function toggleFullscreen(element) {
  const is_fullscreen_mode = element.getAttribute('fullscreen');
  if (is_fullscreen_mode) {
    element.removeAttribute('fullscreen');
    document.exitFullscreen();
  }
  else {
    element.setAttribute('fullscreen', true);

    const fullscreenMethod
    = element.requestFullScreen
    || element.webkitRequestFullScreen
    || element.mozRequestFullScreen
    || element.msRequestFullScreen;

    fullscreenMethod?.call(element);
  }
}

export function getImageUrl(path, name, extension = 'svg') {
  return new URL(`${path}/${name}.${extension}`, import.meta.url).href;
}
