<script setup>
import { debounce } from 'lodash-es';

const props = defineProps({
  wrapper_class: {
    type: String,
    default: '',
  },
  additional_options: {
    type: String,
    default: () => {},
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: null,
  },
  color_list: {
    type: Array,
    default: () => [],
  },
  has_custom_color_picker: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: 'color',
  },
  value_prop: {
    type: String,
    default: 'hex',
  },
  active_index: {
    type: Number,
    default: null,
  },
});

const default_color_list = [
  { name: 'Grey', hex: '#667085', rgb: [102, 112, 133] },
  { name: 'Red', hex: '#F04438', rgb: [240, 68, 56] },
  { name: 'Cyan', hex: '#06AED4', rgb: [6, 174, 212] },
  { name: 'Orange', hex: '#EF6820', rgb: [239, 104, 32] },
  { name: 'Blue', hex: '#2E90FA', rgb: [46, 144, 250] },
  { name: 'Violet', hex: '#875BF7', rgb: [135, 91, 247] },
  { name: 'Teal', hex: '#15B79E', rgb: [21, 183, 158] },
  { name: 'Indigo', hex: '#6172F3', rgb: [97, 114, 243] },
  { name: 'Pink', hex: '#EE46BC', rgb: [238, 70, 188] },
  { name: 'Yellow', hex: '#EAAA08', rgb: [234, 170, 8] },
];

const block_colors = computed(() => {
  if (props.color_list.length)
    return props.color_list;
  return default_color_list;
});

const is_custom_color = ref(false);
const selected_color = ref('');

const color_list = computed(() => {
  return [
    ...block_colors.value,
    { name: 'Custom Color', hex: block_colors.value.findIndex(color => color.hex === selected_color.value) === -1 ? selected_color.value : '', visible: false },
  ];
});

const update_color = debounce((el$, color) => {
  el$.update(color);
}, 100);

function onFormMounted(el$) {
  selected_color.value = props.color || el$.data.color || (props.active_index >= 0 ? block_colors.value[props.active_index]?.hex : null);
  update_color(el$, selected_color.value);
}
function handleCustomColor(event, el$) {
  is_custom_color.value = true;
  selected_color.value = event.target.value;
  update_color(el$, event.target.value);
}

function onSelect(color) {
  is_custom_color.value = false;
  selected_color.value = color;
}
</script>

<template>
  <div :class="wrapper_class">
    <SelectElement
      :name="name"
      :value-prop="value_prop"
      label-prop="name"
      :items="color_list"
      :native="false"
      :can-deselect="false"
      :can-clear="false"
      :limit="10"
      :label="$t('Color')"
      :placeholder="$t('Choose color')"
      :disabled="is_disabled"
      :add-classes="{
        SelectElement: {
          select: {
            dropdown: ['scrollbar'],
          },
        },
      }"
      v-bind="additional_options"
      @mounted="onFormMounted"
      @select="onSelect"
    >
      <template v-if="has_custom_color_picker" #before-list="{ el$ }">
        <div class="p-3 border-b hover:bg-gray-200">
          <label for="custom_block_color" class="flex items-center gap-2">
            <div
              class="h-[10px] w-[10px] rounded-full"
              :style="{ background: 'linear-gradient(90deg, #FD7C7C 0%, #FDAA7C 8.33%, #FDE17C 20.31%, #F3FD7C 29.69%, #A5FD7C 39.06%, #7CFD91 48.44%, #7CFDDE 56.77%, #7CB7FD 64.58%, #9E7CFD 75%, #FA7CFD 83.85%, #FD7C9B 93.75%)' }"
            />
            <span class="text-sm font-semibold text-primary-700">
              {{ $t('Custom Color') }}
            </span>
          </label>
          <input
            id="custom_block_color" :value="selected_color" type="color" class="invisible absolute"
            @input="(event) => handleCustomColor(event, el$)"
          >
        </div>
      </template>
      <template #single-label="{ value }">
        <div class="flex gap-2 items-center absolute left-0 pl-3">
          <IconHawkFilledCircle class="h-[10px] w-[10px]" :style="{ color: value.hex }" />
          <span class="text-sm font-medium text-gray-700">
            {{ is_custom_color || value.name === "Custom Color" ? value.hex : value.name }}
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex gap-2 items-center justify-start">
          <IconHawkFilledCircle class="h-[10px] w-[10px]" :style="{ color: option.hex }" />
          <span class="text-sm font-medium text-gray-700">{{ option.name }}</span>
        </div>
      </template>
    </SelectElement>
  </div>
</template>
