import { computed, ref, watch } from 'vue';

export default function VueFormUploader() {
  return {
    apply: 'FilePreview',
    setup(props, context, component) {
      const file = computed(() => component.el$.value?.model || Object.values(component.el$.value.data)[0] || {});

      function initialize() {
        if (file.value && !file.value?.progress)
          file.value.progress = ref(0);

        if (file.value && !file.value?.error)
          file.value.error = ref(null);
      }

      watch(file, (new_val, old_val) => {
        if (!file.value?.progress)
          initialize();
      }, { immediate: true });

      const reupload = () => {
        component?.form$?.value?.uppy.retryUpload(file.value.uppy_id);
        file.value.error.value = null;
      };

      const progress = computed(() => file.value?.progress);
      const is_error = computed(() => file.value?.error);
      const is_uploading = computed(() => component?.form$?.value.uploading.value);
      const submitting = computed(() => component?.form$?.value?.busy);
      const download_file_url = url => url ? window.open(url, '_blank') : '';
      return {
        ...component,
        file,
        progress,
        submitting,
        is_error,
        is_uploading,
        download_file_url,
        reupload,
        numberToBytes(data) {
          const const_term = 1024;
          const bytes = data / const_term;
          if (!data)
            return '0KB';
          else if (bytes < const_term)
            return `${(bytes).toFixed(2)}KB`;
          else if (bytes < const_term ** 2)
            return `${(data / const_term ** 2).toFixed(2)}MB`;
          else if (bytes < const_term ** 3)
            return `${(data / const_term ** 3).toFixed(2)}GB`;
          else if (bytes < const_term ** 4)
            return `${(data / const_term ** 4).toFixed(2)}TB`;
          else
            return 'Please pass valid option';
        },
      };
    },
  };
}
