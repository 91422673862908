<script>
export default {
  name: 'ObjectElement',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        wrapper: '',
      },
    };
  },
};
</script>

<template>
  <tr>
    <slot />
  </tr>
</template>
