<script setup>
import { cloneDeep, isEqual } from 'lodash-es';

import { watch } from 'vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const $t = inject('$t');
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const graph_data = ref(null);
const dashboard_store = useDashboardStore();
const forceUpdate = ref(0);
const no_data = ref(false);

async function getReports() {
  no_data.value = false;
  graph_data.value = null;
  loading.value = true;
  graph_data.value = props.data.data.csv_data.data;
  columns.value = graph_data.value[0].map((v) => {
    return {
      accessorKey: v,
      id: v,
      header: v,
      size: '400',
    };
  });

  const data = cloneDeep(graph_data.value);
  const keys = data.shift();

  const activity_array = data.map((values) => {
    const obj = {};
    keys.forEach((key, index) => {
      obj[key] = values[index];
    });
    return obj;
  });

  activities.value = activity_array;
  loading.value = false;
}

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: props.data.data.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
    },
  });
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    await getReports();
    forceUpdate.value += 1;
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="activities?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="forceUpdate"
          :data="activities"
          :columns="columns"
          additional_row_classes="even:bg-gray-50"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          :container_class="`h-[${content_height || height}px]`"
          :show_menu_header="false"
          :show_column_borders="true"
          :striped="true"
          is_gapless
          cell_height="30px"
        />
      </div>
    </a>
  </div>
</template>
