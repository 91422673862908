<script setup>
import { useRoute, useRouter } from 'vue-router';

// composables
import { useDocumentActions } from '~/dms/composables/document-actions.composable';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDocumentStore } from '~/dms/store/document.store';

defineProps({
  options: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(['tabChange']);

const $t = inject('$t');

const route = useRoute();
const router = useRouter();

const document_actions = useDocumentActions({});

const auth_store = useAuthStore();
const document_store = useDocumentStore();

const state = reactive({
  open_group_by: false,
});

const is_internal = computed(() => document_store.is_internal);
const tab_items = [
  {
    label: $t('All files'),
    route: 'files-documents-all-files',
  },
  {
    label: $t('Shared with me'),
    route: 'files-documents-shared',
  },
  {
    label: $t('Archived'),
    route: 'files-documents-archived',
  },
  {
    label: $t('Trash'),
    route: 'files-documents-trash',
  },
];
const active_tab_title = computed(() => tab_items.find(item => item.route === route.name)?.label || '');
function onToggleInternal() {
  document_store.reset_files_folders();
  document_store.toggle_is_internal();
}

function switchActiveTab(route) {
  emit('tabChange');
  router.push({ name: route });
}
</script>

<template>
  <div>
    <hawk-button
      v-if="auth_store.is_internal_user"
      :type="is_internal ? 'default' : 'light'" class="mr-3 !ring-0" @click="onToggleInternal"
    >
      {{ $t('Internal') }}
    </hawk-button>
    <HawkMenu
      :has_bordered_trigger="true" additional_trigger_classes="!ring-0" class="mr-3" position="fixed"
      @open="state.open_group_by = true" @close="state.open_group_by = false"
    >
      <template #trigger>
        <div
          class="bg-white rounded-lg shadow-xs text-sm font-medium h-9 flex items-center justify-between px-3 w-44"
        >
          <div>
            <span class="text-gray-900 mr-3">
              <HawkText :content="active_tab_title" placement="top" class="text-gray-900 font-normal" />
            </span>
          </div>
          <IconHawkChevronUp v-if="state.open_group_by" class="w-[16px] h-[16px] text-gray-600" />
          <IconHawkChevronDown v-else class="w-[16px] h-[16px] text-gray-600" />
        </div>
      </template>
      <template #content="{ close }">
        <div class="w-44 text-sm">
          <div
            v-for="item in tab_items" :key="item.route"
            class="h-10 flex items-center px-2 hover:bg-gray-100 cursor-pointer rounded"
            @click="switchActiveTab(item.route); close()"
          >
            <span class="text-gray-900 mr-3">
              <HawkText :content="item.label" placement="top" class="text-gray-700 font-medium" />
            </span>
          </div>
        </div>
      </template>
    </HawkMenu>
  </div>
</template>
