<script setup>
import { useRouter } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';

const BASE_URL = 'https://sungenie.jio.com/dm';
const router = useRouter();
const $toast = inject('$toast');
onMounted(() => {
  getTokenData();
});

const common_store = useCommonStore();
const active_asset = common_store.active_asset;
const $services = inject('$services');
const state = reactive({
  is_loading: false,
  is_error: false,
  token_data: null,
});
const redirect_url = computed(() => {
  return state?.token_data?.data?.featureList?.find(feature => feature.name === 'solarDashboardv4')?.redirectUrl || 'solar-v2';
});
const source_url = computed(() => {
  if (!state?.token_data?.data?.accessToken?.token)
    return null;
  return `${BASE_URL}/${redirect_url.value}?token=${state?.token_data.data.accessToken.token}&sessionId=${state?.token_data?.data?.accessToken?.sessionId}`;
});
async function getTokenData() {
  try {
    state.is_loading = true;
    state.is_error = false;
    const response = await $services.users.get_eanalytix_token({
      body: {
        asset_uid: active_asset.uid,
        asset_code: active_asset.code,
      },
      toast: false,
    });
    state.token_data = response.data;
    state.is_loading = false;
    window.open(source_url.value, '_blank');
    setTimeout(() => {
      router.go(-1);
    }, 1000);
  }
  catch (e) {
    $toast({
      title: 'ERROR!',
      text: e.response.data.message,
      type: 'error',
    });
    state.is_error = true;
    state.is_loading = false;
  }
};
</script>

<template>
  <div class="h-[calc(100vh-64px)]">
    <div v-if="state.is_error">
      <HawkIllustrations type="no-permission" for="users" />
    </div>
    <hawk-loader v-else-if="state.is_loading || !source_url" />
  </div>
</template>
