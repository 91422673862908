<script setup>
import { onBeforeMount } from 'vue';
import InventoryTransactionTypes from '~/inventory/components/inventory-settings/inventory-transaction-types.vue';
import InventoryStockStatuses from '~/inventory/components/inventory-settings/inventory-stock-statuses.vue';
import InventoryWarehouseTypes from '~/inventory/components/inventory-settings/inventory-warehouse-types.vue';
import InventoryItemTypes from '~/inventory/components/inventory-settings/inventory-item-types.vue';
import InventoryVendors from '~/inventory/components/inventory-settings/inventory-vendors.vue';
import InventoryCustomFields from '~/inventory/components/inventory-settings/inventory-custom-fields.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { auth_store, route, router } = useCommonImports();
onBeforeMount(async () => {
  if (!auth_store.check_permission('modify_inventory_settings', route.params.asset_id))
    router.push({
      name: 'inventory-permission-denied',
    });
});
</script>

<template>
  <div class="max-w-[calc(100vw_-_80px)]">
    <div class="p-4">
      <InventoryTransactionTypes />
      <InventoryStockStatuses class="mt-10" />
      <InventoryWarehouseTypes class="mt-10" />
      <InventoryItemTypes class="mt-10" />
      <InventoryVendors class="mt-10" />
      <InventoryCustomFields class="mt-10" />
    </div>
  </div>
</template>
