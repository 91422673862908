<script setup>
import { capitalize } from 'lodash-es';
import { useRoute } from 'vue-router';
import FormTemplateDetailHeaderDescription from '~/forms/components/form-template-detail/form-template-detail-header-description.vue';
import FormTemplateIcon from '~/forms/atoms/form-template-icon.vue';

import EditData from '~/forms/atoms/form-text-input-update.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const $t = inject('$t');
const $toast = inject('$toast');

const form_template_detail_store = useFormTemplateDetailStore();

const route = useRoute();
const original_icon = computed(() => form_template_detail_store.form_template_detail.icon);

const current_icon = ref(original_icon);
// updates the icon for form header
async function templateUpdate(icon) {
  const request = {
    icon: current_icon.value === icon ? null : icon,
  };
  try {
    const { data } = await form_template_detail_store.update_form_details({
      body: request,
    });
    icon_name.value = data?.form?.icon;
  }
  catch (e) {
  }
}
// updates form name and description
async function updateForm(key, value) {
  try {
    await form_template_detail_store.update_form_details({
      body: { [key]: value },
    });
    form_template_detail_store.form_template_track_events(`${capitalize(key)} updated`);
  }
  catch (e) {
  }
}
async function updateFormName(value) {
  updateForm('name', value);
}
async function updateFormDescription(value) {
  updateForm('description', value);
}
const condition_for_routes = computed(() => route.name.split('-').includes('settings'));
</script>

<template>
  <div class="flex">
    <div v-if="form_template_detail_store.form_template_detail.name" class="pl-2 grow">
      <slot />
      <div class="flex items-center gap-2">
        <FormTemplateIcon
          v-if="condition_for_routes && form_template_detail_store?.form_template_detail?.icon"
          :icon_id="form_template_detail_store?.form_template_detail?.icon"
          class="h-5 w-5"
        />
        <!-- Form name and description editable -->
        <EditData
          :data="form_template_detail_store?.form_template_detail?.name" :can_modify="form_template_detail_store.form_template_detail.can_modify_template ? !condition_for_routes : false"
          text_default="name" :save="updateFormName"
        >
          <div v-tippy="{ content: FormTemplateDetailHeaderDescription, maxWidth: 'none' }">
            <icon-hawk-info-circle class="ml-1 relative top-0.5 w-4 h-4 text-gray-600" />
          </div>
        </EditData>
      </div>
    </div>
  </div>
</template>
