<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep } from 'lodash-es';
import Advanced from '~/forms/components/form-workflows/sidebar/approval-block/approval-block-advanced.vue';
import Basic from '~/forms/components/form-workflows/sidebar/approval-block/approval-block-basic.vue';

const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['close']);

const $t = inject('$t');

const form$ = ref(null);
const form_data = ref({});
const email_forms = ref({});
const active_tab = ref('basic');
const default_form = ref({
  name: 'Approval Block',
  description:
                'The approval block would enable the user to approve or reject any block in the workflow',
  days: 1,
  is_template: true,
  is_sequential: false,
  members: {},
  show_use_field: false,
  condition: 'AND',
  min_approvals: 1,
  reassign: {
    is_enabled: false,
    wait: 1,
    members: {},
    show_use_field: false,
  },
  approval_email: {
    subject: '',
    body: '',
    customize_email: false,
  },
  comments_required: false,
  escalations: {
    is_enabled: false,
    is_sequential: false,
    email: {
      subject: '',
      body: '',
      customize_email: false,
    },
    wait: 1,
    members: {},
    condition: 'AND',
    min_approvals: 1,
  },
  reminders: {
    is_enabled: false,
    interval: 1,
    count: 1,
    wait: 1,
    sent: 1,
    email: {
      subject: '',
      body: '',
      customize_email: false,
    },
    mobile: {
      members: [],
      body: '',
    },
  },
  auto_closing: {
    is_enabled: false,
    wait: 1,
    type: 'OUTCOME',
    outcome: 'APPROVE',
  },
  configure_buttons: {
    is_enabled: false,
    approve: 'Approve',
    reject: 'Reject',
  },
});

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : default_form.value;
});

function resetForm(key) {
  form_data.value[key] = {
    ...default_form.value[key],
    is_enabled: form_data.value[key].is_enabled,
    ...(key === 'escalations' && {
      members: [],
    }),
  };
  if (key === 'configure_buttons') {
    form_data.value[key] = {
      ...form_data.value[key],
      approve_text: form_data.value[key].approve,
      reject_text: form_data.value[key].reject,
    };
  }

  if (email_forms.value[key])
    email_forms.value[key] = form_data.value[key].email;
  // Needs to refactored
  if (key === 'escalations') {
    setTimeout(async () => {
      await form$.value.form$.el$('escalations').el$.validateChildren();
    }, 1);
  }
}

function numberInputValidator({ name, e, key = null }) {
  if (e.target.value) {
    const value = Number(e.target.value);
    let value_to_load = 1;
    if (value >= value_to_load && Number.isInteger(value))
      value_to_load = value;
    if (key)
      form_data.value[key][name] = value_to_load;
    else
      form_data.value[name] = value_to_load;
  }
}

function updateEmail(data) {
  email_forms.value[data.key] = {
    ...email_forms.value[data.key],
    ...data.value,
  };
  if (!email_forms.value[data.key].customize_email) {
    email_forms.value[data.key].body = '';
    email_forms.value[data.key].subject = '';
  }
}

async function save() {
  const payload = cloneDeep({ ...default_data.value, ...form_data.value });
  payload.configure_buttons = {
    is_enabled: payload.configure_buttons.is_enabled,
    approve: payload.configure_buttons.approve_text,
    reject: payload.configure_buttons.reject_text,
  };

  payload.members = payload.members.reduce((acc, curr, i) => {
    acc[i + 1] = curr;
    return acc;
  }, {});
  payload.escalations.members = payload.escalations.members.reduce((acc, curr, i) => {
    acc[i + 1] = curr;
    return acc;
  }, {});
  payload.approval_email = email_forms.value.approval_email;
  payload.escalations.email = email_forms.value.escalations;
  payload.reminders.email = email_forms.value.reminders;
  if (props.submit_function)
    await props.submit_function(payload);
}

function init() {
  form_data.value = default_data.value;
  form_data.value.members = Object.values(form_data.value.members);
  form_data.value.escalations.members = Object.values(form_data.value.escalations.members);
  form_data.value.reminders.is_once = form_data.value.reminders.is_once ?? false;
  form_data.value.reminders.frequency = form_data.value.reminders.frequency ?? 0;
  form_data.value.reminders.from = form_data.value.reminders.from ?? 'due_date';
  form_data.value.reminders.when = form_data.value.reminders.when ?? 'before';
  email_forms.value = {
    approval_email: default_data.value.approval_email,
    escalations: default_data.value.escalations.email,
    reminders: default_data.value.reminders.email,
  };
  if (!form_data.value.configure_buttons)
    form_data.value.configure_buttons = default_form.value.configure_buttons;
  form_data.value.configure_buttons = {
    ...form_data.value.configure_buttons,
    approve_text: form_data.value?.configure_buttons?.approve,
    reject_text: form_data.value?.configure_buttons?.reject,
  };
}

init();
</script>

<template>
  <Vueform
    ref="form$"
    v-model="form_data" :sync="true" size="sm" :should_validate_on_mount="true" :display-errors="false"
    :columns="{
      lg: {
        container: 12,
        label: 3,
        wrapper: 12,
      },
    }"
    :messages="{
      required: $t('This field is required'),
    }"
    :endpoint="save"
    @change="$emit('updateIsFormDirty', true)"
  >
    <div class="col-span-12" :class="{ 'pointer-events-none': is_disabled }">
      <div class="flex justify-between items-start mb-10">
        <div class="w-80">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Approval Block') }}
          </div>
          <div class="text-xs text-gray-600">
            {{ $t('Assign team members to review & approve to proceed to the next step in the workflow.') }}
          </div>
        </div>
        <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
          <IconHawkXClose class="text-gray-500" />
        </HawkButton>
      </div>
      <HawkPageHeaderTabs
        class="mb-6 pointer-events-auto"
        :tabs="[
          {
            uid: 'basic',
            label: $t('Basic'),
          },
          {
            uid: 'advanced',
            label: $t('Advanced'),
          },
        ]"
        :active_item="active_tab"
        @tab-click="active_tab = $event.uid"
      />
      <Basic
        v-show="active_tab === 'basic'"
        :step_number="step_number"
        :form_data="form_data"
        :is_disabled="is_disabled"
        :email_forms="email_forms"
        @change-member="form_data.members = $event"
        @update-email="updateEmail($event)"
        @validate-number-input="numberInputValidator"
      />
      <Advanced
        v-show="active_tab !== 'basic'"
        :form$="form$"
        :step_number="step_number" :form_data="form_data"
        :default_data="default_data"
        :email_forms="email_forms" @change-member="form_data.escalations.members = $event"
        @update-email="updateEmail($event)" @reset="resetForm"
        @validate-number-input="numberInputValidator"
        @update-configure-buttons="form_data.configure_buttons = $event"
      />
      <hr class="mb-4">
      <div v-if="!is_disabled" class="flex justify-end">
        <ButtonElement
          :button-label="$t('Cancel')"
          class="mr-3"
          :secondary="true"
          @click="$emit('close')"
        />
        <ButtonElement
          :button-label="$t('Save')"
          :submits="true"
        />
      </div>
    </div>
  </Vueform>
</template>
