<script setup>
import { computed, inject } from 'vue';
import { useModal } from 'vue-final-modal';

import { useRoute, useRouter } from 'vue-router';
import sheetCompare from '~/plans/components/sheet-compare.vue';
import sheetDropdown from '~/plans/components/sheet-dropdown.vue';
import sheetSettings from '~/plans/components/sheet-settings.vue';
import sheetVersionDropdown from '~/plans/components/sheet-version-dropdown.vue';
import sheetToolIcons from '~/plans/components/sheet-tool-icons.vue';
import taskContextMenu from '~/plans/components/context-menu/task-context-menu.vue';
import formContextMenu from '~/plans/components/context-menu/form-context-menu.vue';
import commentContextMenu from '~/plans/components/context-menu/comment-context-menu.vue';
import locationContextMenu from '~/plans/components/context-menu/location-context-menu.vue';
import useEmitter from '~/common/composables/useEmitter';
import { COLORS, CUSTOM_ANNOTATION_TYPES, PLANS_PERMISSION, TOOL_EVENTS } from '~/plans/constants';
import { useDocumentTools } from '~/plans/composables/useDocumentTools';
import { toggleFullscreen } from '~/plans/utils/helper';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useChatStore } from '~/common/stores/chat.store';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useFormsStore } from '~/forms/store/forms.store';

import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import NewForm from '~/forms/components/new-form/new-form-modal.vue';
import { useAnnotationsStore } from '~/plans/store/annotations.store';
import { useSheetStore } from '~/plans/store/sheet.store';
import { useAnnotationsEdit } from '~/plans/composables/useAnnotationsEdit';
import { useCustomAnnotationHandlers } from '~/plans/composables/useCustomAnnotationHandlers';
import { useFormPermissions } from '~/forms/composables/form-permissions.composable';
import { FORM_PERMISSIONS } from '~/forms/constants';
import { usePusherStore } from '~/common/stores/pusher.store.js';

const props = defineProps({
  active_tool: {
    type: String,
  },
  is_fullscreen_mode: {
    type: Boolean,
    default: false,
  },
  custom_annot_context_menu: {
    type: Object,
    required: true,
  },
  show_annotation_edit: {
    type: Boolean,
    default: false,
  },
  show_comment_back_drop: {
    type: Boolean,
    default: false,
  },
  text_annots_selected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onToggleFullscreen', 'onToggleBackdrop', 'onUpdateCustomAnnot']);

const $t = inject('$t');
const document_viewer_instance = inject('document-viewer-instance');
const viewer_container_elem = inject('viewer-container-elem');

const emitter = useEmitter();
const route = useRoute();
const router = useRouter();
const auth_store = useAuthStore();
const annotations_store = useAnnotationsStore();
const chat_store = useChatStore('plans-comment');
const sheet_store = useSheetStore();
const tasks_store = useTasksStore('plans-task');
const forms_store = useFormsStore('plans-form');
const { syncAndGetButtonState } = useFormPermissions();

const current_organization = auth_store.current_organization;
const has_modify_annotations_role = auth_store.check_permission(PLANS_PERMISSION.MODIFY_ANNOTATIONS, route.params.asset_id);
const has_create_annotations_role = auth_store.check_permission(PLANS_PERMISSION.CREATE_ANNOTATIONS, route.params.asset_id);
const annotation_manager = document_viewer_instance.value.getAnnotationManager();
const display_mode = document_viewer_instance.value
  .getDisplayModeManager()
  .getDisplayMode();

const {
  edit_normal_annotation_tools,
  edit_text_annotation_tools,
} = useAnnotationsEdit(document_viewer_instance);
const { clear_route, show_custom_comment_annotation, getContexPositionStyle, post_custom_annotation } = useCustomAnnotationHandlers({ route, router });

const {
  zoom_in,
  zoom_out,
  undo,
  get_selected_annotations,
  delete_annotations,
  get_color_format,
  set_annotations_style,
  get_annotation_by_id,
  create_and_add_stamp_annotation,
  update_comment_annotation,
  pan,
} = useDocumentTools(document_viewer_instance);
const zoom_tools = computed(() => {
  return [
    {
      tooltip: $t('Zoom in'),
      icon: IconHawkPlus,
      event: TOOL_EVENTS.ZOOM_IN,
      action: zoom_in,
    },
    {
      tooltip: $t('Zoom out'),
      icon: IconHawkMinus,
      event: TOOL_EVENTS.ZOOM_OUT,
      action: zoom_out,
    },
    {
      tooltip: $t('Reset'),
      icon: IconHawkRefreshCcwOne,
      event: TOOL_EVENTS.RESET,
      action: () => {
        document_viewer_instance.value.setFitMode(document_viewer_instance.value.FitMode.FitPage);
      },
    },
  ];
});

const drawing_tools = computed(() => {
  return [
    {
      tooltip: $t('Freehand'),
      icon: IconHawkBrushHeavy,
      event: TOOL_EVENTS.FREEHAND,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.FREEHAND);
      },
    },
    {
      tooltip: $t('Line'),
      icon: IconHawkSlashDivider,
      event: TOOL_EVENTS.CREATE_LINE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_LINE);
      },
    },
    {
      tooltip: $t('Arrow'),
      icon: IconHawkArrowDownRight,
      event: TOOL_EVENTS.CREATE_ARROW,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_ARROW);
      },
    },
    {
      tooltip: $t('Polyline'),
      icon: IconHawkPolyline,
      event: TOOL_EVENTS.CREATE_POLYLINE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_POLYLINE);
      },
    },
  ];
});

const shape_tools = computed(() => {
  return [
    {
      tooltip: $t('Rectangle'),
      icon: IconHawkSquare,
      event: TOOL_EVENTS.CREATE_RECTANGLE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_RECTANGLE);
      },
    },
    {
      tooltip: $t('Ellipse'),
      icon: IconHawkCircle,
      event: TOOL_EVENTS.CREATE_ELLIPSE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_ELLIPSE);
      },
    },
    {
      tooltip: $t('Cloud'),
      icon: IconHawkCloudOne,
      event: TOOL_EVENTS.CREATE_CLOUD,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_CLOUD);
      },
    },
    {
      tooltip: $t('Polygon'),
      icon: IconHawkPentagon,
      event: TOOL_EVENTS.CREATE_POLYGON,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_POLYGON);
      },
    },
  ];
});

const measure_tools = computed(() => {
  return [
    {
      tooltip: $t('Calibrate'),
      icon: IconHawkCalibrate,
      event: TOOL_EVENTS.CALIBRATE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CALIBRATE);
      },
    },
    {
      tooltip: $t('Distance'),
      icon: IconHawkSpaceWidthOne,
      event: TOOL_EVENTS.DISTANCE,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.DISTANCE);
      },
    },
    {
      tooltip: $t('Area'),
      icon: IconHawkLineChartDownFour,
      event: TOOL_EVENTS.AREA,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.AREA);
      },
    },
  ];
});

const annotation_tools = computed(() => {
  return [
    {
      tooltip: $t('Drawing'),
      icon: IconHawkBrushOne,
      event: 'drawing',
      sub_tools: drawing_tools.value,
    },
    {
      tooltip: $t('Shape'),
      icon: IconHawkHexagonOne,
      event: 'shape',
      sub_tools: shape_tools.value,
    },
    {
      tooltip: $t('Text'),
      icon: IconHawkTypeOne,
      event: TOOL_EVENTS.CREATE_TEXT,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_TEXT);
      },
    },
    {
      tooltip: $t('Highlight'),
      icon: IconHawkMagicWandOne,
      event: TOOL_EVENTS.CREATE_HIGHLIGHT,
      action: () => {
        emitter.emit('toolActivated', TOOL_EVENTS.CREATE_HIGHLIGHT);
      },
    },
    {
      tooltip: $t('Measure'),
      icon: IconHawkRuler,
      event: 'measure',
      sub_tools: measure_tools.value,
    },
    {
      tooltip: $t('Color'),
      icon: IconHawkFilledCircle,
      container_class: 'h-56 overflow-hidden scrollbar',
      color: annotations_store.annotations_edit_config.outline_color.hex,
      sub_tools: [
        ...COLORS.map(color => ({
          tooltip: $t(color.name),
          icon: IconHawkFilledCircle,
          color: color.hex,
          action: () => {
            annotations_store.annotations_edit_config.outline_color = color;

            const selected_annotations = get_selected_annotations();
            selected_annotations.length && set_annotations_style(selected_annotations, {
              StrokeColor: get_color_format(color.rgb),
              TextColor: get_color_format(color.rgb),
            });

            // To reset the document-tool with the updated color
            const prev_selected_tool = props.active_tool;
            emitter.emit('toolActivated', '');
            emitter.emit('toolActivated', prev_selected_tool);
          },
        })),
      ],
    },
  ];
});

const feature_tools = computed(() => {
  return [
    {
      tooltip: $t('Undo'),
      icon: IconHawkReverseLeft,
      event: TOOL_EVENTS.UNDO,
      disabled: !has_modify_annotations_role,
      action: () => {
        if (has_modify_annotations_role)
          undo();
      },
    },
    {
      tooltip: $t('Pan'),
      icon: IconHawkHandCopy,
      event: TOOL_EVENTS.PAN,
      action: () => emitter.emit('toolActivated', TOOL_EVENTS.PAN),
    },
    {
      tooltip: $t('Edit'),
      icon: IconHawkEditTwo,
      event: TOOL_EVENTS.EDIT,
      disabled: !has_modify_annotations_role,
      action: () => has_modify_annotations_role && emitter.emit('toolActivated', TOOL_EVENTS.EDIT),
    },
    {
      tooltip: props.is_fullscreen_mode ? $t('Collapse') : $t('Fullscreen'),
      icon: props.is_fullscreen_mode ? IconHawkMinimizeOne : IconHawkExpandOne,
      event: TOOL_EVENTS.FULLSCREEN,
      action: () => {
        emit('onToggleFullscreen', !props.is_fullscreen_mode);
        toggleFullscreen(viewer_container_elem.value);
        emitter.emit('toolActivated', TOOL_EVENTS.FULLSCREEN);
      },
    },
    {
      tooltip: $t('Comment'),
      icon: IconHawkMessageDotsCircle,
      event: TOOL_EVENTS.CREATE_COMMENT,
      disabled: !has_modify_annotations_role,
      action: () => has_modify_annotations_role && emitter.emit('toolActivated', TOOL_EVENTS.CREATE_COMMENT),
    },
    {
      tooltip: $t('Setting'),
      icon: IconHawkSettingsOne,
      event: TOOL_EVENTS.SETTING,
      container_class: 'w-64',
      action: () => {
        if (props.active_tool === TOOL_EVENTS.SETTING)
          emitter.emit('toolActivated', '');
        else
          emitter.emit('toolActivated', TOOL_EVENTS.SETTING);

        pan();
      },
    },
  ];
});

const form_cta_state = syncAndGetButtonState();

const creation_tools = computed(() => {
  const sub_tools = [
    {
      tooltip: $t('Create task'),
      icon: IconHawkFileCheckThree,
      event: TOOL_EVENTS.CREATE_TASK,
      action: () => { emitter.emit('toolActivated', TOOL_EVENTS.CREATE_TASK); },
    },
  ];

  auth_store.check_permission(FORM_PERMISSIONS.V2_CREATE_FORMS, route.params.asset_id) && sub_tools.push({
    tooltip: $t('Create form'),
    icon: IconHawkClipboardPlus,
    event: TOOL_EVENTS.CREATE_FORM,
    disabled: form_cta_state.value.state !== 'enabled',
    action: () => { emitter.emit('toolActivated', TOOL_EVENTS.CREATE_FORM); },
  });

  return [
    {
      tooltip: $t('Create'),
      icon: IconHawkMarkerPinOne,
      event: 'create',
      sub_tools,
    },
  ];
});

function closeCommentContext() {
  clear_route();
  annotation_manager.deselectAllAnnotations();

  emit('onUpdateCustomAnnot', {
    is_mouse_over: false,
    pos_x: 0,
    pos_y: 0,
    data: null,
    annotation: null,
    type: null,
  });
}

function onLocationContextClose() {
  const location_marker_annotation = document_viewer_instance.value.getAnnotationManager().getAnnotationsList()
    .find(annot => annot.Subject === CUSTOM_ANNOTATION_TYPES.LOCATION);

  delete_annotations([location_marker_annotation]);
  emitter.emit('toolActivated', '');
  annotations_store.reset_location_marker_config();
}
function onLocationContextConfirm() {
  const location_marker_annotation = document_viewer_instance.value.getAnnotationManager().getAnnotationsList()
    .find(annot => annot.Subject === CUSTOM_ANNOTATION_TYPES.LOCATION);

  const annotation_position = {
    page_number: location_marker_annotation.PageNumber,
    x: location_marker_annotation.getX(),
    y: location_marker_annotation.getY(),
  };

  if (props.active_tool === TOOL_EVENTS.CREATE_TASK) {
    const { open: open_new_task_modal, close: close_new_task_modal, patchOptions: patch_new_task_modal } = useModal({
      component: TaskForm,
      attrs: {
        modal_options: { teleportTo: '#viewer-container' },
      },
    });

    patch_new_task_modal({
      attrs: {
        onClose() {
          close_new_task_modal();
          emitter.emit('toolActivated', '');
          pan();
        },
        async on_submit(data) {
          await post_custom_annotation(CUSTOM_ANNOTATION_TYPES.TASK, data, annotation_position, create_and_add_stamp_annotation);
          emitter.emit('toolActivated', '');
          pan();
          close_new_task_modal();
        },
      },
    });
    open_new_task_modal();
  }
  if (props.active_tool === TOOL_EVENTS.CREATE_FORM) {
    const { open: open_new_form_modal, close: close_new_form_modal, patchOptions: patch_new_form_modal } = useModal({
      component: NewForm,
      attrs: {
        modal_options: { teleportTo: '#viewer-container' },
      },
    });

    patch_new_form_modal({
      attrs: {
        onClose() {
          close_new_form_modal();
          emitter.emit('toolActivated', '');
          pan();
        },
        async on_submit(data) {
          await post_custom_annotation(CUSTOM_ANNOTATION_TYPES.FORM, data, annotation_position, create_and_add_stamp_annotation);
          emitter.emit('toolActivated', '');
          pan();
          close_new_form_modal();
        },
      },
    });
    open_new_form_modal();
  }

  delete_annotations([location_marker_annotation]);
  annotations_store.reset_location_marker_config();
}

function deleteCommentAnnotation() {
  delete_annotations([get_annotation_by_id(props.custom_annot_context_menu.annotation.Id)]);

  emit('onUpdateCustomAnnot', {
    pos_x: 0,
    pos_y: 0,
    data: null,
    annotation: null,
    type: null,
    is_mouse_over: false,
  });

  clear_route();
}

watch(
  () => route.query.sheet_comment,
  async (comment_id) => {
    if (comment_id) {
      emit('onToggleBackdrop', true);

      if (comment_id.includes('annot_')) {
        const annot_id = comment_id.split('_')[1];
        const annot = get_annotation_by_id(annot_id);
        !annot && clear_route(); // when annot_ is present and page is refreshed
        annotation_manager.selectAnnotation(annot);
        const window_coordinates = display_mode.pageToWindow({
          x: annot.getX(),
          y: annot.getY(),
        }, 1);
        const scrollViewElement = document_viewer_instance.value.getScrollViewElement();
        const scroll_top = scrollViewElement.scrollTop;
        const scroll_left = scrollViewElement.scrollLeft;

        emit('onUpdateCustomAnnot', {
          pos_x: window_coordinates.x - scroll_left,
          pos_y: window_coordinates.y - scroll_top,
          data: null,
          annotation: annot,
          type: CUSTOM_ANNOTATION_TYPES.COMMENT,
          is_mouse_over: true,
        });
      }
      else {
        const annotations = annotation_manager.getAnnotationsList();
        const comment_annotation = annotations.find(
          annotation => annotation.Subject === CUSTOM_ANNOTATION_TYPES.COMMENT && annotation.getCustomData('uid') === comment_id,
        );
        emit('onUpdateCustomAnnot', {
          is_mouse_over: true,
        });
        show_custom_comment_annotation(comment_annotation, display_mode, props.custom_annot_context_menu);
      }
    }

    else if (!comment_id) {
      emit('onToggleBackdrop', false);
      logger.log('No Comment ID');
    }
  }, { immediate: true },
);

watch(() => chat_store.comments, (comments) => {
  const deleted_annotation = [];
  const comment_annotations = annotation_manager.getAnnotationsList().filter(annotation => annotation.Subject === CUSTOM_ANNOTATION_TYPES.COMMENT);
  comments.forEach((comment) => {
    const commentAnnotation = comment_annotations.find((annotation) => {
      return annotation.getCustomData('uid') === comment.id;
    });
    if (comment.type !== 'deleted') {
      const { X, Y, PageNumber } = comment.annotation_position;
      const options = {
        uid: comment.id,
        x: X,
        y: Y,
        page_number: PageNumber,
        type: CUSTOM_ANNOTATION_TYPES.COMMENT,
        status: comment.status,
        comment_count: comment.reply_count + 1,
      };

      if (commentAnnotation)
        update_comment_annotation(commentAnnotation, comment);
      else
        create_and_add_stamp_annotation(options);
    }
    else {
      if (commentAnnotation)
        deleted_annotation.push(commentAnnotation);
    }
  });
  delete_annotations(deleted_annotation, { source: 'watcher' });
}, { immediate: true });

watch(() => tasks_store.tasks(), (tasks) => {
  const task_annotations = annotation_manager.getAnnotationsList().filter(annotation => annotation.Subject === CUSTOM_ANNOTATION_TYPES.TASK);
  delete_annotations(task_annotations, { source: 'watcher' });
  tasks.forEach((task) => {
    const { x, y, page_number } = task.properties.annotation_position;
    const options = {
      uid: task.uid,
      x,
      y,
      page_number,
      type: CUSTOM_ANNOTATION_TYPES.TASK,
      status: task.status,
    };
    create_and_add_stamp_annotation(options);
  });
}, { immediate: true });

watch(() => forms_store.forms_map, () => {
  const forms = forms_store.forms;
  const form_annotations = annotation_manager.getAnnotationsList().filter(annotation => annotation.Subject === CUSTOM_ANNOTATION_TYPES.FORM);
  delete_annotations(form_annotations, { source: 'watcher' });
  forms.forEach((form) => {
    const { x, y, page_number } = form.properties.annotation_position;
    const options = {
      uid: form.uid,
      x,
      y,
      page_number,
      type: CUSTOM_ANNOTATION_TYPES.FORM,
      status: form?.status?.submission_status || 'open',
    };
    create_and_add_stamp_annotation(options);
  });
}, { immediate: true, deep: true });

watchEffect(() => {
  const pusher_store = usePusherStore();
  if (pusher_store.is_initialized)
    tasks_store.subscribe_pusher({
      organization: current_organization?.uid,
      ...(route.params.asset_id ? { asset: route.params.asset_id } : {}),
    }, {
      element: sheet_store.sheet.element.uid,
    });
}, { flush: 'post' });
</script>

<template>
  <sheet-tool-icons class="z-1 absolute left-4 top-4 flex flex-col" :tools="zoom_tools" :active_tool="active_tool" />
  <div v-if="!sheet_store.is_compare_view">
    <div class="z-1 absolute left-16 top-4 h-9 text-sm font-medium flex items-center gap-2 rounded-md">
      <sheet-version-dropdown />
    </div>
    <sheet-dropdown class="z-1 absolute right-4 bottom-4" />
    <div class="z-1 absolute right-4 top-4 flex gap-2">
      <template v-if="show_annotation_edit">
        <sheet-tool-icons :tools="text_annots_selected ? edit_text_annotation_tools : edit_normal_annotation_tools" :active_tool="active_tool" :horizontal_icons="true" />
      </template>
      <template v-else>
        <sheet-tool-icons v-if="has_create_annotations_role" :tools="creation_tools" :active_tool="active_tool" :horizontal_icons="true" />
        <sheet-tool-icons v-if="has_create_annotations_role" :tools="annotation_tools" :active_tool="active_tool" :horizontal_icons="true" />
        <sheet-tool-icons :tools="feature_tools" :active_tool="active_tool" :horizontal_icons="true" />
      </template>
    </div>
    <div class="z-1 absolute right-4 top-14">
      <KeepAlive>
        <sheet-settings v-if="active_tool === TOOL_EVENTS.SETTING" />
      </KeepAlive>
    </div>
  </div>
  <div v-if="sheet_store.is_compare_view" class="z-1 absolute right-4 top-4 compare-widget">
    <sheet-compare />
  </div>
  <div
    v-if="props.custom_annot_context_menu.type === CUSTOM_ANNOTATION_TYPES.TASK"
    class="z-1 absolute" :style="{ top: `${props.custom_annot_context_menu.pos_y}px`, left: `${props.custom_annot_context_menu.pos_x}px` }"
    @mouseover="emit('onUpdateCustomAnnot', {
      is_mouse_over: true,
    })"
    @mouseout="emit('onUpdateCustomAnnot', {
      is_mouse_over: false,
    })"
  >
    <task-context-menu
      :style="{ ...getContexPositionStyle(props.custom_annot_context_menu) }"
      :task="props.custom_annot_context_menu.data"
    />
  </div>
  <div
    v-if="props.custom_annot_context_menu.type === CUSTOM_ANNOTATION_TYPES.FORM"
    class="z-1 absolute" :style="{ top: `${props.custom_annot_context_menu.pos_y}px`, left: `${props.custom_annot_context_menu.pos_x}px` }"
    @mouseover="emit('onUpdateCustomAnnot', {
      is_mouse_over: true,
    })"
    @mouseout="emit('onUpdateCustomAnnot', {
      is_mouse_over: false,
    })"
  >
    <form-context-menu
      :style="{ ...getContexPositionStyle(props.custom_annot_context_menu) }"
      :form="props.custom_annot_context_menu.data"
    />
  </div>
  <div
    v-if="annotations_store.location_marker_config.is_visible && !sheet_store.is_compare_view"
    class="z-1 absolute" :style="{ right: `${4}px`, bottom: `${4}px` }"
  >
    <location-context-menu :marker_type="active_tool === TOOL_EVENTS.CREATE_FORM ? 'form' : 'task'" @close="onLocationContextClose" @confirm="onLocationContextConfirm" />
  </div>
  <Teleport to="#viewer-container">
    <div
      v-if="props.custom_annot_context_menu.type === CUSTOM_ANNOTATION_TYPES.COMMENT"
      class="z-1 absolute" :style="{ top: `${props.custom_annot_context_menu.pos_y}px`, left: `${props.custom_annot_context_menu.pos_x}px` }"
      :class="{ 'z-2': props.show_comment_back_drop }"
    >
      <comment-context-menu
        :style="{ ...getContexPositionStyle(props.custom_annot_context_menu) }"
        :annotation="props.custom_annot_context_menu.annotation"
        :comment="props.custom_annot_context_menu.data"
        @deleteAnnot="deleteCommentAnnotation"
        @closeContext="closeCommentContext"
      />
    </div>
  </Teleport>
  <div
    v-if="props.show_comment_back_drop" class="absolute top-0 left-0 h-[calc(100%+1%)] w-[calc(100%+0.5%)] bg-black z-1 opacity-10 rounded-md"
    @wheel.stop
    @mousedown.stop
    @mousemove.stop
    @keydown.stop
    @click="emit('onToggleBackdrop', false); annotation_manager.deselectAllAnnotations()"
  />
</template>

<style lang="scss" scoped>
.compare-widget {
  width: calc(100% - (2rem + 3.125rem));
}

.annotation-edit-widget {
  height: calc(100% - (2rem + 3rem));
}
</style>
