<script setup>
import { useModal } from 'vue-final-modal';
import TransmittalWorkflowBlockEditPopup from '~/dms/components/transmittals/workflow/graph/transmittal-workflow-block-edit-popup.vue';

// Steps

const props = defineProps({
  steps: {
    type: Object,
  },
  published: {
    type: Boolean,
  },
  outcome_strategy_ref: {
    type: Object,
  },
  disabled: {
    type: Boolean,
  },
});

const steps = computed(() => props.steps);

const existing_members_in_workflow = computed(() => steps.value.map(step => step.sub_steps || []).flat().map(sub_step => sub_step.members).flat());

provide('existing_members_in_workflow', existing_members_in_workflow);

function addStep(index) {
  const { open: openEditModal, close: closeEditModal } = useModal({
    component: TransmittalWorkflowBlockEditPopup,
    attrs: {
      existing_members: existing_members_in_workflow.value,
      onClose() {
        closeEditModal();
      },
      async submit(data, form) {
        steps.value.splice(index, 0, {
          index,
          type: 'single',
          condition: form.data.condition,
          on_reject: form.data.on_reject || 'continue',
          active: true,
          min_approvals: form.data.min_approvals,
          sub_steps: [
            {
              sub_index: 1,
              name: form.data.name,
              members: form.data.members.map(user => user.uid || user),
              condition: form.data.condition,
              min_approvals: +form.data.min_approvals,
            },
          ],
          duration: 1,
        });
        closeEditModal();
      },
    },
  });
  openEditModal();
}

function removeStep(index) {
  return steps.value.splice(index, 1);
}
// --- End of steps
</script>

<template>
  <div class="bg-gray-50 mt-10 rounded">
    <div class="p-4 text-md font-semibold">
      {{ $t('Workflow builder') }}
    </div>
    <div class="grid grid-flow-col-dense auto-cols-min h-[500px] overflow-x-auto p-20 scrollbar">
      <div class="flex items-center justify-center">
        <div class="flex-none rounded-full bg-success-500 w-16 h-16 text-white relative ">
          <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Start
          </div>
        </div>
        <div class="flex-none arrow" />
      </div>
      <div v-for="(step, index) of steps" :key="index" :name="step.index || index" class="flex flex-none items-center justify-center">
        <transmittal-workflow-graph-step
          :step="step"
          :visible_options="{
            skip_step: !(steps.length === 1 && step.sub_steps.length === 1) && published,
            edit: true,
            delete: !(steps.length === 1 && step.sub_steps.length === 1) && !published,
          }"
          :is_final="(steps.length === index + 1) && outcome_strategy_ref?.data?.outcome_strategy === 'final'"
          :published="published"
          :disabled="disabled"
          @delete="removeStep(index)"
        />
        <div class="arrow relative">
          <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div v-if="!published" class="rounded-full cursor-pointer bg-gray-50" @click="addStep(+index + 1)">
              <IconHawkPlusCircle class="w-[24px] h-[24px]" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <div class="flex-none rounded-full bg-warning-500 w-16 h-16 text-white relative ">
          <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            End
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.arrow {
  display:flex;
  border-bottom:1px solid #344054;
  width:82px;
  height:1px;
  align-items:center;
  justify-content: flex-end;
  &::after{
    content:'';
    border: solid #344054;
    border-width: 0 1px 1px 0;
    display: inline-block;
    position:relative;
    padding:3px;
    left:-1px;
    top:0.5px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
</style>
