<script setup>
const props = defineProps(['option', 'tag']);
</script>

<template>
  <div>
    <HawkAssetName :uid="props.option.asset" />
    <div>
      {{ props.option.name }}
    </div>
  </div>
</template>
