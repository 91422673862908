<script setup>
import { keyBy } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  warehouse_id: { type: String, default: '' },
  instances: { type: Array, default: () => [] },
  on_save: { type: Function, default: null },
});
const emit = defineEmits(['close', 'save']);
const { route, common_store, $services, $t } = useCommonImports();
const inventory_store = useInventoryStore();

const form$ = ref(null);
const is_loading = ref(false);
const instances_data = ref([]);

const instances = computed(() => {
  const instances = [];

  const getChildren = (instance) => {
    instances.push(instance);
    if (instance?.children?.length)
      instance.children.forEach(child_instance => getChildren(child_instance));
  };

  common_store.get_asset_meta(route.params.asset_id, 'instances').forEach((instance) => {
    getChildren(instance);
  });
  return instances;
});
const instances_map_by_name = computed(() => keyBy(instances.value, 'name'));
const check_invalid_input = computed(() => {
  const instance_name = form$.value?.data?.name;
  if (!instance_name)
    return { disabled: true, message: $t('Block name is required') };
  if (instances_data.value.map(instance => instance.instance_name).includes(instance_name))
    return { disabled: true, message: $t('Block already exists') };
  return { disabled: false, message: '' };
});

function addInstance() {
  const instance_name = form$.value?.data?.name;
  if (instance_name) {
    const instance = instances_map_by_name.value[instance_name];
    instances_data.value.push({
      instance_uid: instance?.uid || instance_name,
      instance_name: instance?.name || instance_name,
    });
    form$.value.update({ name: '' });
  }
}

async function save() {
  // Fetching instances component
  const { data } = await $services.sm_instances.generate_instances({
    body: {
      instances: instances_data.value.map(instance => instance.instance_uid),
    },
    query: {
      asset: route.params.asset_id,
      descendants: true,
    },
  });
  const instances_data_map = keyBy(data, 'uid');

  // Create payload for updating instances
  const instances = instances_data.value.map((instance) => {
    const instance_data = instances_data_map[instance.instance_uid];
    instance.instance_component = instance_data?.component || null;
    instance.properties = instance_data?.properties || null;
    return instance;
  });
  await $services.inventory_warehouses.update_procurement_instances({
    id: props.warehouse_id,
    asset_id: route.params.asset_id,
    query: {
      asset: route.params.asset_id,
    },
    body: { instances },
  });

  if (props.on_save)
    await props.on_save();
  else
    emit('save', instances_data.value);
  emit('close');
}

onMounted(async () => {
  try {
    is_loading.value = true;
    const asset = route.params.asset_id;
    if (asset && !Object.keys(common_store.get_asset_meta(asset) || {}).includes('instances'))
      await common_store.set_asset_instances(asset);
    instances_data.value = props.instances;
    is_loading.value = false;
  }
  catch (error) {
    is_loading.value = false;
    logger.error(error);
  }
});
</script>

<template>
  <HawkModalContainer content_class="rounded-lg !min-w-[550px] !w-[550px]">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 12, wrapper: 12 }"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="mb-1">
              {{ $t('Associate blocks') }}
            </div>
            <div v-if="warehouse_id" class="text-sm text-gray-600 font-normal">
              {{ inventory_store.get_active_warehouse_details(warehouse_id).name }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent class="relative !p-0">
          <div class="text-sm">
            <div class="px-6 pt-6">
              <div class="font-semibold">
                {{ $t('Setup') }}
              </div>
              <div class="text-xs text-gray-600">
                {{ $t('Link one or more blocks to the warehouse to capture the bill of material from the designs. You can also define the blocks manually and adjust their scopes as per the requirement') }}
              </div>
            </div>
            <div class="grid grid-cols-12 w-full gap-4 py-3 sticky top-0 bg-white px-6">
              <div class="col-span-10 mt-[1px]">
                <TextElement
                  name="name"
                  class="!text-sm"
                  placeholder="Enter block name"
                />
              </div>
              <div v-tippy="{ content: check_invalid_input.message, placement: 'top' }" class="col-span-2 flex items-center justify-evenly">
                <ButtonElement
                  :button-label="$t('Add')"
                  class="mr-3"
                  :secondary="true"
                  :disabled="check_invalid_input.disabled"
                  @click="addInstance()"
                />
              </div>
            </div>
            <div class="px-6 pb-6 max-h-[300px] scrollbar">
              <div v-if="!is_loading" class="-ml-2">
                <div v-for="(instance, i) in instances_data" :key="i" class="p-2 group cursor-pointer hover:bg-gray-50">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <div class="text-sm text-gray-700 font-medium">
                        {{ instance.instance_name }}
                      </div>
                      <IconHawkFlash v-if="instance.instance_uid !== instance.instance_name" v-tippy="{ content: $t('Synced with design'), placement: 'top' }" class="w-3.5 h-3.5 text-success-600" />
                    </div>
                    <div
                      class="cursor-pointer invisible group-hover:visible"
                      @click="instances_data.splice(i, 1)"
                    >
                      <IconHawkXClose class="w-5 h-5 text-gray-500 hover:text-gray-900" />
                    </div>
                  </div>
                </div>
              </div>
              <HawkLoader v-else />
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
