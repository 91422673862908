<script setup>
const props = defineProps({
  seconds: {
    type: Number,
    default: 60,
  },
});

const emit = defineEmits(['timeout', 'remainingTime']);

const seconds = ref(props.seconds);

function timer() {
  if (seconds.value === 0)
    return emit('timeout');
  seconds.value--;
  setTimeout(timer, 1000);
}

timer();

onBeforeUnmount(() => {
  clearTimeout(timer);
  emit('remainingTime', seconds.value);
});
</script>

<template>
  <span>
    {{ `0${Math.floor(seconds / 60)}`.slice(-2) }} : {{ `0${Math.floor(seconds % 60)}`.slice(-2) }}
  </span>
</template>
