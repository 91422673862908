<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { isEqual, startsWith, trimStart, uniqBy } from 'lodash-es';

import { watch } from 'vue';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { convertTableUnitsData } from '~/common/utils/common.utils.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
  response_data: {
    type: Object,
  },
  payload: {
    type: Object,
  },
  graph_config: {},
});
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const dashboard_store = useDashboardStore();
const activity_key_map = ref(null);
const no_data = ref(false);
const prevent_watcher = ref(false);
const columns_ref = ref('');

const column_key_map = {
  actual: '% Actual',
  actual_units: 'Actual',
  lag: '% Lag',
  lag_units: 'Lag',
  planned: '% Planned',
  planned_units: 'Planned',
  remaining: '% Remaining',
  remaining_units: 'Remaining',
  total_units: 'Total (Scope)',
};

function formatTable(cell) {
  if (startsWith(cell.getValue(), '-'))
    return { color: '#DC2626' };
  return {};
}

async function getReports() {
  loading.value = true;
  activity_key_map.value = props.payload.properties.activity_table_activities;
  try {
    const data = props.response_data;
    if (data && Object.keys(data)) {
      generateActivities(data.data);
      loading.value = false;
    }
    else {
      no_data.value = true;
      loading.value = false;
    }
  }
  catch (err) {
    logger.log({ err });
    loading.value = false;
  }
}

function replace(string) {
  return string.split('.').join('-');
}

const columns_widths_map = computed(() => props.data.data.columns_widths || {});

function getNestedColumns(columns) {
  const res = columns.reduce((acc, col) => {
    col.column_types?.forEach((sub_col) => {
      if (!acc.includes(sub_col))
        acc.push(sub_col);
    });

    return acc;
  }, []);

  return res;
}

function generateActivities(data) {
  const nested_column_list = getNestedColumns(props.payload.data.columns);

  const column_array = [{
    accessorKey: 'name',
    id: 'name',
    header: 'Field',
    size: columns_widths_map.value.name || '400',
  }];

  Object.values(data).forEach((value) => {
    const current_column = {
      accessorKey: replace(value.id),
      cell: info => `${trimStart(info.getValue(), '-')}`,
      header: value === 'name' ? 'Activity' : (activity_key_map.value.find(e => e.id === value.id)?.name || value.name),
      id: replace(value.id),
      columns: [],
    };


    // eslint-disable-next-line unused-imports/no-unused-vars
    for (const [nestedKey, nestedValue] of Object.entries(value)) {
      if (nestedValue?.values) {
        current_column.columns = nested_column_list.map((nestedCol) => {
          const col_id = replace(`${nestedCol}-${value.id}`);
          return {
            accessorKey: col_id,
            id: col_id,
            header: nestedCol === 'id' ? 'Activity id' : column_key_map[nestedCol],
            size: columns_widths_map.value?.[col_id] || '150',
          };
        });
      }
      column_array.push(current_column);
    }
  });
  // Activities
  const fields = props.data.data.columns.reduce((acc, columnData) => {
    acc.list.push({
      fieldKey: columnData.key,
      fieldName: columnData.name,
    });
    return acc;
  }, {
    list: [],
  });

  const activity_array_better = fields.list.reduce((acc, { fieldKey, fieldName }) => {
    const fieldData = {};
    Object.keys(data).forEach((dataKey) => {
      const dataItem = data[dataKey][fieldKey];
      fieldData.name = dataItem?.units ? `${fieldName} (${dataItem.units})` : fieldName;
      if (typeof dataItem === 'object') {
        Object.keys(dataItem?.values ?? {}).forEach((valueKey) => {
          (fieldData[replace(`${valueKey}-${data[dataKey].id}`)]) = convertTableUnitsData(dataItem?.values[valueKey], dataItem.units);
        });
      }

      else if (nested_column_list.length) {
        const first_subcol_key = nested_column_list[0];

        fieldData[replace(`${first_subcol_key}-${data[dataKey].id}`)] = convertTableUnitsData(dataItem, dataItem?.units);
      }

      else { fieldData[replace(data[dataKey].id)] = convertTableUnitsData(dataItem, null); }
    });
    acc.push(fieldData);
    return acc;
  }, []);

  activities.value = activity_array_better;
  columns.value = uniqBy(column_array, c => c.id);
}

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: props.data.data.activity_table_chart ? 'combined_table_graph' : 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: props.data.data.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
      ...(props.data.data.activity_table_chart ? { graph_config: props.graph_config } : {}),
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    type: 'table',
    data: activities.value.map(a => Object.values(a)),
  });
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

watch(() => props.data.data, async (new_val, old_val) => {
  // subcolumns are too deeply nested, this fixes it
  const new_columns_ref = JSON.stringify(new_val?.columns || {});

  if (new_val && (
    !isEqual(new_val, old_val)
    || new_columns_ref !== columns_ref.value
  )) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();
    columns_ref.value = new_columns_ref;
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-if="activities?.length && !loading">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="columns.length"
          :data="activities"
          :columns="columns"
          additional_table_classes=""
          additional_row_classes="even:bg-gray-50"
          :container_class="`h-[${content_height || height}px]`"
          cell_height="30px"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          freeze_column_id="name"
          :format-table="formatTable"
          :show_column_borders="true"
          :show_menu_header="false"
          :striped="true"
          is_gapless
          @column-resized="columnResized"
        />
      </div>
    </a>
  </div>
</template>
