<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useElementSize, useInfiniteScroll } from '@vueuse/core';
import { useModal } from 'vue-final-modal';
import InventoryCsvImporter from '~/common/components/csv-importer/importers/inventory-v2-csv-importer.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryItemDetails from '~/inventory/components/inventory-items/inventory-item-details.vue';
import InventoryItemFilters from '~/inventory/components/inventory-items/inventory-item-filters.vue';
import InventoryItemList from '~/inventory/components/inventory-items/inventory-item-list.vue';
import InventoryItemRegistrationPopup from '~/inventory/components/inventory-items/inventory-item-registration-popup.vue';

import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  is_list_view: { type: Boolean, default: false },
  warehouse_id: { type: String, default: '' },
});
const el = ref(null);
const { width } = useElementSize(el);
const { $t, route, auth_store, router } = useCommonImports();

const inventory_store = useInventoryStore();
const inventory_list = ref(null);

const state = reactive({
  search: '',
  is_loading: false,
  filters: {},
  pagination_config: {
    pageSize: 25,
    pageIndex: 1,
  },
});

const warehouse_detail = computed(() => inventory_store.get_active_warehouse_details(props?.warehouse_id));

const new_item_options = computed(() => {
  return [
    {
      uid: 'add',
      label: $t('Add new item'),
      to: { name: 'inventory-item-form', params: { ...route.params } },
      icon: IconHawkPlus,
    },
    {
      uid: 'import',
      label: $t('Import spreadsheet'),
      icon: IconHawkDownloadTwo,
      on_click: openImporter,
    },
    ...(route.params.asset_id
      ? [{
          uid: 'register-items',
          label: $t('Register items'),
          icon: IconHawkPackageCheck,
          on_click: openRegisterPopup,
        }]
      : []),
  ];
});

const is_boarding = computed(() => !inventory_store?.items?.length && !state.filters);

async function getItems({ pagination_state }) {
  try {
    if (pagination_state.pageIndex === 1)
      inventory_store.items_map = {};
    state.is_loading = true;
    state.pagination_config = { ...state.pagination_config, ...pagination_state };

    const query = {
      page: pagination_state.pageIndex || 1,
      limit: pagination_state.pageSize,
      asset: route.params.asset_id,
      ...(state?.search?.length ? { search: state.search } : {}),
      ...(props?.warehouse_id ? { warehouse: props?.warehouse_id } : {}),
      ...state.filters,
    };

    await inventory_store.set_items({ query, append: true });
    state.is_loading = false;
  }
  catch (error) {
    logger.log(error);
    state.is_loading = false;
  }
}

function onSearch(value) {
  inventory_store.items_map = {};
  state.search = value;
  getItems({ pagination_state: { pageIndex: 1, pageSize: 25 } });
}

onMounted(() => {
  if (props.warehouse_id ? !warehouse_detail.value.permissions?.read : !auth_store.check_permission('display_items', route.params.asset_id)) {
    router.push({
      name: 'inventory-permission-denied',
    });
  }
  else {
    getItems({ pagination_state: state.pagination_config });
  }
});

function onFilterApply(filter) {
  inventory_store.items_map = {};
  state.filters = filter;
  getItems({ pagination_state: { pageIndex: 1, pageSize: 25 } });
}

const { open: openInventoryCSVImporter, close: closeInventoryCSVImporter, patchOptions: patchInventoryCSVImporter } = useModal({
  component: InventoryCsvImporter,
});
function openImporter() {
  patchInventoryCSVImporter({
    attrs: {
      store: inventory_store,
      onClose() {
        closeInventoryCSVImporter();
      },
      onImportSuccess() {
        getItems({ pagination_state: { pageIndex: 1, pageSize: 25 } });
      },
    },
  });
  openInventoryCSVImporter();
}

const { open: openItemRegistrationPopup, close: closeItemRegistrationPopup, patchOptions: patchItemRegistrationPopup } = useModal({
  component: InventoryItemRegistrationPopup,
});

function openRegisterPopup() {
  patchItemRegistrationPopup({
    attrs: {
      onClose() {
        closeItemRegistrationPopup();
      },
      on_register_items() {
        getItems({ pagination_state: { pageIndex: 1, pageSize: 25 } });
      },
    },
  });
  openItemRegistrationPopup();
}

useInfiniteScroll(inventory_list, async () => {
  if (state.is_loading)
    return;

  state.is_loading = true;
  if ((state.pagination_config.pageIndex * state.pagination_config.pageSize) < inventory_store?.total_items_count) {
    state.pagination_config.pageIndex = state.pagination_config.pageIndex + 1;
    await getItems({ pagination_state: state.pagination_config });
  }
  state.is_loading = false;
}, { distance: 1000 });
</script>

<template>
  <HawkIllustrations v-if="warehouse_id ? !warehouse_detail?.permissions?.read : !auth_store.check_permission('display_items', route.params.asset_id)" type="no-permission" />
  <div v-else>
    <div>
      <div>
        <HawkPageHeader v-if="!is_list_view">
          <template #title>
            <div>
              {{ route.params.asset_id ? $t('Items') : $t('Item master') }} ({{ inventory_store?.total_items_count || 0 }})
              <p class="text-sm font-normal">
                {{ route?.params?.asset_id ? $t('Items used in the asset.') : $t("A unified hub for managing and adding items across all organizational assets.") }}
              </p>
            </div>
          </template>
          <template #right>
            <div class="flex gap-3">
              <template v-if="auth_store.check_permission('create_items', route.params.asset_id)">
                <HawkMenu :items="new_item_options">
                  <template #trigger>
                    <HawkButton>
                      <IconHawkPlus class="text-white" />
                      {{ $t('New Item') }}
                    </HawkButton>
                  </template>
                  <template #item="{ item }">
                    <span class="flex items-center gap-2 text-gray-700 font-medium w-44">
                      <component :is="item.icon" class="text-gray-700 h-4 w-4" />
                      {{ item.label }}
                    </span>
                  </template>
                </HawkMenu>
              </template>
            </div>
          </template>
        </HawkPageHeader>
      </div>
      <HawkPageSecondaryHeader
        v-if="inventory_store.items.length || (!inventory_store.items.length && (Object.keys(state.filters).length || state.search))"
        class="pb-4"
        :class="{ 'px-4': !is_list_view }"
      >
        <template #left>
          <InventoryItemFilters :warehouse_id="warehouse_id" @apply="onFilterApply($event)" />
        </template>
        <template #right>
          <HawkSearchInput
            v-model="state.search"
            :placeholder="$t('Search item')"
            :debounce_time="500"
            @update:model-value="onSearch"
          />
        </template>
      </HawkPageSecondaryHeader>
    </div>
    <HawkLoader v-if="state.pagination_config.pageIndex === 1 && state.is_loading" />
    <div v-else-if="is_boarding">
      <HawkIllustrations type="on-boarding" for="inventory-items" :is_create_indicator="authStore.check_permission('create_items', route.params.asset_id)" />
    </div>
    <template v-else-if="!inventory_store.items.length">
      <HawkIllustrations v-if="state.search || Object.keys(state.filters).length" type="no-results" />
      <HawkIllustrations v-else type="no-data" :for="route?.params?.asset_id ? 'inventory-assets-items' : 'inventory-items'">
        <HawkMenu v-if="!inventory_store.items.length && !(Object.keys(state.filters).length || state.search)" :items="new_item_options" class="mt-3">
          <template #trigger>
            <HawkButton>
              <IconHawkPlus class="text-white" />
              {{ $t('New Item') }}
            </HawkButton>
          </template>
          <template #item="{ item }">
            <span class="flex items-center gap-2 text-gray-700 font-medium w-44">
              <component :is="item.icon" class="text-gray-700 h-4 w-4" />
              {{ item.label }}
            </span>
          </template>
        </HawkMenu>
      </HawkIllustrations>
    </template>
    <div v-else class="grid grid-cols-12 gap-4" :class="{ 'px-4': !is_list_view }">
      <div ref="inventory_list" class="col-span-12 md:col-span-3 h-40 md:h-[calc(100vh-198px)] scrollbar md:sticky md:top-20 border rounded-lg">
        <template v-if="inventory_store.items?.length">
          <InventoryItemList :inventory_items="inventory_store.items" />
        </template>
        <HawkLoader v-if="state.pagination_config.pageIndex !== 1 && state.is_loading" container_class="m-2" />
      </div>
      <div ref="el" class="col-span-12 md:col-span-9 h-40 md:h-[calc(100vh-198px)] scrollbar md:sticky md:top-20 border rounded-lg">
        <InventoryItemDetails :warehouse_id="warehouse_id" :container_width="width - 48" />
      </div>
    </div>
  </div>
</template>
