<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  item_type: {
    type: Object,
    default: () => ({}),
  },
  on_save: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(['close', 'save']);
const { $t } = useCommonImports();
const form$ = ref(null);
const form_data = ref(null);
const is_loading = ref(false);

onMounted(() => {
  if (props.item_type?.uid)
    form_data.value = props.item_type;
});

async function handleSave() {
  is_loading.value = true;
  await props.on_save(form_data.value);
  is_loading.value = false;

  emit('close');
}
</script>

<template>
  <hawk-modal-container content_class="!w-[550px]">
    <Vueform
      ref="form$"
      v-model="form_data"
      :float-placeholders="false"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, label: 3, wrapper: 12 },
      }"
      :add-classes="{ ElementLabel: { wrapper: 'text-gray-700 font-medium' } }"
      size="sm"
      sync
      :format-load="data => data"
      :endpoint="handleSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Item type') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <TextElement
            name="name"
            :label="$t('Name')"
            rules="required"
            class="mb-4"
            :placeholder="$t('Enter name')"
          />
          <TextareaElement
            name="description"
            :label="$t('Description')"
            class="mb-4"
            :placeholder="$t('Enter description')"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="mt-5 flex justify-end text-sm font-medium">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
                :loading="is_loading"
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
