<script setup>
import { cloneDeep, filter, flatten, keyBy, uniq, uniqBy } from 'lodash-es';
import { useTerraStore } from '~/terra/store/terra.store.js';

const emit = defineEmits(['close']);
const terra_store = useTerraStore();
const $services = inject('$services');
const $t = inject('$t');
const $toast = inject('$toast');
const form = ref({});
const options = ref([
  { label: $t('Add values manually'), value: 'manually' },
  { label: $t('Add values dynamically'), value: 'dynamically' },
]);

const is_manual = computed(() => form.value.add_values_type === 'manually');
const workflows = computed(() => {
  return Object.values(terra_store.terra_workflows).map((workflow) => {
    return {
      label: workflow.name,
      value: workflow.uid,
    };
  });
});

const features_to_update = computed(() => {
  return terra_store.selected_features.length
    ? terra_store.selected_features
    : terra_store.features_on_map;
});

const properties_to_select = computed(() => {
  if (form.value.add_values_type === 'dynamically') {
    let keys = uniq(
      flatten(
        features_to_update.value.map(f =>
          Object.keys(f.properties.extraProperties || {}),
        ),
      ),
    );
    keys = filter(keys, key => !key.startsWith('_')).map(p => ({
      value: p,
      label: p,
    }));

    return keys;
  }
  return [];
});

function set_fields() {
  if (terra_store.terra_workflows[form.value.workflow]?.data) {
    form.value.fields = uniqBy(
      flatten(
        Object.values(terra_store.terra_workflows[form.value.workflow].data).map(item => item.fields),
      ),
      f => f.uid,
    ).sort((a, _b) => {
      let val = 1;
      if (a.type !== 'INTG_101')
        val = -1;
      return val;
    }).map(val => ({ ...val, total: val.type === 'INTG_101' ? 1 : 0 }));
  }
}

function numberInputValidator(e, index, name) {
  if (e.target.value) {
    const value = Number(e.target.value);
    let value_to_load = 0;
    if (value >= value_to_load && Number.isInteger(value))
      value_to_load = value;
    form.value.fields[index][name] = value_to_load;
  }
}
function getFieldProgress(uid, data = null) {
  const current_value = data?.[uid]?.current;

  const total = data?.[uid]?.total;

  return current_value / total;
}
function checkThresholdReached(uid, next_destination, data) {
  const current_progress = getFieldProgress(uid, data);

  const threshold_per = next_destination?.required[uid];
  return current_progress >= threshold_per;
}
function getThresholdNotReachedFields(next_destination = null, data = null) {
  const fields = [];
  const required = next_destination?.required;
  for (const uid in required) {
    if (!checkThresholdReached(uid, next_destination, data)) {
      const total = data?.[uid]?.total;
      const current = data?.[uid]?.current;
      const threshold_per = required[uid];
      const threshold_value = total * threshold_per;
      fields.push({
        name: next_destination?.fields?.[uid]?.name ?? '',
        required: (threshold_value - current).toFixed(2),
      });
    }
  }

  return fields;
}
function getDestinationForCumulative(next_destination, data) {
  const new_cumulative_progress = Object.keys(data).reduce((acc, field) => {
    const weight = next_destination.weights[field] ?? 1;

    const current_value = data[field].current || 0;
    const total = data[field]?.total;
    const current_progress = current_value / total;
    return acc + current_progress * weight;
  }, 0);

  let new_feature_type;
  for (const [feature_type, [min, max]] of Object.entries(
    next_destination.destination,
  )) {
    if (min <= new_cumulative_progress && new_cumulative_progress <= max)
      new_feature_type = feature_type;
  }

  return new_feature_type;
}

function checkCanStatusChange(next_destination, data, old_feature_type) {
  if (next_destination?.progress_type === 'cumulative') {
    const new_feature_type = getDestinationForCumulative(
      next_destination,
      data,
    );
    return new_feature_type !== old_feature_type;
  }
  return (
    getThresholdNotReachedFields(next_destination, data).length === 0
  );
}
async function saveAssociation() {
  const features = {};
  const workflow_fields = form.value.fields.map(field => field.uid);
  const deactivated_fields = {};
  const activated_fields = {};
  if (features_to_update.value?.length) {
    features_to_update.value.forEach((feature) => {
      deactivated_fields[feature.properties.uid] = [];
      activated_fields[feature.properties.uid] = [];
      const feature_fields = Object.keys(
        feature.properties.workflowProgress,
      );
      if (feature.properties.uid) {
        features[feature.properties.uid] = { fields: {}, status_changed: false, new_feature_type: '' };
        form.value.fields?.forEach((field) => {
          const current = (feature.properties?.workflowProgress[field.uid]?.current) || Number(field.current) || 0;

          let total = 0;
          if (form.value.add_values_type === 'manually')
            total = Number(field.total);
          else
            if (field.type === 'INTG_101')
              total = 1;
            else
              total = Math.ceil(feature.properties.extraProperties[field.property]) || Number(field.fallback) || 0;

          features[feature.properties.uid].fields[field.uid] = {
            total,
            active: true,
            ...(field.type !== 'INTG_101' && current && {
              current,
            }),
          };
          if (features[feature.properties.uid].fields[field.uid] && features[feature.properties.uid].fields[field.uid].total < feature.properties.workflowProgress[field.uid]?.current) {
            $toast({
              text: `The provided total for ${feature.properties.name
              || 'Untitled'} & ${
                field.name
              } is lesser than existing current value`,
              type: 'error',
            });
            throw new Error('The provided total is less than existing current');
          }
          if (!features[feature.properties.uid].fields[field.uid].total) {
            $toast({
              text: `The total value for ${feature.properties.name}/${field.name} can’t be 0. Please fix and try again.`,
              type: 'error',
            });
            throw new Error('The total value for can’t be 0');
          }
        });
      }
      feature_fields.forEach((field) => {
        if (
          !workflow_fields.includes(field)
          && feature.properties.workflowProgress[field]?.active
        ) {
          features[feature.properties.uid].fields[field] = {
            ...feature.properties.workflowProgress[field],
            active: false,
          };
          deactivated_fields[feature.properties.uid].push(field);
        }
        else if (
          workflow_fields.includes(field)
          && !feature.properties.workflowProgress[field]?.active
        ) {
          features[feature.properties.uid].fields[field] = {
            ...feature.properties.workflowProgress[field],
            active: true,
          };
          activated_fields[feature.properties.uid].push(field);
        }
      });
      let new_feature_type = feature.properties.featureType;

      let step_data = cloneDeep(
        terra_store.terra_workflows[form.value.workflow]?.data[new_feature_type],
      );

      if (step_data?.fields)
        step_data.fields = keyBy(step_data.fields, 'uid');
      if (step_data) {
        const status_changed = checkCanStatusChange(
          step_data,
          features[feature.properties.uid].fields,
          new_feature_type,
        );
        if (status_changed && step_data) {
          if (step_data?.progress_type === 'cumulative') {
            new_feature_type = getDestinationForCumulative(step_data, features[feature.properties.uid].fields);
          }
          else {
            let auto_increment_steps = 1;
            const incrementStep = () => {
              new_feature_type = step_data?.destination;
              step_data = cloneDeep(
                terra_store.terra_workflows[form.value.workflow]?.data[new_feature_type],
              );
              if (step_data) {
                step_data.fields = keyBy(step_data.fields, 'uid');
                auto_increment_steps += 1;
              }
              else {
                auto_increment_steps = 0;
              }
            };
            incrementStep();

            while (auto_increment_steps) {
              const status_changed = checkCanStatusChange(
                step_data,
                features[feature.properties.uid].fields,
                new_feature_type,
              );

              if (status_changed && step_data)
                incrementStep();
              else
                auto_increment_steps = 0;
            }
          }
        }

        features[feature.properties.uid].status_changed = status_changed;
        if (status_changed) {
          features[
            feature.properties.uid
          ].new_feature_type = new_feature_type;
        }
      }
    });
  }

  const response = await $services.features.associate_workflow({
    body: { features, deactivated_fields, activated_fields },
    id: form.value.workflow,
    container_id: terra_store.container.uid,
  });
  const uid = terra_store.selected_features[0]?.properties?.uid;
  terra_store.terra_track_events('Associated workflow', { uid });
  $toast({
    text: 'Workflow associated successfully',
    type: 'success',
  });
  const features_to_update_res = response?.data?.features || [];
  if (features_to_update_res.length) {
    await terra_store.update_features_in_state({
      features: features_to_update_res,
      properties_to_update: ['workflowProgress', 'workflow', 'featureTypeId', 'featureType'],
      clearSelectedFeatures: true,
      oldResponse: features_to_update.value.map(item => ({
        ...item,
        properties: {
          ...item.properties,
          oldfeatureTypeId: item.properties.featureTypeId,
        },
      })),
    });
  }
  emit('close');
}

function initializeForm() {
  form.value.workflow = Object.keys(terra_store.terra_workflows)[0];
  set_fields();
}

initializeForm();
</script>

<template>
  <hawk-modal-container :width="800" content_class="rounded-lg !w-[800px]">
    <Vueform v-model="form" sync size="sm" :display-errors="false" :endpoint="saveAssociation">
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Configure Activity Reporting') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <SelectElement
            name="workflow"
            :label="$t('Workflow')"
            placeholder="Select"
            :search="true"
            :native="false"
            :can-clear="false"
            :items="workflows"
            :rules="[
              'required',
            ]"
            :add-classes="{
              ElementLabel: {
                wrapper: 'font-medium',
              },
            }"
            :columns="{
              lg: {
                container: 12,
                label: 4,
                wrapper: 12,
              },
            }"
            @change="set_fields"
          />
          <RadiogroupElement
            class="my-4"
            :label="$t('Values')"
            name="add_values_type"
            default="manually"
            :items="options"
            :add-classes="{
              ElementLabel: {
                wrapper: 'font-medium',
              },
            }"
            :columns="{
              lg: {
                container: 12,
                label: 4,
                wrapper: 12,
              },
            }"
          />
          <div class="col-span-12">
            <div class="grid grid-cols-12 text-sm font-semibold text-gray-700 my-3">
              <div class="col-span-4 pr-4">
                {{ $t('Field Name') }}
              </div>
              <div class="col-span-4 pr-4">
                {{ is_manual ? $t('Total') : $t('Property') }}
              </div>
              <div v-show="!is_manual" class="col-span-4">
                {{ is_manual ? $t('Current') : $t('Fallback') }}
              </div>
            </div>
          </div>
          <ListElement
            name="fields"
            :rules="[
              'required',
            ]"
            :controls="{
              add: false,
              remove: false,
            }"
          >
            <template #default="{ index }">
              <ObjectElement v-show="form.fields[index].type !== 'INTG_101'" :name="index">
                <div class="col-span-12">
                  <div class="grid grid-cols-12 text-sm py-0.75">
                    <div class="col-span-4 text-sm text-gray-900 pr-4">
                      {{ form.fields[index].name || `Field ${index}` }}
                    </div>
                    <div class="col-span-4 pr-4">
                      <TextElement
                        v-if="is_manual"
                        name="total"
                        input-type="number"
                        :rules="[
                          'integer',
                        ]"
                        :default="0"
                        autocomplete="off"
                        @input="$event => numberInputValidator($event, index, 'total')"
                      />
                      <SelectElement
                        v-else
                        name="property"
                        placeholder="Select"
                        :search="true"
                        :native="false"
                        :can-clear="false"
                        :items="properties_to_select"
                        :rules="[
                          'required',
                          'nullable',
                        ]"
                      />
                    </div>
                    <div class="col-span-4">
                      <TextElement
                        v-show="!is_manual"
                        :name="is_manual ? 'current' : 'fallback'"
                        input-type="number"
                        :rules="[
                          'integer',
                        ]"
                        :default="0"
                        autocomplete="off"
                        @input="$event => numberInputValidator($event, index, is_manual ? 'current' : 'fallback')"
                      />
                    </div>
                  </div>
                </div>
              </ObjectElement>
            </template>
          </ListElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
