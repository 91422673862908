<template>
  <TagsElement>
    <template #tag="{ option, handleTagRemove, disabled }">
      <span :style="{ backgroundColor: option.color }" class="TagsElement.select.tag--> form-bg-tag form-color-tag form-border-width-tag form-border-color-tag font-semibold flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:mr-0  !text-gray-700 !font-normal !text-sm !border-gray-300 !leading-relaxed TagsElement.select.tag_sm--> form-radius-input-tag-sm form-text-small-sm form-py-tag-sm form-pl-tag-sm form-mr-space-tags-sm form-mb-space-tags-sm rtl:form-pr-tag-sm rtl:form-ml-space-tags-sm" tabindex="-1" aria-label="default ❎">
        <span class="TagsElement.select.tagWrapper--> overflow-hidden overflow-ellipsis text-white">{{ option.label }} </span>
        <span
          v-if="!disabled"
          class="TagsElement.select.tagRemove--> flex items-center justify-center p-1 mx-0.5 hover:bg-black hover:bg-opacity-10 group text-white hover:text-black !p-0.5 !rounded hover:!bg-gray-100 !ml-1 TagsElement.select.tagRemove_sm--> form-radius-input-tag-sm"
        >
          <span class="TagsElement.select.tagRemoveIcon--> mask-bg mask-form-remove bg-current inline-block w-3 h-3 " @mousedown.prevent="handleTagRemove(option, $event)" />
        </span>
      </span>
    </template>
    <template #option="{ option }">
      <div class="flex items-center">
        <span class="h-[10px] w-[10px] rounded-full mx-auto inline-block" :style="{ backgroundColor: option.color }" />
        <span class="ml-2">
          {{ option.label }}
        </span>
      </div>
    </template>
  </TagsElement>
</template>
