<script setup>
const props = defineProps({
  header: {
    type: String,
    default: 'Confirm',
  },
  has_confirm_button: {
    type: Boolean,
    default: true,
  },
  content: {
    type: String,
    default: 'Are you sure you want to go ahead with this action?',
  },
  confirm: {
    type: Function,
    default: null,
  },
  is_block_styled_button: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['confirm', 'close']);
const state = reactive({
  loading: false,
});
async function save() {
  try {
    if (props.confirm) {
      state.loading = true;
      await props.confirm();
      state.loading = false;
    }
    else { emit('confirm'); }
  }
  catch (err) {
    state.loading = false;
  }
}
</script>

<template>
  <hawk-modal-container content_class="w-[400px] rounded-lg">
    <div class="flex items-center p-4 justify-between text-lg font-semibold text-gray-800">
      <slot name="title">
        <p class="font-semibold text-lg mb-1">
          {{ props.header }}?
        </p>
      </slot>
      <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center" @click="$emit('close')">
        <IconHawkX />
      </div>
    </div>
    <hawk-modal-content>
      <div class="mb-8">
        <p class="text-gray-700 text-sm">
          {{ props.content }}
          <slot name="content" />
        </p>
      </div>
      <div class="flex justify-end w-full">
        <slot name="footer">
          <HawkButton color="primary" type="outlined" class="mr-3" :block="is_block_styled_button" @click="$emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>

          <HawkButton v-if="has_confirm_button" color="primary" :block="is_block_styled_button" :loading="state.loading" @click="save">
            {{ $t('Confirm') }}
          </HawkButton>
        </slot>
      </div>
    </hawk-modal-content>
  </hawk-modal-container>
</template>
