<script setup>
const props = defineProps({
  items: {
    type: Array,
    default: () => [
      { label: 'Apple', leftSlot: IconIcBaselineApple, rightSlot: 'l1' },
      { label: 'Beach', leftSlot: IconIcBaselineBeachAccess },
      { label: 'Cloud', leftSlot: IconIcBaselineCloud },
    ],
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['md', 'lg'].includes(value);
    },
  },
  icon: {
    type: Boolean,
    default: false,
  },
  block: {
    type: Boolean,
    default: false,
  },
  active_item: {
    type: [String, Number],
  },
  active_key: {
    type: String,
    default: 'uid',
  },
});

const button_group_classes = computed(() => {
  const btnGroup = [];
  switch (props.size) {
    case 'md':
      btnGroup.push(...(props.icon ? ['px-3', 'py-2'] : ['px-4', 'py-1.75']));

      break;
    case 'lg':
      btnGroup.push('px-4', 'py-2.25');
      break;
  }

  if (props.block)
    btnGroup.push('!w-full justify-center');

  return btnGroup;
});
</script>

<template>
  <div>
    <span class="isolate inline-flex rounded-md shadow-sm hawk-button-group" :class="{ 'w-full': block }">
      <button
        v-for="(item) in items" :key="item.label"
        v-tippy="item?.tooltip_text ? item.tooltip_text : null"
        type="button"
        class="relative inline-flex items-center border border-gray-300 hover:bg-gray-100 font-medium text-sm gap-2"
        :class="[button_group_classes, active_item === item[active_key] ? 'bg-gray-100 text-gray-800' : 'bg-white text-gray-500']"
        :disabled="item.disabled"
        @click="item.action ? $emit(item.action) : $emit('select', item)"
      >
        <component :is="item.leftSlot" :class="[active_item === item[active_key] ? ' text-gray-800' : 'text-gray-500']" class="w-5 h-5" />
        <slot :name="item.leftSlot" />
        <span
          v-if="item && item.label"
          :class="[{ 'ml-2': $slots[item.leftSlot] }, { 'mr-2': $slots[item.rightSlot] }]"
        >
          {{ item.label }}
        </span>
        <slot :name="item.rightSlot" />
      </button>
    </span>
  </div>
</template>

<style lang="scss">
.hawk-button-group {
  button:first-of-type {
    @apply rounded-l-md;
  }
  button:last-of-type{
    @apply rounded-r-md;
  }
  button:not(:first-of-type) {
    @apply -ml-px;
  }
}
</style>
