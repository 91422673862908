<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import { onMounted } from 'vue';
import { cloneDeep, sortBy } from 'lodash-es';
import HawkModalContainer from '~/common/components/hawk-modal/hawk-modal-container.vue';
import FamTabForm from '~/forms-as-module/components/fam-tabs-list/fam-tab-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import FamTabListItems from '~/forms-as-module/components/fam-tabs-list/fam-tabs-list-items.vue';
import { sleep } from '~/common/utils/common.utils.js';

const props = defineProps({
  is_popup: {
    type: Boolean,
    default: true,
  },
  columns: {
    type: Array,
    value: () => [],
  },
  get_items: {
    type: Function,
    required: true,
  },
  create_item: {
    type: Function,
    default: null,
  },
  update_item: {
    type: Function,
    default: null,
  },
  delete_item: {
    type: Function,
    default: null,
  },
  texts: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  context_menu: {
    type: Array,
    default: () => ['edit', 'delete', 'columns', 'cf'],
  },
  max_limit: {
    type: Number,
    default: 10,
  },
  formatting_enabled: {
    type: Boolean,
    default: false,
  },
  get_default_name: {
    type: Function,
    default: null,
  },
  is_filters_required: {
    type: Boolean,
    default: true,
  },
  handleColumnsConfiguration: {
    type: Function,
  },
  handleConditionalFormatting: {
    type: Function,
  },
  min_items_limit: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['close']);
const $t = inject('$t');

const items = ref([]);
const is_moving = ref(false);

const filter_popup = useModal({
  component: FamTabForm,
  attrs: {
    onClose() {
      filter_popup.close();
    },
  },
});

function openFilterModal(filter = null) {
  filter_popup.patchOptions({
    attrs: {
      ...(props.texts?.heading?.length
        ? {
            texts: {
              heading: props.texts.heading,
            },
          }
        : {}),
      texts: {
        heading: props.texts.heading,
      },
      filter: filter || (props.get_default_name ? { name: props.get_default_name(), filter: { condition: { logic: 'AND', rules: [] } } } : null),
      columns: props.columns,
      formatting_enabled: props.formatting_enabled,
      is_filters_required: props.is_filters_required,
      on_save: async (data) => {
        await createOrUpdateFilter(data, filter);
      },
    },
  });
  filter_popup.open();
}

async function createOrUpdateFilter(data, filter) {
  if (filter)
    await props.update_item({
      ...data,
      uid: filter.uid,
      order_index: data.order_index ? data.order_index : filter.order_index,
    });
  else
    await props.create_item({
      ...data,
      order_index: props.get_items().length + 1,
    });

  items.value = await getFormattedItems();
  filter_popup.close();
}

async function handleMove(data) {
  is_moving.value = true;

  const items_list = items.value;
  const current_index = data.moved.newIndex;

  const left_node = (current_index - 1) >= 0 ? items_list[current_index - 1] : null;
  const right_node = (current_index + 1) < items_list.length ? items_list[current_index + 1] : null;
  const left_node_order_index = left_node ? left_node.order_index : 0;
  const right_node_order_index = right_node ? right_node.order_index : (items_list.length + 1);

  const filter = cloneDeep(data.moved.element);
  filter.order_index = (right_node_order_index + left_node_order_index) / 2;
  if (props.update_item)
    await props.update_item(filter);

  items.value = await getFormattedItems();
  is_moving.value = false;
}

function handleDelete(filter, index) {
  const delete_popup = useModal({
    component: HawkDeletePopup,
    attrs: {
      header: filter.name,
      onClose() {
        delete_popup.close();
      },
      confirm: async () => {
        if (props.delete_item)
          await props.delete_item({ ...filter, index });

        items.value = await getFormattedItems();
        delete_popup.close();
      },
    },
  });
  delete_popup.open();
}

async function handleSaveItems() {
  if (props.handle_save)
    await props.handle_save(items.value);

  items.value = await getFormattedItems();
  emit('close');
}

async function getFormattedItems() {
  await sleep(10);
  return sortBy(props.get_items(), 'order_index');
}

onMounted(async () => {
  items.value = await getFormattedItems();
});
</script>

<template>
  <HawkModalContainer v-if="is_popup" content_class="rounded-lg w-[700px]">
    <Vueform
      :endpoint="handleSaveItems"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ props.texts.heading }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="max-h-[calc(100vh-40vh)] scrollbar">
          <FamTabListItems
            :items="items"
            :menu_items="context_menu"
            :max_limit="max_limit"
            :min_items_limit="min_items_limit"
            :texts="texts"
            @openFilterPopup="openFilterModal($event)"
            @delete="handleDelete($event.filter, $event.index)"
            @onMove="handleMove($event)"
            @handleColumnConfiguration="handleColumnsConfiguration($event.uid)"
            @handleConditionalFormatting="handleConditionalFormatting($event.uid)"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <ButtonElement
                name="close"
                :button-label="$t('Close')"
                class="mr-3"
                size="sm"
                :secondary="true"
                @click="emit('close')"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </vueform>
  </HawkModalContainer>
  <FamTabListItems
    v-else
    :items="items"
    :menu_items="context_menu"
    :max_limit="max_limit"
    :min_items_limit="min_items_limit"
    :texts="texts"
    @openFilterPopup="openFilterModal($event)"
    @delete="handleDelete($event.filter, $event.index)"
    @onMove="handleMove($event)"
    @handleColumnConfiguration="handleColumnsConfiguration($event.uid)"
    @handleConditionalFormatting="handleConditionalFormatting($event.uid)"
  />
</template>
