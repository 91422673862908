<script setup>
import { onBeforeUnmount, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';

import useEmitter from '~/common/composables/useEmitter';

import { useDocumentStore } from '~/dms/store/document.store';

import HawkZipDownloadToast from '~/common/components/organisms/hawk-zip-download/hawk-zip-download-toast.vue';

const route = useRoute();
const zip = ref(null);
const document_store = useDocumentStore();

const state = reactive({
  zip_data: null,
  zipped_items: [],
  initialize_download: false,
});

const emitter = useEmitter();

emitter.on('download_zip', (value) => {
  state.zip_data = value;
  if (value.type === 'vault' && (value?.files || value?.folders))
    getZipItemsToDownload();
  if (value.is_zip_item)
    state.zipped_items.push(value);
});

emitter.on('download_zip_complete', downloadCompleted);

emitter.on('set-initial-loading', (value) => {
  state.initialize_download = value;
});

onBeforeUnmount(() => {
  emitter.off('download_zip');
  emitter.off('set-initial-loading');
});

async function getZipItemsToDownload() {
  state.initialize_download = true;
  let payload = {
    name: new Date().toLocaleDateString('en-In'),
  };

  if (state.zip_data.files)
    if (state.zip_data.global_files) {
      const response = await document_store.get_download_url({
        body: {
          files: state.zip_data.files.map(uid => ({ uid: uid.uid })),
        },
        query: {
          ...(route.name === 'files-documents-archived' ? { archive: true } : {}),
        },
      });

      payload.files = response.data.urls;
    }
    else {
      const uids = state.zip_data.files.map(file => ({ uid: file.uid }));
      const response = await document_store.get_download_url({
        body: {
          files: uids,
        },
        query: {
          ...(route.name === 'files-documents-archived' ? { archive: true } : {}),
        },
      });
      payload.files = response.data.urls;
    }

  if (state.zip_data.folders) {
    const promises = [];

    state.zip_data.folders.forEach((folder) => {
      promises.push(
        document_store.get_download_url({
          body: {
            folders: [
              {
                uid: folder.uid,
              },
            ],
          },
          query: {
            children: true,
            ...(route.name === 'files-documents-archived' ? { archive: true } : {}),
          },
        }),
      );
    });
    const response = await Promise.all(promises);
    const children = [];
    response.forEach((item) => {
      children.push(item.data.urls[0]);
    });
    payload.children = children;

    if (!payload.files && state.zip_data.folders.length === 1)
      payload = payload.children[0];
  }
  state.zipped_items.push(payload);
  state.initialize_download = false;
}

function downloadCompleted(zip_items) {
  state.zipped_items = state.zipped_items.filter(item => (item === zip_items));
  state.initialize_download = false;
}
</script>

<template>
  <div
    id="download-zip-container"
    ref="zip"
    class="download-zip-container fixed bottom-4 right-4"
  >
    <div v-if="state.initialize_download" class="download-zip p-4 w-full bg-gray-800">
      <div class="text-sm font-medium ml-2">
        {{ $t('Initializing download') }}
      </div>
    </div>
    <div v-if="state.zipped_items.length">
      <HawkZipDownloadToast v-for="(zip_item, index) in state.zipped_items" :key="index" :zip_data="zip_item" />
    </div>
  </div>
</template>

<style lang="scss">
  .download-zip-container {
    z-index: 9998;
    .download-zip {
      color: white;
      margin-bottom: 1rem;
      width: 400px;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(134, 139, 162, 0.3);
      border: solid 1px #868ba2;
      background-color: #1D2939;
    }
  }
</style>
