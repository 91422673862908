<script setup>
import { useRoute } from 'vue-router';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  on_submit: {
    type: Function,
    default: null,
  },
});
const emit = defineEmits(['close']);
const route = useRoute();
const common_store = useCommonStore();
const template_store = useFormTemplatesStore();
const is_loading = ref(false);

const forms$ = ref(null);
async function onSave() {
  const { import_template, category, name } = forms$.value.data;
  const element = common_store?.get_asset(route.params.asset_id)?.element;
  const payload = {
    uid: import_template.uid,
    body: {
      form: {
        name,
        users: false,
        labels: false,
        schedule: false,
        targetElement: element,
        category,
      },
    },
  };
  await props.on_submit(payload);
}

async function formMounted(form$) {
  try {
    is_loading.value = true;
    form$.elements$.name.input.focus();
    const query = { status: 'published', page_size: 100000000 };
    await template_store.get_approve_template({ query });
  }
  catch (e) {}
  is_loading.value = false;
}

const form_template_asset_list = computed(() => {
  const assetMap = Object.values(template_store?.forms_asset_template)
    .filter(
      form => form.target_element.asset !== route.params.asset_id,
    )
    .reduce((final, form) => {
      if (!final[form.target_element.asset])
        final[form.target_element.asset] = [];
      final[form.target_element.asset].push(form);
      return final;
    }, {});
  const template_list = Object.keys(assetMap)
    .filter(asset_id => common_store?.get_asset(asset_id))
    .map((asset_id) => {
      return {
        name: common_store?.get_asset(asset_id)?.name,
        children: assetMap[asset_id],
      };
    });
  const template_asset_list = template_list
    .map((asset) => {
      return {
        label: asset.name,
        items: asset.children.filter(form => form.name).map((form) => {
          return { name: form.name, uid: form.uid };
        }),
      };
    });
  return template_asset_list;
});
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="forms$" size="sm" :display-errors="false"
      :presets="['']" :float-placeholders="false" class="grid grid-rows-1 grid-flow-col gap-4"
      :columns="{
        default: { container: 9 },
        sm: { container: 9, label: 2 },
        md: { container: 9, label: 4, wrapper: 11 },
      }"
      :endpoint="onSave"
      @mounted="formMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t("Import Template") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="min-h-[300px]">
          <TextElement
            class="pb-4"
            autocomplete="off"
            name="name"
            label="Name"
            :rules=" `${['required']}`"
            :placeholder="`${$t('enter form template name')}`"
          />

          <hawk-category-input
            class="mb-4"
            :options="{
              name: 'category',
              label: $t('Category'),
              placeholder: $t('Select Category'),
              tags_removable: true,
            }"
            :multi="false"
          />
          <SelectElement
            label="Choose template"
            name="import_template"
            class="pb-4"
            :object="true"
            track-by="name"
            :rules=" `${['required']}`"
            label-prop="name"
            value-prop="uid"
            :loading="is_loading"
            :native="false"
            :groups="true"
            search="true"
            :items="form_template_asset_list"
          >
            <template #option="{ option }">
              {{ option.name }}
            </template>
            <template #tag="{ option }">
              {{ option.name }}
            </template>
          </SelectElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <hawk-button class="mr-3" type="outlined" color="gray" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
