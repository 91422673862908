import Conversations from '~/conversations/pages/conversations.vue';

const routes = [
  {
    path: '/:asset_id?/conversations',
    name: 'conversations',
    component: Conversations,
    meta: {
      title: 'Conversations',
    },
  },
];
export default routes;
