import { useCommonStore } from '~/common/stores/common.store.js';
import {
  default_date_field,
  default_dates_field,
  default_number_field,
  default_operator_field,
  default_tags_field,
  default_text_field,
  empty_form_operator_fields,
  static_element,
  two_field_columns,
} from '~/dashboard/components/filters/composables/fields-config';

import {
  member_options,
  operator_options,
} from '~/dashboard/components/filters/composables/filters-config';

const ASSET_OPERATOR_OPTIONS = {
  number: [
    'equals',
    'greater than',
    'less than',
    'between',
  ].map((key) => {
    return {
      value: key,
      label: key,
    };
  }),
  planned_actual: [
    ['equals', 'Equals'],
    ['planned_equals', 'Planned equals'],
    ['planned_less_than', 'Planned less than'],
    ['planned_greater_than', 'Planned greater than'],
    ['planned_between', 'Planned between'],
    ['actual_equals', 'Actual equals'],
    ['actual_less_than', 'Actual less than'],
    ['actual_greater_than', 'Actual greater than'],
    ['actual_between', 'Actual between'],
  ].map(item => ({ value: item[0], label: item[1] })),
};

function mapConfigToItems(config) {
  if (!Array.isArray(config))
    return [];
  return config.map(item => ({ value: item.uid, label: item.name }));
}

export function getDMSFilterConfig(field_uid = null) {
  if (!field_uid)
    return {};

  const common_store = useCommonStore();
  const field = common_store.assets_custom_fields.filter(field => field.uid === field_uid)[0];

  const filter_configuration = {
    text: {
      value: 'text',
      type: 'text',
      label: field.name,
      operator: {
        ...default_operator_field,
        items: operator_options.text,
      },
      default_fields: [
        {
          ...default_text_field,
        },
      ],
    },
    number: {
      value: 'number',
      type: 'number',
      label: field.name,
      operator: {
        ...default_operator_field,
        items: ASSET_OPERATOR_OPTIONS.number,
        custom_fields: {
          between: [
            {
              ...default_number_field,
              name: 'start',
              columns: two_field_columns,
              rules: ['required', 'numeric'],
            },
            {
              ...static_element,
              columns: {
                default: { container: 2, label: 12, wrapper: 12 },
                sm: { container: 2, label: 12, wrapper: 12 },
                md: { container: 2, label: 12, wrapper: 12 },
              },
            },
            {
              ...default_number_field,
              name: 'end',
              columns: two_field_columns,
              rules: ['required', 'numeric'],
            },
          ],
        },
      },
      default_fields: [
        {
          ...default_number_field,
          rules: ['required', 'numeric'],
        },
      ],
    },
    date: {
      value: 'date',
      type: 'date',
      label: field.name,
      operator: {
        ...default_operator_field,
        items: operator_options.date,
        custom_fields: {
          between: [
            {
              ...default_dates_field,
            },
          ],
          ...empty_form_operator_fields,
        },
      },
      default_fields: [
        {
          ...default_date_field,
        },
      ],
    },
    dropdown: {
      value: 'dropdown',
      type: 'tag',
      label: field.name,
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          items: mapConfigToItems(field.config),
        },
      ],
    },
    members: {
      value: 'members',
      type: 'tag',
      label: field.name,
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          items: member_options(),
        },
      ],
    },
    percentage: {
      value: 'percentage',
      type: 'number',
      label: field.name,
      operator: {
        ...default_operator_field,
        items: ASSET_OPERATOR_OPTIONS.number,
        custom_fields: {
          between: [
            {
              ...default_number_field,
              name: 'start',
              columns: two_field_columns,
              default: 0,
            },
            {
              ...static_element,
              columns: {
                default: { container: 2, label: 12, wrapper: 12 },
                sm: { container: 2, label: 12, wrapper: 12 },
                md: { container: 2, label: 12, wrapper: 12 },
              },
            },
            {
              ...default_number_field,
              name: 'end',
              columns: two_field_columns,
              default: 100,
            },
          ],
        },
      },
      default_fields: [
        {
          ...default_number_field,
        },
      ],
    },
    planned_actual: {
      value: 'planned_actual',
      type: 'number',
      label: field.name,
      operator: {
        ...default_operator_field,
        items: ASSET_OPERATOR_OPTIONS.planned_actual,
        custom_fields: {
          planned_between: [
            {
              ...default_number_field,
              name: 'start',
              columns: two_field_columns,
              rules: ['required', 'numeric'],
            },
            {
              ...static_element,
              columns: {
                default: { container: 2, label: 12, wrapper: 12 },
                sm: { container: 2, label: 12, wrapper: 12 },
                md: { container: 2, label: 12, wrapper: 12 },
              },
            },
            {
              ...default_number_field,
              name: 'end',
              columns: two_field_columns,
              rules: ['required', 'numeric'],
            },
          ],
          actual_between: [
            {
              ...default_number_field,
              name: 'start',
              columns: two_field_columns,
              rules: ['required', 'numeric'],
            },
            {
              ...static_element,
              columns: {
                default: { container: 2, label: 12, wrapper: 12 },
                sm: { container: 2, label: 12, wrapper: 12 },
                md: { container: 2, label: 12, wrapper: 12 },
              },
            },
            {
              ...default_number_field,
              name: 'end',
              columns: two_field_columns,
              rules: ['required', 'numeric'],
            },
          ],
        },
      },
      default_fields: [
        {
          ...default_number_field,
          rules: ['required', 'numeric'],
        },
      ],
    },
    date_range: {
      value: 'date_range',
      type: 'dates',
      label: field.name,
      operator: {
        ...default_operator_field,
        items: operator_options.date_range,
        custom_fields: {
          start_between: [
            {
              ...default_dates_field,
            },
          ],
          end_between: [
            {
              ...default_dates_field,
            },
          ],
          equals: [
            {
              ...default_number_field,
              rules: ['required', 'numeric', 'min:0'],
            },
          ],
          ...empty_form_operator_fields,
        },
      },
      default_fields: [
        {
          ...default_date_field,
        },
      ],
    },
    checkbox: {
      value: 'checkbox',
      type: 'tag',
      label: field.name,
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          items: mapConfigToItems(field.config),
        },
      ],
    },
  };

  return filter_configuration[field.type] || {};
}
