<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { groupBy, orderBy, sumBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { getCurrencyDetails } from '~/common/utils/common.utils';
import InventoryCustomField from '~/inventory/components/inventory-custom-fields/inventory-custom-field.vue';
import InventoryItemPoPopup from '~/inventory/components/inventory-items/inventory-item-po-popup.vue';
import InventoryItemPoScopePopup from '~/inventory/components/inventory-items/inventory-item-po-scope-popup.vue';
import InventoryItemStockInformation from '~/inventory/components/inventory-items/inventory-item-stock-information.vue';
import InventoryItemTransactions from '~/inventory/components/inventory-items/inventory-item-transactions.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

defineProps({
  warehouse_id: { type: String, default: '' },
  container_width: { type: Number, default: 0 },
});

const { auth_store, route, $t, $services } = useCommonImports();
const inventory_store = useInventoryStore();

const state = reactive({
  is_loading: false,
  item_procurement_details: {},
});

const { open: openPurchaseOrderPopup, close: closePurchaseOrderPopup, patchOptions: patchPurchaseOrderPopup } = useModal({ component: InventoryItemPoPopup });
const { open: openPoScopePopup, close: closePoScopePopup, patchOptions: patchPoScopePopup } = useModal({ component: InventoryItemPoScopePopup });

const item_details = computed(() => inventory_store.get_active_item_details);

const warehouses_stocks = computed(() => {
  const data = [];
  if (item_details.value?.stockstatuses?.length) {
    item_details.value.stockstatuses.forEach(
      ({ resource_id, resource_type, stock_quantity, status }) => {
        if (resource_type === 'warehouse') {
          const warehouse_detail = inventory_store.get_location_details({ type: resource_type, uid: resource_id });
          const status_detail = inventory_store.statuses_map[status];
          data.push({
            warehouse_detail,
            status_detail,
            stock_quantity,
          });
        }
      },
    );
  }
  return data;
});

const stocks_by_status = computed(() => {
  const grouped = groupBy(warehouses_stocks.value.filter(item => item?.status_detail?.name), item => item?.status_detail?.name);
  return orderBy(Object.keys(grouped).map((key) => {
    return {
      status: key,
      quantity: sumBy(grouped[key], item => item?.stock_quantity),
      color: grouped[key][0]?.status_detail?.color,
    };
  }), ['status'], ['asc']);
});

const custom_fields = computed(() => inventory_store.get_custom_fields({ attached_to: 'item' }, true));

const get_custom_field_name = computed(() => {
  return field => field?.item_meta?.properties?.label || field.name;
});

function editPurchaseOrderHandler() {
  patchPurchaseOrderPopup({
    attrs: {
      item: item_details.value,
      orderedQty: state.item_procurement_details?.ordered_qty || 0,
      onUpdate: async () => {
        await getProcurementDetails();
        closePurchaseOrderPopup();
      },
      onClose() { closePurchaseOrderPopup(); },
    },
  });
  openPurchaseOrderPopup();
}

function editPoScopeHandler() {
  patchPoScopePopup({
    attrs: {
      item: item_details.value,
      itemProcurementDetails: {
        ...state.item_procurement_details,
        warehouses: state.item_procurement_details?.warehouses?.sort((a, b) => a.instances.length - b.instances.length) || [],
      },
      onUpdate: async () => {
        await getProcurementDetails();
        closePoScopePopup();
      },
      onClose() { closePoScopePopup(); },
    },
  });
  openPoScopePopup();
}

function getCustomFieldValue(uid) {
  const value = inventory_store.get_active_item_details?.custom_fields?.find(
    field => field.uid === uid,
  )?.value;
  return (Array.isArray(value) && !value.length) ? null : (value || null);
}

async function getProcurementDetails() {
  try {
    state.is_loading = true;
    const request = {
      id: inventory_store?.active_item_uid,
      asset_id: route.params.asset_id,
      query: {
        asset: route.params.asset_id,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      },
    };
    const { data } = await $services.inventory_items.get_item_procurement(request);
    state.item_procurement_details = data?.results;
  }
  catch (error) {
    logger.log('🚀 ~ getProcurementDetails ~ error:', error);
  }
  finally {
    state.is_loading = false;
  }
}

watch(() => inventory_store?.active_item_uid, () => {
  if (route.params.asset_id)
    getProcurementDetails();
}, { immediate: true });
</script>

<template>
  <template v-if="stocks_by_status?.length">
    <div class="flex items-center gap-4 flex-wrap">
      <div v-for="item in stocks_by_status" :key="item.resource_id">
        <div class="border px-3 py-2 min-w-36 rounded-lg" :style="`background-color:${item.color}1a;border:1px solid ${item.color}40`">
          <div class="text-sm font-medium">
            {{ item.status }}
          </div>
          <div class="text-base font-semibold">
            {{ item?.quantity }}
          </div>
        </div>
      </div>
    </div>
    <hr>
  </template>
  <template v-if="route.params.asset_id && auth_store.check_permission('view_bom', route.params.asset_id)">
    <div class="text-sm">
      <div class="font-semibold mb-3">
        {{ $t('Order Details') }}
      </div>
      <div v-if="state.is_loading" class="text-sm text-gray-500 mt-3">
        {{ $t('Loading') }}...
      </div>
      <div v-else class="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-5 text-gray-500">
            {{ $t('Purchase order') }}:
          </div>
          <div class="col-span-7 flex gap-2">
            <div>{{ state.item_procurement_details?.ordered_qty || 0 }}</div>
            <HawkButton v-if="auth_store.check_permission('modify_bom', route.params.asset_id)" type="link" class="h-auto hover:underline" @click="editPurchaseOrderHandler">
              {{ $t('Edit') }}
            </HawkButton>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-5 text-gray-500">
            {{ $t('Scope') }}:
          </div>
          <div class="col-span-7 flex gap-2">
            <div>{{ state.item_procurement_details?.scope || 0 }}</div>
            <HawkButton v-if="auth_store.check_permission('modify_bom', route.params.asset_id)" type="link" class="h-auto hover:underline" @click="editPoScopeHandler()">
              {{ $t('Edit') }}
            </HawkButton>
          </div>
        </div>
      </div>
    </div>
    <hr>
  </template>
  <div class="text-sm">
    <div class="font-semibold mb-3">
      {{ $t('Details') }}
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-5 text-gray-500">
          {{ $t('Cost per item') }}:
        </div>
        <div class="col-span-7">
          {{ `${getCurrencyDetails(item_details?.cost_currency)?.symbol} ${(Number(item_details?.cost).toFixed(2))}` }}
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-5 text-gray-500">
          {{ $t('Unit of measure') }}:
        </div>
        <div class="col-span-7">
          {{ inventory_store.uom_map?.[item_details?.uom]?.name }} ({{ inventory_store.uom_map?.[item_details?.uom]?.symbol }})
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-5 text-gray-500">
          {{ $t('Lead time') }}:
        </div>
        <div class="col-span-7">
          {{ item_details?.lead_time }} {{ $t('days') }}
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-5 text-gray-500">
          {{ $t('Reorder point') }}:
        </div>
        <div class="col-span-7">
          {{ item_details?.reorder_point }} {{ inventory_store.uom_map?.[item_details?.uom]?.symbol }}
        </div>
      </div>
      <div
        v-for="custom_field in custom_fields"
        :key="custom_field.uid" class="grid grid-cols-12 gap-4"
      >
        <div class="col-span-5 text-gray-500 break-all">
          {{ get_custom_field_name(custom_field) }}:
        </div>
        <div class="col-span-7 break-all">
          <InventoryCustomField
            :field="custom_field"
            :value="getCustomFieldValue(custom_field?.uid)"
          />
        </div>
      </div>
    </div>
  </div>
  <template v-if="!warehouse_id">
    <hr>
    <InventoryItemStockInformation />
  </template>
  <hr>
  <InventoryItemTransactions :style="{ maxWidth: `${container_width}px` }" />
</template>
