<script setup>
import { ref } from 'vue';
import { intersection, isEqual } from 'lodash-es';
import PmActivitiesTableComponent from '~/dashboard/components/widgets/pm-widgets/pm-activities-table-component.vue';
import PmTransposeTableComponent from '~/dashboard/components/widgets/pm-widgets/pm-transpose-table-component.vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import graphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const dashboard_schedule_store = useDashboardScheduleStore();
const $services = inject('$services');

const data_source = ref(null);
const response_data = ref([]);
const loading = ref(false);
const payload = ref({});

const legend_map = {
  actual_duration: 'Actual Duration',
  actual_progress: 'Actual Progress',
  lag: 'Lag',
  lag_days: 'Lag Days',
  planned_duration: 'Planned Duration',
  planned_progress: 'Planned Progress',
  remaining_duration: 'Remaining Duration',
  remaining_progress: 'RemainingProgress',
  free_slack: 'Free Slack',
  total_slack: 'Total Slack',
  overdue_days: 'Over Due Days',
  absolute_remaining_duration: 'Absolute Remaining Duration',
  absolute_work_rate: 'Absolute Work Rate',
  planned_work_rate: 'Planned Work Rate',
  actual_work_rate: 'Actual Work Rate',
  bl_work_rate: 'BlWork Rate',
  remaining_work_rate: 'Remaining Work Rate',

};
const widget_data = computed(() => props.data?.data || {});

async function getReports() {
  loading.value = true;
  payload.value = dashboard_schedule_store.parse_schedule_form_to_server_format(widget_data.value);

  try {
    const { data } = await $services.project_management.get_graph({ body: payload.value });
    response_data.value = data;
    if (data && Object.keys(data.data).length) {
      const chart_data = Object.values(data.data);
      const response = [];
      const activity_keys = Object.keys(chart_data[0]);
      const allowed_keys = intersection(activity_keys, Object.keys(legend_map));
      allowed_keys.forEach((key) => {
        response.push({
          seriesname: legend_map[key],
          data: chart_data.map((act) => {
            const activity = toRaw(act);
            return { value: activity[key] };
          }),
        });
      });
      data_source.value = {
        categories: [
          {
            category: Object.values(chart_data).map((a) => {
              const name = payload.value.properties.activity_table_activities.find(activity => activity.id === a.id);
              return { label: name.name };
            }),
          },
        ],
        dataset: response,
      };
      loading.value = false;
    }
    else {
      loading.value = false;
      return [];
    }
  }
  catch (err) {
    loading.value = false;
  }
}
const chart_config = computed(() => props.data.data.chart_config);
const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  width: '100%',
  renderAt: `combined-${props.id}`,
  type: chart_config.value?.chart_display_mode === 'Unstacked' ? 'msbar2d' : 'stackedbar2d',

  dataFormat: 'json',
  dataSource: {
    chart: {
      theme: 'fusion',
      xAxisName: chart_config.value?.x_label || payload?.value?.x?.label || 'Activity',
      yAxisName: chart_config.value?.y_label || payload?.value?.y?.label || '',
      subCaption: chart_config.value?.description || '',
      caption: chart_config.value?.title || '',
      plotToolText: '<b>$xAxisName:</b> $label<br>$seriesName:</b> $value',
      showlegend: chart_config.value?.legend_position === 'none' ? '0' : '1',
      legendPosition: !chart_config.value?.legend_position || chart_config.value?.legend_position === 'none' || !chart_config.value?.legend_position ? 'bottom' : chart_config.value?.legend_position,
      showValues: !chart_config.value?.display_values ? '0' : '1',
      formatNumberScale: chart_config.value?.prettify_values === false ? '0' : '1',
    },
    ...data_source.value,
  },

}));

watch(() => props.data.data, async (new_val, old_val) => {
  delete new_val?.columns_widths;
  delete old_val?.columns_widths;
  if (!isEqual(new_val, old_val))
    await getReports();
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />
    <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
      <graphComponent v-if="!loading && props.data.data.activity_table_chart && graph_config?.renderAt" :id="`combined-${props.id}`" :configuration="graph_config" :table_chart="true" :chart_config="chart_config" :handle_print="false" />
      <component :is="props.data.data.transpose ? PmTransposeTableComponent : PmActivitiesTableComponent" v-if="!loading && payload" :id="props.id" :data="props.data" :content_height="content_height" :response_data="response_data" :graph_config="graph_config" :payload="payload" />
    </div>
  </div>
</template>
