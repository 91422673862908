<script setup>
import { useModal } from 'vue-final-modal';
import { storeToRefs } from 'pinia';
import { pullAt } from 'lodash-es';
import { useDashboardCustomStore } from '~/dashboard/store/dashboard-custom.store.js';
import DashboardCustomDataPopup from '~/dashboard/components/custom-handson/dashboard-custom-data-popup.vue';

const emit = defineEmits(['update']);

const dashboard_custom_store = useDashboardCustomStore();

const {
  custom_configuration,
} = storeToRefs(dashboard_custom_store);

const csv_initial_data = computed(() => custom_configuration.value?.csv_data?.data);

function format_graph_data(csv_items) {
  let data;
  if (csv_items[0].length === 1) {
    data = [];
  }
  else if (custom_configuration.value?.type === 'donut' || csv_items[0].length === 2) {
    data = {
      data: csv_items?.map(item => ({
        label: item[0],
        value: item[1],
      })),
    };
  }
  else {
    const category = csv_items?.map((item) => {
      return { label: item[0] };
    });
    pullAt(category, 0);
    data = {
      categories: [
        {
          category,
        },
      ],
      dataset: csv_items?.[0]
        ?.map((item, index) => ({
          seriesname: item,
          data: csv_items
            .map(item => ({ value: item[index] }))
            .slice(1),
        }))
        .slice(1),
    };
  }
  return data;
}

function saveData(data) {
  emit('update', {
    data,
    formatted_data: format_graph_data(data),
  });
}

const csv_data_popup = useModal({
  component: DashboardCustomDataPopup,
  attrs: {
    onClose() {
      csv_data_popup.close();
    },
    onSave(data) {
      saveData(data);
      csv_data_popup.close();
    },
  },
});
</script>

<template>
  <div v-if="csv_initial_data?.length" class="text-sm font-medium mb-4">
    Widget values are set
  </div>
  <HawkButton
    type="outlined"
    class="w-fit mb-4"
    @click="csv_data_popup.open()"
  >
    Import values
  </HawkButton>
</template>
