import SystemModel from '~/system-model/pages/system-model.vue';
import SmTemplates from '~/system-model/pages/sm-templates.vue';
import smTemplateDetails from '~/system-model/pages/sm-template-details.vue';

const routes = [
  {
    path: '/:asset_id?/system-model',
    name: 'system-model',
    component: SystemModel,
    redirect: { name: 'sm-templates' },
    meta: {
      title: 'System Model',
    },
    children: [
      {
        path: 'templates',
        component: SmTemplates,
        name: 'sm-templates',
        meta: {
          title: 'System Model Templates',
          module: 'system-model',
        },
      },
      {
        path: 'templates/:template_id',
        component: smTemplateDetails,
        name: 'sm-template-details',
        meta: {
          title: 'System Model Template Details',
          module: 'system-model',
        },
      },
    ],
  },
];
export default routes;
