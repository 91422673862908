<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useRouter } from 'vue-router';
import { getCookie } from '~/common/utils/common.utils.js';
import PmExportModal from '~/project-management/components/menus/pm-export-modal.vue';
import PmVersionsModal from '~/project-management/components/menus/pm-versions-modal.vue';
import PmRenameModal from '~/project-management/components/menus/pm-rename-modal.vue';
import PmConfigureAutoSyncModal from '~/project-management/components/menus/pm-configure-auto-sync-modal.vue';
import PmSyncHistoryModal from '~/project-management/components/menus/pm-sync-history-modal.vue';
import PmSyncNowModal from '~/project-management/components/menus/pm-sync-now-modal.vue';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import HawkShare from '~/common/components/organisms/hawk-share.vue';
import { useExportProgressReport } from '~/project-management/composables/export-progress-report.composable.js';

const $t = inject('$t');
const $toast = inject('$toast');
const router = useRouter();

const project_management_store = useProjectManagementStore();
const common_store = useCommonStore();

const { active_schedule, active_view } = storeToRefs(project_management_store);

const { modify_config, set_view_dirtiness, delete_schedule, update_active_schedule } = project_management_store;

const { setExporting, is_report_exporting, exportScheduleReports, cancelExportingScheduleReports } = useExportProgressReport();

const layout_items = [
  {
    uid: 'grid-timeline',
    leftSlot: IconHawkGanttGridTimeline,
    rightSlot: 'grid-timeline',
    action: 'grid-timeline',
    tooltip_text: $t('Grid-timeline view'),
  },
  {
    uid: 'grid',
    leftSlot: IconHawkGanttGrid,
    rightSlot: 'grid',
    action: 'grid',
    tooltip_text: $t('Grid view'),
  },
  {
    uid: 'timeline',
    leftSlot: IconHawkGanttTimeline,
    rightSlot: 'timeline',
    action: 'timeline',
    tooltip_text: $t('Timeline view'),
  },
];

const auto_sync_modal = useModal({
  component: PmConfigureAutoSyncModal,
  attrs: {
    onClose() {
      auto_sync_modal.close();
    },
  },
});

const sync_now_modal = useModal({
  component: PmSyncNowModal,
  attrs: {
    onClose() {
      sync_now_modal.close();
    },
  },
});

const sync_history_modal = useModal({
  component: PmSyncHistoryModal,
  attrs: {
    onClose() {
      sync_history_modal.close();
    },
  },
});

const rename_modal = useModal({
  component: PmRenameModal,
  attrs: {
    onClose() {
      rename_modal.close();
    },
  },
});

const versions_modal = useModal({
  component: PmVersionsModal,
  attrs: {
    onClose() {
      versions_modal.close();
    },
  },
});

const share_modal = useModal({
  component: HawkShare,
});

const export_modal = useModal({
  component: PmExportModal,
  attrs: {
    onClose() {
      export_modal.close();
    },
  },
});

const delete_popup = useModal({
  component: HawkDeletePopup,
});

const menu_items = computed(() => [
  [
    'sync_progress',
    IconHawkRefreshCcwFive,
    'Sync progress',
    null,
    [
      [
        'sync_now',
        IconHawkZap,
        'Sync Now',
        () => {
          sync_now_modal.open();
        },
      ],
      [
        'configure_auto_sync',
        IconHawkToolOne,
        'Configure auto sync',
        () => {
          auto_sync_modal.open();
        },
      ],
      [
        'history',
        IconHawkClockFastForward,
        'History',
        () => {
          sync_history_modal.open();
        },
      ],
    ].map((item) => {
      return {
        name: item[0],
        left_icon: item[1],
        label: $t(item[2]),
        on_click: item[3],
        disabled: !!item?.[4],
      };
    }),
  ],
  [
    'export',
    IconHawkDownloadOne,
    'Export',
    null,
    [
      [
        'export_schedule',
        IconHawkFileDownloadTwo,
        'Schedule',
        () => export_modal.open(),
      ],
      [
        'export_progress_history',
        IconHawkBarChartSquareDown,
        'Progress history',
        () => setExporting(true),
      ],
    ].map((item) => {
      return {
        name: item[0],
        left_icon: item[1],
        label: $t(item[2]),
        on_click: item[3],
        disabled: !!item?.[4],
      };
    }),
  ],
  [
    'versions',
    IconHawkUserOne,
    'Versions',
    () => versions_modal.open(),
  ],
  [
    'share',
    IconHawkShareSeven,
    'Share',
    () => openShareModal(),
  ],
  [
    'rename',
    IconHawkPencilOne,
    'Rename',
    () => rename_modal.open(),
  ],
  [
    'delete',
    IconHawkTrashThree,
    'Delete',
    () => scheduleDeleteHandler(),
  ],
].map((item) => {
  return {
    name: item[0],
    left_icon: item[1],
    label: $t(item[2]),
    on_click: item[3],
    submenu_items: item?.[4],
  };
}));

function changeActiveLayout(layout_uid) {
  modify_config({ key: 'layout', value: layout_uid });
  set_view_dirtiness(true);
}

function scheduleDeleteHandler() {
  delete_popup.patchOptions(
    {
      attrs: {
        header: $t('Delete'),
        content: `${$t('Are you sure you want to delete this schedule')}?`,
        match_text: `${active_schedule.value.name || ''}`,
        match_text_input_placeholder: `${$t('Enter the name of the schedule to delete')}`,
        button_text: $t('Delete'),
        onClose() {
          delete_popup.close();
        },
        confirm: async () => {
          try {
            const active_schedule_uid = active_schedule.value.uid;
            await delete_schedule(active_schedule_uid);
            delete_popup.close();
            for (const key of Object.keys(common_store.assets_custom_fields_map))
              if (common_store.assets_custom_fields_map[key].properties.schedule_uid === active_schedule_uid) {
                delete common_store.assets_custom_fields_map[key].properties.schedule_uid;
                delete common_store.assets_custom_fields_map[key].properties.activity_uid;
              }
            router.push({ name: 'project-management' });
          }
          catch (err) {
            $toast({
              title: $t('Something went wrong'),
              text: $t('Please try again'),
              type: 'error',
            });
          }
        },
      },
    },
  );
  delete_popup.open();
}

async function onShare(data) {
  const members = {};
  let public_access = false;
  if (data.global_access_level === 'read')
    public_access = true;
  data.users.forEach((element) => {
    members[element.uid] = element.access;
  });
  data.teams.forEach((element) => {
    members[element.uid] = element.access;
  });
  await update_active_schedule(
    active_schedule.value.uid,
    {
      members,
      public: public_access,
    },
    true,
  );
}

async function toggleDynamicness() {
  await update_active_schedule(
    active_schedule.value.uid,
    {
      is_dynamic_loading: !active_schedule.value.is_dynamic_loading,
    },
    true,
  );
}

function openShareModal() {
  const members = [];
  const teams = [];
  const all_members = Object.entries(active_schedule.value?.members);
  all_members.forEach((element) => {
    const new_el = {
      uid: element[0],
      access: element[1],
    };
    const teams_uids = Object.keys(common_store.teams_map);
    if (teams_uids?.includes(new_el.uid))
      teams.push(new_el);
    else members.push(new_el);
  });
  let global_access_level;
  if (active_schedule.value?.public)
    global_access_level = 'read';

  share_modal.patchOptions({
    attrs: {
      onClose() {
        share_modal.close();
      },
      is_modal: true,
      is_global_access_visible: true,
      owner_uid: active_schedule.value?.owner?.uid,
      teams,
      members,
      global_access_level,
      global_access_levels: [
        { name: 'no_access', label: $t('No access') },
        { name: 'read', label: $t('Can view'), description: $t('Can view and download this file') },
      ],
      access_levels: [
        { name: 'read', label: $t('Can view'), description: $t('Can view and download this file') },
        { name: 'write', label: $t('Can edit'), description: $t('pm-hawk-share-edit-description') },
      ],
      get_share_data(data) {
        onShare(data);
      },
    },
  });
  share_modal.open();
}
</script>

<template>
  <HawkExportToast
    v-if="is_report_exporting"
    :submit="exportScheduleReports"
    :progress_text="$t('Exporting progress report')"
    :completed_text="$t('Progress report exported successfully')"
    @cancel="cancelExportingScheduleReports"
    @close="() => setExporting(false)"
  />
  <div class="flex items-center mb-6">
    <div class="flex items-center justify-center p-1 rounded-lg cursor-pointer w-9 h-9 hover:bg-gray-50" @click="$router.push({ name: 'project-management' })">
      <IconHawkChevronLeft />
    </div>
    <h1 class="flex items-center ml-2 font-semibold text-md">
      <span class="mr-2">{{ active_schedule.name }}</span>
      <IconHawkInfoCircle
        v-tippy="{
          content: `<span class='font-medium'>${$t('Last modified')}</span>: ${$date(active_schedule.updated_at, 'MMM D, YYYY, h:mm a')}`,
          placement: 'right',
          allowHTML: true,
        }"
        class="inline cursor-pointer"
      />
      <HawkButton v-if="getCookie('__PM_ENABLE_TOGGLE_BUTTON_FOR_DYNAMIC_LOADING')" size="xs" type="outlined" class="ml-4" @click="toggleDynamicness">
        Set as {{ active_schedule?.is_dynamic_loading ? 'not dynamic' : 'dynamic' }}
      </HawkButton>
    </h1>
    <HawkButtonGroup
      :items="layout_items"
      :active_item="active_view.data.layout"
      icon
      size="md"
      class="ml-auto"
      :class="{ 'mr-3': active_schedule.can_modify }"
      @grid-timeline="changeActiveLayout('grid-timeline')"
      @grid="changeActiveLayout('grid')"
      @timeline="changeActiveLayout('timeline')"
    />
    <HawkMenu
      v-if="active_schedule.can_modify"
      :items="menu_items"
      additional_trigger_classes="ring-gray-100"
      additional_dropdown_classes="!mt-1"
      additional_item_classes="w-[208px] h-10 hover:bg-gray-50 cursor-pointer rounded-lg !px-2 mb-1"
    >
      <template #trigger>
        <hawk-button icon type="outlined">
          <IconHawkDotsVertical class="w-5 h-5" />
        </hawk-button>
      </template>
      <template #item="{ item }">
        <div v-if="!['sync_progress', 'export'].includes(item.name)" class="flex items-center text-sm font-medium text-gray-700">
          <component :is="item.left_icon" class="inline mr-2 text-gray-500" />
          {{ item.label }}
        </div>
        <HawkMenu
          v-else
          :items="item.submenu_items"
          class="flex items-center w-full"
          additional_trigger_classes="w-full !ring-0"
          additional_dropdown_classes="right-[108%] !-top-[11px] !bottom-auto"
          additional_item_classes="w-[232px] h-10 hover:bg-gray-50 cursor-pointer rounded-lg !px-2 mb-1"
          position="bottom-left"
        >
          <template #trigger>
            <div class="flex justify-between w-full py-3">
              <div class="flex items-center text-sm font-medium text-gray-700">
                <component :is="item.left_icon" class="inline mr-2 text-gray-500" />
                {{ item.label }}
              </div>
              <IconHawkChevronRight class="text-gray-600" />
            </div>
          </template>
          <template #item="{ item: sync_item }">
            <div class="flex items-center text-sm font-medium text-gray-700" :class="{ 'pointer-events-none': item.disabled }">
              <component :is="sync_item.left_icon" class="inline mr-2 text-gray-500" />
              {{ sync_item.label }}
            </div>
          </template>
        </HawkMenu>
      </template>
    </HawkMenu>
  </div>
</template>
