<script setup>
import { storeToRefs } from 'pinia';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_view } = storeToRefs(project_management_store);
const { modify_config, set_view_dirtiness } = project_management_store;

const group_by_items = [
  ['wbs', 'WBS'],
  ['start_date', 'Start date'],
  ['end_date', 'End date'],
  ['planned_start', 'Planned start date'],
  ['planned_finish', 'Planned finish date'],
  ['actual_start', 'Actual start date'],
  ['actual_finish', 'Actual finish date'],
  ['total_duration', 'Duration'],
  ['total_slack', 'Total slack'],
  ['free_slack', 'Free slack'],
  ['progress', 'Progress'],
  ['critical', 'Critical'],
].map((item) => {
  const obj = {
    uid: item[0],
    label: $t(item[1]),
  };

  return {
    ...obj,
    on_click: () => onOptionSelected(obj),
  };
});

const state = reactive({
  active_group: {
    option: group_by_items[0],
    suboption: null,
  },
});

const active_group_text = computed(() => {
  if (!active_view.value.data.group_by?.length)
    return 'WBS';
  return state.active_group.option?.label + (state.active_group.suboption?.label ? ` → ${state.active_group.suboption?.label}` : '');
});

onMounted(() => setInitialValue());

function get_suboptions(option) {
  let res = [];

  if (
    [
      'start_date',
      'end_date',
      'planned_start',
      'planned_finish',
      'actual_start',
      'actual_finish',
    ].includes(option.uid)
  )
    res = [
      ['day', 'Days'],
      ['week', 'Weeks'],
      ['month', 'Months'],
      ['quarter', 'Quarters'],
      ['year', 'Years'],
    ];

  else if (['total_duration', 'free_slack', 'total_slack'].includes(option.uid))
    res = [
      ['day', 'Days'],
      ['week', 'Weeks'],
    ];

  if (!res.length)
    return [];

  return res.map((item) => {
    const obj = {
      uid: item[0],
      label: $t(item[1]),
    };

    return {
      ...obj,
      on_click: () => onSuboptionSelected(option, obj),
    };
  });
}

function onSuboptionSelected(option, suboption) {
  state.active_group.option = option;
  state.active_group.suboption = suboption;
  modify_config({ key: 'group_by', value: [state.active_group] });
  set_view_dirtiness(true);
}

function onOptionSelected(option) {
  if (!get_suboptions(option).length) {
    if (option.uid === 'wbs') {
      modify_config({ key: 'group_by', value: [] });
      return;
    }

    state.active_group = {
      option,
      suboption: null,
    };
    modify_config({ key: 'group_by', value: [state.active_group] });
    set_view_dirtiness(true);
  }
}

function setInitialValue() {
  const group_by = active_view.value.data.group_by?.[0] || null;
  if (group_by)
    state.active_group = group_by;
}
</script>

<template>
  <hawk-menu
    :items="group_by_items"
    additional_dropdown_classes="right-full !-top-0 mr-4 !mt-0 !bottom-auto w-52"
    additional_trigger_classes="!ring-0"
    additional_item_classes="!p-0"
    position="bottom-left"
  >
    <template #trigger>
      <div v-tippy="{ content: active_group_text, placement: 'bottom' }" class="flex items-center h-9 hover:bg-gray-50 rounded-lg py-2 px-3 -ml-3 w-[232px]">
        <div class="text-sm text-ellipsis overflow-hidden relative whitespace-nowrap">
          <span class="text-gray-500">{{ $t('Group by') }}: </span>
          <span class="font-medium">{{ active_group_text }}</span>
        </div>
        <IconHawkChevronRight class="ml-auto" />
      </div>
    </template>
    <template #item="{ item }">
      <template v-if="get_suboptions(item).length">
        <hawk-menu
          :items="get_suboptions(item)"
          additional_dropdown_classes="right-full !-top-0 mr-1 !mt-0 !bottom-auto"
          additional_trigger_classes="!ring-0 w-full"
          position="bottom-left"
          class="w-full"
        >
          <template #trigger>
            <div class="flex items-center w-full px-3 h py-3">
              <span class="whitespace-nowrap">
                {{ item.label }}
              </span>
              <IconHawkChevronRight class="ml-auto" />
            </div>
          </template>
        </hawk-menu>
      </template>
      <div v-else class="flex items-center w-full px-3 h py-3">
        {{ item.label }}
      </div>
    </template>
  </hawk-menu>
</template>
