<script setup>
import { computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { usePlansStore } from '../store/plans.store';
import { useSheetStore } from '../store/sheet.store';
import { useAnnotationsStore } from '../store/annotations.store';
import { useCustomAnnotationHandlers } from '../composables/useCustomAnnotationHandlers';
import { useDocumentTools } from '../composables/useDocumentTools';

const route = useRoute();
const router = useRouter();

const annotations_store = useAnnotationsStore();
const plans_store = usePlansStore();
const sheet_store = useSheetStore();

const {
  fetch_tasks,
  fetch_forms,
  fetch_comments,
  add_fetched_custom_annotations,
} = useCustomAnnotationHandlers({ route, router });

const $t = inject('$t');
const document_viewer_instance = inject('document-viewer-instance');
const load_document = inject('load-document');

const {
  create_and_add_stamp_annotation,
} = useDocumentTools(document_viewer_instance);

const selected_sheet_name = ref(sheet_store.sheet.name);
const selected_sheet_description = ref(sheet_store.sheet.description);
const is_sheet_dropdown_open = ref(false);
const search_sheet = ref('');
const search_sheet_elem = ref(null);
const sheets = computed(() => plans_store.get_active_drawing_sheets.filter(sheet => sheet.name.toLowerCase().includes(search_sheet.value.toLowerCase())) ?? []);

async function selectSheetHandler(selected_sheet) {
  selected_sheet_name.value = selected_sheet.name;
  selected_sheet_description.value = selected_sheet.description;
  sheet_store.update_active_sheet(selected_sheet.uid, plans_store.sheets_map);
  sheet_store.update_selected_sheet_version(selected_sheet.current_version);
  router.replace({
    ...route,
    params: { sheet_id: selected_sheet.uid },
  });
  load_document(document_viewer_instance, selected_sheet.current_version.url);

  annotations_store.$reset();
  await Promise.all([annotations_store.fetch_annotations(selected_sheet.element.uid), fetch_tasks(), fetch_forms(), fetch_comments()]);
  add_fetched_custom_annotations(create_and_add_stamp_annotation);
}

function getSheetDisplayName(name, description) {
  const description_text = description ? ` (${description})` : '';
  return `${name}${description_text}`;
}
</script>

<template>
  <div class="bg-white border border-gray-300 rounded-md p-4">
    <span class="text-sm font-semibold block mb-2">
      {{ $t("Select sheet") }}
    </span>
    <hawk-menu
      :items="sheets"
      additional_dropdown_classes="max-h-44 scrollbar w-full"
      additional_trigger_classes="ring-gray-100"
      additional_header_classes="border-none pb-0"
      @select="selectSheetHandler"
      @wheel.stop
      @transition-leave="() => search_sheet_elem.focus()"
      @open="is_sheet_dropdown_open = true"
      @close="is_sheet_dropdown_open = false"
    >
      <template #trigger>
        <div class="min-w-[220px] h-9 border rounded-lg flex items-center justify-between px-3 text-sm">
          <input v-if="is_sheet_dropdown_open" ref="search_sheet_elem" v-model="search_sheet" type="text" name="search_sheet" placeholder="Search sheet" @click.stop @keydown.stop>
          <template v-else>
            <hawk-text :length="22" :content="getSheetDisplayName(selected_sheet_name, selected_sheet_description)" />
            <div>
              <IconHawkChevronDown class="text-gray-500 ml-2" />
            </div>
          </template>
        </div>
      </template>
      <template v-if="!sheets.length" #header>
        <div class="text-gray-400">
          {{ $t('No options found') }}
        </div>
      </template>
      <template #item="{ item }">
        <span class="text-sm w-full">
          <hawk-text :content="getSheetDisplayName(item.name, item.description)" />
        </span>
      </template>
    </hawk-menu>
  </div>
</template>
