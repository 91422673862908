<script setup>
import { keyBy } from 'lodash-es';
import { computed } from 'vue';

const props = defineProps({
  tools: {
    type: Array,
    required: true,
  },
  active_tool: {
    type: String,
  },
  horizontal_icons: {
    type: Boolean,
    default: false,
  },
});

const hover_tool = ref('');

const get_flattened_tools = computed(() => {
  const flattened_tools = [];

  props.tools.forEach((tool) => {
    flattened_tools.push({
      event: tool.event,
      tooltip: tool.tooltip,
      icon: tool.icon,
    });
    if (tool.sub_tools)
      tool.sub_tools.forEach((sub_tool) => {
        flattened_tools.push({
          event: sub_tool.event,
          tooltip: sub_tool.tooltip,
          icon: sub_tool.icon,
        });
      });
  });

  return flattened_tools;
});

const hash = computed(() => {
  return keyBy(get_flattened_tools.value, 'event');
});

function isSubtoolActive(tool) {
  return (
    tool?.sub_tools?.find((sub_tool) => {
      return sub_tool.event === props.active_tool;
    })
  );
}
function chooseValue(tool, key) {
  if (!hash.value[props.active_tool] || !isSubtoolActive(tool))
    return tool[key];
  else
  // if any of the sub_tool is active, return icon of the active sub_tool
    return hash.value[props.active_tool][key];
}

function removeHoverIcon() {
  hover_tool.value = '';
}
function subToolClickHandler(sub_tool_action) {
  if (!sub_tool_action)
    return;

  sub_tool_action();
  hover_tool.value = '';
}
</script>

<template>
  <div
    class="opacity-100 h-auto drop-shadow-md"
    :class="[horizontal_icons ? '!flex' : '']"
  >
    <div
      v-for="(tool, i) in tools"
      :key="tool.event"
      class="relative hover:bg-gray-200"
      :class="[tool.disabled ? 'pointer-events-none opacity-75' : null]"
    >
      <div
        :class="{
          '!bg-gray-800': active_tool === tool.event || isSubtoolActive(tool),
          'border-b w-9 h-11': !horizontal_icons,
          'border-r w-11 h-9': horizontal_icons,
          'first:rounded-l-md': horizontal_icons && i === 0,
          'last:rounded-r-md': horizontal_icons && i === tools.length - 1,
          'first:rounded-t-md': !horizontal_icons && i === 0,
          'last:rounded-b-md': !horizontal_icons && i === tools.length - 1,
        }"
        class="border bg-white flex items-center justify-center opacity-100 cursor-pointer hover:bg-gray-800 group"
        @mousedown.stop="() => tool.action?.()"
        @mouseover="hover_tool = tool.event"
        @mouseleave="removeHoverIcon"
      >
        <div v-tippy=" isSubtoolActive(tool) ? chooseValue(tool, 'tooltip') : tool.tooltip">
          <div v-if="tool.letter" class="text-gray-500">
            {{ tool.letter }}
          </div>
          <Component
            :is="isSubtoolActive(tool) ? chooseValue(tool, 'icon') : tool.icon"
            v-else
            class="text-gray-500 group-hover:text-white "
            :class="[
              active_tool === tool.event || isSubtoolActive(tool) ? 'text-white grayscale-[1] brightness-[0] invert-[1]' : null,
              tool.color ? null : 'group-hover:grayscale-[1] group-hover:brightness-[0] group-hover:invert-[1]',
            ]"
            :style="{ color: tool.color }"
          />
          <div
            v-if="(hover_tool === tool.event) && tool?.sub_tools?.length"
            class="absolute right-0"
            @mouseover="hover_tool = tool.event"
            @mouseleave="removeHoverIcon"
          >
            <!-- Wrapper div to handle mouse leave hover_tool correctly -->
            <div class="mt-3.5 w-44 bg-white p-2 rounded-md" :class="tool.container_class">
              <div
                v-for="(sub_tool, idx) of tool.sub_tools"
                :key="idx"
                @mouseover="hover_tool = tool.event"
                @mousedown.stop="() => !sub_tool.disabled && subToolClickHandler(sub_tool.action)"
                @wheel.stop
              >
                <div
                  class="flex items-center hover:bg-gray-50 p-2 rounded-md"
                  :class="[sub_tool.disabled ? 'pointer-events-none opacity-75' : null]"
                >
                  <Component :is="sub_tool.icon" :class="sub_tool.is_size" class="text-gray-500" :style="{ color: sub_tool.color }" />
                  <component :is="sub_tool.component" v-if="sub_tool.component" :custom_props="sub_tool.props" />
                  <span v-else class="text-sm ml-1.5">{{ sub_tool.tooltip }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
