<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  item: { type: Object, default: () => {} },
  on_save: { type: Function },
});

const emit = defineEmits(['updateInstanceForm']);

const inventory_store = useInventoryStore();

const { $t, auth_store, route } = useCommonImports();
const columns = [
  {
    header: $t('Blocks'),
    accessorKey: 'instance_name',
    id: 'instance_name',
  },
  {
    header: $t('Scope'),
    accessorKey: 'qty',
    id: 'qty',
    custom_classes: '!p-0 !pl-6 !w-[250px]',
  },
];
const warehouse_details = computed(() => inventory_store.get_active_warehouse_details(route?.params?.warehouse_id));

const state = reactive({
  is_editing: false,
  instances_form: {},
  is_saving: false,
});

function preventKeydown(e) {
  if (e.keyCode === 189 || e.keyCode === 187 || e.keyCode === 69 || e.keyCode === 38 || e.keyCode === 40 || e.key === '.')
    e.preventDefault();
}
function toggleEdit(is_editing = true) {
  state.is_editing = is_editing;
  state.instances_form = {};
  Object.values(props.item.instances || {}).forEach((instance) => {
    state.instances_form[instance.instance_uid] = instance.qty;
  });
}

async function handleSave() {
  state.is_saving = true;
  await props.on_save(state.instances_form);
  toggleEdit(false);
  state.is_saving = false;
}
</script>

<template>
  <div>
    <div class="mb-1 flex items-center justify-between">
      <div class="font-semibold">
        Block wise details
      </div>
      <template v-if="warehouse_details?.permissions?.modify_bom">
        <HawkButton v-if="state.is_editing" :loading="state.is_saving" type="link" @click="handleSave()">
          {{ $t('Save') }}
        </HawkButton>
        <HawkButton v-else type="link" @click="toggleEdit()">
          {{ $t('Edit scope') }}
        </HawkButton>
      </template>
    </div>
    <div class="border border-gray-100 rounded-[8px] w-[670px] max-h-60 scrollbar">
      <HawkTable
        :data="Object.values(item?.instances || {})"
        :columns="columns"
        :default_height="300"
        is_gapless
        cell_height="40px"
        disable_resize
        :show_menu_header="false"
      >
        <template #instance_name="{ data }">
          <div class="text-sm font-medium">
            {{ data.getValue() }}
          </div>
        </template>
        <template #qty="{ data }">
          <div v-if="!state.is_editing" class="text-sm text-gray-600">
            {{ data.getValue() }} {{ item.uom }}
          </div>
          <div v-else class="w-full" @click.stop>
            <input
              v-model="state.instances_form[data.row.original.instance_uid]"
              type="number"
              name=""
              class="h-[39px] outline-none px-2 bg-blue-50 text-sm w-full"
              @keydown="e => preventKeydown(e)"
              @input="emit('updateInstanceForm', state.instances_form)"
            >
          </div>
        </template>
      </HawkTable>
    </div>
  </div>
</template>
