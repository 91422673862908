<script setup>
import StandardMapDashboard from '~/dashboard/components/standard-dashboard/standard-map-dashboard.vue';
import StandardInventoryDashboard from '~/dashboard/components/standard-dashboard/standard-inventory-dashboard.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

defineProps({
  show_sidebar: {
    type: Boolean,
    default: false,
  },
});

const dashboard_store = useDashboardStore();
</script>

<template>
  <div>
    <StandardMapDashboard v-if="dashboard_store.current_dashboard?.report_data?.module === 'maps'" :show_sidebar="show_sidebar" />
    <StandardInventoryDashboard v-else-if="dashboard_store.scope === 'inventory'" />
  </div>
</template>
