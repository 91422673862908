<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { sortData } from '~/common/utils/common.utils';

const { auth_store, common_store, route, $t, $services, $toast } = useCommonImports();

const form = ref({});

const state = reactive({
  is_open: false,
  is_edit: false,
  show_warning: false,
});
const grouped_data = computed(() => {
  const assets_admins = {};
  for (const user of common_store.users) {
    for (const asset of user.admin_assets || [])
      assets_admins[asset] = [...(assets_admins[asset] || []), user];
  }
  return assets_admins;
});
const admin_users = computed(() => {
  return common_store?.users?.filter(user => !!user?.admin_assets?.length);
});
async function onSave() {
  try {
    await $services.users_v2.asset_admins_patch({
      asset_id: route?.params?.asset_id,
      body: form.value,
    });
    await common_store.update_global_data({ users: true });
    $toast({
      title: $t('Role updated'),
      text: $t('Your changes to the role have been successfully saved'),
      type: 'success',
    });
    state.is_edit = false;
  }
  catch (err) {
    logger.log(err);
    $toast({
      text: err?.data?.message || $t('Something went wrong'),
      title: $t('Error'),
      type: 'error',
    });
  }
}
</script>

<template>
  <div>
    <div class="px-6 py-2.5 grid hover:bg-gray-50 grid-cols-12 text-sm items-center cursor-pointer" @click="state.is_open = !state.is_open;state.show_warning = false">
      <div class="col-span-8">
        <div class="font-medium text-gray-900">
          {{ $t('Asset admins') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ route?.params?.asset_id
            ? $t('Full access to all modules and resources within the asset.')
            : $t('Full access to all modules and resources within the respective assets.') }}
        </div>
      </div>
      <div class="col-span-3">
        <HawkMembers :members="admin_users" type="badge" />
      </div>
      <div class="col-span-1 flex justify-end">
        <HawkButton icon type="text">
          <IconHawkChevronUp v-if="state.is_open" />
          <IconHawkChevronDown v-else />
        </HawkButton>
      </div>
    </div>
    <div v-if="state.is_open" class="relative p-6 border-t pt-3">
      <HawkButton
        v-if="!state.is_edit && !admin_users.length && (auth_store.logged_in_user_details?.is_owner
          || auth_store.logged_in_user_details?.is_manager
          || (auth_store.logged_in_user_details.is_internal && auth_store.check_permission('modify_users', route?.params?.asset_id)))" type="link"
        @click="state.is_edit = true;state.show_warning = false"
      >
        <IconHawkPlus />
        {{ $t('Add members') }}
      </HawkButton>
      <div v-else-if="state.is_edit" class="flex justify-between text-sm">
        <Vueform
          v-model="form"
          size="sm"
          :format-load="(data) => data"
          :display-errors="false"
          :endpoint="onSave" class="w-full"
          :columns="{ sm: { container: 12, label: 4, wrapper: 12 } }"
        >
          <div v-if="route?.params?.asset_id" class="col-span-12 grid gap-4">
            <div class="grid grid-cols-12 gap-8">
              <div class="col-span-12">
                <HawkAssigneeInput
                  :callbacks="{ user_filter: (item) => (item.organization && item.organization === auth_store?.current_organization_uid) }"
                  class="w-[568px]"
                  :multi="true"
                  :options="{
                    'name': route.params.asset_id,
                    'has_teams': false,
                    'placeholder': $t('Select members'),
                    'group-select': false,
                    'object': false,
                    'default': grouped_data[route.params.asset_id] || [],
                  }"
                  :show_scoped_users="false"
                  :show_scoped_teams="false"
                  :format_load="true"
                />
              </div>
            </div>
          </div>
          <div v-else class="col-span-12 grid gap-4 pt-1 max-h-[300px] scrollbar">
            <div v-for="(asset) in sortData(common_store.assets, 'name', ['asc'], true)" :key="asset.uid" class="grid grid-cols-12 gap-8">
              <div class="col-span-3">
                <HawkAssetName :uid="asset.uid" :length="1000" class="break-all" />
              </div>
              <div class="col-span-4">
                <HawkAssigneeInput
                  :callbacks="{ user_filter: (item) => (item.organization && item.organization === auth_store?.current_organization_uid) }"
                  class="w-full"
                  :multi="true"
                  :options="{
                    'name': asset.uid,
                    'has_teams': false,
                    'placeholder': $t('Select members'),
                    'group-select': false,
                    'object': false,
                    'default': grouped_data[asset.uid]?.map(item => item.uid) || [],
                  }"
                  :show_scoped_users="false"
                  :show_scoped_teams="false"
                  :asset_id="asset.uid"
                  :format_load="true"
                  @removed="state.show_warning = true"
                />
              </div>
            </div>
          </div>
          <div class="col-span-12 sticky bottom-0 bg-white">
            <hr>
            <div class="flex justify-between items-center gap-3 sticky bottom-0 mt-4">
              <div v-if="state.show_warning">
                <HawkAlertBanner color="warning">
                  <template #icon>
                    <IconHawkAlertTriangle />
                  </template>
                  <template #content>
                    {{ $t('Access provided directly or through teams will still be retained.') }}
                  </template>
                </HawkAlertBanner>
              </div>
              <div v-else />
              <div class="flex gap-3">
                <HawkButton type="outlined" @click="state.is_edit = false;state.show_warning = false">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  button-class="bg-blue-600"
                  name="submit"
                  :submits="true"
                >
                  {{ $t("Save") }}
                </ButtonElement>
              </div>
            </div>
          </div>
        </Vueform>
      </div>
      <template v-else>
        <div class="flex">
          <div class="flex-1">
            <div v-if="route?.params?.asset_id" class="flex gap-8">
              <HawkMembers :members="grouped_data[route.params.asset_id]" type="badge" />
            </div>
            <div v-else class="grid grid-cols-12">
              <div class="col-span-10 max-h-[300px] scrollbar grid gap-4">
                <div v-for="(users, key) in grouped_data" :key="key" class="grid grid-cols-12 gap-8">
                  <div class="col-span-3">
                    <HawkAssetName :uid="key" />
                  </div>
                  <div class="col-span-8">
                    <HawkMembers :members="users" type="badge" :max_badges_to_display="5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="col-span-2 flex justify-end">
              <HawkButton
                v-if="!state.is_edit && (auth_store.logged_in_user_details?.is_owner
                  || auth_store.logged_in_user_details?.is_manager
                  || (auth_store.logged_in_user_details.is_internal && auth_store.check_permission('modify_users', route?.params?.asset_id)))" type="link" @click="state.is_edit = true;state.show_warning = false"
              >
                {{ $t('Update') }}
              </HawkButton>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
