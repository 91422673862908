<script>
// --------------------------------- Imports -------------------------------- //
import { computed, nextTick, ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'FormFieldTreeElement',
  // ---------------------------------- Props --------------------------------- //
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  // ---------------------------------- Emits --------------------------------- //
  components: {
    FormFieldTree: defineAsyncComponent(() => import('~/forms/molecules/form-field-tree.vue')),
  },
}, {
  setup(props, { element }) {
    // ---------------------------- Injects/Provides ---------------------------- //

    // ----------------------- Variables - Pinia - consts ----------------------- //

    // --------------------- Variables - Pinia - storeToRefs -------------------- //

    // ------------------- Variables - Local - consts and lets ------------------ //

    // ------------------------ Variables - Local - refs ------------------------ //
    const defaultClasses = ref({
      container: '',
    });

    // ---------------------- Variables - Local - reactives --------------------- //

    // --------------------------- Computed properties -------------------------- //
    const is_disabled = computed(() => element.isDisabled.value);
    const is_visible = computed(() => element.visible.value);
    const default_props = computed(() => props.default);

    // -------------------------------- Functions ------------------------------- //
    function onSelect(selected_value) {
      nextTick(() => {
        element.update(selected_value[0]);
        element.form$.value.el$(props.options.options.name).update(selected_value[0]);
      });
    }
    function setDefault() {
      const update_value = typeof default_props.value === 'string'
        ? props.options.data.find(option => option.key === default_props.value)
        : default_props.value;

      element.update(update_value);
      element.form$.value.el$(props.options.options.name).update(update_value);
    }

    // -------------------------------- Watchers -------------------------------- //

    // ----------------------------- Lifecycle Hooks ---------------------------- //

    return {
      defaultClasses,
      is_disabled,
      onSelect,
      setDefault,
      is_visible,
      default_props,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <HawkLoader v-if="options.options_loading" container_class="m-0" />
      <div v-else :key="default_props?.key">
        <FormFieldTree
          v-if="is_visible && options.data.length"
          :key="options?.options?.val"
          :data="options.data"
          :tree_config="{
            options: {
              object: true,
              search: true,
              native: false,
              canClear: false,
              hideSelected: true,
              canDeselect: false,
              removeClasses: {
                SelectElement: {
                  select: {
                    optionSelectedDisabled: 'form-color-on-primary form-bg-primary',
                  },
                },
              },
              ...options.options,
            },
            select_type: 'LEAF_PRIORITY',
            select_view: 'SELECT',
            show_expanded_search: true,
            is_workflow: options.is_workflow,
          }"
          @onSelect="onSelect"
          @setDefaultVal="setDefault"
        />
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <!-- eslint-disable-next-line vue/valid-attribute-name -->
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
