<script setup>
import { useRoute } from 'vue-router';
import TerraCharts from '~/terra/components/charts/terra-charts.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  show_sidebar: {
    type: Boolean,
    default: false,
  },
});

const dashboard_store = useDashboardStore();
const route = useRoute();
const terra_store = useTerraStore(`dashboard_charts_${dashboard_store.current_dashboard?.uid}`);
onMounted(async () => {
  // Setting it in store itself
  if (!terra_store.container) {
    await terra_store.set_container({
      uid: dashboard_store.current_dashboard?.report_data?.data?.id,
      type: 'terra',
    });
    await terra_store.set_terra_workflows({
      asset_id: route.params.asset_id,
    });
  }
});
</script>

<template>
  <div>
    <TerraCharts
      v-if="terra_store.container"
      :key="props.show_sidebar" type="terra-charts-dashboard" :terra_store_name="`dashboard_charts_${dashboard_store.current_dashboard?.uid}`"
      :add_classes="dashboard_store.is_mobile_view ? '!w-full' : route.params.asset_id && !props.show_sidebar ? '!w-[calc(100vw-390px)]' : '!w-[calc(100vw-30px)]'"
    />
  </div>
</template>
