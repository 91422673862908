<script setup>
/*
  options: {
    // text
    truncate_config: {
      type: <String: 'fixed', 'adaptive'>,
      style: <String: 'truncate', 'truncateMiddle'>,
      length: <Number>,
    }
    rich_text: <Boolean>, [default: false]
    text_wrap: <Boolean>, [default: false]

    // number
    decimal_places: <Number>,

    // general
    addons: {
      before: <String>,
      after: <String>,
    },
  }
*/
import DOMPurify from 'dompurify';
import { setNumberDecimals } from '~/dashboard/print/utilities.js';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  options: {
    type: Object,
    required: true,
  },
});

const formatted_text = computed(() => props.value || props.options?.fallback || '-');

const formatted_number = computed(() => {
  if (Number.isNaN(props.value))
    return props.options?.fallback || 0;
  return setNumberDecimals(props.value, props.options?.decimal_places || 2);
});
</script>

<template>
  <div class="flex items-center flex-nowrap" :class="{ 'whitespace-nowrap': !options.text_wrap }">
    <!-- PREFIX -->
    <div v-if="options.addons?.before">
      {{ options.addons?.before }}
    </div>
    <slot name="before" />

    <!-- CONTENT -->
    <div v-if="options.field_type === 'text'" :class="options.classes">
      <p v-if="options?.rich_text" v-html="DOMPurify.sanitize(formatted_text, { ALLOWED_TAGS: [] })" />
      <HawkText
        v-else-if="options.truncate_config?.type"
        :content="formatted_text"
        :length="options.truncate_config?.length"
        :type="options.truncate_config?.style || 'truncate'"
      />
      <p v-else>
        {{ formatted_text }}
      </p>
    </div>
    <div v-else-if="options.field_type === 'number'">
      {{ formatted_number }}
    </div>

    <!-- SUFFIX -->
    <div v-if="options.addons?.after">
      {{ options.addons.after }}
    </div>
    <slot name="after" />
  </div>
</template>
