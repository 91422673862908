<script setup>
import { debounce, orderBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useInfiniteScroll } from '@vueuse/core';
import { useAuthStore } from '~/auth/stores/auth.store';
import { usePusherStore } from '~/common/stores/pusher.store.js';

const props = defineProps({
  resource: {
    type: Object,
    required: true,
  },
  load_more: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(['selectItem']);

const $services = inject('$services');
const $t = inject('$t');
const $date = inject('$date');
const task_store = inject('task_store');

const auth_store = useAuthStore();
const current_organization = auth_store.current_organization;
const current_user = auth_store.logged_in_user_details?.user_id;

const route = useRoute();

watchEffect(() => {
  const pusher_store = usePusherStore();
  if (pusher_store.is_initialized)
    task_store.subscribe_pusher({
      organization: current_organization?.uid,
      ...(route.params.asset_id ? { asset: route.params.asset_id } : {}),
    }, {
      has_comments: true,
      comment_stats: true,
      latest_comment: true,
    });
}, { flush: 'post' });

const conversations_list = ref(null);
const is_tasks_loading = ref(false);

useInfiniteScroll(
  conversations_list,
  debounce(async () => {
    if (is_tasks_loading.value)
      return;

    is_tasks_loading.value = true;
    await props.load_more();
    is_tasks_loading.value = false;
  }, 100),
  { distance: 5 },
);

const tasks = computed(() => orderBy(task_store.tasks(), task => task?.latest_comment?.updated_at, 'desc') || []);

onMounted(async () => {
  if (task_store.tasks().length)
    await handleItemSelect(task_store.tasks()[0]);
});

async function handleItemSelect(task) {
  await task_store.get_task_attachments(task.uid);
  emit('selectItem', 'task', task);
  $services.tasks.get({ id: task.uid });
  // Set unread count to 0 and last viewed to last viewed
  setTimeout(() => {
    task_store.update_comment_stats(task.uid, 0);
    task_store.update_last_read(task.uid, current_user, new Date());
  }, 3000);
}
</script>

<template>
  <div>
    <p class="font-semibold text-lg my-4">
      {{ $t('Conversations') }}
    </p>
    <div
      v-if="tasks.length"
      ref="conversations_list"
      class="rounded-lg border h-[calc(100vh_-_150px)] scrollbar"
    >
      <div v-for="task in tasks" :key="task.uid" @click.stop="handleItemSelect(task)">
        <div
          class="flex justify-between items-center px-4 py-2 hover:bg-gray-50 group cursor-pointer border-b"
          :class="{ 'bg-gray-100': resource?.uid === task?.uid }"
        >
          <div class="flex items-center">
            <div class="h-14 flex flex-col justify-center">
              <p
                v-tippy="task?.name?.length > 20 ? task.name : ''"
                class="text-sm font-medium text-gray-900 whitespace-nowrap max-w-[225px] truncate"
              >
                {{ task.name }}
              </p>

              <div class="flex items-center">
                <div class="mr-[2px]">
                  <p v-if="current_user === task.latest_comment?.owner.uid " class="text-sm text-gray-600">
                    {{ $t('You') }}
                  </p>
                  <HawkMembers v-else :members="task.latest_comment?.owner.uid" type="label" :has_avatar="false" name_classes="!text-sm !font-normal" />
                </div>
                <span class="text-sm text-gray-600 text-center max-w-[250px] truncate" :class="{ 'font-semibold': task.comment_stats.unread_comments }">
                  :  {{ task.latest_comment?.text }}
                </span>
              </div>
            </div>
          </div>
          <div class=" text-sm">
            <div class="flex justify-end">
              <HawkBadge v-if="task.comment_stats.unread_comments" color="blue">
                {{ task.comment_stats.unread_comments }}
              </HawkBadge>
            </div>
            <div class="text-sm text-gray-600">
              {{ $date(task?.latest_comment?.updated_at, 'DATETIME_LONG') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="is_tasks_loading">
        <div v-for="i in 4" :key="i" class="border-b px-4 py-6">
          <hawk-skeleton custom_classes="w-full" />
        </div>
      </div>
    </div>
  </div>
</template>
