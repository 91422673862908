<script setup>
import { useThermStore } from '~/therm/store/therm.store';

const props = defineProps({
  defect_type_id: {
    type: Array,
    default: () => ([]),
  },
  on_update: {
    type: Function,
  },
});
const emits = defineEmits(['close']);

const therm_store = useThermStore();

const form$ = ref(null);

const defect_type_list = computed(() => {
  const feature_types = Object.values(therm_store.feature_types);
  if (feature_types.length)
    return feature_types.filter(type => type.id !== 100);
  return [];
});

async function onSave(formData, { data }) {
  await props.on_update({ ...data }, false);
  emits('close');
}

function onFormMounted(el$) {
  el$.load({ class_id: props.defect_type_id }, true);
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      ref="form$"
      :columns="{
        lg: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
      }"
      size="sm"
      :display-errors="false"
      :endpoint="onSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Defect type') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            v-bind="{
              'name': 'class_id',
              'label': $t('Defect type'),
              'autocomplete': 'off',
              'track-by': 'name',
              'label-prop': 'name',
              'value-prop': 'id',
              'search': true,
              'hide-selected': false,
              'items': defect_type_list,
              'can-clear': false,
              'can-deselect': false,
              'native': false,
            }"
          >
            <template #option="{ option }">
              <div class="flex items-center gap-x-2">
                <div
                  :style="
                    `border:1px solid ${
                      option.properties ? option.properties.color : 'red'
                    }; background: ${
                      option.properties ? option.properties.color : 'red'
                    }`
                  "
                  class="shrink-0 h-2.5 w-2.5 rounded-full border border-red-500 grid place-items-center"
                />
                <div class="text-sm font-gray-900 font-medium">
                  {{ option.name }}
                </div>
              </div>
            </template>
          </SelectElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement :disabled="defect_type_id === form$?.data?.class_id" button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
