<script setup>
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { stringToNumber } from '~/common/utils/common.utils.js';

const props = defineProps({
  members: {
    type: Object,
    default: () => {},
  },
});
const route = useRoute();
const transmittals_store = useTransmittalsStore();

const assignees = computed(() => {
  return (props.members?.submitter || []).filter(member => member.uid);
});

const external_users = computed(() => {
  return (props.members?.submitter || []).filter(member => member.email && !member.uid).map((member) => {
    return {
      uid: member.email,
      index: member.index,
      name: member.email,
      display_picture: null,
      type: 'external',
      is_online: false, // later use
      is_team: false,
    };
  });
});
</script>

<template>
  <!-- Refactor this code -->
  <div class="border border-gray-200 z-50 rounded-lg bg-white shadow-md p-4">
    <div class="grid grid-cols-12 my-2 items-center">
      <div class="col-span-3 text-sm font-medium text-gray-400">
        {{ $t('Owner') }}:
      </div>
      <div class="col-span-9 flex flex-wrap">
        <HawkMembers :members="members?.owner" type="badge" />
      </div>
    </div>
    <div class="grid grid-cols-12 my-2 items-center">
      <div class="col-span-3 text-sm font-medium text-gray-400">
        {{ $t('Submitters') }}:
      </div>
      <div class="col-span-9 flex flex-wrap">
        <div class="inline-flex items-center flex-wrap gap-2 my-1 mr-1">
          <MemberBadge
            v-for="member_details in external_users"
            :member_details="member_details"
            :img_src="member_details?.display_picture"
            :background_color="stringToNumber(member_details?.name)"
            :has_email="true"
          />
        </div>
        <HawkMembers v-if="assignees?.length || !external_users?.length" :members="assignees" type="badge" class="my-1 mr-1" />
      </div>
    </div>
    <div class="grid grid-cols-12 my-2 items-center">
      <div class="col-span-3 text-sm font-medium text-gray-400">
        {{ $t('Approvers') }}:
      </div>
      <div class="col-span-9 flex flex-wrap">
        <HawkMembers :members="members.approver" type="badge" class="my-1 mr-1" />
      </div>
    </div>
    <div class="grid grid-cols-12 my-2 items-center">
      <div class="col-span-3 text-sm font-medium text-gray-400">
        {{ $t('Reviewers') }}:
      </div>
      <div class="col-span-9 flex flex-wrap">
        <HawkMembers :members="members.reviewer" type="badge" class="my-1 mr-1" />
      </div>
    </div>
    <div class="grid grid-cols-12 my-2 items-center">
      <div class="col-span-3 text-sm font-medium text-gray-400">
        {{ $t('Cc/Information') }}:
      </div>
      <div class="col-span-9 flex flex-wrap">
        <HawkMembers :members="members.information" type="badge" class="my-1 mr-1" />
      </div>
    </div>
  </div>
</template>
