<script setup>
import { useModal } from 'vue-final-modal';
import { storeToRefs } from 'pinia';
import { useDashboardCustomStore } from '~/dashboard/store/dashboard-custom.store.js';
import DashboardCustomMapPopup from '~/dashboard/components/custom-map/dashboard-custom-map-popup.vue';

const emit = defineEmits(['update']);

const dashboard_custom_store = useDashboardCustomStore();

const {
  custom_configuration,
} = storeToRefs(dashboard_custom_store);

const initial_data = computed(() => custom_configuration.value?.map_boundaries || null);

const import_map_popup = useModal({
  component: DashboardCustomMapPopup,
  attrs: {
    onClose() {
      import_map_popup.close();
    },
    onSave(data) {
      emit('update', data);
      import_map_popup.close();
    },
  },
});
</script>

<template>
  <div v-if="initial_data" class="text-sm font-medium mb-4">
    Map data is set
  </div>
  <HawkButton
    type="outlined"
    class="w-fit mb-4"
    @click="import_map_popup.open()"
  >
    Import map
  </HawkButton>
</template>
