<script setup>
// --------------------------------- Imports -------------------------------- //
import DOMPurify from 'dompurify';
import { keyBy } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import { $date } from '~/common/utils/date.util';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  el$: {
    type: Object,
    default: () => ({}),
  },
  folder_data: {
    type: Object,
    default: () => {},
  },
});

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //
const extra_props = inject('extra-props');

// ----------------------- Variables - Pinia - consts ----------------------- //
const dms_settings_store = useDMSSettingsStore();

// ------------------- Variables - Local - consts and lets ------------------ //
const { folder_data } = extra_props;
const custom_fields_map = keyBy(dms_settings_store.custom_fields.filter(field => !field.deleted && field.active), 'uid');

// ------------------------ Variables - Local - refs ------------------------ //

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
function formatStartNumber(start_number, padding) {
  return start_number.padStart(padding, '0');
}
function generatePreview(data, form$) {
  const html = data?.html || data || '';
  const schemeArray = getSchemeArray(DOMPurify.sanitize(html) || '');
  let str = ' ';
  if (schemeArray.length) {
    schemeArray.forEach((item) => {
      const name = item.id === null || custom_fields_map[item.id] ? item.label : item.id;
      str += `${previewFormat(name, form$)}`;
    });
    return str;
  }
  return '';
};

function getSchemeArray(content) {
  const div = document.createElement('div');
  div.innerHTML = content;
  const scheme_arr = [];
  const nodes = div?.children[0]?.childNodes || [];
  Array.from(nodes).forEach((node) => {
    scheme_arr.push({
      label: node.textContent,
      id: node.getAttribute ? node.getAttribute('data-id') : null,
    });
  });
  div.remove();
  return scheme_arr;
};

function previewFormat(name, form$) {
  if (typeof (name) !== 'string')
    return name?.label;

  let result = '';
  const currentDate = new Date();
  const common_store = useCommonStore();

  switch (name) {
    case 'name':
      result = folder_data?.name;
      break;
    case 'day':
      result = currentDate.getDate();
      break;
    case 'month_number':
      result = new Date().getMonth() + 1;
      break;
    case 'month_name_short':
      result = currentDate.toLocaleString('en-us', { month: 'short' });
      break;
    case 'month_name_full':
      result = currentDate.toLocaleString('en-us', { month: 'long' });
      break;
    case 'year_short':
      result = currentDate
        .getFullYear()
        .toString()
        .slice(-2);
      break;
    case 'year_full':
      result = currentDate.getFullYear().toString();
      break;
    case 'created_at':
      result = folder_data.created_at;
      if (result)
        result = $date(result, 'DD/MM/YYYY');
      break;
    case 'created_by': {
      const user_details = common_store.get_user(folder_data.owner) || common_store?.internal_users_map?.[folder_data.owner];

      if (user_details)
        return user_details.first_name ? `${user_details.first_name} ${user_details.last_name}` : user_details.email;
      break;
    }
    case 'number':
      result = formatStartNumber(String(form$.el$('starting_number')?.value || 1), form$.el$('number_format')?.value?.length);
      break;
    default:
      result = name;
  }
  return result || name;
};

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div>
    <span v-if="DOMPurify.sanitize(el$.form$.el$('html')?.value || '', { ALLOWED_TAGS: [] })">
      {{ generatePreview(el$.form$.el$('html').value, el$.form$) }}
    </span>
    <span v-else class="text-gray-600">
      {{ $t('Schema Preview') }}
    </span>
  </div>
</template>
