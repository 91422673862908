<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import toGeoJson from '@mapbox/togeojson';
import { onMounted, watch } from 'vue';
import { isFileExtensionAllowed } from '~/common/utils/common.utils.js';
import FeatureTypes from '~/terra/components/feature-type-groups/feature-types.vue';
import { useTerraStore } from '~/terra/store/terra.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable';

const props = defineProps({
  popup_type: {
    type: String,
    default: 'map',
  },
  name: {
    type: String,
    default: '',
  },
  header: {
    type: String,
    default: 'New Map',
  },
  is_layer: {
    type: Boolean,
    default: false,
  },
  on_save: {
    type: Function,
  },
});

const terra_store = useTerraStore();

const $toast = inject('$toast');

const { parseFeature, getStyles } = useTerraHelperComposable();
let turf;
const form = ref({
  name: '',
  file: null,
});
const state = reactive({
  feature_collection: null,
  features_key_values: {},
});
function arraysEqual(a, b) {
  if (a === b)
    return true;
  if (a == null || b == null)
    return false;
  if (a.length !== b.length)
    return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i])
      return false;
  }

  return true;
}
async function import_geojson() {
  const file_data = form.value.file;

  if (!isFileExtensionAllowed(file_data?.name))
    return;

  const reader = new FileReader();
  reader.onload = async function (e) {
    let features = [];
    try {
      if (['.geojson', '.json'].some(type => file_data.name.includes(type)))
        features = await parseGeoJSON(e.target.result);

      else
        features = await parseKML(e.target.result);

      // parse data
      for (const i in features) {
        const feature = parseFeature(features[i]);
        feature.properties.hierarchyProperties = null;
        if (props.popup_type !== 'import')
          delete feature.properties?.project;
        feature.properties.name = String(feature.properties?.name || '');
        features[i] = feature;
      }
    }
    catch (error) {
      $toast({
        title: 'Error parsing features',
        type: 'error',
      });
      logger.error(error);
    }
    state.feature_collection = {
      type: 'FeatureCollection',
      features,
    };
    if (state.feature_collection?.features?.length)
      state.features_key_values = terra_store.extra_properties_group_by({ features: state.feature_collection.features });
    else
      state.features_key_values = {};
  };
  reader.readAsText(file_data);
}
async function parseGeoJSON(data) {
  try {
    const geojson = JSON.parse(data);
    const features = geojson?.features || [];
    return features;
  }
  catch (error) {
    throw new Error(`Error parsing GeoJSON: ${error.message}`);
  }
}
async function parseKML(data) {
  try {
    const kml = new DOMParser().parseFromString(data, 'text/xml');
    const kml_converted = toGeoJson.kml(kml, { styles: false });

    const features = (kml_converted?.features || []).map((feature) => {
      if (feature.geometry.geometries) {
      // Extract geometries from GeometryCollection
        const geometries = feature.geometry.geometries;
        let multiGeometry;
        geometries.forEach((geometry) => {
          const turfGeometry = turf.clone(geometry);
          if (!multiGeometry)
            multiGeometry = turfGeometry;
          else
            multiGeometry = turf.union(multiGeometry, turfGeometry);
        });
        feature.geometry = multiGeometry.geometry;
      }
      if (feature.geometry.type === 'Polygon') {
        feature.geometry.coordinates.forEach((ring) => {
        // Ensure the first and last points are the same
          if (!arraysEqual(ring[0], ring[ring.length - 1]))
            ring.push(ring[0]); // Add the first point to the end
        });
      }

      return feature;
    });
    return features;
  }
  catch (error) {
    throw new Error(`Error parsing KML: ${error.message}`);
  }
}
function updatedFeatures() {
  const features = state.feature_collection?.features || [];
  features.map((f) => {
    if (f.properties.project && !form.value.merge_features)
      delete f.properties?.project;
    if (f.properties?.extraProperties?.[form.value.categorize_by_property]) {
      const key = form.value.categorize_by_property;
      const value = f.properties?.extraProperties?.[key];
      const featureType = state.features_key_values?.[key]?.[value]?.featureType;
      if (featureType) {
        let oldfeatureTypeId = null;
        if (form.value.merge_features)
          oldfeatureTypeId = terra_store.features_hash?.[f.properties?.uid]?.properties?.featureTypeId || null;
        f.properties.oldfeatureTypeId = oldfeatureTypeId;
        f.properties.featureType = featureType.uid;
        f.properties.featureTypeId = featureType.id;
      }
    }
    return f;
  });
  return features;
}
async function submit() {
  try {
    let features = [];
    if (['map', 'import'].includes(props.popup_type))
      features = updatedFeatures();

    const geojson_data = { type: 'FeatureCollection', features };

    await props.on_save({ ...form.value, name: form.value.name, geojson_data });
    // Fly to uploaded features
    if (terra_store.map && ['map', 'import'].includes(props.popup_type) && features?.length) {
      const bounds = turf.bbox(geojson_data);
      await terra_store.map.fitBounds(bounds);
    }
  }
  catch (err) {
    logger.log(err);
    $toast({
      title: 'Importing features failed',
      text: 'Please try again',
      type: 'error',
    });
  }
}
watch(() => form.value.categorize_by_property, (curr, prev) => {
  const values_object = state.features_key_values?.[prev] || {};
  Object.keys(values_object).forEach((value) => {
    state.features_key_values[prev][value].featureType = null;
  });
});
onMounted(async () => {
  turf = await import('@turf/turf');
  form.value.name = props.name;
});
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="form" sync size="sm" :display-errors="false"
      :columns="{
        lg: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
      }"
      :endpoint="submit"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t(header) }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="form.categorize_by_property" :class="{ 'min-h-[400px]': form.categorize_by_property }">
          <TextElement v-if="['map', 'rename'].includes(popup_type)" name="name" :label="$t('Name')" class="mb-5" rules="required" />
          <FileElement
            v-if="['map', 'import'].includes(popup_type)"
            name="file"
            class="mb-5"
            :label="$t('Choose file')"
            :presets="['hawk_file_element']"
            :options="{
              clickable_text: $t('Upload'),
              text: $t('KML or GeoJSON files'),
              description: $t('.kml, .geojson formats supported'),
            }"
            accept=".geojson, .kml, .json"
            :auto="false"
            :drop="true"
            :use_uppy="false"
            @change="import_geojson"
          />
          <template v-if="popup_type === 'import'">
            <CheckboxElement
              class="mb-5 text-primary font-semibold"
              name="advance_options"
              :add-class="{
                input: 'hidden',
              }"
              :default="true"
            >
              <div class="flex items-center text-[#004EEB] ml-[-0.5rem]">
                <IconHawkChevronRight v-if="form && !form.advance_options" class="w-5 text-primary-700" />
                <IconHawkChevronDown v-else class="w-5 text-primary-700" />
                {{ $t('Advanced options') }}
              </div>
            </CheckboxElement>
            <CheckboxElement
              name="merge_features"
              class="mb-5"
              :conditions="[
                ['advance_options', true],
              ]"
              :default="false"
              :label="$t('Merge Features')"
            />
            <SelectElement
              name="categorize_by_property"
              class="mb-5"
              :conditions="[
                ['advance_options', true],
              ]"
              :placeholder="$t('Select')"
              :label="$t('Classify by property')"
              :items="Object.keys(state.features_key_values)"
              :native="false"
            />
            <template v-if="form.advance_options && form.categorize_by_property">
              <div v-for="(value_data, value) in state.features_key_values[form.categorize_by_property]" :key="value">
                <div class="grid grid-cols-12 grid-flow-col gap-2 mb-2 text-sm items-center">
                  <div class="col-span-5">
                    {{ value }}
                  </div>
                  <div class="col-span-1">
                    <IconHawkArrowRight />
                  </div>
                  <div class="col-span-6">
                    <HawkMenu additional_trigger_classes="!ring-0 !border-0" additional_dropdown_classes="!w-80 !max-h-60 scrollbar">
                      <template #trigger>
                        <HawkButton type="plain" size="sm">
                          <div
                            v-if="value_data?.featureType"
                            class="cursor-pointer w-3 h-3 rounded-full bg-gray-400"
                            :style="getStyles(value_data?.featureType)"
                          />
                          <div class="text-sm font-medium">
                            <HawkText :content="value_data?.featureType?.name || 'Select feature'" :length="32" />
                          </div>
                        </HawkButton>
                      </template>
                      <template #content="{ close }">
                        <FeatureTypes
                          type="dropdown" :dropdown_value="feature_type" @select="($event) => {
                            state.features_key_values[form.categorize_by_property][value].featureType = $event;
                            close();
                          }"
                        />
                      </template>
                    </HawkMenu>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-4 font-bold"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                :button-label="(popup_type === 'map' || name === '') ? $t('Create') : popup_type === 'rename' ? $t('Update') : $t('Import')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
