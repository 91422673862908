<script>
export default {
  name: 'Sidebar',
  props: {
    is_gapless: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
    width: {
      type: String,
      default: '600px',
    },
    height: {
      type: String,
      default: '100vh',
    },
    addition_classes: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: true,
    },
    emit_close: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sidebar_classes() {
      return [
        'z-[100]',
        'fixed',
        'top-0',
        'h-full',
        this.is_gapless ? '' : 'p-6',
        'bg-white',
        'scrollbar',
        'border',
        'shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)]',
        'transition-all',
        'duration-500',
      ];
    },
    getStyles() {
      let css = {};
      if (this.position === 'left')
        if (this.show)
          css = { left: '0px' };
        else
          css = { left: `-${this.width}px` };

      else if (this.position === 'right')
        if (this.show)
          css = { right: '0px' };
        else
          css = { right: `-${this.width}px` };
      return {
        ...css,
        width: `${this.width - (this.is_gapless ? 0 : 48)}px`,
      };
    },
  },
  methods: {
    emitClose() {
      if (this.show && this.emit_close)
        $emit('close');
    },
  },
};
</script>

<template>
  <div
    v-click-outside="emitClose" :class="[...sidebar_classes, addition_classes]"
    :style="{
      ...getStyles,
    }"
  >
    <div v-if="show">
      <slot />
    </div>
  </div>
</template>
