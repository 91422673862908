<script setup>
import { keyBy, omit } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { nanoid } from '~/common/utils/common.utils';
import { sanitizeAoA } from '~/common/utils/common.utils.js';
import NewFormModal from '~/forms/components/new-form/new-form-modal.vue';
import { useFormsStore } from '~/forms/store/forms.store';
import SmAddInstanceForm from '~/system-model/components/forms/sm-add-instance-form.vue';
import SmBulkUpdateInstanceForm from '~/system-model/components/forms/sm-bulk-update-instance-form.vue';
import SmImportInstanceForm from '~/system-model/components/forms/sm-import-instance-form.vue';
import SmInstanceForm from '~/system-model/components/forms/sm-instance-form.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

const emit = defineEmits(['totalInstance']);

const { route, router, $t, $services, auth_store } = useCommonImports();
const system_model_store = useSystemModelStore();
const form_store = useFormsStore();
const task_store = useTasksStore();
const { active_component, active_component_details, fields: component_fields, can_modify_resources } = storeToRefs(system_model_store);

const state = reactive({
  component_plans: [],
  parent_instances: [],
  total_instance: 0,
  selected_instances: [],
  is_exporting: false,
  search: '',
  is_loading: false,
  table_instance: null,
  initialized_table: null,
  instance_fields: [],
  component: [],
  is_action_dropdown_open: false,
  is_instance_dropdown_open: false,
});

const selected_instance_uids = computed(() => state.selected_instances.map(({ uid }) => uid));

const instances_list = computed(() => {
  const new_instance_list = (system_model_store.component_instances ?? []).map((instance) => {
    const new_instance = { ...instance };
    new_instance.parents = new_instance.parents.length
      ? new_instance.parents.map(({ name }) => name).join(', ')
      : '-';
    instance.fieldvalues.forEach((item) => {
      const { uid, type, config } = item.field;
      if (['text', 'number', 'url', 'email', 'date'].includes(type)) {
        new_instance[uid] = item.value;
      }
      else {
        const values = Array.isArray(config)
          ? config
            .filter(f => item?.value?.includes(f.uid))
            .map(f => f.name)
          : [];
        new_instance[uid] = values.join(', ');
      }
    });
    return new_instance;
  });
  return new_instance_list;
});

const default_plan = computed(() => {
  return state.component_plans?.find(plan => plan.default)?.uid || '';
});

const field_columns = computed(() => {
  return system_model_store.fields.map((field) => {
    let header_name = field.name;
    if (field?.properties?.currency && field.type === 'number')
      header_name = `${field.name} (in ${field.properties.currency})`;
    return {
      accessorKey: field.uid,
      header: header_name,
      id: field.uid,
    };
  });
});

const columns = computed(() => [
  ...(can_modify_resources.value
    ? [{
        accessorKey: 'select',
        header: '',
        id: 'select',
        size: '5',
        enableSorting: false,
        enableResizing: false,
      }]
    : []),
  {
    accessorKey: 'name',
    header: 'Name',
    id: 'name',
  },
  {
    accessorKey: 'parents',
    header: 'Parents',
    id: 'parents',
  },
  {
    accessorKey: 'aliases',
    header: $t('Aliases'),
    id: 'aliases',
  },
  ...field_columns.value,
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '2',
    show_on_hover: 'true',
  },
],
);

const instance_dropdown = computed(() => {
  return [
    { label: $t('Add manually'), icon: 'add', on_click: () => openAddInstanceForm() },
    { label: $t('Import instances'), icon: 'download', on_click: () => openImportInstanceForm() },
  ];
});

const action_dropdown = computed(() => {
  const selected_instance_length = selected_instance_uids.value.length ? ` (${selected_instance_uids.value.length})` : '';
  return [
    { label: $t('Edit'), on_click: () => openInstanceBulkUpdateModal() },
    { label: $t('Delete') + selected_instance_length, on_click: () => deleteInstanceModal() },
    { label: `${$t('Delete all')} (${state.total_instance})`, on_click: () => deleteAllInstance() },
    {
      label: $t('Create task'),
      disabled: !auth_store.check_permission('create_tasks', route.params.asset_id),
      on_click: () => openTaskModal(),
    },
    {
      label: $t('Create form'),
      disabled: !auth_store.check_permission('v2_create_forms', route.params.asset_id),
      on_click: () => openFormModal(),
    },
  ];
});

watch(active_component, async () => {
  state.selected_instances = [];
  state.search = '';
  getParentInstance();
  await getData();
}, { immediate: true });

watch(() => state.search, async () => {
  await getData();
});

watch(() => state.total_instance, (value) => {
  emit('totalInstance', Number.parseInt(value || 0));
});
const add_instance_modal = useModal({
  component: SmAddInstanceForm,
});

const import_instance_modal = useModal({
  component: SmImportInstanceForm,
});

const instance_bulk_update_modal = useModal({
  component: SmBulkUpdateInstanceForm,
});

const delete_instances_modal = useModal({
  component: HawkDeletePopup,
  slots: {
    content: '',
  },
});
const instance_detail_modal = useModal({
  component: SmInstanceForm,
});

const task_modal = useModal({
  component: TaskForm,
});

const form_modal = useModal({
  component: NewFormModal,
});

async function getParentInstance() {
  const { data } = await $services.sm_components.get({
    id: active_component.value.uid,
    attribute:
            '?include[]=fields.*&include[]=parents.*&include[]=parents.instances.*&include[]=parents.instances.component&include[]=template.plans.*',
    query: {
      remove_slash: true,
    },
  });
  state.instance_fields = data?.fields || [];
  state.component_plans = data?.plans || [];
  state.parent_instances = data?.instances || [];
  state.component = data?.component;
}

async function getData(options) {
  try {
    state.is_loading = true;
    state.table_instance?.clearSelect();
    let current_page = 1;
    if (options?.pagination_state?.pageIndex)
      current_page = options.pagination_state.pageIndex;
    const payload = {
      page_num: current_page,
      page_size: (state.initialized_table?.getState()?.pagination?.pageSize || 25),
      search: state.search,
    };
    state.total_instance = await system_model_store.set_instances(payload);
    state.is_loading = false;
  }
  catch (err) {
    state.is_loading = false;
    logger.error(err);
  }
}

function selectedRowHandler(row) {
  selected_instance_uids.value = row.map(({ original }) => original.uid);
  state.selected_instances = row.map(({ original }) => original);
}

function flattenInstances(instances) {
  const { parents: component_parent, fields: instance_fields } = active_component_details.value;

  return (instances || []).map((i) => {
    instance_fields?.forEach((field) => {
      i[field.uid] = '';
      const fieldvalue = i.fieldvalues.find(fv => fv.field.uid === field.uid);
      if (!fieldvalue)
        return;

      if (field?.config?.length) {
        if (field.type === 'labels' && fieldvalue.value) {
          const field_config = field.config
            .filter(option => fieldvalue.value.includes(option.uid))
            .map(option => option.name)
            .join(', ');
          if (field_config)
            i[field.uid] = field_config;
        }
        else {
          const field_config = field.config.find(c => c.uid === fieldvalue.value);
          if (field_config)
            i[field.uid] = field_config.name;
        }
      }
      else {
        i[field.uid] = fieldvalue.value;
      }
    });
    (component_parent || []).forEach((parent_component) => {
      i[parent_component.uid] = null;
      for (const instance of i.parents) {
        if (instance.component === parent_component.uid) {
          const parent_instances = i[parent_component.uid];
          const instance_position
            = (instance?.positions?.position_x)
              ? ` (${instance.positions.position_x}, ${instance.positions.position_y})`
              : '';
          const instance_value = `${instance.name}${instance_position}`;
          i[parent_component.uid] = parent_instances
            ? `${parent_instances}, ${instance_value}`
            : instance_value;
        }
      }
    });
    return i;
  });
}

function getComponentCustomFields(fields, instance) {
  const fields_hash = keyBy(active_component_details.value?.fields || [], 'uid');
  return fields.map((field) => {
    const fieldType = fields_hash[field].type;
    let single_field = instance[field];

    if (fieldType === 'labels')
      single_field = single_field.split(', ').join(', ');

    else if (fieldType === 'dropdown' && single_field === ' ')
      single_field = null;

    return single_field;
  });
}
function getSpecifiedInstances(instance, parent_component) {
  return instance.parents
    .filter(parent => parent.component === parent_component)
    .map((parent_instance) => {
      if (parent_instance.positions) {
        return [
          instance.name,
          parent_instance.name,
          parent_instance.positions.position_x,
          parent_instance.positions.position_y,
        ];
      }

      return [instance.name, parent_instance.name];
    });
}

async function exportSpreadSheets() {
  const ExcelJS = await import('exceljs');
  const { saveAs } = await import('file-saver');

  const workbook = new ExcelJS.Workbook();

  // how many separate Excel  will open when viewing
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  const { data } = await $services.sm_instances.getAll({
    query: {
      'sort[asc]': ['name'],
      'include[]': ['fieldvalues.field.*', 'parents.component'],
      'sideloading': 'false',
      'filter{component.uid}': active_component.value.uid,
    },
  });
  const all_flat_instances = flattenInstances(data?.instances);
  const components = active_component_details.value;
  const custom_field_values = [];
  const parent_instances = {};
  const custom_fields = component_fields.value.map(field => field.uid);
  const parent_components = components.parents.map(component => component.uid);

  const alias_data = [['name', 'name_1', 'name_2', 'name_3', 'name_4']];

  all_flat_instances?.forEach((instance) => {
    alias_data.push([
      instance.name,
      instance?.name_1 || '',
      instance?.name_2 || '',
      instance.name_3 || '',
      instance?.name_4 || '',
    ]);
    const field_values = getComponentCustomFields(custom_fields, instance);
    custom_field_values.push([instance.name, ...field_values]);
    parent_components.forEach((parent_component) => {
      const specified_instances = getSpecifiedInstances(instance, parent_component);
      if (specified_instances.length) {
        parent_instances[parent_component] = parent_instances[parent_component]
          ? [...parent_instances[parent_component], ...specified_instances]
          : [...specified_instances];
      }
    });
  });

  if (component_fields.value?.length) {
    const fields_worksheet = workbook.addWorksheet('Fields');
    fields_worksheet.addRows(sanitizeAoA([
      ['name', ...component_fields.value.map(field => field.name)],
      ...custom_field_values,
    ]));
  }
  if (components?.parents?.length) {
    components.parents.forEach((component) => {
      const ws = workbook.addWorksheet(component.name);
      ws.addRows(sanitizeAoA([
        [
          'name',
          `~${component.name}`,
          ...((component?.layouts?.layout_x)
            ? ['xpos', 'ypos']
            : []),
        ],
        ...(parent_instances[component.uid] || []),
      ]));
    });
  }

  if (!workbook.worksheets.length) {
    const ws = workbook.addWorksheet(components.name);
    ws.addRows(sanitizeAoA([
      ['name'],
      ...all_flat_instances.map(instance => [instance.name]),
    ]));
  }
  const _ws = workbook.addWorksheet('Aliases');
  _ws.addRows(sanitizeAoA(alias_data));

  try {
    // for csv: const buffer = await workbook.csv.writeBuffer();
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${components.name} - instances.xlsx`);
  }
  catch (error) {
    logger.log('Error writing excel export', error);
  }

  state.is_exporting = false;
}

function makeActiveInstance(instance) {
  const query = { ...route.query };
  delete query.active;
  router.replace({ query: { ...query, instance: instance.uid, component: active_component.value.uid } });
  system_model_store.set_active_instance(instance);
}

function openInstanceBulkUpdateModal() {
  instance_bulk_update_modal.patchOptions({
    attrs: {
      onClose() {
        instance_bulk_update_modal.close();
      },
      async onInstanceUpdated() {
        await getData();
      },
      parent_instances: state.parent_instances,
      selected_instances: selected_instance_uids.value,
    },
  });
  instance_bulk_update_modal.open();
}

function openImportInstanceForm() {
  import_instance_modal.patchOptions({
    attrs: {
      onClose() {
        import_instance_modal.close();
      },
      plan_id: default_plan.value,
      load_instances: getData,
      parent_instances: state.parent_instances,
      instance_fields: state.instance_fields,
      component: state.component,
    },
  });
  import_instance_modal.open();
}

function openAddInstanceForm() {
  add_instance_modal.patchOptions({
    attrs: {
      onClose() {
        add_instance_modal.close();
      },
      plan_id: default_plan.value,
      load_instances: getData,
    },
  });
  add_instance_modal.open();
}

function formatAliases(instance) {
  const { name_1, name_2, name_3, name_4 } = instance;
  const name_list = [name_1, name_2, name_3, name_4];
  if (name_list.every(element => element === null))
    return '-';

  return name_list.filter(Boolean).join(', ');
}

function deleteInstanceModal(instance_uid) {
  delete_instances_modal.patchOptions({
    slots: {
      content: '<p class="text-gray-700 text-sm">Are you sure you want to delete the instances?</p>',
    },
    attrs: {
      header: $t('Delete Instances'),
      onClose() {
        delete_instances_modal.close();
      },
      confirm: async () => {
        try {
          if (instance_uid) {
            await system_model_store.delete_instances([{ uid: instance_uid }], false);
          }
          else {
            const payload = selected_instance_uids.value?.map(uid => ({ uid }));
            await system_model_store.delete_instances(payload);
            state.selected_instances = [];
            state.table_instance?.clearSelect();
            await getData();
          }
          delete_instances_modal.close();
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  });
  delete_instances_modal.open();
}

function deleteAllInstance() {
  delete_instances_modal.patchOptions({
    slots: {
      content: `<p class="text-gray-700 text-sm">You are about to delete <span class="text-gray-900 font-semibold">${state.total_instance}</span> instances of the component <span class="text-gray-900 font-semibold">${active_component_details.value.name}</span>. This action is irreversible. If you want to continue, type confirm in the box.</p>`,
    },
    attrs: {
      header: $t('Delete all instances'),
      button_text: $t('Delete all'),
      match_text: 'confirm',
      show_match_text: false,
      match_text_input_placeholder: 'If you want to continue, type confirm in the box',
      onClose() {
        delete_instances_modal.close();
      },
      onClosed() {
        const attrs = omit(delete_instances_modal.options.attrs, ['button_text']); // reset attrs
        delete_instances_modal.options.attrs = attrs;
      },
      confirm: async () => {
        try {
          await system_model_store.delete_instances([{ component: active_component_details.value.uid }]);
          state.table_instance?.clearSelect();
          state.total_instance = 0;
          delete_instances_modal.close();
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  });
  delete_instances_modal.open();
}

async function editInstanceModal(instance_detail) {
  system_model_store.active_instance_details = instance_detail;
  instance_detail_modal.patchOptions({
    attrs: {
      onClose() {
        instance_detail_modal.close();
      },
      onUpdateCompleted(instance) {
        if (instance) {
          const instance_list = [...system_model_store.component_instances];
          const instance_index = instance_list.findIndex(i => i.uid === instance.uid);
          if (instance_index !== -1) {
            instance_list[instance_index] = { ...instance_list[instance_index], ...instance };
            system_model_store.component_instances = instance_list;
          }
        }
      },
      instance_detail,
    },
  });
  instance_detail_modal.open();
}
async function createTaskFormPayloadForMultipleInstances(data, is_form = false) {
  const payload = [];
  const locations = await system_model_store.get_locations(state.selected_instances.map(({ name }) => name));
  state.selected_instances.forEach((instance) => {
    payload.push({
      ...data,
      ...(is_form ? { uid: nanoid() } : {}),
      name: `${instance?.name || ''} ${data?.name || ''}`?.trim(),
      target_element: instance.element,
      location: locations?.[instance.name] || null,
      properties: { reference_name: instance.name },
      asset: route.params.asset_id,
      prefix_feature_name: true,
    });
  });
  return payload;
}

async function openTaskModal() {
  if (!auth_store.check_permission('create_tasks', route.params.asset_id)) {
    return;
  }
  task_modal.patchOptions({
    attrs: {
      task_type: 'task',
      onClose() {
        task_modal.close();
      },
      async on_submit(data) {
        try {
          if (!state.selected_instances.length)
            return;
          const payload = await createTaskFormPayloadForMultipleInstances(data);
          if (payload.length) {
            await task_store.create_tasks({ tasks: payload }, { where: 'System Model view', how: 'Quick action' });
          }
        }
        catch (error) {
          logger.error(error);
        }
        finally {
          state.selected_instances = [];
          state.table_instance?.clearSelect();
          task_modal.close();
        }
      },
    },
  });
  task_modal.open();
}

async function openFormModal() {
  if (!auth_store.check_permission('v2_create_forms', route.params.asset_id)) {
    return;
  }
  form_modal.patchOptions({
    attrs: {
      task_data: {},
      onClose() {
        form_modal.close();
      },
      async on_submit(data) {
        try {
          const payload = await createTaskFormPayloadForMultipleInstances(data.forms.add[0], true);
          if (payload.length) {
            await form_store.create_form({ body: { forms: { add: payload } }, do_not_open_form_details: true }, true);
          }
        }
        catch (err) {
          logger.error(err);
        }
        finally {
          state.selected_instances = [];
          state.table_instance?.clearSelect();
          form_modal.close();
        }
      },
    },
  });
  form_modal.open();
}
</script>

<template>
  <HawkExportToast v-if="state.is_exporting" :submit="exportSpreadSheets" :progress_text="$t('Exporting to XLSX')" :completed_text="$t('Exported XLSX')" @close="state.is_exporting = false" />
  <div>
    <HawkPageSecondaryHeader v-if="selected_instance_uids.length" class="my-4">
      <template #left>
        <HawkMenu
          :items="action_dropdown"
          additional_trigger_classes="!ring-0"
          @open="state.is_action_dropdown_open = true"
          @close="state.is_action_dropdown_open = false"
        >
          <template #trigger>
            <HawkButton type="outlined" class="font-semibold">
              {{ $t('Actions') }}
              <IconHawkChevronUp v-if="state.is_action_dropdown_open" class="text-gray-700 font-semibold" />
              <IconHawkChevronDown v-else class="text-gray-700 font-semibold" />
            </HawkButton>
          </template>
          <template #item="{ item }">
            <span class="flex items-center gap-2 text-gray-700 font-medium w-max">
              {{ item.label }}
            </span>
          </template>
        </HawkMenu>
      </template>
    </HawkPageSecondaryHeader>
    <HawkPageSecondaryHeader v-else class="my-4">
      <template #left>
        <HawkSearchInput v-model="state.search" placeholder="Search" :debounce_time="300" />
      </template>
      <template #right>
        <div class="flex gap-4">
          <HawkButton
            type="outlined"
            class="font-semibold"
            @click="state.is_exporting = true"
          >
            <IconHawkDownloadOne class="!text-gray-600 h-4 w-4" />
            <span class="text-gray-700">{{ $t('Export') }}</span>
          </HawkButton>
          <HawkMenu
            v-if="can_modify_resources"
            :items="instance_dropdown"
            additional_trigger_classes="!ring-0"
            @open="state.is_instance_dropdown_open = true"
            @close="state.is_instance_dropdown_open = false"
          >
            <template #trigger>
              <HawkButton type="outlined" class="font-semibold">
                {{ $t('Add') }}
                <IconHawkChevronUp v-if="state.is_instance_dropdown_open" class="text-gray-700 font-semibold" />
                <IconHawkChevronDown v-else class="text-gray-700 font-semibold" />
              </HawkButton>
            </template>
            <template #item="{ item }">
              <span class="flex items-center gap-2 text-gray-700 font-medium w-max">
                <IconHawkPlusGray v-if="item.icon === 'add'" class="h-3 w-3" />
                <IconHawkDownloadTwo v-else class="w-4 h-4 text-gray-700" />
                {{ item.label }}
              </span>
            </template>
          </HawkMenu>
        </div>
      </template>
    </HawkPageSecondaryHeader>
    <HawkIllustrations
      v-if="state.search && !instances_list.length"
      type="no-results"
    />
    <HawkTable
      v-else
      :key="active_component_details.uid + field_columns.length"
      class="overflow-x-auto scrollbar"
      :pagination_config="{ totalRows: state.total_instance, pageSize: 25 }"
      :data="instances_list"
      :columns="columns"
      :show_menu_header="false"
      :manual_pagination="true"
      :is_loading="state.is_loading"
      is_gapless
      :non_sortable_columns="['context_menu']"
      @pagination="getData"
      @select-row="selectedRowHandler"
      @table-instance="state.table_instance = $event"
      @table-instance-created="state.initialized_table = $event"
    >
      <template #name="{ data }">
        <div class="cursor-pointer text-gray-900 font-medium" @click="() => makeActiveInstance(data.row.original)">
          {{ data.row.original.name }}
        </div>
      </template>
      <template #aliases="{ data }">
        {{ formatAliases(data.row.original) }}
      </template>
      <template #context_menu="{ data: { row: { original } } }">
        <hawk-menu
          v-if="can_modify_resources"
          class="flex justify-end w-full"
          position="fixed"
          :items="[
            { label: $t('Edit'), on_click: () => editInstanceModal(original) },
            { label: $t('Delete'), on_click: () => deleteInstanceModal(original.uid) },
          ]"
        >
          <template #trigger>
            <IconHawkDotsVertical class="text-gray-600 h-5" />
          </template>
        </hawk-menu>
      </template>
    </HawkTable>
  </div>
</template>

<style lang="scss">
.list_items {
  .form-text-type {
    flex: 1 !important;
  }
}
</style>
