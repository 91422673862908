<script setup>
import { storeToRefs } from 'pinia';
import DashboardFilters from '~/dashboard/components/filters/dashboard-filters.vue';

import { useCommonStore } from '~/common/stores/common.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';

const emit = defineEmits(['update']);

const common_store = useCommonStore();
const dashboard_store = useDashboardStore();

const {
  widget_configuration,
} = storeToRefs(dashboard_store);

const field_types = [
  'text',
  'number',
  'date',
  'dropdown',
  'members',
  'percentage',
  'planned_actual',
  'date_range',
  'checkbox',
];

const filters = common_store.assets_custom_fields.filter(field => field_types.includes(field.type)).map(field => field.uid);

const initial_state = ref([]);
const initial_state_set = ref(false);

function emitUpdate(data) {
  if (!data?.length)
    emit('update', {
      filters: {},
      report_filters: [],
    });

  if (!data?.[0])
    return;

  const filters = data;

  const report_filters = [];

  data.forEach((f) => {
    if (!f)
      return;

    let value = f.value;

    const field_type = common_store.assets_custom_fields_map[f.filter_type].type;

    if ((f.operator === 'between' || f.operator.endsWith('_between')) && !['date', 'date_range'].includes(field_type))
      value = [
        Number(f.start),
        Number(f.end),
      ];

    report_filters.push({
      name: f.filter_type,
      type: f.operator,
      field_type,
      value,
    });
  });

  emit('update', {
    filters,
    report_filters,
  });
}

function setInitialState() {
  const filters = widget_configuration.value?.filters?.report_filters;

  if (!Array.isArray(filters) && !filters?.length) {
    initial_state.value = [];
    initial_state_set.value = true;

    return;
  }

  initial_state.value = filters?.map((f) => {
    const value_obj = {};

    if (!isDateIntervalOperator(f.type))
      value_obj.value = f.value;

    return {
      filter_type: f.name,
      operator: f.type,
      ...value_obj,
    };
  }) || [];

  initial_state_set.value = true;
}

setInitialState();
</script>

<template>
  <div class="text-sm text-blue-600 font-medium my-4 cursor-pointer">
    {{ $t('Advanced filters') }}
  </div>
  <DashboardFilters
    v-if="initial_state_set"
    :filters="filters"
    :initial_state="initial_state"
    service="assets"
    class="mb-4"
    @update="emitUpdate($event)"
  />
</template>
