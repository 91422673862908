<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import DOMPurify from 'dompurify';
import { computed } from 'vue';
import HawkModalContainer from '~/common/components/hawk-modal/hawk-modal-container.vue';
import HawkModalContent from '~/common/components/hawk-modal/hawk-modal-content.vue';
import HawkModalFooter from '~/common/components/hawk-modal/hawk-modal-footer.vue';
import HawkModalHeader from '~/common/components/hawk-modal/hawk-modal-header.vue';
import FormWorkflowSchemaInput from '~/forms/atoms/form-schema-input.vue';
import { STATIC_EMAIL_VARIABLES } from '~/forms/constants';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  form: {
    type: Object,
  },
  step_number: {
    type: Number,
  },
  popup_title: {
    type: String,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  enable_document_suggestions: {
    type: Boolean,
    default: false,
  },
  has_dynamic_fields: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['save', 'close', 'resetForm']);
const $t = inject('$t');
const form_workflow_store = useFormWorkflowStore();

const form_data = ref({});

const is_save_disabled = computed(() => {
  const sanitized_subject = DOMPurify.sanitize(form_data.value.subject, { ALLOWED_TAGS: [] });
  const sanitized_body = DOMPurify.sanitize(form_data.value.body, { ALLOWED_TAGS: [] });

  return props.is_disabled || !sanitized_subject || !sanitized_body;
});

const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        subject: '',
        body: '',
      };
});

const suggestions = computed(() => {
  let document_block_suggestions = [];
  if (props.enable_document_suggestions) {
    document_block_suggestions = form_workflow_store.get_document_generate_blocks().map(item => ({
      label: item.name,
      name: item.name,
      tag: item.name,
      id: item.slug,
    }));
  }
  const default_suggestions = [
    ...STATIC_EMAIL_VARIABLES,
    {
      label: '[Link]',
      name: '[Link]',
      tag: '[Link]',
      id: 'form_link',
      additional_item_classes: 'border-t border-gray-200',
    },
  ];
  return [...document_block_suggestions, ...default_suggestions];
});

async function save() {
  emit('save', form_data.value);
}

function init() {
  form_data.value = default_data.value;
}
init();
</script>

<template>
  <HawkModalContainer content_class="w-[800px]">
    <HawkModalHeader @close="$emit('close')">
      <template #title>
        <div :class="{ 'pointer-events-none': is_disabled }">
          <div class="flex items-center">
            {{ popup_title === 'Custom Email' ? $t(popup_title) : popup_title }}
          </div>
        </div>
      </template>
    </HawkModalHeader>
    <HawkModalContent>
      <div :class="{ 'pointer-events-none': is_disabled }">
        <Vueform
          v-model="form_data"
          :display-errors="false"
          :sync="true"
          :columns="{
            lg: {
              wrapper: 12,
              container: 12,
              label: 12,
            },
          }"
        >
          <div class="col-span-12">
            <FormWorkflowSchemaInput
              name="subject"
              rules="required"
              :disabled="is_disabled"
              :editor_options="{
                placeholder_text: `${$t('Enter subject')}`,
              }"
              :additional_suggestions="suggestions"
              :step_number="step_number"
              :has_dynamic_fields="props.has_dynamic_fields"
            />
            <FormWorkflowSchemaInput
              name="body"
              rules="required"
              :disabled="is_disabled"
              :editor_options="{
                add_custom_class: { ElementError: { container: '!mt-10' } },
                placeholder_text: `${$t('Add your message here')}`,
                menu_enabled: true,
                single_line: false,
                enable_common_rtf_features: true,
                plugins: ['tags', 'tables', 'images'],
              }"
              :additional_suggestions="suggestions"
              :step_number="step_number"
              :has_dynamic_fields="props.has_dynamic_fields"
            />
          </div>
        </Vueform>
      </div>
    </HawkModalContent>

    <HawkModalFooter class="flex items-center" :class="[form?.customize_email ? 'justify-between' : 'justify-end']">
      <template #left>
        <hawk-button
          v-show="form?.customize_email"
          color="error"
          type="link"
          @click="$emit('resetForm')"
        >
          {{ $t('Delete') }}
        </hawk-button>
      </template>
      <template #right>
        <div class="flex items-center">
          <HawkButton
            class="mr-3"
            type="outlined"
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton
            color="primary"
            :disabled="is_save_disabled"
            @click="save()"
          >
            {{ $t('Save') }}
          </HawkButton>
        </div>
      </template>
    </HawkModalFooter>
  </HawkModalContainer>
</template>
