<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const route = useRoute();
const router = useRouter();
const dms_settings_store = useDMSSettingsStore();

const tabs = computed(() => [{
  uid: 'dms-custom-status',
  label: 'Custom Status',
  to: { name: 'dms-custom-status', params: { asset_id: route.params.asset_id } },
  disabled: false,
},
{
  uid: 'dms-custom-fields',
  label: 'Custom Fields',
  to: { name: 'dms-custom-fields', params: { asset_id: route.params.asset_id } },
  disabled: false,
},
{
  uid: 'dms-transmittal-settings',
  label: 'Transmittal Settings',
  to: { name: 'dms-transmittal-settings', params: { asset_id: route.params.asset_id } },
  disabled: false,
},
{
  uid: 'dms-workflow-templates',
  label: 'Workflow Templates',
  to: { name: 'dms-workflow-templates', params: { asset_id: route.params.asset_id } },
  disabled: false,
}]);

const active_tab = computed(() => route.name === 'dms-settings' ? 'dms-custom-status' : route.name);

onBeforeMount(async () => {
  await dms_settings_store.set_configuration();
});

function backHandler() {
  router.push({
    name: 'dms',
  });
}
</script>

<template>
  <div>
    <HawkPageHeader :title="$t('Settings')">
      <template #back>
        <HawkButton
          :icon="true" class="mr-2" type="text" @click="backHandler"
        >
          <IconHawkChevronLeft />
        </HawkButton>
      </template>
      <template #left>
        <hawk-page-header-tabs :tabs="tabs" :active_item="active_tab" />
      </template>
    </HawkPageHeader>
    <hr>
    <div class="px-4">
      <router-view />
    </div>
  </div>
</template>
