import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

// other
import { useModal } from 'vue-final-modal';
import useEmitter from '~/common/composables/useEmitter';

// composables
import { useDocumentGlobalActions } from '~/dms/composables/document-global.composable';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDocumentStore } from '~/dms/store/document.store';
import { useCommonStore } from '~/common/stores/common.store';

// components
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DocumentUploadForm from '~/dms/components/documents/forms/document-upload-form.vue';

export function useDocumentCrud(table) {
  const route = useRoute();

  const document_global_actions = useDocumentGlobalActions();

  const auth_store = useAuthStore();
  const document_store = useDocumentStore();
  const common_store = useCommonStore();

  const emitter = useEmitter();

  const $t = inject('$t');
  const $toast = inject('$toast');

  const active_folder_meta_uid = computed(() => {
    return document_store.active_folder_meta.type === 'folder' ? document_store.active_folder_meta.uid : null;
  });
  const is_internal = computed(() => document_store.is_internal);
  const active_folder_detail = computed(() => document_store.active_folder_detail);

  function calculateType({ item, bulk_items = [] }) {
    if (bulk_items.length > 0)
      return 'bulk';

    else
      return item.type;
  }

  // UPLOAD --> start
  const file_upload_modal = useModal({
    component: DocumentUploadForm,
  });

  function getWebkitRelativePath(file) {
    return file.webkitRelativePath || file.meta?.raw_webkitRelativePath || file.meta?.webkitRelativePath || undefined;
  }

  function createFilesPayload(files, options = {}, hierarchy = null) {
    return files.map((file) => {
      const { sanitized_name = '', location = null, type = null, size = 0, service_object } = file || {};
      const file_webkit_path = getWebkitRelativePath(file);
      const folder_uid = hierarchy?.[file_webkit_path] ? hierarchy[file_webkit_path] : options.folder_uid;
      return {
        name: sanitized_name,
        location,
        file_name: sanitized_name,
        file_size: size,
        file_type: type,
        service: service_object,
        ...(options),
        folder_uid, // override for folder uploading
      };
    });
  }

  async function uploadFolderHandler(form_data, options) {
    const folder_structure = {
      files: form_data?.filter(item => getWebkitRelativePath(item))?.map((item) => {
        return {
          path: getWebkitRelativePath(item),
        };
      }),
      folder: active_folder_meta_uid.value,
      ...options,
    };
    const hierarchy_response = await document_store.create_folder_structure(folder_structure);

    if (hierarchy_response?.data) {
      const hierarchy = hierarchy_response?.data;
      const payload = createFilesPayload(form_data, options, hierarchy);
      const response = await document_store.crud_documents({
        request: {
          body: {
            files: {
              add: payload,
            },
          },
        },
      });

      // update sidebar
      await document_store.set_hierarchy(route.params.asset_id);
      // update documents
      await document_global_actions.getDocuments();

      if (response?.data?.errorCode) {
        $toast({
          title: response?.data?.description || $t('Something went wrong'),
          type: 'error',
          position: 'bottom-right',
        });
        file_upload_modal.close();
      }
      else {
        $toast({
          title: $t('Folder(s) uploaded successfully'),
          type: 'success',
          position: 'bottom-right',
        });
        file_upload_modal.close();
      }
    }
  }

  async function uploadFileHandler(form_data, options) {
    const payload = createFilesPayload(form_data, options);
    const response = await document_store.crud_documents({
      request: {
        body: {
          files: {
            add: payload,
          },
        },
      },
    });
    if (response?.data?.documents?.files?.added?.length) {
      $toast({
        title: $t('File(s) uploaded successfully'),
        type: 'success',
        position: 'bottom-right',
      });
      return response?.data?.documents?.files?.added.map(file => file.uid);
    }
  }

  function uploadHandler(data = { flag: 'file', files: [], options: {}, callback: null }) {
    const flag = data.flag;
    const files = data?.files;

    file_upload_modal.patchOptions({
      attrs: {
        files_to_upload: files,
        is_folder: flag === 'folder',
        is_drop: flag === 'drop',
        is_dnd: flag === 'dnd',
        submit: async (form) => {
          let callback_payload = null;
          const has_webkit_relative_path = form.data?.File?.find((item) => {
            return !!getWebkitRelativePath(item);
          });

          if (flag === 'folder' || has_webkit_relative_path)
            callback_payload = await uploadFolderHandler(form.data?.File, data.options);
          else
            callback_payload = await uploadFileHandler(form.data?.File, data.options);
          if (data.callback)
            await data.callback(callback_payload);
          file_upload_modal.close();
        },
        onClose() {
          file_upload_modal.close();
        },
      },
    });
    file_upload_modal.open();
  }
  // UPLOAD --> end

  // DOWNLOAD --> start
  async function bulkItemDownload(bulk_items) {
    const bulk_files = [];
    const bulk_folders = [];

    bulk_items.forEach((item) => {
      item.type === 'folder' ? bulk_folders.push(item) : bulk_files.push(item);
    });

    const payload = { type: 'vault' };

    if (bulk_files.length)
      if (bulk_files.length === 1 && !bulk_folders.length) {
        const response = await document_store.get_download_url({
          body: {
            files: [{
              uid: bulk_items[0].uid,
            }],
          },
          attribute: 'url',
        });
        const url = response.data.urls[0].url;
        window.open(url, '_blank');
      }
      else {
        payload.files = bulk_files;

        if (bulk_files[0]?.parent !== null)
          payload.parent_folder_uid = bulk_files[0].parent;
        else
          payload.global_files = true;
      }

    if (bulk_folders.length)
      payload.folders = bulk_folders;

    emitter.emit('download_zip', payload);
  }

  async function itemDownload({ file = {}, item = {}, bulk_items = [], query = null }) {
    let local_type = calculateType({ item, bulk_items });
    let local_uid = item.uid;

    if (Object.keys(file).length > 0 && file.cancelable === undefined) {
      local_uid = file.uid;
      local_type = 'file';
    }

    if (local_type === 'file') {
      try {
        const response = await document_store.get_download_url({
          body: {
            files: [{
              uid: local_uid,
            }],
          },
          ...(query ? { query } : {}),
        });
        const url = response.data.urls[0].url;
        window.open(url, '_blank');
      }
      catch (e) {
        logger.error('download failed', e);
        $toast({
          title: $t('Something went wrong'),
          text: $t('Please try again later'),
          type: 'error',
          position: 'bottom-right',
        });
      }
    }

    else if (local_type === 'folder') {
      emitter.emit('download_zip', {
        type: 'vault',
        folders: [item],
      });
    }

    else if (local_type === 'bulk') {
      await bulkItemDownload(bulk_items);
      table?.clearSelect();
    }
  }
  // DOWNLOAD --> end

  // DELETE --> start
  const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
    component: HawkDeletePopup,
  });

  function itemDelete({ item = {}, options = [], bulk_items = [], callback = null, update_hierarchy = true }) {
    patchOptions(
      {
        attrs: {
          header: $t('Delete item(s)'),
          content: $t('Are you sure you want to delete the item(s)? They will be moved to trash and will be permanently deleted after 30 days.'),
          onClose() {
            closeDeletePopup();
          },
          confirm: async () => {
            if (bulk_items.length > 0)
              await onConfirmDeleteBulk({ bulk_items });
            else
              await onConfirmDelete({ item, options, callback, update_hierarchy });
          },
        },
      },
    );
    openDeletePopup();
  }
  async function onConfirmDelete({ item, callback, update_hierarchy }) {
    const local_type = calculateType({ item });
    const local_uid = item.uid;

    try {
      // regardless of type
      await document_store.crud_documents({
        request: {
          body: {
            [`${local_type}s`]: {
              remove: [local_uid],
            },
          },
        },
      });
      if (callback)
        await callback();

      $toast({
        title: $t('File/folder deleted successfully'),
        text: `${item.name} ${$t('file/folder has been deleted successfully')}`,
        type: 'success',
        position: 'bottom-right',
      });
    }
    catch (e) {
      logger.error('DELETE ITEM: ', e);
      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });
    }
    // update sidebar
    if (update_hierarchy)
      await document_store.set_hierarchy(route.params.asset_id);

    // close details
    document_store.set_show_details(false);

    closeDeletePopup();
  }

  async function onConfirmDeleteBulk({ bulk_items = [] }) {
    try {
      const files = [];
      const folders = [];

      if (bulk_items.length)
        bulk_items.forEach((item) => {
          if (item.type === 'folder')
            folders.push(item.uid);
          else
            files.push(item.uid);
        });
      await document_store.crud_documents({
        request: {
          body: {
            files: {
              remove: files,
            },
            folders: {
              remove: folders,
            },
          },
        },
      });

      await document_store.set_hierarchy(route.params.asset_id);

      table?.clearSelect();
      // close details
      document_store.set_show_details(false);
      $toast({
        title: $t('Files/folders deleted successfully'),
        text: `${bulk_items.length} ${$t('files/folders have been deleted successfully')}`,
        type: 'success',
        position: 'bottom-right',
      });
    }
    catch (e) {
      logger.error('ITEMS DELETE: ', e);
      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });
    }
    closeDeletePopup();
  }
  // DELETE --> end

  function getCommonPayload(options = {}) {
    const organization = auth_store.current_organization_uid;
    let target_element = common_store.get_target_element(route.params.asset_id);
    let asset = route.params.asset_id || null;
    let folder_uid = null;

    if (active_folder_detail.value?.type === 'folder') {
      target_element = active_folder_detail.value.target_element;
      asset = active_folder_detail.value.asset;
      folder_uid = active_folder_detail.value.uid;
    }
    else if (active_folder_detail.value?.type === 'organization') {
      const element = common_store.get_target_element();
      target_element = element;
    }
    else if (active_folder_detail.value?.type === 'asset') {
      const element = common_store.get_target_element(active_folder_detail.value.uid);
      target_element = element;
      asset = active_folder_detail.value.uid;
    }

    return {
      target_element,
      asset,
      folder_uid,
      organization,
      internal: is_internal.value,
      location: null,
      public: false,
      ...(options?.create_payload || {}),
    };
  }

  async function updateVersion(data, item, reset = false) {
    const { sample, toggle: is_current } = data;
    try {
      const response = await document_store.crud_documents({
        request: {
          body: {
            files: {
              update: [
                {
                  uid: item.uid,
                  version: {
                    set_as_default: is_current,
                    version: data['version-name'],
                    name: sample.name,
                    description: data.description,
                    service: sample.service_object,
                    uid: crypto.randomUUID(),
                  },
                },
              ],
            },
          },
        },
      });
      const current_version = response?.data?.documents?.files?.updated[0];
      if (current_version && is_current && reset)
        document_store.files_map[current_version?.uid] = {
          ...current_version,
          thumbnail_xsmall: null,
          thumbnail_small: null,
          thumbnail_large: null,
        };
      $toast({
        title: $t('Version Uploaded'),
        text: $t('New version successfully uploaded'),
        type: 'success',
        position: 'bottom-right',
      });
    }
    catch (e) {
      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });
    }
  }

  return {
    uploadHandler,
    itemDownload,
    getCommonPayload,
    itemDelete,
    updateVersion,
  };
}
