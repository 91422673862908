<script setup>
import { storeToRefs } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const { route, router } = useCommonImports();
const system_model_store = useSystemModelStore();
const { active_instance, active_component, active_tab } = storeToRefs(system_model_store);

const current_template = computed(() => system_model_store.get_template_details(route?.params?.template_id));

const router_params = computed(() => route?.params);

const breadcrumbs = computed(() => {
  const breadcrumbs = [
    { uid: 'system_model_label', label: 'System model', disabled: false, to: { name: 'system-model', params: { asset_id: router_params.value?.asset_id, template_id: router_params.value?.template_id } } },
    { uid: current_template?.value?.uid, label: current_template?.value?.name, disabled: false },
  ];
  if (active_component.value)
    breadcrumbs.push(
      { uid: active_component?.value?.uid, label: active_component?.value?.name, disabled: false },
      { uid: 'template_detail', label: active_tab.value === 'SmComponentDetails' ? 'Details' : 'Instances', disabled: false },
    );

  if (active_instance.value)
    breadcrumbs.push({
      uid: active_instance?.value?.uid, label: active_instance?.value?.name, disabled: true,
    });
  return breadcrumbs;
});

async function breadcrumbHandler(item) {
  if (item?.disabled)
    return;
  const { uid } = item;
  if (uid === current_template?.value?.uid) {
    await system_model_store.set_active_component_hierarchy({ template_id: current_template?.value?.uid });
    system_model_store.set_active_tab('SmComponentDetails');
  }
  else if (uid === active_component.value?.uid) {
    system_model_store.set_active_tab('SmComponentDetails');
  }
  else if (uid === 'template_detail') {
    if (active_instance.value)
      system_model_store.set_active_tab('SmInstances');
  }
  system_model_store.set_active_instance(null);
  router.replace({ query: {} });
}
</script>

<template>
  <hawk-breadcrumbs :items="breadcrumbs" @crumbClicked="breadcrumbHandler" />
</template>
