<script setup>
import { useRoute } from 'vue-router';
import { useTransmittalWorkflowStore } from '~/dms/store/transmittal-workflow.store';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';

const route = useRoute();

const is_template_detail = ref(route.query.create ? route.query.create : null);

const $t = inject('$t');
const is_loading = ref(false);

const selected_workflow = ref(null);

const transmittal_workflow_store = useTransmittalWorkflowStore();
const transmittal_permissions = useTransmittalPermissions();

const workflows = computed(() => transmittal_workflow_store.workflows);

const columns = computed(() => {
  return [
    {
      header: $t('Template Name'),
      accessorKey: 'name',
      id: 'name',
      size: 200,
      enableResizing: false,
    },
    {
      header: $t('Category'),
      accessorKey: 'category',
      id: 'category',
      size: 200,
      enableResizing: false,
    },
    {
      header: $t('Created By'),
      accessorKey: 'owner',
      id: 'owner',
      size: 200,
      enableResizing: false,
    },
    {
      header: $t('Status'),
      accessorKey: 'active',
      id: 'active',
      enableResizing: false,
    },
  ];
});

onMounted(async () => {
  is_loading.value = true;
  try {
    await transmittal_workflow_store.set_workflow();
    selected_workflow.value = route?.query?.template_uid ? workflows.value.find(workflow => workflow.uid === route?.query.template_uid) : null;
  }
  catch (e) {
    logger.error(e);
  }
  is_loading.value = false;
});

function selectWorkflow(row) {
  selected_workflow.value = row;
}

async function deleteWorkflow(uid) {
  await transmittal_workflow_store.delete_workflow(uid);
  selected_workflow.value = null;
}

const selected_workflow_from_store = computed(() => transmittal_workflow_store.workflow_map[selected_workflow.value?.uid]);
</script>

<template>
  <transmittal-workflow-detail v-if="is_template_detail || selected_workflow_from_store" :workflow="selected_workflow_from_store" :delete_workflow="deleteWorkflow" @close="is_template_detail = false;selected_workflow = null" />
  <div v-else>
    <div class="flex items-center justify-between py-4">
      <div>
        <div class=" text-lg font-semibold">
          {{ $t('Workflows') }}
        </div>
        <div class="text-xs font-regular">
          {{ $t('Create approval workflows to update document status based on the chosen outcome strategy') }}
        </div>
      </div>
      <div v-tippy="!route.params.asset_id ? $t('Switch to an asset to create a template') : null">
        <hawk-button v-if="transmittal_permissions.checkTransmittalPermission({ permission: 'workflow_new_cta' })" :disabled="!route.params.asset_id" @click="is_template_detail = true">
          <IconHawkPlus />
          {{ $t('Add New Template') }}
        </hawk-button>
      </div>
    </div>
    <hawk-table
      :is_loading="is_loading"
      :data="workflows"
      :columns="columns"
      class="!px-0"
      @row-clicked="selectWorkflow"
    >
      <template #category="category">
        <HawkCategoryName v-if="category.data.getValue()" :uid="category.data.getValue()" />
        <p v-else>
          -
        </p>
      </template>
      <template #owner="owner">
        <HawkMembers :members="[owner.data.getValue()]" type="badge" />
      </template>
      <template #active="active">
        <hawk-badge
          v-if="active.data.getValue()"
          color="green"
        >
          {{ $t("Published") }}
        </hawk-badge>
        <hawk-badge
          v-else
          color="gray"
        >
          {{ $t("Draft") }}
        </hawk-badge>
      </template>
    </hawk-table>
  </div>
</template>
