import { Validator } from '@vueform/vueform';

export function usePasswordRules(username, first_name, last_name) {
  // Rule: 1
  const _oneLowerAndUpperRule = {
    message: 'The Password must contain lower and upper letter.',
    check(value) {
      return /(?=.*[a-z])(?=.*[A-Z])/.test(value);
    },
  };
  const oneLowerAndUpperRule = class extends Validator {
    get message() {
      return _oneLowerAndUpperRule.message;
    }

    check(value) {
      return _oneLowerAndUpperRule.check(value);
    }
  };
  // Rule: 2
  const _oneNumberAndSymbolRule = {
    message: 'The Password must contain num and special character.',
    check(value) {
      return /(?=.*[\W_])(?=.*\d)/.test(value);
    },
  };
  const oneNumberAndSymbolRule = class extends Validator {
    get message() {
      return _oneNumberAndSymbolRule.message;
    }

    check(value) {
      return _oneNumberAndSymbolRule.check(value);
    }
  };
  // Rule: 3
  const _shouldNotContainFirstNameRule = {
    message: 'The Password must not contain first name.',

    check(value, fn) {
      fn = (fn ?? first_name)?.toLowerCase();
      return (!fn ? true : !value?.toLowerCase()?.includes(fn));
    },
  };
  const shouldNotContainFirstNameRule = class extends Validator {
    get message() {
      return _shouldNotContainFirstNameRule.message;
    }

    check(value) {
      return _shouldNotContainFirstNameRule.check(value);
    }
  };
  // Rule: 4
  const _shouldNotContainLastNameRule = {
    message: 'The Password must not contain last name.',

    check(value, ln) {
      ln = (ln ?? last_name)?.toLowerCase();
      return (!ln ? true : !value?.toLowerCase()?.includes(ln));
    },
  };
  const shouldNotContainLastNameRule = class extends Validator {
    get message() {
      return _shouldNotContainLastNameRule.message;
    }

    check(value) {
      return _shouldNotContainLastNameRule.check(value);
    }
  };
  // Rule: 5
  const _shouldNotContainUserNameRule = {
    get message() {
      return 'The Password must not contain part of email/username.';
    },

    check(value, email) {
      email = email ?? username;
      const should_not_contains = [
        ...email?.split('@'),
        ...email?.split('.'),
        ...email?.split('#'),
        ...email?.split(','),
        ...email?.split('_'),
        ...email?.split('-'),

        ...(email?.split('@')?.[0]?.split('.') || []),
        ...(email?.split('@')?.[0]?.split('#') || []),
        ...(email?.split('@')?.[0]?.split(',') || []),
        ...(email?.split('@')?.[0]?.split('_') || []),
        ...(email?.split('@')?.[0]?.split('-') || []),

        ...(email?.split('@')?.[1]?.split('.') || []),
        ...(email?.split('@')?.[1]?.split('#') || []),
        ...(email?.split('@')?.[1]?.split(',') || []),
        ...(email?.split('@')?.[1]?.split('_') || []),
        ...(email?.split('@')?.[1]?.split('-') || []),

      ].filter(item => item).map(item => item.toLowerCase());
      return !should_not_contains.some(item => value?.toLowerCase()?.includes(item));
    },
  };
  const shouldNotContainUserNameRule = class extends Validator {
    get message() {
      return _shouldNotContainUserNameRule.message;
    }

    check(value) {
      return _shouldNotContainUserNameRule.check(value);
    }
  };
  const oneLowerAndUpper = ref(oneLowerAndUpperRule);
  const oneNumberAndSymbol = ref(oneNumberAndSymbolRule);
  const shouldNotContainFirstName = ref(shouldNotContainFirstNameRule);
  const shouldNotContainLastName = ref(shouldNotContainLastNameRule);
  const shouldNotContainUserName = ref(shouldNotContainUserNameRule);
  return {
    oneLowerAndUpper,
    oneNumberAndSymbol,
    shouldNotContainFirstName,
    shouldNotContainLastName,
    shouldNotContainUserName,
    _oneLowerAndUpper: _oneLowerAndUpperRule,
    _oneNumberAndSymbol: _oneNumberAndSymbolRule,
    _shouldNotContainFirstName: _shouldNotContainFirstNameRule,
    _shouldNotContainUserName: _shouldNotContainUserNameRule,
    _shouldNotContainLastName: _shouldNotContainLastNameRule,
  };
}
