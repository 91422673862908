<script setup>
/* SUPPORTS: [category, tag, member, date, text, number, task-status, form-status, asset] */

// Common imports
import HawkCategoryName from '~/common/components/atoms/hawk-category-name.vue';
import HawkMembers from '~/common/components/organisms/hawk-members-badge/hawk-members.vue';
import HawkTagsName from '~/common/components/atoms/hawk-tags-name.vue';

// Task imports
import TaskStatus from '~/tasks/components/atoms/task-status.vue';
import TaskPriority from '~/tasks/components/atoms/task-priority.vue';

// Renderer imports
import AssetRenderer from '~/common/components/hawk-field-renderer/asset-renderer.vue';
import TextRenderer from '~/common/components/hawk-field-renderer/text-renderer.vue';
import DateRenderer from '~/common/components/hawk-field-renderer/date-renderer.vue';
import BooleanRenderer from '~/common/components/hawk-field-renderer/boolean-renderer.vue';

const props = defineProps({
  field_type: {
    type: String,
    required: true,
  },
  options: {
    type: Object,
    required: true,
  },
});

const COMMON_COMPONENTS = {
  category: HawkCategoryName,
  member: HawkMembers,
  tag: HawkTagsName,
};
const TASK_COMPONENTS = {
  'task-status': TaskStatus,
  'task-priority': TaskPriority,
};
const RENDERER_COMPONENTS = {
  date: DateRenderer,
  text: TextRenderer,
  number: TextRenderer,
  asset: AssetRenderer,
  boolean: BooleanRenderer,
};

const TYPE_TO_COMPONENT_MAP = {
  ...COMMON_COMPONENTS,
  ...TASK_COMPONENTS,
  ...RENDERER_COMPONENTS,
};

const component_props = computed(() => {
  if (RENDERER_COMPONENTS[props.field_type])
    return props.options;

  const prop_key_map = {
    'category': 'uid',
    'member': 'members',
    'tag': 'tags',
    'task-status': 'status',
    'task-priority': 'priority',
  };

  return { ...props.options, [prop_key_map?.[props.field_type]]: props.options?.value };
});
</script>

<template>
  <component :is="TYPE_TO_COMPONENT_MAP[field_type]" v-bind="component_props" />
</template>
