<script setup>
// --------------------------------- Imports -------------------------------- //
import { TEMPLATE_ICONS } from '~/forms/constants';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  icon_id: {
    type: Number,
    required: true,
  },
});

// ------------------- Variables - Local - consts and lets ------------------ //

// -------------------------------- Functions ------------------------------- //
function getTemplateIconUrl(icon_id) {
  const imgUrl = new URL(`/assets/template-icons/${TEMPLATE_ICONS[icon_id]}`, import.meta.url).href;
  return imgUrl;
}
</script>

<template>
  <img
    alt="template icon"
    :src="getTemplateIconUrl(icon_id)"
  >
</template>
