<script setup>
import { inject, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { sortBy, sum } from 'lodash-es';
import dayjs from 'dayjs';
import { useCommonStore } from '~/common/stores/common.store.js';
import NewMapPopup from '~/terra/components/self-service/new-map-popup.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import { sleep } from '~/common/utils/common.utils.js';

const common_store = useCommonStore();
const auth_store = useAuthStore();
const { preProcessGeojson } = useTerraHelperComposable();
const { editGroupDetails, getAffectedAndTotalMetrics, getThermTableColumns } = useThermHelperComposable();
const route = useRoute();
const router = useRouter();
const $t = inject('$t');
const $track_event = inject('$track_event');
const $services = inject('$services');

const state = reactive({
  is_loading: false,
  search: '',
});

const new_map_popup = useModal({
  component: NewMapPopup,
  attrs: {
    onClose() {
      new_map_popup.close();
    },
    async on_save({ name, geojson_data }) {
      try {
        const payload = {
          name,
          organization: auth_store?.current_organization?.uid,
        };
        const container_response = await $services.common.post({
          baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
          url: `assets/${route.params.asset_id}/containers`,
          body: {
            container: payload,
          },
        });
        await sleep(1000);
        const container_uid = container_response?.data?.container?.uid;
        const group_response = await $services.common.post({
          baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
          url: `assets/${route.params.asset_id}/containers/${container_uid}/groups`,
          body: {
            project_group: { ...payload, name: 'Layer' },
          },
        });
        await sleep(1000);
        const group_uid = group_response?.data?.project_group?.uid;
        const project_response = await $services.common.post({
          baseURL: import.meta.env.VITE_APP_SENSEHAWK_HOST,
          url: `assets/${route.params.asset_id}/containers/${container_uid}/groups/${group_uid}/projects`,
          body: {
            project: { ...payload, name: 'Block' },
          },
        });
        const project_uid = project_response?.data?.project?.uid;
        await sleep(3000);
        await $services.features.post({
          uid: project_uid,
          body: preProcessGeojson(geojson_data),
          attribute: `container/${container_uid}/project/${project_uid}`,
        });
        $track_event('New map', { asset: common_store.assets_map[route.params.asset_id]?.name, module: 'Terra' });
        router.push({
          name: 'terra-viewer',
          params: { asset_id: route.params.asset_id, id: container_uid },
        });
      }
      catch (err) {
        logger.log(err);
      }
      new_map_popup.close();
    },
  },
});

const columns = computed(() => {
  return [
    {
      id: 'name',
      header: $t('Map Name'),
      accessorKey: 'name',
      enableSorting: false,
      enableResizing: false,
    },
    {
      id: 'description',
      header: $t('Description'),
      accessorKey: 'description',
      enableSorting: false,
      enableResizing: false,
    },
  ];
});
const sorted_terra_list = computed(() => {
  return sortBy(common_store.asset_meta[route.params.asset_id]?.terra_list || [], item => item.name).filter(item => state.search.length ? item.name?.toLowerCase()?.includes(state.search?.toLowerCase()) : true);
});
const sorted_therm_list = computed(() => {
  return sortBy(common_store.asset_meta[route.params.asset_id]?.therm_list || [], item => item.name).filter(item => state.search.length ? item.name?.toLowerCase()?.includes(state.search?.toLowerCase()) : true);
});

watch(() => route.params.asset_id, () => {
  if (common_store.assets_map && route.params.asset_id)
    getData();
}, { immediate: true });

async function getData() {
  state.is_loading = true;
  try {
    const terraList = common_store.asset_meta[route.params.asset_id]?.terra_list;
    const thermList = common_store.asset_meta[route.params.asset_id]?.therm_list;
    // Terra List
    // Will not show loader if there is already a list present and just load the new data/api call in the background
    if (terraList?.length)
      state.is_loading = false;

    const terra_response = await $services.terra_view_service.getAll({
      query: {
        asset: route.params.asset_id,
      },
    });

    common_store.asset_meta[route.params.asset_id] = {
      ...common_store.asset_meta[route.params.asset_id],
      terra_list: terra_response.data || [],
    };

    // Therm list
    // Will not show loader if there is already a list present and just load the new data/api call in the background
    if (thermList?.length)
      state.is_loading = false;
    // Therm list
    const therm_response = await $services.therm.getAll({
      query: {
        assetUid: route.params.asset_id,
        metrics: true,
        complete: true,
        groups: true,
        page_size: 1000,
        page_number: 1,

      },
      attribute: 'views',
    });

    common_store.asset_meta[route.params.asset_id] = {
      ...common_store.asset_meta[route.params.asset_id],
      therm_list: therm_response.data || [],

    };

    state.is_loading = false;
  }
  catch (err) {
    logger.log(err);
    state.is_loading = false;
  }
}

function total_issues(issues) {
  return sum(Object.values({ ...issues, table: 0 }));
}

function handleRowClick(e, name, row_obj) {
  const parent_row = row_obj?.getParentRow();
  if (parent_row)
    router.push({
      name,
      params: { asset_id: route.params.asset_id, id: parent_row.original?.uid },
      query: {
        group: e.uid,
      },
    });
  else if (name === 'terra')
    router.push({
      name: 'terra-viewer',
      params: { asset_id: route.params.asset_id, id: e.uid },
    });
  else
    router.push({
      name,
      params: { asset_id: route.params.asset_id, id: e.uid },
    });
}
function getThermSubRows(row) {
  if (Array.isArray(row.groups) && row.groups?.length)
    return row.groups.map(item => ({ ...item, name: item?.properties?.date ? dayjs(item.properties.date)?.utc()?.format('DD-MM-YYYY') : item.name }));
  return [];
}
</script>

<template>
  <div class="p-4 w-full">
    <p class="font-semibold text-lg mb-6 text-gray-900">
      {{ $t('Maps') }}
    </p>
    <div class="flex gap-3 items-center justify-between">
      <HawkSearchInput v-model:search="search" placeholder="Search" @update:modelValue="state.search = $event" />
      <HawkButton v-if="auth_store.check_permission('modify_terra_containers', route.params.asset_id)" @click="new_map_popup.open()">
        <IconHawkPlus class="text-white" />
        {{ $t('New Map') }}
      </HawkButton>
    </div>
    <div class="mt-4">
      <div v-if="state.is_loading">
        <hawk-loader />
      </div>
      <div v-else-if="sorted_terra_list?.length === 0 && sorted_therm_list?.length === 0">
        <hawk-illustrations type="no-data" for="terra" />
      </div>
      <template v-else>
        <template v-if="sorted_terra_list?.length">
          <p class="font-semibold text-md mb-3 mt-2 text-gray-900">
            {{ $t('Terra') }}
          </p>
          <HawkTable
            is_gapless
            :disable_resize="true"
            :data="sorted_terra_list"
            :columns="columns"
            :show_menu_header="false"
            class="max-w-full"
            @rowClicked="handleRowClick($event, 'terra')"
          >
            <template #name="name">
              <p class="font-medium text-sm">
                {{ name.data.row.original.name }}
              </p>
            </template>
            <template #description="description">
              <p v-if="description.data.row.original.description" class="text-gray-600 text-sm">
                {{ description.data.row.original.description }}
              </p>
              <p v-else class="text-gray-600 text-sm">
                {{ $t('No description added') }}
              </p>
            </template>
          </HawkTable>
        </template>
        <template v-if="sorted_therm_list?.length">
          <p class="font-semibold text-md mb-3 mt-4 text-gray-900">
            {{ $t('Therm') }}
          </p>
          <HawkTable
            is_gapless
            :disable_resize="true"
            :data="sorted_therm_list"
            :columns="getThermTableColumns()"
            :get-children="getThermSubRows"
            :show_menu_header="false"
            sub_rows_key="groups"

            class="max-w-full"
            @rowClicked="(original, event, row_obj) => handleRowClick(original, 'therm', row_obj)"
          >
            <template #name="name">
              <p class="font-medium text-sm">
                {{ name.data.row.original.name }}
              </p>
            </template>
            <template #description="description">
              <p v-if="description.data.row.original.description" class="text-gray-600 text-sm">
                {{ description.data.row.original.description }}
              </p>
              <p v-else class="text-gray-600 text-sm">
                {{ $t('No description added') }}
              </p>
            </template>
            <template #affected="affected">
              <p class="text-gray-600 text-sm">
                {{ getAffectedAndTotalMetrics(affected.data.row.original).affected_capacity }}KW
              </p>
            </template>
            <template #total_dc_capacity="total_dc_capacity">
              <p class="text-gray-600 text-sm">
                {{ getAffectedAndTotalMetrics(total_dc_capacity.data.row.original).total_dc_capacity }}MW
              </p>
            </template>

            <template #issues="issues">
              <p class="text-gray-600 text-sm">
                {{ total_issues(issues.data.row.original.metrics.issues) }}
              </p>
            </template>
            <template #action_button="{ data: group_details }">
              <div
                v-if="group_details.row.original?.projects"
                class="cursor-pointer"
                @click.stop="editGroupDetails(group_details.row.original, group_details.row.getParentRow().original)"
              >
                <IconHawkPencilOne class="text-gray-500 h-4.5 w-4.5" />
              </div>
            </template>
          </HawkTable>
        </template>
      </template>
    </div>
  </div>
</template>
