<template>
  <div class="bg-white absolute top-0 z-[9999] right-0 bottom-0 left-0 grid place-content-center">
    <div>
      <div class="grid place-items-center">
        <IconIllustrationMaintenance class="h-96 w-96" />
      </div>
      <div class="text-center text-2xl font-semibold mb-4">
        Website under maintenance
      </div>
      <div class="text-center">
        This website is currently undergoing scheduled maintenance. We should be back shortly
      </div>
    </div>
  </div>
</template>
