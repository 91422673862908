<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';

import TaskStatus from '~/tasks/components/atoms/task-status.vue';
import DependencyPopup from '~/tasks/components/organisms/dependency-popup.vue';

import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const $date = inject('$date');
const task_store = inject('task_store');

const { task_uid, update_task_uid } = inject('task-uid');
const updateFunction = inject('updateFunction');

const router = useRouter();
const route = useRoute();

const { checkTaskPermission } = useTasksPermissions();

const task_details = computed(() => {
  return task_store.get_current_task(task_uid.value);
});

const dependencies = computed(() => {
  if (task_store.dependencies && Object.keys(task_store.dependencies).length) {
    const linked_tasks = task_details.value?.linked_uids?.map((uid) => {
      if (task_store.dependencies[uid])
        return {
          ...task_store.dependencies[uid],
          uid,
          title: 'Linked',
          type: 'linked_tasks',
        };
      return {
        uid,
        loading: true,
      };
    });
    const blocking_tasks = task_details.value?.blocking?.map((uid) => {
      if (task_store.dependencies[uid])
        return {
          ...task_store.dependencies[uid],
          uid,
          title: 'Blocking',
          type: 'blocking',
        };
      return {
        uid,
        loading: true,
      };
    });
    const waiting_on_tasks = task_details.value?.blocked_by?.map((uid) => {
      if (task_store.dependencies[uid])
        return {
          ...task_store.dependencies[uid],
          uid,
          title: 'Waiting',
          type: 'waiting_on',
        };
      return {
        uid,
        loading: true,
      };
    });
    return [
      ...linked_tasks,
      ...blocking_tasks,
      ...waiting_on_tasks,
    ];
  }
  return [];
});

async function deleteDependency(dependency) {
  const type_map = {
    linked_tasks: 'linked_uids',
    waiting_on: 'blocked_by',
    blocking: 'blocking',
  };
  const arr = task_details.value[type_map[dependency.type]].filter(uid => uid !== dependency.uid);
  const payload = {
    [type_map[dependency.type]]: arr,
  };
  await updateFunction(payload);
}
const { open: openDependencyPopup, close: closeDependencyPopup } = useModal({
  component: DependencyPopup,
  attrs: {
    task_store,
    task_uid: task_uid.value,
    onClose() {
      closeDependencyPopup();
    },
    async onUpdate(payload) {
      await updateFunction(payload);
    },
  },
});
function openDependencyTask(uid) {
  task_store.create_navigation_context({
    current_task_uid: uid,
    task_nav_uids: dependencies.value.map(task => task.uid),
    strict: true,
  });
  task_store.set_navigation_meta({
    disable: {
      prev: task_store.current_task_index === 0,
      next: task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1,
    },
  });
  const task = btoa(JSON.stringify({
    id: uid,
    store_key: task_store.$id,
  }));
  router.replace({
    ...route,
    query: {
      task,
    },
  });
}
</script>

<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <div class="text-lg text-gray-900 font-semibold">
        {{ $t('Dependencies') }}
      </div>
      <HawkButton
        v-if="checkTaskPermission({ permission: 'can_modify', instance: task_details })"
        type="outlined"
        @click="openDependencyPopup"
      >
        <IconHawkPlusWhite class="text-white h-3" />
        <p class="whitespace-nowrap">
          {{ $t('Add Dependencies') }}
        </p>
      </HawkButton>
    </div>
    <div v-if="dependencies.length" class="rounded-lg border">
      <div>
        <template v-for="dependency in dependencies" :key="dependency.uid">
          <div v-if="dependency.loading" class="px-8 py-6 border-b last:border-0">
            <hawk-skeleton custom_classes="w-full" />
          </div>
          <div
            v-else
            class="grid grid-cols-12 py-5 hover:bg-gray-100 group cursor-pointer border-b last:border-0"
            @click.stop="openDependencyTask(dependency.uid)"
          >
            <div class="col-span-4 pl-6 flex flex-col justify-center">
              <p class="text-xs text-gray-600 mb-0.5">
                {{ dependency.title }}
              </p>
              <p class="text-sm font-medium text-gray-900 break-all">
                {{ dependency.name }}
              </p>
            </div>
            <div class="col-span-3 text-sm text-gray-600 flex items-center">
              {{ dependency.category_name || '-' }}
            </div>
            <div class="col-span-3 flex items-center">
              <span class="text-sm whitespace-nowrap" :class="[dependency.due_date && new Date(dependency.due_date) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']">
                {{ dependency.due_date ? $date(dependency.due_date, 'DD MMMM YYYY') : '-' }}
              </span>
            </div>
            <div class="col-span-1 flex items-center">
              <TaskStatus :status="dependency.status " />
            </div>
            <div class="col-span-1 invisible group-hover:visible flex items-center">
              <IconHawkTrashThree
                v-show="checkTaskPermission({ permission: 'can_modify', instance: task_details })"
                class="text-gray-600"
                @click.stop="deleteDependency(dependency)"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <HawkIllustrations v-else variant="mini" type="no-data" for="dependencies" />
  </div>
</template>
