<script setup>
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  object_key: {
    type: String,
  },
  values_object: {
    type: Object,
  },
});
const emit = defineEmits(['updateCount']);
const terra_store = useTerraStore();

const form = ref({ range: [0, 100], min: 0, max: 100 });
const state = reactive({
  min: 0,
  max: 100,
  no_features: false,
});

function updateFilters() {
  form.value.min = form.value.range[0];
  form.value.max = form.value.range[1];
  const values = Object.keys(props.values_object).map(Number);
  state.no_features = true;
  values.forEach((value) => {
    const values_at_default = (form.value.range[0] === state.min && form.value.range[1] === state.max);
    const check_selected = !values_at_default && (value >= form.value.range[0] && value <= form.value.range[1]);
    if (values_at_default || check_selected)
      state.no_features = false;
    terra_store.filters_state.key_values_data[props.object_key][value].selected = check_selected;
  });
  if (!terra_store.filters_state.filter_forms)
    terra_store.filters_state.filter_forms = {};
  terra_store.filters_state.filter_forms[props.object_key] = form.value;
  terra_store.terra_track_events('Filters applied');
  emit('updateCount');
}

function updateRange(key) {
  if (key === 'min' && form.value.min < state.min)
    form.value.min = state.min;
  if (key === 'max' && form.value.max > state.max)
    form.value.max = state.max;
  if (form.value.min < form.value.max)
    form.value.range[key === 'min' ? 0 : 1] = Number(form.value[key]);
}

function setFormData() {
  const values = Object.keys(props.values_object).map(Number);
  values.forEach((val) => {
    const min = Math.floor(val);
    const max = Math.ceil(val);
    if (state.min === 0)
      state.min = min;
    if (state.max === 100)
      state.max = max;
    if (state.min > min)
      state.min = min;
    if (state.max < max)
      state.max = max;
  });
  form.value.range = [state.min, state.max];
  form.value.min = state.min;
  form.value.max = state.max;
  if (terra_store.filters_state.filter_forms?.[props.object_key])
    form.value = { ...form.value, ...terra_store.filters_state.filter_forms[props.object_key] };
}
setFormData();
</script>

<template>
  <Vueform v-model="form" :sync="true" size="sm">
    <SliderElement
      name="range"
      class="mb-4"
      tooltip-position="bottom"
      :merge="-1"
      :min="Number(state.min)"
      :max="Number(state.max)"
      @change="updateFilters"
    />
    <TextElement
      name="min"
      input-type="number"
      :label="$t('Min')"
      autocomplete="off"
      @blur="updateRange('min')"
    />
    <TextElement
      name="max"
      input-type="number"
      :label="$t('Max')"
      autocomplete="off"
      @blur="updateRange('max')"
    />
    <div class="col-span-12">
      <div v-if="(+form.min > +form.max)" class="text-[13px] text-red-600 mb-1">
        {{ $t('Invalid min and max input range') }}
      </div>
      <div v-if="state.no_features" class="text-[13px] text-red-600">
        {{ $t('No features in given range') }}
      </div>
    </div>
  </Vueform>
</template>
