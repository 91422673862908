<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { omit } from 'lodash-es';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import SmComponentForm from '~/system-model/components/forms/sm-component-form.vue';
import SmSidebarTree from '~/system-model/components/sm-sidebar/sm-sidebar-tree.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import SmComponentDuplicateForm from '~/system-model/components/forms/sm-component-duplicate-form.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { route, $t, $toast } = useCommonImports();
const system_model_store = useSystemModelStore();

const { active_instance, sidebar_force_render, sidebar_hierarchy: tree_data } = storeToRefs(system_model_store);

const force_re_render = ref(true);

async function forceRerender() {
  force_re_render.value = false;
  await nextTick();
  force_re_render.value = true;
}

watch(tree_data, () => {
  if (!active_instance.value)
    forceRerender();
});

watch(sidebar_force_render, (newValue) => {
  if (newValue)
    forceRerender();
});

const context_menu = computed(() => {
  return [
    { label: $t('Add child'), action: 'add_child' },
    { label: $t('Edit'), action: 'edit' },
    { label: $t('Delete'), action: 'delete' },
    { label: $t('Detach'), action: 'detach' },
    { label: $t('Duplicate'), action: 'duplicate' },
  ];
});

const sidebar_modal = useModal({
  component: SmComponentForm,
  attrs: {
    onClose() {
      sidebar_modal.close();
    },
  },
});

const detach_component_modal = useModal({
  component: HawkDeletePopup,
});

const duplicate_component_modal = useModal({
  component: SmComponentDuplicateForm,
});

async function addChildComponent(parent_id = null) {
  sidebar_modal.patchOptions({
    attrs: {
      onClose() {
        sidebar_modal.close();
      },
      onClosed() {
        const attrs = omit(sidebar_modal.options.attrs, ['parent_id']); // reset attrs
        sidebar_modal.options.attrs = attrs;
      },
      parent_id,
    },
  });
  sidebar_modal.open();
}

const delete_component_modal = useModal({
  component: HawkDeletePopup,
});

function menuHandler(item, data) {
  const item_uid = data.key.split('-')[0];
  switch (item.action) {
    case 'add_child':
      addChildComponent(item_uid);
      break;
    case 'edit':
      editComponent(item_uid);
      break;
    case 'delete':
      openDeletePopup(item_uid);
      break;
    case 'detach':
      openDetachPopup(item_uid, data?.parentKey?.split('-')[0]);
      break;
    case 'duplicate':
      openDuplicateComponentModal(item_uid);
      break;
  }
}

function openDetachPopup(component_id, parent_id = null) {
  detach_component_modal.patchOptions({
    attrs: {
      header: $t('Detach Component'),
      button_text: $t('Detach'),
      content: 'Are you sure you want to detach this component?',
      confirm: async () => {
        await removeComponentAction({ component_id, parent_id, type: 'detach' });
        detach_component_modal.close();
      },
      onClose() {
        detach_component_modal.close();
      },
    },
  });
  detach_component_modal.open();
}

function openDeletePopup(component_id) {
  delete_component_modal.patchOptions({
    attrs: {
      header: $t('Delete Component'),
      button_text: $t('Delete'),
      content: 'Are you sure you want to delete this component?',
      confirm: async () => {
        await removeComponentAction({ component_id, type: 'delete' });
        delete_component_modal.close();
      },
      onClose() {
        delete_component_modal.close();
      },
    },
  });
  delete_component_modal.open();
}

function openDuplicateComponentModal(component_uid) {
  duplicate_component_modal.patchOptions({
    attrs: {
      onClose() {
        duplicate_component_modal.close();
      },
      component_uid,
    },
  });
  duplicate_component_modal.open();
}

async function editComponent(current_uid) {
  const current_component = await system_model_store.set_active_component_details({ component_id: current_uid }, false);
  sidebar_modal.patchOptions({
    attrs: {
      onClose() {
        sidebar_modal.close();
      },
      onClosed() {
        const attrs = omit(sidebar_modal.options.attrs, ['component_detail']); // reset attrs
        sidebar_modal.options.attrs = attrs;
      },
      component_detail: current_component,
    },
  });
  sidebar_modal.open();
}

async function removeComponentAction(options) {
  await system_model_store.delete_component(options);
  await system_model_store.set_active_component_hierarchy({ template_id: route?.params?.template_id });
  $toast({
    title: 'Component deleted successfully',
    type: 'success',
  });
}
</script>

<template>
  <div id="sidebar_root" class="overflow-y-auto overflow-x-hidden h-[80vh]">
    <SmSidebarTree
      v-if="tree_data.length && force_re_render"
      class="h-full"
      :tree_items="tree_data"
      :context_menu="!active_instance && system_model_store.can_modify_resources ? context_menu : []"
      @selectMenuItem="menuHandler"
    />
  </div>
</template>

<style lang="scss">
$background-color_2: transparent;
$background-color_3: #d0d5dd;
$background-color_4: #a6a9af;

#sidebar_root {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $background-color_2;
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $background-color_2;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $background-color_3;
    &:hover {
      background-color: $background-color_4;
    }
  }
}
</style>

<style lang="scss" scoped>
:deep(.selected_branch) {
  svg {
    color: #fff;
  }
  &:hover {
    svg {
      @apply text-gray-700;
    }
  }
}
</style>
