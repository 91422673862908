<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { debounce } from 'lodash-es';
import { VirTree } from 'vue-virtual-tree';
import { getRandomKey, sleep } from '~/common/utils/common.utils';

const props = defineProps({
  hierarchy_items: {
    type: Array,
    required: true,
  },
  element_data: {
    type: Object,
    required: true,
  },
  selected_instances: {
    type: Object,
    default: () => {},
  },
  multi: {
    type: Boolean,
    default: false,
  },
  dropdownItemTruncateLength: {
    type: Number,
    default: 50,
  },
  allow_custom_locations: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['selected', 'close']);

const virtual_tree = ref('');

const hierarchy_items_keyified = ref([]);
const tree_items = ref([]);
const search_key = ref(null);
const custom_locations = ref([]);

const default_expanded = ref([]);
const default_checked = ref([]);

const active_node_keys = ref(null);
const active_instances = ref({});

onBeforeMount(() => {
  // Add nodekey to each node and set up the tree data
  hierarchy_items_keyified.value = keyify(props.hierarchy_items);
  tree_items.value = hierarchy_items_keyified.value;

  // Setting expanded, selected and checked nodes
  setActiveValues();
  default_expanded.value = findParentIds(tree_items.value, active_instances.value)[0];
});
onMounted(() => {
  if (!props.multi)
    setActiveItemCss();
  if (props.allow_custom_locations)
    custom_locations.value = Object.keys(props.selected_instances).filter(uid => uid.split('-')?.[1] === 'custom').map(uid => ({ name: uid.split('-')?.[0], uid, key: uid }));
});

/* ------ Mounted helpers ------ */
function setActiveValues() {
  if (props.multi)
    active_instances.value = Object.keys(props.selected_instances);
  else
    active_instances.value = getActiveInstances([props.element_data]);
  active_node_keys.value = getActiveNodeKeys(tree_items.value);
  default_checked.value = active_node_keys.value;
}

function randomNodeKey(id) {
  return `${id}-${getRandomKey()}`;
}
function keyify(items) {
  return items.map((item) => {
    if (item?.children) {
      return {
        ...item,
        hasChildren: true,
        children: keyify(item.children),
        nodeKey: randomNodeKey(item.uid),
      };
    }
    else {
      return {
        ...item,
        nodeKey: randomNodeKey(item.uid),
      };
    }
  });
}

function getActiveInstances(elements) {
  const activeInstances = [];

  function searchActiveInstances(element) {
    if (element?.child && Object.keys(element.child).length)
      searchActiveInstances(element.child);
    else
      activeInstances.push(element.uid);
  }
  elements.forEach(element => searchActiveInstances(element));

  return activeInstances;
}

function getActiveNodeKeys(tree_items) {
  const activeNodeKeys = [];

  function searchTree(treeItems, activeInstances) {
    treeItems.forEach((item) => {
      if (activeInstances.includes(item.uid))
        activeNodeKeys.push(item.nodeKey); // Object found, add it to the list
      if (item.hasChildren && item?.children?.length)
        searchTree(item.children, activeInstances);
    });
  }

  searchTree(tree_items, active_instances.value);
  return activeNodeKeys;
}

function findParentIds(nestedData, ids, parentIds = []) {
  const result = [];
  nestedData.forEach((item) => {
    if (ids.includes(item.uid))
      result.push(parentIds);
    if (item?.children) {
      const newParentIds = [...parentIds, item.nodeKey];
      const childResult = findParentIds(item.children, ids, newParentIds);
      if (childResult.length > 0)
        result.push(...childResult);
    }
  });

  return result.length > 0 ? result : [];
}

/* ------ Styling helpers ------ */
function setActiveItemCss() {
  setTimeout(() => {
    const selected_item = document.querySelector('.selected_branch')?.parentElement?.parentElement;
    if (selected_item)
      selected_item.classList.add('active-node');
  }, 50);
}
function toggleExpanded(params) {
  if (params.state && !props.multi)
    setActiveItemCss();
}

/* ------ Search helpers ------ */
const handleSearch = debounce((e) => {
  if (e.target.value) {
    tree_items.value = getFilteredTree(e.target.value);
  }
  else {
    default_expanded.value = [];
    tree_items.value = hierarchy_items_keyified.value;
  }
}, 500);
function getFilteredTree(query) {
  default_expanded.value = [];
  const recursion = (list) => {
    const matchedNodes = [];
    list.forEach((item) => {
      if (item.name.toLowerCase().includes(query.toLowerCase())) {
        matchedNodes.push(item);
      }
      else if (item?.children?.length) {
        const child_data = recursion(item.children);
        if (child_data.length) {
          default_expanded.value.push(item.nodeKey);
          matchedNodes.push({ ...item, children: child_data });
        }
      }
    });
    return matchedNodes;
  };

  if (query)
    return recursion(hierarchy_items_keyified.value);
  return [];
}

/* ------ Selection handlers ------ */
function handleSingleSelection(node) {
  emit('selected', node);
  emit('close');
}
function handleMultiSelection() {
  const selected_nodes = virtual_tree.value.getCheckedNodes();
  emit('selected', [...custom_locations.value, ...selected_nodes]);
}
async function createCustomLocation() {
  custom_locations.value.push({
    name: search_key.value,
    uid: `${search_key.value}-custom`,
    key: `${search_key.value}-custom`,
    custom: true,
  });

  search_key.value = null;
  tree_items.value = hierarchy_items_keyified.value;
  await sleep();
  const selected_nodes = virtual_tree.value?.getCheckedNodes() || [];
  emit('selected', [...custom_locations.value, ...selected_nodes]);
}

function deleteCustomLocation(uid) {
  custom_locations.value = custom_locations.value.filter(item => item.uid !== uid);
}

defineExpose({ setActiveValues, deleteCustomLocation });
</script>

<template>
  <div
    v-click-outside="() => emit('close')"
    class="absolute top-1 right-0 left-0 z-999 border border-gray-300 rounded-md bg-white p-1"
  >
    <hawk-search-input
      v-model="search_key"
      :full_width="true"
      class="mb-1"
      @input="handleSearch"
    />
    <div v-if="hierarchy_items.length">
      <div class="max-h-60 overflow-scroll scrollbar">
        <VirTree
          v-show="tree_items.length"
          ref="virtual_tree"
          :virtual="{ size: 37, remain: 5 }"
          :show-checkbox="multi"
          :check-strictly="multi"
          :default-checked-keys="default_checked"
          :default-selected-keys="active_node_keys"
          :default-expanded-keys="default_expanded"
          :source="tree_items"
          @expand-change="toggleExpanded"
          @check-change="handleMultiSelection"
        >
          <template #node="{ node }">
            <div
              class="flex justify-between"
              :class="[active_node_keys.includes(node.key) ? 'selected_branch' : null]"
              :data-key="node.key"
              @click="() => !multi && handleSingleSelection(node)"
            >
              <div
                v-tippy="node?.name?.length > dropdownItemTruncateLength ? node.name : ''"
                class="flex-1 truncate max-w-full text-sm font-medium item-title"
              >
                {{ node.name }}
              </div>
            </div>
          </template>
        </VirTree>
        <div v-show="!tree_items.length">
          <p
            v-if="allow_custom_locations && search_key"
            class="text-sm font-semibold text-gray-800 p-2 cursor-pointer"
            @click="createCustomLocation"
          >
            + Create {{ search_key }}
          </p>
          <div v-else class="text-sm text-gray-800 p-2">
            {{ $t('No location found') }}
          </div>
        </div>
      </div>
    </div>
    <p
      v-else-if="allow_custom_locations && search_key"
      class="text-sm font-semibold text-gray-800 p-2 cursor-pointer"
      @click="createCustomLocation"
    >
      + Create {{ search_key }}
    </p>
    <div v-else class="text-sm text-gray-800 p-2">
      {{ $t('No location found') }}
    </div>
  </div>
</template>

<style lang="scss">
@import url("vue-virtual-tree/style.css");
</style>

<style scoped lang="scss">
:deep(.vir-tree-node) {
  @apply py-2 px-4 flex items-center hover:bg-gray-100 hover:text-gray-700 text-gray-700 rounded-lg;
  .node-content {
    @apply w-full;
  }
  &.active-node {
    background-color: #344054;
    .item-title {
      color: white;
    }
    svg {
      color: white;
    }
    &:hover {
      @apply bg-gray-100;
      .item-title {
         @apply text-gray-700;
      }
      svg {
        @apply text-gray-500;
      }
    }
  }
}
:deep(.node-title) {
  color: unset;
}
</style>
