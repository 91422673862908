<script setup>
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { DynamicScroller } from 'vue-virtual-scroller';

import { ref } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { debounce, filter, pickBy, startsWith } from 'lodash-es';
import { useTerraStore } from '../store/terra.store';

const emit = defineEmits(['close']);
const terra_store = useTerraStore();
const form = ref({ search_input: '' });
const features_list = ref([]);
const selected_index = ref(0);
const selected_feature = ref({});
const scroller$ = ref({});
const is_focused = ref(true);
const is_loading = ref(false);

async function flyToFeature() {
  if (Object.keys(selected_feature.value).length) {
    const turf = (await import('@turf/turf'));
    terra_store.map.flyTo(
      {
        center: turf.centroid(selected_feature.value).geometry.coordinates,
        essential: true,
        zoom: 18,
      },
    );
    terra_store.selected_features = [selected_feature.value];
  }
  emit('close');
}

async function setFeature(feature) {
  form.value.search_input = feature.properties.name;
  selected_feature.value = feature;
  await flyToFeature();
}

onKeyStroke('ArrowDown', () => {
  selected_index.value = selected_index.value < features_list.value.length - 1 ? selected_index.value + 1 : selected_index.value;
  scroller$.value.scrollToItem(selected_index.value);
});

onKeyStroke('ArrowUp', () => {
  selected_index.value = selected_index.value !== 0 ? selected_index.value - 1 : 0;
  scroller$.value.scrollToItem(selected_index.value);
});

onKeyStroke('Enter', async () => {
  await setFeature(features_list.value[selected_index.value]);
});

const updateFilterList = debounce(() => {
  is_loading.value = true;
  features_list.value = [];
  selected_index.value = 0;
  const check = str => str && String(str).toLowerCase().includes(form.value.search_input.toLowerCase());
  if (form.value.search_input)
    features_list.value = filter(terra_store.features_on_map, (f) => {
      const match_found = (check(f.properties.name) || check(f.properties.description));
      if (!match_found) {
        const extraProperties = pickBy((f.properties.extraProperties || {}), (value, key) => !startsWith(key, '_'));
        return check(JSON.stringify(extraProperties));
      }
      return true;
    });
  is_loading.value = false;
  terra_store.terra_track_events('Features searched');
}, Math.ceil(terra_store.features_on_map.length / 10));
</script>

<template>
  <hawk-modal-container :width="400" content_class="rounded-xl min-w-[600px]" @close="$emit('close')">
    <Vueform v-model="form" sync>
      <TextElement
        :key="is_focused ? 1 : 2"
        name="search_input"
        autocomplete="off"
        class="border-b"
        :placeholder="$t('Search')"
        :add-classes="{
          TextElement: {
            inputContainer: '!h-[56px] !border-0 py-3 focus:!ring-0',
          },
        }"
        :attrs="{ autofocus: true }"
        :focused="is_focused"
        :override-class="{
          inputContainer_focused: 'ring-0',
        }"
        @change="is_loading = true; updateFilterList()"
      >
        <template #addon-before>
          <IconHawkSearchLg />
        </template>
        <template #addon-after>
          <HawkButton icon size="xs" type="outlined">
            <IconHawkKeyMac class="w-5 h-5" />
          </HawkButton>
        </template>
      </TextElement>
    </Vueform>
    <div class="h-[125px] overflow-y-auto scrollbar my-3 ml-5">
      <HawkLoader v-if="is_loading" class="!m-0" />
      <div v-else-if="!features_list.length && form.search_input" class="flex items-center justify-center text-sm h-24 text-gray-500 -ml-5">
        No results found for '{{ form.search_input }}'
      </div>
      <template v-else>
        <DynamicScroller
          ref="scroller$"
          v-slot="{ item, index }"
          class="h-full scrollbar"
          :items="features_list.map((f) => ({ uid: f.properties.uid, ...f }))"
          :min-item-size="42"
          key-field="uid"
          :prerender="10"
        >
          <div
            class="flex text-sm font-medium items-center justify-between px-2 rounded-lg py-2.5 mr-2 cursor-pointer hover:bg-gray-50 text-gray-700"
            :class="{ 'bg-gray-100': selected_index === index }"
            @click="$emit('close'); setFeature(item);"
          >
            <div class="text-[14px]">
              {{ item.properties.name || 'Untitled' }}
            </div>
            <div class="text-gray-600 invisible group-hover: mr-4" :class="{ '!visible': selected_index === index }">
              <IconHawkArrowRight class="w-5 h-5" />
            </div>
          </div>
        </DynamicScroller>
      </template>
    </div>
    <div class="flex items-center m-3">
      <HawkButton icon size="xs" type="outlined" class="p-[6px] mr-1.5">
        <IconHawkArrowUp class="w-[16px] h-[16px]" />
      </HawkButton>

      <HawkButton icon size="xs" class="p-[6px]" type="outlined">
        <IconHawkArrowDown class="w-[16px] h-[16px]" />
      </HawkButton>

      <span class="text-sm text-gray-500 font-semibold mx-2">{{ $t('to navigate') }}</span>

      <HawkButton icon size="xs" class="p-[6px]" type="outlined">
        <IconHawkCornerDownLeft class="w-[16px] h-[16px]" />
      </HawkButton>

      <span class="text-sm text-gray-500 font-semibold mx-2">{{ $t('to select') }}</span>

      <HawkButton class="h-[28px] p-[6px]" icon type="outlined">
        <span class="text-sm text-gray-500 font-semibold"> {{ $t('esc') }}</span>
      </HawkButton>

      <span class="text-sm text-gray-500 font-semibold mx-2">{{ $t('to close') }}</span>
    </div>
  </hawk-modal-container>
</template>
