<script setup>
import { useRoute } from 'vue-router';
import { useTasksStore } from '~/tasks/store/tasks.store';

import ConversationsList from '~/conversations/components/conversations-list.vue';
import ConversationComments from '~/conversations/components/conversation-comments.vue';

const route = useRoute();
const task_store = useTasksStore('conversations_tasks');

const PAGE_SIZE = 25;

const state = reactive({
  resource: {},
  is_loading: false,
  page_number: 1,
});

onMounted(async () => {
  await initializeConversations();
});

watch(() => route.params.asset_id, async (newValue) => {
  if (newValue)
    await initializeConversations();
});

async function initializeConversations() {
  state.page_number = 1;

  state.is_loading = true;
  await getTasks(true);
  state.is_loading = false;
}

async function getTasks(init = false) {
  const query = {
    page_number: state.page_number,
    page_size: PAGE_SIZE,
    is_template: false,
    has_comments: true,
    comment_stats: true,
    latest_comment: true,
    sort: '-last_commented_at',
    ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
  };
  await task_store.set_tasks(query, !init);
}

async function loadMore() {
  if (state.page_number * PAGE_SIZE < task_store.total_task_count) {
    state.page_number = state.page_number + 1;
    await getTasks();
  }
}

const displayConversation = (_module, element) => state.resource = element;

provide('task_store', task_store);
</script>

<template>
  <div class="h-[calc(100vh_-_65px)]">
    <div v-if="state.is_loading" class="h-full flex items-center justify-center">
      <hawk-loader />
    </div>
    <div v-else-if="task_store.tasks().length" class="flex w-full h-full">
      <ConversationsList
        class="w-2/4 border-r px-6"
        :resource="state.resource"
        :load_more="loadMore"
        @select-item="displayConversation"
      />
      <ConversationComments v-if="state.resource?.uid" :key="state.resource.uid" class="w-2/4 px-3 pt-4" :resource="state.resource" />
    </div>
    <div v-else class="w-full h-full flex items-center justify-center">
      <HawkIllustrations type="no-data" for="conversations" />
    </div>
  </div>
</template>
