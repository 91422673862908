<script setup>
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import FormWorkflowSchemaInput from '~/forms/atoms/form-schema-input.vue';
import DocumentGeneratePopup from '~/forms/components/form-workflows/sidebar/document-generate-block/document-generate-popup.vue';

const props = defineProps({
  form_data: {
    type: Object,
  },
  step_number: {
    type: Number,
    default: -1,
  },
  required: {
    type: Boolean,
    default: false,
  },
  is_settings: {
    type: Boolean,
    default: false,
  },
});

const document_generate_popup = useModal({
  component: DocumentGeneratePopup,
  attrs: {
    onClose() {
      document_generate_popup.close();
    },
  },
});
function formatFileData(file) {
  if (file?.service)
    return file;
  if (!file?.service_object)
    return null;
  const { logged_in_user_details } = useAuthStore();
  return {
    service: file.service_object,
    file_name: file?.name || file?.file_name || file?.fname,
    file_size: file?.size || file?.file_size,
    mime_type: file?.type || file?.mime_type,
    created_at: new Date().toISOString(),
    owner: { uid: logged_in_user_details?.user_id },
  };
}
function openGeneratePopup() {
  document_generate_popup.patchOptions({
    attrs: {
      document: props.form_data.document,
      schema: props.form_data.schema,
    },
  });
  document_generate_popup.open();
}
</script>

<template>
  <div class="col-span-12">
    <FileElement
      name="document"
      can-select
      :label="$t('Document template')"
      accept=".docx"
      :rules="['max:5000']"
      :messages="{ max: $t('The file size must not exceed 5 MB') }"
      class="mb-4"
      :presets="['hawk_file_element']"
      :format-data="(n, v) => ({ [n]: formatFileData(v) })"
      :info="is_settings ? $t('Export form data as a PDF using a custom layout/structure.') : ''"
      @updated="update"
    >
      <template #upload-trigger>
        <div class="flex items-center">
          <span class="mr-2">{{ $t('Select') }}</span>
          <IconHawkChevronDown class="w-5 h-5" />
        </div>
      </template>
      <template #description>
        <div v-if="form_data?.document?.service" class="flex items-center justify-between">
          <div class="flex items-center">
            <IconHawkCheckCircleGreen />
            <span class="font-medium mx-1.5 text-gray-900">
              {{ $t('Uploaded') }}
            </span>
            <span> {{ $date(form_data.document.created_at, 'DD[th] MMM YYYY h:m A') }}</span>
          </div>
          <HawkButton type="link" class="pointer-events-auto" @click="openGeneratePopup()">
            {{ $t('Test') }}
          </HawkButton>
        </div>
        <div v-else>
          {{ $t('Select a .docx file as a template for filling the form data.') }}
        </div>
      </template>
    </FileElement>
    <FormWorkflowSchemaInput
      name="schema"
      :rules="required ? ['required'] : []"
      :label="$t('Schema')"
      :description="$t('Define the name of the output file')"
      :editor_options="{
        placeholder_text: `${$t('Enter schema')}`,
      }"
      :step_number="step_number"
    />
  </div>
</template>
