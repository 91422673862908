<script setup>
import { reactive, ref } from 'vue';

const props = defineProps({
  default: String,

  digit_count: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update:otp']);

const digits = reactive([]);

if (props.default && props.default.length === props.digit_count)
  for (let i = 0; i < props.digit_count; i++)
    digits[i] = props.default.charAt(i);

else
  for (let i = 0; i < props.digit_count; i++)
    digits[i] = null;

const otpCont = ref(null);

const handleKeyDown = function (event, index) {
  if (event.key !== 'Tab'
          && event.key !== 'ArrowRight'
          && event.key !== 'ArrowLeft' && event.key !== 'v'
  )
    event.preventDefault();

  if (event.key === 'Backspace') {
    digits[index] = null;

    if (index !== 0)
      (otpCont.value.children)[index - 1].focus();
    emit('update:otp', digits.join(''));

    return;
  }

  if ((/^\d$/).test(event.key)) {
    digits[index] = event.key;

    if (index !== props.digit_count - 1)
      (otpCont.value.children)[index + 1].focus();

    emit('update:otp', digits.join(''));
  }
};

function handlePaste(e) {
  e.preventDefault();
  const text = e.clipboardData.getData('text');
  if (!new RegExp(`^[0-9]{${props.digit_count}}$`).test(text))
    return;

  const copied_digits = text.split('');
  for (let i = 0; i < props.digit_count; i++)
    digits[i] = copied_digits[i];

  emit('update:otp', digits.join(''));
}
</script>

<template>
  <div ref="otpCont">
    <input
      v-for="(el, ind) in digits"
      :key="el + ind"
      v-model="digits[ind]"
      type="text"
      class="w-14 h-14 text-center text-2xl font-extrabold text-slate-900  border border-slate-300  hover:border-slate-400 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100 mr-2"
      :autofocus="ind === 0"
      :placeholder="0"
      maxlength="1"
      @keydown="handleKeyDown($event, ind)"
      @paste="handlePaste"
    >
  </div>
</template>
