<script setup>
// assets
import RichTextIcon from '~icons/solar/document-text-linear';

const props = defineProps({
  fields_list: {
    type: Array,
    default: () => [],
  },
  is_folder: {
    type: Boolean,
    required: false,
    default: false,
  },
  is_drop: {
    type: Boolean,
    required: false,
    default: false,
  },
  is_dnd: {
    type: Boolean,
    required: false,
    default: false,
  },
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  files_to_upload: {
    type: Array,
    default: () => [],
    required: false,
  },
  is_single_file: {
    type: Boolean,
    required: false,
    default: false,
  },
  attachment_config: {
    type: Object,
    default: () => {
      return { meta: { service: 'vault', id: 'upload' } };
    },
  },
  file_input_config: {
    type: Object,
    default: () => ({}),
    required: false,
  },
});

const emit = defineEmits(['close']);

const $toast = inject('$toast');
const $t = inject('$t');

const form = ref({});
const form$ = ref(null);
const is_complete = ref(false);

const state = reactive({
  is_loading: false,
});

const is_upload_disabled = computed(() => {
  if (!is_complete.value)
    return true;
  if (props.is_single_file)
    return !form.value?.File || form.value.File?.has_unsupported_extension;
  else
    return !form.value?.File?.length || form.value.File.some(file => file?.has_unsupported_extension);
});

function onFormMounted(el$) {
  if (!props.is_single_file)
    form$.value.elements$.File.load(Array.from(props.files_to_upload));

  if (!props.is_drop && !props.is_dnd)
    form$.value.elements$.File.input.click();

  else
    nextTick(() => {
      el$.form$.uppy.on('complete', (result) => {
        is_complete.value = true;
      });
    });
}

function onFormUpdated(event) {
  is_complete.value = false;
  if (event.type === 'upload_completed' || event.type === undefined)
    is_complete.value = true;
}

function cancelUpload() {
  form$.value.uppy.cancelAll();
  emit('close');
}
async function submitHandler(form$) {
  state.is_loading = true;
  try {
    await props.submit(form$);
  }
  catch (e) {
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again later'),
      type: 'error',
      position: 'bottom-right',
    });
  }
  state.is_loading = false;
}
</script>

<template>
  <hawk-modal-container @click.stop>
    <Vueform
      ref="form$"
      v-model="form"
      :display-errors="false" :should_validate_on_mount="false"
      :attachment_config="attachment_config"
      :presets="['disable_error_messages']"
      @mounted="onFormMounted"
      @updated="onFormUpdated"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #icon>
            <RichTextIcon />
          </template>
          <template #title>
            {{ is_folder ? $t("Upload folder") : $t("Upload file") }}
          </template>
          <template #subtitle>
            {{ $t("files-uploaded-to-project") }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content class="!py-0">
          <FileElement
            v-if="is_single_file"
            name="File"
            class="pt-4 mb-8"
            :add-classes="{
              FileElement: {
                button: '!bg-white !border-0 hover:!scale-100 hover:!bg-gray-50 !font-medium !text-sm !text-gray-600 !px-3 !py-2',
              },
            }"
            :presets="['hawk_file_element']"
            :auto="false"
            v-bind="file_input_config"
          >
            <template #upload-trigger>
              <IconHawkPlus class="inline -mt-0.5" />
              <span class="text-gray-600 ml-1">{{ $t("Add file") }}</span>
            </template>
          </FileElement>
          <MultifileElement
            v-else
            name="File"
            class="pt-4 mb-8"
            :auto="false"
            :add-class="{
              list: 'overflow-y-auto max-h-[50vh] scrollbar',
            }"
            :presets="is_folder ? ['upload_directory'] : []"
            v-bind="file_input_config"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <!-- Footer -->
          <template #right>
            <div class="flex justify-end w-full">
              <HawkButton type="outlined" text="Cancel" class="mr-2" @click="cancelUpload">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement
                :disabled="is_upload_disabled"
                :loading="state.is_loading"
                :button-label="$t('Upload')"
                size="sm"
                name="submit"
                button-class="vf-btn-primary"
                @click="submitHandler(form$)"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
