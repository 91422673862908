<script setup>

</script>

<template>
  <div class="min-h-full">
    <div v-if="$route.name !== 'form-template-detail-workflow-builder'" class="border-t  border-gray-200 mt-4" />
    <router-view />
  </div>
</template>
