<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute, useRouter } from 'vue-router';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store';
import NewFormTemplateModal from '~/forms/components/new-form-template/new-form-template-modal.vue';
import NewFormTemplateImportModal from '~/forms/components/new-form-template/new-form-template-import.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import { useCommonStore } from '~/common/stores/common.store';
import PlusIcon from '~icons/hawk/plus-gray';
import ImportIcon from '~icons/hawk/import';
import { TEMPLATE_PERMISSIONS } from '~/forms/constants';

const common_store = useCommonStore();
const $track_event = inject('$track_event');

const form_workflow_store = useFormWorkflowStore();
const form_template_store = useFormTemplatesStore();
const router = useRouter();
const route = useRoute();
const auth_store = useAuthStore();

const has_create_template_permission = auth_store.check_permission(TEMPLATE_PERMISSIONS.V2_CREATE_FORM_TEMPLATES, route.params.asset_id);
const has_import_template_permission = auth_store.check_permission(TEMPLATE_PERMISSIONS.V2_IMPORT_FORM_TEMPLATES, route.params.asset_id);

function getTargetElement() {
  if (route.params.asset_id)
    return common_store.active_asset.element;
  return auth_store.current_organization.element;
}

const { open: openFormModal, close: closeFormModal } = useModal({
  component: NewFormTemplateModal,

  attrs: {
    show_type: false,
    show_subtitle: false,
    onClose() {
      closeFormModal();
    },
    async on_submit(data, type) {
      const payload = data;
      payload.template.target_element = getTargetElement();
      payload.is_template = true;
      const form = await form_template_store?.create_form_template({
        body: payload,
        toast: type === 'template',
      });
      if (type === 'template') {
        router.push({ name: 'form-template-detail-form-builder', params: { template_uid: form.uid, asset_id: route.params.asset_id } });
      }
      else {
        await form_workflow_store.createFormWorkflow(form.uid);
        router.push({ name: 'form-template-detail-workflow-builder', params: { template_uid: form.uid, asset_id: route.params.asset_id } });
      }
      const template = form_template_store.form_templates_map[form.uid];
      const properties = { type: type === 'template' ? 'General' : 'Workflow' };
      properties.associated_with = `${template?.target_element?.type?.toUpperCase()}`;
      $track_event('Created', properties);
      closeFormModal();
    },
  },
});

const { open: openImportModal, close: closeImportModal } = useModal({
  component: NewFormTemplateImportModal,
  attrs: {
    onClose() {
      closeImportModal();
    },
    async on_submit(payload) {
      await form_template_store.import_template(payload);
      closeImportModal();
    },
  },
});

const template_options = [
  { uid: 0, label: 'New template', icon: PlusIcon, on_click: openFormModal },
  { uid: 0, label: 'Import template', on_click: openImportModal, icon: ImportIcon, icon_class: 'rotate-90 !w-[16px] !h-[16px]' },
];
</script>

<template>
  <hawk-menu v-if="route.params.asset_id && has_import_template_permission && has_create_template_permission" :items="template_options" additional_trigger_classes="!ring-0 !border-0">
    <template #trigger>
      <hawkButton color="primary">
        <div class="flex justify-between items-center text-white-700 font-semibold cursor-pointer">
          {{ $t('New template') }}
          <IconHawkChevronDown class="w-[16px] h-[16px] ml-2" />
        </div>
      </hawkButton>
    </template>
    <template #item="data">
      <component :is="data.item.icon" class="mr-2 w-[14px] h-[14px]" :class="data.item.icon_class" />
      <span>
        {{ data.item.label }}
      </span>
    </template>
  </hawk-menu>
  <div v-else-if="has_create_template_permission" class="flex cursor-pointer items-center rounded  hover:bg-gray-50">
    <hawkButton color="primary" @click="openFormModal()">
      <icon-hawk-plus class="text-white" /> <span>
        {{ $t('New template') }}
      </span>
    </hawkButton>
  </div>
</template>
