<script setup>
const props = defineProps({
  options: {
    type: Object,
  },
});
const emits = defineEmits(['change']);
const state = ref(props.options.initial_state);

function onChange() {
  if (state.value === 'unchecked')
    state.value = 'intermediate';
  else if (state.value === 'intermediate')
    state.value = 'checked';
  else if (state.value === 'checked')
    state.value = 'unchecked';
  emits('change', state.value);
}
</script>

<template>
  <CheckboxElement
    name="three_state_checkbox"
    v-bind="options"
    :add-class="{ wrapper: '!flex !items-center', input: options.invisible_checkbox ? 'invisible' : '' }"
    :override-class="
      state === 'intermediate' ? { input_default: '!bg-primary-50 !border-primary-500 form-bg-icon-intermediate' }
      : (state === 'checked' ? { input_default: '!bg-primary-50 after:!bg-primary-500 form-bg-icon-check !border-primary-500' }
        : { input_default: 'focus:!border-primary-500 !border-gray-300' })"
    :remove-class="
      state === 'intermediate' ? { input: 'checked:form-bg-icon-check', input_sm: 'form-mt-checkbox-sm' }
      : state === 'unchecked' ? { input: 'checked:form-bg-icon-check', input_sm: 'form-mt-checkbox-sm' }
        : { input: '', input_sm: 'form-mt-checkbox-sm' }
    "
    @input="onChange"
  >
    <template v-if="$slots.default" #default>
      <slot name="default" />
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="$slots.info" #info>
      <slot name="info" />
    </template>
    <template v-if="$slots.description" #description>
      <slot name="description" />
    </template>
    <template v-if="$slots.before" #before>
      <slot name="before" />
    </template>
    <template v-if="$slots.between" #between>
      <slot name="between" />
    </template>
    <template v-if="$slots.after" #after>
      <slot name="after" />
    </template>
  </CheckboxElement>
</template>

<style lang="scss" scoped>
:deep(.form-bg-icon-intermediate){
  border: 1px solid #2E90FA !important;
  mask-image: none;
  &::after{
    position: absolute;
    margin-top: -6.5px !important;
    margin-left: 1.7px !important;
    content: "-";
    font-size: 20px;
    color: #2E90FA !important;
  }
}
</style>
