import Inventory from '~/inventory/pages/inventory.vue';
import InventoryItems from '~/inventory/pages/inventory-items.vue';
import InventoryItemForm from '~/inventory/pages/inventory-item-form.vue';
import InventoryWarehouses from '~/inventory/pages/inventory-warehouses.vue';
import InventoryWarehouseDetails from '~/inventory/pages/inventory-warehouse-details.vue';
import InventoryTransactions from '~/inventory/pages/inventory-transactions.vue';
import InventoryTransactionForm from '~/inventory/pages/inventory-transaction-form.vue';
import InventoryDashboard from '~/inventory/pages/inventory-dashboard.vue';
import InventoryReports from '~/inventory/pages/inventory-reports.vue';
import InventoryReportForm from '~/inventory/pages/inventory-report-form.vue';
import InventoryBOM from '~/inventory/pages/inventory-bom.vue';
import InventorySettings from '~/inventory/pages/inventory-settings.vue';
import InventoryTransactionTypeForm from '~/inventory/pages/inventory-transaction-type-form.vue';
import PermissionDenied from '~/common/pages/permission-denied.vue';

const routes = [
  {
    path: '/:asset_id?/inventory/',
    name: 'inventory',
    meta: {
    },
    redirect: { name: 'inventory-items' },
    component: Inventory,
    children: [
      {
        path: 'permission-denied',
        name: 'inventory-permission-denied',
        component: PermissionDenied,
      },
      {
        path: 'dashboard',
        component: InventoryDashboard,
        name: 'inventory-dashboard',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: 'items',
        component: InventoryItems,
        name: 'inventory-items',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: 'items/form/:item_id?',
        component: InventoryItemForm,
        name: 'inventory-item-form',
        meta: {
          module: 'inventory',
          parent: 'inventory-items',
        },
      },
      {
        path: 'warehouses',
        component: InventoryWarehouses,
        name: 'inventory-warehouses',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: ':warehouse_id?/warehouse-details',
        component: InventoryWarehouseDetails,
        name: 'inventory-warehouse-details',
        meta: {
          module: 'inventory',
          parent: 'inventory-warehouses',
        },
      },
      {
        path: 'transactions',
        component: InventoryTransactions,
        name: 'inventory-transactions',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: 'transactions/:workflow_id?/transaction-form/:transaction_id?',
        component: InventoryTransactionForm,
        name: 'inventory-transaction-form',
        meta: {
          module: 'inventory',
          parent: 'inventory-transactions',
        },
      },
      {
        path: 'reports',
        component: InventoryReports,
        name: 'inventory-reports',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: 'report-forms',
        component: InventoryReportForm,
        name: 'inventory-report-form',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: 'bom',
        component: InventoryBOM,
        name: 'inventory-bom',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: 'settings',
        component: InventorySettings,
        name: 'inventory-settings',
        meta: {
          module: 'inventory',
        },
      },
      {
        path: 'settings/transaction-type/:transaction_type_uid?/',
        component: InventoryTransactionTypeForm,
        name: 'inventory-transaction-type',
        meta: {
          module: 'inventory',
          parent: 'inventory-settings',
        },
      },
    ],
  },

];
export default routes;
