<script setup>
import { isEqual } from 'lodash-es';
import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const $services = inject('$services');

const graph_data = ref(null);
const loading = ref(false);

const widget_data = computed(() => props.data?.data);

const chart_config = computed(() => {
  return props.data?.data?.chart_config;
});
const chart_type = computed(() => {
  const TYPE_TO_CHART_MAP = {
    vertical_graph: chart_config.value?.chart_display_mode === 'Unstacked' ? 'mscolumn2d' : 'stackedcolumn2d',
    horizontal_bar: chart_config.value?.chart_display_mode === 'Unstacked' ? 'msbar2d' : 'stackedbar2d',
    donut: 'doughnut2d',
  };
  if (!widget_data.value)
    return '';
  return TYPE_TO_CHART_MAP[widget_data.value.type] || '';
});

const graph_config = computed(() => ({
  chart_name: widget_data.value.name || '',
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value || '',
  dataSource: {
    chart: {
      xAxisName: widget_data.value?.field?.label || '',
      yAxisName: widget_data.value?.value?.label || '',

      subCaption: widget_data.value?.chart_config?.description
        || widget_data.value.subCaption || '',

    },
    dashboard_index: props.data.i,
    chart_type: widget_data.value.type,
    ...graph_data.value,
  },
}));

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  try {
    const { data } = await $services.dashboard.get_reports({ body: widget_data.value });
    graph_data.value = data;
    loading.value = false;
  }
  catch {
    loading.value = false;
  }
}

watch(widget_data, (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    setTimeout(() => {
      getReports();
    }, 250);
  }
}, { immediate: true, deep: true });

watch(() => props.activeSelectedRange, (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    getReports();
  }
});
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />

    <GraphComponent v-else-if="graph_config?.renderAt" :id="props.id" :configuration="graph_config" :chart_config="chart_config" :group_key="widget_data?.group?.uid" />
  </div>
</template>
