<script setup>
import { useFocus } from '@vueuse/core';
import { debounce, groupBy } from 'lodash-es';
import { onBeforeUnmount } from 'vue';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import FormStatus from '~/forms/atoms/form-status.vue';
import InstanceDetail from '~/system-model/components/sm-global-search/instance-detail.vue';

const emit = defineEmits(['close']);
const { $services, $t, auth_store, common_store, route, router } = useCommonImports();

const state = reactive({
  search: '',
  data: [],
  active_item: { uid: 'all', label: 'All results', count: 0 },
  is_loading: false,
});
const searchInput = ref();
useFocus(searchInput, { initialValue: true });

const global_search = debounce(async () => {
  try {
    state.is_loading = true;
    state.data = [];
    state.active_item = { uid: 'all', label: 'All results', count: 0 };
    const response = await $services.search.getAll({
      query: {
        q: state.search,
        limit: 5,
        asset: route.params.asset_id,
      },
    });
    state.data = response.data || [];
  }
  catch (error) {
    logger.log('error', error);
  }
  finally {
    state.is_loading = false;
  }
}, 500);

const grouped_data = computed(() => groupBy(state.data, 'type'));

const tabs_items = computed(() => {
  const data_map = Object.keys(grouped_data.value).map((i) => {
    return { uid: i, label: i, count: grouped_data.value?.[i]?.length };
  });
  return [
    { uid: 'all', label: 'All results', count: state.data?.length },
    ...data_map,
  ];
});

const search_data = computed(() => {
  if (state.active_item?.uid === 'all')
    return grouped_data.value;
  else return { [state.active_item.uid]: grouped_data.value[state.active_item.uid] };
});

function getInstanceAlias(item) {
  const str = [item?.name_1, item?.name_2, item?.name_3, item?.name_4];
  const temp = str.filter(x => x !== null && x !== 'null' && x !== '');
  return temp.join(', ');
}

const instance_detail_modal = useModal({ component: InstanceDetail });

function openDetails(item) {
  switch (item.type) {
    case 'asset':
      router.push({ name: 'asset-dashboard', params: { asset_id: item.uid } });
      break;

    case 'task':
      router.replace({
        ...route,
        query: {
          ...route.query,
          task: btoa(JSON.stringify({ id: item.uid, track_event_view: 'GlobalSearch' })),
        },
      });
      break;

    case 'team':
      router.push({
        name: 'account-settings-team-details',
        params: { id: item.uid, asset_id: item.asset },
      });
      break;

    case 'role':
      router.push({
        name: 'account-settings-role-details',
        params: { id: item.uid, asset_id: item.asset },
      });
      break;

    case 'user':
      router.push({
        name: 'account-settings-user-details',
        params: { user_id: item.uid, asset_id: item.asset },
      });
      break;

    case 'file':
      router.push({
        name: 'files-documents',
        params: { asset_id: item.asset },
        query: { folder: (item.parent || item.asset || 'null'), document: item.uid },
      });
      break;

    case 'folder':
      router.push({
        name: 'files-documents',
        params: { asset_id: item.asset },
        query: { folder: item.uid },
      });
      break;

    case 'Form':
      router.replace({
        ...route,
        query: {
          ...route.query,
          form: btoa(JSON.stringify({ form_uid: item.uid, track_event_view: 'GlobalSearch' })),
        },
      });
      break;

    case 'instance':
      router.push({
        name: 'sm-template-details',
        params: {
          asset_id: item.asset,
          template_id: item.template_uid,
        },
        query: {
          component: item.component_uid,
          instance: item.uid,
        },
      });
      break;

    case 'comment':
      router.replace({
        ...route,
        query: {
          ...route.query,
          task: btoa(JSON.stringify({ id: item.task_uid, comment_uid: item.uid })),
        },
      });
      break;

    default:
      break;
  }
  if (!(['task', 'Form'].includes(item.type)))
    emit('close');
}

onBeforeUnmount(() => {
  instance_detail_modal?.destroy();
});

const color_status = {
  submitted: '#0eac53',
  open: '#f79c4b',
  drafts: '#f8c900',
};
</script>

<template>
  <HawkModalContainer class="search-popup">
    <div class="col-span-12">
      <div class="flex items-center mx-4 h-14">
        <IconHawkSearchMd class="w-5 h-5 mr-2 text-gray-600" aria-hidden="true" />
        <input
          ref="searchInput"
          v-model="state.search"
          type="text"
          class="w-full text-base font-light placeholder-gray-500"
          :placeholder="`${$t('Search')}...`"
          @keyup="state.search.length > 2 ? global_search() : null"
        >
      </div>
      <hr>
      <div class="max-h-[calc(100vh-64px)] h-[400px] scrollbar">
        <HawkLoader v-if="state.is_loading" />
        <div v-else-if="!Object.keys(search_data).length" class="mx-auto mt-12 text-sm text-center text-gray-400">
          <div v-if="state.search.length > 2" class="break-all">
            {{ $t("No results found for") }} '{{ state.search }}'
          </div>
          <div v-else>
            {{ $t('Your search results will appear here') }}...
          </div>
        </div>
        <div v-else>
          <div class="sticky top-0 px-4 pt-4 bg-white">
            <HawkTabs
              :tabs="tabs_items"
              :active_item="state.active_item.uid"
              :space="4"
              @tab-click="state.active_item = $event"
            />
          </div>
          <!-- h-[450px] scrollbar -->
          <div class="">
            <div v-for="(group, i) in search_data" :key="i" class="pb-4 my-4 text-sm">
              <div class="mx-4 mb-1 text-xs text-gray-600">
                <div v-if="group[0]?.type === 'asset'">
                  {{ $t('Assets') }}
                </div>
                <div v-else-if="group[0]?.type === 'instance'">
                  {{ $t('Components') }}
                </div>
                <div v-else class="capitalize">
                  {{ group[0]?.type }}
                </div>
              </div>
              <div
                v-for="item in group"
                :key="item.uid"
                class="py-1 border-b cursor-pointer"
                @click="openDetails(item)"
              >
                <div
                  class="hover:bg-gray-50 flex items-center justify-between min-h-[48px] px-4"
                >
                  <div class="flex items-center">
                    <div v-if="group[0]?.type === 'file'" class="w-8 h-8 mr-3 rounded-lg">
                      <img
                        v-if="item.thumbnail && item.thumbnail.thumbnailSmall"
                        :src="item.thumbnail.thumbnailSmall"
                        class="flex-shrink-0 w-8 h-8"
                        alt="file thumbnail"
                      >
                      <div v-else class="flex-shrink-0 w-8 h-8 bg-gray-100 rounded-lg" />
                    </div>
                    <div
                      v-else-if="group[0]?.type === 'folder'"
                      class="grid w-8 h-8 mr-2 rounded-lg place-items-center bg-gray-50"
                    >
                      <IconHawkFolder class="flex-shrink-0 w-4 h-4" />
                    </div>
                    <div>
                      <div
                        v-if="group[0]?.type !== 'asset'"
                        class="flex items-center py-1 text-xs text-gray-500"
                      >
                        <span>
                          {{
                            item && item.asset
                              ? common_store.get_asset(item.asset)?.name
                              : auth_store.current_organization?.name
                          }}
                        </span>
                        <IconHawkChevronRight v-if="item && item?.asset ? common_store.get_asset(item?.asset)?.name : auth_store.current_organization?.name" class="w-4 h-4 mx-1" />
                        <span class="capitalize">{{ group[0]?.type }}</span>
                        <IconHawkChevronRight v-if="item && item.navigation" class="w-4 h-4 mx-1" />
                        <div v-if="item && item?.navigation">
                          {{ item.navigation }}
                        </div>
                        <div v-if="item && item?.breadcrumbs" class="flex items-center">
                          <div v-for="breadcrumb in item.breadcrumbs" :key="breadcrumb.uid" class="flex items-center">
                            <IconHawkChevronRight class="w-4 h-4 mx-1" />
                            {{ breadcrumb?.name }}
                          </div>
                        </div>
                      </div>
                      <div class="roboto-regular-14 has-line-height-20">
                        <HawkText :content="group[0]?.type === 'instance' ? item.name : item.title" :length="50" />
                      </div>
                      <div v-if="group[0]?.type === 'instance'" class="mt-5 text-xs text-gray-700">
                        {{ getInstanceAlias(item) }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div v-if="group[0]?.type === 'task'" class="p-2 text-xs rounded-lg is-pointer-events-none">
                      <TaskStatus :status="item?.status" />
                    </div>
                    <div v-else-if="group[0]?.type === 'Form'">
                      <FormStatus
                        :form="{
                          status:
                            { name: item.status, color: item?.current_step?.color ? item.current_step.color : color_status[item.status] },
                        }"
                      />
                    </div>

                    <HawkBadge v-else-if="group[0]?.type === 'instance'">
                      {{ item?.component_name }}
                    </HawkBadge>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </HawkModalContainer>
</template>

<style>
.search-popup .vfm__content {
  @apply min-w-[14rem] sm:!min-w-[40rem] max-w-xl border-0;
}
</style>
