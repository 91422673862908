<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
  load_more: {
    type: Function,
    default: () => {},
  },
  container_class: {
    type: String,
    default: 'mb-2 max-h-[250px]',
  },
  id: {
    default: 'infiniteList',
  },
});

const emit = defineEmits(['pageNumber', 'listInstance']);
const page_number = ref(0);
const loading = ref(false);

onMounted(() => {
  const list = document.getElementById(props.id);
  list?.addEventListener('scroll', handleScroll);
});

function handleScroll(e) {
  loadMore(e.target);
}

async function loadMore(element) {
  if (element && element.clientHeight + element.scrollTop >= element.scrollHeight - 50) {
    if (loading.value)
      return;
    loading.value = true;
    emit('pageNumber', ++page_number.value);
    await props.load_more();
    loading.value = false;
  }
}

onUnmounted(() => {
  const list = document.getElementById(props.id);
  list?.removeEventListener('scroll', handleScroll);
});
function scrollToTop() {
  document.querySelector(`#${props.id}`)?.firstChild?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
}
defineExpose({
  scrollToTop,
});
</script>

<template>
  <div :id="id" class="infinite-list scrollbar" :class="container_class">
    <div id="first_element" />
    <div v-for="(item, index) in list" :key="item.label">
      <div class="infinite-list__item">
        <slot name="item" :item="item" :index="index">
          <p class="p-2 my-2 border border-green-500 ">
            {{ item.label }}
          </p>
        </slot>
      </div>
    </div>
    <slot name="loader">
      <HawkLoader v-if="loading" class="!m-2 !p-0" />
    </slot>
  </div>
</template>
