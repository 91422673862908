<script setup>
// --------------------------------- Imports -------------------------------- //
import { find } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //

const view_items = computed(() => [
  {
    uid: 'list',
    route_name: 'form-list-view',
    leftSlot: IconHawkTableTwo,
    tooltip_text: $t('List view'),
  },
  {
    uid: 'calendar',
    route_name: 'form-calendar-view',
    leftSlot: IconHawkCalendar,
    tooltip_text: $t('Calendar view'),
  },
]);

// -------------------------------- Functions ------------------------------- //
function navigateToFormsInstancesListPage({ template }) {
  router.push({ name: 'form-instances', params: { template_uid: template, asset_id: route.params.asset_id } });
}

function changeLayout(event) {
  switch (event.uid) {
    case 'list':
      router.push({ name: 'form-list-view', params: { ...route.params } });
      break;
    case 'calendar':
      router.push({ name: 'form-calendar-view', params: { ...route.params } });
      break;
  }
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <div class="flex min-h-full flex-col justify-center">
    <HawkPageHeader :title="$t('Forms')" class="-mb-4 -mx-4">
      <template #left>
        <HawkButtonGroup
          :items="view_items"
          icon
          size="md"
          :active_item="find(view_items, function(item) { return item.route_name === route.name; })?.uid"
          @select="changeLayout($event)"
        />
      </template>
      <template #right>
        <!-- Create new Forms CTA -->
        <new-form-button @form-created="navigateToFormsInstancesListPage" />
      </template>
    </HawkPageHeader>
    <div class="mt-4">
      <!-- Forms Router view for calendar and list view -->
      <router-view />
    </div>
  </div>
</template>
