<script setup>
import { useCommonStore } from '~/common/stores/common.store.js';
import { useAuthStore } from '~/auth/stores/auth.store.js';

defineProps({
  uid: {
    type: String,
    default: '',
  },
  organization_class: {
    type: String,
    default: 'text-gray-600',
    required: false,
  },
});
const common_store = useCommonStore();
const auth_store = useAuthStore();

onBeforeMount(() => {
  common_store.set_organization_list();
});
</script>

<template>
  <div class="text-sm" :class="organization_class">
    {{ common_store.get_organization(auth_store.current_organization.uid)?.name || '-' }}
  </div>
</template>
