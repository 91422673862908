<script setup>
import { computed } from 'vue';
import { cloneDeep } from 'lodash-es';

const props = defineProps({
  checklists: {
    type: Array,
  },
});

function tree_to_flat(data) {
  const _data = cloneDeep(data);
  function flatten(xs) {
    return xs.reduce((acc, x) => {
      acc = acc.concat(x);
      if (x.items || x.children) {
        acc = acc.concat(flatten(x.items || x.children));
        x.items = [];
        x.children = [];
      }
      return acc;
    }, []);
  }

  return flatten(_data);
}
const checklist_stats = ref(null);
function get_checklist_stats(data = []) {
  const stats = {};
  const flat_list = tree_to_flat(data);

  stats.total = flat_list.length - data.length;

  const with_status = flat_list.filter(item => item.status === 'resolved');
  const onHold_status = flat_list.filter(item => item.status === 'onHold');
  const rejected_status = flat_list.filter(item => item.status === 'rejected');

  stats.resolved = with_status.length;
  stats.onHold = onHold_status.length;
  stats.rejected = rejected_status.length;

  checklist_stats.value = stats;
}

const progress = computed(() => {
  if (checklist_stats.value)
    return checklist_stats.value.total
      ? Math.ceil(
        (((checklist_stats.value.resolved || 0)
                  + (checklist_stats.value.onHold || 0)
                  + (checklist_stats.value.rejected || 0))
                  / checklist_stats.value.total)
                  * 100,
      )
      : 0;

  else
    return 0;
});

watch(() => props.checklists, (val) => {
  get_checklist_stats(val);
}, { immediate: true });

onBeforeUnmount(() => {
  checklist_stats.value = null;
});
</script>

<template>
  <div v-if="checklist_stats " class="mt-1">
    <div class="flex items-center" style="width: 25rem;">
      <div class="checklist-progress flex">
        <div
          v-tippy="`${checklist_stats.rejected}/${checklist_stats.total} No`"
          class="checklist-progress-rejected"
          :style="{
            width:
              `${(checklist_stats.rejected / checklist_stats.total) * 100
              }%`,
          }"
        />
        <div
          v-tippy="`${checklist_stats.onHold}/${checklist_stats.total} NA`"
          class="checklist-progress-onHold"
          :style="{
            width:
              `${(checklist_stats.onHold / checklist_stats.total) * 100
              }%`,
          }"
          data-tooltip="Tooltip Text"
        />
        <div
          v-tippy="`${checklist_stats.resolved}/${checklist_stats.total} Yes`"
          class="checklist-progress-resolved"
          :style="{
            width:
              `${(checklist_stats.resolved / checklist_stats.total) * 100
              }%`,
          }"
        />
      </div>
      <span class="has-secondary-text ">{{ `${progress}%` }} </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
 .checklist {
      &-progress {
        border-radius:50px;
        height: 5px;
        width: 24rem;
        background-color: rgba(#e2e2ea, 0.3);
        margin-right: 0.7rem;
        overflow: hidden;

        &-rejected {
          background-color: #fa5959;
          height: 5px;
          border-radius:50px;
          &:after {
            content: "";
            background-color: #fa5959;
            height: 5px;
            display: block;
          }
        }
        &-onHold {
          background-color: #5b607e;
          height: 5px;
          border-radius:50px;
          &:after {
            content: "";
            background-color: #5b607e;
            height: 5px;
            display: block;
          }
        }
        &-resolved {
          background-color: #5b9ef3;
          height: 5px;
          border-radius:50px;
          &:after {
            content: "";
            background-color: #5b9ef3;
            height: 5px;
            display: block;
          }
        }
      }
    }
</style>
