<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  item: { type: Object, default: () => {} },
  orderedQty: { type: Number, default: 0 },
});

const emit = defineEmits(['close', 'update']);
const { $services, $t, route } = useCommonImports();
const inventory_store = useInventoryStore();

const form$ = ref(null);
const state = reactive({
  form: {},
  is_loading: false,
});

function onFormMounted(el$) {
  el$.update({ ordered_qty: props.orderedQty || 0 });
}

function preventKeydown(e) {
  if (['+', '-', '.', ',', '=', 'e'].includes(e.key)) {
    e.preventDefault();
  }
}

async function save() {
  try {
    state.is_loading = true;
    const data = await $services.inventory_items.update_item_ordered_qty({
      asset_id: route.params.asset_id,
      body: [{
        uid: props.item.uid,
        ordered_qty: state.form.ordered_qty,
      }],
    });
    if (data.status === 200) {
      emit('update');
    }
  }
  catch (error) {
    logger.log('🚀 ~ save ~ error:', error);
  }
  finally {
    state.is_loading = false;
  }
}
</script>

<template>
  <HawkModalContainer :width="400">
    <Vueform
      ref="form$"
      v-model="state.form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :format-load="(data) => data"
      :endpoint="save"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ $t('Edit purchase order') }}
          </template>
          <template #subtitle>
            #{{ item.number }}
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <TextElement
            label="Purchase order quantity"
            name="ordered_qty"
            :placeholder="$t('Enter purchase order quantity')"
            input-type="number"
            :rules="['numeric', 'min:0', 'required', 'max:999999999']"
            :messages="{
              min: $t('Input cannot be negative'),
              max: $t('Input is restricted to a maximum of 9 digits.'),
            }"
            @keydown="e => preventKeydown(e)"
          >
            <template #addon-after>
              {{ inventory_store.uom_map?.[item?.uom]?.symbol }}
            </template>
          </TextElement>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end items-center gap-3">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
