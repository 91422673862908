<script setup>
import SecondaryPageHeader from '~/common/components/organisms/hawk-page-secondary-header.vue';
import SearchBar from '~/common/components/molecules/hawk-search-input.vue';

const props = defineProps({
  filters_count: {
    type: Number,
    default: 0,
  },
  search: {
    type: Function,
    required: true,
  },
  search_key: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['open-filters', 'clear-filters']);
</script>

<template>
  <SecondaryPageHeader>
    <template #left>
      <div class="flex items-center">
        <SearchBar
          :model-value="search_key"
          :debounce_time="700"
          @update:modelValue="search"
        />
        <div class="flex items-center ml-4">
          <hawk-button
            class="mr-4"
            type="outlined"
            @click="emit('open-filters')"
          >
            <IconHawkFilterIcon class="h-2.5" />
            {{ $t('filters') }}
          </hawk-button>
          <p
            v-if="filters_count"
            class="flex items-center rounded-md p-2 text-sm font-semibold text-primary-700 bg-primary-50"
          >
            {{ `${filters_count} ${$t('Applied')}` }}
            <IconHawkX class="cursor-pointer ml-2 " @click="emit('clear-filters')" />
          </p>
        </div>
      </div>
    </template>
    <template #right>
      <slot name="right" />
    </template>
  </SecondaryPageHeader>
</template>
