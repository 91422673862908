<script setup>
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';

// composables
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

// stores
import { useDocumentStore } from '~/dms/store/document.store.js';
import { useCommonStore } from '~/common/stores/common.store';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';

// components
import DocumentWidget from '~/dms/components/documents/widgets/document-widget.vue';
import HawkAttach from '~/common/components/organisms/hawk-attach/hawk-attach.vue';

const props = defineProps({
  active_task: {
    type: Object,
    default: () => {},
  },
  refetch_activity: {
    type: Function,
    default: null,
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
});

const $t = inject('$t');

const route = useRoute();

const document_crud = useDocumentCrud();

const common_store = useCommonStore();
const document_store = useDocumentStore();
const pm_store = useProjectManagementStore();

const state = reactive({
  is_open: false,
  form: {},
});
const form$ = ref(null);

const attach_modal = useModal({
  component: HawkAttach,
  attrs: {
    onClose() {
      attach_modal.close();
    },
    is_modal: true,
    type: 'Files',
  },
});

const pm_options = computed(() => {
  return {
    query: {
      resource_uid: props.active_task.uid,
      resource_type: 'PM_ACTIVITY',
      is_root: false,
    },
    is_pm: true,
    visible_columns: ['name', 'context_menu', 'updated_at'],
    module: 'project_management',
    create_payload: {
      target_element: common_store.assets_map[route.params.asset_id]?.element,
    },
    is_drag_and_drop: true,
  };
});

function getMenuItems(item) {
  return [
    { label: $t('Delete File'), on_click: () => openDeleteModal(item) },
    { label: $t('Detach File'), on_click: () => detachFile(item) },
  ];
}

function openDeleteModal(item) {
  document_crud.itemDelete({
    item,
    update_hierarchy: false,
    callback: async () => {
      await props.refetch_activity?.();
    },
  });
}

async function detachFile(item) {
  try {
    const references = item.references?.filter(reference => reference.resource_uid !== props.active_task?.uid);
    await updateActivityReference([item.uid], 'remove', [{ references, uid: item.uid }]);
  }
  catch (error) {
    logger.error(error.message);
  }
}

async function updateActivityReference(items, type, references = []) {
  try {
    await pm_store.update_activity_reference({
      activity: props.active_task,
      body: {
        [type]: items.map(uid => ({
          resource_id: uid,
          resource_type: 'FILE',
        })),
      },
    });
    if (references?.length)
      await document_store.crud_documents({
        request: {
          body: {
            files: {
              update: references,
            },
          },
        },
      });
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    await props.refetch_activity?.();
  }
}

function getDropdownItems() {
  return [
    {
      label: $t('New file'),
      uid: 'item1',
      icon: IconHawkFileFour,
      on_click: () => {
        openNewFileUploadModal();
      },
    },
    {
      label: $t('Choose existing file'),
      uid: 'item2',
      icon: IconHawkClipboard,
      on_click: () => {
        openAttachmentModal();
      },
    },
  ];
}

function openNewFileUploadModal(flag, files = []) {
  document_crud.uploadHandler({
    flag,
    files,
    options: {
      ...pm_options.value?.create_payload,
      references: [
        {
          resource_uid: props.active_task.uid,
          resource_type: 'PM_ACTIVITY',
        },
      ],
    },
    callback: async (item) => {
      await updateActivityReference(item, 'add');
    },
  });
}

function openAttachmentModal() {
  attach_modal.patchOptions({
    attrs: {
      onClose() {
        attach_modal.close();
      },
      asset_id: route.params.asset_id,
      async on_attach(data) {
        const attachment_ids = data?.map(item => item.uid);
        const references = data?.map((item) => {
          return {
            uid: item.uid,
            references: [...item.references, {
              resource_uid: props.active_task?.uid,
              resource_type: 'PM_ACTIVITY',
            }],
          };
        });
        await updateActivityReference(attachment_ids, 'add', references);
      },
      restricted_items: props.restricted_items,
    },
  });
  attach_modal.open();
}
function fileDroppedHandler(event) {
  event.preventDefault();
  const files = event?.dataTransfer?.files || null;
  openNewFileUploadModal('dnd', files);
}

function openModal(files) {
  openNewFileUploadModal('drop', files);
}

onBeforeUnmount(() => {
  document_store.$reset();
});
</script>

<template>
  <DocumentWidget
    v-if="props.active_task"
    :options="pm_options"
    @fileDropped="fileDroppedHandler"
  >
    <template #files_vue_form="{ options }">
      <Vueform
        v-if="options.active_tab === 'files' && options.can_create_documents && document_store.documents.length"
        ref="form$"
        :display-errors="false"
        class="my-4"
      >
        <MultifileElement
          name="pm_files"
          :drop="true"
          :auto="false"
          :presets="['hawk_file_element']"
          :options="{
            clickable_text: $t('Click to upload'),
            text: $t('or drag and drop'),
            inline: true,
          }"
          :add-class="{
            list: ['hidden'],
          }"
          @change="openModal"
        />
      </Vueform>
    </template>
    <template #dropdown="{ options }">
      <div class="flex flex-row">
        <hawk-menu
          :items="getDropdownItems(options)"
          position="bottom-left"
          additional_dropdown_classes="w-52"
        >
          <template #trigger>
            <hawk-button
              v-click-outside="() => (state.is_open = false)"
              type="default"
              class="flex justify-between text-gray-700 font-semibold"
              @click="state.is_open = !state.is_open"
            >
              {{ $t('Upload') }}
              <IconHawkChevronUp v-if="state.is_open" class="w-5 h-5" />
              <IconHawkChevronDown v-else-if="!state.is_open" class="w-5 h-5" />
            </hawk-button>
          </template>
          <template #item="data">
            <component :is="data.item.icon" class="text-base mr-2 w-4 h-4" />
            <span>
              {{ data.item.label }}
            </span>
          </template>
        </hawk-menu>
      </div>
    </template>
    <template #context_menu="{ menu_item }">
      <HawkMenu
        :items="getMenuItems(menu_item)"
        position="fixed"
      >
        <template #trigger>
          <IconHawkDotsVertical class="h-4 w-4" />
        </template>
      </HawkMenu>
    </template>
  </DocumentWidget>
</template>
