<script setup>
const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
});

const form$ = ref(null);

watch(() => props.value, (val) => {
  form$.value?.load({ checkbox: val });
});
</script>

<template>
  <Vueform ref="form$" class="pointer-events-none">
    <CheckboxElement name="checkbox" :default="value" />
  </Vueform>
</template>
