<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

const props = defineProps({
  liked_user_uids: {
    type: Array,
    required: true,
  },
  liked_by_me: {
    type: Boolean,
    required: true,
  },
  reaction_count: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['liked']);

const popover_pos_target = ref(null);

const state = reactive({
  open: false,
  popover_position_class: 'right-0 top-0',
});

function togglePopover(value) {
  const current_position = popover_pos_target.value.getBoundingClientRect();
  const screen_center = {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  };
  const y_class
    = current_position.y < screen_center.y ? 'top-full' : 'bottom-full';
  state.popover_position_class = `${y_class}`;

  state.open = value;
}
</script>

<template>
  <Popover
    class="relative flex items-center"
    @mouseenter="togglePopover(true)"
    @mouseleave="togglePopover(false)"
    @click="togglePopover(!state.open)"
  >
    <PopoverButton>
      <div
        class="h-7 min-w-[45px] rounded-xl cursor-pointer bg-gray-50 group-hover:bg-gray-100 flex justify-center items-center"
        @click="emit('liked')"
      >
        <icon-hawk-thumbs-up-filled v-if="liked_by_me" class="h-4 w-4 text-warning-400" />
        <icon-hawk-thumbs-up v-else class="h-4 w-4" :class="{ '!visible': reaction_count }" />
        <span v-if="reaction_count" class="ml-1 text-sm w-2">
          {{ reaction_count }}
        </span>
      </div>
    </PopoverButton>
    <span
      ref="popover_pos_target"
      class="absolute top-0 left-0 w-full h-full pointer-events-none"
    />
    <div v-if="state.open">
      <PopoverPanel
        static
        class="absolute shadow-lg mb-1 z-1 w-fit max-h-[300px] pl-1 py-0.75 scrollbar bg-white border rounded-lg flex flex-col left-0"
        :class="[state.popover_position_class]"
      >
        <div v-for="user_uid in liked_user_uids" :key="user_uid" class="w-full flex flex-col p-2">
          <HawkMembers :members="user_uid" type="label" class="w-full" size="tiny" />
        </div>
      </PopoverPanel>
    </div>
  </Popover>
</template>
