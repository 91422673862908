<script setup>
// --------------------------------- Imports -------------------------------- //
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import SmNoResultsIllustration from '~/system-model/atoms/sm-no-results-illustration.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import DefectStatus from '~/therm/components/atoms/defects-status.vue';
// ---------------------------------- Props --------------------------------- //

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //
const system_model_store = useSystemModelStore();
// --------------------- Variables - Pinia - storeToRefs -------------------- //
const { active_instance_details } = storeToRefs(system_model_store);
// ------------------- Variables - Local - consts and lets ------------------ //
const { $services, route, auth_store, router, $t, $toast } = useCommonImports();
// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //
const state = reactive({
  search_query: '',
  defects_list: [],
});
// --------------------------- Computed properties -------------------------- //

const defects_list = computed(() => {
  let filtered = state.search_query.length
    ? state.defects_list.filter(item =>
      getDefectName(item).toLowerCase().includes(state.search_query.toLowerCase()),
    )
    : state.defects_list;
  filtered = orderBy(filtered, filter => getDefectName(filter), ['asc']);
  return filtered;
});

// -------------------------------- Functions ------------------------------- //
async function getData() {
  try {
    state.is_loading = true;
    const { data } = await $services.therm.post({
      attribute: 'features/details',
      body: {
        filter: {
          organization: auth_store.current_organization?.uid,
          asset: route.params.asset_id,
          advanced_filters: {
            reference_name: [active_instance_details.value.name],
          },
        },
        page: {
          offset: 0,
          limit: Number.MAX_SAFE_INTEGER,
        },
      },
    });
    state.defects_list = data;
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}
function getDefectName(defect) {
  let defect_name = defect?.modified_string_number ?? defect?.string_number ?? '';
  defect_name += defect?.type?.id ? ` : ${defect?.type?.description}` : '';
  return defect_name;
}
function navigateToTherm(defect) {
  if (!defect.view.uid) {
    $toast({
      title: $t('Location not found'),
      text: $t('Can not navigate to the location. You don\'t have access, or the location is no longer available'),
      type: 'warning',
      position: 'bottom-right',
    });
    return;
  }
  const metadata = {
    group: defect.group.uid,
    project: defect.project.uid,
    container: defect.view.uid,
    issue: defect.uid,
  };
  router.push({
    name: 'therm',
    params: {
      asset_id: route.params.asset_id,
      id: defect.view.uid,
    },
    query: {
      metadata: btoa(JSON.stringify(metadata)),
    },
  });
}
// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(async () => {
  await getData();
});
</script>

<template>
  <div>
    <div class="flex items-center justify-between my-6">
      <span class="block text-lg font-semibold text-gray-900">{{ $t('Defects List') }}</span>
      <HawkSearchInput
        v-model="state.search_query"
        :placeholder="$t('Search')"
      />
    </div>
    <HawkLoader v-if="state.is_loading" />
    <SmNoResultsIllustration v-if="!state.is_loading && !defects_list.length" title="No #Defects found" />
    <HawkInfiniteList v-else :list="defects_list" container_class="max-h-96 mb-2 px-2">
      <template #item="{ item, index }">
        <div class="flex py-2 px-2 cursor-pointer group hover:bg-gray-50">
          <div class="flex-auto">
            <div class="flex justify-between mb-2" @click="navigateToTherm(item)">
              <HawkText
                class="text-sm font-medium text-gray-900"
                :length="50"
                :content="getDefectName(item)"
              />
              <div class="flex gap-2 flex-shrink-0 items-center">
                <DefectStatus
                  v-if="item.status?.id"
                  class="text-xs font-medium"
                  :status_object="item.status" type="badge"
                />
                <div v-if="item?.group?.scan_date" class="text-xs font-medium">
                  {{ dayjs(item?.group?.scan_date)?.utc()?.format('MMM DD YYYY') }}
                </div>
              </div>
            </div>
            <div class="flex justify-between mb-1">
              <div v-if="item?.type" class="flex items-center gap-1">
                <HawkBadge>
                  {{ item.type.description }}
                </HawkBadge>
              </div>
              <div v-if="item.assignees?.length" class="flex gap-1 text-xs items-center">
                <HawkMembers :members="item.assignees" type="group" size="xs" popover_position="right" />
              </div>
            </div>
          </div>
          <div class="flex items-center invisible group-hover:visible ml-3" />
        </div>
        <hr v-if="index !== defects_list.length - 1" class="my-2">
      </template>
    </HawkInfiniteList>
  </div>
</template>
