<script>
import { ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'SheetElement',
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
}, {
  setup(props, { element }) {
    const defaultClasses = ref({
      container: '',
      sheet: '',
      $sheet: classes => ([
        classes.sheet,
      ]),
    });

    const sheet_data = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = value;
      },
    });
    const is_disabled = computed(() => element.isDisabled.value);
    return {
      defaultClasses,
      sheet_data,
      is_disabled,
      options: props.options,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <hawk-sheet-input v-model="sheet_data" :options="options" :editable="!is_disabled" :class="{ 'pointer-events-none': is_disabled }" />
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
