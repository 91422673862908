<script setup>
import { useModal } from 'vue-final-modal';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { fieldValueMap, formatAttachment, removeNullProperties } from '~/system-model/utils/helper';
import SmAddFieldsForm from '~/system-model/components/forms/sm-add-fields-form.vue';
import SmCustomFieldsInput from '~/system-model/molecules/sm-custom-fields-input.vue';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  instance_detail: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close', 'updateCompleted']);
const current_organization = inject('current_organization');
const system_model_store = useSystemModelStore();
const auth_store = useAuthStore();

const state = reactive({
  is_loading: false,
});

const instance_fields = computed(() => system_model_store?.fields);
const instance_values = computed(() => fieldValueMap(system_model_store.active_instance_details?.fieldvalues));

function mountedForm(el$) {
  if (props.instance_detail)
    el$.update({
      instance_name: props.instance_detail.name,
      component_name: system_model_store.templates_components_map[props.instance_detail?.component]?.name,
      aliases_list: ['name_1', 'name_2', 'name_4', 'name_4'].map(key => props.instance_detail[key]),
    });
}

async function updateInstanceDetails(formData) {
  const { instance_name, aliases_list } = formData;
  const body = {
    name: instance_name,
    uid: props.instance_detail.uid,
    ...aliases_list.filter(alias => alias).reduce((acc, alias, i) => {
      acc[`name_${i + 1}`] = alias;
      return acc;
    }, {}),
  };
  await system_model_store.update_instances([body]);

  system_model_store.set_sidebar_force_render(false);
  await system_model_store.set_active_instance_hierarchy({ instance_id: props.instance_detail.uid });
  system_model_store.set_sidebar_force_render(true);
}

async function saveInstance(form$) {
  try {
    state.is_loading = true;
    const form_data = form$.data;
    const sanitized_data = removeNullProperties(form_data.custom_field);

    if (!props.instance_detail)
      return;

    await updateInstanceDetails(form_data);
    const create_fields_payload = [];
    const update_fields_payload = [];

    for (const [field_uid, field_value] of Object.entries(sanitized_data)) {
      const field_id = instance_values.value[field_uid]?.uid;
      const has_files = (Array.isArray(field_value)) ? field_value.some(file => file instanceof File) : false;
      const payload = {
        uid: field_id,
        value: has_files ? formatAttachment(field_value, { user_id: auth_store?.logged_in_user_details?.user_id }) : field_value,
      };

      if (field_id)
        update_fields_payload.push(payload);

      else
        create_fields_payload.push({
          field: field_uid,
          instance: props.instance_detail.uid,
          organization: current_organization?.uid,
          value: field_value,
        });
    }
    await Promise.all([
      update_fields_payload.length ? system_model_store.update_field_values(update_fields_payload) : Promise.resolve(),
      create_fields_payload.length ? system_model_store.create_field_value(create_fields_payload) : Promise.resolve(),
    ]);
    const response = await system_model_store.set_active_instance_details({ instance_uid: props.instance_detail.uid });
    emit('updateCompleted', response?.instance);
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}
const new_field_modal = useModal({
  component: SmAddFieldsForm,
  attrs: {
    onClose() {
      new_field_modal.close();
    },
  },
});
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :add-classes="{
        ElementLabel: {
          wrapper: ['text-[#344054] font-medium'],
        },
        TextElement: {
          input: ['text-[#475467]'],
        },
        CheckboxgroupCheckbox: {
          text: ['text-[#475467]'],
        },
        StaticElement: {
          content: ['text-[#344054] font-medium'],
        },
      }"
      @mounted="mountedForm"
      @submit="saveInstance"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('Edit') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :class="instance_detail ? 'max-h-96 scrollbar' : null">
          <TextElement
            v-bind="{
              name: 'instance_name',
              placeholder: 'Enter instance name',
              rules: ['required'],
              class: 'mb-6',
              label: $t('Instance name'),
            }"
          />
          <ListElement
            :label="$t('Aliases')"
            name="aliases_list"
            :initial="4"
            class="mb-6"
            :controls="{
              remove: false,
              add: false,
            }"
          >
            <template #default="{ index }">
              <TextElement
                :name="index"
                :placeholder="`Alias ${index + 1}`"
              />
            </template>
          </ListElement>
          <TextElement
            v-bind="{
              disabled: true,
              name: 'component_name',
              placeholder: 'Enter component name',
              label: $t('Component'),
            }"
          />
          <GroupElement
            v-if="instance_detail"
            name="column2"
          >
            <StaticElement name="divider">
              <div class="my-6 border-t border-t-gray-200" />
            </StaticElement>

            <SmCustomFieldsInput
              :key="instance_fields.length"
              :fields="instance_fields"
              :fieldvalues_map="instance_values"
              :options="{ name: 'custom_field' }"
            />

            <!-- Open adding new fields modal -->
            <StaticElement name="static_btn">
              <hawk-button type="text" @click="new_field_modal.open()">
                <IconHawkPlusGray class="h-3 w-3" />{{ $t('New Field') }}
              </hawk-button>
            </StaticElement>
          </GroupElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="state.is_loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style lang="scss" scoped>
:deep(.add_custom_field) {
  button {
    @apply text-[#475467] font-semibold bg-transparent cursor-pointer pl-0;
  }
}
</style>
