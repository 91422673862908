<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['close', 'createdUom']);
const { $t, $toast } = useCommonImports();
const inventory_store = useInventoryStore();

const form$ = ref(null);
const form = ref({
  uom: {
    name: '',
    symbol: '',
  },
});
const is_loading = ref(false);

async function save() {
  if (!form.value.uom.name)
    return;
  try {
    is_loading.value = true;
    const response = await inventory_store.create_uom({ payload: [form.value.uom] });
    if (response) {
      $toast({
        title: $t('Success'),
        text: $t('UOM Added successfully'),
        type: 'success',
      });
      emit('createdUom', response);
      form.value.uom.name = null;
      form.value.uom.symbol = null;
      is_loading.value = false;
      emit('close');
    }
  }
  catch ({ data: error }) {
    const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
    $toast({
      title: title || 'Something went wrong',
      text: message || 'Please try again',
      type: 'error',
    });
    is_loading.value = false;
  }
}
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form.uom"
      size="sm"
      :display-errors="false"
      @submit="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Add measurement unit') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <GroupElement
            name="container"
          >
            <TextElement
              name="name"
              :label="$t('Name')"
              :placeholder="$t('Enter measurement unit name')"
              class="mb-4"
              rules="required"
            />
            <TextElement
              name="symbol"
              :label="$t('Symbol')"
              :placeholder="$t('Enter symbol')"
              class="mb-4"
              rules="required"
            />
          </GroupElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
                :loading="is_loading"
              >
                {{ $t("Save") }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
