<script setup>
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store.js';
import { useChatStore } from '~/common/stores/chat.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { handleAppUpdate } from '~/common/utils/common.utils';

const $t = inject('$t');
const router = useRouter();
const auth_store = useAuthStore();
const project_management_store = useProjectManagementStore();

const {
  is_fullscreen,
  active_view,
  active_schedule,
  is_refresh_required,
  is_pm_loading,
  pm_loading_message,
} = storeToRefs(project_management_store);
const { current_organization } = storeToRefs(auth_store);

const { set_views, set_schedule, set_is_fullscreen } = project_management_store;

watch(is_refresh_required, async (new_val) => {
  if (new_val) {
    is_refresh_required.value = false;
    is_pm_loading.value = true;
    project_management_store.$reset();
    await loadSchedule();
    is_pm_loading.value = false;
  }
});

onMounted(() => {
  const interval = setInterval(async () => {
    if (!current_organization.value)
      return;

    clearInterval(interval);
    is_pm_loading.value = true;
    await initialize();
    await loadSchedule();
    is_pm_loading.value = false;
  }, 500);
});

async function initialize() {
  try {
    const chat_store = useChatStore();
    chat_store.initialize();
    const { gantt } = await import('dhtmlx-gantt');
    window.gantt = gantt;
    document.addEventListener('fullscreenchange', () => set_is_fullscreen(!!document.fullscreenElement));
  }
  catch (error) {
    logger.error(error);
    if (error.message.includes('import') && error.message.includes('module'))
      handleAppUpdate('package: gantt', true);
  }
}

async function loadSchedule() {
  if (!router.currentRoute.value.params?.schedule_uid)
    return;
  try {
    await set_views(router.currentRoute.value.params.schedule_uid, $t);
    await set_schedule(router.currentRoute.value.params.schedule_uid);
  }
  catch (error) {
    logger.error(error);
  }
}
</script>

<template>
  <div
    id="pm-fullscreen-container"
    class="p-4"
    :class="{ 'h-full': is_fullscreen }"
  >
    <div v-if="active_view && active_schedule">
      <PmHeader v-if="!is_fullscreen" />
      <div :class="{ 'h-full': !is_fullscreen }">
        <PmGanttToolbar />
        <PmGantt :class="{ 'h-[95%]': is_fullscreen }" />
      </div>
      <PmActivityDetails />
    </div>
    <div v-if="is_pm_loading" class="absolute top-0 left-0 w-full h-full z-10 flex items-center justify-center">
      <div v-if="pm_loading_message" class="text-gray-900 z-10">
        {{ pm_loading_message }}
      </div>
      <hawk-loader v-else class="z-10" />
      <div class="bg-white absolute top-0 left-0 w-full h-full opacity-70" />
    </div>
    <PmSaveViewNotification />
  </div>
</template>

<style>
#pm-fullscreen-container:-webkit-full-screen,
#pm-fullscreen-container:-moz-full-screen,
#pm-fullscreen-container:-ms-fullscreen,
#pm-fullscreen-container:fullscreen {
  background-color: #fff;
}
</style>
