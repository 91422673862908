<script setup>
import { computed, inject, onMounted, reactive } from 'vue';
import { saveAs } from 'file-saver';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  zip_data: {
    type: Object,
    required: true,
  },
});

const $toast = inject('$toast');
const $t = inject('$t');
const emitter = useEmitter();

const state = reactive({
  completed_files_count: 0,
  toast_active: false,
  total_files_count: 0,
  current_action: '',
  msg: '',
  progress: '',
  local_data: props.zip_data,
  is_completed: false,
  is_cancelled: false,
});

let JSZip;
let JSZipUtils;

const active_action_text = computed(() => {
  if (state.current_action === 'Downloading files: ')
    return `${state.current_action} ${state.completed_files_count}/${state.total_files_count}`;

  else
    return state.current_action;
});

onMounted(async () => {
  const mod = await import('jszip');
  JSZip = mod.default;

  const mode = await import('jszip-utils');
  JSZipUtils = mode.default;

  generateZip();
});

function totalFilesCounts(folder) {
  state.total_files_count += folder?.files?.length || 0;
  if (folder?.children && folder?.children?.length)
    folder.children.forEach((f) => {
      totalFilesCounts(f);
    });
}

function getBinaryContentFromURL(url) {
  return new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, {
      callback(err, data) {
        if (err)
          reject(err);
        else
          resolve(data);
      },
      progress(e) {
        if (e.percent === 100)
          state.completed_files_count++;
      },
    });
  });
}

async function addFilesToZip(folder, zipInstance) {
  if (folder?.files?.length)
    for (const file of folder.files)
      try {
        const blob = await getBinaryContentFromURL(file.url);
        zipInstance.file(file.name ? file.name : file.uid, blob, { binary: true });
      }
      catch (err) {
        logger.error(err);
        continue;
      }

  if (folder?.children?.length)
    for (const child of folder.children) {
      if (state.is_cancelled)
        return;
      await addFilesToZip(
        child,
        zipInstance.folder(child.name),
      );
    }
}

async function generateZip() {
  state.current_action = 'Downloading files: ';
  totalFilesCounts(props.zip_data);

  if (state.total_files_count === 0) {
    $toast({
      title: $t('Empty folder'),
      text: $t('The folder you are trying to download has no files'),
      type: 'warning',
      position: 'bottom-right',
    });
  }
  else {
    state.toast_active = true;
    state.completed_files_count = 0;
    const zip = JSZip ? new JSZip() : null;
    if (zip) {
      await addFilesToZip(props.zip_data, zip);

      if (state.is_cancelled) { // cancel when everything is downloaded
        cancelDownloadToast();
        return;
      }
      state.current_action = $t('Zipping files to download');
      await new Promise(resolve => setTimeout(resolve, 1000));
      zip.generateAsync({ type: 'blob' }, (metadata) => {
        if (state.is_cancelled) {
          cancelDownloadToast();
        }
        else {
          state.msg = `progression : ${metadata.percent.toFixed(2)} %`;
          state.progress = metadata.percent;
        }
      }).then(blob => onSuccessfulDownload(blob), e => onFailedDownload(e));
    }
    else {
      logger.error('JSZip did not load properly...');
      $toast({
        title: $t('Something went wrong'),
        text: $t('Please try again later'),
        type: 'error',
        position: 'bottom-right',
      });

      state.total_files_count = 0;
      state.completed_files_count = 0;
      state.progress = 0;
      state.toast_active = false;
      emitter.emit('download_zip_complete', local_data.value);
    }
  }
}

function cancelDownloadToast() {
  $toast({
    title: $t('Download cancelled'),
    text: $t('Download successfully cancelled'),
    type: 'info',
    position: 'bottom-right',
  });
}

function onSuccessfulDownload(blob) {
  if (state.is_cancelled) {
    cancelDownloadToast();
  }
  else {
    state.is_completed = true;
    state.current_action = 'Zipping files...';
    saveAs(blob, props.zip_data.name || 'Folder');
    state.current_action = 'Files zipped';
    state.msg = '';
    $toast({
      title: $t('Files downloaded'),
      text: `${state.total_files_count} ${$t('files/folders have been zipped and downloaded successfully')}`,
      type: 'success',
      position: 'bottom-right',
    });
    state.total_files_count = 0;
    state.completed_files_count = 0;
    state.toast_active = false;
    state.is_completed = false;
    emitter.emit('download_zip_complete', state.local_data);
  }
}

function onFailedDownload(err) {
  logger.error(err, 'error zip');
  $toast({
    title: $t('Something went wrong'),
    text: $t('Please try again later'),
    type: 'error',
    position: 'bottom-right',
  });
  emitter.emit('download_zip_complete');
}

function onCancel() {
  state.is_cancelled = true;
  state.toast_active = false;
}
</script>

<template>
  <div v-if="state.toast_active" class="download-zip pl-3 pt-3 pb-4 pr-4 flex items-center justify-between w-full bg-gray-800">
    <div class="flex items-center">
      <hawk-progress-ring v-if="!state.is_completed" :radius="18" :progress="state.progress" :stroke="4" class="mr-1" />
      <div v-else class="bg-green-500 rounded-full mr-2 p-1 border border-white border-2 w-[25px] h-[25px] flex justify-center items-center">
        <IconHawkCheck class="text-sm text-white" />
      </div>
      <div>
        <div class="flex items-start w-full justify-between">
          <h4 class="text-sm font-medium">
            {{ active_action_text }}
          </h4>
        </div>
        <p v-if="state.is_completed" class="text-sm max-w-[400px] font-light">
          {{ $t('Check your downloads folder') }}
        </p>
        <span class="text-sm">
          {{ state.msg }}
        </span>
      </div>
    </div>
    <div>
      <hawk-button v-if="!state.is_completed" type="plain" class="text-white hover:text-blue-500 hover:bg-white" @click="onCancel">
        Cancel
      </hawk-button>
      <IconHawkX v-else @click="onCancel" />
    </div>
  </div>
</template>
