<script setup>
import { storeToRefs } from 'pinia';
import { isEqual } from 'lodash-es';
import { useDashboardCustomStore } from '~/dashboard/store/dashboard-custom.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardCustomRichText from '~/dashboard/components/custom-rich-text/dashboard-custom-rich-text.vue';
import DashboardCustomCsvData from '~/dashboard/components/custom-handson/dashboard-custom-csv-data.vue';
import DashboardCustomMapImport from '~/dashboard/components/custom-map/dashboard-custom-map-import.vue';

const dashboard_custom_store = useDashboardCustomStore();
const dashboard_store = useDashboardStore();

const {
  custom_schema,
  custom_configuration,
  custom_widget_type,
} = storeToRefs(dashboard_custom_store);

const {
  update_custom_configuration,
  set_data,
} = dashboard_custom_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

function updateCustomConfiguration(data, key = null) {
  if (!key) {
    update_custom_configuration({
      ...custom_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...custom_configuration.value,
    };
    copy[key] = data;
    update_custom_configuration(copy);
  }

  validateForm();
}

const form$ = ref(null);

async function validateForm() {
  // sometimes changes take longer
  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();
  let is_valid = !form$.value.hasErrors;
  if ([
    'vertical_graph',
    'horizontal_bar',
    'donut',
    'line', 'area',
    'custom_table',
  ].includes(custom_widget_type.value) && !custom_configuration.value?.csv_data?.data?.length)
    is_valid = false;

  if (custom_widget_type.value === 'map_view' && !custom_configuration.value.map_boundaries)
    is_valid = false;

  set_form_valid(is_valid);
  if (is_valid)
    set_widget_configuration({
      ...custom_configuration.value,
    });
}

watch(widget_asset, (new_val, old_val) => {
  if (!isEqual(new_val, old_val))
    updateCustomConfiguration(widget_asset.value, 'asset_id');
});

onMounted(() => set_data());

onBeforeUnmount(() => {
  dashboard_custom_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <Vueform
    ref="form$"
    size="sm"
    :schema="custom_schema"
    :presets="['supress_errors']"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    :should_validate_on_mount="false"
    :display-errors="false"
    class="mb-4"
    @change="updateCustomConfiguration($event)"
  />
  <DashboardCustomMapImport
    v-if="custom_widget_type === 'map_view'"
    @update="updateCustomConfiguration($event, 'map_boundaries')"
  />
  <DashboardCustomCsvData
    v-if="[
      'vertical_graph',
      'horizontal_bar',
      'donut',
      'line',
      'area',
      'custom_table',
    ].includes(custom_widget_type)"
    @update="updateCustomConfiguration($event, 'csv_data')"
  />
  <DashboardCustomRichText
    v-if="custom_widget_type === 'rich_text'"
    @input="updateCustomConfiguration($event, 'rich_text')"
  />
</template>
