<script setup>
import { useTerraStore } from '~/terra/store/terra.store';

const emits = defineEmits(['save']);
const terra_store = useTerraStore();
const form = ref(null);

const get_available_property_keys = computed(() => {
  const available_keys = Object.keys(terra_store.extra_properties_group_by(terra_store.features_on_map));
  return [{ label: 'Name', value: 'name' }, ...available_keys.map(value => ({ label: value, value }))];
});

function onSave() {
  terra_store.settings.label_property_key = form.value.property;
  if (terra_store?.settings?.display_labels)
    emits('updateLabels');
  else
    emits('close');
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform v-model="form" sync size="sm" :display-errors="false" :endpoint="onSave">
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Configure label') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <SelectElement
            name="property"
            search
            :default="terra_store.settings.label_property_key || 'name'"
            :items="get_available_property_keys"
            :native="false"
            :can-clear="false"
            :label="$t('Property')"
            :description="$t('Choose a property to label the features')"
            :columns="{
              lg: {
                container: 12,
                label: 5,
                wrapper: 12,
              },
            }"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
