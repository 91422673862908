<script setup>
import { compact } from 'lodash-es';

// <HawkTag :rounded="false" type="outlined" color="red" is_clearable>
//   <IconHawkClockFastForward class="w-4 h-4" />
//   <div>Test goes here</div>
//   <IconHawkClockFastForward class="w-4 h-4" />
// </HawkTag>

const props = defineProps({
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['sm', 'md', 'lg'].includes(value);
    },
  },
  color: {
    type: String,
    default: 'gray',
    validator(value) {
      return ['white', 'slate', 'gray', 'zinc', 'neutral', 'stone', 'red', 'orange', 'amber', 'yellow', 'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet', 'purple', 'fuchsia', 'pink', 'rose'].includes(value);
    },
  },
  is_clearable: {
    type: Boolean,
    default: false,
  },
  has_background: {
    type: Boolean,
    default: true,
  },
});

const uuid = (crypto.randomUUID()).substring(0, 8);

const inner_content = ref([]);

function getSizeClasses() {
  const size_classes = {
    sm: ['text-xs'],
    md: ['text-sm'],
    lg: ['text-sm'],
  };
  return size_classes[props.size || 'sm'];
}

function getColorClasses() {
  const background_class = {
    white: 'bg-white',
    slate: 'bg-slate-50',
    gray: 'bg-gray-50',
    zinc: 'bg-zinc-50',
    neutral: 'bg-neutral-50',
    stone: 'bg-stone-50',
    red: 'bg-red-50',
    orange: 'bg-orange-50',
    amber: 'bg-amber-50',
    yellow: 'bg-yellow-50',
    lime: 'bg-lime-50',
    green: 'bg-green-50',
    emerald: 'bg-slate-50',
    teal: 'bg-teal-50',
    cyan: 'bg-cyan-50',
    sky: 'bg-sky-50',
    blue: 'bg-blue-50',
    indigo: 'bg-indigo-50',
    violet: 'bg-violet-50',
    purple: 'bg-purple-50',
    fuchsia: 'bg-fuchsia-50',
    pink: 'bg-pink-50',
    rose: 'bg-rose-50',
  };
  const color_classes = {
    white: ['border-slate-200', 'text-slate-700'],
    slate: ['border-slate-200', 'text-slate-700'],
    gray: ['border-gray-200', 'text-gray-700'],
    zinc: ['border-zinc-200', 'text-zinc-700'],
    neutral: ['border-neutral-200', 'text-neutral-700'],
    stone: ['border-stone-200', 'text-stone-700'],
    red: ['border-red-200', 'text-red-700'],
    orange: ['border-orange-200', 'text-orange-700'],
    amber: ['border-amber-200', 'text-amber-700'],
    yellow: ['border-yellow-200', 'text-yellow-700'],
    lime: ['border-lime-200', 'text-lime-700'],
    green: ['border-green-200', 'text-green-700'],
    emerald: ['border-emerald-200', 'text-slate-700'],
    teal: ['border-teal-200', 'text-teal-700'],
    cyan: ['border-cyan-200', 'text-cyan-700'],
    sky: ['border-sky-200', 'text-sky-700'],
    blue: ['border-blue-200', 'text-blue-700'],
    indigo: ['border-indigo-200', 'text-indigo-700'],
    violet: ['border-violet-200', 'text-violet-700'],
    purple: ['border-purple-200', 'text-purple-700'],
    fuchsia: ['border-fuchsia-200', 'text-fuchsia-700'],
    pink: ['border-pink-200', 'text-pink-700'],
    rose: ['border-rose-200', 'text-rose-700'],
  };
  return [...color_classes[props.color || 'gray'], (props.has_background ? background_class[props.color || 'gray'] : '')];
}

const tag_classes = computed(() => {
  const classes = ['w-fit', 'inline-flex', 'items-center', 'gap-1', 'py-[3px]', 'text-xs', 'rounded-lg', 'border'];

  classes.push(...getSizeClasses());

  classes.push(...getColorClasses());

  if (inner_content.value && inner_content.value[1]?.tagName === 'svg' && inner_content.value[3]?.tagName === 'svg')
    classes.push('px-1');
  else if (inner_content.value && inner_content.value[1]?.tagName === 'svg')
    classes.push('pl-1 pr-2');
  else if (inner_content.value && inner_content.value[3]?.tagName === 'svg')
    classes.push('pl-2 pr-1');
  else
    classes.push('px-2');

  return compact(classes);
});

onMounted(() => {
  inner_content.value = (document?.getElementById(`${uuid}`))?.childNodes;
});
</script>

<template>
  <div :id="uuid" :class="tag_classes">
    <slot />
    <IconHawkX v-if="is_clearable" class="h-4 w-4 cursor-pointer hover:text-gray-900" @click.stop="$emit('clear')" />
  </div>
</template>
