<script setup>
import { useModal } from 'vue-final-modal';

import CustomExportForm from '~/tasks/components/organisms/custom-export/custom-export-form.vue';

const props = defineProps({
  export_method: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['confirm', 'close']);

const { open: openCustomExportForm, close: closeCustomExportForm, patchOptions: patchCustomExportForm } = useModal({
  component: CustomExportForm,
});

function customizeReport() {
  patchCustomExportForm(
    {
      attrs: {
        onClose() {
          closeCustomExportForm();
        },
        onExport: (payload) => {
          props.export_method(payload);
          emit('close');
        },
      },
    },
  );
  openCustomExportForm();
}

function exportWithoutCustomization() {
  props.export_method({});
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-content>
      <HawkFeaturedIcon theme="light-circle-outline" color="primary" size="xl" class="mb-6">
        <IconHawkFileTwo />
      </HawkFeaturedIcon>
      <p class="text-gray-900 text-lg font-semibold">
        {{ $t('Do you want to customize the report?') }}
      </p>
      <p class="text-gray-600 text-sm mb-6">
        {{ $t('You can customize the report and also add summary to the exported pdf. Summary will be shown before task details in the report') }}
      </p>
      <div class="flex items-center cursor-pointer" @click="customizeReport">
        <IconHawkPencilOne class="h-4 text-gray-600 mr-2" />
        <p class="text-gray-600 text-sm font-semibold">
          {{ $t('Customize report') }}
        </p>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer class="flex justify-between items-center">
      <template #right>
        <!-- Footer -->
        <div class="flex justify-end items-center">
          <hawk-button
            class="mr-5"
            type="outlined"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </hawk-button>
          <hawk-button
            color="primary"
            @click="exportWithoutCustomization"
          >
            {{ $t('Download without customization') }}
          </hawk-button>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
