<script setup>
import { storeToRefs } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { TRANSMITTAL_STATUS } from '~/dms/constants';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const emit = defineEmits(['update']);

const { DATE_FILTER_OPTIONS } = useFamConstants();

const { $t, common_store, route } = useCommonImports();
const dms_settings_store = useDMSSettingsStore();
const dashboard_store = useDashboardStore();
const { document_status } = storeToRefs(dms_settings_store);

const {
  widget_configuration,
} = storeToRefs(dashboard_store);

const field_types_map = {
  dropdown: {
    data_type: 'multi_select',
    operators: ['containsAnyOf'],
  },
  number: {
    data_type: 'number',
    operators: ['isBetween'],
  },
  single_select: {
    data_type: 'single_select',
    operators: ['isAnyOf'],
  },
  multi_select: {
    data_type: 'multi_select',
    operators: ['containsAnyOf'],
  },
  date: {
    data_type: 'date',
    operators: ['between'],
  },
  checkbox: {
    data_type: 'multi_select',
    operators: ['isAnyOf'],
  },
  text: {
    data_type: 'text',
    operators: ['contains'],
  },
  yes_no_na: {
    data_type: 'boolean',
    operators: ['isEqualTo'],
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
      { value: 'NA', label: 'NA' },
    ],
  },
  members: {
    data_type: 'multi_select',
    operators: ['isAnyOf'],
    option_type: 'member',
    options: common_store.scope_users(route.params?.asset_id || null),
  },
};
const transmittal_status_enum = [
  'pending_upload',
  'waiting_for_upload',
  'pending_review',
  'waiting_for_review',
  'completed',
  'cancelled',
];
const form_data = ref({ filters: [] });

const init = ref(true);

const rules_state = reactive({
  is_invalid: false,
  get_filters_payload: null,
});

const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => !field.deleted && field.active));

const standard_files_filters = computed(() => ({
  status: {
    uid: 'status',
    name: $t('Status'),
    type: 'select',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: document_status.value?.map(({ name, uid }) => ({ uid, name })) || [],
  },
  name: {
    uid: 'name',
    name: $t('Name'),
    data_type: 'text',
    operators: ['contains'],
  },
  number: {
    uid: 'number',
    name: $t('Number'),
    data_type: 'text',
    operators: ['contains'],
  },
  modified_on: {
    uid: 'modified_on',
    name: 'Modified On',
    data_type: 'date',
    operators: ['between'],
  },
  shared_with: {
    uid: 'shared_with',
    name: 'Shared With',
    data_type: 'multi_select',
    option_type: 'member',
    operators: ['containsAnyOf'],
  },
}));

const standard_trasmittal_filters = computed(() => ({
  status: {
    name: 'Status',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: transmittal_status_enum.map(status => ({ name: TRANSMITTAL_STATUS[status].title, uid: status })),
    uid: 'status',
  },
  issue_purpose: {
    name: 'Issue purpose',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: (dms_settings_store?.issue_purposes || []).map(status => ({ name: status.name, uid: status.name })),
    uid: 'issue_purpose',
  },
  priority: {
    name: 'Priority',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: [{ name: 'Critical', uid: 'critical' }, { name: 'High', uid: 'high' }, { name: 'Medium', uid: 'medium' }, { name: 'Low', uid: 'low' }, { name: 'Not set', uid: 'not_set' }],
    uid: 'priority',
  },
  category: {
    name: 'Category',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    uid: 'category',
    option_type: 'categories',
  },
  created_on: {
    name: 'Created on',
    is_static: true,
    data_type: 'date',
    operators: ['between'],
    uid: 'created_on',
  },
  submitters: {
    name: 'Submitters',
    is_static: true,
    type: 'member',
    data_type: 'single_select',
    operators: ['containsAnyOf', 'doesNotContain'],
    option_type: 'member',
    uid: 'submitters',
  },
  reviewers: {
    name: 'Reviewers',
    is_static: true,
    type: 'member',
    data_type: 'single_select',
    operators: ['containsAnyOf', 'doesNotContain'],
    option_type: 'member',
    uid: 'reviewers',
  },
  approvers: {
    name: 'Approvers',
    is_static: true,
    type: 'member',
    data_type: 'single_select',
    operators: ['containsAnyOf', 'doesNotContain'],
    option_type: 'member',
    uid: 'approvers',
  },
  cc: {
    name: 'CC',
    is_static: true,
    type: 'member',
    data_type: 'single_select',
    operators: ['containsAnyOf', 'doesNotContain'],
    option_type: 'member',
    uid: 'cc',
  },
  due_date: {
    name: 'Due date',
    is_static: true,
    data_type: 'date',
    operators: ['between'],
    uid: 'due_date',
  },
}));
const standard_filters = computed(() => {
  if (widget_configuration.value?.data_source === 'files')
    return standard_files_filters.value;
  else return standard_trasmittal_filters.value;
});
const filter_options = computed(() => {
  const custom_fields_filters = {};
  if (custom_fields.value?.length) {
    custom_fields.value.forEach((element) => {
      custom_fields_filters[element.uid] = {
        uid: element.uid,
        name: element.label,
        options: element.items?.map(({ label, value }) => ({ uid: value, name: label })) || [],
        type: 'select',
        is_static: true,
        ...(field_types_map[element.type] || {}),
      };
    });
  }

  return { ...standard_filters.value, ...custom_fields_filters };
});

const dashboard_custom_date_filter_options = computed(() => {
  const date_filter_options = DATE_FILTER_OPTIONS;
  date_filter_options.between = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.between];
  date_filter_options.isNotBetween = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.isNotBetween];
  date_filter_options.isEqualTo = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.isEqualTo];
  date_filter_options.isNotEqualTo = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.isNotEqualTo];
  return date_filter_options;
});

function emitUpdate(data) {
  form_data.value.filters = data;

  if (rules_state.is_invalid)
    return;

  const report_filters = [];

  data.forEach((f) => {
    if (!f)
      return;
    const value = f.value;
    report_filters.push({
      name: f.field,
      type: f.operator,
      value,
    });
  });
  emit('update', {
    filters: rules_state.get_filters_payload.filters() || [],
    report_filters,
  });
}

function setInitialState() {
  dms_settings_store.fetch_all_statuses();
  dms_settings_store.fetch_all_issue_purposes();
  const filters = widget_configuration.value?.filters?.filters;
  form_data.value.filters = filters || [];

  init.value = false;
}

onMounted(() => {
  setInitialState();
});
</script>

<template>
  <div class="text-sm text-blue-600 font-medium cursor-pointer">
    {{ $t('Advanced filters') }}
  </div>
  <Vueform
    v-if="!init"
    :key="`${route.name}is_internal`"
    v-model="form_data"
    sync
    size="sm"
  >
    <div class="col-span-12 mt-4">
      <FamTabFormRules
        name="filters"
        :rules="form_data.filters"
        :fields="Object.values(filter_options)"
        :is_required="false"
        :is_generic="true"
        :custom_date_filter_options="dashboard_custom_date_filter_options"
        @update-rules="emitUpdate($event)"
        @invalid="($event) => (rules_state.is_invalid = $event)"
        @get-filter-payload="rules_state.get_filters_payload = $event"
      />
    </div>
  </Vueform>
</template>
