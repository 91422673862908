<script setup>
import HawkActivitiesTemplate from '~/common/components/organisms/hawk-activities/hawk-activities-template.vue';

import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});
const task_store = useTasksStore();

const ACTIVITY_VERB_MAP = {
  CREATE_FOLDER: 'created the folder',
  DELETE_CHILD_FOLDER: 'deleted folder',
  MOVE_FOLDER: 'moved the folder here',
  DOWNLOAD_FOLDER: 'downloaded the folder',
  CREATE_FILE: 'created the file',
  UPDATE_FILE: 'renamed the file',
  DOWNLOAD_FILE: 'downloaded the file',
  READ_FILE: 'viwed the file',
  UPDATE_USERS: '',
  UPDATE_LABELS: '',
  CREATED_FILE_VERSION: 'added a new version',
  FILE_DEFAULT_VERSION_CHANGED: 'marked the version as active',
  DELETE_FILE_VERSION: 'deleted the version',
  MOVE_FILE: 'moved the file here',
  FILE_UNARCHIVED: 'unarchived file',
  FILE_MOVED: 'moved the file here',
  FILE_DELETED: 'deleted the file',
  FILE_ARCHIVED: 'archived the file',

};
</script>

<template>
  <HawkActivitiesTemplate :activity="activity" class="text-sm">
    <template #action>
      {{ ACTIVITY_VERB_MAP[activity.verb] }}
    </template>
    <template #from="{ from }">
      <span class="mx-[5px]">
        {{ $t('from') }}
      </span>
    </template>
    <template #to="{ to }">
      <span class="mx-[5px]">
        {{ $t('to') }}
      </span>
    </template>
    <template #meta="{ meta }">
      <div class="mx-[5px]">
        {{ activity.meta.labels }}
        <div v-if="['UPDATE_USERS'].includes(activity.verb)">
          {{ activity.meta.users[0].op === 'add' ? 'added' : 'removed' }} {{ activity.meta.users.length }} users <HawkMembers :members="activity.meta.users" class="whitespace-nowrap" type="badge" />
        </div>
        <div v-else-if="['UPDATE_LABELS'].includes(activity.verb)">
          {{ activity.meta.lebels[0].op === 'add' ? 'added' : 'removed' }} {{ activity.meta.labels?.length }} team <HawkMembers :members="activity.meta.labels" class="whitespace-nowrap" />
        </div>
        <div v-else-if="meta.key === 'uid'" class="text-gray-600">
          {{ activity.meta.name }}
        </div>
        <div v-else-if="meta.items.name" class="text-gray-600">
          {{ meta.items.name }}
        </div>
        <div v-else class="text-gray-600">
          {{ activity?.meta?.file?.name }}
        </div>
      </div>
    </template>
  </HawkActivitiesTemplate>
</template>
