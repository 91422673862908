<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';

import TaskActivitiesItem from '~/common/components/organisms/hawk-activities/task-activities/task-activities-item.vue';

const props = defineProps({
  activity: {
    type: Object,
    required: true,
  },
});

const activity_text = {
  TICKET_ASSIGNMENT_CHANGED: 'changed assignment',
  TICKET_UNASSIGNED: 'unassigned task from',
  TICKET_ASSIGNED: 'assigned task to',
  TICKET_PRIORITY_CHANGED: 'changed priority',
  DUE_DATE_CHANGED: 'changed due date',
  ATTACHMENT_DELETED: 'deleted attachment',
  ATTACHMENT_UPLOADED: 'added attachment',
  MULTIPLE_ATTACHMENT_UPLOADED: 'added multiple attachments',
  TICKET_STATUS_CHANGED: 'changed status',
  CATEGORY_ADDED: 'set category',
  START_DATE_CHANGED: 'changed start date',
  CATEGORY_REMOVED: 'removed category',
  CATEGORY_CHANGED: 'changed category',

  PARENT_REMOVED: 'removed parent task',
  SUBTASK_REMOVED: 'removed subtask',
  SUBTASK_STATUS_CHANGED: 'updated subtask status',
  DEPENDENCY_REMOVED: 'removed dependency',
  DEPENDENCY_ADDED: 'added dependency',
};
// Activities which has "from" null
const initial_activity_text = {
  TICKET_ASSIGNMENT_CHANGED: 'set assignment',
  CATEGORY_ADDED: 'set category',
  TICKET_PRIORITY_CHANGED: 'set priority',
  DUE_DATE_CHANGED: 'set due date',
  TICKET_STATUS_CHANGED: 'set status',
  START_DATE_CHANGED: 'set start date',
  PARENT_ADDED: 'added parent task',
  SUBTASK_STATUS_CHANGED: 'added subtask status',
  ASSIGNEE_ADDED: 'added assignees',
  ASSIGNEE_REMOVED: 'removed assignees',
  SUBTASK_ADDED: 'added subtask',
  WATCHERS_ADDED: 'added watchers',
  WATCHERS_REMOVED: 'removed watchers',
};

const has_meta_from = computed(() => {
  const key = Object.keys(props.activity.meta).filter(key => key !== 'task')[0];
  return !!props.activity.meta[key].from;
});
const auth_store = useAuthStore();
const current_user_id = auth_store.logged_in_user_details.user_id;

const actor = computed(() => {
  return props.activity.actor.split(':')[1];
});
</script>

<template>
  <div v-if="props.activity" class="flex items-start my-2.5">
    <HawkMembers
      class="mr-2"
      size="xs"
      :has_name="false"
      :members="actor"
    />
    <div class="pl-1">
      <div v-if="actor === current_user_id" class="text-xs text-[#475467] flex text-center items-center whitespace-nowrap">
        <p class="mr-2 text-gray-700 text-sm">
          {{ $t('You') }}
        </p>
        <p class="text-xs text-[#475467] whitespace-nowrap">
          {{ $date(props.activity.created_at, 'DATETIME_LONG_WITH_LONG_DAY_AND_MONTH') }}
        </p>
      </div>
      <p v-else class="flex text-center items-center flex-wrap">
        <HawkMembers :members="actor" :has_avatar="false" type="label" class="mr-2 text-gray-700 whitespace-nowrap" />
        <span class="text-xs text-[#475467] whitespace-nowrap">
          {{ $date(props.activity.created_at, 'DATETIME_LONG_WITH_LONG_DAY_AND_MONTH') }}
        </span>
      </p>
      <TaskActivitiesItem :activity="props.activity" />
    </div>
  </div>
</template>
