<script setup>
/*
  options: {
    truncate_config: {
      format: <String: 'fixed', 'adaptive'>,
      style: <String: 'truncate', 'truncateMiddle'>,
      length: <Number>,
    }
    rich_text: <Boolean>
  }
*/
import dayjs from 'dayjs';
import { $date } from '~/common/utils/date.util';

const props = defineProps({
  value: {
    type: String,
    default: null,
  },
  options: {
    type: Object,
    required: true,
  },
});

const is_date_valid = computed(() => props.value && dayjs(props.value).isValid());

const formatted_date = computed(() => {
  if (is_date_valid.value)
    return $date(props.value, props.options.format);
  else if (props.options.fallback)
    return props.options.fallback;
  else
    return '-';
});
</script>

<template>
  <p
    class="text-sm whitespace-nowrap text-gray-600"
    :class="{
      '!text-error-700': options.flag_overdue && (is_date_valid && new Date(value) <= new Date()),
    }"
  >
    {{ formatted_date }}
  </p>
</template>
