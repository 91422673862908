<script setup>
// --------------------------------- Imports -------------------------------- //
import { groupBy, keyBy, uniqBy } from 'lodash-es';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';
import { useDocumentStore } from '~/dms/store/document.store';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  store: {
    type: Object,
    default: () => {},
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['import-success']);

// ---------------------------- Injects/Provides ---------------------------- //
const $toast = inject('$toast');
const $t = inject('$t');

// ----------------------- Variables - Pinia - consts ----------------------- //
const document_store = useDocumentStore();
const document_crud = useDocumentCrud();

// ------------------- Variables - Local - consts and lets ------------------ //
const active_folder = document_store.active_folder_detail;

const legends = {
  error: {
    style: {
      backgroundColor: 'red', // Light red
      color: 'white',
    },
    label: $t('Required/Invalid value'),
    description: $t('The data is either invalid/duplicate or can\'t be modified.'),
  },
};

// ---------------------- Variables - Local - refs & reactives --------------------- //

const columns = ref([
  {
    field: 'folder_name',
    label: $t('Folder name'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Name of the folder to import the documents'),
    example: 'Civil documents',
    preHook: async (colData, formattedData) => {
      return colData.map((data, idx) => {
        let { is_valid, style, message } = getInitAttributes(data);
        const invalid_characters_str = '\\/:*?\'\'<>|';
        const folder_name_regex = /^[^\\:*?'"<>|]+$/;

        if (!folder_name_regex.test(data))
          ({ is_valid, style, message } = getInvalidAttributes(data, `${$t('A folder name can not contain any of the following characters')}: ${invalid_characters_str}`));

        return {
          data,
          properties: {
            is_valid,
            message,
            style,
          },
        };
      });
    },
  },
  {
    field: 'document_number',
    label: $t('Document number'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Unique document number'),
    example: 'NLB001-E59R',
  },
  {
    field: 'document_name',
    label: $t('Document name'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Name of the document'),
    example: 'DC layout',
  },
  {
    field: 'category',
    label: $t('Category'),
    data_type: 'text',
    data_format: 'Text',
    required: true,
    description: $t('Category of the document'),
    example: 'Electrical',
  },
]);

const schema = reactive({
  legends,
  columns: [...columns.value],
  methods: {
    callback: async (data) => {
      const folders_created = await createFolders(data);
      const folders_created_map = keyBy(folders_created, 'name');

      const files_to_add = data.map(item => ({
        name: item.document_name,
        category: item.category,
        number: item.document_number,
        is_placeholder: true,
        target_element: folders_created_map[item.folder_name].element,
        folder_uid: folders_created_map[item.folder_name].uid,
        asset: folders_created_map[item.folder_name].asset,
      }));

      try {
        await document_store.crud_documents({
          request: {
            body: {
              files: {
                add: files_to_add,
              },
            },
          },
        });
      }
      catch (e) {
        logger.error(e);
      }

      await document_store.set_hierarchy();
    },
  },
});

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //
async function createFolders(data) {
  let response = [];
  const common_payload = document_crud.getCommonPayload();
  const unique_folders = uniqBy(data, folder => folder.folder_name);
  const existing_folder_map_by_name = keyBy(document_store.folders, 'name');
  const folder_groups = groupBy(unique_folders, (folder) => {
    if (folder.folder_name.includes('/'))
      return 'hierarchy_folders';

    return existing_folder_map_by_name[folder.folder_name]?.uid ? 'existing_folders' : 'non_existing_folders';
  });

  const folders_to_create = (folder_groups.non_existing_folders || []).map(folder => ({ ...common_payload, name: folder.folder_name }));
  const existing_folders_data = (folder_groups.existing_folders || []).map(folder => existing_folder_map_by_name[folder.folder_name]);
  const hierarchy_folders_to_create = (folder_groups.hierarchy_folders || []).map(folder => (
    {
      ...common_payload,
      name: folder.folder_name,
      path: `${folder.folder_name}/${folder.document_name}`,
    }
  ));

  try {
    let normal_folders = [];
    let hierarchy_folders = [];

    if (folders_to_create.length) {
      const { data } = await document_store.crud_documents({
        request: {
          body: {
            folders: {
              add: folders_to_create,
            },
          },
        },
      });
      normal_folders = data.documents.folders.added || [];
    }

    if (hierarchy_folders_to_create.length) {
      // If folder name is 'F4/F4.1/F4.1.1' then create folder hierarchy
      const { data } = await document_store.create_folder_structure({
        files: hierarchy_folders_to_create.map(folder => ({ path: folder.path })),
        ...common_payload,
      });

      const hierarchy_folders_map = keyBy(hierarchy_folders_to_create, 'path');

      hierarchy_folders = Object.keys(data).map(hierarchy_path =>
        ({
          ...hierarchy_folders_map[hierarchy_path],
          uid: data[hierarchy_path],
          element: hierarchy_folders_map[hierarchy_path]?.target_element,
        }),
      );
    }

    response = [...normal_folders, ...hierarchy_folders];
  }
  catch (e) {
    logger.error(e);
  }

  return [...response, ...existing_folders_data];
}

function getInitAttributes(data = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: {
      backgroundColor: 'none',
    },
    translated_data: data,
    message: '',
  };
}

function getInvalidAttributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: false,
    style: legends.error.style,
    translated_data: data,
    message,
  };
}

function getUpdateAttributes(data, message = '') {
  return {
    is_disabled: false,
    is_valid: true,
    style: legends.warning.style,
    translated_data: data,
    message,
  };
}
</script>

<template>
  <csv-importer
    :schema="schema"
    :automap_columns="true"
    :generate_sample_file_name="() => 'Documents'"
    @import-success="emit('import-success')"
  />
</template>
