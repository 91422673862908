<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  uom: {
    type: String,
    default: '-',
  },
  is_symbol: {
    type: Boolean,
    default: false,
  },
  is_full: {
    type: Boolean,
    default: false,
  },
});
const inventory_store = useInventoryStore();
const uom_details = computed(() => inventory_store.get_uom(props.uom));
</script>

<template>
  <span>
    {{ is_full
      ? `${uom_details?.name} ${uom_details?.symbol ? ` (${uom_details?.symbol})` : ''}`
      : (is_symbol ? uom_details?.symbol : uom_details?.name) }}
  </span>
</template>
