<script setup>
import { useModal } from 'vue-final-modal';
import InventoryCustomFieldForm from '~/inventory/components/inventory-custom-fields/inventory-custom-field-form.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  attached_to: {
    type: String,
  },
  attached_to_uid: {
    type: String,
  },
});

const emit = defineEmits(['added', 'updated']);

const inventory_store = useInventoryStore();

const { open: openCustomFieldPopup, close: closeCustomFieldPopup } = useModal({
  component: InventoryCustomFieldForm,
  attrs: {
    onClose() {
      closeCustomFieldPopup();
    },
    onCancel() {
      closeCustomFieldPopup();
    },
    onSave(custom_field) {
      inventory_store.add_custom_field({
        uid: custom_field.uid,
        value: custom_field,
      });
      const field = {
        item_meta: {
          ...custom_field.attached_to[0],
          attached_to_index: 0,
        },
        value: null,
        uid: custom_field.uid,
      };
      closeCustomFieldPopup();
      emit('added', field);
    },

    onAdded(custom_field) {
      inventory_store.add_custom_field({
        uid: custom_field.uid,
        value: custom_field,
      });
      const meta_index = custom_field.attached_to.findIndex(item => (item.type === props.attached_to && (props.attached_to_uid ? item.uid === props.attached_to_uid : true)));
      const field = {
        item_meta: {
          ...custom_field.attached_to[meta_index],
          attached_to_index: meta_index,
        },
        value: null,
        uid: custom_field.uid,
      };
      closeCustomFieldPopup();
      emit('added', field);
    },
    onUpdate() {

    },
    attached_to: {
      type: props.attached_to,
      uid: props.attached_to_uid,
    },
  },
});
</script>

<template>
  <div @click="openCustomFieldPopup">
    <slot name="trigger">
      <HawkButton class="w-max" type="text">
        <IconHawkPlus /> {{ $t('Add Field') }}
      </HawkButton>
    </slot>
  </div>
</template>
