<script setup>
import { getDurationAndPeriod } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  duration: {
    type: Number,
    required: true,
  },
});

const PERIOD_MAP = {
  Days: 1,
  Weeks: 7,
  Months: 30,
  Years: 365,
};

const form_data = ref(null);
const duration_period = ref('Days');

const show_dropdown = ref(false);

const duration_options = [
  {
    label: 'Days',
    on_click: () => duration_period.value = 'Days',
  },
  {
    label: 'Weeks',
    on_click: () => duration_period.value = 'Weeks',
  },
  {
    label: 'Months',
    on_click: () => duration_period.value = 'Months',
  },
  {
    label: 'Years',
    on_click: () => duration_period.value = 'Years',
  },
];

onMounted(() => {
  if (props.duration) {
    const { value, period } = getDurationAndPeriod(props.duration);
    form_data.value = {
      duration_value: value,
    };
    duration_period.value = period;
  }
});

function getDuration() {
  return {
    duration: Number(form_data.value.duration_value) * PERIOD_MAP[duration_period.value],
  };
}

function validateDuration(e) {
  if (e.target.value) {
    const value = Number(e.target.value);
    let VALUE_TO_LOAD = 1;
    if (value >= VALUE_TO_LOAD && Number.isInteger(value))
      VALUE_TO_LOAD = value;

    form_data.value.duration_value = VALUE_TO_LOAD;
  }
}

defineExpose({ form_data, getDuration });
</script>

<template>
  <Vueform
    v-model="form_data"
    sync
    size="sm"
    :columns="{
      default: { container: 12, label: 3, wrapper: 12 },
      sm: { container: 12, label: 3, wrapper: 12 },
      md: { container: 12, label: 3, wrapper: 12 },
    }"
  >
    <TextElement
      label="Set duration"
      name="duration_value"
      placeholder="Enter a value"
      input-type="number"
      :add-classes="{
        ElementLayout: {
          innerContainer: 'border border-gray-300 rounded-lg h-full w-full focus-within:ring-4 focus-within:ring-primary-100 focus-within:border-primary-600',
          innerWrapper: '!col-span-9',
          innerWrapperAfter: '!col-span-3 flex items-center justify-end',
        },
      }"
      :remove-classes="{
        TextElement: {
          inputContainer_default: 'focused:form-ring',
          inputContainer: 'border-solid form-border-width-input',
          inputContainer_success: 'border-solid form-border-width-input focused:form-ring',
        },
      }"
      @input="validateDuration"
    >
      <template #after>
        <hawk-menu
          :items="duration_options"
          additional_trigger_classes="!ring-0"
          position="fixed"
          @open="show_dropdown = true"
          @close="show_dropdown = false"
        >
          <template #trigger>
            <div
              class="px-2 py-[6px] flex items-center rounded-lg w-full h-full"
            >
              {{ duration_period }}
              <IconHawkChevronDown
                class="transition-transform"
                :class="{ 'rotate-180 !visible': show_dropdown }"
              />
            </div>
          </template>
        </hawk-menu>
      </template>
    </TextElement>
  </Vueform>
</template>
