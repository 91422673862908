<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import PmEditCustomField from '~/project-management/components/pm-edit-custom-field.vue';
import PmFieldMappingsModal from '~/project-management/components/activity-details/pm-field-mappings-modal.vue';

const $date = inject('$date');
const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_task, active_schedule } = storeToRefs(project_management_store);

const new_field_modal = useModal({
  component: PmEditCustomField,
  attrs: {
    onClose() {
      new_field_modal.close();
    },
  },
});

const field_mappings_modal = useModal({
  component: PmFieldMappingsModal,
  attrs: {
    onClose() {
      field_mappings_modal.close();
    },
  },
});

const fields_menu_items = [
  {
    label: $t('Field mappings'),
    left_icon: IconHawkDataflowThree,
    on_click() {
      field_mappings_modal.open();
    },
  },
];

const custom_fields = computed(() => {
  return active_schedule.value?.custom_fields || {};
});

const field_quantities = computed(() => {
  return active_task.value?.field_quantities || {};
});

function field_quantities_percentage(field) {
  if (field?.current === 0)
    return 0;
  return Math.floor((field?.current / field?.total) * 100);
}

const active_task_custom_field_values = computed(() => {
  return active_task.value?.custom_field_values || {};
});

function getDateFieldValue(field_name) {
  if (active_task_custom_field_values.value[field_name])
    return $date(active_task_custom_field_values.value[field_name], 'YYYY-MM-DD');
  return undefined;
}

const active_task_info = computed(() => {
  return [
    [$t('Activity name'), () => {
      return active_task.value.name;
    }],
    [$t('Activity ID'), () => {
      return active_task.value.id;
    }],
    [$t('Start'), () => {
      return $date(active_task.value.start_date, 'DATE_MED');
    }],
    [$t('status'), () => {
      return active_task.value.status || 'In progress';
    }],
    [$t('Finish'), () => {
      return $date(active_task.value.end_date, 'DATE_MED');
    }],
    [$t('Progress'), () => {
      return `${Math.round(active_task.value.progress * 100)}${$t('% Complete')}`;
    }],
    [$t('Duration'), () => {
      return `${Math.ceil(active_task.value.total_duration)} ${$t('Days')}`;
    }],
    [$t('Critical'), () => {
      return active_task.value.is_critical ? $t('Yes') : $t('No');
    }],
  ].map((i) => {
    return {
      label: i[0],
      value: i[1],
    };
  });
});

const additional_details = computed(() => {
  return [
    [$t('Planned Start'), () => {
      return active_task.value.planned_start ? $date(active_task.value.planned_start, 'DATE_MED') : '-';
    }],
    [$t('Planned Finish'), () => {
      return active_task.value.planned_finish ? $date(active_task.value.planned_finish, 'DATE_MED') : '-';
    }],
    [$t('Actual Start'), () => {
      return active_task.value.actual_start ? $date(active_task.value.actual_start, 'DATE_MED') : '-';
    }],
    [$t('Actual Finish'), () => {
      return active_task.value.actual_finish ? $date(active_task.value.actual_finish, 'DATE_MED') : '-';
    }],
    [$t('Planned Duration'), () => {
      if (active_task.value.planned_duration !== undefined || active_task.value.planned_duration !== null)
        return `${Math.round(active_task.value.planned_duration)} ${$t('Days')}`;
      return '-';
    }],
    [$t('Actual Duration'), () => {
      if (active_task.value.actual_duration !== undefined || active_task.value.actual_duration !== null)
        return `${Math.round(active_task.value.actual_duration)} ${$t('Days')}`;
      return '-';
    }],
    [$t('Total Slack'), () => {
      return `${active_task.value.total_slack} ${$t('Days')}`;
    }],
    [$t('Free Slack'), () => {
      return `${active_task.value.free_slack} ${$t('Days')}`;
    }],
    ...(active_schedule.value.baseline
      ? [
          [$t('Baseline Start'), () => {
            return active_task.value.bl_start ? $date(active_task.value.bl_start, 'DATE_MED') : '-';
          }],
          [$t('Baseline Finish'), () => {
            return active_task.value.bl_finish ? $date(active_task.value.bl_finish, 'DATE_MED') : '-';
          }],
          [$t('Baseline Duration'), () => {
            if (active_task.value.bl_duration !== undefined || active_task.value.bl_duration !== null)
              return `${Math.round(active_task.value.bl_duration)} ${$t('Days')}`;
            return '-';
          }],
        ]
      : []),
  ].map((i) => {
    return {
      label: i[0],
      value: i[1],
    };
  });
});

function openEditCustomFieldModal(operation_type, field_name, field_value) {
  new_field_modal.patchOptions({
    attrs: {
      operation_type,
      field_name,
      ...(operation_type === 'update' ? { field_value } : {}),
      ...(operation_type === 'update' ? { field_type: custom_fields.value[field_name]?.type } : {}),
      ...((operation_type === 'update' && custom_fields.value[field_name]?.config) ? { config: custom_fields.value[field_name].config } : {}),
    },
  });
  new_field_modal.open();
}
</script>

<template>
  <div class="relative flex justify-between">
    <div class="w-2/3">
      <div class="flex justify-between items-center mb-1">
        <div class="text-lg font-semibold text-gray-900">
          {{ $t('Fields') }}
        </div>
        <HawkMenu :items="fields_menu_items" additional_trigger_classes="!ring-0" position="bottom-left">
          <template #item="{ item }">
            <component :is="item.left_icon" class="inline mr-2 text-gray-500" />
            {{ item.label }}
          </template>
        </HawkMenu>
      </div>
      <div class="text-sm leading-5 mb-4 grid grid-cols-2 gap-y-3 gap-x-12">
        <div
          v-for="info in active_task_info"
          :key="`task-info-${info.label}` "
          class="grid grid-cols-2"
        >
          <div class="text-gray-700 font-medium">
            {{ info.label }}:
          </div>
          <div class="text-gray-900 font-normal">
            <span v-if="$t('Activity name') === info.label">
              {{ info.value() }}
            </span>
            <span v-else v-tippy="{ content: info.value(), placement: 'top' }">
              {{ $filters.truncate(info.value(), 20) }}
            </span>
          </div>
        </div>
      </div>
      <hr class="my-4">
      <div class="text-sm leading-5">
        <div class="grid grid-cols-2 gap-x-12 gap-y-3">
          <div
            v-for="details in additional_details"
            :key="`task-details-${details.label}` "
            class="grid grid-cols-2"
          >
            <div class="text-gray-700 font-medium mr-8">
              {{ details.label }}:
            </div>
            <div class="text-gray-900 font-normal">
              <span v-tippy="{ content: details.value(), placement: 'top' }">
                {{ $filters.truncate(details.value(), 20) }}
              </span>
            </div>
          </div>
        </div>
        <template v-if="active_task.activity_code_values?.length">
          <hr class="my-4">
          <div class="font-semibold mb-5">
            {{ $t('Activity details') }}
          </div>
          <div class="grid grid-cols-2 gap-x-12 gap-y-3">
            <div
              v-for="code of active_task.activity_code_values"
              :key="code.id"
              class="grid grid-cols-2"
            >
              <div class="w-32 text-gray-700 font-medium mr-8">
                {{ code.name }}
              </div>
              <div class="w-56 text-gray-900 font-normal">
                <span v-tippy="{ content: code.value.description, placement: 'top' }">
                  {{ $filters.truncate(code.value.description, 20) }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-if="active_task.type === 'task'">
          <hr class="mt-4 mb-2">
          <div class="flex items-center justify-between mb-1">
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Custom Fields') }}
            </div>
            <hawk-button type="text" @click="openEditCustomFieldModal('create')">
              <IconHawkPlus class="text-gray-700" />
              <span class="text-sm font-semibold text-gray-700">
                {{ $t('New field') }}
              </span>
            </hawk-button>
          </div>
          <div v-if="Object.keys(custom_fields).length" class="grid grid-cols-2 gap-y-3 gap-x-12">
            <div
              v-for="field_name in Object.keys(custom_fields)"
              :key="field_name"
              class="grid grid-cols-2 hover:bg-gray-50 rounded-lg group px-2 py-1 -ml-2"
            >
              <div class="text-sm text-gray-700 font-medium flex items-center w-32">
                {{ field_name }}:
              </div>
              <div class="flex items-center ml-1">
                <div class="text-sm text-gray-900 font-normal">
                  <template v-if="active_task_custom_field_values[field_name]">
                    <span v-if="custom_fields[field_name].type === 'date'">
                      {{ $date(active_task_custom_field_values[field_name], 'DATE_MED') }}
                    </span>
                    <span v-else>
                      <span v-if="custom_fields[field_name].type === 'money'">
                        {{ custom_fields[field_name].config.currency.symbol }}
                      </span>
                      <span v-tippy="{ content: active_task_custom_field_values[field_name], placement: 'top' }">
                        {{ $filters.truncate(active_task_custom_field_values[field_name], 20) }}
                      </span>
                    </span>
                  </template>
                  <template v-else>
                    &mdash;
                  </template>
                </div>
                <div
                  class="flex opacity-0 items-center ml-auto rounded-full hover:bg-gray-200 p-2 cursor-pointer group-hover:opacity-100"
                  @click="
                    openEditCustomFieldModal(
                      'update',
                      field_name,
                      custom_fields[field_name].type === 'date'
                        ? getDateFieldValue(field_name)
                        : active_task_custom_field_values[field_name],
                    )"
                >
                  <IconHawkPencilOne class="text-primary w-3 h-3" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="Object.keys(field_quantities).length">
          <hr class="my-4">
          <div class="font-semibold mb-5">
            {{ $t('Work performed') }}
          </div>
          <div class="text-sm leading-5 mb-4 grid grid-cols-2 gap-y-3 gap-x-12">
            <div
              v-for="[code, value] in Object.entries(
                field_quantities,
              )"
              :key="code"
              class="grid grid-cols-2"
            >
              <div class="text-gray-700 font-medium">
                {{ value.name }} ({{ value.units }})
              </div>
              <div class="text-gray-900 font-normal">
                {{ value.current }}/{{ value.total }}
                ({{
                  field_quantities_percentage(value)
                }}%)
                <span
                  v-if="value.work_rate && value.units && !(Number.isNaN(value.work_rate))"
                  v-tippy="{
                    content: `${$t('Work rate')}: ${value.work_rate} ${value.units}/${$t('day')}`,
                    placement: 'top',
                  }"
                  class="ml-1 cursor-pointer"
                >
                  <IconHawkInfoCircle class="inline mb-1" />
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="w-px bg-gray-100 ml-6" />
    <PmGeneralTabProgress class="w-1/3" />
  </div>
</template>
