import IconHawkMicrosoft from '~icons/integrations/microsoft';
import IconHawkOracle from '~icons/integrations/oracle';
import IconHawkSharepoint from '~icons/integrations/sharepoint';
import { keyBy } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

function useThirdPartyConstants() {
  const { $t } = useCommonImports();
  const integration_items = [
    {
      name: 'Sharepoint',
      icon: IconHawkSharepoint,
      type: 'SHAREPOINT',
      account_count: 2,
      can_add_account: true,
      description: $t('Sync document from TaskMapper to your Sharepoint accounts'),
      notes: [
        $t('Streamline workflows, improve collaboration and accessibility through Sharepoint integration. Automatically sync the documents from DMS to a Sharepoint account and maintain consistent and updated documents within the integrated sites.'),
        $t('Configure one/more destinations and control the sync to either sync all documents or certain documents that match a specific criteria. Allow other members to use the integration on your behalf and delegate the integration capabilities without sharing the Sharepoint credentials or additional licenses.'),
      ],
      features: [
        $t('Connect one/more accounts.'),
        $t('Integrate and sync folder items from TaskMapper to Sharepoint.'),
        $t('Grant access and share your account with other team members to use in TaskMapper.'),
        $t('Setup multiple destinations.'),
      ],
    },
    {
      name: 'Primavera P6',
      icon: IconHawkOracle,
      type: 'Primavera P6',
      account_count: 2,
      can_add_account: false,
      description: $t('Efficiently import and manage your project schedules'),
      notes: [
        $t('Seamlessly import your existing project schedules from Primavera P6 into TaskMapper.'),
      ],
      features: [
        $t('Import XER/XML schedules.'),
        $t('Visualize the data in Gantt and Table formats.'),
        $t('Manage activities, work breakdown structure and milestones.'),
        $t('Update progress, status and other custom fields.'),
        $t('Sync with map and automatically update progress.'),
        $t('Manage multiple versions.'),
        $t('Extract insights, s-curve, charts and other metrics.'),
        $t('Resource and Budget management.'),
        $t('Setup automations to notify, remind and run a workflow.'),
        $t('Real-time collaboration'),
      ],
    },
    {
      name: 'Microsoft Projects',
      icon: IconHawkMicrosoft,
      type: 'Microsoft Projects',
      account_count: 2,
      can_add_account: false,
      description: $t('Efficiently import and manage your project schedules'),
      notes: [
        $t('Seamlessly import your existing project schedules from Primavera P6 into TaskMapper.'),
      ],
      features: [
        $t('Import .mpp schedules.'),
        $t('Visualize the data in Gantt and Table formats.'),
        $t('Manage activities, work breakdown structure and milestones.'),
        $t('Update progress, status and other custom fields.'),
        $t('Sync with map and automatically update progress.'),
        $t('Manage multiple versions.'),
        $t('Extract insights, s-curve, charts and other metrics.'),
        $t('Resource and Budget management.'),
        $t('Setup automations to notify, remind and run a workflow.'),
        $t('Real-time collaboration'),
      ],
    },
  ];
  return { integration_items, integration_items_map: keyBy(integration_items, 'type') };
}
export { useThirdPartyConstants };
