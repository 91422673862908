<script setup>
import HawkActivitiesTemplate from '~/common/components/organisms/hawk-activities/hawk-activities-template.vue';

import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});
const task_store = useTasksStore();
const show_from = ref(true);
const show_to = ref(true);
const ACTIVITY_VERB_MAP = {

  STATUS_CHANGED: 'changed status',
  PRIORITY_CHANGED: 'changed priority',
  START_DATE_CHANGED: (from, to) => {
    if (!from && to)
      return 'set the start date';
    else if (from && !to)
      return 'removed the start date';
    else return 'changed the start date';
  },
  DUE_DATE_CHANGED: (from, to) => {
    if (!from && to)
      return 'set the due date';
    else if (from && !to)
      return 'removed the due date';
    else return 'changed the due date';
  },
  ATTACHMENTS_ADDED: '',
  ATTACHMENTS_REMOVED: '',
  ASSIGNEES_ADDED: 'added assignees',
  ASSIGNEES_REMOVED: 'removed assignees',
  WATCHERS_ADDED: 'added watchers',
  WATCHERS_REMOVED: 'removed watchers',
  CATEGORY_CHANGED: (from, to) => {
    const from_uid = props.activity?.meta?.category?.from?.uid;
    const to_uid = props.activity?.meta?.category?.to?.uid;
    if (!from_uid && to_uid)
      return 'set the category';
    else if (from_uid && !to_uid)
      return `removed the category ${props.activity?.meta?.category?.from?.name}`;
    else return 'changed category';
  },
  DEPENDENCIES_REMOVED: '',
  DEPENDENCIES_ADDED: '',
  SUBTASK_ADDED: 'added subtask',
  SUBTASK_REMOVED: 'removed subtask',
  SCHEDULE_CHANGED: (from, to) => {
    if (!from && to)
      return 'scheduled the task';
    else if (from && !to)
      return 'unscheduled the task';
    else return 'changed the schedule';
  },
  TAGS_ADDED: 'added tags',
  TAGS_REMOVED: 'removed tags',
  DELETED: 'deleted task',
  CHECKLIST_ADDED: 'added checklist',
  CHECKLIST_REMOVED: 'removed checklist',
  TASK_UNBLOCKED: 'unblocked task',
  COMMENT_ADDED: 'added a comment',
  LOCATION_CHANGED: (from, to) => {
    if (!from && to)
      return 'set the location';
    else if (from && !to)
      return 'removed the location';
    else return 'changed the location';
  },
  CREATED: 'created the task',
  RENAMED: 'renamed task',
};

function getActivityMessage(verb, from, to) {
  if (typeof ACTIVITY_VERB_MAP[verb] === 'function') {
    const message = ACTIVITY_VERB_MAP[verb](from, to);
    if (message === 'set the category')
      show_from.value = false;
    if (message.startsWith('removed the category')) {
      show_from.value = false;
      show_to.value = false;
    }
    if (verb === 'SCHEDULE_CHANGED') {
      show_from.value = false;
      show_to.value = false;
    }
    return message;
  }

  else { return ACTIVITY_VERB_MAP[verb]; }
}
</script>

<template>
  <HawkActivitiesTemplate :activity="activity" class="text-sm" :exclude_keys="['task']">
    <template #action="{ action }">
      <div v-if="getActivityMessage(action.verb, action.has_meta_from, action.has_meta_to)">
        {{ getActivityMessage(action.verb, action.has_meta_from, action.has_meta_to) }}
      </div>
    </template>
    <template #from="{ from }">
      <template v-if="show_from">
        <span class="mx-[5px]">
          {{ $t('from') }}
        </span>
        <TaskPriority v-if="from.key === 'priority'" :priority="from.value" />
        <TaskStatus v-else-if="from.key === 'status'" :status="from.value" />
        <HawkCategoryName v-else-if="from.key === 'category'" :uid="from.value?.uid || from.value" class="inline-flex" />
        <div v-else-if="from.key === 'due_date' || from.key === 'start_date'" class="inline-flex">
          {{ $date(from.value) }}
        </div>
        <div v-else-if="['location', 'name'].includes(from.key)" class="font-semibold text-gray-800">
          {{ from.value }}
        </div>
      </template>
    </template>
    <template #to="{ to }">
      <template v-if="show_to">
        <span class="mx-[5px]">
          {{ $t('to') }}
        </span>
        <TaskPriority v-if="to.key === 'priority'" :priority="to.value" class="mx-[5px]" />
        <TaskStatus v-else-if="to.key === 'status'" :status="to.value" />
        <HawkCategoryName v-else-if="to.key === 'category'" :uid="to.value?.uid || to.value" class="inline-flex" />
        <div v-else-if="to.key === 'due_date' || to.key === 'start_date'" class="inline-flex">
          {{ $date(to.value) }}
        </div>
        <div v-else-if="['location', 'name'].includes(to.key)" class="font-semibold text-gray-800">
          {{ to.value }}
        </div>
      </template>
    </template>
    <template #meta="{ meta }">
      <div class="mx-[5px]">
        <div v-if="meta.key === 'dependencies'">
          <span class="text-sm">
            {{ meta.items?.added ? $t("added") : $t("removed") }}
            {{ meta.items?.added?.length || meta.items?.removed?.length }}
            {{ meta.items.type === 'blockedBy' ? $t("blocking") : meta.items.type === 'blocking' ? $t("blocked") : $t("linked") }}
            {{ (meta.items?.added?.length || meta.items?.removed?.length) > 1 ? $t("tasks") : $t("task") }}
          </span>
        </div>
        <div v-else-if="meta.key === 'blockedBy'">
          <span class="text-sm">
            {{ `${meta.items.length} ${$t("blocking")} ${$t("task")}` }}
          </span>
        </div>
        <div v-else-if="meta.key === 'blocking'">
          <span class="text-sm">
            {{ `${meta.items.length} ${$t("blocked")} ${$t("task")}` }}
          </span>
        </div>
        <div v-else-if="meta.key === 'linkedTo'">
          <span class="text-sm">
            {{ `${meta.items.length} ${$t("linked")} ${$t("task")}` }}
          </span>
        </div>
        <div v-else-if="meta.key === 'assignees' || meta.key === 'watchers'">
          <HawkMembers :members="meta.items.added || meta.items.removed" type="badge" :max_badges_to_display="2" />
        </div>
        <div v-else-if="meta.key === 'attachments'">
          <p v-if="activity.verb === 'ATTACHMENTS_ADDED'">
            {{ $t("added") }} {{ `${meta.items.length} ${meta.items.length === 1 ? $t("attachment") : $t("attachments")}` }}
          </p>
          <p v-else>
            {{ $t("removed") }}  {{ `${meta.items.length} ${meta.items.length === 1 ? $t("attachment") : $t("attachments")}` }}
          </p>
        </div>
        <div v-else-if="meta.key === 'uids'">
          <div
            v-if="meta.items?.length"
            class="text-sm "
          >
            <p
              class="flex items-center no-whitespace-wrap"
            >
              <IconHawkDependencyLinked class="h-3 mx-[5px] text-primary" />
              {{ task_store?.tasks_map[meta.items[0]]?.name || `*${$t('Task added')}` }}
            </p>
          </div>
        </div>
        <div v-else-if="meta.key === 'subtask'">
          <span class="flex items-center">
            <IconHawkDependencyLinked class="h-3 mx-[5px] text-primary" />
            {{ meta.items.name }}
          </span>
        </div>
        <div v-else-if="meta.key === 'tags'">
          <HawkTagsName :tags="meta.items.added || meta.items.removed" />
        </div>
        <div v-else-if="meta.key === 'checklist'" class="flex flex-wrap">
          <p v-for="item in meta.items" :key="item.name" class="flex items-center text-gray-600 text-xm no-whitespace-wrap ">
            {{ item.name }}
          </p>
        </div>
      </div>
    </template>
  </HawkActivitiesTemplate>
</template>
