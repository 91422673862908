<script setup>
import AttachFiles from '~/common/components/organisms/hawk-attach/attach-files/attach-files.vue';
import AttachDocuments from '~/common/components/organisms/hawk-attach/attach-documents/attach-documents.vue';
import AttachForms from '~/common/components/organisms/hawk-attach/attach-forms.vue';
import AttachTasks from '~/common/components/organisms/hawk-attach/attach-tasks.vue';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: value => ['Files', 'Forms', 'Tasks'].includes(value),
  },
  asset_id: {
    type: String,
    deafult: '',
  },
  restricted_items: {
    type: Array,
    default: () => [],
  },
  on_attach: {
    type: Function,
    default: () => {},
  },
  additional_queries: {
    type: Object,
    default: () => ({}),
  },
  additional_table_options: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['close', 'attached']);
const common_store = useCommonStore();

const state = reactive({
  search: null,
  selected_items: [],
  loading: false,
});
const component_map = {
  Files: common_store.is_development ? AttachDocuments : AttachFiles,
  Forms: AttachForms,
  Tasks: AttachTasks,
};

async function handleSave() {
  state.loading = true;
  await props.on_attach(state.selected_items);
  state.loading = false;

  emit('attached', state.selected_items);
  emit('close');
}
const handleSelection = e => state.selected_items = e;
</script>

<template>
  <hawk-modal-container content_class="!w-[720px] !overflow-clip">
    <hawk-modal-content class="!p-0" :is_scroll="false">
      <div class="col-span-12">
        <div class="flex h-14 items-center mx-4">
          <IconHawkSearchMd class="h-5 w-5 text-gray-600 mr-2" aria-hidden="true" />
          <input
            v-model="state.search"
            type="text"
            class="w-full placeholder-gray-500 text-base font-light"
            placeholder="Search..."
            autofocus
          >
          <hawk-button type="text" icon @click="emit('close')">
            <IconHawkX class="h-5 w-5 text-gray-600" aria-hidden="true" />
          </hawk-button>
        </div>
        <hr class="mb-2">
        <div class="mt-2">
          <component
            :is="component_map[type]"
            :search="state.search"
            :asset_id="asset_id"
            :restricted_items="restricted_items"
            :additional_queries="additional_queries"
            :additional_table_options="additional_table_options"
            @selected="handleSelection"
          />
        </div>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end">
          <HawkButton color="primary" type="outlined" class="mr-3" @click="emit('close')">
            {{ $t('Cancel') }}
          </HawkButton>
          <HawkButton
            color="primary"
            :loading="state.loading"
            :disabled="!state.selected_items.length"
            @click="handleSave"
          >
            {{ $t('Save') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
