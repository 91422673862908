<script setup>
import { isEqual } from 'lodash-es';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { setNumberDecimals } from '~/dashboard/print/utilities.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const $services = inject('$services');

const common_store = useCommonStore();
const dashboard_store = useDashboardStore();

const progress_data = ref({
  ratio: 0,
  actual: 0,
  planned: 0,
});

const is_loading = ref(false);

const progress_percent = computed(() => {
  return Number.parseInt(progress_data.value.ratio * 100);
});

const widget_data = computed(() => {
  return props.data.data;
});

const prefix_suffix = computed(() => {
  const field_uid = widget_data.value?.field.uid;
  const field_config = common_store.assets_custom_fields_map[field_uid];
  return {
    prefix: field_config?.properties?.prefix || null,
    suffix: field_config?.properties?.suffix || null,
  };
});

async function getReports() {
  is_loading.value = true;
  try {
    const { data } = await $services.dashboard.get_reports({ body: widget_data.value });
    progress_data.value = data;
    is_loading.value = false;
  }
  catch (err) {
    is_loading.value = false;
  }
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: widget_data.value.name,
    renderType: 'progress-card',
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      configuration_name: null,
      type: null,
      progress_data: { ...progress_data.value, label: widget_data.value.field.label },
      prefix_suffix: prefix_suffix.value,
      dashboard_index: props.data.i,
    },
  });
}

watch(widget_data, (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val))
    setTimeout(async () => {
      await getReports();
      if (props.id !== 'preview')
        updatePrintMap();
    }, 250);
}, { immediate: true }, { deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="is_loading" class="!m-2" />
    <div v-else class="px-3">
      <div class="text-right text-sm">
        {{ setNumberDecimals(progress_data.actual || 0, 2) }} / {{ setNumberDecimals(progress_data.planned || 0, 2) }}
      </div>
      <div class="flex items-baseline">
        <p class="text-md font-medium text-gray-600 mr-4">
          {{ progress_percent }}%
        </p>
        <div class="w-full bg-gray-100 h-[10px] rounded-lg">
          <div
            class="bg-primary-500 h-full rounded-lg"
            :style="{
              width:
                `${progress_percent}%`,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
