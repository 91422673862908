<script>
import { ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'SignatureElement',
}, {
  setup(props, { element }) {
    const defaultClasses = ref({
      container: '',
      signature: '',
      $signature: classes => ([
        classes.signature,
      ]),
    });

    const signature = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = value;
      },
    });
    const is_disabled = computed(() => element.isDisabled.value);
    return {
      defaultClasses,
      signature,
      is_disabled,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <hawk-signature v-model="signature" :form$="form$" :class="{ 'pointer-events-none': is_disabled }" />
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
