<script setup>
import { useRoute } from 'vue-router';

// other
import { useModal } from 'vue-final-modal';

// stores
import { useDocumentStore } from '~/dms/store/document.store';

// composables
import { useDocumentPermissions } from '~/dms/composables/document-permissions.composable';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

// components
import DocumentVersions from '~/dms/components/documents/versions/document-versions.vue';
import DocumentVersionForm from '~/dms/components/documents/forms/document-version-form.vue';

const props = defineProps({
  file: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');
const $toast = inject('$toast');

const route = useRoute();

const document_permissions = useDocumentPermissions();

const document_store = useDocumentStore();
const document_crud = useDocumentCrud();

const file_details = computed(() => document_store.active_item_details);

const version_modal = useModal({
  component: DocumentVersionForm,
});

function openVersionModal() {
  version_modal.patchOptions({
    attrs: {
      onClose() {
        version_modal.close();
      },
      on_submit: data => document_crud.updateVersion(data, props.file),
      item: props.file,
    },
  });
  version_modal.open();
}
</script>

<template>
  <hawk-modal-container>
    <div class="col-span-12">
      <hawk-modal-header @close="emit('close')">
        <template #title>
          <div class="flex items-center">
            <div class="flex flex-col justify-start">
              {{ $t('Versions') }}
            </div>
          </div>
        </template>
      </hawk-modal-header>
      <!-- Body -->
      <hawk-modal-content>
        <hawk-button v-if="document_permissions.checkDocumentPermission({ permission: 'versions', item: props.file })" type="plain" class="mb-4" @click="openVersionModal">
          <icon-ic-outline-plus class="text-lg" />
          {{ $t('Upload New Version') }}
        </hawk-button>
        <DocumentVersions
          :versions="file_details?.versions || []"
          :file="{ ...file, defaultversion: file_details?.defaultversion }"
          :is_popup="true"
        />
      </hawk-modal-content>
    </div>
  </hawk-modal-container>
</template>
