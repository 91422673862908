<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { groupBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import HawkFieldsSelector from '~/common/components/organisms/hawk-fields-selector/hawk-fields-selector.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  on_register_items: {
    type: Function,
    default: () => {},
  },
  warehouse_details: {
    type: Object,
    default: () => ({}),
  },
});
const { $t, $services, route, common_store } = useCommonImports();
const inventory_store = useInventoryStore();

const state = reactive({
  items: [],
  selected: [],
  is_loading_items: false,
  scope: 'asset',
});

const items_by_category = computed(() => {
  const grouped_items = groupBy(state.items, 'category');
  return Object.keys(grouped_items).map((category_uid) => {
    return {
      uid: category_uid,
      name: inventory_store.item_types_map[category_uid]?.name || 'No category',
      children: grouped_items[category_uid],
    };
  });
});

async function getItems() {
  try {
    state.is_loading_items = true;
    let payload = {};
    if (state.scope === 'asset')
      payload = { query: { asset: false } };

    const { data } = await $services.inventory_items.getAll(payload);
    state.items = data.items;
    state.is_loading_items = false;
  }
  catch (errors) {
    logger.log(errors);
    state.is_loading_items = false;
  }
}
async function setSelectedItems() {
  state.selected = state.items.filter(item => item.assets[route.params.asset_id]).map((item) => {
    return {
      ...item,
      disabled: !item.can_delete,
    };
  });
}

async function registerItems(updated_items) {
  if (!route.params.asset_id)
    return;
  const body = { set: updated_items.map(item => item.uid) };
  await $services.inventory_items.register_items_in_asset({
    asset_id: route.params.asset_id,
    body,
    toast: false,
  });
  props.on_register_items();
}
const { open: openItemUnRegistrationConfirmationHandler, close: closeItemUnRegistrationConfirmationHandler, patchOptions: patchItemUnRegistrationConfirmationHandler } = useModal({
  component: HawkDeletePopup,
});

function itemUnRegistrationConfirmationHandler(items, confirm_callback) {
  patchItemUnRegistrationConfirmationHandler(
    {
      attrs: {
        header: $t('Unregister item'),
        header_icon: IconHawkDashboardPreviewWarning,
        button_text: 'Unregister',
        button_color: 'primary',
        content: `Are you sure you want to unregister ${items.map(item => item.name).join(', ')} from ${common_store?.active_asset?.name}.`,
        modalOptions: {
          escToClose: false,
          clickToClose: false,
        },
        onClose() {
          confirm_callback(false);
          closeItemUnRegistrationConfirmationHandler();
        },
        confirm: () => {
          confirm_callback(true);
          closeItemUnRegistrationConfirmationHandler();
        },
      },
    },
  );
  openItemUnRegistrationConfirmationHandler();
}

onMounted(async () => {
  await getItems();
  setSelectedItems();
});
</script>

<template>
  <HawkFieldsSelector
    :items="items_by_category"
    :selected_items="state.selected"
    :texts="{
      heading: $t('Register items'),
      left_heading: state.scope === 'asset' ? $t('Master item list') : $t('Asset item list'),
      right_heading: state.scope === 'asset' ? $t('Asset item list') : $t('Warehouse item list'),
    }"
    :is_loading_items="state.is_loading_items"
    :max_selected_items="Number.MAX_SAFE_INTEGER"
    :is-left-move-confirmed="itemUnRegistrationConfirmationHandler"
    :close_on_save="true"
    :update="registerItems"
    :hidden_features="['arrow-up', 'arrow-down']"
  >
    <template #right_item="{ label, is_selected, item }">
      <div
        class="py-2 pl-4 pr-3 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50 break-all group"
        :class="{ 'bg-gray-100 hover:!bg-gray-100 selected-available-field': is_selected }"
      >
        <div class="flex justify-between">
          <div class="flex items-center">
            <div :class="!item.can_delete ? 'opacity-40' : ''">
              {{ label }}
            </div>
            <IconHawkInfoCircle
              v-if="!item.can_delete"
              v-tippy="'Items used in transactions can not be unregistered'"
              class="w-4 h-4 ml-2"
            />
          </div>
        </div>
      </div>
    </template>
  </HawkFieldsSelector>
</template>
