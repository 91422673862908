<script>
import DOMPurify from 'dompurify';
import { useCommonStore } from '~/common/stores/common.store.js';

import { $date } from '~/common/utils/date.util';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';

export default {
  props: ['el$'],
  setup() {
    const form_template_detail_store = useFormTemplateDetailStore();

    return {
      form_template_detail_store,
    };
  },
  computed: {
    form_template_detail() {
      return this.form_template_detail_store?.form_template_detail || {};
    },
  },
  methods: {
    isFromField(str) {
      const pattern = /field_\d+/;
      return pattern.test(str);
    },
    generatePreview(data, form$) {
      const html = data?.html || data || '';
      const schemeArray = this.getSchemeArray(DOMPurify.sanitize(html) || '');
      let str = ' ';
      if (schemeArray.length) {
        schemeArray.forEach((item) => {
          const name = item.id && this.isFromField(item.id) ? item : item.label;
          str += `${this.previewFormat(name, form$, item)}`;
        });
        return str;
      }
      return '';
    },
    getSchemeArray(content) {
      const div = document.createElement('div');
      div.innerHTML = content;
      const scheme_arr = [];
      const nodes = div?.children[0]?.childNodes || [];
      Array.from(nodes).forEach((node) => {
        scheme_arr.push({
          label: node.textContent,
          id: node.getAttribute ? node.getAttribute('data-id') : null,
        });
      });
      div.remove();
      return scheme_arr;
    },
    previewFormat(name, form$, item) {
      if (typeof (name) !== 'string')
        return name.label;

      const currentDate = new Date();
      const common_store = useCommonStore();
      const element = this.form_template_detail;
      switch (item?.id) {
        case 'day':
          return currentDate.getDate();
        case 'month_number':
          return new Date().getMonth() + 1;
        case 'month_name_short':
          return currentDate.toLocaleString('en-us', { month: 'short' });
        case 'month_name_full':
          return currentDate.toLocaleString('en-us', { month: 'long' });
        case 'year_short':
          return currentDate
            .getFullYear()
            .toString()
            .slice(-2);
        case 'year_full':
          return currentDate.getFullYear().toString();
        case 'name':
          return element?.name;
        case 'category':
          return common_store.get_category(element?.category)?.name || 'Construction';
        case 'due_date': {
          const due_date = element.step_due_date || element.due_date || currentDate;
          return $date(due_date, 'DD-MM-YYYY');
        }
        case 'created_by':
        case 'submitted_by': {
          const user_details = common_store.get_user(element.owner) || common_store?.internal_users_map?.[element.owner] || { email: 'John' };
          return user_details.first_name ? `${user_details.first_name} ${user_details.last_name}` : user_details.email;
        }
        case 'created_at':
        case 'submitted_at': {
          const created_at = element.created_at || currentDate;
          return $date(created_at, 'DD-MM-YYYY HH:mm');
        }
        case 'issue_purpose':
          return 'As built';
        case 'number':
        case 'Number':
          return form$.el$('auto_number_data.next_number')?.value || 1;
        default:
          return name;
      }
    },
  },
};
</script>

<template>
  <div>
    {{ generatePreview(el$.form$.el$('auto_number_data.html').value, el$.form$) }}
  </div>
</template>
