<script setup>
import { groupBy, isEmpty, omit } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';
import { useDocumentStore } from '~/dms/store/document.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import DmsDocumentsRequestForm from '~/dms/components/documents/forms/dms-documents-request-form.vue';
import TransmittalDetailReplyPopup from '~/dms/components/transmittals/transmittal-detail/transmittal-detail-reply-popup.vue';
import DmsTransmitForm from '~/dms/components/documents/forms/dms-transmit-form.vue';

const { router, route, $t } = useCommonImports();

const transmittal_store = useTransmittalsStore();
const document_store = useDocumentStore();
const dms_settings_store = useDMSSettingsStore();
const dms_documents_settings_store = useDMSSettingsStore('documents');

const state = reactive({
  isContextOpen: false,
  file_to_show: '',
  is_fetching_detail: false,
  is_loading: false,
  document_details: {},
  selected_row_uid: null,
  sidebar_key: 1,
});

const form$ = ref(null);

const document_request_modal = useModal({
  component: DmsDocumentsRequestForm,
  attrs: {
    onClosed() {
      const attrs = omit(document_request_modal.options.attrs, ['is_cancel', 'content', 'header', 'sub_header', 'header_icon', 'button_text', 'is_mark_as_submitted']); // reset attrs
      document_request_modal.options.attrs = attrs;
    },
  },
});

const reply_modal = useModal({
  component: TransmittalDetailReplyPopup,
  attrs: {
    onClosed() {
      const attrs = omit(reply_modal.options.attrs, ['content', 'header', 'sub_header', 'header_icon', 'button_text']);
      reply_modal.options.attrs = attrs;
    },
  },
});

const transmit_modal = useModal({
  component: DmsTransmitForm,
  attrs: {
    onClosed() {
      const attrs = omit(transmit_modal.options.attrs, ['content', 'header', 'sub_header', 'header_icon', 'button_text']);
      transmit_modal.options.attrs = attrs;
    },
  },
});

function openCompleteForm() {
  document_request_modal.patchOptions({
    attrs: {
      header: $t('Close Transmittal'),
      button_text: $t('Close'),
      confirm: async (data) => {
        await markAsComplete(data.comment);
      },
      onClose() {
        document_request_modal.close();
      },
    },
  });
  document_request_modal.open();
}

function onRowClick(row_data) {
  state.selected_row_uid = row_data?.uid;
  openSidebar();
}

function openSidebar() {
  state.document_details = (transmittal_store.transmittal?.documents || []).find(doc => doc.uid === state.selected_row_uid);
  if (state?.document_details?.unread)
    state.document_details.unread = false;
}

const transmittal = computed(() => {
  if (!isEmpty(transmittal_store.transmittal)) {
    const { members } = transmittal_store.transmittal;
    const grouped_members = groupBy(members, 'role');
    return {
      ...transmittal_store.transmittal,
      assignees: grouped_members?.submitter || [],
      reviewers: grouped_members?.approver || [],
    };
  }
  return {};
});

const uploaded_files = computed(() => Object.values(form$.value?.data?.documents || {})?.map?.(doc => doc.file) || []);
const has_drafts = computed(() => !state.is_loading && uploaded_files.value.filter(file => file?.service_object)?.length > 0);

onMounted(() => {
  getData();
});

watch(() => route.params.asset_id, (val) => {
  if (val)
    getData();
});

watch(() => route.params.transmittal_uid, (val) => {
  if (val)
    getData();
});

async function getData() {
  try {
    state.is_fetching_detail = true;
    const promise1 = transmittal_store.set_public_transmittal({ transmittal_uid: route.params.transmittal_uid || props.transmittal_uid, query: { payload: route.query.payload } });
    const promise2 = dms_settings_store.set_custom_fields_public({ query: { allowed_in: 'transmittals', payload: route.query.payload } });
    await Promise.all([promise1, promise2]);
    state.is_fetching_detail = false;
  }
  catch (err) {
    state.is_fetching_detail = false;
  }
}

function onAttachmentClick(item) {
  state.file_to_show = item;
}

async function documentDownload({ file }) {
  const response = await document_store.get_download_url({
    body: {
      files: [{
        uid: file.uid,
      }],
    },
  });
  const url = response.data.urls[0].url;
  window.open(url, '_blank');
}

const is_saving = ref(false);

async function transmitTransmittal({ description, supporting_documents, otp }) {
  try {
    state.is_loading = true;
    const { documents = {} } = form$.value.data;
    delete documents.three_state_checkbox;
    const filtered_documents = transmittal.value.documents?.filter(document => !document.cancelled && document.actions.can_submit);
    const payload = filtered_documents.map((document) => {
      const { status, comments } = document?.draft || {};
      const attachment = documents?.[document.uid]?.file;
      return {
        uid: document.uid,
        ...(status ? { status } : {}),
        comments: documents?.[document.uid]?.comments || comments || '',
        service_object: attachment?.service_object,
        name: document?.name,
        file_name: attachment?.name,
        type: attachment?.type,
        size: attachment?.size,
      };
    });
    await transmittal_store.transmit_public_transmittal({
      transmittal_uid: transmittal.value.uid,
      payload: route.query.payload,
      transmittals: {
        documents: payload,
        otp,
        message: {
          body: description,
          attachments: supporting_documents,
        },
      },
    });
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

async function openTransmitModal() {
  try {
    transmit_modal.patchOptions({
      attrs: {
        header: $t('Transmit'),
        button_text: $t('Transmit'),
        is_public_page: true,
        confirm: async (data) => {
          await transmitTransmittal(data || {});
          transmit_modal.close();
        },
        onClose() {
          transmit_modal.close();
        },
      },
    });
    transmit_modal.open();
  }
  catch (err) {
    logger.error(err);
  }
}

function openReplyModal() {
  try {
    reply_modal.patchOptions({
      attrs: {
        header: $t('Reply'),
        button_text: $t('Send'),
        transmittal: transmittal.value,
        submit: async (form_data, { requestData }) => {
          await transmittal_store.transmittal_send_message({
            transmittal_uid: route.params.transmittal_uid,
            payload: requestData,
          });
          reply_modal.close();
        },
        onClose() {
          reply_modal.close();
        },
      },
    });
    reply_modal.open();
  }
  catch (err) {
    logger.error(err);
  }
}

function onFormMounted(el$) {
  if (transmittal.value) {
    const { documents } = transmittal.value;
    if (!isEmpty(documents))
      documents.forEach((doc) => {
        el$.data.documents[doc.uid] = doc;
      });
  }
}

function onFormUpdated(event) {
  if (event.type === 'upload_started')
    state.is_loading = true;
  else if (event.type === 'upload_completed')
    state.is_loading = false;
}
</script>

<template>
  <div v-if="state.is_fetching_detail" class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
    <HawkLoader container_class="m-0 " />
  </div>
  <div v-else-if="!transmittal?.uid">
    <hawk-illustrations type="no-permission" />
  </div>
  <Vueform
    v-else
    ref="form$"
    size="sm"
    class="p-4"
    @mounted="onFormMounted"
    @updated="onFormUpdated"
  >
    <transmittal-detail :is_public_page="true" :transmittal="transmittal" :is_detail_page="true" :read_only="read_only" class="mb-5" />
    <ObjectElement
      :key="transmittal?.documents"
      name="documents"
      :columns="{
        default: { container: 12, wrapper: 12, label: 12 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 12, label: 12, wrapper: 12 },
      }"
    >
      <transmittal-detail-document
        :form$="form$"
        :read_only="read_only"
        :options="{
          is_date_disabled: true,
          can_add_documents: false,
        }"
        :is_public_page="true"
        :transmittal="transmittal"
        :visible_columns="
          ['name', 'versions', 'due_date', 'comments_in', 'comments_out', 'status', 'public_uploads']"
        :context_download="documentDownload"
        :file_to_show="state.file_to_show"
        @closeAttachment="state.file_to_show = ''"
        @rowClick="onRowClick"
      />
    </ObjectElement>
    <div v-if="transmittal?.actions?.can_transmit" class="border-t  col-span-12 -mx-4 px-4 mt-5 pt-4">
      <div class="flex justify-between">
        <div />
        <div class="flex justify-end">
          <hawk-button
            :loading="state.is_loading"
            :disabled="state.is_loading || !has_drafts"
            @click="openTransmitModal"
          >
            {{ $t('Send') }}
          </hawk-button>
        </div>
      </div>
    </div>
  </Vueform>
</template>
