<script setup>
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';

const props = defineProps({
  is_popup: {
    type: Boolean,
    default: false,
  },
  previous_step_number: {
    type: Number,
    default: -1,
  },
  step_number: {
    type: Number,
    default: -1,
  },
});

const form_template_detail_store = useFormTemplateDetailStore();
const auth_store = useAuthStore();

const draggable_component = defineAsyncComponent(() => import('vuedraggable'));
const draggable = shallowRef(draggable_component);

if (props.is_popup)
  provide('form_template_detail_store', form_template_detail_store);
const has_edit_access = computed(() => form_template_detail_store.form_template_detail.status === 'published' || !form_template_detail_store?.form_template_detail?.can_modify_template);

const selected_field = ref(null);
const selected_section = ref(null);
const sections = ref(null);
const right_pane = ref(null);
const templates = ref([]);
const terra_containers = ref([]);

const is_sticky = ref(false);
const $services = inject('$services');
provide('terra-containers', terra_containers);

onMounted (async () => {
  const { data } = await $services.forms.getAll({
    attribute: 'fields',
    query: {
      isTemplate: true,
    },
  });
  templates.value = data?.fields;
  if (!props.is_popup)
    addStickyHeader();
});
function addStickyHeader() {
  const sibling = document.querySelector('.form-builder-header-sibling');

  const observer = new IntersectionObserver(
    ([e]) => { is_sticky.value = e.intersectionRatio < 1; },
    { threshold: 0.1, rootMargin: '-40px' },
  );
  observer.observe(sibling);
}

function removeDeletedOptions(option) {
  if (Array.isArray(option?.field?.config)) {
    const config = option.field.config.filter(config => !config?.deleted);
    option.field.config = config;
    return option;
  }
  return option;
}

async function fetchTerraContainers() {
  const { data } = await $services.terra_view_service.getAll({
    query: {
      asset: form_template_detail_store.form_template_detail?.target_element?.asset,
      detail: true,
    },
  });
  terra_containers.value = data.map(container => ({
    ...container,
    children: container.groups.map(group => ({
      ...group,
      children: group.projects,
    })),
  }));
}
watch(
  selected_field,
  (field_obj) => {
    const is_asset_scoped_template = !!form_template_detail_store.form_template_detail?.target_element?.asset;
    if (auth_store.check_split('multi_text_field_config') && is_asset_scoped_template && field_obj && field_obj.field.type === 'multi_text')
      fetchTerraContainers();
  },
);
</script>

<template>
  <div class="form-builder">
    <!-- Header -->
    <div class="form-builder-header-sibling" />
    <FormBuilderHeader v-if="!is_popup" :is_sticky="is_sticky" class="sticky top-12 bg-white z-[18] -mx-4 px-4 py-4" :class="is_sticky ? 'shadow-md pb-2 pt-6' : 'py-4'" />
    <!-- Builder -->
    <div class="grid gap-4" :style="has_edit_access ? 'pointer-events:none;' : 'grid-template-columns:1fr 23rem;'">
      <!-- Left -->
      <FormBuilderSections
        v-if="form_template_detail_store.form_template_detail.sections.length > 0"
        ref="sections"
        :selected_field="selected_field"
        :selected_section="selected_section"
        :draggable="draggable"
        @select_field="selected_field = removeDeletedOptions($event);selected_section = null;"
        @select_section="selected_section = $event;selected_field = null;"
      />
      <hawk-loader v-else />
      <!-- Right -->
      <div v-if="!has_edit_access" @click.stop @keydown.esc="() => { selected_field = null;selected_section = null }">
        <div ref="right_pane" class="sticky" :style="`top:${props.is_popup ? '0' : '8.3'}rem;`">
          <FormBuilderFieldConfiguration v-if="selected_field" :step_number="step_number" :previous_step_number="previous_step_number" :options="selected_field" @close="selected_field = null" />
          <FormBuilderSectionConfiguration v-else-if="selected_section" :section="selected_section" @close="selected_section = null" />
          <FormBuilderDraggableFields v-else :templates="templates" />
        </div>
      </div>
    </div>
  </div>
</template>
