<script setup>
const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const DASHBOARD_VERB_MAP = {
  CREATED: 'created the dashboard',
  UPDATED: 'updated the dashboard',
  DELETED: 'deleted the dashboard',
  EXPORTED: 'exported the dashboard',
};
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="Object.keys(DASHBOARD_VERB_MAP).includes(activity.verb)" class="text-sm">
      {{ DASHBOARD_VERB_MAP[activity.verb] }}
    </div>
  </div>
</template>
