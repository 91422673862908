<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store';
import { sortData } from '~/common/utils/common.utils';
import HawkAvatar from '~/common/components/organisms/hawk-members-badge/hawk-avatar.vue';

const props = defineProps({
  options: {
    type: Object,
    default: () => {},
  },
  default_asset: {
    type: String,
  },
  multi: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['select']);

const form_values = ref({});
const form$ = ref(null);
const asset = ref(null);
const search = ref('');
const route = useRoute();
const common_store = useCommonStore();

const sorted_assets_list = computed(() => {
  return sortData(common_store?.assets, 'name', ['asc'], true);
});

onMounted(() => {
  if (props.default_asset) {
    asset.value = props.default_asset;
    form$.value.load(
      {
        asset: props.default_asset,
      },
      true,
    );
  }
});

function setAsset(e) {
  asset.value = e;
  emit('select', e);
}

const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];
function stringToNumber(string) {
  let total = 0;
  for (const char of string)
    total += char.charCodeAt(0);

  return color_set[total % color_set.length];
}
</script>

<template>
  <Vueform
    v-if="route.params.asset_id"
    ref="form$"
    v-model="form_values"
    size="sm"
    class="w-full md:w-[250px]"
  >
    <v-tags-input
      v-if="multi"
      :options="{
        'autocomplete': 'off',
        'track-by': 'name',
        'label-prop': 'name',
        'value-prop': 'uid',
        'search': true,
        'name': 'asset',
        'can-clear': true,
        'placeholder': 'Search Asset',
        'items': sorted_assets_list,
        ...options,
      }"
    />
    <SelectElement
      v-else
      name="asset"
      autocomplete="off"
      :native="false"
      track-by="name"
      label-prop="name"
      value-prop="uid"
      :search="true"
      :hide-selected="false"
      :items="sorted_assets_list"
      :default="default_asset"
      placeholder="Search Asset"
      :can-clear="false"
      :add-classes="{
        SelectElement: {
          select: {
            container: '!bg-gray-700 !border-white !text-white focused:!border-white',
            caret: '!bg-white',
            search: '!text-white !pl-9',
          },
        },
      }"
      @select="setAsset"
      @search-change="search = $event"
    >
      <template #placeholder>
        <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
          <IconHawkSearchMd class="h-4 w-4 text-gray-300 mr-1.5" aria-hidden="true" />
          <p class="text-gray-300">
            {{ options?.placeholder ? options?.placeholder : $t('Search Asset') }}
          </p>
        </div>
      </template>
      <template #before>
        <div v-if="search?.length" class="relative">
          <div class="absolute z-[1] top-2.5 left-3">
            <IconHawkSearchMd class="text-white h-4 w-4" />
          </div>
        </div>
      </template>
      <template #single-label="{ value }">
        <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
          <IconHawkLayersTwoTwo class="mr-2.5 h-4 w-4" />
          <p
            v-tippy="value.name"
            class="truncate max-w-[175px]"
          >
            {{ value.name }}
          </p>
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex justify-between w-full">
          <div class="flex items-center">
            <HawkAvatar
              class="mr-2"
              :img_src="option?.cover_image?.url"
              :src="option?.cover_image?.url"
              :name="option?.name"
              :background_color="stringToNumber(option?.name)"
            />
            <p
              v-tippy="option.name"
              class="font-medium truncate max-w-[175px]"
            >
              {{ option.name }}
            </p>
          </div>
          <div class="flex items-center">
            <IconHawkCheck v-if="option.uid === asset" class="text-blue-500 h-4 w-4" />
          </div>
        </div>
      </template>
    </SelectElement>
  </Vueform>
</template>

<style lang="scss" scoped>
  :deep(.form-bg-primary) {
    background-color: #F9FAFB;
  }
  :deep(.form-color-on-primary) {
    color: black;
  }
</style>
