import { acceptHMRUpdate, defineStore } from 'pinia';
import { flatMap, groupBy, keyBy, sortBy } from 'lodash-es';
import { PLANS_PERMISSION } from '../constants';
import { useSheetStore } from './sheet.store';
import { useAuthStore } from '~/auth/stores/auth.store';

export const usePlansStore = defineStore('plans', {
  state: () => ({
    drawings_map: {},
    sheets_map: {},
    active_drawing_uid: null,
    is_upload_flow: false,
    uploaded_document_arr: [], // local browser upload
    all_drawings: {}, // contains all drawings which does not have sheets and elements as well
  }),
  getters: {
    get_drawings(state) {
      const filtered_drawings = Object.values(state.drawings_map).filter(drawing => drawing.sheets?.length);
      return sortBy(filtered_drawings, drawing_ob => drawing_ob.name.toLowerCase());
    },
    get_active_drawing(state) {
      return state.drawings_map[state.active_drawing_uid];
    },
    get_active_drawing_sheets(state) {
      return sortBy(state.drawings_map[state.active_drawing_uid]?.sheets, sheet_obj => sheet_obj.name.toLowerCase());
    },
    get_all_sheets(state) {
      // Contains all sheets with respective drawing_uid and drawing_name in it
      return flatMap(state.drawings_map, (value, key) => {
        const drawing_sheets = value.sheets ?? [];
        const sheets_with_drawing_uid = drawing_sheets.map(sheet => ({ ...sheet, drawing_uid: key, drawing_name: state.drawings_map[key].name }));
        return sheets_with_drawing_uid;
      });
    },
    get_all_drawing_names(state) {
      return Object.values(state.all_drawings).map(drawing => ({ name: drawing.name, uid: drawing.uid }));
    },
    get_uploaded_document_bucket(state) {
      return groupBy(state.uploaded_document_arr, doc => doc.assigned_drawing ?? 'unassigned');
    },
    get_searched_sheets(state) {
      return (search_keyword) => {
        const drawings = Object.values(state.drawings_map);
        const filtered_drawings = drawings.map((drawing) => {
          const drawing_sheets = drawing.sheets ?? [];
          const filtered_sheets = drawing_sheets.filter(sheet => sheet.name.toLowerCase().includes(search_keyword.toLowerCase()));
          return ({
            ...drawing,
            sheets: filtered_sheets,
          });
        }).filter(drawing => drawing.sheets.length);

        return keyBy(filtered_drawings, 'uid');
      };
    },
  },
  actions: {
    async fetch_drawings() {
      const query = {
        sheets: true,
        element: true,
        asset: this.$router.currentRoute.value.params.asset_id,
      };

      try {
        const { data } = await this.$services.plans.getAll({ query });

        const auth_store = useAuthStore(); // Move this to top scope if more actions are using it
        const has_view_plan_role = auth_store.check_permission(PLANS_PERMISSION.VIEW_PLANS, this.$router.currentRoute.value.params.asset_id);
        const has_view_private_plan_role = auth_store.check_permission(PLANS_PERMISSION.VIEW_PRIVATE_PLANS, this.$router.currentRoute.value.params.asset_id);

        if (!has_view_plan_role && has_view_private_plan_role) {
          data.drawings.forEach((drawing) => {
            const private_sheets = drawing.sheets.filter(sheet => sheet.private);
            drawing.sheets = private_sheets;
          });
          data.drawings = data.drawings.filter(drawing => drawing.sheets.length);
        }

        this.drawings_map = keyBy(data.drawings, 'uid');
        const active_drwaing_uid = this.$router.currentRoute.value.params.drawing_id ?? sortBy(data.drawings, drawing_ob => drawing_ob.name.toLowerCase())[0]?.uid;
        active_drwaing_uid && this.set_active_drawing_uid(active_drwaing_uid);
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    set_active_drawing_uid(uid) {
      this.active_drawing_uid = uid;
      this.sheets_map = keyBy(this.drawings_map[uid].sheets, 'uid');
    },
    async patch_calibration_to_drawing(calibration) {
      try {
        const body = {
          drawings: {
            uid: this.$router.currentRoute.value.params.drawing_id,
            properties: {
              ...this.drawings_map[this.active_drawing_uid].properties,
              ...calibration,
            },
          },
        };

        const { data } = await this.$services.plans.patch({
          body,
        });

        this.drawings_map[this.active_drawing_uid].properties = data.drawings[0].properties;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async fetch_all_drawings() {
      const query = {
        asset: this.$router.currentRoute.value.params.asset_id,
      };

      try {
        const { data } = await this.$services.plans.getAll({ query });

        this.all_drawings = keyBy(data.drawings, 'uid');
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async create_drawing(payload) {
      const body = { ...payload };

      try {
        const { data } = await this.$services.plans.post({ body });

        this.drawings_map[data.drawing.uid] = data.drawing;
        this.all_drawings[data.drawing.uid] = data.drawing;

        return data.drawing.uid;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async patch_new_sheet_version(payload, drawing_uid, options = {}) {
      const body = { ...payload };

      try {
        const { data } = await this.$services.plans.patch({
          id: drawing_uid,
          attribute: 'sheets',
          body,
        });

        if (options.sheet_uid) {
          const sheet_store = useSheetStore();
          const old_sheet_ver_data = this.sheets_map[options.sheet_uid];

          this.sheets_map[options.sheet_uid] = data.sheets[0];
          sheet_store.update_active_sheet(options.sheet_uid, this.sheets_map);

          if (options.default_version) {
            sheet_store.update_selected_sheet_version(this.sheets_map[options.sheet_uid].current_version);

            // Update the drawings_map[id].sheets as well with updated sheet version
            const sheet_index = (this.drawings_map[drawing_uid]?.sheets || []).findIndex(sheet => sheet.uid === options.sheet_uid);
            if (sheet_index !== -1 && this.drawings_map[drawing_uid]?.sheets) {
              this.drawings_map[drawing_uid].sheets[sheet_index] = data.sheets[0];
              this.drawings_map[drawing_uid].sheets[sheet_index].element = old_sheet_ver_data.element;
              this.drawings_map[drawing_uid].sheets[sheet_index].current_version.thumbnails.xsmall = options.thumbnail;
            }
          }
        }

        this.$toast({
          title: 'New sheet version added successfully',
          text: `${data.sheets.length} sheets have updated successfully with new version`,
          type: 'success',
        });
      }
      catch (error) {
        logger.error(error);
        this.$toast({
          title: 'New version could not be added',
          text: error.data.description,
          type: 'error',
        });
        return error;
      }
    },
    async upload_sheet_to_drawing(payload, drawing_uid) {
      const body = { ...payload };

      try {
        const { data } = await this.$services.plans.post({
          id: drawing_uid,
          attribute: 'sheets',
          body,
        });

        if (this.drawings_map[drawing_uid].sheets)
          this.drawings_map[drawing_uid].sheets.push(...data.sheets);
        else
          this.drawings_map[drawing_uid].sheets = data.sheets;

        this.set_active_drawing_uid(drawing_uid);

        this.$toast({
          title: 'Sheets added successfully',
          text: `${data.sheets.length} sheets have added successfully to the drawing.`,
          type: 'success',
        });
      }
      catch (error) {
        logger.error(error);

        error.data?.description && this.$toast({
          title: 'Could not be added',
          text: error.data.description,
          type: 'error',
        });
        return error;
      }
    },
    async assign_sheets_to_drawing(sheet_uids, drawing_uid) {
      const body = {
        sheets: sheet_uids.map(sheet_uid => ({ drawing: drawing_uid, uid: sheet_uid })),
      };

      try {
        const { data } = await this.$services.plans.patch({
          id: drawing_uid,
          attribute: 'sheets',
          body,
        });

        return data.sheets;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async delete_drawing(drawing_uid) {
      try {
        await this.$services.plans.delete({ id: drawing_uid });

        delete this.drawings_map[drawing_uid];
        this.get_drawings[0]?.uid && this.set_active_drawing_uid(this.get_drawings[0].uid);

        this.$toast({
          title: 'Drawing deleted successfully',
          text: '',
          type: 'success',
        });
      }
      catch (error) {
        logger.error(error);
        this.$toast({
          title: 'Something went wrong',
          text: 'Drawing could not be deleted',
          type: 'error',
        });
        return error;
      }
    },
    async rename_drawing(drawing_uid, updated_name) {
      const body = {
        drawings: {
          uid: drawing_uid,
          name: updated_name,
        },
      };

      try {
        await this.$services.plans.patch({
          body,
        });

        this.drawings_map[drawing_uid].name = updated_name;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async delete_sheet(drawing_id, sheet_uid) {
      try {
        await this.$services.plans.delete({ id: drawing_id, attribute: `sheets/${sheet_uid}` });
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async edit_sheet_data(drawing_id, sheet_uids, payload, custom_body = null) {
      // Bulk update sheet data
      const body = {
        sheets: sheet_uids.map(sheet_uid => ({
          uid: sheet_uid,
          ...payload,
        })),
      };

      try {
        await this.$services.plans.patch({
          id: drawing_id,
          attribute: 'sheets',
          body: custom_body || body,
        });
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    assign_drawing_to_docs(selected_docs_uid, drawing_name) {
      this.uploaded_document_arr.forEach((doc) => {
        if (selected_docs_uid.includes(doc.doc_instance.id))
          doc.assigned_drawing = drawing_name;
      });

      // Remove duplicate files and files with no name while assigning to drawing
      const all_assigned_drawings = this.uploaded_document_arr.filter(
        (doc, index, self) => doc.assigned_drawing && doc.page_data.title_text.trim() && index === self.findIndex(obj => doc.page_data.title_text === obj.page_data.title_text),
      );
      const all_unassigned_drawings = this.uploaded_document_arr.filter(doc => !doc.assigned_drawing);
      this.uploaded_document_arr = [...all_unassigned_drawings, ...all_assigned_drawings];
    },
    update_duplicate_docs() {
      this.uploaded_document_arr.forEach((doc) => {
        const sheet_data = this.get_all_sheets.find(sheet => sheet.name === doc.page_data.title_text);
        if (sheet_data) {
          doc.assigned_drawing = sheet_data.drawing_name;
          doc.is_duplicate = true;
          doc.existing_sheet_uid = sheet_data.uid;
          doc.existing_sheet_name = sheet_data.name;
          doc.existing_drawing_uid = sheet_data.drawing_uid;
        }
        else {
          delete doc.is_duplicate;
          delete doc.existing_sheet_uid;
          delete doc.existing_sheet_name;
          delete doc.existing_drawing_uid;
        }
      });
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(usePlansStore, import.meta.hot));
