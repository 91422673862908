<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { reactive } from 'vue';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  asset_uid: {
    type: String,
  },
  resources: {
    type: Array,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['updateResources', 'validate']);

const $services = inject('$services');
const common_store = useCommonStore();

const state = reactive({
  options: [],
  is_loading: true,
});
async function getData() {
  state.options = [];
  state.is_loading = true;
  if (!common_store.asset_meta?.[props.asset_uid]?.resources) {
    const response = await $services.assets.post({
      attribute: 'tree-views',
      body: {
        assets: [props.asset_uid],
      },
    });

    if (!common_store.asset_meta?.[props.asset_uid])
      common_store.asset_meta[props.asset_uid] = {};
    common_store.asset_meta[props.asset_uid].resources = response.data?.assets[0];
  }
  state.options = common_store.asset_meta[props.asset_uid].resources?.containers || [];
  state.options = state.options.map((container) => {
    return {
      ...container,
      children: (container?.groups || []).map((group) => {
        return { ...group, children: group.projects || [] };
      }),
    };
  });
  setTimeout(() => {
    state.is_loading = false;
    emits('validate');
  });
}
onMounted(async () => {
  await getData();
});
</script>

<template>
  <div>
    <HawkLoader v-if="state.is_loading" container_class="justify-start" :width="6" :height="6" />
    <HawkTreeSelect
      v-else
      :options="{
        name: 'resources',
        placeholder: $t('Select'),
        object: true,
        loading: state.is_loading,
        disabled,
        rules: ['required'],
      }"
      label=""
      children_key="children"
      data_type="object"
      label_key="name"
      value_key="uid"
      :data="state.options || []"
      :initial_state="props.resources || []"
      select_type="BRANCH_PRIORITY"
      :option_text_length="30"
      @update-form="emits('updateResources', $event)"
    />
  </div>
</template>
