<script setup>
import { filter, orderBy } from 'lodash-es';
import { useTerraStore } from '~/terra/store/terra.store.js';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  user_access: {
    type: String,
    default: '',
  },
  selected_blocks: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['update']);
const $services = inject('$services');
const terra_store = useTerraStore();
const form_data = ref({
  data: [],
});
const state = reactive({
  projects_options: [],
});

const container = computed(() => terra_store.container);

async function getData() {
  try {
    state.is_loading = true;

    const { data } = await $services.terra_view_service.get({
      id: container.value.uid,
    });

    state.projects_options = [
      ...orderBy(
        filter(data.groups, p => p.properties?.date),
        [p => p.properties.date],
        ['desc'],
      ),
      ...orderBy(
        filter(data.groups, p => !p.properties?.date),
        [p => p.name],
        ['asc'],
      ),
    ];

    state.is_loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ file: terra-sharing-blocks.vue:32 ~ loading ~ error:', error);
    state.is_loading = false;
  }
}

async function updateProjects(data) {
  form_data.value.data = data;

  emit('update', data);
}

onMounted(() => {
  getData();
});
</script>

<template>
  <div v-show="user_access !== 'full'" class="mt-6">
    <HawkLoader v-if="state.is_loading" class="!m-0 !mb-4" />
    <Vueform
      v-else-if="state.projects_options.length"
      :key="state.projects_options.length + props.selected_blocks.length"
      v-model="form_data"
      size="sm"
      sync
      class="mb-4"
      :columns="{
        default: {
          container: 12,
          label: 12,
          wrapper: 12,
        },
        sm: { label: 12 },
        md: { label: 12 },
        lg: { label: 12 },
      }"
    >
      <HawkTreeSelect
        :options="{
          name: 'data',
          label: $t('Blocks'),
          placeholder: $t('Select blocks'),
        }"
        :initial_state="props.selected_blocks"
        :data="state.projects_options || []"
        children_key="projects"
        label_key="name"
        value_key="uid"
        select_type="BRANCH_PRIORITY"
        @updateForm="updateProjects($event)"
      />
    </Vueform>
  </div>
</template>
