export default function VueFormDnd() {
  return {
    apply: 'DragAndDrop',
    props: {
      // The options received by the FileElement are passed as it is to the DragAndDrop element.
      options: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
  };
}
