<script>
import { ref } from 'vue';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'DmsUploadElement',
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
}, {
  setup(props, { element }) {
    const defaultClasses = ref({
      container: '',
    });

    const upload_data = computed({
      get() {
        return element.model.value;
      },
      set(value) {
        element.model.value = value;
      },
    });
    const is_disabled = computed(() => element.isDisabled.value);

    function addFiles(files) {
      if (!upload_data.value)
        upload_data.value = [];
      upload_data.value = [...upload_data.value, ...files];
    }

    function deleteFile(file) {
      upload_data.value = upload_data.value.filter(upload_file => upload_file.file_name !== file.file_name);
    }

    return {
      defaultClasses,
      upload_data,
      is_disabled,
      addFiles,
      deleteFile,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <div class="grid gap-3">
        <hawk-attachments-list
          v-if="upload_data?.length"
          :items="upload_data"
          :can_delete="true"
          :can_download="$props.views?.can_download ?? true"
          :can_view="$props.views?.can_view ?? true"
          :filename_truncate_length="$props.views?.filename_truncate_length ?? 30"
          @delete="deleteFile"
        />
        <document-import-dropdown v-if="options?.single_file ? !upload_data?.length : true" :is_single_file="options?.single_file" :attachment_config="options?.attachment_config" @addFiles="addFiles" />
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
