<script setup>
import { flatMapDeep } from 'lodash-es';

const props = defineProps(['field', 'value']);
const emits = defineEmits(['update']);
const checklist = props.value || props.field?.config?.items;

function getChecklist(checklist) {
  if (!checklist?.children?.length)
    return checklist;

  return [checklist, flatMapDeep(checklist.children, getChecklist)];
}

function update(updated_checklist) {
  const flatten_checklist = flatMapDeep(updated_checklist, getChecklist);
  emits('update', flatten_checklist.map(checklist => ({ ...checklist, resolved: checklist.status === 'resolved', children: undefined })));
}
</script>

<template>
  <div>
    <div id="container" class="border-b border-gray-300 pb-3 last:border-b-0">
      <ol>
        <ChecklistItem
          class="my-2"
          :subitem_level="0"
          :items="checklist"
          :sortable="false"
          :dropdown_items="[]"
          :can_modify_status="true"
          @updateChecklist="update"
        />
      </ol>
    </div>
  </div>
</template>


