import sanitize from 'sanitize-s3-objectkey';
import DOMPurify from 'dompurify';

export default function VueFormMultiFile() {
  return {
    apply: 'MultifileElement',
    props: {
      rules: {
        default: 'no_blacklist_extensions',
      },
      use_uppy: {
        type: Boolean,
        required: false,
        default: true,
      },
      // The attributes that can go inside the options are:
      // 1. clickable_text: A highlighted text that opens up the file selector when clicked.
      // 2. text: Follows the clickable_text
      // 3. description
      // 4. inline (Boolean): denotes if the content is inline.
      options: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
    mounted() {
      if (this.use_uppy)
        this.on('add', (index, value) => {
          if (value) {
            const path_exist = value.webkitRelativePath || value?.meta?.webkitRelativePath;
            const existingFile = this.form$.uppy.getFiles().find(searchFile => searchFile.name === sanitize(value.name));
            if (existingFile && !path_exist) {
              this.data[this.name][index] = existingFile.data;
              this.update(this.data[this.name]);
              const existingFilesInData = Object.values(this.data[this.name])?.filter(file => file?.name === value.name);
              if (existingFilesInData?.length > 1)
                this.remove(index);
              return;
            }
            try {
              if (value?.meta?.webkitRelativePath)
                value.meta.webkitRelativePath = (value.webkitRelativePath || value?.meta?.webkitRelativePath) ? sanitize(value.webkitRelativePath || value.meta.webkitRelativePath) : undefined;

              const relative_path = (value.webkitRelativePath || value?.meta?.webkitRelativePath) ? sanitize(value.webkitRelativePath || value?.meta?.webkitRelativePath) : undefined;

              const id = this.form$.uppy.addFile({
                data: value,
                name: value?.name,
                type: value?.type,
                meta: {
                  relativePath: relative_path,
                },
              });
              const file = this.form$.uppy.getFile(id);
              file.name = DOMPurify.sanitize(sanitize(value.name));
              file.meta.name = file.name;
              value.sanitized_name = file.meta.name;
              if (this.options?.resource)
                file.meta.resource = this.options.resource;
              file.meta.webkitRelativePath = relative_path;
              value.uppy_id = id;
            }
            catch (error) {
              logger.info(`[DEBUG] vueform-multifile.js::57\n${error}`);
            }
          }
        });
    },
    beforeDestroy() {
      if (this.use_uppy)
        this.off('add');
    },
  };
}
