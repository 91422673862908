<script setup>
import { isEqual } from 'lodash-es';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { setNumberDecimals } from '~/dashboard/print/utilities.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const $services = inject('$services');

const dashboard_store = useDashboardStore();

const is_loading = ref(false);

const number_value = ref(0);

const widget_data = computed(() => props.data?.data);

async function getReports() {
  is_loading.value = true;
  try {
    const { data } = await $services.dashboard.get_reports({ body: props.data.data });
    number_value.value = setNumberDecimals(data.total || 0, 2);
    is_loading.value = false;
  }
  catch (err) {
    is_loading.value = false;
  }
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: widget_data.value.name,
    renderType: 'index-chart',
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      configuration_name: null,
      type: null,
      index: number_value.value,
      dashboard_index: props.data.i,
    },
  });
}

watch(widget_data, (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val))
    setTimeout(async () => {
      await getReports();
      if (props.id !== 'preview')
        updatePrintMap();
    }, 250);
}, { immediate: true }, { deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="is_loading" class="!m-1" />
    <div
      v-else
      class="px-3 text-lg font-semibold"
    >
      {{ number_value }}
    </div>
  </div>
</template>
