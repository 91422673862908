<script setup>
import { useModal } from 'vue-final-modal';
import FiltersPopupForms from '~/forms/components/form-filter.vue';
import IconFilter from '~icons/hawk/filter-icon';

const emit = defineEmits(['save']);
const state = reactive({
  search: '',
  filters: {},
});

const save = () => emit('save', { q: state.search ? state.search : undefined, ...(state.filters || {}) });
const { open: openFiltersPopup, close: closeFiltersPopup, patchOptions: patchFiltersPopup } = useModal({
  component: FiltersPopupForms,
  attrs: {
    onClose() {
      closeFiltersPopup();
    },
    onSave(e) {
      state.filters = e;
      save();
    },
  },
});

const filter_size = computed(() => Object.keys(state.filters || {}).length);

async function clearFilters() {
  state.filters = {};
  save();
}
</script>

<template>
  <div class="flex gap-3 flex-shrink-0">
    <HawkSearchInput
      v-model="state.search"
      :placeholder="$t('Search')" class="flex-shrink-0"
      @update:modelValue="save"
    />
    <hawk-button
      class="mr-4"
      type="outlined"
      @click="() => {
        patchFiltersPopup({ });
        openFiltersPopup()
      }"
    >
      <IconFilter class="text-gray-600 w-[15px] h-2.5" />
      <span class="text-sm text-[#667085]">{{ $t('filters') }}</span>
    </hawk-button>
    <p
      v-if="filter_size"
      class="flex items-center rounded-md p-2 text-sm font-semibold text-primary-700 bg-primary-50"
    >
      {{ `${filter_size} ${$t('Applied')}` }}
      <Icon-hawk-x-close class="cursor-pointer ml-2 " @click="clearFilters" />
    </p>
  </div>
</template>
