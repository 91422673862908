import { useInventoryStore } from '~/inventory/store/inventory.store.js';

export function useCustomFields() {
  const inventory_store = useInventoryStore();

  function get_custom_field(uid) {
    return inventory_store.get_custom_field(uid, true);
  }

  return {
    map_custom_fields(custom_fields_config, get_custom_fields, data) {
      if (!data) {
        custom_fields_config.value = get_custom_fields.map((field) => {
          return {
            item_meta: field.item_meta,
            value: field?.type === 'checkboxes' ? [] : null,
            uid: field.uid,
          };
        });
      }
      else {
        custom_fields_config.value = data
          .map((f) => {
            return {
              ...f,
              item_meta: get_custom_fields.find(
                ({ uid }) => uid === f.uid,
              )?.item_meta,
            };
          }).filter(f => get_custom_field(f.uid) && f.item_meta?.active === true);
        custom_fields_config.value.push(
          ...get_custom_fields
            .filter((field) => {
              return !custom_fields_config.value.find(
                f => f.uid === field.uid && field.active,
              );
            })
            .map((field) => {
              return {
                value: field?.type === 'checkboxes' ? [] : null,
                uid: field.uid,
                item_meta: field.item_meta,
              };
            }),
        );
      }
      return custom_fields_config.value;
    },
  };
}
