<script setup>
import ChevronRight from '~icons/hawk/chevron-right?raw';
import DotsVertical from '~icons/hawk/dots-vertical?raw';
import DOMPurify from 'dompurify';

import tippy from 'tippy.js';

import { useTemplateRef } from 'vue';
import { useRoute } from 'vue-router';

import {
  formattedDate,
  getLocationName,
  getPriorityObject,
  hawkCategory,
  hawkUserTeamBadgeGroup,
  sanitizeLocations,
} from '~/common/composables/kanban-helpers.js';

import { truncateMiddle } from '~/common/filters/truncate.js';

import { useCommonStore } from '~/common/stores/common.store';
import { changeIconDimensions } from '~/common/utils/common.utils';

import { load_js_css_file } from '~/common/utils/load-script.util';

import { useTaskNavigation } from '~/tasks/composables/task.navigation.js';
import { handleDependencyWarning, useTasksPermissions } from '~/tasks/composables/task-composables.js';

const emit = defineEmits(['viewTask', 'copyTask', 'duplicateTask', 'addDependencies', 'convertToSubtask', 'archiveTask', 'deleteTask']);

const $t = inject('$t');
const task_store = inject('task_store');

const route = useRoute();

const common_store = useCommonStore();

const { checkTaskPermission, checkStatusUpdatePermission } = useTasksPermissions();

const { subscribe_navigator, unsubscribe_navigator, setNavigationContext } = useTaskNavigation(task_store, 'kanban_view');

const kanban_ref = useTemplateRef('kanban_ref');
const kanban_board = ref(null);

const state = reactive({
  page_size: 15,
  page_number: {
    1: 1,
    2: 1,
    3: 1,
    4: 1,
    5: 1,
  },
  is_menu_open: false,
  is_column_collapsed: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  },
});

/* ----- PERMISSIONS ------ */
function getMenuItems({ card }) {
  return [
    {
      id: 'view-details',
      label: $t('View Details'),
      onClick: () => handleViewTask(card.uid, card.status, true),
    },
    {
      id: 'copy-url',
      label: $t('Copy URL'),
      onClick: () => emit('copyTask', card),
    },
    ...(checkTaskPermission({ permission: 'can_create_tasks' })
      ? [{
          id: 'duplicate',
          label: $t('Duplicate'),
          onClick: () => emit('duplicateTask', card),
        }]
      : []),
    ...(checkTaskPermission({ permission: 'can_modify', instance: card })
      ? [
          {
            id: 'add-dependencies',
            label: $t('Add Dependencies'),
            onClick: () => emit('addDependencies', card),
          },
          {
            id: 'convert-to-subtask',
            label: $t('Convert to Subtask'),
            onClick: () => emit('convertToSubtask', card),
          },
        ]
      : []),
    {
      id: 'archive',
      label: $t('Archive'),
      onClick: () => emit('archiveTask', card),
    },
    ...(checkTaskPermission({ permission: 'can_modify', instance: card })
      ? [
          {
            id: 'delete-task',
            label: $t('Delete Task'),
            onClick: () => emit('deleteTask', card),
          },
        ]
      : []),
  ];
}

const cardShape = computed(() => {
  return {
    label: true,
    description: true,
    progress: true,
    start_date: true,
    end_date: true,
    users: {
      show: false,
    },
    color: true,
    menu: {
      show: true,
      items: getMenuItems,
    },
    cover: true,
    attached: false,
  };
});

const columns = computed(() => {
  return task_store.status_values.map(val => ({
    ...val,
    label: `${val.label} (${task_store.get_task_count_by_status(val.value)})`,
    id: val.value,
    name: val.label,
    collapsed: state.is_column_collapsed[val.value],
  }));
});

const cards = computed(() => {
  return task_store.tasks().map(task => ({
    ...task,
    label: task.name,
    column: task.status,
    id: task.uid,
  }));
});

subscribe_navigator(async (status) => {
  state.page_number[status] = state.page_number[status] + 1;
  await getTasks({ status }, false, true);
  return kanban_board.value.getAreaCards(status);
}, handleViewTask);

task_store.$onAction(async ({ args, name, after }) => {
  after(() => {
    if (name === 'set_task_details') {
      const task = task_store.get_current_task(args[0]);
      kanban_board.value.updateCard({
        id: args[0],
        card: {
          ...task,
          column: task.status,
        },
      });
    }
    else if (name === 'remove_tasks' || name === 'archive_tasks') {
      const task_uids = args[0];
      task_uids.forEach(uid =>
        kanban_board.value.deleteCard({ id: uid }),
      );
    }
    else if (name === 'set_tasks_count') {
      columns.value.forEach(column =>
        kanban_board.value.updateColumn({
          id: column.id,
          column: { label: `${column.name} (${task_store.get_task_count_by_status(column.value)})` },
        }));
    }
    else if (name === 'duplicate_tasks' || name === 'update_tasks') {
      refreshView();
    }
    // reinitialize tippy for changes
    setTimeout(() => {
      tippy('[data-tippy-content]');
    }, 100);
  });
});

onBeforeUnmount(() => {
  unsubscribe_navigator();
});

onUnmounted(() => {
  document.removeEventListener('click', menuClickHandler);
  document.removeEventListener('scroll', scrollHandler, true);
});

async function initialize() {
  await load_js_css_file('https://cdn.jsdelivr.net/gh/sensehawk/cdn/dhtmlx-kanban/kanban.js', 'kanban_js', 'js');
  await load_js_css_file('https://cdn.jsdelivr.net/gh/sensehawk/cdn/dhtmlx-kanban/kanban.css', 'kanban_css', 'css');

  kanban_board.value = new kanban.Kanban(kanban_ref.value, {
    cards: cards.value,
    columns: columns.value,
    cardShape: cardShape.value,
    cardTemplate: kanban.template(cardTemplate),
    scrollType: 'column',
    readonly: {
      edit: false,
      add: false,
      select: true,
      dnd: true,
    },
  });

  kanban_board.value.api.intercept('move-card', ({ id, columnId }) => {
    const task = kanban_board.value.getCard(id);
    const status = Number.parseInt(columnId);
    if (!checkStatusUpdatePermission(task, status))
      return false;
  });

  kanban_board.value.api.on('end-drag-card', async ({ id, columnId }) => {
    const task = kanban_board.value.getCard(id);
    const status = Number.parseInt(columnId);
    if (!checkStatusUpdatePermission(task, status))
      return false;
    const selection = [task.uid];
    const res = await task_store.update_tasks(selection, { status });
    if (res?.data?.description === 'Task Blocked') {
      handleDependencyWarning(
        task_store,
        task,
        { status },
        async (payload) => {
          await task_store.update_tasks([task.uid], payload);
          task_store.task_track_events('Status updated', { mode: 'Single', count: 1 }, task.uid);
        },
        refreshView,
      );
      return false;
    }
  });

  kanban_board.value.api.on('select-card', ({ id, groupMode }) => {
    if (!id || groupMode)
      return;
    kanban_board.value.api.exec('unselect-card', {});
    const task = kanban_board.value.getCard(id);
    handleViewTask(task.uid, task.status, true);
  });

  const columns_x = document.getElementsByClassName('wx-list-wrapper');
  Array.from(columns_x)?.forEach((column) => {
    column.addEventListener('scroll', onColumnScroll);
  });

  const collapse_buttons = document.getElementsByClassName('wx-collapse-icon');
  Array.from(collapse_buttons)?.forEach((collapse_button) => {
    collapse_button.addEventListener('click', (e) => {
      const status = e.target?.parentElement?.dataset?.columnHeader || e.target?.parentElement?.parentElement?.dataset?.columnHeader;
      state.is_column_collapsed[status] = !state.is_column_collapsed[status];
      if (!state.is_column_collapsed[status]) {
        // Reattach column scroll handler
        const column_wrapper = document.querySelector(`[data-drop-area="${status}"]`);
        column_wrapper.childNodes?.[0]?.addEventListener('scroll', onColumnScroll);
      }
    });
  });

  tippy('[data-tippy-content]');

  document.addEventListener('click', menuClickHandler);
  document.addEventListener('scroll', scrollHandler, true);
}

function menuClickHandler(e) {
  const attribute = e.target?.dataset?.menuId || e.target?.parentElement?.dataset?.menuId || e.target?.parentElement?.parentElement?.dataset?.menuId;
  if (attribute) {
    state.is_menu_open = !state.is_menu_open;
    const menu = document.getElementsByClassName('menu')?.[0];
    if (!state.is_menu_open)
      menu?.remove();
  }
  else {
    state.is_menu_open = false;
  }
}

function scrollHandler(_e) {
  state.is_menu_open = false;
  const menu = document.getElementsByClassName('menu')?.[0];
  menu?.remove();
}

function onColumnScroll(e) {
  const element = e.target;
  const column_status = element.parentElement.dataset.dropArea;
  if (task_store.get_tasks_by_status(column_status).length < task_store.get_task_count_by_status(column_status)) {
    if (Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 1) {
      state.page_number[column_status] = state.page_number[column_status] + 1;
      getTasks({ status: column_status }, false, true);
    }
  }
}

function handleViewTask(current_task_uid, status, create_new_context) {
  setNavigationContext(
    create_new_context,
    { current_task_uid, tasks: kanban_board.value.getAreaCards(status) },
    { page_number: state.page_number[status], page_size: state.page_number, status },
  );
  emit('viewTask', current_task_uid);
}

async function getTasks({ status }, init, append) {
  await task_store.set_kanban_tasks({
    status: status || [1, 2, 3, 4, 5],
    page_size: state.page_size,
    page_number: status ? state.page_number[status] : 1,
    ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
  }, append);
  if (init)
    await initialize();
  else
    refreshView();
}

async function searchTasks(value) {
  task_store.set_search_key(value);
  await task_store.set_kanban_tasks({
    page_number: 1,
    page_size: state.page_size,
    is_template: false,
    ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
  }, false);
  refreshView();
}

function cardTemplate({ cardFields }) {
  const { label, priority } = cardFields;
  const task_priority = getPriorityObject()?.[priority];
  const location_name = getLocationName(cardFields);
  const category_name = common_store.get_category(cardFields.category)?.name || null;
  const due_date_class = cardFields.due_date && new Date(cardFields.due_date) <= new Date() ? 'text-error-700' : 'text-gray-700';

  const users = common_store.filter_users(cardFields.assignees);
  const teams = common_store.filter_teams(cardFields.assignees);
  const user = common_store.get_user(users?.[0]);

  const sanitized_locations = sanitizeLocations(location_name);

  return `
    <div class="p-3">
        <div class="relative flex justify-between pb-2">
          <div class="flex flex-col">
            <div
              class="text-xs font-regular text-gray-600 flex items-center"
              data-tippy-content="${sanitized_locations.map((name, idx) => {
    return `${name} ${idx === sanitized_locations.length - 1 ? '' : '&gt; '}`;
  }).join(' ')}"
            >
              <span>${sanitized_locations.length ? sanitized_locations[0] : ''}</span>
              ${sanitized_locations.length > 1
                  ? `<span class="flex items-center text-gray-300 mx-1">${changeIconDimensions(ChevronRight, 16, 16)}</span>
                <span>${truncateMiddle(sanitized_locations.at(-1))}</span>`
                  : ''}
            </div>
            <div class="flex justify-between text-sm font-medium text-gray-900 pt-1 label-class">
              ${DOMPurify.sanitize(label, { ALLOWED_TAGS: [] }) || $t('Invalid task name')}
            </div>
          </div>
          <button
            data-menu-id=${cardFields.id}
            data-ignore-selection="true"
            class="hidden-by-default px-2 h-9 absolute right-1 top-1"
          >
            ${DotsVertical}
          </button>
        </div>
        ${
          cardFields.due_date || category_name
            ? `
            <div class="flex justify-between pb-2 pt-2 text-sm font-regular">
              <span class="text-gray-900">
                ${hawkCategory(category_name)}
              </span>
              <span class="${due_date_class}">
                ${formattedDate(cardFields)}
              </span>
            </div>
            `
            : ''
        }
        <div class="flex justify-between">
          <span class="flex items-center ${task_priority.priority_class_icon}">
            ${task_priority.IconToBeDisplayed}
            <span class="pl-1 text-xs ${task_priority.priority_class_label} !flex !items-center">
              ${task_priority.label}
            </span>
          </span>
          <span class="flex items-center">
            ${hawkUserTeamBadgeGroup(cardFields, users, teams, user) || ''}
          </span>
        </div>
    </div>`;
}

function refreshView() {
  kanban_board.value.parse({ cards: cards.value, columns: columns.value });
}

defineExpose({ getTasks, searchTasks, refreshView });
</script>

<template>
  <div ref="kanban_ref" class="wx-material-theme w-full h-[calc(100vh_-_190px)] -mt-6" />
</template>

<style scoped lang="scss">
@import "~/tasks/styles/kanban-skin.scss";
</style>

<style lang="scss">
  .wx-material-theme {
    .menu {
      border-radius: 10px !important;
      padding: 0.25rem !important;
      width: 180px !important;
      .item {
        display: flex !important;
        align-items: center !important;
        padding: 1.25rem 1rem !important;
        color: #434e61 !important;
        font-weight: 500 !important;
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
      }
      .item:hover {
        background-color: rgb(249 250 251);
      }
    }
  }
</style>
