<script setup>
import CollapsibleDescription from '~/acct-settings/components/acct-settings-audit-logs/collapsible-description.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  level: {
    type: Number,
    default: 1,
  },
});

const is_expanded = ref(false);
</script>

<template>
  <div class="text-xs text-gray-500">
    <div class="grid grid-cols-12 my-2">
      <div class="col-span-3 flex items-center">
        <IconHawkChevronRight
          class="transition-transform mr-2 cursor-pointer"
          :class="{ 'rotate-90': is_expanded }"
          @click="() => is_expanded = !is_expanded"
        />
        <p>
          {{ data.key }}
        </p>
      </div>
      <div v-if="!is_expanded" class="col-span-9" :style="{ 'margin-left': `${-(60 + (level - 1) * 7)}px` }">
        <div v-if="data.value?.component">
          <component :is="data.value.component" v-bind="data.value.props" />
        </div>
        <p v-else>
          {{ data.value }}
        </p>
      </div>
    </div>
    <div v-if="is_expanded" class="relative ml-2.5">
      <template v-for="key in Object.keys(data.child)" :key="key">
        <CollapsibleDescription
          v-if="data.child[key].child"
          :data="data.child[key]"
          :level="props.level + 1"
        />
        <div v-else class="flex items-center text-xs mb-4">
          <div class="h-[1px] bg-gray-200 w-[18px] mr-4" />
          <div class="grid grid-cols-12 w-full">
            <div class="col-span-3 flex items-center">
              {{ key }}
            </div>
            <div class="col-span-9" :style="{ 'margin-left': `${-(93 + (level - 1) * 7)}px` }">
              <div v-if="data.child[key]?.component">
                <component :is="data.child[key].component" v-bind="data.child[key]?.props" />
              </div>
              <p v-else>
                {{ data.child[key] }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <div class="absolute bg-gray-200 top-[-8px] w-[1px] h-full" />
    </div>
  </div>
</template>
