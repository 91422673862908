<script setup>
const props = defineProps({
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  description: {
    type: String,
    default: '',
  },
  data: {
    type: Object,
    default: () => ({
      geofencing_distance: null,
    }),
  },
});
const emit = defineEmits(['close']);
const form$ = ref(null);
const focusElement = () => form$.value.elements$.geofencing_distance.input.focus();
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]" :options="{ 'focus-trap': { allowOutsideClick: true, onPostActivate: focusElement } }">
    <Vueform
      ref="form$"
      :presets="['']"
      :display-errors="false"
      :endpoint="props.submit"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }"
      @mounted="form$ => form$.load(props.data)"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Geofencing') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            name="geofencing_distance"
            input-type="number"
            rules="numeric|nullable|min:0"
            :label="$t('Distance')"
            :description="props.description"
            :columns="{
              container: 12,
              label: 2,
              wrapper: 12,
            }"
            :attrs="{ min: '0', autofocus: true }"
            :addons="{
              after: $t('meters'),
            }"
            @mounted="elementMounted"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
