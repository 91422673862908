import { defineStore } from 'pinia';

export function useFormFilterStore(key) {
  return defineStore(key ? `${key}-filter` : 'form-filter', {
    state: () => ({
      filters: {},
      search_key: '',
      filter_payload: {},
    }),
    getters: {
      filters_count: (state) => {
        // exclude_filter_keys is the list of key name that needs to be excluded for counting filters applied as some of them are required only for Backend computations
        const exclude_filter_keys = ['all_access', 'created_at_start', 'due_date_start', 'start_date_start'];

        return Object.keys(state?.filters).filter(filter_key => !exclude_filter_keys.includes(filter_key)).length;
      },
    },
    actions: {
      format_filter(payload) {
        const original_payload = payload;
        const data = {};

        this.filters = { ...payload };
        delete original_payload?.date_group;

        this.filter_payload = { ...data, ...original_payload };
      },
      set_filters({ payload, reset }) {
        if (reset) {
          this.filters = {};
          this.filter_payload = {};
        }
        else { this.format_filter(payload); }
      },
      set_search_key(value) {
        this.search_key = value;
      },
    },
  })();
}
