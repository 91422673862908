<script setup>
import { isEqual } from 'lodash-es';
import { watch } from 'vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
});

const $services = inject('$services');
const loading = ref(false);
const dashboard_terra_store = useDashboardTerraStore();
const dashboard_store = useDashboardStore();
const state = reactive({
  table_dataset: [],
  table_columns: [],
  payload: null,
  no_data: false,
  prevent_watcher: false,
});
const force_render = ref(0);
const columns_widths_map = computed(() => props.data.data.columns_widths || {});
const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

async function getReports() {
  loading.value = true;
  state.payload = dashboard_terra_store.parse_terra_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.terra_view_service.get_graph({ body: state.payload });

    if (data?.length) {
      const is_transpose = props.data?.data?.transpose;
      const is_grouped = props.data?.data?.feature_group !== 'none';

      if (is_grouped)
        is_transpose ? generateGroupedTransposeTable(data) : generateGroupedTable(data);
      else
        is_transpose ? generateTransposedTable(data) : generateTable(data);

      state.no_data = false;
      loading.value = false;
      force_render.value += 1;
    }
    else {
      state.no_data = true;
      state.table_dataset = [];
      state.table_columns = [];
      loading.value = false;
    }
  }
  catch {
    loading.value = false;
  }
}

function generateTable(data) {
  const columns = [
    {
      id: 'feature_type',
      accessorKey: 'feature_type',
      size: columns_widths_map.value.feature_type || 250,
      header: 'Class',
    },
    {
      id: 'count',
      accessorKey: 'count',
      size: columns_widths_map.value.count || 250,
      header: 'Count',
    },
  ];

  const dataset = data.map(item => ({
    feature_type: item.featureType_name,
    count: item.count,
  }));

  state.table_columns = [...columns];
  state.table_dataset = [...dataset];
}

function generateTransposedTable(data) {
  const columns = data.map(item => ({
    id: item.featureType_uid,
    accessorKey: item.featureType_uid,
    size: columns_widths_map.value[item.featureType_uid] || 150,
    header: item.featureType_name,
  }));

  const dataset = {};
  data.forEach((item) => {
    dataset[item.featureType_uid] = item.count;
  });

  state.table_columns = [...columns];
  state.table_dataset = [dataset];
}

function generateGroupedTable(data) {
  const columns = [
    {
      id: 'feature_type',
      accessorKey: 'feature_type',
      size: columns_widths_map.value.feature_type || 250,
      header: 'Class',
    },
  ];

  const used_columns_keys = [];

  const dataset = {};

  data.forEach((item) => {
    const column_key = String(item.key);
    if (!used_columns_keys.includes(column_key)) {
      columns.push({
        id: column_key,
        accessorFn: data => data[column_key],
        size: columns_widths_map.value[column_key] || 150,
        header: column_key,
      });

      used_columns_keys.push(column_key);
    }
  });

  data.forEach((item) => {
    if (!dataset[item.featureType_uid]) {
      dataset[item.featureType_uid] = {
        feature_type: item.featureType_name,
      };
    }

    const column_key = String(item.key);
    dataset[item.featureType_uid][column_key] = item.count;
  });

  state.table_columns = [...columns];
  state.table_dataset = Object.values(dataset || {});
}

function generateGroupedTransposeTable(data) {
  let first_column_header = null;
  if (props.data?.data?.feature_group === 'extraProperties')
    first_column_header = 'Property';
  else if (props.data?.data?.feature_group === 'project')
    first_column_header = 'Project';

  const columns = [
    {
      id: 'group',
      accessorKey: 'group',
      size: columns_widths_map.value.group || 250,
      header: first_column_header,
    },
  ];
  const dataset = {};
  const used_columns_keys = [];

  data.forEach((item) => {
    if (!used_columns_keys.includes(item.featureType_uid)) {
      columns.push({
        id: item.featureType_uid,
        accessorFn: data => data[item.featureType_uid],
        size: columns_widths_map.value[item.featureType_uid] || 150,
        header: item.featureType_name,
      });

      used_columns_keys.push(item.featureType_uid);
    }
  });

  data.forEach((item) => {
    const row_key = String(item.key);
    if (!dataset[row_key]) {
      dataset[row_key] = {
        group: row_key,
      };
    }

    dataset[row_key][item.featureType_uid] = item.count;
  });

  state.table_columns = [...columns];
  state.table_dataset = Object.values(dataset || {});
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  state.prevent_watcher = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: state.table_columns,
      activities: state.table_dataset,
      dataset: state.table_dataset,
      is_transpose: false,
      dashboard_index: props.data.i,
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    type: 'table',
    data: state.table_dataset.value.map(a => Object.values(a)),
  });
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (state.prevent_watcher) {
      state.prevent_watcher = false;
      return;
    }
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="state.no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="state.table_dataset?.length && state.table_columns?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="force_render"
          :data="state.table_dataset"
          :columns="state.table_columns"
          additional_table_classes=""
          additional_row_classes="even:bg-gray-50"
          :container_class="`h-[${content_height || height}px]`"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          :show_menu_header="false"
          :show_column_borders="true"
          :striped="true"
          is_gapless
          cell_height="30px"
          @column-resized="columnResized"
        />
      </div>
    </a>
  </div>
</template>
