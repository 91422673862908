<template>
  <div class="block md:flex items-center justify-between">
    <div>
      <slot name="left" />
    </div>
    <div>
      <slot name="right" />
    </div>
  </div>
</template>
