<script setup>
const props = defineProps({
  columns: {
    type: Array,
    default: () => [],
  },
  generate_sample_file_name: {
    type: Function,
    default: () => 'sample_data',
  },
});

const table_columns = [
  {
    accessorKey: 'label',
    header: 'Column name',
    id: 'label',
  },
  {
    accessorKey: 'data_format',
    header: 'Type',
    id: 'data_format',
  },
  {
    accessorKey: 'example',
    header: 'Example',
    id: 'example',
  },
  {
    accessorKey: 'required',
    header: 'Required',
    id: 'required',
  },
  {
    accessorKey: 'description',
    header: 'Description',
    id: 'description',
  },
];
async function downloadSampleExcel() {
  const ExcelJS = await import('exceljs');
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sample Sheet');

  const header_row = props.columns.map(item => `${item.label || item.field}${item.required ? '*' : ''}`);
  worksheet.addRow(header_row);

  const example_row = props.columns.map(item => item.example || '');
  worksheet.addRow(example_row);

  const file_name = props.generate_sample_file_name ? props.generate_sample_file_name() : 'sample';
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${file_name}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }).catch((err) => {
    console.error('Error generating sample file:', err);
  });
}
</script>

<template>
  <div class="flex items-center justify-between px-8">
    <div>
      <p class="text-md font-semibold">
        {{ $t('Get ready to import your data') }}
      </p>
      <p class="text-xs">
        {{ $t('The following columns are supported. Some may be required, rest are optional') }}
      </p>
    </div>
    <hawk-button type="outlined" @click="downloadSampleExcel">
      {{ $t('Download template') }}
    </hawk-button>
  </div>
  <hawk-table class="mt-4" :data="columns" :columns="table_columns">
    <template #required="row">
      {{ row.data.row.original?.required ? 'Yes' : 'No' }}
    </template>
  </hawk-table>
</template>
