<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  status: {
    type: String,
    default: '-',
  },
});
const inventory_store = useInventoryStore();

const status = computed(() => inventory_store.statuses_map[props.status] || null);
</script>

<template>
  <HawkBadge :custom_color="status?.color">
    {{ status?.name }}
  </HawkBadge>
</template>
