<script>
export default {
  name: 'FileElement',
  data() {
    return {
      merge: true,
      defaultClasses: {
        container: '',
        button: '',
      },
    };
  },
};
</script>

<template>
  <component :is="elementLayout" ref="container">
    <template #element>
      <!-- Drag n drop -->
      <DragAndDrop
        v-if="drop && canDrop && canSelect"
        ref="area"
        title=""
        :options="options"
        :description="options?.description || ''"
        :disabled="isDisabled"
        @click="handleClick"
        @drop.prevent="handleDrop"
      />

      <!-- Upload button -->
      <HawkButton
        v-else-if="canSelect"
        :type="options.button_type ?? 'outlined'"
        :class="options.button_classes"
        v-bind="aria"
        :aria-labelledby="labelId"
        :aria-placeholder="form$.translations.vueform.elements[type].select"
        role="button"
        tabindex="0"
        @click.prevent="handleClick"
        @keypress.enter.space="handleClick"
      >
        <slot name="upload-trigger" />
      </HawkButton>

      <!-- Hidden file input -->
      <input
        v-if="canSelect && !isDisabled && !preparing"
        v-show="false"
        :id="fieldId"
        ref="input"
        type="file"
        :accept="accept"
        @change="handleChange"
      >

      <!-- Preview -->
      <slot name="preview">
        <FilePreview v-if="value" :key="view" :attrs="aria" />
      </slot>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </component>
</template>
