<script setup>
import { isArray } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { FILTER_FIELDS, filterToRuleFormatter, ruleToFilterFormatter } from '~/dashboard/components/filters/composables/tasks-filters.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import FamTabFormRules from '~/forms-as-module/components/fam-tabs-list/fam-tab-form-rules.vue';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const emit = defineEmits(['update']);

const dashboard_store = useDashboardStore();

const { widget_configuration } = storeToRefs(dashboard_store);

const init = ref(true);
const form_data = ref({ filters: [], others: [] });
const show_other_filters = ref(false);

const rules_state = reactive({
  is_invalid: false,
  get_filters_payload: null,
});

const { DATE_FILTER_OPTIONS } = useFamConstants();

const dashboard_custom_date_filter_options = computed(() => {
  const date_filter_options = DATE_FILTER_OPTIONS;
  date_filter_options.between = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.between];
  date_filter_options.isNotBetween = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.isNotBetween];
  date_filter_options.isEqualTo = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.isEqualTo];
  date_filter_options.isNotEqualTo = [{ value: 'dashboard_active_selected_range', label: 'Selected range' }, ...date_filter_options.isNotEqualTo];
  return date_filter_options;
});
function loadTaskFilters() {
  /*
    If filter_rules exist in widget data => Load it in fam-tab-form-rules component
    Else if report_filters exist in widget data => Format report_data to filter rules and load it in fam-tab-form-rules component
         if other type filter exists in report data, Load it
  */
  const filter_rules = widget_configuration.value?.filter_rules || [];
  const report_data = isArray(widget_configuration.value?.filters?.report_filters) ? widget_configuration.value?.filters?.report_filters : [];

  if (filter_rules.length)
    form_data.value.filters = filter_rules;
  else if (isArray(widget_configuration.value?.filters?.report_filters) && widget_configuration.value?.filters?.report_filters?.length)
    form_data.value.filters = filterToRuleFormatter(report_data);

  form_data.value.others = report_data?.filter(f => f.name === 'others')?.[0]?.value || [];
  show_other_filters.value = form_data.value.others.length > 0;

  init.value = false;
}

function updateRules(rules) {
  if (rules)
    form_data.value.filters = rules;

  if (rules_state.is_invalid)
    return;

  const filter_rules = rules_state.get_filters_payload.filters() || [];

  const { filters, report_filters } = ruleToFilterFormatter(filter_rules);

  if (form_data.value.others.length) {
    report_filters.push({
      name: 'others',
      type: null,
      value: form_data.value.others,
    });
    form_data.value.others.forEach(key => filters[key] = true);
  }

  emit('update', {
    filters,
    report_filters,
    filter_rules: rules,
  });
}

onMounted(() => {
  loadTaskFilters();
});
</script>

<template>
  <div class="text-sm text-blue-600 font-medium cursor-pointer">
    {{ $t('Advanced filters') }}
  </div>
  <Vueform
    v-if="!init"
    v-model="form_data"
    sync
    size="sm"
  >
    <div class="col-span-12 mt-4">
      <FamTabFormRules
        name="filters"
        :rules="form_data.filters"
        :fields="FILTER_FIELDS"
        :is_required="false"
        :is_generic="true"
        :custom_date_filter_options="dashboard_custom_date_filter_options"
        @update-rules="updateRules"
        @invalid="($event) => (rules_state.is_invalid = $event)"
        @get-filter-payload="rules_state.get_filters_payload = $event"
      />
      <div
        type="plain"
        class="flex gap-2 items-center pb-4 rounded-lg text-xs text-gray-700 cursor-pointer"
        @click="show_other_filters = !show_other_filters"
      >
        <p class="text-sm font-semibold">
          Others
        </p>
        <IconHawkChevronDown
          class="text-gray-700 transition-transform"
          :class="{ 'rotate-180': show_other_filters }"
        />
      </div>
      <CheckboxgroupElement
        v-show="show_other_filters"
        class="mb-8"
        name="others"
        :items="[
          { label: 'Bookmarked', value: 'bookmarked' },
          { label: 'Archived', value: 'archived' },
          { label: 'Comments', value: 'has_comments' },
        ]"
        @change="() => updateRules()"
      />
    </div>
  </Vueform>
</template>
