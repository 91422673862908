<script setup>
import { inject } from 'vue';
import { useRoute } from 'vue-router';

import TaskDetailsTopbar from '~/tasks/pages/task-details-new/left-section/task-details-topbar.vue';
import TaskDetailsOverview from '~/tasks/pages/task-details-new/left-section/task-details-overview.vue';
import TaskDetailsAttachments from '~/tasks/pages/task-details-new/left-section/task-details-attachments.vue';
import TaskDetailsChecklists from '~/tasks/pages/task-details-new/left-section/task-checklists/task-details-checklists.vue';
import TaskDetailsSubtasks from '~/tasks/pages/task-details-new/left-section/task-details-subtasks.vue';

import TaskDetailsDependencies from '~/tasks/pages/task-details-new/left-section/task-details-dependencies.vue';

import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  task_details: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['taskNameVisible']);

const task_store = inject('task_store', {});
const is_template = inject('is-template');
const header_height = inject('header_height');

const route = useRoute();

const { checkTaskPermission } = useTasksPermissions();

const active_tab = ref('checklists');

const checklist_count = computed(() => props.task_details.checklists_stats?.total_items || 0);
const subtask_count = computed(() => props.task_details.sub_task_uids?.length || 0);

const dependency_count = computed(() => Object.keys(task_store.dependencies).length || 0);
const tabs = computed(() => [
  {
    uid: 'checklists',
    label: 'Checklists',
    on_click: () => active_tab.value = 'checklists',
    count: checklist_count,
  },
  {
    uid: 'subtasks',
    label: 'Subtasks',
    on_click: () => active_tab.value = 'subtasks',
    count: subtask_count,
  },
  ...(is_template
    ? []
    : [{
        uid: 'dependencies',
        label: 'Dependencies',
        on_click: () => active_tab.value = 'dependencies',
        count: dependency_count,
      }]),
]);

const sectionHeight = computed(() => {
  return `${window.innerHeight - header_height.value}px`;
});

onMounted(() => {
  const left_section = document.getElementById('task-details-left-section');
  left_section.addEventListener('scroll', () => {
    const task_name = document.getElementById('task-name');
    emit('taskNameVisible', task_name.getBoundingClientRect().bottom >= header_height.value + 5);
  });
});

async function showDependencies() {
  active_tab.value = 'dependencies';
  await nextTick();
  const dependency_element = document.getElementById('task-details-dependencies');
  dependency_element.scrollIntoView({ behavior: 'smooth' });
}
</script>

<template>
  <div id="task-details-left-section" class="p-6 scrollbar" :style="{ height: sectionHeight }">
    <TaskDetailsTopbar :task_details="props.task_details" @badgeClicked="showDependencies" />
    <TaskDetailsOverview class="my-6" />
    <hr class="border-t-2">
    <TaskDetailsAttachments
      class="my-6"
      :can_modify="checkTaskPermission({ permission: 'can_modify', instance: task_details })"
      :attachments_count="props.task_details.attachments_count"
    />
    <hr class="border-t-2">

    <hawk-tabs :tabs="tabs" :active_item="active_tab" class="py-6" />
    <TaskDetailsChecklists v-show="active_tab === 'checklists'" />
    <TaskDetailsSubtasks v-show="active_tab === 'subtasks'" />
    <TaskDetailsDependencies v-show="active_tab === 'dependencies'" id="task-details-dependencies" />
  </div>
</template>
