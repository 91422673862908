<script setup>
import { useRoute, useRouter } from 'vue-router';
import { ref } from 'vue';
import { jwtDecode } from 'jwt-decode';

import { accessTokenCookie } from '~/common/utils/common.utils';
import { openJoinOrganizationInfo } from '~/auth/utils/auth.utils.js';

const form = ref({});
const state = reactive({
  is_loading: false,
  decoded_data: null,
  error_data: null,
});
const $services = inject('$services');
const $t = inject('$t');

const route = useRoute();
const router = useRouter();

if (route.query.p)
  state.decoded_data = jwtDecode(route.query.p);

function getRouteDetails() {
  if (accessTokenCookie())
    return { label: $t('Back to Home'), to: { name: 'home' } };
  else
    return { label: $t('Sign in'), to: { name: 'sign-in' } };
}

async function confirmInvitation() {
  try {
    state.is_loading = true;
    await $services.auth.confirmEnrollment({
      body: {},
      query: {
        p: route.query.p,
      },
    });
    await openJoinOrganizationInfo(state.decoded_data.org_uid, state.decoded_data.organization_name, IconHawkCheckCircle, () => router.push(getRouteDetails().to));
    state.is_loading = false;
  }
  catch (error) {
    if (error?.data?.code === 'CORE_25')
      await openJoinOrganizationInfo(state.decoded_data.org_uid, state.decoded_data.organization_name, IconHawkCheckCircle, () => router.push(getRouteDetails().to));
    else if (error?.data?.code)
      state.error_data = error.data;
    else
      state.error_data = { title: (error.data.title || $t('Error')), message: (error.data?.message || error.data) };
    state.is_loading = false;
    logger.log(error.data);
  }
}
</script>

<template>
  <div class="min-h-screen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24">
      <div class="flex justify-center mb-6">
        <HawkIconTaskmapperIcon />
      </div>
      <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
        {{ $t('Accept Invitation') }}
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="flex flex-col text-black mb-10">
          {{ $t('You have been invited to join another organization') }}
          <span class="font-semibold mt-3 text-lg">{{ state.decoded_data.organization_name }}</span>
        </div>
        <Vueform
          v-model="form" :display-errors="false"
          size="sm"
          :columns="{
            default: { container: 12, label: 12, wrapper: 12 },
            sm: { container: 12, label: 12, wrapper: 12 },
          }"
          @submit="confirmInvitation"
        >
          <div class="col-span-12 grid gap-6">
            <ButtonElement
              name="submit" :button-label="$t('Accept Invitation')"
              :submits="true" :loading="state.is_loading"
              button-class="w-full bg-blue-600 !py-[9px] !text-sm"
            />
          </div>
        </Vueform>
        <HawkInfo v-if="state.error_data" class="mt-3 capitalize" type="error" :title="state.error_data.title" :description="state.error_data.message" :action_button="getRouteDetails().label" @action-button="$router.push(getRouteDetails().to)" />
      </div>
    </div>
  </div>
</template>
