<script setup>
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import CustomReportPopup from '~/therm/components/customize-report/custom-report-popup.vue';
import { useThermStore } from '~/therm/store/therm.store.js';

const props = defineProps({
  exportType: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close', 'export']);

const { auth_store, $t } = useCommonImports();

const custom_report_popup = useModal({
  component: CustomReportPopup,
  attrs: {
    onClose() {
      custom_report_popup.close();
    },
  },
});

const form$ = ref(null);
const therm_store = useThermStore();

const get_popup_details = computed(() => {
  if (props.exportType === 'PDF')
    return { title: $t('Export pdf'), label: $t('Include in PDF') };
  return { title: $t('Export'), label: $t('Export as') };
});
const group_report = computed(() => {
  return therm_store.active_group?.summary_report?.report;
});

function getPDFOptionItems() {
  return [
    { label: $t('Defect details'), value: 'defect_details', disabled: true, checked: true },
    { label: $t('Raw images'), value: 'raw_images' },
    { label: `${$t('Attachments')} (${$t('manually added')})`, value: 'attachments' },
    { label: $t('Notes'), value: 'notes' },
    { label: `${$t('Tasks')} (${$t('manually added')})`, value: 'tasks_unassociated' },
  ];
}

function getPDFOptions(data) {
  return {
    show_defects: data.pdf_options?.includes('defect_details'),
    show_raw_images: data.pdf_options?.includes('raw_images'),
    show_attachments: data.pdf_options?.includes('attachments'),
    show_notes: data.pdf_options?.includes('notes'),
    show_tasks_unassociated: data.pdf_options?.includes('tasks_unassociated'),
  };
}

async function onSave() {
  const data = form$.value.data.options;
  const export_as = data.export_as || props.exportType;

  if (export_as === 'Custom report') {
    const response = await fetch(group_report.value?.url);
    const response_blob = await response.blob();
    saveAs(response_blob, group_report.value?.file_name);
  }
  else {
    const pdf_options = getPDFOptions(data);
    emit('export', { export_as, pdf_options });
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ get_popup_details.title }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <ObjectElement :label="get_popup_details.label" name="options">
            <div class="col-span-12">
              <RadiogroupElement
                v-if="!exportType"
                name="export_as"
                default="Excel"
                :add-classes="{
                  RadiogroupElement: {
                    wrapper: 'gap-y-1',
                  },
                }"
                :items="[
                  'Excel',
                  'PDF',
                  'Custom report',
                ].filter(item => item !== 'Custom report' || group_report?.url)"
              >
                <template #radio="{ item, value, el$, classes, isDisabled, id, name }">
                  <input
                    :id="id"
                    v-model="el$.value"
                    type="radio"
                    :value="value"
                    :class="classes.input"
                    :name="name"
                    :disabled="isDisabled"
                    :aria-label="item.label"
                  >

                  <span
                    :class="classes.text"
                    v-html="item.label"
                  />

                  <IconHawkPencilOne v-if="item.label === 'Custom report' && auth_store.is_internal_user" class="ml-2 w-4.5 h-4.5 cursor-pointer" @click="custom_report_popup.open()" />
                </template>
              </RadiogroupElement>
              <div v-if="(exportType || form$?.data?.options?.export_as) === 'PDF'" class="ml-4 mt-1">
                <CheckboxgroupElement
                  name="pdf_options"
                  :default="['defect_details']"
                  :add-classes="{
                    CheckboxgroupElement: {
                      wrapper: 'gap-y-1.5',
                    },
                  }"
                  :items="getPDFOptionItems()"
                />
              </div>
            </div>
          </ObjectElement>
        </hawk-modal-content>
        <hawk-modal-footer class="flex items-center justify-between">
          <template v-if="auth_store.is_internal_user && !group_report?.url" #left>
            <div class="flex items-center gap-2 cursor-pointer" @click="custom_report_popup.open()">
              <IconHawkPencilOne class="text-gray-500 h-4.5 w-4.5" />
              <div class="text-sm text-gray-600 font-semibold">
                {{ $t('Customize report') }}
              </div>
            </div>
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <ButtonElement
                :button-label="$t('Cancel')"
                class="mr-3"
                :secondary="true"
                @click="emit('close')"
              />
              <ButtonElement
                :button-label="$t('Export')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
