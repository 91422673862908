<script setup>
import { computed } from 'vue';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  transmittal: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);
const transmittals_store = useTransmittalsStore();
const dms_settings_store = useDMSSettingsStore('transmittals');
const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => !field.deleted && field.active));

const form$ = ref(null);
const state = reactive({
  is_loading: false,
});

const transmittal = computed(() => transmittals_store.transmittal);
function formMounted(el$) {
  if (transmittal.value) {
    const { name, issue_purpose, category, priority, field_values } = transmittal.value;
    el$.update({
      name,
      issue_purpose,
      category,
      priority,
      field_values,
    });
  }
}

async function submitHandler(form$) {
  try {
    state.is_loading = true;
    await transmittals_store.update_transmittal({
      transmittal_uid: transmittal.value.uid,
      transmittals: form$.data,
    });
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
    emit('close');
  }
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @mounted="formMounted"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Edit transmittal') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            name="name"
            :label="$t('Name')"
            :rules="['required']"
            :placeholder="$t('Enter name')"
            class="mb-4"
          />
          <TextElement
            name="issue_purpose"
            :label="$t('Issue purpose')"
            :placeholder="$t('Enter issue purpose')"
            class="mb-4"
          />
          <SelectElement
            v-bind="{
              name: 'priority',
              label: $t('Priority'),
              valueProp: 'name',
              labelProp: 'label',
              placeholder: $t('Select Priority'),
              items: transmittals_store.priority_values,
              canClear: false,
              canDeselect: false,
              native: false,
              class: 'mb-4',
            }"
          >
            <template #single-label="{ value }">
              <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                <TaskPriority :priority="value.value" />
              </div>
            </template>
            <template #option="{ option }">
              <TaskPriority :priority="option.value" />
            </template>
          </SelectElement>
          <hawk-category-input
            :options="{
              name: 'category',
              label: $t('Category'),
              placeholder: $t('Select Category'),
              multi: false,
              canClear: false,
            }"
            class="mb-4"
          />
          <hawk-custom-fields-input
            name="field_values"
            :configuration="custom_fields"
            :columns="{
              default: { container: 12, wrapper: 12, label: 12 },
              sm: { container: 12, label: 4, wrapper: 12 },
              md: { container: 12, label: 4, wrapper: 12 },
            }"
            :disable_edit="true"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-3" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  submits
                  size="sm"
                  name="submit" :button-label="$t('Save')"
                  :loading="state.is_loading"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
