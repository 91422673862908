<script setup>
import { onMounted } from 'vue';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { groupBy, reduce, sortBy, sum, sumBy, uniq, values } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store';
import { useTerraStore } from '~/terra/store/terra.store.js';
import TerraMetricsTable from '~/terra/components/barcode-scan/terra-metrics-table.vue';
import TerraDuplicateTable from '~/terra/components/barcode-scan/terra-duplicates-table.vue';
import TerraProgressBar from '~/terra/components/barcode-scan/terra-progress-bar.vue';
import TerraProgressHistory from '~/terra/components/barcode-scan/terra-scan-history.vue';

const props = defineProps({
  can_modify_features: {
    type: Boolean,
    default: false,
  },
});

const $services = inject('$services');
const $t = inject('$t');

const common_store = useCommonStore();
const terra_store = useTerraStore();

const default_overview_data = {
  inverter_completion: {
    total: 0,
    completed: 0,
    percentage: 0,
  },
  tracker_completion: {
    total: 0,
    completed: 0,
    percentage: 0,
  },
  module_progress: {
    total: 0,
    completed: 0,
  },
  tracker_progress: {
    completed: {
      key: 'completed',
      labelFont: '14px',
      color: '#12B76A',
      label: $t('Completed'),
      value: 0,
    },
    inprogress: {
      key: 'inprogress',
      labelBorderRadius: '4px',
      labelFont: '14px',
      color: '#FDB022',
      label: $t('In-progress'),
      value: 0,
    },
    pending: {
      key: 'pending',
      labelFont: '14px',
      color: '#F04438',
      label: $t('Pending'),
      value: 0,
    },
  },
};

const state = reactive({
  is_loading: false,
  projects: [],
  metrics_data_map: [],
  serial_numbers_data: [],
  scan_history: { data: [], columns: [] },
  overview_data: default_overview_data,
  has_permission: true,
});

function initOverviewData() {
  const { inverter_completion, tracker_completion, module_progress, tracker_progress } = default_overview_data;

  Object.values(state.metrics_data_map).forEach((project_metrics) => {
    inverter_completion.total += 1;
    if (project_metrics.pending === 0 && project_metrics.inprogress === 0)
      inverter_completion.completed += 1;

    module_progress.total += project_metrics.total_modules_count || 0;
    module_progress.completed += project_metrics.num_scanned_modules || 0;

    tracker_progress.pending.value += project_metrics.pending || 0;
    tracker_progress.inprogress.value += project_metrics.inprogress || 0;
    tracker_progress.completed.value += project_metrics.completed || 0;

    tracker_completion.completed = tracker_progress.completed.value;
    tracker_completion.total = tracker_progress.pending.value + tracker_progress.inprogress.value + tracker_progress.completed.value;
  });

  inverter_completion.percentage = calculatePercentage(inverter_completion.completed, inverter_completion.total);
  tracker_completion.percentage = calculatePercentage(tracker_completion.completed, tracker_completion.total);
  module_progress.percentage = calculatePercentage(module_progress.completed, module_progress.total);

  state.overview_data = {
    inverter_completion,
    tracker_completion,
    module_progress,
    tracker_progress,
  };
  getTrackerProgress();
}

function calculatePercentage(completed, total) {
  return total > 0 ? Math.floor((completed / total) * 100) : 0;
}

function getTrackerProgress() {
  const { pending, inprogress, completed } = state.overview_data.tracker_progress;
  const total = pending.value + inprogress.value + completed.value;
  const percentage_completed = (completed.value / total) * 100;
  const percentage_inprogress = (inprogress.value / total) * 100;
  const percentage_pending = (pending.value / total) * 100;
  state.overview_data.tracker_progress.progress_details = [
    { percentage: percentage_completed, color: completed.color, tooltip: `${completed.value} Completed` },
    { percentage: percentage_inprogress + percentage_completed, color: inprogress.color, tooltip: `${inprogress.value} In-progress` },
    { percentage: percentage_pending + percentage_inprogress + percentage_completed, color: pending.color, tooltip: `${pending.value} Pending` },
  ];
}

function getColor(key) {
  return state.overview_data[key].percentage === 100 ? { color: '#12B76A', badge: 'green' } : { color: '#1570EF', badge: 'blue' };
}

async function getData() {
  state.projects = Object.keys(terra_store.active_projects_data_map({ all_projects: true }));
  await getMetricsData();
  await getSerialNumberData();
  await getScanHistory();
}

async function getMetricsData() {
  try {
    state.metrics_data_map = (await $services.terra.post({
      url: 'features/barcode/metrics',
      body: {
        projects: state.projects,
      },
    })).data;
  }
  catch (error) {
    logger.error(error);
  }
}

async function getSerialNumberData() {
  try {
    state.serial_numbers_data = (await $services.terra.post({
      url: 'features/barcode/serial-numbers',
      query: {
        duplicate: true,
        json: true,
      },
      body: {
        projects: state.projects,
      },
    })).data;
  }
  catch (error) {
    logger.error(error);
  }
}

async function getScanHistory() {
  try {
    const scan_history_data = (await $services.terra.get({
      url: `barcodes/log/container/${terra_store.container.uid}`,
      query: {
        group_by: ['date', 'project'],
      },
    })).data;
    const grouped_date = groupBy(scan_history_data, 'date');
    const projects = uniq(scan_history_data.map(item => item.project));
    const table = {};
    Object.entries(grouped_date).forEach(([date, entries]) => {
      table[date] = reduce(entries, (acc, entry) => {
        acc[entry.project] = (acc[entry.project] || 0) + entry.total_added;
        return acc;
      }, {});
      table[date].Total = sum(values(table[date]));
    });
    const totals = { Total: 0 };

    projects.forEach((project) => {
      totals[project] = sumBy(values(table), data => data?.[project] || 0);
      totals.Total += totals[project];
    });
    totals.date = 'Total';

    const project_index_map = Object.values(terra_store.active_projects_data_map({ all_projects: true })).reduce((acc, project, i) => ({ ...acc, [project.uid]: i }), {});
    const columns = [
      { header: 'Date', id: 'date', accessorKey: 'date' },
      ...projects.map(project_uid => ({
        header: terra_store.active_projects_data_map({ all_projects: true })[project_uid].name,
        id: project_uid,
        accessorKey: project_uid,
      })).sort((a, b) => project_index_map[a.id] - project_index_map[b.id]),
      { header: 'Total', id: 'Total', accessorKey: 'Total' },
    ];
    const data = [
      ...sortBy(Object.entries(table).map(([date, entries]) => ({ ...entries, date })), val => -(new Date(val.date).getTime())),
      totals,
    ];

    state.scan_history = {
      data,
      columns,
    };
  }
  catch (error) {
    logger.error(error);
  }
}

function saveFile(data, filename) {
  const final = Papa.unparse(data, {
    header: true,
    escapeFormulae: true,
  });
  const csvData = new Blob([final], { type: 'text/csv;charset=utf-8;' });
  saveAs(csvData, filename);
  terra_store.terra_track_events('Scan - Export data');
}

function getProps() {
  return {
    asset_name: common_store.active_asset.name || 'Untitled',
    current_date: dayjs().format('YYYY-MMM-DD'),
  };
}

onMounted(async () => {
  try {
    state.is_loading = true;
    await getData();
    initOverviewData();
    const grouped_data = groupBy(state.serial_numbers_data, 'project_uid');
    Object.keys(grouped_data).forEach((uid) => {
      state.metrics_data_map[uid].duplicates = grouped_data?.[uid]?.length || 0;
    });
    state.has_permission = (state.overview_data.module_progress.total > 0);
    state.is_loading = false;
  }
  catch (error) {
    logger.error(error);
    state.is_loading = false;
  }
});
</script>

<template>
  <div class="p-6">
    <div class="text-lg font-semibold">
      {{ terra_store.container.name }}
    </div>
    <HawkLoader v-if="state.is_loading" />
    <HawkIllustrations v-else-if="!state.has_permission" type="get-started" for="terra-scan" @onCtaClick="$emit('handleChatToggle')" />
    <div v-else>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mt-6 mb-2 flex-wrap">
        <div class="border rounded-xl h-28 p-3">
          <div class="text-md font-medium mb-3">
            {{ $t('Inverter completion') }}
          </div>
          <div class="flex items-center mb-3">
            <div class="font-semibold text-lg mr-2">
              {{ state.overview_data.inverter_completion.percentage }}%
            </div>
            <HawkBadge :is_rounded="false" :color="getColor('inverter_completion').badge">
              {{ state.overview_data.inverter_completion.completed }}/{{ state.overview_data.inverter_completion.total }}
            </HawkBadge>
          </div>
          <TerraProgressBar :progress_details="[{ color: getColor('inverter_completion').color, percentage: state.overview_data.inverter_completion.percentage }]" />
        </div>
        <div class="border rounded-xl h-28 p-3">
          <div class="text-md font-medium mb-3">
            {{ $t('Tracker completion') }}
          </div>
          <div class="flex items-center mb-3">
            <div class="font-semibold text-lg mr-2">
              {{ state.overview_data.tracker_completion.percentage }}%
            </div>
            <HawkBadge :is_rounded="false" :color="getColor('tracker_completion').badge">
              {{ state.overview_data.tracker_completion.completed }}/{{ state.overview_data.tracker_completion.total }}
            </HawkBadge>
          </div>
          <TerraProgressBar :progress_details="[{ color: getColor('tracker_completion').color, percentage: state.overview_data.tracker_completion.percentage }]" />
        </div>
        <div class="border rounded-xl h-28 p-3">
          <div class="text-md font-medium mb-3">
            {{ $t('Module progress') }}
          </div>
          <div class="flex items-center mb-3">
            <div class="font-semibold text-lg mr-2">
              {{ state.overview_data.module_progress.percentage }}%
            </div>
            <HawkBadge :is_rounded="false" :color="getColor('module_progress').badge">
              {{ state.overview_data.module_progress.completed }}/{{ state.overview_data.module_progress.total }}
            </HawkBadge>
          </div>
          <TerraProgressBar :progress_details="[{ color: getColor('module_progress').color, percentage: state.overview_data.module_progress.percentage }]" />
        </div>

        <div class="border rounded-xl h-28 p-3">
          <div class="text-md font-medium mb-3">
            {{ $t('Tracker progress') }}
          </div>
          <div class="flex flex-col justify-end items-start h-12">
            <div class="flex flex-wrap gap-x-4 gap-y-1">
              <div v-for="(progress, i) in state.overview_data.tracker_progress" :key="i" class="flex items-center">
                <div class="mr-2 w-2 h-2 rounded-full" :style="{ backgroundColor: progress.color }" />
                <div class="text-xs font-medium">
                  {{ progress.label }}
                </div>
              </div>
            </div>
            <TerraProgressBar class="!mt-3" :progress_details="state.overview_data.tracker_progress.progress_details" />
          </div>
        </div>
      </div>
      <TerraMetricsTable
        :data="state.metrics_data_map"
        :projects="state.projects"
        :filename_options="getProps()"
        @save-file="saveFile"
      />
      <TerraProgressHistory
        v-if="state.scan_history.data.length"
        :data="state.scan_history.data"
        :columns="state.scan_history.columns"
      />
      <TerraDuplicateTable
        :data="state.serial_numbers_data"
        :projects="state.projects"
        :can_modify_features="can_modify_features"
        :filename_options="getProps()"
        @save-file="saveFile"
        @clear-duplicates="state.serial_numbers_data = []"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  :deep() {
    th {
      font-size: 14px;
      color: #475467,
    }
  }
</style>
