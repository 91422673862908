import { useI18nStore } from '~/common/stores/i18n.store';

export function ERROR_STATUS(code){
  const $t = useI18nStore().$t;
  const error_code = {
    no_permission: {
      title: $t('No access'),
      message: $t('Please contact your administrator for access.'),
    },
    // item
    item_name_exists: {
      title: $t('Item already exists'),
      message: $t('Item name already exists. Please enter a different item name to continue.'),
    },
    item_number_exists: {
      title: $t('Item already exists'),
      message: $t('Item code already exists. Please enter a different item code to continue.'),
    },
    item_creation_failed: {
      title: $t('Failed to create item'),
      message: $t('Item creation failed. Please try again.'),
    },
    item_deletion_failed: {
      title: $t('Failed to delete item'),
      message: $t('Item deletion failed. Please try again.'),
    },
    item_stock_export_failed: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    item_stock_aggregate_failed: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    update_item_status_failed: {
      title: $t('Failed to update item status'),
      message: $t('Item status update failed. Please try again.'),
    },
    // warehouse
    warehouse_creation_failed: {
      title: $t('Failed to create warehouse'),
      message: $t('Warehouse creation failed. Please try again.'),
    },
    warehouse_name_exists: {
      title: $t('Warehouse name already exists'),
      message: $t('Warehouse name already exists. Please enter a different name to continue.'),
    },
    warehouse_number_exists: {
      title: $t('Warehouse code already exists'),
      message: $t('Warehouse code already exists. Please enter a different code to continue.'),
    },
    // warehouse type
    warehouse_type_exists: {
      title: $t('Warehouse type already exists'),
      message: $t('Warehouse type already exists. Please enter a different warehouse type to continue.'),
    },
    warehouse_type_deletion_failed: {
      title: $t('Failed to delete warehouse'),
      message: $t('Warehouse deletion failed. Please try again.'),
    },
    // bom
    bom_warehouse_invalid: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    invalid_bom_format: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    bom_sync_failed: {
      title: $t('Failed to sync bill of material'),
      message: $t('Bill of material could not be synced. Please try again. '),
    },
    // vendor
    vendor_name_exists: {
      title: $t('Vendor already exists'),
      message: $t('Vendor already exists. Please enter a different vendor to continue.'),
    },
    vendor_exists: {
      title: $t('Vendor already exists'),
      message: $t('Vendor already exists. Please enter a different vendor to continue.'),
    },
    vendor_deletion_failed: {
      title: $t('Failed to delete vendor'),
      message: $t('Vendor deletion failed. Please try again.'),
    },
    // measurement unit
    measurement_symbol_exists: {
      title: $t('Measurement symbol already exists'),
      message: $t('A measurement with this symbol already exists. Please enter a different symbol to continue.'),
    },
    measurement_name_exists: {
      title: $t('Measurement already exists'),
      message: $t('Measurement already exists. Please enter a different name for your measurement to continue.'),
    },
    measurement_deletion_failed: {
      title: $t('Failed to delete measurement'),
      message: $t('Measurement deletion failed. Please try again.'),
    },
    // item type
    item_type_name_exists: {
      title: $t('Item type already exists'),
      message: $t('Item type already exists. Please enter a different item type to continue.'),
    },
    item_type_exists: {
      title: $t('Item type already exists'),
      message: $t('Item type already exists. Please enter a different item type to continue.'),
    },
    item_type_deletion_failed: {
      title: $t('Failed to delete item type'),
      message: $t('Item type deletion failed. Please try again.'),
    },
    // category
    category_name_exists: {
      title: $t('Item type already exists'),
      message: $t('Item type already exists. Please enter a different item type to continue.'),
    },
    category_exists: {
      title: $t('Item type already exists'),
      message: $t('Item type already exists. Please enter a different item type to continue.'),
    },
    category_deletion_failed: {
      title: $t('Failed to delete item type'),
      message: $t('Item type deletion failed. Please try again.'),
    },
    // stock status
    stock_status_name_exists: {
      title: $t('Stock status already exists'),
      message: $t('Stock status already exists. Please enter a different stock status to continue.'),
    },
    stock_status_exists: {
      title: $t('Stock status already exists'),
      message: $t('Stock status already exists. Please enter a different stock status to continue.'),
    },
    stock_status_deletion_failed: {
      title: $t('Failed to delete stock status'),
      message: $t('Stock status deletion failed. Please try again.'),
    },
    // custom fields
    custom_field_creation_failed: {
      title: $t('Failed to create custom field'),
      message: $t('Custom field creation failed. Please try again.'),
    },
    custom_field_deletion_failed: {
      title: $t('Failed to delete custom field'),
      message: $t('Custom field deletion failed. Please try again.'),
    },
    custom_field_remove_failed: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    // transaction
    transactions_export_failed: {
      title: $t('Failed to export transactions'),
      message: $t('Transactions export failed. Please try again.'),
    },
    transaction_export_failed: {
      title: $t('Failed to export transaction'),
      message: $t('Transaction export failed. Please try again.'),
    },
    transaction_number_generation_failed: {
      title: $t('Failed to generate transaction number'),
      message: $t('Transaction number generation failed. Please try again.'),
    },
    transaction_serial_number_invalid: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_notify_failed: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_number_exists: {
      title: $t('Transaction number already exists'),
      message: $t('Transaction number already exists. Please enter a different transaction number to continue.'),
    },
    transaction_date_invalid: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_adjustment_item_invalid: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_draft_failed: {
      title: $t('Failed to create a draft of the transaction'),
      message: $t('Transaction draft creation failed. Please try again. '),
    },
    transaction_publish_failed: {
      title: $t('Failed to publish transaction'),
      message: $t('Transaction could not be published. Please try again.'),
    },
    transaction_invalid_format: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    invalid_warehouse_workflow: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    invalid_vendor_workflow: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    item_price_not_match: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    currency_not_match: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    invalid_quantity: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    inactive_item: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    asset_required: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    form_required: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_published: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_stock_invalid: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_pallet_required: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_invalid_stock_status: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_not_valid_stock: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_stock_quantity_not_valid: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_required_stock: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_not_deleted: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    invalid_email: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    invalid_transaction_index: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    custom_field_required: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    serial_number_exists: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    custom_field_name_exists: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    transaction_item_used: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
    invalid_destination_warehouse: {
      title: $t('Something went wrong'),
      message: $t('Please try again'),
    },
  };
  return error_code?.[code] || {}
}
