<script setup>
import { computed } from 'vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  uid: {
    type: String,
    default: null,
  },
});

const system_model_store = useSystemModelStore();

const component_name = computed(() => system_model_store.templates_components_map[props.uid]?.name);
</script>

<template>
  <hawk-badge v-if="component_name" v-tippy="component_name" custom_classes="mr-1 mb-1 border !border-gray-300 rounded-lg font-medium" :is_rounded="false">
    {{ $filters.truncate(component_name) }}
  </hawk-badge>
</template>
