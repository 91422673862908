<script setup>
import { countBy, flatMap } from 'lodash-es';
import dayjs from 'dayjs';

import { useCommonStore } from '~/common/stores/common.store';
import { useTagsStore } from '~/common/stores/tags.store';

const props = defineProps({
  tasks: {
    type: Array,
    default: () => ([]),
  },
  // status and priority filtered data
  block_one_filtered_data: {
    type: Array,
    default: () => ([]),
  },
  // status, priority, category filtered data
  block_two_filtered_data: {
    type: Array,
    default: () => ([]),
  },
  // status, priority, category and tag filtered data
  block_three_filtered_data: {
    type: Array,
    default: () => ([]),
  },
  active_date: {
    type: Date,
    default: () => new Date(),
  },
  active_date_range: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['filter', 'setScheduleDate']);

const task_store = inject('task_store');

const common_store = useCommonStore();
const tags_store = useTagsStore();

const form$ = ref(null);
const form_data = ref(null);

const state = reactive({
  priority_visible: true,
  category_visible: true,
  tags_visible: true,
  assignees_visible: true,
  status_visible: true,
});

const priority_data = computed(() => {
  const count = countBy(props.tasks, t => t.priority);
  return task_store.priority_values.map(priority_data => ({ ...priority_data, count: count[priority_data.value] || 0 }));
});

const status_data = computed(() => {
  const count = countBy(props.tasks, t => t.status);
  return task_store.status_values.map(status_data => ({ ...status_data, count: count[status_data.value] || 0 }));
});

const category_data = computed(() => {
  const block_one_category_count = countBy(props.block_one_filtered_data, task => task.category);
  const data = props.tasks.reduce((obj, task) => {
    const category_obj = common_store.get_category(task.category);
    if (task.category !== 'not-set' && category_obj?.uid)
      obj[task.category] = {
        ...category_obj,
        count: block_one_category_count?.[task.category] || 0,
        value: task.category,
      };
    return obj;
  }, {});
  return [...Object.values(data).sort((a, b) => a.name.localeCompare(b.name)), {
    name: 'Not set',
    value: 'not-set',
    uid: 'not-set',
    count: block_one_category_count?.['not-set'] || 0,
  }];
});

const tags_data = computed(() => {
  const block_two_tags_count = countBy(flatMap(props.block_two_filtered_data, task => task.tags));
  const tags = flatMap(props.tasks, task => task.tags);
  const task_tags = tags.reduce((obj, tag) => {
    const tag_obj = tags_store.get_tag(tag);
    if (tag_obj?.uid)
      obj[tag] = {
        ...tag_obj,
        count: block_two_tags_count?.[tag] || 0,
        value: tag,
      };
    return obj;
  }, {});
  return [...Object.values(task_tags).sort((a, b) => a.name.localeCompare(b.name)), {
    name: 'Not set',
    count: block_two_tags_count?.['not-set'] || 0,
    member: false,
    value: 'not-set',
    uid: 'not-set',
  }];
});

const assignees_data = computed(() => {
  const block_three_assignees_count = countBy(flatMap(props.block_three_filtered_data, task => task.assignees));
  const assignees = flatMap(props.tasks, task => task.assignees);
  const task_assignees = assignees.reduce((obj, assignee) => {
    const assignee_obj = common_store.get_user(assignee) || common_store.get_team(assignee);
    if (assignee_obj?.uid)
      obj[assignee] = {
        ...assignee_obj,
        name: common_store.get_user_or_team_name(assignee),
        count: block_three_assignees_count?.[assignee] || 0,
        member: !!common_store.get_user(assignee),
        value: assignee,
      };
    return obj;
  }, {});
  return [...Object.values(task_assignees).sort((a, b) => a.name.localeCompare(b.name)), {
    name: 'Unassigned',
    count: block_three_assignees_count['not-set'] || 0,
    member: false,
    value: 'not-set',
    uid: 'not-set',
  }];
});

function setFilter() {
  const payload = form_data.value;
  delete payload.dates;
  emit('filter', payload);
}
function setDate(value) {
  if (!dayjs(props.active_date).isSame(dayjs(value), 'day'))
    emit('setScheduleDate', value);
}

watch(() => props.active_date, (newValue, oldValue) => {
  if (oldValue !== newValue)
    form_data.value.dates = newValue;
});
watch(() => props.active_date_range, (newVal, oldVal) => {
  const start_range_changed = !dayjs(newVal[0]).isSame(oldVal[0]);
  const end_range_changed = !dayjs(newVal[1]).isSame(oldVal[1]);
  if (start_range_changed || end_range_changed) {
    const dates = form_data.value.dates;
    form$.value.clear();
    form_data.value.dates = dates;
  }
});
</script>

<template>
  <Vueform ref="form$" v-model="form_data" sync size="sm">
    <div class="col-span-12">
      <DateTimeElement
        name="dates"
        class="date_time"
        :options="{
          inline: true,
          format: 'dd/MM/yyyy',
          monthChangeOnScroll: false,
        }"
        @change="setDate"
      />
      <hr class="mx-2 border-gray-300">
      <div class="px-2">
        <div class="flex justify-between cursor-pointer mt-4 my-2" @click="state.priority_visible = !state.priority_visible;">
          <span class="text-sm font-medium text-gray-500 pl-3">{{ $t('Priority') }}</span>
          <IconHawkChevronUp v-if="state.priority_visible" />
          <IconHawkChevronDown v-else />
        </div>
        <div v-show="state.priority_visible">
          <CheckboxgroupElement
            v-if="priority_data.length"
            name="priority"
            :items="priority_data"
            @change="setFilter()"
          >
            <template #checkbox="{ item, value, el$, classes, isDisabled, id, name }">
              <div class="flex justify-between items-center w-full px-3 py-[6px] hover:bg-gray-50 rounded-lg">
                <span class="flex">
                  <input
                    :id="id"
                    v-model="el$.model"
                    type="checkbox"
                    :value="value"
                    :class="classes.input"
                    class="!mr-0"
                    :name="name"
                    :disabled="isDisabled"
                  >
                  <TaskPriority :priority="item.value" class="ml-3" />
                </span>
                <span>
                  <HawkBadge size="sm" color="gray">
                    {{ item.count }}
                  </HawkBadge>
                </span>
              </div>
            </template>
          </CheckboxgroupElement>
          <div v-else class="text-sm font-regular text-gray-400 ml-3">
            {{ $t('No tasks available') }}
          </div>
        </div>
      </div>
      <hr class="mt-4 mx-2 border-gray-300">
      <div class="px-2">
        <div class="flex justify-between mb-2 cursor-pointer mt-4" @click="state.status_visible = !state.status_visible;">
          <span class="text-sm font-medium text-gray-500 pl-3">{{ $t('Status') }}</span>
          <IconHawkChevronUp v-if="state.status_visible" />
          <IconHawkChevronDown v-else />
        </div>
        <div v-show="state.status_visible">
          <CheckboxgroupElement
            v-if="status_data.length"
            name="status"
            :items="status_data"
            @change="setFilter"
          >
            <template #checkbox="{ item, value, el$, classes, isDisabled, id, name }">
              <div class="flex justify-between items-center w-full px-3 py-[6px] hover:bg-gray-50 rounded-lg">
                <span class="flex items-center">
                  <input
                    :id="id"
                    v-model="el$.model"
                    type="checkbox"
                    :value="value"
                    :class="classes.input"
                    class="!mt-0 !mr-0"
                    :name="name"
                    :disabled="isDisabled"
                  >
                  <div
                    class="w-3 h-3 rounded-full mx-3"
                    :style="{ backgroundColor: item.color }"
                  />
                  <span class="text-sm font-medium text-gray-700">{{ item.label }}</span>
                </span>
                <span>
                  <HawkBadge size="sm" color="gray">
                    {{ item.count }}
                  </HawkBadge>
                </span>
              </div>
            </template>
          </CheckboxgroupElement>
          <div v-else class="text-sm font-regular text-gray-400 ml-3">
            {{ $t('No tasks available') }}
          </div>
        </div>
      </div>
      <hr class="mt-4 mx-2 border-gray-300">
      <div class="px-2">
        <div class="flex justify-between mt-4 mb-2 cursor-pointer" @click="state.category_visible = !state.category_visible">
          <span class="text-sm font-medium text-gray-500 pl-3">{{ $t('Category') }}</span>
          <IconHawkChevronUp v-if="state.category_visible" />
          <IconHawkChevronDown v-else />
        </div>
        <div v-show="state.category_visible" class="max-h-80 scrollbar">
          <CheckboxgroupElement
            v-if="category_data.length"
            name="category"
            :items="category_data"
            @change="setFilter"
          >
            <template #checkbox="{ item, el$, classes, isDisabled, id, name }">
              <div class="flex justify-between items-center w-full px-3 py-[6px] hover:bg-gray-50 rounded-lg">
                <span class="flex">
                  <input
                    :id="id"
                    v-model="el$.model"
                    type="checkbox"
                    :value="item.uid"
                    :class="classes.input"
                    class="!mr-0"
                    :name="name"
                    :disabled="isDisabled"
                  >
                  <span class="text-sm font-medium text-gray-700 ml-3">{{ item.name }}</span>
                </span>
                <span>
                  <HawkBadge size="sm" color="gray">
                    {{ item.count }}
                  </HawkBadge>
                </span>
              </div>
            </template>
          </CheckboxgroupElement>
          <div v-else class="text-sm font-regular text-gray-400 ml-3">
            {{ $t('No tasks available') }}
          </div>
        </div>
        <hr class="mt-4 border-gray-300">
        <div class="flex justify-between mt-4 mb-2 cursor-pointer " @click="state.tags_visible = !state.tags_visible">
          <span class="text-sm font-medium text-gray-500 pl-3">{{ $t('Tags') }}</span>
          <IconHawkChevronUp v-if="state.tags_visible" />
          <IconHawkChevronDown v-else />
        </div>
        <div v-show="state.tags_visible" class="max-h-80 scrollbar">
          <CheckboxgroupElement
            v-if="tags_data.length"
            name="tags"
            :items="tags_data"
            @change="setFilter"
          >
            <template #checkbox="{ item, el$, classes, isDisabled, id, name }">
              <div class="flex justify-between items-center w-full px-3 py-[6px] hover:bg-gray-50 rounded-lg">
                <span class="flex">
                  <input
                    :id="id"
                    v-model="el$.model"
                    type="checkbox"
                    :value="item.uid"
                    :class="classes.input"
                    class="!mr-0"
                    :name="name"
                    :disabled="isDisabled"
                  >
                  <span class="text-sm font-medium text-gray-700 ml-3">{{ item.name }}</span>
                </span>
                <span>
                  <HawkBadge size="sm" color="gray">
                    {{ item.count }}
                  </HawkBadge>
                </span>
              </div>
            </template>
          </CheckboxgroupElement>
          <div v-else class="text-sm font-regular text-gray-400 ml-3">
            {{ $t('No tasks available') }}
          </div>
        </div>
      </div>
      <hr class="mt-4 mx-2 border-gray-300">
      <div class="px-2">
        <div class="flex justify-between mt-4 mb-2 cursor-pointer" @click="state.assignees_visible = !state.assignees_visible">
          <span class="text-sm font-medium text-gray-500 pl-3">{{ $t('Assignees') }}</span>
          <IconHawkChevronUp v-if="state.assignees_visible" />
          <IconHawkChevronDown v-else />
        </div>
        <div v-show="state.assignees_visible">
          <CheckboxgroupElement
            v-if="assignees_data.length"
            name="assignees"
            :items="assignees_data"
            @change="setFilter"
          >
            <template #checkbox="{ item, el$, classes, isDisabled, id, name }">
              <div class="flex justify-between items-center w-full px-3 py-[6px] hover:bg-gray-50 rounded-lg">
                <span class="flex">
                  <input
                    :id="id"
                    v-model="el$.model"
                    type="checkbox"
                    :value="item.uid"
                    :class="classes.input"
                    class="!mt-[5px] !mr-0"
                    :name="name"
                    :disabled="isDisabled"
                  >
                  <HawkMembers
                    :members="item.uid"
                    :name_truncate_length="20"
                    type="label"
                    size="xs"
                    class="ml-3"
                  />
                </span>
                <span>
                  <HawkBadge size="sm" color="gray">
                    {{ item.count }}
                  </HawkBadge>
                </span>
              </div>
            </template>
          </CheckboxgroupElement>
          <div v-else class="text-sm font-regular text-gray-400 ml-3">
            {{ $t('No tasks available') }}
          </div>
        </div>
      </div>
    </div>
  </Vueform>
</template>

<style lang="scss" scoped>
.date_time {
  :deep(.dp__menu){
    z-index: 1;
    min-width: 0;
    border: none;
  }
}
</style>
