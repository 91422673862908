<script setup>
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  data: {
    type: Array,
    default: () => ([]),
  },
});

const emit = defineEmits(['update']);

const form_tree_select = ref(null);

const state = reactive({
  is_loading: false,
});

function handleItemClick(e) {
  if (!form_tree_select.value)
    return;
  form_tree_select.value.data = e;
  const payload = e.reduce((acc, curr) => {
    if (curr.uid?.includes('block_'))
      acc.blocks.push(curr.uid);
    else
      acc.fields.push(curr.uid);
    return acc;
  }, { fields: [], blocks: [] });
  emit('update', payload);
}
</script>

<template>
  <div
    class="w-72 scrollbar h-[calc(100vh-140px)] sticky top-20 p-4 text-sm hidden lg:block"
  >
    <HawkLoader v-if="state.is_loading" />
    <template v-else>
      <Vueform
        v-model="form_tree_select"
        sync
        size="sm"
      >
        <HawkTreeSelect
          :key="data?.length"
          :options="{
            name: 'data',
            object: true,
          }"
          add_classes="!w-64 !h-[calc(100vh-172px)]"
          children_key="children"
          data_type="object"
          label_key="name"
          value_key="uid"
          :data="data"
          :show_tree_select="true"
          :expand_all_items="true"
          select_type="LEAF_PRIORITY"
          @updateForm="handleItemClick"
        />
      </Vueform>
    </template>
  </div>
</template>
