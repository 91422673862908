<script setup>
import { reactive } from 'vue';
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  custom_view_instance: {
    type: Object,
  },
  current_view_payload: {
    type: Object,
  },
});
const emit = defineEmits(['closeSaveView']);

const $t = inject('$t');
const $toast = inject('$toast');
const terra_store = useTerraStore();
const route = useRoute();

const state = reactive({
  is_saving_view: false,
});

const get_current_view = computed(() => route.query.view_uid || 'default');

async function updateCurrentView() {
  state.is_saving_view = true;
  await props.custom_view_instance.updateCustomView({ uid: get_current_view.value, payload: props.current_view_payload });
  $toast({
    title: $t('Successfully saved view'),
    text: $t('Custom view has been saved successfully.'),
    type: 'success',
    position: 'bottom-right',
  });
  terra_store.terra_track_events('View updated');
  state.is_saving_view = false;
  emit('closeSaveView');
}
async function handleSaveView() {
  if (route.query.view_uid) {
    await updateCurrentView();
    terra_store.terra_track_events('Save view');
  }
  else {
    props.custom_view_instance.openViewPopup();
    terra_store.terra_track_events('Create view');
  }
}
</script>

<template>
  <HawkButton type="outlined">
    <HawkButton type="link" :loading="state.is_saving_view" @click="handleSaveView">
      <IconHawkSaveOne /> {{ $t('Save view') }} <IconHawkXClose class="w-4 h-4" @click.stop="emit('closeSaveView')" />
    </HawkButton>
  </HawkButton>
</template>
