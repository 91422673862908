import AcceptInvitation from '~/auth/pages/accept-invitation.vue';
import DomainConfirmation from '~/auth/pages/domain-confirmation.vue';
import ForgotPassword from '~/auth/pages/forgot-password.vue';
import HandleSignIn from '~/auth/pages/handle-social-login.vue';
import EmailInstructionsInfo from '~/auth/pages/instructions-info.vue';
import InvitationHandler from '~/auth/pages/invitations-handler.vue';
import ResetPassword from '~/auth/pages/reset-password.vue';
import SignIn from '~/auth/pages/sign-in.vue';
import SignUp from '~/auth/pages/sign-up.vue';
import VerifyEmail from '~/auth/pages/verify-email.vue';

const routes = [
  {
    path: '/signup',
    name: 'sign-up',
    component: SignUp,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Sign Up',
    },
  },
  {
    path: '/auth',
    redirect: { name: 'sign-in' },
    meta: {
      noAuthRequires: true,
    },
  },
  {
    path: '/auth/sign-in',
    name: 'sign-in',
    component: SignIn,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Sign In',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Forgot Password',
    },
  },
  {
    path: '/auth/check-email',
    name: 'check-email',
    component: EmailInstructionsInfo,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Check Email',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Reset Password',
    },
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: AcceptInvitation,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Confirm',
    },
  },
  {
    path: '/invitations/:org_id',
    name: 'invitations',
    component: InvitationHandler,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Invitation',
    },
  },
  {
    path: '/confirm-domain',
    name: 'confirm-domain',
    component: DomainConfirmation,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Domain confirmation',
    },
  },
  {
    path: '/auth/verify-email',
    name: 'verify-email',
    component: VerifyEmail,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
      title: 'Verify Email',
    },
  },
  {
    path: '/auth/callback',
    name: 'handle-sign-in',
    component: HandleSignIn,
    meta: {
      noAuthRequires: true,
      layout: 'auth',
    },
  },

];
export default routes;
