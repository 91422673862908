// Place package-related functions (imports, licensing etc.) here to reduce redundancy

function licenseFusioncharts(fusioncharts) {
  return new Promise((resolve, _reject) => {
    fusioncharts.ready(() => {
      fusioncharts.options.license({
        key: import.meta.env.VITE_APP_FUSIONCHART_KEY,
        creditLabel: false,
      });
      fusioncharts.options.SVGDefinitionURL = 'relative';

      resolve();
    });
  });
}

export async function import_fusioncharts() {
  const VueFusionChartsComponent = (await import('vue-fusioncharts/component')).default;
  const FusionCharts = (await import('fusioncharts')).default;
  const Charts = (await import('fusioncharts/fusioncharts.charts')).default;
  const FusionTheme = (await import('fusioncharts/themes/fusioncharts.theme.fusion')).default;
  const TimeSeries = (await import('fusioncharts/fusioncharts.timeseries')).default;
  const Widgets = (await import('fusioncharts/fusioncharts.widgets')).default;
  const ExcelExport = (await import('fusioncharts/fusioncharts.excelexport')).default;
  const PowerCharts = (await import('fusioncharts/fusioncharts.powercharts')).default;
  await licenseFusioncharts(FusionCharts);

  return {
    VueFusionChartsComponent,
    FusionCharts,
    Charts,
    FusionTheme,
    TimeSeries,
    Widgets,
    PowerCharts,
    ExcelExport,
  };
}
