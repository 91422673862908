<script setup>
import { computed, onMounted } from 'vue';
import { Validator } from '@vueform/vueform';
import { flatten, uniq } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import HawkSubmitButton from '~/common/components/atoms/hawk-submit-button.vue';

const props = defineProps({
  value: {
    type: Object,
    default: null,
  },
  is_published: {
    type: Boolean,
    default: false,
  },
  has_cancel: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close', 'save', 'change']);

const $t = inject('$t');

const common_store = useCommonStore();
const route = useRoute();

const isValidEmail = ref({});

const $services = inject('$services');

const state = reactive({
  loading: false,
  fetched_users: [],
});

const form$ = ref(null);
const form = ref(null);

async function save() {
  const form_data = form$.value.requestData;
  emit('save', form_data);
}

function formatData(name, value) {
  return {
    [name]: value.map(email => ({
      email,
      name: null,
      uid: null,
    })),
  };
}

function formatLoad(value) {
  return value.map(item => item.email);
}

const checkEmails = class extends Validator {
  get message() {
    return 'Please enter valid email address';
  }

  check(value) {
    // Custom validation, check each value(type-object) whether it is a valid email address
    return (flatten(value)).map((val) => {
      const emailFormat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      isValidEmail.value[val] = val.match(emailFormat);
      return !val.match(emailFormat);
    }).every(email => !email);
  }
};

const users_in_assets = computed(() => common_store?.members_scope_users(route?.params?.asset_id) || []);
const teams_in_assets = computed(() => common_store?.scope_teams(route?.params?.asset_id).map((team) => {
  return {
    ...team,
    is_team: true,
    email: team?.users.map(uid => common_store.get_user(uid)?.email),
  };
}) || []);

async function setUsers(search) {
  state.fetched_users = [
    ...(users_in_assets.value?.length ? [{ label: 'Members', items: users_in_assets.value }] : []),
    ...(teams_in_assets.value?.length ? [{ label: 'Teams', items: teams_in_assets.value }] : []),
  ];
}

function added(item) {
  form$.value.update({
    emails: uniq(flatten(item)),
  });
}

onMounted(async () => {
  state.loading = true;
  await setUsers();
  state.loading = false;
});
</script>

<template>
  <HawkModalContainer>
    <Vueform
      ref="form$"
      v-model="form"
      :display-errors="false"
      class="grid grid-flow-col gap-4"
      size="sm"
      :endpoint="save"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ $t('Notify team members') }}
          </template>
          <template #subtitle>
            {{ $t('You can select users or enter their email address. They will receive transaction details through email.') }}
          </template>
        </HawkModalHeader>
        <HawkModalContent :is_scroll="false" class="max-w-[575px] max-h-[575px]">
          <hawk-email-tags-input
            class="mb-4"
            :options="{
              'name': 'emails',
              'label': $t('Notify'),
              'placeholder': $t('Enter Email Addresses'),
              'tags_removable': true,
              'create': true,
              'class': 'mb-4',
              'show-options': true,
              'isValidEmail': isValidEmail,
              'label-prop': 'email',
              'value-prop': 'email',
              'loading': state.loading,
              'close-on-select': true,
              'groups': true,
              'track-by': 'email',
              'format-data': formatData,
              'format-load': formatLoad,
              'handleCreateInline': added,
              'add-classes': {
                TagsElement: {
                  select: {
                    tags: '!pl-2',
                    placeholder: '!pl-3',
                  },
                },
              },
            }"
            :items="state.fetched_users"
            :rules="['required', checkEmails]"
          />
        </HawkModalContent>
        <HawkModalFooter class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-2" type="outlined" color="gray" @click="$emit('close')">
                {{ props.has_cancel ? $t('Cancel') : $t('Skip') }}
              </hawk-button>
              <HawkSubmitButton :form$="form$">
                {{ $t('Send') }}
              </HawkSubmitButton>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
