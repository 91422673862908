<script setup>
import { useThermStore } from '~/therm/store/therm.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  defects: {
    type: Array,
  },
});
const emit = defineEmits(['close']);

const therm_store = useThermStore();
const { $t, $toast, route } = useCommonImports();

const form$ = ref(null);

const is_save_disabled = computed(() => {
  const form_data = form$.value?.data;
  if (!form_data)
    return true;
  return !(
    Object.keys(form_data).some((key) => {
      if (key === 'assignees_group')
        return !!form_data[key]?.assignees?.length;
      else
        return form_data[key] !== null && form_data[key] !== undefined;
    }));
});

function getPayload() {
  const payload = {};
  const form_data = form$.value.data;

  if (form_data.status)
    payload.status = form_data.status;

  if (form_data.priority)
    payload.priority = form_data.priority;

  if (form_data.assignees_group?.assignees?.length)
    payload.assignees = {
      [form_data.assignees_group.assignees_option]: form_data.assignees_group.assignees.map(assignee => assignee.uid),
    };

  return payload;
}

async function onSave() {
  try {
    const payload = getPayload();
    await therm_store.bulkUpdateFeatures(props.defects, payload);
    $toast({
      title: $t('Success'),
      text: $t('Defects updated successfully'),
      type: 'success',
    });
    emit('close');
  }
  catch (error) {
    $toast({
      title: $t('Error'),
      text: error.data.message || $t('Something went wrong'),
      type: 'error',
    });
    logger.log(error);
  }
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Update') }} {{ defects.length }} {{ $t('defects') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <SelectElement
            class="mb-6"
            name="status"
            label-prop="name"
            value-prop="uid"
            track-by="name"
            :label="$t('Defect status')"
            :placeholder="$t('Select Status')"
            :items="therm_store?.status_configs"
            :search="true"
          />
          <SelectElement
            class="mb-6"
            name="priority"
            label-prop="name"
            value-prop="uid"
            track-by="name"
            :label="$t('Defect priority')"
            :placeholder="$t('Select Priority')"
            :items="therm_store?.priority_configs"
            :search="true"
          />
          <ObjectElement
            class="mb-4"
            name="assignees_group"
            :label="$t('Assignees')"
          >
            <RadiogroupElement
              name="assignees_option"
              default="add"
              :items="{
                add: $t('Assign'),
                remove: $t('Unassign'),
              }"
            />
            <HawkAssigneeInput
              class="mb-6"
              :options="{
                name: 'assignees',
                has_teams: true,
                placeholder: $t('Select Assignees'),
              }"
              :asset_id="route.params.asset_id"
              :multi="true"
              :format_load="true"
            />
          </ObjectElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" :disabled="is_save_disabled" name="submit" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
