<script setup>
const props = defineProps({
  map_data_legend: {
    type: Array,
    default: () => [],
  },
});
</script>

<template>
  <div>
    <div v-for="(val, i) in (props.map_data_legend || [])" :key="i" class="p-4">
      <div class="text-sm font-medium">
        {{ val.name }}
      </div>

      <div v-for="(feature, index) in val.featureTypes" :key="index">
        <div class="flex justify-between items-center py-1">
          <div class="flex items-center">
            <div
              class="cursor-pointer min-w-[14px] w-3.5 h-3.5 rounded-full bg-white"
              :style="{ border: `2px solid ${feature.color}` }"
            />
            <HawkText class="text-sm ml-2" :content="feature.name" />
          </div>
          <div class=" text-xs font-medium text-end text-gray-600 bg-gray-100 rounded-full px-2 py-1 cursor-pointer">
            {{ feature.count }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
