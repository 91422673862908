<script setup>
import { storeToRefs } from 'pinia';
import { useDashboardThermStore } from '~/dashboard/store/dashboard-therm.store.js';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const emit = defineEmits(['update']);

const dashboard_therm_store = useDashboardThermStore();

const {
  therm_configuration,
  group_options,
  therm_view_uid,
  is_loading_groups,
} = storeToRefs(dashboard_therm_store);

const { set_groups } = dashboard_therm_store;

const selected_groups = ref({ data: [] });
const hawk_tree_reset = ref(0);

const widget_asset = computed(() => therm_configuration.value?.asset_id || null);

function updateGroups(data) {
  selected_groups.value.data = data;

  emit('update', data);
}

watch(therm_view_uid, () => {
  // reset hawk tree select component to use new data
  hawk_tree_reset.value += 1;
  selected_groups.value.data = [];
  set_groups();
});

watch(widget_asset, (new_val, old_val) => {
  if (new_val !== old_val && old_val)
    updateGroups([]);
});

set_groups();
</script>

<template>
  <div>
    <HawkLoader v-if="is_loading_groups" class="!m-0 !mb-4" />
    <Vueform
      v-else-if="group_options?.length"
      :key="hawk_tree_reset + group_options.length"
      v-model="selected_groups"
      size="sm"
      sync
      class="mb-6"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
    >
      <HawkTreeSelect
        :options="{
          name: 'data',
          label: $t('Groups'),
        }"
        :data="group_options"
        children_key="groups"
        label_key="name"
        value_key="uid"
        select_type="LEAF_PRIORITY"
        :initial_state="therm_configuration.group || []"
        @updateForm="updateGroups($event)"
      />
    </Vueform>
  </div>
</template>
