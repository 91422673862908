<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  on_file_drop: {
    type: Function,
    required: true,
  },
});

const route = useRoute();
const auth_store = useAuthStore();

const is_dragging = ref(false);

const can_create_documents = computed(() => auth_store.check_permission('create_documents', route?.params?.asset_id));

let counter = 0;

function dragEnter(e) {
  if (e.dataTransfer.effectAllowed === 'move')
    return;

  if (!can_create_documents.value)
    return;
  counter += 1;
  is_dragging.value = true;
}

function dragLeave() {
  if (!can_create_documents.value)
    return;
  counter--;
  if (counter === 0)
    is_dragging.value = false;
}

function onDrop(e) {
  props.on_file_drop(e);
  dragLeave();
  is_dragging.value = false;
}

onMounted(() => {
  const drag_element = document.getElementById('table_wrapper');
  drag_element.addEventListener('dragenter', dragEnter);
  drag_element.addEventListener('dragleave', dragLeave);
});

onUnmounted(() => {
  const drag_element = document.getElementById('table_wrapper');
  drag_element?.removeEventListener('dragenter', dragEnter);
  drag_element?.removeEventListener('dragleave', dragLeave);
});
</script>

<template>
  <div
    v-show="is_dragging" id="table_wrapper" @drop="onDrop" @dragover.prevent
    @dragenter.prevent
  >
    <div
      class="form-shadow-input form-bg-input form-color-input form-border-width-input border-dashed w-full transition inline-flex flex-col items-center justify-center p-6 cursor-pointer text-center form-radius-large form-border-color-input drag-n-drop-full"
    >
      <span
        class=" inline-block w-9 h-8 mask-bg mask-form-inbox-in form-bg-primary"
      /><span class=" font-semibold mt-3">Upload files</span><span>Drop files or click here to upload</span>
    </div>
  </div>
  <div v-show="!is_dragging">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
#table_wrapper {
  --tw-bg-opacity: 1;
  .drag-n-drop-full {
    position: relative;
    width: 100%;
    min-height: 70vh;
    border-style: dashed;
    border-color: #1570EF;
    border-width: 2px;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    font-size: 14px;
    color: #4b5563;
    .font-semibold.mt-3 {
      margin-top: 0;
      color: rgb(37 99 235 / var(--tw-bg-opacity));
      margin-right: 5px;
    }
    .mask-bg.mask-form-inbox-in {
      margin-right: 10px;
      background-color: rgb(37 99 235 / var(--tw-bg-opacity));
    }
  }
}
</style>
