<script setup>
import { sortBy } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';
import { useTransmittalsStore } from '~/dms/store/transmittals.store';

const emit = defineEmits(['close']);

const route = useRoute();
const router = useRouter();
const transmittal_store = useTransmittalsStore();
const documents = sortBy(transmittal_store.transmittal?.documents || [], document_obj => document_obj.name.toLowerCase());

const selected_document_index = ref(documents.findIndex(document => route.params.document_uid === document.uid));
const form = ref({
  select_document: route.params.document_uid,
});

function changeRoute() {
  emit('close');
  router.replace({ name: 'document-markup', params: { document_uid: form.value.select_document, transmittal_uid: route.params.transmittal_uid } });
  selected_document_index.value = documents.findIndex(document => route.params.document_uid === document.uid);
}
function slideHandler(type) {
  if (type === 'prev')
    selected_document_index.value--;

  if (type === 'next')
    selected_document_index.value++;

  emit('close');
  router.replace({ name: 'document-markup', params: { document_uid: documents[selected_document_index.value].uid, transmittal_uid: route.params.transmittal_uid } });
}
</script>

<template>
  <div v-if="(transmittal_store.transmittal?.documents || []).length > 1">
    <Vueform
      v-model="form"
      sync
    >
      <div class="flex gap-2 items-center col-span-12">
        <div class="border border-gray-300 p-2 rounded-md cursor-pointer" :class="{ 'pointer-events-none opacity-50': selected_document_index === 0 }" @click="() => slideHandler('prev')">
          <IconHawkArrowLeft />
        </div>
        <SelectElement
          class="w-3/4"
          name="select_document"
          :native="false"
          :can-deselect="false"
          :can-clear="false"
          track-by="name"
          :search="true"
          label-prop="name"
          value-prop="uid"
          :items="documents"
          @change="changeRoute"
        />
        <div class="border border-gray-300 p-2 rounded-md cursor-pointer" :class="{ 'pointer-events-none opacity-50': selected_document_index === documents.length - 1 }" @click="() => slideHandler('next')">
          <IconHawkArrowRight />
        </div>
      </div>
    </Vueform>
  </div>
</template>
