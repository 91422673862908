<script setup>
import { groupBy, map } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import HawkFieldsSelector from '~/common/components/organisms/hawk-fields-selector/hawk-fields-selector.vue';

const $t = inject('$t');

const { set_widget_configuration } = useDashboardStore();
const dashboard_forms_store = useDashboardFormsStore();
const form_template_detail_store = useFormTemplateDetailStore();

const forms_configuration = computed(() => dashboard_forms_store.forms_configuration);

const is_workflow = computed(() => form_template_detail_store.form_template_detail.workflow || dashboard_forms_store?.is_workflow_template);

const { open: openColumnSelectorModal, close: closeColumnSelectorModal, patchOptions } = useModal({
  component: HawkFieldsSelector,
  attrs: {
    onClose() {
      closeColumnSelectorModal();
    },
  },
});

function getFilteredData(field_data) {
  let filtered_data = {};

  if (is_workflow.value)
    filtered_data = groupBy(field_data, field => field.form_block_id ? 'tree_data' : 'root_data');
  else
    filtered_data = groupBy(field_data, field => field.section_id ? 'tree_data' : 'root_data');

  return filtered_data;
}

function transformData(data) {
  const result = [];

  const { root_data = [], tree_data = [] } = getFilteredData(data);

  root_data.forEach((field) => {
    result.push({
      ...field,
      name: field.label,
      label: field.name || field.label,
      uid: field.uid || field.key,
      disabled: field?.disabled,
    });
  });

  if (is_workflow.value) {
    const grouped_by_form_block_id = groupBy(tree_data, 'form_block_id');
    const structured_data = map(grouped_by_form_block_id, (items, form_block_id) => {
      const form = {
        name: items[0].form_block_name,
        label: items[0].form_block_name,
        uid: form_block_id,
        children: [],
      };

      const grouped_by_section_id = groupBy(items, 'section_id');

      form.children = map(grouped_by_section_id, (fields, section_id) => {
        return {
          name: fields[0].section_name,
          label: fields[0].section_name,
          uid: section_id,
          children: fields.map(field => ({
            ...field,
            name: field.name || field.label,
            label: field.name || field.label,
            uid: field.uid || field.key,
            disabled: field?.disabled,
          })),
        };
      });

      return form;
    });
    result.push(...structured_data);
  }
  else {
    const grouped_by_section_id = groupBy(tree_data, 'section_id');
    const structured_data = map(grouped_by_section_id, (items, section_id) => {
      const section = {
        name: items[0].section_name,
        label: items[0].section_name,
        uid: section_id,
        children: [],
      };

      section.children = items.map(field => ({
        ...field,
        name: field.name || field.label,
        label: field.name || field.label,
        uid: field.uid || field.key,
        disabled: field?.disabled,
      }));

      return section;
    });
    result.push(...structured_data);
  }

  return result;
}

const columns = computed(() => {
  return dashboard_forms_store.available_form_columns?.map(c => ({ ...c, name: c.label, uid: c.key })) || [];
});

function handleAddColumn() {
  patchOptions({
    component: HawkFieldsSelector,
    attrs: {
      items: transformData(columns.value),
      selected_items: forms_configuration.value.columns?.map(col => ({ ...col, name: col.label, uid: col.key })) || [],
      onClose() {
        closeColumnSelectorModal();
      },
      texts: {
        heading: $t('Choose columns'),
        left_heading: $t('Available columns'),
        right_heading: $t('Selected columns'),
      },
      emit_save_on_change: false,
      update: (columns) => {
        dashboard_forms_store.update_forms_configuration({ ...dashboard_forms_store.forms_configuration, columns });
      },
    },
  });
  openColumnSelectorModal();
}
</script>

<template>
  <div v-for="col in forms_configuration?.columns" :key="col.key" class="flex items-center mb-2 px-2 h-[36px] border border-gray-300 rounded-lg">
    {{ col.label }}
  </div>
  <hawk-button
    type="outlined"
    size="xs"
    @click="handleAddColumn"
  >
    {{ $t('Choose columns') }}
  </hawk-button>
</template>
