import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import dayjsBusinessDays from 'dayjs-business-days2';

dayjs.extend(minMax);
dayjs.extend(dayjsBusinessDays);
dayjs.setWorkingWeekdays([1, 2, 3, 4, 5, 6]);
export function getPriorDay(dayjs_date, range = 'current') {
  const prior_day_range = range;
  const prior_days = dayjs_date.subtract(prior_day_range, 'day');
  return prior_days;
}
export function safeDivision(numerator, denominator) {
  return denominator !== 0 && !isNil(numerator) && !isNil(denominator) ? numerator / denominator : null;
}

export function safeSubtraction(value1, value2) {
  value1 = value1 ?? 0;
  value2 = value2 ?? 0;
  return !isNil(value1) && !isNil(value2) ? value1 - value2 : null;
}

export function safeMultiplication(value1, value2) {
  return !isNil(value1) && !isNil(value2) ? value1 * value2 : null;
}
export function bDuration(start, end) {
  if (isNil(start) || isNil(end))
    return null;
  return dayjs(end).businessDiff(dayjs(start));
}
