<script setup>
import { computed, inject, ref } from 'vue';
import { useModal } from 'vue-final-modal';

import { useRoute } from 'vue-router';
import { useSheetStore } from '../store/sheet.store';
import { useDocumentTools } from '../composables/useDocumentTools';
import { PLANS_PERMISSION } from '../constants';
import SheetPrivacyToggle from './sheet-privacy-toggle.vue';
import { useExportPDF } from '~/forms/composables/form-detail-composable';
import TaskFilters from '~/tasks/components/organisms/tasks-filters.vue';
import FormFilter from '~/forms/components/form-filter.vue';
import CustomExportConfirmationPopup from '~/tasks/components/organisms/custom-export/custom-export-confirmation-popup.vue';

import useEmitter from '~/common/composables/useEmitter';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useFormsStore } from '~/forms/store/forms.store';

import { bulkTasksExporter } from '~/tasks/composables/task-composables.js';

const emitter = useEmitter();

const document_viewer_instance = inject('document-viewer-instance');
const $t = inject('$t');
const $track_event = inject('$track_event');

const route = useRoute();
const sheet_store = useSheetStore();
const task_store = useTasksStore('plans-task');
const forms_store = useFormsStore('plans-form');
const {
  download_pdf,
  filter_annotations,
} = useDocumentTools(document_viewer_instance);
const { formsPDFExportHandler } = useExportPDF();

const { logged_in_user_details, check_permission, check_split } = useAuthStore();
const can_modify_plans = computed(() => check_permission(PLANS_PERMISSION.MODIFY_PLANS, route.params.asset_id));
const tasks = computed(() => task_store.tasks());
const forms = computed(() => forms_store.forms);

const task_filter_popup = useModal({
  component: TaskFilters,
  attrs: {
    onClose() {
      task_filter_popup.close();
    },
    modal_options: { teleportTo: '#viewer-container' },
  },
});
const form_filter_popup = useModal({
  component: FormFilter,
  attrs: {
    onClose() {
      form_filter_popup.close();
    },
    modal_options: { teleportTo: '#viewer-container' },
  },
});

const is_pdf_exporting = ref(false);
const export_type = ref('');
const open_export_options = ref(false);
const task_custom_export_options = ref({});

const form = ref({
  tasks: true,
  forms: true,
  markups: true,
  markups_radio: 'everything',
  comments: true,
});

const current_user_id = logged_in_user_details.user_id;
const common_setting_option_class = 'flex items-center cursor-pointer hover:bg-gray-50 p-2 rounded-md gap-1';
const common_filter_option_class = 'flex items-center cursor-pointer hover:bg-gray-50 p-2 rounded-md justify-between';
const checkbox_class = {
  CheckboxElement: {
    text: 'text-[14px] font-medium ml-2',
  },
};
const radio_class = {
  RadiogroupRadio: {
    text: 'ml-2',
  },
};

function openTasksFiltersPopup() {
  const extra_query = {
    associated_with: 'plan',
    descendant: true,
    element: sheet_store.sheet.element,
    linked: true,
  };

  task_filter_popup.patchOptions({
    attrs: {
      task_store,
      onFilter(val) {
        task_store.set_tasks({
          ...(route.params.asset_id ? { asset_uid: route.params.asset_id } : {}),
          ...extra_query,
        });
        task_filter_popup.close();
      },
    },
  });
  task_filter_popup.open();
}

function openFormFiltersPopup() {
  form_filter_popup.patchOptions({
    attrs: {
      forms_store,
      extra_query: {
        element: sheet_store.sheet.element,
        all_access: true,
      },
      onFilter(val) {
        form_filter_popup.close();
      },
    },
  });
  form_filter_popup.open();
}

function annotFilterHandler() {
  filter_annotations({ ...form.value, current_user_id });
  $track_event('filter_markups', { where: 'Plan' });
}

async function exportHandler() {
  if (export_type.value === 'form')
    await formsPDFExportHandler(forms.value, route.params?.template_uid, () => is_pdf_exporting.value = false, forms_store);
  else
    await bulkTasksExporter(tasks.value.map(task => task.uid), () => is_pdf_exporting.value = false, task_custom_export_options.value);
}

function exportCTAHandler(type, payload) {
  export_type.value = type;

  if (is_pdf_exporting.value)
    is_pdf_exporting.value = false;
  nextTick(() => {
    if (type === 'task')
      task_custom_export_options.value = payload;
    is_pdf_exporting.value = true;
  });
}

/* ------- TASK CUSTOM EXPORT ------- */
const { open: openCustomExportConfirmationPopup, close: closeCustomExportConfirmationPopup, patchOptions: patchCustomExportConfirmationPopup } = useModal({
  component: CustomExportConfirmationPopup,
});
function customBulkTaskPdfExportHandler() {
  patchCustomExportConfirmationPopup(
    {
      attrs: {
        export_method: payload => exportCTAHandler('task', payload),
        onClose() {
          closeCustomExportConfirmationPopup();
        },
      },
    },
  );
  openCustomExportConfirmationPopup();
}

function getTooltip(type) {
  const data = type === 'form' ? forms.value : tasks.value;

  if (!data.length)
    return $t(`No ${type === 'form' ? 'forms' : 'tasks'} found`);
  if (data.length > 100)
    return $t(`Can not export more than 100 ${type === 'form' ? 'forms' : 'tasks'}`);

  return '';
};
</script>

<template>
  <div class="w-72 p-2 bg-white rounded-md border drop-shadow-md text-sm font-medium" @mousedown.stop>
    <!-- Settings list -->
    <div v-if="can_modify_plans" :class="common_setting_option_class">
      <IconHawkLockOne class="text-gray-500" />
      <SheetPrivacyToggle />
    </div>
    <div :class="common_setting_option_class" @click="open_export_options = !open_export_options">
      <IconHawkDownloadOne class="text-gray-500" />
      {{ $t('Export as PDF') }}
      <IconHawkChevronRight class="mr-0 ml-auto text-gray-500" />
    </div>
    <!-- Export extra options -->
    <div v-if="open_export_options" class="relative">
      <div class="absolute w-[169px] drop-shadow-md bg-white -top-10 -left-[180px] rounded-md p-2">
        <div
          class="flex items-center p-2 rounded-md justify-between cursor-pointer hover:bg-gray-50"
          @click="() => download_pdf(false)"
        >
          {{ $t('Sheet only') }}
        </div>
        <div
          class="flex items-center p-2 rounded-md justify-between cursor-pointer hover:bg-gray-50"
          @click="download_pdf"
        >
          {{ $t('Sheet + Annotations') }}
        </div>
        <div
          v-if="check_split('tasks_export_pdf')"
          class="flex items-center p-2 rounded-md justify-between text-gray-400"
          :class="{ 'cursor-pointer hover:bg-gray-50 text-gray-700': !!tasks.length }"
          @click="() => { check_split('summary_tasks_export') ? customBulkTaskPdfExportHandler() : exportCTAHandler('task', {}) }"
        >
          <span
            v-tippy="getTooltip('task')"
          >
            {{ $t('Export tasks') }}
          </span>
        </div>
        <div
          v-if="check_split('forms_export_pdf')"
          class="flex items-center p-2 rounded-md justify-between text-gray-400"
          :class="{ 'cursor-pointer hover:bg-gray-50 text-gray-700': !!forms.length }"
          @click="() => exportCTAHandler('form')"
        >
          <span
            v-tippy="getTooltip('form')"
          >
            {{ $t('Export forms') }}
          </span>
        </div>
      </div>
    </div>
    <div
      :class="common_setting_option_class" @click="() => {
        emitter.emit('toolActivated', '')
        sheet_store.set_compare_view(true)
      }"
    >
      <IconHawkCompare class="text-gray-500" />
      {{ $t('Compare') }}
    </div>
    <!-- Filter section -->
    <hr class="border-b border-gray-200 mb-1">
    <p class="text-gray-900 ml-2 mt-2 font-semibold">
      {{ $t('Filters') }}
    </p>
    <Vueform v-model="form" class="my-1" sync>
      <div class="col-span-12 text-gray-700 text-sm font-medium">
        <div :class="common_filter_option_class">
          <div class="flex items-center ">
            <CheckboxElement :add-classes="checkbox_class" name="tasks" @change="annotFilterHandler">
              {{ $t('Tasks') }}
            </CheckboxElement>
          </div>
          <div class="cursor-pointer" @click="openTasksFiltersPopup">
            <IconHawkSettingsOne class="w-[16px] h-[16px]" />
          </div>
        </div>
        <div :class="common_filter_option_class">
          <div class="flex items-center">
            <CheckboxElement :add-classes="checkbox_class" name="forms" @change="annotFilterHandler">
              {{ $t('Forms') }}
            </CheckboxElement>
          </div>
          <div class="cursor-pointer" @click="openFormFiltersPopup">
            <IconHawkSettingsOne class="w-[16px] h-[16px]" />
          </div>
        </div>
        <div :class="common_filter_option_class">
          <div class="flex items-center">
            <CheckboxElement :add-classes="checkbox_class" name="markups" @change="annotFilterHandler">
              {{ $t('Markups') }}
            </CheckboxElement>
          </div>
        </div>
        <div v-if="form.markups" :class="common_filter_option_class" class="ml-7">
          <RadiogroupElement
            name="markups_radio"
            :add-class="radio_class"
            class="!text-sm"
            :items="{
              everything: $t('Everything'),
              created_by_me: $t('Created by me'),
            }"
            :conditions="[['markups', true]]"
            @change="annotFilterHandler"
          />
        </div>
        <div :class="common_filter_option_class">
          <div class="flex items-center">
            <CheckboxElement :add-classes="checkbox_class" name="comments" @change="annotFilterHandler">
              {{ $t('Comments') }}
            </CheckboxElement>
          </div>
        </div>
        <div v-if="form.comments" :class="common_filter_option_class" class="ml-7">
          <RadiogroupElement
            name="comments_radio"
            :add-class="radio_class"
            class="!text-sm"
            :items="{
              assigned_to_me: $t('Assigned to me'),
              show_resolved: $t('Show resolved'),
            }"
            :conditions="[['comments', true]]"
            @change="annotFilterHandler"
          />
        </div>
      </div>
    </Vueform>
  </div>
  <HawkExportToast
    v-if="is_pdf_exporting"
    v-bind="{
      ...(export_type === 'form' && forms.length > 1 ? {
        completed_text: $t('Export request received'),
        completed_subtitle: $t('You will receive an email to download once the export is completed.'),
      } : {}),
    }"
    :submit="exportHandler" @cancel="is_pdf_exporting = false" @close="is_pdf_exporting = false"
  />
</template>
