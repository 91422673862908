<script setup>
import { onKeyStroke } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { useProjectManagementStore } from '~/project-management/store/pm.store';

const emit = defineEmits(['close']);

const $toast = inject('$toast');
const $t = inject('$t');

const router = useRouter();
const route = useRoute();
const project_management_store = useProjectManagementStore();
const { create_schedule } = project_management_store;

let file_data = '';
let service_object = {};
let body = {};

const state = reactive({
  is_file_present: false,
  is_loading: false,
});

const form$ = ref(null);

function onFormUpdated(event) {
  if (event.type !== 'upload_completed')
    return;
  state.is_file_present = true;
  file_data = form$.value.data.file;
  service_object = {
    fileName: file_data.name,
    fileSize: file_data.size,
    service: file_data.service_object,
  };
  body = {
    service_object,
    name: service_object.fileName,
    asset: route.params.asset_id,
    imported: true,
  };
}

async function onUploadSchedule() {
  state.is_loading = true;
  const schedule = await create_schedule(body);
  state.is_loading = false;
  emit('close');

  if (!schedule?.uid)
    return;

  router.push({
    name: 'schedule-details',
    params: { schedule_uid: schedule.uid },
  });

  $toast({
    text: $t('Your changes have been saved and your schedule is added'),
    type: 'success',
    title: $t('Schedule created successfully'),
    timeout: 2000,
    position: 'bottom-right',
    has_close_button: true,
  });
}

onKeyStroke('Enter', () => {
  if (!state.is_file_present)
    return;
  onUploadSchedule();
});
</script>

<template>
  <HawkModalContainer>
    <Vueform
      ref="form$"
      size="sm"
      :attachment_config="{
        meta: {
          service: 'pm',
        },
      }"
      @updated="onFormUpdated"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div class="flex flex-col justify-start">
              {{ $t("New Schedule") }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <div class="flex justify-between w-full col-span-full">
            <div class="flex w-full col-span-full justify-center">
              <FileElement
                class="w-full -mt-2"
                :presets="['hawk_file_element']"
                :auto="true"
                name="file"
                :options="{
                  clickable_text: $t('Upload'),
                  text: ` Primavera P6 or MS projects ${$t('schedule file')}.`,
                  description: `.xer, .xml ${$t('formats supported')}`,
                }"
                accept=".xml,.xer,.mpp"
                :drop="true"
                @remove="state.is_file_present = false"
              />
            </div>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end w-full col-span-full">
              <ButtonElement
                name="cancel"
                :secondary="true"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </ButtonElement>
              <ButtonElement
                name="create"
                class="ml-3"
                :disabled="!state.is_file_present"
                :loading="state.is_loading"
                @click="onUploadSchedule()"
              >
                {{ $t('Create') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
