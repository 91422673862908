<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  quantity: {
    default: 0,
    type: Number,
  },
  uom: {
    default: '',
    type: String,
  },
});

const inventory_store = useInventoryStore();
const state = reactive({

});
</script>

<template>
  <div class="inline-flex">
    {{ quantity }} {{ inventory_store?.uom_map?.[uom]?.symbol || '' }}
  </div>
</template>
