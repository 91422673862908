export function useMapboxFeatureSelection({ layer_ids = [], task_form_selection = false }, onFeaturesSelected) {
  const styleSelection = (features, {
    mapbox_instance,
    colors_map = {},
    colors_map_accessor,
  }) => {
    const features_with_color_property = (features || []).map((f) => {
      f.properties.color
      = (colors_map ? f?.properties?.color || colors_map[f.properties[colors_map_accessor]]?.properties?.color : f.properties.color)
      || null;

      return f;
    });
    if (mapbox_instance.getLayer('linestring_feature_layer')) {
      const styles = [
        {
          name: 'line-dasharray',
          selected: ['literal', [1, 1]],
          not_selected: ['literal', [1, 0]],
        },
        {
          name: 'line-width',
          selected: 2.5,
          not_selected: 2,
        },
      ];
      styles.forEach((style) => {
        mapbox_instance.setPaintProperty('linestring_feature_layer', style.name, [
          'case',
          [
            'in',
            ['get', 'uid'],
            ['literal', features_with_color_property.map(feature => feature.properties.uid)],
          ],
          style.selected,
          style.not_selected,
        ]);
      });
    }
    if (!mapbox_instance.getSource('selected_feature')) {
      mapbox_instance.addSource('selected_feature', {
        type: 'geojson',
        data: {
          features: features_with_color_property,
          type: 'FeatureCollection',
        },
      });
    }

    else {
      mapbox_instance.getSource('selected_feature').setData({
        features: features_with_color_property,
        type: 'FeatureCollection',
      });
    }

    if (features?.length) {
      if (!mapbox_instance.getLayer('selected_feature-points')) {
        mapbox_instance.addLayer(
          {
            id: 'selected_feature-points',
            type: 'circle',
            source: 'selected_feature',
            filter: ['==', '$type', 'Point'],
            paint: {
              'circle-stroke-width': 0.5,
            },
          },
          'symbol-layer-icon',
        );
      }
    }
  };
  const clearSelectedFeatures = () => {
    onFeaturesSelected([]);
  };
  const handleMapClick = (event, mapboxInstance) => {
    if (event.stopPropagation)
      event.stopPropagation();
    else event.originalEvent.stopPropagation();

    const features = mapboxInstance.queryRenderedFeatures(event.point, { layers: layer_ids });
    if (features.length)

      onFeaturesSelected([features[0]], (event.originalEvent.ctrlKey || event.originalEvent.metaKey) ? 'ctrlSelect' : 'click', task_form_selection);

    else clearSelectedFeatures();
  };

  const bulkSelectFeatures = (mapboxInstance) => {
    const canvas = mapboxInstance.getCanvasContainer();
    // Variable to hold the starting xy coordinates
    // when `mousedown` occured.
    let start;
    // Variable to hold the current xy coordinates
    // when `mousemove` or `mouseup` occurs.
    let current;
    // Variable for the draw box element.
    let box;

    canvas.addEventListener('mousedown', mouseDown, true);

    // Return the xy coordinates of the mouse position
    function mousePos(e) {
      const rect = canvas.getBoundingClientRect();
      // eslint-disable-next-line no-undef
      return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop,
      );
    }

    function mouseDown(e) {
      // Continue the rest of the function if the shiftkey is pressed.
      if (!(e.shiftKey && e.button === 0))
        return;

      // Disable default drag zooming when the shift key is held down.
      mapboxInstance.dragPan.disable();
      logger.log(mapboxInstance.dragPan);
      // Call functions for the following events
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('keydown', onKeyDown);

      // Capture the first xy coordinates
      start = mousePos(e);
    }

    function onMouseMove(e) {
      // Capture the ongoing xy coordinates
      current = mousePos(e);

      // Append the box element if it doesnt exist
      if (!box) {
        box = document.createElement('div');
        box.classList.add('boxdraw');
        canvas.appendChild(box);
      }

      const minX = Math.min(start.x, current.x);
      const maxX = Math.max(start.x, current.x);
      const minY = Math.min(start.y, current.y);
      const maxY = Math.max(start.y, current.y);

      // Adjust width and xy position of the box element ongoing
      const pos = `translate(${minX}px,${minY}px)`;
      box.style.transform = pos;
      box.style.WebkitTransform = pos;
      box.style.width = `${maxX - minX}px`;
      box.style.height = `${maxY - minY}px`;
    }

    function onMouseUp(e) {
      // Capture xy coordinates
      finish([start, mousePos(e)], e);
    }

    function onKeyDown(e) {
      // If the ESC key is pressed
      if (e.keyCode === 27)
        finish();
    }

    function finish(bbox, e) {
      // Remove these events now that finish has been called.
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('mouseup', onMouseUp);

      if (box) {
        box.parentNode.removeChild(box);
        box = null;
      }

      // If bbox exists. use this value as the argument for `queryRenderedFeatures`
      if (bbox) {
        const features = mapboxInstance.queryRenderedFeatures(bbox, {
          layers: layer_ids,
        });
        if (features.length)

          onFeaturesSelected(features, (e.ctrlKey || e.metaKey) ? 'ctrlShiftSelect' : 'shiftSelect');

        else clearSelectedFeatures();
      }

      mapboxInstance.dragPan.enable();
    }
  };
  const drawEvents = (mapboxInstance) => {
    mapboxInstance.on('draw.create', (e) => {
      onFeaturesSelected(e.features, 'create');
    });
    mapboxInstance.on('draw.update', (e) => {
      onFeaturesSelected(e.features, 'update');
    });

    mapboxInstance.on('draw.selectionchange', (e) => {
      onFeaturesSelected(e.features, 'selectionchange');
    });
  };

  const loadSelectionEvents = ({ mapbox_instance }) => {
    mapbox_instance.on('click', e => handleMapClick(e, mapbox_instance));
    mapbox_instance.on('touchstart', e => handleMapClick(e, mapbox_instance));
    if (!task_form_selection)
      drawEvents(mapbox_instance);
    bulkSelectFeatures(mapbox_instance);
  };

  return { clearSelectedFeatures, styleSelection, loadSelectionEvents };
}
