<script setup>
// stores
import { storeToRefs } from 'pinia';
import { useDocumentStore } from '~/dms/store/document.store';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

// components
import DocumentFilters from '~/dms/components/documents/document-filters.vue';
import DocumentUploadDropdown from '~/dms/components/atoms/document-upload-dropdown.vue';

const { auth_store, $t, common_store, route } = useCommonImports();
const document_store = useDocumentStore();
const dms_settings_store = useDMSSettingsStore();
const { document_status } = storeToRefs(dms_settings_store);
const { is_internal, search_query } = storeToRefs(document_store);

const field_types_map = {
  dropdown: {
    data_type: 'multi_select',
    operators: ['containsAnyOf'],
  },
  number: {
    data_type: 'number',
    operators: ['isBetween'],
  },
  single_select: {
    data_type: 'single_select',
    operators: ['isAnyOf'],
  },
  multi_select: {
    data_type: 'multi_select',
    operators: ['containsAnyOf'],
  },
  date: {
    data_type: 'date',
    operators: ['between'],
  },
  checkbox: {
    data_type: 'multi_select',
    operators: ['isAnyOf'],
  },
  text: {
    data_type: 'text',
    operators: ['contains'],
  },
  yes_no_na: {
    data_type: 'boolean',
    operators: ['isEqualTo'],
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
      { value: 'NA', label: 'NA' },
    ],
  },
  members: {
    data_type: 'multi_select',
    operators: ['isAnyOf'],
    option_type: 'member',
    options: common_store.scope_users(route.params?.asset_id || null),
  },
};

const display_filters_ref = ref(null);
const document_view_ref = ref(null);

provide('display_filters_ref', display_filters_ref);

const state = reactive({
  query: '',
  is_search_in_progress: false,
});

const custom_fields = computed(() => dms_settings_store.custom_fields.filter(field => !field.deleted && field.active));

const standard_filters = computed(() => ({
  status: {
    uid: 'status',
    name: $t('Status'),
    type: 'select',
    is_static: true,
    data_type: 'single_select',
    operators: ['isAnyOf'],
    options: document_status.value?.map(({ name, uid }) => ({ uid, name })) || [],
  },
  name: {
    uid: 'name',
    name: $t('Name'),
    data_type: 'text',
    operators: ['contains'],
  },
  number: {
    uid: 'number',
    name: $t('Number'),
    data_type: 'text',
    operators: ['contains'],
  },
  modified_on: {
    uid: 'modified_on',
    name: 'Modified On',
    data_type: 'date',
    operators: ['between'],
  },
  shared_with: {
    uid: 'shared_with',
    name: 'Shared With',
    data_type: 'multi_select',
    option_type: 'member',
    operators: ['containsAnyOf'],
    options: common_store.scope_users(route.params?.asset_id || null),
  },
}));

const filter_options = computed(() => {
  const custom_fields_filters = {};
  if (custom_fields.value?.length)
    custom_fields.value.forEach((element) => {
      custom_fields_filters[element.uid] = {
        uid: element.uid,
        name: element.label,
        options: element.items?.map(({ label, value }) => ({ uid: value, name: label })) || [],
        type: 'select',
        is_static: true,
        ...(field_types_map[element.type] || {}),
      };
    });

  return { ...standard_filters.value, ...custom_fields_filters };
});

function updateSearch(e) {
  const search = e !== '' ? e : null;
  document_store.set_search_query(search);
}
async function applyFilters(filtersData) {
  try {
    if (!filtersData.length)
      search_query.value = null;
    await document_view_ref.value.getData();
  }
  catch (error) {
    logger.error(error);
  }
}

onMounted(async () => {
  if (!dms_settings_store.custom_fields.length)
    await dms_settings_store.set_custom_fields({
      query: {
        allowed_in: 'documents',
      },
    });
});
</script>

<template>
  <div>
    <div class="flex gap-4 mb-2">
      <div class="w-[314px]">
        <hawk-search-input v-model="search_query" placeholder="Search" @update:modelValue="updateSearch" />
      </div>
      <div class="flex-1">
        <HawkDisplayFilters
          :key="route.name + is_internal"
          ref="display_filters_ref"
          :view_configuration="{
            service: 'dms',
            resource_type: 'files',
            feature: 'list_view',
            name: auth_store.current_organization.uid,
            store_key: 'dms_files_list_view',
            modify_permissions: auth_store.check_permission('configure_dms_settings', route.params.asset_id),
          }"
          :filter_options="filter_options"
          :default_filters="Object.values(standard_filters)"
          :field_modal_options="{
            texts: {
              heading: $t('Choose fields to filter'),
              left_heading: $t('Available filters'),
              right_heading: $t('Displayed filters'),
            },
          }"
          @apply="applyFilters"
        />
      </div>
      <div class="flex">
        <DocumentFilters @tabChange="updateSearch('')" />
        <DocumentUploadDropdown />
      </div>
    </div>
    <div>
      <router-view v-slot="{ Component }">
        <component :is="Component" ref="document_view_ref" :search_query="state.query" />
      </router-view>
    </div>
  </div>
</template>
