<script setup>
import { markRaw, reactive } from 'vue';
import { useRouter } from 'vue-router';
import Layers from '~/terra/components/layers/terra-layer.vue';
import FeatureTypes from '~/terra/components/feature-type-groups/feature-types.vue';
import Filters from '~/terra/components/terra-filters.vue';
import Workflows from '~/terra/components/workflow.vue';
import BulkUpdateFeaturesTable from '~/terra/components/table/bulk-update-features-table.vue';
import Sharing from '~/terra/components/terra-sharing.vue';
import Charts from '~/terra/components/charts/terra-charts.vue';
import Settings from '~/terra/components/terra-settings.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useCommonStore } from '~/common/stores/common.store';
import PivotTable from '~/terra/components/pivot-table/pivot-table.vue';
import BarcodeScan from '~/terra/components/barcode-scan/terra-barcode-scan.vue';

const props = defineProps({
  sidebar_state: {
    type: Object,
  },
});
const emit = defineEmits(['sidebarToggle', 'activeMenu', 'openCreationPopup', 'setSymbols', 'groupOrProjectToggled', 'containerChange', 'saveView', 'updateActiveMenu', 'updateTableActive']);
const auth_store = useAuthStore();
const terra_store = useTerraStore();
const common_store = useCommonStore();
const router = useRouter();
const state = reactive({
  component_tab_map: {
    layers: markRaw(Layers),
    classes: markRaw(FeatureTypes),
    filters: markRaw(Filters),
    workflows: markRaw(Workflows),
    sharing: markRaw(Sharing),
    settings: markRaw(Settings),
    analytics: markRaw(PivotTable),
  },

});
const top_menu_list = computed(() => {
  return [
    {
      id: 'layers',
      label: 'Layers',
      icon: IconHawkLayersTwoOne,
    },
    {
      id: 'classes',
      label: 'Classes',
      icon: IconHawkGridOne,
    },
    {
      id: 'filters',
      label: 'Filters',
      icon: IconHawkFilterFunnelOne,
    },
    {
      id: 'table',
      label: 'Table',
      icon: IconHawkTableTwo,
    },
    // {
    //   id: 'digital-twin',
    //   label: 'Digital twin',
    //   icon: IconHawkDataflowFour,
    // },
    ...(terra_store.check_permission('view_workflows')
      ? [{
          id: 'workflows',
          label: 'Workflows',
          icon: IconHawkDataflowOne,
        }]
      : []),

    ...(auth_store.check_split('pivot-table') && terra_store.check_permission('view_pivot')
      ? [{
          id: 'analytics',
          label: 'Analyze',
          icon: IconHawkBarChartSquareTwo,
          has_full_view: true,
        }]
      : []),
    ...(auth_store.check_split('terra_barcode_scan') && terra_store.check_permission('view_scan_data')
      ? [{
          id: 'scan',
          label: 'Scan',
          icon: IconHawkScan,
          disabled_toggle: true,
          has_full_view: true,
        }]
      : []),
  ];
});
const bottom_menu_list = computed(() => {
  return [
    ...(terra_store.check_permission('view_charts')
      ? [{
          id: 'charts',
          label: 'Charts',
          icon: IconHawkLineChartUpOne,
          disabled_toggle: true,
          has_full_view: true,
        }]
      : []),
    ...(terra_store.check_permission('share_container')
      ? [{
          id: 'sharing',
          label: 'Sharing',
          icon: IconHawkShareSeven,
        }]
      : []),

    {
      id: 'settings',
      label: 'Settings',
      icon: IconHawkSettingsOne,
    },
  ];
});
const get_sidebar_width = computed(() => {
  if (props.sidebar_state.active_menu === 'analytics')
    return ['!relative z-[101] bg-gray-25 overflow-auto left-[80px] w-[calc(100vw-80px)] h-[calc(100vh-65px)]'];
  else if (props.sidebar_state.active_menu)
    return ['w-[300px] left-[80px]'];
  else return ['w-0 left-[80px]'];
});
function toggleActiveMenu(menu) {
  if (menu?.has_full_view) {
    emit('updateTableActive', false);
    emit('updateActiveMenu', props.sidebar_state.active_menu === menu.id ? '' : menu.id);
    router.push({ query: { view_name: menu.id } });
  }

  else if (menu.id === 'table') {
    if (['charts', 'analytics'].includes(props.sidebar_state.active_menu))
      emit('updateActiveMenu', '');
    emit('updateTableActive', !(props.sidebar_state.is_table_active && menu.id === 'table'));
  }

  else {
    emit('updateActiveMenu', props.sidebar_state.active_menu === menu.id ? '' : menu.id);
  }
  if (props.sidebar_state.active_menu === 'charts')
    terra_store.terra_track_events('Charts opened');

  emit('saveView');
}

function handleCtaClick(is_chart_cta = false) {
  common_store.toggle_chat_with_us(true);
  if (is_chart_cta)
    terra_store.terra_track_events('Requested for charts');
}

// TODO: refactor z-index for whole terra
function checkHasFullView() {
  return props.sidebar_state.is_table_active || ['charts', 'analytics', 'scan'].includes(props.sidebar_state.active_menu);
}
</script>

<template>
  <div class="relative" :class="{ 'z-1': !checkHasFullView() }">
    <div class="h-[calc(100vh-64px)] w-[80px] transition-all duration-200 ease-in-out fixed z-999 pointer-events-auto bg-white border-r border-gray-300">
      <div class="flex flex-col h-full scrollbar">
        <div
          v-for="menu in top_menu_list" :key="menu.id"
          class="flex w-full py-2 flex-col items-center gap-1 text-gray-600 cursor-pointer hover:bg-gray-100 border-y border-white"
          :class="{ 'bg-gray-100 text-primary-600 !border-gray-300': sidebar_state.active_menu === menu.id || (sidebar_state.is_table_active && menu.id === 'table') }"
          @click="toggleActiveMenu(menu)"
        >
          <component :is="menu.icon" class="w-6 h-6" />
          <div class="text-xs font-medium leading-4">
            {{ $t(menu.label) }}
          </div>
        </div>
        <div class="mt-auto">
          <div v-for="menu in bottom_menu_list" :key="menu.id" class="flex w-full py-2 flex-col items-center gap-1 text-gray-600 cursor-pointer hover:bg-gray-100 border-y border-white" :class="{ 'bg-gray-100 text-primary-600 !border-gray-300': sidebar_state.active_menu === menu.id }" @click="toggleActiveMenu(menu)">
            <component :is="menu.icon" class="w-6 h-6" />
            <div class="text-xs font-medium leading-4">
              {{ $t(menu.label) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!['table', 'charts', 'scan'].includes(sidebar_state.active_menu)" class="h-[calc(100vh-65px)] bottom-0 transition-all duration-200 ease-in-out fixed z-999 pointer-events-auto bg-white" :class="get_sidebar_width">
      <KeepAlive>
        <component
          :is="state.component_tab_map[sidebar_state.active_menu]"
          @containerChange="emit('containerChange', $event)"
          @setSymbols="emit('setSymbols')"
          @toggleLabels="emit('toggleLabels')"
          @groupOrProjectToggled="emit('groupOrProjectToggled')"
          @featureTypeToggle="emit('saveView')"
          @saveView="emit('saveView')"
        />
      </KeepAlive>
    </div>
    <BulkUpdateFeaturesTable
      v-if="sidebar_state.is_table_active"
      :is_table_active="sidebar_state.is_table_active"
      :active_menu="sidebar_state.active_menu"
      @openCreationPopup="emit('openCreationPopup', $event)"
      @saveView="emit('saveView')"
      @close="emit('updateTableActive', false);"
    />

    <div v-if="['charts', 'scan'].includes(sidebar_state.active_menu)" class="relative z-[101] bg-gray-25 overflow-auto left-[80px] w-[calc(100vw-80px)] h-[calc(100vh-65px)]">
      <BarcodeScan
        v-if="sidebar_state.active_menu === 'scan'"
        :can_modify_features="terra_store.check_permission('modify_features')"
        @handleChatToggle="handleCtaClick()"
      />
      <div v-else-if="!auth_store.is_internal_user && !terra_store.container?.properties?.is_charts_published">
        <HawkIllustrations type="on-boarding" for="terra-charts" @onCtaClick="handleCtaClick(true)" />
      </div>
      <Charts v-else />
    </div>
  </div>
</template>
