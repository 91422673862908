import { orderBy } from 'lodash-es';
import useEmitter from '~/common/composables/useEmitter';

export function useTaskNavigation(task_store, source) {
  const emitter = useEmitter();

  async function navigateListView(viewHandler, direction, callback) {
    let updated_uid = null;
    if (direction === 'next') {
      if (task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1) {
        const tasks = await callback(1);
        task_store.set_navigation_meta({
          task_nav_uids: tasks.map(task => task.uid),
        });
      }
      updated_uid = task_store.navigation_meta.task_nav_uids[task_store.current_task_index + 1];
    }
    else if (direction === 'prev') {
      let cur_index = task_store.current_task_index;
      if (task_store.current_task_index === 0) {
        const tasks = await callback(-1);
        task_store.set_navigation_meta({
          task_nav_uids: tasks.map(task => task.uid),
        });
        cur_index = task_store.navigation_meta.task_nav_uids.length;
      }
      updated_uid = task_store.navigation_meta.task_nav_uids[cur_index - 1];
    }
    viewHandler(updated_uid, false);
  }

  async function navigateKanbanView(viewHandler, direction, callback) {
    let updated_uid = task_store.navigation_meta.task_nav_uids[task_store.current_task_index - 1];
    const current_task_uid = task_store.navigation_meta.task_nav_uids[task_store.current_task_index];
    const status = task_store.get_current_task(current_task_uid).status;
    if (direction === 'next') {
      if (task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1) {
        const tasks = await callback(status);
        task_store.set_navigation_meta({
          task_nav_uids: tasks.map(task => task.uid),
        });
      }
      updated_uid = task_store.navigation_meta.task_nav_uids[task_store.current_task_index + 1];
    }
    viewHandler(updated_uid, status);
  }

  function navigateCalendarView(viewHandler, direction) {
    const updated_uid = task_store.navigation_meta.task_nav_uids[task_store.current_task_index + (direction === 'next' ? 1 : -1)];
    viewHandler(updated_uid, false);
  }

  async function navigateCardList(viewHandler, direction, callback) {
    let updated_uid = task_store.navigation_meta.task_nav_uids[task_store.current_task_index - 1];
    if (direction === 'next') {
      if (task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1) {
        const tasks = await callback();
        task_store.set_navigation_meta({
          task_nav_uids: tasks.map(task => task.uid),
        });
      }
      updated_uid = task_store.navigation_meta.task_nav_uids[task_store.current_task_index + 1];
    }
    viewHandler(updated_uid, false);
  }

  const subscribe_navigator = (callback, viewHandler) => emitter.on('navigate-task', async (direction) => {
    switch (source) {
      case 'list_view': {
        await navigateListView(viewHandler, direction, callback);
        break;
      }
      case 'kanban_view': {
        await navigateKanbanView(viewHandler, direction, callback);
        break;
      }
      case 'calendar_view': {
        navigateCalendarView(viewHandler, direction);
        break;
      }
      case 'card_list': {
        await navigateCardList(viewHandler, direction, callback);
        break;
      }
    }
  });
  const unsubscribe_navigator = () => emitter.off('navigate-task');

  function getNavigatorDisabledState(component_context) {
    const strict = task_store.navigation_meta.strict;
    switch (source) {
      case 'list_view': {
        return {
          prev: task_store.current_task_index === 0 && (strict ? true : component_context.page_number === 1),
          next: task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1
            && (strict ? true : component_context.page_number * component_context.page_size >= task_store.total_task_count),
        };
      }
      case 'kanban_view': {
        return {
          prev: component_context.page_number === 1 && task_store.current_task_index === 0,
          next: (strict ? true : task_store.get_tasks_by_status(component_context.status).length >= task_store.get_task_count_by_status(component_context.status))
            && (task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1),
        };
      }
      case 'calendar_view': {
        return {
          prev: task_store.current_task_index === 0,
          next: task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1,
        };
      }
      case 'card_list': {
        return {
          prev: task_store.current_task_index === 0,
          next: (strict ? true : component_context.page_number * component_context.page_size >= task_store.total_task_count)
            && (task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1),
        };
      }
    }
  }

  function getTaskNavUids(navigation_context) {
    switch (source) {
      case 'list_view': {
        const task = task_store.get_current_task(navigation_context.current_task_uid);
        if (task.parent_task_uid && navigation_context?.strict)
          return orderBy(task_store.get_subtasks(task.parent_task_uid), 'subtask_orderindex', 'asc').map(subtask => subtask.uid);
        else
          return navigation_context.tasks.map(task => task.uid);
      }
      case 'kanban_view':
        return navigation_context.tasks.map(task => task.uid);
      case 'calendar_view':
        return navigation_context.tasks.sort((task1, task2) => (task1.start_date > task2.start_date) ? 1 : -1).map(task => task.uid);
      case 'card_list':
        return navigation_context.tasks.map(task => task.uid);
    }
  }

  function setNavigationContext(
    create_new_context,
    navigation_context = { current_task_uid: null, tasks: [] },
    component_context = { page_number: 1, page_size: 25 },
  ) {
    if (create_new_context)
      task_store.create_navigation_context();

    task_store.set_navigation_meta({
      current_task_uid: navigation_context.current_task_uid,
      ...(create_new_context && { task_nav_uids: getTaskNavUids(navigation_context) }),
      strict: navigation_context?.strict,
    });

    const disable = getNavigatorDisabledState(component_context);
    task_store.set_navigation_meta({ disable });
  }
  return { subscribe_navigator, unsubscribe_navigator, setNavigationContext };
}
