import { SUPPORTED_FORMULAS } from 'hot-formula-parser';

import FormFieldHierarchy from '~/forms/molecules/form-field-hierarchy.vue';
import FormulaTestCTA from '~/forms/components/form-formula-test/formula-test-cta.vue';

export function useFormulaFieldSchema(options) {
  const $t = options.$t;

  return {
    formula_data: {
      type: 'object',
      schema: {
        html: {
          type: 'WysiwygEditor',
          options: {
            single_line: true,
            menu_enabled: false,
            enable_common_rtf_features: false,
            plugins: ['disable-enter', 'tags', 'formulas'],
            placeholder_text: $t('Enter formula here'),
            suggestions: [],
            formula_suggestions: SUPPORTED_FORMULAS?.map(formula_name => ({ id: formula_name, label: formula_name })),
            footer: {
              component: FormFieldHierarchy,
              component_props: {
                nested_suggestions: [
                  ...(options?.extra_suggestions || []),
                ],
                is_workflow: options?.is_workflow,
              },
            },
            nested_suggestions: [
              ...(options?.extra_suggestions || []),
            ],
          },
          native: false,
          rules: ['required'],
          columns: {
            default: { container: 12, label: 12, wrapper: 12 },
            sm: { container: 12, label: 12, wrapper: 12 },
            md: { container: 12, label: 12, wrapper: 12 },
          },
          label: $t('Formula'),
          info: $t('Formula will pick up the fields which are only above the formula field.'),
          object: true,
          slots: {
            after: FormulaTestCTA,
          },
        },
      },
    },
  };
}
