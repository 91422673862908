<script setup>
import { computed, inject, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { sortBy } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

const common_store = useCommonStore();
const is_loading = ref(false);
const $services = inject('$services');
const route = useRoute();
const $t = inject('$t');
const router = useRouter();
const search = ref('');
const filtered_data = computed(() => {
  const list = common_store.assets_map[route.params.asset_id][
    route.params.type === 'terra' ? 'terra_list' : 'therm_list'
  ];
  const sorted_list = sortBy(list, (item) => {
    return item.name;
  });
  return (Object.keys(common_store.assets_map).length && list)
    ? sorted_list.filter(item =>
      item.name.toLowerCase().includes(search.value.toLowerCase()),
    )
    : [];
});

const asset_list_type = computed(() => route.params.type === 'therm' ? 'therm-map' : 'terra');
const columns = computed(() => {
  return [
    {
      id: 'name',
      header: 'Map Name',
      accessorKey: 'name',
      enableSorting: false,
      enableResizing: false,
    },
    {
      id: 'description',
      header: 'Description',
      accessorKey: 'description',
      enableSorting: false,
      enableResizing: false,
    },
  ];
});
watch(route, () => {
  if (common_store.assets_map)
    getData();
}, { immediate: true });

async function getData(forceUpdate = false) {
  is_loading.value = true;
  const terraList = common_store.assets_map[route.params.asset_id].terra_list;
  const thermList = common_store.assets_map[route.params.asset_id].therm_list;
  if (route.params.type === 'terra' && terraList && !forceUpdate) {
    if (terraList.length === 1)
      router.push({
        name: 'terra-viewer',
        params: {
          asset_id: route.params.asset_id,
          id: terraList[0].uid,
        },
      });
    is_loading.value = false;
    return;
  }
  else if (route.params.type === 'therm' && thermList) {
    if (thermList.length === 1)
      router.push({
        name: 'therm',
        params: {
          asset_id: route.params.asset_id,
          id: thermList[0].uid,
        },
      });
    is_loading.value = false;

    return;
  }

  try {
    if (!forceUpdate)

    /* -------------------------------------------------------------------------- */
    /*                               for terra data                               */
    /* -------------------------------------------------------------------------- */

      if (route.params.type === 'terra') {
        const terraResponse = await $services.terra_view_service.getAll({
          query: {
            asset: route.params.asset_id,
          },
        });

        const terra = terraResponse.data;

        if (terra.length)
          if (terra.length === 1)
            router.push({
              name: 'terra-viewer',
              params: {
                asset_id: route.params.asset_id,
                id: terra[0].uid,
              },
            });

          else

            common_store.set_terra_therm_list_in_assets({
              asset_id: route.params.asset_id,
              data: terra,
              key: 'terra_list',
            });
      }
      else if (route.params.type === 'therm') {
      /* -------------------------------------------------------------------------- */
      /*                               for therm data                               */
      /* -------------------------------------------------------------------------- */
        const thermResponse = await $services.therm.getAll({
          query: {
            assetUid: route.params.asset_id,
            metrics: true,
            complete: true,
          },
          attribute: 'views',
        });
        const therm = thermResponse.data;
        if (therm.length)
          if (therm.length === 1)
            router.push({
              name: 'therm',
              params: {
                asset_id: route.params.asset_id,
                id: therm[0].uid,
              },
            });

          else
            common_store.set_terra_therm_list_in_assets({
              asset_id: route.params.asset_id,
              data: therm.length === 1 ? [therm] : therm,
              key: 'therm_list',
            });
      }
    is_loading.value = false;
  }
  catch (err) {
    logger.log(err);
    is_loading.value = false;
  }
}

function handleRowClick(e) {
  router.push({
    name: route.params.type === 'therm' ? 'therm' : 'terra-viewer',
    params: { asset_id: route.params.asset_id, id: e.uid },
  });
}
</script>

<template>
  <div class="p-4 w-full">
    <p class="font-semibold text-lg mb-6 text-gray-900">
      {{ `${route.params.type.slice(0, 1).toUpperCase()}${route.params.type.slice(1)}` }}
    </p>
    <HawkSearchInput v-model:search="search" placeholder="Search" @update:modelValue="search = $event" />
    <div class="mt-4">
      <div v-if="is_loading">
        <hawk-loader />
      </div>
      <div v-else-if="filtered_data.length === 0 ">
        <hawk-illustrations type="no-data" :for="asset_list_type" />
      </div>
      <TableWrapperVue v-else-if="filtered_data.length">
        <HawkTable
          is_gapless
          :disable_resize="true"
          :data="filtered_data"
          :columns="columns"
          :show_menu_header="false"
          class="max-w-full"
          @rowClicked="handleRowClick"
        >
          <template #name="name">
            <p class="font-medium text-sm">
              {{ name.data.row.original.name }}
            </p>
          </template>
          <template #description="description">
            <p v-if="description.data.row.original.description" class="text-gray-600 text-sm">
              {{ description.data.row.original.description }}
            </p>
            <p v-else class="text-gray-600 text-sm">
              {{ $t('No description added') }}
            </p>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
