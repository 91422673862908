<script>
import { computed, ref, toRefs } from 'vue';
import DOMPurify from 'dompurify';
import VueformElement from '@vueform/vueform/element';

export default VueformElement({
  name: 'WysiwygEditorElement',
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
}, {
  components: {
    HawkWysiwygEditorComponent: defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue')),
  },
  setup(props, { element }) {
    const defaultClasses = ref({
      container: '',
      wysiwygeditor: '',
      $wysiwygeditor: classes => ([
        classes.wysiwygeditor,
      ]),
    });

    const { options } = toRefs(props);
    const wysiwyg_instance = ref(null);
    const search_field = ref('');

    const is_disabled = computed(() => element.isDisabled.value);

    const component_key = computed(() => options.value?.placeholder_text || {});

    const tag_items = computed(() => {
      const all_suggestions = [...(options.value?.suggestions || []), ...(options.value?.nested_suggestions || [])];
      return all_suggestions.map(suggestion => ({
        id: suggestion.id,
        label: suggestion.label,
      }));
    });

    const formula_items = computed(() => options.value?.formula_suggestions || []);

    const wysiwygeditor = computed({
      get() {
        return element.model.value || options.value?.modelValue || '';
      },
      set(value) {
        const sanitized_data = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
        element.model.value = sanitized_data ? value : '';
      },
    });

    const suggestions_searched = computed(() => {
      const suggestions = (options.value?.suggestions || []).slice(3);

      return suggestions.filter(suggestion => suggestion.label.toLowerCase().includes(search_field.value.toLowerCase())).map(suggestion => ({
        label: suggestion.label,
        additional_item_classes: suggestion?.additional_item_classes,
        menu_item_header: suggestion?.menu_item_header,
        on_click: () => addSuggestion({
          id: suggestion.id,
          label: suggestion.label,
        }),
      }));
    });

    const nested_suggestions_searched = computed(() => (options.value?.nested_suggestions || []).filter(suggestion => suggestion.label.toLowerCase().includes(search_field.value.toLowerCase())));

    function onInitialized(event) {
      wysiwyg_instance.value = event;
    }

    function getSuggestionDropdownItems(suggestions) {
      return suggestions.map(suggestion => ({
        label: suggestion.label,
        on_click: () => addSuggestion({
          id: suggestion.id,
          label: suggestion.label,
        }),
      }));
    }

    function addSuggestion(suggestion) {
      if (wysiwyg_instance.value)
        wysiwyg_instance.value.insertTag({
          id: suggestion.id,
          label: suggestion.label,
        }, false, 'tag');
    }

    function handleSearch(e) {
      search_field.value = e.target.value;
    }

    return {
      defaultClasses,
      wysiwygeditor,
      is_disabled,
      tag_items,
      formula_items,
      component_key,
      onInitialized,
      addSuggestion,
      getSuggestionDropdownItems,
      search_field,
      suggestions_searched,
      nested_suggestions_searched,
      handleSearch,
    };
  },
});
</script>

<template>
  <ElementLayout>
    <template #element>
      <HawkWysiwygEditorComponent
        v-bind="options || {}"
        :key="component_key"
        v-model="wysiwygeditor"
        :tag_items="tag_items"
        :formula_items="formula_items"
        :editor_enabled="!is_disabled"
        :class="{
          '!p-0 !h-auto': options?.single_line,
          'pointer-events-none': is_disabled && !options?.enable_events,
          'border border-gray-300 rounded-lg': !is_disabled,
        }"
        :editor_classes="options?.single_line ? '!py-2' : ''"
        @initialized="onInitialized"
      />
      <div v-if="tag_items.length && !is_disabled" class="flex flex-wrap relative">
        <div
          v-for="(suggestion, index) in tag_items.slice(0, 3)"
          :key="suggestion.id"
          class="cursor-pointer mt-2 mr-2"
        >
          <HawkBadge v-if="index < 3" @click="addSuggestion(suggestion)">
            {{ suggestion.label }}
          </HawkBadge>
        </div>
        <template v-if="tag_items?.length > 3">
          <HawkMenu
            additional_trigger_classes="!ring-0 !border-0"
            additional_dropdown_classes="!w-60 !h-72 scrollbar"
            additional_header_classes="border-0 sticky top-0 z-999 bg-white"
            :additional_footer_classes="`${!options.footer || !nested_suggestions_searched.length ? 'border-0' : null}`"
            position="fixed"
            :items="suggestions_searched"
          >
            <template #header>
              <div>
                <HawkSearchInput v-model="search_field" class="bg-white" full_width :placeholder="$t('Search')" @input="handleSearch" @keydown.space.stop />
              </div>
            </template>
            <template v-if="!nested_suggestions_searched.length && !suggestions_searched.length" #content>
              <div class="mt-2 px-5 text-sm text-gray-400">
                {{ $t('No options found') }}
              </div>
            </template>
            <template #trigger>
              <div
                class="cursor-pointer mt-2 mr-2"
              >
                <HawkBadge>
                  <IconHawkPlus class="w-2.5 h-2.5" /> {{ ((options?.suggestions || []).length + (options?.nested_suggestions || []).length) - 3 }}
                </HawkBadge>
              </div>
            </template>
            <template #footer="{ close }">
              <div v-if="options.footer">
                <component
                  :is="options.footer.component"
                  v-bind="options.footer.component_props"
                  :close="close"
                  :add_suggestion="addSuggestion"
                  :search_field="search_field"
                  :has_default_suggestions="!!options?.suggestions?.length"
                />
              </div>
            </template>
          </HawkMenu>
        </template>
      </div>
    </template>

    <!-- Default element slots -->
    <template v-for="(component, slot) in elementSlots" #[slot]>
      <!-- eslint-disable-next-line vue/valid-attribute-name -->
      <slot :name="slot" :el$="el$">
        <component :is="component" :el$="el$" />
      </slot>
    </template>
  </ElementLayout>
</template>
