<script setup>
import { isEqual } from 'lodash-es';
import { useCommonImports } from '~/common/composables/common-imports.composable';
import { TEMPLATE_ICONS } from '~/forms/constants';
import FormTemplateIcon from '~/forms/atoms/form-template-icon.vue';

const props = defineProps({
  callback: {
    type: Function,
    default: null,
  },
  save: {
    type: Function,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);

const { $t } = useCommonImports();

const selected_icon_id = ref(props?.icon || null);

function setIcon(icon) {
  selected_icon_id.value = icon;
}
function iconEqual(icon) {
  return isEqual(selected_icon_id.value, icon);
}
</script>

<template>
  <hawk-modal-container>
    <div class="col-span-12">
      <hawk-modal-header @close="emit('close')">
        <template #title>
          {{ $t("Choose an Icon") }}
        </template>
      </hawk-modal-header>
      <hawk-modal-content>
        <div class="grid gap-5 grid-cols-7">
          <FormTemplateIcon
            v-for="icon_id in Object.keys(TEMPLATE_ICONS).map(Number)"
            :key="icon_id"
            :icon_id="icon_id"
            class="h-14 w-14 rounded-md border-2 p-2 border-gray-300 hover:cursor-pointer"
            :class="{ 'border-primary-600': icon_id === selected_icon_id }"
            @click="setIcon(icon_id)"
          />
        </div>
      </hawk-modal-content>
      <hawk-modal-footer>
        <template #right>
          <div class="flex justify-end w-full">
            <hawk-button
              class="mr-5"
              type="outlined"
              @click="$emit('close')"
            >
              {{ $t('Cancel') }}
            </hawk-button>
            <hawk-button
              color="primary"
              :disabled="!selected_icon_id"
              @click="save(selected_icon_id)"
            >
              {{ $t('Save') }}
            </hawk-button>
          </div>
        </template>
      </hawk-modal-footer>
    </div>
  </hawk-modal-container>
</template>
