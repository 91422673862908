/* eslint-disable no-undef */
import { acceptHMRUpdate, defineStore } from 'pinia';
import { cloneDeep, keyBy } from 'lodash-es';
import DOMPurify from 'dompurify';
import { $date } from '~/common/utils/date.util';
import { getDefaultView } from '~/project-management/constants/pm-default-view';

const PROPERTIES_GENERAL = [
  'activity_code_values',
  'actual_cost',
  'actual_duration',
  'actual_qty',
  'actual_work',
  'auto_progress_sync',
  'cost',
  'cpi',
  'created_at',
  'custom_field_values',
  'data_date',
  'earned_value',
  'editable',
  'field_quantities',
  'field_quantities_percentage',
  'free_slack',
  'intial_progress',
  'is_critical',
  'is_milestone',
  'name',
  'percent_schedule_complete_progress',
  'percent_schedule_complete',
  'percent_work_complete',
  'planned_cost',
  'planned_duration',
  'planned_qty',
  'planned_value',
  'planned_work',
  'predecessors',
  'progress_type',
  'progress',
  'references',
  'remaining_cost',
  'remaining_duration',
  'remaining_work',
  'spi',
  'status',
  'successors',
  'total_slack',
  'units',
  'updated_at',
  'wbs_path',
  'weight',
  'work',
  'work_rate',
];
const PROPERTIES_FOR_DATES = [
  'planned_start',
  'planned_finish',
  'actual_start',
  'actual_finish',
  'bl_start',
  'bl_finish',
];

export const useProjectManagementStore = defineStore('project_management', {
  state: () => ({
    active_task_uid: null,
    active_schedule: null,
    active_schedule_links: null,
    active_view: null,
    gantt_instance: null,
    is_searching: false,
    is_fullscreen: false,
    is_refresh_required: false,
    is_pm_loading: false,
    pm_loading_message: '',
    view_dirtiness: 0,
    schedules: [],
    markers: [],
    views: [],
    search_config: {
      search_string: '',
      current_match_index: -1,
      match_count: -1,
      search_results: [], // contains the UIDs of the tasks that match the search
    },
    auto_update_progress_cache: {},
  }),
  getters: {
    // This getter returns the data in a format consumable by DHTMLX Gantt
    active_schedule_data() {
      return {
        data: Object.values(this.active_schedule.activities),
        links: [...this.active_schedule_links],
      };
    },
    active_task() {
      return this.active_schedule?.activities?.[this.active_task_uid];
    },
    active_task_auto_progress_cache() {
      return this.auto_update_progress_cache[this.active_task_uid];
    },
    active_schedule_sync_history() {
      try {
        return this.active_schedule.sync_history.map((data, index) => {
          return {
            ...data,
            id: index + 1,
          };
        }).reverse();
      }
      catch (error) {
        return [];
      }
    },
  },
  actions: {
    async set_versions() {
      const { data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id: this.active_schedule.uid,
        attribute: 'versions',
      });
      return data;
    },
    async create_version({ uid, body }) {
      try {
        const id = uid ?? this.active_schedule.uid;
        const { data } = await this.$services.project_management.post({
          url: 'project-management/schedules',
          id,
          attribute: 'versions',
          body,
        });
        return data;
      }
      catch (error) {
        logger.error(error);
        this.$toast(
          {
            title: error.data.title,
            text: error.data.description,
            type: 'error',
            timeout: 4000,
          },
        );
        return error;
      }
    },
    async update_version({ schedule_uid, version_id, body }) {
      const schedule_id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.patch({
        url: 'project-management/schedules',
        id: schedule_id,
        attribute: `versions/${version_id}`,
        body,
      });
      return data;
    },
    async delete_version({ schedule_uid, version_id }) {
      const schedule_id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.delete({
        url: 'project-management/schedules',
        id: schedule_id,
        attribute: `versions/${version_id}`,
      });
      return data;
    },
    async download_version({ schedule_uid, version_id }) {
      const schedule_id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id: schedule_id,
        attribute: `versions/${version_id}/download`,
      });
      return data;
    },
    async configure_auto_sync({ schedule_uid, body }) {
      const id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id,
        attribute: 'register-sync-trigger',
        body,
      });
      this.update_active_schedule(
        id,
        { sync_schedule: data.data.sync_schedule },
        false,
      );
    },
    async sync_activity_progress({ schedule_uid, body, signal }) {
      const id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        url: 'project-management/schedules',
        id,
        body,
        attribute: 'sync-progress',
        signal,
      });

      const promises = data?.data?.map?.(payload => this.update_activity(payload, false));
      await Promise.allSettled(promises);
      gantt.render();

      const { data: schedule_data } = await this.$services.project_management.get({
        url: 'project-management/schedules',
        id,
        query: { select: ['schedules'] },
      });
      this.update_active_schedule(
        id,
        schedule_data.data[0],
        false,
      );
      return data;
    },
    async delete_schedule(id) {
      await this.$services.project_management.delete({
        url: 'project-management/schedules',
        id,
      });
    },
    async set_schedules(query) {
      try {
        const { data } = await this.$services.project_management.getAll({
          attribute: 'schedules',
          query,
        });
        this.schedules = data?.data ?? [];
      }
      catch (error) {
        logger.error(error);
      }
    },
    async search_tasks(id_or_name, schedule_uid = null) {
      try {
        const { data } = await this.$services.project_management.get({
          id: schedule_uid ?? this.active_schedule.uid,
          url: 'project-management/schedules',
          attribute: 'dhtmlx',
          query: { id_or_name },
        });

        const activities = data.data;

        for (const task of activities) {
          this.preprocessTask(task);
          this.active_schedule.activities[task.uid] = task;
        }
      }
      catch (error) {
        logger.error(error);
      }
    },
    async set_children_tasks(parent_id = null, schedule_uid = null, fetch_only = false) {
      try {
        const { data } = await this.$services.project_management.get({
          id: schedule_uid ?? this.active_schedule.uid,
          url: 'project-management/schedules',
          attribute: 'dhtmlx',
          ...(parent_id ? { query: { parent_id } } : { query: { level: 1 } }),
        });

        if (fetch_only)
          return data;

        const activities = data.data;

        for (const task of activities) {
          this.preprocessTask(task);
          this.active_schedule.activities[task.uid] = task;
        }
      }
      catch (error) {
        logger.error(error);
      }
    },
    async set_schedule(schedule_uid) {
      const found = this.schedules.find(schedule => schedule.uid === schedule_uid);
      let is_dynamic_loading = false;
      if (found) {
        is_dynamic_loading = found?.is_dynamic_loading;
      }
      else {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: schedule_uid,
        });
        const schedule = data.data[0];
        is_dynamic_loading = schedule?.is_dynamic_loading;
      }

      let select = [
        'versions',
        'calendars',
        'relations',
        'resources',
        'schedules',
        'suggestions',
        'resource_assignments',
      ];

      if (!is_dynamic_loading)
        select.push('activities');

      select = select.join(',');

      try {
        // fetch the schedule with selected properties
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: schedule_uid,
          query: { select },
        });
        const schedule = data.data[0];

        if (is_dynamic_loading) {
          const data = await this.set_children_tasks(null, schedule_uid, true);
          schedule.activities = data.data;
        }

        // initialize markers and add the default ones
        const markers = [
          [new Date(), 'today', 'Today', 'today-marker'],
          [new Date(schedule.data_date), 'data-date', 'Data date', 'data-date-marker'],
        ];

        this.set_markers(markers.map(marker =>
          ['start_date', 'name', 'text', 'css']
            .reduce((acc, cur, index) => {
              acc[cur] = marker[index];
              return acc;
            }, {}),
        ));

        // prepare the data to be used with DHTMLX Gantt
        for (const task of schedule.activities) {
          this.preprocessTask(task);

          // if the type of a task is project, then add project-specific markers
          if (task.type === 'project')
            markers.push(
              [task.start_date, `project_${task.uid}_start`, 'Project Start', 'project-start-marker'],
              [task.end_date, `project_${task.uid}_end`, 'Project End', 'project-end-marker'],
            );
        }

        schedule.activities = keyBy(schedule.activities, 'uid');

        // transform activities and markers and set the active_schedule data
        this.active_schedule = schedule;
        this.active_schedule_links = schedule.relations;

        const found = this.schedules?.find(s => s.uid === schedule.uid);
        if (!found)
          this.schedules.push(schedule);
      }
      catch (error) {
        logger.error(error);
      }
    },
    preprocessTask(task) {
      task.name = DOMPurify.sanitize(task.name, { ALLOWED_TAGS: [] });
      task.total_duration = task.duration;
      task.type = task.is_milestone ? 'milestone' : task.type.toLowerCase();
      task.weight = task.type === 'project' && task.weight === null ? 1 : task.weight;
      task.start_date = gantt.date.day_start(new Date(task.start));
      task.end_date = gantt.date.day_start(new Date(task.finish));
      task.start_date_str = $date(task.start_date, 'DATE_MED');
      task.end_date_str = $date(task.end_date, 'DATE_MED');
      task.progress_str = `${Math.round(task.progress * 100)}%`;
      task.bl_start = task.bl_start ? gantt.date.day_start(new Date(task.bl_start)) : null;
      task.bl_finish = task.bl_finish ? gantt.date.day_start(new Date(task.bl_finish)) : null;
      task.actual_start = task.actual_start ? gantt.date.day_start(new Date(task.actual_start)) : null;
      task.actual_finish = task.actual_finish ? gantt.date.day_start(new Date(task.actual_finish)) : null;
      task.planned_start = task.planned_start ? gantt.date.day_start(new Date(task.planned_start)) : null;
      task.planned_finish = task.planned_finish ? gantt.date.day_start(new Date(task.planned_finish)) : null;
    },
    async set_views(schedule_uid, $t) {
      try {
        // fetch the views stored on server against this schedule
        const { data } = await this.$services.project_management.getAll({
          attribute: `schedules/${schedule_uid}/views`,
        });

        const user_defined_views = (data?.data || []).filter(v => !v?.data?.configuration);

        // set the views array
        this.views = [getDefaultView($t), ...user_defined_views];

        // find the view that has the is_default property set to true
        const default_user_defined_view = user_defined_views.find(view => view.default);

        this.set_active_view(default_user_defined_view?.uid);
      }
      catch (error) {
        logger.error(error);
      }
    },
    set_active_view(view_uid = '__default') {
      this.set_active_task_uid();
      const view = this.views.find(view => view.uid === view_uid);
      this.active_view = cloneDeep(view);
      this.set_view_dirtiness(false);
    },
    async create_view(label, is_default = true) {
      const config = {
        ...this.active_view.data,
        grid_width: gantt.config.grid_width,
        columns: gantt.config.columns.filter(column => column.name !== 'select-columns'),
      };
      try {
        const payload = { ...config, label };
        delete payload.uid;
        const { data } = await this.$services.project_management.post({
          attribute: `schedules/${this.active_schedule.uid}/views`,
          body: {
            default: is_default,
            data: payload,
          },
        });

        if (is_default === true)
          this.views.forEach((item) => {
            item.default = false;
          });

        const view = data?.data;
        if (!view)
          throw new Error('empty response');

        this.views = [...this.views, view];
        this.set_active_view(view.uid);
      }
      catch (error) {
        logger.error(error);
      }
    },
    async delete_view(view_uid) {
      let resolve = null;
      try {
        const data = await this.$services.project_management.delete({
          attribute: `/schedules/${this.active_schedule.uid}/views/${view_uid}`,
        });

        resolve = data;
      }
      catch (error) {
        logger.error(error);
        return resolve;
      }

      this.set_active_view('__default');
      this.views = this.views.filter(v => v.uid !== view_uid);

      return resolve;
    },
    async update_view(view) {
      try {
        view.data.grid_width = gantt.config.grid_width;
        view.data.columns = gantt.config.columns.filter(column => column.name !== 'select-columns');
        await this.$services.project_management.patch({
          attribute: `schedules/${this.active_schedule.uid}/views/${view.uid}`,
          body: { data: view.data },
        });

        const index = this.views.findIndex(v => v.uid === view.uid);
        if (index !== -1)
          this.views[index] = view;
      }
      catch (error) {
        logger.error(error);
      }
    },
    async create_update_or_delete_custom_field({ type, schedule_uid, body }) {
      if (!['post', 'patch', 'delete'].includes(type))
        return;

      const id = schedule_uid ?? this.active_schedule.uid;
      const { data } = await this.$services.project_management[type]({
        id,
        url: 'project-management/schedules',
        attribute: 'custom-field',
        body,
      });

      if (body.field && body.new_name && body.field !== body.new_name) {
        this.update_column({
          old_name: body.field,
          new_name: body.new_name,
        });
      }
      else {
        // to force deeply nested fields like currency symbols to refresh
        const columns = cloneDeep(this.active_view.data.columns);
        this.active_view.data.columns = columns;
      }

      if (!data?.data?.custom_fields)
        return;

      this.update_active_schedule(id, { custom_fields: data.data.custom_fields }, false);
      return data;
    },
    async add_or_update_custom_field_value(body) {
      const id = this.active_schedule.uid;
      const { data } = await this.$services.project_management.post({
        id,
        url: 'project-management/schedules',
        attribute: 'activities/custom-field',
        body,
      });

      if (!data?.data?.length)
        return;

      const custom_fields = cloneDeep(this.active_schedule.custom_fields);
      for (const field in custom_fields) {
        const fields = data.data.map(a => a.custom_field_values[field]).filter(x => x);
        custom_fields[field].values.push(...fields);
      }
      this.update_active_schedule(id, { custom_fields }, false);

      const promises = data?.data?.map?.(payload => this.update_activity(payload, false));
      await Promise.allSettled(promises);
      gantt.render();

      return data;
    },
    async update_activity_reference({ activity, body }) {
      if (!activity?.uid)
        return;

      const id = this.active_schedule.uid;
      const { data } = await this.$services.project_management.patch({
        id,
        url: 'project-management/schedules',
        attribute: `activities/${activity.uid}/references`,
        body,
      });

      await this.update_activity(data?.data, false);
      gantt.render();

      return data;
    },
    async update_activity_progress(
      {
        progress,
        data_date,
        actual_start_date,
        actual_finish_date,
      },
    ) {
      const body = { progress, data_date };

      if (this.active_task.progress === 0 && progress < 1) {
        body.actual_start = actual_start_date;
      }
      else if (this.active_task.progress > 0 && progress === 1) {
        body.actual_finish = actual_finish_date;
      }
      else if (this.active_task.progress === 0 && progress === 1) {
        body.actual_start = actual_start_date;
        body.actual_finish = actual_finish_date;
      }

      try {
        const { data } = await this.$services.project_management.patch({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${this.active_task.uid}/progress`,
          body,
        });

        const timer = ms => new Promise(resolve => setTimeout(resolve, ms));

        for (const task of data.data) {
          await timer(100);
          await this.update_activity(task, false);
        }
        gantt.render();

        return data;
      }
      catch (error) {
        this.$toast(
          {
            title: error?.data?.title || 'Something went wrong',
            text: error?.data?.description || 'Please try again',
            type: 'error',
            timeout: 4000,
          },
        );
      }
    },
    async update_activity(payload, refetch = false) {
      if (!payload && !refetch)
        return;

      const uid = payload?.uid;

      const activity = this.active_schedule.activities?.[uid];

      if (!gantt.isTaskExists(activity?.id))
        return;

      let update_data = payload;
      if (refetch) {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${uid}`,
        });

        update_data = data.data[0];
      }

      const task = gantt.getTask(activity.id);

      for (const key in update_data) {
        let value = update_data[key];

        if (['start', 'start_date'].includes(key)) {
          value = gantt.date.day_start(new Date(update_data.start));
          activity.start_date = value;
          task.start_date = value;
        }
        else if (['finish', 'end_date'].includes(key)) {
          value = gantt.date.day_start(new Date(update_data.finish));
          activity.end_date = value;
          task.end_date = value;
        }
        else if (key === 'duration') {
          value = update_data.duration;
          activity.duration = value;
          activity.total_duration = value;
          task.duration = value;
          task.total_duration = value;
        }
        else if (PROPERTIES_FOR_DATES.includes(key)) {
          value = update_data[key]
            ? gantt.date.day_start(new Date(update_data[key]))
            : null;
          activity[key] = value;
          task[key] = value;
        }
        else if (PROPERTIES_GENERAL.includes(key)) {
          activity[key] = value;
          task[key] = value;
        }
      }

      this.preprocessTask(task);
      this.active_schedule.activities[uid] = { ...activity };
    },
    async set_activity_auto_progress(body) {
      const { data } = await this.$services.project_management.post({
        id: this.active_schedule.uid,
        url: 'project-management/schedules',
        attribute: `activities/${this.active_task.uid}/sync-progress`,
        body,
      });

      await this.update_activity({
        uid: this.active_task.uid,
        auto_progress_sync: data.data,
      }, false);
      gantt.render();

      return data;
    },
    async remove_activity_auto_progress_link() {
      const { data } = await this.$services.project_management.delete({
        id: this.active_schedule.uid,
        url: 'project-management/schedules',
        attribute: `activities/${this.active_task.uid}/sync-progress`,
      });

      if (data?.data?.length) {
        for (const activity of data.data)
          await this.update_activity(activity, false);
        gantt.render();
      }
      return data;
    },
    set_view_dirtiness(bool) {
      this.view_dirtiness = bool ? this.view_dirtiness + 1 : 0;
    },
    set_search_config(val) {
      this.search_config = {
        ...this.search_config,
        ...val,
      };
    },
    modify_filter(payload) {
      const filters = { ...(this.active_view?.filters ?? {}), ...payload };
      this.active_view.data.filters = filters;
    },
    set_markers(payload) {
      this.markers = [...payload];
    },
    set_active_task_uid(uid = null, delay = 250) {
      if (this.active_task_uid === uid)
        return;

      if (!uid) {
        const already_selected_task_id = gantt.getSelectedId();
        if (already_selected_task_id)
          gantt.unselectTask(already_selected_task_id);
      }

      const task = this.active_schedule?.activities?.[uid];

      this.active_task_uid = uid;

      if (!uid || !task)
        return;

      gantt.selectTask(task.id);

      setTimeout(() => {
        gantt.showTask(task.id);
      }, delay); // the delay ensure the resize operation (due to activity details popping up) has finished before it is brought into view
    },
    modify_config(payload) {
      if (this.active_view.data[payload.key] !== payload.value)
        this.active_view.data[payload.key] = payload.value;
    },
    toggle_config(keys = []) {
      for (const key of keys)
        this.active_view.data[key] = !this.active_view[key];
    },
    set_is_fullscreen(bool) {
      this.is_fullscreen = bool;
    },
    set_all_tasks_open_state(bool) {
      window.gantt.eachTask((task) => {
        task.$open = bool;
      });
      window.gantt.render();
    },
    set_active_date_range(val) {
      this.active_view.data.active_date_range = {
        ...this.active_view.data.active_date_range,
        ...val,
      };
    },
    async create_schedule(body) {
      try {
        const { data } = await this.$services.project_management.post({
          url: 'project-management/schedules',
          body,
        });
        return data.data[0];
      }
      catch (error) {
        logger.error(error);
        this.$toast({
          title: error.data.title,
          text: error.data.description,
          type: 'error',
          timeout: 4000,
        });
        return error;
      }
    },
    async update_active_schedule(id, body, sync = false) {
      const schedule = cloneDeep({ ...this.active_schedule, ...body });

      if (this.active_schedule?.uid === id)
        this.active_schedule = schedule;

      if (!sync)
        return;

      await this.$services.project_management.patch({
        url: 'project-management/schedules',
        id,
        body,
      });
    },
    update_column({ old_name, new_name }) {
      gantt.eachTask((task) => {
        const activity = { ...this.active_schedule.activities[task.uid] };

        // update gantt's copy of the task
        task.custom_field_values[new_name] = activity.custom_field_values[old_name];
        // update store's copy of the activity
        activity.custom_field_values[new_name] = activity.custom_field_values[old_name];

        delete task.custom_field_values[old_name];
        delete activity.custom_field_values[old_name];

        this.active_schedule.activities[task.uid] = activity;
      });

      const columns = [...this.active_view.data.columns];
      const found = columns.find(column => column.label === old_name);
      if (found) {
        found.label = new_name;
        found.name = `custom_field_${new_name}`;
        this.active_view.data.columns = columns;
      }

      gantt.render();
    },
    async get_activity_logs(activity_uid) {
      if (!activity_uid)
        return;

      try {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${activity_uid}/logs`,
          activity_uid,
          query: { type: 'PROGRESS_UPDATE' },
        });

        return data?.data || [];
      }
      catch (error) {
        logger.error(error);
        return null;
      }
    },
    async get_activity_progress_history(activity_uid) {
      if (!activity_uid)
        return;

      try {
        const { data } = await this.$services.project_management.get({
          url: 'project-management/schedules',
          id: this.active_schedule.uid,
          attribute: `activities/${activity_uid}/progress-history`,
          activity_uid,
        });

        return data?.data || [];
      }
      catch (error) {
        logger.error(error);
        return null;
      }
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useProjectManagementStore, import.meta.hot));
