<script setup>
import { onMounted } from 'vue';
import { useModal } from 'vue-final-modal';

import { useIntegrationsStore } from '~/common/stores/integrations.store';
import IntegrationDetails from '~/third-party-widgets/components/integrations/integration-details.vue';
import { useThirdPartyConstants } from '~/third-party-widgets/utils/constants.js';

const integration_store = useIntegrationsStore();
const { integration_items } = useThirdPartyConstants();
const integrations_store = useIntegrationsStore();

const { open: openIntegrationModal, close: closeIntegrationModal, patchOptions: patchTeamsOptions } = useModal({
  component: IntegrationDetails,
});

function openIntegrationHandler(option) {
  patchTeamsOptions(
    {
      attrs: {
        integration_type: option.type,
        onClose() {
          closeIntegrationModal();
        },
      },
    },
  );
  openIntegrationModal();
}

onMounted(() => {
  integrations_store.set_integrations();
});
</script>

<template>
  <div>
    <HawkPageHeader class="!px-0">
      <template #title>
        <div>
          <div>{{ $t('Integrations') }}</div>
          <div class="text-sm text-gray-600 font-normal">
            {{ $t('Seamlessly connect third-party applications with TaskMapper to centralize information and boost collaboration') }}
          </div>
        </div>
      </template>
    </HawkPageHeader>
    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
      <div
        v-for="item in integration_items" :key="item.id" class="shadow hover:shadow-lg p-3 rounded-2xl grid gap-4 cursor-pointer"
        @click="openIntegrationHandler(item)"
      >
        <div class="flex justify-between">
          <div>
            <div class="font-semibold pb-0.5">
              {{ item.name }}
            </div>
            <HawkBadge v-if="integration_store.integrations_map?.[item?.type]?.length" color="blue">
              <IconHawkLinkFour class="w-3 h-3" />

              {{ integration_store.integrations_map[item.type].length }}
              {{ $t('accounts') }}
            </HawkBadge>
          </div>
          <div class="w-11 h-11 grid place-content-center shadow-md bg-white rounded">
            <component :is="item.icon" class="w-11 h-11" />
          </div>
        </div>

        <div class="text-xs">
          {{ $t(item.description) }}
        </div>
        <hr>
        <div class="flex justify-between">
          <HawkButton type="outlined" @click="openIntegrationHandler(item)">
            {{ $t('View integration') }}
          </HawkButton>
        </div>
      </div>
    </div>
  </div>
</template>
