<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const tabs = computed(() => [
  {
    uid: 'tasks',
    label: 'Tasks',
    to: { name: 'task-templates', params: { asset_uid: route.params.asset_id } },
    disabled: false,
  },
  {
    uid: 'checklists',
    label: 'Checklists',
    to: { name: 'checklistTemplates', params: { asset_uid: route.params.asset_id } },
    disabled: false,
  },
]);
const active_tab = computed(() => route.name === 'checklistTemplates' ? 'checklists' : 'tasks');
</script>

<template>
  <div>
    <hawk-tabs v-if="!route.params.checklist_id" :tabs="tabs" :active_item="active_tab" />
    <router-view />
  </div>
</template>
