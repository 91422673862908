<script setup>
import DOMPurify from 'dompurify';

const props = defineProps(['option']);
</script>

<template>
  <div>
    <p v-if="props?.option?.label_template" class="font-medium" v-html="DOMPurify.sanitize(props?.option?.label_template)" />
    <p v-else-if="!props?.option?.label_template" class="font-medium">
      {{ props?.option?.label }}
    </p>
    <p v-if="props?.option?.description" class="mt-1 text-gray-500">
      {{ props.option.description }}
    </p>
  </div>
</template>
