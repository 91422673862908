<script setup>
import { useRoute, useRouter } from 'vue-router';

import { currentOrganizationCookie } from '~/common/utils/common.utils';
import { useThirdPartyConstants } from '~/third-party-widgets/utils/constants.js';

const $services = inject('$services');
const $toast = inject('$toast');
const route = useRoute();
const router = useRouter();
const { integration_items_map } = useThirdPartyConstants();

async function createIntegration() {
  try {
    const type = route.params.type.toUpperCase();
    await $services.integrations.post({
      body: {
        name: '',
        description: '',
        type,
        organization: currentOrganizationCookie(),
        code: route.query.code,
      },
    });
    const name = integration_items_map?.[type]?.name || type;
    $toast({
      title: 'Integrations',
      text: `Integrated ${name} successfully.`,
      type: 'success',
    });
    router.push({ name: 'account-settings-integrations' });
  }
  catch (error) {
    $toast({
      title: 'Integrations',
      text: 'Integration unsuccessful',
      type: 'error',
    });
    logger.log('🚀 ~ createIntegration ~ error:', error);
    router.push({ name: 'home' });
  }
}
createIntegration();
</script>

<template>
  <div />
</template>
