<script setup>
import { cloneDeep, isEmpty, keyBy, omit } from 'lodash-es';
import { computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Validator } from '@vueform/vueform';
import dayjs from 'dayjs';
import { useTransmittalsStore } from '~/dms/store/transmittals.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTransmittalWorkflowConfiguration, useTransmittalWorkflowPayloadConfiguration } from '~/dms/composables/useTransmittalWorkflow.js';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useTransmittalWorkflowStore } from '~/dms/store/transmittal-workflow.store';

import useEmitter from '~/common/composables/useEmitter';

const router = useRouter();
const route = useRoute();
const $toast = inject('$toast');

const authStore = useAuthStore();
const transmittals_store = useTransmittalsStore();
const dms_settings_store = useDMSSettingsStore('transmittals');
const emitter = useEmitter();
const transmittal_workflow_store = useTransmittalWorkflowStore();

const form$ = ref(null);
const $t = inject('$t');

const { formatSteps, formatStatusMap } = useTransmittalWorkflowPayloadConfiguration();
const { getSelectedWorkflow, get_default_statuses } = useTransmittalWorkflowConfiguration();

provide('form$', form$);

const state = reactive({
  is_transmitting: false,
  is_fetching: false,
  is_sub_workflow: !!transmittals_store.sub_workflow_documents,
  default_documents: keyBy(transmittals_store.sub_workflow_documents || [], 'uid'),
  created_transmittal_uid: null,
});

transmittals_store.sub_workflow_documents = null;

const current_step = computed(() => form$.value?.steps$.current$?.name);
const transmittal = computed(() => transmittals_store.transmittal);
const logged_in_user = computed(() => authStore?.logged_in_user_details);
const transmittal_uid = computed(() => route?.query?.transmittal);
const transmittal_configuration = ref(null);
const default_statuses = ref(null);
const workflows = computed(() => transmittal_workflow_store.workflows);
const new_documents = ref([]);


emitter.on('create-transmittals', submitTransmittals);
emitter.on('transmittal-save-as-draft', saveAsDrafts);
emitter.on('update-transmittal-form', updateForms);

const $date = inject('$date');

function get_formatted_due_date(duration) {
  if (!duration)
    return '-';
  const date = new Date();
  date.setDate(date.getDate() + +duration);
  return $date(date, 'DATE_MED');
}

const document_options = computed(() => ({
  is_date_disabled: true,
  can_add_documents: form$?.value?.tabs$?.current$?.name === 'documents',
  is_comments_enabled: form$?.value?.tabs$?.current$?.name === 'documents',
  due_date: form$?.value?.data?.submission_due_date ? $date(form$?.value?.data?.submission_due_date, 'DATE_MED') : get_formatted_due_date(form$?.value?.data?.reviewal_duration),
}));

onBeforeMount(async () => {
  state.is_fetching = true;
  transmittals_store.transmittal = {};
  const promise = dms_settings_store.fetch_all_issue_purposes();
  const promise1 = dms_settings_store.fetch_all_statuses();
  const promise2 = dms_settings_store.set_custom_fields({ query: { active: true, allowed_in: 'transmittals' } });
  const promise3 = transmittal_workflow_store.set_workflow({
    query: {
      active: true,
    },
  });
  let promise4;
  if (transmittal_uid.value)
    promise4 = getData();
  else
    default_statuses.value = get_default_statuses();
  await Promise.all([promise, promise1, promise2, promise3, promise4]);
  state.is_fetching = false;
});

async function getData() {
  await transmittals_store.set_transmittal({
    transmittal_uid: transmittal_uid.value,
    query: {
      documents: true,
    },
  });
  const { workflow, review_status_map } = transmittal.value;
  transmittal_configuration.value = workflow && !isEmpty(workflow) ? getSelectedWorkflow({ workflow }) : undefined;
  default_statuses.value = get_default_statuses(workflow?.status_map || review_status_map);
}

function updateForms(options) {
  form$.value.update(options);
}

function formMounted(el$) {
  if (transmittal_uid.value) {
    const { documents, messages } = transmittal.value;
    if (!isEmpty(documents)){
      documents.forEach((doc) => {
        doc.due_date = doc.submission_due_date;
        el$.data.documents[doc.uid] = doc;
      });
      new_documents.value = documents;
    }
    setTimeout(() => {
      el$.update({
        ...transmittal.value,
        reviewal_duration: documents?.[0]?.reviewal_duration,
        message: {
          body: messages?.length ? messages[0].message : null,
          attachments: messages?.length ? messages[0].attachments : null,
        },
      });
    }, 10);
  }
}

function formatWorkflowData(data, workflow_template) {
  if (transmittal_configuration.value && data) {
    const payload = cloneDeep(data);
    payload.steps = formatSteps(transmittal_configuration.value.workflow.steps);
    payload.status_map = formatStatusMap(payload?.status_map, transmittal_configuration.value.workflow.status_map);
    payload.name = workflows.value?.find(workflow => workflow.uid === workflow_template)?.name;
    return payload;
  }
}

function getTransmittalType(has_submitter, has_reviewer, has_approver, has_workflow) {
  if (has_submitter && (has_reviewer || has_approver || has_workflow))
    return 'submission_reviewal';
  else if (!has_submitter && (has_reviewer || has_approver || has_workflow))
    return 'reviewal';
  else
    return 'submission';
}

function formatTransmittalData(data, extras = {}) {
  const members = (data?.members || []).map(member => ({ ...member, role: member.access || member.role }));
  const has_approver = !!members.find(member => member.access === 'approver');
  const has_reviewer = !!members.find(member => member.access === 'reviewer');
  const has_submitter = !!members.find(member => member.access === 'submitter');
  const workflow = formatWorkflowData(data?.workflow, data?.workflow_template);
  const status_map = data?.review_status_map?.status_map || data?.workflow?.status_map;
  const review_status_map = status_map ? formatStatusMap(status_map, default_statuses.value) : undefined;
  const review_status_set = review_status_map?.reduce((status_set, status) => {
    if (status.review_status)
      status_set[status.key] = status.review_status;

    return status_set;
  }, {});

  const remove_extension = filename => filename.substring(0, filename.lastIndexOf('.')) || filename;

  const document_data = form$.value.data.documents;

  const documents = new_documents.value.map(document => ({
    ...omit(document, 'versions'),
    comments: document_data[document.uid]?.comments,
    mode: has_submitter ? 'submit' : 'review',
    file_name: document.name,
    submission_due_date: data.submission_due_date,
    reviewal_duration: !Number.isNaN(data?.reviewal_duration) ? +data?.reviewal_duration : undefined,
    name: remove_extension(document.name),
  }));

  // transmittal due date calculation
  const current_date = dayjs();
  const reviewal_due_date = data?.reviewal_duration ? current_date.add(data.reviewal_duration, 'day') : null;
  const reviewal_due_date_iso_string = reviewal_due_date?.toISOString();
  const submission_due_date = data?.submission_due_date ? dayjs(data.submission_due_date) : null;

  let transmittal_due_date = data.submission_due_date || reviewal_due_date_iso_string;
  if (submission_due_date && reviewal_due_date)
    transmittal_due_date = submission_due_date.add(data.reviewal_duration, 'day').toISOString();
  // transmittal due date calculation end

  const review_condition = data?.review_condition ? 'review_condition' : 'manual';
  const transmittals = {
    ...omit(data, ['reviewers', 'information', 'information_submitter', 'workflow_template', 'review_status_map', 'approvers_submitter', 'approvers', 'is_request_documents', 'is_request_review', 'submitter', 'duration', 'reviewal_duration']),
    asset: route?.params?.asset_id || null,
    members,
    workflow,
    workflow_template: data?.workflow_template,
    due_date: transmittal_due_date,
    review_status_map,
    review_status_set,
    feedback_visibility: data?.workflow?.feedback_visibility || data?.feedback_visibility,
    review_type: data?.workflow_template ? 'workflow' : review_condition,
    type: getTransmittalType(has_submitter, has_reviewer, has_approver, data?.workflow_template),
    message: data?.message || '',
    documents,
    field_values: data?.field_values,
    ...extras,
  };
  return transmittals;
}

async function transmittalActions(transmittal_uid, payload) {
  const uid = state.created_transmittal_uid || transmittal_uid;
  return uid ? await transmittals_store.update_transmittal({ transmittal_uid: uid, transmittals: payload }) : await transmittals_store.create_transmittal({ transmittals: payload });
}

async function submitTransmittals(form$) {
  try {
    state.is_transmitting = true;
    const payload = formatTransmittalData(form$.requestData);
    const response = await transmittalActions(transmittal_uid.value, payload);
    if (response?.data?.code === 'BAD_REQUEST') {
      $toast({ text: response.data.description, type: 'error' });
    }
    else if (response) {
      const transmit_response = await transmittals_store.transmit_transmittal({
        transmittal_uid: response.uid,
        transmittals: {
          message: payload.message,
          documents: response.documents.map(({ uid, comments_out, comments_in }) => ({
            uid,
            comments: (comments_in || comments_out || ''),
          })),
        },
      });
      if (transmit_response)
        router.push({ name: 'files-transmittals-detail', params: { asset_id: route.params.asset_id, transmittal_uid: response.uid } });
    }
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_transmitting = false;
  }
}

async function saveAsDrafts() {
  try {
    await form$.value.validate();
    if (form$.value.invalid) {
      $toast({
        title: 'Error',
        text: 'Please fill the required fields',
        type: 'error',
      });
      const error_tab = form$.value.tabs$?.tabs$Array.find(tab => tab.invalid);
      error_tab?.select();
      return;
    }
    const formatted_data = formatTransmittalData(form$.value.requestData);
    const response = await transmittalActions(transmittal_uid.value, formatted_data);
    state.created_transmittal_uid = response.uid;
  }
  catch (err) {
    logger.error(err);
  }
}

onUnmounted(() => {
  emitter.off('create-transmittals', submitTransmittals);
  emitter.off('transmittal-save-as-draft', saveAsDrafts);
  emitter.off('update-transmittal-form', updateForms);
});

const isEmptyObjectRule = class extends Validator {
  get message() {
    return $t('At least one document is required');
  }

  check(value) {
    return new_documents.value.length > 0;
  }
};

const hasExistingTransmittal = class extends Validator {
  get message() {
    return $t('Some documents are already present in another transmittal');
  }

  check(value) {
    return new_documents.value.filter(file => file.active_transmittal?.uid).length === 0;
  }
};
</script>

<template>
  <div class="py-4">
    <create-transmittal-header
      class="mt-2"
    />
    <div v-if=" state.is_fetching">
      <HawkLoader />
    </div>
    <Vueform
      v-else ref="form$"
      size="sm"
      :loading="state.is_transmitting"
      :columns="{
        default: { container: 12, wrapper: 12, label: 3 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, label: 3, wrapper: 12 },
      }"
      :messages="{
        required: 'This field is required',
      }"
      @submit="submitTransmittals"
      @mounted="formMounted"
    >
      <template #empty>
        <FormTabs class="max-w-[990px] ml-4 mt-4 !mb-0">
          <FormTab
            name="details"
            label="General"
            :elements="['priority', 'category', 'issue_purpose', 'name', 'message', 'field_values']"
          />
          <FormTab label="Documents" name="documents" :elements="['documents']" />

          <FormTab
            label="Recipients"
            name="recipients"
            :elements="['review_status_map', 'workflow_template', 'workflow', 'recipient_details', 'feedback_visibility', 'review_type', 'reviewal_duration', 'reviewal_entry_status', 'submission_entry_status', 'submission_due_date', 'review_condition', 'minimum_approvals', 'elements_divider', 'members', 'reset_due_dates']"
          />

          <FormTab
            name="preview" label="Preview/Publish"
            :elements="['preview', 'documents']"
          />
        </FormTabs>

        <FormElements class="border-t border-b py-5">
          <create-transmittal-general
            class="col-span-12 max-w-[700px]  pl-4"
            :documents="form$?.data?.documents"
          />
          <create-transmittal-recipient
            v-model="transmittal_configuration"
            v-model:default_statuses="default_statuses"
            :form$="form$"
            :transmittal="transmittal"
            :new_documents="new_documents"
            :is_sub_workflow="state.is_sub_workflow"
          />

          <GroupElement name="preview">
            <transmittal-detail
              v-if="form$?.tabs$?.current$?.name === 'preview'"
              :transmittal="formatTransmittalData(form$?.requestData, { owner: transmittal?.owner || logged_in_user?.user_id })"
              class="border-b pb-5 mb-5  px-4"
            />
          </GroupElement>
          <ObjectElement
            name="documents"
            :columns="{
              default: { container: 12, wrapper: 12, label: 12 },
              sm: { container: 12, label: 12, wrapper: 12 },
              md: { container: 12, label: 12, wrapper: 12 },
            }"
            :default="state.default_documents"
            :add-classes="{
              ElementError: {
                container: ['ml-5'],
              },
            }"
            :rules="[isEmptyObjectRule, hasExistingTransmittal]"
          >
            <transmittal-detail-document
              classes="col-span-12 px-4"
              :form$="form$"
              :options="document_options"
              :transmittal="form$?.data"
              :new_documents="new_documents"
              @updateDocument="new_documents = $event"
              @delete="new_documents=new_documents.filter(doc=>doc.uid!==$event);form$.elements$.documents.validate();"
            />
          </ObjectElement>
        </FormElements>
        <create-transmittal-footer :save_as_draft="saveAsDrafts" class="px-4" />
      </template>
    </Vueform>
  </div>
</template>
