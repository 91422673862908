<script setup>
import { isArray, isObject } from 'lodash-es';

const props = defineProps({
  progress: {
    type: [String, Array, Number],
    default: null,
  },
  total: {
    type: Number,
    default: 100,
  },
  placement: {
    type: String,
    default: 'top',
    validator(value) {
      return ['auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end'].includes(value);
    },
  },
  tooltip_content: {
    type: Function,
    default: () => '',
  },
  show_legend: {
    type: Boolean,
    default: false,
  },
  show_tooltip: {
    type: Boolean,
    default: true,
  },
  show_value_in_percentage: {
    type: Boolean,
    default: false,
  },
  has_custom_color: {
    type: Boolean,
    default: false,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: Array,
    default: () => ['blue', 'red', 'green', 'orange', 'indigo', 'purple'],
  },
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['xs', 'sm', 'md', 'lg'].includes(value);
    },
  },
});

const state = reactive({
  total: 0,
});

const progress_color = computed(() => props.color);

const progress_value = computed(() => {
  const value = [];
  const previous_left = [];
  if (isArray(props.progress))
    props.progress.forEach((progress, index) => {
      if (isObject(progress)) {
        previous_left.push(index === 0 ? 0 : ((100 * props.progress[index - 1]?.value) / state.total) + previous_left[index - 1]);
        const val = ((100 * progress.value) / state.total).toFixed(2);
        value.push({ ...progress, value_in_percentage: val, left: previous_left[index], index, s: value[index], uid: getUuid() });
      }
      else {
        previous_left.push(index === 0 ? 0 : ((100 * props.progress[index - 1]) / state.total) + previous_left[index - 1]);
        const val = ((100 * progress) / state.total).toFixed(2);
        value.push({ value: progress, value_in_percentage: val, left: previous_left[index], index, s: value[index], uid: getUuid() });
      }
    });
  else if (typeof props.progress === 'string' || typeof props.progress === 'number')
    value.push({ value: props.progress, value_in_percentage: (props.progress * 100).toFixed(2) / state.total, uid: getUuid() });

  return value;
});

function getTooltipContent(item) {
  if (props.tooltip_content(item))
    return props.tooltip_content(item);
  if (props.show_value_in_percentage)
    return item?.title ? `${item.title} : ${item.value_in_percentage}%` : `${item.value_in_percentage}%`;
  return item?.title ? `${item.title} : ${item.value}` : `${item.value}`;
}

function getUuid() {
  return (crypto.randomUUID()).substring(0, 8);
}

onMounted(() => {
  let sum = 0;
  if (isArray(props.progress))
    sum = (props.progress).reduce((accumulator, currentValue) => accumulator + isObject(currentValue) ? currentValue.value : currentValue, 0);
  else if (typeof props.progress === 'string' || typeof props.progress === 'number')
    sum = props.progress <= 100 ? 100 : props.progress;
  state.total = (props.total <= sum) ? sum : props.total;
});

const height_class = computed(() => {
  if (props.size === 'xs')
    return 'h-[6px]';
  else if (props.size === 'sm')
    return 'h-2';
  else if (props.size === 'md')
    return 'h-[10px]';
  else if (props.size === 'lg')
    return 'h-3';
});

function backgroundProgressColor(index) {
  if (props.is_disabled)
    return 'bg-gray-400 pointer-events-none';
  return !props.has_custom_color ? `bg-${progress_color.value[index % progress_color.value?.length]}-500` : null;
}
</script>

<template>
  <div>
    <div v-if="show_legend" class="flex flex-wrap gap-3 mb-2 text-xs text-gray-600" :class="{ 'opacity-40': is_disabled }">
      <div v-for="(item, index) in progress_value" :key="item.uid" class="flex items-center gap-2">
        <div
          class="h-2 w-2 rounded-full"
          :class="[!has_custom_color ? `bg-${progress_color[index % progress_color?.length]}-500` : null]"
          :style="[has_custom_color ? `background-color:${item?.color ? item?.color : '#667085'}` : null]"
        />
        {{ item?.title ? item.title : item.value }}
      </div>
    </div>
    <div class="bg-gray-200 rounded-lg relative w-full" :class="height_class">
      <div
        v-for="(item, index) in progress_value" :key="item.uid"
        v-tippy="{ content: show_tooltip ? getTooltipContent(item) : '', placement }"
        class="absolute cursor-pointer"
        :class="[backgroundProgressColor(index),
                 { 'rounded-l-lg': index === 0 },
                 { 'rounded-r-lg': index === (progress_value.length - 1) }, height_class]"
        :style="[`width:${item.value_in_percentage}%;left:${item.left}%`, has_custom_color ? `background-color:${item?.color ? item?.color : '#667085'}` : null]"
      />
    </div>
  </div>
</template>
