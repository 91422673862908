<script setup>
defineProps({
  sheet_url: {
    type: [File, String, Object], // File blob, String path, pdf tron doc object
  },
});
defineEmits(['close']);

function initDocViewer(doc_instance, doc_tools) {
  doc_tools.pan();
}
</script>

<template>
  <hawk-modal-container content_class="!w-auto">
    <hawk-modal-header @close="$emit('close')">
      <template #title>
        {{ $t('File preview') }}
      </template>
    </hawk-modal-header>
    <div class="scrollbar text-sm h-[calc(100vh-300px)] w-[70vw] mx-2">
      <sheet-viewer :sheet_url="sheet_url" :no_tools="true" scroll_view_class="border-0 h-full" @on-doc-load="initDocViewer" />
    </div>
  </hawk-modal-container>
</template>
