import { reactive } from 'vue';
import { useRoute } from 'vue-router';

// stores
import { useAuthStore } from '~/auth/stores/auth.store';

// constants
import { DOCUMENT_PERMISSIONS } from '~/dms/constants';
import { FORM_PERMISSIONS } from '~/forms/constants';

export function useDocumentPermissions() {
  const route = useRoute();
  const auth_store = useAuthStore();

  const asset_id = route.params.asset_id;

  // global permissions
  const global_permissions = reactive({
    can_create_documents: auth_store.check_permission(DOCUMENT_PERMISSIONS.CREATE_DOCUMENTS, asset_id),
    can_create_v2_forms: auth_store.check_permission(FORM_PERMISSIONS.V2_CREATE_FORMS, asset_id),
    can_create_tasks: auth_store.check_permission('create_tasks', asset_id),
  });

  // document level permissions check
  function checkDocumentPermission({ permission = '', item = {} }) {
    switch (permission) {
      case 'share':
        return item?.actions?.can_share || false;
      case 'rename':
      case 'edit':
        return item?.actions?.can_rename || false;
      case 'move':
        return item?.actions?.can_move || false;
      case 'versions':
        return item?.actions?.can_add_versions || false;
      case 'delete':
        return item?.actions?.can_delete || false;
      case 'integrate':
        return auth_store.check_split('DMS_sharepoint') && (item?.actions?.can_integrate || false);
      case 'restore':
        return item?.actions?.can_restore || false;
      case 'transfer':
        return item?.actions?.can_transfer_ownership || false;
      case 'task':
        return global_permissions.can_create_tasks;
      case 'form':
        return global_permissions.can_create_v2_forms;
      case 'view':
      case 'download':
      case 'export':
        return true;
      case 'weightage':
        return (item?.actions?.can_assign_weightage);
      case 'autonumbering':
        return auth_store.check_split('files_v2_advanced') && (item?.actions?.can_add_auto_number_data || false);
      case 'bulk_update':
        return auth_store.check_split('files_v2_advanced');
      case 'archive':
        return item?.actions?.can_archive || false;
      case 'destination_folder':
        return item?.write_access || item?.ancestor_write_access || item?.owner_access;
      default:
        return false;
    }
  }

  // bulk selection permissions check
  function checkDocumentBulkPermission({ permission = '', bulk_items = [] }) {
    return bulk_items.every(bulk_item => checkDocumentPermission({ permission, item: bulk_item }));
  }

  return {
    checkDocumentPermission,
    checkDocumentBulkPermission,
  };
}
