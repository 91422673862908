<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps({
  show_info: {
    type: String,
  },
});

const emits = defineEmits(['close']);
</script>

<template>
  <TransitionRoot as="template" :show="show_info === 'review_condition'" @close="emits('close')">
    <Dialog as="div" class="relative z-[1000]">
      <div class="fixed inset-y-0 right-0 flex pl-10">
        <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
          <DialogPanel class="pointer-events-auto w-[400px]">
            <div class="relative h-full flex flex-col bg-white shadow-xl flex-1 px-4 sm:px-6 pb-6 overflow-auto scrollbar">
              <div class="text-gray-700 text-sm">
                <div class="flex justify-between items-center py-4">
                  <div class="flex items-center">
                    <div class="bg-gray-100 rounded-full p-2 mr-3">
                      <IconHawkAlertCircle />
                    </div>
                    <div>
                      <div class="text-lg font-semibold text-gray-900">
                        Condition
                      </div>
                      <div class="text-sm text-gray-700">
                        Info
                      </div>
                    </div>
                  </div>
                  <div class="cursor-pointer">
                    <IconHawkXClose @click="emits('close')" />
                  </div>
                </div>
                <div>This feature automates document review workflows by intelligently determining the next step based on the reviewers' responses.</div>
                <ol class="list-decimal px-4 ">
                  <li class="list-item font-medium my-4">
                    Option 1 <br>
                    Requires all members to approve:
                  </li>
                  <ul class="list-disc">
                    <li class="list-item">
                      A specific number of reviewers must submit a response for the step to progress.
                    </li>
                    <li class="list-item">
                      If all mandatory reviewers haven't responded, the step remains in progress.After everyone responds the status is calculated.
                    </li>
                    <li class="list-item">
                      {{ 'The overall status reflects the lowest impact response of all reviewers. (Rejected < Revise and Resubmit < Acknowledged < Approved with Comments < Approved).' }}
                    </li>
                    <li class="list-item">
                      <span class="font-medium">Note:</span> In the status mapping section, the order of statuses is structured based on the impact of the review status, with the highest impact status positioned first and the lowest impact status placed last.
                    </li>
                  </ul>
                  <div class="mt-2 -ml-2">
                    <div class="font-medium">
                      Example:
                    </div>
                    <div>Step 1 has 4 mandatory reviewers.</div>
                    <div>Reviewer 1: Approved</div>
                    <div>Reviewer 2: Approved</div>
                    <div>Reviewer 3: Acknowledged</div>
                    <div>Reviewer 4: Revise and Resubmit</div>
                    <div>Here, the overall status becomes Revise and Resubmit due to the lowest impact response among completed reviews.</div>
                  </div>
                  <li class="list-item font-medium my-4">
                    Option 2 <br>
                    Requires any members to approve:
                  </li>
                  <ul class="list-disc">
                    <li class="list-item">
                      Any number of reviewers can provide feedback, with a minimum required to proceed (optional).
                    </li>
                    <li class="list-item">
                      Once the minimum required number of reviewers have responded, the step can progress.
                    </li>
                    <li class="list-item">
                      {{ 'The overall status reflects the highest impact response submitted by any reviewer. (Approved > Approved with Comments > Acknowledged > Revise and Resubmit > Rejected)' }}
                    </li>
                    <li class="list-item">
                      <span class="font-medium">Note:</span> In the status mapping section, the order of statuses is structured based on the impact of the review status, with the highest impact status positioned first and the lowest impact status placed last.
                    </li>
                  </ul>
                  <div class="mt-2 -ml-2">
                    <div class="font-medium">
                      Example:
                    </div>
                    <div>Step 2 allows any reviewer to respond, with a minimum of 2 required.</div>
                    <div>Reviewer 1: Approve</div>
                    <div>Reviewer 2: Rejected</div>
                    <div>The minimum requirement (2 reviewers) is met, allowing the step to progress.</div>
                    <div>The overall status becomes Approved due to the highest impact response</div>
                  </div>
                </ol>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
