<script setup>
import { debounce } from 'lodash-es';
import { Validator } from '@vueform/vueform';

const props = defineProps({
  type: {
    type: String,
    validator: value => ['New Checklist', 'New Checklist Template'].includes(value),
  },
  checklist: {
    type: Object,
    default: () => {
      return {
        name: '',
        items: [],
      };
    },
  },
  on_submit: {
    type: Function,
    required: true,
  },
  index: {
    type: Number,
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');
const $services = inject('$services');

const form$ = ref(null)
const form_data = ref(null);

const show_input = ref(true);

const checklist_templates = ref([]);

const items_list = ref([]);
const search_key = ref('');

const loading = ref(false);

function onFormMounted(el$) {
   el$.load({ checklist_items: props.checklist.items })
};

onMounted(async () => {
  const { data } = await $services.checklists.getAll({ query: { page_number: 1, page_size: 5 } });
  checklist_templates.value = data.checklists;
})
watch(search_key, debounce(async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    const { data } = await $services.checklists.getAll({ query: { page_number: 1, page_size: 5, q: search_key.value } });
    checklist_templates.value = data.checklists;
  }
}, 500));

// Validator for items check
const item_field_validator = class extends Validator {
  get message() {
    return $t('Checklist should have atleast one item');
  }

  check(event) {
    return event?.length || form_data.value.checklist_items?.length;
  }
};

// Event handlers
async function pasteHandler(e) {
  items_list.value = e;
  if (e.length === 1)
    await addItemToCache(e[0]);
  else if (e.length > 1)
    await addItemsToCache();
}
async function blurHandler() {
  if (!items_list.value.length && form$.value.data.items) {
    await addItemToCache(form$.value.data.items);
    form$.value.elements$.items.clear();
  }
}
function handleKeyDown(e) {
  if (e.key === 'Enter' || e.keyCode === 13) {
    form$.value.validate();
    blurHandler();
    e.preventDefault();
    e.stopPropagation();
  }
}

// Item addition, renaming and deletion handlers
function clearHandler() {
  items_list.value = [];
  form$.value.elements$.items.clear();
}
async function addItemToCache(name) {
  await form$.value.elements$.checklist_items.add({
    name,
    uid: crypto.randomUUID(),
  });
  items_list.value = [];
}
async function addItemsToCache() {
  const form_items = items_list.value.map((name) => {
    return {
      name,
      uid: crypto.randomUUID(),
    };
  });
  await form$.value.elements$.checklist_items.load([...form_data.value.checklist_items, ...form_items]);
  items_list.value = [];
  form$.value.elements$?.items.clear();
}
function deleteItem(idx) {
  form$.value.elements$.checklist_items.remove(idx);
  form$.value.elements$?.items?.reinitValidation();
  if (!form_data.value.checklist_items.length)
    show_input.value = true;
}
async function addTemplateItems(val) {
  if (val?.items.length)
    await form$.value.elements$.checklist_items.load(val.items);
}
function clearTemplateItems() {
  form$.value.elements$.checklist_items.clear();
}

const override_classes = computed(() => {
  if (form_data.value?.checklist_items?.length)
    return {
      ElementLayout: {
        innerContainer: 'flex items-center justify-between',
        innerWrapper: 'w-full',
      },
    };
  return {};
});

// Popup save
async function handleSave() {
  loading.value = true;
  let payload = {
    name: form_data.value.name,
    items: form_data.value.checklist_items,
    order_index: props.index,
    ...(props.type === 'New Checklist Template' && { is_template: true }),
  };
  if (form_data.value.template)
    payload = {
      ...payload,
      include: {
        assignee: true,
        attachments: true,
        items: false,
      },
      template_uid: form_data.value.template.uid,
      action: 'load_template',
    };
  await props.on_submit(payload);
  loading.value = false;
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="form_data"
      sync
      size="sm"
      :should_validate_on_mount="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :display-errors="false"
      :prepare="blurHandler"
      :endpoint="handleSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t(type) }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <!-- Checklist Name -->
          <TextElement
            class="mb-4"
            name="name"
            autocomplete="off"
            :label="$t('Checklist name')"
            :placeholder="$t('Checklist name')"
            :rules="['required']"
            :default="checklist.name"
          />
          <SelectElement
            v-if="type === 'New Checklist'"
            class="mb-4"
            name="template"
            track-by="name"
            label-prop="name"
            value-prop="uid"
            :label="$t('Template')"
            :placeholder="$t('Choose Template')"
            :search="true"
            :object="true"
            :items="checklist_templates"
            @search-change="search_key = $event"
            @select="addTemplateItems"
            @clear="clearTemplateItems"
          />
          <p class="font-semibold text-gray-700 mb-3 mt-4">
            {{ $t('Items') }}
          </p>
          <ListElement name="checklist_items" :controls="{ add: false, remove: false, sort: false }">
            <template #default="{ index: idx }">
              <ObjectElement
                :name="idx"
              >
                <TextElement
                  name="name"
                  autocomplete="off"
                  :override-classes="{
                    ElementLayout: {
                      innerContainer: 'flex items-center justify-between',
                      innerWrapper: 'w-full',
                    },
                  }"
                  placeholder="Item name"
                >
                  <template #addon-before>
                    <p>
                      {{ idx + 1 }}.
                    </p>
                  </template>
                  <template #after>
                    <div class="cursor-pointer mx-3">
                      <IconHawkTrashThree class="text-gray-600" @click="deleteItem(idx)" />
                    </div>
                  </template>
                </TextElement>
                <HiddenElement name="uid" />
                <HiddenElement name="note" />
                <HiddenElement name="assignee" />
                <HiddenElement name="attachments" />
                <HiddenElement name="children" />
              </ObjectElement>
            </template>
          </ListElement>

          <hawk-multiline-input
            v-if="show_input"
            v-click-outside="() => blurHandler()"
            class="mb-4 mt-2"
            :options="{
              name: 'items',
              label: '',
              placeholder: 'Enter or paste item names here',
              rules: [item_field_validator],
              overrideClasses: override_classes,
            }"
            :clear_on_confirm="true"
            @create="pasteHandler"
            @clear="() => clearHandler()"
            @keydown="handleKeyDown"
          >
            <template #addon-before>
              <p>
                {{ form_data?.checklist_items?.length + 1 }}.
              </p>
            </template>
            <template #after>
              <div v-if="form_data?.checklist_items?.length" class="cursor-pointer mx-3">
                <IconHawkTrashThree class="text-gray-600" @click="show_input = false" />
              </div>
            </template>
          </hawk-multiline-input>
          <div v-else class="text-sm font-semibold text-gray-700 cursor-pointer" @click="show_input = true">
            +{{ $t('Add Item') }}
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-5" type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :loading="loading" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
