<script setup>
import ElbowLeftIcon from '~icons/hawk/elbow-left';
import ElbowRightIcon from '~icons/hawk/elbow-right';
import FullScreeIcon from '~icons/hawk/full-screen';

const props = defineProps({
  attachment: {
    type: Object,
  },
  document_viewer: {
    type: Object,
  },
});

const emits = defineEmits(['close', 'fullScreen', 'download', 'delete']);

const document_viewer_instance = computed(() => props.document_viewer);

const setFitMode = fit => document_viewer_instance.value?.setFitMode(document_viewer_instance.value.FitMode[fit]);

const zoom_values = [{ label: 'Actual Size', on_click: () => setFitMode('Zoom') },
  { label: 'Page Fit', on_click: () => setFitMode('FitPage') },
  { label: 'Page width', on_click: () => setFitMode('FitWidth') },
  { label: '50%', on_click: () => document_viewer_instance.value?.zoomTo(0.5) },
  { label: '75%', on_click: () => document_viewer_instance.value?.zoomTo(0.75) },
  { label: '100%', on_click: () => document_viewer_instance.value?.zoomTo(1) },
  { label: '125%', on_click: () => document_viewer_instance.value?.zoomTo(1.25) },
  { label: '150%', on_click: () => document_viewer_instance.value?.zoomTo(1.5) },
  { label: '200%', on_click: () => document_viewer_instance.value?.zoomTo(2) },
  { label: '300%', on_click: () => document_viewer_instance.value?.zoomTo(3) },
  { label: '400%', on_click: () => document_viewer_instance.value?.zoomTo(4) },
];

const current_zoom_value = computed(() => Math.floor((document_viewer_instance.value?.getZoomLevel() || 1) * 100));

function zoom_in() {
  document_viewer_instance.value.zoomTo(
    document_viewer_instance.value.getZoomLevel() + 0.25,
  );
}

function zoom_out() {
  document_viewer_instance.value.zoomTo(
    document_viewer_instance.value.getZoomLevel() - 0.25,
  );
}

function rotate_right() {
  document_viewer_instance.value.rotateCounterClockwise();
}

function rotate_left() {
  document_viewer_instance.value.rotateClockwise();
}
</script>

<template>
  <div class="flex text-white items-center justify-between w-full p-4 ">
    <div class="flex z-2 gap-3">
      <HawkButton icon type="plain" class="text-white hover:bg-gray-800" @click="emits('fullscreen')">
        <FullScreeIcon />
      </HawkButton>
      <hawk-menu :items="zoom_values" additional_trigger_classes="!ring-0 !border-0">
        <template #trigger="{ open }">
          <HawkButton type="plain" class="text-white hover:bg-gray-800">
            {{ current_zoom_value }}%
            <IconHawkChevronUp v-if="open" class="text-xl" />
            <IconHawkChevronDown v-else class="text-xl" />
          </HawkButton>
        </template>
      </hawk-menu>
      <HawkButton icon type="plain" class="text-white hover:bg-gray-800" @click="zoom_out">
        <IconHawkMinusCircle />
      </HawkButton>
      <HawkButton icon type="plain" class="text-white hover:bg-gray-800" @click="zoom_in">
        <IconHawkPlusCircle />
      </HawkButton>
      <div class="border border-l my-2" />
      <HawkButton icon type="plain" class="text-white hover:bg-gray-800" @click="rotate_right">
        <ElbowLeftIcon />
      </HawkButton>
      <HawkButton icon type="plain" class="text-white hover:bg-gray-800" @click="rotate_left">
        <ElbowRightIcon />
      </HawkButton>
    </div>
    <div class="flex items-center relative -ml-[183px]">
      <div>
        <HawkBreadcrumbs
          v-if="props.attachment?.breadcrumb_text?.length"
          text_classes="text-xs text-gray-300"
          :show_active_color="false"
          :items="props.attachment.breadcrumb_text"
        />
        <hawk-text :content="props.attachment.file_name" :length="50" />
      </div>
    </div>
    <div class="flex gap-3">
      <slot name="header-right-content" />
      <HawkButton icon type="plain" class="text-white hover:bg-gray-800" @click="emits('download')">
        <IconHawkDownloadTwo />
      </HawkButton>
      <div class="border border-l my-2" />
      <HawkButton icon type="plain" class="text-white hover:bg-gray-800" @click="emits('close')">
        <IconHawkX />
      </HawkButton>
    </div>
  </div>
</template>
