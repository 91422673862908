<script setup>
import { useModal } from 'vue-final-modal';
import { isEmpty } from 'lodash-es';
import parser from 'ua-parser-js';
import DOMPurify from 'dompurify';
import HawkSignaturePopup from '~/common/components/organisms/hawk-signature-popup.vue';

const props = defineProps(['modelValue', 'form$', 'placeholder']);
const emits = defineEmits(['update:modelValue']);

const img_src = computed(() => props.modelValue?.service?.url || props.modelValue?.location || props.modelValue?._file?.location || props.modelValue);
const meta = computed(() => props.modelValue?.meta);
const info = computed(() => (meta.value.city ? { Location: `${meta.value.city}, ${meta.value.region}`, Device: `${getDeviceInfo(meta.value.user_agent)}` } : null));

function getDeviceInfo(user_agent) {
  if (user_agent) {
    let string = '';
    const result = parser(user_agent);
    const device_os = result?.device?.model || result?.os?.name;
    string += result?.browser?.name ? `${result?.browser?.name}` : '';
    string += device_os ? `, ${device_os}` : '';
    return string;
  }
  return '';
}

function getInfoHtml(info) {
  const html_string = Object.entries(info || {}).map(([key, value]) => {
    return `<div><b>${key}</b>: ${value}</div>`;
  }).join(' ');
  return DOMPurify.sanitize(html_string);
}

const { open: openSignaturePopup, close: closeSignaturePopup } = useModal({
  component: HawkSignaturePopup,
  attrs: {
    signature: img_src.value,
    form$: props.form$,
    onSave(signature) {
      emits('update:modelValue', signature);
      closeSignaturePopup();
    },
    onClose() {
      closeSignaturePopup();
    },
  },
});
</script>

<template>
  <div @click="!placeholder ? openSignaturePopup() : null">
    <div v-if="!isEmpty(props.modelValue)">
      <img :src="img_src" class="max-h-[40px] cursor-pointer border rounded-lg border-gray-300" alt="signature">
      <div v-if="meta" class="flex gap-2 items-center mt-2">
        <div class="flex items-center">
          {{ $t('Signed by') }}:
          <HawkMembers :members="meta.owner?.uid || meta.owner" type="badge" class="ml-2" />
        </div>
        <div class="flex items-center">
          {{ $t('on') }}
          <HawkText class="ml-2" :content="$date(meta.created_at, 'L_DATETIME_MED')" :length="30" />
        </div>
        <div v-if="info" v-tippy="{ content: getInfoHtml(info), allowHTML: true, placement: 'bottom' }" class="pointer-events-auto">
          <IconHawkInfoCircle class="ml-1 w-4 h-4 mt-0.5" />
        </div>
      </div>
    </div>
    <hawk-button v-else type="text">
      <IconHawkPlus class="text-white" /> <span>
        {{ $t("Add Signature") }}
      </span>
    </hawk-button>
  </div>
</template>
