<script setup>
import { cloneDeep, orderBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useTasksPermissions } from '~/tasks/composables/task-composables.js';
import ChecklistForm from '~/tasks/pages/task-details-new/left-section/task-checklists/checklist-form.vue';
import ChecklistProgress from '~/tasks/pages/task-details-new/left-section/task-checklists/checklist-progress.vue';
import Checklist from '~/tasks/pages/task-details-new/left-section/task-checklists/task-details-checklist.vue';
import { useChecklistStore } from '~/tasks/store/checklists.store.js';

const Draggable = defineAsyncComponent(() => import('vuedraggable'));

const task_store = inject('task_store');
const $t = inject('$t');
const { task_uid } = inject('task-uid');
const is_template = inject('is-template');

const checklist_store = useChecklistStore();
const { checkTaskPermission } = useTasksPermissions();

const data_loading = ref(false);
const checklists = computed({
  get() {
    return orderBy(checklist_store.checklists, 'order_index');
  },
  set(value) {
    checklist_store.update_checklists(task_uid.value, value);
  },
});
const checklists_length = computed(() => {
  return checklists.value.length;
});
const task_details = computed(() => {
  return task_uid.value ? task_store.get_current_task(task_uid.value) : {};
});
const can_modify = computed(() => {
  return checkTaskPermission({ permission: 'can_modify', instance: task_details.value });
});

onUnmounted(() => {
  checklist_store.$reset();
});

const { open: openChecklistForm, close: closeChecklistForm, patchOptions: patchChecklistOptions } = useModal({
  component: ChecklistForm,
});
async function handleChecklistForm() {
  patchChecklistOptions({
    attrs: {
      type: 'New Checklist',
      index: checklists_length.value,
      onClose() {
        closeChecklistForm();
      },
      async on_submit(data) {
        const payload = {
          checklists: [data],
        };
        if (data.template_uid)
          await checklist_store.load_from_template(task_uid.value, payload);
        else
          await checklist_store.create_checklist(task_uid.value, payload);
        task_store.task_track_events('Checklist created', {}, task_uid.value, ['associated_with']);
      },
    },
  });
  openChecklistForm();
}

function handleChange() {
  checklists.value.forEach((item, index) => (item.order_index = index));
}

function handleMoveChecklist(current_checklist, direction) {
  let current_index = null;
  if (current_checklist?.order_index)
    current_index = current_checklist.order_index;
  else
    current_index = checklists.value.findIndex(checklist => checklist.uid === current_checklist.uid);

  const new_index = current_index + direction;
  if (new_index >= 0 && new_index < checklists.value.length) {
    const temp_checklist = cloneDeep(checklists.value);
    temp_checklist[new_index].order_index = current_index;
    temp_checklist[current_index].order_index = new_index;
    temp_checklist.map((checklist, index) => ({ ...checklist, order_index: index }));
    checklists.value = Object.assign([], temp_checklist);
  }
}
function handleDeleteChecklist() {
  task_store.task_track_events('Checklist removed', {}, task_uid.value, ['associated_with']);
}
</script>

<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="flex">
        <p class="font-semibold text-lg mr-4">
          {{ $t('Checklists') }}
        </p>
        <ChecklistProgress v-if="checklists && checklists.length && !is_template" :checklists="checklists" />
      </div>
      <div class="pt-1 pr-1">
        <HawkButton v-if="can_modify" type="outlined" size="sm" @click="handleChecklistForm">
          {{ $t('New Checklist') }}
        </HawkButton>
      </div>
    </div>
    <div v-if="data_loading" class="flex items-center justify-center">
      <hawk-loader />
    </div>
    <Draggable
      v-else-if="checklists.length"
      v-model="checklists"
      tag="div"
      draggable=".is_draggable"
      item-key="element"
      :disabled="!can_modify"
      @change="handleChange"
    >
      <template #item="{ element }">
        <div class="is_draggable ">
          <Checklist
            :checklist="element"
            :can_modify="can_modify"
            :checklist_length="checklists_length"
            @move-up="handleMoveChecklist(element, -1)"
            @move-down="handleMoveChecklist(element, 1)"
            @task-checklist-deleted="handleDeleteChecklist"
          />
        </div>
      </template>
    </Draggable>
    <HawkIllustrations v-else variant="mini" type="no-data" for="checklists" />
  </div>
</template>
