<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable';
import MapToolIcons from '~/terra/components/map-tool-icons.vue';
import GalleryView from '~/terra/molecules/gallery/gallery-view.vue';
import DefectGalleryViewDetails from '~/therm/components/defect-details/defect-gallery-view-details.vue';
import ThermBulkActions from '~/therm/components/therm-bulk-actions.vue';
import ThermFeatureDetails from '~/therm/components/therm-feature-details.vue';
import { useThermTaskFilters } from '~/therm/composables/therm-filters.js';
import { useThermStore } from '../store/therm.store';

defineProps({
  active_tools: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Array,
    default: () => [],
  },

});
const emit = defineEmits(['toolActivated']);
const { $t } = useCommonImports();
const therm_store = useThermStore();

function activateTool(tool) {
  emit('toolActivated', tool.event);
  emit(tool.event);
}

const zoom_tools = computed(() => {
  return [
    {
      tooltip: $t('Zoom in'),
      icon: IconHawkPlus,
      event: 'zoom-in',
      is_size: 'w-[20px] h-[20px]',
      action: () => activateTool({ event: 'zoom-in' }),
    },
    {
      tooltip: $t('Zoom out'),
      icon: IconHawkMinus,
      event: 'zoom-out',
      is_size: 'w-[20px] h-[20px]',
      action: () => activateTool({ event: 'zoom-out' }),
    },
    {
      tooltip: $t('Reset'),
      icon: IconHawkRefreshCcwOne,
      event: 'reset-north',
      is_size: 'w-[20px] h-[20px]',
      action: () => activateTool({ event: 'reset-north' }),
    },
  ];
});

const creation_tool = computed(() => {
  return [
    {
      tooltip: $t('Create'),
      icon: IconHawkPoint,
      event: 'create',
      action: () => {
        activateTool({ event: 'create' });
      },
    },

  ];
});

const filter_tools = computed(() => {
  return [
    // {
    //   tooltip: $t('Search'),
    //   icon: HawkIconSearch,
    //   event: 'search',
    //   action: () => {
    //   },
    // },
    {
      tooltip: $t('Filter defects'),
      icon: IconHawkFilter,
      event: 'filters',
      action: () => useThermTaskFilters(),
      is_active: (() => {
        const filter_keys = Object.keys(therm_store.filters);
        if (filter_keys.length === 2 && filter_keys.every(key => ['temp_diff_elements', 'temperature_difference'].includes(key)) && therm_store.filters.temperature_difference[0] === 0 && therm_store.filters.temperature_difference[1] === 100)
          return false;
        return filter_keys.length > 0;
      })(),
    },
    {
      tooltip: $t('Compare'),
      icon: IconHawkCompare,
      event: 'compare',
      action: () => {
        activateTool({ event: 'compare' });
      },
    },
  ];
});
</script>

<template>
  <div>
    <div class="absolute left-3 top-3">
      <MapToolIcons
        style="position: relative"
        :tools="zoom_tools"
        :active_tool="active_tool"
        :disabled="disabled"
        :active_tools="active_tools"
      />
    </div>
    <div class="absolute right-4 top-3 flex">
      <GalleryView
        :store="therm_store"
        is_therm_viewer
      >
        <template #feature_details>
          <DefectGalleryViewDetails />
        </template>
      </GalleryView>

      <div class="relative flex flex-col  mr-2.5">
        <MapToolIcons
          style="position: relative"
          :tools="creation_tool"
          :disabled="disabled"
          :active_tools="active_tools"
          :horizontal_icons="true"
        />
      </div>
      <MapToolIcons
        style="position: relative"
        :tools="filter_tools"
        :disabled="[]"
        :horizontal_icons="true"
      />
    </div>
    <ThermFeatureDetails />
  </div>
</template>
