<script setup>
import { debounce } from 'lodash-es';
import { onMounted } from 'vue';

import TaskStatus from '~/tasks/components/atoms/task-status.vue';

const props = defineProps({
  dependencyType: {
    type: String,
    validator: (value) => { return ['Linked Tasks', 'Waiting On', 'Blocking'].includes(value); },
  },
  dependencies: {
    type: Array,
    default: () => [],
  },
  restrictedTasks: {
    type: Array,
    default: () => [],
  },
  asset: {
    type: String,
    default: 'null',
  },
  loading: {
    type: Boolean,
  },
});

const emit = defineEmits(['add', 'delete']);
const $services = inject('$services');

const form$ = ref(null);
const show_input = ref(false);
const tasks = ref([]);
const search_key = ref('');
const response_loading = ref(false);
onMounted(async () => {
  const { data } = await $services.tasks.getAll({
    query: {
      page_number: 1,
      page_size: 10,
      ...(search_key.value && { q: search_key.value }),
      asset_uid: props.asset,
    },
  });
  tasks.value = data.tasks;
});
watch(search_key, debounce(async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    response_loading.value = true;
    const { data } = await $services.tasks.getAll({
      query: {
        page_number: 1,
        page_size: 10,
        q: search_key.value,
        is_template: false,
        asset_uid: props.asset,
      },
    });
    tasks.value = data.tasks;
    response_loading.value = false;
  }
}, 200));

const component_map = {
  'Waiting On': {
    icon: IconHawkDependencyWaitingOn,
    class: 'text-red-500',
    description: 'Tasks that must be completed before working on this task',
    add_message: 'Add waiting on task',
  },
  'Blocking': {
    icon: IconHawkDependencyBlocking,
    class: 'text-red-500',
    description: 'Tasks that can\'t start until this task is completed',
    add_message: 'Add task that is blocked',
  },
  'Linked Tasks': {
    icon: IconHawkDependencyLinked,
    class: 'text-blue-500',
    description: 'Tasks that are related to each other but not dependent on each other',
    add_message: 'Add linked task',
  },
};
function addDependency() {
  const data = form$.value.data.task;
  emit('add', data);
  show_input.value = false;
}
const available_dependency_options = computed(() => {
  if (tasks.value.length) {
    const restricted_uids = props.restrictedTasks.map(task => task.uid);
    return tasks.value.filter(task => !restricted_uids.includes(task.uid));
  }
  return [];
});
function addClickHandler() {
  show_input.value = true;
  setTimeout(() => form$.value.elements$.task.input.focus(), 0);
}
</script>

<template>
  <div class="grid grid-cols-6 gap-10">
    <div class="col-span-2 text-gray-700">
      <div class="grid grid-cols-6">
        <component :is="component_map?.[dependencyType]?.icon" class="col-span-1" :class="component_map[dependencyType].class" />
        <div class="col-span-5">
          <p class="text-sm font-medium mb-1">
            {{ $t(dependencyType) }}
          </p>
          <p class="text-xs w-[144px]">
            {{ $t(component_map[dependencyType].description) }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-span-4">
      <div v-if="loading">
        <hawk-skeleton custom_classes="w-full mb-1" />
        <hawk-skeleton custom_classes="w-full" />
      </div>
      <div>
        <div v-if="dependencies.length" class="mb-3 mr-3">
          <template v-for="dependency in dependencies" :key="dependency.name">
            <div class="grid grid-cols-12 py-1.75 hover:bg-gray-50 group cursor-pointer rounded-md">
              <div class="col-span-8 pl-3 flex flex-col justify-center text-sm text-gray-900">
                {{ dependency.name }}
              </div>
              <div class="col-span-3 flex items-center">
                <TaskStatus :status="dependency.status " />
              </div>
              <div class="col-span-1 pr-1 invisible group-hover:visible flex items-center justify-center">
                <IconHawkTrashThree class="text-gray-600" @click="emit('delete', dependency)" />
              </div>
            </div>
          </template>
        </div>
        <div v-if="show_input" class="grid grid-cols-12">
          <Vueform ref="form$" size="sm" class="col-span-10">
            <SelectElement
              v-click-outside="() => show_input = false"
              class="mb-2 ml-3"
              v-bind="{
                'name': 'task',
                'placeholder': $t('Choose Task'),
                'track-by': 'name',
                'label-prop': 'name',
                'value-prop': 'uid',
                'items': available_dependency_options,
                'hide-selected': false,
                'search': true,
                'object': true,
                'loading': response_loading,
              }"
              :remove-classes="{
                SelectElement: {
                  select: {
                    dropdownHidden: 'hidden',
                  },
                },
              }"
              @search-change="search_key = $event"
              @select="addDependency"
            />
          </Vueform>
          <div class="flex items-center justify-center col-span-2 pb-2">
            <IconHawkCross class="text-gray-500 cursor-pointer h-3" @click="show_input = false" />
          </div>
        </div>
        <div class="flex items-center cursor-pointer text-primary-700 text-sm font-semibold pl-3" @click="addClickHandler">
          <IconHawkPlusWhite class="mr-1 h-3" />
          {{ $t(component_map[dependencyType].add_message) }}
        </div>
      </div>
    </div>
  </div>
</template>
