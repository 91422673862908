<script setup>
import { watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useIntegrationsStore } from '~/common/stores/integrations.store';
import IntegrationDeactivateModal from '~/third-party-widgets/components/integrations/integration-deactivate-modal.vue';
import { useThirdPartyConstants } from '~/third-party-widgets/utils/constants.js';

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  integration_type: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);

const form$ = ref(null);
const { $services, $date, auth_store, route } = useCommonImports();
const { integration_items } = useThirdPartyConstants();
const integration_meta = computed(() => integration_items.find(item => item.type === props.integration_type));
const state = reactive({
  integration_in_edit: null,
  form_data: {
    assignees_type: 'Private account',
    members: [],
  },
  is_loading: false,
});

const integration_store = useIntegrationsStore();
const integration_details = computed(() => integration_store.integrations_map[props.integration_type]);

watch(() => state.integration_in_edit, () => {
  logger.log(state.integration_in_edit, form$);
  const is_members_present = !!state.integration_in_edit?.members.length;
  form$.value[0]?.load(
    {
      assignees_type: is_members_present ? 'Shared account' : 'Private account',
      members: state.integration_in_edit?.members,
    },
    true,
  );
}, {
  deep: true,
});
function getStatus(item) {
  const status = item.active;
  const is_expired = new Date(item.expires_in) <= new Date();
  if (status === false)
    return { color: 'red', title: 'Inactive', is_expired };
  else if (is_expired)
    return { color: 'red', title: 'Expired', is_expired };
  else if (status === true)
    return { color: 'green', title: 'Active', is_expired };
}

const { open: openIntegrationModal, close: closeIntegrationModal, patchOptions: patchTeamsOptions } = useModal({
  component: IntegrationDeactivateModal,
});

function openIntegrationHandler(id) {
  patchTeamsOptions(
    {
      attrs: {
        id,
        integrations: integration_details.value,
        onClose() {
          closeIntegrationModal();
        },
      },
    },
  );
  openIntegrationModal(id);
}

async function authorizeIntegration() {
  try {
    await integration_store.authorize_integration({
      integration_type: integration_meta.value?.type,
    });
  }
  catch (err) {
    logger.log('🚀 ~ authorizeIntegration ~ err:', err);
  }
}

async function updateIntegration(option) {
  logger.log('🚀 ~ updateIntegration ~ option:', option);
  try {
    state.is_loading = true;
    const response = await $services.integrations.patch({
      id: option._id,
      body: {
        members: state.form_data.members?.map(item => item.uid),
      },
    });
    const updated_item = response.data;
    const integrations = integration_store.integrations_map[props.integration_type];
    const index = integrations.findIndex(integration => integration._id === updated_item._id);
    if (index !== -1) {
      integrations[index] = updated_item;
      integration_store.integrations_map[props.integration_type] = integrations;
    }

    state.integration_in_edit = null;
    state.is_loading = false;
  }
  catch (error) {
    logger.log('🚀 ~ updateIntegration ~ error:', error);
    state.is_loading = false;
  }
}

function checkUserIsOwner(item) {
  return item.owner === auth_store.logged_in_user_id;
}
</script>

<template>
  <hawk-modal-container content_class="w-[800px]">
    <hawk-modal-header>
      <template #header>
        <div class="p-6 border-b border-b-gray-200">
          <div class="flex justify-between">
            <div class="flex gap-4">
              <div class="w-11 h-11 grid place-content-center shadow-md bg-white rounded">
                <component :is="integration_meta.icon" class="w-6 h-6" />
              </div>
              <div>
                <div class="font-semibold text-lg">
                  {{ integration_meta?.name }}
                </div>
                <div class="text-sm">
                  {{ integration_meta?.description }}
                </div>
              </div>
            </div>
            <HawkButton icon type="text" @click="emit('close')">
              <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
            </HawkButton>
          </div>
        </div>
      </template>
    </hawk-modal-header>
    <hawk-modal-content>
      <div class="grid gap-6">
        <div>
          <div v-if="integration_meta?.notes?.length">
            <div v-for="(note, i) in integration_meta.notes" :key="i" class="text-sm font-gray-900 pb-2">
              {{ note }}
            </div>
          </div>
          <div v-if="integration_meta?.features?.length">
            <div class="text-sm font-semibold py-1">
              {{ $t('Features') }}
            </div>
            <ul class="list-disc leading-6 text-sm font-gray-900 pl-6">
              <li v-for="(feature, i) in integration_meta.features" :key="i" class="list-item">
                {{ feature }}
              </li>
            </ul>
          </div>
        </div>
        <hr v-if="integration_meta.can_add_account">
        <div v-if="integration_meta.can_add_account" class="grid gap-4">
          <div class="flex justify-between items-center">
            <div v-if="integration_details?.length" class="text-sm font-semibold">
              {{ integration_details?.length }} {{ $t('accounts') }}
            </div>
            <div v-else />
            <HawkButton type="link" @click="authorizeIntegration()">
              <IconHawkPlus />
              {{ $t('Add account') }}
            </HawkButton>
          </div>
          <div v-if="!integration_details?.length" class="text-sm text-gray-500 mt-3">
            {{ $t('No accounts found') }}
          </div>
          <div v-else>
            <div v-for="item in integration_details" :key="item._id" class="border p-3 rounded-lg grid gap-4 hover:bg-gray-50 group" :class="{ 'bg-gray-100': state.integration_in_edit?._id === item._id }">
              <div class="flex items-center justify-between">
                <div class="flex gap-2 items-center">
                  <HawkText class="font-semibold text-sm" :content="item.account?.name" />

                  <span class="text-xs">(<HawkText :length="100" :content="item.account?.email" />)</span>

                  <HawkBadge :color="getStatus(item)?.color">
                    {{ getStatus(item)?.title }}
                  </HawkBadge>
                </div>
                <div class="flex gap-3 invisible group-hover:visible" :class="{ '!visible': state.integration_in_edit?._id === item._id }">
                  <HawkButton v-if="state.integration_in_edit?._id !== item._id" v-tippy="!checkUserIsOwner(item) && $t('Only connected user can share the account')" :class="{ 'opacity-50': !checkUserIsOwner(item) }" on type="text" @click="state.integration_in_edit = checkUserIsOwner(item) ? item : null">
                    <IconHawkShareSeven />
                  </HawkButton>
                  <HawkButton v-if="checkUserIsOwner(item) || auth_store.check_permission('modify_integrations', route.params.asset_id)" icon type="text" @click="openIntegrationHandler(item._id)">
                    <IconHawkTrashThree />
                  </HawkButton>
                </div>
              </div>

              <div class="flex justify-between">
                <Vueform
                  v-show="state.integration_in_edit?._id === item._id"
                  ref="form$"
                  v-model="state.form_data"
                  sync class="w-[50%]" size="sm" :columns="{
                    default: { container: 12, label: 0, wrapper: 12 },
                    sm: { container: 12, label: 0, wrapper: 12 },
                  }"
                >
                  <RadiogroupElement
                    :default="item.members?.length ? 'Shared account' : 'Private account'"
                    name="assignees_type"
                    :items="[
                      'Private account',
                      'Shared account',
                    ]"
                  />
                  <hawk-assignee-input

                    :options="{
                      name: 'members',
                      conditions: [
                        ['assignees_type', 'Shared account'],
                      ],
                      has_teams: true,
                      placeholder: $t('Select members'),
                      columns: {
                        default: { container: 12 },

                      },
                    }"
                    :multi="true"
                    :format_load="true"
                  />
                </Vueform>
                <div v-if="state.integration_in_edit === null">
                  <div v-if="item.members?.length">
                    <div class="text-xs text-gray-500 mb-1">
                      {{ $t('Shared with') }}:
                    </div>
                    <HawkMembers :members="item.members" type="group" has_border :max_badges_to_display="6" />
                  </div>
                  <div v-else class="text-xs">
                    {{ 'Private' }}
                  </div>
                  <HawkText class="text-xs text-gray-500" length="50" :content="`${$t('Expires on')} ${$date(item.expires_in, 'DD MMMM YYYY, h:mm A')}`" />
                </div>
                <div>
                  <div class="text-xs text-gray-500 mb-1">
                    {{ $t('Connected by') }}:
                  </div>
                  <div class="flex gap-2 items-center">
                    <HawkMembers :members="item.created_by" type="badge" />
                    <span class="text-xs text-gray-500">{{ $date(item.created_at, 'DD MMMM YYYY, h:mm A') }}</span>
                  </div>
                </div>
              </div>

              <div
                v-if="state.integration_in_edit?._id === item._id"
              >
                <hr>
                <div class="flex justify-end gap-3 mt-3">
                  <HawkButton
                    type="outlined"
                    @click="state.integration_in_edit = null"
                  >
                    {{ $t("Cancel") }}
                  </HawkButton>
                  <HawkButton :loading="state.is_loading" @click="updateIntegration(item)">
                    {{ $t("Save") }}
                  </HawkButton>
                </div>
              </div>
              <div v-if="getStatus(item).is_expired" class="flex items-center justify-between">
                <div class="text-red-600 flex gap-2">
                  <IconHawkAlertTriangle class="w-4 h-4" />
                  <span class="text-xs">{{ $t('Expired on') }} {{ $date(item.is_expired, 'DD MMMM YYYY') }}.</span>
                </div>
                <HawkButton type="link" @click="authorizeIntegration()">
                  {{ $t('Reconnect') }}
                </HawkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #footer>
        <div class="px-6 pb-6" />
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>
