<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useThermStore } from '~/therm/store/therm.store';
import ThermReportGraph from '~/therm/components/therm-report-graph.vue';

const { $t } = useCommonImports();
const therm_store = useThermStore('therm_dashboard');

const temperature_graph_details = computed(() => {
  return {
    type: 'column2d',
    title: $t('Temperature Histogram'),
    description: $t('Graphical distribution of defects in relation to temperature differences.'),
    xAxisName: 'Temperature difference',
    yAxisName: 'No. of defects',
    plotToolText: '$yAxisName: $value',
    id: 'temperature_difference_container',
    hide_download: true,
  };
});
const temperature_graph_data = computed(() => {
  const temperature_difference = therm_store.report_defects
    .map(feature => feature.temperature_difference.toFixed(3))
    .sort((a, b) => a - b);
  const grouped_data = temperature_difference.reduce((acc, curr) => {
    if (acc[curr])
      acc[curr] = ++acc[curr];
    else
      acc[curr] = 1;
    return acc;
  }, {});
  const data = [];
  for (const temperature in grouped_data)
    data.push({
      label: temperature,
      value: grouped_data[temperature],
      color: '#53B1FD',
    });

  return { data };
});
</script>

<template>
  <div class="border rounded-xl h-[450px] p-4 my-4">
    <ThermReportGraph
      :graph_details="temperature_graph_details"
      :data="temperature_graph_data"
      :has_borders="false"
      height="370"
      class="overflow-hidden"
    />
  </div>
</template>
