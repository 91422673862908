<script setup>
import { onBeforeMount } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import Dashboard from '~/dashboard/pages/dashboard.vue';

const { $t, route, router, auth_store } = useCommonImports();

const state = reactive({
  is_loading: false,
  is_created: false,
  active_item: null,
  can_edit: true,
});

const report_dropdown_options = computed(() => [
  {
    label: 'Report 1',
    uid: '1',
    is_pinned: true,
    on_click: () => {},
  },
  {
    label: 'Report 2',
    uid: '2',
    on_click: () => {},
  }],
);
onBeforeMount(() => {
  if (!auth_store.check_permission('view_inventory_reports', route.params.asset_id))
    router.push({
      name: 'inventory-permission-denied',
    });
});
</script>

<template>
  <div class="w-[calc(100vw_-_80px)]">
    <Dashboard
      :options="{
        can_modify_resource: auth_store.check_permission('modify_inventory_reports', route.params.asset_id),
        hide_side_bar: true,
        is_stand_alone: false,
        hide_dashboard_options: false,
        show_top_bar: false,
        dashboard_label: 'Reports',
      }"
    />
  </div>
</template>
