export const DOCUMENT_PERMISSIONS = {
  VIEW_DOCUMENTS: 'view_documents',
  CREATE_DOCUMENTS: 'create_documents',
  MODIFY_DOCUMENTS: 'modify_documents',
  SHARE_DOCUMENTS: 'share_documents',
};

export const TRANSMITTAL_PERMISSIONS = {
  CREATE_TRANSMITTALS: 'create_transmittals',
  CONFIGURE_DMS_SETTINGS: 'configure_dms_settings',
  VIEW_WORKFLOWS: 'view_dms_workflow_templates',
  CREATE_WORKFLOWS: 'create_dms_workflow_templates',
  MODIFY_WORKFLOWS: 'modify_dms_workflow_templates',
  CREATE_DMS_STATUS: 'create_dms_statuses',
  MODIFY_DMS_STATUS: 'modify_dms_statuses',
  CREATE_DMS_CUSTOM_FIELDS: 'create_dms_custom_fields',
  MODIFY_DMS_CUSTOM_FIELDS: 'modify_dms_custom_fields',
  VIEW_TRANSMITTAL: 'view_transmittals',
};

export const document_status_keys = ['pending_review', 'reviewed', 'approved', 'approved_with_comments', 'revise_resubmit', 'rejected'];

export const TRANSMITTAL_STATUS = {
  pending: {
    title: 'Pending',
    color: 'yellow',
    class: '!bg-gray-500',
  },
  pending_review: {
    title: 'Pending review',
    color: 'gray',
    class: '!bg-gray-500',
  },
  waiting_for_submission: {
    title: 'Waiting for submission',
    color: 'yellow',
  },
  pending_upload: {
    title: 'Pending upload',
    color: '',
    class: '!bg-gray-500',
  },
  waiting_for_review: {
    title: 'Waiting for review',
    color: 'yellow',
    class: '!bg-warning-600',
  },
  waiting_for_other_transmittal: {
    title: 'Waiting',
    color: 'gray',
  },
  waiting_for_upload: {
    title: 'Waiting for upload',
    color: 'yellow',
    class: '!bg-warning-600',
  },
  request_for_review: {
    title: 'Request for review',
    color: 'purple',
  },
  revise_resubmit: {
    title: 'Revise & Resubmit',
    color: 'orange',
    class: '!bg-orange-400',
  },
  cancelled: {
    title: 'Cancelled',
    color: 'gray',
  },
  completed: {
    title: 'Completed',
    color: 'green',
  },
  approved: {
    title: 'Approved',
    color: 'green',
    class: '!bg-green-600',

  },
  approved_with_comments: {
    title: 'Approved with comments',
    color: 'lightgreen',
    class: '!bg-green-400',
  },
  rejected: {
    title: 'Rejected',
    color: 'red',
    class: '!bg-red-500',
  },
  reviewed: {
    title: 'Reviewed',
    color: 'blue',
    class: '!bg-blue-600',
  },
  submitted: {
    title: 'Submitted',
    color: 'green',
    class: '!bg-green-600',
  },
  not_submitted: {
    title: 'Not submitted',
    color: 'gray',
  },
};
