<script setup>
const props = defineProps({
  size: {
    type: String,
    default: 'sm',
    validator(value) {
      return ['xs', 'sm', 'md', 'lg', 'xl'].includes(value);
    },
  },
  color: {
    type: String,
    default: 'primary',
    validator(value) {
      return ['primary', 'gray', 'error', 'warning', 'success'].includes(value);
    },
  },
  theme: {
    type: String,
    default: 'light-circle',
    validator(value) {
      return ['light-circle', 'light-circle-outline', 'dark-circle', 'light-square', 'mid-square', 'dark-square', 'modern'].includes(value);
    },
  },
});

function getRounded(value) {
  const round_class = [];
  switch (value) {
    case 'xs':
      round_class.push('rounded-md');
      break;
    case 'sm':
      round_class.push('rounded-md');
      break;
    case 'md':
      round_class.push('rounded-lg');
      break;
    case 'lg':
      round_class.push('rounded-[10px]');
      break;
    case 'xl':
      round_class.push('rounded-xl');
      break;
  }
  return round_class;
}

const featured_classes = computed(() => {
  const classes = ['grid', 'place-items-center'];

  switch (props.size) {
    case 'xs':
      classes.push('h-6 w-6', '[&>svg]:h-3 [&>svg]:w-3');
      break;
    case 'sm':
      classes.push('h-8 w-8', '[&>svg]:h-4 [&>svg]:w-4');
      break;
    case 'md':
      classes.push('h-10 w-10', '[&>svg]:h-5 [&>svg]:w-5');
      break;
    case 'lg':
      classes.push('h-12 w-12', '[&>svg]:h-6 [&>svg]:w-6');
      break;
    case 'xl':
      classes.push('h-14 w-14', '[&>svg]:h-7 [&>svg]:w-7');
      break;
  }

  switch (props.theme) {
    case 'light-circle':
      classes.push('rounded-full');
      switch (props.color) {
        case 'primary':
          classes.push('bg-primary-100', '[&>svg]:text-primary-600');
          break;
        case 'gray':
          classes.push('bg-gray-100', '[&>svg]:text-gray-600');
          break;
        case 'error':
          classes.push('bg-error-100', '[&>svg]:text-error-600');
          break;
        case 'warning':
          classes.push('bg-warning-100', '[&>svg]:text-warning-600');
          break;
        case 'success':
          classes.push('bg-success-100', '[&>svg]:text-success-600');
          break;
      }
      break;

    case 'light-circle-outline':
      classes.push('rounded-full');
      switch (props.color) {
        case 'primary':
          classes.push('bg-primary-100', 'border-2 border-primary-50', 'ring-4 ring-primary-50', '[&>svg]:text-primary-600');
          break;
        case 'gray':
          classes.push('bg-gray-100', 'border-2 border-gray-50', 'ring-4 ring-gray-50', '[&>svg]:text-gray-600');
          break;
        case 'error':
          classes.push('bg-error-100', 'border-2 border-error-50', 'ring-4 ring-error-50', '[&>svg]:text-error-600');
          break;
        case 'warning':
          classes.push('bg-warning-100', 'border-2 border-warning-50', 'ring-4 ring-warning-50', '[&>svg]:text-warning-600');
          break;
        case 'success':
          classes.push('bg-success-100', 'border-2 border-success-50', 'ring-4 ring-success-50', '[&>svg]:text-success-600');
          break;
      }
      break;

    case 'dark-circle':
      classes.push('rounded-full');
      switch (props.color) {
        case 'primary':
          classes.push('bg-primary-600', 'border-4 border-primary-700', 'ring-4 ring-primary-700', '[&>svg]:text-white');
          break;
        case 'gray':
          classes.push('bg-gray-600', 'border-4 border-gray-700', 'ring-4 ring-gray-700', '[&>svg]:text-white');
          break;
        case 'error':
          classes.push('bg-error-600', 'border-4 border-error-700', 'ring-4 ring-error-700', '[&>svg]:text-white');
          break;
        case 'warning':
          classes.push('bg-warning-600', 'border-4 border-warning-700', 'ring-4 ring-warning-700', '[&>svg]:text-white');
          break;
        case 'success':
          classes.push('bg-success-600', 'border-4 border-success-700', 'ring-4 ring-success-700', '[&>svg]:text-white');
          break;
      }
      break;

    case 'light-square':{
      classes.push(...getRounded(props.size));
      switch (props.color) {
        case 'primary':
          classes.push('bg-primary-100', '[&>svg]:text-primary-600');
          break;
        case 'gray':
          classes.push('bg-gray-100', '[&>svg]:text-gray-600');
          break;
        case 'error':
          classes.push('bg-error-100', '[&>svg]:text-error-600');
          break;
        case 'warning':
          classes.push('bg-warning-100', '[&>svg]:text-warning-600');
          break;
        case 'success':
          classes.push('bg-success-100', '[&>svg]:text-success-600');
          break;
      }
      break;
    }

    case 'mid-square':
      classes.push(...getRounded(props.size));
      switch (props.color) {
        case 'primary':
          classes.push('bg-primary-600', '[&>svg]:text-white');
          break;
        case 'gray':
          classes.push('bg-gray-600', '[&>svg]:text-white');
          break;
        case 'error':
          classes.push('bg-error-600', '[&>svg]:text-white');
          break;
        case 'warning':
          classes.push('bg-warning-600', '[&>svg]:text-white');
          break;
        case 'success':
          classes.push('bg-success-600', '[&>svg]:text-white');
          break;
      }
      break;

    case 'dark-square':
      classes.push(...getRounded(props.size));
      switch (props.color) {
        case 'primary':
          classes.push('bg-primary-800', '[&>svg]:text-white');
          break;
        case 'gray':
          classes.push('bg-gray-800', '[&>svg]:text-white');
          break;
        case 'error':
          classes.push('bg-error-800', '[&>svg]:text-white');
          break;
        case 'warning':
          classes.push('bg-warning-800', '[&>svg]:text-white');
          break;
        case 'success':
          classes.push('bg-success-800', '[&>svg]:text-white');
          break;
      }
      break;

    case 'modern':
      classes.push(...getRounded(props.size), 'border', 'shadow-sm');
      break;
  }

  return classes;
});
</script>

<template>
  <div :class="featured_classes">
    <slot />
  </div>
</template>
