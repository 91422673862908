<script setup>
import { cloneDeep } from 'lodash-es';
import { nextTick } from 'vue';
import { useRoute } from 'vue-router';
import FormBuilderTableSummaryButton from '~/forms/components/form-builder/form-builder-table-summary-button.vue';

const props = defineProps(['section']);

const emits = defineEmits(['close']);

const $t = inject('$t');

const route = useRoute();
const form_template_detail_store = inject('form_template_detail_store');
const $services = inject('$services');
const $toast = inject('$toast');
const $track_event = inject('$track_event');
const section = computed(() => props.section.section);
const counter = computed(() => props.section.counter);

let old_section = cloneDeep(props.section.section);

provide('form_builder_section_configuration', section);

async function save(data, form) {
  try {
    if (section.value.uid)
      await form_template_detail_store.update_section(section.value.uid, form.data, props.section.section_index);
    else
      await add_section(form.data);
    old_section = section.value;
    emits('close');
  }
  catch (e) {
    $toast({ text: e?.data?.message || 'Section Updating failed!', type: 'error' });
  }
}

async function add_section(form) {
  try {
    const section_data = { ...(section?.value || {}) };
    section.value.type = 'loading';
    await $services.forms.post({
      attribute: 'sections',
      body: {
        section: { ...section_data, ...form },
      },
    });
    await form_template_detail_store.set_form_template({ id: route.params.template_uid });
    const types = {
      default: 'Basic',
      checklist: 'Checklist',
      checklistv2: 'Checklist',
      tablev2: 'Table',
      signature: 'Signature',
    };
    $track_event('add_section', {
      type: types[section_data.type],
    });
  }
  catch (e) {
    $toast({ text: e?.data?.message || 'Section adding failed!', type: 'error' });
  }
}

const form$ = ref(null);
const common_section_schema = {
  name: {
    type: 'textarea',
    fieldName: 'name',
    label: $t('Name'),
    rows: '1',
    rules: ['required'],
  },
  description: {
    type: 'textarea',
    fieldName: 'name',
    label: $t('Description'),
  },
};
const submit_button_schema = {
  footer_button: {
    type: 'group',
    addClasses: {
      GroupElement: {
        wrapper: ['!flex', 'justify-end'],
      },
    },
    schema: {
      cancel: {
        type: 'button',
        buttonLabel: $t('Cancel'),
        secondary: true,
        resets: true,
        inline: true,
      },
      submit: {
        type: 'button',
        buttonLabel: section.value.uid ? $t('Save') : $t('Add'),
        inline: true,
        submits: true,
      },
    },
  },
};
// sections types
const basic_section_schema = {
  ...common_section_schema,
  ...submit_button_schema,
};

const checklist_section_schema = {
  ...common_section_schema,
  ...submit_button_schema,
};

const signature_section_schema = {
  ...common_section_schema,
  ...submit_button_schema,
};
const table_section_schema = {
  ...common_section_schema,
  properties: {
    type: 'object',
    schema: {
      can_modify_prefilled_values: {
        type: 'checkbox',
        columns: {
          container: 12,
        },
        text: $t('Allow modifying prefilled data'),
      },
      can_add_rows: {
        type: 'checkbox',
        columns: {
          container: 12,
        },
        text: $t('Allow adding/removing rows'),
        default: true,
      },
      setup_summary: {
        type: 'static',
        columns: {
          container: 12,
        },
        slots: { default: FormBuilderTableSummaryButton },
      },
      section_summary: {
        type: 'hidden',
        meta:true,
        columns: {
          container: 12,
        },
      },
    },
  },
  ...submit_button_schema,
};

const section_schema = {
  default: basic_section_schema,
  tablev2: table_section_schema,
  checklist: checklist_section_schema,
  checklistv2: checklist_section_schema,
  signature: signature_section_schema,
};

function resetSection() {
  if (!old_section?.uid) {
    form_template_detail_store.reset_form_template();
  }
  else {
    const index = form_template_detail_store.form_template_detail.sections.findIndex(section => section.uid === old_section.uid);
    if (form_template_detail_store.form_template_detail.sections[index]) {
      form_template_detail_store.form_template_detail.sections[index].name = old_section.name;
      form_template_detail_store.form_template_detail.sections[index].description = old_section.description;
      form_template_detail_store.form_template_detail.sections[index].properties = old_section.properties;
    }
  }
}

function autoSizeTextArea() {
  setTimeout(() => {
    form$.value?.elements$?.name?.autosize();
    form$.value?.elements$?.description?.autosize();
  }, 50);
}

function autofocusInputs() {
  nextTick(() => {
    if (props.section.focus_description)
      form$.value?.elements$?.description?.input?.focus();
    else
      form$.value?.elements$?.name?.input?.focus();
  });
}

watch(section, () => {
  resetSection();
  old_section = cloneDeep(props.section.section);
  autofocusInputs();
  autoSizeTextArea();
});

watch(counter, () => {
  autofocusInputs();
});

function formMounted($el) {
  autofocusInputs();
  autoSizeTextArea();
}

onUnmounted(() => {
  resetSection();
});
</script>

<template>
  <div>
    <div class="border rounded-lg sticky top-16">
      <div class="p-4">
        <div class="flex justify-between items-center">
          <div v-if="section.uid" class="font-semibold text-lg">
            {{ $t('Edit Section') }}
          </div>
          <div v-else class="font-semibold text-lg">
            {{ $t('Add Section') }}
          </div>
          <hawk-button type="text" @click="emits('close')">
            <icon-hawk-x-close />
          </hawk-button>
        </div>
        <div class="mt-4">
          <Vueform
            ref="form$"
            v-model="section"
            :presets="['text_area_without_enter']"
            :schema="section_schema[section?.type === 'loading' ? old_section.type : section?.type]"
            :columns="{
              container: 12,
              label: 4,
              wrapper: 12,
            }"
            :add-classes="{ ElementLayout: { container: 'pb-2' } }"
            size="sm"
            :sync="true"
            :endpoint="save"
            :display-errors="false"
            @keypress.enter.prevent="form$.submit()"
            @reset="emits('close')"
            @mounted="formMounted"
          />
        </div>
      </div>
    </div>
  </div>
</template>
