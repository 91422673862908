<script setup>
import { useModal } from 'vue-final-modal';
import HawkSheetPopup from '~/common/components/molecules/hawk-sheet-popup.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  editable: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Object,
    default: () => ({
      heading: '',
      placeholder: 'Enter codes',
      table_settings: {
        columns: [
          {
            data: 'text',
          },
        ],
        colHeaders: ['Enter text'],
      },
    }),
  },
});

const emits = defineEmits(['update:modelValue']);

const table_settings = {
  rowHeaders: true,
  licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
  width: '100%',
  height: '100%',
  stretchH: 'all',
  rowHeights: 35,
  minRows: 9,
  minSpareRows: 1,
  viewportRowRenderingOffset: 100,
  className: 'custom-httable',
  ...(props.options?.table_settings || {}),
};

if (props.options?.table_settings?.columns)
  table_settings.columns = props.options?.table_settings?.columns.map(column => ({ ...column, readOnly: !props.editable }));

const { open: openSheetPopup, close: closeSheetPopup, patchOptions } = useModal({
  component: HawkSheetPopup,
});

function openFilledSheetPopup() {
  patchOptions({
    attrs: {
      options: { ...(props.options || {}), table_settings: { ...table_settings, data: props.modelValue } },
      editable: props.editable,
      onSave(data) {
        emits('update:modelValue', data.filter(value => value));
        closeSheetPopup();
      },
      onClose() {
        closeSheetPopup();
      },
    },
  });
  openSheetPopup();
}
</script>

<template>
  <div>
    <div v-if="editable" class="border border-gray-300 shadow-xs rounded-lg flex cursor-pointer p-2" @click="openFilledSheetPopup">
      <div v-if="modelValue?.length > 0" class="truncate overflow-hidden flex-1">
        {{ modelValue?.slice(0, 100)?.join(', ') }}
      </div>
      <div v-else class="placeholder text-gray-500 flex-1">
        {{ options.placeholder }}
      </div>
      <div class="text-gray-500">
        <IconHawkExpandOne />
      </div>
    </div>
    <div v-else-if="modelValue?.length > 0" class="flex items-center">
      <div class="truncate overflow-hidden mr-1">
        {{ modelValue?.slice(0, 100)?.join(', ') }}
      </div>
      <hawk-button type="link" class="pointer-events-auto flex-shrink-0" @click="openFilledSheetPopup">
        {{ modelValue.length }} {{ modelValue.length > 1 ? $t('entries') : $t('entry') }}
      </hawk-button>
    </div>
    <div v-else>
      -
    </div>
  </div>
</template>
