<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { route } = useCommonImports();
function onClickReload() {
  window.location.href = window.location.origin + (route.query.from || '');
}
function onClickGoToHome() {
  window.location.href = window.location.origin;
}
</script>

<template>
  <div class="bg-white absolute top-0 z-[9999] right-0 bottom-0 left-0 grid place-content-center">
    <div class="grid place-items-center -mt-20">
      <IconIllustrationMaintenance class="h-96 w-96" />
    </div>
    <div class="text-center text-2xl font-semibold mb-4">
      {{ $t('Something went wrong!') }}
    </div>
    <div class="text-center">
      <div class="flex gap-3 justify-center">
        <HawkButton :type="route.query.from ? 'outlined' : 'default'" @click="onClickGoToHome">
          {{ $t('Take me home') }}
        </HawkButton>
        <HawkButton v-if="route.query.from" @click="onClickReload">
          {{ $t('Reload') }}
        </HawkButton>
      </div>
    </div>
  </div>
</template>
