<script setup>
import { Validator } from '@vueform/vueform';
import { useTransmittalPermissions } from '~/dms/composables/transmittal-permissions.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { get_default_suggestions } from '~/forms/composables/form-builder-auto-number-composable';
import DMSTransmittalSchema from '~/dms/components/settings/dms-transmittal-schema.vue';
import FormBuilderAutoNumberPreview from '~/forms/components/form-builder/form-builder-autonumber-preview.vue';

const $t = inject('$t');

const default_suggestions = get_default_suggestions({ $t });

// Password should contain one lower and one upper
const NumberFieldRule = class extends Validator {
  get message() {
    return 'Schema should contain one number field';
  }

  check(value) {
    return (value || '').includes('data-id=\"number\"');
  }
};

const schema = {
  html: {
    type: 'WysiwygEditor',
    options: {
      single_line: true,
      menu_enabled: false,
      enable_common_rtf_features: false,
      plugins: ['disable-enter', 'tags'],
      placeholder_text: 'Enter scheme here',
      suggestions: [
        ...default_suggestions,
        {
          id: 'issue_purpose',
          label: `${$t('Issue purpose')} (As built)`,
        },
      ],
      footer: {
        component: DMSTransmittalSchema,
        component_props: {
          nested_suggestions: [],
        },
      },
      nested_suggestions: [],
    },
    default: '<p>TRA-<span data-type="suggestion-plugin-tag" class="tag-suggestion" data-id="number" data-label="Number">Number</span></p>',
    native: false,
    rules: ['required', NumberFieldRule],
    columns: {
      default: { container: 12, label: 12, wrapper: 12 },
      sm: { container: 12, label: 12, wrapper: 12 },
      md: { container: 12, label: 12, wrapper: 12 },
    },
    label: $t('Scheme'),
    object: true,
  },
  preview: {
    type: 'static',
    columns: {
      default: { container: 12, label: 12, wrapper: 12 },
      sm: { container: 12, label: 12, wrapper: 12 },
      md: { container: 12, label: 12, wrapper: 12 },
    },
    label: $t('Preview'),
    placeholder: 'Preview',
    disabled: true,
    addClasses: {
      ElementLayout: {
        container: ['border', 'border-gray-300', 'bg-gray-50', 'p-2', 'rounded-lg'],
      },
      ElementLabel: {
        wrapper: ['font-bold', 'text-gray-700', 'mb-2'],
      },
    },
    content: FormBuilderAutoNumberPreview,
  },
};

const form$ = ref(null);
const transmittal_permissions = useTransmittalPermissions();
const dms_settings_store = useDMSSettingsStore();

const state = reactive({
  is_loading: false,
});

const configuration = computed(() => dms_settings_store.configuration);

watch(() => configuration.value?.uid, (value) => {
  if (value)
    schema.html.default = configuration.value.auto_number_data?.html;
}, { immediate: true });

async function submitHandler(form$) {
  try {
    state.is_loading = true;
    const payload = {
      auto_number_data: form$.data?.auto_number_data,
    };
    if (configuration.value?.uid)
      await dms_settings_store.update_configuration(configuration.value?.uid, payload);
    else
      await dms_settings_store.add_configuration(payload);
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

// Initialize
const is_loading = ref(false);
onMounted(async () => {
  is_loading.value = true;
  try {
    await dms_settings_store.set_custom_fields({
      query: {
        allowed_in: 'transmittals',
      },
    });
  }
  catch (e) {
    logger.error(e);
  }
  is_loading.value = false;
});
</script>

<template>
  <hawk-loader v-if="is_loading" />
  <div v-else>
    <div class="flex flex-col mb-2 mt-4">
      <span class="text-lg font-semibold text-gray-900">{{
        $t("Schema")
      }}</span>
      <span class="text-xs text-gray-600">{{
        $t("Automate transmittal number creation with a customized schema")
      }}</span>
    </div>
    <Vueform
      :key="configuration?.uid"
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        container: 12,
        label: 12,
        wrapper: 12,
      }"
      :add-classes="{
        FormElements: { container: 'gap-[20px]' },
        ObjectElement: { wrapper: 'gap-[20px]' },
      }"
      :remove-classes="{
        FormElements: { container_sm: 'form-gap-y-gutter-sm' },
        ObjectElement: { wrapper_sm: 'form-gap-gutter-sm' },
      }"
      @submit="submitHandler"
    >
      <div class="col-span-12">
        <div class="max-w-[500px]">
          <ObjectElement name="auto_number_data" :schema="schema" />
        </div>
        <!-- Footer -->
        <div
          v-if="
            !transmittal_permissions.checkTransmittalPermission(
              'save_transmittal_schema',
            )
          "
          class="flex sticky bottom-0 border-t border-t-gray-200 mt-6 bg-white py-6 z-1 justify-end gap-3"
        >
          <HawkButton type="outlined">
            {{ $t("Cancel") }}
          </HawkButton>
          <ButtonElement
            submits
            name="assign_drawing_submit"
            :button-label="$t('Save')"
            :loading="state.is_loading"
          />
        </div>
      </div>
    </Vueform>
  </div>
</template>
