<script setup>
import { onMounted } from 'vue';
import { debounce, flatMapDeep, keyBy } from 'lodash-es';
import { storeToRefs } from 'pinia';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

const emit = defineEmits(['close', 'save']);

const dashboard_schedule_store = useDashboardScheduleStore();

const {
  get_gantt_task_data,
} = dashboard_schedule_store;

const {
  schedule_configuration,
} = storeToRefs(dashboard_schedule_store);

const activities_tree = ref([]);
const all_activities = ref([]);
const is_loading = ref(false);
const form_data = ref({
  data: [],
});

const initial_data = computed(() => schedule_configuration.value?.activities?.map(a => a.value) || []);

function getChildrenActivities(activity) {
  if (!activity)
    return [];

  if (!activity.children || !activity.children.length)
    return activity;

  return [activity, flatMapDeep(activity.children, getChildrenActivities)];
}

function getGanttData() {
  activities_tree.value = [];
  all_activities.value = [];
  is_loading.value = true;
  const interval = setInterval(async () => {
    activities_tree.value = await get_gantt_task_data();
    // get a flat array of the activities to also get the name more easily
    const flat_array = flatMapDeep(activities_tree.value, getChildrenActivities);
    all_activities.value = keyBy(flat_array, 'uid');
    if (activities_tree.value?.length)
      clearInterval(interval);

    else activities_tree.value = [];

    is_loading.value = false;
  }, 500);
}

const getFilteredGanttData = debounce(() => {
  getGanttData();
}, 300);

function emitUpdate() {
  const res = [];
  if (!form_data.value?.data) {
    emit('close');
    return;
  }

  form_data.value.data.forEach((activity_uid) => {
    const activity = all_activities.value[activity_uid];
    if (activity)
      res.push({
        value: activity_uid,
        label: activity.name,
        id: activity.id,
        has_children: activity.children?.length > 0,
      });
  });

  emit('save', res);
}

onMounted(() => {
  getGanttData();
});
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div class="text-lg font-semibold">
          {{ $t('Select activities') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="flex flex-col justify-between w-[900px] min-h-[300px]">
      <Vueform
        v-if="activities_tree.length && !is_loading"
        v-model="form_data"
        size="sm"
        class="w-full [&>*]:grid-cols-1"
      >
        <HawkTreeSelect
          :key="initial_data?.length"
          show_tree_select
          :show_expanded_search="true"
          :options="{
            name: 'data',
            search: true,
          }"
          :data="activities_tree"
          :initial_state="initial_data"
          children_key="children"
          label_key="name"
          value_key="uid"
          :flat="true"
          class="flex flex-col justify-between w-[900px] min-h-[300px]"
          add_classes="!h-auto min-h-[240px] max-h-[420px] max-[1366px]:max-h-[320px] border border-gray-300 border-solid rounded-lg"
          @updateForm="form_data.data = $event"
        />
      </Vueform>
      <HawkLoader v-if="is_loading" />
      <div v-else-if="!activities_tree.length" class="w-full h-auto min-h-[240px] max-h-[420px] border border-gray-300 border-solid rounded-lg">
        <div class="text-sm font-medium text-center text-gray-700 pt-4">
          {{ $t('No data available') }}
        </div>
      </div>
    </div>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="emitUpdate"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
