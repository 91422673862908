<script setup>
import { isNil, omitBy } from 'lodash-es';
import { useRoute } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useTasksStore } from '~/tasks/store/tasks.store.js';
import { useChecklistStore } from '~/tasks/store/checklists.store';

import TaskPriority from '~/tasks/components/atoms/task-priority.vue';
import TaskTemplateDropdown from '~/tasks/components/atoms/task-template-dropdown.vue';

const props = defineProps({
  task_store: {
    type: Object,
    required: true,
  },
  tasks: {
    type: Array,
    required: true,
  },
  asset_id: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: 'tasks',
  },
});
const emit = defineEmits(['close']);

const route = useRoute();

const auth_store = useAuthStore();
const template_store = useTasksStore('template');
const checklist_store = useChecklistStore();

template_store.set_tasks({ is_template: true });
checklist_store.set_checklists();

const form$ = ref(null);
const form_data = ref(null);
const loading = ref(false);

const is_save_disabled = computed(() => {
  if (!form_data.value)
    return true;

  return !(
    Object.keys(form_data.value).some((key) => {
      if (key === 'assignees_group')
        return !!form_data.value[key]?.users?.length;
      else if (key === 'tags_group')
        return !!form_data.value[key]?.tags?.length;
      else if (key === 'checklists')
        return !!form_data.value[key]?.length;
      else
        return form_data.value[key] !== null && form_data.value[key] !== undefined;
    })
  );
});

async function handleLoadFromTemplate(data) {
  const template_payload = { tasks: [] };
  props.tasks.forEach((task) => {
    template_payload.tasks.push({
      action: 'load_template',
      name: template_store.tasks_map[data.template_uid]?.name,
      task_uid: task.uid,
      template_uid: data.template_uid,
      organization: auth_store.current_organization?.uid,
      include: {
        assignees: true,
        attachments: true,
        checklists: {
          asignee: true,
          dueDate: true,
          attachments: true,
          resolved: true,
        },
        priority: true,
        watchers: true,
        users: true,
        labels: true,
        schedule: true,
        tags: true,
        status: true,
        category: true,
        sub_tasks: true,
        due_date: !task.due_date,
        start_date: !task.start_date,
        properties: false,
      },
    });
  });

  await props.task_store.load_from_template(template_payload);
  if (props.tasks.length)
    props.task_store.task_track_events('Loaded a template', {}, props.tasks[0].uid, ['associated_with']);
}

async function handleChecklistLoadfromTemplate(data) {
  const payload = [];
  data.checklists.forEach((checklist_uid) => {
    props.tasks.forEach(task => payload.push({
      task_uid: task.uid,
      include: {
        assignee: true,
        attachments: true,
        items: true,
      },
      template_uid: checklist_uid,
      action: 'load_template',
    }));
  });
  await checklist_store.load_multiple_checklists(payload);
}

async function handleUpdates(uids, data) {
  const payload = {};
  Object.keys(data).forEach((key) => {
    if (key === 'tags_group') {
      if (data[key].tags_option)
        if (data[key].tags.length)
          payload.tags = {
            [data[key].tags_option.toLowerCase()]: data[key].tags,
          };
    }
    else if (key === 'assignees_group') {
      if (data[key].assignees_option)
        if (data[key].users.length) {
          const key_map = {
            Assign: 'add',
            Unassign: 'remove',
          };
          payload.users = {
            [key_map[data[key].assignees_option]]: data[key].users.map((user) => {
              return {
                uid: user.uid,
                access: 'assignees',
              };
            }),
          };
        }
    }
    else {
      payload[key] = data[key];
    }
  });
  await props.task_store.update_tasks(uids, payload, true);
  const { properties } = props.task_store.get_event_properties({
    tasks: [{
      ...payload, assignees: form_data.value.assignees_group?.users || [], tags: form_data.value.tags_group?.tags || [],
    }],
  });
  properties.forEach((updated_key) => {
    props.task_store.task_track_events(`${updated_key} updated`, { mode: uids.length ? 'Bulk' : 'Single', count: uids.length }, uids[0]);
  });
}

// Popup save
async function handleSave() {
  loading.value = true;
  const uids = props.tasks?.map(task => task.uid);
  const data = omitBy(form_data.value, isNil);

  if (data.template_uid)
    await handleLoadFromTemplate(data);
  if (data.checklists?.length)
    await handleChecklistLoadfromTemplate(data);
  // Other updates

  delete data.template_uid;
  delete data.checklists;
  await handleUpdates(uids, data);

  loading.value = false;
  emit('close');
}

function setCreatedCategory(e) {
  form$.value.elements$.category.load(e.uid);
}
function setCreatedTag(e) {
  form$.value.elements$.tags_group.children$.tags.load([...form_data.value.tags_group.tags, e.uid]);
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="form_data"
      sync
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('update') }} {{ tasks.length }} {{ $t('tasks') }}
            </div>
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <SelectElement
            class="mb-4"
            v-bind="{
              name: 'status',
              label: $t('status'),
              placeholder: $t('Select Status'),
              items: task_store.status_values,
              native: false,
            }"
          />

          <SelectElement
            class="mb-4"
            v-bind="{
              name: 'priority',
              label: $t('Priority'),
              placeholder: $t('Select Priority'),
              items: task_store.priority_values,
              native: false,
            }"
          >
            <template #single-label="{ value }">
              <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                <TaskPriority :priority="value.value" />
              </div>
            </template>
          </SelectElement>

          <DateTimeElement
            :key="form_data?.due_date"
            class="mb-4"
            name="start_date"
            :label="$t('Start Date')"
            :options="{
              format: 'dd/MM/yyyy',
              placeholder: $t('Select Start Date'),
              startTime: { hours: 0, minutes: 0 },
              maxDate: form$?.data?.due_date ? new Date(form$.data.due_date) : null,
            }"
          />

          <DateTimeElement
            :key="form_data?.start_date"
            class="mb-4"
            name="due_date"
            :label="$t('Due Date')"
            :options="{
              format: 'dd/MM/yyyy',
              placeholder: $t('Select Due Date'),
              startTime: { hours: 23, minutes: 59, seconds: 59 },
              minDate: form$?.data?.start_date ? new Date(form$?.data?.start_date) : null,
            }"
          />

          <hawk-category-input
            class="mb-4"
            :options="{
              name: 'category',
              label: $t('Category'),
              placeholder: $t('Select Category'),
              create: true,
              from: 'Tasks',
            }"
            :setter_function="setCreatedCategory"
            :asset_id="asset_id"
          />

          <ObjectElement
            class="mb-4"
            name="assignees_group"
            :label="$t('Assignees')"
          >
            <RadiogroupElement
              name="assignees_option"
              default="Assign"
              :items="[
                'Assign',
                'Unassign',
              ]"
            />
            <hawk-assignee-input
              :options="{
                name: 'users',
                conditions: [
                  ['assignees_group.assignees_option', ['Assign', 'Unassign']],
                ],
                has_teams: true,
                placeholder: $t('Select Assignees'),
              }"
              :asset_id="props.asset_id"
              :multi="true"
              :format_load="true"
            />
          </ObjectElement>

          <ObjectElement
            class="mb-4"
            name="tags_group"
            :label="$t('tags')"
          >
            <RadiogroupElement
              name="tags_option"
              default="Add"
              :items="[
                'Add',
                'Remove',
              ]"
            />
            <hawk-tags-input
              :options="{
                name: 'tags',
                placeholder: $t('Select Tags'),
                conditions: [
                  ['tags_group.tags_option', ['Add', 'Remove']],
                ],
                create: true,
              }"
              :setter_function="setCreatedTag"
            />
          </ObjectElement>

          <v-tags-input
            v-if="type !== 'therm'"
            class="mb-4"
            :options="{
              'name': 'checklists',
              'label': $t('Checklists'),
              'placeholder': $t('Select Checklists'),
              'track-by': 'name',
              'label-prop': 'name',
              'value-prop': 'uid',
              'items': checklist_store.checklists,
              'search': true,
              'closeOnSelect': false,
            }"
          />

          <TaskTemplateDropdown
            class="mb-4"
            :options="{
              name: 'template_uid',
              label: $t('Load from template'),
              placeholder: $t('Choose Template'),
            }"
            :asset_id="route.params.asset_id"
            :show_only_org_templates="!route.params.asset_id"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-button
                color="primary"
                :loading="loading"
                :disabled="is_save_disabled"
                @click="handleSave"
              >
                {{ $t('Save') }}
              </hawk-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
