<script setup>
import { useModal } from 'vue-final-modal';
import { uniq } from 'lodash-es';

import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

const props = defineProps({
  can_modify: {
    type: Boolean,
    required: true,
  },
  attachments_count: {
    type: Number,
    default: 0,
  },
});

const task_store = inject('task_store');
const { task_uid } = inject('task-uid');
const $toast = inject('$toast');
const $t = inject('$t');

const attachments_count = toRefs(props).attachments_count;
const is_loading = ref(false);

async function attachmentUploadHandler(attachments) {
  try {
    is_loading.value = true;
    await task_store.set_task_attachments(task_uid.value, { attachments });
    if (attachments.length)
      task_store.task_track_events('Attachments added', {
        count: attachments.length,
        filetype: uniq(attachments.map(val => val.file_type)).join(', '),
        filesize: attachments.map(val => val.file_size).reduce((acc, val) => acc + val, 0),
        location: 'Task details',
      }, task_uid.value);
    is_loading.value = false;
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
      position: 'bottom-right',
    });
    is_loading.value = false;
    logger.error(error);
  }
}

function attachmentDownloadHandler(file) {
  task_store.task_track_events('Attachments downloaded', { filesize: file.file_size, filetype: file.mime_type || file.extension, location: 'Task details' }, task_uid.value);
}
const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});
function attachmentDeleteHandler(file) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Attachment'),
        content: `Are you sure you want to delete ${file.file_name}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await task_store.delete_task_attachments(task_uid.value, file.uid);
            task_store.task_track_events('Attachments removed', {
              count: 1,
              filetype: file.type || file.mime_type,
              filesize: file.file_size,
              location: 'Task details',
            }, task_uid.value);
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}
async function notesUpdateHandler(data) {
  await task_store.update_task_attachments(task_uid.value, { attachment: data });
  const file = task_store.attachments_map[data.uid];
  task_store.task_track_events('Attachment notes updated', {
    filetype: file.file_type || file.mime_type,
    filesize: file.file_size,
    location: 'Task details',
  }, task_uid.value);
}
</script>

<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="font-semibold text-lg">
        {{ $t('Attachments') }}
      </div>
      <DocumentImportDropdown
        :attachment_config="{ meta: { service: 'tickets', id: task_uid } }"
        trigger_classes="!font-medium"
        @addFiles="attachmentUploadHandler"
      />
    </div>
    <div>
      <hawk-loader v-if="is_loading" />
      <hawk-attachments-grid
        v-else-if="task_store.attachments.length"
        :items="task_store.attachments"
        :can_delete="can_modify"
        :enable_description="true"
        @view="task_store.task_track_events('Attachments viewed', { filesize: $event.file_size, filetype: $event.mime_type || $event.extension, location: 'Task details' }, task_uid)"
        @delete="attachmentDeleteHandler"
        @updateNotes="notesUpdateHandler"
      />
    </div>
  </div>
</template>
