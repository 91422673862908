<script setup>
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps(['signature', 'form$']);

const emit = defineEmits(['close', 'save']);

const $t = inject('$t');
const form = ref(null);
const active_tab = ref('Draw');
const signature_pad = ref(null);
const canvas = ref('canvas');
const auth_store = useAuthStore();
const loading = ref(false);
const previous_signature = ref(null);
const form$ = props.form$;

const tabs = [{
  uid: 'Draw',
  label: $t('Draw'),
  on_click: () => { active_tab.value = 'Draw'; },
},
{
  uid: 'Type',
  label: $t('Type'),
  on_click: () => { active_tab.value = 'Type'; },
},
// {
//   uid: 'Upload',
//   label: $t('Upload'),
//   on_click: () => { active_tab.value = 'Upload'; },
// }
];

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

async function imageCompression(blob) {
  const Compressor = await import('compressorjs');
  return new Promise(
    (resolve, reject) =>
      new Compressor(blob, {
        quality: 0.8,
        maxWidth: 1024,
        width: 1024,
        checkOrientation: false,
        convertSize: 0,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      }),
  );
}

async function getCompressedBase64String(file) {
  const compressed_image = await imageCompression(file);
  return toBase64(compressed_image);
}

function preview_text(signature_text = '') {
  if (!canvas.value)
    return;
  const ctx = canvas.value.getContext('2d');
  ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
  ctx.font = '36px Great Vibes';
  ctx.fillText(signature_text, 20, 50);
}

async function store_signature_for_user(data) {
  await auth_store.update_signature({
    body: {
      signature: data,
    },
  });
}

async function uploadFile(value) {
  const response = await fetch(value);
  const blob = await response.blob();
  const new_file = new File([blob], `${uuidv4()}.png`, { type: 'png' });
  const id = form$.uppy.addFile(new_file);
  new_file.uppy_id = id;
  new_file.location = value;
  return new_file;
}

async function save() {
  let signature_data = null;
  switch (active_tab.value) {
    case 'Draw':
      signature_data = previous_signature.value
        ? { data: previous_signature.value, isEmpty: false }
        : signature_pad.value.saveSignature();
      break;
    case 'Upload':
      signature_data = {
        data: await getCompressedBase64String(form.value.file),
        isEmpty: false,
      };
      break;
    case 'Type':
      signature_data = {
        data: canvas.value.toDataURL(),
        isEmpty: false,
      };
      break;
  }
  if (!signature_data.isEmpty && signature_data.data !== auth_store.signature)
    store_signature_for_user(signature_data.data);
  if (signature_data.data) {
    signature_data.data = await uploadFile(signature_data.data);
    const { logged_in_user_details } = useAuthStore();
    const now = new Date().toISOString();
    const address = auth_store.ip_address;
    signature_data.data.meta = {
      owner: { uid: logged_in_user_details?.user_id },
      created_at: now,
      city: address?.city,
      region: address?.region,
      country: address?.country,
      user_agent: navigator.userAgent,
    };
  }
  emit('save', signature_data.isEmpty ? null : signature_data.data);
}

function clear_signature() {
  previous_signature.value = null;
  signature_pad.value.clearSignature();
}

onMounted(async () => {
  previous_signature.value = props.signature || auth_store.signature;
});
</script>

<template>
  <hawk-modal-container content_class="w-[650px] rounded-lg">
    <div style="font-family:Great Vibes;visibility:hidden;width:0;height:0;">
      Sample
    </div>
    <Vueform
      v-model="form"
      :presets="['']"
      :display-errors="false"
      :endpoint="save"
      :columns="{
        default: { container: 8, wrapper: 12, label: 4 },
        sm: { container: 12, label: 12, wrapper: 12 },
        md: { container: 8, label: 4, wrapper: 12 },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Add Signature') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <div class="mb-8">
            <hawk-tabs
              :tabs="tabs"
              :active_item="active_tab"
              class="mt-5"
            />
            <div class="mt-6">
              <div v-show="active_tab === 'Draw'">
                <img v-if="previous_signature" :src="previous_signature" class="h-[164px]" alt="previous signature">
                <VueSignaturePad :key="`sign_${!!previous_signature}`" v-show="!previous_signature" ref="signature_pad" width="100%" height="164px" class="border-dashed border-2 rounded-lg border-primary-200" />
                <div class="flex justify-end mt-4">
                  <HawkButton type="text" @click="clear_signature">
                    {{ $t('Clear') }}
                  </HawkButton>
                </div>
              </div>
              <div v-if="active_tab === 'Type'">
                <TextElement name="Type" :label="$t('Enter Signature')" :rules="['required']" @change="preview_text" />
                <StaticElement class="mt-6" label="Preview" name="preview">
                  <canvas
                    id="myCanvas"
                    ref="canvas"
                    class="is-flex is-center w-[100%] border rounded-lg border-gray-200"
                    height="108"
                  />
                </StaticElement>
              </div>
              <div v-if="active_tab === 'Upload'">
                <FileElement name="file" :label="$t('Upload Signature')" :drop="true" :rules="['required']" view="gallery" accept="image/*" />
              </div>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
</style>
