<script setup>
import { useModal } from 'vue-final-modal';
import SheetPreviewModal from './sheet-preview-modal.vue';

const props = defineProps({
  document_pages: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(['close', 'confirm']);

const radio_default_class = 'RadiogroupRadio.input--> flex items-center justify-center flex-shrink-0 appearance-none cursor-pointer rounded-full outline-zero transition-input duration-200 border-solid form-border-width-radio form-shadow-input focus:form-ring checked:form-bg-icon-radio form-shadow-handles mb-2 RadiogroupRadio.input_md--> form-w-checkbox form-h-checkbox form-mr-space-checkbox form-mt-checkbox RadiogroupRadio.input_default--> form-bg-input form-border-color-input hover:form-bg-input-hover hover:form-border-color-input-hover hover:form-shadow-handles-hover focused:form-bg-input-focus focused:form-border-color-input-focus focused:form-shadow-handles-focus focused-hover:form-shadow-handles-hover checked:form-bg-primary checked:form-border-color-checked checked-hover:form-bg-primary checked-hover:form-border-color-checked checked-focused:form-bg-primary checked:!bg-primary-50 checked:after:!bg-primary-500 checked:!border-primary-500';
const selected_doc_id = ref(null);

function onConfirmHandler() {
  const doc = props.document_pages.find(document_page => selected_doc_id.value === document_page.doc_instance.id);

  emit('confirm', doc);
  emit('close');
}

function docCardClickHandler(doc_id) {
  const doc = props.document_pages.find(document_page => doc_id === document_page.doc_instance.id);
  const { open: open_sheet_preview_modal, close: close_sheet_preview_modal } = useModal({
    component: SheetPreviewModal,
    attrs: {
      onClose() {
        close_sheet_preview_modal();
      },
      sheet_url: doc.file_blob,
    },
  });

  open_sheet_preview_modal();
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      size="sm"
      class="w-full"
      :display-errors="false"
      @submit="onConfirmHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('close')">
          <template #title>
            {{ $t('Select a page') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <!-- Document Cards -->
          <div class="grid grid-cols-3 gap-4 mb-6">
            <template v-for="(doc, doc_index) in document_pages" :key="`${doc.doc_instance.id}`">
              <div class="border-t rounded-md shadow-md outline outline-transparent" :class="{ 'border-t-0 !outline-blue-600': selected_doc_id === doc.doc_instance.id }">
                <div class="cursor-pointer relative h-[128px] group" @click="() => docCardClickHandler(doc.doc_instance.id)">
                  <div class="absolute h-full w-full">
                    <img :src="doc.thumbnail" alt="plan thumbnail" class="h-full w-full object-contain">
                  </div>
                  <div class="absolute h-full w-full rounded-t-md group-hover:bg-black/50 flex flex-col items-start">
                    <div class="pl-2 pt-1 group-hover:block -mb-2" :class="{ hidden: selected_doc_id !== doc.doc_instance.id }">
                      <input
                        v-model="selected_doc_id"
                        :value="doc.doc_instance.id"
                        type="radio"
                        name="doc_radio"
                        :class="[radio_default_class]"
                        @click.stop.exact
                      >
                    </div>
                    <HawkButton color="primary" type="outlined" size="sm" class="text-xs !px-2 !h-8 m-auto hidden group-hover:block !ring-transparent">
                      {{ $t('View') }}
                    </HawkButton>
                  </div>
                </div>
                <div class="flex p-2 border-t justify-between h-[38px]">
                  <div class="flex flex-col gap-1.5 text-sm font-semibold">
                    <hawk-text :length="22" :content="`${$t('Page')} ${doc_index + 1}`" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end w-full">
              <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement :disabled="!selected_doc_id" submits name="select_drawing_submit" :button-label="$t('Confirm')" button-class="vf-btn-primary" />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
