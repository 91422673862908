<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    active_item: {
      type: String,
      default: '',
    },
    active_key: {
      type: String,
      default: 'uid',
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['sm', 'md'].includes(value);
      },
    },
    container_class: {
      type: String,
      default: '',
    },
    space: {
      type: Number,
      default: 8,
    },
    show_item: {
      type: Number,
      default: 5,
    },
    character_length: {
      type: Number,
    },
  },
  emits: ['tabClick'],
  data() {
    return {
      open_more: false,
    };
  },
  computed: {
    visible_tab_items() {
      const all_items = this.tabs;
      return all_items.slice(0, this.show_item);
    },
    more_tab_items() {
      const all_items = this.tabs;
      return all_items.slice(this.show_item, this.tabs.length);
    },
  },
  methods: {
    onTabClick(tab) {
      if (tab.disabled)
        return;

      if (tab?.to && Object.keys(tab?.to)?.length) {
        this.$router.push(tab.to);
        this.$emit('tabClick', tab);
      }
      else if (tab?.on_click) {
        tab?.on_click();
      }
      else {
        this.$emit('tabClick', tab);
      }
    },
    tab_label_classes(tab) {
      const classes = [];
      if (this.size === 'sm')
        classes.push('text-sm');
      else if (this.size === 'md')
        classes.push('text-base');

      const is_active_item = tab[this.active_key] === this.active_item;
      if (is_active_item)
        classes.push ('border-primary-700', 'text-primary-700');
      else
        classes.push('border-transparent', 'text-gray-500');

      if (tab.disabled) {
        classes.push('opacity-40');
      }
      else {
        classes.push('cursor-pointer');
        if (!is_active_item)
          classes.push('hover:text-gray-700', 'hover:border-gray-200');
      }

      return classes;
    },
  },
};
</script>

<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select
        id="tabs"
        name="tabs"
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      >
        <option
          v-for="tab in tabs"
          :key="tab.label"
          :selected="tab[active_key] === active_item"
        >
          {{ tab.label }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200" :class="container_class">
        <nav
          class="-mb-px flex flex-wrap gap-y-2"
          :class="`gap-${space}`"
          aria-label="Tabs"
        >
          <div
            v-for="tab in visible_tab_items"
            :key="tab.label"
            v-tippy="{ content: tab?.disabled ? tab?.disabled_tooltip : '', placement: 'top' }"
            class="whitespace-no wrap flex px-1 pt-0.5 pb-3 border-b-2 font-semibold cursor-pointer capitalize"
            :class="tab_label_classes(tab)"
            :aria-current="tab[active_key] === active_item ? 'page' : undefined"
            @click="onTabClick(tab)"
          >
            <HawkText :content="tab.label" :length="tab.character_length || character_length || 9999" />
            <slot name="icon" />
            <span
              v-if="tab.count"
              class="hidden ml-2 py-0.5 px-2 rounded-full font-medium md:inline-block"
              :class="[
                tab[active_key] === active_item
                  ? 'bg-blue-100 text-blue-600'
                  : 'bg-gray-100 text-gray-900',
                { 'text-xs': size === 'sm' },
                { 'text-sm': size === 'md' },
              ]"
            >
              {{ tab.count }}
            </span>
          </div>
          <div v-if="more_tab_items?.length">
            <HawkMenu
              additional_trigger_classes="!ring-0"
              :items="more_tab_items"
              @open="open_more = true"
              @close="open_more = false"
            >
              <template #trigger>
                <div
                  class="text-gray-500 hover:text-gray-700 hover:border-gray-200 font-semibold text-sm flex items-center"
                  :class="{ '!text-blue-600': more_tab_items.filter(tab => tab?.[active_key] === active_item)?.length }"
                >
                  More
                  <IconHawkChevronUp v-if="open_more" class="text-lg" />
                  <IconHawkChevronDown v-else class="text-lg" />
                </div>
              </template>
              <template #content>
                <div class="w-60 text-sm p-1">
                  <HawkButton
                    v-for="tab in more_tab_items" :key="tab.label"
                    type="text"
                    class="w-full grid"
                    :class="[
                      tab[active_key] === active_item
                        ? '!text-blue-600 font-semibold'
                        : '!text-gray-700 font-medium',
                      { 'text-sm': size === 'sm' },
                      { 'text-base': size === 'md' },
                      { 'pointer-events-none opacity-40': tab.disabled },
                    ]"
                    :aria-current="tab[active_key] === active_item ? 'page' : undefined"
                    @click="onTabClick(tab)"
                  >
                    <div class="w-full flex items-center justify-between">
                      <HawkText :content="tab.label" />
                      <span
                        v-if="tab.count"
                        class="hidden py-0.5 px-2 rounded-full font-medium md:inline-block"
                        :class="[
                          tab[active_key] === active_item
                            ? 'bg-blue-100 text-blue-600'
                            : 'bg-gray-100 text-gray-900',
                          { 'text-xs': size === 'sm' },
                          { 'text-sm': size === 'md' },
                        ]"
                      >
                        {{ tab.count }}
                      </span>
                    </div>
                  </HawkButton>
                </div>
              </template>
            </HawkMenu>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
