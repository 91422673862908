<script setup>
const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});

const USER_VERB_MAP = {
  SIGN_UP: 'signed up',
  INVITED: (activity) => {
    return `invited a user to join the organization ${activity.meta.organization?.name || ''}`;
  },
  ASSET_ACCESS_CHANGED: (activity) => {
    return `modified accessible resources for the asset ${activity.meta?.asset?.name || ''}`;
  },
  PASSWORD_RECOVERED: 'has reset the password',
  PERMISSIONS_CHANGED: 'modified the permissions',
  PASSWORD_CHANGED: 'changed their password',
  LOGGED_IN: 'logged in',
  LOGGED_OUT: 'logged out',
  LOGIN_ATTEMPT_FAILED: 'failed login attempt',
  LOCKED: 'account locked',
  UNLOCKED: 'account unlocked',
  LOCK_LIMIT_REACHED: 'reached lock out limit',
  JOINED_ORGANIZATION: (activity) => {
    return `joined the organization - ${activity.meta?.organization?.name || ''}`;
  },
  REMOVED_FROM_ORGANIZATION: 'removed the user from organization',
  HOME_ORGANIZATION_CHANGED: (activity) => {
    return `moved from ${activity.meta.organization.name}`;
  },
  NOTIFICATION_PREFERENCES_CHANGED: 'updated their notification preferences',
};

const TEAM_VERB_MAP = {
  CREATED: 'created the team',
  UPDATED: 'updated the team',
  DELETED: 'deleted the team',
  ASSET_ACCESS_CHANGED: (activity) => {
    return `modified accessible resources for the asset ${activity.meta?.asset?.name || ''}`;
  },
};

const ROLE_VERB_MAP = {
  CREATED: 'created the role',
  UPDATED: 'updated the role',
  DELETED: 'deleted the role',
  PERMISSIONS_CHANGED: 'modified the permissions',
};

const PROJECT_VERB_MAP = {
  CREATED: 'created the project',
  UPDATED: 'updated the project',
  DELETED: 'deleted the project',
  IMAGES_ADDED: 'added images',
  IMAGES_SYNC_STARTED: 'initiated sync from s3',
  IMAGES_SYNC_COMPLETED: 'all images are synced',
  REPORT_CREATED: activity => `created a report ${activity.meta?.report?.name || ''}`,
  REPORT_UPDATED: activity => `updated a report ${activity.meta?.report?.name || ''}`,
  REPORT_DELETED: activity => `deleted a report ${activity.meta?.report?.name || ''}`,
  REPORT_DOWNLOADED: activity => `downloaded a report ${activity.meta?.report?.name || ''}`,
};

function getVerbMap(resource) {
  switch (resource) {
    case 'USER':
      return USER_VERB_MAP;
    case 'TEAM':
      return TEAM_VERB_MAP;
    case 'ROLE':
      return ROLE_VERB_MAP;
    case 'PROJECT':
      return PROJECT_VERB_MAP;
    default:
      return {
        CREATED: `created the ${resource.toLowerCase()}`,
        UPDATED: `updated the ${resource.toLowerCase()}`,
        DELETED: `deleted the ${resource.toLowerCase()}`,
      };
  }
}

function getActivityText(activity) {
  const verb_map = getVerbMap(activity.resource_name);

  if (typeof verb_map[activity.verb] === 'function')
    return verb_map[activity.verb](activity);
  return verb_map[activity.verb];
}
</script>

<template>
  <div>
    <div>
      <div class="flex flex-wrap">
        <slot name="activity_prefix" />
        <div v-if="Object.keys(getVerbMap(activity.resource_name)).includes(activity.verb)" class="text-sm">
          {{ getActivityText(activity) }}
        </div>
        <div v-if="activity.verb === 'NOTIFICATION_SENT'" class="text-sm">
          {{ activity.meta.notification.type }} {{ activity.meta.notification.channel }} notification sent to  <HawkMembers :members="activity.meta.notification.to" type="badge" />
        </div>
        <div v-else-if="activity.verb === 'DEACTIVATED'" class="text-sm">
          deactivated the user <HawkMembers :members="activity.meta.user" type="badge" />
        </div>
        <div v-else-if="activity.verb === 'TEAMS_ASSIGNED'" class="text-sm flex items-center">
          assigned the teams <HawkMembers :members="activity.meta.teams.map(team => team.uid)" type="badge" class="ml-2" />
        </div>
        <div v-else-if="activity.verb === 'TEAMS_UNASSIGNED'" class="text-sm flex items-center">
          unassigned the teams <HawkMembers :members="activity.meta.teams.map(team => team.uid)" type="badge" class="ml-2" />
        </div>
        <div v-else-if="activity.verb === 'ROLES_ASSIGNED'" class="text-sm">
          assigned the roles <span class="font-semibold">{{ activity.meta.roles.map(role => role.name).join(', ') }}</span>
        </div>
        <div v-else-if="activity.verb === 'ROLES_UNASSIGNED'" class="text-sm">
          unassigned the roles <span class="font-semibold">{{ activity.meta.roles.map(role => role.name).join(', ') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
