<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import IconHawkSharepoint from '~icons/integrations/sharepoint';

import { keyBy } from 'lodash-es';
// composables
import { h, onMounted } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useDocumentActions } from '~/dms/composables/document-actions.composable';
import { useDocumentBulkActions } from '~/dms/composables/document-bulk-actions.composable';
import { useDocumentCrud } from '~/dms/composables/document-crud.composable';

// stores
import { useDocumentStore } from '~/dms/store/document.store';

// components
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useIntegrationsStore } from '~/common/stores/integrations.store';
import DocumentBreadcrumbs from '~/dms/components/atoms/document-breadcrumbs.vue';
import DocumentThumbnail from '~/dms/components/atoms/document-thumbnail.vue';
import DocumentActionBar from '~/dms/components/documents/table/document-action-bar.vue';
import DocumentContextMenu from '~/dms/components/documents/table/document-context-menu.vue';
import DocumentDetailsSlider from '~/dms/components/documents/table/document-details-slider.vue';
import DocumentTableItem from '~/dms/components/documents/table/document-table-item.vue';
import { useExportDocumentsMetadata } from '~/dms/composables/document-metadata-export.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  documents: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Object,
    required: false,
  },
  is_widget: {
    type: Boolean,
    required: false,
    default: false,
  },
  source: {
    type: String,
    required: false,
    default: 'files',
  },
  refresh_documents: {
    type: Function,
    default: null,
  },
  is_table_loading: {
    type: Boolean,
    default: false,
  },
  canResize: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['tableInstanceCreated', 'columnResized']);

let dbl_click_time = null;

const display_filters_ref = inject('display_filters_ref');
const { $t, route, router, auth_store: authStore } = useCommonImports();

const document_actions = useDocumentActions({ source: props.source });
const document_bulk_actions = useDocumentBulkActions();
const document_crud = useDocumentCrud();

const document_store = useDocumentStore();
const integrations_store = useIntegrationsStore();
const dms_settings_store = useDMSSettingsStore();
const { exportDocumentsMetaData, is_metadata_exporting, startExporting, cancelExportingMetadata } = useExportDocumentsMetadata(props.table_instance);

const table_instance = ref(null);
const form$ = ref(null);

const state = reactive({
  freeze_table: '-1',
  selected_items: [],
  form: {},
  has_filter_applied: false,
  is_dbl_click: null,
  is_table_loading: false,
  is_details_loading: false,
  file_to_show: '',
});

const file_details = computed(() => document_store.active_item_details);
const active_folder_detail = computed(() => document_store.active_folder_detail);
const element = computed(() => props.options?.query?.element);
const search_query = computed(() => document_store.search_query);
const create_documents = computed(() => authStore.check_permission('create_documents', route?.params?.asset_id));
const custom_fields = computed(() => dms_settings_store.custom_fields?.filter(field => field.active) || []);

const active_tab = computed(() => route.meta.page);

const empty_screen = computed(() => {
  const { type } = document_store.active_folder_meta;
  return type !== 'folder' && ['shared', 'archive', 'trash'].includes(active_tab.value) && !props.documents?.length && !state.is_table_loading;
});
const is_filtered_view = computed(() => {
  return ['files', 'shared'].includes(active_tab.value) && (display_filters_ref?.value?.filters?.length > 0 || search_query.value);
});
const can_navigate = computed(() => {
  let value;
  switch (route.meta.page) {
    case 'files':
    case 'shared':
    case 'archive':
      value = true;
      break;
    case 'trash':
      value = false;
      break;
  }
  return value;
});

const custom_field_columns = computed(() => custom_fields.value.map(custom_field => ({
  header: custom_field.label,
  accessorKey: custom_field.uid,
  id: custom_field.uid,
})));

const columns = computed(() => {
  const base = [{
    id: 'select',
    header: '',
    accessorKey: 'select',
    static: true,
  }, {
    header: 'Name',
    accessorKey: 'name',
    id: 'name',
    cell: info => info.getValue(),
    columns: [],
    static: true,
  }, ...(authStore.check_split('files_v2_advanced')
    ? [
        {
          header: 'Number',
          accessorKey: 'number',
          id: 'number',
          cell: info => info.getValue(),
          columns: [],
          static: true,
        },
        {
          header: 'Status',
          accessorKey: 'status',
          id: 'status',
          static: true,
        },
        ...custom_field_columns.value,
      ]
    : []), {
    header: 'Progress',
    accessorKey: 'progress',
    id: 'progress',
    visible: false,
    cell: info => info.getValue(),
    columns: [],
  }, {
    header: 'Weight',
    accessorKey: 'weightage',
    id: 'weightage',
    visible: false,
    cell: info => info.getValue(),
    columns: [],
  }];
  const shared = [
    {
      header: 'Shared on',
      accessorKey: 'shared_at',
      id: 'shared_at',
      cell: info => info.getValue(),
      columns: [],
      static: true,
    },
    {
      header: 'Shared by',
      accessorKey: 'shared_by',
      id: 'shared_by',
      cell: info => info.getValue(),
      columns: [],
      static: true,
    },
  ];
  const trash = [
    {
      header: 'Deleted On',
      accessorKey: 'updated_at',
      id: 'updated_at',
      cell: info => info.getValue(),
      columns: [],
      static: true,
    },
    {
      header: 'Permanent deletion',
      accessorKey: 'permanent_deletion',
      id: 'permanent_deletion',
      static: true,
    },
  ];
  const all = [
    {
      header: 'Modified On',
      accessorKey: 'updated_at',
      id: 'updated_at',
      cell: info => info.getValue(),
      columns: [],
      static: true,
    },
    {
      header: 'Shared with',
      accessorKey: 'sharing',
      id: 'sharing',
      cell: info => info.getValue(),
      columns: [],
      static: true,

    },
  ];
  const context_menu = [
    {
      accessorKey: 'context_menu',
      header: '',
      id: 'context_menu',
      size: '5',
      show_on_hover: 'true',
      static: true,
    },
  ];
  if (props.is_widget) {
    let all_columns = [...base, ...all];
    if (props.options?.hide_selection_checkbox) {
      all_columns = all_columns.filter(column => column.id !== 'select');
    }

    if (props.options?.visible_columns?.length) {
      all_columns = all_columns.filter(column => props.options.visible_columns.includes(column.id));
    }

    if (props.options?.widget_columns?.length) {
      all_columns = props.options.widget_columns.map((widget_column) => {
        const a = all_columns.find(c => c.id === widget_column.id);
        return { ...a, ...widget_column };
      });
    }
    return [
      ...all_columns,
      ...(props.options?.extra_columns?.length ? props.options.extra_columns : []),
      ...(props.options?.visible_columns?.includes?.('context_menu') ? context_menu : []),
    ];
  }

  if (active_tab.value === 'shared')
    return [...base, ...shared, ...context_menu];
  if (active_tab.value === 'trash')
    return [...base, ...trash, ...context_menu];

  return [...base, ...all, ...context_menu];
});

const default_columns = computed(() => columns.value.filter(column => column.visible !== false));

const custom_view_configuration = {
  service: 'dms',
  resource_type: 'files',
  feature: 'list_view',
  name: authStore.current_organization.uid,
  store_key: 'dms_files_list_view',
  modify_permissions: authStore.check_permission('configure_dms_settings', route.params.asset_id),
};

function groupByFunction(data) {
  const group_by = 'updated_at';
  const groupOrder = [
    $t('Today'),
    $t('Yesterday'),
    $t('Last 7 days'),
    $t('Last 30 days'),
    $t('Earlier'),
  ];

  const groupedData = data.reduce((group, row) => {
    const { original } = row;
    const groupName = determineGroup(original[group_by]);
    group[groupName] = group[groupName] ?? {
      show: false,
      rows: [],
    };
    group[groupName].rows.push(row);
    return group;
  }, {});

  // Sort the groups based on the predefined group order
  const sortedGroups = Object.keys(groupedData).sort((a, b) => {
    const indexA = groupOrder.indexOf(a);
    const indexB = groupOrder.indexOf(b);
    return indexA - indexB;
  });

  // Create a new object with the sorted groups
  const sortedGroupedData = {};
  sortedGroups.forEach((groupName) => {
    sortedGroupedData[groupName] = groupedData[groupName];
  });

  return sortedGroupedData;
}

function determineGroup(value) {
  const now = new Date();
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterdayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
  const last7DaysStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
  const last30DaysStart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29);

  const parsedValue = new Date(value);

  if (parsedValue >= todayStart)
    return $t('Today');

  else if (parsedValue >= yesterdayStart)
    return $t('Yesterday');

  else if (parsedValue >= last7DaysStart)
    return $t('Last 7 days');

  else if (parsedValue >= last30DaysStart)
    return $t('Last 30 days');

  else
    return $t('Earlier');
}

function shouldGroup() {
  if (['shared', 'trash'].includes(active_tab.value))
    return groupByFunction;
}

function onRowSelect(items) {
  state.selected_items = [];
  if (items.length > 0)
    items.forEach(item => state.selected_items.push(item.original));
}

function openUploadModal(flag, files) {
  document_crud.uploadHandler({
    flag,
    files,
    options: document_crud.getCommonPayload(props.options),
    form: form$,
  });
  setTimeout(() => {
    form$.value.reset();
  });
}

function clearSelectedRows() {
  state.selected_items = [];
  table_instance.value?.clearSelect();
}

async function getData() {
  state.is_table_loading = true;
  await props.refresh_documents?.(false);
  state.is_table_loading = false;
}

// context menu actions
function onItemDownload(data) {
  if (route.meta.page === 'archive')
    data.query = { archive: true };
  document_crud.itemDownload(data);
}

watch(() => document_store.active_folder_meta, () => {
  clearSelectedRows();
});

async function breadcrumbsHandler(breadcrumb) {
  const { type, uid, is_widget } = breadcrumb.item;
  if (uid === 'files' && !is_widget)
    return;

  const folder_meta_payload = is_widget ? { type: undefined, uid: undefined } : { type, uid };
  document_store.set_active_folder_meta(folder_meta_payload);
  await getData();
}

async function dblClickHandler(item) {
  clearTimeout(dbl_click_time);

  if (is_filtered_view.value)
    display_filters_ref?.value?.resetFilters(false);

  const target_route = {
    name: route.name,
    params: { ...router.currentRoute.value.params },
    query: { folder: item.uid },
  };

  if (item.type === 'folder') {
    if (!can_navigate.value)
      return;

    document_store.set_show_details(false);
    document_store.set_active_folder_meta(item);

    // redirect to all files if on shared view
    if (route.meta.page === 'shared')
      target_route.name = 'files-documents-all-files';

    router.push(target_route);

    if (!search_query.value)
      await getData();

    document_store.set_search_query(null);
    state.file_to_show = '';
  }
  else {
    showAttachment(item);
  }
}

async function cellClickHandler(item) {
  state.is_details_loading = true;
  clearTimeout(dbl_click_time);
  dbl_click_time = setTimeout(() => {
    if (item.id.includes('context_menu') || item.id.includes('select')) {
      document_store.set_show_details(false);
    }
    else {
      document_store.set_show_details(true);
      document_store.set_active_item_meta({ type: item.row.original.type, uid: item.row.original.uid });
      router.push({ query: { folder: document_store.active_folder_meta.uid, document: item.row.original.uid } });
    }
    state.is_details_loading = false;
  }, 400);
}

function showAttachment(item) {
  document_store.set_active_item_meta({
    type: item.type,
    uid: item.uid,
  });
  state.file_to_show = item.uid;
  state.is_details_loading = false;
}
function onOpenContext(item) {
  document_store.set_active_item_meta({
    type: item.type,
    uid: item.uid,
  });
}
function onSidebarClose() {
  dbl_click_time = null;
  state.file_to_show = '';
}

function emitColumnResized(resized_column, columns) {
  emit('columnResized', resized_column, columns);
};
onMounted(() => {
  if (authStore.check_split('DMS_sharepoint'))
    integrations_store.set_integrations({ is_dms: true, forceUpdate: true });
});

defineExpose({
  columns: columns.value,
});
</script>

<template>
  <HawkExportToast
    v-if="is_metadata_exporting"
    :submit="exportDocumentsMetaData"
    :progress_text="$t('Exporting documents')"
    :completed_text="$t('Documents exported')"
    @cancel="cancelExportingMetadata"
    @close="() => is_metadata_exporting = false"
  />
  <template v-if="!display_filters_ref?.filters?.length">
    <DocumentBreadcrumbs
      v-if="active_folder_detail?.uid"
      :active_item="active_folder_detail"
      :add_class="active_tab !== 'files' ? 'my-3' : null"
      :is_widget="props.is_widget"
      @on-click="breadcrumbsHandler"
    />
  </template>
  <div v-if="!is_filtered_view" class="mt-3">
    <div v-if="state.selected_items.length > 0" class="h-[50px] flex items-center">
      <DocumentActionBar
        :items="state.selected_items"
        :source="source"
        :active_tab="active_tab"
        :table_instance="table_instance"
        @clear="clearSelectedRows"
      />
    </div>
    <slot
      v-else
      name="files_vue_form"
      :options="{
        can_create_documents: create_documents,
        active_tab,
      }"
    >
      <Vueform
        v-if="active_tab === 'files' && create_documents && !is_widget"
        ref="form$"
        v-model="state.form"
        :display-errors="false"
        sync
        :class="{ 'my-4': options?.is_pm }"
      >
        <MultifileElement
          name="sample"
          :drop="true"
          :auto="false"
          :presets="['hawk_file_element']"
          :options="{
            clickable_text: $t('Click to upload'),
            text: $t('or drag and drop'),
            inline: true,
          }"
          :add-class="{
            list: ['hidden'],
          }"
          @change="openUploadModal('drop', $event)"
        />
      </Vueform>
    </slot>
  </div>
  <!-- Empty screen -->
  <HawkIllustrations v-if="is_widget && !documents?.length && !state.is_table_loading" variant="mini" type="no-data" for="files" />
  <HawkIllustrations
    v-else-if="empty_screen" type="no-data" for="files"
  />

  <div v-else>
    <component :is="is_widget ? 'a' : TableWrapperVue" class="!mt-4">
      <HawkTable
        :pagination_config="options.enable_pagination ? { totalRows: documents.length, pageSize: 25 } : null"
        :is_gapless="true"
        :non_sortable_columns="['context_menu']"
        :data="documents"
        :is_loading="state.is_table_loading || props.is_table_loading"
        :freeze_table="state.freeze_table"
        :show_menu_header="!(options?.disable_context_menu || is_widget)"
        grouping_row_classes="rounded-lg bg-gray-100 py-3"
        :columns="columns"
        :default_columns="default_columns"
        :group_by_function="shouldGroup()"
        :custom_view_configuration="!options.disable_custom_view_configuration && custom_view_configuration || {}"
        :header_context_menu_config="(!options.disable_header_context_menu_config && custom_view_configuration.modify_permissions) ? {
          is_menu_fixed: true,
          column_settings: true,
        } : {}"
        :disable_resize="!canResize"
        @table-instance="table_instance = $event"
        @select-row="onRowSelect"
        @cell-click="cellClickHandler"
        @row-dbl-click="dblClickHandler"
        @table-instance-created="emit('tableInstanceCreated', $event)"
        @column-resized="emitColumnResized"
      >
        <template #name="slotProps">
          <div class="flex items-center item-detail" :uid="slotProps.data.row.original.uid">
            <DocumentThumbnail
              :file="slotProps.data.row.original"
              :index="slotProps.data.row.index"
              :should_open="state.file_to_show === slotProps.data.row.original.uid"
              :context_download="document_crud.itemDownload"
              source="files"
              @close-attachment="state.file_to_show = ''"
            />
            <DocumentTableItem
              :is_filtered_view="!!is_filtered_view"
              :ancestors="slotProps.data.row.original?.ancestors || []"
              :name="slotProps.data.getValue() || ''"
              :is_public_folder="slotProps.data.row.original?.type === 'folder' && !slotProps.data.row.original?.parent && !slotProps.data.row.original?.private"
              :document="slotProps.data.row.original"
            />
            <div v-if="slotProps.data.row.original?.integrations?.filter(item => item.active)?.length" v-tippy="$t('Integrated with Sharepoint')" class="flex items-center ml-2 justify-evenly gap-2">
              <IconHawkSharepoint v-if="slotProps.data.row.original?.integrations?.some(item => item.type === 'Sharepoint')" class="w-5 h-5" />
            </div>
          </div>
        </template>
        <template v-for="(custom_field) in custom_fields" #[custom_field.uid]="slotProps" :key="custom_field.uid">
          <hawk-custom-field class="text-sm" :field="custom_field" :value="keyBy(slotProps.data.row.original.field_values, 'field')?.[custom_field.uid]?.value" />
        </template>
        <template #status="slotProps">
          <template v-if="slotProps.data.row.original?.status?.name">
            <HawkBadge :custom_color="dms_settings_store.document_status_map[slotProps.data.row.original.status?.uid]?.color">
              {{ slotProps.data.row.original.status.name }}
            </HawkBadge>
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #number="slotProps">
          {{ slotProps.data.row.original.number || '-' }}
        </template>
        <template #updated_at="slotProps">
          <span class="gray-600 font-normal">
            {{ $date(slotProps.data.row.original.updated_at, 'L_DATETIME_MED') }}
          </span>
        </template>
        <template #sharing="slotProps">
          <HawkMembers :members="slotProps.data.row.original.members" type="badge" is_fixed />
        </template>
        <template #shared_by="slotProps">
          <HawkMembers :members="slotProps.data.row.original.shared_data?.shared_by?.uid" type="badge" is_fixed />
        </template>
        <template #shared_at="slotProps">
          <span class="gray-600 font-normal">
            {{ $date(slotProps.data.row.original.shared_data?.shared_at, 'L_DATETIME_MED') }}
          </span>
        </template>
        <template #progress="slotProps">
          <div v-if="slotProps.data.row.original.status_weightage" class="flex rounded-md w-40 h-2 overflow-hidden brightness-125">
            <div
              v-for="status in slotProps.data.row.original.status_weightage"
              :key="status.name"
              v-tippy="{ content: h('div', { class: 'flex items-center' }, [h('span', { class: 'w-4 h-4 mr-2 rounded-full', style: `background-color:${status.color}` }), h('span', `${status.name}: ${parseFloat(status.value).toFixed(2)}%`)]) }"
              class="h-full"
              :style="`width:${status.value}%;background-color:${status.color}`"
            />
          </div>
        </template>
        <template #weightage="slotProps">
          <div v-if="slotProps.data.row.original.weightage" class="flex rounded-md w-40 h-4 overflow-hidden brightness-125">
            {{ isNaN(slotProps.data.row.original.weightage) ? '-' : `${parseFloat(slotProps.data.row.original.weightage || 0).toFixed(2)}%` }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #context_menu="item">
          <slot name="context_menu" :menu_item="item?.data?.row?.original">
            <DocumentContextMenu
              :item="item?.data?.row?.original"
              :element="element"
              :is_widget="is_widget"
              :active_tab="active_tab"
              :options="options"
              @click.stop=""
              @open="freeze_table = item?.data?.row?.id"
              @close="freeze_table = '-1'"
              @download="onItemDownload"
              @archive="(data) => document_bulk_actions.itemArchive(data)"
              @delete="(data) => document_crud.itemDelete(data)"
              @rename="(data) => document_actions.itemRename(data)"
              @share="(data) => document_bulk_actions.itemShare(data)"
              @versions="(data) => document_actions.openVersions(data)"
              @transfer-ownership="(data) => document_actions.openTransferOwnership(data)"
              @autonumbering="(data) => document_actions.openAutoNumbering(data)"
              @create-task="(data) => document_actions.openCreateTask(data)"
              @create-form="(data) => document_actions.openCreateForm(data)"
              @move="(data) => document_bulk_actions.itemMove(data)"
              @edit="(data) => document_actions.openCustomFieldModal(data?.item)"
              @restore="(data) => document_bulk_actions.itemRestore(data)"
              @integrate="(data) => document_bulk_actions.itemIntegrate(data)"
              @open-context="() => onOpenContext(item?.data?.row?.original)"
              @weightage="(data) => document_actions.openAssignWeightage(data)"
              @export="startExporting"
            >
              <template #trigger>
                <IconHawkDotsVertical />
              </template>
            </DocumentContextMenu>
          </slot>
        </template>
        <template #group-icon-close>
          <IconHawkMinusCircle class="text-gray-600 h-4.5 w-4.5" />
        </template>
        <template #group-icon-open>
          <IconHawkPlusCircle class="text-gray-600 h-4.5 w-4.5" />
        </template>
        <template #group-label="{ data }">
          <span class="text-gray-900 text-sm font-semibold">
            {{ data.value }}
          </span>
        </template>
        <template #noData>
          <HawkIllustrations v-if="state.has_filter_applied" type="no-results" for="files" />
          <HawkIllustrations v-else type="no-data" for="files" />
        </template>
      </HawkTable>
    </component>
  </div>
  <DocumentDetailsSlider
    v-if="file_details"
    :item="file_details || {}"
    :options="options"
    :active_tab="active_tab"
    :source="source"
    :is_loading="state.is_details_loading"
    @close="onSidebarClose"
    @attachment-click="showAttachment"
  />
</template>
