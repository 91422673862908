<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import FormStatus from '~/forms/atoms/form-status.vue';
import { useFormsStore } from '~/forms/store/forms.store';

import { listViewDateFormatter } from '~/tasks/composables/view-helpers.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  store_key: {
    type: String,
    default: 'forms',
  },
  extra_query: {
    type: Object,
    default: () => {},
  },
  wrapper_class: {
    type: String,
    default: '',
  },
  height: {},
  options: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['updatePrintMap', 'loading']);
const authStore = useAuthStore();
const dashboard_store = useDashboardStore();
const dashboard_forms_store = useDashboardFormsStore();

const { forms_v2_filters } = storeToRefs(dashboard_forms_store);
const route = useRoute();
const router = useRouter();
const current_organization = authStore.current_organization;
const is_loading = ref(false);
const prevent_watcher = ref(false);
const forceUpdate = ref(0);
const hawk_infinite_list = ref(null);

const list_page_number = ref(0);
const q = ref('');
const forms_store = useFormsStore(props.id ? `forms-${props.id}` : props.store_key);

function openFormDetails(item) {
  router.push({ query: { ...route.query, form: btoa(JSON.stringify({ form_uid: item.uid, store_key: props.id ? `forms-${props.id}` : props.store_key })) } });
}

async function getData({ pagination_state = { pageIndex: 1, pageSize: 25 } }, append = false) {
  is_loading.value = true;
  if (!append) {
    list_page_number.value = 1;
    hawk_infinite_list.value?.scrollToTop();
  }
  if (pagination_state?.pageIndex === 1)
    emit('loading', true);
  const query = {
    page_number: pagination_state?.pageIndex || 1,
    page_size: pagination_state?.pageSize || 25,
    asset_uid: props.data?.data?.asset_id || [],
    organization: current_organization.uid,
    all_access: true,
    ...(dashboard_store.scope !== 'global' && dashboard_store.scope !== 'organization'
      ? {
          parent_form_uid: props.data?.data?.form,
        }
      : {}),
    scope: props.data?.data?.scope ? 'user' : 'organization',
    is_child: true,

    ...(props.extra_query
      || {
        only_metrics: false,
        status: 'published',
        advanced_filters: btoa(JSON.stringify({ formFilters: forms_v2_filters.value })),
      }),
    ...(
      Object.values(props?.data?.data?.filters?.filters || {}).length
        ? props?.data?.data?.filters?.filters
        : {}
    ),
    q: q.value,
    ...(props.options?.query || {}),
  };

  await forms_store.set_forms({ query }, append);
  is_loading.value = false;
  emit('loading', false);
}

async function getNextForms() {
  if (forms_store.total_form_count > forms_store.forms?.length) {
    await getData({
      pagination_state: { pageIndex: ++list_page_number.value },
    }, true);
  }
}

watch(() => props?.data, async (new_val, old_val) => {
  if (!old_val || (!isEqual(new_val, old_val))) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getData({});
    forceUpdate.value++;
    if (props.id !== 'preview')
      emit('updatePrintMap');
  }
}, { immediate: true }, { deep: true });

defineExpose({
  getData,
  q,
});
</script>

<template>
  <div :class="wrapper_class">
    <div v-if="!forms_store.forms?.length">
      <HawkIllustrations v-if="options?.show_no_data" variant="mini" type="no-data" for="forms" />
      <div
        v-else-if="options.show_no_data_text" class="text-sm font-semiBold w-full"
        :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'"
      >
        {{ $t('No data present') }}
      </div>
    </div>
    <HawkInfiniteList
      v-else
      :id="`infinite-${id}`"
      ref="hawk_infinite_list"
      container_class="!overflow-y-auto overflow-x-hidden"
      :list="forms_store.forms"
      :load_more="getNextForms"
      :style="`max-height:${height}px`"
    >
      <template #item="{ item, index }">
        <div
          class="flex py-2 px-2 cursor-pointer group hover:bg-gray-50"
          @click="openFormDetails(item)"
        >
          <div class="flex-auto">
            <div class="flex justify-between mb-2">
              <div class="text-sm font-medium break-all">
                {{ item.name }}
              </div>
              <div class="flex gap-1 flex-shrink-0">
                <div>
                  <span
                    v-if="item.due_date"
                    class="text-xs whitespace-nowrap py-0.5 px-2 font-medium"
                    :class="[item.due_date && new Date(item.due_date) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']"
                  >
                    {{ listViewDateFormatter({ date: item.due_date }) }}
                  </span>
                </div>
                <FormStatus
                  :form="item"
                  custom_class="content-center text-sm rounded-full px-2 py-1 inline-flex text-sm whitespace-nowrap font-medium"
                />
              </div>
            </div>
            <div class="flex justify-between mb-1">
              <div class="flex items-center gap-1">
                <HawkBadge v-if="item.category">
                  <HawkCategoryName :key="item.category" :uid="item.category" class="text-xs" />
                </HawkBadge>
              </div>
              <div v-if="item.assignees?.length" class="flex gap-1 text-xs items-center">
                <HawkMembers :members="item.assignees" type="group" size="xs" popover_position="right" />
              </div>
            </div>
          </div>
          <div class="flex items-center invisible group-hover:visible ml-3">
            <slot name="context_menu" :item="item" :index="index" />
          </div>
        </div>
        <hr v-if="index !== forms_store?.forms?.length - 1" class="my-2">
      </template>
    </HawkInfiniteList>
  </div>
</template>
