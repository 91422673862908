import { cloneDeep } from 'lodash-es';
import { computed, inject } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkFieldsSelector from '~/common/components/organisms/hawk-fields-selector/hawk-fields-selector.vue';
import FamTabsList from '~/forms-as-module/components/fam-tabs-list/fam-tabs-list.vue';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';
import { useFamViewStore } from '~/forms-as-module/store/fam-view.store.js';

export function useFamCustomView(meta = {}) {
  const $t = inject('$t');
  const fam_view_store = useFamViewStore(meta.fam_view_store_id || meta.feature, meta);

  const { parseRulesDateData } = useFamConstants();

  const views = computed(() => fam_view_store.views_list);
  const active_view = computed(() => fam_view_store.active_view);
  const active_view_id = computed(() => fam_view_store.active_view_id);
  const display_filters = computed(() => fam_view_store.display_filters);
  const active_view_columns = computed(() => fam_view_store.active_view_columns);
  const active_view_conditional_formatting = computed(() => fam_view_store.active_view_conditional_formatting);
  const active_view_conditional_formatting_map = computed(() => fam_view_store.active_view_conditional_formatting_map);
  const gallery_columns = computed(() => fam_view_store.fields_supported_type_map.gallery_columns);
  const gallery_filters = computed(() => fam_view_store.fields_supported_type_map.gallery_filters);
  const columns = computed(() => fam_view_store.fields_supported_type_map.columns);
  const filters = computed(() => fam_view_store.fields_supported_type_map.filters);

  function setCustomViews(views) {
    fam_view_store.views = cloneDeep(views || []);
  }
  function getConfigureTabsProperties(is_dashboard = false) {
    return {
      texts: {
        heading: $t('Configure tabs'),
        create_cta: $t('New tab'),
      },
      is_filters_required: false,
      min_items_limit: 1,
      get_default_name: () => fam_view_store.getDefaultViewName(),
      get_items: () => {
        return (views.value || []).map(view => ({ ...view.data, uid: view.uid }));
      },
      columns: fam_view_store.filters_supported_fields_list,
      create_item: async (data) => {
        await fam_view_store.createView({ data });
      },
      update_item: async (data) => {
        await fam_view_store.updateView({
          uid: data.uid,
          name: data.name,
          data,
        });
      },
      delete_item: async (data) => {
        await fam_view_store.deleteView(data.uid);
      },
      handleColumnsConfiguration: (uid) => {
        setActiveViewID(uid);
        handleColumnsConfiguration();
      },
      handleConditionalFormatting: (uid) => {
        setActiveViewID(uid);
        handleConditionalFormatting();
      },
      ...(!is_dashboard && { context_menu: ['edit', 'delete'] }),
    };
  }
  function handleConfigureTabs() {
    const modal = useModal({
      component: FamTabsList,
      attrs: {
        ...getConfigureTabsProperties(),
        onClose() {
          modal.close();
        },
      },
    });
    modal.open();
  }
  async function handleColumnResize(_resized_column, columns_widths, columns) {
    await fam_view_store.updateView(
      {
        uid: active_view.value?.uid,
        data: {
          columns: columns.map(item => ({ field: item.id, default: null, size: columns_widths[item.id].size })),
          display_filters: display_filters.value.map(item => ({ field: item.uid, default: null })),
        },
      },
    );
  }
  function handleColumnsConfiguration() {
    const configure_filters_popup = useModal({
      component: HawkFieldsSelector,
      attrs: {
        items: columns.value,
        selected_items: active_view_columns.value,
        max_selected_items: 20,
        onClose() {
          configure_filters_popup.close();
        },
        texts: {
          heading: $t('Customize columns'),
          left_heading: $t('Hidden Columns'),
          right_heading: $t('Visible Columns'),
        },
        emit_save_on_change: false,
        update: async (data) => {
          await fam_view_store.updateView(
            {
              uid: active_view.value?.uid,
              data: {
                columns: data.map(item => ({ field: item.uid, default: null, size: item.size })),
                display_filters: display_filters.value.map(item => ({ field: item.uid, default: null })),
              },
              order_index: data.order_index,
            },
          );
        },
      },
    });
    configure_filters_popup.open();
  }
  function handleDisplayFilterConfiguration(onUpdateCallback) {
    const configure_filters_popup = useModal({
      component: HawkFieldsSelector,
      attrs: {
        items: filters.value,
        selected_items: display_filters.value,
        max_selected_items: 10,
        onClose() {
          configure_filters_popup.close();
        },
        texts: {
          heading: $t('Choose fields to filter'),
          left_heading: $t('Available filters'),
          right_heading: $t('Displayed filters'),
        },
        emit_save_on_change: false,
        update: async (data) => {
          await fam_view_store.updateView(
            {
              uid: active_view.value?.uid,
              data: {
                display_filters: data.map(item => ({ field: item.uid, default: null })),
                columns: active_view_columns.value.map(item => ({ field: item.uid, default: null, size: item.size })),
              },
            },
          );
          onUpdateCallback?.();
        },
      },
    });
    configure_filters_popup.open();
  }

  function handleConditionalFormatting() {
    const modal = useModal({
      component: FamTabsList,
      attrs: {
        texts: {
          heading: 'Conditional formatting',
          create_cta: 'Add rule',
        },
        formatting_enabled: true,
        get_items: () => {
          setActiveViewID(active_view_id.value);
          return active_view_conditional_formatting.value;
        },
        context_menu: ['edit', 'delete'],
        columns: fam_view_store.filters_supported_fields_list,
        create_item: async (data) => {
          const conditional_formatting = (active_view?.value?.data?.conditional_formatting || []);
          conditional_formatting.push(data);
          logger.log(active_view?.value, active_view_id.value);
          await fam_view_store.updateView({ uid: active_view_id.value, data: { conditional_formatting } });
        },
        update_item: async (data) => {
          const conditional_formatting = cloneDeep((active_view?.value?.data?.conditional_formatting || []));
          const index = conditional_formatting.findIndex(val => val.uid === data.uid);
          if (index === -1)
            conditional_formatting.push(data);
          else
            conditional_formatting[index] = data;
          if (active_view?.value?.uid)
            await fam_view_store.updateView({ uid: active_view_id.value, data: { conditional_formatting } });
        },
        delete_item: async (data) => {
          const conditional_formatting = (active_view?.value?.data?.conditional_formatting || []).filter(val => val.uid !== data.uid);
          if (active_view?.value?.uid)
            await fam_view_store.updateView({ uid: active_view_id.value, data: { conditional_formatting } });
        },
        onClose() {
          modal.close();
        },
      },
    });
    modal.open();
  }

  function getAdvanceDataFilters(additional_filter_ref, active_tab_details) {
    const advanced_filter = [];
    if (active_tab_details && active_tab_details?.uid !== 'all') {
      advanced_filter.push({
        logic: { type: active_tab_details?.data?.filter?.condition.logic === 'All' ? 'AND' : 'OR' },
        rules: parseRulesDateData(active_tab_details?.data?.filter?.condition?.rules || []),
      });
    }

    const additional_filters = additional_filter_ref?.value || additional_filter_ref;
    if (additional_filters?.filters?.length) {
      advanced_filter.push({
        logic: { type: 'AND' },
        rules: parseRulesDateData(additional_filters.filters || []),
      });
    }
    return advanced_filter;
  }
  function setActiveViewID(uid) {
    fam_view_store.setActiveViewID(uid);
  }
  return {
    handleConfigureTabs,
    handleDisplayFilterConfiguration,
    handleColumnsConfiguration,
    handleConditionalFormatting,
    setActiveViewID,
    active_view,
    display_filters,
    views,
    active_view_conditional_formatting,
    active_view_conditional_formatting_map,
    active_view_id,
    gallery_columns,
    gallery_filters,
    getConfigureTabsProperties,
    getAdvanceDataFilters,
    active_view_columns,
    handleColumnResize,
    setCustomViews,
  };
}
