import { sortBy } from 'lodash-es';
import {
  default_date_field,
  default_dates_field,
  default_operator_field,
  default_select_field,
  default_tags_field,
  default_text_field,
  default_toggle_field,
  empty_form_operator_fields,
} from '~/dashboard/components/filters/composables/fields-config';
import {
  categories_options,
  member_options,
  operator_options,
  time_options,
} from '~/dashboard/components/filters/composables/filters-config';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store';

const form_status_options = [
  ['open', 'Open'],
  ['draft', 'Draft'],
  ['submitted', 'Submitted'],
].map((item) => {
  return {
    value: item[0],
    label: item[1],
  };
});

function form_template_options() {
  const dashboard_store = useDashboardFormsStore();

  return sortBy(dashboard_store.forms_options || [], 'label');
}

const form_operator_options = {
  standard: [
    ['arrayEquals', 'Is'],
    ['arrayNotEquals', 'Is not'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  text: [
    ['equal', 'Equal'],
    ['notEqual', 'Not equal'],
    ['stringContains', 'Contains'],
    ['startsWith', 'Starts with'],
    ['endsWith', 'Ends with'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  long_text: [
    {
      value: 'stringContains',
      label: 'Contains',
    },
  ],
  number: [
    ['numberEquals', 'Equal'],
    ['numberNotEquals', 'Not equal'],
    ['greaterThan', 'Greater than'],
    ['greaterThanInclusive', 'Greater than inclusive'],
    ['lessThan', 'Less than'],
    ['lessThanInclusive', 'Less than inclusive'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  date: [
    ['equal', 'Is'],
    ['earlierThan', 'Earlier than'],
    ['laterThan', 'Later than'],
    ['between', 'Between'],
    ['notBetween', 'Not between'],
    ['this_week', 'This week'],
    ['this_month', 'This month'],
    ['this_quarter', 'This quarter'],
    ['this_year', 'This year'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),

  array: [
    ['equal', 'Equal'],
    ['notEqual', 'Not equal'],
    ['arrayEquals', 'In'],
    ['arrayNotEquals', 'Not in'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),

  label: [
    ['contains', 'Equals'],
    ['doesNotContain', 'Not equals'],
    ['arrayIn', 'In'],
    ['arrayNotIn', 'Not in'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  dropdown: [
    ['equal', 'Equal'],
    ['notEqual', 'Not equal'],
    ['arrayEquals', 'In'],
    ['arrayNotEquals', 'Not in'],
  ].map((item) => {
    return {
      value: item[0],
      label: item[1],
    };
  }),
  attachment: [
    {
      value: 'has attachments',
      label: 'Has attachments',
    },
  ],
};

const form_operator_map = {
  text: 'text',
  url: 'text',
  short_text: 'text',
  long_text: 'long_text',
  phone_number: 'number',
  number: 'number',
  decimal: 'number',
  users: 'array',
  member: 'array',
  dropdown: 'dropdown',
  label: 'label',
  date_time: 'date',
  categories: 'array',
  status: 'array',
  auto_number: 'text',
};

export function getFormsListFilterConfig(name = null) {
  if (!name)
    return {};

  const filter_configuration = {
    submission_status: {
      value: 'submission_status',
      type: 'tag',
      label: 'Status',
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          items: form_status_options,
        },
      ],
    },
    assignees: {
      value: 'assignees',
      type: 'tag',
      label: 'Assignees',
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          items: member_options(),
        },
      ],
    },
    due_date: {
      value: 'due_date',
      type: 'date',
      label: 'Due date',
      operator: {
        ...default_operator_field,
        items: operator_options.date,
        custom_fields: {
          between: [
            {
              ...default_dates_field,
            },
          ],
          ...empty_form_operator_fields,
        },
      },
      default_fields: [
        {
          ...default_date_field,
        },
      ],
    },
    category: {
      value: 'category',
      type: 'tag',
      label: 'Category',
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          search: true,
          items: categories_options(),
        },
      ],
    },
    created_by: {
      value: 'created_by',
      type: 'tag',
      label: 'Created by',
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          items: member_options(),
        },
      ],
    },
    created_at: {
      value: 'created_at',
      type: 'date',
      label: 'Created at',
      operator: {
        ...default_operator_field,
        items: operator_options.date,
        custom_fields: {
          between: [
            {
              ...default_dates_field,
            },
          ],
          ...empty_form_operator_fields,
        },
      },
      default_fields: [
        {
          ...default_date_field,
        },
      ],
    },
    last_submitted_by: {
      value: 'last_submitted_by',
      type: 'tag',
      label: 'Last submitted by',
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          items: member_options(),
        },
      ],
    },
    last_submitted_on: {
      value: 'last_submitted_on',
      type: 'date',
      label: 'Last submitted on',
      operator: {
        ...default_operator_field,
        items: operator_options.date,
        custom_fields: {
          between: [
            {
              ...default_dates_field,
            },
          ],
          ...empty_form_operator_fields,
        },
      },
      default_fields: [
        {
          ...default_date_field,
        },
      ],
    },
    parent_form_uid: {
      value: 'parent_form_uid',
      type: 'tag',
      label: 'Template',
      operator: {
        ...default_operator_field,
      },
      default_fields: [
        {
          ...default_tags_field,
          search: true,
          items: form_template_options(),
        },
      ],
    },
  };

  return filter_configuration[name] || {};
}

function getFormAndSectionMetaData(field) {
  return {
    ...(field.section_id ? { section_id: field.section_id } : {}),
    ...(field.section_name ? { section_name: field.section_name } : {}),
    ...(field.form_block_id ? { form_block_id: field.form_block_id } : {}),
    ...(field.form_block_name ? { form_block_name: field.form_block_name } : {}),
  };
}

export function getFormsFilterConfig(field) {
  const filter = {
    value: field.value,
    label: field.label,
    uid: field.value,
    ...getFormAndSectionMetaData(field),
  };

  if (form_operator_map[field.type]) {
    filter.operator = {};
    filter.operator = {
      ...default_operator_field,
      items: form_operator_options[form_operator_map[field.type]],
    };
  }

  // add vueform fields for each filter type
  // representation-block.vue in the old app

  if (['short_text', 'long_text', 'text', 'url', 'auto_number'].includes(field.type)) {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_text_field,
      },
    ];
  }
  else if (['number', 'decimal', 'phone_number'].includes(field.type)) {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_text_field,
        inputType: 'number',
      },
    ];
  }
  else if (['users', 'member'].includes(field.type)) {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_tags_field,
        items: member_options(),
      },
    ];
  }
  else if (field.type === 'status') {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_tags_field,
        items: form_status_options,
      },
    ];
  }
  else if (field.type === 'categories') {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_tags_field,
        items: categories_options(),
      },
    ];
  }
  else if (field.type === 'date_time' && field.config?.type === 'time') {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_tags_field,
        items: time_options(),
      },
    ];

    filter.operator.custom_fields = { ...empty_form_operator_fields };
  }
  else if (field.type === 'date_time') {
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_dates_field,
      },
    ];

    filter.operator.custom_fields = {};
    filter.operator.custom_fields = {
      equal: [
        {
          ...default_date_field,
        },
      ],
      earlierThan: [
        {
          ...default_date_field,
        },
      ],
      laterThan: [
        {
          ...default_date_field,
        },
      ],
      ...empty_form_operator_fields,
    };
  }
  else if (field.type === 'label') {
    const field_options = field.config.map(i => ({ value: i.name, label: i.name }));

    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_tags_field,
        items: field_options,
      },
    ];
  }
  else if (field.type === 'dropdown') {
    const field_options = field.config.map(i => ({ value: i.uid || i.name, label: i.name }));

    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_select_field,
        items: field_options,
      },
    ];

    filter.operator.custom_fields = {
      arrayEquals: [
        {
          ...default_tags_field,
          items: field_options,
        },
      ],
      arrayNotEquals: [
        {
          ...default_tags_field,
          items: field_options,
        },
      ],
    };
  }
  else if (field.type === 'attachment') {
    filter.operator = {
      type: 'hidden',
      default: 'has attachments',
      component: 'HiddenElement',
      name: 'operator',
    };
    filter.default_fields = [];
    filter.default_fields = [
      {
        ...default_toggle_field,
        label: 'Has attachment',
      },
    ];
  }

  // standard filters have the 2 default array operators
  if (['status', 'assignees', 'createdBy', 'submittedBy', 'category'].includes(filter.value))
    filter.operator.items = form_operator_options.standard;

  return filter;
}
