<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { onKeyStroke } from '@vueuse/core';
import { ref } from 'vue';

const props = defineProps({
  header: {
    type: String,
    default: 'Delete',
  },
  content: {
    type: String,
    default: 'Are you sure you want to delete? This action cannot be undone.',
  },
  confirm: {
    type: Function,
    default: () => {
      return true;
    },
  },
  show_toast: {
    type: Boolean,
    default: false,
  },
  can_delete: {
    type: Boolean,
    required: false,
    default: true,
  },
  match_text: {
    type: String,
    required: false,
  },
  match_text_header: {
    type: String,
    required: false,
  },
  show_match_text: {
    type: Boolean,
    default: true,
  },
  match_text_input_placeholder: {
    type: String,
    required: false,
    default: '',
  },
  button_text: {
    type: String,
    required: false,
    default: 'Delete',
  },
  button_color: {
    type: String,
    required: false,
    default: 'error',
  },
  header_icon: {
    type: Object,
    required: false,
    default: IconHawkTrashOneRedRipple,
  },
  modalOptions: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const emit = defineEmits(['close']);

const $toast = inject('$toast');
const is_loading = ref(false);
async function deleteItems() {
  is_loading.value = true;
  await props.confirm();
  is_loading.value = false;
  if (props.show_toast) {
    $toast({
      text: 'Deleted successfully',
      type: 'success',
    });
  }
}

const form$ = ref(null);
const is_match_text_matching = computed(() => {
  if (!props?.match_text)
    return true;
  const form_data = form$.value?.data;
  return form_data?.match_text_entered === props.match_text;
});

onKeyStroke('Enter', (event) => {
  if (props.can_delete && is_match_text_matching.value) {
    event.preventDefault();
    deleteItems();
  }
});
</script>

<template>
  <hawk-modal-container content_class="w-[400px] rounded-lg" :options="props.modalOptions">
    <div class="flex items-start p-6 justify-between text-lg font-semibold text-gray-800">
      <component :is="header_icon" class="w-12 h-12" />

      <div class="flex items-center justify-center">
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2" @click="emit('close')">
          <IconHawkXClose class="w-6 h-6" />
        </div>
      </div>
    </div>
    <hawk-modal-content>
      <div class="mb-8 -mt-6">
        <p class="font-semibold text-lg mb-1">
          {{ props.header }}
        </p>
        <p v-if="match_text && show_match_text" class="text-gray-900 text-sm font-semibold mt-2 mb-1">
          {{ props.match_text_header || props.match_text }}
        </p>
        <slot name="content">
          <p class="text-gray-700 text-sm">
            {{ props.content }}
          </p>
        </slot>
        <div v-if="match_text" class="mt-3">
          <Vueform ref="form$" size="sm">
            <TextareaElement
              :rows="1"
              name="match_text_entered"
              :placeholder="match_text_input_placeholder"
            />
          </Vueform>
        </div>
      </div>
      <div class="flex justify-end">
        <HawkButton color="primary" type="outlined" class="mr-3" @click="$emit('close')">
          {{ $t('Cancel') }}
        </HawkButton>
        <HawkButton
          :color="button_color"
          :loading="is_loading"
          :disabled="!can_delete || !is_match_text_matching"
          @click="deleteItems"
        >
          {{ button_text }}
        </HawkButton>
      </div>
    </hawk-modal-content>
  </hawk-modal-container>
</template>
