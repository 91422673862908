<script setup>
/*
  options: {
    truncate_config: {
      type: <String: 'fixed', 'adaptive'>,
      style: <String: 'truncate', 'truncateMiddle'>,
      length: <Number>,
    }
    text_wrap: <Boolean>, [default: false]
  }
*/
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});

const { get_asset } = useCommonStore();

const asset_name = computed(() => get_asset(props?.value)?.name || props.options?.fallback || '-');
</script>

<template>
  <div :class="{ 'whitespace-nowrap': !options.text_wrap }">
    <HawkText
      v-if="options.truncate_config?.type"
      :content="asset_name"
      :length="options.truncate_config?.length"
      :type="options.truncate_config?.style || 'truncate'"
    />
    <p v-else>
      {{ asset_name }}
    </p>
  </div>
</template>
