<script setup>
import { debounce, reverse } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import DashboardScheduleActivitiesPopup from '~/dashboard/components/schedule-activities/dashboard-schedule-activities-popup.vue';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

const props = defineProps({
  widgetType: {
    type: String,
  },
});

const emit = defineEmits(['update']);

const dashboard_schedule_store = useDashboardScheduleStore();

const {
  active_schedule,
  schedule_configuration,
} = storeToRefs(dashboard_schedule_store);

const {
  set_schedule_details,
} = dashboard_schedule_store;

const selected_activities = ref({
  list: [],
});

const form$ = ref(null);
const activity_parents = ref([]);
const is_loading = ref(false);

const activities_modal = useModal({
  component: DashboardScheduleActivitiesPopup,
  attrs: {
    onClose() {
      activities_modal.close();
    },
    onSave(data) {
      updateSelectedActivities(data);
      activities_modal.close();
    },
  },
});

function updateSelectedActivities(data) {
  selected_activities.value.list = [
    ...data,
  ];
  emitUpdate();
}

function emitUpdate() {
  form$.value.validate();
  const is_valid = !form$.value.hasErrors;
  if (is_valid)
    emit('update', selected_activities.value.list);
  else
    emit('update', []);
}

async function getActivities() {
  if (active_schedule?.value?.uid) {
    try {
      is_loading.value = true;
      const data = await set_schedule_details(active_schedule.value.uid);
      const gantt = window.gantt;
      await gantt.clearAll();
      await gantt.parse(data);
      is_loading.value = false;
    }
    catch {
      is_loading.value = false;
    }
  }
}

function getAncestors(index) {
  const activity = selected_activities.value?.list?.[index] || null;
  if (!activity)
    return;

  const parents = [];
  const gantt = window.gantt;
  gantt.eachParent((task) => {
    parents.push({
      value: task.uid,
      label: task.name,
      id: task.id,
      has_children: true,
    });
  }, activity.id);
  activity_parents.value = reverse(parents);

  return parents;
}

function updateActivityWithParent(parent, index) {
  selected_activities.value.list[index].label = parent.label;
  emitUpdate();
}

const updateActivityName = debounce((data, index) => {
  selected_activities.value.list[index].label = data.target.value;
  emitUpdate();
}, 300);

watch(active_schedule, (new_val, old_val) => {
  // if there is no old value, it means it's on initial load
  if (new_val && new_val?.uid !== old_val?.uid) {
    getActivities();
    if (old_val)
      selected_activities.value.list = [];
  }
}, { immediate: true });

function onFormMounted() {
  if (schedule_configuration.value?.activities?.length)
    selected_activities.value.list = schedule_configuration.value.activities;
};
</script>

<template>
  <div>
    <div class="text-sm text-gray-700 font-medium mb-4 flex">
      {{ $t('Activities') }}<span v-if="props.widgetType !== 's_curve'" class="text-amber-600">*</span> <HawkLoader v-if="is_loading" container_class="pl-2" :width="5" :height="5" />
    </div>

    <Vueform
      ref="form$"
      v-model="selected_activities"
      size="sm"
      sync
      :class="{ 'mb-4': selected_activities.list?.length }"
      @mounted="onFormMounted"
    >
      <ListElement
        name="list"
        :sort="true"
        :initial="0"
        :controls="{ add: false, remove: true, sort: true }"
        @sort="emitUpdate"
        @remove="emitUpdate"
      >
        <template #default="{ index }">
          <ObjectElement
            :name="index"
          >
            <div class="col-span-full flex">
              <TextElement
                name="label"
                label=""
                class="w-full"
                :rules="['required']"
                @keyup="updateActivityName($event, index)"
              />
              <HawkMenu
                position="bottom-left"
                class="ml-4"
                additional_dropdown_classes="w-[590px]"
                @close="activity_parents = []"
              >
                <template #trigger>
                  <HawkButton
                    type="outlined"
                    :icon="true"
                    @click="getAncestors(index)"
                  >
                    <IconHawkChevronDown />
                  </HawkButton>
                </template>
                <template #content="{ close }">
                  <div class="w-full">
                    <div class="px-3 py-3 text-sm font-medium border-b border-solid border-b-gray-300">
                      {{ $t('Select activity') }}
                    </div>
                    <div
                      v-for="parent in activity_parents"
                      :key="`parent${parent.uid}`"
                      class="relative px-3 h py-3 text-sm cursor-pointer rounded-lg text-gray-700 hover:bg-gray-50"
                      @click="updateActivityWithParent(parent, index); close()"
                    >
                      {{ parent.label }}
                    </div>
                    <div v-if="!activity_parents.length" class="px-3 py-3 text-sm font-medium">
                      {{ $t(`Activity doesn't have parents`) }}
                    </div>
                  </div>
                </template>
              </HawkMenu>
            </div>
          </ObjectElement>
        </template>
      </ListElement>
    </Vueform>
    <HawkButton
      size="sm"
      type="text"
      class="w-fit"
      :disabled="is_loading"
      @click="activities_modal.open()"
    >
      <IconHawkPlus />
      <span class="ml-1">
        {{ $t('Add activities') }}
      </span>
    </HawkButton>
  </div>
</template>
