<script setup>
import { useRoute, useRouter } from 'vue-router';

// other
import { VirTree } from 'vue-virtual-tree';
import useEmitter from '~/common/composables/useEmitter';

// stores
import { useDocumentStore } from '~/dms/store/document.store.js';

// components
import DocumentTreeItem from '~/dms/components/documents/document-tree-item.vue';

const props = defineProps({
  active_folder: {
    type: Object,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
  sidebar_class: {
    type: String,
    required: false,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  multi: {
    type: Boolean,
    default: false,
  },
  default_checked: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['activeFolderChange', 'selected']);
const route = useRoute();
const router = useRouter();

const emitter = useEmitter();
emitter.on('set-default-expanded', setDefaultExpanded);

const document_store = useDocumentStore();
const state = reactive({
  default_expanded: route.params.asset_id ? [] : ['null'],
});

const virtual_tree = ref(null);

const selected_item = computed(() => {
  return props.active_folder.uid;
});

function handleTreeItemClick(item) {
  // add url support
  router.push({ query: { folder: item.uid } });

  if (props.options?.is_form_workflow) {
    emit('activeFolderChange', item);
    setActiveItemCss();
    return;
  }
  const type = item.type === undefined ? 'folder' : item.type;

  // prevent same data loading
  if (item.uid !== document_store.active_folder_meta.uid)
    emit('activeFolderChange', { uid: item.uid, type });

  // close file detail slider
  document_store.set_show_details(false);
  setActiveItemCss();
}

function onExpandChange() {
  setActiveItemCss();
}

function setActiveItemCss() {
  setTimeout(() => {
    const prev_selected_node = document.querySelector('.vir-tree-node.active-node');
    if (prev_selected_node)
      prev_selected_node.classList.remove('active-node');

    const selected_item = document.querySelector('.selected_branch')?.parentElement?.parentElement;
    if (selected_item)
      selected_item.classList.add('active-node');
  }, 50);
}

function setDefaultExpanded() {
  const local_data = [];
  const active_folder_detail = document_store.active_folder_detail;

  if (props.active_folder.type === 'asset' || active_folder_detail?.asset) {
    const asset_uid = active_folder_detail?.asset || props.active_folder?.uid;
    local_data.push(asset_uid);
  }
  else if (props.active_folder.type === 'organization'
  || (!props.active_folder.asset && props.active_folder.type !== 'files')
  ) {
    local_data.push('null');
  }

  const ancestors = document_store.document_details_map[document_store.active_folder_meta?.uid]?.ancestors || [];
  for (const item of ancestors)
    local_data.push(item.uid);
  if (props.active_folder && !['asset', 'organization', 'files'].includes(props.active_folder.type))
    local_data.push(props.active_folder.uid);

  state.default_expanded = local_data;
  setActiveItemCss();
}
watch(() => props.active_folder, () => {
  setActiveItemCss();
});

function handleMultiSelection() {
  const selected_nodes = virtual_tree.value.getCheckedNodes();
  emit('selected', selected_nodes);
}

onBeforeMount(() => {
  setDefaultExpanded();
});
</script>

<template>
  <div class="overflow-y-scroll scrollbar h-[80vh] w-[314px]" :class="sidebar_class">
    <VirTree
      :key="state.default_expanded?.length"
      ref="virtual_tree"
      :source="data"
      :show-checkbox="multi"
      :default-checked-keys="default_checked"
      :default-selected-key="selected_item"
      :virtual="{ size: 35 }"
      :default-expanded-keys="state.default_expanded"
      @expandChange="item => onExpandChange(item)"
      @checkChange="handleMultiSelection"
    >
      <template #icon="{ loading }">
        <hawk-loader v-if="loading" />
        <div v-else class="text-lg text-gray-500 custom-icons">
          <icon-ic-outline-chevron-right />
        </div>
      </template>
      <template #node="{ node }">
        <DocumentTreeItem v-if="!multi" :tree_item="node" :selected_item="selected_item" @item-click="item => !multi && handleTreeItemClick(item)" />
        <div v-else>
          <p v-tippy="node.name?.length > 20 ? node.name : ''" class="max-w-[200px] truncate text-sm text-gray-700 font-medium px-2 my-[-4px]">
            {{ node.name }}
          </p>
        </div>
      </template>
    </VirTree>
  </div>
</template>

<style scoped lang="scss">
// needed for styling the sidebar
:deep(.vir-tree-node) {
  @apply flex items-center text-gray-700 rounded-lg p-0 mb-1;
  transition: none;
  .node-arrow.expanded {
    margin-bottom: 1px;
  }
  .node-content {
    @apply w-full;
  }
  .node-arrow {
    margin-left: 5px;
    margin-right: 3px;
    width: 20px;
  }
  &:hover {
    background-color: #f9fafb;
    .node-text,
    .node-arrow {
      color: #374151 !important;
    }
    .text-gray-600.text-sm,
    .truncate.text-sm {
      color: #101828
    }
    .mr-3.text-xl {
      color: black
    }

    .text-gray-700.font-medium.files {
      color: #101828;
    }
  }
}

:deep(.vir-tree-node).active-node {
  &:hover {
    background-color: #374151;
    color: white;
    .text-gray-600.text-sm,
    .truncate.text-sm {
      color: white;
    }
    .mr-3.text-xl {
      color: white;
    }
    svg {
      color: white;
    }
    .node-text,
    .node-arrow {
      color: white;
    }
    .custom-icons {
      svg {
        color: white;
      }
    }
    .text-gray-700.font-medium.files {
      color: white;
    }
  }
}

:deep(.node-title) {
  color: unset;
}
</style>

<style lang="scss">
@import url("vue-virtual-tree/style.css");
.active-node {
  background-color: #344054;
  color: white;
  .text-gray-600.text-sm,
  .truncate.text-sm {
    color: white;
  }
  .mr-3.text-xl {
    color: white;
  }
  svg {
    color: white;
  }
  .node-text,
  .node-arrow {
    color: white !important;
  }
  .text-gray-700.font-medium.files {
    color: white;
  }
  .custom-icons {
    svg {
      color: white;
    }
  }
}
</style>
