<script setup>
import { computed } from 'vue';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  uid: {
    type: String,
    default: '',
  },
  asset_class: {
    type: String,
    required: false,
    default: 'text-gray-600',
  },
  length: {
    type: Number,
    default: 20,
  },
  active_classes: {
    type: String,
    default: '',
  },
});

const common_store = useCommonStore();
const asset = computed(() => {
  return common_store.get_asset(props?.uid);
},
);
</script>

<template>
  <div
    class="text-sm"
    :class="[asset_class, active_classes]"
  >
    <HawkText :content="asset && asset.name || ''" :length="length" />
  </div>
</template>
