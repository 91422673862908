<script setup>
import { computed, ref } from 'vue';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

const props = defineProps({
  add_suggestion: {
    type: Function,
    default: () => undefined,
  },
  close: {
    type: Function,
    default: () => undefined,
  },
  nested_suggestions: {
    type: Array,
    default: () => [],
  },
  search_field: {
    type: String,
    default: '',
  },
});

const dms_settings_store = useDMSSettingsStore();

const selected_tree_item = ref(null);

const custom_fields = dms_settings_store.custom_fields.filter(field => !field.deleted && field.active).map(field => ({ ...field, id: field.uid }));

const nested_suggestions_searched = computed(() => custom_fields.filter(suggestion => suggestion.label.toLowerCase().includes(props.search_field.toLowerCase())));

const get_formatted_structure = computed(() => {
  if (nested_suggestions_searched.value.length === 0)
    return [];
  return [{
    label: 'Custom fields',
    id: 1,
    uid: 1,
    subRows: nested_suggestions_searched.value,
  }];
});

function handleTreeSelectItem({ item }) {
  if (!item.subRows?.length)
    props.add_suggestion({
      id: item.id,
      label: item.label,
    });
}
</script>

<template>
  <HawkTree
    children_key="subRows"
    value_key="label"
    key_field="uid"
    :items="get_formatted_structure"
    :full_row_click="true"
    :selected_item="selected_tree_item"
    :expand_icon_on_right="false"
    @selectItem="handleTreeSelectItem"
  >
    <template #item="item_data">
      <span class="text-sm font-medium text-gray-700">
        {{ `${item_data.data.label}` }}
      </span>
    </template>
    <template #collapse-icon>
      <IconHawkChevronRight />
    </template>
  </HawkTree>
</template>
