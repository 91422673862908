<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { cloneDeep, orderBy } from 'lodash-es';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import FeatureTypeGroupActions from '~/terra/components/feature-type-groups/feature-type-group-actions.vue';

const props = defineProps({
  type: { type: String, default: 'list' },
  terra_store_name: {
    type: String,
    default: 'terra',
  },
  dropdown_value: {
    type: Object,
    default: null,
  },
  filter_features_type: {
    type: Array,
    default: () => [],
  },
  workflow: {},
});
const emit = defineEmits(['featureTypeToggle', 'select']);

const { getStyles } = useTerraHelperComposable();

const terra_store = useTerraStore(props.terra_store_name);
// data properties
const expanded_groups = ref([]);
const inactiveFeatures = ref([]);
const state = reactive({
  display_all: false,
});

// computed
const last_selected_feature_type = computed(
  () => terra_store.last_selected_feature_type,
);
const ftg = computed(() => {
  if (props?.workflow && last_selected_feature_type.value)
    return orderBy(
      terra_store.ftg,
      fg => fg.uid !== last_selected_feature_type.value.featureTypeGroup,
    );
  return orderBy(terra_store?.ftg || [], ['name']);
});
const sorted_ftg = computed(() => {
  let ftg_ = [];
  if (props?.type === 'list')
    ftg.value.forEach((item) => {
      if (state.display_all || item.featureTypes.some(type => Boolean(terra_store.features_count[type.id])))
        ftg_.push(item);
    });
  else ftg_ = ftg.value.map(item => ({ ...item, featureTypes: item.featureTypes.filter(val => !props.filter_features_type.includes(val.uid)) }));
  if (props.dropdown_value) {
    const group_index = ftg_.findIndex(val => val.uid === props.dropdown_value.featureTypeGroup);
    if (group_index !== -1) {
      const group_val = ftg_[group_index];
      ftg_.splice(group_index, 1);
      ftg_ = [group_val].concat(ftg_);
    }
  }
  return ftg_;
});
const inactive_feature_types = computed(
  () => terra_store.inactive_feature_types,
);

// watcher
watch(ftg, (new_value, old_value) => {
  if (props.type !== 'dropdown' && (!old_value || !old_value.length) && new_value)
    expanded_groups.value = ftg.value.map(item => item.uid);
});
watch(() => props.dropdown_value, (val) => {
  init();
}, { immediate: true });
// methods
function onselect_feature(feature) {
  emit('select', feature);
  terra_store.last_selected_feature_type = feature;
}
async function setInactiveFeatures(feature) {
  emit('featureTypeToggle');
  inactiveFeatures.value = cloneDeep(inactive_feature_types.value);
  const index = inactiveFeatures.value.indexOf(feature.id);
  if (index === -1)
    inactiveFeatures.value.push(feature.id);

  else inactiveFeatures.value.splice(index, 1);
  terra_store.terra_track_events('Classes filtered');
  terra_store.inactive_feature_types = inactiveFeatures.value;
  terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
  terra_store.update_map_features_and_polygon();
}
function expandGroups(group) {
  const index = expanded_groups.value.indexOf(group.uid);
  if (index === -1)
    expanded_groups.value.push(group.uid);
  else expanded_groups.value.splice(index, 1);
}
function isGroupInActive(group) {
  return group.featureTypes.every(item =>
    inactive_feature_types.value.includes(item.id),
  );
}
async function disableAllButOne(feature) {
  terra_store.inactive_feature_types = Object.values(terra_store.feature_types)
    .filter(type => Number(type.id) !== feature.id).map(item => Number(item.id));
  terra_store.terra_track_events('Classes filtered');
  terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
  terra_store.update_map_features_and_polygon();
  terra_store.set_polygon();
}
async function setInactiveGroups(group) {
  const features = group.featureTypes.map(item => item.id);
  emit('featureTypeToggle');
  if (isGroupInActive(group)) {
    const removedArray = inactive_feature_types.value.filter(
      item => !features.includes(item),
    );
    terra_store.inactive_feature_types = removedArray;
  }
  else {
    terra_store.inactive_feature_types = [
      ...inactive_feature_types.value,
      ...features,
    ];
  }
  terra_store.terra_track_events('Classes filtered');
  terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
  terra_store.update_map_features_and_polygon();
  terra_store.set_polygon();
}
function sort(items) {
  return orderBy(items, ['order_index', 'name']);
}
function resetFilters() {
  terra_store.filters_state = {
    ...terra_store.filters_state,
    ordered_keys_map: {},
    key_values_data: {},
    active_filter: null,
  };
  terra_store.update_features_on_map_flag += 1; // trigger watcher in terra filter
}
function init() {
  if (props.type === 'list') {
    expanded_groups.value = ftg.value.map(item => item.uid);
  }
  else {
    expanded_groups.value = [];
    if (props.dropdown_value?.featureTypeGroup)
      expanded_groups.value = [props.dropdown_value.featureTypeGroup];
    else if (sorted_ftg.value.length)
      expanded_groups.value = [sorted_ftg.value[0].uid];
  }
}

init();
</script>

<template>
  <div :class="{ 'h-full scrollbar': type === 'list' }">
    <div v-if="type === 'list'" class="flex justify-between items-center mb-2 p-4">
      <div class="text-md font-semibold">
        {{ $t('Classes') }}
      </div>
      <div class="flex items-center gap-1">
        <HawkMenu
          additional_trigger_classes="ring-gray-100"
          additional_dropdown_classes="-ml-[140px] w-[180px]"
        >
          <template #content>
            <div class="p-2">
              <HawkCheckbox id="display_all" :model-value="state.display_all" @input="state.display_all = !state.display_all">
                {{ $t('Display all') }}
              </HawkCheckbox>
            </div>
          </template>
        </HawkMenu>
        <FeatureTypeGroupActions v-if="terra_store_name === 'terra' && terra_store.check_permission('modify_featuretypes')" action_type="add-group" :ftg="item" :inactive_feature_types="inactive_feature_types" @click.stop="" />
      </div>
    </div>
    <div :class="type !== 'dropdown' ? 'px-4 pb-4' : ''">
      <div v-for="item in sorted_ftg.filter((val) => (!isGroupInActive(val) && type === 'dropdown') || type !== 'dropdown')" :key="item.uid" class="feature-types__list">
        <div
          class="py-2 pr-2.25 flex items-center justify-between hover:bg-gray-50 cursor-pointer group"
          :class="[type === 'dropdown' ? 'pl-2' : 'pl-0']"
          @click="expandGroups(item)"
        >
          <span
            v-if="type === 'list' || type === 'dropdown'"
            class="text-[14px] font-medium text-gray-500 break-all"
            :class="{ 'opacity-50': isGroupInActive(item) }"
          >
            {{ item.name }}
          </span>
          <div class="flex justify-end items-center">
            <template v-if="type === 'list'">
              <FeatureTypeGroupActions v-if="terra_store_name === 'terra' && terra_store.check_permission('modify_featuretypes')" :ftg="item" :inactive_feature_types="inactive_feature_types" class="mx-2 invisible group-hover:visible" @click.stop="" />
              <span
                class="cursor-pointer text-gray-600 text-[16px] mx-2"
                @click.stop="setInactiveGroups(item)"
              >
                <IconHawkEyeTwo v-if="!isGroupInActive(item)" class="w-4.5 h-4.5" />
                <IconHawkEyeOffTwo v-else class="w-4.5 h-4.5" />
              </span>
            </template>
            <span class="text-sm cursor-pointer">
              <IconHawkChevronUp v-if="expanded_groups.includes(item.uid)" />
              <IconHawkChevronDown v-else />
            </span>
          </div>
        </div>
        <div v-if="expanded_groups.includes(item.uid)">
          <div
            v-for="feature in sort(item.featureTypes)"
            :key="feature.id"
            class="py-1.25 pr-2.25 pl-3 group/item hover:bg-gray-50"
          >
            <div
              class="flex justify-between"
              @click="
                type === 'dropdown'
                  ? onselect_feature(feature)
                  : setInactiveFeatures(feature)
              "
            >
              <div class="flex items-center cursor-pointer">
                <FeatureTypeGroupActions
                  v-if="type === 'list' && terra_store_name === 'terra' && terra_store.container?.modify_symbology"
                  action_type="add-group"
                >
                  <template #action_trigger_btn="{ actions }">
                    <div
                      class="w-0 invisible group-hover/item:visible group-hover/item:w-4"
                      @click.stop="actions.openFeature(item, feature, { is_symbology: true })"
                    >
                      <IconHawkPencilOne class="text-gray-500 h-4 w-4" />
                    </div>
                  </template>
                </FeatureTypeGroupActions>

                <div class="w-4 visible" :class="{ 'group-hover/item:invisible group-hover/item:w-0': type === 'list' }">
                  <div
                    class="cursor-pointer w-3.5 h-3.5 rounded-full bg-gray-400"
                    :style="getStyles(feature)"
                  />
                </div>

                <p
                  :class="{
                    'line-through bg-gray-100':
                      inactive_feature_types.includes(feature.id),
                  }"
                  class="is-pointer whitespace-nowrap  text-[14px] font-medium text-gray-800 ml-2"
                >
                  <HawkText :content="feature.name" :length="type === 'dropdown' ? 18 : 20" />
                </p>
              </div>

              <p
                v-if="type === 'list'"
                v-tippy="`Display only ${feature.name}`"
                class=" text-[12px] font-medium text-end text-gray-600 bg-gray-100 rounded-full px-2 py-1 cursor-pointer"
                @click.stop="disableAllButOne(feature)"
              >
                {{ terra_store.features_count[feature.id] || 0 }}
              </p>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div v-if="type === 'list' && terra_store.filters_state.active_filter" class="absolute bottom-0 p-4">
      <HawkAlertBanner>
        <template #icon>
          <HawkFeaturedIcon theme="light-circle-outline" size="sm" class="flex-shrink-0">
            <IconHawkInfoCircle />
          </HawkFeaturedIcon>
        </template>
        <template #content>
          <div class="text-gray-600 font-normal text-sm mb-2">
            {{ $t('Features are colored by properties. Click Reset to color by classes.') }}
          </div>
          <HawkButton type="link" @click="resetFilters()">
            {{ $t('Reset') }}
          </HawkButton>
        </template>
      </HawkAlertBanner>
    </div>
  </div>
</template>
