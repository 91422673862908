<script setup>
import { watchDebounced } from '@vueuse/core';

import { findIndex, intersectionBy, keyBy, omit } from 'lodash-es';
import { onMounted, reactive } from 'vue';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkColumnsSelector from '~/common/components/organisms/hawk-columns-selector.vue';
import { hexToRgb, sleep } from '~/common/utils/common.utils';
import FilterForm from '~/terra/components/filters/filter-form.vue';
import FilterGradientPopup from '~/terra/components/filters/filter-gradient-popup.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

import '@envis/vcolor-picker/dist/style.css';

const filter_selector_modal = useModal({
  component: HawkColumnsSelector,
  attrs: {
    onClose() {
      filter_selector_modal.close();
    },
    texts: {
      heading: 'Filters settings',
      left_heading: 'Available filters',
      right_heading: 'Added filters',
    },
    is_nested: false,
    immediate: false,
    label_field_name: 'name',
  },
});

const filter_gradient_modal = useModal({
  component: FilterGradientPopup,
  attrs: {
    onClose() {
      filter_gradient_modal.close();
    },
  },
});

const { applyGradientColors } = useTerraHelperComposable();

const auth_store = useAuthStore();
const terra_store = useTerraStore();
const state = reactive({
  filter_options: {},
  show_all_map: {},
  toggle_filter_form: {},
  show_filter_loader: null,
  color_update: {},
});
const filters$ = ref(null);
let VColorPicker = null;

const features_hash = computed(() => keyBy(terra_store?.features, item => item.properties.uid));

async function toggleActiveFilter(filter) {
  // toggle color filter trigger save view
  terra_store.show_save_view = true;

  state.color_update = {};
  state.show_filter_loader = filter;
  setTimeout(async () => {
    if (terra_store.filters_state.active_filter && terra_store.filters_state.key_values_data[terra_store.filters_state.active_filter]) {
      Object.keys(terra_store.filters_state.key_values_data[terra_store.filters_state.active_filter]).forEach((key) => {
        terra_store.filters_state.key_values_data[terra_store.filters_state.active_filter][key].color = null;
        terra_store.filters_state.key_values_data[terra_store.filters_state.active_filter][key].line_opacity = 1;
      });
    }
    if (terra_store.filters_state.active_filter === filter) {
      terra_store.filters_state.active_filter = null;
      terra_store.filters_state.gradient_form = null;
    }
    else {
      terra_store.filters_state.active_filter = filter;
      Object.keys(terra_store.filters_state.key_values_data[filter]).forEach((key) => {
        terra_store.filters_state.key_values_data[filter][key].color = getRandomColor();
        terra_store.filters_state.key_values_data[filter][key].line_opacity = 1;
      });
    }
    await featuresProcessing();
    if (terra_store.filters_state.active_filter)
      terra_store.terra_track_events('Colored by property');
    state.show_filter_loader = null;
  }, 500);
}
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++)
    color += letters[Math.floor(Math.random() * 16)];

  return color;
}
function toggleKey(key) {
  const filter = terra_store.filters_state.key_values_data[key];
  const all_selected = !Object.values(filter).every(val => val.selected);
  Object.keys(filter).forEach((value_key) => {
    toggleValue(key, value_key, all_selected);
  });
  terra_store.terra_track_events('Filters applied');
  updateCount();
}

function toggleValue(key, value_key, status = null) {
  // toggle checkbox trigger save view
  terra_store.show_save_view = true;

  const selected = status === null ? !terra_store.filters_state.key_values_data[key][value_key].selected : status;
  terra_store.filters_state.key_values_data[key][value_key].selected = selected;
  if (status === null) {
    updateCount(key, value_key);
    terra_store.terra_track_events('Filters applied');
  }
}
function getFeatures() {
  let features = [];
  const key_index = findIndex(Object.keys(state.filter_options), (key) => {
    for (const value_key in state.filter_options[key]) {
      if (terra_store.filters_state.key_values_data[key][value_key].selected) {
        features = state.filter_options[key][value_key].features;
        return true;
      }
    }
  });
  return { features: key_index === -1 ? terra_store.features : features, key_index };
}
async function featuresProcessing() {
  const features = terra_store.filters_state.features_on_map.map(f => features_hash.value[f.properties.uid]);
  terra_store.filters_state.features_on_map = features;
  terra_store.update_map_features_and_polygon({ features });
  await terra_store.map.once('idle');
}
function updateCount(key, value) {
  const { key_index, features } = getFeatures();
  const selected_features_by_key = {};
  let updates_features_count = terra_store.extra_properties_group_by({ features });
  const persist_features_count = terra_store.extra_properties_group_by();
  Object.keys(state.filter_options).forEach((filter_key, i) => {
    Object.keys(state.filter_options[filter_key]).forEach((value_key) => {
      let update_value = null;
      const current_value = { ...state.filter_options[filter_key][value_key], ...terra_store.filters_state.key_values_data[filter_key][value_key] };
      if (i <= key_index)
        update_value = persist_features_count?.[filter_key]?.[value_key];
      else
        update_value = updates_features_count?.[filter_key]?.[value_key];
      if ((key !== filter_key && value !== value_key) && current_value.selected && !(update_value?.features || []).length)
        current_value.selected = false;
      if (current_value.selected) {
        if (!selected_features_by_key[filter_key])
          selected_features_by_key[filter_key] = [];
        selected_features_by_key[filter_key].push(...(update_value?.features || []));
      }
      state.filter_options[filter_key][value_key] = {
        ...omit(current_value, ['selected', 'color']),
        count: update_value?.count || 0,
      };
      terra_store.filters_state.key_values_data[filter_key][value_key] = omit(current_value, ['features', 'count']);
    });
    // To fix the count for next iteration when multiple blocks are selected
    if (selected_features_by_key[filter_key])
      updates_features_count = terra_store.extra_properties_group_by({ features: selected_features_by_key[filter_key] });
  });
  const updated_features = intersectionBy(...(key_index === -1 ? [features] : Object.values(selected_features_by_key)), (f) => {
    f.properties = features_hash.value[f.properties.uid].properties;
    return f.properties.uid;
  });
  terra_store.filters_state.features_on_map = updated_features;
  terra_store.update_map_features_and_polygon({ features: terra_store.filters_state.features_on_map });
}
async function resetFilters() {
  terra_store.filters_state = {
    ...terra_store.filters_state,
    ordered_keys_map: {},
    key_values_data: {},
    active_filter: null,
  };
  state.color_update = {};
  state.filter_options = terra_store.extra_properties_group_by();
  terra_store.filters_state.features_on_map = terra_store.features;
  terra_store.terra_track_events('Filters reset');
  await featuresProcessing();
}
function openFilterPopup() {
  let keys = Object.keys(terra_store.filters_state.ordered_keys_map);
  if (!keys.length)
    keys = Object.keys(state.filter_options);
  filter_selector_modal.patchOptions({
    attrs: {
      all_columns: terra_store.filters_state.all_keys.map(key => ({ name: key, id: key })),
      active_columns: keys.map(key => ({ name: key, id: key })),
      onSave(event) {
        // For filtering keys trigger save view
        terra_store.show_save_view = true;

        terra_store.filters_state.ordered_keys_map = event.reduce((acc, column, i) => {
          acc[column.id] = i + 1;
          return acc;
        }, {});
        terra_store.terra_track_events('Filter properties changed');
        updateFeaturesCount();
      },
    },
  });
  filter_selector_modal.open();
}
function updateFeaturesCount() {
  const updated_options = {};
  let updated_count = {};
  const updated_filter_options = terra_store.extra_properties_group_by();
  let keys = Object.keys(terra_store.filters_state.ordered_keys_map);
  if (!keys.length)
    keys = Object.keys(updated_filter_options);

  keys.forEach((key) => {
    updated_options[key] = { ...updated_filter_options[key], ...(state.filter_options[key] || {}) };
    if (!updated_count.key) {
      for (const value in updated_options[key]) {
        if (terra_store.filters_state.key_values_data[key][value].selected)
          updated_count = { key, value };
      }
    }
  });
  state.filter_options = updated_options;
  updateCount(updated_count.key, updated_count.value);
}
function getColor(data, type) {
  if (type === 'hex') {
    const [r, g, b] = data.style.match(/\d+/g).map(Number);
    const rgbHex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    return { color: `#${rgbHex}`, line_opacity: data.alpha };
  }
  else {
    const rgb = hexToRgb(data.color || '#000000');
    return {
      red: rgb.r,
      green: rgb.g,
      blue: rgb.b,
      alpha: Number.isNaN(data.line_opacity) ? 1 : data.line_opacity,
    };
  }
}
function getKeyValueData(key, value) {
  return terra_store.filters_state.key_values_data?.[key]?.[value] || {};
}
function showFilterForm(values_object) {
  const values = Object.keys(values_object).map(Number);
  return values.every(val => !Number.isNaN(val));
}
function getValuesObject(key, values_object) {
  if (state.show_all_map[key])
    return values_object;
  const values = Object.entries(values_object);
  return Object.fromEntries(values.slice(0, 5));
}
function toggleFilterForm(key) {
  state.toggle_filter_form[key] = !state.toggle_filter_form[key];
  terra_store.terra_track_events(`Number slider ${state.toggle_filter_form[key] ? 'enabled' : 'disabled'}`);
}
function openGradientPopup(object_key, object_values) {
  filter_gradient_modal.patchOptions({
    attrs: {
      object_key,
      object_values,
      gradient_form_data: terra_store.filters_state.gradient_form,
      on_save: async (form) => {
        // Resetting current active filter
        if (terra_store.filters_state.active_filter && terra_store.filters_state.key_values_data[terra_store.filters_state.active_filter])
          Object.keys(terra_store.filters_state.key_values_data[terra_store.filters_state.active_filter]).forEach(key => terra_store.filters_state.key_values_data[terra_store.filters_state.active_filter][key].color = null);

        terra_store.filters_state.active_filter = object_key;
        terra_store.filters_state.gradient_form = form;
        setGradientColors();

        await featuresProcessing();
        terra_store.show_save_view = true;
        filter_gradient_modal.close();
      },
    },
  });
  filter_gradient_modal.open();
}

function setGradientColors() {
  const object_key = terra_store.filters_state.active_filter;
  const object_values = state.filter_options[object_key];
  // data contains array of value key and respective color
  const data = applyGradientColors(terra_store.filters_state.gradient_form, object_values);
  data.forEach(({ value_keys, color }) => {
    value_keys.forEach((value_key) => {
      terra_store.filters_state.key_values_data[object_key][value_key].color = color;
    });
  });
}

async function loadColorPicker() {
  const { VColorPicker } = await import('@envis/vcolor-picker');
  return VColorPicker;
}

watch(() => terra_store.update_features_on_map_flag, () => {
  state.filter_options = terra_store.extra_properties_group_by();
  if (terra_store.filters_state?.active_filter && terra_store.filters_state?.active_filter === terra_store.filters_state?.gradient_form?.property)
    setGradientColors();
  updateFeaturesCount();
});
watchDebounced(() => terra_store.filters_state.is_mounted && terra_store.filters_state.quick_filter, async (quick_filter) => {
  if (quick_filter) {
    const quick_filter = terra_store.filters_state.quick_filter;
    state.show_all_map[quick_filter.name] = true;
    await sleep(100);
    const element_id = `${quick_filter.name}_${quick_filter.value}`;
    const element = document.getElementById(element_id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      updateFeaturesCount();
      terra_store.filters_state.quick_filter = null;
    }
  }
}, { debounce: 100 });

onMounted(async () => {
  VColorPicker = await loadColorPicker();
  state.filter_options = terra_store.extra_properties_group_by();
  if (!terra_store.filters_state.is_mounted) {
    terra_store.filters_state.features_on_map = terra_store.features;
    terra_store.filters_state.is_mounted = true;
  }
});
</script>

<template>
  <div ref="filters$" class="h-full overflow-auto scrollbar p-4">
    <div class="flex items-center justify-between mb-8">
      <div class="text-base font-semibold">
        {{ $t('Filters') }}
      </div>
      <div class="flex items-center">
        <div class="cursor-pointer mx-1" @click="resetFilters()">
          <IconHawkReverseLeft class="w-5 h-5 text-gray-600" />
        </div>
        <div class="cursor-pointer ml-1" @click="openFilterPopup()">
          <IconHawkSettingsOne class="w-5 h-5 text-gray-600" />
        </div>
      </div>
    </div>
    <div v-for="(object_value, object_key, i) in state.filter_options" :key="i">
      <div class="flex items-center justify-between my-1">
        <div class="flex items-center">
          <div>
            <HawkCheckbox :id="object_key" :model-value="Object.values(object_value).length !== 0 && Object.keys(object_value).every(val => getKeyValueData(object_key, val).selected)" @input.stop="toggleKey(object_key)" />
          </div>
          <div class="text-sm font-semibold pt-0.5">
            <HawkText :content="object_key" />
            <span class="text-gray-600 font-normal">
              ({{ Object.keys(object_value).filter(val => getKeyValueData(object_key, val).selected).length }} {{ $t('of') }} {{ Object.values(object_value).length }} {{ $t('selected') }})
            </span>
          </div>
        </div>
        <div class="flex items-center">
          <div v-if="!state.toggle_filter_form[object_key]" class="cursor-pointer ml-2" @click="toggleActiveFilter(object_key)">
            <HawkLoader v-if="state.show_filter_loader === object_key" container_class="m-0" :height="4" :width="4" />
            <IconHawkPaintPour v-else class="w-4 h-4" :class="[terra_store.filters_state.active_filter === object_key ? 'text-primary-500 fill-primary-200' : 'text-gray-600']" />
          </div>
          <div v-if="showFilterForm(object_value)" class="cursor-pointer ml-2" @click="openGradientPopup(object_key, object_value)">
            <IconHawkSettingsOne class="w-4 h-4 text-gray-600" />
          </div>
          <div v-if="auth_store.check_split('terra_numerical_slider') && showFilterForm(object_value)" class="cursor-pointer ml-2" @click="toggleFilterForm(object_key)">
            <IconHawkToggleThreeLeft v-if="!state.toggle_filter_form[object_key]" />
            <IconHawkToggleThreeRight v-else class="text-primary-700" />
          </div>
        </div>
      </div>

      <FilterForm
        v-if="state.toggle_filter_form[object_key]"
        :object_key="object_key"
        :values_object="object_value"
        @update-count="updateCount()"
      />
      <div v-else>
        <div v-for="(value, key) in getValuesObject(object_key, object_value)" :id="`${object_key}_${key}`" :key="key">
          <div class="flex items-center justify-between my-1">
            <div class="flex items-center">
              <div>
                <HawkCheckbox :id="`${object_key}-${key}`" :model-value="getKeyValueData(object_key, key).selected" @input.stop="toggleValue(object_key, key)" />
              </div>
              <div class="text-sm text-gray-700 pt-0.5">
                <HawkText :content="key" />
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-xs">
                {{ value.count }}
              </div>
              <HawkMenu
                :has_bordered_trigger="false"
                :additional_trigger_classes="`!ring-0 p-0 ml-1 mt-1.5 ${terra_store.filters_state.active_filter === object_key ? 'cursor-pointer' : 'pointer-events-none'}`"
                additional_dropdown_classes="!h-auto !w-auto !ml-6 rounded"
                position="fixed"
                @open="state.color_update[`${object_key}-${key}-fill`] = value.color"
                @close="state.color_update[`${object_key}-${key}-fill`] = null"
              >
                <template #trigger>
                  <div class="flex items-center">
                    <div
                      class="w-4 h-4 rounded-full"
                      :class="{ 'border border-gray-300': !getKeyValueData(object_key, key).color }"
                      :style="{ backgroundColor: getKeyValueData(object_key, key).color || '#fff' }"
                    />
                  </div>
                </template>
                <template #content="{ close }">
                  <div class="p-3">
                    <div class="flex items-center justify-end mb-2">
                      <IconHawkXClose class="w-5 h-5 text-gray-900 cursor-pointer" @click="close()" />
                    </div>
                    <VColorPicker
                      class="text-sm font-normal"
                      :color="getColor(getKeyValueData(object_key, key), 'rgba')"
                      :on-start-change="color => state.color_update[`${object_key}-${key}-fill`] = getColor(color, 'hex')"
                      :on-end-change="color => state.color_update[`${object_key}-${key}-fill`] = getColor(color, 'hex')"
                      :on-change="color => state.color_update[`${object_key}-${key}-fill`] = getColor(color, 'hex')"
                      :history-enabled="false"
                      :preset-enabled="false"
                      :eye-dropper-enabled="false"
                    />
                    <div class="flex items-center justify-end">
                      <IconHawkCheck
                        class="text-primary-700 w-6 h-6 cursor-pointer"
                        @click="() => {
                          terra_store.filters_state.key_values_data[object_key][key].color = state.color_update[`${object_key}-${key}-fill`].color;
                          terra_store.filters_state.key_values_data[object_key][key].line_opacity = state.color_update[`${object_key}-${key}-fill`].line_opacity;
                          featuresProcessing();
                          terra_store.show_save_view = true;
                          close();
                        }"
                      />
                    </div>
                  </div>
                </template>
              </HawkMenu>
            </div>
          </div>
        </div>
        <HawkButton v-if="Object.keys(object_value).length > 5" size="xs" type="link" class="ml-6 !pb-0 border-0" @click="state.show_all_map[object_key] = !state.show_all_map[object_key]">
          {{ state.show_all_map[object_key] ? $t('See less') : $t('See all') }}
        </HawkButton>
      </div>

      <div class="my-3 border" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.ui-color-picker) {
  margin: 0px;
  width: 224px;
  .picker-area, .color-preview-area {
    padding: 0px;
  }
  .rgb-input-group {
    margin-top: 8px;
    .input-field {
      flex-direction: column-reverse;
    }
  }
  .input {
    background-color: #fff;
  }
}
</style>
