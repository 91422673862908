<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import dompurify from 'dompurify';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';

const props = defineProps({
  comment_type: {
    type: String,
    required: true,
  },
  enable_editor: {
    type: Boolean,
    required: true,
  },
  comment_text: {
    type: String,
  },
  module: {
    type: String,
    default: 'plans',
  },
  autofocus: {
    type: Boolean,
    default: true,
  },
  has_custom_mentions: {
    type: Boolean,
    default: false,
  },
  custom_mentions: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['input', 'initialized', 'blur', 'focus', 'send', 'delete', 'reply', 'close']);

const route = useRoute();

const { logged_in_user_details } = useAuthStore();
const { scope_users, get_user } = useCommonStore();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];

const wysiwyg_ctx = ref(null);
const viewer_container_elem = props.module === 'plans' ? inject('viewer-container-elem') : null;

// All users
const mention_items = computed(() => {
  const mention_obj = (user) => {
    let display_name = user.email ? user.email?.split('@')[0] : '';
    if (user.first_name)
      display_name = `${user.first_name} ${user.last_name || ''}`;

    return {
      bg_color: stringToNumber(user.first_name || user.email),
      label: dompurify.sanitize(display_name.trim(), { ALLOWED_TAGS: [] }),
      id: user.uid,
      avatar: user?.display_picture ?? '',
      email: dompurify.sanitize(user.email, { ALLOWED_TAGS: [] }),
    };
  };

  const result = scope_users(route?.params?.asset_id)
    .filter(user => user.uid !== logged_in_user_details.user_id)
    .map(mention_obj);

  const custom_mentions_list = props.custom_mentions
    .filter(user_id => user_id !== logged_in_user_details.user_id)
    .map((user_id) => {
      const user = get_user(user_id);
      return mention_obj(user);
    });

  return props.has_custom_mentions ? custom_mentions_list : result;
});

function stringToNumber(string) {
  let total = 0;
  for (const char of string)
    total += char.charCodeAt(0);

  return color_set[total % color_set.length];
}

function onInitialized(ctx) {
  wysiwyg_ctx.value = ctx;
  emit('initialized', ctx);
}

function onInput(input) {
  emit('input', input);
}

function onSend() {
  emit('send');
  wysiwyg_ctx.value.setEditorContent('');
}

function sanitizeHtml(html_string) {
  return dompurify.sanitize(html_string);
}
</script>

<template>
  <div>
    <slot name="comment" />
    <div v-if="enable_editor">
      <!-- Comment input box -->
      <HawkWysiwygEditorComponent
        :key="comment_text"
        :autofocus="autofocus"
        :plugins="['mentions']"
        :placeholder_text="comment_type === 'reply' ? $t('Type your reply') : $t('Type something to comment')"
        :mention_items="mention_items"
        :initial_content="comment_text"
        :editor_enabled="true"
        :append_mentions_to="viewer_container_elem"
        class="border rounded-md"
        editor_classes="min-h-[44px] py-3 mb-1"
        @input="onInput"
        @ctrlEnter="onSend"
        @initialized="onInitialized"
      />
    </div>
    <div v-else>
      <div class="px-2 ml-9 mt-3 text-sm" v-html="sanitizeHtml(comment_text)" />
    </div>
  </div>
</template>
