<script setup>
// --------------------------------- Imports -------------------------------- //
import TextIcon from '~icons/hawk/file-five';
import ToggleIcon from '~icons/hawk/toggle-three-right';
import NumberIcon from '~icons/hawk/number';
import DateIcon from '~icons/hawk/calendar';
import MoneyIcon from '~icons/hawk/coins-stacked-two';
import DropdownIcon from '~icons/hawk/chevron-down';
import CheckboxIcon from '~icons/hawk/check-square';
import RadioIcon from '~icons/hawk/checkbox-base';
import RichTextIcon from '~icons/hawk/file-two';
import EmailIcon from '~icons/hawk/mail-three';
import PhoneIcon from '~icons/hawk/phone';
import GlobeIcon from '~icons/hawk/globe-two';
import FileUploadIcon from '~icons/hawk/upload-one';
import FilePlusTwo from '~icons/hawk/file-plus-two';
import MembersIcon from '~icons/hawk/users-one';
import SignatureIcon from '~icons/hawk/signature';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  update: {
    type: Function,
    require: true,
  },
  selected_items: {
    type: Array,
    required: true,
  },
});
// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['addColumn', 'close', 'editColumn']);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const field_type_icon_map = {
  'short_text': TextIcon,
  'number': NumberIcon,
  'long_text': RichTextIcon,
  'yes-no': ToggleIcon,
  'checkbox': CheckboxIcon,
  'radio': RadioIcon,
  'dropdown': DropdownIcon,
  'members': MembersIcon,
  'attachment': FileUploadIcon,
  'date_time': DateIcon,
  'email': EmailIcon,
  'phone': PhoneIcon,
  'money': MoneyIcon,
  'signature': SignatureIcon,
  'url': GlobeIcon,
  'multi_text': FilePlusTwo,
  'formula': DateIcon,
};

// ------------------------ Variables - Local - refs ------------------------ //

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //

// -------------------------------- Functions ------------------------------- //

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <HawkFieldsSelector
    :texts="{
      heading: $t('Configure columns'),
    }"
    :selected_items="selected_items"
    :is_nested="false"
    :immediate="false"
    :hidden_features="['arrow-left', 'arrow-right', 'left-search', 'right-search', 'clear-all', 'left-column']"
    :update="update"
    @close="emit('close')"
  >
    <template #right_item="{ label, is_selected, item }">
      <div
        class="p-1 text-sm font-medium flex items-center justify-between text-gray-700 cursor-pointer hover:bg-gray-50 group"
        :class="{
          'bg-gray-100 hover:!bg-gray-100 selected-active-field': is_selected,
        }"
      >
        <div class="flex gap-2 items-center">
          <component :is="field_type_icon_map[item?.config?.type === 'formula' ? item.config.type : item.properties.type]" class="w-5" />
          <HawkText :content="label" :length="25" />
        </div>
        <HawkButton class="invisible group-hover:visible" icon type="plain" @click="() => emit('editColumn', item)">
          <IconHawkEditTwo class="w-5" />
        </HawkButton>
      </div>
    </template>
    <template #additional_content>
      <HawkButton class="mt-3" type="text" @click="() => emit('addColumn')">
        <IconHawkPlus />
        {{ $t('New column') }}
      </HawkButton>
    </template>
  </HawkFieldsSelector>
</template>
