<script setup>
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { uniq } from 'lodash-es';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { currencies } from '~/common/utils/constants.ts';

const props = defineProps({
  operation_type: {
    type: String,
    default: 'create',
  },
  field_name: {
    type: String,
    default: '',
  },
  field_type: {
    type: String,
    default: '',
  },
  field_value: {
    required: false,
    default: null,
  },
  config: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');

const project_management_store = useProjectManagementStore();
const { active_task, active_schedule, is_fullscreen, active_view } = storeToRefs(project_management_store);
const { create_update_or_delete_custom_field, add_or_update_custom_field_value, modify_config } = project_management_store;

dayjs.extend(customParseFormat);

const value_data = computed(() => {
  if (props.field_type === 'date')
    if (props.field_value)
      return dayjs(props.field_value, 'YYYY-MM-DD').toDate();
    else return null;

  return props.field_value;
});

const state = reactive({
  field_data: {
    name: props.field_name,
    type: props.field_type,
    value: value_data.value,
    currency: props.config?.currency?.code,
  },
  is_loading: false,
});

const currency_items = computed(() => Object.values(currencies).map((c) => {
  return {
    ...c,
    label: c.name,
    value: c.code,
  };
}));
const custom_field_names = computed(() => Object.keys(active_schedule.value?.custom_fields || {}));
const fieldname_already_exists = computed(() => state.field_data.name !== props.field_name && custom_field_names.value.includes(state.field_data.name));
const has_currency = computed(() => (
  (props.operation_type === 'update' && props?.config?.currency) || state.field_data.type === 'money'),
);
const prevent_save_create = computed(() => {
  if (fieldname_already_exists.value)
    return true; // prevents save if the name already exists
  if (!state.field_data.name)
    return true; // prevents save if the name is null or undefined
  if (
    state.field_data.name === props.field_name
    && (props?.config?.currency?.code === state.field_data?.currency)
    && state.field_data.value === props.field_value
  )
    return true; // prevents save if the name, currency and value are unchanged
  if (!state.field_data.type)
    return true; // prevents save if the type is null or undefined
  return (state.field_data.type === 'money' && (!state.field_data.currency)); // prevents save if the currency is null or undefined
});
const prevent_save_update = computed(() => {
  if (state.field_data.name !== props.field_name && fieldname_already_exists.value)
    return true; // prevents save if the name has changed and the new name already exists
  if (!state.field_data.name)
    return true; // prevents save if the name is null or undefined
  if (state.field_data.name === props.field_name) // prevents save if the name is unchanged
    if (props?.config?.currency?.code === state.field_data?.currency) // prevents save if the currency is unchanged
      if (state.field_data.value === props.field_value) // prevents save if the value is unchanged
        return true;
      else if (state.field_data.type === 'date' && !!props.field_value && !!state.field_data.value)
        if (state.field_data.value.toISOString() === dayjs(props.field_value, 'YYYY-MM-DD').toDate().toISOString())
          return true; // prevents save if the value is unchanged (for dates)
  return !state.field_data.value; // prevents save if the value is null or undefined
});
const is_save_button_disabled = computed(() => props.operation_type === 'update' ? prevent_save_update.value : prevent_save_create.value);
const text_field_options = computed(() => uniq(active_schedule.value?.custom_fields?.[props.field_name]?.values));
const type_items = [
  ['text', 'Text'],
  ['number', 'Number'],
  ['money', 'Money'],
  ['date', 'Date'],
].map(item => ({
  value: item[0],
  label: $t(item[1]),
}));

function getDefaultCurrency() {
  let default_currency = '';
  Object.values(currencies).forEach((item) => {
    if (item.code === state.field_data?.currency?.code)
      default_currency = item.code;
  });
  return default_currency;
}

async function createCustomField(selected_currency) {
  await create_update_or_delete_custom_field({
    type: 'post',
    body: {
      field: state.field_data.name,
      type: state.field_data.type,
      ...(state.field_data.type === 'money'
        ? {
            config: {
              currency: {
                code: selected_currency.code,
                symbol: selected_currency.symbol,
              },
            },
          }
        : {}),
    },
  });
}

async function updateCustomField(selected_currency) {
  if (state.field_data.name !== props.field_name || props?.config?.currency?.code !== state.field_data?.currency)
    await create_update_or_delete_custom_field({
      type: 'patch',
      body: {
        field: props.field_name,
        new_name: state.field_data.name,
        ...(state.field_data.type === 'money'
          ? {
              config: {
                currency: {
                  code: selected_currency.code,
                  symbol: selected_currency.symbol,
                },
              },
            }
          : {}),
      },
    });
  if (state.field_data.value !== props.field_value)
    await add_or_update_custom_field_value(
      {
        uids: [active_task.value?.uid],
        custom_fields: [
          {
            field: state.field_data.name,
            value: state.field_data.value,
          },
        ],
      },
    );
}

async function saveField() {
  if (
    state.is_loading
    || (props.operation_type === 'create' && prevent_save_create.value)
    || (props.operation_type === 'update' && prevent_save_update.value)
  )
    return;
  let selected_currency = null;
  Object.values(currencies).forEach((item) => {
    if (item.code === state.field_data.currency)
      selected_currency = item;
  });
  try {
    state.is_loading = true;
    if (props.operation_type === 'create')
      await createCustomField(selected_currency);

    else if (props.operation_type === 'update')
      await updateCustomField(selected_currency);

    emit('close');
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}

async function deleteField() {
  await create_update_or_delete_custom_field({
    type: 'delete',
    body: { fields: state.field_data.name },
  });
  modify_config({
    key: 'columns',
    value: active_view.value.data.columns.filter(item => item.label !== state.field_data.name),
  });
  emit('close');
}
</script>

<template>
  <HawkModalContainer :options="{ teleportTo: is_fullscreen ? '#pm-fullscreen-container' : 'body' }">
    <Vueform
      v-model="state.field_data"
      sync
      size="sm"
      :columns="{
        default: { container: 12, label: 5, wrapper: 12 },
        sm: { container: 12, label: 5, wrapper: 12 },
        md: { container: 12, label: 5, wrapper: 12 },
      }"
      :display-errors="false"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            <div v-if="props.operation_type === 'create'" class="text-lg text-gray-900 font-semibold">
              {{ $t('New Field') }}
            </div>
            <div v-else class="text-lg text-gray-900 font-semibold">
              {{ $t('Update Field') }}
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent :is_scroll="false">
          <TextElement
            :default="props.operation_type === 'create' ? '' : props.field_name"
            name="name"
            :placeholder="$t('Enter field name')"
            :label="$t('Name')"
            class="w-full mb-6"
            rules="not_regex:/\./"
          />
          <SelectElement
            name="type"
            :disabled="props.operation_type === 'update'"
            :default="props.operation_type === 'create' ? '' : props.field_type"
            :items="type_items"
            :search="true"
            :native="false"
            :label="$t('Type')"
            :placeholder="$t('Select field type')"
            input-type="search"
            autocomplete="off"
            :can-clear="false"
            class="w-full mb-6"
          />
          <SelectElement
            v-if="has_currency"
            name="currency"
            :default="getDefaultCurrency()"
            :items="currency_items"
            :search="true"
            :native="false"
            :label="$t('Currency')"
            :placeholder="$t('Select currency')"
            input-type="search"
            autocomplete="off"
            :can-clear="false"
            class="w-full mb-6"
          />
          <template v-if="props.field_name">
            <DateTimeElement
              v-if="props?.field_type === 'date'"
              name="value"
              class="w-full"
              :label="$t('Value')"
              :options="{
                placeholder: $t('Enter value'),
              }"
            />
            <SelectElement
              v-else-if="props?.field_type === 'text'"
              :append-new-option="false"
              :can-clear="false"
              :create="true"
              :default="props.field_value"
              :items="text_field_options"
              :native="false"
              :search="true"
              autocomplete="on"
              class="w-full"
              :label="$t('Value')"
              input-type="search"
              name="value"
              :placeholder="$t('Enter value')"
            />
            <QuantityElement
              v-else
              :default="props.field_value"
              name="value"
              class="w-full"
              :label="$t('Value')"
            />
          </template>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-between w-[550px]">
              <HawkButton v-if="props.operation_type === 'update'" type="plain" color="error" @click="deleteField">
                {{ $t('Delete') }}
              </HawkButton>
              <div class="flex flex-row-reverse justify-start w-full">
                <ButtonElement
                  name="save"
                  submits
                  :button-label="$t('Save')"
                  :disabled="is_save_button_disabled"
                  :loading="state.is_loading"
                  @click="saveField"
                />
                <ButtonElement
                  name="cancel"
                  :button-label="$t('Cancel')"
                  :secondary="true" class="mr-4"
                  @click="emit('close')"
                />
              </div>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
