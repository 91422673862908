<script setup>
import { useModal } from 'vue-final-modal';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryAddCustomField from '~/inventory/components/inventory-custom-fields/inventory-add-custom-field.vue';
import HawkAssigneeInput from '~/common/components/vueform/hawk-assignee-input.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

const props = defineProps({
  options: {
    type: Object,
    default: () => {},
  },
  data: {
    type: Array,
    default: () => [],
  },
  values_map: {
    type: Object,
    default: () => {},
  },
  label_size: {
    type: Number,
    default: 6,
  },
  input_size: {
    type: Number,
    default: 8,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  attached_to: {
    type: String,
  },
  attached_to_uid: {
    type: String,
  },
  can_remove: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);
const { $t, $toast, auth_store, route } = useCommonImports();
const inventory_store = useInventoryStore();
const custom_fields_data = computed(() => props.data);
const list$ = ref({});

const field_details = computed(() => {
  return (uid) => {
    return inventory_store.custom_fields_map[uid];
  };
});

const details_by_index = computed(() => {
  return (index) => {
    return field_details.value(props.data[index]?.uid);
  };
});

function get_component(index) {
  const field = details_by_index.value(index);
  if (!field)
    return;

  switch (field?.type) {
    case 'text':
    case 'number':
    case 'email':
    case 'url':
    case 'phone_number':
      return 'TextElement';
    case 'member':
      return HawkAssigneeInput;
    case 'members':
      return HawkAssigneeInput;
    case 'dropdown':
      return 'SelectElement';
    case 'checkboxes':
      return 'CheckboxgroupElement';
    case 'date':
      return 'DateTimeElement';
    case 'signature':
      return 'SignatureElement';
  }
}

function format_signature_data(name, file) {
  if (!file)
    return { [name]: null };
  const file_service_object = file?.service_object
    ? {
        service: file.service_object,
        file_name: file.name,
        file_size: file.size,
        mime_type: file.type,
        meta: file.meta,
      }
    : file;
  return { [name]: file.file_name ? file : file_service_object };
}

function format_signature_load(file) {
  return file?.file_name
    ? {
        ...file,
        service: {
          ...file.service,
          url: file.url,
        },
      }
    : (file || null);
}

function format_members(members) {
  return members?.length ? members?.map(member => member?.uid || member) : [];
}

function format_members_data(name, value) {
  return {
    [name]: value?.map(member => member?.uid || member),
  };
}

function getSignatureData(value) {
  return value?.[0] || value;
}

function get_props(index) {
  const field = details_by_index.value(index);
  if (!field)
    return;

  const attrs = {
    name: 'value',
    label: field.name,
    placeholder: `Enter ${field.name}`,
    rules: [],
    description: field.description,
    disabled: !!props.options.disabled,
  };
  if (props.data[index].item_meta?.properties?.mandatory ?? field.mandatory)
    attrs.rules.push('required');

  if (field.config)
    attrs.items = field.config.map(item => ({ value: item, label: item }));

  switch (field?.type) {
    case 'text':
      return attrs;
    case 'number':
      attrs.inputType = 'number';
      if (attrs.rules.includes('required'))
        attrs.rules.push('numeric');
      else
        attrs.rules.push(...['nullable', 'numeric']);

      return attrs;
    case 'dropdown':
      attrs.placeholder = `Select ${field.name}`;
      attrs.search = true;
      attrs.native = false;
      attrs.inputType = 'search';
      attrs.autocomplete = 'off';
      return attrs;
    case 'email':
      attrs.inputType = 'email';
      if (attrs.rules.includes('required'))
        attrs.rules.push('email');
      else
        attrs.rules.push(...['nullable', 'email']);

      return attrs;
    case 'url':
      attrs.inputType = 'url';
      if (attrs.rules.includes('required'))
        attrs.rules.push('url');
      else
        attrs.rules.push(...['nullable', 'url']);

      attrs.floating = false;
      attrs.placeholder = 'eg. http(s)://domain.com';
      return attrs;
    case 'phone_number':
      attrs.inputType = 'tel';
      return attrs;
    case 'checkboxes':
    case 'date':
      return attrs;
    case 'member':
      return {
        options: {
          ...attrs,
          object: false,
        },
        multi: false,
        asset_id: route.params?.asset_id,
      };
    case 'members':
      return {
        options: {
          ...attrs,
          'format-load': format_members,
          'format-data': format_members_data,
        },
        multi: true,
        asset_id: route.params?.asset_id,
      };
    case 'signature':
      return {
        ...attrs,
        'format-data': format_signature_data,
        'format-load': format_signature_load,
        'onMounted': (data) => {
          const signature = getSignatureData(data.value);
          data.el$.load(signature, true);
        },
      };
    default:
      return attrs;
  }
}

function preventKeydown(e) {
  if (e.keyCode === 189 || e.keyCode === 187 || e.keyCode === 69)
    e.preventDefault();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

function onRemoveField(field, index) {
  patchOptions({
    attrs: {
      header: 'Remove field',
      button_text: 'Remove',
      content: `Are you sure you want to remove ${field.name} field?`,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        try {
          const field_data = inventory_store.get_custom_fields({ uid: props.attached_to_uid, attached_to: props.attached_to }).find(item => item.uid === field.uid);
          field_data.attached_to[field_data.item_meta.attached_to_index].active = false;
          await inventory_store.update_custom_field({
            id: field.uid,
            body: { custom_field: field_data },
          });
          closeDeletePopup();
          emit('close');
        }
        catch ({ data: error }) {
          const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
          $toast({
            title: title || 'Something went wrong',
            text: message || 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  openDeletePopup();
}
</script>

<template>
  <template v-if="props.data.length">
    <ListElement
      ref="list$"
      :name="options.name"
      size="sm"
      :controls="{
        add: false,
        remove: false,
      }"
      :columns="options.columns"
      :initial="props.data.length"
    >
      <template #default="{ index }">
        <ObjectElement :name="index" class="group">
          <HiddenElement name="uid" :default="details_by_index(index)?.uid" />
          <div class="flex col-span-12 gap-3">
            <component
              :is="get_component(index)"
              v-bind="get_props(index)"
              :readonly="props.readonly"
              :columns="{
                default: { container: 12, label: label_size, wrapper: 12 },
                sm: { container: 12, label: label_size, wrapper: 12 },
                md: { container: 12, label: label_size, wrapper: 12 },
              }"
              class="col-span-12 w-full"
              :add-classes="{
                ElementLabel: {
                  container: 'break-all',
                },
              }"
              @keydown="e => { details_by_index(index)?.type === 'number' ? preventKeydown(e) : null }"
            />
            <template v-if="props.can_remove">
              <HawkButton
                v-tippy="{ content: 'Remove field' }" class="flex-shrink-0 relative invisible group-hover:visible" icon size="xxs" color="gray" rounded
                :class="label_size === 12 ? 'top-6' : 'top-1'"
                @click="onRemoveField(details_by_index(index), index)"
              >
                <IconHawkX />
              </HawkButton>
            </template>
          </div>
        </ObjectElement>
      </template>
    </ListElement>
  </template>

  <InventoryAddCustomField
    v-if="auth_store.check_permission('modify_inventory_settings', route.params.asset_id)"
    :attached_to="props.attached_to"
    :attached_to_uid="props.attached_to_uid"
  />
</template>
