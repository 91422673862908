<script setup>
import { storeToRefs } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import SmGraph from '~/system-model/components/sm-graph/sm-graph.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { listToTree } from '~/common/utils/common.utils';

const { route, router } = useCommonImports();
const system_model_store = useSystemModelStore();
const { active_instance, active_instance_hierarchy } = storeToRefs(system_model_store);

const instance_tree = ref([]);

watch(active_instance_hierarchy, (value) => {
  if (value?.length)
    instance_tree.value = listToTree(value);
}, { immediate: true });

async function graphClickHandler(instance, double = false) {
  const component = getInstanceComponent(instance.component);
  system_model_store.set_active_component(component);
  system_model_store.set_active_instance(instance);
  system_model_store.set_sidebar_force_render(false);
  if (double)
    await system_model_store.set_active_instance_hierarchy();

  await new Promise(resolve => setTimeout(resolve, 10));
  router.replace({ query: { ...route.query, instance: instance.uid, component: component.uid } });
  system_model_store.set_sidebar_force_render(true);
}

function getInstanceComponent(component) {
  return system_model_store.templates_components_map[component];
}
</script>

<template>
  <SmGraph
    v-if="instance_tree.length"
    :items="instance_tree"
    :active="active_instance"
    @element-dbl-clicked="instance => graphClickHandler(instance, true)"
    @element-clicked="graphClickHandler"
  />
  <div v-else class="w-full h-full flex items-center justify-center">
    No data
  </div>
</template>
